import React, { Fragment, useState } from "react"
import ReactDOM from "react-dom"
import styled from "@emotion/styled"
import { Button, Avatar, BreadCrumb, Icon, Authorize } from "@components"
import RiderComponent from "./RiderComponent"
import TableKendo from "./TableKendo"
import { toCurrency, toPercent, convertNaNtoZero, captureScreen } from "@common/helper"
import { FieldArray } from "redux-form"
import SummaryModal from "./SummaryModal"
import Loading from "@pages/index/Loading.js"
import SaveDraftModal from "./SaveDraftModal"
import defaultLogo from "@assets/images/img-no-insurance.png"
import ControlTableUpDownStickyTab from "@pages/orders/show/ProductDetail/ControlTableUpDownStickyTab.js"
import { savePDF } from "@progress/kendo-react-pdf"

const MainLayout = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const DetailLayout = styled(MainLayout)``

const CustomAvatar = styled(Avatar)`
  border-radius: 5%;
  margin-right: 11px;
  border: none;
`
const InsurancePlan = styled.div`
  font-size: 30px;
  font-weight: normal;
  height: 39px;
  width: max-content;
  color: #666666;
  font-family: "ThaiSans Neue";
`
const CompanyName = styled.div`
  font-size: 16px;
  height: 33px;
  font-weight: bold;
  color: #0275d8;
  font-family: Sarabun, sans-serif;
`

const HeadContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  width: 550px;
`

const HeaderDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`
const StaffDetailsContainer = styled.div`
  min-width: 768px;
  width: 100%;
  height: 40px;
  background: #025aa5;
  display: flex;
  font-family: Sarabun, sans-serif;
  color: #ffffff;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  font-weight: normal;
`
const SubStaffDetailsContainer = styled.div`
  padding: 0 20px;
`
const TopicContainer = styled.div`
  min-width: 768px;
  margin-bottom: 20px;
  width: 100%;
  height: 40px;
  background: #294558;
  display: flex;
  font-family: Sarabun, sans-serif;
  color: #ffffff;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  font-weight: normal;
`

const SubDetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 6px;
`
const SubToppic = styled.div`
  height: 23px;
  color: #666666;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
`
const ContentContainer = styled.div`
  width: 550px;
  margin-bottom: 35px;
`

const RiderContainer = styled.div`
  min-height: 50px;
  width: 100%;

  display: flex;
  justify-content: center;
  flex-direction: column;
`
const NoContent = styled.div`
  display: flex;
  justify-content: center;
`

const SubToppicBigSize = styled.div`
  font-size: 16px;
  font-weight: normal;
  display: flex;
  align-self: flex-start;
  margin-bottom: 12px;
  font-family: Sarabun, sans-serif;
  color: #0275d8;
  margin-top: ${(props) => props.marginTop};
`

const SubDetail = styled.div`
  height: 23px;
  color: #666666;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
`
const IconCheckCircle = styled(Icon)`
  color: #5cb85c;
`

const IconTimesCircle = styled(Icon)`
  color: #d9534f;
`
const ConfirmBuyButton = styled(Button)`
  display: flex;
  background: #ffffff;
  border-radius: 4px;
  width: 118px;
  height: 38px;
  margin-right: 6px;
  box-shadow: unset;
  border-color: #0275d8;
  color: #0275d8;
  span.k-icon.k-i-cart {
    font-size: 18px;
  }
`
const SaveDraftButton = styled(Button)`
  display: flex;
  background: #ffffff;
  border-radius: 4px;
  height: 38px;
  margin-right: 6px;
  box-shadow: unset;
  border-color: #666666;
  color: #666666;
  span.k-icon.k-i-cart {
    font-size: 18px;
  }
`
const CancelButton = styled(Button)`
  display: flex;
  background: #ffffff;
  border-radius: 4px;
  width: 106px;
  height: 38px;
  margin-right: 6px;
  box-shadow: unset;

  span.k-icon.k-i-cart {
    font-size: 18px;
  }
`
const BreadCrumbLayout = styled.div`
  width: 100%;
  /* margin-top: 30px; */
  a,
  div {
    font-size: 10px;
  }
`
const ButtonsContainer = styled.div`
  display: flex;
  margin-bottom: 30px;
`
const WrapGraph = styled.div`
  padding: 30px 59px 35px 59px;
  margin-bottom: 30px;
`
const GraphImg = styled.img`
  width: 100%;
  min-width: 650px;
`
const Description = styled.div`
  color: #d9534f;
  font-size: 14px;
  margin-bottom: 8px;
  margin-top: -5px;
`

const DownButton = styled(Button)`
  display: flex;
  background: #ffffff;
  border-radius: 4px;
  height: 38px;
  box-shadow: unset;
  border-color: #666666;
  color: #666666;
  span.k-icon.k-i-cart {
    font-size: 18px;
  }
`
const SubRider = styled(SubDetailContainer)`
  padding-left: 24px;
  margin-top: 0;
`
const SubRiderPolicyDetail = styled(SubRider)`
  flex-direction: column;
`
const SubToppicRider = styled(SubToppic)`
  margin-top: 12px;
`
const downloadProduct = async (container, name) => {
  await savePDF(ReactDOM.findDOMNode(container), {
    scale: 0.6,
    paperSize: "A4",
    margin: "1cm",
    fileName: `${name}`,
  })
}
export const DownloadScreenButton = (props) => (
  <DownButton
    className={props.className}
    style={props.style}
    awesomeIcon="file-export"
    onClick={() => downloadProduct(props.container, props.name)}
  >
    ดาวน์โหลด
  </DownButton>
)

export const SummaryPage = (props) => {
  const [container, setContainer] = useState(null)
  return (
    <MainLayout>
      <BreadCrumbLayout>
        <BreadCrumb links={props.links} />
      </BreadCrumbLayout>
      {props.loading ? (
        <Loading />
      ) : (
        <>
          <DetailLayout ref={(component) => setContainer(component)}>
            <HeadContainer>
              <CustomAvatar
                src={(props.productDetail && props.productDetail.companyLogoUrl) || defaultLogo}
                size="100"
              />
              <HeaderDetailContainer>
                <InsurancePlan>{props.productDetail && props.productDetail.name}</InsurancePlan>
                <CompanyName>
                  สำหรับ เพศ {props.params.gender === "female" ? "หญิง" : "ชาย"} อายุ{" "}
                  {props.productDetail.age} ปี
                </CompanyName>
              </HeaderDetailContainer>
            </HeadContainer>

            <StaffDetailsContainer>
              <SubStaffDetailsContainer>
                ผู้แนะนำ :{" "}
                {props.memberType === "affiliate"
                  ? `${props.currentUserName.firstName} ${props.currentUserName.lastName}`
                  : props.affiliateDetail?.name
                  ? props.affiliateDetail.name
                  : "-"}
              </SubStaffDetailsContainer>
              <SubStaffDetailsContainer>
                ผู้ขาย :{" "}
                {props.memberType === "salesman" || props.memberType === "sales_leader"
                  ? `${props.currentUserName.firstName} ${props.currentUserName.lastName}`
                  : props.salesmanDetail?.name
                  ? props.salesmanDetail.name
                  : "-"}
              </SubStaffDetailsContainer>
            </StaffDetailsContainer>
            <TopicContainer>รายละเอียดแบบประกัน</TopicContainer>
            <ContentContainer>
              <SubDetailContainer>
                <SubToppic>ทุนประกันหลัก</SubToppic>
                <SubDetail>
                  {props.productDetail && toCurrency(props.productDetail.sumInsured)} บาท
                </SubDetail>
              </SubDetailContainer>
              <SubDetailContainer>
                <SubToppic>ค่าเบี้ยประกันหลัก</SubToppic>
                <SubDetail>
                  {props.productDetail && toCurrency(props.productDetail.premium)} บาท/งวด
                </SubDetail>
              </SubDetailContainer>
              <SubDetailContainer>
                <SubToppic>ระยะเวลาชำระเบี้ย</SubToppic>
                <SubDetail>{props.productDetail && props.productDetail.payPeriod} ปี</SubDetail>
              </SubDetailContainer>
              <br />

              {props.values.riders && props.values.riders.length > 0 && (
                <>
                  <SubToppicRider>สัญญาเพิ่มเติม</SubToppicRider>
                  {props.values.riders.map((rider) => (
                    <>
                      <SubRiderPolicyDetail>
                        <SubToppic>{rider.name}</SubToppic>
                        {rider.hasSumInsured && (
                          <SubDetailContainer>
                            <SubToppic>ทุนประกัน</SubToppic>
                            <SubDetail>{toCurrency(rider.sumInsured)} บาท</SubDetail>
                          </SubDetailContainer>
                        )}
                        <SubDetailContainer>
                          <SubToppic>ค่าเบี้ยประกัน</SubToppic>
                          <SubDetail>{toCurrency(rider.premium)} บาท/งวด</SubDetail>
                        </SubDetailContainer>
                      </SubRiderPolicyDetail>
                      <br />
                    </>
                  ))}
                </>
              )}

              <SubDetailContainer>
                <SubToppic>ค่าเบี้ยประกันรวม</SubToppic>
                <SubDetail>{toCurrency(props.totalPremium)} บาท/งวด</SubDetail>
              </SubDetailContainer>
              {props.productDetail && props.productDetail.insuranceType === "annuity" && (
                <>
                  <br />
                  <SubDetailContainer>
                    <SubToppic>เงินบำนาญ</SubToppic>
                    <SubDetail>
                      {toCurrency(
                        (props.productDetail.avgRefund / 100) * props.productDetail.sumInsured
                      )}{" "}
                      บาท
                    </SubDetail>
                  </SubDetailContainer>
                </>
              )}
            </ContentContainer>
            <TopicContainer>ผลประโยชน์ที่ได้รับ</TopicContainer>
            <ContentContainer>
              <SubToppicBigSize>ลดหย่อนภาษีได้</SubToppicBigSize>
              <SubDetailContainer>
                <SubToppic>แบบประกันหลัก</SubToppic>
                <SubDetail>
                  {props.productDetail.isTaxSaving ? (
                    <IconCheckCircle awesomeIcon={["fas", "check-circle"]} />
                  ) : (
                    <IconTimesCircle awesomeIcon={["fas", "times-circle"]} />
                  )}
                </SubDetail>
              </SubDetailContainer>
              {props.values.riders && props.values.riders.length > 0 && (
                <>
                  <SubToppicRider>สัญญาเพิ่มเติม</SubToppicRider>
                  {props.values.riders.map((rider) => (
                    <SubRider>
                      <SubToppic>{rider.name}</SubToppic>
                      <SubDetail>
                        {rider.isTaxSaving ? (
                          <IconCheckCircle awesomeIcon={["fas", "check-circle"]} />
                        ) : (
                          <IconTimesCircle awesomeIcon={["fas", "times-circle"]} />
                        )}
                      </SubDetail>
                    </SubRider>
                  ))}
                </>
              )}
              <br />
              <SubToppicBigSize>ความคุ้มครอง</SubToppicBigSize>
              <SubDetailContainer>
                <SubToppic>ทุนประกันหลัก</SubToppic>
                <SubDetail>
                  {props.productDetail && toCurrency(props.productDetail.sumInsured)} บาท
                </SubDetail>
              </SubDetailContainer>
              <SubDetailContainer>
                <SubToppic>ระยะเวลาคุ้มครอง</SubToppic>
                <SubDetail>
                  ถึงอายุ {props.productDetail && props.productDetail.protectEndAge} ปี
                </SubDetail>
              </SubDetailContainer>
              <SubToppicBigSize marginTop="20px">ผลตอบแทน</SubToppicBigSize>
              <SubDetailContainer>
                <SubToppic>ผลประโยชน์เมื่อครบสัญญา</SubToppic>
                <SubDetail>
                  {toPercent(props.productDetail && props.productDetail.policyReturnRate)}
                </SubDetail>
              </SubDetailContainer>
              {props.productDetail && props.productDetail.insuranceType === "annuity" && (
                <SubDetailContainer>
                  <SubToppic>เงินบำนาญต่อปี</SubToppic>
                  <SubDetail>
                    {toCurrency(
                      (props.productDetail.avgRefund / 100) * props.productDetail.sumInsured
                    )}{" "}
                    บาท
                  </SubDetail>
                </SubDetailContainer>
              )}
              {props.productDetail && props.productDetail.insuranceType === "annuity" && (
                <SubDetailContainer>
                  <SubToppic>ช่วงเวลารับบำนาญ</SubToppic>
                  <SubDetail>
                    {`${props.productDetail.minAgeAnnuity}-${props.productDetail.maxAgeAnnuity}`} ปี
                  </SubDetail>
                </SubDetailContainer>
              )}
            </ContentContainer>
            {!props.isCreateLead && props.productDetail && props.productDetail.protectionGraphUrl && (
              <Fragment>
                <TopicContainer>กราฟผลประโยชน์และความคุ้มครอง</TopicContainer>
                <WrapGraph>
                  <GraphImg
                    src={props.productDetail && props.productDetail.protectionGraphUrl}
                    alt={props.productDetail && props.productDetail.protectionGraphUrl}
                  />
                </WrapGraph>
              </Fragment>
            )}
            <TopicContainer>ตารางความคุ้มครองจากเบี้ยมาตรฐาน</TopicContainer>
            <Description>
              * เบี้ยประกันที่แสดงเป็นเบี้ยที่คำนวณจากขั้นอาชีพมาตรฐานเท่านั้น
              อาจไม่ตรงกับเบี้ยประกันที่ต้องจ่ายจริง
            </Description>
            <div style={{ marginBottom: "30px" }}>
              <TableKendo
                displayType={props.displayType}
                fullBenefit={props.productDetail && props.productDetail.insuranceCoverageTable}
              />
            </div>
            <TopicContainer>เงื่อนไข</TopicContainer>
            <ContentContainer>
              <SubDetailContainer>
                <SubToppic>อายุที่รับสมัคร</SubToppic>
                <SubDetail>
                  {`${props.productDetail.minAgeInsured} -
          ${props.productDetail.maxAgeInsured} ปี`}
                </SubDetail>
              </SubDetailContainer>
              <SubDetailContainer>
                <SubToppic>ระยะเวลาจ่ายเบี้ย</SubToppic>
                <SubDetail>{props.productDetail.payPeriodText}</SubDetail>
              </SubDetailContainer>
            </ContentContainer>
            <TopicContainer>สัญญาเพิ่มเติม</TopicContainer>

            <RiderContainer>
              {props.values.riders && props.values.riders.length > 0 ? (
                <FieldArray
                  name="riders"
                  component={RiderComponent}
                  readOnly={props.readOnly}
                  editPackagePage={props.editPackagePage}
                  productDetail={props.productDetail}
                  change={props.change}
                />
              ) : (
                <NoContent>ไม่มีสัญญาเพิ่มเติม</NoContent>
              )}
            </RiderContainer>
          </DetailLayout>
          <ButtonsContainer
            hasPaymentMethod={props.hasPaymentMethod}
            isCreateLead={props.isCreateLead}
          >
            <CancelButton icon="arrow-chevron-left" onClick={props.onCancel} styletype="delete">
              ย้อนกลับ
            </CancelButton>
            {props.params.orderId && props.params.leadId && props.orderState === "drafting" && (
              <CancelButton icon="cancel" onClick={props.onDelete} styletype="delete">
                ลบแบบ
              </CancelButton>
            )}
            <Authorize roles={["member.salesman", "member.team_leader", "member.affiliate"]}>
              {(!props.params.orderId || props.orderState === "drafting") && (
                <SaveDraftButton
                  type="button"
                  onClick={props.handleSaveDraft}
                  icon="save"
                  readOnly={props.readOnly}
                >
                  บันทึกแบบ
                </SaveDraftButton>
              )}
            </Authorize>
            <DownloadScreenButton
              style={{ marginRight: 6 }}
              container={container}
              name={props.productDetail.name}
            />
            <Authorize roles={["member.salesman", "member.team_leader", "member.affiliate"]}>
              {props.params.state !== "new" && (
                <ConfirmBuyButton
                  type="button"
                  onClick={props.onFormSubmit}
                  icon={props.iconButton}
                  readOnly={props.readOnly}
                  titleButton={props.titleButton}
                >
                  {props.titleButton}
                </ConfirmBuyButton>
              )}
            </Authorize>
          </ButtonsContainer>
          <SummaryModal
            open={props.toggleSummaryModal}
            onClose={props.onClose}
            values={props.orderResponse}
            riders={props.values.riders}
            orderId={props.orderIdResponse}
            leadId={props.params.leadId}
          />
          <SaveDraftModal
            open={props.openSaveDraftModal}
            onClose={() => {
              props.setOpenSaveDraftModal(false)
            }}
            values={props.orderResponse}
            riders={props.values.riders}
            orderId={props.orderIdResponse}
            leadId={props.params.leadId}
          />
        </>
      )}
      <ControlTableUpDownStickyTab />
    </MainLayout>
  )
}
