import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { compose } from "recompose"
import { convertNaNtoZero, toCurrency } from "@common/helper"

const MainLayout = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${(props) =>
    props.toggleSticky &&
    css`
      position: sticky;
      position: -webkit-sticky;
      top: 60px;
      left: 0px;
      z-index: 3;
    `}
`

const StickyTabLayout = styled.div`
  /* position: fixed;
  top: 59px;
  left: 0;
  right: 0; */
  width: 768px;
  height: 38px;
  background: #229ec0;
  border: 1px solid #eeeef2;
  z-index: 8;

  display: flex;
  justify-content: center;
  align-items: center;
`
const StickyTabTextLayout = styled.div`
  display: flex;
  font-weight: bold;
  justify-content: space-between;

  width: 550px;
`
const StickyTabTextLeft = styled.div`
  color: white;
`

const StickyTabTextRight = styled.div`
  color: white;
`

const StickyTab = (props) => (
  <MainLayout className={props.className} toggleSticky={props.sticky}>
    <StickyTabLayout id="stickyTab">
      <StickyTabTextLayout>
        <StickyTabTextLeft>
          {props.tabMessage
            ? props.tabMessage
            : `เบี้ยสัญญาหลัก ${props.haveRider ? "+ สัญญาเพิ่มเติม" : ""}`}
          {/* ค่าเบี้ยประกันที่ต้องจ่าย */}
        </StickyTabTextLeft>
        <StickyTabTextRight>{toCurrency(props.summary)} บาท</StickyTabTextRight>
      </StickyTabTextLayout>
    </StickyTabLayout>
  </MainLayout>
)

const enhancer = compose()

export default enhancer(StickyTab)
