import React, { useCallback, useMemo } from "react"
import { Grid, GridColumn, Input, CurrencyInput, Dropdown } from "@components"
import styled from "@emotion/styled"
import { compose } from "recompose"
import { withHooks } from "@enhancers/index"
import { Field } from "redux-form"

const Layout = styled.div``
const Table = styled(Grid)`
  .k-widget.k-grid {
    border-left-color: white;
    border-right-color: white;
  }

  .k-grid-header {
    height: 100% !important;
    padding: 0px !important;
  }

  .k-header {
    text-align: end;

    .k-link {
      padding: 17px 12px !important;
    }

    :nth-of-type(2) {
      text-align: start;
    }

    :nth-of-type(3) {
      text-align: ${(props) => (props.readOnly ? "end" : "center")};
    }

    :nth-of-type(4) {
      text-align: ${(props) => (props.readOnly ? "end" : "center")};
    }
  }

  .k-master-row {
    text-align: end;

    > td {
      padding: 8px 12px !important;
      line-height: 21px;

      :nth-of-type(3) > div {
        justify-content: flex-end;
      }

      :nth-of-type(4) > div {
        justify-content: flex-end;
      }

      :nth-of-type(5) {
        margin: 0 !important;

        > div {
          width: 100%;
          justify-content: flex-end;
        }

        .k-widget.k-combobox.k-header.k-combobox-clearable {
          padding: unset !important;
          margin: 0 !important;
        }
      }
    }
  }

  .k-master-row.k-alt {
    > td:nth-of-type(2) {
      line-height: 21px;
    }
  }
`
const CustomInput = styled(Input)`
  width: 161px;
`
const CustomCurrencyInput = styled(CurrencyInput)`
  width: 56px;
`
const CustomDropdown = styled(Dropdown)`
  width: 56px;

  .k-searchbar {
    > input {
      padding: 0px !important;
      padding-left: 15px !important;
    }
  }

  .k-icon.k-clear-value.k-i-close {
    display: none;
  }
`
const WithPercentTd = styled.div`
  display: flex;
`
const UnitPercent = styled.div`
  margin-left: 4px;
  font-size: 16px;
  line-height: 21px;
  align-self: center;
`

const CommissionStructureTable = (props) => {
  return (
    <Layout>
      <Table data={props.data} readOnly={props.readOnly}>
        <GridColumn title="ระดับ" field="positionLevel" width="61" />
        <GridColumn title="ตำแหน่ง" cell={props.positionNameCell} width="186" />
        <GridColumn title="คอมมิชชัน" cell={props.percentCell} width="100" />
        <GridColumn title="ค่าบริหาร" cell={props.managementPercentCell} width="100" />
        <GridColumn title="ตำแหน่งว่างให้" cell={props.reserveForLevelCell} width="120" />
      </Table>
    </Layout>
  )
}

const enhancer = compose(
  withHooks((props) => {
    const data = useMemo(() => {
      if (props.from === "show") {
        return props.initialValues
      } else {
        return props.commissionStructure
      }
    }, [props.fields])

    const positionNameCell = useCallback(
      (cell) => {
        return (
          <td>
            <Field
              name={`${props.name}.mkCommissionRates[${cell.dataIndex}].positionName`}
              component={CustomInput}
              placeholder="ระบุ"
              readOnly={props.readOnly}
            />
          </td>
        )
      },
      [props.from, props.readOnly]
    )

    const percentCell = useCallback(
      (cell) => {
        return (
          <td>
            <WithPercentTd>
              <Field
                name={`${props.name}.mkCommissionRates[${cell.dataIndex}].commissionPercent`}
                component={CustomCurrencyInput}
                readOnly={props.readOnly}
                max={100}
                min={0}
              />
              <UnitPercent>%</UnitPercent>
            </WithPercentTd>
          </td>
        )
      },
      [props.from, props.readOnly]
    )

    const managementPercentCell = useCallback(
      (cell) => {
        return (
          <td>
            <WithPercentTd>
              <Field
                name={`${props.name}.mkCommissionRates[${cell.dataIndex}].managementPercent`}
                component={CustomCurrencyInput}
                readOnly={props.readOnly}
                max={100}
                min={0}
              />
              <UnitPercent>%</UnitPercent>
            </WithPercentTd>
          </td>
        )
      },
      [props.from, props.readOnly]
    )

    const reserveForLevelCell = useCallback(
      (cell) => {
        return (
          <td>
            <Field
              name={`${props.name}.mkCommissionRates[${cell.dataIndex}].reserveForLevel`}
              component={CustomDropdown}
              readOnly={props.readOnly}
              options={props.reserveForLevelOptions}
            />
          </td>
        )
      },
      [props.from, props.readOnly, props.reserveForLevelOptions]
    )

    return {
      data,
      positionNameCell,
      percentCell,
      managementPercentCell,
      reserveForLevelCell,
    }
  })
)

export default enhancer(CommissionStructureTable)
