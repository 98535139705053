import React, { Fragment } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { compose, withHandlers, withProps, withState } from "recompose"
import { withValues, withField, withHidden, withStores } from "@enhancers"
import { Input, Field, Checkbox } from "@components"
import SubDistrict from "./Address/SubDistrict"
import District from "./Address/District.js"
import Province from "./Address/Province.js"
import Postcode from "./Address/Postcode.js"
import { isEmpty } from "lodash"

const Container = styled.div`
  display: ${(props) => (props.checkboxValue ? "none" : "flex")};
  flex-direction: column;

  ${(props) =>
    props.readOnly &&
    css`
      padding: 0px 20px 30px 20px;
    `}
`
const CustomInput = styled(Input)`
  > div:last-of-type {
    > input {
      width: 200px;
    }
  }

  ${(props) =>
    props.meta.touched &&
    props.meta.invalid &&
    css`
      > div:last-of-type {
        > input {
          border: 1px solid #dc3545;
          border-radius: 4px;

          :hover:not(.k-state-invalid) {
            font-family: Sarabun, sans-serif;
            font-size: 16px;
            border-radius: 4px;
            border: 1px solid #dc3545;
          }
        }
      }
    `}
`
const CustomBuildingInput = styled(Input)`
  > div:last-of-type {
    > input {
      width: 500px;
    }
  }

  ${(props) =>
    props.meta.touched &&
    props.meta.invalid &&
    css`
      > div:last-of-type {
        > input {
          border: 1px solid #dc3545;
          border-radius: 4px;

          :hover:not(.k-state-invalid) {
            font-family: Sarabun, sans-serif;
            font-size: 16px;
            border-radius: 4px;
            border: 1px solid #dc3545;
          }
        }
      }
    `}
`
const CustomVillageNoInput = styled(Input)`
  > div:last-of-type {
    > input {
      width: 100px;
    }
  }

  ${(props) =>
    props.meta.touched &&
    props.meta.invalid &&
    css`
      > div:last-of-type {
        > input {
          border: 1px solid #dc3545;
          border-radius: 4px;

          :hover:not(.k-state-invalid) {
            font-family: Sarabun, sans-serif;
            font-size: 16px;
            border-radius: 4px;
            border: 1px solid #dc3545;
          }
        }
      }
    `}
`
const CustomProvince = styled(Province)`
  > div:last-of-type {
    > span {
      width: 200px;
    }
  }

  ${(props) =>
    props.meta.touched &&
    props.meta.invalid &&
    css`
      > div:last-of-type {
        > span {
          border-radius: 4px;

          :hover:not(.k-state-invalid) {
            font-family: Sarabun, sans-serif;
            font-size: 16px;
            border-radius: 4px;
          }
        }
      }
    `}
`
const CustomDistrict = styled(District)`
  > div:last-of-type {
    > span {
      width: 200px;
    }
  }

  ${(props) =>
    props.meta.touched &&
    props.meta.invalid &&
    css`
      > div:last-of-type {
        > span {
          border: 1px solid #dc3545;
          border-radius: 4px;

          :hover:not(.k-state-invalid) {
            font-family: Sarabun, sans-serif;
            font-size: 16px;
            border-radius: 4px;
            border: 1px solid #dc3545;
          }
        }
      }
    `}
`
const CustomSubDistrict = styled(SubDistrict)`
  > div:last-of-type {
    > span {
      width: 200px;
    }
  }

  ${(props) =>
    props.meta.touched &&
    props.meta.invalid &&
    css`
      > div:last-of-type {
        > span {
          border: 1px solid #dc3545;
          border-radius: 4px;

          :hover:not(.k-state-invalid) {
            font-family: Sarabun, sans-serif;
            font-size: 16px;
            border-radius: 4px;
            border: 1px solid #dc3545;
          }
        }
      }
    `}
`
const CustomPostcode = styled(Postcode)`
  > div:last-of-type {
    > span {
      width: 200px;
    }
  }

  ${(props) =>
    props.meta.touched &&
    props.meta.invalid &&
    css`
      > div:last-of-type {
        > span {
          border: 1px solid #dc3545;
          border-radius: 4px;

          :hover:not(.k-state-invalid) {
            font-family: Sarabun, sans-serif;
            font-size: 16px;
            border-radius: 4px;
            border: 1px solid #dc3545;
          }
        }
      }
    `}
`
const FieldAddressContainer = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: center;

  ${(props) =>
    props.readOnly &&
    css`
      margin-top: 0px;
      margin-left: 74px;
    `}

  > div:nth-of-type(2) {
    margin-top: 0px;
  }
`
const Caption = styled.div`
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  color: #666666;
  font-weight: 600;
`
const Info = styled.div`
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  color: #666666;
  font-weight: "unset";
  margin-left: 44px;
`
const AddressHeaderContainer = styled(FieldAddressContainer)`
  height: 33.5px;
`

const Address = (props) => (
  <Fragment>
    {props.checkboxLabel && (
      <Checkbox
        label={props.checkboxLabel}
        value={props.checkboxValue}
        onChange={props.onCheckboxChange}
        name={props.name}
        hidden={props.orderState !== "new"}
      />
    )}
    {!props.checkboxValue && (
      <Container
        checkboxValue={props.checkboxValue}
        className={props.className || "" + props.name}
        readOnly={props.readOnly}
      >
        {props.readOnly && (
          <AddressHeaderContainer>
            <Caption>ที่อยู่</Caption>
            <Info>{props.addressValues}</Info>
            {/*<Field
              name="addressValues"
              component={CustomInput}
              readOnly={props.readOnly}
              hidden={props.hiddenHousingNo}
              leftWidth="105"
            />*/}
          </AddressHeaderContainer>
        )}
        {!props.readOnly && (
          <FieldAddressContainer>
            <Field
              name="housingNo"
              caption={props.housingNoCaption || "เลขที่"}
              component={CustomInput}
              readOnly={props.readOnly}
              hidden={props.hiddenHousingNo}
              validate={
                props.readOnly || props.optional
                  ? null
                  : props.currentRole === "member.affiliate"
                  ? null
                  : "required"
              }
              leftWidth="105"
            />
            <Field
              name="villageNo"
              caption={props.villageNoCaption || "หมู่"}
              component={CustomVillageNoInput}
              readOnly={props.readOnly}
              hidden={props.hiddenVillageNo}
              leftWidth="40"
            />
          </FieldAddressContainer>
        )}
        {!props.readOnly && (
          <FieldAddressContainer>
            <Field
              name="building"
              caption={props.buildingCaption || "หมู่บ้าน/อาคาร"}
              component={CustomBuildingInput}
              readOnly={props.readOnly}
              hidden={props.hiddenBuilding}
              leftWidth="105"
            />
          </FieldAddressContainer>
        )}
        {!props.readOnly && (
          <FieldAddressContainer>
            <Field
              name="lane"
              caption={props.laneCaption || "ซอย/ตรอก"}
              component={CustomInput}
              readOnly={props.readOnly}
              hidden={props.hiddenLane}
              leftWidth="105"
            />
            <Field
              name="road"
              caption={props.roadCaption || "ถนน"}
              component={CustomInput}
              readOnly={props.readOnly}
              hidden={props.hiddenRoad}
              leftWidth="105"
            />
          </FieldAddressContainer>
        )}
        <FieldAddressContainer readOnly={props.readOnly}>
          <Field
            name="subDistrict"
            caption={props.subDistrictCaption || "ตำบล/แขวง"}
            isReadOnlyAddressCaption={props.readOnly}
            component={CustomSubDistrict}
            readOnly={props.readOnly}
            onAfterChange={props.onSelectSubDistrict}
            hidden={props.hiddenSubDistrict}
            validate={props.readOnly || props.optional ? null : "required"}
            leftWidth="105"
          />
          <Field
            name="district"
            caption={props.districtCaption || "อำเภอ/เขต"}
            isReadOnlyAddressCaption={props.readOnly}
            component={CustomDistrict}
            readOnly={props.readOnly}
            hidden={props.hiddenDistrict}
            validate={props.readOnly || props.optional ? null : "required"}
            leftWidth="105"
          />
        </FieldAddressContainer>
        <FieldAddressContainer readOnly={props.readOnly}>
          <Field
            name="province"
            caption={props.provinceCaption || "จังหวัด"}
            isReadOnlyAddressCaption={props.readOnly}
            component={CustomProvince}
            readOnly={props.readOnly}
            hidden={props.hiddenProvince}
            validate={props.readOnly || props.optional ? null : "required"}
            leftWidth="105"
          />
          <Field
            name="postcode"
            caption={props.postcodeCaption || "รหัสไปรษณีย์"}
            isReadOnlyAddressCaption={props.readOnly}
            component={CustomPostcode}
            readOnly={props.readOnly}
            hidden={props.hiddenPostcode}
            validate={props.readOnly || props.optional ? null : "required"}
            leftWidth="105"
          />
        </FieldAddressContainer>
      </Container>
    )}
  </Fragment>
)

Address.SubDistrict = SubDistrict
Address.District = District
Address.Province = Province

const fieldNames = [
  "housingNo",
  "villageNo",
  "roomNo",
  "addressCurrentNo",
  "building",
  "lane",
  "road",
  "subDistrict",
  "district",
  "province",
  "postcode",
]

const enhancer = compose(
  withHidden(),
  withField(),
  withStores((stores) => ({
    currentRole: stores.appStore.currentRole,
  })),
  withState("checkboxValue", "setCheckboxValue", (props) => props.selected || false),
  withState("filledFields", "setFilledFields", []),
  withValues(...fieldNames),
  withProps((props) =>
    fieldNames.reduce(
      (memo, name) => ({
        ...memo,
        [`${name}Props`]: {
          ...props.inputs[name],
          ...props[`${name}Props`],
        },
      }),
      {}
    )
  ),
  withProps((props) => {
    const housingNo = (props.values[props.name] && props.values[props.name].housingNo) || {}
    const villageNo = (props.values[props.name] && props.values[props.name].villageNo) || {}
    const building = (props.values[props.name] && props.values[props.name].building) || {}
    const lane = (props.values[props.name] && props.values[props.name].lane) || {}
    const road = (props.values[props.name] && props.values[props.name].road) || {}
    const addressValues = `${isEmpty(housingNo) ? "" : housingNo} ${
      isEmpty(villageNo) ? "" : villageNo
    } ${isEmpty(building) ? "" : building} ${isEmpty(lane) ? "" : lane} ${
      isEmpty(road) ? "" : road
    }`
    return { addressValues: addressValues }
  }),
  withHandlers({
    onSelectSubDistrict: (props) => (values) => {
      props.change(`${props.name}.subDistrict`, values.subDistrict)
      props.change(`${props.name}.district`, values.district)
      props.change(`${props.name}.province`, values.province)
      props.change(`${props.name}.postcode`, values.postcode)
    },
    onCheckboxChange: (props) => (value) => {
      props.setCheckboxValue(value)

      if (!props.values.currentAddress) {
        return
      }

      const currentAddressKeys = Object.keys(props.values.currentAddress).filter(
        (key) => props.values.currentAddress[key] !== null
      )

      const filledFields = []

      if (value) {
        currentAddressKeys.forEach((key) => {
          if (props.values[props.name]) {
            if (props.values.currentAddress[key] !== props.values[props.name][key]) {
              props.change(`${props.name}.${key}`, props.values.currentAddress[key])
              filledFields.push(key)
            }
          } else {
            props.change(`${props.name}.${key}`, props.values.currentAddress[key])
            filledFields.push(key)
          }
        })
        props.setFilledFields(filledFields)
      } else {
        props.filledFields.forEach((field) => props.change(`${props.name}.${field}`, null))
      }
    },
  })
)

Address.enhancer = enhancer

export default enhancer(Address)
