import { compose, withProps } from "recompose"
import { withAuthorized } from "@enhancers"
import { isEmpty } from "lodash"

const Authorize = ({ children, ...props }) => {
  if (children instanceof Function) {
    return children(props)
  } else {
    return children
  }
}

const enhancer = compose(
  withProps((props) => {
    const authorize = {}
    if (props.roles) {
      authorize.roles = props.roles
    }
    if (props.role) {
      authorize.role = props.role
    }
    if (props.notRoles) {
      authorize.notRoles = props.notRoles
    }
    if (props.notRole) {
      authorize.notRole = props.notRole
    }

    if (!isEmpty(authorize)) {
      return { authorize }
    }
  }),
  withAuthorized()
)

export default enhancer(Authorize)
