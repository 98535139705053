import React, { useEffect } from "react"
import styled from "@emotion/styled"
import { compose, withState, withHandlers } from "recompose"
import { withForm, withStores, withRouter, withHooks } from "@enhancers"
import {
  Button,
  Input,
  Form,
  Field,
  Dropdown,
  TabStripTab,
  Avatar,
  Address,
  Grid as Table,
  GridColumn,
  BrowseButton,
} from "@components"
import DeleteModal from "./DeleteModal"

import * as path from "@common/path"
import { MenuTab } from "../../leads/show/CustomerInfo"
import { exceptNullKeys } from "@common/helper"

export const Layout = styled(Form)`
  width: 100%;
  justify-content: center;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`

const LeftPanel = styled.div`
  font-size: 30px;
  font-weight: bold;
  font-family: ThaiSans Neue;
  display: flex;
  align-items: center;
  color: #666666;
`

const RightPanel = styled.div``

const ButtonGroup = styled.div`
  > * {
    margin-left: 10px;
  }
`

const DetailContainer = styled.div`
  opacity: 1;
  padding: 10px 15px;
`
const SubmitButton = styled(Button)`
  width: 100px;
`
const CancelButton = styled(Button)`
  width: 90px;
`
const HeadButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  > div {
    margin-right: 10px;
  }
`
const DownloadButton = styled(Button)``

const InsurerInfo = (props) => (
  <Layout>
    <Header>
      <LeftPanel>ข้อมูลบริษัทประกัน</LeftPanel>
      <RightPanel>
        {props.isEdit ? (
          <ButtonGroup>
            <CancelButton
              icon="cancel"
              onClick={props.onCancel}
              children="ยกเลิก"
              styletype="delete"
            />
            <SubmitButton
              icon="floppy"
              onClick={props.onSave}
              children="บันทึก"
              styletype="agree"
            />
          </ButtonGroup>
        ) : (
          <ButtonGroup>
            <CancelButton icon="delete" onClick={props.onDelete} children="ลบ" styletype="delete" />
            <SubmitButton icon="edit" onClick={props.onEdit} children="แก้ไข" />
          </ButtonGroup>
        )}
      </RightPanel>
    </Header>

    <MenuTab selected={props.tabSelected} onSelect={props.onTabChange}>
      <TabStripTab title="ข้อมูลบริษัทประกัน">
        <DetailContainer>
          <Field
            name="logo"
            caption="โลโก้"
            component={Avatar}
            readOnly={!props.isEdit}
            leftWidth="200"
          />
          <Field
            name="name"
            caption="ชื่อ"
            component={Input}
            readOnly={!props.isEdit}
            validate="required"
            leftWidth="200"
          />
          <Field
            name="shortName"
            caption="ชื่อย่อ"
            component={Input}
            readOnly={!props.isEdit}
            validate="required"
            leftWidth="200"
          />
          <Field
            name="referenceCode"
            caption="รหัสอ้างอิง (สมุดคุม) TQM"
            component={Input}
            readOnly={!props.isEdit}
            validate="required"
            leftWidth="200"
          />
          <Field
            name="website"
            caption="เว็บไซต์"
            component={Input}
            readOnly={!props.isEdit}
            leftWidth="200"
          />
          <Field
            name="companyType"
            caption="ประเภท"
            component={Dropdown}
            options={props.typeOptions}
            readOnly={!props.isEdit}
            validate="required"
            leftWidth="200"
          />
          <Field
            name="ageCalculationType"
            caption="การคำนวณอายุ"
            component={Dropdown}
            options={props.ageCalculationTypeOptions}
            readOnly={!props.isEdit}
            validate="required"
            leftWidth="200"
          />
        </DetailContainer>
      </TabStripTab>

      <TabStripTab title="ที่อยู่บริษัทประกัน">
        <DetailContainer>
          <Field name="address" component={Address} readOnly={!props.isEdit} />
        </DetailContainer>
      </TabStripTab>

      <TabStripTab title="ขั้นอาชีพ">
        <DetailContainer>
          <HeadButtonContainer>
            <BrowseButton
              accept=".csv, .xls, .xlsx"
              onClick={props.onImport}
              multiple={false}
              awesomeIcon="upload"
              children="นำเข้าไฟล์"
              hidden={!props.isEdit}
            />
            <DownloadButton
              awesomeIcon="download"
              onClick={props.onDownload}
              children="นำออกไฟล์"
            />
          </HeadButtonContainer>

          <Table
            data={props.occupations.slice(props.skip, props.take + props.skip)}
            skip={props.skip}
            take={props.take}
            total={props.occupations.length}
            pageable={{
              buttonCount: 5,
            }}
            onPageChange={props.pageChange}
            style={{ marginTop: 27 }}
          >
            <GridColumn title="กลุ่มอาชีพ" field="category" width="200" />
            <GridColumn title="อาชีพ" field="name" width="200" />
            <GridColumn title="ขั้นอาชีพ" field="level" width="100" />
            <GridColumn title="เพิ่มเบี้ยพิเศษ (ต่อทุน1000)" field="specialPremium" width="205" />
          </Table>
        </DetailContainer>
      </TabStripTab>
    </MenuTab>

    <DeleteModal
      open={props.isShowDeleteModal}
      onClose={props.onCloseModal}
      caption="Insurer"
      onAfterDeleteSubmit={props.onAfterDeleteSubmit}
    />
  </Layout>
)

const enhancer = compose(
  withRouter(),
  withStores((stores, props) => ({
    fetchInsurer: stores.insurerStore.fetchById,
    deleteInsurer: stores.insurerStore.destroy,
    updateInsurer: stores.insurerStore.update,
    fetchPreviewOccupations: stores.insurerStore.fetchPreviewOccupations,
    downloadOccupation: stores.insurerStore.downloadOccupation,
    insurerId: props.match.params.insurerId,
    initialValues: stores.insurerStore.current,
    typeOptions: stores.insurerStore.$("configs.attributes.companyType", []),
    stateOptions: stores.insurerStore.$("configs.attributes.state.options", []),
    ageCalculationTypeOptions: stores.insurerStore.$(
      "configs.attributes.ageCalculationType.options",
      []
    ),
  })),
  withState("isEdit", "setIsEdit", false),
  withState("tabSelected", "setTabSelected", 0),
  withState("isShowDeleteModal", "setIsShowDeleteModal", false),
  withState("occupations", "setOccupations", []),
  withState("previewOccupations", "setPreviewOccupations", []),
  withState("skip", "setSkip", 0),
  withState("take", "setTake", 10),
  withForm({ form: "editInsurerInfo" }),
  withHandlers({
    onCloseModal: (props) => () => props.setIsShowDeleteModal(false),
    onDelete: (props) => () => props.setIsShowDeleteModal(true),
    onAfterDeleteSubmit: (props) => async () => {
      await props.deleteInsurer(props.insurerId)
      await props.history.push(path.insurersPath())
    },
    onEdit: (props) => () => {
      props.setIsEdit(true)
    },
    onCancel: (props) => () => {
      props.reset("editInsurerInfo")
      props.setIsEdit(false)
    },
    onSave: (props) =>
      props.handleSubmit(async (values) => {
        if (typeof values.logo === "string") {
          const { logo, ...rest } = values
          values = rest
        }
        const exceptNullParams = exceptNullKeys(values, "address")
        await props.updateInsurer(props.insurerId, {
          ...exceptNullParams,
          occupations: props.occupations,
        })
        await props.setIsEdit(false)
      }),
    onTabChange: (props) => (event) => {
      props.setTabSelected(event.selected)
    },
    onImport: (props) => async (file) => {
      const occupations = await props.fetchPreviewOccupations({ file })
      props.setPreviewOccupations(occupations)
    },
    onDownload: (props) => async () => {
      try {
        await props.downloadOccupation(props.insurerId)
      } catch {
        alert("นำออกไฟล์ไม่สำเร็จ กรุณาลองใหม่ภายหลัง")
      }
    },
    pageChange: (props) => (event) => {
      props.setSkip(event.page.skip)
      props.setTake(event.page.take)
    },
  }),
  withHooks((props) => {
    useEffect(() => {
      const occupations = (props.initialValues || {}).occupations
      props.setOccupations(occupations || [])
    }, [(props.initialValues || {}).occupations])

    useEffect(() => {
      const occupations = props.previewOccupations
      props.setOccupations(occupations)
    }, [props.previewOccupations])

    return
  })
)

export default enhancer(InsurerInfo)
