import React from "react"
import styled from "@emotion/styled"
import { compose, withProps, withHandlers, withState, lifecycle } from "recompose"
import moment from "moment"
import * as path from "@common/path"
import { BreadCrumb, Button, Dropdown, Field } from "@components/index"
import { LoadingScope } from "@components"
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout"
import { displayNumber } from "@common/helper"
import { get, isEqual, isEmpty } from "lodash"

import CardProduct from "./CardProduct"
import { withForm, withStores, withRouter } from "@enhancers/index"

const Layout = styled.div`
  display: flex;
  flex-direction: column;
`
const BreadCrumbLayout = styled.div`
  width: 100%;
  margin-bottom: 37px;
`
const HeaderBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`
const Topic = styled.div`
  font-family: ThaiSans Neue;
  font-size: 30px;
  font-weight: bold;
  color: #666666;
`
const WrapperHeader = styled.div`
  display: flex;
  flex-wrap: nowrap;
  > div:first-of-type {
    margin-right: 16px;
  }
`
const CustomDropdown = styled(Dropdown)`
  > div:first-of-type {
    height: 100%;
  }
`
const CustomButton = styled(Button)`
  width: 116px;
`

const NotFound = styled.div`
  font-size: 16px;
  font-family: Sarabun, sans-serif;
  color: #333333;
  color: #666666;
  width: 678px;
  height: 664px;
  background-color: #eeeef2;
  border: 1px solid #eeeef2;
  display: flex;
  justify-content: center;
  padding-top: 40px;
`

const Body = styled.div`
  font-size: 16px;
  font-family: Sarabun, sans-serif;
  color: #333333;
  > div:first-of-type {
    .k-link.k-header {
      border-radius: 4px 4px 0px 0px;
    }
  }
`
const CustomPanelBar = styled(PanelBar)`
  .k-item.k-state-default {
    .k-link.k-header {
      background-color: #294558;
      color: #fff;
      font-family: Sarabun, sans-serif;
      font-weight: bold;
      text-decoration: none;

      :hover {
        background-color: #294558;
        color: #fff;
      }
    }
  }
  .k-link.k-state-focused.k-state-selected {
    box-shadow: none !important;
  }
  .k-item.k-state-default.k-state-expanded {
    .k-link.k-header {
      > div {
        > div:nth-of-type(2) {
          display: none;
        }
      }
    }
  }
  .k-animation-container {
    z-index: 1;
  }
`
const Section = styled.div`
  display: flex;
  flex-direction: column;
`
const SectionHeader = styled.div`
  font-size: 16px;
  color: #666666;
  font-weight: bold;
  font-family: Sarabun, sans-serif;
  background-color: #e2e5e9;
  height: 40px;
  padding: 0 20px;
  display: flex;
  align-items: center;
`
const SectionBody = styled.div`
  background-color: #ffffff;
  border: 1px solid #eeeef2;
  padding: 30px 0px 16px 0px;
`
const SectionRow = styled.div`
  display: flex;
  align-items: flex-start;
`
const WrapperDetail = styled.div`
  flex: 1;
  padding: 0px 45px 0px 45px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;
`
const WrapperBefore = styled(WrapperDetail)`
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  color: #666666;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  > div:first-of-type {
    margin-bottom: 4px;
  }
`
const Caption = styled.div`
  font-weight: bold;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  width: 155px;
  color: #666666;
`
const Description = styled.div`
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  color: #666666;
  white-space: nowrap;
`
const TitlePanel = styled.div`
  width: calc(100% - 26px);
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
`
const SectionBodyCard = styled(SectionBody)`
  padding: 30px 24px 8px 24px;
`

const EditBy = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  font-weight: bold;
  font-family: Sarabun, sans-serif;
  font-size: 10px;

  > div {
    font-size: 10px;
  }

  > div:first-of-type {
    margin-right: 22px;
  }
`
const CustomCardProduct = styled(CardProduct)`
  > div:first-of-type {
    > div:first-of-type {
      margin-right: 30px;
    }
  }
`
const FooterEditBy = styled.div`
  height: 30px;
  padding-right: 46px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #e2e5e9;
  border: 1px solid #eeeef2;
  > div {
    color: #666666;
  }
`

export const BlueText = styled.div`
  color: ${(props) => (props.isBlue ? "#0275D8" : "#666666")};
  font-weight: ${(props) => (props.isBlue ? "bold" : "normal")};
`
const History = (props) => (
  <Layout>
    {props.loading && <LoadingScope />}

    <BreadCrumbLayout>
      <BreadCrumb links={props.links} />
    </BreadCrumbLayout>

    <HeaderBody>
      <Topic>ประวัติคอมมิชชั่นรับ</Topic>
      <WrapperHeader>
        <Field
          name="installment"
          caption="ประจำปี/งวด"
          component={CustomDropdown}
          options={props.installmentOptions}
          leftWidth="100"
          rightWidth="196"
        />

        <CustomButton icon="arrow-chevron-left" onClick={props.onBack} children="ย้อนกลับ" />
      </WrapperHeader>
    </HeaderBody>

    {props.currentHistory ? (
      <Body>
        {props.currentHistory.reverse().map((history, index) => (
          <CustomPanelBar key={index}>
            <PanelBarItem
              expanded={index === 0 ? true : false}
              title={
                <TitlePanel>
                  <div>
                    {history.event === "create"
                      ? `สร้างเมื่อ ${moment(history.eventDatedAt).format(
                          "DD/MM/YYYY เวลา HH:mm น."
                        )}`
                      : history.event === "import"
                      ? `แก้ไขเมื่อ ${moment(history.eventDatedAt).format(
                          "DD/MM/YYYY เวลา HH:mm น. (นำเข้าไฟล์)"
                        )}`
                      : `แก้ไขเมื่อ ${moment(history.eventDatedAt).format(
                          "DD/MM/YYYY เวลา HH:mm น."
                        )}`}
                  </div>
                  {history.updatedBy ? (
                    <EditBy>
                      <div>{history.event === "import" ? "นำเข้าโดย" : "แก้ไขโดย"}</div>
                      <div>{history.updatedBy}</div>
                    </EditBy>
                  ) : null}
                </TitlePanel>
              }
            >
              {history.insurerCommission ? (
                <Section>
                  <SectionHeader>ข้อมูลคอมมิชชั่น</SectionHeader>
                  <SectionBody>
                    <SectionRow>
                      <WrapperDetail>
                        <Caption>ปี/งวด</Caption>
                        <Description>{history.insurerCommission.paymentInstallment}</Description>
                      </WrapperDetail>
                      <WrapperDetail>
                        <Caption>วันที่รับเงิน</Caption>
                        <Description>
                          {history.insurerCommission.receivedDate
                            ? `${moment(history.insurerCommission.receivedDate).format(
                                "DD/MM/YYYY"
                              )}`
                            : null}
                        </Description>
                      </WrapperDetail>
                    </SectionRow>

                    <SectionRow>
                      <WrapperDetail>
                        <Caption>ชำระเงินผ่าน</Caption>
                        <Description>{history.insurerCommission.paymentVia}</Description>
                      </WrapperDetail>
                      <WrapperDetail>
                        <Caption>ประเภทการชำระเงิน</Caption>
                        <Description>{history.insurerCommission.paymentMethod}</Description>
                      </WrapperDetail>
                    </SectionRow>

                    <SectionRow>
                      <WrapperDetail>
                        <Caption>รหัสพนักงานขาย</Caption>
                        <Description>{history.insurerCommission.staffCode}</Description>
                      </WrapperDetail>
                    </SectionRow>
                  </SectionBody>
                </Section>
              ) : null}

              {history.totalInfo ? (
                <Section>
                  <SectionHeader>รวมทั้งหมด</SectionHeader>
                  <SectionBody>
                    <SectionRow>
                      <WrapperDetail>
                        <Caption>เบี้ยสุทธิ</Caption>
                        <Description style={{ height: "48px" }}>
                          <BlueText
                            isBlue={
                              history.event !== "import" &&
                              history.totalInfo.previousTotalPremium !==
                                history.totalInfo.realTotalPremium
                            }
                          >
                            {displayNumber(
                              parseFloat(history.totalInfo.totalPremium || 0).toFixed(2)
                            )}{" "}
                            บาท
                          </BlueText>
                        </Description>
                      </WrapperDetail>
                      <WrapperBefore>
                        <BlueText isBlue={history.event === "import"}>
                          *รับจริง{" "}
                          {displayNumber(
                            parseFloat(history.totalInfo.realTotalPremium || 0).toFixed(2)
                          )}{" "}
                          บาท
                        </BlueText>
                        <BlueText
                          isBlue={
                            history.event === "import" ||
                            history.totalInfo.previousTotalPremium !==
                              history.totalInfo.realTotalPremium
                          }
                        >
                          คงเหลือ{" "}
                          {displayNumber(
                            parseFloat(
                              parseFloat(history.totalInfo.totalPremium || 0).toFixed(2) -
                                parseFloat(history.totalInfo.realTotalPremium || 0).toFixed(2)
                            ).toFixed(2)
                          )}{" "}
                          บาท
                        </BlueText>
                      </WrapperBefore>
                    </SectionRow>

                    <SectionRow>
                      <WrapperDetail>
                        <Caption>ดอกเบี้ย</Caption>
                        <Description>
                          <BlueText
                            isBlue={
                              history.event !== "import" &&
                              history.totalInfo.previousInterestAmount !==
                                history.totalInfo.realInterestAmount
                            }
                          >
                            {displayNumber(
                              parseFloat(history.totalInfo.interestAmount || 0).toFixed(2)
                            )}{" "}
                            บาท
                          </BlueText>
                        </Description>
                      </WrapperDetail>
                    </SectionRow>

                    <SectionRow>
                      <WrapperDetail>
                        <Caption>ค่านายหน้า</Caption>
                        <Description>
                          <BlueText
                            isBlue={
                              history.event !== "import" &&
                              history.totalInfo.previousTotalCommission !==
                                history.totalInfo.realTotalCommission
                            }
                          >
                            {displayNumber(
                              parseFloat(history.totalInfo.totalCommission || 0).toFixed(2)
                            )}{" "}
                            บาท
                          </BlueText>
                        </Description>
                      </WrapperDetail>
                      <WrapperBefore>
                        <BlueText isBlue={history.event === "import"}>
                          *รับจริง{" "}
                          {displayNumber(
                            parseFloat(history.totalInfo.realTotalCommission || 0).toFixed(2)
                          )}{" "}
                          บาท
                        </BlueText>
                        <BlueText
                          isBlue={
                            history.event === "import" ||
                            history.totalInfo.previousTotalCommission !==
                              history.totalInfo.realTotalCommission
                          }
                        >
                          คงเหลือ{" "}
                          {displayNumber(
                            parseFloat(
                              parseFloat(history.totalInfo.totalCommission || 0).toFixed(2) -
                                parseFloat(history.totalInfo.realTotalCommission || 0).toFixed(2)
                            ).toFixed(2)
                          )}{" "}
                          บาท
                        </BlueText>
                      </WrapperBefore>
                    </SectionRow>

                    <SectionRow>
                      <WrapperDetail>
                        <Caption>ค่าบริการ</Caption>
                        <Description>
                          <BlueText
                            isBlue={
                              history.event !== "import" &&
                              history.totalInfo.previousTotalServiceCharge !==
                                history.totalInfo.realTotalServiceCharge
                            }
                          >
                            {displayNumber(
                              parseFloat(history.totalInfo.totalServiceCharge || 0).toFixed(2)
                            )}{" "}
                            บาท
                          </BlueText>
                        </Description>
                      </WrapperDetail>
                      <WrapperBefore>
                        <BlueText isBlue={history.event === "import"}>
                          *รับจริง{" "}
                          {displayNumber(
                            parseFloat(history.totalInfo.realTotalServiceCharge || 0).toFixed(2)
                          )}{" "}
                          บาท
                        </BlueText>
                        <BlueText
                          isBlue={
                            history.event === "import" ||
                            history.totalInfo.previousTotalServiceCharge !==
                              history.totalInfo.realTotalServiceCharge
                          }
                        >
                          คงเหลือ{" "}
                          {displayNumber(
                            parseFloat(
                              parseFloat(history.totalInfo.totalServiceCharge || 0).toFixed(2) -
                                parseFloat(history.totalInfo.realTotalServiceCharge || 0).toFixed(2)
                            ).toFixed(2)
                          )}{" "}
                          บาท
                        </BlueText>
                      </WrapperBefore>
                    </SectionRow>
                  </SectionBody>
                </Section>
              ) : null}

              {!isEmpty(history.lifeProduct) ? (
                <Section>
                  <SectionHeader>แบบประกันหลัก</SectionHeader>
                  <SectionBodyCard>
                    <CustomCardProduct
                      event={history.event}
                      logoPath={history.insurerCommission.logoPath}
                      product={history.lifeProduct}
                    />
                  </SectionBodyCard>
                </Section>
              ) : null}

              {history.riderProducts.length > 0 ? (
                <Section>
                  <SectionHeader>สัญญาเพิ่มเติม</SectionHeader>
                  <SectionBodyCard>
                    {history.riderProducts.map((rider, index) => (
                      <CardProduct
                        event={history.event}
                        logoPath={history.insurerCommission.logoPath}
                        product={rider}
                      />
                    ))}
                  </SectionBodyCard>
                </Section>
              ) : null}

              {history.updatedBy ? (
                <FooterEditBy>
                  <EditBy>
                    <div>{history.event === "import" ? "นำเข้าโดย" : "แก้ไขโดย"}</div>
                    <div>{history.updatedBy}</div>
                  </EditBy>
                </FooterEditBy>
              ) : null}
            </PanelBarItem>
          </CustomPanelBar>
        ))}
      </Body>
    ) : (
      <NotFound>ไม่พบข้อมูล</NotFound>
    )}
  </Layout>
)

const enhancer = compose(
  withRouter(),
  withState("loading", "setLoading", false),
  withStores((stores, props) => {
    const insurerCommissionId = props.match.params.insurerCommissionId

    return {
      fetchConfigsById: stores.insurerCommissionStore.fetchConfigsById,
      fetchHistoryById: stores.insurerCommissionStore.fetchHistoryById,
      currentHistory: stores.insurerCommissionStore.currentHistory,
      installmentOptions: stores.insurerCommissionStore.$(
        "detailConfigs.attributes.installment.options",
        []
      ),
      insurerCommissionId: insurerCommissionId,
      initialValues: {
        installment: parseInt(insurerCommissionId),
      },
    }
  }),
  withForm({
    form: "InsuranceCommissionHistory",
  }),
  withProps((props) => {
    let links = []

    links = [
      { label: "คอมมิชชั่นรับ", path: path.insurerCommissionsPath() },
      {
        label: "ประวัติการแก้ไข",
        path: path.insurerCommissionHistoryPath(props.insurerCommissionId),
      },
    ]

    return {
      links,
    }
  }),
  withHandlers({
    onBack: (props) => () => {
      props.history.push(path.insurerCommissionsPath())
    },
  }),
  lifecycle({
    async componentDidMount() {
      this.props.setLoading(true)
      await Promise.all([
        this.props.fetchConfigsById(this.props.insurerCommissionId),
        this.props.fetchHistoryById(this.props.insurerCommissionId),
      ])
      this.props.setLoading(false)
    },
    async componentDidUpdate(prevProps) {
      const prevInstallmentValue = get(prevProps, "values.installment")
      const installmentValue = get(this.props, "values.installment")

      if (!isEqual(prevInstallmentValue, installmentValue)) {
        await this.props.fetchHistoryById(installmentValue)
      }
    },
  })
)

export default enhancer(History)
