import BaseStore from "./BaseStore"
import { tqldServer } from "@common/api"
import { get, set } from "lodash"

class PoliciesStore extends BaseStore {
  constructor() {
    super({
      portDetailTable: {},
      portDetail: { lifeInsurancePolicy: {}, recommendProducts: [] },
      products: [],
      riderPolicies: [],
      currentRider: null,
      riderPolicyDetail: {},
      searchProductParams: {},
      configs: {},
    })
  }

  fetchConfigs = async () => {
    const response = await tqldServer.tqmBackend.get("/policies/configs")

    response.data.data.configs.filters.policyType.options.splice(1, 1)

    this.configs = response.data.data.configs
  }

  fetchProducts = async (params) => {
    const response = await tqldServer.tqmBackend.get("/policies", {
      birthdate: params.birthdate,
      gender: params.gender,
      searchBy: params.searchBy,
      searchValue: params.searchValue,
      policyType: params.type,
      weightType: 3,
      sortBy: params.sortBy || {},
    })
    const { policies } = response.data.data || []
    const customProducts = policies.map((data) => {
      return {
        ...data,
        age: params.age,
        gender: params.gender,
        sumInsured: params.sumInsured,
      }
    })

    this.products = customProducts
  }

  getDetail = async (params) => {
    const response = await tqldServer.tqmBackend.get(`/policies/${params.id}`, params)

    const premium = get(response, "data.data.policy.premium")
    if (![null, undefined].includes(premium)) {
      set(response, "data.data.policy.premium", parseFloat(premium))
    }

    this.portDetailTable = response.data.data.benefit
    this.portDetail = response.data.data.policy
    this.riderPolicies = response.data.data.riderPolicies.sort((a, b) => (a.isFree ? -1 : 1))

    this.configs = response.data.data.configs
    return response.data.data
  }

  getSupplementDetail = async (value) => {}

  postBuyProduct = async (value) => {}

  getHsSearchDetail = async (params) => {
    const response = await tqldServer.tqmBackend.get(`/rider_policies/${params.id}`, {
      birthdate: params.birthdate,
      gender: params.gender,
    })
    this.riderPolicyDetail = response.data.data.riderPolicyDetail
  }

  getCiSearchDetail = async (params, pure) => {
    const response = await tqldServer.tqmBackend.get(`/rider_policies/${params.id}`, {
      birthdate: params.birthdate,
      gender: params.gender,
      sumInsured: params.sumInsured,
      paymentPeriod: params.paymentPeriod,
      occupationCategory: params.occupationCategory,
      occupation: params.occupation,
    })
    if (!pure) {
      this.riderPolicyDetail = response.data.data.riderPolicyDetail
    }

    return response.data.data.riderPolicyDetail
  }

  storeSearchProductParams = async (values) => {
    this.searchProductParams = values
  }

  clearProduct = async (params) => {
    this.products = []
  }
}
export default new PoliciesStore()
