import React, { useMemo } from "react"
import styled from "@emotion/styled"
import { compose, withHandlers } from "recompose"
import { withRouter } from "@enhancers"
import { Button, Header as TextHeader, BreadCrumb, LoadingScope } from "@components"
import * as path from "@common/path"

import ViewAsOrderDetail from "./ViewAsOrderDetail"
import ViewAsMemberDetail from "./ViewAsMemberDetail/index"
import ViewAsAffiliateDetail from "./ViewAsAffiliateDetail"

const Layout = styled.div`
  margin: 0px auto 25px auto;
  transform: translate(calc((314px - (100vw - 678px)) / 2));
  width: calc(100vw - 250px);

  @media only screen and (max-width: 1024px) {
    width: 100%;
    transform: none;
  }
`
const BreadCrumbLayout = styled.div``
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 24px 0 12px 0;
  line-height: normal;
`
const LeftPanel = styled(TextHeader)`
  display: flex;
  align-items: center;
  color: #666666;
`
const RightPanel = styled.div``
const ActionButton = styled(Button)`
  width: 116px;

  .k-icon {
    font-size: 16px;
    margin-right: 6px;
  }
`

const SaleCommissions = (props) => {
  const renderViewAsDetail = useMemo(() => {
    if (props.location.query.viewType === "orders") return <ViewAsOrderDetail />
    if (props.location.query.viewType === "salesman") return <ViewAsMemberDetail />
    if (props.location.query.viewType === "affiliates") return <ViewAsAffiliateDetail />
  }, [props.location.query.viewType])

  return (
    <Layout>
      <BreadCrumbLayout>
        <BreadCrumb
          links={[
            {
              label: "คอมมิชชั่นจ่าย",
              path: path.saleCommissionsPath(props.location.query),
            },
            { label: "รายละเอียดคอมมิชชั่น", path: path.saleCommissionPath() },
          ]}
        />
      </BreadCrumbLayout>
      <Header>
        <LeftPanel>คอมมิชชั่นจ่าย</LeftPanel>
        <RightPanel>
          <ActionButton icon="arrow-chevron-left" onClick={props.onBack} children="ย้อนกลับ" />
        </RightPanel>
      </Header>
      {renderViewAsDetail}
    </Layout>
  )
}

const enhancer = compose(
  withRouter(),
  withHandlers({
    onBack: (props) => () => {
      props.history.push(path.saleCommissionsPath(props.location.query))
    },
  })
)

export default enhancer(SaleCommissions)
