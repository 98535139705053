import React from "react"
import styled from "@emotion/styled"
import { compose, withProps } from "recompose"
import { withField, withCaption, withReadOnly, withHidden } from "@enhancers/index"
import { BrowseButton, Input } from "./index"

const Layout = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  > div:first-of-type {
    max-width: 255px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const ReadOnly = styled.div`
  min-height: 33.5px;
  display: flex;
  align-items: center;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
`

const InputFile = (props) => (
  <Layout>
    {props.value.name && <Input value={props.value.name} readOnly />}
    <BrowseButton
      accept={props.accept || ".doc,.xls,.pdf,.jpeg,.jpg,.png"}
      multiple={false}
      onClick={props.onChange}
      awesomeIcon="upload"
      children={props.children || "อัพโหลดใหม่"}
    />
  </Layout>
)

const enhancer = compose(
  withField(),
  withHidden(),
  withCaption(),
  withProps((props) => ({
    readOnly: props.readOnly === undefined ? !props.onChange : props.readOnly,
  })),
  withReadOnly((props) => (
    <ReadOnly>
      <Input value={props.value.name} {...props} />
    </ReadOnly>
  ))
)

export default enhancer(InputFile)
