import React, { useState, useCallback, useEffect } from "react"
import { ComboBox } from "@progress/kendo-react-dropdowns"
import { compose, withHandlers, withProps, lifecycle } from "recompose"
import {
  withField,
  withCaption,
  withReadOnly,
  withHidden,
  omitProps,
  withHooks,
} from "../enhancers/index"
import styled, { css } from "@styled"
import withOptions from "@enhancers/withOptions"
import { getOptionLabel } from "@common/helper"
import { filterBy } from "@progress/kendo-data-query"
import { slice } from "lodash"

const CustomComboBox = styled(ComboBox)`
  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
    `} :hover {
    .k-dropdown-wrap {
      background-color: #eeeef2;
    }
    .k-select {
      background-color: #eeeef2;
    }
  }

  .k-header {
    padding: 0px;
  }

  .k-dropdown-wrap .k-combobox:not(.k-state-invalid) {
    border-color: #eeeef2;
  }

  .k-dropdown-wrap {
    .k-i-close {
      right: 20px;
    }
  }

  .k-clear-value {
    right: 20px;
  }

  ${(props) =>
    props.noDelete &&
    css`
      .k-dropdown-wrap {
        .k-i-close {
          display: none !important;
        }
      }
    `}

  .k-select {
    background-color: transparent;
    border: none;
    border-width: 0 !important;
    :hover {
      background-color: transparent;
      border: none;
    }
  }
  .k-searchbar {
    input {
      padding: 0.375rem 0.75rem !important;
      ::placeholder {
        font-family: Sarabun, sans-serif;
        font-size: 16px;
        color: #bec3c7;
        opacity: 1;
      }
      font-family: Sarabun, sans-serif;
      font-size: 16px;
    }
  }
`

const ReadOnly = styled.div`
  min-height: 33.5px;
  display: flex;
  align-items: center;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  color: #666666;
`

export const enhancer = compose(
  withHidden(),
  withField(),
  withCaption(),
  withOptions(),
  withProps((props) => {
    const options = props.options

    options.forEach((option, index) => {
      const duplicateOrder = slice(options, index + 1, options.length).filter(
        ({ label }) => label === option.label
      ).length
      const uniqSuffix = "​".repeat(duplicateOrder)
      option.label = `${option.label}${uniqSuffix}`
    })

    return { options }
  }),
  withProps((props) => {
    const data = props.options.map(({ label }) => label || "")
    const value = getOptionLabel(props.options, props.value)
    const className = props.className || "" + props.name
    return { data, value, className }
  }),
  withReadOnly((props) => <ReadOnly className={props.className}>{props.value}</ReadOnly>),
  withHandlers({
    onChange: (props) => (event) => {
      const value = (props.options.find(({ label }) => label === event.target.value) || {}).value
      props.onChange && props.onChange(value === undefined ? null : value, event)
    },
  }),
  withProps((props) => ({
    valid: props.forceValid === undefined ? !props.touched || props.valid : props.forceValid,
  })),
  omitProps("onBlur"),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (JSON.stringify(prevProps.options) !== JSON.stringify(this.props.options)) {
        const selectedOption = this.props.options.find(({ label }) => label === this.props.value)

        if (!selectedOption && (this.props.value !== undefined || this.props.value !== null)) {
          this.props.onChange({ target: { value: undefined } })
        }
      }
    },
  }),
  withHooks((props) => {
    const [loading, setLoading] = useState(false)
    const [filterTaskId, setFilterTaskId] = useState(null)
    const [filteredData, setFilteredData] = useState(null)
    const onFilterChange = useCallback(
      (e) => {
        if (filterTaskId) {
          clearTimeout(filterTaskId)
        }
        setFilterTaskId(
          setTimeout(() => {
            setFilteredData(filterBy(props.data.slice(), e.filter))
            setLoading(false)
          }, 400)
        )

        setLoading(true)
      },
      [filterTaskId, props.data]
    )

    useEffect(() => {
      setFilteredData(null)
    }, [props.value])

    return {
      filterable: true,
      loading,
      onFilterChange,
      data: filteredData || props.data,
    }
  })
)

export default enhancer(CustomComboBox)
