import React from "react"
import styled from "@emotion/styled"
import { Button, Avatar } from "@components"
import { compose, withProps } from "recompose"
import { withRouter, withForm } from "@enhancers/index"
import defaultLogo from "@assets/images/img-no-insurance.png"
import * as path from "@common/path"

const Layout = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 318px;
  background-color: white;
  border-radius: 4px;
  width: 280px;

  margin-bottom: 20px;

  padding: 16px 15px;
`
const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-top: 16px;
`
const Body = styled.div`
  display: flex;
  flex-direction: column;
`
const WrapperTitle = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 59px;
`
const PackageName = styled.div`
  font-size: 16px;
  margin-bottom: 10px;
  color: #666666;
  font-family: Sarabun, sans-serif;
  font-weight: bold;
`
const Title = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 20px;
`
const CompanyLogo = styled(Avatar)`
  width: 100%;
  height: 100%;
`
const CompanyName = styled.div`
  font-size: 10px;
  font-family: Sarabun, sans-serif;
  color: #666666;
`
const Logo = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin-right: 17px;
  min-width: 50px;
`
const Footer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`
const CustomTagA = styled.a`
  display: flex;
  flex-direction: row;
  text-decoration: none;
`
const CustomButton = styled(Button)`
  width: 120px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #0275d8 !important;
  color: #0275d8;

  .k-icon {
    font-size: 20px;
  }
`
const RowInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  height: 22px;
`
const Content = styled.div`
  text-align: left;
  font-size: 12px;
  font-family: Sarabun, sans-serif;
  color: #666666;
  font-weight: bold;
`
const PremiumContent = styled.div`
  text-align: left;
  font-size: 12px;
  color: #666666;
  font-family: Sarabun, sans-serif;
  display: flex;
  white-space: nowrap;
  align-items: center;
`
const Premium = styled.div`
  > span {
    font-size: 16px;
    font-weight: bold;
    color: #666666;
  }

  text-align: left;
  font-size: 12px;
`
const SpecialPremium = styled.div`
  font-size: 16px;
  font-weight: bold;
`

const displayNumber = (value) => {
  let nums = ("" + value).split(".")
  const onlyNums = nums[0].replace(/[^-\d]/g, "")
  let rgx = /(\d+)(\d{3})/
  let x1 = onlyNums
  while (rgx.test(x1)) {
    // eslint-disable-next-line no-useless-concat
    x1 = x1.replace(rgx, "$1" + "," + "$2")
  }
  return x1
}

const PackageCard = (props) => (
  <Layout>
    <DescriptionWrapper>
      <Body>
        <Title>
          <Logo>
            <CompanyLogo src={props.product.companyLogoUrl || defaultLogo} size={56} square />
          </Logo>
          <WrapperTitle>
            <PackageName>{props.product.packageName}</PackageName>
            <CompanyName>{props.product.companyName}</CompanyName>
          </WrapperTitle>
        </Title>

        <RowInfo>
          <Content>ชื่อประกันหลัก</Content>
          <Premium>
            <PremiumContent>{props.product.policyName}</PremiumContent>
          </Premium>
        </RowInfo>

        <RowInfo>
          <Content>ทุนประกันหลัก</Content>
          <Premium>
            <PremiumContent>
              <SpecialPremium>{displayNumber(props.product.lifeSumInsured)}</SpecialPremium>
              &nbsp;&nbsp;บาท
            </PremiumContent>
          </Premium>
        </RowInfo>

        <RowInfo>
          <Content>ทุนสัญญาเพิ่มเติม</Content>
          <Premium>
            <PremiumContent>
              {displayNumber(props.additionalSumInsured)}&nbsp;&nbsp;บาท
            </PremiumContent>
          </Premium>
        </RowInfo>

        <RowInfo>
          <Content>เบี้ยประกันรวม</Content>
          <Premium>
            <PremiumContent>
              {displayNumber(props.product.totalPremium)}
              &nbsp;&nbsp;บาท
            </PremiumContent>
          </Premium>
        </RowInfo>
      </Body>

      <Footer>
        <CustomTagA>
          <CustomButton
            to={path.productPackageEditPath(props.product.id, {
              leadId: props.leadId,
              gender: props.gender,
              sumInsured: props.sumInsured,
              birthdate: props.birthdate,
              type: props.search.type,
              packageId: props.product.id,
              from: props.search.from,
              opportunityKey: props.search.opportunityKey,
            })}
            style={{ marginRight: 10 }}
            icon="calculator"
          >
            คำนวณเบี้ย
          </CustomButton>
          <CustomButton
            to={path.productPackageShowDetailPath(props.product.id, {
              leadId: props.leadId,
              gender: props.gender,
              sumInsured: props.sumInsured,
              birthdate: props.birthdate,
              type: props.search.type,
              packageId: props.product.id,
              from: props.search.from,
              opportunityKey: props.search.opportunityKey,
            })}
            icon="search"
          >
            รายละเอียด
          </CustomButton>
        </CustomTagA>
      </Footer>
    </DescriptionWrapper>
  </Layout>
)

const enhancer = compose(
  withForm({ form: "PackageInfoOptions", destroyOnUnmount: false }),
  withRouter({
    queryTypes: {
      birthdate: "date",
      gender: "string",
      sumInsured: "integer",
      type: "string",
      leadId: "integer",
      from: "string",
    },
  }),
  withProps((props) => {
    const { packagesRiderInsurancePolicies, age, gender, sumInsured } = props.product

    const { birthdate, leadId } = props.location.query
    const additionalSumInsured =
      packagesRiderInsurancePolicies &&
      packagesRiderInsurancePolicies.reduce((sum, riderPolicy) => {
        return riderPolicy.sumInsured ? sum + parseInt(riderPolicy.sumInsured) : sum + 0
      }, 0)
    return {
      additionalSumInsured: additionalSumInsured,
      leadId,
      age,
      gender,
      sumInsured,
      birthdate,
    }
  })
)

export default enhancer(PackageCard)
