import React from "react"
import styled from "@emotion/styled"
import { compose, withHandlers } from "recompose"
import { Button, Dialog, DialogActionsBar } from "@components"
import { withForm, withStores } from "@enhancers"
import SuggestForm from "./SuggestForm"

export const CustomDialog = styled(Dialog)`
  .k-window {
    border: 1px solid #e6e6e6;
    min-width: 348px;
  }
`

export const Title = styled.div`
  font-size: 16px;
  padding: 20px 15px;
  font-family: Sarabun, sans-serif;
  color: #666666;
`

export const CloseButton = styled(Button)`
  height: 52px;
  color: #666666;
`

export const SubmitButton = styled(Button)`
  height: 52px;
  background: #0275d8;
  border: 1px solid #eeeef2;
  color: #ffffff;
`

const SuggestionModal = (props) => <SuggestForm {...props} />

export default SuggestionModal
