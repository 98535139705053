import { flatten, uniq } from "lodash"
import { compose, withState, withHandlers, defaultProps, withProps } from "recompose"
import { AutoComplete } from "@components/index"
import addressData from "./address_data.json"

const postcodeList = uniq(
  flatten(
    addressData.map(([province, districtData]) =>
      flatten(
        districtData.map(([district, subDistrictData]) =>
          flatten(subDistrictData.map(([subDistrict, postcode]) => `${postcode}`))
        )
      )
    )
  )
)

const searchPostCode = (q, options = {}) => {
  let result = postcodeList.filter((postcode) => postcode.includes(q))

  if (options.limit) {
    result = result.slice(0, options.limit)
  }

  return result
}

const enhancer = compose(
  defaultProps({
    delay: 400,
    subDistrictOnly: false,
  }),
  withState("loading", "setLoading", false),
  withState("searchTask", "setSearchTask", null),
  withState("options", "setOptions", (props) => searchPostCode(props.value, { limit: 5 })),
  withProps((props) => {
    const { name } = props.input || {}
    const className = props.className + " " + name || "" + name
    return { className }
  }),
  withHandlers({
    onChange: (props) => (value) => {
      if (props.searchTask) {
        clearTimeout(props.searchTask)
      }
      props.setLoading(true)
      props.setSearchTask(
        setTimeout(() => {
          props.setOptions(searchPostCode(value, { limit: 5 }))
          props.setLoading(false)
        }, props.delay)
      )

      if (props.subDistrictOnly) {
        const [subDistrict] = value.split(" » ")
        value = subDistrict
      }

      props.onChange && props.onChange(value)
    },
  })
)

export default enhancer(AutoComplete)
