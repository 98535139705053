import React from "react"
import styled, { css } from "@styled"
import {
  Chart,
  ChartTitle,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartAxisDefaults,
  ChartTooltip,
} from "@progress/kendo-react-charts"
import { displayNumber } from "@common/helper"

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  margin-top: 18px;
  border-radius: 5px;
`
const SwapContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 23px;
  align-items: center;
`
const Swap = styled.div`
  display: flex;
  align-items: flex-end;
`
const SwapButton = styled.div`
  font-family: Sarabun;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  cursor: pointer;

  ${(props) =>
    props.isactive &&
    css`
      pointer-events: none;
      color: #0275d8;
    `}
`

const nestedTooltipRender = ({ point }) => (
  <span>
    <a>{displayNumber(point.value)}</a>
  </span>
)

const ChartContainer = (props) => (
  <Chart>
    <ChartTitle />
    <ChartCategoryAxis>
      <ChartCategoryAxisItem categories={props.graph.xAxisCatagory} />
    </ChartCategoryAxis>
    <ChartAxisDefaults labels={{ format: "n0" }} />
    <ChartTooltip render={nestedTooltipRender} />
    <ChartSeries>
      <ChartSeriesItem
        type="line"
        data={props.graph.commission}
        markers={{ visible: false }}
        tooltip={{ visible: true }}
        color="#025AA5"
      />
    </ChartSeries>
  </Chart>
)

const CommissionGraph = (props) => {
  return (
    <Layout>
      <SwapContainer>
        <Swap>
          <SwapButton>คอมมิชชัน</SwapButton>
        </Swap>
      </SwapContainer>

      {props.graph && <ChartContainer graph={props.graph} />}
    </Layout>
  )
}

export default CommissionGraph
