import React from "react"
import { compose, withHandlers, withProps, lifecycle, withState } from "recompose"
import { withField, withCaption } from "@enhancers"
import Button from "./Button"
import styled, { css } from "@styled"

const Layout = styled.div`
  display: flex;

  > span {
    max-width: 120px;
    margin-left: 0;
  }

  > input {
    flex: 1;
    margin-top: 0px !important;
    margin-left: 10px;
    font-family: Sarabun, sans-serif;
    font-size: 16px;
  }
  button {
    border-radius: 0;
  }
  button:first-of-type {
    border-radius: 5px 0 0 5px;
  }
  button:last-child {
    border-radius: 0 5px 5px 0;
  }
`

const CustomButton = styled(Button)`
  width: 100px;
  height: 38px;
  opacity: 1;
  background: #ffffff;
  color: #0275d8;
  :active,
  :hover {
    background: #0275d8;
    border: 1px solid #0275d8;
    font-weight: normal;
    color: #ffffff;
  }
  :disabled {
    opacity: 1;
    color: #deeaf6;
    border-color: #deeaf6;
  }
  ${(props) =>
    props.isactive &&
    css`
      font-weight: normal;
      pointer-events: none;
      background: #0275d8;
      border: 1px solid #0275d8;
      color: #ffffff;
    `}
`
const paymentSelect = [
  { label: "รายปี", value: "yearly", disabled: true, key: "12" },
  { label: "ราย 6 เดือน", value: "half_yearly", disabled: true, key: "6" },
  { label: "ราย 3 เดือน", value: "quarterly", disabled: true, key: "3" },
  { label: "รายเดือน", value: "monthly", disabled: true, key: "1" },
]
const prepareSortPayment = {
  yearly: "12",
  half_yearly: "6",
  quarterly: "3",
  monthly: "1",
}
const PaymentPeriodInput = (props) => {
  const showPayment =
    props.configs &&
    paymentSelect.map((payment) => {
      if (props.configs.find((config) => config.value === payment.value)) {
        return {
          ...payment,
          disabled: false,
        }
      }
      return payment
    })

  return (
    <Layout className={props.className}>
      {showPayment.map((config) => (
        <CustomButton
          value={config.value}
          onClick={props.makeOnChange(config.value)}
          isactive={props.value === config.value}
          disabled={config.disabled}
        >
          {config.label}
        </CustomButton>
      ))}
      {/* <CustomButton
        value="12"
        onClick={props.makeOnChange("12")}
        isactive={props.value === "12" ? "true" : undefined}
      >
        รายปี
      </CustomButton>
      <CustomButton
        value="6"
        onClick={props.makeOnChange("6")}
        isactive={props.value === "6" ? "true" : undefined}
      >
        ราย 6 เดือน
      </CustomButton>
      <CustomButton
        value="3"
        onClick={props.makeOnChange("3")}
        isactive={props.value === "3" ? "true" : undefined}
      >
        ราย 3 เดือน
      </CustomButton>
      <CustomButton
        value="1"
        onClick={props.makeOnChange("1")}
        isactive={props.value === "1" ? "true" : undefined}
      >
        รายเดือน
      </CustomButton> */}
    </Layout>
  )
}

const enhancer = compose(
  withField(),
  withCaption(),
  withState("paymentPeriodConfigs", "setPaymentPeriodConfigs", null),
  withHandlers({
    makeOnChange: (props) => (fieldName) => (value) => {
      props.onChange(fieldName)
    },
  }),
  withProps((props) => ({
    value: props.value || props.paymentPeriodConfigs,
  })),
  lifecycle({
    componentDidMount() {
      if (this.props.configs && this.props.configs[0]) {
        const prepareSort = this.props.configs.map((payment) => {
          payment["key"] = prepareSortPayment[payment.value]
          return payment
        })
        const sortConfigs = prepareSort.sort((a, b) => b.key - a.key)
        this.props.setPaymentPeriodConfigs(sortConfigs[0].value)
      }
    },
  })
)

export default enhancer(PaymentPeriodInput)
