import React from "react"
import styled from "@emotion/styled"
import { compose, withHandlers, withProps, lifecycle } from "recompose"
import { withRouter, withStores } from "@enhancers/index"
import { Button, BreadCrumb } from "@components"
import * as path from "@common/path"

const Layout = styled.div`
  margin: 0px auto 25px auto;
`

const Body = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const AllContainer = styled.div`
  width: 500px;
  border: 1px solid #e6e6e6;
  padding: 15px;
  background-color: #ffffff;
`
const TypeText = styled.div`
  letter-spacing: 0;
  text-align: center;
  color: #666666;
  font-size: 30px;
`
const BreadCrumbLayout = styled.div`
  width: 100%;
`
const ContainerButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  > button {
    display: flex;
    justify-content: flex-start;
    width: 436px;
  }
  > button :first-of-type {
    margin-top: 16px;
  }

  > button :nth-of-type(n + 2) {
    margin-top: 12px;
  }
`

const Planning = (props) => (
  <Layout>
    <BreadCrumbLayout>
      <BreadCrumb links={props.links} />
    </BreadCrumbLayout>
    <Body>
      <AllContainer>
        <TypeText>เลือกแบบประกันที่ต้องการ</TypeText>
        <ContainerButton>
          <Button awesomeIcon="shield-alt" onClick={props.onClickTypeShortPath("family")}>
            วางแผนปกป้องภาระที่อาจจะตกถึงครอบครัว
          </Button>
          <Button awesomeIcon="coins" onClick={props.onClickTypeShortPath("protectIncome")}>
            วางแผนเพื่อสร้างความคุ้มครองด้านรายได้ของครอบครัว
          </Button>
          <Button
            awesomeIcon="hand-holding-heart"
            onClick={props.onClickTypeShortPath("retirement")}
          >
            วางแผนเงินเกษียณที่ใช้อยู่ดูแลเราไปจนแก่เฒ่า
          </Button>
          <Button awesomeIcon="child" onClick={props.onClickTypeShortPath("education")}>
            วางแผนคุ้มครองค่าใช้จ่ายการศึกษาบุตร
          </Button>
          <Button awesomeIcon="briefcase" onClick={props.onClickTypePath("tax")}>
            วางแผนลดหย่อนภาษีด้วยประกัน
          </Button>
          <Button awesomeIcon="piggy-bank" onClick={props.onClickTypePath("saving")}>
            วางแผนสะสมเงินพร้อมทุนประกัน
          </Button>
        </ContainerButton>
      </AllContainer>
    </Body>
  </Layout>
)

const enhancer = compose(
  withRouter(),
  withStores((stores) => ({
    fetchById: stores.leadStore.fetchById,
    current: stores.leadStore.current,
  })),
  withProps((props) => {
    return {
      ...props.location.query,
    }
  }),
  withProps((props) => {
    const { from = "" } = props
    let links = []
    switch (from) {
      case "custom_lead":
        links = [
          { label: "ลูกค้าใหม่", path: path.leadsPath("custom") },
          {
            label: "รายละเอียดลีด",
            path: path.leadOrderInfoPath(props.leadId),
          },
          {
            label: "วางแผนค้นหาแบบประกัน",
          },
        ]
        break
      case "batch_lead":
        links = [
          { label: "ลีดทีคิวเอ็ม", path: path.leadsPath("all") },
          {
            label: "รายละเอียดลีด",
            path: path.leadOrderInfoPath(props.leadId),
          },
          {
            label: "วางแผนค้นหาแบบประกัน",
          },
        ]
        break
      default:
        links = [
          {
            label: "ผลิตภัณฑ์",
            path: "/",
          },
          {
            label: "วางแผนค้นหาแบบประกัน",
          },
        ]
        break
    }
    return { links }
  }),
  withHandlers({
    onClickTypeShortPath: (props) => (type) => () => {
      props.history.push(
        path.planningTypeShortPath(type, {
          ...props.location.query,
        })
      )
    },
    onClickTypePath: (props) => (type) => () => {
      props.history.push(
        path.planningTypePath(type, {
          ...props.location.query,
        })
      )
    },
  }),
  lifecycle({
    componentDidMount() {
      if (this.props.leadId) {
        this.props.fetchById(this.props.leadId)
      }
    },
  })
)
export default enhancer(Planning)
