import React from "react"
import styled from "@emotion/styled"
import { compose, withHandlers } from "recompose"
import { withForm, withRouter, withStores } from "@enhancers/index"
import { Button, Field, CurrencyInput, GenderInput, DatePicker } from "@components"
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout"
import * as path from "@common/path"
import { convertNaNtoZero, convertMinustoZero } from "@common/helper"
import { WrapInputDate, AgeLabel } from "./SimpleForm"
import moment from "moment"

export const Layout = styled.div`
  margin: 0px 45px 25px 45px;
`

export const Body = styled.div`
  width: 100%;
`

export const AgeAndGenderLayout = styled.div`
  display: flex;
  width: 500px;
  padding: 16px 15px 12px 15px;
  flex-wrap: wrap;
  > div {
    :nth-of-type(odd) {
      display: flex;
      justify-content: space-between;
      width: 85%;
      margin-top: 16px;
      > div {
        > div {
          line-height: 24px;
        }
      }
    }
    :nth-of-type(even) {
      margin-top: 16px;
      display: flex;
      align-items: center;
      margin-left: 6px;
    }
  }

  width: 100%;
`

export const CustomPanelBar = styled(PanelBar)`
  width: 100%;
  margin-top: 16px;
  border: unset;

  > div {
    > span.k-link.k-header {
      background-color: #627b8b;
      border-color: #627b8b;
      color: #fff;
      :hover {
        background-color: #627b8b;
        border-color: #627b8b;
        color: #fff;
      }
    }
    > span.k-link.k-header.k-state-selected {
      border: unset;
      box-shadow: unset;
      background-color: #627b8b;
      border-color: #627b8b;
      color: #fff;
    }
    > span.k-link.k-header.k-state-selected.k-state-focused {
      border: unset;
      box-shadow: unset;
      background-color: #627b8b;
      border-color: #627b8b;
      color: #fff;
    }
    > div {
      z-index: unset;
      > div {
        margin: 16px 15px 12px 15px;
        > div {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          > div {
            > div {
              display: flex;
              justify-content: space-between;
              margin-top: 16px;
            }
            :nth-of-type(odd) {
              width: 85%;
              > div {
                > div:first-of-type {
                  height: 50px;
                }
              }
            }
            :nth-of-type(even) {
              padding-top: 16px;
              margin-left: 6px;
            }
          }
        }
      }
    }
  }
`

const Unit = styled.div`
  width: 29px;
`

const ConfirmButton = styled(Button)`
  display: flex;
  margin: auto;
  width: fit-content;
  margin-top: 16px;
`

export const Footer = styled.div`
  background: #f8f9fa;
  padding: 26px 16px 16px 16px;
  margin-top: 10px;
  margin-bottom: -16px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`
export const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const TextResult = styled.div`
  text-align: center;
  margin-bottom: 10px;
  line-height: 1.5;
`
export const ValueResult = styled.div`
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 18px;
`

export const BoldText = styled.b``

const Planning = (props) => (
  <Layout>
    <Body>
      <AgeAndGenderLayout>
        <Field
          component={GenderInput}
          name="gender"
          caption="เพศ"
          rightWidth="200"
          placeholder="กรุณาระบุ"
          validate="required"
        />
        <Unit />

        <WrapInputDate>
          {props.values.birthdate && (
            <AgeLabel show={props.values.birthdate !== null}>
              (อายุ {moment().diff(moment(props.values.birthdate), "years")} ปี)
            </AgeLabel>
          )}
          <Field
            caption="วันเกิด"
            name="birthdate"
            component={DatePicker}
            placeholder="กรุณาระบุ"
            validate="required"
            rightWidth="200"
            leftWidth="225"
            max={new Date()}
          />
        </WrapInputDate>
        <Unit />
      </AgeAndGenderLayout>
      <CustomPanelBar>
        <PanelBarItem expanded={true} title="ด้านหนี้สิน">
          <Field
            component={CurrencyInput}
            name="home"
            caption="ภาระสินเชื่อบ้านที่อยู่อาศัยคงค้าง"
            rightWidth="200"
            placeholder="กรุณาระบุ"
            min={0}
          />
          <Unit>บาท</Unit>
          <Field
            component={CurrencyInput}
            name="car"
            caption="ภาระสินเชื่อรถยนต์คงค้าง"
            rightWidth="200"
            placeholder="กรุณาระบุ"
            min={0}
          />
          <Unit>บาท</Unit>
          <Field
            component={CurrencyInput}
            name="person"
            caption="ภาระสินเชื่อส่วนบุคคลคงค้าง"
            rightWidth="200"
            placeholder="กรุณาระบุ"
            min={0}
          />
          <Unit>บาท</Unit>
          <Field
            component={CurrencyInput}
            name="business"
            caption="ภาระสินเชื่อธุรกิจคงค้าง"
            rightWidth="200"
            placeholder="กรุณาระบุ"
            min={0}
          />
          <Unit>บาท</Unit>
          <Field
            component={CurrencyInput}
            name="another"
            caption="ภาระหนี้สินอื่นๆ คงค้าง"
            rightWidth="200"
            placeholder="กรุณาระบุ"
            min={0}
          />
          <Unit>บาท</Unit>
          <Field
            component={CurrencyInput}
            name="sumTry"
            caption="รวมหนี้สิน"
            leftWidth="300"
            readOnly
            placeholder="กรุณาระบุ"
          />
          <Unit>บาท</Unit>
        </PanelBarItem>
        <PanelBarItem title={"ด้านสินทรัพย์"}>
          <Field
            component={CurrencyInput}
            name="deposit"
            caption="เงินฝากออมทรัพย์/ ฝากประจำ/ เงินฝากอื่นๆ"
            rightWidth="200"
            placeholder="กรุณาระบุ"
            min={0}
          />
          <Unit>บาท</Unit>
          <Field
            component={CurrencyInput}
            name="asset"
            caption="สินทรัพย์สภาพคล่องอื่นๆที่เตรียมไว้ชำระหนี้"
            rightWidth="200"
            placeholder="กรุณาระบุ"
            min={0}
          />
          <Unit>บาท</Unit>
          <Field
            component={CurrencyInput}
            name="capital"
            caption="ทุนประกันชีวิตที่มีอยู่แล้ว"
            rightWidth="200"
            placeholder="กรุณาระบุ"
            min={0}
          />
          <Unit>บาท</Unit>
          <Field
            component={CurrencyInput}
            name="sumNotTry"
            caption="รวมหนี้สิน"
            leftWidth="300"
            readOnly
            placeholder="กรุณาระบุ"
          />
          <Unit>บาท</Unit>
        </PanelBarItem>
      </CustomPanelBar>
    </Body>

    <Footer>
      <ResultContainer>
        <TextResult>
          {convertNaNtoZero(props.values.sumTry) - convertNaNtoZero(props.values.sumNotTry) < 0 ? (
            <>
              สินทรัพย์และทุนประกันที่คุณมีครอบคลุมภาระที่คุณต้องการคุ้มครองแล้ว
              แต่คุณสามารถค้นหาแบบประกันที่ช่วยเพิ่มเติมความคุ้มครองภาระของคุณได้อีก
            </>
          ) : convertNaNtoZero(props.values.sumTry) - convertNaNtoZero(props.values.sumNotTry) <
            100000 ? (
            <>
              ทุนประกันที่คุณต้องการน้อยกว่าขั้นต่ำของแบบประกันที่มีโดยทั่วไป
              เราจะค้นหาแบบประกันให้คุณโดยเลือกจากทุนประกันขั้นต่ำที่ 100,000 บาทแทน
            </>
          ) : (
            <>
              คุณสามารถใช้แบบประกันประเภท <BoldText>ตลอดชีพ</BoldText> หรือ{" "}
              <BoldText>ชั่วระยะเวลา</BoldText> ในการสร้างความคุ้มครองนี้โดยทุนประกันจะอยู่ในช่วง
            </>
          )}
        </TextResult>
        <ValueResult>
          {new Intl.NumberFormat().format(
            convertMinustoZero(
              convertNaNtoZero(props.values.sumTry) - convertNaNtoZero(props.values.sumNotTry)
            )
          )}{" "}
          บาท
        </ValueResult>
      </ResultContainer>

      <ConfirmButton
        icon="arrow-right"
        onClick={props.onSubmit}
        disabled={props.values.sumTry < props.values.sumNotTry}
      >
        ถัดไป
      </ConfirmButton>
    </Footer>
  </Layout>
)

const enhancer = compose(
  withRouter({
    queryTypes: {
      birthdate: "date",
    },
  }),
  withStores((stores, props) => ({
    fetchById: stores.leadStore.fetchById,
    initialValues: {
      birthdate: props.location.query.birthdate,
      gender: props.location.query.gender,
    },
  })),
  withForm({
    form: "PlanningFormFull",
    enableReinitialize: true,
    onChange: (values, _, props) => {
      const {
        home = "0",
        car = "0",
        person = "0",
        business = "0",
        another = "0",
        deposit = "0",
        asset = "0",
        capital = "0",
      } = values

      let sumTry = 0
      let sumNotTry = 0
      let homeEdit = isNaN(parseInt(home)) ? 0 : parseInt(home)
      let carEdit = isNaN(parseInt(car)) ? 0 : parseInt(car)
      let personEdit = isNaN(parseInt(person)) ? 0 : parseInt(person)
      let businessEdit = isNaN(parseInt(business)) ? 0 : parseInt(business)
      let anotherEdit = isNaN(parseInt(another)) ? 0 : parseInt(another)
      let depositEdit = isNaN(parseInt(deposit)) ? 0 : parseInt(deposit)
      let assetEdit = isNaN(parseInt(asset)) ? 0 : parseInt(asset)
      let capitalEdit = isNaN(parseInt(capital)) ? 0 : parseInt(capital)
      sumTry = homeEdit + carEdit + personEdit + businessEdit + anotherEdit
      sumNotTry = depositEdit + assetEdit + capitalEdit
      values.sumTry = sumTry
      values.sumNotTry = sumNotTry
    },
  }),
  // lifecycle({
  //   async componentDidMount() {
  //     if (!this.props.location.query.age || !this.props.location.query.gender) {
  //       let response = {
  //         customerInfo: {
  //           age: null,
  //           gender: null,
  //         },
  //       }

  //       // if (this.props.location.query.leadId) {
  //       //   response = await this.props.fetchById(this.props.location.query.leadId)
  //       // }

  //       // this.props.history.push(
  //       //   path.planningTypeFullPath(this.props.match.params.type, {
  //       //     lead_id: this.props.location.query.leadId,
  //       //     age: response.customerInfo.age,
  //       //     gender: response.customerInfo.gender,
  //       //   })
  //       // )
  //     }
  //   },
  // }),
  withHandlers({
    onSubmit: (props) =>
      props.handleSubmit(async (values) => {
        let sumTry = isNaN(parseInt(values.sumTry)) ? 0 : parseInt(values.sumTry)
        let sumNotTry = isNaN(parseInt(values.sumNotTry)) ? 0 : parseInt(values.sumNotTry)

        const { query } = props.location || {}
        const { leadId } = query || {}

        props.history.push(
          path.productsPath(leadId, {
            ...props.location.query,
            birthdate: values.birthdate,
            gender: values.gender,
            searchValue: sumTry - sumNotTry || 100000,
            type: "whole_life",
            searchBy: "sum_insured",
          })
        )
      }),
  })
)
export default enhancer(Planning)
