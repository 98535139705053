import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { Switch, Route, Redirect } from "react-router-dom"
import { compose, lifecycle } from "recompose"
import { withRouter, withStores } from "@enhancers"
import * as path from "@common/path"
import { Link, BreadCrumb } from "@components"

import InsurerInfo from "./InsurerInfo"
import DocumentInfo from "./DocumentInfo"
import InsurerNewInfo from "../new/InsurerInfo"

const Layout = styled.div``
const BreadCrumbLayout = styled.div``
const Menu = styled.div`
  display: flex;
  margin-bottom: 20px;
`
const MenuItem = styled(Link)`
  flex: 1;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #294558;
  text-decoration: none;

  :hover,
  &.active {
    background: #294558;
    color: white;
  }

  ${(props) =>
    props.disable &&
    css`
      pointer-events: none;
    `}
`
const Body = styled.div`
  display: flex;
  justify-content: center;
`

const InsurerShow = (props) => (
  <Layout>
    <BreadCrumbLayout>
      <BreadCrumb insurer={props.insurer} />
    </BreadCrumbLayout>
    <Menu>
      <MenuItem to={path.insurerInfoPath(props.insurerId)}>ข้อมูลบริษัทประกัน</MenuItem>
      <MenuItem
        to={path.insurerDocumentInfoPath(props.insurerId)}
        disable={props.insurerId === "new"}
      >
        เอกสาร
      </MenuItem>
    </Menu>
    <Body>
      <Switch>
        <Route path={path.insurerInfoNewPath()} component={InsurerNewInfo} exact />
        <Route path={path.insurerInfoPath(":insurerId")} component={InsurerInfo} />
        <Route path={path.insurerDocumentInfoPath(":insurerId")} component={DocumentInfo} />
        <Redirect
          from={path.insurerPath(":insurerId")}
          to={path.insurerInfoPath(":insurerId")}
          exact
        />
      </Switch>
    </Body>
  </Layout>
)

const enhancer = compose(
  withRouter(),
  withStores((stores, props) => ({
    fetchInsurer: stores.insurerStore.fetchById,
    fetchDocuments: stores.insurerStore.fetchDocuments,
    clearInsurer: stores.insurerStore.clearInsurer,
    insurer: stores.insurerStore.current,
    insurerId: props.match.params.insurerId,
    fetchConfigs: stores.insurerStore.fetchConfigs,
  })),
  lifecycle({
    async componentDidMount() {
      await this.props.fetchConfigs()
      if (this.props.insurerId === "new") {
        await this.props.clearInsurer()
      } else {
        await Promise.all([
          this.props.fetchInsurer(this.props.insurerId),
          this.props.fetchDocuments(this.props.insurerId),
        ])
      }
    },
  })
)

export default enhancer(InsurerShow)
