import React from "react"
import ReactDOM from "react-dom"

import * as serviceWorker from "./serviceWorker"
import "@progress/kendo-theme-bootstrap/dist/all.css"
import "@progress/kendo-react-animation"
import "@assets/css/index.css"

import { env } from "@env"
import loadExtension from "./extension"
import { isClient } from "@common/helper"
import slack from "@common/slack"

import App from "./pages/index/index"

// import LogRocket from "logrocket"
// LogRocket.init("embsjd/tqm-life-backend-react")

loadExtension()

if (isClient) {
  window.onerror = function (msg, url, lineNo, columnNo, error) {
    const currentUrl = window.location.href
    slack.bug(`
      ${env.name}
      URL: ${currentUrl}
      Error: ${msg}
    `)
  }
}

// if (env === "local") {
//   let whyDidYouRender = require("@welldone-software/why-did-you-render")
//   // let ReactRedux = require("react-redux")
//   whyDidYouRender(React, {
//     trackAllPureComponents: true,
//     trackExtraHooks: [[require("react-redux/lib"), "useSelector"]],
//   })
// }

ReactDOM.render(<App />, document.getElementById("root"))
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
