import React from "react"
import styled from "@emotion/styled"
import { compose, withHandlers, withProps } from "recompose"
import { Field, Button, DatePicker, CurrencyInput, Dropdown } from "@components/index"
import moment from "moment"
import CommissionStructureTable from "./CommissionStructureTable"

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  margin-top: 21px;
  padding: 16px 24px 24px 24px;
  background-color: #fff;
`
const Row = styled.div`
  display: flex;
  justify-content: space-between;
`
const CustomField = styled(Field)`
  [class*="LeftPanel"] > div {
    font-weight: 400;
  }
`
const CustomDatePicker = styled(DatePicker)`
  [class*="RightPanel"] > div > span {
    width: 160px;
  }
`
const TableContainer = styled.div``
const TableHeaderTable = styled.div`
  margin-bottom: ${(props) => (props.firstArray ? "8px" : "16px")};
`
const TableSubHeader = styled.div`
  font-size: 12px;
  font-family: Sarabun, sans-serif;
  line-height: 16px;
  margin-bottom: 16px;
`
const TrashButton = styled(Button)`
  width: 160px;
  align-self: center;
  margin-top: 24px;
`
const SalesCommissionStuctureCard = (props) => {
  return (
    <Layout>
      <Row>
        <CustomField
          component={CustomDatePicker}
          name={`${props.stucture}.beginDate`}
          caption="วันที่มีผลบังคับ"
          leftWidth="110"
          rightWidth="160"
          min={props.nextDay}
          readOnly={props.readOnly}
          placeholder="กรุณาเลือก"
          style={{ marginBottom: "16px" }}
        />
        <CustomField
          component={Dropdown}
          name={`${props.stucture}.affiliateTeamLevel`}
          caption="ระดับทีมผู้แนะนำ"
          options={props.affiliateTeamLevelOptions}
          leftWidth="130"
          rightWidth="160"
          readOnly={props.readOnly}
          placeholder="กรุณาเลือก"
          style={{ marginBottom: "16px" }}
        />
      </Row>
      <Row>
        <CustomField
          component={CurrencyInput}
          name={`${props.stucture}.suggestedPercent`}
          caption="ค่าแนะนำ (%)"
          leftWidth="110"
          rightWidth="160"
          readOnly={props.readOnly}
          placeholder="กรุณาระบุ"
          style={{ marginBottom: "16px" }}
          min={0}
          max={100}
        />
        <CustomField
          component={Dropdown}
          name={`${props.stucture}.suggestedLevel`}
          caption="คำนวณจากระดับ"
          options={props.suggestedLevelOptions}
          leftWidth="130"
          rightWidth="160"
          readOnly={props.readOnly}
          placeholder="กรุณาเลือก"
          style={{ marginBottom: "16px" }}
        />
      </Row>
      <TableContainer>
        <TableHeaderTable>คอมมิชชั่นจ่าย</TableHeaderTable>

        {props.index === 0 ? (
          <TableSubHeader>ระดับต่ำสุด คือ ระดับ 1 พนักงานขาย หรือ ทีมผู้แนะนำ</TableSubHeader>
        ) : (
          <TableSubHeader>
            ระดับที่ตัวเลขน้อยกว่าจะอยู่ใกล้กับจุดที่เกิดการขายมากกว่า เช่น ระดับ 1 พนักงานขาย,
            ระดับ 2 หัวหน้าทีม
          </TableSubHeader>
        )}
        <CommissionStructureTable
          name={props.stucture}
          caption="คอมมิชชั่นจ่าย"
          readOnly={props.readOnly}
          fields={props.fields}
          index={props.index}
          commissionStructure={props.commissionStructure}
          initialValues={props.initialValues}
          from={props.from}
          reserveForLevelOptions={props.reserveForLevelOptions}
        />
      </TableContainer>

      <TrashButton
        icon="trash"
        onClick={() => props.onDeleteStucture(props.index)}
        styletype="delete"
        hidden={props.readOnly || !props.moreThanOneBeneficiary}
      >
        ลบโครงสร้าง
      </TrashButton>
    </Layout>
  )
}

const enhance = compose(
  withProps((props) => {
    const moreThanOneBeneficiary = props.fields.length > 1
    const initialValues = (props.fields.get(props.index) || {}).mkCommissionRates || []
    const canEdit = (props.fields.get(props.index) || {}).canEdit
    const nextDay = moment().add(1, "days")._d
    const readOnly = canEdit !== undefined ? props.readOnly || !canEdit : props.readOnly
    const affiliateTeamLevelOptions = props.commissionStructure.map((d) => ({
      label: `${d.positionLevel}`,
      value: d.positionLevel,
    }))
    affiliateTeamLevelOptions.unshift({ label: "ไม่มี", value: "" })

    const suggestedLevelOptions = (props.commissionStructure || []).map((item) => ({
      label: item.positionLevel,
      value: item.positionLevel,
    }))

    return {
      moreThanOneBeneficiary,
      initialValues,
      nextDay,
      readOnly,
      affiliateTeamLevelOptions,
      suggestedLevelOptions
    }
  }),
  withHandlers({
    onDeleteStucture: (props) => (index) => {
      props.onDeleteStucture(index)
    },
  })
)
export default enhance(SalesCommissionStuctureCard)
