import React, { useEffect, useMemo, useState } from "react"
import styled from "@styled"
import { compose, lifecycle } from "recompose"
import { withHooks, withRouter, withStores } from "@enhancers/index"
import { LoadingScope } from "@components/index"
import { Icon, Authorize } from "@components"
import FilterDate from "./FilterDate"
import InsuranceCompanyCard from "./InfoCard/InsuranceCompanyCard"
import InsurancePolicyCard from "./InfoCard/InsurancePolicyCard"
import TeamCard from "./InfoCard/TeamCard"
import SalesmanCard from "./InfoCard/SalesmanCard"
import PaymentCard from "./InfoCard/PaymentPeriodCard"
import CommissionInsurerCard from "./InfoCard/CommissionInsurerCard"
import CommissionOderCard from "./InfoCard/CommissionOrderCard"
import isEmpty from "lodash/isEmpty"

const Layout = styled.div`
  margin-top: 84px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const CardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const SalesCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 212.66px;
  height: 131px;
  background: ${(props) => props.color};
  border-radius: 5px;
  padding: 16px;
`
const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #ffffff;
`
const TitleIcon = styled(Icon)`
  font-size: 20px;
`
const TitleText = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #ffffff;
`
const TotalValue = styled.div`
  display: flex;
  justify-content: flex-end;
  font-weight: bold;
  font-size: 32px;
  line-height: 37px;
  color: #ffffff;
  margin-top: 6px;
`
const CurrentMonthContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 14px;
`
const CurrentMonthText = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
`
const CurrentMonthValue = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
`

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0 0;
`

const FilterHeader = styled.h2`
  margin: 0;
  font-size: 30px;
  font-family: "ThaiSans Neue";
`
const FilterDateContainer = styled.div`
  display: flex;
`
const PeriodTypeText = styled.h2`
  font-size: 16px;
  font-weight: normal;
  align-self: center;
  margin-right: 16px;
`

const Dashboard = (props) => {
  return (
    <>
      {props.isLoading ? (
        <LoadingScope />
      ) : (
        <Layout>
          {props.isGraphLoading && <LoadingScope />}

          <CardContainer>
            {props.summary.map((value, index) => (
              <SalesCard key={index} color={value.color}>
                <TitleContainer>
                  <TitleIcon awesomeIcon={value.awesomeIcon} icon={value.icon} />
                  <TitleText>{value.titleText}</TitleText>
                </TitleContainer>

                <TotalValue>{value.totalValue}</TotalValue>

                <CurrentMonthContainer>
                  <CurrentMonthText>{value.currentMonthText}</CurrentMonthText>
                  <CurrentMonthValue>{value.currentMonthValue}</CurrentMonthValue>
                </CurrentMonthContainer>
              </SalesCard>
            ))}
          </CardContainer>
          <FilterContainer>
            <FilterHeader>
              {props.dashboardType === "affiliate" ? "สรุปยอดขายจากผู้แนะนำ" : "สรุปยอดขาย"}
            </FilterHeader>
            <FilterDateContainer>
              <PeriodTypeText>{props.periodType}</PeriodTypeText>
              <FilterDate onSubmit={props.onFilterSubmit} />
            </FilterDateContainer>
          </FilterContainer>

          <Authorize
            roles={[
              "member.biz_dev",
              "member.admin",
              "member.brokerage_admin",
              "member.team_leader",
              "member.salesman",
            ]}
          >
            <InsuranceCompanyCard
              data={props.insuranceCompany}
              graph={props.insuranceCompanyGraph}
              dashboardType={props.dashboardType}
            />
          </Authorize>

          <Authorize
            roles={[
              "member.biz_dev",
              "member.admin",
              "member.brokerage_admin",
              "member.team_leader",
              "member.salesman",
            ]}
          >
            <InsurancePolicyCard
              data={props.insurancePolicy}
              graph={props.insuranceCompanyGraph}
              dashboardType={props.dashboardType}
            />
          </Authorize>

          <Authorize roles={["member.biz_dev", "member.admin", "member.brokerage_admin"]}>
            <TeamCard
              data={props.team}
              graph={props.insuranceCompanyGraph}
              dashboardType={props.dashboardType}
            />
          </Authorize>

          <Authorize
            roles={[
              "member.biz_dev",
              "member.admin",
              "member.brokerage_admin",
              "member.team_leader",
            ]}
          >
            <SalesmanCard data={props.salesman} dashboardType={props.dashboardType} />
          </Authorize>

          <Authorize
            roles={[
              "member.biz_dev",
              "member.admin",
              "member.brokerage_admin",
              "member.team_leader",
              "member.salesman",
            ]}
          >
            <PaymentCard data={props.paymentPeriod} dashboardType={props.dashboardType} />
          </Authorize>

          <Authorize roles={["member.salesman", "member.team_leader"]}>
            <CommissionInsurerCard data={props.insurerCommission} graph={props.commissionGraph} />
          </Authorize>
          <Authorize roles={["member.salesman", "member.team_leader"]}>
            <CommissionOderCard data={props.ordersCommission} graph={props.commissionGraph} />
          </Authorize>
        </Layout>
      )}
    </>
  )
}

const PERIOD_OPTIONS = [
  { label: "รายวัน", value: "daily" },
  { label: "รายเดือน", value: "monthly" },
  { label: "รายไตรมาส", value: "quarterly" },
  { label: "รายปี", value: "yearly" },
]

const enhancer = compose(
  withRouter(),
  withStores((stores) => ({
    currentRole: stores.appStore.currentRole,

    fetchOrdersSummary: stores.dashboardStore.fetchOrdersSummary,
    fetchInsuranceCompanyGraph: stores.dashboardStore.fetchInsuranceCompanyGraph,
    fetchInsuranceCompany: stores.dashboardStore.fetchInsuranceCompany,
    fetchConfig: stores.dashboardStore.fetchConfig,
    fetchInsurancePolicy: stores.dashboardStore.fetchInsurancePolicy,
    fetchDashboardTeam: stores.dashboardStore.fetchDashboardTeam,
    fetchDashboardSalesmen: stores.dashboardStore.fetchDashboardSalesmen,
    fetchDashboardPaymentPeriod: stores.dashboardStore.fetchDashboardPaymentPeriod,
    fetchDashboardCommissionGraph: stores.dashboardStore.fetchDashboardCommissionGraph,
    fetchDashboardInsurerCommission: stores.dashboardStore.fetchDashboardInsurerCommission,
    fetchDashboardOrdersCommission: stores.dashboardStore.fetchDashboardOrdersCommission,

    ordersSummary: stores.dashboardStore.ordersSummary,
    insuranceCompanyGraph: stores.dashboardStore.insuranceCompanyGraph,
    insuranceCompany: stores.dashboardStore.insuranceCompany,
    insurancePolicy: stores.dashboardStore.insurancePolicy,
    team: stores.dashboardStore.team,
    salesman: stores.dashboardStore.salesman,
    paymentPeriod: stores.dashboardStore.paymentPeriod,
    commissionGraph: stores.dashboardStore.commissionGraph,
    insurerCommission: stores.dashboardStore.insurerCommission,
    ordersCommission: stores.dashboardStore.ordersCommission,

    memberStatusOptions: stores.dashboardStore.$("configs.attributes.memberStatus.options", []),
  })),

  withHooks((props) => {
    const [summary, setSummary] = useState([])
    const [insuranceCompanyGraph, setInsuranceCompanyGraph] = useState({})
    const [commissionGraph, setCommissionGraph] = useState({ xAxisCatagory: [], commission: [] })
    const [isLoading, setIsLoading] = useState(false)
    const [isGraphLoading, setIsGraphLoading] = useState(false)
    const [periodType, setPeriodType] = useState("รายปี")
    const [dashboardType, setDashboardType] = useState(null)

    useEffect(() => {
      const xAxisCatagory = []

      const premiumRejected = []
      const premiumSalable = []
      const premiumTotal = []

      const policyRejected = []
      const policySalable = []
      const policyTotal = []

      if (!isEmpty(props.insuranceCompanyGraph.graph)) {
        props.insuranceCompanyGraph.graph.forEach((xAxis) => {
          xAxisCatagory.push(xAxis.xAxisName)

          premiumRejected.push(xAxis.premium.rejected)
          premiumSalable.push(xAxis.premium.salable)
          premiumTotal.push(xAxis.premium.total)

          policyRejected.push(xAxis.policy.rejected)
          policySalable.push(xAxis.policy.salable)
          policyTotal.push(xAxis.policy.total)
        })

        setInsuranceCompanyGraph({
          premiumGraph: {
            xAxisCatagory,
            rejected: premiumRejected,
            salable: premiumSalable,
            total: premiumTotal,
          },
          policyGraph: {
            xAxisCatagory,
            rejected: policyRejected,
            salable: policySalable,
            total: policyTotal,
          },
        })
      }
    }, [props.insuranceCompanyGraph.graph])

    useEffect(() => {
      const xAxisCatagory = []
      const commission = []

      if (!isEmpty(props.commissionGraph.graph)) {
        props.commissionGraph.graph.forEach((xAxis) => {
          xAxisCatagory.push(xAxis.xAxisName)
          commission.push(xAxis.commission.amount)
        })
      }
      setCommissionGraph({ xAxisCatagory, commission })
    }, [props.commissionGraph.graph])

    useEffect(() => {
      const summary = []

      summary.push(
        {
          awesomeIcon: ["far", "folder-open"],
          titleText: "กรมธรรม์ทั้งหมด",
          totalValue: props.ordersSummary.salablePolicy.total,
          currentMonthText: "กรมธรรม์เดือนนี",
          currentMonthValue: props.ordersSummary.salablePolicy.currentMonth,
          color: "#0275D8",
        },
        {
          icon: "cart",
          titleText: "ยอดเบี้ยทั้งหมด",
          totalValue: props.ordersSummary.salablePremium.total,
          currentMonthText: "ยอดเบี้ยเดือนนี้",
          currentMonthValue: props.ordersSummary.salablePremium.currentMonth,
          color: "#5CB85C",
        },
        {
          awesomeIcon: ["far", "frown"],
          titleText: "ปิดการขายไม่สำเร็จ",
          totalValue: props.ordersSummary.rejectedPolicy.total,
          currentMonthText: "เดือนนี้",
          currentMonthValue: props.ordersSummary.rejectedPolicy.currentMonth,
          color: "#D9534F",
        }
      )

      setSummary(summary)
    }, [props.ordersSummary])

    useEffect(() => {
      setDashboardType(props.history.location.pathname.includes("affiliate") ? "affiliate" : null)
    }, [props.history.location.pathname])

    const insurancePolicy = useMemo(() => {
      const policy = props.insurancePolicy.map((policy) => {
        let policyState = {}
        const state = policy.policyState.charAt(0).toUpperCase() + policy.policyState.slice(1)
        switch (policy.policyState) {
          case "online":
            policyState = {
              state,
              color: "#5CB85C",
            }
            break
          case "offline":
            policyState = {
              state,
              color: "#D9534F",
            }
            break
          default:
            policyState = {
              state,
            }
            break
        }
        return {
          ...policy,
          policyState: policyState,
        }
      })

      return policy
    }, [props.insurancePolicy])

    const salesman = useMemo(() => {
      const salesman = props.salesman.map((member) => {
        let memberStatus = {}

        const status = props.memberStatusOptions.find(
          (option) => option.value === member.memberStatus
        )

        if (!isEmpty(status)) {
          switch (status.value) {
            case "normal":
              memberStatus = {
                status: status.label,
                color: "#5CB85C",
              }
              break
            case "resigned":
              memberStatus = {
                status: status.label,
                color: "#D9534F",
              }
              break
            default:
              memberStatus = {
                status: status.label,
              }
              break
          }
          return {
            ...member,
            memberStatus: memberStatus,
          }
        }
      })

      return salesman
    }, [props.salesman, props.memberStatusOptions])

    const onFilterSubmit = async (values) => {
      setIsGraphLoading(true)

      const periodType = PERIOD_OPTIONS.find((option) => option.value === values.periodType)
      setPeriodType(periodType.label)
      const filterParams = {
        ...values,
        page: 1,
        perPage: 5,
        dashboardType: dashboardType,
      }
      const filterGraphParams = {
        ...values,
        dashboardType: dashboardType,
      }

      if (
        ["member.biz_dev", "member.admin", "member.brokerage_admin"].includes(props.currentRole)
      ) {
        await Promise.all([
          props.fetchInsuranceCompanyGraph(filterGraphParams),
          props.fetchInsuranceCompany(filterParams),
          props.fetchInsurancePolicy(filterParams),
          props.fetchDashboardTeam(filterParams),
          props.fetchDashboardSalesmen(filterParams),
          props.fetchDashboardPaymentPeriod(filterParams),
        ])
      } else if (props.currentRole === "member.team_leader") {
        await Promise.all([
          props.fetchInsuranceCompanyGraph(filterGraphParams),
          props.fetchInsuranceCompany(filterParams),
          props.fetchInsurancePolicy(filterParams),
          props.fetchDashboardSalesmen(filterParams),
          props.fetchDashboardPaymentPeriod(filterParams),
          props.fetchDashboardCommissionGraph(filterGraphParams),
          props.fetchDashboardInsurerCommission(filterParams),
          props.fetchDashboardOrdersCommission(filterParams),
        ])
      } else if (props.currentRole === "member.salesman") {
        await Promise.all([
          props.fetchInsuranceCompanyGraph(filterGraphParams),
          props.fetchInsuranceCompany(filterParams),
          props.fetchInsurancePolicy(filterParams),
          props.fetchDashboardPaymentPeriod(filterParams),
          props.fetchDashboardCommissionGraph(filterGraphParams),
          props.fetchDashboardInsurerCommission(filterParams),
          props.fetchDashboardOrdersCommission(filterParams),
        ])
      }
      setIsGraphLoading(false)
    }

    return {
      summary,
      insuranceCompanyGraph,
      commissionGraph,
      isLoading,
      insurancePolicy,
      salesman,
      isGraphLoading,
      periodType,
      setIsLoading,
      onFilterSubmit,
      setPeriodType,
      dashboardType,
    }
  }),
  lifecycle({
    async componentDidMount() {
      this.props.setIsLoading(true)
      const dashboardType = this.props.history.location.pathname.includes("affiliate")
        ? "affiliate"
        : null
      const defaultFilter = {
        dashboardType: dashboardType,
        periodType: "yearly",
        submittedAt: {
          min: new Date(new Date().getFullYear(), 0, 1),
          max: new Date(new Date().getFullYear(), 11, 31),
        },
        page: 1,
        perPage: 5,
      }
      if (
        ["member.biz_dev", "member.admin", "member.brokerage_admin"].includes(
          this.props.currentRole
        )
      ) {
        await Promise.all([
          this.props.fetchConfig(),
          this.props.fetchOrdersSummary({ dashboardType: dashboardType }),
          this.props.fetchInsuranceCompanyGraph(defaultFilter),
          this.props.fetchInsuranceCompany(defaultFilter),
          this.props.fetchInsurancePolicy(defaultFilter),
          this.props.fetchDashboardTeam(defaultFilter),
          this.props.fetchDashboardSalesmen(defaultFilter),
          this.props.fetchDashboardPaymentPeriod(defaultFilter),
        ])
      } else if (this.props.currentRole === "member.team_leader") {
        await Promise.all([
          this.props.fetchConfig(),
          this.props.fetchOrdersSummary({ dashboardType: dashboardType }),
          this.props.fetchInsuranceCompanyGraph(defaultFilter),
          this.props.fetchInsuranceCompany(defaultFilter),
          this.props.fetchInsurancePolicy(defaultFilter),
          this.props.fetchDashboardSalesmen(defaultFilter),
          this.props.fetchDashboardPaymentPeriod(defaultFilter),
          this.props.fetchDashboardCommissionGraph(defaultFilter),
          this.props.fetchDashboardInsurerCommission(defaultFilter),
          this.props.fetchDashboardOrdersCommission(defaultFilter),
        ])
      } else if (this.props.currentRole === "member.salesman") {
        await Promise.all([
          this.props.fetchConfig(),
          this.props.fetchOrdersSummary({ dashboardType: dashboardType }),
          this.props.fetchInsuranceCompanyGraph(defaultFilter),
          this.props.fetchInsuranceCompany(defaultFilter),
          this.props.fetchInsurancePolicy(defaultFilter),
          this.props.fetchDashboardPaymentPeriod(defaultFilter),
          this.props.fetchDashboardCommissionGraph(defaultFilter),
          this.props.fetchDashboardInsurerCommission(defaultFilter),
          this.props.fetchDashboardOrdersCommission(defaultFilter),
        ])
      }
      this.props.setIsLoading(false)
    },
  })
)

export default enhancer(Dashboard)
