import { compose, withState, withHandlers, lifecycle, withProps } from "recompose"
import { withForm, withStores, withRouter, withHooks } from "@enhancers"
import * as path from "@common/path"
import { isEmpty } from "lodash"
import { PackageNewPage as PackageShowPage } from "../new/index"
import { useEffect } from "react"
import { toCurrency } from "@common/helper"
import { validate } from "../new/index"

const enhancer = compose(
  withRouter(),
  withState("isEdit", "setIsEdit", false),
  withState("showDeleteModal", "setShowDeleteModal", false),
  withStores((stores, props) => ({
    updatePackage: stores.packageStore.updatePackage,
    deletePackage: stores.packageStore.deletePackage,
    fetchProductCurrent: stores.packageStore.fetchCurrent,
    package: stores.packageStore.current,
    fetchConfig: stores.packageStore.fetchConfig,
    originalPolicyOptions: stores.packageStore.$("configs.attributes.lifeId.options", []),
    originalRiderOptions: stores.packageStore.$("configs.attributes.riderId.options", []),
    originalCommissionOptions: stores.packageStore.$("configs.attributes.commission.options", []),
    insurerOptions: stores.packageStore.$("configs.attributes.insuranceCompany.options", []),
    stateOptions: stores.packageStore.$("configs.attributes.state.options", []),
  })),
  withState("policyOptions", "setPolicyOptions", []),
  withState("riderOptions", "setRiderOptions", []),
  withState("commissionOptions", "setCommissionOptions", []),

  withProps((props) => {
    if (!isEmpty(props.package)) {
      const initialValues = {
        ...props.package,
        policyId: props.package.policy.id,
        insurer: (
          props.originalPolicyOptions.find((policy) => policy.value === props.package.policy.id) ||
          {}
        ).insuranceCompanyId,
        policySumInsured: props.package.lifeSumInsured,
        policyCommission: props.package.lifeCommissionId,
        riders:
          props.package.packagesRiderInsurancePolicies.length !== 0
            ? props.package.packagesRiderInsurancePolicies.map((rider) => {
                return {
                  riderPolicyId: rider.insurancePolicyId,
                  riderSumInsured: rider.sumInsured,
                  riderCommission: rider.commissionInsurancePolicyId,
                  lockable: rider.lockable,
                }
              })
            : [{}],
        state: props.package.state,
        isF2fChannel: props.package.isF2fChannel,
        isOnlineChannel: props.package.isOnlineChannel,
        onlineAt: props.package.onlineAt,
        comment: props.package.comment,
      }
      return {
        initialValues,
      }
    }
  }),
  withProps((props) => {
    return {
      breadCrumbPackage: [
        { label: "แพ็กเก็จ", path: path.packagesPath() },
        {
          label: props.package && `${props.package.name}`,
          path: path.packagePath(props.match.params.packageId),
        },
      ],
    }
  }),
  withForm({
    form: "EditPackageForm",
    validate,
  }),

  withHandlers({
    edit: (props) => () => {
      props.setIsEdit(!props.isEdit)
    },
    cancel: (props) => () => {
      props.reset(props.form)
      props.setIsEdit(!props.isEdit)
    },
    save: (props) =>
      props.handleSubmit(async (values) => {
        let riders = values.riders.filter(
          (rider) => !isEmpty(rider) && rider.riderPolicyId !== null
        )
        const params = {
          packageId: props.match.params.packageId,
          name: values.name,
          referenceCode: values.referenceCode,
          policyId: values.policyId,
          policySumInsured: values.policySumInsured,
          isF2fChannel: values.isF2fChannel,
          isOnlineChannel: values.isOnlineChannel,
          onlineAt: values.onlineAt,
          comment: values.comment,
          riders: riders.map((rider) => {
            return {
              riderPolicyId: rider.riderPolicyId,
              riderSumInsured: rider.riderSumInsured,
              lockable: rider.lockable || false,
            }
          }),
          policyCommissions: [
            { policyId: values.policyId, commissionId: values.policyCommission },
            ...riders.map((rider) => {
              return {
                policyId: rider.riderPolicyId,
                commissionId: rider.riderCommission,
              }
            }),
          ],
          state: values.state,
        }
        try {
          await props.updatePackage(params)
          props.setIsEdit(false)
        } catch (error) {
          const errMsg = error.errors?.base || "ไม่สามารถบันทึกแพ็กเกจได้"
          window.alert(errMsg)
          if (error.name === "SubmissionError") {
            throw error
          }
        }
      }),
    openDeleteModal: (props) => () => {
      props.setShowDeleteModal(!props.showDeleteModal)
    },
    delete: (props) => async () => {
      try {
        await props.deletePackage(props.match.params.packageId)
        props.history.push(path.packagesPath())
      } catch (error) {
        const errMsg = error.errors?.base || "ไม่สามารถลบแพ็กเกจได้"
        window.alert(errMsg)
      }
    },
  }),
  lifecycle({
    async componentDidMount() {
      await Promise.all[
        (this.props.fetchProductCurrent(this.props.match.params.packageId),
        this.props.fetchConfig())
      ]
    },
  }),
  withState("wordingPolicySumInsure", "setWordingPolicySumInsure", ""),
  withHooks((props) => {
    useEffect(() => {
      const policyOptions = props.originalPolicyOptions.filter(
        (policy) => policy.insuranceCompanyId === props.values.insurer
      )
      props.setPolicyOptions(policyOptions)
      const policyInsurerId = (
        props.policyOptions.find((policy) => policy.value === props.values.policyId) || {}
      ).insuranceCompanyId
      const riderOptions = props.originalRiderOptions.filter(
        (rider) => rider.insuranceCompanyId === policyInsurerId
      )
      const commissionOptions = props.originalCommissionOptions.filter(
        (commission) => commission.insuranceCompanyId === policyInsurerId
      )

      props.setRiderOptions(riderOptions)
      props.setCommissionOptions(commissionOptions)
    }, [props.values.policyId, props.values.insurer, props.riderOptions, props.policyOptions])

    useEffect(() => {
      let wording = ""
      const policy =
        props.policyOptions.find((policy) => policy.value === props.values.policyId) || {}
      if (policy.minSumInsured && policy.maxSumInsured) {
        wording = `(${toCurrency(policy.minSumInsured)}-${toCurrency(policy.maxSumInsured)} บาท)`
      } else if (policy.minSumInsured) {
        wording = `(${toCurrency(policy.minSumInsured)} บาทขึ้นไป)`
      } else if (policy.maxSumInsured) {
        wording = `(ไม่เกิน ${toCurrency(policy.maxSumInsured)} บาท)`
      }
      props.change("policyCommission", policy.commissionId)

      props.setWordingPolicySumInsure(wording)
    }, [props.values.policyId])

    return
  })
)

export default enhancer(PackageShowPage)
