import React from "react"
import styled from "@emotion/styled"
import { compose, withState, withHandlers, withProps } from "recompose"
import { withForm, withStores, withRouter } from "@enhancers"
import {
  Button,
  Input,
  DatePicker,
  Form,
  Field,
  InputName,
  Dropdown,
  Header as TextHeader,
} from "@components"
import RejectModal from "./RejectModal"
import * as path from "@common/path"

export { default as DeleteModal } from "./DeleteModal"

export const Layout = styled(Form)`
  width: 100%;
  justify-content: center;
`
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  line-height: normal;
`
export const LeftPanel = styled(TextHeader)`
  display: flex;
  align-items: center;
  color: #666666;
  font-weight: bold;
`
export const RightPanel = styled.div``
export const ButtonGroup = styled.div`
  > * {
    margin-left: 10px;
  }
`
export const ActionButton = styled(Button)``
const RejectButton = styled(Button)`
  width: 143px;
`
export const Body = styled.div`
  border-radius: 4px;
  padding: 21px 15px 32px 15px;
  background: #ffffff;
  border: 1px solid #eeeef2;
`
const CustomInputName = styled(InputName)`
  > div:last-of-type {
    > div {
      width: 100%;
      > span {
        width: 160px;
      }
      > input {
        width: 160px;
      }
    }
  }
`

export const LeadInfo = (props) => (
  <Layout>
    <Header>
      <LeftPanel>ข้อมูล Lead</LeftPanel>
      <RightPanel>
        {props.isEdit ? (
          <ButtonGroup>
            <ActionButton icon="cancel" onClick={props.onCancel} children="ยกเลิก" />
            <ActionButton
              icon="floppy"
              onClick={props.onSave}
              disabled={!props.valid}
              children="บันทึก"
            />
          </ButtonGroup>
        ) : (
          <ButtonGroup>
            <RejectButton
              icon="cancel"
              onClick={props.onReject}
              children="ยกเลิก Lead"
              hidden={!props.canRejectLead}
              styletype="secondary"
            />
            <ActionButton
              icon="edit"
              onClick={props.onEdit}
              children="แก้ไข"
              authorize={{
                roles: ["member.salesman", "member.team_leader"],
              }}
            />
          </ButtonGroup>
        )}
      </RightPanel>
    </Header>
    <Body>
      <Field
        name="state"
        caption="สถานะ Lead"
        component={Dropdown}
        options={props.stateOptions}
        readOnly={!props.isEdit}
      />
      <Field name="batchCode" caption="Batch" component={Input} readOnly={!props.isEdit} />
      <Field
        name="assignedNo"
        caption="ครั้งที่นำเข้า"
        component={Input}
        readOnly={!props.isEdit}
      />
      <Field
        name="leadSource"
        caption="แหล่งที่มาของ Lead"
        component={Input}
        readOnly={!props.isEdit}
      />
      <Field
        name="assignerNameInfo"
        caption="ผู้มอบหมาย"
        component={props.type === "new" ? Dropdown : CustomInputName}
        readOnly={!props.isEdit}
      />
      <Field
        name="assigneeNameInfo"
        caption="ผู้ได้รับมอบหมาย"
        component={props.type === "new" ? Dropdown : CustomInputName}
        readOnly={!props.isEdit}
      />
      <Field
        name="startDate"
        caption="วันที่เริ่มขาย"
        component={DatePicker}
        readOnly={!props.isEdit}
      />
      <Field
        name="expiredDate"
        caption="วันหมดอายุ"
        component={DatePicker}
        readOnly={!props.isEdit}
      />
    </Body>
    <RejectModal
      open={props.isShowRejectModal}
      onClose={props.onCloseRejectModal}
      onSubmit={props.onConfirmReject}
    />
  </Layout>
)

const enhancer = compose(
  withRouter(),
  withStores((stores, props) => ({
    leadId: props.match.params.leadId,
    updateLeadInfo: stores.leadStore.update,
    rejectLead: stores.leadStore.reject,
    lead: stores.leadStore.current || {},
    batchId: stores.leadStore.$("current.batchCode"),
    stateOptions: stores.leadStore.$("configs.attributes.state.options"),
    currentRole: stores.appStore.currentRole,
    currentSaleId: stores.appStore.$("currentSale.id"),
    isEdit: stores.leadStore.isEdit,
    changeEditState: stores.leadStore.changeEditState,
  })),
  withState("isShowRejectModal", "setIsShowRejectModal", false),
  withForm({
    form: "editLeadInfo",
    destroyOnUnmount: false,
  }),
  withHandlers({
    onReject: (props) => async () => {
      props.setIsShowRejectModal(true)
    },
    onCloseRejectModal: (props) => () => {
      props.setIsShowRejectModal(false)
    },
    onConfirmReject: (props) => async (values) => {
      await props.rejectLead(props.leadId, values)
      props.history.push(path.leadsPath(props.batchId))
    },
    onEdit: (props) => () => {
      props.changeEditState()
    },
    onCancel: (props) => () => {
      props.reset("editLeadInfo")
      props.changeEditState()
    },
    onSave: (props) =>
      props.handleSubmit(async (values) => {
        values = {
          ...props.values.customerInfo,
          ...props.values,
        }
        await props.updateLeadInfo(props.leadId, values)
        props.changeEditState()
      }),
  }),
  withProps((props) => ({
    isEdit: props.isEdit,
    leadNoRequire: "required",
  })),
  withProps((props) => {
    let canRejectLead = null

    switch (props.currentRole) {
      case "member.lead_admin":
        canRejectLead = ["new", "assigned"].includes(props.lead.state)
        break
      case "member.salesman":
      case "member.team_leader":
        canRejectLead = ["new", "assigned"].includes(props.lead.state)
        canRejectLead = canRejectLead && `${props.currentSaleId}` === `${props.lead.assigneeId}`
        break
      default:
        canRejectLead = false
    }

    return { canRejectLead }
  })
)

export default enhancer(LeadInfo)
