import React from "react"
import styled from "@emotion/styled"
import { compose, withHandlers, lifecycle, withState, withProps } from "recompose"
import { withStores, withForm, withRouter } from "@enhancers"
import {
  Grid,
  GridColumn,
  makeCell,
  Input,
  Field,
  DatePicker,
  Dropdown,
  FilterButton,
} from "@components"
import * as path from "@common/path"
import { exceptNullKeys, toCurrency } from "@common/helper"
import FilterPanel from "./FilterPanel"
import { cloneDeep, get, isEmpty, isEqual } from "lodash"
import deepMerge from "deepmerge"
import moment from "moment"

const Body = styled.div`
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #eeeef2;
`
const TextTopic = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  font-size: 16px;
  color: #ffffff;
  font-family: Sarabun, sans-serif;
  font-weight: bold;
`
const TopicContainer = styled.div`
  width: 100%;
  height: 40px;
  background: #294558;
  opacity: 1;
  display: flex;
  justify-content: space-between;

  > div:first-of-type {
    padding-left: 16px;
  }

  > div:last-of-type {
    padding-right: 16px;
  }
`
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 50px;
  padding: 30px 40px;

  > div {
    > div {
      min-width: 314px;
      > div:last-of-type {
        > div {
          padding-top: 0 !important;
        }
      }
    }
  }
`
const FirstRowContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`
const LastRowContentContainer = styled(FirstRowContentContainer)`
  > div:last-of-type > div:last-of-type > div:last-of-type {
    color: ${(props) => props.children[1].props.isactive === true && "red"};
  }
`
const Table = styled(Grid)`
  .k-grid-header .k-header {
    font-weight: bold;
  }
`
const CheckboxTd = styled.td`
  position: sticky;
  left: 0;
  border-right-width: 1px;
  background-color: #ffffff;
`
const StatusTd = styled(CheckboxTd)`
  right: 0;
`
const CustomFilterButton = styled(FilterButton)`
  > button {
    width: 143px;

    > .k-icon {
      margin: unset;
      margin-right: 12px;
      font-size: 19px;
    }
  }
`

const SaleCommissions = (props) => (
  <Body>
    <TopicContainer>
      <TextTopic>ข้อมูลพนักงาน</TextTopic>
      <TextTopic>ตำแหน่ง: {props.data.salesmanPosition}</TextTopic>
    </TopicContainer>

    <ContentContainer>
      <FirstRowContentContainer>
        <Field name="staffCode" component={Input} caption="รหัสพนักงาน" leftWidth="110" readOnly />
        <Field name="teamName" component={Input} caption="ทีมขาย" leftWidth="70" readOnly />
      </FirstRowContentContainer>

      <LastRowContentContainer>
        <Field name="fullName" component={Input} caption="ชื่อพนักงาน" leftWidth="110" readOnly />
        <Field
          name="memberStatus"
          component={Input}
          caption="สถานะ"
          leftWidth="70"
          isactive={props.memberStatus === "ลาออก" ? true : false}
          readOnly
        />
      </LastRowContentContainer>
    </ContentContainer>

    <TopicContainer>
      <TextTopic>ข้อมูลคอมมิชชั่น</TextTopic>
      <TextTopic>รวม {toCurrency(props.data.totalCommissionAmount)} บาท</TextTopic>
    </TopicContainer>

    <ContentContainer>
      <CustomFilterButton
        expanded={props.filterExpanded}
        onExpand={props.onExpandFilter}
        disabled={props.disabledFilter}
      >
        <FilterPanel values={props.values} />
      </CustomFilterButton>
      <Table
        loadingTable={props.loadingTable}
        data={props.salesDetails}
        pageable
        skip={props.paging.skip}
        take={props.paging.perPage}
        total={props.paging.totalCount}
        onPageChange={props.onPageChange}
      >
        <GridColumn title="รหัสคำสั่งซื้อ" field="tqmOrderId" width="110px" />
        <GridColumn title="ชื่อลูกค้า" field="customerName" width="180px" />
        <GridColumn title="แบบประกัน" field="policyName" width="180px" />
        <GridColumn field="insuranceCompanyName" title="บริษัทประกัน" width="182px" />
        <GridColumn
          title="วันที่แจ้งงาน"
          cell={makeCell(DatePicker, (cell) => ({
            readOnly: true,
            value: cell.dataItem.submittedAt,
          }))}
          width="120px"
        />
        <GridColumn
          title="รายได้"
          cell={(cell) => <td>{toCurrency(cell.dataItem.totalInsurerCommission)}</td>}
          width="100px"
        />
        <GridColumn
          title="เบี้ยสุทธิ"
          cell={(cell) => <td>{toCurrency(cell.dataItem.totalPremium)}</td>}
          width="100px"
        />
        <GridColumn
          title="สัดส่วนการจ่าย"
          cell={(cell) => <td>{toCurrency(cell.dataItem.commissionPercent)}</td>}
          width="120px"
        />
        <GridColumn
          title="คอมมิชชันจ่าย"
          cell={(cell) => <td>{toCurrency(cell.dataItem.commissionAmount)}</td>}
          width="115px"
        />
        <GridColumn
          title="วันที่รับเงิน"
          cell={makeCell(DatePicker, (props) => ({
            readOnly: true,
            value: props.dataItem.receivedDate,
          }))}
          width="120px"
        />
        <GridColumn
          title="วันที่จ่าย"
          cell={makeCell(DatePicker, (cell) => ({
            readOnly: true,
            value: cell.dataItem.commissionReceivedDate,
          }))}
          width="120px"
        />
        <GridColumn title="งวดที่" field="installment" width="80px" />
        <GridColumn title="ทีม" field="teamName" width="120px" />
        <GridColumn
          title="สถานะ"
          field="memberStatus"
          cell={(cell) => {
            return (
              <StatusTd className={"k-grid-content-sticky"}>
                <Dropdown
                  value={cell.dataItem.commissionStatus}
                  options={props.commissionDetailStatusOptions}
                  readOnly
                />
              </StatusTd>
            )
          }}
          width="120px"
          locked
        />
      </Table>
    </ContentContainer>
  </Body>
)

const validate = (values) => {
  const errors = {}

  const minReceivedAt = get(values, "receivedAt.min")
  const maxReceivedAt = get(values, "receivedAt.max")
  if (minReceivedAt && maxReceivedAt && minReceivedAt > maxReceivedAt) {
    errors.receivedAt = {
      min: "กรุณากรอกวันที่ให้ถูกต้อง",
      max: "กรุณากรอกวันที่ให้ถูกต้อง",
    }
  }

  if (!isEmpty(errors)) {
    errors._error = errors
  }

  return errors
}

const enhancer = compose(
  withRouter(),
  withState("loadingTable", "setLoadingTable", false),
  withState("filterExpanded", "setFilterExpanded", false),

  withStores((stores) => ({
    fetchById: stores.saleCommissionStore.fetchById,
    data: stores.saleCommissionStore.current,
    salesDetails: stores.saleCommissionStore.salesCommissionDetails,
    paging: stores.saleCommissionStore.paging,
    fetchConfigById: stores.saleCommissionStore.fetchConfigById,
    updateFullPaid: stores.saleCommissionStore.updateFullPaid,
    memberStatusOptions: stores.saleCommissionStore.$(
      "configsById.attributes.memberStatus.options",
      []
    ),
    commissionDetailStatusOptions: stores.saleCommissionStore.$(
      "configsById.attributes.salesCommissionDetailStatus.options",
      []
    ),
  })),
  withProps((props) => ({
    paging: {
      ...props.paging,
      skip: (props.paging.page - 1) * props.paging.perPage,
    },
  })),

  withProps((props) => {
    let memberStatus
    if (props.data.memberStatus && props.memberStatusOptions.length !== 0) {
      memberStatus = props.memberStatusOptions.find(
        (option) => option.value === props.data.memberStatus
      ).label
    }

    const filterParams = cloneDeep(props.location.query)
    if (filterParams.insurancePolicyId) {
      filterParams.insurancePolicyId = parseInt(filterParams.insurancePolicyId)
    }
    if (filterParams.insuranceCompanyId) {
      filterParams.insuranceCompanyId = parseInt(filterParams.insuranceCompanyId)
    }
    if (filterParams.submittedAt?.min) {
      filterParams.submittedAt.min = moment(filterParams.submittedAt.min, "DD/MM/YYYY")._d
    }
    if (filterParams.submittedAt?.max) {
      filterParams.submittedAt.max = moment(filterParams.submittedAt.max, "DD/MM/YYYY")._d
    }
    if (filterParams.receivedDate?.min) {
      filterParams.receivedDate.min = moment(filterParams.receivedDate.min, "DD/MM/YYYY")._d
    }
    if (filterParams.receivedDate?.max) {
      filterParams.receivedDate.max = moment(filterParams.receivedDate.max, "DD/MM/YYYY")._d
    }
    const initialValues = deepMerge.all([props.data, { memberStatus }, filterParams])
    return {
      memberStatus,
      initialValues,
      filterParams,
    }
  }),
  withState("fetchInsurerCommissionsTask", "setFetchInsurerCommissionsTask", null),
  withHandlers({
    onUpdateFilterParams: (props) => async (values, delay = 0) => {
      const filterParams = cloneDeep(values)
      const delParams = [
        "fullName",
        "isFullPaid",
        "memberStatus",
        "salesCommissionDetails",
        "salesmanPosition",
        "staffCode",
        "teamName",
        "totalCommissionAmount",
      ]
      delParams.forEach((e) => delete filterParams[e])

      const exceptNullParams = exceptNullKeys(
        filterParams,
        "insurancePolicyId",
        "commissionStatus",
        "insuranceCompanyId"
      )
      if (props.fetchInsurerCommissionsTask) {
        clearTimeout(props.fetchInsurerCommissionsTask)
      }
      await props.setFetchInsurerCommissionsTask(
        setTimeout(() => {
          props.history.push(
            path.saleCommissionPath(props.match.params.saleCommissionId, {
              viewType: props.location.query.viewType,
              ...filterParams,
              ...exceptNullParams,
            })
          )
        }, delay)
      )
    },
  }),
  withForm({
    form: "ShowSalesCommissionMemberDetail",
    validate,
    onChange: (filters, _, props) => {
      props.onUpdateFilterParams(filters, 400)
    },
  }),
  withHandlers({
    onExpandFilter: (props) => (expanded) => {
      props.setFilterExpanded(expanded)
    },
    onPageChange: (props) => async (event) => {
      const page = event.page.skip / event.page.take + 1
      await props.fetchById(props.match.params.saleCommissionId, {
        ...props.filterParams,
        page,
        viewType: props.location.query.viewType,
      })
    },
  }),
  lifecycle({
    async componentDidMount() {
      this.props.setLoadingTable(true)
      await Promise.all([
        this.props.fetchConfigById(this.props.match.params.saleCommissionId),
        this.props.fetchById(this.props.match.params.saleCommissionId, {
          ...this.props.filterParams,
          viewType: this.props.location.query.viewType,
        }),
      ])
      this.props.setLoadingTable(false)
    },
    async componentDidUpdate(prevProps) {
      if (!isEqual(this.props.filterParams, prevProps.filterParams)) {
        this.props.setLoadingTable(true)
        const newParams = cloneDeep({
          ...this.props.filterParams,
          viewType: this.props.location.query.viewType,
        })
        await Promise.all([
          this.props.fetchConfigById(this.props.match.params.saleCommissionId),
          this.props.fetchById(this.props.match.params.saleCommissionId, newParams),
        ])
        this.props.setLoadingTable(false)
      }
    },
  })
)

export default enhancer(SaleCommissions)
