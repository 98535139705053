import React, { useCallback, useState } from "react"
import styled from "@emotion/styled"
import { compose, withProps } from "recompose"
import { withStores, withRouter, withForm, withHooks } from "@enhancers"
import { displayNumber } from "@common/helper"
import { find, isEqual } from "lodash"
import { desktopSize } from "@common/styled"
import { Field, Icon, CurrencyInput } from "@components"
import RiderPremiumComponent from "./RiderPremiumComponent"

const WrapHolderInfo = styled.div`
  margin-top: 40px;
`
const HolderTitle = styled.div`
  width: 678px;
  height: 40px;
  background: #294558;
  border-radius: 4px 4px 0px 0px;
  color: #ffffff;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding-left: 16px;

  ${(props) =>
    desktopSize(`
    width: 100%;
  `)}
`
const HolderInfo = styled.div`
  padding: 20px;
  background: #ffffff;
  border: 1px solid #eeeef2;
  border-radius: 0 0 4px 4px;
  div {
    line-height: 2;
  }
`
const Wrap = styled.div`
  display: flex;
  margin-bottom: 24px;
`
const PolicyWarp = styled(Wrap)`
  flex-direction: column;
  margin-bottom: 14px;
`
const LeftInfo = styled.div`
  flex: 1;
`
const RightInfo = styled.div`
  flex: 1;
`
const TitleInfo = styled.div`
  color: #0275d8;
  font-weight: bold;
  margin-bottom: 16px;
`
const Col = styled.div`
  display: flex;
  margin-bottom: 16px;
`
const Row = styled.div`
  display: flex;
  margin-bottom: 6px;
`
const ValueContainer = styled(Col)`
  width: 100%;
  margin-bottom: 6px;

  > div:first-of-type {
    min-width: 112.56px;
  }
`
const SumInsuredContainer = styled(ValueContainer)`
  padding-left: 15px;
  max-width: 318px;

  > div:first-of-type {
    min-width: 97.56px;
  }
`
const Label = styled.div`
  color: #666666;
  font-weight: bold;
  min-width: 35.4%;
`
const Value = styled.div`
  min-width: 86.4px;
`
export const IconContainer = styled.div`
  display: ${(props) => props.display};
  justify-content: center;
  align-self: center;
  cursor: pointer;
  margin-left: 15px;
`
export const PencilIcon = styled(Icon)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0275d8;
`
export const CheckIcon = styled(Icon)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0275d8;
`
export const Unit = styled.div`
  display: ${(props) => props.display};
  align-self: center;
  margin-left: 5px;
`

const OrderInfo = (props) => {
  return (
    <WrapHolderInfo>
      <HolderTitle>รายละเอียดคำสั่งซื้อ</HolderTitle>
      <HolderInfo>
        <Wrap>
          <LeftInfo>
            <TitleInfo>ข้อมูลผู้เอาประกัน</TitleInfo>
            <Col>
              <Label>ชื่อ-นามสกุล</Label>
              <Value>{props.holderName}</Value>
            </Col>
            <Col>
              <Label>อาชีพ</Label>
              <Value>{props.holderCareer}</Value>
            </Col>
          </LeftInfo>
          <RightInfo>
            <TitleInfo>ข้อมูลผู้ชำระเบี้ย</TitleInfo>
            <Col>
              <Label>ชื่อ-นามสกุล</Label>
              <Value>{props.insurancePayerName}</Value>
            </Col>
          </RightInfo>
        </Wrap>

        <TitleInfo>ข้อมูลผู้ขาย</TitleInfo>
        <Wrap>
          <LeftInfo>
            <Col>
              <Label>รหัสพนักงาน</Label>
              <Value>{props.salesman && props.salesman.staffCode}</Value>
            </Col>
            <Col>
              <Label>ชื่อทีม</Label>
              <Value>{props.salesman && props.salesman.teamName}</Value>
            </Col>
          </LeftInfo>
          <RightInfo>
            <Col>
              <Label>ชื่อพนักงาน</Label>
              <Value> {props.salesman && props.salesman.name}</Value>
            </Col>
          </RightInfo>
        </Wrap>

        <TitleInfo>ข้อมูลแบบประกัน</TitleInfo>
        <PolicyWarp className="tqm-doccument-info-product">
          <Row>
            <ValueContainer>
              <Label>บริษัทประกัน</Label>
              <Value>
                {props.order.productDetail && props.order.productDetail.insuranceCompanyName}
              </Value>
            </ValueContainer>
            <ValueContainer>
              <Label>โหมดการชำระ</Label>
              <Value>{props.paymentMethod && props.paymentMethod.label}</Value>
            </ValueContainer>
          </Row>
          <Row>
            <ValueContainer>
              <Label>ชื่อแบบ</Label>
              <Value>{props.order.productDetail && props.order.productDetail.policyName}</Value>
            </ValueContainer>
          </Row>
          <Row>
            <SumInsuredContainer>
              <Label>ทุนประกัน</Label>
              <Value>{displayNumber(parseInt(props.order.sumInsured))}</Value>
              <Unit>บาท</Unit>
            </SumInsuredContainer>

            {/* <ValueContainer>
              <Label>เบี้ยประกัน</Label>
              <Value>{displayNumber(props.order.premium)} บาท</Value>
            </ValueContainer> */}

            <Field
              name="premium"
              caption="เบี้ยต่องวด"
              component={CurrencyInput}
              readOnly={!props.isEditLifePrmium}
              leftWidth="112.55"
              rightWidth={props.isEditLifePrmium ? "120" : "86.4"}
              disabled={props.isProgress}
            />
            <Unit display={!props.isEditLifePrmium ? "flex" : "none"}>บาท</Unit>

            {!props.isProgress && props.order.state === "doc_verifying" && (
              <IconContainer
                onClick={props.isEditLifePrmium ? props.onSave : props.toggleEditPremium}
              >
                {props.isEditLifePrmium ? (
                  <CheckIcon icon={"check"} />
                ) : (
                  <PencilIcon icon={"pencil"} />
                )}
              </IconContainer>
            )}
          </Row>
        </PolicyWarp>

        {props.riderProducts &&
          props.riderProducts.map((product, index) => (
            <PolicyWarp key={index} className="tqm-doccument-info-product">
              <Row>
                <ValueContainer>
                  <Label>ชื่อแบบ</Label>
                  <Value>{product.productDetail && product.productDetail.policyName}</Value>
                </ValueContainer>
              </Row>
              <Row>
                <SumInsuredContainer>
                  <Label>ทุนประกัน</Label>
                  <Value>{displayNumber(parseInt(product.sumInsured))}</Value>
                  <Unit>บาท</Unit>
                </SumInsuredContainer>

                {/* <ValueContainer>
                  <Label>เบี้ยประกัน</Label>
                  <Value>{displayNumber(product.premium)} บาท</Value>
                </ValueContainer> */}

                <Field
                  name="riders"
                  component={RiderPremiumComponent}
                  rider={product}
                  index={index}
                  updatePremium={props.updatePremium}
                  orderId={props.order.id}
                  orderState={props.order.state}
                />
              </Row>
            </PolicyWarp>
          ))}

        <ValueContainer>
          <Label>เบี้ยรวมต่องวด:</Label>
          <Value>{displayNumber(props.order.totalPremium)}</Value>
        </ValueContainer>
      </HolderInfo>
    </WrapHolderInfo>
  )
}

const enhancer = compose(
  withRouter(),
  withStores((stores) => {
    let initialValues = stores.orderStore.$("current", {})
    const mappedRiderProducts = initialValues.riderProducts.map((riderProduct) => {
      return { ...riderProduct, coverageDate: initialValues.coverageDate }
    })

    return {
      order: stores.orderStore.current || {},
      paymentPeriodOptions: stores.orderStore.$("current.paymentOptions", []),
      careerOptions: stores.orderStore.$("orderConfigs.attributes.career.options", []),
      riderProducts: mappedRiderProducts,
      initialValues: initialValues,
      updatePremium: stores.orderStore.updatePremium,
    }
  }),
  withProps((props) => {
    const { order, paymentPeriodOptions } = props || {}
    const { salesman } = order || {}
    const holderName = `${order.insuranceHolder.nameInfo.prefixName} ${order.insuranceHolder.nameInfo.firstName} ${order.insuranceHolder.nameInfo.lastName}`
    const holderCareer = find(props.careerOptions, (option) =>
      isEqual(order.insuranceHolder.careerCode, option.value)
    )?.label
    const insurancePayerName = `${order.insurancePayer.nameInfo.prefixName} ${order.insurancePayer.nameInfo.firstName} ${order.insurancePayer.nameInfo.lastName}`
    const paymentMethod = paymentPeriodOptions.find(({ value }) => order.paymentPeriod === value)

    return {
      sumInsured: order.sumInsured,
      premium: order.premium,
      paymentMethod,
      holderName,
      insurancePayerName,
      holderCareer,
      salesman,
    }
  }),
  withForm({
    form: "orderInfo",
  }),
  withHooks((props) => {
    const [isEditLifePrmium, setIsEditLifePrmium] = useState(false)
    const [isProgress, setIsProgress] = useState(false)

    const onSave = useCallback(async () => {
      setIsProgress(true)
      try {
        await props.updatePremium(props.order.id, { lifePremium: props.values.premium })
      } catch (error) {
        const errorMsg = error.errors?.base || "แก้ไขเบี้ยประกันไม่สำเร็จ"
        window.alert(errorMsg)
        props.reset(props.form)
      }
      setIsProgress(false)
      setIsEditLifePrmium(!isEditLifePrmium)
    })

    const toggleEditPremium = useCallback(() => {
      setIsEditLifePrmium(!isEditLifePrmium)
    })

    return {
      isProgress,
      isEditLifePrmium,
      toggleEditPremium,
      onSave,
    }
  })
)

export default enhancer(OrderInfo)
