import { intersection, isEmpty } from "lodash"
import { get } from "lodash"
import thaiNationalID from "@common/thaiNationalId"
import moment from "moment"

const validate = (values, props) => {
  const currentRole = props.currentRole
  const errors = {}
  const {
    insuranceHolder,
    insurancePayer,
    currentAddress,
    homeAddress,
    officeAddress,
    paymentPeriod,
    paymentMethod,
    firstDueDate,
    routeCode,
    contactAddressType,
    policyAddressType,
    paymentCreditCardHolderName,
    paymentBankAccountName,
    paymentCreditCardNumber,
    paymentBankAccountNumber,
    paymentCreditCardType,
    paymentBankAccountType,
    paymentCreditCardBankCode,
    paymentBankCode,
    paymentBankBranchName,
    paymentCreditCardExpiryDate,
  } = values || {}
  const { paymentCreditCardExpiryMonth, paymentCreditCardExpiryYear } =
    paymentCreditCardExpiryDate || {}
  const insuranceHolderRequiredFields =
    currentRole !== "member.affiliate"
      ? [
          "saleDate",
          "nameInfo",
          "idCard",
          "birthdate",
          "gender",
          "maritalStatus",
          "mobilePhoneNumber",
          "careerCode",
        ]
      : ["nameInfo", "gender", "birthdate", "mobilePhoneNumber"]

  const insuranceHolderFilteredKeys = Object.keys(insuranceHolder || {}).filter(
    (item) => insuranceHolder[item] === null || insuranceHolder[item] === ""
    // ||(item === "idCard" && !thaiNationalID(insuranceHolder[item]))
  )

  const invalidHolderFields = intersection(
    insuranceHolderRequiredFields,
    insuranceHolderFilteredKeys
  )

  const insuranceHolderError = {}

  if (invalidHolderFields.length !== 0) {
    insuranceHolderError.insuranceHolder = invalidHolderFields
    errors._error = {
      insuranceHolder: invalidHolderFields,
    }
  }

  const insurancePayerRequiredFields =
    currentRole !== "member.affiliate"
      ? [
          "nameInfo",
          "birthdate",
          "idCard",
          "gender",
          "maritalStatus",
          "mobilePhoneNumber",
          "careerCode",
        ]
      : []

  const insurancePayerFilteredKeys = Object.keys(insurancePayer || {}).filter(
    (item) => insurancePayer[item] === null || insurancePayer[item] === ""
  )

  const invalidPayerFields = intersection(insurancePayerRequiredFields, insurancePayerFilteredKeys)

  const insurancePayerError = {}

  if (invalidPayerFields.length !== 0) {
    insurancePayerError.insurancePayer = invalidPayerFields
    errors._error = {
      insurancePayer: invalidPayerFields,
    }
  }

  const insuranceCurrentAddressError = {}

  if (currentAddress) {
    const insuranceCurrentAddressRequiredFields =
      currentRole !== "member.affiliate"
        ? ["housingNo", "building", "subDistrict", "district", "province", "postcode"]
        : ["subDistrict", "building", "district", "province", "postcode"]

    const insuranceCurrentAddressFilteredKeys = Object.keys(currentAddress || {}).filter((item) => {
      const isBlank = currentAddress[item] === null || currentAddress[item] === ""
      if (!isBlank && item === "postcode") {
        return currentAddress[item].length > 8
      } else if (item === "building") {
        return currentAddress[item] && currentAddress[item].length > 100
      } else {
        return isBlank
      }
    })

    const invalidCurrentAddressFields = intersection(
      insuranceCurrentAddressRequiredFields,
      insuranceCurrentAddressFilteredKeys
    )

    if (invalidCurrentAddressFields.length !== 0) {
      insuranceCurrentAddressError.currentAddress = invalidCurrentAddressFields
      errors._error = {
        currentAddress: invalidCurrentAddressFields,
      }
    }
  } else {
    insuranceCurrentAddressError.currentAddress =
      currentRole !== "member.affiliate"
        ? ["housingNo", "subDistrict", "district", "province", "postcode"]
        : ["subDistrict", "district", "province", "postcode"]
    errors._error = {
      currentAddress: insuranceCurrentAddressError.currentAddress,
    }
  }

  const insuranceOfficeAddressError = {}

  if (officeAddress) {
    const insuranceOfficeAddressRequiredFields =
      currentRole !== "member.affiliate"
        ? ["housingNo", "building", "subDistrict", "district", "province", "postcode"]
        : []

    const insuranceOfficeAddressFilteredKeys = Object.keys(officeAddress || {}).filter((item) => {
      const isBlank = officeAddress[item] === null || officeAddress[item] === ""
      if (!isBlank && item === "postcode") {
        return officeAddress[item].length > 8
      } else if (item === "building") {
        return officeAddress[item] && officeAddress[item].length > 100
      } else {
        return isBlank
      }
    })

    const invalidOfficeAddressFields = intersection(
      insuranceOfficeAddressRequiredFields,
      insuranceOfficeAddressFilteredKeys
    )

    if (invalidOfficeAddressFields.length !== 0) {
      insuranceOfficeAddressError.officeAddress = invalidOfficeAddressFields
      errors._error = {
        officeAddress: invalidOfficeAddressFields,
      }
    }
  } else {
    insuranceCurrentAddressError.officeAddress =
      currentRole !== "member.affiliate"
        ? ["housingNo", "subDistrict", "district", "province", "postcode"]
        : []

    errors._error = {
      officeAddress: insuranceCurrentAddressError.officeAddress,
    }
  }

  const insuranceHomeAddressError = {}

  if (homeAddress) {
    const insuranceHomeAddressRequiredFields =
      currentRole !== "member.affiliate"
        ? ["housingNo", "building", "subDistrict", "district", "province", "postcode"]
        : []

    const insuranceHomeAddressFilteredKeys = Object.keys(homeAddress || {}).filter((item) => {
      const isBlank = homeAddress[item] === null || homeAddress[item] === ""

      if (!isBlank && item === "postcode") {
        return homeAddress[item].length > 8
      } else if (item === "building") {
        return homeAddress[item] && homeAddress[item].length > 100
      } else {
        return isBlank
      }
    })

    const invalidHomeAddressFields = intersection(
      insuranceHomeAddressRequiredFields,
      insuranceHomeAddressFilteredKeys
    )

    if (invalidHomeAddressFields.length !== 0) {
      insuranceHomeAddressError.homeAddress = invalidHomeAddressFields
      errors._error = {
        homeAddress: invalidHomeAddressFields,
      }
    }
  } else {
    insuranceCurrentAddressError.homeAddress =
      currentRole !== "member.affiliate"
        ? ["housingNo", "subDistrict", "district", "province", "postcode"]
        : []
    errors._error = {
      homeAddress: insuranceCurrentAddressError.homeAddress,
    }
  }

  const paymentPlansError = {}

  if (!firstDueDate && currentRole !== "member.affiliate") {
    paymentPlansError.firstDueDate = ["firstDueDate"]
    errors._error = {
      firstDueDate: ["firstDueDate"],
    }
  }

  if (!paymentPeriod && currentRole !== "member.affiliate") {
    paymentPlansError.paymentPeriod = ["paymentPeriod"]
    errors._error = {
      paymentPeriod: ["paymentPeriod"],
    }
  }

  if (!paymentMethod && currentRole !== "member.affiliate") {
    paymentPlansError.paymentMethod = ["paymentMethod"]
    errors._error = {
      paymentMethod: ["paymentMethod"],
    }
  }

  if (!routeCode && currentRole !== "member.affiliate") {
    paymentPlansError.routeCode = ["routeCode"]
    errors._error = {
      routeCode: ["routeCode"],
    }
  }

  if (!["bill_payment", "cash"].includes(paymentMethod) && !!paymentMethod) {
    if (["credit_card", "credit_card_cash"].includes(paymentMethod)) {
      if (!paymentCreditCardHolderName) {
        paymentPlansError.paymentCreditCardHolderName = ["paymentCreditCardHolderName"]
        errors._error = {
          paymentCreditCardHolderName: ["paymentCreditCardHolderName"],
        }
      }

      if (!paymentCreditCardNumber) {
        paymentPlansError.paymentCreditCardNumber = ["paymentCreditCardNumber"]
        errors._error = {
          paymentCreditCardNumber: ["paymentCreditCardNumber"],
        }
      }

      if (!paymentCreditCardType) {
        paymentPlansError.paymentCreditCardType = ["paymentCreditCardType"]
        errors._error = {
          paymentCreditCardType: ["paymentCreditCardType"],
        }
      }

      if (!paymentCreditCardBankCode) {
        paymentPlansError.paymentCreditCardBankCode = ["paymentCreditCardBankCode"]
        errors._error = {
          paymentCreditCardBankCode: ["paymentCreditCardBankCode"],
        }
      }

      if (!paymentCreditCardExpiryMonth || !paymentCreditCardExpiryYear) {
        paymentPlansError.paymentCreditCardExpiryDate = ["paymentCreditCardExpiryDate"]
        errors._error = {
          paymentCreditCardExpiryDate: ["paymentCreditCardExpiryDate"],
        }
      } else if (paymentCreditCardExpiryMonth === "0") {
        paymentPlansError.paymentCreditCardExpiryDate = ["paymentCreditCardExpiryDate"]
        errors._error = {
          paymentCreditCardExpiryDate: ["paymentCreditCardExpiryDate"],
        }
      } else if (paymentCreditCardExpiryMonth === "00") {
        paymentPlansError.paymentCreditCardExpiryDate = ["paymentCreditCardExpiryDate"]
        errors._error = {
          paymentCreditCardExpiryDate: ["paymentCreditCardExpiryDate"],
        }
      } else if (paymentCreditCardExpiryMonth > 12) {
        paymentPlansError.paymentCreditCardExpiryDate = ["paymentCreditCardExpiryDate"]
        errors._error = {
          paymentCreditCardExpiryDate: ["paymentCreditCardExpiryDate"],
        }
      } else if (paymentCreditCardExpiryYear > moment().year() + 10) {
        paymentPlansError.paymentCreditCardExpiryDate = ["paymentCreditCardExpiryDate"]
        errors._error = {
          paymentCreditCardExpiryDate: ["paymentCreditCardExpiryDate"],
        }
      } else if (paymentCreditCardExpiryYear < moment().year()) {
        paymentPlansError.paymentCreditCardExpiryDate = ["paymentCreditCardExpiryDate"]
        errors._error = {
          paymentCreditCardExpiryDate: ["paymentCreditCardExpiryDate"],
        }
      }
    } else {
      if (!paymentBankAccountName) {
        paymentPlansError.paymentBankAccountName = ["paymentBankAccountName"]
        errors._error = {
          paymentBankAccountName: ["paymentBankAccountName"],
        }
      }

      if (!paymentBankAccountNumber) {
        paymentPlansError.paymentBankAccountNumber = ["paymentBankAccountNumber"]
        errors._error = {
          paymentBankAccountNumber: ["paymentBankAccountNumber"],
        }
      }

      if (!paymentBankAccountType) {
        paymentPlansError.paymentBankAccountType = ["paymentBankAccountType"]
        errors._error = {
          paymentBankAccountType: ["paymentBankAccountType"],
        }
      }

      if (!paymentBankCode) {
        paymentPlansError.paymentBankCode = ["paymentBankCode"]
        errors._error = {
          paymentBankCode: ["paymentBankCode"],
        }
      }

      if (!paymentBankBranchName) {
        paymentPlansError.paymentBankBranchName = ["paymentBankBranchName"]
        errors._error = {
          paymentBankBranchName: ["paymentBankBranchName"],
        }
      }
    }
  }

  const policyAddressTypeError = {}

  if (!policyAddressType && currentRole !== "member.affiliate") {
    policyAddressTypeError.policyAddressType = ["policyAddressType"]
    errors._error = {
      policyAddressType: ["policyAddressType"],
    }
  }

  if (!contactAddressType && currentRole !== "member.affiliate") {
    policyAddressTypeError.contactAddressType = ["contactAddressType"]
    errors._error = {
      contactAddressType: ["contactAddressType"],
    }
  }

  if (
    get(values.insuranceHolder, "insuranceBeneficiaries.length") === 0 ||
    get(values.insuranceHolder, "insuranceBeneficiaries.length") === undefined
  ) {
    errors._error = {
      ...insuranceHolderError,
      ...insurancePayerError,
      ...insuranceCurrentAddressError,
      ...insuranceOfficeAddressError,
      ...insuranceHomeAddressError,
      ...policyAddressTypeError,
      ...paymentPlansError,
    }
  } else {
    errors._error = {
      ...insuranceHolderError,
      ...insurancePayerError,
      ...insuranceCurrentAddressError,
      ...insuranceOfficeAddressError,
      ...insuranceHomeAddressError,
      ...policyAddressTypeError,
      ...paymentPlansError,
    }

    const insuranceBeneficiariesArrayErrors = []

    if (!get(values.insuranceHolder, "isStatutoryHeir")) {
      values.insuranceHolder &&
        values.insuranceHolder.insuranceBeneficiaries &&
        values.insuranceHolder.insuranceBeneficiaries.forEach((insuranceBeneficiary, index) => {
          const insuranceBeneficiaryError = {}
          const insuranceBeneficiariesNameInfo = {
            prefixName: get(insuranceBeneficiary.nameInfo, "prefixName") ? null : "required",
            firstName: get(insuranceBeneficiary.nameInfo, "firstName") ? null : "required",
            lastName: get(insuranceBeneficiary.nameInfo, "lastName") ? null : "required",
          }

          if (
            get(insuranceBeneficiariesNameInfo, "prefixName") === null &&
            get(insuranceBeneficiariesNameInfo, "firstName") === null &&
            get(insuranceBeneficiariesNameInfo, "lastName") === null
          ) {
            delete insuranceBeneficiaryError.nameInfo
          } else {
            insuranceBeneficiaryError.nameInfo = insuranceBeneficiariesNameInfo
            insuranceBeneficiariesArrayErrors[index] = insuranceBeneficiaryError
          }

          if (
            !insuranceBeneficiaryError ||
            !insuranceBeneficiary.relationship ||
            insuranceBeneficiary.relationship === null ||
            insuranceBeneficiary.relationship === "" ||
            insuranceBeneficiary.relationship === undefined
          ) {
            insuranceBeneficiaryError.relationship = "required"
            insuranceBeneficiariesArrayErrors[index] = insuranceBeneficiaryError
          }

          const percent =
            get(values.insuranceHolder, "insuranceBeneficiaries").reduce((sum, item) => {
              return sum + parseFloat(item.percent || 0)
            }, 0) || 0

          if (percent === null || percent === "" || percent === undefined || percent === 0) {
            insuranceBeneficiaryError.percent = "required"
            insuranceBeneficiariesArrayErrors[index] = insuranceBeneficiaryError
          } else {
            if (percent !== 100) {
              insuranceBeneficiaryError.percent = "required"
              insuranceBeneficiariesArrayErrors[index] = insuranceBeneficiaryError
            }
          }
        })
    }

    if (insuranceBeneficiariesArrayErrors.length && currentRole !== "member.affiliate") {
      errors.insuranceBeneficiaries = {
        ...errors.insuranceBeneficiaries,
        ...insuranceBeneficiariesArrayErrors,
      }
      errors._error = {
        ...insuranceHolderError,
        ...insurancePayerError,
        ...insuranceCurrentAddressError,
        ...insuranceOfficeAddressError,
        ...insuranceHomeAddressError,
        ...policyAddressTypeError,
        insuranceBeneficiaries: insuranceBeneficiariesArrayErrors,
        ...paymentPlansError,
      }
    }
  }

  if (isEmpty(errors._error)) {
    return null
  } else {
    return errors
  }
}

export default validate
