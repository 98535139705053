import React, { useEffect } from "react"
import styled from "@emotion/styled"
import { Field, Button, Dropdown } from "@components"
import { compose, lifecycle, withHandlers, withProps } from "recompose"
import { isEmpty } from "lodash"
import { withHooks } from "@enhancers/index"

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2px 21px 6px 21px;
`
const HeadText = styled(Button)`
  color: #d9534f;
  margin-bottom: 14px;
  border-color: white;
  padding-left: 4px;
  width: fit-content;

  .k-icon {
    font-size: 20px;
    margin-right: 12.5px;
  }

  :hover {
    cursor: default;
    color: #d9534f;
    border-color: white;
  }
`
const Caption = styled.div`
  align-self: center;
  min-width: 101px;
`
const FieldContainer = styled.div`
  display: flex;
  margin-bottom: 12px;

  > span {
    min-width: 231px;
    min-height: 38px;
  }
`
const AddCommissionRateDetailsButton = styled(Button)`
  width: 20px;
  border-color: white;
  margin-left: 2px;

  .k-icon {
    font-size: 20px;
  }

  :hover {
    border-color: white;
  }
`
const TrashButton = styled(Button)`
  width: 20px;
  border-color: white;
  margin-left: 2px;

  .k-icon {
    font-size: 20px;
  }

  :hover {
    border-color: white;
  }
`

const SalesmanExcepted = (props) => (
  <Layout>
    <HeadText icon="filter" children={props.isOrder ? "ยกเว้นรหัสคำสั่งซื้อ" : "ยกเว้นพนักงาน"} />
    {props.fields.map((salesmanExcepted, index) => {
      const value = props.values[index]
      let customOptions = props.baseOptions
      if (!isEmpty(value)) {
        customOptions = [{ label: value, value }, ...props.baseOptions]
      }
      return (
        <FieldContainer key={index} PrevLength={props.fields.length}>
          {index === 0 ? (
            <Caption>{props.isOrder ? "รหัสคำสั่งซื้อ" : "ชื่อพนักงาน"}</Caption>
          ) : (
            <Caption />
          )}
          <Field
            component={Dropdown}
            name={`${salesmanExcepted}`}
            options={customOptions}
            isFirstChild={index === 0 ? true : false}
            placeholder="กรุณาระบุ"
            required
          />
          {props.fields.length - 1 === index ? (
            <AddCommissionRateDetailsButton icon="plus" onClick={props.onAdd} />
          ) : (
            <TrashButton icon="trash" onClick={() => props.onDelete(index)} styletype="delete" />
          )}
        </FieldContainer>
      )
    })}
  </Layout>
)

const enhancer = compose(
  withHandlers({
    onAdd: (props) => () => {
      props.fields.push({})
    },
    onDelete: (props) => (index) => {
      if (props.fields.length > 1) {
        props.fields.remove(index)
      }
    },
  }),
  withProps((props) => {
    const values = props.fields.getAll() || []
    const baseOptions = props.options.filter((option) => !values.includes(option.value))
    return { baseOptions, values }
  }),
  withHooks((props) => {
    useEffect(() => {
      if (props.fields.length === 0) {
        props.fields.push({})
      }
    }, [props.isOrder])
    return {}
  })
)

export default enhancer(SalesmanExcepted)
