const convertUplineTree = (data) => {
  if (!data) {
    return null
  }

  if (!(data instanceof Array)) {
    data = [data]
  }

  const tree = []
  data.forEach((item) => {
    const { childs, value } = item
    tree.push({
      value: { ...value },
      expanded: true,
      items: convertUplineTree(childs),
    })
  })

  return tree
}

export default convertUplineTree
