import { compose, lifecycle, withHandlers, withProps, withState } from "recompose"
import { withStores, withForm, withRouter } from "@enhancers"
import * as path from "@common/path"
import { convertNaNtoZero, isClient } from "@common/helper"
import { get, isNaN, isEqual, find } from "lodash"
import { SummaryPage } from "@pages/products/custom/show/SummaryPage"
import moment from "moment"

const enhancer = compose(
  withRouter({
    queryTypes: {
      birthdate: "date",
      gender: "string",
      sumInsured: "integer",
      type: "string",
      searchBy: "string",
      leadId: "integer",
      from: "string",
      occupation: "string",
      occupationCategory: "string",
    },
  }),
  withState("isCreateLead", "setIsCreateLead", false),
  withState("isSaveDraft", "setIsSetDraft", false),
  withState("orderResponse", "setOrderResponse", {}),
  withForm({
    form: "buyPackage",
    destroyOnUnmount: false,
  }),
  withStores((stores, props) => ({
    fetchPackagePolicyById: stores.packageStore.fetchPackagePolicyById,
    productDetail: stores.packageStore.current,
    addOrderPackage: stores.orderStore.addOrderPackage,
    fetchLead: stores.leadStore.fetchById,
    deleteOrder: stores.orderStore.deleteOrder,
    updateOrderPackage: stores.orderStore.updateOrderPackage,
    fetchOrder: stores.orderStore.fetchOrder,
    orderState: stores.orderStore.$("current.state"),
    memberType: stores.appStore.$("currentUser.memberInfo.memberType"),
    currentUserName: stores.appStore.$("currentUser.nameThInfo"),
  })),
  withProps((props) => {
    const searchParams = props.location.query
    const { packageId } = props.match.params
    let { totalPremium, packagesRiderInsurancePolicies = [] } = props.productDetail

    const age = searchParams.birthdate
      ? moment().diff(moment(searchParams.birthdate), "years")
      : null

    packagesRiderInsurancePolicies = packagesRiderInsurancePolicies.map((rider) => {
      const riderParam = (searchParams.riders || []).find((r) => `${r.id}` === `${rider.id}`)
      return { ...rider, ...riderParam, selected: true }
    })

    let totalPremiumRider = 0
    let totalSumInsuredRider = 0

    searchParams.riders &&
      searchParams.riders.forEach((item) => {
        totalPremiumRider += isNaN(item.premium)
          ? convertNaNtoZero(item.premium)
          : parseFloat(item.premium)
        totalSumInsuredRider += isNaN(item.sumInsured)
          ? convertNaNtoZero(item.sumInsured)
          : parseFloat(item.premium)
      })

    const premiumParams = isNaN(searchParams.premium)
      ? convertNaNtoZero(searchParams.premium)
      : parseFloat(searchParams.premium)

    totalPremium = premiumParams + parseFloat(totalPremiumRider)

    return {
      productDetail: {
        ...props.productDetail.policy,
        insuranceCoverageTable: props.productDetail.benefit || [],
        riders: packagesRiderInsurancePolicies,
        protectionPeriodText:
          props.productDetail &&
          props.productDetail.policy &&
          props.productDetail.policy.protectionPeriodText &&
          props.productDetail.policy.protectionPeriodText.protectPeriodAge,
        premium: searchParams.premium,
        sumInsured: searchParams.sumInsured,
      },
      riders: packagesRiderInsurancePolicies,
      params: {
        ...searchParams,
        age,
        packageId,
      },
      totalPremiumRider,
      totalSumInsuredRider,
      totalPremium,
      titleButton: "สนใจซื้อ",
      iconButton: "cart",
    }
  }),
  withHandlers({
    onCancel: (props) => () => {
      const {
        leadId,
        orderId,
        // age,
        // gender,
        // sumInsured,
        // birthdate,
        // type
      } = props.params
      if (leadId && orderId) {
        // props.history.push(path.ordersPath())
        props.history.push(
          path.productPackageEditPath(props.params.packageId, props.location.query)
        )
      } else {
        props.history.push(
          path.productPackageEditPath(props.params.packageId, props.location.query)
        )

        // props.history.push(
        //   path.productsPath({
        //     age,
        //     leadId,
        //     gender,
        //     sumInsured,
        //     birthdate,
        //     type,
        //   })
        // )
      }
    },
    onDelete: (props) => async () => {
      await props.deleteOrder(props.params.orderId)
      props.history.push(path.ordersPath())
    },
    onFormSubmit: (props) =>
      props.handleSubmit(async (values) => {
        try {
          if (props.params.leadId && props.params.orderId) {
            const leadInfo = await props.fetchLead(props.params.leadId)

            const nameInfo = { ...get(leadInfo, "customerInfo.nameInfo", {}) }
            if (["น.ส."].includes(nameInfo.prefixName)) {
              nameInfo.prefixName = "นางสาว"
            }

            const orderResponse = await props.updateOrderPackage(props.params.orderId, {
              packageId: props.params.packageId,
              leadId: props.params.leadId,
              gender: props.params.gender,
              birthdate: props.params.birthdate,
              age: props.params.age,
              nameInfo,
              isConfirmed: true,
              policyPremium: props.params.premium,
              paymentPeriod: props.params.paymentPeriod,
              occupation: props.params.occupation,
              occupationCategory: props.params.occupationCategory,
              opportunityKey: props.params.opportunityKey,
              riders: (props.params.riders || []).map((r) => ({
                riderPolicyId: r.id,
                riderPremium: r.premium,
              })),
            })

            props.history.push(path.orderHolderInfoPath(orderResponse.order.id))
          } else if (props.params.leadId) {
            const leadInfo = await props.fetchLead(props.params.leadId)

            const nameInfo = { ...get(leadInfo, "customerInfo.nameInfo", {}) }
            if (["น.ส."].includes(nameInfo.prefixName)) {
              nameInfo.prefixName = "นางสาว"
            }

            try {
              const orderResponse = await props.addOrderPackage({
                packageId: props.params.packageId,
                leadId: props.params.leadId,
                gender: props.params.gender,
                birthdate: props.params.birthdate,
                age: props.params.age,
                nameInfo,
                isDraft: false,
                policyPremium: props.params.premium,
                paymentPeriod: props.params.paymentPeriod,
                occupation: props.params.occupation,
                occupationCategory: props.params.occupationCategory,
                opportunityKey: props.params.opportunityKey,
                riders: (props.params.riders || []).map((r) => ({
                  riderPolicyId: r.id,
                  riderPremium: r.premium,
                })),
              })
              props.history.push(path.orderHolderInfoPath(orderResponse.order.id))
            } catch (e) {
              if (e.message === "Submit Validation Failed" && e.errors.base) {
                alert(e.errors.base)
              }
            }
          } else {
            props.history.push(
              path.productPackageCreateLeadPath(props.params.packageId, {
                ...props.location.query,
                packageId: props.params.packageId,
                isDraft: false,
                maxAgeInsured: props.productDetail.maxAgeInsured,
              })
            )
          }
        } catch (e) {
          const error = get(e, "errors.base", null)

          if (error) {
            window.alert(error)
          } else {
            window.alert("สร้าง Order ไม่สำเร็จ")
          }
          if (e.name === "SubmissionError") {
            throw e
          }
        }
      }),
    handleSaveDraft: (props) =>
      props.handleSubmit(async (values) => {
        try {
          if (props.params.leadId && props.params.orderId) {
            const leadInfo = await props.fetchLead(props.params.leadId)

            const nameInfo = { ...get(leadInfo, "customerInfo.nameInfo", {}) }
            if (["น.ส."].includes(nameInfo.prefixName)) {
              nameInfo.prefixName = "นางสาว"
            }
            await props.updateOrderPackage(props.params.orderId, {
              packageId: props.params.packageId,
              leadId: props.params.leadId,
              gender: props.params.gender,
              birthdate: props.params.birthdate,
              age: props.params.age,
              nameInfo,
              isConfirmed: false,
              occupation: props.params.occupation,
              occupationCategory: props.params.occupationCategory,
              policyPremium: props.params.premium,
              paymentPeriod: props.params.paymentPeriod,
              opportunityKey: props.params.opportunityKey,
              riders: (props.params.riders || []).map((r) => ({
                riderPolicyId: r.id,
                riderPremium: r.premium,
              })),
            })
            props.history.push(path.ordersPath())
          } else if (props.params.leadId) {
            const leadInfo = await props.fetchLead(props.params.leadId)

            const nameInfo = { ...get(leadInfo, "customerInfo.nameInfo", {}) }
            if (["น.ส."].includes(nameInfo.prefixName)) {
              nameInfo.prefixName = "นางสาว"
            }
            await props.addOrderPackage({
              packageId: props.params.packageId,
              leadId: props.params.leadId,
              gender: props.params.gender,
              birthdate: props.params.birthdate,
              age: props.params.age,
              nameInfo,
              isDraft: true,
              occupation: props.params.occupation,
              occupationCategory: props.params.occupationCategory,
              policyPremium: props.params.premium,
              paymentPeriod: props.params.paymentPeriod,
              opportunityKey: props.params.opportunityKey,
              riders: (props.params.riders || []).map((r) => ({
                riderPolicyId: r.id,
                riderPremium: r.premium,
              })),
            })
            props.history.push(path.ordersPath())
          } else {
            props.history.push(
              path.productPackageCreateLeadPath(props.params.packageId, {
                ...props.location.query,
                packageId: props.params.packageId,
                isDraft: true,
                maxAgeInsured: props.productDetail.maxAgeInsured,
              })
            )
            props.setIsSetDraft(true)
          }
        } catch (e) {
          const error = get(e, "errors.base", null)

          if (error) {
            window.alert(error)
          } else {
            window.alert("สร้าง Order Save Draft ไม่สำเร็จ")
          }
          if (e.name === "SubmissionError") {
            throw e
          }
        }
      }),
  }),
  lifecycle({
    async componentDidMount() {
      if (isClient) {
        window.scrollTo(0, 0)
      }

      const params = this.props.params

      if (this.props.params.orderId) {
        await this.props.fetchOrder(this.props.params.orderId)
      }

      const response = await this.props.fetchPackagePolicyById({
        packageId: params.packageId,
        birthdate: params.birthdate,
        gender: params.gender,
        premium: params.premium,
        paymentPeriod: params.paymentPeriod,
      })

      response.riderPolicies = response.packagesRiderInsurancePolicies || []

      const newProps = {}

      newProps.readOnly = true

      newProps.riders = (params.riders || []).map((rider) => {
        const selectedRider = find(response.riderPolicies, (r) => {
          return isEqual(`${r.id}`, rider.id)
        })
        return { ...selectedRider, ...rider, selected: true }
      })

      newProps.sumInsured =
        params.searchBy === "sum_insured" ? params.searchValue : response.policy.sumInsured
      newProps.premium =
        params.searchBy === "premium" ? params.searchValue : response.policy.premium
      newProps.birthdate = params.birthdate
      newProps.policyId = params.productId
      newProps.searchBy = params.searchBy

      this.props.initialize(newProps)
    },
  }),
  withProps((props) => {
    const { params = {} } = props
    let links = []
    switch (params.from) {
      case "custom_lead":
        links = [
          { label: "ลูกค้าใหม่", path: path.leadsPath("custom") },
          {
            label: "รายละเอียดลีด",
            path: path.leadOrderInfoPath(params.leadId),
          },
          {
            label: "ค้นหาแบบประกัน",
            path: path.productsSearchPath(params.leadId, {
              leadId: params.leadId,
              age: params.age,
              gender: params.gender,
              sumInsured: params.sumInsured,
              type: params.type,
              birthdate: params.birthdate,
              from: params.from,
              opportunityKey: params.opportunityKey,
            }),
          },
          {
            label: "เลือกแบบประกัน",
            path: path.productsPath(params.leadId, {
              age: params.age,
              gender: params.gender,
              type: params.type,
              sumInsured: params.sumInsured,
              leadId: params.leadId,
              birthdate: params.birthdate,
              from: params.from,
              opportunityKey: params.opportunityKey,
            }),
          },
          {
            label: "สรุปแบบประกัน",
          },
        ]
        break
      case "batch_lead":
        links = [
          { label: "ลีดทีคิวเอ็ม", path: path.leadsPath("all") },
          {
            label: "รายละเอียดลีด",
            path: path.leadOrderInfoPath(params.leadId),
          },
          {
            label: "ค้นหาแบบประกัน",
            path: path.productsSearchPath(params.leadId, {
              leadId: params.leadId,
              age: params.age,
              gender: params.gender,
              sumInsured: params.sumInsured,
              type: params.type,
              birthdate: params.birthdate,
              from: params.from,
              opportunityKey: params.opportunityKey,
            }),
          },
          {
            label: "เลือกแบบประกัน",
            path: path.productsPath(params.leadId, {
              age: params.age,
              gender: params.gender,
              type: params.type,
              sumInsured: params.sumInsured,
              leadId: params.leadId,
              birthdate: params.birthdate,
              from: params.from,
              opportunityKey: params.opportunityKey,
            }),
          },
          {
            label: "สรุปแบบประกัน",
          },
        ]
        break
      case "order":
        links = [
          {
            label: "คำสั่งซื้อ",
            path: path.ordersPath(),
          },
          {
            label: "สรุปแบบประกัน",
          },
        ]
        break
      default:
        links = [
          {
            label: "ผลิตภัณฑ์",
            path: "/",
          },
          {
            label: "ค้นหาแบบประกัน",
            path: path.productsSearchPath({
              age: params.age,
              gender: params.gender,
              sumInsured: params.sumInsured,
              type: params.type,
              birthdate: params.birthdate,
              opportunityKey: params.opportunityKey,
            }),
          },
          {
            label: "เลือกแบบประกัน",
            path: path.productsPath({
              age: params.age,
              gender: params.gender,
              type: params.type,
              sumInsured: params.sumInsured,
              birthdate: params.birthdate,
              from: params.from,
              opportunityKey: params.opportunityKey,
            }),
          },
          {
            label: "สรุปแบบประกัน",
          },
        ]
        break
    }
    return { links }
  })
)

export default enhancer(SummaryPage)
