import React from "react"
import styled from "@emotion/styled"
import { css } from "@styled"
import { compose, withHandlers, withProps, defaultProps } from "recompose"
import { withStores, withRouter } from "@enhancers"
import {
  Grid,
  GridColumn,
  makeCell,
  DatePicker,
  Dropdown,
  Checkbox,
  makeHeaderCell,
} from "@components"
import * as path from "@common/path"
import { toCurrency } from "@common/helper"

const Table = styled(Grid)`
  .k-grid-header .k-header {
    font-weight: bold;
  }

  ${(props) =>
    props.isactive
      ? css`
          .k-grid-header .k-header {
            :nth-of-type(7) {
              text-align: end;
            }
            :nth-of-type(8) {
              text-align: end;
            }
            :nth-of-type(9) {
              text-align: end;
            }
          }

          .k-master-row > td {
            :nth-of-type(7) {
              text-align: end;
            }
            :nth-of-type(8) {
              text-align: end;
            }
            :nth-of-type(9) {
              text-align: end;
            }
          }
        `
      : css`
          .k-grid-header .k-header {
            :nth-of-type(7) {
              text-align: end;
            }
            :nth-of-type(8) {
              text-align: end;
            }
            :nth-of-type(9) {
              text-align: end;
            }
          }

          .k-master-row > td {
            :nth-of-type(7) {
              text-align: end;
            }
            :nth-of-type(8) {
              text-align: end;
            }
            :nth-of-type(9) {
              text-align: end;
            }
          }
        `}
`
const CustomCheckbox = styled(Checkbox)`
  .k-item.k-treeview-item {
    height: 26px !important;
    padding-bottom: 5px;
  }
`
const CheckboxTd = styled.td`
  position: sticky;
  left: 0;
  border-right-width: 1px;
  background-color: #ffffff;
`

const ViewAsAffiliate = (props) => {
  return (
    <Table
      loadingTable={props.loadingTable}
      data={props.data}
      pageable
      skip={props.paging.skip}
      take={props.paging.perPage}
      total={props.paging.totalCount}
      onPageChange={props.onPageChange}
      onRowClick={props.onRowClick}
      isactive={props.verification}
    >
      {props.verification && (
        <GridColumn
          title=""
          width="42px"
          locked
          headerCell={makeHeaderCell(CustomCheckbox, () => ({
            value: props.isSelectedAll,
            onChange: (checked) => props.onSelectAll(checked),
          }))}
          cell={(cell) => {
            return (
              <CheckboxTd className={`k-grid-content-sticky ${cell.field}`}>
                <Checkbox
                  value={cell.dataItem.selected}
                  onChange={(checked) => props.onSelectRow(checked, cell.dataItem)}
                />
              </CheckboxTd>
            )
          }}
        />
      )}
      <GridColumn title="ชื่อทีมผู้แนะนำ" field="affiliateTeamName" width="130px" />
      <GridColumn title="พื้นที่" field="areaCode" width="90px" />
      <GridColumn
        title="สาขา"
        width="120px"
        cell={makeCell(Dropdown, (cell) => ({
          readOnly: true,
          value: cell.dataItem.branchCode,
          options: props.branchOptions,
        }))}
      />
      <GridColumn title="รหัสหัวหน้าผู้แนะนำ" field="leaderStaffCode" width="150px" />
      <GridColumn title="ชื่อหัวหน้าผู้แนะนำ" field="leaderName" width="180px" />
      <GridColumn
        title="วันที่รับเงิน (ล่าสุด)"
        cell={makeCell(DatePicker, (cell) => ({
          readOnly: true,
          value: cell.dataItem.receivedDate,
        }))}
        width="140px"
      />
      <GridColumn
        title="ค่าแนะนำรวม"
        cell={(cell) => <td>{toCurrency(cell.dataItem.totalCommissionAmount)}</td>}
        width="120px"
      />
      <GridColumn
        title="ค่าแนะนำจ่ายแล้ว"
        cell={(cell) => <td>{toCurrency(cell.dataItem.totalCommissionAmountPaid)}</td>}
        width="140px"
      />
      <GridColumn
        title="ค่าแนะนำรอทำจ่าย"
        cell={(cell) => <td>{toCurrency(cell.dataItem.totalCommissionAmountWaiting)}</td>}
        width="150px"
      />
    </Table>
  )
}

const enhancer = compose(
  withRouter(),
  withStores((stores) => ({
    paging: stores.saleCommissionStore.paging,
    branchOptions: stores.saleCommissionStore.$("configs.attributes.branch.options", []),
  })),
  defaultProps({
    initialValues: {},
  }),
  withProps((props) => ({
    paging: {
      ...props.paging,
      skip: (props.paging.page - 1) * props.paging.perPage,
    },
  })),
  withHandlers({
    onRowClick: (props) => (event) => {
      if (!props.verification) {
        props.history.push(path.saleCommissionPath(event.dataItem.id, { viewType: "affiliates" }))
      }
    },
  })
)

export default enhancer(ViewAsAffiliate)
