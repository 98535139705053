import React from "react"
import Spinner from "react-spinkit"
import styled from "@styled"
import { compose } from "recompose"

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: ${(props) => (props.fullScreen ? "fixed" : "absolute")};
  background: rgba(255, 255, 255, 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
`

const Title = styled.div`
  margin-bottom: 8px;
`

const LoadingScope = (props) => (
  <Layout
    className={props.className}
    fullScreen={props.fullScreen}
    onClick={(e) => e.stopPropagation()}
  >
    <Title>{props.message || "Loading"}</Title>
    <Spinner name="ball-beat" />
  </Layout>
)

const enhancer = compose()

export default enhancer(LoadingScope)
