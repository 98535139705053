import React from "react"
import { compose, withState, withHandlers } from "recompose"

const enhancer = compose(
  withState("tdId", "setTdId", (props) => `AA${parseInt(Math.random() * 1000000)}`),
  withHandlers({
    onClick: (props) => () => {
      const e = document.querySelector(`.${props.tdId}`)
      e.click()
    },
  })
)

const makeCell = (Component, mapProps) =>
  enhancer(({ className, ...props }) => {
    const { preventClick, ...cellProps } = mapProps(props)
    return (
      <td className={props.tdId} style={cellProps.style}>
        <div onClick={!preventClick ? props.onClick : undefined}>
          <Component {...cellProps} />
        </div>
      </td>
    )
  })

export default makeCell
