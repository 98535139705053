import React, { Fragment } from "react"
import styled from "@emotion/styled"
import { compose, withHandlers, withState } from "recompose"
import { Button, Dialog } from "@components"

const CustomDialog = styled(Dialog)`
  .custom-title {
    font-size: 16px;
    font-weight: bold;
  }

  .k-window-titlebar {
    padding: 0 11px;
  }
`
const Body = styled.div`
  padding: 30px 0px;
`

const ContainerButton = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  > button {
    display: flex;
    justify-content: flex-start;
    width: 230px;
  }
  span {
    margin-right: 20px !important;
    font-size: 20px;
  }
  > button :last-child {
    margin-top: 20px;
  }
`

const LeadMenuModal = (props) => (
  <Fragment>
    <CustomDialog
      title={<p class="custom-title">โปรดเลือกรายการ</p>}
      onClose={() => props.onClose()}
      isShowModal={props.open}
      width="348px"
    >
      <Body>
        <ContainerButton>
          <Button icon="search" onClick={props.gotoLead}>
            ดูลีด/ดึงลีดกลับ
          </Button>
          <Button icon="group" onClick={props.gotoAssignLead}>
            มอบหมายลีด
          </Button>
        </ContainerButton>
      </Body>
    </CustomDialog>{" "}
  </Fragment>
)

const enhancer = compose(
  withState("showSearchModal", "setShowSearchModal", false),
  withHandlers({
    gotoLead: (props) => () => {
      props.gotoLead && props.gotoLead()
    },
    gotoAssignLead: (props) => () => {
      props.gotoAssignLead && props.gotoAssignLead()
    },
  })
)
export default enhancer(LeadMenuModal)
