/* eslint-disable no-dupe-keys */
import React, { Fragment } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { withStores, withRouter } from "@enhancers"
import { reduxForm } from "redux-form"
import {
  Grid,
  GridColumn,
  makeCell,
  LoadingScope,
  FilterButton,
  Button,
  DateInput,
  BrowseButton,
  Dropdown,
  Authorize,
} from "@components"
import { compose, withHandlers, withState, lifecycle, withProps, defaultProps } from "recompose"
import * as path from "@common/path"
import FilterPanel from "./FilterPanel"
import defaultLogo from "@assets/images/img-no-insurance.png"
import { get, isEqual, cloneDeep, isEmpty } from "lodash"
import moment from "moment"
import ExportModal from "./ExportModal"
import { toCurrency, exceptNullKeys, getOptionLabel } from "@common/helper"
import ImportFileModal from "./ImportFileModal"
import { AllLeadContainer, AssignedLeadContainer, RightPanel } from "@pages/leads/index/index"
import { desktopSize } from "@common/styled"

export const Layout = styled.div`
  margin: 60px auto 60px auto;
  width: 100%;
`
const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 12px 0;
  margin-top: 20px;
  justify-content: space-between;
`
const CustomFilterButton = styled(FilterButton)`
  > button {
    width: 143px;
  }
`
const StickyTab = styled.div`
  height: 40px;
  width: 100%;
  position: -webkit-sticky;
  position: fixed;
  top: 60px;
  left: 0px;
  z-index: 3;
  display: ${(props) => (props.shouldDisplayTab ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  background-color: #229ec0;
  @media only screen and (max-width: 768px) {
    padding-left: 80px;
  }
`
const Table = styled(Grid)`
  .k-grid-header {
    .k-header {
      font-weight: bold;
    }
  }

  .k-grid-container {
    .k-grid-content.k-virtual-content {
      > div {
        .k-grid-table {
          > tbody {
            .k-master-row {
              > td {
                cursor: pointer;
              }
            }
            .wrap-text {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
`
const StateButton = styled(Button)`
  width: 96px;
  border: 1px solid #627b8b;
  color: #627b8b;
`
const WrapButton = styled.div`
  display: flex;

  ${(props) =>
    props.expanded &&
    css`
      width: 100%;
      justify-content: flex-end;
    `}

  button {
    color: #627b8b;
    border-color: #627b8b;
    :first-of-type {
      margin-right: 8px;
    }
  }
`
const Logo = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 5px;
  vertical-align: middle;
`
const ExportButton = styled(Button)`
  width: 116px;
  justify-content: space-around;
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
`
const ReportCommissions = styled(Button)`
  min-width: 231px;
  height: 38px;
`
const ComfirmVerifyingCommissions = styled(Button)`
  min-width: 216px;
  height: 38px;
  margin-left: 6px;
`
export const DataContainer = styled.div`
  display: flex;
  height: 40px;
  width: 82%;
`

const InsurerCommission = (props) => {
  return (
    <Fragment>
      {props.loading ? (
        <LoadingScope />
      ) : (
        <Fragment>
          <StickyTab shouldDisplayTab={true}>
            <DataContainer>
              <AllLeadContainer>{`ทั้งหมด ${props.paging.totalCount} คอมมิชชั่น`}</AllLeadContainer>
              <RightPanel>
                <AssignedLeadContainer>{`เปลี่ยนเบี้ยโดย operation ${props.operationChangedCount} คอมมิชชั่น`}</AssignedLeadContainer>
              </RightPanel>
            </DataContainer>
          </StickyTab>
          <Layout>
            <Header>
              <CustomFilterButton
                expanded={props.filterExpanded}
                onExpand={props.onExpandFilter}
                disabled={props.disabledFilter}
              >
                <FilterPanel disabled={props.disabledFilter} />
              </CustomFilterButton>
              <WrapButton expanded={props.filterExpanded}>
                <Button
                  awesomeIcon={props.loadingImport ? "spin" : "upload"}
                  onClick={props.toggleImportModal}
                  children="นำเข้าไฟล์"
                />
                <ExportButton
                  awesomeIcon="file-export"
                  onClick={() => props.setIsShowModal(true)}
                  children="รายงาน"
                />
              </WrapButton>
            </Header>
            <Table
              loading={props.loadingTable}
              data={props.insurerCommissions}
              onRowClick={props.onClickRow}
              pageable={{
                buttonCount: 5,
              }}
              skip={props.paging.skip}
              take={props.paging.perPage}
              total={props.paging.totalCount}
              onPageChange={props.onPageChange}
              paging={props.paging}
            >
              <GridColumn
                field="tqmOrderId"
                title="รหัสคำสั่งซื้อ"
                width="110px"
                cell={(cell) => (
                  <td
                    className="wrap-text"
                    style={
                      cell.dataItem.isOperationChanged
                        ? { backgroundColor: "#DEEAF6" }
                        : { backgroundColor: "#FFF" }
                    }
                  >
                    {cell.dataItem.tqmOrderId}
                  </td>
                )}
              />
              <GridColumn
                field="fullName"
                className="wrap-text"
                title="ชื่อลูกค้า"
                width="155px"
                cell={(cell) => (
                  <td
                    className="wrap-text"
                    style={
                      cell.dataItem.isOperationChanged
                        ? { backgroundColor: "#DEEAF6" }
                        : { backgroundColor: "#FFF" }
                    }
                  >
                    {cell.dataItem.fullName}
                  </td>
                )}
              />
              <GridColumn
                field="customerData"
                className="wrap-text"
                title="ข้อมูลลูกค้า"
                width="160px"
                resizable={true}
                cell={(cell) => (
                  <td
                    style={
                      cell.dataItem.isOperationChanged
                        ? { backgroundColor: "#DEEAF6" }
                        : { backgroundColor: "#FFF" }
                    }
                  >
                    <StateButton
                      onClick={() =>
                        props.history.push(path.orderHolderInfoPath(cell.dataItem.orderId))
                      }
                    >
                      ดูรายละเอียด
                    </StateButton>
                  </td>
                )}
              />
              <GridColumn
                field="policyName"
                title="แบบประกัน"
                width="120px"
                filterable={false}
                cell={(cell) => (
                  <td
                    className="wrap-text"
                    style={
                      cell.dataItem.isOperationChanged
                        ? { backgroundColor: "#DEEAF6" }
                        : { backgroundColor: "#FFF" }
                    }
                  >
                    <Logo src={cell.dataItem.logoPath || defaultLogo} /> {cell.dataItem.policyName}
                  </td>
                )}
              />
              <GridColumn
                field="yearPeriod"
                title="ปี / งวด"
                width="80px"
                resizable={true}
                cell={(cell) => {
                  return (
                    <td
                      style={
                        cell.dataItem.isOperationChanged
                          ? { backgroundColor: "#DEEAF6" }
                          : { backgroundColor: "#FFF" }
                      }
                    >
                      {cell.dataItem.policyYear
                        ? `${cell.dataItem.policyYear}/${cell.dataItem.paymentInstallment}`
                        : ""}
                    </td>
                  )
                }}
              />
              <GridColumn
                field="totalPremium"
                title="เบี้ยสุทธิ"
                width="100px"
                resizable={true}
                cell={(cell) => (
                  <td
                    style={
                      cell.dataItem.isOperationChanged
                        ? { backgroundColor: "#DEEAF6" }
                        : { backgroundColor: "#FFF" }
                    }
                  >
                    {toCurrency(cell.dataItem.totalPremium)}
                  </td>
                )}
              />
              <GridColumn
                field="totalCommission"
                title="ค่านายหน้า"
                width="100px"
                resizable={true}
                cell={(cell) => (
                  <td
                    className="wrap-text"
                    style={
                      cell.dataItem.isOperationChanged
                        ? { backgroundColor: "#DEEAF6" }
                        : { backgroundColor: "#FFF" }
                    }
                  >
                    {cell.dataItem.totalCommission}
                  </td>
                )}
              />
              <GridColumn
                field="totalServiceCharge"
                title="ค่าบริการ"
                width="100px"
                resizable={true}
                cell={(cell) => (
                  <td
                    style={
                      cell.dataItem.isOperationChanged
                        ? { backgroundColor: "#DEEAF6" }
                        : { backgroundColor: "#FFF" }
                    }
                  >
                    {toCurrency(cell.dataItem.totalServiceCharge)}
                  </td>
                )}
              />
              <GridColumn
                field="coverageDate"
                title="วันที่คุ้มครอง"
                width="120px"
                resizable={true}
                cell={makeCell(DateInput, (cellProps) => ({
                  readOnly: true,
                  value: cellProps.dataItem.coverageDate,
                  style: cellProps.dataItem.isOperationChanged
                    ? { backgroundColor: "#DEEAF6" }
                    : { backgroundColor: "#FFF" },
                }))}
              />
              <GridColumn
                field="paymentStatus"
                title="สถานะการชำระเงิน"
                width="155px"
                filterable={false}
                cell={makeCell(Dropdown, (cellProps) => ({
                  options: props.paymentStatusOptions,
                  readOnly: true,
                  value: cellProps.dataItem.paymentStatus,
                  style: cellProps.dataItem.isOperationChanged
                    ? { backgroundColor: "#DEEAF6" }
                    : { backgroundColor: "#FFF" },
                }))}
              />
              <GridColumn
                field="premiumPaidDate"
                title="วันชำระเงิน"
                width="120px"
                resizable={true}
                cell={makeCell(DateInput, (cellProps) => ({
                  readOnly: true,
                  value: cellProps.dataItem.premiumPaidDate,
                  style: cellProps.dataItem.isOperationChanged
                    ? { backgroundColor: "#DEEAF6" }
                    : { backgroundColor: "#FFF" },
                }))}
              />
              <GridColumn
                field="receivedDate"
                title="วันที่รับเงิน"
                width="120px"
                resizable={true}
                cell={makeCell(DateInput, (cellProps) => ({
                  readOnly: true,
                  value: cellProps.dataItem.receivedDate,
                  style: cellProps.dataItem.isOperationChanged
                    ? { backgroundColor: "#DEEAF6" }
                    : { backgroundColor: "#FFF" },
                }))}
              />
              <GridColumn
                field="importedDate"
                title="วันนำเข้าไฟล์"
                width="120px"
                resizable={true}
                cell={makeCell(DateInput, (cellProps) => ({
                  readOnly: true,
                  value: cellProps.dataItem.importedDate,
                  style: cellProps.dataItem.isOperationChanged
                    ? { backgroundColor: "#DEEAF6" }
                    : { backgroundColor: "#FFF" },
                }))}
              />
              <GridColumn
                field="estimatedIncomeExportedDate"
                title="วันที่นำออกประมาณการรายได้"
                width="206px"
                resizable={true}
                cell={makeCell(DateInput, (cellProps) => ({
                  readOnly: true,
                  value: cellProps.dataItem.estimatedIncomeExportedDate,
                  style: cellProps.dataItem.isOperationChanged
                    ? { backgroundColor: "#DEEAF6" }
                    : { backgroundColor: "#FFF" },
                }))}
              />
              <GridColumn
                field="receivedIncomeExportedDate"
                title="วันที่นำออกรายได้รับ"
                width="138px"
                resizable={true}
                cell={makeCell(DateInput, (cellProps) => ({
                  readOnly: true,
                  value: cellProps.dataItem.receivedIncomeExportedDate,
                  style: cellProps.dataItem.isOperationChanged
                    ? { backgroundColor: "#DEEAF6" }
                    : { backgroundColor: "#FFF" },
                }))}
              />
              <GridColumn
                field="remainingIncomeExportedDate"
                title="วันที่นำออกรายได้ค้างรับ"
                width="164px"
                resizable={true}
                cell={makeCell(DateInput, (cellProps) => ({
                  readOnly: true,
                  value: cellProps.dataItem.remainingIncomeExportedDate,
                  style: cellProps.dataItem.isOperationChanged
                    ? { backgroundColor: "#DEEAF6" }
                    : { backgroundColor: "#FFF" },
                }))}
              />
              <GridColumn
                field="policySaleType"
                title="ประเภท"
                width="120px"
                resizable={true}
                cell={(cell) => (
                  <td
                    style={
                      cell.dataItem.isOperationChanged
                        ? { backgroundColor: "#DEEAF6" }
                        : { backgroundColor: "#FFF" }
                    }
                  >
                    {getOptionLabel(props.policySaleTypeOptions, cell.dataItem.policySaleType)}
                  </td>
                )}
              />
              <GridColumn
                field="policyApprovedDate"
                title="วันอนุมัติกรมธรรม์"
                width="122px"
                resizable={true}
                cell={makeCell(DateInput, (cellProps) => ({
                  readOnly: true,
                  value: cellProps.dataItem.policyApprovedDate,
                  style: cellProps.dataItem.isOperationChanged
                    ? { backgroundColor: "#DEEAF6" }
                    : { backgroundColor: "#FFF" },
                }))}
              />
              <GridColumn
                field="policyNumber"
                title="เลขกรมธรรม์"
                width="140px"
                resizable={true}
                cell={(cell) => (
                  <td
                    style={
                      cell.dataItem.isOperationChanged
                        ? { backgroundColor: "#DEEAF6" }
                        : { backgroundColor: "#FFF" }
                    }
                  >
                    {cell.dataItem.policyNumber}
                  </td>
                )}
              />
              <GridColumn
                field="policyStatus"
                title="สถานะกรมธรรม์"
                width="135px"
                resizable={true}
                cell={(cell) => (
                  <td
                    style={
                      cell.dataItem.isOperationChanged
                        ? { backgroundColor: "#DEEAF6" }
                        : { backgroundColor: "#FFF" }
                    }
                  >
                    {cell.dataItem.policyStatus}
                  </td>
                )}
              />
              <GridColumn
                field="history"
                title="ประวัติ"
                width="200px"
                resizable={true}
                cell={(cell) => (
                  <td
                    style={
                      cell.dataItem.isOperationChanged
                        ? { backgroundColor: "#DEEAF6" }
                        : { backgroundColor: "#FFF" }
                    }
                  >
                    <StateButton
                      onClick={() =>
                        props.history.push(path.insurerCommissionHistoryPath(cell.dataItem.id))
                      }
                    >
                      ตรวจสอบ
                    </StateButton>
                  </td>
                )}
              />
              <GridColumn
                field="editingStatus"
                title="การแก้ไข"
                width="120px"
                resizable={true}
                cell={makeCell(Dropdown, (cellProps) => ({
                  options: props.editingStatusOptions,
                  readOnly: true,
                  value: cellProps.dataItem.editingStatus,
                  style: cellProps.dataItem.isOperationChanged
                    ? { backgroundColor: "#DEEAF6" }
                    : { backgroundColor: "#FFF" },
                }))}
              />
              <GridColumn
                field="state"
                title="สถานะ"
                width="110px"
                cell={makeCell(Dropdown, (cellProps) => ({
                  options: props.stateOptions,
                  readOnly: true,
                  value: cellProps.dataItem.state,
                  style: cellProps.dataItem.isOperationChanged
                    ? { backgroundColor: "#DEEAF6" }
                    : { backgroundColor: "#FFF" },
                }))}
              />
            </Table>

            <ButtonContainer>
              <Authorize roles={["member.accountant"]}>
                <WrapButton>
                  <ReportCommissions
                    awesomeIcon="file-export"
                    onClick={props.exportInsurerCommissionForVerify}
                    children="ออกรายงานเพื่อตรวจสอบ"
                    type="disbled"
                  />
                  <ComfirmVerifyingCommissions
                    awesomeIcon="check"
                    onClick={props.gotoCommissionsVerificationPage}
                    children="ยืนยันรายการตรวจสอบ"
                  />
                </WrapButton>
              </Authorize>
              <Authorize roles={["member.accountant_approver"]}>
                <WrapButton>
                  <ComfirmVerifyingCommissions
                    awesomeIcon="check"
                    onClick={props.gotoCommissionEditApprovePage}
                    children="อนุมัติรายการแก้ไข"
                  />
                </WrapButton>
              </Authorize>
            </ButtonContainer>
          </Layout>
        </Fragment>
      )}
      <ExportModal open={props.isShowModal} onClose={props.onCloseAndOpenModal} />
      <ImportFileModal
        open={props.toggleImportValue}
        onClose={props.toggleImportModal}
        onImportFile={props.onImport}
      />
    </Fragment>
  )
}

export const convertStringToDate = (value) => moment(value, "DD/MM/YYYY").toDate()
export const convertDateToString = (value) =>
  moment(value).format("DD/MM/YYYY", {
    locale: "en",
  })

export const validate = (values) => {
  const errors = {}

  const minCoverageDate = get(values, "coverageDate.min")
  const maxCoverageDate = get(values, "coverageDate.max")
  if (minCoverageDate && maxCoverageDate && minCoverageDate > maxCoverageDate) {
    errors.coverageDate = { min: "กรุณากรอกวันที่ให้ถูกต้อง", max: "กรุณากรอกวันที่ให้ถูกต้อง" }
  }

  const minPremiumPaidDate = get(values, "premiumPaidDate.min")
  const maxPremiumPaidDate = get(values, "premiumPaidDate.max")
  if (minPremiumPaidDate && maxPremiumPaidDate && minPremiumPaidDate > maxPremiumPaidDate) {
    errors.premiumPaidDate = { min: "กรุณากรอกวันที่ให้ถูกต้อง", max: "กรุณากรอกวันที่ให้ถูกต้อง" }
  }

  const minreceivedDate = get(values, "receivedDate.min")
  const maxreceivedDate = get(values, "receivedDate.max")
  if (minreceivedDate && maxreceivedDate && minreceivedDate > maxreceivedDate) {
    errors.receivedDate = {
      min: "กรุณากรอกวันที่ให้ถูกต้อง",
      max: "กรุณากรอกวันที่ให้ถูกต้อง",
    }
  }
  const minPolicyApprovedDate = get(values, "policyApprovedDate.min")
  const maxPolicyApprovedDate = get(values, "policyApprovedDate.max")
  if (
    minPolicyApprovedDate &&
    maxPolicyApprovedDate &&
    minPolicyApprovedDate > maxPolicyApprovedDate
  ) {
    errors.policyApprovedDate = {
      min: "กรุณากรอกวันที่ให้ถูกต้อง",
      max: "กรุณากรอกวันที่ให้ถูกต้อง",
    }
  }

  const minEstimatedIncomeExportedDate = get(values, "estimatedIncomeExportedDate.min")
  const maxEstimatedIncomeExportedDate = get(values, "estimatedIncomeExportedDate.max")
  if (
    minEstimatedIncomeExportedDate &&
    maxEstimatedIncomeExportedDate &&
    minEstimatedIncomeExportedDate > maxEstimatedIncomeExportedDate
  ) {
    errors.estimatedIncomeExportedDate = {
      min: "กรุณากรอกวันที่ให้ถูกต้อง",
      max: "กรุณากรอกวันที่ให้ถูกต้อง",
    }
  }

  const minReceivedIncomeExportedDate = get(values, "receivedIncomeExportedDate.min")
  const maxReceivedIncomeExportedDate = get(values, "receivedIncomeExportedDate.max")
  if (
    minReceivedIncomeExportedDate &&
    maxReceivedIncomeExportedDate &&
    minReceivedIncomeExportedDate > maxReceivedIncomeExportedDate
  ) {
    errors.receivedIncomeExportedDate = {
      min: "กรุณากรอกวันที่ให้ถูกต้อง",
      max: "กรุณากรอกวันที่ให้ถูกต้อง",
    }
  }
  const minRemainingIncomeExportedDate = get(values, "remainingIncomeExportedDate.min")
  const maxRemainingIncomeExportedDate = get(values, "remainingIncomeExportedDate.max")
  if (
    minRemainingIncomeExportedDate &&
    maxRemainingIncomeExportedDate &&
    minRemainingIncomeExportedDate > maxRemainingIncomeExportedDate
  ) {
    errors.remainingIncomeExportedDate = {
      min: "กรุณากรอกวันที่ให้ถูกต้อง",
      max: "กรุณากรอกวันที่ให้ถูกต้อง",
    }
  }

  if (!isEmpty(errors)) {
    errors._error = errors
  }

  return errors
}

export const exceptNullParams = (params) => {
  return exceptNullKeys(
    params,
    "insuranceCompanyId",
    "policySaleType",
    "tqmOrderId",
    "customerName",
    "state",
    "editingStatus",
    "coverageDate.min",
    "coverageDate.max",
    "premiumPaidDate.min",
    "premiumPaidDate.max",
    "receivedDate.min",
    "receivedDate.max",
    "policyApprovedDate.min",
    "policyApprovedDate.max",
    "estimatedIncomeExportedDate.min",
    "estimatedIncomeExportedDate.max",
    "receivedIncomeExportedDate.min",
    "receivedIncomeExportedDate.max",
    "remainingIncomeExportedDate.min",
    "remainingIncomeExportedDate.max"
  )
}

const enhancer = compose(
  withRouter(),
  withState("loading", "setLoading", false),
  withState("loadingTable", "setLoadingTable", false),
  withState("isShowModal", "setIsShowModal", false),
  withState("toggleImportValue", "setToggleImportValue", false),
  withState(
    "reportInsurerCommissionModalVisibility",
    "setReportInsurerCommissionModalVisibility",
    false
  ),
  withStores((stores) => ({
    fetchInsurerCommissions: stores.insurerCommissionStore.fetch,
    fetchConfigs: stores.insurerCommissionStore.fetchConfigs,
    insurerCommissions: stores.insurerCommissionStore.all,
    paging: stores.insurerCommissionStore.paging,
    operationChangedCount: stores.insurerCommissionStore.operationChangedCount,
    stateOptions: stores.insurerCommissionStore.$("configs.attributes.state.options", []),
    paymentStatusOptions: stores.insurerCommissionStore.$(
      "configs.attributes.paymentStatus.options",
      []
    ),
    policySaleTypeOptions: stores.insurerCommissionStore.$(
      "configs.attributes.policySaleType.options",
      []
    ),
    importInsurerCommission: stores.insurerCommissionStore.import,
    exportInsuranceCommissionFile: stores.insurerCommissionStore.exportInsuranceCommissionFile,
    currentRole: stores.appStore.currentRole,
    editingStatusOptions: stores.insurerCommissionStore.$(
      "configs.attributes.editingStatus.options",
      []
    ),
  })),
  defaultProps({
    initialValues: {},
  }),
  withState("errors", "setErrors", null),
  withState("fetchInsurerCommissionsTask", "setFetchInsurerCommissionsTask", null),
  withHandlers({
    gotoCommissionsVerificationPage: (props) => () => {
      props.history.push(path.commissionVerification({ ...props.location.query }))
    },
    gotoCommissionEditApprovePage: (props) => () => {
      props.history.push(path.commissionEditApprove())
    },
    exportInsurerCommissionForVerify: (props) => async (state) => {
      try {
        const params = exceptNullParams(props.location.query)
        delete params.state

        await props.exportInsuranceCommissionFile(params)
        props.setReportInsurerCommissionModalVisibility(
          !props.reportInsurerCommissionModalVisibility
        )
      } catch (e) {
        window.alert("ไม่สามารถออกรายงานเพื่อตรวจสอบได้")
      }
    },
    onUpdateFilterParams: (props) => async (values, delay = 0) => {
      const filterParams = cloneDeep(values)
      props.setLoadingTable(true)

      if (get(filterParams, "coverageDate.min")) {
        filterParams.coverageDate.min = convertDateToString(filterParams.coverageDate.min)
      }
      if (get(filterParams, "coverageDate.max")) {
        filterParams.coverageDate.max = convertDateToString(filterParams.coverageDate.max)
      }
      if (get(filterParams, "premiumPaidDate.min")) {
        filterParams.premiumPaidDate.min = convertDateToString(filterParams.premiumPaidDate.min)
      }
      if (get(filterParams, "premiumPaidDate.max")) {
        filterParams.premiumPaidDate.max = convertDateToString(filterParams.premiumPaidDate.max)
      }
      if (get(filterParams, "receivedDate.min")) {
        filterParams.receivedDate.min = convertDateToString(filterParams.receivedDate.min)
      }
      if (get(filterParams, "receivedDate.max")) {
        filterParams.receivedDate.max = convertDateToString(filterParams.receivedDate.max)
      }
      if (get(filterParams, "policyApprovedDate.min")) {
        filterParams.policyApprovedDate.min = convertDateToString(
          filterParams.policyApprovedDate.min
        )
      }
      if (get(filterParams, "policyApprovedDate.max")) {
        filterParams.policyApprovedDate.max = convertDateToString(
          filterParams.policyApprovedDate.max
        )
      }
      if (get(filterParams, "estimatedIncomeExportedDate.min")) {
        filterParams.estimatedIncomeExportedDate.min = convertDateToString(
          filterParams.estimatedIncomeExportedDate.min
        )
      }
      if (get(filterParams, "estimatedIncomeExportedDate.max")) {
        filterParams.estimatedIncomeExportedDate.max = convertDateToString(
          filterParams.estimatedIncomeExportedDate.max
        )
      }
      if (get(filterParams, "receivedIncomeExportedDate.min")) {
        filterParams.receivedIncomeExportedDate.min = convertDateToString(
          filterParams.receivedIncomeExportedDate.min
        )
      }
      if (get(filterParams, "receivedIncomeExportedDate.max")) {
        filterParams.receivedIncomeExportedDate.max = convertDateToString(
          filterParams.receivedIncomeExportedDate.max
        )
      }
      if (get(filterParams, "remainingIncomeExportedDate.min")) {
        filterParams.remainingIncomeExportedDate.min = convertDateToString(
          filterParams.remainingIncomeExportedDate.min
        )
      }
      if (get(filterParams, "remainingIncomeExportedDate.max")) {
        filterParams.remainingIncomeExportedDate.max = convertDateToString(
          filterParams.remainingIncomeExportedDate.max
        )
      }
      if (props.fetchInsurerCommissionsTask) {
        clearTimeout(props.fetchInsurerCommissionsTask)
      }
      await props.setFetchInsurerCommissionsTask(
        setTimeout(() => {
          props.history.push(path.insurerCommissionsPath(filterParams))
        }, delay)
      )
      props.setLoadingTable(false)
    },
  }),
  reduxForm({
    form: "InsurerCommissionForm",
    validate,
    onChange: (filters, _, props) => {
      props.onUpdateFilterParams(filters, 400)
    },
  }),
  withProps((props) => ({
    paging: {
      ...props.paging,
      skip: (props.paging.page - 1) * props.paging.perPage,
    },
  })),
  withState("loadingImport", "setLoadingImport", false),
  withState("filterExpanded", "setFilterExpanded", false),
  withHandlers({
    toggleImportModal: (props) => () => {
      props.setToggleImportValue(!props.toggleImportValue)
    },
    onExpandFilter: (props) => (expanded) => {
      props.setFilterExpanded(expanded)
    },
    onCloseAndOpenModal: (props) => () => {
      props.setIsShowModal(!props.isShowModal)
    },
    onImport: (props) => async (values) => {
      props.setLoadingImport(true)
      try {
        await props.importInsurerCommission(values)
        props.setToggleImportValue(!props.toggleImportValue)
        await props.fetchInsurerCommissions()
      } catch (e) {
        alert("นำเข้าไฟล์ไม่สำเร็จ")
      }
      props.setLoadingImport(false)
    },
  }),
  withProps((props) => {
    const filterParams = cloneDeep(props.location.query)
    if (
      get(filterParams, "coverageDate.max") &&
      get(filterParams, "coverageDate.min") &&
      filterParams.coverageDate.min > filterParams.coverageDate.max
    ) {
      delete filterParams.coverageDate
    }
    if (
      get(filterParams, "premiumPaidDate.max") &&
      get(filterParams, "premiumPaidDate.min") &&
      filterParams.premiumPaidDate.min > filterParams.premiumPaidDate.max
    ) {
      delete filterParams.premiumPaidDate
    }
    if (
      get(filterParams, "receivedDate.max") &&
      get(filterParams, "receivedDate.min") &&
      filterParams.receivedDate.min > filterParams.receivedDate.max
    ) {
      delete filterParams.receivedDate
    }
    if (
      get(filterParams, "policyApprovedDate.max") &&
      get(filterParams, "policyApprovedDate.min") &&
      filterParams.policyApprovedDate.min > filterParams.policyApprovedDate.max
    ) {
      delete filterParams.policyApprovedDate
    }
    if (
      get(filterParams, "estimatedIncomeExportedDate.max") &&
      get(filterParams, "estimatedIncomeExportedDate.min") &&
      filterParams.estimatedIncomeExportedDate.min > filterParams.estimatedIncomeExportedDate.max
    ) {
      delete filterParams.estimatedIncomeExportedDate
    }
    if (
      get(filterParams, "receivedIncomeExportedDate.max") &&
      get(filterParams, "receivedIncomeExportedDate.min") &&
      filterParams.receivedIncomeExportedDate.min > filterParams.receivedIncomeExportedDate.max
    ) {
      delete filterParams.receivedIncomeExportedDate
    }
    if (
      get(filterParams, "remainingIncomeExportedDate.max") &&
      get(filterParams, "remainingIncomeExportedDate.min") &&
      filterParams.remainingIncomeExportedDate.min > filterParams.remainingIncomeExportedDate.max
    ) {
      delete filterParams.remainingIncomeExportedDate
    }
    if (get(filterParams, "coverageDate.min")) {
      filterParams.coverageDate.min = convertStringToDate(filterParams.coverageDate.min)
    }
    if (get(filterParams, "coverageDate.max")) {
      filterParams.coverageDate.max = convertStringToDate(filterParams.coverageDate.max)
    }
    if (get(filterParams, "premiumPaidDate.min")) {
      filterParams.premiumPaidDate.min = convertStringToDate(filterParams.premiumPaidDate.min)
    }
    if (get(filterParams, "premiumPaidDate.max")) {
      filterParams.premiumPaidDate.max = convertStringToDate(filterParams.premiumPaidDate.max)
    }
    if (get(filterParams, "receivedDate.min")) {
      filterParams.receivedDate.min = convertStringToDate(filterParams.receivedDate.min)
    }
    if (get(filterParams, "receivedDate.max")) {
      filterParams.receivedDate.max = convertStringToDate(filterParams.receivedDate.max)
    }
    if (get(filterParams, "policyApprovedDate.min")) {
      filterParams.policyApprovedDate.min = convertStringToDate(filterParams.policyApprovedDate.min)
    }
    if (get(filterParams, "policyApprovedDate.max")) {
      filterParams.policyApprovedDate.max = convertStringToDate(filterParams.policyApprovedDate.max)
    }
    if (get(filterParams, "estimatedIncomeExportedDate.min")) {
      filterParams.estimatedIncomeExportedDate.min = convertStringToDate(
        filterParams.estimatedIncomeExportedDate.min
      )
    }
    if (get(filterParams, "estimatedIncomeExportedDate.max")) {
      filterParams.estimatedIncomeExportedDate.max = convertStringToDate(
        filterParams.estimatedIncomeExportedDate.max
      )
    }
    if (get(filterParams, "receivedIncomeExportedDate.min")) {
      filterParams.receivedIncomeExportedDate.min = convertStringToDate(
        filterParams.receivedIncomeExportedDate.min
      )
    }
    if (get(filterParams, "receivedIncomeExportedDate.max")) {
      filterParams.receivedIncomeExportedDate.max = convertStringToDate(
        filterParams.receivedIncomeExportedDate.max
      )
    }
    if (get(filterParams, "remainingIncomeExportedDate.min")) {
      filterParams.remainingIncomeExportedDate.min = convertStringToDate(
        filterParams.remainingIncomeExportedDate.min
      )
    }
    if (get(filterParams, "remainingIncomeExportedDate.max")) {
      filterParams.remainingIncomeExportedDate.max = convertStringToDate(
        filterParams.remainingIncomeExportedDate.max
      )
    }
    if (filterParams.tqmOrderId && filterParams.tqmOrderId.length < 4) {
      delete filterParams.tqmOrderId
    }
    if (filterParams.customerName && filterParams.customerName.length < 4) {
      delete filterParams.customerName
    }

    return { filterParams }
  }),
  withHandlers({
    onClickRow: (props) => (data) => {
      props.history.push(path.insurerCommissionPath(data.dataItem.id))
    },
    onPageChange: (props) => (event) => {
      const page = event.page.skip / event.page.take + 1
      props.history.push(path.insurerCommissionsPath({ ...props.location.query, page }))
    },
  }),
  lifecycle({
    async componentDidMount() {
      this.props.setLoading(true)
      await Promise.all([
        this.props.fetchConfigs(),
        this.props.fetchInsurerCommissions(exceptNullParams(this.props.filterParams)),
      ])
      this.props.initialize(this.props.filterParams)
      this.props.setLoading(false)
    },
    async componentDidUpdate(prevProps) {
      if (!isEqual(this.props.filterParams, prevProps.filterParams)) {
        this.props.setLoadingTable(true)
        await this.props.fetchInsurerCommissions(exceptNullParams(this.props.filterParams))
        this.props.setLoadingTable(false)
      }
    },
  })
)

export default enhancer(InsurerCommission)
