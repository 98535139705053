/* eslint-disable no-dupe-keys */
import React, { Fragment } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { withStores, withRouter, withForm } from "@enhancers"
import {
  Grid,
  GridColumn,
  makeCell,
  Button,
  Checkbox,
  BreadCrumb,
  DateInput,
  Dropdown,
  FilterButton,
  makeHeaderCell,
  LoadingScope,
} from "@components"
import { compose, withHandlers, withState, lifecycle, withProps } from "recompose"
import * as path from "@common/path"
import SuccessModal from "./SuccessModal"
import ExportFilterPanel from "./ExportFilterPanel"
import { isEqual, cloneDeep } from "lodash"
import { validate, Layout } from "./index"

const BreadCrumbLayout = styled.div``
const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 12px 0;
  margin-top: 20px;
  justify-content: space-between;
`
const CustomFilterButton = styled(FilterButton)`
  > button {
    width: 143px;
  }
`
const WrapButton = styled.div`
  display: flex;

  ${(props) =>
    props.expanded &&
    css`
      width: 100%;
      justify-content: flex-end;
    `}

  button {
    color: #627b8b;
    border-color: #627b8b;
    :first-of-type {
      margin-right: 8px;
    }
  }
`
const BackButton = styled(Button)`
  width: 116px;
`
const ReportButton = styled(Button)`
  width: 180px;
`
const Table = styled(Grid)`
  .k-grid-container {
    .k-grid-content.k-virtual-content {
      > div {
        .k-grid-table {
          > tbody {
            .k-master-row {
              height: 50px;
              > td {
                cursor: pointer;
                padding-top: 0px !important;
                padding-bottom: 0px !important;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
            .k-master-row.k-alt {
              > td {
                cursor: pointer;
                padding-top: 0px !important;
                padding-bottom: 0px !important;
              }
            }
          }
        }
      }
    }
  }
  .wrap-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
const CustomTd = styled.td`
  position: sticky;
  left: 0;
  padding: 12px;
  border-right-width: 1px;
  background-color: #ffffff;
`

const InsurerCommissionsReportPreparation = (props) => (
  <Layout>
    <BreadCrumbLayout>
      <BreadCrumb
        links={[
          {
            label: "คอมมิชชั่นรับ",
            path: path.insurerCommissionsPath(),
          },
          {
            label: "ออกรายงานคอมมิชชั่นรับ",
            path: path.insurerCommissionsReportPreparationPath(),
          },
        ]}
      />
    </BreadCrumbLayout>
    <Header>
      <CustomFilterButton
        expanded={props.filterExpanded}
        onExpand={props.onExpandFilter}
        disabled={props.disabledFilter}
      >
        <ExportFilterPanel disabled={props.disabledFilter} incomeType={props.incomeType} />
      </CustomFilterButton>
      <WrapButton expanded={props.filterExpanded}>
        <BackButton
          icon="arrow-chevron-left"
          onClick={props.onBack}
          children="ย้อนกลับ"
          styletype="secondary"
        />
        <ReportButton
          awesomeIcon="file-export"
          onClick={props.onReport}
          children="ยืนยันออกรายงาน"
          disabled={props.insurerCommissions.length === 0}
        />
      </WrapButton>
    </Header>
    <Table loadingTable={props.loadingTable} data={props.insurerCommissions} total={1} pageable>
      <GridColumn
        title=""
        headerCell={makeHeaderCell(Checkbox, () => ({
          value: props.isSelectedAll,
          onChange: (checked) => props.onSelectAll(checked),
        }))}
        width="42px"
        locked
        cell={(cellProps) => {
          return (
            <CustomTd className={`k-grid-content-sticky`}>
              <Checkbox
                value={cellProps.dataItem.selected}
                onChange={(checked) => props.onSelectRow(checked, cellProps.dataItem)}
              />
            </CustomTd>
          )
        }}
      />
      <GridColumn field="tqmOrderId" title="รหัสคำสั่งซื้อ" width="108px" />
      <GridColumn field="fullName" title="ชื่อลูกค้า" width="170px" />
      <GridColumn field="insurerRefCode" title="สมุดคุม" width="74" />
      <GridColumn field="insuranceCompanyName" title="บริษัทประกัน" width="160" />
      <GridColumn field="policyNumber" title="เลขกรมธรรม์" width="112px" />
      <GridColumn
        title="วันที่คุ้มครอง"
        cell={makeCell(DateInput, (cellProps) => ({
          readOnly: true,
          value: cellProps.dataItem.coverageDate,
        }))}
        width="108px"
      />
      <GridColumn
        title="วันทีแจ้งงาน"
        cell={makeCell(DateInput, (cellProps) => ({
          readOnly: true,
          value: cellProps.dataItem.submittedAt,
        }))}
        width="108px"
      />
      <GridColumn field="paymentViaTqm" title="ชำระเงินผ่านTQM" width="145px" />
      <GridColumn field="paymentViaIns" title="ชำระเงินผ่านINS" width="137px" />
      <GridColumn field="totalPremium" title="เบี้ยสุทธิ" width="106px" />
      <GridColumn field="totalCommission" title="ค่านายหน้า" width="99px" />
      <GridColumn field="totalServiceCharge" title="ค่าบริการ" width="89px" />
      <GridColumn
        title="วันที่ชำระเงิน"
        cell={makeCell(DateInput, (cellProps) => ({
          readOnly: true,
          value: cellProps.dataItem.premiumPaidDate,
        }))}
        width="112px"
      />
      <GridColumn field="policyName" title="แบบประกัน" width="177px" />
      <GridColumn field="insuranceType" title="ประเภท" width="116px" />
      <GridColumn
        field="yearPeriod"
        title="ปี/งวด"
        cell={(cell) => {
          return (
            <td>
              {cell.dataItem.policyYear
                ? `${cell.dataItem.policyYear}/${cell.dataItem.paymentInstallment}`
                : ""}
            </td>
          )
        }}
        width="85px"
      />
      <GridColumn
        title="ประเภทการชำระเงิน"
        cell={makeCell(Dropdown, (cellProps) => ({
          readOnly: true,
          value: cellProps.dataItem.paymentMethod,
          options: props.paymentMethodOptions,
        }))}
        width="162px"
      />
    </Table>
    <SuccessModal
      open={props.openSuccessModel}
      onClose={props.closeSuccessModel}
      data={props.reportData}
    />
  </Layout>
)

const enhancer = compose(
  withRouter(),
  withState("selectedLeadIds", "setSelectedLeadIds", []),
  withState("fetchLeadTask", "setFetchLeadTask", null),
  withState("openSuccessModel", "setOpenSuccessModel", false),
  withState("filterExpanded", "setFilterExpanded", true),
  withState("loadingTable", "setLoadingTable", false),
  withState("fetchInsurerCommissionsTask", "setFetchInsurerCommissionsTask", null),
  withStores((stores) => ({
    fetchCommissions: stores.insurerCommissionStore.fetch,
    insurerCommissions: stores.insurerCommissionStore.all,
    exportCommission: stores.insurerCommissionStore.export,
  })),
  withProps((props) => {
    const incomeType = props.location.query.incomeType
    let insurerCommissions = props.insurerCommissions
    insurerCommissions.forEach(
      (commission) => (commission.selected = props.selectedLeadIds.includes(commission.id))
    )
    return {
      insurerCommissions,
      incomeType,
    }
  }),
  withProps((props) => {
    const reportDate = props.location.query.exportedAt || ""
    let dataType = ""
    if (props.location.query.incomeType === "estimated_income") {
      dataType = "ประมาณการรายได้"
    } else if (props.location.query.incomeType === "received_income") {
      dataType = "รายได้รับ"
    } else {
      dataType = "รายได้ค้างรับ"
    }
    const fileName =
      dataType +
      (reportDate.split("/")[0] || "") +
      (reportDate.split("/")[1] || "") +
      (reportDate.split("/")[2] || "")

    const reportData = {
      reportDate: reportDate,
      dataType: dataType,
      fileName: fileName,
    }
    return { reportData }
  }),
  withProps((props) => {
    const paymentMethodOptions = [
      {
        label: "Bill Payment",
        value: "bill_payment",
      },
      {
        label: "บัตรเครดิต",
        value: "credit_card",
      },
      {
        label: "บัตรเครดิต ครั้งแรกเงินสด",
        value: "credit_card_cash",
      },
      {
        label: "Direct Debit ครั้งแรกเงินสด",
        value: "direct_debit_cash",
      },
      {
        label: "เงินสด",
        value: "cash",
      },
    ]

    const isSelectedAll =
      props.insurerCommissions.length > 0
        ? props.insurerCommissions.every((row) => row.selected)
        : false

    return { paymentMethodOptions, isSelectedAll }
  }),

  withHandlers({
    onSelectRow: (props) => (value, dataItem) => {
      if (value) {
        props.setSelectedLeadIds([...props.selectedLeadIds, dataItem.id])
      } else {
        props.setSelectedLeadIds(props.selectedLeadIds.filter((id) => id !== dataItem.id))
      }
    },
    onBack: (props) => () => {
      props.history.push(path.insurerCommissionsPath())
    },
    closeSuccessModel: (props) => () => {
      props.setOpenSuccessModel(!props.openSuccessModel)
      props.history.push(path.insurerCommissionsPath())
    },
    onReport: (props) => async () => {
      try {
        await props.exportCommission({
          exportedDate: props.location.query.exportedAt,
          incomeType: props.location.query.incomeType,
          insurerCommissionIds: props.selectedLeadIds,
        })
        props.setOpenSuccessModel(true)
      } catch (e) {
        window.alert("ออกรายงานไม่สำเร็จ")
      }
    },
    onExpandFilter: (props) => (expanded) => {
      props.setFilterExpanded(expanded)
    },
    onUpdateFilterParams: (props) => async (values, delay = 0) => {
      const filterParams = cloneDeep(values)
      props.setLoadingTable(true)
      if (props.fetchInsurerCommissionsTask) {
        clearTimeout(props.fetchInsurerCommissionsTask)
      }
      await props.setFetchInsurerCommissionsTask(
        setTimeout(async () => {
          await props.fetchCommissions({
            page: 1,
            perPage: 10000,
            incomeType: props.incomeType,
            ...filterParams,
          })
          props.setLoadingTable(false)
        }, delay)
      )
    },
    onSelectAll: (props) => (selected) => {
      if (selected) {
        const insurerCommissionIds = props.insurerCommissions.map((commission) => commission.id)
        props.setSelectedLeadIds(insurerCommissionIds)
      } else {
        props.setSelectedLeadIds([])
      }
    },
  }),
  withForm({
    form: "InsurerCommissionReportPreparationForm",
    validate,
    onChange: (filters, _, props) => {
      props.onUpdateFilterParams(filters, 400)
    },
  }),
  lifecycle({
    async componentDidMount() {
      this.props.setLoadingTable(true)
      if (this.props.incomeType) {
        const insurerCommissions = await this.props.fetchCommissions({
          page: 1,
          perPage: 10000,
          incomeType: this.props.incomeType,
        })
        const insurerCommissionIds = insurerCommissions.map((commission) => commission.id)
        this.props.setSelectedLeadIds(insurerCommissionIds)
        this.props.setLoadingTable(false)
      }
    },
    componentDidUpdate(prevProps) {
      if (!isEqual(this.props.insurerCommissions, prevProps.insurerCommissions)) {
        const insurerCommissionIds = this.props.insurerCommissions.map(
          (commission) => commission.id
        )
        this.props.setSelectedLeadIds(insurerCommissionIds)
      }
    },
  })
)

export default enhancer(InsurerCommissionsReportPreparation)
