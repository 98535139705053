import React, { Fragment, useState, useCallback } from "react"
import styled from "@styled"
import { Button, FilterButton, Switch, Case } from "@components/index"
import FilterPanel from "./FilterPanel"
import { compose } from "recompose"
import { withHooks, withStores, withRouter } from "@enhancers/index"
import * as path from "@common/path"
import AssignModal from "./AssignModal"
import SalePanel from "./SalePanel"
import AssignedSuccessModal from "./AssignedSuccessModal"

const Layout = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 12px 0;
  margin-top: 15px;
`
const ButtonGroup = styled.div`
  display: ${(props) => (props.disabled ? "none" : "flex")};

  flex: 1;
  justify-content: flex-end;

  > *:not(:nth-of-type(1)) {
    margin-left: 10px;
  }
`
const UnAssignButton = styled(Button)`
  width: 151px;
`
const AssignButton = styled(Button)`
  width: 130px;
`
const UnAssignToTeamButton = styled(Button)`
  width: 108px;
  border-color: #627b8b;
  color: #627b8b;
`
const CreateLeadButton = styled(Button)`
  width: 130px;
`

const Header = (props) => (
  <Layout>
    <FilterButton
      expanded={props.filterExpandedValue}
      onExpand={props.expandFilter}
      disabled={props.disabledFilter}
    >
      <FilterPanel
        disabled={props.disabledFilter}
        disabledStateFilter={props.disabledStateFilter}
        mode={props.mode}
      />
    </FilterButton>
    <ButtonGroup>
      <Switch>
        <Case when={props.mode === null}>
          {props.batchId === "custom" ? (
            <CreateLeadButton
              awesomeIcon="plus"
              children="สร้างลีด"
              styletype="normal"
              onClick={props.createLead}
              authorize={{
                roles: ["member.salesman", "member.affiliate", "member.team_leader"],
              }}
            />
          ) : (
            <Fragment>
              <UnAssignButton
                icon="undo"
                onClick={props.unassignLead}
                children="ดึงลีดกลับ"
                authorize={{
                  roles: ["user.admin", "member.lead_admin"],
                }}
                styletype="secondary"
              />
              <UnAssignToTeamButton
                icon="undo"
                onClick={props.unassignLeadToTeam}
                children="โยกลีด"
                authorize={{
                  roles: ["user.admin", "member.team_leader"],
                }}
              />
              <AssignButton
                awesomeIcon="user"
                onClick={props.assignLead}
                children="มอบหมาย"
                authorize={{
                  roles: ["user.admin", "member.lead_admin", "member.team_leader"],
                }}
                styletype="normal"
              />
            </Fragment>
          )}
        </Case>
        <Case
          when={["deleting", "assigning", "unAssigning", "unAssigningToTeam"].includes(props.mode)}
        >
          <Button icon="cancel" onClick={props.cancel} children="ยกเลิก" styletype="delete" />
          <Button
            icon="check"
            onClick={props.confirm}
            children="ยืนยัน"
            styletype="agree"
            disabled={props.selectedLeadIds.length === 0}
          />
        </Case>
      </Switch>
    </ButtonGroup>
    {props.toggleAssignModalValue && props.currentRole !== "member.team_leader" && (
      <AssignModal
        form="LeadModalFilterForm"
        toggleValue={props.toggleAssignModalValue}
        setToggleModalValue={props.setToggleAssignModalValue}
      />
    )}
    <SalePanel
      open={props.toggleSalePanelValue}
      onCancel={props.toggleSalePanel}
      onSubmit={props.confirmSalePanel}
      selectedLeadIds={props.selectedLeadIds}
    />
    <AssignedSuccessModal
      modalTitle="มอบหมายลีด"
      infoCaption="มอบหมายลีดสำเร็จ"
      buttonTitle="ปิด"
      open={props.toggleAssignedSuccessModalValue}
      onClose={props.toggleAssignedSuccessModal}
      details={props.successAssignDetail}
    />
  </Layout>
)

const enhancer = compose(
  withRouter(),
  withStores((stores, props) => ({
    batchId: props.match.params.batchId,
    query: props.location.query,
    mode: props.location.query.mode,
    currentRole: stores.appStore.currentRole,
    fetchLeads: stores.leadStore.fetch,
    deleteLeads: stores.leadStore.deleteAll,
    assignLeads: stores.leadStore.assign,
    unassignLeads: stores.leadStore.unassign,
    unassignLeadsToTeam: stores.leadStore.unassignToTeam,
  })),
  withHooks((props) => {
    const [filterExpandedValue, setFilterExpandedValue] = useState(false)
    const [toggleAssignModalValue, setToggleAssignModalValue] = useState(false)
    const [toggleSalePanelValue, setToggleSalePanelValue] = useState(false)
    const [toggleAssignedSuccessModalValue, setToggleAssignedSuccessModalValue] = useState(false)
    const [successAssignDetail, setSuccessAssignDetail] = useState({})

    const expandFilter = useCallback(() => {
      setFilterExpandedValue(!filterExpandedValue)
    })

    const toggleSalePanel = useCallback(() => {
      setToggleSalePanelValue(!toggleSalePanelValue)
    })

    const toggleAssignedSuccessModal = useCallback(() => {
      setToggleAssignedSuccessModalValue(!toggleAssignedSuccessModalValue)
    })

    const createLead = useCallback(() => {
      props.history.push(path.newLeadPath({ batchId: props.batchId }))
    })

    const assignLead = useCallback(async () => {
      if (props.currentRole !== "member.team_leader") {
        setToggleAssignModalValue(true)
        return
      }

      props.history.push(
        path.leadsPath(props.batchId, {
          ...props.query,
          state: { eq: "team_assigned" },
          mode: "assigning",
          page: 1,
          perPage: 10000,
        })
      )
    })

    const confirm = useCallback(async () => {
      switch (props.mode) {
        case "deleting":
          await props.deleteLeads({ leadIds: props.selectedLeadIds })
          break
        case "assigning":
          setToggleSalePanelValue(!toggleSalePanelValue)
          break
        case "unAssigning":
          await props.unassignLeads(props.selectedLeadIds)
          props.history.push(path.batchLeadsPath(props.batchId))
          break
        case "unAssigningToTeam":
          try {
            await props.unassignLeadsToTeam(props.selectedLeadIds)
            props.history.push(path.leadsPath(props.batchId))
          } catch (error) {
            alert(error.message || "Error")
          }
          break
        default:
          break
      }
    })

    const confirmSalePanel = useCallback(
      async (selectedSaleIds, selectedSalesName, selectedTeamIds, options) => {
        const selectedLeadIds = props.selectedLeadIds

        try {
          await props.assignLeads(selectedLeadIds, selectedSaleIds, selectedTeamIds, options)
          setToggleSalePanelValue(!toggleSalePanelValue)

          let selectedTeamsName = []
          if (selectedTeamIds.length > 0) {
            selectedTeamsName = selectedTeamIds.map(
              (selectedSaleId) => props.teams.find((team) => team.id === selectedSaleId).name
            )
          }

          setSuccessAssignDetail({
            selectedLeads: selectedLeadIds,
            salesLeader: {
              caption: "พนักงานขาย",
              values: selectedSalesName,
              haveValues: selectedSalesName.length > 0,
            },
            sales:
              {
                caption: "พนักงานขาย",
                values: selectedSalesName,
                haveValues: selectedSalesName.length > 0,
              } || {},
            teams:
              {
                caption: "ทีม",
                values: selectedTeamsName,
                haveValues: selectedTeamsName.length > 0,
              } || {},
          })
          setToggleAssignedSuccessModalValue(!toggleAssignedSuccessModalValue)
          if (props.currentRole === "member.lead_admin") {
            props.history.push(path.batchLeadsPath(props.batchId))
          } else {
            props.history.push(path.leadsPath(props.batchId))
          }
        } catch (e) {
          alert("ไม่สามารถมอบหมายได้สำเร็จเนื่องจากไม่พบหัวหน้าทีม")
        }
      }
    )

    const unassignLead = useCallback(async () => {
      props.history.push(
        path.batchLeadsPath(props.batchId, {
          ...props.query,
          state: { eq: "team_assigned" },
          mode: "unAssigning",
          page: 1,
          perPage: 10000,
        })
      )
    })

    const unassignLeadToTeam = useCallback(() => {
      props.history.push(
        path.leadsPath(props.batchId, {
          ...props.query,
          state: { eq: "assigned" },
          mode: "unAssigningToTeam",
          page: 1,
          perPage: 10000,
        })
      )
    })

    const cancel = useCallback(() => {
      if (props.currentRole === "member.team_leader") {
        props.history.push(path.leadsPath(props.batchId))
      } else {
        props.history.push(path.batchLeadsPath(props.batchId))
      }
    })

    return {
      filterExpandedValue,
      expandFilter,
      createLead,
      assignLead,
      unassignLead,
      confirmSalePanel,
      unassignLeadToTeam,

      toggleAssignModalValue,
      setToggleAssignModalValue,
      toggleAssignedSuccessModalValue,
      toggleAssignedSuccessModal,
      toggleSalePanelValue,
      toggleSalePanel,

      mode: props.mode || null,
      confirm,
      cancel,
      successAssignDetail,
    }
  })
)

export default enhancer(Header)
