import React from "react"
import styled from "@emotion/styled"
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout"

const Layout = styled.div`
  flex: 1;
  width: 100%;
`
const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
`
const Title = styled.div`
  width: 350px;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
`
const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 130px;
`
const Detail = styled.div`
  display: flex;
  justify-content: flex-end;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
`

const Hs = (props) => (
  <Layout>
    {props.riderPolicyDetail.protectionDetail !== undefined && (
      <PanelBar>
        <PanelBarItem expanded={true} title="วงเงินสูงสุดต่อการนอนโรงพยาบาลใน 1 ครั้ง">
          {props.riderPolicyDetail.protectionDetail.general.map((general) => (
            <ContentContainer>
              <Title>{general.label}</Title>
              <DetailContainer>
                {general.displayValue.map((displayValue) => (
                  <Detail>{displayValue}</Detail>
                ))}
              </DetailContainer>
            </ContentContainer>
          ))}
        </PanelBarItem>
        <PanelBarItem expanded={true} title="ความคุ้มครองค่ารักษาพยาบาลผู้ป่วยใน (IPD)">
          {props.riderPolicyDetail.protectionDetail.ipd.map((ipd) => (
            <ContentContainer>
              <Title>{ipd.label}</Title>
              <DetailContainer>
                {ipd.displayValue.map((displayValue) => (
                  <Detail>{displayValue}</Detail>
                ))}
              </DetailContainer>
            </ContentContainer>
          ))}
        </PanelBarItem>
        <PanelBarItem expanded={true} title="ความคุ้มครองค่ารักษาพยาบาลผู้ป่วยนอก (OPD)">
          {props.riderPolicyDetail.protectionDetail.opd.map((opd) => (
            <ContentContainer>
              <Title>{opd.label}</Title>
              {opd.displayValue.map((displayValue) => (
                <Detail>{displayValue}</Detail>
              ))}
            </ContentContainer>
          ))}
        </PanelBarItem>
        <PanelBarItem expanded={true} title="ความคุ้มครองอื่นๆ">
          {props.riderPolicyDetail.protectionDetail.others.map((others) => (
            <ContentContainer>
              <Title>{others.label}</Title>
              {others.displayValue.map((displayValue) => (
                <Detail>{displayValue}</Detail>
              ))}
            </ContentContainer>
          ))}
        </PanelBarItem>
      </PanelBar>
    )}
  </Layout>
)

export default Hs
