import React, { useEffect } from "react"
import { compose, withProps } from "recompose"
import * as path from "@common/path"
import styled from "@emotion/styled"
import { Link, Header as TextHeader } from "@components"
import { withStores, withRouter, withHooks } from "@enhancers/index"
import { matchPath } from "react-router"
import { get, findKey } from "lodash"
import { rolesInfo } from "@stores/mobx/appStore"
import ProfileMenu from "./ProfileMenu"
import tqmLogo from "@assets/images/tqm_life_logo.jpg"
import { desktopSize } from "@common/styled"
import SidebarMenu from "./SidebarMenu"

const MainLayout = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  height: 60px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10001;
`
const Layout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  /* max-width: 724px; */
  padding: 0px 22px;

  ${(props) =>
    desktopSize(`
    // max-width: 1123px;
    // padding: 0px 22px;
    max-width: 100%;
    padding: 0 40px;
  `)}
`
const LeftPanel = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-start;
  > div {
    display: block;
    width: 225px;
    font-family: "ThaiSans Neue";
    font-size: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const CustomLink = styled(Link)`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
`
const TitleLink = styled(Link)`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-start;
  font-family: "ThaiSans Neue";
  font-weight: bold;
  color: #666666;
  font-size: 30px;
  width: 100%;

  > div {
    > span {
      font-size: 20px;
    }
  }

  > div:first-of-type {
    margin-right: 7px;
  }

  > div:nth-of-type(2) {
    display: block;
    width: 200px;
    white-space: nowrap;
  }

  > div {
    display: flex;
    align-items: center;
    font-family: "ThaiSans Neue";
    font-weight: bold;
    color: #666666;
    font-size: 30px;
  }

  /* ${(props) =>
    desktopSize(`
      display: none !important;
  `)} */
`

const Logo = styled.img`
  display: flex;
  align-items: center;
  height: 40px;
`
const Title = styled(TextHeader)`
  font-family: "ThaiSans Neue";
  font-size: 30px;
  color: #666666;
  display: flex;
  align-items: center;
`

const Header = (props) => (
  <MainLayout id="header">
    <Layout>
      <LeftPanel>{props.TitleComponent}</LeftPanel>
      <CustomLink to={path.rootPath()}>
        <Logo src={tqmLogo} />
      </CustomLink>
      <ProfileMenu />
    </Layout>
  </MainLayout>
)

const titleMappings = {
  แบทช์: [{ path: path.batchesPath(), exact: true }],
  ลีดทีคิวเอ็ม: [{ path: path.leadsPath("all"), exact: true }],
  ลีดผู้แนะนำ: [{ path: path.leadPoolsPath(), exact: true }],
  ลีดส่วนตัว: [{ path: path.leadsPath("custom"), exact: true }],
  ลีด: [{ path: path.leadsPath(":batchId") }],
  รายละเอียดลีด: [{ path: path.leadPath(":leadId") }],
  รายละเอียดลีดแบทช์: [{ path: path.batchLeadsPath(":batchId") }],
  คำสั่งซื้อ: [{ path: path.ordersPath(), exact: true }],
  รายละเอียดคำสั่งซื้อ: [{ path: path.orderPath(":orderId") }],
  เปรียบเทียบแบบประกัน: [
    { path: path.productsComparePath(":leadId") },
    { path: path.productsComparePath() },
  ],
  คำนวณเบี้ย: [{ path: path.productCustomEditPath(":productId") }],
  รายละเอียด: [
    { path: path.productCustomShowDetailPath(":productId") },
    { path: path.productPackageShowDetailPath(":packageId") },
  ],
  สรุปแบบประกัน: [{ path: path.productCustomPath(":productId"), exact: true }],
  ค้นหาแบบประกัน: [
    { path: path.productsSearchPath(), exact: true },
    { path: path.productsSearchPath(":type") },
  ],
  เลือกผลิตภัณฑ์: [{ path: path.productsPath(":leadId") }],
  รายละเอียดแพ็กเกจ: [{ path: path.productPackagePath(":packageId"), exact: true }],
  ผลิตภัณฑ์: [{ path: path.productsPath(), exact: true }],
  บริษัทประกัน: [
    { path: path.insurersPath(), exact: true },
    { path: path.insurerPath(":insurerId") },
  ],
  แบบประกันชีวิต: [{ path: path.lifeInsurancePoliciesPath(), exact: true }],
  สัญญาเพิ่มเติม: [{ path: path.healthInsurancePoliciesPath(), exact: true }],
  คอมมิชชั่น: [{ path: path.commissisonPath(), exact: true }],
  ตั้งค่าสัญญาเพิ่มเติม: [
    { path: path.ridersPath(), exact: true },
    { path: path.newRiderPath(), exact: true },
    { path: path.riderPath(":riderId"), exact: true },
  ],
  จัดการแพ็กเก็จ: [
    { path: path.packagesPath(), exact: true },
    { path: path.newPackagePath(), exact: true },
    { path: path.packagePath(":packageId"), exact: true },
  ],
  การบริหารจัดการทีม: [
    { path: path.teamsPath(), exact: true },
    { path: path.newTeamPath(), exact: true },
    { path: path.teamPath(":teamId"), exact: true },
  ],
  วางแผนแบบประกัน: [
    { path: path.planningPath(), exact: true },
    { path: path.planningTypePath(":type") },
  ],
  ตั้งค่าคอมมิชชั่นรับ: [
    { path: path.commissionMappingsPath(), exact: true },
    { path: path.newCommissionMappingPath(), exact: true },
    {
      path: path.commissionMappingPath(":commissionId"),
      exact: true,
    },
  ],
  การแจ้งเตือน: [{ path: path.notificationPath(), exact: true }],
  จัดการผู้ใช้ของทีคิวเอ็ม: [
    { path: path.tqmMembersPath(), exact: true },
    { path: path.newTqmMembersPath(), exact: true },
    { path: path.tqmMemberPath(":id") },
  ],
  คอมมิชชั่นรับ: [{ path: path.insurerCommissionsPath(), exact: true }],
  คอมมิชชั่นจ่าย: [{ path: path.saleCommissionsPath(), exact: true }],
  ตั้งค่าการแก้ไข: [{ path: path.commissionEditSettingPath(), exact: true }],
  ออกรายงานคอมมิชชั่นรับ: [{ path: path.insurerCommissionsReportPreparationPath() }],
  ออกรายงานคอมมิชชั่นจ่าย: [{ path: path.saleCommissionsReportPreparationPath() }],
  ประวัติการแก้ไข: [
    { path: path.insurerCommissionHistoryPath(":insurerCommissionId"), exact: true },
  ],
  รายละเอียดคอมมิชชั่น: [
    { path: path.insurerCommissionPath(":insurerCommissionId") },
    { path: path.saleCommissionPath(":id") },
  ],
  ตั้งค่าวิธีจ่ายเงิน: [
    { path: path.paymentMappingsPath(), exact: true },
    { path: path.paymentMappingPath(":id"), exact: true },
  ],
  "ตั้งค่าวิธีจ่ายเงิน(แพ็กเกจ)": [
    { path: path.packagePaymentMappingsPath(), exact: true },
    { path: path.packagePaymentMappingPath(":id"), exact: true },
  ],
  ยืนยันรายการตรวจสอบ: [{ path: path.commissionVerification(), exact: true }],
  // [`สรุปยอดขาย ${moment().format("DD/MM/YYYY")}`]: [{ path: path.dashboardPath(), exact: true }],
  // [`สถิติ ${moment().format("YYYY")}`]: [{ path: path.statsPath(), exact: true }],
  แดชบอร์ด: [
    { path: path.dashboardPath(), exact: true },
    { path: path.dashboardAffPath(), exact: true },
    { path: path.statsPath(), exact: true },
    { path: path.dashboardInsuranceCompanyPath(), exact: true },
    { path: path.dashboardInsurancePolicyPath(), exact: true },
    { path: path.dashboardTeamPath(), exact: true },
    { path: path.dashboardSalesmanPath(), exact: true },
    { path: path.dashboardPaymentPeriodPath(), exact: true },
    { path: path.dashboardAffInsuranceCompanyPath(), exact: true },
    { path: path.dashboardAffInsurancePolicyPath(), exact: true },
    { path: path.dashboardAffTeamPath(), exact: true },
    { path: path.dashboardAffSalesmanPath(), exact: true },
    { path: path.dashboardAffPaymentPeriodPath(), exact: true },
    { path: path.dashboardInsurerCommissionPath(), exact: true },
    { path: path.dashboardOrdersCommissionPath(), exact: true },
  ],
  จัดการแผนการตลาด: [
    { path: path.marketPlansPath(), exact: true },
    { path: path.newMarketPlanPath(), exact: true },
    { path: path.marketPlanPath(":id"), exact: true },
  ],
  คำนวณโบนัส: [
    { path: path.bonusCalculationPath(), exact: true },
    { path: path.showBonusCalculationPath(":id"), exact: true },
  ],
}

const enhancer = compose(
  withRouter(),
  withStores((stores, props) => ({
    currentRole: stores.appStore.currentRole,
    roles: stores.appStore.roles,
  })),
  withHooks((props) => {
    useEffect(() => {
      if (
        sessionStorage.getItem("fromAGT") === "true" &&
        sessionStorage.getItem("fromPortal") === "true"
      ) {
      } else if (sessionStorage.getItem("fromAGT") === "true") {
        document.getElementById("header").style.display = "none"
      }
    }, [])
  }),
  withProps((props) => {
    const namePath = findKey(titleMappings, (items) =>
      items.find((item) =>
        matchPath(`${props.location.pathname}`, {
          path: item.path,
          exact: item.exact,
        })
      )
    )

    let title = ""
    const rolesOption = props.roles.map((role) => ({
      name: get(rolesInfo, `${role}.name`) || role,
      value: role,
    }))
    title = rolesOption.find((role) => role.value === props.currentRole)
      ? rolesOption.find((role) => role.value === props.currentRole).name
      : ""

    let TitleComponent
    if (namePath) {
      TitleComponent = (
        <>
          <SidebarMenu namePath={namePath} />
          {/* <TitleLink to={path.rootPath()}>
            <div>
              <Icon awesomeIcon="home" />
            </div>
            <div>{namePath}</div>
          </TitleLink> */}
        </>
      )
    }

    return { TitleComponent: TitleComponent, title: title, rolesOption }
  })
)

export default enhancer(Header)
