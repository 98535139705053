import React from "react"
import styled from "@emotion/styled"
import { compose, withHandlers } from "recompose"
import { Button, Field, CurrencyInput, Tooltip, GenderInput, DatePicker } from "@components"
import { withForm, withRouter, withStores } from "@enhancers/index"
import * as path from "@common/path"
import { toCurrency, convertNaNtoZero, convertMinustoZero } from "@common/helper"
import {
  ValueResult,
  ResultContainer,
  TextResult,
  Footer,
  Layout,
  WrapInputDate,
  AgeLabel,
} from "../DebtProtectionForm/SimpleForm"
import moment from "moment"

const Body = styled.div`
  display: flex;
  justify-content: flex-start;

  flex-wrap: wrap;
  > div {
    :nth-of-type(odd) {
      display: flex;
      justify-content: space-between;
      width: 80%;
      margin-top: 16px;
      > div {
        > div {
          line-height: 24px;
        }
      }
    }
    :nth-of-type(even) {
      margin-top: 16px;
      display: flex;
      align-items: center;
      margin-left: 6px;
    }
  }

  width: 100%;
`

const UntiContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 18%;
`
const Unit = styled.div`
  margin-right: 4px;
`

const ConfirmButton = styled(Button)`
  width: fit-content;
  align-self: flex-end;
  margin: auto;
  margin-top: 20px;
`

const Planning = (props) => (
  <Layout>
    <Body>
      <Field
        component={GenderInput}
        name="gender"
        caption="เพศ"
        rightWidth="173"
        placeholder="กรุณาระบุ"
        validate="required"
      />
      <Unit />
      <WrapInputDate>
        {props.values.birthdate && (
          <AgeLabel show={props.values.birthdate !== null}>
            (อายุ {moment().diff(moment(props.values.birthdate), "years")} ปี)
          </AgeLabel>
        )}
        <Field
          caption="วันเกิด"
          name="birthdate"
          component={DatePicker}
          placeholder="กรุณาระบุ"
          validate="required"
          rightWidth="173"
          leftWidth="225"
          max={new Date()}
        />
      </WrapInputDate>
      <Unit />
      <Field
        component={CurrencyInput}
        name="preparedExpense"
        caption="ค่าใช้จ่ายจำเป็นที่ควรเตรียมไว้"
        rightWidth="173"
        placeholder="กรุณาระบุ"
        min={0}
      />
      <Unit>บาท/เดือน</Unit>
      <Field
        component={CurrencyInput}
        name="expectedMoney"
        caption="เงินได้ประจำที่คาดว่าจะได้รับ"
        rightWidth="173"
        placeholder="กรุณาระบุ"
        min={0}
      />
      <UntiContainer>
        <Unit>บาท/เดือน</Unit>
        <Tooltip position="right" anchorElement="target">
          <span class="k-icon k-i-information" title="ตั้งแต่เกษียณจนอายุคาดหมาย" />
        </Tooltip>
      </UntiContainer>
    </Body>
    <Footer>
      <ResultContainer>
        <TextResult>
          เงินบำนาญที่คุณต้องการน้อยกว่าขั้นต่ำของแบบประกันที่สามารถทำได้โดยทั่วไป
          เราจะค้นหาแบบประกันให้คุณโดยเลือกจากเงินบำนาญขั้นต่ำที่ 60,000 บาท/ปี แทน
        </TextResult>
        <ValueResult>
          {toCurrency(
            convertMinustoZero(convertMinustoZero(convertNaNtoZero(props.values.finalSummary)))
          )}{" "}
          บาท
        </ValueResult>
      </ResultContainer>
      <ConfirmButton
        icon="arrow-right"
        onClick={props.onSubmit}
        disabled={props.values.finalSummary < 0}
      >
        ถัดไป
      </ConfirmButton>
    </Footer>
  </Layout>
)

const enhancer = compose(
  withRouter({
    queryTypes: {
      birthdate: "date",
    },
  }),
  withStores((stores, props) => ({
    fetchById: stores.leadStore.fetchById,
    initialValues: {
      birthdate: props.location.query.birthdate,
      gender: props.location.query.gender,
    },
  })),
  withForm({
    form: "planningRetireSimpleForm",

    onChange: (values, _, props) => {
      let preparedExpenseCal = convertNaNtoZero(values.preparedExpense)
      let expectedMoneyCal = convertNaNtoZero(values.expectedMoney)

      values.finalSummary = convertNaNtoZero((preparedExpenseCal - expectedMoneyCal) * 12)
    },
  }),
  withHandlers({
    onSubmit: (props) =>
      props.handleSubmit(async (values) => {
        let finalSummary = convertNaNtoZero(values.finalSummary)

        const { query } = props.location || {}
        const { leadId } = query || {}

        props.history.push(
          path.productsPath(leadId, {
            ...props.location.query,
            birthdate: values.birthdate,
            gender: values.gender,
            searchValue: finalSummary || 60000,
            type: "whole_life",
            searchBy: "sum_insured",
          })
        )
      }),
  })
)
export default enhancer(Planning)
