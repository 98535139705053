// import { Card as RiderCard } from "./Card"
import React from "react"
import { compose, withProps } from "recompose"
import styled from "@emotion/styled"
import { Field, CurrencyInput, CurrencyInputWithPecision } from "@components/index"
import { FieldContainer, RemarkContainer, Premium, Remain } from "./CommissionTabDetail"
import { Avatar } from "@components/index"
import { displayNumber } from "@common/helper"

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 20px 24px 20px;
  border: 1px solid #eeeef2;
  border-radius: 4px;
  background-color: #fff;
  margin-top: 10px;

  > div:last-of-type {
    > div:last-of-type {
      margin-bottom: 0px;
    }
  }
`
const Row = styled.div`
  display: flex;
`
const Caption = styled.div`
  color: #666666;
  font-size: 16px;
  font-family: Sarabun, sans-serif;
  font-weight: bold;
  min-height: 38px;
  > div:first-of-type {
    margin-right: 10px;
  }
  margin-bottom: 5px;
`
const CustomInput = styled(CurrencyInput)`
  > div:first-of-type {
    width: 152px;
  }
  > div:last-of-type {
    width: 150px;
    > div {
      max-width: 220px;
      width: 100%;
    }
    > input {
      max-width: 220px;
      width: 100%;
    }
  }
`
const CustomCurrencyInputWithPecision = styled(CurrencyInputWithPecision)`
  width: auto;

  > div:first-of-type {
    width: 152px;
  }
  > div:last-of-type {
    width: 170px;
    > div {
      max-width: 220px;
      width: 100%;
    }
    > input {
      max-width: 220px;
      width: 100%;
    }
  }
`
const UnitContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 10px;
`

const RiderCard = (props) => (
  <Layout>
    <Caption>
      {props.logoPath ? <Avatar src={props.logoPath} size="38" /> : null}
      {props.policyName}
    </Caption>

    <Row>
      <FieldContainer>
        <Field
          name={`${props.data}.yearlyPremium`}
          caption="เบี้ยรวมทั้งปี"
          component={CustomInput}
          readOnly={props.defaultReadOnly}
          leftWidth="152"
          unit="บาท"
          precision={2}
        />
      </FieldContainer>
    </Row>

    <Row>
      <FieldContainer isReceived={props.isCommissionIsReceivedOrApprove}>
        <Field
          name={`${props.data}.premium`}
          caption="เบี้ยสุทธิ"
          component={CustomInput}
          readOnly={props.defaultReadOnly}
          leftWidth="152"
          unit="บาท"
          precision={2}
        />
        {/* {props.isCommissionIsReceivedOrApprove ? (
          <RemarkContainer>
            <Premium>{`*รับจริง ${
              displayNumber(parseFloat(props.realPremium)) || "-"
            } บาท`}</Premium>
            <Remain>{`คงเหลือ ${
              displayNumber(
                parseFloat(props.initialValues.premium || 0).toFixed(2) -
                  parseFloat(props.realPremium).toFixed(2)
              ) || "-"
            } บาท`}</Remain>
          </RemarkContainer>
        ) : null} */}
      </FieldContainer>
    </Row>

    <Row>
      <FieldContainer isReceived={props.isCommissionIsReceivedOrApprove}>
        <Field
          name={`${props.data}.commissionAmount`}
          caption="ค่านายหน้า"
          component={CustomCurrencyInputWithPecision}
          readOnly={props.readOnly}
          leftWidth="152"
          unit="บาท"
          precision={2}
        />
        {!props.readOnly && (
          <UnitContainer>
            <Premium>บาท</Premium>
          </UnitContainer>
        )}
        {/* {props.isCommissionIsReceivedOrApprove ? (
          <RemarkContainer>
            <Premium>{`*รับจริง ${
              displayNumber(parseFloat(props.realCommissionAmount)) || "-"
            } บาท`}</Premium>
            <Remain>{`คงเหลือ ${
              displayNumber(
                parseFloat(props.initialValues.commissionAmount || 0).toFixed(2) -
                  parseFloat(props.realCommissionAmount || 0).toFixed(2)
              ) || "-"
            } บาท`}</Remain>
          </RemarkContainer>
        ) : null} */}
      </FieldContainer>
    </Row>

    <Row>
      <FieldContainer isReceived={props.isCommissionIsReceivedOrApprove}>
        <Field
          name={`${props.data}.serviceCharge`}
          caption="ค่าบริการ"
          component={CustomCurrencyInputWithPecision}
          readOnly={props.readOnly}
          leftWidth="152"
          unit="บาท"
          precision={2}
        />
        {!props.readOnly && (
          <UnitContainer>
            <Premium>บาท</Premium>
          </UnitContainer>
        )}
        {/* {props.isCommissionIsReceivedOrApprove ? (
          <RemarkContainer>
            <Premium>{`*รับจริง ${
              displayNumber(parseFloat(props.realServiceCharge)) || "-"
            } บาท`}</Premium>
            <Remain>{`คงเหลือ ${
              displayNumber(
                parseFloat(props.initialValues.serviceCharge || 0).toFixed(2) -
                  parseFloat(props.realServiceCharge || 0).toFixed(2)
              ) || "-"
            } บาท`}</Remain>
          </RemarkContainer>
        ) : null} */}
      </FieldContainer>
    </Row>
  </Layout>
)

const enhance = compose(
  withProps((props) => {
    const { policyName, realPremium, realCommissionAmount, realServiceCharge } =
      props.initialValues || {}

    return {
      policyName,
      realPremium,
      realCommissionAmount,
      realServiceCharge,
    }
  })
)

export default enhance(RiderCard)
