import React from "react"
import styled from "@emotion/styled"
import { Field, Dropdown, CurrencyInput } from "@components"

const Layout = styled.div``
const Container = styled.div`
  justify-content: flex-end;
  > div {
    margin-top: 16px;
  }
  > div:nth-of-type(1) {
    margin-top: 0;
  }
`

const PolicyComponent = (props) => (
  <Layout>
    <Container>
      <Field
        caption="แบบประกันชีวิต"
        name="policyId"
        component={Dropdown}
        options={props.policyOptions}
        readOnly={props.readOnly}
        disabled={props.disabled}
        placeholder="กรุณาเลือก"
        validate="required"
        leftWidth="150"
        rightWidth={props.readOnly ? "480" : "245"}
        onChange={() => {
          props.change("riders", [{}])
          props.change("policySumInsured", null)
        }}
        key={JSON.stringify(props.policyOptions)}
      />
      <Field
        caption="ทุนประกัน"
        name="policySumInsured"
        component={CurrencyInput}
        readOnly={props.readOnly}
        disabled={props.disabled}
        placeholder="โปรดระบุ"
        leftWidth="150"
        rightWidth="245"
        validate="required"
      />
      <Field
        caption="คอมมิชชั่น"
        name="policyCommission"
        component={Dropdown}
        options={props.commissionOptions}
        readOnly={props.readOnly}
        validate="required"
        disabled={props.disabled}
        placeholder="กรุณาเลือก"
        leftWidth="150"
        rightWidth={props.readOnly ? "480" : "245"}
      />
    </Container>
  </Layout>
)

export default PolicyComponent
