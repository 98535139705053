import React from "react"
import { withProps, compose, withHandlers } from "recompose"
import styled from "@emotion/styled"
import Dropdown from "../Dropdown"
import Button from "../Button"

const Layout = styled.div``

const FilterLayout = styled.div`
  margin-left: 0.375rem;
  margin-right: 1px;
  min-width: 33.5px;
`

const DropdownFilterCellComponent = (props) => (
  <Layout className="k-filtercell">
    <Dropdown options={props.options} onChange={props.onChange} value={props.value} />
    <FilterLayout className="k-filtercell-wrapper">
      <Button
        icon="filter-clear"
        title="Clear"
        onClick={props.onClearButtonClick}
        hidden={!props.value}
      />
    </FilterLayout>
  </Layout>
)

const enhancer = compose(
  withHandlers({
    onChange: (props) => (value, event) => {
      const hasValue = !!value
      props.onChange({
        value: hasValue ? value : "",
        operator: hasValue ? "eq" : "",
        syntheticEvent: event.syntheticEvent,
      })
    },
    onClearButtonClick: (props) => (event) => {
      event.preventDefault()
      props.onChange({
        value: "",
        operator: "",
        syntheticEvent: event,
      })
    },
  })
)

const DropdownFilterCell = enhancer(DropdownFilterCellComponent)

DropdownFilterCell.withProps = (props) => withProps(() => props)(DropdownFilterCell)

DropdownFilterCell.withOptions = (options) => DropdownFilterCell.withProps({ options })

export default DropdownFilterCell
