import React, { useMemo } from "react"
import styled from "@emotion/styled"
import { compose, lifecycle, withHandlers, withProps, withState } from "recompose"
import { withHooks, withRouter, withStores } from "@enhancers/index"
import { Icon, GridColumn, Authorize } from "@components"
import { isEmpty } from "lodash"
import { exceptNullKeys } from "@common/helper"
import {
  Layout,
  TitleText,
  Body,
  Header,
  HeaderColumn,
  ReportButton,
  CustomTable as Table,
} from "./InsuranceCompany"
import {
  InsuranceCompany as InsuranceCompanyContainer,
  CompanyLogo,
} from "../index/InfoCard/InsuranceCompanyCard"
import { CatagoryText } from "../index/InfoCard/InsurancePolicyCard"
import FilterPanel from "./FilterPanel"
import FilterDate from "../index/FilterDate"

const CustomTable = styled(Table)`
  .k-header:nth-of-type(2) > div > div {
    right: 180px !important;
  }

  .k-master-row > td {
    text-align: end;

    :nth-of-type(1) {
      text-align: start;
      padding: 0 12px;
    }

    :nth-of-type(2) {
      text-align: start;
      padding: 0 12px;
    }

    :nth-of-type(3) {
      text-align: start;
      padding: 0 12px;
    }
  }
`

const InsurancePolicy = (props) => (
  <Layout>
    <TitleText>
      {props.dashboardType === "affiliate"
        ? "ยอดขายแยกตามแบบประกันจากผู้แนะนำ"
        : "ยอดขายแยกตามแบบประกัน"}
    </TitleText>
    <Body>
      <Header>
        <FilterDate onSubmit={props.onDateFilterSubmit} />
        {props.dashboardType !== "affiliate" ? (
          <Authorize roles={["member.biz_dev", "member.admin", "member.brokerage_admin"]}>
            <ReportButton icon="print" onClick={props.onExport} children="ออกรายงาน" />
          </Authorize>
        ) : null}
      </Header>
      <CustomTable
        data={props.insurancePolicy}
        pageable
        skip={props.paging.skip}
        take={props.paging.perPage}
        total={props.paging.totalCount}
        onPageChange={props.onPageChange}
        loadingTable={props.isLoading}
        filterIndex={props.filterIndex}
      >
        <GridColumn
          title="บริษัทประกัน"
          field="companyName"
          cell={(cell) => {
            return (
              <td>
                <InsuranceCompanyContainer>
                  <CompanyLogo src={cell.dataItem.companyLogo} />
                  <CatagoryText>{cell.dataItem.companyName}</CatagoryText>
                </InsuranceCompanyContainer>
              </td>
            )
          }}
          columnMenu={(columnProps) => (
            <FilterPanel
              {...columnProps}
              onSubmit={props.filterChange}
              options={props.insuranceCompanyOptions}
              initialValues={props.filterTableColumn1}
            />
          )}
          width="135"
        />
        <GridColumn
          title="แบบประกัน"
          field="policyName"
          columnMenu={(columnProps) => (
            <FilterPanel
              {...columnProps}
              onSubmit={props.filterChange}
              options={props.insurancePolicyOptions}
              initialValues={props.filterTableColumn2}
            />
          )}
          width="300"
        />
        <GridColumn
          title="สถานะแบบประกัน"
          field="policyState"
          cell={(cell) => {
            return (
              <td>
                <CatagoryText color={cell.dataItem.policyState.color}>
                  {cell.dataItem.policyState.state}
                </CatagoryText>
              </td>
            )
          }}
          columnMenu={(columnProps) => (
            <FilterPanel
              {...columnProps}
              onSubmit={props.filterChange}
              options={props.insurancePolicyStateOptions}
              initialValues={props.filterTableColumn3}
            />
          )}
          width="173"
        />
        <GridColumn
          title={
            <HeaderColumn
              onClick={props.sortingChange(
                "salable_premium",
                props.sortSalablePremium,
                props.setSortSalablePremium
              )}
              isactive={props.sortSalablePremium !== null}
            >
              ยอดเบี้ยที่ขายได้
              <div>
                <Icon awesomeIcon="sort" />
              </div>
            </HeaderColumn>
          }
          field="salablePremium"
          width="166"
        />
        <GridColumn
          title={
            <HeaderColumn
              onClick={props.sortingChange(
                "rejected_premium",
                props.sortRejectedPremium,
                props.setSortRejectedPremium
              )}
              isactive={props.sortRejectedPremium !== null}
            >
              ยอดเบี้ยที่ยกเลิก
              <div>
                <Icon awesomeIcon="sort" />
              </div>
            </HeaderColumn>
          }
          field="rejectedPremium"
          width="163"
        />
        <GridColumn
          title={
            <HeaderColumn
              onClick={props.sortingChange(
                "total_premium",
                props.sortTotalPremium,
                props.setSortTotalPremium
              )}
              isactive={props.sortTotalPremium !== null}
            >
              ยอดเบี้ยรวม
              <div>
                <Icon awesomeIcon="sort" />
              </div>
            </HeaderColumn>
          }
          field="totalPremium"
          width="136"
        />
        <GridColumn
          title={
            <HeaderColumn
              onClick={props.sortingChange(
                "salable_policy",
                props.sortSalablePolicy,
                props.setSortSalablePolicy
              )}
              isactive={props.sortSalablePolicy !== null}
            >
              กรมธรรม์ที่ขายได้
              <div>
                <Icon awesomeIcon="sort" />
              </div>
            </HeaderColumn>
          }
          field="salablePolicy"
          width="174"
        />
        <GridColumn
          title={
            <HeaderColumn
              onClick={props.sortingChange(
                "rejected_policy",
                props.sortRejectedPolicy,
                props.setSortRejectedPolicy
              )}
              isactive={props.sortRejectedPolicy !== null}
            >
              กรมธรรม์ที่ยกเลิก
              <div>
                <Icon awesomeIcon="sort" />
              </div>
            </HeaderColumn>
          }
          field="rejectedPolicy"
          width="174"
        />
        <GridColumn
          title={
            <HeaderColumn
              onClick={props.sortingChange(
                "total_policy",
                props.sortTotalPolicy,
                props.setSortTotalPolicy
              )}
              isactive={props.sortTotalPolicy !== null}
            >
              กรมธรรม์รวม
              <div>
                <Icon awesomeIcon="sort" />
              </div>
            </HeaderColumn>
          }
          field="totalPolicy"
          width="145"
        />
      </CustomTable>
    </Body>
  </Layout>
)

const enhancer = compose(
  withRouter(),
  withStores((stores) => ({
    fetchInsurancePolicy: stores.dashboardStore.fetchInsurancePolicy,
    fetchConfig: stores.dashboardStore.fetchConfig,
    insurancePolicy: stores.dashboardStore.insurancePolicy,
    paging: stores.dashboardStore.paging,
    export: stores.dashboardStore.exportProductReport,
    insuranceCompanyOptions: stores.dashboardStore.$("configs.filter.insuranceCompany.options", []),
    insurancePolicyOptions: stores.dashboardStore.$("configs.filter.insurancePolicy.options", []),
    insurancePolicyStateOptions: stores.dashboardStore.$(
      "configs.filter.insurancePolicyState.options",
      []
    ),
  })),
  withState("isLoading", "setIsLoading", false),

  withState("sortSalablePremium", "setSortSalablePremium", null),
  withState("sortRejectedPremium", "setSortRejectedPremium", null),
  withState("sortTotalPremium", "setSortTotalPremium", null),
  withState("sortSalablePolicy", "setSortSalablePolicy", null),
  withState("sortRejectedPolicy", "setSortRejectedPolicy", null),
  withState("sortTotalPolicy", "setSortTotalPolicy", null),
  withState("filterIndex", "setFilterIndex", null),
  withState("exportFilter", "setExportFilter", null),
  withState("filterTable", "setFilterTable", null),
  withState("filterTableColumn1", "setFilterTableColumn1", {}),
  withState("filterTableColumn2", "setFilterTableColumn2", {}),
  withState("filterTableColumn3", "setFilterTableColumn3", {}),
  withProps((props) => ({
    paging: {
      ...props.paging,
      skip: (props.paging.page - 1) * props.paging.perPage,
    },
    defaultFilterDate: {
      periodType: "yearly",
      dashboardType: props.history.location.pathname.includes("affiliate") ? "affiliate" : null,
      submittedAt: {
        min: new Date(new Date().getFullYear(), 0, 1),
        max: new Date(new Date().getFullYear(), 11, 31),
      },
    },
    dashboardType: props.history.location.pathname.includes("affiliate") ? "affiliate" : null,
  })),
  withHandlers({
    resetFiltering: (props) => () => {
      props.setSortSalablePremium(null)
      props.setSortRejectedPremium(null)
      props.setSortTotalPremium(null)
      props.setSortSalablePolicy(null)
      props.setSortRejectedPolicy(null)
      props.setSortTotalPolicy(null)
      props.setFilterIndex(null)
      props.setFilterTableColumn1({})
      props.setFilterTableColumn2({})
      props.setFilterTableColumn3({})
    },
  }),
  withHandlers({
    onPageChange: (props) => async (event) => {
      const page = event.page.skip / event.page.take + 1
      props.setIsLoading(true)
      await props.fetchInsurancePolicy({
        ...props.defaultFilterDate,
        ...props.filterTable,
        page,
        perPage: 10,
      })
      props.setIsLoading(false)
    },
    onExport: (props) => async () => {
      await props.export(props.exportFilter)
    },
    filterChange: (props) => async (filter) => {
      const filterValues = []

      filter.selectedValues.find((item) => {
        if (item.selected === true) {
          filterValues.push(item.value)
        }
      })

      if (!isEmpty(filterValues)) {
        props.resetFiltering()
      }

      let params = {}

      switch (filter.orderBy) {
        case "companyName":
          params = {
            insuranceCompanyIds: filterValues,
            orderBy: "insurance_company_name",
            order: filter.order,
          }
          props.setFilterTableColumn1(filter)
          props.setFilterIndex(1)
          break
        case "policyName":
          params = {
            insurancePolicyIds: filterValues,
            orderBy: "insurance_policy_name",
            order: filter.order,
          }
          props.setFilterTableColumn2(filter)
          props.setFilterIndex(2)
          break
        case "policyState":
          params = {
            insurancePolicyStates: filterValues,
            orderBy: "insurance_policy_state",
            order: filter.order,
          }
          props.setFilterTableColumn3(filter)
          props.setFilterIndex(3)
          break
        default:
          break
      }

      const exceptNullParams = exceptNullKeys(params, "orderBy", "order")
      const dateFilter = !isEmpty(props.exportFilter) ? props.exportFilter : props.defaultFilterDate

      props.setFilterTable({ ...exceptNullParams, ...dateFilter })
      props.setIsLoading(true)
      await props.fetchInsurancePolicy({ ...dateFilter, ...exceptNullParams })
      props.setIsLoading(false)
    },
    sortingChange: (props) => (column, orderType, setSortValue) => async () => {
      let orderTypeFilter = null
      if (orderType === null) {
        orderTypeFilter = "desc"
      } else if (orderType === "desc") {
        orderTypeFilter = "asc"
      } else {
        orderTypeFilter = null
      }
      const params = {
        orderBy: column,
        order: orderTypeFilter,
      }
      const exceptNullParams = exceptNullKeys(params, "orderBy", "order")
      const dateFilter = !isEmpty(props.exportFilter) ? props.exportFilter : props.defaultFilterDate

      props.setFilterTable({ ...exceptNullParams, ...dateFilter })

      props.setIsLoading(true)
      if (orderTypeFilter !== null) {
        await props.fetchInsurancePolicy({ ...dateFilter, ...exceptNullParams })
      } else {
        await props.fetchInsurancePolicy(dateFilter)
      }

      props.resetFiltering()
      setSortValue(orderTypeFilter)
      props.setIsLoading(false)
    },
    onDateFilterSubmit: (props) => async (values) => {
      props.setIsLoading(true)
      const params = { ...values, dashboardType: props.dashboardType }
      await props.fetchInsurancePolicy(params)
      props.setIsLoading(false)

      props.setExportFilter(params)
      props.setFilterTable(params)
      props.resetFiltering()
    },
  }),

  withHooks((props) => {
    const insurancePolicy = useMemo(() => {
      const policy = props.insurancePolicy.map((policy) => {
        let policyState = {}
        const state = policy.policyState.charAt(0).toUpperCase() + policy.policyState.slice(1)
        switch (policy.policyState) {
          case "online":
            policyState = {
              state,
              color: "#5CB85C",
            }
            break
          case "offline":
            policyState = {
              state,
              color: "#D9534F",
            }
            break
          default:
            policyState = {
              state,
            }
            break
        }
        return {
          ...policy,
          policyState: policyState,
        }
      })

      return policy
    }, [props.insurancePolicy])

    return {
      insurancePolicy,
    }
  }),
  lifecycle({
    async componentDidMount() {
      this.props.setIsLoading(true)
      await Promise.all([
        this.props.fetchInsurancePolicy(this.props.defaultFilterDate),
        this.props.fetchConfig(),
      ])
      this.props.setIsLoading(false)
    },
  })
)

export default enhancer(InsurancePolicy)
