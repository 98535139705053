import React from "react"
import styled from "@emotion/styled"

const Layout = styled.div`
  flex: 1;
  width: 100%;
  margin-top: 16px;
`

const NoProduct = (props) => <Layout>No Detail</Layout>

export default NoProduct
