import React from "react"
import styled from "@emotion/styled"
import { withProps, compose, withState } from "recompose"
import * as path from "@common/path"
import { Switch, Route, Redirect } from "react-router-dom"
import { css } from "@styled"
import { Link } from "@components"
import { Menu } from "@pages/leads/show/index"

import Dashboard from "./Dashboard"
import Stats from "@pages/stats/index"
import InsuranceCompany from "../show/InsuranceCompany"
import InsurancePolicy from "../show/InsurancePolicy"
import TeamDashboard from "../show/TeamDashboard"
import SalesmanDashboard from "../show/SalesmanDashboard"
import PaymentPeriodDashboard from "../show/PaymentPeriodDashboard"
import InsurerCommissionDashboard from "../show/InsurerCommissionDashboard"
import OrdersCommissionDashboard from "../show/OrdersCommissionDashboard"

const Layout = styled.div`
  display: flex;
  justify-content: center;
`
const Body = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 678px;
  > div {
    margin-bottom: 16px;
  }
`
const CustomMenu = styled(Menu)`
  padding-left: 64px;
`
const MenuItem = styled(Link)`
  flex: 1;
  display: flex;
  height: 56px;
  align-items: center;
  justify-content: center;
  color: #666666;
  text-decoration: none;
  font-size: 16px;
  background: #e2e5e9;
  font-family: Sarabun, sans-serif;
  font-weight: normal;

  :hover {
    background: #294558;
    color: white;
    font-weight: normal;
    color: #ffffff;
  }
`
const DashBoardMenuItem = styled(MenuItem)`
  ${(props) =>
    props.active &&
    css`
      background: #294558;
      color: white;
      font-weight: normal;
      color: #ffffff;
    `}
`
const StatisticMenuItem = styled(MenuItem)`
  ${(props) =>
    props.active &&
    css`
      background: #294558;
      color: white;
      font-weight: normal;
      color: #ffffff;
    `}
`
export const CatagoryText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
`

const DashboardIndexPage = (props) => (
  <Layout>
    <CustomMenu>
      <DashBoardMenuItem to={path.dashboardPath()} active={props.activeMenu === "dashboard"}>
        สรุปยอดขาย
      </DashBoardMenuItem>
      <DashBoardMenuItem to={path.dashboardAffPath()} active={props.activeMenu === "affiliate"}>
        สรุปยอดขายจากผู้แนะนำ
      </DashBoardMenuItem>
      <StatisticMenuItem to={path.statsPath()} active={props.activeMenu === "statistic"}>
        สถิติคำสั่งซื้อ
      </StatisticMenuItem>
    </CustomMenu>

    <Body>
      <Switch>
        <Route path={path.dashboardPath()} key="dashboard" component={Dashboard} exact />
        <Route path={path.statsPath()} component={Stats} exact />
        <Route path={path.dashboardInsuranceCompanyPath()} component={InsuranceCompany} />
        <Route path={path.dashboardInsurancePolicyPath()} component={InsurancePolicy} />
        <Route path={path.dashboardTeamPath()} component={TeamDashboard} />
        <Route path={path.dashboardSalesmanPath()} component={SalesmanDashboard} />
        <Route path={path.dashboardPaymentPeriodPath()} component={PaymentPeriodDashboard} />
        <Route path={path.dashboardAffPath()} key="affDashboard" component={Dashboard} exact />
        <Route path={path.dashboardAffInsuranceCompanyPath()} component={InsuranceCompany} />
        <Route path={path.dashboardAffInsurancePolicyPath()} component={InsurancePolicy} />
        <Route path={path.dashboardAffTeamPath()} component={TeamDashboard} />
        <Route path={path.dashboardAffSalesmanPath()} component={SalesmanDashboard} />
        <Route path={path.dashboardAffPaymentPeriodPath()} component={PaymentPeriodDashboard} />
        <Route
          path={path.dashboardInsurerCommissionPath()}
          component={InsurerCommissionDashboard}
        />
        <Route path={path.dashboardOrdersCommissionPath()} component={OrdersCommissionDashboard} />
        <Redirect
          from={path.dashboardPath()}
          to={{
            pathname: path.dashboardPath(),
          }}
          exact
        />
      </Switch>
    </Body>
  </Layout>
)
const enhancer = compose(
  withProps((props) => {
    const dashboardPath = [
      "/dashboard",
      "/dashboard/insurance_company",
      "/dashboard/insurance_policy",
      "/dashboard/team",
      "/dashboard/salesman",
      "/dashboard/payment_period",
      "/dashboard/insurer_commission",
      "/dashboard/orders_commission",
    ]

    let activeMenu
    if (dashboardPath.includes(props.history.location.pathname)) {
      activeMenu = "dashboard"
    } else if (props.history.location.pathname.includes("affiliate")) {
      activeMenu = "affiliate"
    } else {
      activeMenu = "statistic"
    }

    return {
      activeMenu,
    }
  })
)

export default enhancer(DashboardIndexPage)
