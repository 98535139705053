const validate = (values) => {
  const errors = {}

  if (values.packageName && values.riders && values.riders.length > 0) {
    const ridersArrayErrors = []
    values.riders.forEach((rider, index) => {
      const riderError = {}
      if (rider.selected && !rider.sumInsured && rider.type === "ci") {
        riderError.sumInsured = "required"
        ridersArrayErrors[index] = riderError
      }
    })

    if (ridersArrayErrors.length) {
      errors.riders = {
        ...errors.ridersArrayErrors,
        ...ridersArrayErrors,
      }
    }
  }

  if (values.xxx && values.riders && values.riders.length > 0) {
    const ridersArrayErrors = []
    values.riders.forEach((rider, index) => {
      const riderError = {}
      if (rider.selected && !rider.sumInsured && rider.type === "ci") {
        riderError.sumInsured = "required"
        ridersArrayErrors[index] = riderError
      }
    })

    if (ridersArrayErrors.length) {
      errors.riders = {
        ...errors.ridersArrayErrors,
        ...ridersArrayErrors,
      }
    }
  }

  return errors
}

export default validate
