import { compose, withHandlers, lifecycle, withState } from "recompose"
import { withForm, withRouter, withStores, withHooks } from "@enhancers/index"
import * as path from "@common/path"
import { CommissionMappingInfoPage } from "../show/index"
import { useEffect } from "react"

const enhancer = compose(
  withRouter(),
  withState("selectedCommissionOptions", "setSelectedCommissionOptions", []),
  withStores((stores) => ({
    fetchConfig: stores.commissionMappingStore.fetchConfig,
    updateCommission: stores.commissionMappingStore.updateCommission,
    policyOptions: [
      ...stores.commissionMappingStore.$("configs.attributes.lifeInsurancePolicy.options", []),
      ...stores.commissionMappingStore.$("configs.attributes.healthInsurancePolicy.options", []),
    ],
    commissionOptions: stores.commissionMappingStore.$("configs.attributes.commission.options", []),
    isEdit: true,
    isCreate: true,
  })),
  withForm({
    form: "newCommissionMappingForm",
  }),
  withHandlers({
    onCancel: (props) => () => {
      props.history.push(path.commissionMappingsPath())
    },
    onCreate: (props) =>
      props.handleSubmit(async (values) => {
        await props.updateCommission(values.insurancePolicyId, values.commissionId)
        props.history.push(path.commissionMappingsPath())
      }),
  }),
  withState("filteredPolicyOptions", "setFilteredPolicyOptions", null),
  lifecycle({
    async componentDidMount() {
      await this.props.fetchConfig()
      if (this.props.location.query.insuranceCompanyId) {
        const filteredPolicyOptions = this.props.policyOptions.filter(
          (policy) => policy.insurerId === parseInt(this.props.location.query.insuranceCompanyId)
        )
        this.props.setFilteredPolicyOptions(filteredPolicyOptions)
      }
    },
  }),
  withHooks((props) => {
    useEffect(() => {
      if (props.values.insurancePolicyId) {
        const policyInsurerId = props.policyOptions.find(
          (option) => option.value === props.values.insurancePolicyId
        )
        const selectedCommissionOptions = props.commissionOptions.filter(
          (option) => option.insurerId === policyInsurerId.insurerId
        )
        props.setSelectedCommissionOptions(selectedCommissionOptions)
      }
    }, [props.values.insurancePolicyId])
  })
)

export default enhancer(CommissionMappingInfoPage)
