import React, { Fragment, useMemo, useState, useCallback } from "react"
import styled from "@emotion/styled"
import { compose } from "recompose"
import { Button, Authorize, Header as TextHeader } from "@components"
import { withRouter, withStores, withHooks } from "@enhancers/index"
import AddDocumentOrderModal from "../AddDocumentOrderModal"

const HeaderLayout = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: normal;
  margin-top: 29px;
  margin-bottom: 12px;
`
const Topic = styled(TextHeader)`
  display: flex;
  align-items: center;
  color: #666666;
  font-weight: bold;
`
const LeftPanel = styled.div`
  display: flex;
  align-items: center;
  > span:first-of-type {
    width: 177px;
  }
`
const RightPanel = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin-left: 10px;
  }
`
const AddDocumentButton = styled(Button)``
const SentSystemButton = styled(Button)`
  min-width: 160px;
  width: 100%;
`
const SubmitToTqmButton = styled(Button)`
  width: 100%;
  max-width: 115px;
  margin-left: 10px;
`

const Header = (props) => (
  <HeaderLayout>
    <LeftPanel>
      <Topic>{props.currentRole === "member.biz_dev" ? "ตรวจสอบเอกสาร" : "แนบเอกสาร"}</Topic>
    </LeftPanel>
    <RightPanel>
      <Authorize roles={["user.admin", "member.team_leader", "member.salesman"]}>
        <SentSystemButton
          onClick={props.onWorkNotiDoc}
          disabled={!props.someDocumentAreUploaded}
          awesomeIcon="paper-plane"
          children={"ส่งตรวจเอกสาร"}
          hidden={props.orderState === "completed"}
        />
      </Authorize>
      <Authorize roles={["user.admin", "member.team_leader", "member.salesman"]}>
        <AddDocumentButton
          icon="add"
          onClick={props.toggleAddDoucument}
          children="เพิ่มเอกสาร"
          disabled={props.orderState === "rejected"}
        />
      </Authorize>

      <Authorize roles={["member.biz_dev"]}>
        <Fragment>
          <AddDocumentButton
            icon="add"
            onClick={props.toggleAddDoucument}
            children="เพิ่มเอกสาร"
            styletype="secondary"
            disabled={props.orderState === "rejected"}
          />
          <SubmitToTqmButton
            onClick={props.submitToTqm}
            awesomeIcon="paper-plane"
            children="แจ้งงาน"
            disabled={!props.allDocApproved}
            hidden={props.orderState !== "doc_verifying"}
          />
          <AddDocumentButton
            icon="check"
            onClick={props.submitDocToTqm}
            disabled={!props.allDocApproved}
            children="ส่งเอกสาร"
            hidden={!["approving", "rejected", "completed"].includes(props.orderState)}
          />
        </Fragment>
      </Authorize>
    </RightPanel>
    <AddDocumentOrderModal
      open={props.isShowAddDocumentOrderModal}
      onClose={props.toggleAddDoucument}
      title="เพิ่มเอกสาร"
      onAfterAddSubmit={props.afterAddDocSubmit}
      form="AddDocumentOrderForm"
    />
  </HeaderLayout>
)

const enhancer = compose(
  withRouter(),
  withStores((stores, props) => ({
    currentRole: stores.appStore.currentRole,
    orderDocumentLists: stores.orderStore.$("orderDocumentLists", []),
    allDocApproved: stores.orderStore.allDocApproved,
    createOrderDocumentByOrderId: stores.orderStore.createOrderDocumentByOrderId,
    fetchOrderDocumentsByOrderId: stores.orderStore.fetchOrderDocumentsByOrderId,
  })),
  withHooks((props) => {
    const { params } = props.match || {}
    const { orderId } = params || {}
    const [isShowAddDocumentOrderModal, setIsShowAddDocumentOrderModal] = useState(false)

    const toggleAddDoucument = useCallback(() => {
      setIsShowAddDocumentOrderModal(!isShowAddDocumentOrderModal)
    })

    const someDocumentAreUploaded = useMemo(() => {
      return props.orderDocumentLists.some((item) => item.state === "uploaded")
    }, [props.orderDocumentLists])

    const afterAddDocSubmit = useCallback(
      async (values) => {
        try {
          await props.createOrderDocumentByOrderId(orderId, values)
        } catch (e) {
          alert(e.errors?.base || "เพิ่มเอกสารไม่สำเร็จ")
        }
        await props.fetchOrderDocumentsByOrderId(orderId)
      },
      [props.onAfterAddSubmit]
    )

    return {
      isShowAddDocumentOrderModal,
      toggleAddDoucument,
      afterAddDocSubmit,
      someDocumentAreUploaded,
    }
  })
)

export default enhancer(Header)
