import React, { useState, useCallback, useEffect } from "react"
import { compose, withState } from "recompose"
import styled, { css } from "@styled"
import { Grid, GridColumn, Button, LoadingScope, BreadCrumb, FilterButton } from "@components/index"
import * as path from "@common/path"
import { withStores, withHooks, withRouter } from "@enhancers/index"
import FilterPanel from "./FilterPanel"
import { call } from "@common/helper"

const Layout = styled.div`
  margin: 0px auto 25px auto;
`
const Header = styled.div`
  display: flex;
  margin: 0px 0 12px 0;
  justify-content: space-around;

  ${(props) =>
    props.isactive &&
    css`
      flex-direction: column;
    `}
`
const Left = styled.div``
const Right = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  > div {
    margin-left: 6px;
  }
`
const CustomButton = styled(Button)`
  width: 96px;
`
const Table = styled(Grid)``

const CommissionMappingIndexPage = (props) => (
  <React.Fragment>
    {props.loading ? (
      <LoadingScope />
    ) : (
      <Layout>
        <BreadCrumb
          links={[
            { label: "คอมมิชชั่นรับ", path: path.commissisonPath() },
            { label: "ตั้งค่าคอมมิชชั่นรับ", path: path.commissionMappingsPath() },
          ]}
        />
        <Header isactive={props.filterExpandedValue}>
          <Left>
            <FilterButton expanded={props.filterExpandedValue} onExpand={props.toggleExpandFilter}>
              <FilterPanel />
            </FilterButton>
          </Left>
          <Right>
            <CustomButton awesomeIcon="plus" onClick={props.createMapping} children="สร้าง" />
          </Right>
        </Header>
        <Table data={props.comissionMappings} onRowClick={props.onRowClick}>
          <GridColumn title="บริษัทประกัน" field="insuranceCompanyName" width="200" />
          <GridColumn title="แบบประกัน" field="insurancePolicyName" width="300" />
          <GridColumn title="คอมมิชชั่น" field="commissionName" width="300" />
        </Table>
      </Layout>
    )}
  </React.Fragment>
)

const enhancer = compose(
  withRouter(),
  withState("loading", "setLoading", false),
  withStores((stores) => ({
    fetch: stores.commissionMappingStore.fetch,
    fetchConfig: stores.paymentMappingStore.fetchConfigs,
    comissionMappings: stores.commissionMappingStore.all,
  })),
  withHooks((props) => {
    const { query } = props.location || {}
    const [loading, setLoading] = useState(false)
    const [filterExpandedValue, setFilterExpandedValue] = useState(false)
    const [fetchDataTask, setFetchDataTask] = useState(null)

    const rowClick = useCallback((event) => {
      props.history.push(path.commissionMappingPath(event.dataItem.insurancePolicyId, query))
    })

    const createMapping = useCallback((event) => {
      props.history.push(path.newCommissionMappingPath(query))
    })

    const toggleExpandFilter = useCallback(() => {
      setFilterExpandedValue(!filterExpandedValue)
    })

    useEffect(() => {
      setLoading(true)
      call(async () => {
        await Promise.all([props.fetch(), props.fetchConfig()])
      })
      setLoading(false)
    }, [])

    useEffect(() => {
      if (fetchDataTask) {
        clearTimeout(fetchDataTask)
      }
      const fetchData = setTimeout(async () => {
        await props.fetch(query)
      }, 800)
      setFetchDataTask(fetchData)
    }, [JSON.stringify(query)])

    return {
      loading,
      rowClick,
      toggleExpandFilter,
      filterExpandedValue,
      createMapping,
    }
  })
)

export default enhancer(CommissionMappingIndexPage)
