import React from "react"
import { compose, lifecycle, withHandlers, withProps, withState } from "recompose"
import { withRouter, withStores } from "@enhancers/index"
import { Icon, GridColumn } from "@components"
import { exceptNullKeys } from "@common/helper"
import {
  Layout,
  TitleText,
  Body,
  Header,
  HeaderColumn,
  CustomTable as Table,
} from "./InsuranceCompany"
import FilterDate from "../index/FilterDate"
import { isEmpty } from "lodash"

const PaymentPeriodDashboard = (props) => (
  <Layout>
    <TitleText>
      {props.dashboardType === "affiliate"
        ? "ยอดขายตามรูปแบบการชำระเงินจากผู้แนะนำ"
        : "ยอดขายตามรูปแบบการชำระเงิน"}
    </TitleText>
    <Body>
      <Header>
        <FilterDate onSubmit={props.onDateFilterSubmit} />
      </Header>
      <Table data={props.paymentPeriod} loading={props.isLoading} filterIndex={props.filterIndex}>
        <GridColumn title="รูปแบบการชำระเงิน" field="paymentPeriod" width="160" />
        <GridColumn
          title={
            <HeaderColumn
              onClick={props.sortingChange(
                "salable_premium",
                props.sortSalablePremium,
                props.setSortSalablePremium
              )}
              isactive={props.sortSalablePremium !== null}
            >
              ยอดเบี้ยที่ขายได้
              <div>
                <Icon awesomeIcon="sort" />
              </div>
            </HeaderColumn>
          }
          field="salablePremium"
          width="166"
        />
        <GridColumn
          title={
            <HeaderColumn
              onClick={props.sortingChange(
                "rejected_premium",
                props.sortRejectedPremium,
                props.setSortRejectedPremium
              )}
              isactive={props.sortRejectedPremium !== null}
            >
              ยอดเบี้ยที่ยกเลิก
              <div>
                <Icon awesomeIcon="sort" />
              </div>
            </HeaderColumn>
          }
          field="rejectedPremium"
          width="163"
        />
        <GridColumn
          title={
            <HeaderColumn
              onClick={props.sortingChange(
                "total_premium",
                props.sortTotalPremium,
                props.setSortTotalPremium
              )}
              isactive={props.sortTotalPremium !== null}
            >
              ยอดเบี้ยรวม
              <div>
                <Icon awesomeIcon="sort" />
              </div>
            </HeaderColumn>
          }
          field="totalPremium"
          width="136"
        />
        <GridColumn
          title={
            <HeaderColumn
              onClick={props.sortingChange(
                "salable_policy",
                props.sortSalablepolicy,
                props.setSortSalablepolicy
              )}
              isactive={props.sortSalablepolicy !== null}
            >
              กรมธรรม์ที่ขายได้
              <div>
                <Icon awesomeIcon="sort" />
              </div>
            </HeaderColumn>
          }
          field="salablePolicy"
          width="174"
        />
        <GridColumn
          title={
            <HeaderColumn
              onClick={props.sortingChange(
                "rejected_policy",
                props.sortRejectedPolicy,
                props.setSortRejectedPolicy
              )}
              isactive={props.sortRejectedPolicy !== null}
            >
              กรมธรรม์ที่ยกเลิก
              <div>
                <Icon awesomeIcon="sort" />
              </div>
            </HeaderColumn>
          }
          field="rejectedPolicy"
          width="174"
        />
        <GridColumn
          title={
            <HeaderColumn
              onClick={props.sortingChange(
                "total_policy",
                props.sortTotalPolicy,
                props.setSortTotalPolicy
              )}
              isactive={props.sortTotalPolicy !== null}
            >
              กรมธรรม์รวม
              <div>
                <Icon awesomeIcon="sort" />
              </div>
            </HeaderColumn>
          }
          field="totalPolicy"
          width="145"
        />
      </Table>
    </Body>
  </Layout>
)

const enhancer = compose(
  withRouter(),
  withStores((stores) => ({
    fetchDashboardPaymentPeriod: stores.dashboardStore.fetchDashboardPaymentPeriod,
    export: stores.dashboardStore.exportSalesmanReport,
    paymentPeriod: stores.dashboardStore.paymentPeriod,
  })),
  withState("isLoading", "setIsLoading", false),
  withState("sortSalablePremium", "setSortSalablePremium", null),
  withState("sortRejectedPremium", "setSortRejectedPremium", null),
  withState("sortTotalPremium", "setSortTotalPremium", null),
  withState("sortSalablepolicy", "setSortSalablepolicy", null),
  withState("sortRejectedPolicy", "setSortRejectedPolicy", null),
  withState("sortTotalPolicy", "setSortTotalPolicy", null),
  withState("filterIndex", "setFilterIndex", null),
  withState("exportFilter", "setExportFilter", null),
  withHandlers({
    resetFiltering: (props) => () => {
      props.setSortSalablePremium(null)
      props.setSortRejectedPremium(null)
      props.setSortTotalPremium(null)
      props.setSortSalablepolicy(null)
      props.setSortRejectedPolicy(null)
      props.setSortTotalPolicy(null)
      props.setFilterIndex(null)
    },
  }),
  withProps((props) => ({
    defaultFilterDate: {
      dashboardType: props.history.location.pathname.includes("affiliate") ? "affiliate" : null,
      periodType: "yearly",
      submittedAt: {
        min: new Date(new Date().getFullYear(), 0, 1),
        max: new Date(new Date().getFullYear(), 11, 31),
      },
    },
    dashboardType: props.history.location.pathname.includes("affiliate") ? "affiliate" : null,
  })),
  withHandlers({
    sortingChange: (props) => (column, orderType, setSortValue) => async () => {
      let orderTypeFilter = null
      if (orderType === null) {
        orderTypeFilter = "desc"
      } else if (orderType === "desc") {
        orderTypeFilter = "asc"
      } else {
        orderTypeFilter = null
      }
      const params = {
        orderBy: column,
        order: orderTypeFilter,
      }
      const exceptNullParams = exceptNullKeys(params, "orderBy", "order")
      const dateFilter = !isEmpty(props.exportFilter) ? props.exportFilter : props.defaultFilterDate

      props.setIsLoading(true)
      if (orderTypeFilter !== null) {
        await props.fetchDashboardPaymentPeriod({ ...dateFilter, ...exceptNullParams })
      } else {
        await props.fetchDashboardPaymentPeriod(dateFilter)
      }

      props.resetFiltering()
      setSortValue(orderTypeFilter)
      props.setIsLoading(false)
    },
    onDateFilterSubmit: (props) => async (values) => {
      props.setIsLoading(true)
      const params = {
        ...values,
        dashboardType: props.dashboardType,
      }
      await props.fetchDashboardPaymentPeriod(params)
      props.setIsLoading(false)

      props.setExportFilter(params)
      props.resetFiltering()
    },
  }),
  lifecycle({
    async componentDidMount() {
      this.props.setIsLoading(true)
      await this.props.fetchDashboardPaymentPeriod(this.props.defaultFilterDate)
      this.props.setIsLoading(false)
    },
  })
)

export default enhancer(PaymentPeriodDashboard)
