import { extendObservable, toJS } from "mobx"
import objectPath from "object-path"

class BaseStore {
  constructor(state = {}) {
    extendObservable(this, state)
  }

  toJS() {
    return toJS(this)
  }

  $ = (path, rescueValue) => objectPath.get(this.toJS(), path, rescueValue)
}

export default BaseStore
