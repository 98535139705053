import React from "react"
import { compose, withHandlers, withProps, withState } from "recompose"
import { withField, withCaption, withReadOnly, withHidden } from "@enhancers/index"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { displayNumber, numberParser } from "@common/helper"
import { Input } from "./Input"
import classnames from "classnames"
import { isNaN } from "lodash"
import numeral from "numeral"

const ReadOnly = styled.div`
  min-height: 33.5px;
  display: flex;
  align-items: center;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
`

export const CustomInput = styled(Input)`
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #eeeef2;

  ${(props) =>
    props.disabled &&
    css`
      background: #ffffff;
      color: #eeeef2;
      pointer-events: none;
    `}

  ${(props) =>
    !props.valid &&
    css`
      border-radius: 4px;
      border: 1px solid #dc3545;

      :focus:not(.k-state-invalid),
      :hover:not(.k-state-invalid) {
        font-family: Sarabun, sans-serif;
        font-size: 16px;
        border-radius: 4px;
        border: 1px solid #dc3545;
      }
    `}

  input {
    ::placeholder {
      font-family: Sarabun, sans-serif;
      font-size: 16px;
      color: #bec3c7;
      opacity: 1;
    }
  }

  ::placeholder {
    font-family: Sarabun, sans-serif;
    font-size: 16px;
    color: #bec3c7;
    opacity: 1;
  }
`

const CaptionStyle = css`
  > * :nth-of-type(2) > div {
    padding-top: 6px;
  }
`
const ValueContainer = styled.div`
  display: flex;
`
const Value = styled.span`
  ${(props) =>
    props.customStyles
      ? css`
          ${props.customStyles}
        `
      : css`
          color: #5cb85c;
          font-family: Sarabun, sans-serif;
          font-size: 16px;
        `}
`
const Unit = styled.span`
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  margin-left: 10px;
  align-self: center;
`

const enhancer = compose(
  withHidden(),
  withField(),
  withCaption({
    className: CaptionStyle,
  }),
  withReadOnly((props) => {
    let value = parseFloat(props.value)
    // NOTE: This condition for check value '12345.678' and 12345.678 are allowed to display
    if (!isNaN(value)) {
      if (![null, undefined].includes(props.precision)) {
        value = numeral(value).format(`0,0.${`0`.repeat(props.precision)}`)
      } else {
        value = numeral(value).format(`0,0.[0000000000]`)
      }
    } else {
      value = null
    }

    return (
      <ReadOnly style={props.style}>
        {props.unit ? (
          <ValueContainer>
            <Value customStyles={props.customStyles}>{value || "-"}</Value>
            <Unit>{props.unit}</Unit>
          </ValueContainer>
        ) : (
          value
        )}
      </ReadOnly>
    )
  }),
  withState("displayValue", "setDisplayValue", (props) => displayNumber(props.value)),
  withHandlers({
    onFocus: (props) => (e) => {
      props.setDisplayValue(displayNumber(props.value))
      props.onFocus && props.onFocus(props.value)
    },
    onChange: (props) => (e) => {
      try {
        const currentToLastLength = e.target.value.length - e.target.selectionStart
        const element = e.target

        window.requestAnimationFrame(() => {
          element.selectionStart = element.value.length - currentToLastLength
          element.selectionEnd = element.value.length - currentToLastLength
        })
      } catch (e) {}

      let value = e.target.value ? numberParser(e.target.value) : null
      let prevValue = value
      if (value !== null && value !== undefined) {
        if (props.min) {
          value = Math.max(value, props.min)
        }
        if (props.max) {
          value = Math.min(value, props.max)
        }
        if (!value && value !== 0 && value !== "0") {
          value = null
        }
      }

      props.onChange && props.onChange(value)

      if (prevValue !== value) {
        props.setDisplayValue(value)
      } else if (/^0(\d)$/.test(e.target.value)) {
        // eslint-disable-next-line
        const [_, digit] = e.target.value.match(/^0(\d)$/)
        props.setDisplayValue(digit)
      } else if (/^(0|[1-9][\d,]*)?(\.[\d]*)?$/.test(e.target.value)) {
        // eslint-disable-next-line
        const [_, digit = "", precision = ""] = e.target.value.match(/^(0|[1-9][\d,]*)?(\.[\d]*)?$/)
        const displayValue = `${displayNumber(digit)}${precision}`
        props.setDisplayValue(displayValue)
      }
    },
    onBlur: (props) => (e) => {
      props.setDisplayValue(displayNumber(props.value))
      props.onBlur && props.onBlur(props.value)
      props.onAfterChange && props.onAfterChange(props.value)
    },
  }),
  withProps((props) => {
    let value = null
    if (props.active) {
      value = props.displayValue
    } else {
      value = [null, undefined].includes(props.value) ? props.defaultValue : props.value
      value = displayNumber(value)
    }
    const valid = !props.touched || props.valid

    return { value, valid }
  }),
  withProps((props) => ({
    className: classnames({
      [props.className]: props.className,
      "k-textbox": true,
    }),
    value: props.value,
  }))
)

export default enhancer(CustomInput)
