import React from "react"
import { compose } from "recompose"
import { withStores } from "@enhancers/index"
import { GridColumn } from "@components/index"
import * as path from "@common/path"
import CommissionGraph from "./CommissionGraph"
import {
  Layout,
  ReportCardTitle,
  FooterContainer,
  ShowMoreDetailContainer,
  ShowMoreDetailText,
  CaretRightIcon,
  Table,
} from "./InsuranceCompanyCard"
import { formatDate } from "common/helper"

function CommissionOderCard(props) {
  return (
    <Layout>
      <ReportCardTitle>คอมมิชชันแยกตามคำสั่งซื้อ</ReportCardTitle>
      <CommissionGraph graph={props.graph} />
      <Table data={props.data} style={{ marginTop: 27 }}>
        <GridColumn title="รหัสคำสั่งซื้อ" field="tqmOrderId" width="130" />
        <GridColumn title="เบี้ยประกัน" field="netPremium" width="100" />
        <GridColumn title="คอมมิชชัน" field="commissionAmount" width="100" />
        <GridColumn
          title="วันที่จ่าย"
          field="commissionReceivedDate"
          width="120"
          cell={(cell) => {
            return <td>{formatDate(cell.dataItem.commissionReceivedDate)}</td>
          }}
        />
        {props.currentRole === "member.team_leader" && (
          <GridColumn title="รหัสพนักงาน" field="staffCode" width="120" />
        )}
        {props.currentRole === "member.team_leader" && (
          <GridColumn title="ชื่อพนักงาน" field="salesmanName" width="200" />
        )}
        <GridColumn title="ทีม" field="teamName" width="100" />
      </Table>

      <FooterContainer>
        <ShowMoreDetailContainer to={path.dashboardOrdersCommissionPath()}>
          <ShowMoreDetailText>ดูคอมมิชชันแยกตามคำสั่งซื้อทั้งหมด</ShowMoreDetailText>
          <CaretRightIcon awesomeIcon={"caret-right"} />
        </ShowMoreDetailContainer>
      </FooterContainer>
    </Layout>
  )
}

const enhancer = compose(
  withStores((stores) => ({
    currentRole: stores.appStore.currentRole,
  }))
)

export default enhancer(CommissionOderCard)
