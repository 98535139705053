import React from "react"
import styled from "@emotion/styled"
import { Button, Dialog, Field, Dropdown, CurrencyInput } from "@components"
import { compose, withHandlers, withProps } from "recompose"
import { withStores, withForm, withRouter } from "@enhancers/index"
import { Row } from "./FilterPanel"
import { find } from "lodash"
import { safe, exceptNullKeys } from "@common/helper"
import { DialogActionsBar } from "@progress/kendo-react-dialogs"
import * as path from "@common/path"

const FilterRow = styled(Row)`
  > div {
    & div:nth-of-type(1) {
      margin-bottom: unset;
    }

    & div:nth-of-type(2) {
      > span {
        padding: 0px;
        background-color: white;
        > span {
          > span:nth-of-type(2) {
            > span {
              color: black;
            }
          }
        }
      }
      .k-state-invalid {
        > span {
          border-color: #dc3545 !important;
        }
      }
    }
  }
`
const CustomDialog = styled(Dialog)`
  display: flex;
  flex-direction: column;

  .k-window-titlebar {
    min-height: 62px;
    .k-dialog-title {
      font-size: 16px;
    }
  }
`
const DialogBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 30px;
`
const DialogFooter = styled(DialogActionsBar)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 20px;
`
const AssignButton = styled(Button)`
  border-left: 0.5px solid #ccc;
  color: white;
  background-color: #0275d8;

  :hover {
    background-color: #0275d8;
    color: white;
  }
  min-height: 52px;
`

const CancelButton = styled(Button)`
  border-left: 0.5px solid #ccc;
  color: #666;
  min-height: 52px;
`

const AssignModal = (props) => (
  <CustomDialog title="เลือกลีดที่จะมอบหมาย" onClose={() => props.onClose()}>
    <DialogBody>
      <FilterRow>
        <Field
          component={Dropdown}
          name="province"
          caption="จังหวัด"
          captionStyle={{ fontWeight: "normal" }}
          options={props.provinceOptions}
          leftWidth="60"
          rightWidth="138"
          disabled={props.disabled}
          placeholder="กรุณาเลือก"
          validate="required"
        />
        <Field
          component={Dropdown}
          name="district"
          caption="อำเภอ"
          captionStyle={{ fontWeight: "normal" }}
          options={props.districtOptions}
          leftWidth="60"
          rightWidth="138"
          disabled={props.disabled}
          placeholder="กรุณาเลือก"
        />
        <Field
          component={Dropdown}
          name="subDistrict"
          caption="ตำบล"
          captionStyle={{ fontWeight: "normal" }}
          options={props.subDistrictOptions}
          leftWidth="60"
          rightWidth="138"
          disabled={props.disabled}
          placeholder="กรุณาเลือก"
        />
      </FilterRow>
      <FilterRow>
        <Field
          component={Dropdown}
          name="gender.eq"
          caption="เพศ"
          captionStyle={{ fontWeight: "normal" }}
          options={props.genderOptions}
          leftWidth="60"
          rightWidth="138"
          disabled={props.disabled}
          placeholder="กรุณาเลือก"
        />
        <Field
          component={CurrencyInput}
          name="age.min"
          caption="อายุ"
          captionStyle={{ fontWeight: "normal" }}
          leftWidth="60"
          rightWidth="57"
          disabled={props.disabled}
          placeholder="ระบุ"
          min={0}
          max={99}
        />
        <Field
          component={CurrencyInput}
          name="age.max"
          caption="-"
          captionStyle={{ fontWeight: "normal" }}
          leftWidth="12"
          rightWidth="57"
          placeholder="ระบุ"
          min={0}
          max={99}
        />

        <Field
          component={CurrencyInput}
          name="limit"
          caption="จำนวน"
          captionStyle={{ fontWeight: "normal" }}
          leftWidth="60"
          rightWidth="138"
          disabled={props.disabled}
          placeholder="กรุณาระบุ"
          min={0}
        />
      </FilterRow>
    </DialogBody>
    <DialogFooter>
      <CancelButton styletype="delete" onClick={props.onClose}>
        ย้อนกลับ
      </CancelButton>
      <AssignButton styletype="primary" onClick={props.onAssign}>
        เลือกลีด
      </AssignButton>
    </DialogFooter>
  </CustomDialog>
)

const enhancer = compose(
  withRouter(),
  withProps((props) => {
    return {
      initialValues: props.location.query,
    }
  }),
  withForm({ form: "LeadModalFilterForm", destroyOnUnmount: false }),
  withStores((stores, props) => ({
    fetchLeads: stores.leadStore.fetch,
    currentRole: stores.appStore.currentRole,
    teamId: stores.appStore.$("currentSale.team.id"),
    saleId: stores.appStore.$("currentSale.id"),
    batchId: props.match.params.batchId,
    genderOptions: stores.leadStore.$("configs.filters.gender.options", []),
    provinceOptions: stores.leadStore.$("configs.filters.address.provinces", []),
  })),
  withProps((props) => {
    const provinceOptions = props.provinceOptions
    const districtOptions = safe(
      () => find(provinceOptions, { value: props.values.province }).districts,
      []
    )
    const subDistrictOptions = safe(
      () => find(districtOptions, { value: props.values.district }).subDistricts,
      []
    )
    return {
      provinceOptions,
      districtOptions,
      subDistrictOptions,
    }
  }),
  withHandlers({
    fetchLeads: (props) => async (params) => {
      if (props.currentRole === "member.team_leader") {
        return await props.fetchLeads({
          teamId: { eq: props.teamId },
          ...params,
        })
      } else if (props.currentRole === "member.salesman") {
        return await props.fetchLeads({
          assigneeId: { eq: props.saleId },
          ...params,
        })
      } else if (
        ["member.lead_admin", "member.biz_dev", "member.admin"].includes(props.currentRole)
      ) {
        if (props.batchId === "custom") {
          return await props.fetchLeads({
            leadBatchId: null,
            state: { eq: "new" },
            ...params,
          })
        } else {
          return await props.fetchLeads({
            leadBatchId: { eq: props.batchId },
            state: { eq: "new" },
            ...params,
          })
        }
      } else {
        return await props.fetchLeads({
          leadBatchId: { eq: props.batchId },
          ...params,
        })
      }
    },
  }),
  withHandlers({
    onClose: (props) => () => {
      props.setToggleModalValue(false)
    },
    onAssign: (props) =>
      props.handleSubmit(async (values) => {
        values = exceptNullKeys(
          values,
          "age.min",
          "age.max",
          "state.eq",
          "gender.eq",
          "province",
          "district",
          "subDistrict",
          "teamId.eq"
        )

        if (props.currentRole === "member.team_leader") {
          props.history.push(
            path.leadsPath(props.batchId, {
              ...values,
              state: { eq: "team_assigned" },
              mode: "assigning",
              page: 1,
              perPage: 10000,
            })
          )
        } else {
          props.history.push(
            path.batchLeadsPath(props.batchId, {
              ...values,
              state: { eq: "new" },
              mode: "assigning",
              page: 1,
              perPage: 10000,
            })
          )
        }

        props.setToggleModalValue(false)
        props.gotoLead && props.gotoLead()
      }),
  })
)

export default enhancer(AssignModal)
