import React, { useEffect } from "react"
import styled from "@emotion/styled"
import { compose, lifecycle, withHandlers, withState } from "recompose"
import { withHooks } from "@enhancers/index"
import { Checkbox, Icon } from "@components"
import { isEmpty } from "lodash"

const Filter = styled.div`
  background: #ffffff;
  border: 1px solid #eeeef2;
  box-sizing: border-box;
  border-radius: 5px;
`
const SortButton = styled.div`
  height: 40px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: ${(props) => props.isactive && "#007bff"};

  > div,
  span {
    color: ${(props) => props.isactive && "#ffffff"};
  }
`
const SortText = styled.div`
  color: #666666;
`
const SearchFilter = styled.div`
  border-top: 1px solid #eeeef2;
  padding: 18px 14px 0 14px;
`
const SearchHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 13px;
`
const HeadButton = styled.div`
  font-family: Sarabun;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: #0275d8;
  text-decoration-line: underline;
  cursor: pointer;
`
const ActionsBar = styled.div`
  display: flex;
  flex-direction: row;
`
const CaretRightIcon = styled(Icon)`
  font-size: 20px;
  margin: 0 4px;
  color: #666666;
`
const CustomInput = styled.input`
  width: 100%;
  font-family: "FontAwesome";
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #eeeef2;
  height: 56px;
  padding: 17.5px 30px !important;
`
const CheckboxContainer = styled.div`
  margin: 8px 0;
  height: 200px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 6px;
    max-height: 40px;
  }

  ::-webkit-scrollbar-thumb {
    background: #e0e0e0;
    border-radius: 3px;
  }
`
const CustomCheckbox = styled(Checkbox)`
  .k-item.k-treeview-item {
    height: 40px;
  }
`
const CloseButton = styled.button`
  height: 56px;
  color: #666666;
  background-color: #ffffff;
  border-radius: 0;
  border: none;
  border-top: 1px solid #eeeef2;
  border-bottom-left-radius: 5px;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    border-bottom-left-radius: 5px;
  }
`
const SubmitButton = styled.button`
  height: 56px;
  background: #0275d8;
  border: 1px solid #eeeef2;
  color: #ffffff;
  border-radius: 0;
  border-bottom-right-radius: 5px;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    border-bottom-right-radius: 5px;
  }
`

const FilterPanel = (props) => {
  return (
    <Filter>
      <SortButton onClick={props.onOrderItem("asc")} isactive={props.filterParams.order === "asc"}>
        <SortText>เรียงจาก A</SortText>
        <CaretRightIcon icon={"arrow-right"} />
        <SortText>Z</SortText>
      </SortButton>
      <SortButton
        onClick={props.onOrderItem("desc")}
        isactive={props.filterParams.order === "desc"}
      >
        <SortText>เรียงจาก Z</SortText>
        <CaretRightIcon icon={"arrow-right"} />
        <SortText>A</SortText>
      </SortButton>
      <SearchFilter>
        <SearchHeader>
          <HeadButton onClick={props.selectAllOrClear(true)}>เลือกทั้งหมด</HeadButton>
          <HeadButton onClick={props.selectAllOrClear(false)}>ล้าง</HeadButton>
        </SearchHeader>
        <CustomInput
          onChange={props.searchValues}
          placeholder="&#xf002; ค้นหา..."
          style={{ fontFamily: "Arial, FontAwesome" }}
        />

        <CheckboxContainer>
          {props.filterOption.map((item, index) => (
            <CustomCheckbox
              key={index}
              value={props.findCheckboxValue(item.label)}
              label={item.label}
              onChange={(checked) => props.onSelectName(checked, item)}
            />
          ))}
        </CheckboxContainer>
      </SearchFilter>
      <ActionsBar className={"k-columnmenu-actions"}>
        <CloseButton className={"k-button"} onClick={props.onCloseMenu}>
          ยกเลิก
        </CloseButton>
        <SubmitButton className={"k-button k-primary"} onClick={props.onFilterSubmit}>
          ตกลง
        </SubmitButton>
      </ActionsBar>
    </Filter>
  )
}

const enhancer = compose(
  withState("filterOption", "setFilterOption", []),
  withState("selectedValues", "setSelectedValues", []),
  withState("orderItem", "setOrderItem", ""),
  withState("filterParams", "setFilterParams", {}),
  withHandlers({
    onSelectName: (props) => (value, dataItem) => {
      const filterData = (props.selectedValues || []).filter(
        (item) => item.label !== dataItem.label
      )

      const tempSelectedValues = [
        ...filterData,
        { label: dataItem.label, value: dataItem.value, selected: value },
      ]
      props.setSelectedValues(tempSelectedValues)
      props.setFilterParams({ ...props.filterParams, selectedValues: tempSelectedValues })
    },
    searchValues: (props) => (event) => {
      const filteredOption = []

      props.selectedValues.map((item) => {
        const found = item.label.toLowerCase().includes(event.target.value.toLowerCase())

        if (found) {
          filteredOption.push({ label: item.label, value: item.value })
        }
      })
      props.setFilterOption(filteredOption)
    },
    onOrderItem: (props) => (event) => () => {
      if (props.filterParams.order === event) {
        props.setFilterParams({ ...props.filterParams, order: null })
      } else {
        props.setFilterParams({ ...props.filterParams, order: event })
      }
    },
    selectAllOrClear: (props) => (event) => () => {
      const selectedValues = []

      props.options.map((item) => {
        selectedValues.push({
          label: item.label,
          value: item.value,
          selected: event,
        })
      })
      props.setSelectedValues(selectedValues)
      props.setFilterParams({ ...props.filterParams, selectedValues })
    },
    onFilterSubmit: (props) => async () => {
      props.onCloseMenu()
      await props.onSubmit(props.filterParams)
    },
    findCheckboxValue: (props) => (label) => {
      const checkboxValue = (
        props.selectedValues.find((selected) => selected.label === label) || {}
      ).selected

      return checkboxValue
    },
  }),
  withHooks((props) => {
    useEffect(() => {
      if (props.selectedValues.length === 0 && props.options) {
        let selectedValues = []
        const defualtSelectAll =
          props.defualtSelectAll === undefined ? false : props.defualtSelectAll

        if (!isEmpty(props.initialValues)) {
          selectedValues = props.initialValues.selectedValues
        } else {
          props.filterOption.map((item) => {
            selectedValues.push({
              label: item.label,
              value: item.value,
              selected: defualtSelectAll,
            })
          })
        }
        props.setFilterOption(props.options)
        props.setSelectedValues(selectedValues)
        props.setFilterParams({
          ...props.filterParams,
          order: (props.initialValues || {}).order,
          orderBy: props.column.field,
          selectedValues,
        })
      }
    }, [props.options, props.filterOption, props.initialValues, props.defualtSelectAll])
  })
)

export default enhancer(FilterPanel)
