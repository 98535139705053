/* eslint-disable array-callback-return */
import React, { useMemo } from "react"
import styled from "@emotion/styled"
import { compose, withHandlers, withState, withProps, lifecycle } from "recompose"
import { Grid, GridColumn, makeCell, DatePicker, Authorize, Icon, LoadingScope } from "@components"
import { withRouter, withStores } from "@enhancers/index"
import ComfirmOrderModal from "../ConfirmDocumentToSystemModal"
import VerifiedFailOrSuccessModal from "../VerifiedFailOrSuccessModal"
import { formatDate } from "@common/helper"
import SuccessAppoveDocumentModal from "../SuccessAppoveDocumentModal"
import SuccessSendDocumentModal from "../SuccessSendDocumentModal"
import OrderInfo from "./OrderInfo"
import ApproveOrderSuccessModal from "../HolderInfo/ApproveOrderSuccessModal"
import UploadDocumentModal from "../UploadDocumentModal"

import Header from "./Header"
import Annotation from "./Annotation"
import State from "./State"

const Layout = styled.div`
  position: relative;
  width: 100%;
  justify-content: center;

  [class*="k-master-row"] {
    > td:nth-last-of-type(3) {
      padding: 0px;
    }
  }
`
const Table = styled(Grid)`
  .k-grid-header-sticky {
    background-color: #294558;
    color: #ffffff;
    font-family: Sarabun, sans-serif;
    font-size: 16px;
    border-left: none !important;
  }

  .k-grid-container {
    .k-grid-content.k-virtual-content {
      > div {
        .k-grid-table {
          > tbody {
            .k-master-row {
              > td {
                padding-top: 0px !important;
                padding-bottom: 0px !important;
              }
            }
            .k-master-row.k-alt {
              > td {
                padding-top: 0px !important;
                padding-bottom: 0px !important;
              }
            }
          }
        }
      }
    }
  }
`
const LayoutFileName = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  > img {
    width: 20px;
    margin-right: 6px;
    color: #666666;
  }

  > div {
    font-size: 16px;
    font-family: Sarabun, sans-serif;
    color: #666666;
  }
`
const FileName = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const CustomIcon = styled(Icon)`
  font-size: 20px;
`
const CustomAnnotationTd = styled.td`
  position: sticky;
  right: 0;
  padding: 0px !important;
  background-color: #fff;

  > td {
    border-bottom: unset !important;
  }

  .k-grid-content-sticky {
    border-left: unset !important;
  }

  > div {
    justify-content: center;
  }
`

const DisplayFileName = (props) => (
  <td>
    <LayoutFileName>
      <CustomIcon icon="file-pdf" />
      <FileName>{props.dataItem.name}</FileName>
    </LayoutFileName>
  </td>
)

const DocumentInfo = (props) => (
  <Layout>
    {props.loading && <LoadingScope />}
    <Header
      onWorkNotiDoc={props.onWorkNotiDoc}
      onAddDocumentOrderModal={props.onAddDocumentOrderModal}
      submitToTqm={props.submitToTqm}
      submitDocToTqm={props.submitDocToTqm}
      orderState={props.orderState}
    />
    <Table
      data={props.documents}
      onRowClick={props.onClickRow}
      pageable
      skip={props.paging.skip}
      take={props.paging.perPage}
      total={props.paging.totalCount}
      onPageChange={props.onPageChange}
      footer={props.footerDescription}
    >
      <GridColumn field="name" title="ชื่อไฟล์" width="175px" cell={DisplayFileName} />
      <GridColumn
        field="state"
        title="สถานะ"
        width="121px"
        cell={makeCell(State, (cellProps) => ({
          orderState: props.orderState,
          dataItem: cellProps.dataItem,
        }))}
      />
      <GridColumn field="sourceType" title="แหล่งที่มา" width="136px" />
      <GridColumn
        field="updatedAt"
        title="วันที่แก้ไข"
        width="110px"
        cell={makeCell(DatePicker, (props) => ({
          readOnly: true,
          value: props.dataItem.updatedAt,
        }))}
        filterable={false}
      />
      <GridColumn
        field="annotation"
        title=" "
        cell={(cellProps) => {
          return (
            <CustomAnnotationTd className={`${cellProps.tdId}`}>
              <Annotation
                onClickUploadDocumentButton={() =>
                  props.setCurrentUploadDataItem(cellProps.dataItem)
                }
                onAfterDeleteDocument={props.refetch}
                readOnly={true}
                dataItem={cellProps.dataItem}
                orderState={props.orderState}
                // onAfterApproveDoc={(dataItem) => props.displayModal(dataItem)}
              />
            </CustomAnnotationTd>
          )
        }}
        filterable={false}
        width="365px"
        locked
      />
    </Table>
    <Authorize roles={["member.biz_dev"]}>
      <OrderInfo />
    </Authorize>
    <UploadDocumentModal
      open={props.currentUploadDataItem}
      onClose={() => props.setCurrentUploadDataItem(null)}
      title="แก้ไขไฟล์"
      onAfterAddSubmit={props.onAfterEditSubmit}
      form="UploadDocumentModalForm"
      initialValues={props.currentUploadDataItem}
      readNameOnly={false}
    />
    <ComfirmOrderModal
      open={props.isOpenConfirmModal}
      onClose={props.onCloseAndOpenConfirmModal}
      title="เพิ่มเอกสาร"
      onWorkNotiDoc={props.onWorkNotiDoc}
      form="AddDocumentOrderForm"
    />
    <VerifiedFailOrSuccessModal
      title={props.validateFailModalMessage.title}
      info={props.validateFailModalMessage.info}
      message={props.validateFailModalMessage.message}
      open={props.toggleValidateFailOrSuccessModal}
      onClose={props.onCloseVerifiedFailModal}
    />
    <SuccessAppoveDocumentModal
      open={props.summaryModal}
      onClose={props.onCloseAndOpenSummaryModal}
      orderId={props.orderId}
      history={props.history}
    />
    <SuccessSendDocumentModal
      open={props.toggleSendDocumentModal}
      onClose={props.onCloseSuccessSendDocumentModal}
    />
    <ApproveOrderSuccessModal
      open={props.toggleDocumentApproval}
      onClose={props.closeApprovalDocumentModal}
      onCloseModal={props.closeApprovalDocumentModal}
      modalTitle="อนุมัติเอกสาร"
      validateFailModalMessage={props.successApprovalModalMessage}
      buttonTitle="ปิด"
    />
  </Layout>
)

const enhancer = compose(
  withRouter(),
  withState("toggleSubmitDocModal", "setToggleSubmitDocModal", false),
  withStores((stores, props) => {
    const orderState =
      stores.orderStore.$("current.state") === "salesman_rejected"
        ? "rejected"
        : stores.orderStore.$("current.state")
    return {
      fetchOrder: stores.orderStore.fetchOrder,
      fetchOrderDocumentsByOrderId: stores.orderStore.fetchOrderDocumentsByOrderId,
      downloadAllDocumentByOrderId: stores.orderStore.downloadAllDocumentByOrderId,
      sendOrderDocumentByOrderId: stores.orderStore.sendOrderDocumentByOrderId,
      createOrderDocumentByOrderId: stores.orderStore.createOrderDocumentByOrderId,
      updateOrderDocumentVerifyFailByOrderId:
        stores.orderStore.updateOrderDocumentVerifyFailByOrderId,
      submitDocumentByOrderId: stores.orderStore.submitDocumentByOrderId,
      documents: stores.orderStore.orderDocumentLists,
      paging: stores.orderStore.pagingOrderDocumentLists,
      documentTypeOptions: stores.orderStore.$("orderConfigs.attributes.documentType.options", []),
      sourceTypeOptions: stores.orderStore.$(
        "orderDocumentConfigs.attributes.sourceType.options",
        []
      ),
      orderId: props.match.params.orderId,
      initialValues: {
        remark: stores.orderStore.$("current.remark"),
      },
      orderState: orderState,
      orderDocumentLists: stores.orderStore.$("orderDocumentLists", []),
      submitDocument: stores.orderStore.submitDocument,
      latestApprovedDate: stores.orderStore.footer.latestApprovedDate,
      documentApprove: stores.orderStore.documentApprove,
      fetchConfigsDocumentOrder: stores.orderStore.fetchConfigsDocumentOrder,
      submitToTqm: stores.orderStore.submitToTqm,
      submitDocToTqm: stores.orderStore.submitDocToTqm,
      footer: stores.orderStore.footer,
      updateOrderDocumentByDocumentId: stores.orderStore.updateOrderDocumentByDocumentId,
    }
  }),
  withProps((props) => {
    const footerDescription = `วันที่ส่งตรวจล่าสุด : ${formatDate(
      props.footer.latestDocSubmittedAt
    )} ${props.footer.latestDocSubmittedAt.toLocaleTimeString("en-GB")}`

    return {
      footerDescription: footerDescription,
    }
  }),
  withProps((props) => {
    const haveStateNewOrRejectDocument = useMemo(
      () =>
        props.orderDocumentLists.reduce(
          (memo, item) => (memo === false ? ["new", "rejected"].includes(item.state) : true),
          false
        ),
      [props.orderDocumentLists]
    )
    return {
      haveStateNewOrRejectDocument: haveStateNewOrRejectDocument,
      someDocumentAreUploaded: props.orderDocumentLists.some((item) => item.state === "uploaded"),
    }
  }),
  withState("toggleDocumentApproval", "setToggleDocumentApproval", false),
  withState("successApprovalModalMessage", "setSuccessApprovalModalMessage", {
    info: null,
    message: null,
  }),
  withState("valueDropdown", "setValueDropdown", null),
  withState("isOpenConfirmModal", "setIsOpenConfirmModal", false),
  withState("summaryModal", "setSummaryModal", false),
  withState("toggleValidateFailOrSuccessModal", "setToggleValidateFailOrSuccessModal", false),
  withState("toggleSendDocumentModal", "setToggleSendDocumentModal", false),
  withState("validateFailModalMessage", "setValidateFailModalMessage", {
    title: "ไม่สามารถแจ้งงานไม่ผ่านได้",
    info: "",
    message: `สถานะของเอกสารต้องไม่เท่ากับ "รอการตรวจสอบ"`,
  }),
  withState("currentUploadDataItem", "setCurrentUploadDataItem", false),
  withState("loading", "setLoading", false),
  withProps((props) => {
    const paging = useMemo(() => {
      return {
        ...props.paging,
        skip: (props.paging.page - 1) * props.paging.perPage,
      }
    }, [props.paging.page, props.paging.perPage])
    return {
      paging: paging,
      stateOptions: [
        { label: "รอการนำเข้าไฟล์", value: "new" },
        { label: "นำเข้าไฟล์แล้ว", value: "uploaded" },
        { label: "รอตรวจสอบ", value: "verifying" },
        { label: "อนุมัติ", value: "approved" },
        { label: "ส่งเอกสารแล้ว", value: "submitted" },
      ],
    }
  }),
  withHandlers({
    refetch: (props) => async () => {
      await props.fetchOrderDocumentsByOrderId(props.orderId, {
        filter: props.valueDropdown,
      })
    },
    displayModal: (props) => (dataItem) => {
      props.setSuccessApprovalModalMessage({
        info: "อนุมัติเอกสารสำเร็จ",
        message: `${dataItem.name} อนุมัติแล้ว`,
      })
      props.setToggleDocumentApproval(!props.toggleDocumentApproval)
    },
  }),
  withHandlers({
    closeApprovalDocumentModal: (props) => async () => {
      await props.setToggleDocumentApproval(!props.toggleDocumentApproval)
    },
    submitToTqm: (props) => async () => {
      try {
        await props.submitToTqm(props.orderId)
        await Promise.all([
          props.fetchOrder(props.orderId),
          props.fetchOrderDocumentsByOrderId(props.orderId),
        ])
      } catch (e) {
        try {
          if (
            e.errors.base === "ส่งงานเข้าระบบสำเร็จ แต่ส่งเอกสารไม่สำเร็จ" ||
            e.errors.base === "ส่งเอกสารไม่สำเร็จ"
          ) {
            alert(e.errors.base)
            await props.fetchOrder(props.orderId)
          } else {
            alert(e.errors.base)
          }
        } catch (_error) {
          alert("ส่งงานเข้าระบบไม่สำเร็จ")
        }
      }
    },
    onPageChange: (props) => async (event) => {
      const page = event.page.skip / event.page.take + 1
      await props.fetchOrderDocumentsByOrderId(props.orderId, {
        filter: props.valueDropdown,
        page,
      })
    },
    onFilterDropdownChange: (props) => async (value) => {
      props.setValueDropdown(value)
      await props.fetchOrderDocumentsByOrderId(props.orderId, { filter: value })
    },
    onDownloadAll: (props) => () => {
      props.downloadAllDocumentByOrderId(props.orderId)
    },
    submitDocToTqm: (props) => async () => {
      try {
        await props.submitDocToTqm(props.orderId)
        await props.fetchOrderDocumentsByOrderId(props.orderId)
      } catch (error) {
        window.alert("ส่งเอกสารไม่สำเร็จ")
      }
    },
    onConfirmSummaryModal: (props) => async () => {
      await props.sendOrderDocumentByOrderId(props.orderId)
      await props.fetchOrder(props.orderId)
      props.setSummaryModal(false)
    },
    onCloseSubmitDocModal: (props) => () => {
      props.setToggleSubmitDocModal(false)
    },
    onWorkNotiDoc: (props) => async () => {
      try {
        await props.submitDocumentByOrderId(props.orderId)
        await Promise.all([
          props.fetchOrder(props.orderId),
          props.fetchOrderDocumentsByOrderId(props.orderId),
        ])
        props.setToggleSendDocumentModal(!props.toggleSendDocumentModal)
      } catch (error) {
        window.alert("ส่งตรวจเอกสารไม่สำเร็จ")
      }
    },
    onCloseAndOpenConfirmModal: (props) => () => {
      props.setIsOpenConfirmModal(!props.isOpenConfirmModal)
    },
    onCloseAndOpenSummaryModal: (props) => () => {
      props.setSummaryModal(!props.summaryModal)
    },
    onCloseVerifiedFailModal: (props) => () => {
      props.setToggleValidateFailOrSuccessModal(false)
    },
    onCloseSuccessSendDocumentModal: (props) => () => {
      props.setToggleSendDocumentModal(!props.toggleSendDocumentModal)
    },
    onAfterEditSubmit: (props) => async (values) => {
      await props.updateOrderDocumentByDocumentId(props.orderId, values.id, values)
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.setLoading(true)
      this.props.fetchConfigsDocumentOrder({ orderId: this.props.orderId })
      this.props.setLoading(false)
    },
  })
)

export default enhancer(DocumentInfo)
