import React from "react"
import styled from "@emotion/styled"
import { compose, withHandlers } from "recompose"
import { Dialog, DialogActionsBar } from "@components"
import { CloseButton, SubmitButton } from "./RejectModal"

const CustomDialog = styled(Dialog)`
  .k-widget.k-window.k-dialog {
    min-width: 348px;
  }
`
const DeleteModalText = styled.div`
  font-size: 16px;
  padding: 20px 15px;
  font-family: Sarabun, sans-serif;
  color: #666666;
`

const DeleteModal = (props) => (
  <CustomDialog title={"ยืนยันการลบ Lead"} onClose={() => props.onClose()} isShowModal={props.open}>
    <DeleteModalText>กรุณากดยืนยันเพื่อลบ Lead</DeleteModalText>
    <DialogActionsBar>
      <CloseButton
        type="button"
        onClick={() => props.onClose()}
        children="ยกเลิก"
        styletype="delete"
      />
      <SubmitButton type="button" onClick={props.onSubmit} children="ยืนยัน" />
    </DialogActionsBar>
  </CustomDialog>
)

const enhancer = compose(
  withHandlers({
    onSubmit: (props) => async () => {
      await props.onSubmit()
      props.onClose()
    },
  })
)
export default enhancer(DeleteModal)
