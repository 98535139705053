import React from "react"
import styled from "@emotion/styled"
import { compose, withProps } from "recompose"
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout"

import PackageCard from "./PackageCard"
import ProductCard from "./ProductCard"

import { filter } from "lodash"

const Layout = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
`

const CustomPanelBar = styled(PanelBar)`
  min-width: 678px;
  margin-bottom: 10px;
  border: none;
  .k-item.k-state-default {
    .k-link.k-header {
      background-color: #294558;
      color: #fff;
      font-family: Sarabun, sans-serif;
      font-weight: bold;
      text-decoration: none;

      :hover {
        background-color: #294558;
        color: #fff;
      }
    }
  }
  .k-link.k-state-focused.k-state-selected {
    box-shadow: none !important;
  }
  .k-item.k-state-default.k-state-expanded {
    .k-link.k-header {
      > div {
        > div:nth-of-type(2) {
          display: none;
        }
      }
    }
  }
  .k-animation-container {
    z-index: 1;
    border: none;
    .k-content {
      background-color: #f7f7f9;
    }
  }
`

const Section = styled.div`
  display: flex;
  overflow-x: auto;
  padding-top: 20px;
  padding-left: 49px;
  > div {
    min-width: 280px;
    margin-right: 20px;
  }
`
const BodyForAllProduct = (props) => (
  <Layout>
    {props.wholeLifeProducts.length > 0 && (
      <CustomPanelBar>
        <PanelBarItem expanded={true} title="ประกันตลอดชีพ">
          <Section>
            {props.wholeLifeProducts.map((product, index) => (
              <ProductCard key={index} product={product} search={props.search} />
            ))}
          </Section>
        </PanelBarItem>
      </CustomPanelBar>
    )}

    {props.savingProducts.length > 0 && (
      <CustomPanelBar>
        <PanelBarItem expanded={true} title="ประกันออมทรัพย์">
          <Section>
            {props.savingProducts.map((product, index) => (
              <ProductCard key={index} product={product} search={props.search} />
            ))}
          </Section>
        </PanelBarItem>
      </CustomPanelBar>
    )}

    {props.termProducts.length > 0 && (
      <CustomPanelBar>
        <PanelBarItem expanded={true} title="ประกันชั่วระยะเวลา">
          <Section>
            {props.termProducts.map((product, index) => (
              <ProductCard key={index} product={product} search={props.search} />
            ))}
          </Section>
        </PanelBarItem>
      </CustomPanelBar>
    )}

    {props.annuityProducts.length > 0 && (
      <CustomPanelBar>
        <PanelBarItem expanded={true} title="ประกันบำนาญ">
          <Section>
            {props.annuityProducts.map((product, index) => (
              <ProductCard key={index} product={product} search={props.search} />
            ))}
          </Section>
        </PanelBarItem>
      </CustomPanelBar>
    )}

    {props.packagePolicies.length > 0 && (
      <CustomPanelBar>
        <PanelBarItem expanded={true} title="แบบแพ็คเกจ">
          <Section>
            {props.packagePolicies.map((product, index) => (
              <PackageCard
                key={index}
                product={product}
                search={props.search}
                leadId={props.leadId}
              />
            ))}
          </Section>
        </PanelBarItem>
      </CustomPanelBar>
    )}
  </Layout>
)

const enhancer = compose(
  withProps((props) => {
    const productsPolicies = filter(props.dataPolicies, (product) => product.type === "product")
    const wholeLifeProducts = filter(
      productsPolicies,
      (product) => product.insuranceType === "whole_life"
    )
    const savingProducts = filter(productsPolicies, (product) => product.insuranceType === "saving")
    const termProducts = filter(productsPolicies, (product) => product.insuranceType === "term")
    const annuityProducts = filter(
      productsPolicies,
      (product) => product.insuranceType === "annuity"
    )

    return {
      wholeLifeProducts: wholeLifeProducts,
      savingProducts: savingProducts,
      termProducts: termProducts,
      annuityProducts: annuityProducts,
      packagePolicies: filter(props.dataPolicies, (product) => product.type === "package"),
    }
  })
)

export default enhancer(BodyForAllProduct)
