import axios, { Axios } from "axios"
import humps from "humps"
import Qs from "qs"
import objectToFormData from "object-to-formdata"
import downloadjs from "downloadjs"
import moment from "moment"
import objectPath from "object-path"
import env from "@env"
import { SubmissionError } from "redux-form"
import { safe } from "./helper"
import mime from "mime-types"

const transformKeysToSnakeCase = (object) => {
  if (object instanceof File) {
    return object
  }
  if (object instanceof Array) {
    return object.map((obj) => transformKeysToSnakeCase(obj))
  }
  if (object instanceof Object) {
    return Object.entries(object)
      .map(([key, value]) => [humps.decamelize(key), transformKeysToSnakeCase(value)])
      .reduce(
        (memo, [key, value]) => ({
          ...memo,
          [key]: transformKeysToSnakeCase(value),
        }),
        {}
      )
  }
  return object
}

const transformKeysToCamelCase = (object) => {
  if (object instanceof ArrayBuffer) {
    return object
  }
  return humps.camelizeKeys(object)
}

const isFileExist = (object) => {
  if (object instanceof File) {
    return true
  }
  if (object instanceof Array) {
    return object.map((obj) => isFileExist(obj)).reduce((memo, exist) => memo || exist, false)
  }
  if (object instanceof Object) {
    return isFileExist(Object.values(object))
  }
  return false
}

const transformFormDataIfFileExist = (object) =>
  isFileExist(object) ? objectToFormData(object) : object

const transfromStringToDate = (object) => {
  if (object instanceof ArrayBuffer) {
    return object
  }
  if (object instanceof Array) {
    return object.map((obj) => transfromStringToDate(obj))
  }
  if (object instanceof Object) {
    return Object.entries(object).reduce(
      (memo, [key, value]) => ({
        ...memo,
        [key]: transfromStringToDate(value),
      }),
      {}
    )
  }
  if (typeof object === "string") {
    if (/^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2})?/.test(object)) {
      return moment(object).toDate()
    }
  }
  return object
}

const transformMomentOrDateToString = (object) => {
  if (object instanceof File) {
    return object
  }
  if (object instanceof Date) {
    object = moment(object)
  }
  if (object && object._isAMomentObject) {
    return object.format()
  }
  if (object instanceof Array) {
    return object.map((obj) => transformMomentOrDateToString(obj))
  }
  if (object instanceof Object) {
    return Object.entries(object).reduce(
      (memo, [key, value]) => ({
        ...memo,
        [key]: transformMomentOrDateToString(value),
      }),
      {}
    )
  }
  return object
}

const arraybufferToJson = (arraybuffer) => {
  const decodedString = String.fromCharCode.apply(null, new Uint8Array(arraybuffer))
  return JSON.parse(decodedString)
}

const defaultConfig = {
  transformRequest: [
    transformMomentOrDateToString,
    transformKeysToSnakeCase,
    transformFormDataIfFileExist,
    ...axios.defaults.transformRequest,
  ],
  transformResponse: [
    ...axios.defaults.transformResponse,
    transformKeysToCamelCase,
    transfromStringToDate,
  ],
  timeout: 3000000,
  validateStatus: (status) => status >= 200 && status < 400,
}

window.qs = Qs
export default class Api extends Axios {
  constructor(config = {}, ...rest) {
    const { requestInterceptor, responseInterceptor } = config

    super({ ...defaultConfig, ...config }, ...rest)

    this.interceptors.request.use((request) => {
      requestInterceptor && requestInterceptor(request)

      const { method, params } = request
      if (method === "get" || method === "delete") {
        request.paramsSerializer = (params) => {
          return Qs.stringify(params, {
            arrayFormat: "brackets",
            encode: true,
            strictNullHandling: true,
          })
        }

        request.params = [
          transformMomentOrDateToString,
          transformKeysToSnakeCase,
          transformFormDataIfFileExist,
        ].reduce((memo, transform) => transform(memo), params)
      }

      return request
    })
    this.interceptors.response.use(
      (response) => {
        responseInterceptor && responseInterceptor(response)
        return response
      },
      (error) => {
        const response = objectPath.get(error, "response.data", {})
        const { code, message, errors = [] } =
          response instanceof ArrayBuffer ? arraybufferToJson(response) : response

        switch (code) {
          case "unauthorized":
            const appStore = require("@stores/mobx/appStore").default
            appStore.logout()
            break
          case "bad_request":
            throw new SubmissionError({ code, message })
          case "validate_failed":
            if (errors instanceof Array && !errors[0].field) {
              throw errors[0].message
            }
            let validateData = errors.reduce((memo, { field, message }) => {
              field = humps.camelize(field)
              field = field.replace(/\//g, ".")

              message = message || "invalid"

              objectPath.set(memo, field, message)
              return memo
            }, {})
            validateData._error = { ...validateData }
            throw new SubmissionError(validateData)
          case "bad_request":
            throw new SubmissionError({ base: message })
          default:
            break
        }

        if (objectPath.get(error, "config.disableErrorNotification") !== true) {
          let description = message || errors.map(({ message }) => message).join(", ")

          if (!description || description === "Internal server error.") {
            description = "เกิดข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้งในภายหลัง"
          }
        }

        return Promise.reject(error)
      }
    )
  }

  async get(path, params, options = {}) {
    const response = await super.get(path, { params, ...options })
    return response
  }

  async delete(path, params, options = {}) {
    const response = await super.delete(path, { params, ...options })
    return response
  }

  async download(path, params, options = {}) {
    let { method = "get", viewMode = false, timeout = 3000000, ...restOptions } = options
    const response = await this[method](
      path,
      { pure: true, ...params },
      { responseType: "arraybuffer", timeout, ...restOptions }
    )
    const { data, headers } = response

    const filename = safe(() => {
      try {
        // NOTE: Many filename from server fix follow server sent.
        return decodeURIComponent(headers["content-disposition"].match(/filename\*="?(.*)"?/)[1])
      } catch {
        return headers["content-disposition"].match(/filename="?(.*)"?/)[1]
      }
    })
    // NOTE: Cannot rely "content-type" from server
    // const mimeType = headers["content-type"]
    const mimeType = mime.lookup(filename)

    if (viewMode) {
      // NOTE: when need prevent by browser suggest to use `https://github.com/lancedikson/bowser`
      const file = new Blob([data], { type: mimeType })
      const fileURL = URL.createObjectURL(file)

      // Check popup blocker
      let win = window.open(fileURL, "_blank")
      let loading = setTimeout(function () {
        //Browser has blocked it
        alert(
          "ไม่สามารถเปิดดูไฟล์ได้ หากติดตั้ง blocker extension ไว้กรุณาปิดใช้งาน และลองใหม่อีกครั้ง"
        )
      }, 5000)

      try {
        win.addEventListener("load", function () {
          clearTimeout(loading)
        })
      } catch (e) {
        clearTimeout(loading)
        alert(
          "ไม่สามารถเปิดดูไฟล์ได้ หากติดตั้ง blocker extension ไว้กรุณาปิดใช้งาน และลองใหม่อีกครั้ง"
        )
      }
    } else {
      downloadjs(data, filename, mimeType)
    }
  }
}

export const TQLD_AUTH_TOKEN_KEY = "Tqld-Auth-Token"
export const TQLD_CURRENT_MEMBER_ID_KEY = "Tqld-Current-Member-Id"

export const Server = {
  tqld: {
    tqmBackend: new Api({
      baseURL: `${env.tqldApiEndpoint}/tqm_backend/api/v1`,
      requestInterceptor: (request) => {
        request.headers = {
          ...request.headers,
          [TQLD_AUTH_TOKEN_KEY]: localStorage.getItem(TQLD_AUTH_TOKEN_KEY),
          [TQLD_CURRENT_MEMBER_ID_KEY]: localStorage.getItem(TQLD_CURRENT_MEMBER_ID_KEY),
        }
        return request
      },
    }),
    backend: new Api({
      baseURL: `${env.tqldApiEndpoint}/backend/api/v2`,
      requestInterceptor: (request) => {
        request.headers = {
          ...request.headers,
          [TQLD_AUTH_TOKEN_KEY]: localStorage.getItem(TQLD_AUTH_TOKEN_KEY),
        }
        return request
      },
    }),
    frontend: new Api({
      baseURL: `${env.tqldApiEndpoint}/frontend/api/v2`,
      requestInterceptor: (request) => {
        request.headers = {
          ...request.headers,
          [TQLD_AUTH_TOKEN_KEY]: localStorage.getItem(TQLD_AUTH_TOKEN_KEY),
        }
        return request
      },
    }),
  },
}

Server.tqld.authTokenKey = "Tqld-Auth-Token"

export const tqldServer = Server.tqld
export const tqmBackendServer = Server.tqld.tqmBackend

export const api = new Api()
