// eslint-disable-next-line array-callback-return
import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { compose, withProps, lifecycle, withHandlers, withState, defaultProps } from "recompose"
import {
  Button,
  Dropdown,
  Form,
  Field,
  CurrencyInput,
  Radio,
  DatePicker,
  DateInput,
} from "@components/index"
import { withForm, withStores, withRouter } from "@enhancers/index"
import moment from "moment"
import * as path from "@common/path"
import { exceptNullKeys } from "@common/helper"

const LayoutOutside = styled.div`
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  ${(props) =>
    props.toggleValue
      ? css`
          display: flex;
        `
      : css`
          display: none;
        `};
`
const Layout = styled(Form)`
  display: none;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  z-index: 4;
  width: 412px;
  padding-top: 38px;
  background-color: #ffffff;
  border: 1px solid #eeeef2;
  border-radius: 4px;
  top: -1px;
  left: -1px;

  ${(props) =>
    props.toggleValue
      ? css`
          display: flex;
        `
      : css`
          display: none;
        `}
`
const InfomationContainer = styled.div`
  display: flex;
  align-self: center;
  flex-direction: column;
  padding: 20px 27px;

  [class*="Caption"] {
  }
`
const Line = styled.div`
  border-bottom: 1px solid #eeeef2;
`
const Option = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 27px 37px 34px;

  > div :nth-of-type(2) {
    margin-top: 10px;

    > div {
      margin-bottom: -8px;
      .k-radio-label {
        padding-left: 0px;
      }

      > div {
        ::before {
          margin-left: 170px;
          width: 13px;
          height: 13px;
          background-color: white !important;
          border-color: black !important;
          box-shadow: unset !important;
        }
        ::after {
          margin-left: 170px;
          width: 5px;
          height: 5px;
          background-color: black !important;
          border-color: black !important;
          box-shadow: unset !important;
        }
      }
    }
  }
`
const Content = styled.div`
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  color: #0275d8;
  font-family: Sarabun, sans-serif;
`
const Footer = styled.div`
  display: flex;
  justify-content: center;
`
const CustomTagA = styled.a`
  text-decoration: none;
  width: 100%;
`
const CustomButton = styled(Button)`
  width: 100%;
  font-size: 16px;
  height: 52px;
  background-color: #0275d8;
  color: #eeeef2;
  border-radius: 0 0 4px 4px;
`
const CustomDropdown = styled(Dropdown)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
const CustomCurrencyInput = styled(CurrencyInput)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`
const CustomDateInput = styled(DateInput)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
const WrapInputDate = styled.div`
  position: relative;
  margin-top: 10px;
`
const AgeLabel = styled.div`
  position: absolute;
  right: 0;
  top: 7px;
  font-size: 14px;
`
const ConfigLayout = styled.div`
  display: flex;
  height: 38px;
  justify-content: space-between;
`
const ConfigButton = styled(Button)`
  width: 143px;
  height: 38px;
  color: #666666;
  border: none;
  z-index: 5;
  span {
    font-size: 20px;
    margin-right: 12px !important;
  }
  :hover,
  :active {
    border: none;
    color: #666666;
  }
`
const ProductsearchByContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const FilterPanel = (props) => (
  <ConfigLayout>
    <ConfigButton icon={props.icon || "filter"} onClick={props.clickFilterButton}>
      {props.children || "ปรับตัวเลือก"}
    </ConfigButton>

    <Layout toggleValue={props.toggleValue}>
      <InfomationContainer>
        <Content>ข้อมูลทั่วไป</Content>
        <Field
          name="gender"
          component={CustomDropdown}
          caption="เพศ"
          placeholder="กรุณาเลือก"
          options={props.genderOptions}
          validate="required"
        />
        <WrapInputDate>
          <Field
            name="birthdate"
            component={CustomDateInput}
            caption={"วันเกิด"}
            validate="required"
            placeholder="กรุณาเลือก"
          />
          {/* {props.values.birthdate && (
            <AgeLabel> (อายุ {moment().diff(moment(props.values.birthdate), "years")} ปี)</AgeLabel>
          )} */}
        </WrapInputDate>
        {props.values.type !== "all" && props.values.type !== "package" && (
          <ProductsearchByContainer>
            {/* <Field
              name="searchBy"
              component={CustomDropdown}
              options={props.searchByOptions}
              caption="ค้นหาโดย"
              placeholder="กรุณาเลือก"
              validate="required"
            /> */}
            <Field
              name="searchValue"
              component={CustomCurrencyInput}
              caption="ทุนประกัน" //"จำนวนเงิน"
              placeholder="กรุณาระบุ"
              min={0}
              validate="required"
            />
          </ProductsearchByContainer>
        )}
      </InfomationContainer>
      <Line />
      <Option>
        <Content>ประเภท</Content>
        {props.policyTypeOptions && (
          <Field name="type" component={Radio} options={props.policyTypeOptions} />
        )}
      </Option>
      <Footer>
        <CustomTagA>
          <CustomButton onClick={props.handleSubmit(props.filterProducts)}>แสดงผล</CustomButton>
        </CustomTagA>
      </Footer>
    </Layout>
    <LayoutOutside toggleValue={props.toggleValue} onClick={props.onClickOutSide}></LayoutOutside>
  </ConfigLayout>
)

const enhancer = compose(
  withRouter({
    queryTypes: {
      birthdate: "date",
      gender: "string",
      type: "string",
      searchBy: "string",
      searchValue: "integer",
      leadId: "integer",
    },
  }),
  defaultProps({
    searchByOptions: [
      { label: "ทุนประกันขั้นต่ำ", value: "sum_insured" },
      { label: "เบี้ยประกันที่ต้องการซื้อ", value: "premium" },
    ],
  }),
  withState("toggleValue", "setToggleValue", false),
  withStores((stores) => ({
    genderOptions: stores.productStore.$("configs.filters.gender.options", [
      {
        label: "ชาย",
        value: "male",
      },
      {
        label: "หญิง",
        value: "female",
      },
    ]),
  })),
  withHandlers({
    clickFilterButton: (props) => () => {
      props.setToggleValue(!props.toggleValue)
    },
    onClickOutSide: (props) => () => {
      props.setToggleValue(false)
    },
    filterProducts: (props) => async (values) => {
      const birthdate = moment(values.birthdate).format("DD/MM/YYYY", {
        locale: "en",
      })
      const searchValue =
        values.type === "all" || values.type === "package" ? null : values.searchValue
      const age = values.birthdate ? moment().diff(moment(values.birthdate), "years") : null
      const searchBy = values.type === "all" || values.type === "package" ? null : values.searchBy
      const params = {
        ...values,
        searchValue: searchValue,
        type: values.type,
        searchBy: searchBy || "sum_insured",
        birthdate,
      }
      const exceptNullParams = exceptNullKeys(params, "searchValue", "searchBy")
      props.history.push(path.productsPath(props.leadId, { ...params, ...exceptNullParams }))
      props.setToggleValue(false)
    },
  }),
  withProps((props) => {
    const policyTypeOptions = [
      {
        label: "ตลอดชีพ",
        value: "whole_life",
      },
      {
        label: "สะสมทรัพย์",
        value: "saving",
      },
      {
        label: "บำนาญ",
        value: "annuity",
      },
      {
        label: "แพ็กเกจ",
        value: "package",
      },
      {
        label: "ทั้งหมด",
        value: "all",
      },
    ]

    const { query = {} } = props.location
    let { birthdate, age } = query
    if (birthdate) {
      birthdate = moment(birthdate, "DD/MM/YYYY").toDate()
    } else if (age) {
      birthdate = moment().add(-age, "years").toDate()
    } else {
      birthdate = null
    }

    return {
      policyTypeOptions,
      initialValues: {
        ...query,
        birthdate: birthdate,
      },
    }
  }),
  withForm({ form: "FilterProductForm", destroyOnUnmount: false }),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (prevProps.toggleValue !== this.props.toggleValue && !this.props.toggleValue) {
        this.props.reset()
      }
    },
  })
)

export default enhancer(FilterPanel)
