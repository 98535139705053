import React, { useCallback, useState } from "react"
import styled, { css } from "@styled"
import { compose } from "recompose"
import { withHooks, withRouter } from "@enhancers/index"
import {
  Chart,
  ChartTitle,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartAxisDefaults,
  ChartTooltip,
} from "@progress/kendo-react-charts"
import { displayNumber } from "@common/helper"

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  margin-top: 31px;
  border-radius: 5px;
`
const SwapContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 23px;
  align-items: center;
`
const Swap = styled.div`
  display: flex;
  align-items: flex-end;
`
const SwapButton = styled.div`
  font-family: Sarabun;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  cursor: pointer;

  ${(props) =>
    props.isactive &&
    css`
      pointer-events: none;
      color: #0275d8;
    `}
`
const Separator = styled.div`
  color: #e0e0e0;
  margin: 0 8px;
`
const Explanation = styled.div`
  display: flex;
`
const ExplanationItem = styled.div`
  display: flex;
  align-items: center;
  margin-left: 14px;
`
const ExplanationColor = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  background-color: ${(props) => props.color};
`
const ExplanationText = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
`

const nestedTooltipRender = ({ point }) => (
  <span>
    <a>{displayNumber(point.value)}</a>
  </span>
)

const ChartContainer = (props) => (
  <Chart>
    <ChartTitle />
    <ChartCategoryAxis>
      <ChartCategoryAxisItem categories={props.graph.xAxisCatagory} />
    </ChartCategoryAxis>
    <ChartAxisDefaults labels={{ format: "n0" }} />
    <ChartTooltip render={nestedTooltipRender} />
    <ChartSeries>
      <ChartSeriesItem
        type="line"
        data={props.graph.salable}
        markers={{ visible: false }}
        tooltip={{ visible: true }}
        color="#0275D8"
      />
      <ChartSeriesItem
        type="line"
        data={props.graph.rejected}
        markers={{ visible: false }}
        tooltip={{ visible: true }}
        color="#D9534F"
      />
      <ChartSeriesItem
        type="line"
        data={props.graph.total}
        markers={{ visible: false }}
        tooltip={{ visible: true }}
        color="#025AA5"
      />
    </ChartSeries>
  </Chart>
)

const GraphContainer = (props) => {
  return (
    <Layout>
      <SwapContainer>
        <Swap>
          <SwapButton onClick={props.swapGraph("premium")} isactive={props.isGraph === "premium"}>
            ยอดเบี้ย
          </SwapButton>
          <Separator>|</Separator>
          <SwapButton onClick={props.swapGraph("policy")} isactive={props.isGraph === "policy"}>
            จำนวนกรมธรรม์
          </SwapButton>
        </Swap>

        <Explanation>
          <ExplanationItem>
            <ExplanationColor color="#0275D8" />
            <ExplanationText>
              {props.isGraph === "premium" ? "ยอดเบี้ยที่ขายได้" : "กรมธรรม์ที่ขายได้"}
            </ExplanationText>
          </ExplanationItem>
          <ExplanationItem>
            <ExplanationColor color="#D9534F" />
            <ExplanationText>
              {props.isGraph === "premium" ? "ยอดเบี้ยที่ยกเลิก" : "กรมธรรม์ที่ยกเลิก"}
            </ExplanationText>
          </ExplanationItem>
          <ExplanationItem>
            <ExplanationColor color="#025AA5" />
            <ExplanationText>
              {props.isGraph === "premium" ? "ยอดเบี้ยรวม" : "กรมธรรม์รวม"}
            </ExplanationText>
          </ExplanationItem>
        </Explanation>
      </SwapContainer>

      <ChartContainer
        graph={
          props.isGraph === "premium"
            ? props.graph.premiumGraph || {}
            : props.graph.policyGraph || {}
        }
      />
    </Layout>
  )
}

const enhancer = compose(
  withRouter(),
  withHooks(() => {
    const [isGraph, setIsGraph] = useState("premium")

    const swapGraph = useCallback(
      (event) => () => {
        setIsGraph(event)
      },
      [isGraph]
    )

    return {
      isGraph,
      swapGraph,
    }
  })
)

export default enhancer(GraphContainer)
