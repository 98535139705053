import { compose, withProps } from "recompose"

import Ai from "@pages/products/custom/show/DefaultDetailModal/Ai"
import Ci from "@pages/products/custom/show/DefaultDetailModal/Ci"
import Cr from "@pages/products/custom/show/DefaultDetailModal/Cr"
import Hb from "@pages/products/custom/show/DefaultDetailModal/Hb"
import Hs from "@pages/products/custom/show/DefaultDetailModal/Hs"

import NoProduct from "@pages/products/custom/show/DefaultDetailModal/NoProduct"
import { DefaultDetailModal } from "@pages/products/custom/show/DefaultDetailModal/index"
import Wp from "@pages/products/custom/show/DefaultDetailModal/Wp"
import Pb from "@pages/products/custom/show/DefaultDetailModal/Pb"

const enhancer = compose(
  withProps((props) => {
    return {
      BodyComponent:
        {
          AiProduct: Ai,
          CiProduct: Ci,
          CrProduct: Cr,
          HbProduct: Hb,
          HsProduct: Hs,
          WpProduct: Wp,
          PbProduct: Pb,
        }[props.riderPolicyDetail.type] || NoProduct,
      riderPolicyDetail: {
        protectionDetail:
          props.riderPolicyDetail.productDetail &&
          props.riderPolicyDetail.productDetail.protectionDetail,
        totalDiseases:
          props.riderPolicyDetail.productDetail &&
          props.riderPolicyDetail.productDetail.totalDiseases,
        ...props.riderPolicyDetail,
      },
    }
  })
)

export default enhancer(DefaultDetailModal)
