import React from "react"
import styled from "@emotion/styled"
import { compose, withHandlers } from "recompose"
import { withForm, withStores } from "@enhancers"
import { Input, Form, Field, DialogActionsBar, InputFile, Dropdown } from "@components"
import { CustomDialog, CloseButton, SubmitButton } from "./SuggestionModal"

const Layout = styled(Form)`
  padding: 48px 24px;
  padding-bottom: 56px;
`
const CustomDropdown = styled(Dropdown)`
  > div:nth-of-type(2) {
    > span {
      padding: 0px;
      background-color: transparent;
    }
  }
`

export const SuggestForm = (props) => (
  <CustomDialog title={props.title} onClose={props.onClose} isShowModal={props.open}>
    <Layout>
      <Field
        name="submitType"
        caption="แจ้งการแนะนำไปยัง"
        component={CustomDropdown}
        options={props.affiliateOrderSubmitTypeOptions}
        validate="required"
        placeholder="กรุณาเลือก"
        readOnly={props.readNameOnly}
      />
    </Layout>
    <DialogActionsBar>
      <CloseButton type="button" onClick={props.onClose} children="ยกเลิก" styletype="delete" />
      <SubmitButton type="button" onClick={props.onSubmit} children="ยืนยัน" />
    </DialogActionsBar>
  </CustomDialog>
)

const enhancer = compose(
  withStores((stores, props) => {
    return {
      affiliateOrderSubmitTypeOptions: stores.orderStore.$(
        "orderConfigs.attributes.affiliateOrderSubmitType.options",
        []
      ),
    }
  }),
  withForm({ form: "SuggestForm" }),
  withHandlers({
    onClose: (props) => () => {
      props.reset(props.from)
      props.onClose()
    },
    onSubmit: (props) =>
      props.handleSubmit(async (values) => {
        await props.onSubmit(values)
        await props.reset(props.from)
        await props.onClose()
      }),
  })
)
export default enhancer(SuggestForm)
