import React, { Fragment } from "react"
import { Grid as KendoGrid, GridNoRecords } from "@progress/kendo-react-grid"
import styled from "@common/styled"
import LoadingScope from "./LoadingScope"
import { withCypress } from "@enhancers/index"
import { withProps, compose } from "recompose"
import { get } from "lodash"

import { desktopSize } from "@common/styled"

const CustomKendoGrid = styled(KendoGrid)`
  border-radius: 4px;
  tabel {
    min-width: 676px !important;
  }
  .k-grid-header {
    height: 50px;
    border-radius: 4px 4px 0 0;
    background-color: #294558;
    border-color: #294558;
  }
  .k-grid-header-wrap {
    border-radius: 4px 4px 0px 0px;
  }
  .k-pager-wrap {
    border-radius: 4px;
  }

  thead {
    background-color: #294558;
    color: #ffffff;
    font-family: Sarabun, sans-serif;
    font-size: 16px !important;
  }

  .k-header {
    border: none;
    margin: -12px -8px !important;
    padding: 12px 8px !important;
  }

  .k-grid-header-sticky {
    background-color: #294558;
    color: #ffffff;
    font-family: Sarabun, sans-serif;
    font-size: 16px;
    border-left: 1px solid #eeeef2;
  }

  .k-link {
    font-family: Sarabun, sans-serif;
    font-size: 16px;
    margin: -12px -8px !important;
    padding: 12px 8px !important;
  }

  .k-alt {
    background-color: transparent;
  }

  tbody tr:hover {
    background: #f7f7f9;
  }
  ${(props) =>
    !props.pageable &&
    `.k-grid-content {
      overflow-y: hidden;

    }`}

  .k-grid-norecords, .k-grid-norecords:hover {
    background-color: #eeeef2;
  }
  td {
    border: none;
    font-family: Sarabun, sans-serif;
    font-size: 16px;
    color: #666666;
    padding: 0px 8px;

    border-bottom: 1px solid #eeeef2;
    line-height: 47px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    > button {
      vertical-align: baseline;
    }
  }

  .k-master-row:hover .k-grid-content-sticky {
    background-color: #f7f7f9;
  }

  .k-grid-content-sticky {
    font-family: Sarabun, sans-serif;
    font-size: 16px;
    color: #666666;
    background-color: #ffffff;
    border-left: 1px solid #eeeef2;
    border-bottom-color: #eeeef2;
  }

  .k-grid-norecords {
    background-color: #eeeef2;
  }

  .k-pager-wrap {
    height: 50px;
    align-items: center;
  }
  .k-grid-pager {
    border-color: #eeeef2;
    background-color: #ffffff;
    border-radius: 0px 0px 4px 4px;
    .k-pager-nav {
      margin: unset !important;
      padding: 0.375rem 0.375rem !important;
    }
    .k-pager-numbers {
      .k-link {
        color: #666666;
        margin: unset !important;
        padding: 0.375rem 0.375rem !important;
      }
      .k-state-selected {
        background-color: #f7f7f9;
        border-color: #eeeef2;
      }
    }
    .k-pager-nav:hover {
      color: #666666;
    }
    .k-icon {
      font-size: 20px;
      color: #666666;
    }

    a {
      border-color: #eeeef2 !important;
      opacity: 1;

      &.k-state-disabled > span {
        opacity: 0.65;
      }

      &:not(:first-of-type) {
        border-left: none;
      }
    }

    .k-pager-numbers {
      li:first-of-type a {
        border-left: none;
      }
    }
  }

  ${(props) =>
    desktopSize(`
      table {
        width: 100% !important;
      }
  `)}
`

const Layout = styled.div`
  position: relative;

  .k-widget.k-grid {
    border-color: #eeeef2;

    .k-grid-header {
      border: none;

      .k-grid-header-wrap {
        border: none;

        thead tr {
          height: 50px;
        }
      }
    }
  }

  .k-pager-wrap.k-grid-pager.k-widget {
    display: flex;
    justify-content: flex-end;
    padding-right: 12px;

    .k-pager-info.k-label {
      display: none;
    }
  }
`

const Footer = styled.div`
  position: absolute;
  bottom: 0;
  height: 52px;
  padding-left: 12px;
  display: flex;
  align-items: center;
  z-index: 2;
  font-size: 10px;
  font-family: "Sarabun", sans-serif;
`
const CustomTd = styled.div`
  height: 52px;
  padding: 12px;
  display: flex;
  align-items: center;

  justify-content: space-between;

  z-index: 2;
  font-size: 10px;
  font-family: "Sarabun", sans-serif;
  background-color: #f8f9fa;
  border-top: 0px !important;
  border: 1px solid #dde2e7;
  border-radius: 0px 0px 5px 5px;

  position: absolute;
  bottom: 0px;
  border-right: none;
  height: 50px;
`
const FooterText = styled.div`
  font-size: 10px;
  font-family: "Sarabun", sans-serif;
`

const NoRecordsWrapper = styled.div`
  min-height: 127px;
  position: relative;
  > div {
    position: absolute;
    top: 40px;
    left: 300px;
  }
`

const Grid = ({
  className,
  footer,
  loading,
  loadingTable,
  cypress,
  children,
  noRecords = "ไม่พบข้อมูล",
  pageable,
  empty,
  ...gridProps
}) => (
  <Layout className={className} {...cypress}>
    <CustomKendoGrid pageable={empty ? null : pageable} {...gridProps}>
      <GridNoRecords>
        <NoRecordsWrapper>
          <div>{noRecords}</div>
        </NoRecordsWrapper>
      </GridNoRecords>
      {children}
    </CustomKendoGrid>
    {!empty && (
      <Fragment>
        {typeof footer === "object" ? (
          <CustomTd>
            <FooterText>{footer.left}</FooterText>
            <FooterText>{footer.right}</FooterText>
          </CustomTd>
        ) : pageable ? (
          <Footer>{footer}</Footer>
        ) : null}
      </Fragment>
    )}
    {loading || (loadingTable && <LoadingScope fullScreen={loadingTable ? false : true} />)}
  </Layout>
)

const enhancer = compose(
  withCypress("Grid"),
  withProps((props) => ({
    empty: get(props, "data.length", 0) === 0,
  }))
)

export default enhancer(Grid)
