import React from "react"
import styled from "@emotion/styled"
import { Dialog, DialogActionsBar, Button } from "@components/index"
import { compose, withHandlers } from "recompose"
import { withRouter } from "@enhancers/index"
import * as path from "@common/path"

const CustomDialog = styled(Dialog)`
  .k-window-titlebar {
    min-height: 62px;
    .k-dialog-title {
      font-size: 16px;
    }
  }
  * {
    font-family: Sarabun, sans-serif !important;
  }
`
const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 400px;
  align-items: center;
  padding: 27px 34px 50px 34px;
`
const Caption = styled.span`
  font-size: 16px;
  color: #5cb85c;
  font-weight: bold;
  margin-top: 7px;
  font-family: Sarabun, sans-serif;
`
const DialogFooter = styled(DialogActionsBar)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 20px;
`
const CancelButton = styled(Button)`
  border-left: 0.5px solid #ccc;
  color: #666;
  min-height: 52px;
  :hover {
    border-color: #ccc;
    color: #666;
  }
`
const Bold = styled.span`
  font-weight: bold;
`
const Text = styled.div`
  margin-top: 13px;
  text-align: center;
`
const SaveDraftModal = (props) => {
  return (
    <CustomDialog title="บันทึกร่างแบบประกัน" onClose={props.onClose} isShowModal={props.open}>
      <Layout>
        <Caption>บันทึกร่างแบบประกันสำเร็จ</Caption>
        <Text>
          ข้อมูลร่างแบบประกันจะถูกแสดงใน <Bold>คำสั่งซื้อ</Bold> <br />
          โดยมีสถานะเป็น <Bold>แบบร่าง</Bold>
        </Text>
      </Layout>
      <DialogFooter>
        <CancelButton styletype="delete" onClick={() => props.history.push(path.rootPath())}>
          ไปหน้าเมนู
        </CancelButton>
      </DialogFooter>
    </CustomDialog>
  )
}

const enhance = compose(
  withRouter(),
  withHandlers({
    onClose: (props) => () => {
      props.onClose()
    },
  })
)

export default enhance(SaveDraftModal)
