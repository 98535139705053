import React, { Fragment } from "react"
import styled from "@emotion/styled"
import { compose, withHandlers, withProps } from "recompose"
import { withRouter, withStores } from "@enhancers/index"
import {
  Grid,
  GridColumn,
  makeCell,
  makeHeaderCell,
  Checkbox,
  InputName,
  Dropdown,
  DatePicker,
  PhoneNumberInput,
} from "@components"
import { getOptionLabel, safe } from "@common/helper"
import { Input } from "@components/Input"

const CustomInput = styled(Input)`
  width: 100%;
  border: none;
  color: #666666;
  background-color: transparent;
  :active,
  :hover,
  :focus {
    border: none;
  }
`

const CustomCheckbox = styled(Checkbox)`
  .k-item.k-treeview-item {
    height: 24px !important;
  }
`

const Table = (props) => (
  <Fragment>
    {props.match.params.batchId !== "all" && props.match.params.batchId !== "custom" && (
      <Grid
        loading={props.loading}
        loadingTable={props.loadingTable}
        data={props.data}
        pageable={{
          buttonCount: 5,
        }}
        skip={props.paging.skip}
        take={props.paging.perPage}
        total={props.paging.totalCount}
        onPageChange={props.onPageChange}
        onRowClick={props.onRowClick}
        footer={props.footerDescription}
      >
        {props.showSelectColumn && (
          <GridColumn
            title=""
            headerCell={makeHeaderCell(CustomCheckbox, () => ({
              value: props.isSelectedAll,
              onChange: (checked) => props.onSelectAll(checked),
            }))}
            width="42px"
            cell={makeCell(Checkbox, (cellProps) => ({
              value: cellProps.dataItem.selected,
              disabled: cellProps.dataItem.disabled,
              hidden: !cellProps.dataItem.hasSelect,
              preventClick: true,
              onChange: (checked) => props.onSelectRow(checked, cellProps.dataItem),
            }))}
          />
        )}
        <GridColumn field="id" title="รหัสลีด" width="100px" />
        <GridColumn field="batchCode" title="รหัสแบทช์" width="100px" />
        <GridColumn
          field="teamId"
          title="ทีมรับผิดชอบ"
          width="120px"
          cell={makeCell(CustomInput, (cellProps) => ({
            readOnly: true,
            value: safe(
              () => props.teams.find((team) => cellProps.dataItem.teamId === team.id).name
            ),
          }))}
        />
        <GridColumn field="assigneeStaffCode" title="รหัสพนักงาน" width="120px" />
        <GridColumn field="assigneeFullname" title="ผู้ดูแลลีด" width="150px" />
        <GridColumn
          field="customerInfo"
          title="ชื่อลูกค้า"
          width="130px"
          cell={makeCell(InputName, (cellProps) => ({
            readOnly: true,
            value: cellProps.dataItem.customerInfo.nameInfo,
          }))}
        />
        <GridColumn field="customerInfo.currentAddress.subDistrict" title="ตำบล" width="100px" />
        <GridColumn field="customerInfo.currentAddress.district" title="อำเภอ" width="100px" />
        <GridColumn field="customerInfo.currentAddress.province" title="จังหวัด" width="120px" />
        <GridColumn field="customerInfo.currentAddress.villageNo" title="หมู่" width="50px" />
        <GridColumn
          field="customerInfo.gender"
          title="เพศ"
          width="60px"
          cell={makeCell(Dropdown, (cellProps) => ({
            readOnly: true,
            options: props.genderOptions,
            value: cellProps.dataItem.customerInfo.gender,
          }))}
        />
        <GridColumn field="customerInfo.age" title="อายุ" width="60px" />
        <GridColumn field="assignedNo" title="จ่ายครั้งที่" width="100px" />
        <GridColumn
          field="assignedAt"
          title="วันที่จ่ายลีด"
          width="110px"
          cell={makeCell(DatePicker, (cellProps) => ({
            readOnly: true,
            value: cellProps.dataItem.assignedAt,
          }))}
        />
        <GridColumn field="stateName" title="สถานะ" width="165px" locked />
      </Grid>
    )}

    {props.match.params.batchId === "all" && (
      <Grid
        loading={props.loading}
        loadingTable={props.loadingTable}
        data={props.data}
        pageable={{
          buttonCount: 5,
        }}
        skip={props.paging.skip}
        take={props.paging.perPage}
        total={props.paging.totalCount}
        onPageChange={props.onPageChange}
        onRowClick={props.onRowClick}
        footer={props.footerDescription}
      >
        {props.showSelectColumn && (
          <GridColumn
            title=""
            headerCell={makeHeaderCell(CustomCheckbox, () => ({
              value: props.isSelectedAll,
              onChange: (checked) => props.onSelectAll(checked),
            }))}
            width="42px"
            cell={makeCell(Checkbox, (cellProps) => ({
              value: cellProps.dataItem.selected,
              disabled: cellProps.dataItem.disabled,
              hidden: !cellProps.dataItem.hasSelect,
              preventClick: true,
              onChange: (checked) => props.onSelectRow(checked, cellProps.dataItem),
            }))}
          />
        )}
        <GridColumn
          field="customerInfo"
          title="ชื่อลูกค้า"
          width="130px"
          cell={makeCell(InputName, (cellProps) => ({
            readOnly: true,
            value: cellProps.dataItem.customerInfo.nameInfo,
          }))}
        />
        <GridColumn field="customerInfo.currentAddress.province" title="จังหวัด" width="80px" />
        <GridColumn field="customerInfo.currentAddress.district" title="อำเภอ" width="75px" />
        <GridColumn field="customerInfo.currentAddress.subDistrict" title="ตำบล" width="110px" />
        <GridColumn field="appointmentStatus" title="สถานะนัดหมาย" width="120px" />
        <GridColumn field="assigneeStaffCode" title="รหัสพนักงาน" width="120px" />
        {props.currentRole === "member.team_leader" && (
          <GridColumn field="assigneeFullname" title="ผู้ดูแลลีด" width="150px" />
        )}
        <GridColumn
          field="appointmentAt"
          title="วันนัดหมาย"
          width="115px"
          cell={makeCell(DatePicker, (props) => ({
            readOnly: true,
            value: props.dataItem.appointmentAt,
          }))}
        />

        <GridColumn
          field="customerInfo.gender"
          title="เพศ"
          width="60px"
          cell={makeCell(Dropdown, (cellProps) => ({
            readOnly: true,
            options: props.genderOptions,
            value: cellProps.dataItem.customerInfo.gender,
          }))}
        />
        <GridColumn field="customerInfo.age" title="อายุ" width="60px" />
        <GridColumn
          field="customerInfo.mobilePhoneNumber"
          title="เบอร์โทรศัพท์"
          width="130px"
          cell={makeCell(PhoneNumberInput, (cellProps) => ({
            readOnly: true,
            isMobileNumber:
              (cellProps.dataItem.customerInfo.mobilePhoneNumber || {}).length === 10
                ? true
                : false,
            value: cellProps.dataItem.customerInfo.mobilePhoneNumber,
          }))}
        />

        <GridColumn
          field="startDate"
          title="วันเริ่มขาย"
          width="115px"
          cell={makeCell(DatePicker, (props) => ({
            readOnly: true,
            value: props.dataItem.startDate,
          }))}
        />
        <GridColumn
          field="expiredDate"
          title="วันหมดอายุ"
          width="115px"
          cell={makeCell(DatePicker, (props) => ({
            readOnly: true,
            value: props.dataItem.expiredDate,
          }))}
        />
        <GridColumn field="batchCode" title="รหัสแบทช์" width="100px" />
        <GridColumn field="stateName" title="สถานะลีด" width="165px" locked />
      </Grid>
    )}

    {props.match.params.batchId === "custom" && (
      <Grid
        loading={props.loading}
        loadingTable={props.loadingTable}
        data={props.data}
        pageable={{
          buttonCount: 5,
        }}
        skip={props.paging.skip}
        take={props.paging.perPage}
        total={props.paging.totalCount}
        onPageChange={props.onPageChange}
        onRowClick={props.onRowClick}
        footer={props.footerDescription}
      >
        {props.showSelectColumn && (
          <GridColumn
            title=""
            headerCell={makeHeaderCell(CustomCheckbox, () => ({
              value: props.isSelectedAll,
              onChange: (checked) => props.onSelectAll(checked),
            }))}
            width="42px"
            cell={makeCell(Checkbox, (cellProps) => ({
              value: cellProps.dataItem.selected,
              disabled: cellProps.dataItem.disabled,
              hidden: !cellProps.dataItem.hasSelect,
              preventClick: true,
              onChange: (checked) => props.onSelectRow(checked, cellProps.dataItem),
            }))}
          />
        )}
        <GridColumn
          field="customerInfo"
          title="ชื่อลูกค้า"
          width="130px"
          cell={makeCell(InputName, (cellProps) => ({
            readOnly: true,
            value: cellProps.dataItem.customerInfo.nameInfo,
          }))}
        />
        <GridColumn
          field="customerInfo.gender"
          title="เพศ"
          width="60px"
          cell={makeCell(Dropdown, (cellProps) => ({
            readOnly: true,
            options: props.genderOptions,
            value: cellProps.dataItem.customerInfo.gender,
          }))}
        />
        <GridColumn field="customerInfo.age" title="อายุ" width="60px" />
        <GridColumn
          field="customerInfo.mobilePhoneNumber"
          title="เบอร์โทรศัพท์"
          width="130px"
          cell={makeCell(PhoneNumberInput, (cellProps) => ({
            readOnly: true,
            isMobileNumber:
              (cellProps.dataItem.customerInfo.mobilePhoneNumber || {}).length === 10
                ? true
                : false,
            value: cellProps.dataItem.customerInfo.mobilePhoneNumber,
          }))}
        />
        <GridColumn field="appointmentStatus" title="สถานะนัดหมาย" width="160px" />
        <GridColumn field="stateName" title="สถานะ" width="165px" locked />
      </Grid>
    )}
  </Fragment>
)

const enhancer = compose(
  withRouter(),
  withStores((stores) => ({
    stateOptions: stores.leadStore.$("configs.filters.state.options", []),
    genderOptions: stores.leadStore.$("configs.filters.gender.options", []),
    currentRole: stores.appStore.currentRole,
  })),
  withProps((props) => {
    const data = props.data

    data.forEach((row) => {
      row.stateName = getOptionLabel(props.stateOptions, row.state)
      row.assigneeFullname = `${row.assigneeNameInfo.prefixName || ""} ${
        row.assigneeNameInfo.firstName || ""
      } ${row.assigneeNameInfo.lastName || ""}`
    })

    return {
      paging: {
        ...props.paging,
        skip: (props.paging.page - 1) * props.paging.perPage,
      },
      data,
    }
  }),
  withProps((props) => {
    let footerDescription

    if (props.match.params.batchId === "custom") {
      footerDescription = `ทั้งหมด ${props.footer.leadsCount}`
    } else if (
      props.currentRole === "member.lead_admin" ||
      props.currentRole === "member.team_leader"
    ) {
      footerDescription = `จ่ายแล้ว : ${props.footer.assignedLeadsCount} จากทั้งหมด ${props.footer.leadsCount}`
    } else if (props.currentRole === "member.salesman") {
      footerDescription = `ขายได้ : ${props.footer.completedLeadsCount} จากทั้งหมด ${props.footer.leadsCount}`
    }

    return {
      footerDescription: footerDescription,
    }
  }),
  withProps((props) => {
    const data = props.data
    const isSelectedAll = data.length > 0 ? data.every((row) => row.selected) : false

    return {
      isSelectedAll,
    }
  }),
  withHandlers({
    onRowClick: (props) => async (event) => {
      if (props.onRowClick) {
        await props.onRowClick(event)
      }
      if (props.rowPath) {
        const rowPath =
          props.rowPath instanceof Function ? props.rowPath(event.dataItem) : props.rowPath
        props.history.push(rowPath)
      }
    },
    onPageChange: (props) => async (event) => {
      if (props.onPageChange) {
        const page = event.page.skip / event.page.take + 1
        await props.onPageChange(page, event)
      }
    },
  })
)

export default enhancer(Table)
