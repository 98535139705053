import { findKey } from "lodash"
import { isClient } from "@common/helper"

const config = {
  production: {
    name: "Production",
    siteRoot: "https://life-backoffice.tqm.co.th",
    oldSiteRoot: "https://life-product.tqm.co.th",
    tqldApiEndpoint: "https://life-api.tqm.co.th",
    slackWebhookUrl:
      "https://hooks.slack.com/services/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",
    agenttoolsRoot: "https://agent-tools.tqm.co.th",
  },
  uat: {
    name: "Uat",
    siteRoot: "https://life-backoffice-uat.tqm.co.th",
    oldSiteRoot: "https://life-product-uat.tqm.co.th",
    tqldApiEndpoint: "https://life-api-uat.tqm.co.th",
    slackWebhookUrl:
      "https://hooks.slack.com/services/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",
    agenttoolsRoot: "https://dev.agent-tools.tqld.xyz",
  },
  staging: {
    name: "Staging",
    siteRoot: "https://backoffice-stg.tqm.tqld.xyz",
    oldSiteRoot: "https://product-stg.tqm.tqld.xyz",
    tqldApiEndpoint: "https://api-stg.tqm.tqld.xyz",
    slackWebhookUrl:
      "https://hooks.slack.com/services/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",
    agenttoolsRoot: "https://dev.agent-tools.tqld.xyz",
  },
  develop: {
    name: "Develop",
    siteRoot: "https://backoffice-dev.tqm.tqld.xyz",
    oldSiteRoot: "https://product-dev.tqm.tqld.xyz",
    tqldApiEndpoint: "https://api-dev.tqm.tqld.xyz",
    slackWebhookUrl:
      "https://hooks.slack.com/services/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",
    agenttoolsRoot: "https://dev.agent-tools.tqld.xyz",
  },
  local: {
    name: "Local",
    siteRoot: `http://localhost:${process.env.PORT}`,
    oldSiteRoot: process.env.REACT_APP_PRODUCT,
    tqldApiEndpoint: process.env.REACT_APP_API_ENDPOINT,
    slackWebhookUrl:
      "https://hooks.slack.com/services/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",
    agenttoolsRoot: process.env.REACT_APP_AGENT_TOOLS,
  },
}

const getEnvByHost = () =>
  isClient
    ? findKey(config, {
        siteRoot: window.location.origin,
      })
    : null

export const env = process.env.APP_ENV || getEnvByHost() || "local"

export default config[env]
