import React from "react"
import { compose, withHandlers, withProps } from "recompose"
import { withField, withCaption } from "@enhancers"
import { Dropdown } from "@components"
import { range, includes } from "lodash"
import styled from "@emotion/styled"

const Layout = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 20px;
`
const SubDetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 115px;
`
const SubToppic = styled.div`
  min-width: 45px;
  color: #666666;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  font-weight: bold;
`
const SubDetail = styled.div`
  color: #666666;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  margin-left: 26px;

  &.noWrapClass {
    white-space: nowrap;
  }
`
const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 388px;
  min-height: 58px;
  padding: 20px 0;
  margin-left: 20px;
`
const DropdownContainer = styled.div`
  padding: 10px 0;
  .k-widget {
    width: 188px;
  }
  .k-widget:first-child {
    margin-right: 20px;
  }
`

const InputQuarterlyDate = (props) => (
  <Layout className={props.className || "" + props.name}>
    {props.readOnly ? (
      <ContentContainer>
        <ColumnContainer>
          <SubDetailContainer>
            <SubToppic>วันที่</SubToppic>
            <SubDetail>{props.value.day}</SubDetail>
          </SubDetailContainer>
        </ColumnContainer>
        <ColumnContainer>
          <SubDetailContainer>
            <SubToppic>เดือน</SubToppic>
            <SubDetail>
              {(props.monthOptions.find((item) => item.value === props.value.month) || {}).label}
            </SubDetail>
          </SubDetailContainer>
        </ColumnContainer>
      </ContentContainer>
    ) : (
      <DropdownContainer>
        <Dropdown
          placeholder="วัน"
          value={props.value.day}
          onChange={props.makeOnChange("day")}
          options={props.dayOptions}
          touched={props.touched}
          valid={props.valid}
          disabled={props.disabled}
        />
        <Dropdown
          placeholder="เดือน"
          value={props.value.month}
          onChange={props.makeOnChange("month")}
          options={props.monthOptions}
          touched={props.touched}
          valid={props.valid}
          disabled={props.disabled}
        />
      </DropdownContainer>
    )}
  </Layout>
)

const enhancer = compose(
  withField(),
  withCaption(),
  withHandlers({
    makeOnChange: (props) => (fieldName) => (value) => {
      const month = fieldName === "month" ? value : props.value.month
      const day = fieldName === "day" ? value : props.value.day

      if (includes(["4", "6", "9", "11"], month)) {
        props.onChange({
          ...props.value,
          ["month"]: month,
          ["day"]: parseInt(day) > 30 ? "30" : day,
        })
      } else if (month === "2") {
        props.onChange({
          ...props.value,
          ["month"]: month,
          ["day"]: parseInt(day) > 28 ? "28" : day,
        })
      } else {
        props.onChange({ ...props.value, [fieldName]: value })
      }
    },
  }),
  withProps((props) => ({
    value: props.value || {},
    valid: !props.touched || props.valid,
    monthOptions: [
      { label: "มกราคม", value: "1" },
      { label: "กุมภาพันธ์", value: "2" },
      { label: "มีนาคม", value: "3" },
      { label: "เมษายน", value: "4" },
      { label: "พฤษภาคม", value: "5" },
      { label: "มิถุนายน", value: "6" },
      { label: "กรกฎาคม", value: "7" },
      { label: "สิงหาคม", value: "8" },
      { label: "กันยายน", value: "9" },
      { label: "ตุลาคม", value: "10" },
      { label: "พฤศจิกายน", value: "11" },
      { label: "ธันวาคม", value: "12" },
    ],
    dayOptions: range(1, 32).map((v) => `${v}`),
  }))
)

export default enhancer(InputQuarterlyDate)
