import React from "react"
import styled, { keyframes } from "@styled"

const smallmoveKeyFrames = keyframes`
  0% {
    top: 100%;
    left: 45%;
    opacity: 0;
  }
  25% {
    top: 50%;
    left: 40%;
    opacity: 0.7;
  }
  50% {
    top: 30%;
    left: 55%;
    opacity: 0.4;
  }
  75% {
    top: 40%;
    left: 40%;
    opacity: 0.6;
  }
  100% {
    top: 10%;
    left: 45%;
    opacity: 0;
  }
`

const medmoveKeyFrames = keyframes`
  0% {
    top: 0px;
    left: 20%;
    opacity: 0;
  }
  25% {
    top: 300px;
    left: 80%;
    opacity: 0.7;
  }
  50% {
    top: 240px;
    left: 55%;
    opacity: 0.4;
  }
  75% {
    top: 100px;
    left: 40%;
    opacity: 0.6;
  }
  100% {
    top: 0px;
    left: 20%;
    opacity: 0;
  }
`

const bigmoveKeyFrames = keyframes`
  0% {
    top: 0px;
    right: 4%;
    opacity: 0;
  }
  25% {
    top: 100px;
    right: 40%;
    opacity: 0.4;
  }
  50% {
    top: 240px;
    right: 45%;
    opacity: 0.8;
  }
  75% {
    top: 100px;
    right: 35%;
    opacity: 0.6;
  }
  100% {
    top: 0px;
    right: 4%;
    opacity: 0;
  }
`

const Layout = styled.div`
  width: 100%;
  height: 100%;
  font-family: "Raleway", sans-serif;
  margin: 0;
  padding: 0;
  min-height: 350px;
  overflow: hidden;
  margin-top: 100px;

  .circles {
    text-align: center;
    position: relative;
  }

  .circles p {
    font-size: 240px;
    color: #fff;
    position: relative;
    z-index: 2;
    margin: 0;
  }

  .circles p small {
    font-size: 40px;
    line-height: 100%;
    vertical-align: top;
  }

  .circles .circle.small {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    background: #999;
    position: fixed;
    z-index: 1;
    top: 80px;
    left: 50%;
    animation: 7s ${smallmoveKeyFrames} infinite cubic-bezier(1, 0.22, 0.71, 0.98);
    animation-delay: 1.2s;
    opacity: 0;
  }

  .circles .circle.med {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background: #999;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 10%;
    animation: 7s ${medmoveKeyFrames} infinite cubic-bezier(0.32, 0.04, 0.15, 0.75);
    animation-delay: 0.4s;
    opacity: 0;
  }

  .circles .circle.big {
    width: 400px;
    height: 400px;
    border-radius: 50%;
    background: #999;
    position: fixed;
    z-index: 1;
    top: 200px;
    right: 0;
    animation: 8s ${bigmoveKeyFrames} infinite;
    animation-delay: 1s;
    opacity: 0;
  }
`

const NotFoundPage = () => (
  <Layout>
    <div className="circles">
      <p>
        404
        <br />
        <small>PAGE NOT FOUND</small>
      </p>
      <span className="circle big" />
      <span className="circle med" />
      <span className="circle small" />
    </div>
  </Layout>
)

export default NotFoundPage
