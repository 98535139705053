import { Button } from "@progress/kendo-react-buttons"
import { compose, withProps, withHandlers, defaultProps, withState } from "recompose"
import { omitProps, withRouter, withHidden, withAuthorized, withCypress } from "@enhancers/index"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import isPromise from "is-promise"

const NormalStyle = css`
  border: 1px solid #0275d8;
  color: #0275d8;

  :hover {
    background-color: #ffffff;
    border: 1px solid #025aa5;
    color: #025aa5;
  }
`
const SecondaryStyle = css`
  border: 1px solid #627b8b;
  color: #627b8b;

  :hover {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #294558;
    color: #294558;
  }
`
const DeleteStyle = css`
  border: 1px solid #666666;
  color: #666666;

  :hover {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #d9534f;
    color: #d9534f;
  }
`
const AgreeStyle = css`
  border: 1px solid #5cb85c;
  color: #5cb85c;

  :hover {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #3a783a;
    color: #3a783a;
  }
`

const StyledMapping = {
  normal: NormalStyle,
  secondary: SecondaryStyle,
  delete: DeleteStyle,
  agree: AgreeStyle,
  default: NormalStyle,
}

const CustomButton = styled(Button)`
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  background-color: #ffffff;
  border-radius: 4px;
  padding-left: 16px;
  padding-right: 16px;
  width: 131px;
  height: 38px;

  /* overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  line-height: 36px;
  padding: 0 16px;

  > .k-icon {
    margin-top: -4px;
  } */

  opacity: 1;

  :disabled {
    color: #eeeef2;
    border-color: #eeeef2;
    opacity: 1;
  }

  ${(props) => props.loading && "pointer-events: none;"}

  ${(props) => StyledMapping[props.styletype] || StyledMapping.default}
`

const enhancer = compose(
  withCypress("Button", { inspect: true }),
  withHidden(),
  withAuthorized(),
  withState("progress", "setProgress", false),
  defaultProps({
    cache: {},
  }),
  withRouter(),
  defaultProps({ type: "button", cache: {} }),
  // lifecycle({
  //   componentDidMount() {
  //     this.props.cache.destroyed = false
  //   },
  //   componentWillUnmount() {
  //     this.props.cache.destroyed = true
  //   },
  // }),
  withHandlers({
    onClick: (props) => async (event) => {
      if (props.onClick) {
        props.setProgress(true)
        const action = props.onClick(event)

        if (isPromise(action)) {
          await action
        }
        // if (!props.cache.destroyed) {
        props.setProgress(false)
        // }
      }
      props.to && props.history.push(props.to)
    },
  }),
  withProps(
    (props) =>
      (props.progress || props.loading) && {
        icon: "loading",
      }
  ),
  withProps((props) => {
    if (props.awesomeIcon) {
      const awesomeIcon =
        typeof props.awesomeIcon === "string" ? ["fas", props.awesomeIcon] : props.awesomeIcon
      const [awesomeIconStyle, awesomeIconName] = awesomeIcon

      return {
        iconClass: `k-button-icontext k-icon ${awesomeIconStyle} fa-${awesomeIconName}`,
      }
    }
  }),
  omitProps(
    "authorized",
    "staticContext",
    "progress",
    "setProgress",
    "history",
    "location",
    "match",
    "awesomeIcon",
    "cache"
  )
)

export default enhancer(CustomButton)
