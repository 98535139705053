import React from "react"
import styled from "@emotion/styled"
import { compose, lifecycle, withProps, withState } from "recompose"
import { ProductShowPage } from "@pages/orders/show/ProductDetail/index"
import { withStores, withRouter, withForm, omitProps } from "@enhancers/index"
import { toCurrency } from "@common/helper"
import { LoadingScope, BreadCrumb } from "@components"
import * as path from "@common/path"

const Layout = styled.div`
  position: relative;
`
const CustomBreadCrumbLayout = styled.div`
  a {
    font-size: 10px !important;
  }
`
const ProductInfo = (props) => (
  <Layout>
    <CustomBreadCrumbLayout>
      <BreadCrumb links={props.listBreadCrumbs} />
    </CustomBreadCrumbLayout>

    <ProductShowPage {...props} />
    {props.loading && <LoadingScope fullScreen />}
  </Layout>
)

const enhancer = compose(
  withRouter(),
  withState("loading", "setLoading", false),
  withStores((stores, props) => ({
    fetchOrderProductById: stores.orderStore.fetchOrderProductById,
    orderProduct: stores.orderStore.orderProduct,
    riderProducts: stores.orderStore.riderProducts,
    orderId: props.match.params.orderId,
    currentRole: stores.appStore.currentRole,
  })),
  withForm({ form: "OrderProductDetail" }),
  withProps((props) => {
    const { productDetail, benefit, sumInsured = 0, premium = 0 } = props.orderProduct || {}
    let sumInsuredSummary = parseFloat(sumInsured)
    let premiumSummary = parseFloat(premium)
    let riderPremiumSummary = 0
    let riderSumInsurerSummary = 0
    props.riderProducts &&
      props.riderProducts.forEach((item) => {
        const { sumInsured = 0, premium = 0 } = item
        riderPremiumSummary += parseFloat(premium)
        riderSumInsurerSummary += parseFloat(sumInsured)
        sumInsuredSummary += parseFloat(sumInsured)
        premiumSummary += parseFloat(premium)
      })

    const stickySummary = parseInt(props.orderProduct.premium) + riderPremiumSummary

    return {
      id: props.orderProduct.id,
      productDetail: {
        orderProduct: props.orderProduct,
        companyLogoUrl: props.orderProduct.companyLogoUrl,
        name: productDetail && productDetail.policyName,
        companyName: productDetail && productDetail.insuranceCompanyName,
        sumInsured: parseInt(props.orderProduct.sumInsured),
        premium: parseInt(props.orderProduct.premium),
        yieldToMaturity: productDetail && productDetail.yieldToMaturity,
        avgRefund: productDetail && productDetail.avgRefund,
        insuranceCoverageTable: benefit || { fullBenefit: null },
        maxAgeInsured: productDetail && productDetail.maxAgeInsured,
        minAgeInsured: productDetail && productDetail.minAgeInsured,
        noonStar: productDetail && productDetail.noonStar,
        irr: productDetail && productDetail.irr,
        protectEndAge: productDetail && productDetail.protectionPeriod,
        minAgeAnnuity: productDetail && productDetail.minAgeAnnuity,
        maxAgeAnnuity: productDetail && productDetail.maxAgeAnnuity,
        payPeriodText: props.orderProduct.payPeriodText,
        insuranceType: props.orderProduct.insuranceType,
        sumInsuredSummary,
        premiumSummary,
        riderPremiumSummary,
        riderSumInsurerSummary,
      },
      riderPolicies: props.riderProducts,
      readOnly: true,
      stickySummary,
    }
  }),
  withProps((props) => {
    let listBreadCrumbs = [
      {
        label: "คำสั่งซื้อ",
        path: path.ordersPath(),
      },
      {
        label: props.currentRole === "member.biz_dev" ? "สรุปแบบประกัน" : "รายละเอียดคำสั่งซื้อ",
        path: path.orderPath(props.orderId),
      },
    ]

    return {
      listBreadCrumbs: listBreadCrumbs,
    }
  }),
  lifecycle({
    async componentWillMount() {
      this.props.setLoading(true)

      const response = await this.props.fetchOrderProductById(this.props.orderId)

      const riders = []

      response.riderProducts.forEach((riderProduct) => {
        let params = {}
        params.name = riderProduct.productDetail.policyName
        params.state = riderProduct.state
        params.companyName = riderProduct.productDetail.insuranceCompanyName
        params.type = riderProduct.type === "CiProduct" ? "ci" : "hs"
        params.sumInsured = riderProduct.sumInsured
        params.premium = toCurrency(riderProduct.premium)

        riders.push(params)
      })

      const newProps = {}
      newProps.riders = riders

      this.props.initialize(newProps)
      this.props.setLoading(false)
    },
  }),
  omitProps("fetchOrderProductById", "orderId", "orderProduct")
)

export default enhancer(ProductInfo)
