import React from "react"
import styled from "@emotion/styled"
import { compose, withHandlers, withProps } from "recompose"
import { withForm, withStores } from "@enhancers"
import {
  TextArea,
  Form,
  Field,
  DialogActionsBar,
  Dialog,
  Button,
  Dropdown,
  DatePicker,
} from "@components"

const Layout = styled(Form)`
  padding: 24px 45px 36px 45px;
`

const CustomDialog = styled(Dialog)``

const CancelButton = styled(Button)`
  overflow: unset !important;
  height: 52px;
  color: #666666;
`

const SubmitButton = styled(Button)`
  overflow: unset !important;
  height: 52px;
  background-color: #0275d8;
  color: white;

  :hover {
    background-color: #0275d8;
    color: white;
    border-color: white;
  }
`

const ActionCodeCreateModal = (props) => (
  <CustomDialog title={props.title} onClose={props.onClose} isShowModal={props.open}>
    <Layout>
      <Field
        name="actionType"
        caption="Action Code"
        component={Dropdown}
        options={props.actionCodesOptions}
        validate="required"
      />
      <Field name="requestRemark" caption={props.actionRemarkCaption} component={TextArea} />
      <Field
        name="actionStatus"
        caption="สถานะ"
        component={Dropdown}
        options={props.actionStatusOptions}
        validate="required"
      />
      <Field
        component={DatePicker}
        name="dueDate"
        caption="ครบกำหนดแก้ไข"
        validate="required"
        placeholder="ครบกำหนดแก้ไข"
        min={new Date()}
      />
    </Layout>

    <DialogActionsBar>
      <CancelButton type="button" onClick={props.onClose} children="ยกเลิก" />
      <SubmitButton type="primary" onClick={props.onSubmit} children="ยืนยัน" />
    </DialogActionsBar>
  </CustomDialog>
)

const enhancer = compose(
  withStores((stores, props) => ({
    currentRole: stores.appStore.currentRole,
    actionStatusOptions: stores.actionCodeStore.$("configs.attributes.actionStatus.options", []),
    actionCodesOptions: stores.actionCodeStore.$("configs.attributes.actionCode.options", []),
  })),
  withProps((props) => ({
    initialValues: {
      dueDate: new Date(),
    },
    actionRemarkCaption: props.currentRole === "member.biz_dev" ? "แจ้งแก้ไข" : "ถาม",
  })),
  withForm({ form: "ActionCodeForm" }),
  withHandlers({
    onClose: (props) => () => {
      props.reset(props.from)
      props.onClose()
    },
    onSubmit: (props) =>
      props.handleSubmit(async (values) => {
        await props.onAfterActionCode(values)
        props.reset(props.from)
        props.onClose()
      }),
  })
)
export default enhancer(ActionCodeCreateModal)
