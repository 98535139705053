import React from "react"
import { DatePicker, Calendar } from "@progress/kendo-react-dateinputs"
import { compose, withHandlers, withProps, withState, lifecycle } from "recompose"
import { withField, withReadOnly, withCaption, omitProps, withHidden } from "@enhancers/index"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import moment from "moment"
import { zipObject } from "lodash"

const monthFullEnList = "January_February_March_April_May_June_July_August_September_October_November_December".split(
  "_"
)
const monthFullThList = "มกราคม_กุมภาพันธ์_มีนาคม_เมษายน_พฤษภาคม_มิถุนายน_กรกฎาคม_สิงหาคม_กันยายน_ตุลาคม_พฤศจิกายน_ธันวาคม".split(
  "_"
)
const monthShortEnList = "Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec".split("_")
const monthShortThList = "ม.ค._ก.พ._มี.ค._เม.ย._พ.ค._มิ.ย._ก.ค._ส.ค._ก.ย._ต.ค._พ.ย._ธ.ค.".split("_")
const dayShortEnList = "Mo_Tu_We_Th_Fr_Sa_Su".split("_")
const dayShortThList = "จ._อ._พ._พฤ._ศ._ส._อา.".split("_")

const translateList = {
  today: zipObject(["TODAY"], ["วันนี้"]),
  monthFull: zipObject(monthFullEnList, monthFullThList),
  monthShort: zipObject(monthShortEnList, monthShortThList),
  dayShort: zipObject(dayShortEnList, dayShortThList),
}

const reflowBuddishYearOnCalendar = () => {
  const isTH = moment.locale() === "th"

  if (isTH) {
    let $targets =
      document.querySelectorAll(
        ".k-calendar-navigation .k-content li span, .k-calendar-header .k-title, .k-calendar tbody tr:first-child th, .k-calendar-view.k-calendar-decadeview .k-link, .k-calendar-view.k-calendar-centuryview .k-link"
      ) || []

    $targets.forEach((target) => {
      target.innerHTML = target.innerHTML.replace(/\d{4}/g, (input) => {
        const year = parseInt(input)
        if (isTH) {
          if (year < 2300) {
            return year + 543
          } else {
            return year
          }
        } else {
          if (year > 2300) {
            return year - 543
          } else {
            return year
          }
        }
      })
    })

    $targets = document.querySelectorAll(".k-calendar-navigation .k-content li span") || []

    $targets.forEach((target) => {
      const to = translateList.monthShort[target.innerHTML]
      if (to) {
        target.innerHTML = to
      }
    })

    $targets =
      document.querySelectorAll(
        ".k-calendar-header .k-title, .k-calendar tbody tr:first-child th"
      ) || []

    $targets.forEach((target) => {
      const from = Object.keys(translateList.monthFull).find(
        (f) => target.innerHTML.indexOf(f) >= 0
      )
      const to = translateList.monthFull[from]

      if (to) {
        target.innerHTML = target.innerHTML.replace(from, to)
      }
    })

    $targets = document.querySelectorAll(".k-calendar-weekdays th") || []
    $targets.forEach((target) => {
      const to = translateList.dayShort[target.innerHTML]
      if (to) {
        target.innerHTML = to
      }
    })

    $targets = document.querySelectorAll(".k-calendar-view.k-calendar-yearview td .k-link") || []
    $targets.forEach((target) => {
      const to = translateList.monthShort[target.innerHTML]
      if (to) {
        target.innerHTML = to
      }
    })

    const $target = document.querySelector(".k-calendar .k-today")

    if ($target) {
      $target.innerHTML = $target.innerHTML.replace("TODAY", "วันนี้")
    }

    const monthViewScroll = document.querySelector(
      ".k-calendar .k-calendar-monthview .k-scrollable"
    )
    if (monthViewScroll) {
      monthViewScroll.removeEventListener("scroll", reflowBuddishYearOnCalendar)
      monthViewScroll.addEventListener("scroll", reflowBuddishYearOnCalendar)
    }
  }
}

Calendar.prototype.componentDidUpdate = function (_, prevState) {
  if (prevState.activeView !== this.state.activeView) {
    this.scrollSyncService.configure(this.state.activeView)
  }
  if (this.calendarViewList) {
    this.isActive ? this.calendarViewList.focusActiveDate() : this.calendarViewList.blurActiveDate()
  }
  this.didNavigationChange = false
  this.oldValue = this.value

  reflowBuddishYearOnCalendar()
}

const Layout = styled.div`
  position: relative;
  width: 100%;
  min-height: 38px;

  .k-dateinput-wrap input {
    pointer-events: none;
    opacity: 0;
  }

  :hover {
    > span {
      .k-dateinput-wrap {
        background-color: #eeeef2;
      }

      .k-select {
        background-color: #eeeef2;
      }
    }
  }

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
    `}
`

const Placeholder = styled.div`
  position: absolute;
  background-color: transparent;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  color: #bec3c7;
  /* width: 145px; */
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  opacity: ${(props) => (props.showPlaceholder ? "1" : "0")};
`
const CancelButton = styled.div`
  position: absolute;
  left: 100px;
  background-color: transparent;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  opacity: 0.7;

  cursor: pointer;
  :hover {
    opacity: 1;
  }
`

const CustomDatePicker = styled(DatePicker)`
  width: 100%;
  min-height: 38px;

  :hover {
    .k-select {
      background-color: transparent;
      border: none;
    }
  }

  .k-select {
    background-color: transparent;
    border: none;
    border-left: transparent !important;
    :hover {
      background-color: transparent;
      border: none;
    }
  }

  .k-picker-wrap {
    border-color: #eeeef2;
  }

  .k-icon {
    color: #666666;
  }
  .k-dateinput-wrap {
    > input {
      font-family: Sarabun, sans-serif;
      font-size: 16px;
    }
    > input:first-of-type {
      ${(props) =>
        props.showPlaceholder &&
        css`
          pointer-events: none;
          color: transparent;
          user-select: none;
        `};
    }

    > input:first-of-type {
      ${(props) =>
        props.value &&
        css`
          padding-right: 10px;
        `};
    }
  }
  .k-dateinput-wrap {
    background-color: transparent;
  }
  ${(props) =>
    props.error &&
    css`
      .k-picker-wrap.k-state-default {
        border-color: #dc3545;
      }
    `}
`

const ReadOnly = styled.div`
  min-height: 33.5px;
  display: flex;
  align-items: center;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  color: #666666;
`

const DateDisplay = styled.div`
  position: absolute;
  top: 2px;
  bottom: 2px;
  left: 2px;
  display: flex;
  align-items: center;
  padding: 0px 8px;
`
const Error = styled.div`
  font-family: Sarabun, sans-serif;
  font-size: 12px;
  color: #dc3545;
  margin-top: 4px;
`

const DateField = (props) => (
  <>
    <Layout className={props.className} disabled={props.disabled}>
      <Placeholder showPlaceholder={props.showPlaceholder} onClick={props.onPlaceholder}>
        {props.placeholder}
      </Placeholder>

      {props.value && (
        <CancelButton onClick={props.onCancel}>
          <span className="k-icon k-i-close"></span>
        </CancelButton>
      )}

      <CustomDatePicker showPlaceholder={props.showPlaceholder} {...props} error={!props.valid} />

      <DateDisplay>{props.dateForDisplay}</DateDisplay>
    </Layout>
    {props.showErrorMessage && props.error && <Error>{props.error}</Error>}
  </>
)

const enhancer = compose(
  withHidden(),
  withField(),
  withState("UUID", "setUUID", () => `datePicker-${Math.floor(Math.random() * 10000)}`),
  withProps((props) => ({
    className: `${props.className || ""} ${props.UUID} ${props.name}`,
  })),
  withHandlers({
    onChange: (props) => (e) => {
      props.onChange(e.target.value)
    },
    onPlaceholder: (props) => () => {
      const $dataInput = document.querySelector(`.${props.UUID} .k-dateinput`)
      const $dataSelect = document.querySelector(
        `.${props.UUID} .k-select:not(.custom-date-picker)`
      )
      const $dataSelected = document.querySelector(`.${props.UUID} .k-select.custom-date-picker`)

      if ($dataInput && $dataSelect) {
        $dataSelect.click()
        $dataSelect.classList.add("custom-date-picker")
      } else {
        if ($dataSelected) {
          $dataSelected.classList.remove("custom-date-picker")
        }
      }
    },
    onCancel: (props) => () => {
      props.onChange(null)
    },
  }),
  withProps((props) => ({
    valid: !props.touched || props.valid,
    value: props.value || null,
    showPlaceholder: props.placeholder && !props.value,
    format: "dd/MM/yyyy",
    formatPlaceholder: { day: "วัน", month: "เดือน", year: "ปี" },
  })),
  withCaption(),
  withProps((props) => ({
    dateForDisplay: props.value ? moment(props.value).format("DD/MM/YYYY") : null,
  })),
  withReadOnly((props) => <ReadOnly>{props.value && props.dateForDisplay}</ReadOnly>),
  omitProps("onBlur"),
  lifecycle({
    componentDidUpdate() {},
  })
)

export default enhancer(DateField)
