import React, { Fragment } from "react"
import styled from "@emotion/styled"
import { compose, withState, withHandlers, lifecycle } from "recompose"
import { Button, Input, Icon, Avatar, Checkbox, Field, CurrencyInput } from "@components"
import { withStores, withRouter } from "@enhancers/index"
import DefaultDetailModal from "./DefaultDetailModal"
import defaultLogo from "@assets/images/img-no-insurance.png"
import moment from "moment"
import { Lightbox } from "react-modal-image"
import { CalculatePremiumErrorMessage } from "./edit"
import BenefitRawModal from "./BenefitRawModal"
import { desktopSize } from "@common/styled"
import { Dropdown } from "@components"
import { get } from "lodash"
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout"

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: auto;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  margin-bottom: 24px;
  background: ${(props) => (props.isSelected ? "#deeaf6" : "#fff")};
  border: 1px solid #eeeef2;
  border-radius: 4px;
  padding: 15px 15px 0 20px;
  width: 100%;

  ${(props) =>
    desktopSize(`
      max-width: 830px !important;

  `)}
`

const DetailButton = styled(Button)`
  display: ${(props) => (props.readOnly ? "none" : "flex")};
  background: #ffffff;
  border-radius: 4px;
  width: 139px;
  height: 38px;
  margin-top: 5px;
  align-self: flex-end;
`

const DetailField = styled(Field)`
  font-weight: normal;
  height: 30px;
  font-size: 15px;
  margin-left: 9px;
  > div:first-of-type {
    min-width: 106px;
    text-align: left;
    color: #666666;
  }
  > div:last-of-type {
    margin-left: 24px;
    > div {
      justify-content: flex-end;
      padding-top: 2px;
    }
  }
`

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  .k-mid {
    display: flex;
    align-items: center;
    > .k-checkbox-wrapper {
      display: flex;
    }
    > span {
      font-size: 12px !important ;
      color: ${(props) => (props.noSelected ? "#0275d8" : "#ffffff")};
    }
  }
`
const ProductNameContainer = styled.div`
  display: flex;
`
const BodyContainer = styled.div`
  display: ${(props) => (props.hidden ? "none" : "flex")};
  height: auto;
  justify-content: space-between;
  margin-bottom: 23px;
  align-items: center;
  input {
    text-align: right;
  }
`
const Unit = styled.div`
  min-width: 60px;
  margin-left: 4px;
  align-self: center;
  overflow: visible;
  white-space: nowrap;
`
const CheckboxField = styled(Field)``

const SumInsuredField = styled(Field)`
  min-height: 38px;
  font-weight: normal;
  > div:first-of-type {
    min-width: fit-content;
    margin-bottom: unset;
    margin-right: 15px;
  }
  > div:last-of-type {
    input {
    }
    div {
      justify-content: flex-end;
    }
  }
  .css-ffiurl--withCaption--Caption--withCaption--Caption {
    font-weight: normal;
  }
`
const CompanyAvatar = styled(Avatar)`
  border-radius: 50px;
`
const NoContent = styled.div`
  display: flex;
  justify-content: center;
`
const PaymentIcon = styled(Icon)`
  text-align: center;
  display: ${(props) => props.hidden && "none"};
  visibility: ${(props) => props.visibility || "visible"};
`
const EditIcon = styled.div`
  span {
    color: #0275d8;
    display: ${(props) => props.disabled && "none"};
  }

  cursor: ${(props) => !props.disabled && "pointer"};
  min-width: 40px;
  max-width: 40px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const WrapRiderPayment = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const WrapIcon = styled.div`
  width: 50px;
  height: 38px;
  min-width: 40px;
  max-width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`
const UnitSumInsured = styled.span`
  margin-left: 4px;
`
const OptionContainer = styled.div`
  display: flex;
  padding: 0px 30px;
  margin-bottom: 10px;
`

const CustomCurrencyInput = styled(CurrencyInput)`
  ${(props) => props.disabled && "input { color: #666666 !important; }"}
`
const PanelLine = styled.div`
  width: 100%;
  border-top: 1px solid #eeeef2;
`
const ConditionContent = styled.div`
  background-color: transparent;
  margin-top: -5px;
  padding-bottom: 23px;
`
const CustomPanelBar = styled(PanelBar)`
  background-color: transparent;
  width: 100%;
  border: 0;

  .k-animation-container {
    z-index: unset;

    .k-panel.k-group {
      .k-item.k-state-default {
        .k-link {
          .k-icon.k-i-arrow-s.k-panelbar-expand {
            right: 33.75rem;
          }

          .k-icon.k-i-arrow-n.k-panelbar-collapse {
            right: 33.75rem;
          }
        }
      }
    }
  }

  .k-content {
    background-color: transparent;
  }

  .k-item.k-state-default {
    .k-link {
      padding: 0px;
      background-color: #fff;
      color: #0275d8;
      font-weight: bold;
      font-family: Sarabun, sans-serif;
      text-decoration: underline;
    }

    .k-link.k-state-selected.k-state-focused {
      box-shadow: none;
    }

    .k-link.k-header {
      background-color: transparent;
      color: #0275d8;
      font-family: Sarabun, sans-serif;
      font-weight: bold;
      text-decoration: none;
      height: 49px;

      :hover {
        background-color: transparent;
        color: #0275d8;
      }
    }

    .k-link.k-header.k-state-selected {
      background-color: transparent;
      color: #0275d8;

      :hover {
        background-color: transparent;
        color: #0275d8;
      }
    }
  }

  .k-item.k-state-default.k-state-expanded {
    .k-link {
      background-color: transparent;
      color: #0275d8;
      font-weight: bold;
      font-family: Sarabun, sans-serif;
      text-decoration: underline;
    }

    .k-link.k-state-selected {
    }

    .k-link.k-state-selected.k-state-focused {
    }

    .k-link.k-header {
      background-color: transparent;
      color: #0275d8;
      text-decoration: none;

      :hover {
        background-color: transparent;
        color: #0275d8;
      }
    }

    .k-link.k-header.k-state-selected {
      background-color: transparent;
      color: #0275d8;

      :hover {
        background-color: transparent;
        color: #0275d8;
      }
    }
  }

  .k-icon.k-i-arrow-n.k-panelbar-collapse {
    background-color: #eeeef2;
    border-radius: 50%;
    right: 0;

    ::before {
      color: #627b8b;
      content: "\\e013";
    }
  }

  .k-icon.k-i-arrow-s.k-panelbar-expand {
    background-color: #eeeef2;
    border-radius: 50%;
    right: 0;

    ::before {
      color: #627b8b;
      content: "\\e015";
    }
  }
`

const additionalContract = (props) => {
  let hasRider = false

  return (
    <React.Fragment>
      {props.fields.map((rider, index) => {
        const riderData = props.fields.get(index) || {}
        const selected = props.fields.get(index) && props.fields.get(index).selected
        const isFree = props.fields.get(index) && props.fields.get(index).isFree
        const hasSumInsured = props.fields.get(index) && props.fields.get(index).hasSumInsured
        const lockable = riderData.lockable
        hasRider = hasRider || selected

        if (index === props.fields.length - 1 && !hasRider && !props.isEdit) {
          return <NoContent>ไม่มีสัญญาเพิ่มเติม</NoContent>
        }

        return (
          <Layout
            id={`rider_card_${index}`}
            key={index}
            isEdit={props.isEdit}
            isSelected={props.isEdit && selected}
          >
            <HeaderContainer>
              <ProductNameContainer>
                {props.isEdit && (
                  <CheckboxField
                    name={`${rider}.selected`}
                    component={Checkbox}
                    readOnly={
                      isFree ||
                      props.readOnly ||
                      !props.isEdit ||
                      props.preventDeselectRider ||
                      lockable
                    }
                    onChange={async () => {
                      const rider = props.fields.get(index)
                      if (rider.selected) {
                        const indexEditRiderPremiumList = [...props.indexEditRiderPremiumList]
                        indexEditRiderPremiumList[index] = false
                        props.setIndexEditRiderPremiumList(indexEditRiderPremiumList)
                        if (rider.sumInsured) {
                          const response = await props.getCiSearchDetail({
                            id: rider.id,
                            birthdate: props.location.query.birthdate,
                            gender: props.location.query.gender || "male",
                            sumInsured: rider.sumInsured || 0,
                            occupationCategory: props.occupationCategory,
                            occupation: props.occupation,
                          })
                          props.change(`riders[${index}].premium`, response.premium)
                        }
                      }
                    }}
                  />
                )}
                <CompanyAvatar
                  src={(props.productDetail && props.productDetail.companyLogoUrl) || defaultLogo}
                  size="38"
                />
                <DetailField name={`${rider}.name`} component={Input} readOnly={true} />
              </ProductNameContainer>
              <DetailButton
                awesomeIcon="search"
                onClick={() => props.onShowdetail(props.fields.get(index))}
                children="รายละเอียด"
                disabled={
                  !(
                    isFree ||
                    !hasSumInsured ||
                    ![null, undefined, ""].includes(props.fields.get(index).sumInsured)
                  )
                }
              />
            </HeaderContainer>

            {riderData.planOptions && (
              <OptionContainer>
                <Dropdown
                  options={riderData.planOptions}
                  forceValid={!riderData.error}
                  disabled={!riderData.selected}
                  onChange={(id) => {
                    const selectedRiderPlan = get(
                      riderData.planOptions.find((r) => r.value === id),
                      "rider"
                    )

                    const { name, sumInsured, selected } = riderData
                    const premium = null

                    props.change(`${rider}`, {
                      ...riderData,
                      ...selectedRiderPlan,
                      name,
                      sumInsured,
                      selected,
                      premium,
                    })

                    setTimeout(() => {
                      props.change(`riders[${index}].error`, false)
                      props.onBlurSumInsuredRider(rider, index)
                    })
                  }}
                  name="planOption"
                  caption="แผน"
                  leftWidth="46"
                  rightWidth="245"
                  value={riderData.id}
                  noDelete
                />
              </OptionContainer>
            )}

            <BodyContainer hidden={isFree}>
              <WrapRiderPayment>
                <SumInsuredField
                  name={`${rider}.sumInsured`}
                  readOnly={props.readOnly || !props.isEdit}
                  component={CustomCurrencyInput}
                  caption="จำนวนเงินเอาประกันภัย"
                  hidden={!hasSumInsured}
                  disabled={!selected || props.disabledSumInsured}
                  onChange={() =>
                    setTimeout(() => {
                      props.onBlurSumInsuredRider(rider, index)
                    })
                  }
                  min={0}
                />
                {hasSumInsured && !props.isEdit && <UnitSumInsured> บาท</UnitSumInsured>}
              </WrapRiderPayment>
              <WrapRiderPayment>
                {hasSumInsured ? (
                  <WrapIcon>
                    <PaymentIcon
                      hidden={!props.isEdit}
                      awesomeIcon={
                        props.loadingEditRiderPremiumList[index] || props.forceRiderLoadingPremium
                          ? "spinner"
                          : "chevron-right"
                      }
                      spin={
                        props.loadingEditRiderPremiumList[index] || props.forceRiderLoadingPremium
                      }
                    />
                  </WrapIcon>
                ) : (
                  <WrapIcon>
                    <PaymentIcon visibility={"hidden"} awesomeIcon={"chevron-right"} />
                  </WrapIcon>
                )}
                {props.calculatePremiumErrorMessage[index] ? (
                  <CalculatePremiumErrorMessage>
                    {props.calculatePremiumErrorMessage[index]}
                  </CalculatePremiumErrorMessage>
                ) : (
                  <Fragment>
                    <SumInsuredField
                      name={`${rider}.premium`}
                      component={CustomCurrencyInput}
                      readOnly={!props.indexEditRiderPremiumList[index]}
                      caption="ค่าเบี้ยประกัน"
                    />
                    {!props.indexEditRiderPremiumList[index] && <Unit>บาท/งวด</Unit>}
                    {props.isEdit && (
                      <EditIcon
                        disabled={!selected}
                        onClick={() => selected && props.toggleEditRiderPremium(index)}
                      >
                        <Icon
                          awesomeIcon={
                            props.indexEditRiderPremiumList[index] ? "check" : "pencil-alt"
                          }
                        />
                      </EditIcon>
                    )}
                  </Fragment>
                )}
              </WrapRiderPayment>
            </BodyContainer>

            {riderData.conditionsHtml && (
              <>
                {riderData.selected && (
                  <CustomPanelBar>
                    <PanelLine />
                    <PanelBarItem expanded={true} title="เงื่อนไขในการเลือกสัญญาเพิ่มเติม">
                      <ConditionContent
                        dangerouslySetInnerHTML={{ __html: `${riderData.conditionsHtml}` }}
                      />
                    </PanelBarItem>
                  </CustomPanelBar>
                )}
                {!riderData.selected && (
                  <CustomPanelBar>
                    <PanelLine />
                    <PanelBarItem expanded={false} title="เงื่อนไขในการเลือกสัญญาเพิ่มเติม">
                      <ConditionContent
                        dangerouslySetInnerHTML={{ __html: `${riderData.conditionsHtml}` }}
                      />
                    </PanelBarItem>
                  </CustomPanelBar>
                )}
              </>
            )}
            <DefaultDetailModal isShowModal={props.isShowModal} onClose={props.onCloseModal} />
          </Layout>
        )
      })}

      {props.showingBenefitImage && (
        <Lightbox
          small={props.showingBenefitImage.src}
          large={props.showingBenefitImage.src}
          alt={props.showingBenefitImage.name}
          onClose={props.closeBenefitImageModal}
        />
      )}
      <BenefitRawModal
        benefitRaw={props.showingBenefitRaw}
        isShowModal={props.showingBenefitRaw}
        onClose={props.closeBenefitRawModal}
      />
    </React.Fragment>
  )
}
const enhancer = compose(
  withRouter({
    queryTypes: {
      birthdate: "date",
    },
  }),
  withState("selectedValues", "setSelectedValues", []),
  withState("isShowModal", "setIsShowModal", false),
  withState("indexEditRiderPremiumList", "setIndexEditRiderPremiumList", []),
  withState("loadingEditRiderPremiumList", "setLoadingEditRiderPremiumList", []),
  withState("calculatePremiumTask", "setRiderPremiumTask", null),
  withState("premiumLoading", "setRiderPremiumLoading", false),
  withState("showingBenefitImage", "setShowingBenefitImage", false),
  withState("loadingCalculate", "setLoadingCalculate", false),
  withState("calculatePremiumErrorMessage", "setCalculatePremiumErrorMessage", () => []),
  withState("showingBenefitRaw", "setShowingBenefitRaw", false),
  withStores((stores) => ({
    getCiSearchDetail: stores.productStore.getCiSearchDetail,
    getHsSearchDetail: stores.productStore.getHsSearchDetail,
  })),
  withHandlers({
    toggleEditRiderPremium: (props) => (index) => {
      const newIndexEditRiderPremiumList = [...props.indexEditRiderPremiumList]
      const rider = props.fields.get(index)
      if (!rider.premium && newIndexEditRiderPremiumList[index]) {
        newIndexEditRiderPremiumList[index] = true
      } else {
        newIndexEditRiderPremiumList[index] = !newIndexEditRiderPremiumList[index]
      }
      props.setIndexEditRiderPremiumList(newIndexEditRiderPremiumList)
    },
    onShowModal: (props) => () => {
      props.setIsShowModal(true)
    },
    onCloseModal: (props) => () => {
      props.setIsShowModal(false)
    },
    closeBenefitImageModal: (props) => () => {
      props.setShowingBenefitImage(null)
    },
    closeBenefitRawModal: (props) => () => {
      props.setShowingBenefitRaw(null)
    },
    onShowdetail: (props) => async (values) => {
      if (values.benefitImageUrl) {
        props.setShowingBenefitImage({
          name: values.name,
          src: values.benefitImageUrl,
        })
        return
      } else if (values.benefitRaw) {
        props.setShowingBenefitRaw(values.benefitRaw)
        return
      }

      const { query } = props.location || {}
      const age = moment().diff(query.birthdate, "years")

      switch (values.type) {
        case "hs":
          await props.getHsSearchDetail({
            id: values.insurancePolicyId || values.id,
            birthdate: query.birthdate,
            gender: query.gender || "male",
            paymentPeriod: props.paymentPeriod,
          })
          props.setIsShowModal(true)
          break
        case "ci":
        case "ai":
        case "cr":
        case "wp":
        case "pb":
        case "hb":
          await props.getCiSearchDetail({
            id: values.insurancePolicyId || values.id,
            birthdate: query.birthdate,
            gender: query.gender || "male",
            sumInsured: values.sumInsured,
            paymentPeriod: props.paymentPeriod,
          })
          props.setIsShowModal(true)
          break
        default:
          break
      }
    },
    onBlurSumInsuredRider: (props) => (name, index) => {
      if (props.calculatePremiumTask) {
        clearTimeout(props.calculatePremiumTask)
      }
      const calculatePremiumTask = setTimeout(async () => {
        props.setRiderPremiumLoading(true)
        props.onRiderPremiumChange && props.onRiderPremiumChange(true)
        const { id, sumInsured } = props.fields.get(index) || {}
        props.loadingEditRiderPremiumList[index] = true
        props.setLoadingCalculate(true)
        try {
          props.calculatePremiumErrorMessage[index] = null

          const response = await props.getCiSearchDetail(
            {
              id: id,
              birthdate: props.location.query.birthdate,
              gender: props.location.query.gender || "male",
              sumInsured: sumInsured || 0,
              paymentPeriod: props.paymentPeriod,
              occupationCategory: props.occupationCategory,
              occupation: props.occupation,
            },
            true
          )
          props.change(`${name}.premium`, response.premium)
          if (get(response, "warningMessage")) {
            window.alert(response.warningMessage)
          }
        } catch (e) {
          props.calculatePremiumErrorMessage[index] = null
          if (e.message === "Submit Validation Failed" && e.errors.occupation) {
            window.alert(e.errors.occupation)
            props.calculatePremiumErrorMessage[index] = e.errors.occupation
          } else if (e.message === "Submit Validation Failed" && e.errors.base) {
            props.calculatePremiumErrorMessage[index] = e.errors.base
            // window.alert(e.errors.base)
          } else if (e.response.status === 404) {
            props.calculatePremiumErrorMessage[index] = e.response.data.message
          } else {
            window.alert("เกิดข้อผิดพลาดบางอย่างขึ้น กรุณาลองใหม่ภายหลัง")
            console.log(e)
          }
        }
        props.loadingEditRiderPremiumList[index] = false
        props.setLoadingCalculate(false)

        props.setRiderPremiumLoading(false)
        props.onRiderPremiumChange && props.onRiderPremiumChange(false)
      }, 800)

      props.setRiderPremiumTask(calculatePremiumTask)
    },
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (prevProps.isEdit !== this.props.isEdit) {
        this.props.setIndexEditRiderPremiumList([])
      }

      if (
        this.props.paymentPeriod &&
        prevProps.paymentPeriod !== this.props.paymentPeriod &&
        !this.props.preventCalculateRiderPremiumWhenPaymentPeriodChange
      ) {
        this.props.fields.forEach((rider, index) => {
          const riderData = this.props.fields.get(index) || {}
          if (riderData.id) {
            if (riderData.hasSumInsured) {
              if (![null, undefined, ""].includes(riderData.sumInsured)) {
                this.props.onBlurSumInsuredRider(rider, index)
              }
            } else {
              this.props.onBlurSumInsuredRider(rider, index)
            }
          }
        })
      }
    },
  })
)

export default enhancer(additionalContract)
