import React from "react"
import { compose, withProps } from "recompose"
import { withField, withCaption, omitProps, withReadOnly, withHidden } from "@enhancers/index"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

const ReadOnly = styled.div`
  min-height: 33.5px;
  display: flex;
  align-items: center;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
`

const CustomTextarea = styled.textarea`
  ${(props) =>
    props.valid === false &&
    css`
      border-color: #dc3545 !important;
    `}
  font-family: Sarabun, sans-serif;
  font-size: 16px;
`

const TextArea = (props) => <CustomTextarea className="k-textarea" {...props} />

const enhancer = compose(
  withHidden(),
  withField(),
  withCaption(),
  withReadOnly((props) => (
    <ReadOnly>
      <div>{props.value}</div>
    </ReadOnly>
  )),
  withProps((props) => ({
    valid: !props.touched || props.valid,
    value: props.value || "",
  })),
  omitProps(
    "active",
    "asyncValidating",
    "autofilled",
    "dirty",
    "invalid",
    "pristine",
    "dispatch",
    "visited",
    "touched",
    "submitFailed",
    "submitting"
  )
)

export default enhancer(TextArea)
