import React from "react"
import { compose, withProps } from "recompose"
import { Switch } from "react-router-dom"
import styled from "@emotion/styled"
import * as path from "@common/path"
import { PrivateRoute } from "@components/index"
import { Route, Redirect } from "react-router-dom"

import BatchIndexPage from "@pages/batches/index/index"
import BatchLeadsIndexPage from "@pages/leads/index/batchLeads"

import LeadNewPage from "@pages/leads/new/index"

import LeadIndexPage from "@pages/leads/index/index"
import LeadShowPage from "@pages/leads/show/index"

import LeadPoolIndexPage from "@pages/lead_pool/index/index"

import OrderIndex from "@pages/orders/index/index"
import OrderShowPage from "@pages/orders/show/index"
import OrderProductInfoPage from "@pages/orders/show/ProductInfo"

import Planning from "@pages/products/planning/index/index"
import PlanningType from "@pages/products/planning/show/index"
import ProductSelection from "@pages/products/search/index"
import CompareProductDetailPath from "@pages/products/compare/index"

import ProductListPage from "@pages/products/index/index"

import ProductCustomShowDetailPage from "@pages/products/custom/show/ShowDetail"
import ProductCustomEditPage from "@pages/products/custom/show/edit"
import ProductCustomSummaryPage from "@pages/products/custom/show/index"
import ProductCustomCreateLeadPage from "@pages/products/custom/show/create_lead"

import ProductPackageShowDetailPage from "@pages/products/package/show/ShowDetail"
import ProductPackageEditPage from "@pages/products/package/show/edit"
import ProductPackageShowPage from "@pages/products/package/show/index"
import ProductPackageCreateLeadPage from "@pages/products/package/show/create_lead"

import InsurerCommissionsIndex from "@pages/insurer_commissions/index/index"
import InsurerCommissionShow from "@pages/insurer_commissions/show/index"
import InsurerCommissionHistory from "@pages/insurer_commissions/show/history/index"
import InsurerCommissionsReportPreparation from "@pages/insurer_commissions/index/ReportPreparation"
import CommissionVerification from "@pages/insurer_commissions/show/commission_verification/index"
import CommissionEditApprove from "@pages/insurer_commissions/show/commission_edit_approve/index"

import SaleCommissionsIndex from "@pages/sale_commissions/index/index"
import SaleCommissionShow from "@pages/sale_commissions/show/index"
import SaleCommissionsReportPreparation from "@pages/sale_commissions/index/ReportPreparation"
import SaleCommissionVerification from "@pages/sale_commissions/index/CommissionVerification"
import SalesCommissionHistory from "@pages/sale_commissions/show/History/index"

import CommissionEditSetting from "@pages/commission_edit_setting/index"

import InsurerIndexPage from "@pages/insurers/index/index"
import InsurerShowPage from "@pages/insurers/show/index"

import LifeInsurancePolicy from "@pages/life_insurance_policy/index"
import HealthInsurancePolicy from "@pages/health_insurance_policy/index"

import Rider from "@pages/rider_mappings/index/index"
import RiderShow from "@pages/rider_mappings/show/index"
import RiderNew from "@pages/rider_mappings/new/index"

import Packages from "@pages/packages/index/index"
import PackageNew from "@pages/packages/new/index"
import Package from "@pages/packages/show/index"

import PaymentMappingsIndex from "@pages/payment_mappings/index/index"
import PaymentMappingShow from "@pages/payment_mappings/show/index"

import PackagePaymentMappingsIndex from "@pages/package_payment_mappings/index/index"
import PackagePaymentMappingShow from "@pages/package_payment_mappings/show/index"

import Commission from "@pages/commission/index"

import CommissionMappingIndexPage from "@pages/commission_mapping/index/index"
import CommissionMappingShowPage from "@pages/commission_mapping/show/index"
import CommissionMappingNewPage from "@pages/commission_mapping/new/index"

import BonusCalculationIndexPage from "@pages/bonus_calculation/index/index"
import BonusCalculationShowPage from "@pages/bonus_calculation/show/index"

import TeamIndexPage from "@pages/teams/index/index"
import TeamNewPage from "@pages/teams/new/index"
import TeamShowPage from "@pages/teams/show/index"
import TeamHistoryPage from "@pages/teams/show/History/index"

import TqmMembersIndex from "@pages/tqm_member/index/index"
import TqmMemberShow from "@pages/tqm_member/show/index"
import NewTqmMembers from "@pages/tqm_member/new/index"

import NotFoundPage from "@pages/404/index"
import NotificationPage from "@pages/notification/index/index"
import Landing from "@pages/landing/index"
import Dashboard from "@pages/dashboard/index/index"
// import DashboardReport from "@pages/dashboard/index/Dashboard"
// import DashboardStats from "@pages/stats/index"

import MarketPlanIndexPage from "@pages/market_plan/index/index"
import MarketPlanNewPage from "@pages/market_plan/new/index"
import MarketPlanShowPage from "@pages/market_plan/show/index"

// import { useStores } from "@hooks/index"
// import { withHooks } from "@enhancers/index"

const Layout = styled.div`
  padding-bottom: 45px;
  width: 100%;
  height: 100%;
  max-width: 678px;
  margin: 0px 2vw 0px calc(2vw + 64px);
  @media screen and (min-width: 840px) {
    margin: ${(props) => (props.isExpandWidth ? "0px 2vw 0px calc(2vw + 7%)" : "0 auto")};
    max-width: ${(props) => (props.isExpandWidth ? "calc(100vw - 15%)" : "678px")};
  }
`

// prettier-ignore
const Body = props => (
  <Layout id="main_body" isExpandWidth={props.isExpandWidth}>
    <Switch>
      <PrivateRoute path={path.batchesPath()}                               component={BatchIndexPage}               exact authorize={{ roles: ["user.admin", "member.lead_admin", "member.team_leader", "member.salesman", "member.biz_dev", "member.admin"] }} />

      <PrivateRoute path={path.newLeadPath()}                               component={LeadNewPage}                        authorize={{ roles: ["user.admin", "member.lead_admin", "member.team_leader", "member.salesman", "member.biz_dev", "member.admin", "member.affiliate"] }} />

      <PrivateRoute path={path.batchLeadsPath(":batchId")}                  component={BatchLeadsIndexPage}               exact authorize={{ roles: ["user.admin", "member.lead_admin", "member.biz_dev", "member.admin"] }} />

      <PrivateRoute path={path.leadsPath(":batchId")}                       component={LeadIndexPage}                exact authorize={{ roles: ["user.admin", "member.lead_admin", "member.team_leader", "member.salesman", "member.biz_dev", "member.admin", "member.affiliate"] }} />
      <PrivateRoute path={path.leadPath(":leadId")}                         component={LeadShowPage}                       authorize={{ roles: ["user.admin", "member.lead_admin", "member.team_leader", "member.salesman", "member.biz_dev", "member.admin", "member.affiliate"] }} />
      <PrivateRoute path={path.leadPoolsPath()}                             component={LeadPoolIndexPage}                 exact authorize={{ roles: ["user.admin", "member.lead_admin", "member.team_leader", "member.brokerage_admin" ]}} />

      <PrivateRoute path={path.ordersPath()}                                component={OrderIndex}                   exact authorize={{ roles: ["user.admin", "member.lead_admin", "member.biz_dev", "member.team_leader", "member.salesman", "member.affiliate"] }} />
      <PrivateRoute path={path.newOrderProductInfoPath(":orderId")}         component={OrderProductInfoPage}         exact authorize={{ roles: ["user.admin", "member.lead_admin", "member.biz_dev", "member.team_leader", "member.salesman", "member.affiliate"] }} />
      <PrivateRoute path={path.orderPath(":orderId")}                       component={OrderShowPage}                      authorize={{ roles: ["user.admin", "member.lead_admin", "member.biz_dev", "member.team_leader", "member.salesman", "member.affiliate", "member.accountant", "member.accountant_approver"] }} />

      <PrivateRoute path={path.planningTypePath(":type")}        component={PlanningType}                 authorize={{ roles: ["user.admin", "member.lead_admin", "member.biz_dev", "member.team_leader", "member.salesman", "member.affiliate"] }} />
      <PrivateRoute path={path.planningPath()}                   component={Planning}               exact authorize={{ roles: ["user.admin", "member.lead_admin", "member.biz_dev", "member.team_leader", "member.salesman", "member.affiliate"] }} />

      <PrivateRoute path={path.productPackageCreateLeadPath(":packageId")}  component={ProductPackageCreateLeadPage}       exact authorize={{ roles: ["user.admin", "member.team_leader", "member.salesman", "member.lead_admin", "member.biz_dev", "member.affiliate"] }} />
      <PrivateRoute path={path.productPackageEditPath(":packageId")}        component={ProductPackageEditPage}             exact authorize={{ roles: ["user.admin", "member.team_leader", "member.salesman", "member.lead_admin", "member.biz_dev", "member.affiliate"] }} />
      <PrivateRoute path={path.productPackagePath(":packageId")}            component={ProductPackageShowPage}             exact authorize={{ roles: ["user.admin", "member.team_leader", "member.salesman", "member.biz_dev", "member.affiliate"] }} />
      <PrivateRoute path={path.productPackageShowDetailPath(":packageId")}  component={ProductPackageShowDetailPage}       exact authorize={{ roles: ["user.admin", "member.team_leader", "member.salesman", "member.biz_dev", "member.affiliate"] }} />

      <PrivateRoute path={path.productCustomCreateLeadPath(":productId")}   component={ProductCustomCreateLeadPage}        exact authorize={{ roles: ["user.admin", "member.team_leader", "member.salesman", "member.lead_admin", "member.biz_dev", "member.affiliate"] }} />
      <PrivateRoute path={path.productCustomPath(":productId")}             component={ProductCustomSummaryPage}           exact authorize={{ roles: ["user.admin", "member.team_leader", "member.salesman", "member.lead_admin", "member.biz_dev", "member.affiliate"] }} />
      <PrivateRoute path={path.productCustomEditPath(":productId")}         component={ProductCustomEditPage}              exact authorize={{ roles: ["user.admin", "member.team_leader", "member.salesman", "member.lead_admin", "member.biz_dev", "member.affiliate"] }} />
      <PrivateRoute path={path.productCustomShowDetailPath(":productId")}   component={ProductCustomShowDetailPage}        exact authorize={{ roles: ["user.admin", "member.team_leader", "member.salesman", "member.lead_admin", "member.biz_dev", "member.affiliate"] }} />

      <PrivateRoute path={path.productsComparePath(":leadId")}              exact component={CompareProductDetailPath}  authorize={{ roles: ["user.admin", "member.lead_admin", "member.biz_dev", "member.team_leader", "member.salesman", "member.affiliate"] }} />
      <PrivateRoute path={path.productsComparePath()}                       exact component={CompareProductDetailPath}  authorize={{ roles: ["user.admin", "member.lead_admin", "member.biz_dev", "member.team_leader", "member.salesman", "member.affiliate"] }} />
      <PrivateRoute path={path.productsSearchPath()}                        component={ProductSelection}                     exact authorize={{ roles: ["user.admin", "member.lead_admin", "member.biz_dev", "member.team_leader", "member.salesman", "member.affiliate"] }} />
      <PrivateRoute path={path.productsPath(":leadId")}                     exact component={ProductListPage}  authorize={{ roles: ["user.admin", "member.lead_admin", "member.biz_dev", "member.team_leader", "member.salesman", "member.affiliate"] }} />
      <PrivateRoute path={path.productsPath()}                              component={ProductListPage}    exact authorize={{ roles: ["user.admin", "member.team_leader", "member.salesman", "member.biz_dev", "member.affiliate"] }} />

      <PrivateRoute path={path.insurerCommissionsPath()}                             component={InsurerCommissionsIndex}            exact authorize={{ roles: ["user.admin", "member.accountant", "member.accountant_approver"] }} />
      <PrivateRoute path={path.insurerCommissionsReportPreparationPath()}            component={InsurerCommissionsReportPreparation}      authorize={{ roles: ["user.admin", "member.accountant", "member.accountant_approver"] }} />
      <PrivateRoute path={path.insurerCommissionHistoryPath(":insurerCommissionId")} component={InsurerCommissionHistory}                 authorize={{ roles: ["user.admin", "member.accountant", "member.accountant_approver"] }} />
      <PrivateRoute path={path.insurerCommissionPath(":insurerCommissionId")}        component={InsurerCommissionShow}                    authorize={{ roles: ["user.admin", "member.accountant", "member.accountant_approver"] }} />
      <PrivateRoute path={path.commissionVerification()}                             component={CommissionVerification}                   authorize={{ roles: ["user.admin", "member.accountant"] }} />
      <PrivateRoute path={path.commissionEditApprove()}                              component={CommissionEditApprove}                   authorize={{ roles: ["member.accountant_approver"] }} />

      <PrivateRoute path={path.saleCommissionsPath()}                                component={SaleCommissionsIndex}               exact authorize={{ roles: ["user.admin", "member.accountant", "member.accountant_approver"] }} />
      <PrivateRoute path={path.saleCommissionsReportPreparationPath()}               component={SaleCommissionsReportPreparation}         authorize={{ roles: ["user.admin", "member.accountant", "member.accountant_approver"] }} />
      <PrivateRoute path={path.saleCommissionHistoryPath(":saleCommissionId")}       component={SalesCommissionHistory}                   authorize={{ roles: ["user.admin", "member.accountant", "member.accountant_approver"] }} />
      <PrivateRoute path={path.saleCommissionPath(":saleCommissionId")}              component={SaleCommissionShow}                       authorize={{ roles: ["user.admin", "member.accountant", "member.accountant_approver"] }} />
      <PrivateRoute path={path.saleCommissionVerification()}                         component={SaleCommissionVerification}               authorize={{ roles: ["user.admin", "member.accountant", "member.accountant_approver"] }} />

      <PrivateRoute path={path.commissionEditSettingPath()}                 component={CommissionEditSetting}                       exact authorize={{ roles: ["user.admin", "member.accountant_approver"] }} />

      <PrivateRoute path={path.insurersPath()}                              component={InsurerIndexPage}              exact authorize={{ roles: ["user.admin", "member.policy_admin", "member.biz_dev"] }} />
      <PrivateRoute path={path.insurerPath(":insurerId")}                   component={InsurerShowPage}                     authorize={{ roles: ["user.admin", "member.policy_admin", "member.biz_dev"] }} />

      <PrivateRoute path={path.lifeInsurancePoliciesPath()}                  component={LifeInsurancePolicy}          exact authorize={{ roles: ["user.admin", "member.policy_admin", "member.biz_dev"] }} />
      <PrivateRoute path={path.healthInsurancePoliciesPath()}                component={HealthInsurancePolicy}        exact authorize={{ roles: ["user.admin", "member.policy_admin", "member.biz_dev"] }} />

      <PrivateRoute path={path.ridersPath()}                                 component={Rider}                        exact authorize={{ roles: ["user.admin", "member.policy_admin", "member.biz_dev"] }} />
      <PrivateRoute path={path.newRiderPath()}                               component={RiderNew}                     exact authorize={{ roles: ["user.admin", "member.policy_admin", "member.biz_dev"] }} />
      <PrivateRoute path={path.riderPath(":riderId")}                        component={RiderShow}                    exact authorize={{ roles: ["user.admin", "member.policy_admin", "member.biz_dev"] }} />

      <PrivateRoute path={path.packagesPath()}                               component={Packages}                     exact authorize={{ roles: ["user.admin", "member.policy_admin", "member.biz_dev"] }} />
      <PrivateRoute path={path.newPackagePath()}                             component={PackageNew}                   exact authorize={{ roles: ["user.admin", "member.policy_admin", "member.biz_dev"] }} />
      <PrivateRoute path={path.packagePath(":packageId")}                    component={Package}                      exact authorize={{ roles: ["user.admin", "member.policy_admin", "member.biz_dev"] }} />

      <PrivateRoute path={path.paymentMappingsPath()}                        component={PaymentMappingsIndex}         exact authorize={{ roles: ["user.admin", "member.policy_admin", "member.biz_dev"] }} />
      <PrivateRoute path={path.paymentMappingPath(":id")}                    component={PaymentMappingShow}                 authorize={{ roles: ["user.admin", "member.policy_admin", "member.biz_dev"] }} />

      <PrivateRoute path={path.packagePaymentMappingsPath()}                 component={PackagePaymentMappingsIndex}        exact authorize={{ roles: ["user.admin", "member.policy_admin", "member.biz_dev"] }} />
      <PrivateRoute path={path.packagePaymentMappingPath(":id")}             component={PackagePaymentMappingShow}                authorize={{ roles: ["user.admin", "member.policy_admin", "member.biz_dev"] }} />

      <PrivateRoute path={path.commissisonPath()}                            component={Commission}                   exact authorize={{ roles: ["user.admin", "member.policy_admin" ,"member.admin", "member.biz_dev", "member.accountant", "member.accountant_approver"] }} />

      <PrivateRoute path={path.commissionMappingsPath()}                     component={CommissionMappingIndexPage}           exact authorize={{ roles: ["user.admin","member.admin", "member.policy_admin", "member.biz_dev", "member.accountant", "member.accountant_approver"] }} />
      <PrivateRoute path={path.newCommissionMappingPath()}                   component={CommissionMappingNewPage}             exact authorize={{ roles: ["user.admin","member.admin","member.policy_admin", "member.biz_dev", "member.accountant", "member.accountant_approver"] }} />
      <PrivateRoute path={path.commissionMappingPath(":commissionId")}       component={CommissionMappingShowPage}            exact authorize={{ roles: ["user.admin","member.admin", "member.policy_admin", "member.biz_dev", "member.accountant", "member.accountant_approver"] }} />

      <PrivateRoute path={path.bonusCalculationPath()}                       component={BonusCalculationIndexPage}            exact authorize={{ roles: ["user.admin", "member.policy_admin", "member.biz_dev", "member.accountant", "member.accountant_approver"] }} />
      <PrivateRoute path={path.showBonusCalculationPath(":commissionId")}    component={BonusCalculationShowPage}             exact authorize={{ roles: ["user.admin", "member.policy_admin", "member.biz_dev", "member.accountant", "member.accountant_approver"] }} />

      <PrivateRoute path={path.teamsPath()}                                  component={TeamIndexPage}                exact authorize={{ roles: ["user.admin", "member.admin", "member.biz_dev"] }} />
      <PrivateRoute path={path.newTeamPath()}                                component={TeamNewPage}                  exact authorize={{ roles: ["user.admin", "member.admin", "member.biz_dev"] }} />
      <PrivateRoute path={path.teamPath(":teamId")}                          component={TeamShowPage}                 exact authorize={{ roles: ["user.admin", "member.admin", "member.biz_dev"] }} />
      <PrivateRoute path={path.teamHistoryPath(":teamId")}                   component={TeamHistoryPage}              exact authorize={{ roles: ["user.admin", "member.admin", "member.biz_dev"] }} />

      <PrivateRoute path={path.tqmMembersPath()}                             component={TqmMembersIndex}              exact authorize={{ roles: ["user.admin","member.admin", "member.biz_dev", "member.accountant", "member.accountant_approver"] }} />
      <PrivateRoute path={path.newTqmMembersPath()}                          component={NewTqmMembers}                exact authorize={{ roles: ["user.admin","member.admin", "member.biz_dev"] }} />
      <PrivateRoute path={path.tqmMemberPath(":id")}                         component={TqmMemberShow}                      authorize={{ roles: ["user.admin","member.admin", "member.biz_dev", "member.accountant", "member.accountant_approver"] }} />

      <PrivateRoute path={path.marketPlansPath()}                            component={MarketPlanIndexPage}          exact authorize={{ roles: ["user.admin", "member.admin", "member.biz_dev", "member.accountant", "member.accountant_approver"] }} />
      <PrivateRoute path={path.newMarketPlanPath()}                          component={MarketPlanNewPage}            exact authorize={{ roles: ["user.admin", "member.admin", "member.biz_dev"] }} />
      <PrivateRoute path={path.marketPlanPath(":id")}                        component={MarketPlanShowPage}                 authorize={{ roles: ["user.admin", "member.admin", "member.biz_dev", "member.accountant", "member.accountant_approver"] }} />

      <PrivateRoute
        path={path.notificationPath()}
        component={NotificationPage}
        exact authorize={{
          roles: [
            "user.admin",
            "member.lead_admin",
            "member.biz_dev",
            "member.team_leader",
            "member.salesman",
            "member.accountant",
            "member.accountant_approver",
            "member.admin",
            "member.brokerage_admin",
            "member.affiliate",
          ]
        }}
      />

      <PrivateRoute
        path={path.dashboardPath()}
        component={Dashboard}
        authorize={{
          roles: [
            "user.admin",
            "member.team_leader",
            "member.salesman",
            "member.biz_dev",
            "member.admin",
            "member.brokerage_admin",
          ]
        }}
      />
      {/* <PrivateRoute path={path.reportPath()}                                 component={DashboardReport}                        authorize={{ roles: ["user.admin", "member.admin" , "member.team_leader", "member.salesman"] }} /> */}
      {/* <PrivateRoute path={path.statsPath()}                                  component={DashboardStats}                         authorize={{ roles: ["user.admin", "member.admin" , "member.team_leader", "member.salesman"] }} /> */}

      {/*<PrivateRoute path={path.insurerCommissionPath(":id")}        component={InsurerCommissionsIndex}         authorize={{ roles: ["user.admin","member.accountant", "member.team_leader"] }} />*/}

      <Route path={path.notFoundPath()} component={NotFoundPage} exact />
      <Route path={path.homePath()} component={Landing} exact />

      <Redirect to={path.homePath()} />
    </Switch>
  </Layout>
)

const pathToExpandWidth = [
  "batches",
  "batchLeads",
  "leads",
  "insurer_commissions",
  "sale_commissions",
  "report_preparation",
]

const enhancer = compose(
  withProps(() => {
    if (window) {
      const pathname = window.location.pathname.split("/").pop()
      const isExpandWidth = !!pathToExpandWidth.includes(pathname)

      return { isExpandWidth }
    }
  })
)

export default enhancer(Body)
