import React from "react"
import styled from "@emotion/styled"
import { CurrencyInput, Input } from "@components/index"

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 20px 24px 20px;
  border: 1px solid #eeeef2;
  border-radius: 4px;
  background-color: #fff;
  margin-top: 10px;
`
const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`
const CustomInput = styled(Input)`
  > div:nth-of-type(2) {
    > div {
      padding-top: 0px !important;
    }
  }
`
export const DetailPaymentCard = (props) =>
  props.input.value && (
    <Layout>
      <Row>
        <CustomInput
          value={
            { salesman: "พนักงานขาย", sales_leader: "หัวหน้าพนักงานขาย" }[
              props.input.value.position
            ]
          }
          caption="ตำแหน่ง"
          readOnly
          leftWidth="152"
          rightWidth="152"
        />

        <div>
          <CustomInput
            value={{ normal: "ปกติ", resigned: "ลาออก" }[props.input.value.status]}
            caption="สถานะ"
            readOnly
            leftWidth="60"
            rightWidth="50"
          />
        </div>
      </Row>
      <Row>
        <CustomInput
          value={props.input.value.name}
          caption="ชื่อ"
          readOnly
          leftWidth="152"
          rightWidth="200"
        />
      </Row>
      <Row>
        <CurrencyInput
          value={props.input.value.commissionPercent}
          caption="เปอร์เซ็นต์การจ่าย"
          readOnly
          leftWidth="152"
          rightWidth="152"
          unit="%"
        />
      </Row>
      <Row>
        <CurrencyInput
          value={props.input.value.commissionAmount}
          caption="คิดเป็นเงิน"
          readOnly
          leftWidth="152"
          rightWidth="152"
          unit="บาท"
          precision={2}
        />
      </Row>
    </Layout>
  )

export default DetailPaymentCard
