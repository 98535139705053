import React from "react"
import { compose, withProps, defaultProps, withHandlers } from "recompose"
import { withField, withCaption, withReadOnly, omitProps } from "@enhancers"
import { Input, Dropdown, Field } from "@components"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

const ReadOnly = styled.div`
  min-height: 33.5px;
  display: flex;
  align-items: center;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  width: 265.42px !important;
`

const Layout = styled.div`
  display: flex;
  height: 38px;
  max-width: 205px;
  width: 100%;

  > span {
    flex: 1;
    margin-left: 0;
  }

  > input {
    /* flex: 1; */
    width: 97.5px;
    margin-top: 0px !important;
    margin-left: 10px;
    font-family: Sarabun, sans-serif;
    height: 38px;
    font-size: 16px;
  }

  ${(props) =>
    props.prefixName
      ? css`
          > input:first-of-type {
            flex: 1;
            margin-top: 0px !important;
            margin-left: 10px;
          }
        `
      : css`
          > input:first-of-type {
            flex: 1;
            margin-top: 0px !important;
            margin-left: 0px;
          }
        `}
`
const CustomInput = styled(Input)`
  ::placeholder {
    font-family: Sarabun, sans-serif;
    font-size: 16px;
    color: #bec3c7;
  }
`

const InputName = (props) => (
  <Layout prefixName={props.prefixName}>
    {props.prefixName && (
      <Field
        name={`${props.name}.prefixName`}
        options={props.options}
        component={Dropdown}
        validate={props.validated ? "required" : null}
        placeholder="คำนำหน้า"
        onChange={props.changePrefixName}
      />
    )}
    {props.firstName && (
      <Field
        name={`${props.name}.firstName`}
        component={CustomInput}
        validate={props.validated ? "required" : null}
        placeholder="ชื่อ"
      />
    )}
    {props.lastName && (
      <Field
        name={`${props.name}.lastName`}
        component={CustomInput}
        validate={props.validated ? "required" : null}
        placeholder="นามสกุล"
      />
    )}
  </Layout>
)

const enhancer = compose(
  omitProps("validate"),
  withField(),
  withCaption(),
  defaultProps({
    options: ["คุณ", "นาย", "นาง", "นางสาว", "ด.ช.", "ด.ญ."],
  }),
  withProps((props) => ({
    value: props.value || {},
  })),
  withProps((props) => {
    const { config } = props
    const configs = (config && config.split("|")) || ["prefixName", "firstName", "lastName"]
    const newProps = {}
    configs.map((config) => (newProps[config] = config))
    return {
      ...props,
      ...newProps,
    }
  }),
  withHandlers({
    changePrefixName: (props) => (e) => {
      props.onPrefixNameChange && props.onPrefixNameChange(e)
    },
  }),
  withReadOnly((props) => {
    const { customerInfo } = props.values || {}
    const { nameInfo } = customerInfo || {}
    const { prefixName, firstName, lastName } = nameInfo || props.values || {}
    return (
      <ReadOnly>
        {prefixName || props.value.prefixName}
        {firstName || props.value.firstName} {lastName || props.value.lastName}
      </ReadOnly>
    )
  })
)

export default enhancer(InputName)
