import React from "react"
import { compose, withState, withHandlers, branch, renderNothing, lifecycle } from "recompose"
import { withStores, withForm, withRouter } from "@enhancers"
import { Dialog, DialogActionsBar, Input, Button, Field } from "@components"
import styled from "@emotion/styled"
import * as path from "@common/path"

const CustomDialog = styled(Dialog)`
  .k-dialog {
    width: 350px;
    height: 348px;
  }
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > * {
    width: 302px !important;
    /* margin: 0 20px; */
  }

  padding: 20px 0px;
  > div {
    display: flex;
    margin-top: 10px;
    flex-direction: column;

    > div:nth-of-type(1) {
      width: 100%;
    }

    .k-mid {
      display: flex;
      align-items: center;
      > .k-checkbox-wrapper {
        display: flex;
      }
    }
  }
`
const CustomInput = styled(Input)`
  > div[class*="LeftPanel"] {
    > div {
      font-weight: 400;
    }
  }
  > div[class*="RightPanel"] {
    > input {
      height: 56px;
    }
  }
`

const CustomButton = styled(Button)`
  height: 50px;
  display: flex;
  align-items: center;
`

const SignInModal = (props) => (
  <CustomDialog title="เข้าสู่ระบบ" closeIcon={false} isShowModal onSubmit={props.onSubmit}>
    <Body>
      <Field
        name="username"
        component={CustomInput}
        caption="บัญชีผู้ใช้"
        validate="required"
        autoFocus
        placeholder="กรุณาระบุ"
      />
      <Field
        name="password"
        component={CustomInput}
        type="password"
        caption="รหัสผ่าน"
        validate="required"
        placeholder="กรุณาระบุ"
      />
    </Body>
    <DialogActionsBar>
      <CustomButton primary type="submit" loading={props.submitting}>
        ยืนยัน {props.title}
      </CustomButton>
    </DialogActionsBar>
  </CustomDialog>
)

const enhancer = compose(
  withRouter(),
  withStores((stores) => ({
    token: stores.appStore.token,
    authenticate: stores.appStore.authenticate,
    title: stores.appStore.title,
    logout: stores.appStore.logout,
  })),
  branch((props) => props.token, renderNothing),
  withState("open", "setOpen", true),
  withForm({ form: "SignInForm" }),
  withHandlers({
    onClose: (props) => () => {
      props.setOpen(false)
    },
    onSubmit: (props) =>
      props.handleSubmit(async (values) => {
        try {
          await props.authenticate(values)
          props.history.push(path.homePath())
        } catch (e) {
          if (
            e.message === "Submit Validation Failed" ||
            e.message === "Request failed with status code 401"
          ) {
            alert("บัญชีผู้ใช้หรือรหัสผ่านไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง")
          } else if ((e.response || {}).status === 400) {
            alert(e.response.data.message)
          } else {
            alert("เกิดข้อผิดพลาดของระบบ กรุณาลองใหม่ภายหลัง")
          }
        }
      }),
  })
)

export default enhancer(SignInModal)
