import React from "react"
import styled from "@emotion/styled"
import { css } from "@styled"
import { compose, withHandlers, withProps, defaultProps } from "recompose"
import { withStores, withRouter } from "@enhancers"
import {
  Grid,
  GridColumn,
  makeCell,
  DatePicker,
  Dropdown,
  Checkbox,
  makeHeaderCell,
} from "@components"
import * as path from "@common/path"
import { toCurrency } from "@common/helper"

const Table = styled(Grid)`
  .k-grid-header .k-header {
    font-weight: bold;
  }

  ${(props) =>
    props.isactive
      ? css`
          .k-grid-header .k-header:nth-of-type(6) {
            text-align: end;
          }
          .k-master-row > td:nth-of-type(6) {
            text-align: end;
          }
        `
      : css`
          .k-grid-header .k-header:nth-of-type(5) {
            /* text-align: end; */
          }
          .k-master-row > td:nth-of-type(5) {
            text-align: end;
          }
        `}
`
const CustomCheckbox = styled(Checkbox)`
  .k-item.k-treeview-item {
    height: 26px !important;
    padding-bottom: 5px;
  }
`
const CheckboxTd = styled.td`
  position: sticky;
  left: 0;
  border-right-width: 1px;
  background-color: #ffffff;
`
const StatusTd = styled(CheckboxTd)`
  right: 0;
`

const ViewAsMember = (props) => (
  <Table
    loadingTable={props.loadingTable}
    data={props.data}
    pageable
    skip={props.paging.skip}
    take={props.paging.perPage}
    total={props.paging.totalCount}
    onPageChange={props.onPageChange}
    onRowClick={props.onRowClick}
    isactive={props.verification}
  >
    {props.verification && (
      <GridColumn
        title=""
        width="42px"
        locked
        headerCell={makeHeaderCell(CustomCheckbox, () => ({
          value: props.isSelectedAll,
          onChange: (checked) => props.onSelectAll(checked),
        }))}
        cell={(cell) => {
          return (
            <CheckboxTd className={`k-grid-content-sticky ${cell.field}`}>
              <Checkbox
                value={cell.dataItem.selected}
                onChange={(checked) => props.onSelectRow(checked, cell.dataItem)}
              />
            </CheckboxTd>
          )
        }}
      />
    )}

    <GridColumn title="รหัสพนักงาน" field="staffCode" width="109px" />
    <GridColumn title="ชื่อพนักงาน" field="fullName" width="137px" />
    <GridColumn
      title="ตำแหน่ง"
      cell={makeCell(Dropdown, (cell) => ({
        value: cell.dataItem.salesmanPosition,
        options: props.salesmanPositionOptions,
        readOnly: true,
      }))}
      width="107px"
    />
    <GridColumn title="ทีมขาย" field="teamName" width="100px" />
    <GridColumn
      title="วันที่รับเงิน (ล่าสุด)"
      cell={makeCell(DatePicker, (props) => ({
        readOnly: true,
        value: props.dataItem.receivedDate,
      }))}
      width="90px"
    />
    <GridColumn
      title="คอมมิชชั่นรวม"
      cell={(cell) => <td>{toCurrency(cell.dataItem.totalCommissionAmount)}</td>}
      width="115px"
    />
    <GridColumn
      title="คอมมิชชั่นจ่ายแล้ว"
      cell={(cell) => <td>{toCurrency(cell.dataItem.totalCommissionAmountPaid)}</td>}
      width="115px"
    />
    <GridColumn
      title="คอมมิชชั่นรอทำจ่าย"
      cell={(cell) => <td>{toCurrency(cell.dataItem.totalCommissionAmountWaiting)}</td>}
      width="115px"
    />
    <GridColumn
      title="สถานะ"
      field="memberStatus"
      cell={(cell) => {
        return (
          <StatusTd className={"k-grid-content-sticky"}>
            <Dropdown
              value={cell.dataItem.memberStatus}
              options={props.memberStatusOptions}
              readOnly
            />
          </StatusTd>
        )
      }}
      width="68px"
      locked
    />
  </Table>
)

const enhancer = compose(
  withRouter(),
  withStores((stores) => ({
    fetch: stores.saleCommissionStore.fetch,
    paging: stores.saleCommissionStore.paging,
    fetchConfig: stores.saleCommissionStore.fetchConfig,
    memberStatusOptions: stores.saleCommissionStore.$("configs.filters.memberStatus.options", []),
    salesmanPositionOptions: stores.saleCommissionStore.$(
      "configs.filters.salesmanPosition.options",
      []
    ),
  })),
  defaultProps({
    initialValues: {},
  }),
  withProps((props) => ({
    paging: {
      ...props.paging,
      skip: (props.paging.page - 1) * props.paging.perPage,
    },
  })),
  withHandlers({
    onRowClick: (props) => (event) => {
      if (!props.verification) {
        props.history.push(path.saleCommissionPath(event.dataItem.id, { viewType: "salesman" }))
      }
    },
    // onPageChange: (props) => async (event) => {
    //   const page = event.page.skip / event.page.take + 1
    //   await props.fetch({ ...props.filterParams, page })
    // },
  })
)

export default enhancer(ViewAsMember)
