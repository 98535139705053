import moment from "moment"
import { isClient } from "@common/helper"

export const patchBuddhistEra = () => {
  const format = moment.fn.format
  moment.fn.format = function (token, options = {}) {
    const { locale = moment.locale() } = options

    if (locale === "th" && token) {
      const year = this.year() + 543
      token = token.replace("YYYY", year)
      token = token.replace("YY", year % 100)
    }
    return format.call(this, token)
  }

  if (isClient) {
    window.moment = moment
  }
}
