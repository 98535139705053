import React from "react"
import styled, { css } from "@styled"
import {
  Button,
  Input,
  Dropdown,
  Field,
  BreadCrumb,
  PhoneNumberInput,
  DatePicker,
} from "@components/index"
import { compose, withState, withHandlers, withProps, lifecycle } from "recompose"
import { withForm, withStores, withRouter } from "@enhancers/index"
import * as path from "@common/path"
import DeleteModal from "./DeleteModal"
import _ from "lodash"
import { FieldArray } from "redux-form"

import InputName from "./MemberInputName"
import Radio from "./MemberRadio"
import SalesCommissionPlansField from "./MarketPlan/SalesCommissionPlansField"

const Body = styled.div`
  border-radius: 4px;
  padding: 16px 35px;
  background: #ffffff;
  border: 1px solid #eeeef2;
  > div {
    margin-bottom: 16px;
  }
`
const Layout = styled.div`
  margin: 0px auto 25px auto;
`
const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding: 12px 0px;
  align-items: center;
`
const ButtonContainer = styled.div`
  display: ${(props) => (props.show ? "flex" : "none")};
`
const EditButton = styled(Button)`
  margin-left: 10px;
  width: 90px;
`
const SaveButton = styled(Button)`
  display: flex;
  margin-left: 10px;
  width: 90px;
`
const TrashButton = styled(Button)`
  width: 84px;
`
const CancelButton = styled(SaveButton)`
  width: 90px;
`
const BreadCrumbLayout = styled.div`
  margin: 0px;
`
const CustomField = styled(Field)`
  ${(props) =>
    props.readOnly
      ? css`
          flex-direction: row;

          [class*="LeftPanel"] {
            width: unset;
            min-width: 200px;
          }
        `
      : css`
          flex-direction: column;

          [class*="LeftPanel"] {
            width: 100%;
          }
        `}
`

const TopicContainer = styled.div`
  width: 100%;
  height: 56px;
  background: #294558;
  opacity: 1;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`

const TextTopic = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  font-size: 16px;
  padding: 18px 24px;
  color: #ffffff;
  font-family: Sarabun, sans-serif;
`
const StructureContainer = styled.div`
  background: #eeeef2;
  padding: 24px 31px 32px 31px;
`

export const TqmMemberShowPage = (props) => (
  <Layout>
    <BreadCrumbLayout>
      <BreadCrumb links={props.breadCrumbLinks} />
    </BreadCrumbLayout>
    <Header>
      <ButtonContainer show={true}>
        <ButtonContainer
          show={
            !props.isEdit &&
            props.currentRole !== "member.accountant" &&
            props.currentRole !== "member.accountant_approver"
          }
        >
          <TrashButton
            icon="delete"
            onClick={props.onShowDeleteModal}
            children="ลบ"
            styletype="delete"
          />
          <EditButton icon="edit" onClick={props.onEdit} children="แก้ไข" />
        </ButtonContainer>

        <ButtonContainer
          show={
            props.isEdit &&
            props.currentRole !== "member.accountant" &&
            props.currentRole !== "member.accountant_approver"
          }
        >
          <CancelButton
            icon="cancel"
            onClick={props.onCancel}
            children="ยกเลิก"
            styletype="delete"
          />
          <SaveButton
            icon="floppy"
            onClick={props.handleSubmit(props.onSave)}
            children="บันทึก"
            styletype="agree"
          />
        </ButtonContainer>
      </ButtonContainer>
    </Header>

    <TopicContainer>
      <TextTopic>ข้อมูลส่วนบุคคล</TextTopic>
    </TopicContainer>

    <Body>
      <Field
        name="username"
        component={Input}
        caption="บัญชีผู้ใช้"
        placeholder="โปรดระบุ"
        readOnly={!props.isEdit}
        validate="required"
        leftWidth="200"
        rightWidth="256"
      />

      <CustomField
        name="nameThInfo"
        component={InputName}
        caption="ชื่อภาษาไทย"
        readOnly={!props.isEdit}
        validate="required"
      />

      <CustomField
        name="nameEnInfo"
        component={InputName}
        caption="ชื่อภาษาอังกฤษ"
        readOnly={!props.isEdit}
        options={["Mr.", "Mrs.", "Miss"]}
      />

      <Field
        name="phoneNumber"
        component={PhoneNumberInput}
        caption="เบอร์มือถือ"
        readOnly={!props.isEdit}
        placeholder="โปรดระบุ"
        isMobileNumber={true}
        leftWidth="200"
        rightWidth="256"
      />

      <Field
        name="staffCode"
        component={Input}
        caption="รหัสพนักงาน"
        placeholder="โปรดระบุ"
        readOnly={!props.isEdit}
        validate="required"
        leftWidth="200"
        rightWidth="256"
      />

      <Field
        name="startJobDate"
        component={DatePicker}
        caption="วันที่เริ่มงาน"
        placeholder="โปรดระบุ"
        readOnly={!props.isEdit}
        leftWidth="200"
        rightWidth="256"
      />

      <CustomField
        name="memberType"
        component={Radio}
        caption="สิทธิ์การใช้งานภายในระบบ"
        options={props.memberTypeOptions}
        readOnly={!props.isEdit}
        disabled={props.mode === "edit"}
        validate="required"
      />
      {/* <Field name="isLeader" component={Checkbox} caption="หัวหน้าทีม" readOnly={!props.isEdit} /> */}
      {props.values.memberType === "salesman" && (
        <Field
          name="teamId"
          component={Dropdown}
          options={props.teamOptions}
          caption="ทีม"
          readOnly={!props.isEdit}
          leftWidth="200"
          rightWidth="256"
        />
      )}
      <Field
        name="memberStatus"
        component={Dropdown}
        options={props.memberStatusOptions}
        caption="สถานะ"
        readOnly={!props.isEdit}
        hidden={props.form === "newTqmUser"}
        leftWidth="200"
        rightWidth="256"
      />
    </Body>
    {props.values.memberType && (
      <StructureContainer>
        <FieldArray
          name="salesCommissionPlans"
          component={SalesCommissionPlansField}
          commissionStructure={props.commissionStructure}
          readOnly={!props.isEdit}
          marketPlanOptions={props.marketPlanOptions}
          uplineOptions={props.uplineOptions}
          values={props.values}
          change={props.change}
          fullname={props.fullname}
          required={!!props.salesmanRoleSelected}
        />
      </StructureContainer>
    )}

    <DeleteModal
      open={props.isShowDeleteModal}
      onClose={props.onCloseModal}
      onDelete={props.onDelete}
      username={props.username}
    />
  </Layout>
)

const enhancer = compose(
  withRouter(),
  withStores((stores, props) => ({
    fetchOne: stores.tqmUserStore.fetchOne,
    initialValues: {
      ...stores.tqmUserStore.current,
      ...stores.tqmUserStore.current.memberInfo,
    },
    fetchConfigAttributes: stores.tqmUserStore.fetchConfigAttributes,
    memberTypeOptions: stores.tqmUserStore.$("configs.attributes.memberType.options", []),
    prefixNameEn: stores.tqmUserStore.$("configs.attributes.prefixNameEn.options", []),
    delete: stores.tqmUserStore.delete,
    update: stores.tqmUserStore.update,
    memberStatusOptions: stores.tqmUserStore.$("configs.attributes.memberStatus.options", []),
    teamOptions: stores.tqmUserStore.$("configs.attributes.team.options", []),
    marketPlanOptions: stores.tqmUserStore.$("configs.attributes.marketPlan.options", []),
    uplineOptions: stores.tqmUserStore.$("configs.attributes.upline.options", []),
    currentRole: stores.appStore.currentRole,
  })),
  withForm({ form: "EditTqmUserInfo" }),
  withState("isEdit", "setIsEdit", false),
  withState("mode", "setMode", "edit"),
  withState("isShowDeleteModal", "setIsShowDeleteModal", false),
  withHandlers({
    onEdit: (props) => () => {
      props.setIsEdit(true)
    },
    onCancel: (props) => () => {
      props.reset()
      props.setIsEdit(false)
    },
    onSave: (props) => async (values) => {
      try {
        let salesCommissionPlans = (values.salesCommissionPlans || []).map(
          (salesCommissionPlan) => {
            if (!_.isEmpty(salesCommissionPlan) && salesCommissionPlan.canEdit !== false) {
              return {
                beginDate: salesCommissionPlan.beginDate,
                id: salesCommissionPlan.id,
                positionLevel: salesCommissionPlan.positionLevel,
                mkPlanId: salesCommissionPlan.mkPlanId,
                uplineId: salesCommissionPlan.uplineId,
              }
            }
          }
        )

        salesCommissionPlans = salesCommissionPlans.filter(
          (salesCommissionPlan) => salesCommissionPlan
        )

        const newValues = {
          ...values,
          member_info: {
            staffCode: values.staffCode,
            memberType: values.memberType,
            teamId: values.teamId,
            isLeader: values.isLeader,
            parentId: values.parentId,
            memberStatus: values.memberStatus,
          },
          salesCommissionPlans: salesCommissionPlans,
          id: props.match.params.id,
        }
        await props.update(newValues)
        props.setIsEdit(false)
      } catch (error) {
        if (error.errors) {
          alert(error.errors.base || error.errors.username || error.errors.message)
        } else {
          alert("มีข้อผิดพลาดบางอย่างเกิดขึ้น")
        }
      }
    },
    onDelete: (props) => async () => {
      await props.delete(props.match.params.id)
      props.history.push(path.tqmMembersPath())
    },
    onCloseModal: (props) => () => props.setIsShowDeleteModal(false),
    onShowDeleteModal: (props) => () => props.setIsShowDeleteModal(true),
  }),
  lifecycle({
    async componentDidMount() {
      await Promise.all([
        this.props.fetchOne(this.props.match.params.id),
        this.props.fetchConfigAttributes(),
      ])
    },
  }),
  withProps((props) => {
    const salesmanRoleSelected = ["salesman", "sales_leader"].includes(props.values.memberType)

    const fullname =
      (props.values.nameThInfo || {}).firstName + " " + (props.values.nameThInfo || {}).lastName

    return {
      username: (props.initialValues || {}).username,
      salesmanRoleSelected: salesmanRoleSelected,
      type: "show",
      breadCrumbLinks: [
        { label: "จัดการผู้ใช้ของทีคิวเอ็ม", path: path.tqmMembersPath() },
        {
          label: props.initialValues.username,
          path: path.tqmMemberPath(props.match.params.id),
        },
      ],
      fullname,
    }
  })
)

export default enhancer(TqmMemberShowPage)
