import { compose, withState, withProps } from "recompose"
import styled from "@common/styled"
import env from "@env"
import IframeResizer from "iframe-resizer-react"
import { omitProps } from "@enhancers/index"

const IFrame = styled(IframeResizer)``

IFrame.OldSite = compose(
  withState("id", "setId", (props) => props.id || `iframe_${parseInt(Math.random() * 10000)}`),
  withProps((props) => ({
    src: `${env.oldSiteRoot}/${props.src}`,
  })),
  omitProps("setId")
)(IFrame)

export default IFrame
