import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
import { css } from "@styled"
import { compose, withHandlers, withProps } from "recompose"
import { Button, Dialog, Field, DatePicker, Form, Radio } from "@components"
import { DialogActionsBar } from "@progress/kendo-react-dialogs"
import * as path from "@common/path"
import { withRouter, withForm, withHooks } from "@enhancers/index"
import moment from "moment"

const CustomDialog = styled(Dialog)`
  > div:last-of-type {
    width: 420 !important;
  }

  > div {
    > div :last-child {
      margin-top: 30px;
    }
  }
`
const InputContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  padding: 32px 19px 40px 24px;

  > div {
    :nth-of-type(2) {
      margin-top: 32px;
    }
    :nth-of-type(3) {
      margin-top: 32px;
    }

    > div :last-child {
      > div {
        display: flex;
      }
      display: flex;
      > input {
        width: 63%;
      }
    }
  }

  > input {
    width: 100%;
  }
`
const CustomRadio = styled(Radio)`
  color: #666666;

  > div :last-child {
    > div {
      flex-direction: column;
    }
  }
`
const CustomDatePicker = styled(DatePicker)``
const CancelButton = styled(Button)`
  border-left: 0.5px solid #ccc;
  color: #666;
  min-height: 52px;
`
const GotoButton = styled(Button)`
  border-left: 0.5px solid #ccc;
  color: white;
  background-color: #0275d8;
  min-height: 52px;

  :hover {
    background-color: #0275d8;
    color: white;
  }

  :disabled {
    color: #eeeef2;
    border-color: #eeeef2;
    opacity: 0.65;
  }
`
const DialogFooter = styled(DialogActionsBar)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 20px;
`

const ExportModal = (props) => (
  <CustomDialog
    title="ออกรายงานคอมมิชชั่นจ่าย"
    onClose={() => props.onClose()}
    isShowModal={props.open}
    width="433px"
  >
    <InputContainer>
      <Field
        component={CustomDatePicker}
        name="exportedDate"
        caption="วันที่นำออก"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="141"
        rightWidth="177"
        disabled={props.disabled}
        placeholder="กรุณาเลือก"
      />
      <Field
        leftWidth="141"
        name="groupBy"
        component={CustomRadio}
        caption="แยกตาม"
        options={props.groupByOptions}
        validate="required"
      />
      <Field
        leftWidth="141"
        name="exportedType"
        component={CustomRadio}
        caption="ข้อมูลนำออก"
        options={props.exportedTypeOptions}
        validate="required"
      />
    </InputContainer>
    <DialogFooter>
      <CancelButton onClick={props.onClose}>ย้อนกลับ</CancelButton>
      <GotoButton styletype="primary" onClick={props.gotoExportPrepare} disabled={props.invalid}>
        ต่อไป
      </GotoButton>
    </DialogFooter>
  </CustomDialog>
)

const convertDateToString = (value) =>
  moment(value).format("DD/MM/YYYY", {
    locale: "en",
  })

const enhancer = compose(
  withRouter(),
  withProps((props) => {
    let viewType = ""

    if (props.location.query.viewType === "salesman") {
      viewType = "salesman"
    } else if (props.location.query.viewType === "orders") {
      viewType = "order"
    } else if (props.location.query.viewType === "affiliates") {
      viewType = "affiliate"
    }

    const initialValues = {
      groupBy: viewType,
    }
    return {
      initialValues,
    }
  }),
  withForm({
    form: "SaleCommissionsExportForm",
  }),
  withHandlers({
    gotoExportPrepare: (props) =>
      props.handleSubmit((values) => {
        const { exportedType, exportedDate, groupBy } = values
        if (exportedDate) {
          props.history.push(
            path.saleCommissionsReportPreparationPath({
              exportedDate: convertDateToString(exportedDate),
              exportedType: exportedType,
              groupBy: groupBy,
            })
          )
        } else {
          props.history.push(
            path.saleCommissionsReportPreparationPath({
              exportedType: exportedType,
              groupBy: groupBy,
            })
          )
        }
      }),
  }),
  withHooks((props) => {
    const [exportedTypeOptions, setExportedTypeOptions] = useState(null)
    const [groupByOptions, setGroupByOptions] = useState([
      {
        label: "รหัสคำสั่งซื้อ",
        value: "order",
      },
      {
        label: "พนักงาน",
        value: "salesman",
      },
      {
        label: "ทีมผู้แนะนำ",
        value: "affiliate",
      },
    ])

    useEffect(() => {
      if (props.values.groupBy === "salesman") {
        setExportedTypeOptions([
          {
            label: "คอมมิชชั่นจ่าย",
            value: "sales_commission",
          },
        ])
      } else if (props.values.groupBy === "order") {
        setExportedTypeOptions([
          {
            label: "คำนวณคอมมิชชั่น",
            value: "calculate_commission",
          },
          {
            label: "คอมมิชชั่นจ่าย",
            value: "sales_commission",
          },
          {
            label: "คอมมิชชั่นค้างจ่าย/จ่ายล่วงหน้า",
            value: "sales_commission_pending",
          },
        ])
      } else if (props.values.groupBy === "affiliate") {
        setExportedTypeOptions([
          {
            label: "คอมมิชชั่นจ่าย",
            value: "sales_commission",
          },
        ])
      }
    }, [props.values.groupBy])

    return {
      exportedTypeOptions,
      groupByOptions,
    }
  })
)

export default enhancer(ExportModal)
