import React from "react"
import styled from "@styled"
import { GridColumn } from "@components/index"
import * as path from "@common/path"
import GraphContainer from "./GraphContainer"
import {
  Layout,
  ReportCardTitle,
  FooterContainer,
  ShowMoreDetailContainer,
  ShowMoreDetailText,
  CaretRightIcon,
  Table as DefaultTable,
} from "./InsuranceCompanyCard"

export const Table = styled(DefaultTable)`
  .k-master-row > td {
    text-align: end;

    :nth-of-type(1) {
      text-align: start;
      padding: 0 12px;
    }

    :nth-of-type(2) {
      text-align: start;
      padding: 0 12px;
    }

    :nth-of-type(3) {
      text-align: start;
      padding: 0 12px;
    }
  }
`

const TeamCard = (props) => {
  return (
    <Layout>
      <ReportCardTitle>
        {props.dashboardType === "affiliate" ? "ยอดขายแยกตามสาขาจากผู้แนะนำ" : "ยอดขายแยกตามสาขา"}
      </ReportCardTitle>
      <GraphContainer graph={props.graph} />
      <Table data={props.data} style={{ marginTop: 27 }}>
        <GridColumn title="ภาค" field="regionalManagerName" width="170" />
        <GridColumn title="ผู้จัดการเขต" field="areaManagerName" width="170" />
        <GridColumn title="สาขา/ทีม" field="teamName" width="150" />
        <GridColumn title="ยอดเบี้ยที่ขายได้" field="salablePremium" width="136" />
        <GridColumn title="ยอดเบี้ยที่ยกเลิก" field="rejectedPremium" width="133" />
        <GridColumn title="ยอดเบี้ยรวม" field="totalPremium" width="106" />
        <GridColumn title="กรมธรรม์ที่ขายได้" field="salablePolicy" width="144" />
        <GridColumn title="กรมธรรม์ที่ยกเลิก" field="rejectedPolicy" width="144" />
        <GridColumn title="กรมธรรม์รวม" field="totalPolicy" width="115" />
      </Table>

      <FooterContainer>
        <ShowMoreDetailContainer
          to={
            props.dashboardType === "affiliate"
              ? path.dashboardAffTeamPath()
              : path.dashboardTeamPath()
          }
        >
          <ShowMoreDetailText>ดูยอดขายแยกตามสาขา</ShowMoreDetailText>
          <CaretRightIcon awesomeIcon={"caret-right"} />
        </ShowMoreDetailContainer>
      </FooterContainer>
    </Layout>
  )
}

export default TeamCard
