import React from "react"
import styled from "@emotion/styled"
import { compose, withHandlers } from "recompose"
import { Button, Dialog, Field, DatePicker, Form, Radio } from "@components"
import { DialogActionsBar } from "@progress/kendo-react-dialogs"
import * as path from "@common/path"
import { withRouter, withForm } from "@enhancers/index"
import moment from "moment"

const CustomDialog = styled(Dialog)`
  > div {
    > div :last-child {
      margin-top: 30px;
    }
  }
`
const InputContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  padding: 30px 24px 35px 24px;

  > div {
    > div :last-child {
      > div {
        display: flex;
      }
      display: flex;
      > input {
        width: 63%;
      }
    }
  }

  > input {
    width: 100%;
  }
`
const CustomRadio = styled(Radio)`
  color: #666666;

  > div :last-child {
    > div {
      flex-direction: column;
    }
  }
`
const CustomDatePicker = styled(DatePicker)`
  margin-bottom: 15px;
`
const CancelButton = styled(Button)`
  border-left: 0.5px solid #ccc;
  color: #666;
  min-height: 52px;
`
const GotoButton = styled(Button)`
  border-left: 0.5px solid #ccc;
  color: white;
  background-color: #0275d8;

  :hover {
    background-color: #0275d8;
    color: white;
  }

  :disabled {
    color: #eeeef2;
    border-color: #eeeef2;
    opacity: 0.65;
  }

  min-height: 52px;
`
const DialogFooter = styled(DialogActionsBar)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 20px;
`

const ExportModal = (props) => (
  <CustomDialog
    title="ออกรายงานคอมมิชชั่นรับ"
    onClose={() => props.onClose()}
    isShowModal={props.open}
    width="433px"
  >
    <InputContainer>
      <Field
        component={CustomDatePicker}
        name="exportedAt"
        caption="วันที่นำออก"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="145"
        rightWidth="177"
        disabled={props.disabled}
        placeholder="กรุณาเลือก"
      />
      <Field
        leftWidth="145"
        name="incomeType"
        component={CustomRadio}
        caption="ข้อมูลนำออก"
        options={roleOptions}
        validate="required"
      />
    </InputContainer>
    <DialogFooter>
      <CancelButton onClick={props.onClose}>ยกเลิก</CancelButton>
      <GotoButton styletype="primary" onClick={props.gotoExportPrepare} disabled={props.invalid}>
        ต่อไป
      </GotoButton>
    </DialogFooter>
  </CustomDialog>
)

const roleOptions = [
  {
    label: "ประมาณการรายได้",
    value: "estimated_income",
  },
  {
    label: "รายได้รับ",
    value: "received_income",
  },
  {
    label: "รายได้ค้างรับ",
    value: "remaining_income",
  },
]

const convertDateToString = (value) => {
  if (value) {
    return moment(value).format("DD/MM/YYYY", {
      locale: "en",
    })
  } else {
    return null
  }
}

const enhancer = compose(
  withRouter(),
  withForm({
    form: "InsurerCommissionExportForm",
  }),
  withHandlers({
    gotoExportPrepare: (props) => () => {
      const { incomeType, exportedAt } = props.values
      props.history.push(
        path.insurerCommissionsReportPreparationPath({
          exportedAt: exportedAt ? convertDateToString(exportedAt) : undefined,
          incomeType: incomeType,
        })
      )
    },
  })
)

export default enhancer(ExportModal)
