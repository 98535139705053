import React from "react"
import styled from "@emotion/styled"
import { compose, withHandlers } from "recompose"
import { withForm, withStores } from "@enhancers"
import { DialogActionsBar, Dialog, Button, TextArea, Form, Field } from "@components"
import moment from "moment"
import { getOptionLabel } from "@common/helper"

const Layout = styled(Form)`
  padding: 24px 45px 36px 45px;
  width: 415px;
`

const SubmitButton = styled(Button)`
  overflow: unset !important;
  height: 52px;
  background-color: #0275d8;
  color: white;

  :hover {
    background-color: #0275d8;
    color: white;
    border-color: white;
  }
`
const CustomDialog = styled(Dialog)``

const CancelButton = styled(Button)`
  overflow: unset !important;
  align-self: center;
`

const TextLabel = styled.div`
  font-size: 16px;
  font-weight: bold;
  width: 190px;
  color: #666666;
`

const TextValue = styled.div`
  font-size: 16px;
  width: 100%;
  display: block;
  overflow-wrap: break-word;
  color: ${(props) => props.overdueDate && "#d9534f"};
`

const RowContent = styled.div`
  display: flex;
  margin-top: 15px;
  margin-bottom: 5px;
  width: 100%;
`
const RowBorder = styled(RowContent)`
  border: 1px solid #bec3c7;
  padding: 5px 10px;
  flex-direction: column;
  min-height: 100px;
  max-height: 100px;
  overflow-y: auto;
  margin-bottom: 5px;
`
const RowContentRemark = styled(RowContent)`
  margin-top: 10px;
  margin-bottom: 5px;
`
const OverdueDate = styled.div`
  color: #d9534f;
`

const ActionCodeDetailModal = (props) => (
  <CustomDialog title={props.title} onClose={props.onClose} isShowModal={props.open}>
    <Layout>
      <RowContent>
        <TextLabel>วันที่แจ้ง</TextLabel>
        <TextValue overdueDate={props.actionCode && props.actionCode.isOverdue}>
          {props.actionCode && moment(props.actionCode.requestDate).format("DD/MM/YYYY")}
          {props.actionCode && props.actionCode.isOverdue && (
            <OverdueDate>
              * เกินกำหนดมา {moment().diff(moment(props.actionCode.requestDate), "days")} วัน
            </OverdueDate>
          )}
        </TextValue>
      </RowContent>
      <RowContent>
        <TextLabel>ครบกำหนดแก้ไข</TextLabel>
        <TextValue>
          {props.actionCode && moment(props.actionCode.dueDate).format("DD/MM/YYYY")}
        </TextValue>
      </RowContent>
      <RowContent>
        <TextLabel>การติดต่อลูกค้า</TextLabel>
        <TextValue>
          {props.actionCode &&
            getOptionLabel(props.actionCodesOptions, props.actionCode.actionType)}
        </TextValue>
      </RowContent>
      <RowContent>
        <TextLabel>หมายเหตุ</TextLabel>
      </RowContent>
      <RowBorder>
        <TextValue>
          {props.actionCode &&
            props.actionCode.requestRemark &&
            `คำถาม: ${props.actionCode.requestRemark}`}
        </TextValue>
        <TextValue>
          {props.actionCode &&
            props.actionCode.actionRemark &&
            `คำตอบ: ${props.actionCode.actionRemark}`}
        </TextValue>
      </RowBorder>
      {props.actionCode && !props.actionCode.actionRemark && (
        <RowContentRemark>
          <Field name="actionRemark" leftWidth="70" caption="ตอบกลับ" component={TextArea} />
        </RowContentRemark>
      )}
      {props.actionCode && props.actionCode.actionRemark && (
        <RowContent>
          <TextLabel>วันที่ตอบกลับ</TextLabel>
          <TextValue>
            {props.actionCode &&
              props.actionCode.replyAt &&
              moment(props.actionCode.replyAt).format("DD/MM/YYYY")}
          </TextValue>
        </RowContent>
      )}
    </Layout>

    <DialogActionsBar>
      <CancelButton
        type="button"
        onClick={props.onClose}
        children={props.actionCode && props.actionCode.actionRemark ? "ปิด" : "ยกเลิก"}
      />
      {props.actionCode && !props.actionCode.actionRemark && (
        <SubmitButton type="primary" onClick={props.onSubmit} children="ยืนยัน" />
      )}
    </DialogActionsBar>
  </CustomDialog>
)

const enhancer = compose(
  withForm({ form: "ActionCodeUpdateDetailsForm" }),
  withStores((stores, props) => ({
    updateActionCode: stores.actionCodeStore.updateActionCode,
  })),
  withHandlers({
    onClose: (props) => () => {
      props.reset(props.from)
      props.onClose()
    },
    onSubmit: (props) =>
      props.handleSubmit(async (values) => {
        try {
          await props.updateActionCode(props.actionCode.id, values)
        } catch (e) {
          window.alert("ตอบกลับไม่ได้")
        }
        props.reset(props.from)
        props.onClose()
      }),
  })
)
export default enhancer(ActionCodeDetailModal)
