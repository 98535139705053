import BaseStore from "./BaseStore"
import { tqldServer } from "@common/api"

class CommissionMappingStore extends BaseStore {
  constructor() {
    super({
      all: [],
      paging: {
        page: 1,
        perPage: 10,
        totalCount: 0,
        totalPages: 1,
      },
      configs: {},
      current: {},
    })
  }

  fetch = async (params) => {
    const response = await tqldServer.tqmBackend.get("commission_mappings/list", params)
    this.all = response.data.data.commissionMappings
  }

  fetchById = async (id) => {
    const response = await tqldServer.tqmBackend.get("commission_mappings", {
      insurancePolicyId: id,
    })
    this.current = response.data.data.commissionMappings
  }

  fetchConfig = async () => {
    const response = await tqldServer.tqmBackend.get("commission_mappings/configs")
    this.configs = response.data.data.configs
  }

  updateCommission = async (insurancePolicyId, commissionId) => {
    await tqldServer.tqmBackend.patch("commission_mappings", {
      insurancePolicyId: insurancePolicyId,
      commissionId: commissionId,
    })
  }

  removeCommission = async (id) => {
    await tqldServer.tqmBackend.patch("commission_mappings/remove_commission", {
      insurancePolicyId: id,
    })
  }
}

export default new CommissionMappingStore()
