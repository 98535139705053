import React, { useCallback } from "react"
import { GridColumn, Field } from "@components"
import { compose, withHandlers } from "recompose"
import { withHooks } from "@enhancers/index"
import {
  Layout,
  Table,
  CustomInput as Input,
  CustomButton as Button,
  CustomIcon as Icon,
  BonusContainerTd,
  TitleContainer,
} from "./MonthlyBonusTable"

const PersistentBonusTable = (props) => {
  const initialData =
    props.data.length > 0
      ? [...props.data]
      : [{ limraPercentMin: null, limraPercentMax: null, bonusPercent: null }]

  return (
    <Layout>
      <Table data={initialData} readOnly={props.readOnly} style={{ marginTop: 7 }}>
        <GridColumn title="อัตราความยั่งยืนแบบลิมร่า (%)">
          <GridColumn title="ต่ำสุด" cell={props.limraPercentMinCell} width="70px" />
          <GridColumn title="สูงสุด" cell={props.limraPercentMaxCell} width="70px" />
        </GridColumn>
        <GridColumn
          title={
            <TitleContainer>
              <a>อัตราโบนัส</a>
              <a>(% ต่อ All ARYP)</a>
            </TitleContainer>
          }
          cell={props.bonusPercentCell}
          width="100px"
        />
      </Table>
    </Layout>
  )
}

const enhancer = compose(
  withHandlers({
    addItem: (props) => () => {
      props.change(`${props.fieldName}companyPersistentBonusRatesAttributes`, [
        ...props.data,
        {
          limraPercentMin: null,
          limraPercentMax: null,
          bonusPercent: null,
        },
      ])
    },

    removeItem: (props) => (index) => () => {
      let array = props.data
      if (index > -1) {
        array.splice(index, 1)
      }

      props.change(`${props.fieldName}companyPersistentBonusRatesAttributes`, array)
    },
  }),
  withHooks((props) => {
    const limraPercentMinCell = useCallback(
      (cell) => {
        return (
          <td>
            <Field
              name={`${props.fieldName}companyPersistentBonusRatesAttributes[${cell.dataIndex}].limraPercentMin`}
              component={Input}
              onBlur={(e) => {
                e.preventDefault()
              }}
              placeholder="โปรดระบุ"
              readOnly={!props.readOnly}
            />
          </td>
        )
      },
      [props.readOnly, props.data.length, props.limraMonthCount]
    )

    const limraPercentMaxCell = useCallback(
      (cell) => {
        return (
          <td>
            <Field
              name={`${props.fieldName}companyPersistentBonusRatesAttributes[${cell.dataIndex}].limraPercentMax`}
              component={Input}
              onBlur={(e) => {
                e.preventDefault()
              }}
              placeholder="โปรดระบุ"
              readOnly={!props.readOnly}
            />
          </td>
        )
      },
      [props.readOnly, props.data.length, props.limraMonthCount]
    )

    const bonusPercentCell = useCallback(
      (cell) => {
        return (
          <BonusContainerTd>
            <Field
              name={`${props.fieldName}companyPersistentBonusRatesAttributes[${cell.dataIndex}].bonusPercent`}
              component={Input}
              onBlur={(e) => {
                e.preventDefault()
              }}
              placeholder="โปรดระบุ"
              readOnly={!props.readOnly}
            />
            {props.readOnly && (
              <>
                {cell.dataIndex + 1 !== props.data.length ? (
                  <Button onClick={props.removeItem(cell.dataIndex)} styletype="delete">
                    <Icon icon="minus" />
                  </Button>
                ) : (
                  <Button
                    onClick={() => props.addItem()}
                    hidden={cell.dataIndex + 1 !== props.data.length}
                  >
                    <Icon icon="plus" />
                  </Button>
                )}
              </>
            )}
          </BonusContainerTd>
        )
      },
      [props.readOnly, props.data.length, props.limraMonthCount]
    )

    return {
      limraPercentMinCell,
      limraPercentMaxCell,
      bonusPercentCell,
    }
  })
)

export default enhancer(PersistentBonusTable)
