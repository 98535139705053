import React from "react"
import styled from "@emotion/styled"
import { compose, withProps, withHandlers, lifecycle, withState } from "recompose"
import { withStores, withRouter } from "@enhancers"

import { Grid, GridColumn, Avatar, makeCell, LoadingScope, DatePicker } from "@components"
import * as path from "@common/path"

const Layout = styled.div`
  margin: 0px auto 25px auto;
`

const Table = styled(Grid)`
  margin-top: 48px;

  .k-header:first-of-type {
    text-align-last: center;
  }
`

const LayoutDisplayLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const DisplayLogo = (props) => (
  <td>
    <LayoutDisplayLogo>
      <Avatar src={props.dataItem.logo} readOnly />
    </LayoutDisplayLogo>
  </td>
)

const BonusCalculationIndexPage = (props) => (
  <React.Fragment>
    {props.loading ? (
      <LoadingScope />
    ) : (
      <Layout>
        <Table
          data={props.insurers}
          onRowClick={props.onClickRow}
          pageable
          skip={props.paging.skip}
          take={props.paging.perPage}
          total={props.paging.totalCount}
          onPageChange={props.onPageChange}
        >
          <GridColumn field="logo" title="โลโก้" editable={false} width="60px" cell={DisplayLogo} />
          <GridColumn field="shortName" title="ชื่อย่อ" width="70px" />
          <GridColumn field="name" title="ชื่อบริษัทประกัน" width="300px" />
          <GridColumn
            field="updatedAt"
            title="วันที่แก้ไขล่าสุด"
            cell={makeCell(DatePicker, (cellProps) => ({
              readOnly: true,
              value: cellProps.dataItem.updatedAt,
            }))}
            width="120px"
          />
        </Table>
      </Layout>
    )}
  </React.Fragment>
)

const enhancer = compose(
  withState("loading", "setLoading", false),
  withStores((stores) => ({
    fetchInsurers: stores.insurerStore.fetch,
    insurers: stores.insurerStore.all,
    paging: stores.insurerStore.paging,
  })),
  withProps((props) => ({
    paging: {
      ...props.paging,
      skip: (props.paging.page - 1) * props.paging.perPage,
    },
  })),
  withRouter(),
  withHandlers({
    onClickRow: (props) => (item) => {
      props.history.push(path.showBonusCalculationPath(item.dataItem.id))
    },
    onPageChange: (props) => async (event) => {
      const page = event.page.skip / event.page.take + 1
      await props.fetchInsurers({ ...props.values, page })
    },
  }),
  lifecycle({
    async componentDidMount() {
      this.props.setLoading(true)
      await Promise.all([this.props.fetchInsurers()])
      this.props.setLoading(false)
    },
  })
)

export default enhancer(BonusCalculationIndexPage)
