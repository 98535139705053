import React, { useCallback } from "react"
import { GridColumn, Field } from "@components"
import { compose, withHandlers } from "recompose"
import { withHooks } from "@enhancers/index"
import {
  Layout,
  Table,
  CustomInput as Input,
  CustomButton as Button,
  CustomIcon as Icon,
  BonusContainerTd,
  TitleContainer,
} from "./MonthlyBonusTable"

const YearlyBonusTable = (props) => {
  return (
    <Layout>
      <Table data={props.data} readOnly={props.readOnly}>
        <GridColumn title="เบี้ยประกันปีแรกที่ปรับปรุงแล้ว (AFYP) (บาท)">
          <GridColumn title="ต่ำสุด" cell={props.afypMinCell} width="100px" />
          <GridColumn title="สูงสุด" cell={props.afypMaxCell} width="100px" />
        </GridColumn>
        <GridColumn
          title={
            <TitleContainer>
              <a>อัตราโบนัส</a>
              <a>(% ต่อ AFYP ตั้งแต่บาทแรก)</a>
            </TitleContainer>
          }
          cell={props.bonusPercentCell}
          width="100px"
        />
      </Table>
    </Layout>
  )
}

const enhancer = compose(
  withHandlers({
    addItem: (props) => () => {
      props.change("companyYearlyBonusRates", [
        ...props.data,
        {
          afypMin: null,
          afypMax: null,
          bonusPercent: null,
        },
      ])
    },

    removeItem: (props) => (index) => () => {
      let array = props.data
      if (index > -1) {
        array.splice(index, 1)
      }

      props.change("companyYearlyBonusRates", array)
    },
  }),
  withHooks((props) => {
    const afypMinCell = useCallback(
      (cell) => {
        return (
          <td>
            <Field
              name={`companyYearlyBonusRates[${cell.dataIndex}].afypMin`}
              component={Input}
              onBlur={(e) => {
                e.preventDefault()
              }}
              placeholder="โปรดระบุ"
              readOnly={!props.readOnly}
            />
          </td>
        )
      },
      [props.readOnly, props.data.length]
    )

    const afypMaxCell = useCallback(
      (cell) => {
        return (
          <td>
            <Field
              name={`companyYearlyBonusRates[${cell.dataIndex}].afypMax`}
              component={Input}
              onBlur={(e) => {
                e.preventDefault()
              }}
              placeholder="โปรดระบุ"
              readOnly={!props.readOnly}
            />
          </td>
        )
      },
      [props.readOnly, props.data.length]
    )

    const bonusPercentCell = useCallback(
      (cell) => {
        return (
          <BonusContainerTd>
            <Field
              name={`companyYearlyBonusRates[${cell.dataIndex}].bonusPercent`}
              component={Input}
              onBlur={(e) => {
                e.preventDefault()
              }}
              placeholder="โปรดระบุ"
              readOnly={!props.readOnly}
            />

            {props.readOnly && (
              <>
                {cell.dataIndex + 1 !== props.data.length ? (
                  <Button onClick={props.removeItem(cell.dataIndex)} styletype="delete">
                    <Icon icon="minus" />
                  </Button>
                ) : (
                  <Button
                    onClick={() => props.addItem()}
                    hidden={cell.dataIndex + 1 !== props.data.length}
                  >
                    <Icon icon="plus" />
                  </Button>
                )}
              </>
            )}
          </BonusContainerTd>
        )
      },
      [props.readOnly, props.data.length]
    )

    return {
      afypMinCell,
      afypMaxCell,
      bonusPercentCell,
    }
  })
)

export default enhancer(YearlyBonusTable)
