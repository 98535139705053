import React, { useState, useEffect, useCallback, useMemo } from "react"
import styled, { css } from "@styled"
import { compose } from "recompose"
import { withForm, withStores, withHooks, withRouter } from "@enhancers"
import { Field, Button, Dropdown, Grid, GridColumn, Checkbox, Icon, makeCell } from "@components"
import { ButtonGroup } from "@pages/leads/show/LeadInfo"
import moment from "moment"

const Layout = styled.div`
  display: flex;
  flex-direction: column;
`
const BreadCrumbLayout = styled.div`
  width: 100%;
  margin-bottom: 37px;
`
const HeaderBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`
const Topic = styled.div`
  font-family: ThaiSans Neue;
  font-size: 30px;
  font-weight: bold;
  color: #666666;
  margin-bottom: 20px;
`
const WrapperHeader = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;

  > div:first-of-type {
    margin-right: 16px;
  }
`
const ActionButton = styled(Button)`
  width: 104px;

  > span {
    font-size: 22px;
  }
`
const Table = styled(Grid)`
  .k-grid-header {
    .k-header {
      :first-of-type {
        padding-left: 30px !important;
      }
      :nth-of-type(2) {
        text-align: center;
      }
      :nth-of-type(3) {
        text-align: center;
      }
    }
  }

  .k-grid-container {
    .k-grid-content.k-virtual-content {
      > div {
        .k-grid-table {
          > tbody {
            .k-master-row {
              > td:first-of-type {
                padding-left: 30px !important;
              }
              > td:nth-of-type(2) {
                text-align: center;
              }
              > td:nth-of-type(3) {
                text-align: center;
              }
            }

            .k-item.k-treeview-item {
              justify-content: center;
            }
          }
        }
      }
    }
  }
`
const IconCheckCircle = styled(Icon)`
  color: #5cb85c;
`
const IconTimesCircle = styled(Icon)`
  color: #d9534f;
`
const CustomCheckbox = styled(Checkbox)`
  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: unset;

      .k-checkbox {
        border-color: #adb5bd !important;
      }
    `}
`
const CustomDropdown = styled(Dropdown)`
  > div:first-of-type {
    height: 100%;
  }

  ${(props) =>
    props.disabled &&
    props.isEdit &&
    css`
      opacity: 0.5;
    `}
`

const EditSetting = (props) => (
  <Layout>
    <BreadCrumbLayout />
    <HeaderBody>
      <Topic>ตั้งค่าการแก้ไข</Topic>
      <WrapperHeader>
        <Field
          name="year"
          caption="ปี"
          component={CustomDropdown}
          options={props.yearOptions}
          leftWidth="30"
          rightWidth="196"
        />

        {props.isEdit ? (
          <ButtonGroup>
            <ActionButton
              icon="cancel"
              onClick={props.onCancel}
              children="ยกเลิก"
              styletype="secondary"
            />
            <ActionButton icon="floppy" onClick={props.onSave} children="บันทึก" />
          </ButtonGroup>
        ) : (
          <ButtonGroup>
            <ActionButton icon="edit" onClick={props.onEdit} children="แก้ไข" />
          </ButtonGroup>
        )}
      </WrapperHeader>
    </HeaderBody>

    <Table data={props.data}>
      <GridColumn
        field="month"
        title="เดือน"
        width="256px"
        cell={makeCell(CustomDropdown, (cell) => ({
          options: props.monthOptions,
          value: cell.dataItem.month,
          readOnly: true,
          isEdit: props.isEdit,
          disabled: !cell.dataItem.canEdit,
        }))}
      />
      <GridColumn
        field="insurerCommissionEditable"
        title="การแก้ไขคอมมิชชั่นรับ"
        width="210px"
        cell={(cell) => {
          return (
            <>
              {props.isEdit ? (
                <td>
                  <CustomCheckbox
                    value={props.findInsurerCommission(cell.dataItem)}
                    onChange={(checked) => props.selectInsurerCommission(checked, cell.dataItem)}
                    // disabled={!cell.dataItem.canEdit}
                    disabled={true}
                  />
                </td>
              ) : (
                <td>
                  {cell.dataItem.canEdit && (
                    <>
                      {props.findInsurerCommission(cell.dataItem) ? (
                        <IconCheckCircle awesomeIcon={["fas", "check-circle"]} />
                      ) : (
                        <IconTimesCircle awesomeIcon={["fas", "times-circle"]} />
                      )}
                    </>
                  )}
                </td>
              )}
            </>
          )
        }}
      />
      <GridColumn
        field="salesCommissionEditable"
        title="การแก้ไขคอมมิชชั่นจ่าย"
        width="210px"
        cell={(cell) => {
          return (
            <>
              {props.isEdit ? (
                <td>
                  <CustomCheckbox
                    value={props.findSalesCommission(cell.dataItem)}
                    onChange={(checked) => props.selectSalesCommission(checked, cell.dataItem)}
                    disabled={!cell.dataItem.canEdit}
                  />
                </td>
              ) : (
                <td>
                  {cell.dataItem.canEdit && (
                    <>
                      {props.findSalesCommission(cell.dataItem) ? (
                        <IconCheckCircle awesomeIcon={["fas", "check-circle"]} />
                      ) : (
                        <IconTimesCircle awesomeIcon={["fas", "times-circle"]} />
                      )}
                    </>
                  )}
                </td>
              )}
            </>
          )
        }}
      />
    </Table>
  </Layout>
)

const enhencer = compose(
  withRouter(),
  withStores((stores) => {
    const currentYear = moment().year()
    return {
      fetch: stores.commissionEditableStore.fetch,
      data: stores.commissionEditableStore.all,
      fetchConfigs: stores.commissionEditableStore.fetchConfigs,
      monthOptions: stores.commissionEditableStore.$("configs.attributes.month.options", []),
      yearOptions: stores.commissionEditableStore.$("configs.filter.year.options", []),
      create: stores.commissionEditableStore.create,
      year: currentYear,
      initialValues: {
        year: currentYear,
      },
    }
  }),
  withForm({
    form: "CommissionEditSetting",
  }),
  withHooks((props) => {
    const [commissionEditableMonths, setCommissionEditableMonths] = useState([])
    const [isEdit, setIsEdit] = useState(false)

    useEffect(() => {
      props.fetchConfigs()
      props.fetch({ year: props.year })
    }, [])

    useEffect(() => {
      props.fetch({ year: props.values.year })
    }, [props.values.year])

    const newData = useMemo(() => {
      const newData = props.data.map((item) => {
        if (props.values.year < props.year) {
          return {
            ...item,
            canEdit: true,
          }
        } else {
          if (item.month <= moment().month() + 1) {
            return {
              ...item,
              canEdit: true,
            }
          } else {
            return {
              ...item,
              canEdit: false,
            }
          }
        }
      })
      setCommissionEditableMonths(newData)
      return newData
    }, [props.data])

    const onEdit = useCallback(() => {
      setIsEdit(!isEdit)
    })

    const onCancel = useCallback(() => {
      setCommissionEditableMonths(props.data)
      setIsEdit(!isEdit)
    })

    const onSave = useCallback(async () => {
      const response = await props.create({ year: props.values.year, commissionEditableMonths })
      setCommissionEditableMonths(response)
      setIsEdit(!isEdit)
    }, [props.values, commissionEditableMonths])

    const findInsurerCommission = useCallback(
      (value) => {
        if (commissionEditableMonths.length === 0) {
          setCommissionEditableMonths(props.data)
        }
        return (commissionEditableMonths.find((item) => item.month === value.month) || {})
          .insurerCommissionEditable
      },
      [props.data, commissionEditableMonths]
    )

    const findSalesCommission = useCallback(
      (value) => {
        if (commissionEditableMonths.length === 0) {
          setCommissionEditableMonths(props.data)
        }
        return (commissionEditableMonths.find((item) => item.month === value.month) || {})
          .salesCommissionEditable
      },
      [props.data, commissionEditableMonths]
    )

    const selectInsurerCommission = useCallback(
      (checked, value) => {
        const commissionEditable = commissionEditableMonths.find(
          (item) => item.month === value.month
        )
        const tempCommissionEditableMonths = commissionEditableMonths.filter(
          (commissionEditableMonth) => commissionEditableMonth.month !== value.month
        )
        tempCommissionEditableMonths.push({
          ...commissionEditable,
          insurerCommissionEditable: checked,
        })
        setCommissionEditableMonths(tempCommissionEditableMonths)
      },
      [commissionEditableMonths]
    )

    const selectSalesCommission = useCallback(
      (checked, value) => {
        const commissionEditable = commissionEditableMonths.find(
          (item) => item.month === value.month
        )
        const tempCommissionEditableMonths = commissionEditableMonths.filter(
          (commissionEditableMonth) => commissionEditableMonth.month !== value.month
        )
        tempCommissionEditableMonths.push({
          ...commissionEditable,
          salesCommissionEditable: checked,
        })
        setCommissionEditableMonths(tempCommissionEditableMonths)
      },
      [commissionEditableMonths]
    )

    return {
      commissionEditableMonths,
      isEdit,
      data: newData,
      onEdit,
      onCancel,
      onSave,
      findInsurerCommission,
      findSalesCommission,
      selectInsurerCommission,
      selectSalesCommission,
    }
  })
)

export default enhencer(EditSetting)
