import { compose, lifecycle, withHandlers, withProps, withState } from "recompose"
import { withStores, withForm, withRouter, withHooks } from "@enhancers"
import * as path from "@common/path"
import { convertNaNtoZeroFloat, isClient } from "@common/helper"
import { SubmissionError } from "redux-form"
import { get, isEqual, find, isEmpty, forEach, intersection, toString } from "lodash"
import validate from "@pages/packages/new/validate"
import moment from "moment"
import { ProductEdit } from "../../custom/show/edit"
import { useMemo, useEffect, useState } from "react"

const goUp = (position) => {
  let $target = null

  if (position === undefined || position === null) {
    $target = document.querySelector('[class*="CalculateInputContainer"]')
  } else {
    $target = document.querySelector(`#rider_card_${position}`)
  }

  if ($target) {
    $target.scrollIntoView({ behavior: "smooth", block: "center" })
  }
}

const enhancer = compose(
  withRouter({
    queryTypes: {
      birthdate: "date",
      gender: "string",
      sumInsured: "integer",
      type: "string",
      searchBy: "string",
      leadId: "integer",
      from: "string",
      occupationLevel: "integer",
      maxAgeInsured: "integer",
      searchValue: "integer",
      enablePeriod: "boolean",
    },
  }),
  withState("toggleSummaryModal", "setToggleSummaryModal", false),
  withState("loading", "setLoading", false),
  withState("haveRider", "setHaveRider", false),
  withState("isEditPremium", "setToggleEditPremium", false),
  withStores((stores) => {
    return {
      getDetail: stores.packageStore.fetchPackagePolicyById,
      insuranceCoverageTable: stores.packageStore.portDetailTable,
      productDetail: stores.packageStore.portDetail,
      postBuyProduct: stores.packageStore.postBuyProduct,
      riderPolicies: stores.packageStore.riderPolicies,
      addOrder: stores.orderStore.addOrder,
      fetchLead: stores.leadStore.fetchById,
      paymentPeriodConfigs: stores.packageStore.$("configs.attributes.paymentPeriod", []),
      occupationCategoryOptions: stores.packageStore.$(
        "configs.attributes.occupationCategories",
        []
      ),
      fetchOrder: stores.orderStore.fetchOrder,
    }
  }),
  withState("selectedRiderMap", "setSelectedRidersMap", {}),
  withState("forceRiderLoadingPremium", "setForceRiderLoadingPremium", false),

  // branch(
  //   (props) => !props.location.query.enablePeriod,
  //   withProps((props) => ({
  //     paymentPeriodConfigs: props.paymentPeriodConfigs.filter((opt) => opt.value === "yearly"),
  //   }))
  // ),
  withForm({
    form: "buyProduct",
    validate,
  }),
  withProps((props) => {
    const searchData = props.location.query
    const { packageId } = props.match.params
    const { productDetail } = props
    let premium =
      searchData.searchBy === "premium" ? searchData.searchValue : productDetail.premium || 0
    const sumInsured =
      searchData.searchBy === "sum_insured" ? searchData.searchValue : productDetail.sumInsured || 0

    if (![null, undefined, ""].includes(searchData.premium)) {
      premium = searchData.premium
    }

    const age = searchData.birthdate ? moment().diff(moment(searchData.birthdate), "years") : null
    return {
      titleButton: "สรุปแบบประกัน",
      iconButton: "check",
      params: {
        ...searchData,
        searchBy: "sum_insured",
        searchValue: sumInsured,
        age,
        packageId,
        premium,
        sumInsured,
      },
      disabledSumInsured: true,
      productDetail: {
        ...props.productDetail,
        premium,
        sumInsured,
        insuranceCoverageTable: props.insuranceCoverageTable && props.insuranceCoverageTable,
      },
      preventCalculateRiderPremiumWhenPaymentPeriodChange: true,
      forceRiderLoadingPremium: props.forceRiderLoadingPremium,
    }
  }),
  withState("calculatePremiumTask", "setCalculatePremiumTask", null),
  withState("calculatePremiumLoading", "setCalculatePremiumLoading", false),
  withState("calculatePremiumErrorMessage", "setCalculatePremiumErrorMessage", null),
  withState("calculateSumInsuredErrorMessage", "setCalculateSumInsuredErrorMessage", null),
  withHandlers({
    makeInitialValues: (props) => async (response) => {
      const { params, paymentPeriodConfigs } = props
      const newProps = {}

      let paymentPeriodConfig = (paymentPeriodConfigs || []).find((item) => item.value === 'yearly')
      if (!paymentPeriodConfig) paymentPeriodConfig = (paymentPeriodConfigs || [])[0]

      const riderPolicies = response.riderPolicies.map((rider) => ({
        ...rider,
        selected: !!(rider.isFree || rider.selected),
      }))
      newProps.sumInsured =
        params.searchBy === "sum_insured" ? params.searchValue : response.policy.sumInsured
      newProps.premium =
        params.searchBy === "premium" ? params.searchValue : response.policy.premium

      newProps.paymentPeriod = props.params.paymentPeriod || paymentPeriodConfig?.value || "yearly"
      newProps.occupationCategory = props.params.occupationCategory
      newProps.occupation = props.params.occupation
      newProps.searchBy = props.params.searchBy

      if (![null, undefined, ""].includes(params.premium)) {
        newProps.premium = params.premium
      }

      newProps.riders = props.params.riders
        ? riderPolicies.map((rider) => {
            let selectedRider = find(props.params.riders, (r) => {
              return isEqual(r.id, `${rider.id}`)
            })
            if (selectedRider) {
              return {
                ...rider,
                ...selectedRider,
                selected: true,
              }
            } else {
              return rider
            }
          })
        : riderPolicies

      // BEGIN
      const customRiders = newProps.riders.map((rider) => {
        // let [categoryName, planName] = rider.name.split(/ แผน | แบบ | Plan /)
        // const prefix = planName ? rider.name.match(/ (แผน |แบบ |Plan )/)[1] : ""
        // rider = { ...rider, planName: `${prefix}${planName}`, categoryName }

        return rider
      })

      const riderGroup = {}
      customRiders.forEach((rider) => {
        const name = rider.categoryName || rider.name

        riderGroup[name] = riderGroup[name] || []
        riderGroup[name] = [...riderGroup[name], rider]
      })

      const customRiders2 = []
      forEach(riderGroup, (riders, name) => {
        if (riders.length > 1) {
          const planOptions = riders.map((r) => ({ label: r.planName, value: r.id, rider: r }))

          const paramRiders = props.params.riders || []

          const paramRiderIds = paramRiders.map((r) => `${r.id}`)
          const groupRiderIds = riders.map((r) => `${r.id}`)

          const selectedRiderId = intersection(paramRiderIds, groupRiderIds)[0]

          const selectedParamRider = paramRiders.find((r) => r.id === selectedRiderId)
          const selectedGroupRider = riders.find((r) => r.id === selectedRiderId)

          const selected = !!selectedRiderId

          customRiders2.push({
            ...selectedGroupRider,
            ...selectedParamRider,
            name,
            planOptions,
            selected,
          })
        } else {
          customRiders2.push(riders[0])
        }
      })

      newProps.riders = customRiders2

      // END

      props.initialize(newProps)
    },
    changeRouteParams: (props) => (value) => {
      props.history.push(
        path.productCustomEditPath(props.params.packageId, { ...props.params, searchBy: value })
      )
    },
  }),
  withHandlers({
    onClose: (props) => () => {
      props.setToggleSummaryModal(false)
    },
    changePremiumRider: (props) => (value) => {
      props.setCalculatePremiumLoading(value)
    },
    calculatePremium: (props) => (value) => {
      const searchBy = get(props.values, "searchBy", "sum_insured")
      if (props.calculatePremiumTask) {
        clearTimeout(props.calculatePremiumTask)
      }
      props.setToggleEditPremium(false)

      const calculatePremiumTask = setTimeout(async () => {
        props.setCalculatePremiumLoading(true)
        props.setForceRiderLoadingPremium(true)

        const occupationCategory = get(props.values, "occupationCategory", null)
        const occupation = get(props.values, "occupation", null)
        try {
          const response = await props.getDetail({
            packageId: props.params.packageId,
            birthdate: props.params.birthdate,
            gender: props.params.gender,
            paymentPeriod: props.values.paymentPeriod,
            occupationCategory: occupationCategory,
            occupation: occupation,
          })
          props.setCalculatePremiumErrorMessage(null)
          props.setCalculateSumInsuredErrorMessage(null)

          response.riderPolicies = response.packagesRiderInsurancePolicies

          props.change("premium", response.policy.premium)
          props.change("sumInsured", response.policy.sumInsured)

          if (props.values.riders) {
            props.values.riders.forEach((rider, index) => {
              if (rider.id) {
                const riderPolicy = response.riderPolicies.find((item) => item.id === rider.id)
                if (riderPolicy) props.change(`riders[${index}].premium`, riderPolicy.premium)
              }
            })
          }

          if (get(response, "warningMessage")) {
            const warningMessage = response.warningMessage.replace(/\\n/gi, "\n")
            window.alert(warningMessage)
          }
        } catch (e) {
          switch (props.values.searchBy) {
            case "sum_insured":
              props.setCalculatePremiumErrorMessage("เงินเอาประกันน้อยเกินไป")
              break
            case "premium":
              props.setCalculateSumInsuredErrorMessage("เบี้ยประกันน้อยเกินไป")
              break
          }
          if (e.message === "Submit Validation Failed") {
            window.alert(e.errors.occupation)
          } else {
            window.alert("เกิดข้อผิดพลาดบางอย่างขึ้น")
            console.log(e)
          }
        }

        props.setCalculatePremiumLoading(false)
        props.setForceRiderLoadingPremium(false)
      }, 10)

      props.setCalculatePremiumTask(calculatePremiumTask)
    },
    handleToggleEditPremium: (props) => () => {
      props.setToggleEditPremium(!props.isEditPremium)
      const premium = get(props.values, "premium", 0)
      const sumInsured = get(props.values, "sumInsured", 0)
      const searchBy = get(props.values, "searchBy", "sum_insured")
      if ((!premium || !sumInsured) && props.isEditPremium) {
        props.setToggleEditPremium(true)
      } else {
        if (searchBy === "sum_insured") {
          props.change("premium", premium)
        } else {
          props.change("sumInsured", sumInsured)
        }
        props.setToggleEditPremium(!props.isEditPremium)
      }
    },
    onCancel: (props) => () => {
      const { query } = props.location || {}
      if (props.params.leadId && props.params.orderId) {
        props.history.push(path.ordersPath())
      } else {
        const params = {
          ...props.location.query,
          sumInsured: query.type === "all" ? null : query.sumInsured,
        }
        props.history.push(path.productsPath(params))
      }
    },
    onFormSubmit: (props) =>
      props.handleSubmit(async (values) => {
        let error = { riders: [] }
        let checkError = false
        const paramsRider = []

        let errorPosition = null

        for (let index = 0; index < props.values.riders.length; index++) {
          const { selected, sumInsured, premium, id, isFree, hasSumInsured } = props.values.riders[
            index
          ]
          if (!isFree && selected) {
            const e = {}
            if (hasSumInsured) {
              if ([null, undefined, ""].includes(sumInsured)) {
                e.sumInsured = "no value"
              }
            }

            if ([null, undefined, ""].includes(premium)) {
              e.premium = "no value"
            }

            if ([null, undefined, ""].includes(id)) {
              e.id = "no value"
              props.change(`riders[${index}].error`, true)
            } else {
              props.change(`riders[${index}].error`, false)
            }

            error.riders.push(e)

            if (!isEmpty(e)) {
              checkError = true
              if (errorPosition === null) {
                errorPosition = index
              }
            }
          } else {
            error.riders.push({})
          }

          if (selected) {
            paramsRider.push({
              id,
              premium,
              sumInsured,
            })
          }
        }

        if (props.calculatePremiumErrorMessage) {
          checkError = true
          error.sumInsured = "invalid"
        }
        if (props.calculateSumInsuredErrorMessage) {
          checkError = true
          error.premium = "invalid"
        }
        if (!props.values.premium) {
          error.premium = "invalid"
          checkError = true
        }
        if (!props.values.sumInsured) {
          checkError = true
          error.sumInsured = "invalid"
        }

        if (checkError) {
          if (error.sumInsured || error.premium) {
            errorPosition = null
          }

          goUp(errorPosition)

          throw new SubmissionError(error)
        } else {
          props.setToggleEditPremium(false)
          const searchBy = get(props.values, "searchBy", "sum_insured")
          const premium = get(props.values, "premium", 0)
          const sumInsured = get(props.values, "sumInsured", 0)
          const occupationCategory = get(props.values, "occupationCategory", null)
          const occupation = get(props.values, "occupation", null)

          props.history.push(
            path.productPackagePath(props.params.packageId, {
              ...props.location.query,
              searchBy: searchBy,
              searchValue: searchBy === "sum_insured" ? sumInsured : premium,
              premium: premium,
              sumInsured: sumInsured,
              paymentPeriod: props.values.paymentPeriod || "yearly",
              premium: props.values.premium,
              riders: paramsRider,
              occupationCategory: occupationCategory,
              occupation: occupation,
            })
          )
        }
      }),
  }),
  lifecycle({
    async componentWillMount() {
      this.props.setLoading(true)

      try {
        const response = await this.props.getDetail({
          packageId: this.props.params.packageId,
          birthdate: this.props.params.birthdate,
          gender: this.props.params.gender,
        })

        response.riderPolicies = response.packagesRiderInsurancePolicies
        response.riderPolicies.forEach((rider) => {
          rider.selected = rider.lockable
        })

        this.props.makeInitialValues(response)
        this.props.setLoading(false)
      } catch (error) {
        const errorMsg = error.errors?.base || "มีข้อผิดพลาดบางอย่างเกิดขึ้น"
        alert(errorMsg)
        this.props.history.push(path.productsPath(this.props.params))
        // switch (error.name) {
        //   case "SubmissionError":
        //     alert(error.errors.base)

        //     this.props.history.push(path.productsPath(this.props.params))
        //     break
        //   default:
        //     alert("มีข้อผิดพลาดบางอย่างเกิดขึ้น")
        // }
      }
    },
    componentDidMount() {
      if (isClient) {
        window.scrollTo(0, 0)
      }
    },
  }),
  withHooks((props) => {
    const occupationOptions = useMemo(() => {
      let tempOccupationOptions = []
      if (
        props.occupationCategoryOptions.length !== 0 &&
        props.occupationCategoryOptions[0].value === null
      ) {
        tempOccupationOptions = props.occupationCategoryOptions[0].occupations
        return tempOccupationOptions
      } else {
        tempOccupationOptions = props.occupationCategoryOptions.find(
          (option) => option.value === props.values.occupationCategory
        )
        return (tempOccupationOptions || {}).occupations
      }
    }, [props.occupationCategoryOptions, props.values.occupationCategory])

    useEffect(() => {
      if (![null, undefined, ""].includes(props.values.occupation)) {
        props.calculatePremium(props.values.sumInsured)
      }
    }, [props.values.occupation])

    let stickySummaryRider = 0
    let stickySummarySumInsuredRider = 0

    const [stickySummary, setStickySummary] = useState(0)

    useEffect(() => {
      props.setHaveRider(false)
      props.values.riders &&
        props.values.riders.forEach((item) => {
          if (item.selected) {
            stickySummaryRider += convertNaNtoZeroFloat(item.premium)
            stickySummarySumInsuredRider += convertNaNtoZeroFloat(item.sumInsured)
            props.setHaveRider(true)
          }
        })
      setStickySummary(parseFloat(props.values.premium) + parseFloat(stickySummaryRider))
    }, [props.values.riders, props.values.premium])

    return {
      occupationOptions,
      stickySummary: stickySummary,
      stickySummaryRider: stickySummaryRider,
      stickySummarySumInsuredRider: stickySummarySumInsuredRider,
    }
  }),
  withProps((props) => {
    const { params } = props
    let links = []
    switch (params.from) {
      case "custom_lead":
        links = [
          { label: "ลูกค้าใหม่", path: path.leadsPath("custom") },
          {
            label: "รายละเอียดลีด",
            path: path.leadOrderInfoPath(params.leadId),
          },
          {
            label: "ค้นหาแบบประกัน",
            path: path.productsSearchPath(params.leadId, {
              leadId: params.leadId,
              gender: params.gender,
              type: params.type,
              birthdate: params.birthdate,
              from: params.from,
              searchValue: params.searchValue,
              searchBy: params.searchBy,
              opportunityKey: params.opportunityKey,
            }),
          },
          {
            label: "เลือกแบบประกัน",
            path: path.productsPath(params.leadId, {
              gender: params.gender,
              type: params.type,
              leadId: params.leadId,
              birthdate: params.birthdate,
              from: params.from,
              searchValue: params.searchValue,
              searchBy: params.searchBy,
              opportunityKey: params.opportunityKey,
            }),
          },
          {
            label: "คำนวณเบี้ย",
          },
        ]
        break
      case "batch_lead":
        links = [
          { label: "ลีดทีคิวเอ็ม", path: path.leadsPath("all") },
          {
            label: "รายละเอียดลีด",
            path: path.leadOrderInfoPath(params.leadId),
          },
          {
            label: "ค้นหาแบบประกัน",
            path: path.productsSearchPath(params.leadId, {
              leadId: params.leadId,
              gender: params.gender,
              type: params.type,
              birthdate: params.birthdate,
              from: params.from,
              searchValue: params.searchValue,
              searchBy: params.searchBy,
              opportunityKey: params.opportunityKey,
            }),
          },
          {
            label: "เลือกแบบประกัน",
            path: path.productsPath(params.leadId, {
              gender: params.gender,
              type: params.type,
              leadId: params.leadId,
              birthdate: params.birthdate,
              from: params.from,
              searchValue: params.searchValue,
              searchBy: params.searchBy,
              opportunityKey: params.opportunityKey,
            }),
          },
          {
            label: "คำนวณเบี้ย",
          },
        ]
        break
      case "order":
        links = [
          {
            label: "คำสั่งซื้อ",
            path: path.ordersPath(),
          },
          {
            label: "คำนวณเบี้ย",
          },
        ]
        break
      default:
        links = [
          {
            label: "ผลิตภัณฑ์",
            path: "/",
          },
          {
            label: "ค้นหาแบบประกัน",
            path: path.productsSearchPath({
              gender: params.gender,
              type: params.type,
              birthdate: params.birthdate,
              searchValue: params.searchValue,
              searchBy: params.searchBy,
              opportunityKey: params.opportunityKey,
            }),
          },
          {
            label: "เลือกแบบประกัน",
            path: path.productsPath({
              gender: params.gender,
              type: params.type,
              birthdate: params.birthdate,
              from: params.from,
              searchValue: params.searchValue,
              searchBy: params.searchBy,
              opportunityKey: params.opportunityKey,
            }),
          },
          {
            label: "คำนวณเบี้ย",
          },
        ]
        break
    }
    return { links }
  })
)

export default enhancer(ProductEdit)
