import React from "react"
import styled from "@styled"
import { Field, Dropdown, Input } from "@components"
import { compose, withProps } from "recompose"
import { withStores } from "@enhancers/index"

export const Row = styled.div`
  display: flex;
  margin-top: 12px;

  &:nth-of-type(1) {
    margin-top: 0;
  }

  > div {
    margin-left: 12px;

    &:nth-of-type(1) {
      margin-left: 0;
    }
  }
`

const FilterPanel = (props) => (
  <React.Fragment>
    <Row>
      <Field
        component={Input}
        name="name"
        caption="ชื่อพนักงาน"
        captionStyle={{ fontWeight: "normal" }}
        disabled={props.disabled}
        placeholder="กรุณาระบุ"
        leftWidth="150"
        rightWidth="150"
      />
      <Field
        component={Input}
        name="staffCode"
        caption="รหัสพนักงาน"
        captionStyle={{ fontWeight: "normal" }}
        disabled={props.disabled}
        placeholder="กรุณาระบุ"
        leftWidth="100"
        rightWidth="150"
      />
    </Row>
    <Row>
      <Field
        component={Dropdown}
        name="memberType"
        caption="ตำแหน่งงานในระบบ"
        captionStyle={{ fontWeight: "normal" }}
        options={props.memberTypeOptions}
        leftWidth="150"
        rightWidth="412"
        disabled={props.disabled}
        placeholder="กรุณาเลือก"
      />
    </Row>

    <Row>
      <Field
        component={Dropdown}
        name="memberStatus"
        caption="สถานะ"
        captionStyle={{ fontWeight: "normal" }}
        options={props.memberStatusOptions}
        disabled={props.disabled}
        placeholder="กรุณาเลือก"
        leftWidth="150"
        rightWidth="412"
      />
    </Row>
    <Row>
      <Field
        component={Dropdown}
        name="teamId"
        caption="ชื่อทีม"
        captionStyle={{ fontWeight: "normal" }}
        options={props.teamOptions}
        disabled={props.disabled}
        placeholder="กรุณาเลือก"
        leftWidth="150"
        rightWidth="412"
      />
    </Row>
    <Row>
      <Field
        component={Input}
        name="position"
        caption="ตำแหน่งงาน"
        captionStyle={{ fontWeight: "normal" }}
        disabled={props.disabled}
        placeholder="กรุณาระบุ"
        leftWidth="150"
        rightWidth="412"
      />
    </Row>
  </React.Fragment>
)

const enhancer = compose(
  withStores((stores) => ({
    fetchConfig: stores.tqmUserStore.fetchConfig,
    memberTypeOptions: stores.tqmUserStore.$("configs.filters.memberType.options", []),
    memberStatusOptions: stores.tqmUserStore.$("configs.filters.memberStatus.options", []),
    teamOptions: stores.tqmUserStore.$("configs.filters.team.options", []),
  }))
)

export default enhancer(FilterPanel)
