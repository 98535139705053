import React from "react"
import styled from "@emotion/styled"
import { compose, withHandlers, lifecycle, withState } from "recompose"
import { withStores } from "@enhancers"
import NotificationCard from "./NotificationCard"
import { isEqual, isEmpty, map, reduce } from "lodash"

const Layout = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`

const Body = styled.div`
  border-top: 1px solid #eeeef2;
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  .css-x18ug3--withCaption--LeftPanel {
    min-width: unset;
  }
`
const NoNotification = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  color: #666666;
  margin-top: 50px;
`

const NotificationIndex = (props) => (
  <Layout>
    {props.notifications.length > 0 ? (
      <Body>
        {props.notifications.map((item) => (
          <NotificationCard data={item} key={item.id} />
        ))}
      </Body>
    ) : (
      <NoNotification>ไม่มีรายการแจ้งเตือน</NoNotification>
    )}
  </Layout>
)

const enhancer = compose(
  withState("backupStyles", "setBackupStyles", (props) => ({})),
  withStores((stores, props) => ({
    currentRole: stores.appStore.currentRole,
    fetchNotifications: stores.appStore.fetchNotifications,
    viewNotificationsStore: stores.appStore.viewNotifications,
    notifications: stores.appStore.$("notifications", []),
  })),
  withHandlers({
    viewNotifications: (props) => async () => {
      const ids = map(props.notifications, "id")
      if (!isEmpty(ids)) {
        await props.viewNotificationsStore(ids)
        props.fetchNotifications()
      }
    },
  }),
  lifecycle({
    async componentDidMount() {
      await this.props.fetchNotifications()
      this.props.viewNotifications()

      try {
        const $mainBody = document.getElementById("main_body")

        this.props.setBackupStyles({
          mainBodyStyles: {
            maxWidth: $mainBody.style.maxWidth,
          },
        })

        $mainBody.style.maxWidth = "unset"
      } catch (e) {}
    },
    async componentDidUpdate(prevProps) {
      const prevIds = reduce(
        prevProps.notifications,
        (memo, item) => [...memo, { id: item.id, isViewed: item.isViewed }],
        []
      )
      const nextIds = reduce(
        this.props.notifications,
        (memo, item) => [...memo, { id: item.id, isViewed: item.isViewed }],
        []
      )

      if (!isEqual(prevIds, nextIds)) {
        this.props.viewNotifications()
      }
    },
    componentWillUnmount() {
      try {
        const $mainBody = document.getElementById("main_body")

        $mainBody.style.maxWidth = this.props.backupStyles.mainBodyStyles.maxWidth
      } catch (e) {}
    },
  })
)

export default enhancer(NotificationIndex)
