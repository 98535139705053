import React from "react"
import styled from "@emotion/styled"
import { compose, withHandlers } from "recompose"
import { Button, Field, CurrencyInput, GenderInput, DatePicker } from "@components"
import { withForm, withRouter, withStores } from "@enhancers/index"
import * as path from "@common/path"
import { convertNaNtoZero, convertMinustoZero } from "@common/helper"
import {
  ValueResult,
  ResultContainer,
  TextResult,
  Footer,
  BoldText,
  Layout,
  Body,
  Unit,
  WrapInputDate,
  AgeLabel,
} from "../DebtProtectionForm/SimpleForm"
import moment from "moment"

const ConfirmButton = styled(Button)`
  width: fit-content;
  align-self: flex-end;
  margin: auto;
  margin-top: 20px;
`

const Planning = (props) => (
  <Layout>
    <Body>
      <Field
        component={GenderInput}
        name="gender"
        caption="เพศ"
        rightWidth="173"
        placeholder="กรุณาระบุ"
        validate="required"
      />
      <Unit />
      <WrapInputDate>
        {props.values.birthdate && (
          <AgeLabel show={props.values.birthdate !== null}>
            (อายุ {moment().diff(moment(props.values.birthdate), "years")} ปี)
          </AgeLabel>
        )}
        <Field
          caption="วันเกิด"
          name="birthdate"
          component={DatePicker}
          placeholder="กรุณาระบุ"
          validate="required"
          rightWidth="173"
          leftWidth="275"
          max={new Date()}
        />
      </WrapInputDate>
      <Unit />
      <Field
        component={CurrencyInput}
        name="buy"
        caption="จำนวนเงินที่ต้องเตรียม"
        rightWidth="173"
        placeholder="กรุณาระบุ"
        min={0}
      />
      <Unit>บาท</Unit>
      <Field
        component={CurrencyInput}
        name="asset"
        caption="จำนวนเงินที่เตรียมไว้แล้ว"
        rightWidth="173"
        placeholder="กรุณาระบุ"
        min={0}
      />
      <Unit>บาท</Unit>
    </Body>

    <Footer>
      <ResultContainer>
        <TextResult>
          {convertNaNtoZero(props.values.buy) -
            (convertNaNtoZero(props.values.asset) + convertNaNtoZero(props.values.capital)) <
          0 ? (
            <>
              สินทรัพย์และทุนประกันที่คุณมีครอบคลุมภาระที่คุณต้องการคุ้มครองแล้ว
              แต่คุณสามารถค้นหาแบบประกันที่ช่วยเพิ่มเติมความคุ้มครองภาระของคุณได้อีก
            </>
          ) : convertNaNtoZero(props.values.buy) -
              (convertNaNtoZero(props.values.asset) + convertNaNtoZero(props.values.capital)) <
            100000 ? (
            <>
              ทุนประกันที่คุณต้องการน้อยกว่าขั้นต่ำของแบบประกันที่มีโดยทั่วไป
              เราจะค้นหาแบบประกันให้คุณโดยเลือกจากทุนประกันขั้นต่ำที่ 100,000 บาทแทน
            </>
          ) : (
            <>
              คุณสามารถใช้แบบประกันประเภท <BoldText>สะสมทรัพย์</BoldText>{" "}
              ในการสร้างความคุ้มครองนี้โดยทุนประกันจะอยู่ในช่วง
            </>
          )}
        </TextResult>
        <ValueResult>
          {new Intl.NumberFormat().format(
            convertMinustoZero(
              convertNaNtoZero(props.values.buy) -
                (convertNaNtoZero(props.values.asset) + convertNaNtoZero(props.values.capital))
            )
          )}{" "}
          บาท
        </ValueResult>
      </ResultContainer>

      <ConfirmButton
        icon="arrow-right"
        onClick={props.onSubmit}
        disabled={
          convertNaNtoZero(props.values.buy) <
          convertNaNtoZero(props.values.asset) + convertNaNtoZero(props.values.capital)
        }
      >
        ถัดไป
      </ConfirmButton>
    </Footer>
  </Layout>
)

const enhancer = compose(
  withRouter({
    queryTypes: {
      birthdate: "date",
    },
  }),
  withStores((stores, props) => ({
    fetchById: stores.leadStore.fetchById,
    initialValues: {
      birthdate: props.location.query.birthdate,
      gender: props.location.query.gender,
    },
  })),
  withForm({ form: "PlanningFormShortEducation" }),
  withHandlers({
    onSubmit: (props) =>
      props.handleSubmit(async (values) => {
        const { buy = "0", asset = "0" } = values

        let buyEdit = isNaN(parseInt(buy)) ? 0 : parseInt(buy)
        let assetEdit = isNaN(parseInt(asset)) ? 0 : parseInt(asset)

        const { query } = props.location || {}
        const { leadId } = query || {}

        props.history.push(
          path.productsPath(leadId, {
            ...props.location.query,
            birthdate: values.birthdate,
            gender: values.gender,
            searchValue: buyEdit - assetEdit || 100000,
            type: "saving",
            searchBy: "sum_insured",
          })
        )
      }),
  })
)
export default enhancer(Planning)
