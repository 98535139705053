import React from "react"
import { GridColumn } from "@components/index"
import * as path from "@common/path"
import CommissionGraph from "./CommissionGraph"
import {
  Layout,
  ReportCardTitle,
  InsuranceCompany,
  CompanyLogo,
  FooterContainer,
  ShowMoreDetailContainer,
  ShowMoreDetailText,
  CaretRightIcon,
  Table,
  CatagoryText,
} from "./InsuranceCompanyCard"

function CommissionInsurerCard(props) {
  return (
    <Layout>
      <ReportCardTitle>คอมมิชชันแยกตามบริษัทประกัน</ReportCardTitle>
      <CommissionGraph graph={props.graph} />
      <Table data={props.data} style={{ marginTop: 27 }}>
        <GridColumn
          title="บริษัทประกัน"
          field="name"
          cell={(cell) => {
            return (
              <td>
                <InsuranceCompany>
                  <CompanyLogo src={cell.dataItem.logo} />
                  <CatagoryText>{cell.dataItem.name}</CatagoryText>
                </InsuranceCompany>
              </td>
            )
          }}
          width="144"
        />
        <GridColumn title="เบี้ยประกัน" field="netPremium" width="144" />
        <GridColumn title="คอมมิชชัน" field="commissionAmount" width="144" />
      </Table>

      <FooterContainer>
        <ShowMoreDetailContainer to={path.dashboardInsurerCommissionPath()}>
          <ShowMoreDetailText>ดูคอมมิชชันแยกตามบริษัทประกันทั้งหมด</ShowMoreDetailText>
          <CaretRightIcon awesomeIcon={"caret-right"} />
        </ShowMoreDetailContainer>
      </FooterContainer>
    </Layout>
  )
}

export default CommissionInsurerCard
