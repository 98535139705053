import React, { useState, useCallback } from "react"
import { withHooks } from "@enhancers/index"
import { compose } from "recompose"
import { CurrencyInput } from "@components"
import { IconContainer, PencilIcon, CheckIcon, Unit } from "./OrderInfo"

const RiderPremium = (props) => {
  return (
    <React.Fragment>
      <CurrencyInput
        onChange={(value) => {
          props.setRiderPremium(value)
        }}
        name={`riderPremium` + props.index}
        caption="เบี้ยต่องวด"
        leftWidth="112.55"
        rightWidth={props.isEditPrmium ? "120" : "86.4"}
        value={props.riderPremium}
        readOnly={!props.isEditPrmium}
        disabled={props.isProgress}
      />
      <Unit display={!props.isEditPrmium ? "flex" : "none"}>บาท</Unit>

      {!props.isProgress && props.orderState === "doc_verifying" && (
        <IconContainer onClick={props.isEditPrmium ? props.onSave : props.toggleEditPremium}>
          {props.isEditPrmium ? <CheckIcon icon={"check"} /> : <PencilIcon icon={"pencil"} />}
        </IconContainer>
      )}
    </React.Fragment>
  )
}
const enhancer = compose(
  withHooks((props) => {
    const [isEditPrmium, setIsEditPrmium] = useState(false)
    const [riderPremium, setRiderPremium] = useState(props.rider.premium)
    const [isProgress, setIsProgress] = useState(false)

    const toggleEditPremium = useCallback(() => {
      setIsEditPrmium(!isEditPrmium)
    })

    const onSave = useCallback(async () => {
      const riders = [
        {
          riderPolicyId: props.rider.policyId,
          riderPremium: riderPremium,
        },
      ]
      setIsProgress(true)
      try {
        await props.updatePremium(props.orderId, { riders: riders })
      } catch (error) {
        const errorMsg = error.errors?.base || "แก้ไขเบี้ยประกันไม่สำเร็จ"
        window.alert(errorMsg)
        setRiderPremium(props.rider.premium)
      }
      setIsProgress(false)
      setIsEditPrmium(!isEditPrmium)
    })

    return {
      isProgress,
      onSave,
      isEditPrmium,
      toggleEditPremium,
      riderPremium,
      setRiderPremium,
    }
  })
)

export default enhancer(RiderPremium)
