import React from "react"
import styled from "@emotion/styled"
import { compose, withProps, withHandlers, lifecycle, withState } from "recompose"
import { withStores, withRouter } from "@enhancers"

import { Grid, GridColumn, Avatar, Button, makeCell, Dropdown, LoadingScope } from "@components"
import * as path from "@common/path"

const Layout = styled.div`
  margin: 0px auto 25px auto;
`
const Table = styled(Grid)``
const Header = styled.div`
  display: flex;
  margin: 20px 0 12px 0;
  flex-wrap: wrap;
`
const Left = styled.div``
const Right = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  > div {
    margin-left: 6px;
  }
`
const LayoutDisplayLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const DisplayLogo = (props) => (
  <td>
    <LayoutDisplayLogo>
      <Avatar src={props.dataItem.logo} readOnly />
    </LayoutDisplayLogo>
  </td>
)

const LayoutDisplayWebsite = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  > a {
    width: calc(1 * (2px + 0.75rem + 1.5em));
    height: calc(1 * (2px + 0.75rem + 1.5em));
    padding: 0.375rem;
    display: flex;
    justify-content: center;
    align-items: center;

    background: white;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    padding-left: 16px;
    padding-right: 16px;

    &:hover {
      border-color: #c7cdd5;
      color: #212529;
      background-color: #ced3db;
    }
  }
`
const DisplayWebsite = (props) => (
  <td>
    <LayoutDisplayWebsite>
      <a href={props.dataItem.website} target="_blank" rel="noopener noreferrer">
        <span className="k-icon k-i-globe-outline" />
      </a>
    </LayoutDisplayWebsite>
  </td>
)

const CustomButton = styled(Button)`
  width: 96px;
`

const InsurerIndex = (props) => (
  <React.Fragment>
    {props.loading ? (
      <LoadingScope />
    ) : (
      <Layout>
        <Header>
          <Left />
          <Right>
            <CustomButton awesomeIcon="plus" onClick={props.onCreate} children="สร้าง" />
          </Right>
        </Header>
        <Table
          data={props.insurers}
          onRowClick={props.onClickRow}
          pageable
          skip={props.paging.skip}
          take={props.paging.perPage}
          total={props.paging.totalCount}
          onPageChange={props.onPageChange}
        >
          <GridColumn field="logo" title="โลโก้" editable={false} width="90px" cell={DisplayLogo} />
          <GridColumn field="name" title="ชื่อ" width="300px" />
          <GridColumn field="shortName" title="ชื่อย่อ" width="200px" />
          <GridColumn field="referenceCode" title="รหัสอ้างอิง (สมุดคุม) TQM" width="200px" />
          <GridColumn field="webSite" title="เว็บไซต์" width="100px" cell={DisplayWebsite} />
          <GridColumn
            field="companyType"
            title="ประเภท"
            width="166px"
            cell={makeCell(Dropdown, (cellProps) => ({
              readOnly: true,
              options: props.typeOptions,
              value: cellProps.dataItem.companyType,
            }))}
          />
        </Table>
      </Layout>
    )}
  </React.Fragment>
)

const enhancer = compose(
  withState("loading", "setLoading", false),
  withStores((stores) => ({
    fetchInsurers: stores.insurerStore.fetch,
    fetchConfigs: stores.insurerStore.fetchConfigs,
    insurers: stores.insurerStore.all,
    paging: stores.insurerStore.paging,
    configs: stores.insurerStore.configs,
    typeOptions: stores.insurerStore.$("configs.filters.companyType", []),
  })),
  withProps((props) => ({
    paging: {
      ...props.paging,
      skip: (props.paging.page - 1) * props.paging.perPage,
    },
  })),
  withRouter(),
  withHandlers({
    onCreate: (props) => () => {
      props.history.push(path.insurerPath("new"))
    },
    onClickRow: (props) => (item) => {
      props.history.push(path.insurerPath(item.dataItem.id))
    },
    onPageChange: (props) => async (event) => {
      const page = event.page.skip / event.page.take + 1
      await props.fetchInsurers({ ...props.values, page })
    },
  }),
  lifecycle({
    async componentDidMount() {
      this.props.setLoading(true)
      await Promise.all([this.props.fetchInsurers(), this.props.fetchConfigs()])
      this.props.setLoading(false)
    },
  })
)

export default enhancer(InsurerIndex)
