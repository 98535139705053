import React from "react"
import styled from "@emotion/styled"
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs"
import { compose, withHandlers } from "recompose"
import { Button } from "@components/index"

const Message = styled.div`
  font-size: 16px;
  font-family: Sarabun, sans-serif;
  color: #666666;
  line-height: normal;
`
const CustomDialog = styled(Dialog)`
  .k-dialog {
    background: #ffffff;
    box-shadow: 0px 3px 4px #0000000f;
    border: 1px solid #e6e6e6;
  }

  .k-window-titlebar {
    background: #294558;
    font-weight: bold;
    font-size: 16px;
    font-family: Sarabun, sans-serif;
    color: #ffffff;
    padding: 20px 15px;
    .k-dialog-title {
      line-height: normal;
    }
    .k-dialog-close {
      opacity: 1;
    }
    .k-icon {
      color: #ffffff;
      font-size: 20px;
    }
  }
`
const CencelButton = styled(Button)`
  min-height: 52px;
`
const SubmitButton = styled(Button)`
  min-height: 52px;
  background: #0275d8;
  border: 1px solid #eeeef2;
  border-radius: 0px 0px 4px 0px;
  color: #ffffff;
`

const Modal = (props) => (
  <CustomDialog className={props.className} title={props.title} onClose={props.toggleModal}>
    <Message>{props.message}</Message>
    <DialogActionsBar>
      <CencelButton onClick={props.toggleModal} styletype="delete">
        ยกเลิก
      </CencelButton>
      <SubmitButton onClick={props.onOk} styletype="normal">
        ยืนยัน
      </SubmitButton>
    </DialogActionsBar>
  </CustomDialog>
)

const enhancer = compose(
  withHandlers({
    toggleModal: (props) => () => {
      props.setModalVisible(false)
      props.onCancel && props.onCancel()
    },
  })
)

export default enhancer(Modal)
