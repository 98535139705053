import deepmerge from "deepmerge"

export const SET_BASE_STORE = "@@SET_BASE_STORE@@"

export const setStores = (updatedStates) => {
  const stores = require("./index.js").default
  stores.dispatch({
    type: SET_BASE_STORE,
    payload: updatedStates,
  })
}

export const reducer = (states = {}, action) => {
  switch (action.type) {
    case SET_BASE_STORE:
      return deepmerge(states, action.payload)
    default:
      return states
  }
}

class ModelStore {
  constructor(storeName, initialState) {
    this.storeName = storeName
    this.initialState = initialState

    this.setStates({
      [this.storeName]: this.initialState,
    })
  }

  setStores = setStores

  setState = (fieldName, value) => {
    this.setStates({
      [fieldName]: value,
    })
  }

  setStates = (values) => {
    this.setStores({
      [this.storeName]: values,
    })
  }
}

export default ModelStore
