import BaseStore from "./BaseStore"
import { tqldServer } from "@common/api"

class commissionEditableStore extends BaseStore {
  constructor() {
    super({
      all: [],
      configs: {},
    })
  }

  fetch = async (params) => {
    const response = await tqldServer.tqmBackend.get(`commission_editable_months`, params)
    this.all = response.data.data.commissionEditables

    return response.data.data.commissionEditables
  }

  fetchConfigs = async () => {
    const response = await tqldServer.tqmBackend.get(`commission_editable_months/configs`)
    this.configs = response.data.data.configs
  }

  create = async (params) => {
    const response = await tqldServer.tqmBackend.post(`commission_editable_months`, params)

    return response.data.data.commissionEditables
  }
}

export default new commissionEditableStore()
