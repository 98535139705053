import React, { useEffect } from "react"
import { compose, withState, withHandlers, defaultProps, lifecycle } from "recompose"
import { withHooks, withRouter } from "@enhancers/index"
import styled from "@styled"
import { css } from "@emotion/core"
import { desktopSize } from "@common/styled"
import { Icon, Tooltip } from "@components"
import * as path from "@common/path"
import { Authorize } from "@components"
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout"
import { enableBodyScrolling } from "@common/helper"

import icon1 from "@assets/images/menu_icon/1.svg"
import icon2 from "@assets/images/menu_icon/2.svg"
import icon3 from "@assets/images/menu_icon/3.svg"
import icon4 from "@assets/images/menu_icon/4.svg"
import icon5 from "@assets/images/menu_icon/5.svg"
import icon6 from "@assets/images/menu_icon/6.svg"
import icon7 from "@assets/images/menu_icon/7.svg"
import icon8 from "@assets/images/menu_icon/8.svg"
import icon9 from "@assets/images/menu_icon/9.svg"
import icon10 from "@assets/images/menu_icon/10.svg"
import icon11 from "@assets/images/menu_icon/11.svg"
import icon12 from "@assets/images/menu_icon/12.svg"
import icon13 from "@assets/images/menu_icon/13.svg"
import icon14 from "@assets/images/menu_icon/14.svg"
import icon15 from "@assets/images/menu_icon/15.svg"
import icon16 from "@assets/images/menu_icon/16.svg"
import icon17 from "@assets/images/menu_icon/17.svg"
import icon18 from "@assets/images/menu_icon/18.svg"
import icon19 from "@assets/images/menu_icon/19.svg"
import icon20 from "@assets/images/menu_icon/20.svg"
import icon21 from "@assets/images/menu_icon/21.svg"
import icon22 from "@assets/images/menu_icon/22.svg"
import icon23 from "@assets/images/menu_icon/50.svg"
import icon24 from "@assets/images/menu_icon/51.svg"
import icon25 from "@assets/images/menu_icon/25.svg"

const TitleLinkDesktopSize = styled.div`
  position: relative;
  align-items: center;
  flex: 1;
  justify-content: flex-start;
  font-family: "Sarabun";
  font-weight: normal;
  color: #666666;
  line-height: 21px;
  width: 100%;
  height: 100%;

  > div:first-of-type {
    display: flex;
    cursor: pointer;
    visibility: hidden;
    > div {
      > span {
        font-size: 20px;
      }
    }

    > div:first-of-type {
      margin-right: 7px;
    }

    > div:nth-of-type(2) {
      display: block;
      white-space: nowrap;
    }

    > div {
      display: flex;
      align-items: center;
      font-family: "Sarabun";
      font-weight: normal;
      color: #666666;
      font-size: 16px;
    }
  }

  /* display: none !important; */

  ${(props) =>
    desktopSize(`
      display: flex !important;
  `)}
`

const LayoutSidebar = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  background-color: #0275d8;
  top: 60px;
  left: 0px;
  min-width: 64px;
  max-width: 64px;
  transition: 0.5s;
  /* height: calc(100% - 60px); */
  height: calc(100vh - 60px);
  overflow-y: auto;

  > div:last-of-type {
    pointer-events: none;
    border-bottom: 0px;
    > div {
      display: none;
    }
  }

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  ${(props) =>
    props.toggleMenu &&
    css`
      min-width: 256px;
      max-width: 256px;
    `}
`

const MenuLink = styled.div`
  display: flex;
  align-items: center;
  min-height: 40px;
  border-bottom: 1px solid #025aa5;
  transition: padding-left 0.5s;
  background-color: #0275d8;

  ${(props) =>
    props.isactive &&
    css`
      pointer-events: none;
      background-color: #025aa5;
    `}

  ${(props) =>
    !props.disabled
      ? css`
          cursor: pointer;
        `
      : css`
          pointer-events: none;

          > div {
            opacity: 0.5;
          }
        `}

  ${(props) =>
    props.isSubMenu
      ? css`
          padding-left: 40px;
        `
      : css`
          padding-left: 20px;
        `}

  ${(props) =>
    !props.toggleMenu &&
    css`
      padding-left: 20px;
    `}

    div {
      display: flex;
      width: 100%;

      .tqm-icon {
      background-color: white;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      > img {
        color: #0d70cf;
        width: 18px;
        height: 18px;
      }
  }

 .tqm-menu-label {
    color: #ffffff;
    margin-left: 15px;
    font-size: 16px;
    font-family: "Sarabun";
    width: calc(100% - 50px);
    text-overflow: clip;
    white-space: nowrap;
    overflow: hidden;
  }
    }
`
const CustomPanelBar = styled(PanelBar)`
  background-color: #0275d8;
  width: 100%;
  border: 0;
  transition: 0.5s, padding-left 0.5s;

   ${(props) =>
     props.isactive &&
     css`
       pointer-events: none;
       background-color: #025aa5;
     `}

  ${(props) =>
    !props.disabled
      ? css`
          cursor: pointer;
        `
      : css`
          pointer-events: none;

          .k-link.k-header > div {
            opacity: 0.5;
          }
        `}

  ${(props) =>
    props.toggleMenu
      ? css`
          min-width: 256px;
          max-width: 256px;
        `
      : css`
          min-width: 64px;
          max-width: 64px;
        `}

  .k-link.k-header {
    cursor: pointer;

    > div  {
      display: flex;
    width: 100%;
      .tqm-icon {
      background-color: white;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      > img {
        color: #0d70cf;
        width: 18px;
        height: 18px;
      }
    }

  .tqm-menu-label {
      color: #ffffff;
      margin-left: 15px;
      font-size: 16px;
      font-family: "Sarabun";
      width: calc(100% - 50px);
      text-overflow: clip;
      white-space: nowrap;
      overflow: hidden;
    }
    }
  }

  .k-animation-container {
    z-index: unset;
    border-radius: 0;
  }

  .k-content {
    background-color: transparent;
  }

  .k-item.k-state-default {
    .k-link {
      padding: 0 0 0 20px;
      background-color: #fff;
      color: #ffffff;
      font-weight: normal;
      font-family: Sarabun, sans-serif;
      text-decoration: underline;
    }

    .k-link.k-state-selected.k-state-focused {
      box-shadow: none;
    }

    .k-link.k-header {
      background-color: transparent;
      color: #ffffff;
      font-family: Sarabun, sans-serif;
      font-weight: normal;
      text-decoration: none;
      height: 40px;
      border-bottom: 1px solid #025aa5;
      transition: padding-left 0.5s;

      :hover {
        background-color: transparent;
        color: #ffffff;
        border-bottom: 1px solid #025aa5;
      }

      ${(props) =>
        props.toggleMenu && props.isSubMenu
          ? css`
              padding-left: 40px;
            `
          : css`
              padding-left: 20px;
            `}
    }

    .k-link.k-header.k-state-selected {
      background-color: transparent;
      color: #ffffff;
      border-bottom: 1px solid #025aa5;

      :hover {
        background-color: transparent;
        color: #ffffff;
      }
    }
  }

  .k-item.k-state-default.k-state-expanded {
    .k-link {
      background-color: transparent;
      color: #ffffff;
      font-weight: normal;
      font-family: Sarabun, sans-serif;
      text-decoration: underline;
    }

    .k-link.k-header {
      background-color: transparent;
      color: #ffffff;
      text-decoration: none;

      :hover {
        background-color: transparent;
        color: #ffffff;
      }
    }

    .k-link.k-header.k-state-selected {
      background-color: transparent;
      color: #ffffff;

      :hover {
        background-color: transparent;
        color: #ffffff;
      }
    }
  }

  .k-link.k-header.k-state-focused {
    box-shadow: unset;

    :hover {
      box-shadow: unset;
      }
  }

  .k-icon.k-i-arrow-n.k-panelbar-collapse {
    right: 20;
    transition: opacity 0.5s;

    ::before {
      font-size: 20px;
      color: #ffffff;
      content: "\\e013";
    }

    ${(props) =>
      (!props.toggleMenu || props.disabled) &&
      css`
        padding: 0 0 0 20px;
        visibility: hidden;
        opacity: 0;
      `}
  }

  .k-icon.k-i-arrow-s.k-panelbar-expand {
    right: 20;
    transition: opacity 0.5s;

    ::before {
      font-size: 20px;
      color: #ffffff;
      content: "\\e015";
    }

    ${(props) =>
      (!props.toggleMenu || props.disabled) &&
      css`
        padding: 0 0 0 20px;
        visibility: hidden;
        opacity: 0;
      `}
  }
`

const SidebarBackground = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  bottom: 0;
  top: 60px;
  left: 0;
  right: 0;

  ${(props) =>
    !props.toggleMenu &&
    css`
      display: contents;
    `}
`

const MenuButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: #deeaf6;
  min-height: 40px;
  padding-left: 24px;
  transition: 0.5s;

  > .tqm-icon > span {
    color: #025aa5;
    font-size: 20px;
  }

  > .tqm-menu-label {
    color: #025aa5;
    margin-left: 15px;
    font-size: 16px;
    font-family: "Sarabun";
    width: calc(100% - 50px);
    text-overflow: clip;
    white-space: nowrap;
    overflow: hidden;
  }
`

const SidebarMenu = (props) => (
  <TitleLinkDesktopSize>
    <div onClick={props.toggleSidebarMenus}>
      <div>
        <Icon awesomeIcon="bars" />
      </div>
      <div>{props.namePath}</div>
    </div>

    <SidebarBackground toggleMenu={props.toggleSidebar} onClick={props.toggleSidebarMenus} />

    <LayoutSidebar toggleMenu={props.toggleSidebar}>
      <MenuButton onClick={props.toggleSidebarMenus}>
        <div className="tqm-icon">
          <Icon awesomeIcon="bars" />
        </div>
        <div className="tqm-menu-label">
          <div>เมนู</div>
        </div>
      </MenuButton>
      {props.menuLists.map((item, index) => (
        <Authorize key={index} roles={item.roles}>
          {({ authorized }) => (
            <>
              {item.subMenuItems ? (
                <CustomPanelBar disabled={!authorized} toggleMenu={props.toggleSidebar}>
                  <PanelBarItem
                    expanded={true}
                    title={
                      <>
                        {props.toggleSidebar ? (
                          <div>
                            <div className="tqm-icon">
                              <img src={item.icon} />
                            </div>
                            <div className="tqm-menu-label">
                              <div>{item.label}</div>
                            </div>
                          </div>
                        ) : (
                          <Tooltip position="right" anchorElement="target" delay={1}>
                            <div className="tqm-icon">
                              <img src={item.icon} title={item.label} />
                            </div>
                            <div className="tqm-menu-label">
                              <div>{item.label}</div>
                            </div>
                          </Tooltip>
                        )}
                      </>
                    }
                  >
                    {item.subMenuItems.map((subMenuItem, subMenuIndex) => (
                      <Authorize key={subMenuIndex} roles={subMenuItem.roles}>
                        {({ authorized }) => (
                          <>
                            {subMenuItem.subMenuItems ? (
                              <CustomPanelBar
                                disabled={!authorized}
                                toggleMenu={props.toggleSidebar}
                                isSubMenu={true}
                              >
                                <PanelBarItem
                                  expanded={true}
                                  title={
                                    <>
                                      {props.toggleSidebar ? (
                                        <div>
                                          <div className="tqm-icon">
                                            <img src={subMenuItem.icon} />
                                          </div>
                                          <div className="tqm-menu-label">
                                            <div>{subMenuItem.label}</div>
                                          </div>
                                        </div>
                                      ) : (
                                        <Tooltip position="right" anchorElement="target" delay={1}>
                                          <div className="tqm-icon">
                                            <img src={subMenuItem.icon} title={subMenuItem.label} />
                                          </div>
                                          <div className="tqm-menu-label">
                                            <div>{subMenuItem.label}</div>
                                          </div>
                                        </Tooltip>
                                      )}
                                    </>
                                  }
                                >
                                  {subMenuItem.subMenuItems.map((subItem, subIndex) => (
                                    <Authorize key={subIndex} roles={subItem.roles}>
                                      {({ authorized }) => (
                                        <>
                                          <MenuLink
                                            toggleMenu={props.toggleSidebar}
                                            disabled={!authorized}
                                            onClick={props.makeOnClickLink(subItem.path)}
                                            isSubMenu={true}
                                            isactive={subItem.path === props.location.pathname}
                                          >
                                            {props.toggleSidebar ? (
                                              <div>
                                                <div className="tqm-icon">
                                                  <img src={subItem.icon} />
                                                </div>
                                                <div className="tqm-menu-label">
                                                  <div>{subItem.label}</div>
                                                </div>
                                              </div>
                                            ) : (
                                              <Tooltip
                                                position="right"
                                                anchorElement="target"
                                                delay={1}
                                              >
                                                <div className="tqm-icon">
                                                  <img src={subItem.icon} title={subItem.label} />
                                                </div>
                                                <div className="tqm-menu-label">
                                                  <div>{subItem.label}</div>
                                                </div>
                                              </Tooltip>
                                            )}
                                          </MenuLink>
                                        </>
                                      )}
                                    </Authorize>
                                  ))}
                                </PanelBarItem>
                              </CustomPanelBar>
                            ) : (
                              <MenuLink
                                toggleMenu={props.toggleSidebar}
                                disabled={!authorized}
                                isSubMenu={true}
                                onClick={props.makeOnClickLink(subMenuItem.path)}
                                isactive={subMenuItem.path === props.location.pathname}
                              >
                                {props.toggleSidebar ? (
                                  <div>
                                    <div className="tqm-icon">
                                      <img src={subMenuItem.icon} />
                                    </div>
                                    <div className="tqm-menu-label">
                                      <div>{subMenuItem.label}</div>
                                    </div>
                                  </div>
                                ) : (
                                  <Tooltip position="right" anchorElement="target" delay={1}>
                                    <div className="tqm-icon">
                                      <img src={subMenuItem.icon} title={subMenuItem.label} />
                                    </div>
                                    <div className="tqm-menu-label">
                                      <div>{subMenuItem.label}</div>
                                    </div>
                                  </Tooltip>
                                )}
                              </MenuLink>
                            )}
                          </>
                        )}
                      </Authorize>
                    ))}
                  </PanelBarItem>
                </CustomPanelBar>
              ) : (
                <MenuLink
                  disabled={!authorized}
                  onClick={props.makeOnClickLink(item.path)}
                  toggleMenu={true}
                  isactive={item.path === props.location.pathname}
                >
                  {props.toggleSidebar ? (
                    <div>
                      <div className="tqm-icon">
                        <img src={item.icon} />
                      </div>
                      <div className="tqm-menu-label">
                        <div>{item.label}</div>
                      </div>
                    </div>
                  ) : (
                    <Tooltip position="right" anchorElement="target" delay={1}>
                      <div className="tqm-icon">
                        <img src={item.icon} title={item.label} />
                      </div>
                      <div className="tqm-menu-label">
                        <div>{item.label}</div>
                      </div>
                    </Tooltip>
                  )}
                </MenuLink>
              )}
            </>
          )}
        </Authorize>
      ))}
    </LayoutSidebar>
  </TitleLinkDesktopSize>
)

const MENULISTS = [
  {
    label: "แดชบอร์ด",
    roles: [
      "member.team_leader",
      "member.salesman",
      "member.biz_dev",
      "member.admin",
      "member.brokerage_admin",
    ],
    path: path.dashboardPath(),
    icon: icon1,
  },
  {
    label: "ลีด",
    icon: icon2,
    subMenuItems: [
      {
        label: "จัดการลีดทีคิวเอ็ม",
        roles: ["member.lead_admin", "member.biz_dev", "member.admin"],
        path: path.batchesPath(),
        icon: icon3,
      },
      {
        label: "จัดการลีดจากผู้แนะนำ",
        roles: ["member.lead_admin", "member.team_leader", "member.brokerage_admin"],
        path: path.leadPoolsPath(),
        icon: icon25,
      },
      {
        label: "ลีดทีคิวเอ็ม",
        roles: ["member.team_leader", "member.salesman"],
        path: path.leadsPath("all"),
        icon: icon4,
      },
      {
        label: "ลูกค้าใหม่",
        roles: [
          "member.team_leader",
          "member.salesman",
          "member.biz_dev",
          "member.admin",
          "member.affiliate",
        ],
        path: path.leadsPath("custom"),
        icon: icon5,
      },
      {
        label: "คำสั่งซื้อ",
        roles: ["member.team_leader", "member.salesman", "member.biz_dev", "member.affiliate"],
        path: path.ordersPath(),
        icon: icon6,
      },
    ],
    roles: [
      "member.team_leader",
      "member.salesman",
      "member.biz_dev",
      "member.lead_admin",
      "member.affiliate",
      "member.brokerage_admin",
      "member.admin",
    ],
  },
  {
    label: "แบบประกัน",
    icon: icon7,
    subMenuItems: [
      {
        label: "ผลิตภัณฑ์",
        icon: icon8,
        roles: ["member.team_leader", "member.salesman", "member.biz_dev", "member.affiliate"],
        subMenuItems: [
          {
            label: "วางแผนค้นหาประกัน",
            roles: ["member.team_leader", "member.salesman", "member.biz_dev", "member.affiliate"],
            path: path.planningPath(),
            icon: icon9,
          },
          {
            label: "เลือกแบบประกัน",
            roles: ["member.team_leader", "member.salesman", "member.biz_dev", "member.affiliate"],
            path: path.productsSearchPath(),
            icon: icon10,
          },
        ],
      },
      {
        label: "แบบประกันชีวิต",
        roles: ["member.policy_admin", "member.biz_dev"],
        path: path.lifeInsurancePoliciesPath(),
        icon: icon11,
      },
      {
        label: "สัญญาเพิ่มเติม",
        roles: ["member.policy_admin", "member.biz_dev"],
        path: path.healthInsurancePoliciesPath(),
        icon: icon12,
      },
      {
        label: "จัดการแพ็กเกจ",
        roles: ["member.policy_admin", "member.biz_dev"],
        path: path.packagesPath(),
        icon: icon13,
      },
      {
        label: "ตั้งค่าวิธีจ่ายเงิน",
        roles: ["member.policy_admin", "member.biz_dev"],
        path: path.paymentMappingsPath(),
        icon: icon14,
      },
      {
        label: "ตั้งค่าวิธีจ่ายเงิน (แพ็กเกจ)",
        roles: ["member.policy_admin", "member.biz_dev"],
        path: path.packagePaymentMappingsPath(),
        icon: icon24,
      },
    ],
    roles: [
      "member.team_leader",
      "member.salesman",
      "member.biz_dev",
      "member.policy_admin",
      "member.affiliate",
    ],
  },
  {
    label: "บริษัทประกัน",
    roles: ["member.policy_admin", "member.biz_dev"],
    path: path.insurersPath(),
    icon: icon15,
  },
  {
    label: "คอมมิชชัน",
    icon: icon16,
    subMenuItems: [
      {
        label: "คอมมิชชันรับ",
        roles: ["member.accountant", "member.accountant_approver"],
        path: path.insurerCommissionsPath(),
        icon: icon17,
      },
      {
        label: "คอมมิชชันจ่าย",
        roles: ["member.accountant", "member.accountant_approver"],
        path: path.saleCommissionsPath(),
        icon: icon18,
      },
      {
        label: "ตั้งค่าคอมมิชชันรับ",
        roles: [
          "member.policy_admin",
          "member.biz_dev",
          "member.accountant",
          "member.accountant_approver",
        ],
        path: path.commissisonPath(),
        icon: icon19,
      },
      {
        label: "ตั้งค่าการแก้ไขคอมมิชชัน",
        roles: ["member.accountant_approver"],
        path: path.commissionEditSettingPath(),
        icon: icon20,
      },
      {
        label: "คำนวณโบนัส",
        roles: [
          "member.policy_admin",
          "member.biz_dev",
          "member.accountant",
          "member.accountant_approver",
        ],
        path: path.bonusCalculationPath(),
        icon: icon20,
      },
    ],
    roles: [
      "member.policy_admin",
      "member.biz_dev",
      "member.accountant",
      "member.accountant_approver",
    ],
  },
  {
    label: "การบริหารจัดการทีม",
    path: path.teamsPath(),
    roles: ["member.admin","member.biz_dev",],
    icon: icon21,
  },
  {
    label: "จัดการแผนการตลาด",
    path: path.marketPlansPath(),
    roles: ["member.admin", "member.biz_dev", "member.accountant", "member.accountant_approver"],
    icon: icon23,
  },
  // {
  //   label: "การบริหารจัดการทีม",
  //   icon: icon21,
  //   subMenuItems: [
  //     {
  //       label: "การบริหารจัดการทีม",
  //       roles: ["member.admin"],
  //       path: path.teamsPath(),
  //       icon: icon21,
  //     },
  //     {
  //       label: "จัดการแผนการตลาด",
  //       roles: ["member.admin"],
  //       path: path.marketPlansPath(),
  //       icon: icon23,
  //     },
  //   ],
  //   roles: ["member.admin"],
  // },
  {
    label: "จัดการผู้ใช้",
    roles: ["member.admin", "member.biz_dev", "member.accountant", "member.accountant_approver"],
    path: path.tqmMembersPath(),
    icon: icon22,
  },
  // {
  //   label: "จัดการผู้ใช้",
  //   subMenuItems: [
  //     {
  //       label: "จัดการผู้ใช้ของทีคิวเอ็ม",
  //       roles: ["member.admin"],
  //       path: path.tqmMembersPath(),
  //       icon: icon22,
  //     },
  //   ],
  //   roles: ["member.admin"],
  //   icon: icon22,
  // },

  {
    label: "",
  },
]

// const MENULISTS = [
//   {
//     label: "จัดการลีดทีคิวเอ็ม",
//     roles: ["member.lead_admin"],
//     path: path.batchesPath(),
//     icon: icon01,
//   },
//   {
//     label: "ลีดทีคิวเอ็ม",
//     roles: ["member.team_leader", "member.salesman"],
//     path: path.leadsPath("all"),
//     icon: icon02,
//   },
//   {
//     label: "ลูกค้าใหม่",
//     roles: ["member.team_leader", "member.salesman", "member.biz_dev"],
//     path: path.leadsPath("custom"),
//     icon: icon03,
//   },
//   {
//     label: "คำสั่งซื้อ",
//     roles: ["member.team_leader", "member.salesman", "member.biz_dev"],
//     path: path.ordersPath(),
//     icon: icon04,
//   },
//   {
//     label: "ผลิตภัณฑ์",
//     roles: ["member.team_leader", "member.salesman", "member.biz_dev"],
//     path: path.homePath(),
//     icon: icon05,
//   },
//   {
//     label: "คอมมิชชั่นรับ",
//     roles: ["member.accountant", "member.accountant_approver"],
//     path: path.insurerCommissionsPath(),
//     icon: icon06,
//   },
//   {
//     label: "คอมมิชชั่นจ่าย",
//     roles: ["member.accountant", "member.accountant_approver"],
//     path: path.saleCommissionsPath(),
//     icon: icon07,
//   },
//   {
//     label: "บริษัทประกัน",
//     roles: ["member.policy_admin", "member.biz_dev"],
//     path: path.insurersPath(),
//     icon: icon08,
//   },
//   {
//     label: "แบบประกันชีวิต",
//     roles: ["member.policy_admin", "member.biz_dev"],
//     path: path.lifeInsurancePoliciesPath(),
//     icon: icon09,
//   },
//   {
//     label: "สัญญาเพิ่มเติม",
//     roles: ["member.policy_admin", "member.biz_dev"],
//     path: path.healthInsurancePoliciesPath(),
//     icon: icon10,
//   },
//   {
//     label: "จัดการแพ็กเก็จ",
//     roles: ["member.policy_admin", "member.biz_dev"],
//     path: path.packagesPath(),
//     icon: icon11,
//   },
//   {
//     label: "ตั้งค่าวิธีจ่ายเงิน",
//     roles: [
//       "member.policy_admin",
//       "member.biz_dev",
//       "member.accountant",
//       "member.accountant_approver",
//     ],
//     path: path.paymentMappingsPath(),
//     icon: icon12,
//   },
//   {
//     label: "ตั้งค่าคอมมิชชั่นรับ",
//     roles: [
//       "member.policy_admin",
//       "member.biz_dev",
//       "member.accountant",
//       "member.accountant_approver",
//     ],
//     path: path.commissisonPath(),
//     icon: icon13,
//   },
//   {
//     label: "การบริหารจัดการทีม",
//     roles: ["member.admin"],
//     path: path.teamsPath(),
//     icon: icon14,
//   },
//   {
//     label: "จัดการผู้ใช้ของทีคิวเอ็ม",
//     roles: ["member.admin"],
//     path: path.tqmMembersPath(),
//     icon: icon15,
//   },

//   {
//     label: "สรุปยอดขาย",
//     roles: ["member.admin"],
//     path: path.dashboardPath(),
//   },
//   {
//     label: "สถิติ",
//     roles: ["member.admin"],
//     path: path.statsPath(),
//   },
// ]

const enhancer = compose(
  defaultProps({ menuLists: MENULISTS }),
  withRouter(),
  withState("toggleSidebar", "setToggleSidebar", false),
  // withState("showPlanningModal", "setShowPlanningModal", false),
  withHandlers({
    makeOnClickLink: (props) => (path) =>
      path &&
      (() => {
        if (path.startsWith("http")) {
          window.open(path, "_blank")
          props.setToggleSidebar(!props.toggleSidebar)
          props.setToggleSidebar(false)
        } else {
          setTimeout(() => props.history.push(path), 200)
          props.setToggleSidebar(false)
        }
      }),
    // gotoProduct: (props) => () => {
    //   props.setShowPlanningModal(true)
    //   props.setToggleSidebar(!props.toggleSidebar)
    // },
    toggleSidebarMenus: (props) => () => {
      props.setToggleSidebar(!props.toggleSidebar)
    },
  }),
  lifecycle({
    componentDidMount() {
      if (this.props.toggleSidebar) {
        enableBodyScrolling(false)
      }
    },
    componentDidUpdate(prevProps) {
      if (prevProps.toggleSidebar !== this.props.toggleSidebar) {
        enableBodyScrolling(!this.props.toggleSidebar)
      }
    },
    componentWillUnmount() {
      if (this.props.toggleSidebar) {
        enableBodyScrolling(true)
      }
    },
  })
)

export default enhancer(SidebarMenu)
