import React from "react"
import styled from "@emotion/styled"
import { css } from "@styled"
import { compose, withHandlers } from "recompose"
import { withForm, withStores, withRouter } from "@enhancers/index"
import { Button, Field, CurrencyInput, Radio, Link, GenderInput, DatePicker } from "@components"
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout"
import { Switch, Route } from "react-router-dom"
import * as path from "@common/path"
import { url2Obj, convertNaNtoZero, convertMinustoZero } from "@common/helper"

import {
  Body,
  Layout,
  BoldText,
  Footer,
  ResultContainer,
  TextResult,
  ValueResult,
  AgeAndGenderLayout,
} from "../DebtProtectionForm/AdvanceForm"
import { WrapInputDate, AgeLabel } from "../DebtProtectionForm/SimpleForm"
import moment from "moment"

import ChooseForm from "./ChooseForm"
import SuggestForm from "./SuggestForm"

const CustomPanelBar = styled(PanelBar)`
  width: 100%;
  margin-top: 16px;
  border: unset;

  > div {
    > span.k-link.k-header {
      background-color: #627b8b;
      border-color: #627b8b;
      color: #fff;
      :hover {
        background-color: #627b8b;
        border-color: #627b8b;
        color: #fff;
      }
    }
    > span.k-link.k-header.k-state-selected {
      border: unset;
      box-shadow: unset;
      background-color: #627b8b;
      border-color: #627b8b;
      color: #fff;
    }
    > span.k-link.k-header.k-state-selected.k-state-focused {
      border: unset;
      box-shadow: unset;
      background-color: #627b8b;
      border-color: #627b8b;
      color: #fff;
    }
    > div {
      z-index: unset;
      > div {
        margin: 16px 15px 12px 15px;
        > div {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          > div {
            > div {
              display: flex;
              justify-content: space-between;
              margin-top: 16px;
            }
            width: 100%;
          }
        }
      }
    }
  }
`

const HypothesisContainer = styled.div`
  display: flex;
`

const HypothesisInputFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
`
const HypothesisInputFieldSubContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const Unit = styled.div`
  width: 29px;
`
const UnitCustom = styled.div`
  margin-left: 6px;
`

const Menu = styled.div`
  display: flex;
  justify-content: center !important;
  margin-top: 16px;
  > a:first-of-type {
    border-radius: 4px 0px 0px 4px;
  }
  > a:last-child {
    width: 113px;
    border-radius: 0px 4px 4px 0px;
  }
`
const MenuItem = styled(Link)`
  background: #ffffff;
  border: 1px solid #deeaf6;
  opacity: 1;
  width: 88px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #deeaf6;
  font-size: 16px;
  font-family: regular;
  :active,
  :hover {
    opacity: 0.4;
    color: #0275d8;
  }

  ${(props) =>
    props.active &&
    css`
      border: 1px solid #0275d8;
      pointer-events: none;
      background: white;
      color: #0275d8;
      font-family: Sarabun, sans-serif;
      font-weight: bold;
    `}
`

const ConfirmButton = styled(Button)`
  display: flex;
  margin: auto;
  width: fit-content;
  margin-top: 16px;
`

const PanelBarThreeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  > div {
    :nth-of-type(odd) {
      width: 90%;
      display: flex;
      justify-content: space-between;
      padding-right: 10px;
    }
    :nth-of-type(even) {
      width: 10%;
      display: flex;
      align-items: center;
    }
  }
`

const radioHypothesisOption = [
  {
    label: "วางแผนว่าจะมีบุตรในอีก",
    value: "planChild",
  },
  {
    label: "มีบุตรแล้ว อายุบุตรปัจจุบัน",
    value: "currentChild",
  },
]

const Planning = (props) => (
  <Layout>
    <Body>
      <AgeAndGenderLayout>
        <Field
          component={GenderInput}
          name="gender"
          caption="เพศ"
          rightWidth="200"
          placeholder="กรุณาระบุ"
          validate="required"
        />
        <Unit />
        <WrapInputDate>
          {props.values.birthdate && (
            <AgeLabel show={props.values.birthdate !== null}>
              (อายุ {moment().diff(moment(props.values.birthdate), "years")} ปี)
            </AgeLabel>
          )}
          <Field
            caption="วันเกิด"
            name="birthdate"
            component={DatePicker}
            placeholder="กรุณาระบุ"
            validate="required"
            rightWidth="200"
            leftWidth="225"
            max={new Date()}
          />
        </WrapInputDate>
        <Unit />
      </AgeAndGenderLayout>
      <CustomPanelBar>
        <PanelBarItem expanded={true} title="สมมติฐาน">
          <HypothesisContainer>
            <Field
              component={Radio}
              options={radioHypothesisOption}
              name="HypothesisRadio"
              rightWidth="200"
              placeholder="กรุณาระบุ"
            />
            <HypothesisInputFieldContainer>
              <HypothesisInputFieldSubContainer>
                <Field
                  component={CurrencyInput}
                  name="planChild"
                  placeholder="กรุณาระบุ"
                  readOnly={props.values.HypothesisRadio !== "planChild"}
                  onChange={props.onTermTypeChange}
                  min={0}
                  max={100}
                />
                <UnitCustom>ปี</UnitCustom>
              </HypothesisInputFieldSubContainer>
              <HypothesisInputFieldSubContainer>
                <Field
                  component={CurrencyInput}
                  name="currentChild"
                  placeholder="กรุณาระบุ"
                  readOnly={props.values.HypothesisRadio !== "currentChild"}
                  onChange={props.onTermTypeChange}
                  min={0}
                  max={100}
                />
                <UnitCustom>ปี</UnitCustom>
              </HypothesisInputFieldSubContainer>
            </HypothesisInputFieldContainer>
          </HypothesisContainer>
        </PanelBarItem>

        <PanelBarItem expanded={true} title={"ระดับการศึกษาที่คาดหวัง"}>
          <Menu>
            <MenuItem
              to={path.planningTypeFullSuggestPath(props.match.params.type, {
                lead_id: url2Obj(props.location.search).leadId,
                age: url2Obj(props.location.search).age,
                gender: url2Obj(props.location.search).gender,
              })}
              active={props.location.pathname === "/products/planning/education/full/suggest"}
            >
              แนะนำ
            </MenuItem>
            <MenuItem
              to={path.planningTypeFullChoosePath(props.match.params.type, {
                lead_id: url2Obj(props.location.search).leadId,
                age: url2Obj(props.location.search).age,
                gender: url2Obj(props.location.search).gender,
              })}
              active={props.location.pathname === "/products/planning/education/full/choose"}
            >
              เลือกเอง
            </MenuItem>
          </Menu>
          <Switch>
            <Route
              path={path.planningTypeFullSuggestPath(":type")}
              render={() => <SuggestForm {...props} />}
            />
            <Route
              path={path.planningTypeFullChoosePath(":type")}
              render={() => <ChooseForm {...props} />}
            />
          </Switch>
        </PanelBarItem>

        <PanelBarItem title={"เตรียมพร้อมค่าใช้จ่าย"} expanded={true}>
          <PanelBarThreeContainer>
            <Field
              component={CurrencyInput}
              name="AmountToPrepare"
              caption="จำนวนเงินที่ต้องเตรียม"
              rightWidth="200"
              placeholder="กรุณาระบุ"
              readOnly
            />
            <Unit>บาท</Unit>
            <Field
              component={CurrencyInput}
              name="AmountAlreadyPrepared"
              caption="จำนวนเงินที่เตรียมไว้แล้ว"
              rightWidth="200"
              placeholder="กรุณาระบุ"
              min={0}
            />
            <Unit>บาท</Unit>
          </PanelBarThreeContainer>
        </PanelBarItem>
      </CustomPanelBar>
    </Body>

    <Footer>
      <ResultContainer>
        <TextResult>
          {convertNaNtoZero(props.values.AmountToPrepare) -
            convertNaNtoZero(props.values.AmountAlreadyPrepared) <
          0 ? (
            <>
              สินทรัพย์และทุนประกันที่คุณมีครอบคลุมภาระที่คุณต้องการคุ้มครองแล้ว
              แต่คุณสามารถค้นหาแบบประกันที่ช่วยเพิ่มเติมความคุ้มครองภาระของคุณได้อีก
            </>
          ) : convertNaNtoZero(props.values.AmountToPrepare) -
              convertNaNtoZero(props.values.AmountAlreadyPrepared) <
            100000 ? (
            <>
              ทุนประกันที่คุณต้องการน้อยกว่าขั้นต่ำของแบบประกันที่มีโดยทั่วไป
              เราจะค้นหาแบบประกันให้คุณโดยเลือกจากทุนประกันขั้นต่ำที่ 100,000 บาทแทน
            </>
          ) : (
            <>
              คุณสามารถใช้แบบประกันประเภท <BoldText>สะสมทรัพย์</BoldText>{" "}
              ในการสร้างความคุ้มครองนี้โดยทุนประกันจะอยู่ในช่วง
            </>
          )}
        </TextResult>
        <ValueResult>
          {new Intl.NumberFormat().format(
            convertMinustoZero(
              convertNaNtoZero(props.values.AmountToPrepare) -
                convertNaNtoZero(props.values.AmountAlreadyPrepared)
            )
          )}{" "}
          บาท
        </ValueResult>
      </ResultContainer>

      <ConfirmButton
        icon="arrow-right"
        onClick={props.onSubmit}
        disabled={
          convertNaNtoZero(props.values.AmountToPrepare) <
          convertNaNtoZero(props.values.AmountAlreadyPrepared)
        }
      >
        ถัดไป
      </ConfirmButton>
    </Footer>
  </Layout>
)

const enhancer = compose(
  withRouter({
    queryTypes: {
      birthdate: "date",
    },
  }),
  withStores((stores, props) => ({
    initialValues: {
      termType0: "normal",
      termType1: "normal",
      termType2: "normal",
      termType3: "normal",
      termType4: "normal",
      termType5: "normal",
      termPrice0: 419642,
      termPrice1: 249889,
      termPrice2: 337310,
      termPrice3: 860256,
      termPrice4: 966927,
      termPrice5: 1568742,
      planChild: 0,
      currentChild: 0,
      HypothesisRadio: "planChild",
      inflationPerYear: 4,

      birthdate: props.location.query.birthdate,
      gender: props.location.query.gender,
    },
    fetchById: stores.leadStore.fetchById,
  })),
  withForm({
    form: "PlanningFormFull",
    enableReinitialize: true,
    onChange: (values, _, props) => {
      if (props.location.pathname === "/products/planning/education/full/suggest") {
        let first = convertNaNtoZero(values.planChild)
        let second = convertNaNtoZero(values.currentChild)
        let sum0 = 0
        let sum1 = 0
        let sum2 = 0
        let sum3 = 0
        let sum4 = 0
        let sum5 = 0
        let i0 = 0
        let i1 = 0
        let i2 = 0
        let i3 = 0
        let i4 = 0
        let i5 = 0
        let cost0 = 0
        let cost1 = 0
        let cost2 = 0
        let cost3 = 0
        let cost4 = 0
        let cost5 = 0
        let func0 = 0
        let func1 = 0
        let func2 = 0
        let func3 = 0
        let func4 = 0
        let func5 = 0
        let count = 0

        const ArraySelector = [
          values.termType0,
          values.termType1,
          values.termType2,
          values.termType3,
          values.termType4,
          values.termType5,
        ]

        while (count < ArraySelector.length) {
          if (ArraySelector[count] === "normal") {
            if (count === 0) cost0 = 50000
            if (count === 1) cost1 = 50000
            if (count === 2) cost2 = 60000
            if (count === 3) cost3 = 100000
            if (count === 4) cost4 = 200000
            if (count === 5) cost5 = 300000
          } else if (ArraySelector[count] === "national") {
            if (count === 0) cost0 = 100000
            if (count === 1) cost1 = 100000
            if (count === 2) cost2 = 120000
            if (count === 3) cost3 = 200000
            if (count === 4) cost4 = 400000
            if (count === 5) cost5 = 500000
          } else {
            if (count === 0) cost0 = 400000
            if (count === 1) cost1 = 400000
            if (count === 2) cost2 = 480000
            if (count === 3) cost3 = 800000
            if (count === 4) cost4 = 1600000
            if (count === 5) cost5 = 1800000
          }
          count++
        }

        if (values.HypothesisRadio === "planChild") {
          func0 = first + 6
          func1 = first + 12
          func2 = first + 15
          func3 = first + 18
          func4 = first + 22
          func5 = first + 24
        } else {
          func0 = 6 - second
          func1 = 12 - second
          func2 = 15 - second
          func3 = 18 - second
          func4 = 22 - second
          func5 = 24 - second
        }

        while (i0 < 6) {
          sum0 += cost0 * Math.pow(1.04, func0 + i0)
          i0++
        }
        while (i1 < 3) {
          sum1 += cost1 * Math.pow(1.04, func1 + i1)
          i1++
        }
        while (i2 < 3) {
          sum2 += cost2 * Math.pow(1.04, func2 + i2)
          i2++
        }
        while (i3 < 4) {
          sum3 += cost3 * Math.pow(1.04, func3 + i3)
          i3++
        }
        while (i4 < 2) {
          sum4 += cost4 * Math.pow(1.04, func4 + i4)
          i4++
        }
        while (i5 < 2) {
          sum5 += cost5 * Math.pow(1.04, func5 + i5)
          i5++
        }

        values.termPrice0 = Math.round(sum0)
        values.termPrice1 = Math.round(sum1)
        values.termPrice2 = Math.round(sum2)
        values.termPrice3 = Math.round(sum3)
        values.termPrice4 = Math.round(sum4)
        values.termPrice5 = Math.round(sum5)

        values.AmountToPrepare =
          (values.level0 ? Math.round(sum0) : 0) +
          (values.level1 ? Math.round(sum1) : 0) +
          (values.level2 ? Math.round(sum2) : 0) +
          (values.level3 ? Math.round(sum3) : 0) +
          (values.level4 ? Math.round(sum4) : 0) +
          (values.level5 ? Math.round(sum5) : 0)
      } else {
        let first = convertNaNtoZero(values.planChild)
        let second = convertNaNtoZero(values.currentChild)
        let third = convertNaNtoZero(values.inflationPerYear)
        let sum0 = 0
        let sum1 = 0
        let sum2 = 0
        let sum3 = 0
        let sum4 = 0
        let sum5 = 0
        let i0 = 0
        let i1 = 0
        let i2 = 0
        let i3 = 0
        let i4 = 0
        let i5 = 0
        let cost0 = convertNaNtoZero(values.termPricePerYear0)
        let cost1 = convertNaNtoZero(values.termPricePerYear1)
        let cost2 = convertNaNtoZero(values.termPricePerYear2)
        let cost3 = convertNaNtoZero(values.termPricePerYear3)
        let cost4 = convertNaNtoZero(values.termPricePerYear4)
        let cost5 = convertNaNtoZero(values.termPricePerYear5)
        let func0 = 0
        let func1 = 0
        let func2 = 0
        let func3 = 0
        let func4 = 0
        let func5 = 0
        if (values.HypothesisRadio === "planChild") {
          func0 = first + convertNaNtoZero(values.yearStart0)
          func1 = first + convertNaNtoZero(values.yearStart1)
          func2 = first + convertNaNtoZero(values.yearStart2)
          func3 = first + convertNaNtoZero(values.yearStart3)
          func4 = first + convertNaNtoZero(values.yearStart4)
          func5 = first + convertNaNtoZero(values.yearStart5)
        } else {
          func0 = convertNaNtoZero(values.yearStart0) - second
          func1 = convertNaNtoZero(values.yearStart1) - second
          func2 = convertNaNtoZero(values.yearStart2) - second
          func3 = convertNaNtoZero(values.yearStart3) - second
          func4 = convertNaNtoZero(values.yearStart4) - second
          func5 = convertNaNtoZero(values.yearStart5) - second
        }

        while (i0 < values.yearEnd0 - values.yearStart0 + 1) {
          sum0 += cost0 * Math.pow(1 + third / 100, func0 + i0)
          i0++
        }
        while (i1 < values.yearEnd1 - values.yearStart1 + 1) {
          sum1 += cost1 * Math.pow(1 + third / 100, func1 + i1)
          i1++
        }
        while (i2 < values.yearEnd2 - values.yearStart2 + 1) {
          sum2 += cost2 * Math.pow(1 + third / 100, func2 + i2)
          i2++
        }
        while (i3 < values.yearEnd3 - values.yearStart3 + 1) {
          sum3 += cost3 * Math.pow(1 + third / 100, func3 + i3)
          i3++
        }
        while (i4 < values.yearEnd4 - values.yearStart4 + 1) {
          sum4 += cost4 * Math.pow(1 + third / 100, func4 + i4)
          i4++
        }
        while (i5 < values.yearEnd5 - values.yearStart5 + 1) {
          sum5 += cost5 * Math.pow(1 + third / 100, func5 + i5)
          i5++
        }

        values.yearEnd0 = convertNaNtoZero(values.yearStart0)
          ? convertNaNtoZero(values.yearStart0) + 5
          : 0
        values.yearEnd1 = convertNaNtoZero(values.yearStart1)
          ? convertNaNtoZero(values.yearStart1) + 2
          : 0
        values.yearEnd2 = convertNaNtoZero(values.yearStart2)
          ? convertNaNtoZero(values.yearStart2) + 2
          : 0

        values.sumTermPricePerYear0 = Math.round(sum0)
        values.sumTermPricePerYear1 = Math.round(sum1)
        values.sumTermPricePerYear2 = Math.round(sum2)
        values.sumTermPricePerYear3 = Math.round(sum3)
        values.sumTermPricePerYear4 = Math.round(sum4)
        values.sumTermPricePerYear5 = Math.round(sum5)

        values.AmountToPrepare =
          (values.level0 ? Math.round(sum0) : 0) +
          (values.level1 ? Math.round(sum1) : 0) +
          (values.level2 ? Math.round(sum2) : 0) +
          (values.level3 ? Math.round(sum3) : 0) +
          (values.level4 ? Math.round(sum4) : 0) +
          (values.level5 ? Math.round(sum5) : 0)
      }
    },
  }),
  withHandlers({
    onSubmit: (props) =>
      props.handleSubmit(async (values) => {
        const { query } = props.location || {}
        const { leadId } = query || {}

        props.history.push(
          path.productsPath(leadId, {
            ...props.location.query,
            birthdate: values.birthdate,
            gender: values.gender,
            searchValue:
              convertNaNtoZero(values.AmountToPrepare) -
                convertNaNtoZero(values.AmountAlreadyPrepared) || 100000,
            type: "saving",
            searchBy: "sum_insured",
          })
        )
      }),
  })
)
export default enhancer(Planning)
