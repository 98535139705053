import React from "react"
import styled from "@styled"
import { get } from "lodash"
import { compose, withHandlers, withState } from "recompose"
import { withForm } from "@enhancers"
import { Input, Form, Field, DialogActionsBar, InputBatchFile } from "@components"

import { CustomDialog as Dialog, CloseButton, SubmitButton } from "@pages/insurers/show/DeleteModal"

import AlertModal from "@pages/batches/AlertModal"

const CustomDialog = styled(Dialog)`
  > div {
    > div:first-of-type {
      > div:first-of-type {
        font-size: 16px;
      }
    }
  }
`
const Layout = styled(Form)`
  padding: 15px;

  > div:nth-of-type(2) {
    flex-direction: column;
    align-items: flex-start;

    > div:first-of-type {
      > div {
      }
    }
  }
`

export const ImportFileModal = (props) => (
  <CustomDialog title="นำเข้าไฟล์" onClose={props.onClose} isShowModal={props.open}>
    <Layout>
      <Field name="leadFile" component={InputBatchFile} isactive={true} />
      <Field
        name="batchCode"
        component={Input}
        hidden={!props.values.leadFile}
        rightWidth="306"
        caption="ตั้งรหัสแบทช์"
        validate="required"
      />
    </Layout>
    <DialogActionsBar>
      <CloseButton
        type="button"
        onClick={props.onCancel}
        children="ยกเลิก"
        styletype="delete"
        hidden={!props.values.leadFile}
      />
      <SubmitButton
        type="button"
        onClick={props.onImport}
        children="ยืนยัน"
        hidden={!props.values.leadFile}
      />
    </DialogActionsBar>
    <AlertModal
      title="นำเข้าไฟล์ล้มเหลว"
      message={props.message || "การนำเข้าไฟล์ล้มเหลว โปรดตรวจสอบอีกครั้ง"}
      show={props.isShowAlertModal}
      onOk={props.closeAlertModal}
      onCancel={props.closeAlertModal}
    />
  </CustomDialog>
)

const enhancer = compose(
  withState("isShowAlertModal", "setIsShowAlertModal", false),
  withState("intervalId", "setIntervalId", 0),
  withState("message", "setErrorMessage", null),
  withForm({ form: "InputFileForm" }),
  withHandlers({
    onClose: (props) => () => {
      props.reset(props.form)
      props.onClose()
    },
  }),
  withHandlers({
    onCancel: (props) => () => {
      props.reset(props.form)
    },
    onImport: (props) =>
      props.handleSubmit(async (values) => {
        try {
          await props.onImport(values)
        } catch (e) {
          let errorMsg = get(e, "errors.base", null)
          if (!errorMsg) errorMsg = "นำเข้าไฟล์ไม่สำเร็จ"
          props.setIsShowAlertModal(true)
          props.setErrorMessage(errorMsg)
        } finally {
          props.onClose()
        }
      }),
    closeAlertModal: (props) => () => {
      props.setIsShowAlertModal(false)
    },
  })
)
export default enhancer(ImportFileModal)
