import React from "react"
import styled from "@emotion/styled"
import { compose, withHandlers } from "recompose"
import { Button, Dialog, Field, DatePicker, Form, InputFile } from "@components"
import { DialogActionsBar } from "@progress/kendo-react-dialogs"
import { withRouter, withForm } from "@enhancers/index"

const CustomDialog = styled(Dialog)`
  > div {
    > div :last-child {
      margin-top: 30px;
    }
  }
`
const InputContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  padding: 30px 24px 35px 24px;

  > div {
    > div :last-child {
      > div {
        display: flex;
      }
      display: flex;
      > input {
        width: 63%;
      }
    }
  }

  > input {
    width: 100%;
  }
`
const CustomDatePicker = styled(DatePicker)`
  margin-bottom: 15px;
`
const CancelButton = styled(Button)`
  border-left: 0.5px solid #ccc;
  color: #666;
  min-height: 52px;
`
const GotoButton = styled(Button)`
  border-left: 0.5px solid #ccc;
  color: white;
  background-color: #0275d8;

  :hover {
    background-color: #0275d8;
    color: white;
  }

  :disabled {
    color: #eeeef2;
    border-color: #eeeef2;
    opacity: 0.3 !important;
  }

  min-height: 52px;
`
const DialogFooter = styled(DialogActionsBar)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 20px;
`
const Note = styled.div`
  margin-top: 10px;
  text-align: center;
  font-size: 10px;
  color: #666666;
  opacity: 0.3;
`

const ImportFileModal = (props) => (
  <CustomDialog title="นำเข้าไฟล์" onClose={props.onClose} isShowModal={props.open} width="433px">
    <InputContainer>
      <Field
        component={CustomDatePicker}
        name="importedDate"
        caption="วันนำเข้าไฟล์"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="150"
        rightWidth="177"
        disabled={props.disabled}
        placeholder="กรุณาเลือก"
        validate="required"
      />
      <Field
        name="file"
        caption="เอกสาร"
        component={InputFile}
        readOnly={props.readFileOnly}
        hidden={props.authorized}
        children="อัพโหลด"
        accept=".xlsx"
        validate="required"
        leftWidth="150"
      />
      <Note>หมายเหตุ: รับไฟล์สกุล .xlsx เท่านั้น ขนาดไม่เกินไฟล์ละ 2 MB</Note>
    </InputContainer>
    <DialogFooter>
      <CancelButton onClick={props.onClose}>ยกเลิก</CancelButton>
      <GotoButton styletype="primary" onClick={props.importFile} disabled={props.invalid}>
        ยืนยัน
      </GotoButton>
    </DialogFooter>
  </CustomDialog>
)

const enhancer = compose(
  withRouter(),
  withForm({
    form: "InsurerCommissionImportFileForm",
  }),
  withHandlers({
    importFile: (props) => async () => {
      props.onImportFile && (await props.onImportFile(props.values))
    },
  })
)

export default enhancer(ImportFileModal)
