import React, { useEffect, useState } from "react"
import styled from "@styled"
import { compose } from "recompose"
import { withStores, withHooks, withRouter } from "@enhancers/index"
import Header from "./Header"
import Body from "./Body"
import Footer from "./Footer"
import SignInModal from "./SignInModal"
import Loading from "./Loading"
import OldSiteAutoAuthIFrame from "./OldSiteAutoAuthIFrame"
import { call, isAgentToolsPath } from "@common/helper"
import { Switch, Route, Redirect } from "react-router-dom"
import { TQLD_AUTH_TOKEN_KEY } from "@common/api"

export const MainLayout = styled.div`
  position: relative;
  min-height: 100vh;
  background: #f7f7f9;

  /* @media screen and (max-width: 760px) {
    min-width: 100vh;
  } */
`

const App = (props) => (
  <>
    <MainLayout key={props.currentRole} id="main_page">
      {!props.initialized ? (
        <Loading />
      ) : props.authenticated ? (
        <>
          <Header />
          <Body />
          <Footer />
        </>
      ) : (
        <Switch>
          <Route path="/" component={SignInModal} />
          <Redirect to="/" />
        </Switch>
      )}
    </MainLayout>
    <OldSiteAutoAuthIFrame />
  </>
)

const enhancer = compose(
  withRouter(),
  withStores((stores) => ({
    authenticate: stores.appStore.authenticate,
    logout: stores.appStore.logout,
    currentRole: stores.appStore.currentRole,
    hidden: !stores.appStore.initialized,
    authenticated: !!stores.appStore.token,
  })),
  withHooks((props) => {
    const [initialized, setInitialized] = useState(false)

    useEffect(() => {
      call(async () => {
        const {
          location: {
            pathname,
            query: { redirectPath = "", fromPortal = false },
          },
        } = props

        try {
          const fromAGT = !!redirectPath && isAgentToolsPath(pathname)
          const splitedPath = pathname.split("/")
          if (fromAGT && !!splitedPath[3]) {
            localStorage.setItem(TQLD_AUTH_TOKEN_KEY, splitedPath[3])
            sessionStorage.setItem("fromAGT", true)
            sessionStorage.setItem("fromPortal", fromPortal)
          }
          await props.authenticate(null, () => {
            if (fromAGT) window.location.href = redirectPath
          })
        } catch (e) {
          await props.logout()
        }
        setInitialized(true)
      })
      // eslint-disable-next-line
    }, [])

    return { initialized }
  })
)

export default enhancer(App)
