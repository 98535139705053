import React from "react"
import { Checkbox, Input } from "@components"
import { compose, withHandlers } from "recompose"
import styled from "@common/styled"

const Layout = styled.div`
  display: flex;
  flex-direction: table-column;
`
const Caption = styled.div`
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  color: #666666;
  font-weight: 600;
  min-width: 180px;
  display: flex;
  align-items: center;
  height: 33.5px;
`
const Container = styled.div``
const CheckboxContainer = styled.div``
const SubItem = styled.div`
  padding-left: 25px;
`
const CustomCheckbox = styled(Checkbox)`
  color: #666666;
`

const PaymentMappingInput = (props) => (
  <Layout>
    <Caption>{props.caption}</Caption>
    <Container>
      {props.options &&
        props.options.map((paymentPeriod) => (
          <CheckboxContainer>
            <Input value={paymentPeriod.label} readOnly />
            <SubItem>
              {paymentPeriod.paymentMethods.map((paymentMethod) => (
                <CustomCheckbox
                  name={paymentPeriod.value + paymentMethod.value}
                  label={paymentMethod.label}
                  value={props.findPaymentMethodValue(
                    props.input.value,
                    paymentPeriod.value,
                    paymentMethod.value
                  )}
                  onChange={(checked) =>
                    props.onSelectPaymentMethod(paymentPeriod.value, paymentMethod.value, checked)
                  }
                />
              ))}
            </SubItem>
          </CheckboxContainer>
        ))}
    </Container>
  </Layout>
)

const enhancer = compose(
  withHandlers({
    findPaymentPeriodValue: () => (value, paymentPeriod) => {
      return value.find((item) => {
        return item.value === paymentPeriod.value
      })
    },
    findPaymentMethodValue: () => (value, paymentPeriodValue, paymentMethodValue) => {
      return value.find((item) => {
        const filter = item.value === paymentPeriodValue
        let value = null
        if (filter) {
          value = item.paymentMethods.find(
            (paymentMethod) => paymentMethod.value === paymentMethodValue
          )
        }
        return value
      })
    },
    onSelectPaymentPeriod: (props) => (value, checked) => {
      const data = (props.input.value || []).filter((item) => item.value !== value)
      if (checked) {
        data.push({ value: value, paymentMethods: [] })
      }
      props.input.onChange(data)
    },
    onSelectPaymentMethod: (props) => (paymentPeriod, paymentMethodValue, checked) => {
      const data = (props.input.value || []).filter((item) => item.value !== paymentPeriod)
      let lastedData = (props.input.value || []).filter((item) => item.value === paymentPeriod)

      let newPaymentMethods = lastedData.length > 0 && lastedData[0].paymentMethods
      newPaymentMethods = (newPaymentMethods || []).filter(
        (item) => item.value !== paymentMethodValue
      )
      if (checked) {
        newPaymentMethods = [...newPaymentMethods, { value: paymentMethodValue }]
      }
      if (newPaymentMethods.length !== 0) {
        data.push({ value: paymentPeriod, paymentMethods: newPaymentMethods })
      }
      props.input.onChange(data)
    },
  })
)

export default enhancer(PaymentMappingInput)
