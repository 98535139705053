import React from "react"
import styled from "@styled"
import { Field, Dropdown, DatePicker, Input } from "@components"
import { compose } from "recompose"
import { withStores } from "@enhancers/index"

const Layout = styled.div`
  padding-left: 5px;
  padding-bottom: 5px;
`
const Row = styled.div`
  display: flex;
  margin-top: 20px;

  &:nth-of-type(1) {
    margin-top: 0;
  }

  &:last-of-type {
    > div {
      margin-left: 16px;
    }
  }

  > div {
    margin-left: 32px;

    &:nth-of-type(1) {
      margin-left: 0;
    }
  }
`
const CustomField = styled(Field)`
  > div > div {
    font-weight: unset;
  }
`

const FilterPanel = (props) => (
  <Layout>
    <Row>
      <CustomField
        component={Input}
        name="staffCode"
        caption="รหัสพนักงาน"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="99"
        rightWidth="231"
        placeholder="กรุณาระบุ"
      />
    </Row>
    <Row>
      <CustomField
        component={Input}
        name="fullName"
        caption="ชื่อพนักงาน"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="99"
        rightWidth="231"
        placeholder="กรุณาระบุ"
      />
    </Row>
    <Row>
      <CustomField
        component={Dropdown}
        name="salesGroup"
        caption="ทีมขาย"
        captionStyle={{ fontWeight: "normal" }}
        options={props.salesGroupOptions}
        leftWidth="99"
        rightWidth="231"
        placeholder="กรุณาเลือก"
      />
    </Row>
    <Row>
      <CustomField
        component={Dropdown}
        name="memberStatus"
        caption="สถานะพนักงาน"
        captionStyle={{ fontWeight: "normal" }}
        options={props.memberStatusOptions}
        leftWidth="99"
        rightWidth="231"
        placeholder="กรุณาเลือก"
      />
    </Row>
  </Layout>
)

const enhancer = compose(
  withStores((stores) => ({
    salesGroupOptions: stores.saleCommissionStore.$("configs.filters.salesGroup.options", []),
    memberStatusOptions: stores.saleCommissionStore.$("configs.filters.memberStatus.options", []),
  }))
)

export default enhancer(FilterPanel)
