import React from "react"
import { compose, withState, defaultProps, withProps } from "recompose"
import { withStores } from "@enhancers/index"
import {
  Field,
  CurrencyInput,
  DatePicker,
  Input,
  Grid,
  GridColumn,
  makeCell,
} from "@components/index"
import styled from "@emotion/styled"
import TeamCard from "./TeamCard"
import DetailPaymentCard from "./DetailPaymentCard"
import {
  FieldContainer,
  RemarkContainer,
  Premium,
  Remain,
  NoDataContainer,
} from "./CommissionTabDetail"
import { FormSection } from "redux-form"

import { displayNumber } from "@common/helper"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #eeeef2;
`
const Row = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 25px 39px 25px;
`
const DetailContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`
const DetailHeader = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  background-color: #e2e5e9;
  align-items: center;
  justify-content: space-between;
  padding: 0 13px;
`
const DetailHeaderCaption = styled.span`
  color: #666666;
  font-size: 16px;
  font-family: Sarabun, sans-serif;
  font-weight: bold;
`
const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px 20px 40px 20px;
`
const CustomInput = styled(CurrencyInput)`
  > div:first-of-type {
    width: 167px;
  }
  > div:last-of-type {
    width: 150px;
    > div {
      width: 220px;
    }
    > input {
      width: 220px;
    }
  }
`
const RowDetail = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  line-height: 21px;
  margin-bottom: 16px;

  > div > div:last-of-type > div {
    padding-top: 0 !important;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`
const Table = styled(Grid)`
  .k-grid-header {
    background-color: #e2e5e9 !important;
    border-color: #e2e5e9 !important;
    padding: 0 !important;
    .k-header {
      /* font-weight: bold; */
      > a {
        padding: 12px !important;
      }
    }
  }

  .k-grid-header-wrap > table > thead > tr {
    background-color: #e2e5e9;
    border-color: #e2e5e9;
    > th > a {
      color: #666666;
    }
  }

  /* .k-grid-content.k-virtual-content > div {
    background-color: #eeeef2;
  } */

  .k-master-row {
    > td {
      flex-direction: row;
      padding: 0 12px;
    }

    > td:nth-of-type(4) {
      color: #5cb85c;
      text-align: end;
    }
    > td:nth-of-type(5) > div > div {
      color: #5cb85c;
      justify-content: flex-end;
    }
  }
`

const SaleCommissionTabDetail = (props) => (
  <Container>
    <Row>
      <FieldContainer>
        <Field
          name="salesCommissionPercent"
          caption="คอมมิชชั่นจ่าย"
          component={CustomInput}
          readOnly={props.defaultReadOnly}
          leftWidth="152"
          unit="%"
          precision={2}
        />
      </FieldContainer>

      <FieldContainer isReceived={props.isReceivedCommissionState}>
        <Field
          name="salesCommissionAmount"
          caption="ยอดจ่ายคอมมิชชั่น"
          component={CustomInput}
          readOnly={props.defaultReadOnly}
          leftWidth="167"
          unit="บาท"
          precision={2}
        />
        {props.isReceivedCommissionState ? (
          <RemarkContainer>
            <Premium>{`*รับจริง ${
              displayNumber(parseFloat(props.realSalesCommissionAmount)) || "-"
            } บาท`}</Premium>
            <Remain>{`คงเหลือ ${
              displayNumber(
                parseFloat(
                  parseFloat(props.salesCommissionAmount || 0).toFixed(2) -
                    parseFloat(props.realSalesCommissionAmount || 0).toFixed(2)
                ).toFixed(2)
              ) || "-"
            } บาท`}</Remain>
          </RemarkContainer>
        ) : null}
      </FieldContainer>
    </Row>

    <DetailContainer>
      <DetailHeader>
        <DetailHeaderCaption>ข้อมูลคอมมิชชั่นจ่าย</DetailHeaderCaption>
        {props.totalSalesCommission && (
          <DetailHeaderCaption>{`รวม ${displayNumber(
            parseFloat(props.totalSalesCommission)
          )} บาท`}</DetailHeaderCaption>
        )}
      </DetailHeader>

      <Row>
        <RowDetail>
          <Field caption="ทีมขาย" name="teamName" component={Input} leftWidth="107" readOnly />
          <Field
            caption="วันที่จ่าย"
            name="commissionReceivedDate"
            component={DatePicker}
            readOnly={!props.isEdit}
            leftWidth="107"
            rightWidth="138"
            placeholder="กรุณาเลือก"
          />
        </RowDetail>

        <Table data={props.salesCommissionDetails} style={{ marginBottom: "20px" }}>
          <GridColumn title="รหัสพนักงาน" field="staffCode" width="108px" />
          <GridColumn title="ชื่อพนักงาน" field="fullName" width="160px" />
          <GridColumn title="ตำแหน่ง" field="positionName" width="170px" />
          <GridColumn
            title="สัดส่วน(%)"
            field="commissionPercent"
            // cell={makeCell(CurrencyInput, (cell) => ({
            //   readOnly: true,
            //   value: cell.dataItem.percent,
            //   // style: { color: "#5CB85C", display: "flex", justifyContent: "flex-end" },
            //   // style: {
            //   //   color: props.dataItem.percent >= 0 ? "#5CB85C" : "#D9534F",
            //   // },
            // }))}
            width="96px"
          />

          <GridColumn
            title="คอมมิชชั่นจ่าย"
            field="commissionAmount"
            cell={makeCell(CurrencyInput, (cell) => ({
              readOnly: true,
              value: cell.dataItem.commissionAmount,
              // style: { color: "#5CB85C", display: "flex", justifyContent: "flex-end" },
              // style: {
              //   color: props.dataItem.commissionAmount >= 0 ? "#5CB85C" : "#D9534F",
              // },
            }))}
            width="119px"
          />
          <GridColumn title="รหัสผู้แนะนำ" field="suggestedBy" width="120px" />
          <GridColumn title="สัดส่วนค่าแนะนำ" field="suggestedPercent" width="150px" />
          <GridColumn title="ค่าแนะนำ" field="suggestedCommission" width="120px" />
          <GridColumn title="ส่วนต่างค่าแนะนำ" field="diffSuggestedCommission" width="150px" />
        </Table>

        <RowDetail>
          <Field
            caption="ทีมผู้แนะนำ"
            name="salesCommissionAffiliateTeam.affiliateTeamName"
            component={Input}
            leftWidth="107"
            readOnly
          />
        </RowDetail>
        <Table data={[{ ...props.salesCommissionAffiliateTeam }]}>
          <GridColumn title="ตำแหน่ง" field="positionName" width="108px" />
          <GridColumn
            title="สัดส่วน (%)"
            field="commissionPercent"
            width="108px"
            cell={(props) => (
              <td style={{ color: "#5CB85C" }}>{props.dataItem.commissionPercent}</td>
            )}
          />
          <GridColumn
            title="คอมมิชชันจ่าย"
            field="commissionAmount"
            width="108px"
            cell={(props) => (
              <td style={{ color: "#5CB85C" }}>{props.dataItem.commissionAmount}</td>
            )}
          />
        </Table>
      </Row>

      {/* <DetailContainer>
      <DetailHeader>
        <DetailHeaderCaption>ทีมที่รับผิดชอบ</DetailHeaderCaption>
      </DetailHeader>

      <CardContainer>
        <FormSection name="team">
          {props.team ? (
            <TeamCard
              initialValues={props.team}
              readOnly={props.readOnly}
              defaultReadOnly={props.defaultReadOnly}
              isReceivedCommissionState={props.isReceivedCommissionState}
            />
          ) : (
            <NoDataContainer>
              <Premium>--- ไม่มีทีมที่รับผิดชอบ ---</Premium>
            </NoDataContainer>
          )}
        </FormSection>
      </CardContainer>
    </DetailContainer>

    <DetailContainer>
      <DetailHeader>
        <DetailHeaderCaption>รายละเอียดการจ่ายเงิน</DetailHeaderCaption>
      </DetailHeader>

      <CardContainer>
        <Field name="salesman" component={DetailPaymentCard} />
        <Field name="salesLeader" component={DetailPaymentCard} />
      </CardContainer>*/}
    </DetailContainer>
  </Container>
)

const enhance = compose(
  withStores((stores, props) => ({
    insurerCommissionState: stores.insurerCommissionStore.$("current.state"),
  })),
  withState("readOnly", "setReadOnly", true),
  defaultProps({ defaultReadOnly: true }),
  withProps((props) => {
    return {
      initialValues: props.initialValues,
      isReceivedCommissionState:
        props.insurerCommissionState === "real_commission_waiting" ||
        props.insurerCommissionState === "real_commission_received" ||
        props.insurerCommissionState === "approved"
          ? true
          : false,
    }
  })
)

export default enhance(SaleCommissionTabDetail)
