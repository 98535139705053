import React from "react"
import styled from "@emotion/styled"
import { Button, Icon } from "@components"
import { compose, withHandlers, withProps, lifecycle } from "recompose"
import moment from "moment"
import "moment/locale/th"
import * as path from "@common/path"
import { withRouter, withStores } from "@enhancers/index"
import { get } from "lodash"

const Layout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  border-bottom: 1px solid ${(props) => (props.isReaded ? "#FFF" : "#EEEEF2")};
  background-color: ${(props) => (props.isReaded ? "white" : "rgb(2,117,216,0.2)")};
`
const WrapperLayout = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 678px;
  min-width: 678px;
  padding: 16px 10px 18px 10px;
`

const LeftContainer = styled.div`
  max-width: 340px;
  display: flex;
  flex-direction: column;
`
const RightContainer = styled.div`
  display: flex;
  align-items: center;
`
const CaptionContainer = styled.div`
  display: flex;
`
const TimeStampContainer = styled.div`
  display: flex;
  align-items: center;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  color: #666666;
  margin-top: 5px;
  > span {
    margin-right: 11px;
  }
`

const StateButton = styled(Button)`
  width: 140px;
  color: ${(props) => (props.isReaded ? "#627B8B" : "#0275d8")};
  border-color: ${(props) => (props.isReaded ? "#627B8B" : "#0275d8")};
  :hover {
    color: ${(props) => (props.isReaded ? "#294558" : "#0275d8")};
    border-color: ${(props) => (props.isReaded ? "#294558" : "#0275d8")};
  }
`
const Text = styled.span`
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  color: ${(props) => (props.isReaded ? "#666666" : "rgb(2, 117, 216)")};
`
const BellIcon = styled(Icon)`
  color: ${(props) => (props.isReaded ? "#666666" : "#F0AD4E")};
`

const NotificationCard = (props) => (
  <Layout
    onClick={() => props.onClick(props.data && props.data.notificationType)}
    isReaded={props.data.isRead}
  >
    <WrapperLayout>
      <LeftContainer>
        <CaptionContainer>
          <Text isReaded={props.data.isRead}>{props.data.title}</Text>
        </CaptionContainer>
        <TimeStampContainer>
          <BellIcon isReaded={props.data.isRead} icon="bell" />
          <div>{` ${props.timeStamp}`}</div>
        </TimeStampContainer>
      </LeftContainer>
      <RightContainer>
        <StateButton
          isReaded={props.data.isRead}
          onClick={() => props.onClick(props.data.notificationType)}
          hidden={props.hiddenButton}
        >
          {props.textButton}
        </StateButton>
      </RightContainer>
    </WrapperLayout>
  </Layout>
)

const enhancer = compose(
  withRouter(),
  withStores((stores, props) => ({
    updateNotifications: stores.appStore.updateNotifications,
    fetchNotifications: stores.appStore.fetchNotifications,
  })),
  withProps((props) => {
    const timeStamp = moment(props.data.createdAt).fromNow()
    let textButton = null

    switch (get(props.data, "notificationType")) {
      case "order":
        if (get(props.data, "info.state") === "new") {
          textButton = get(props.data, "info.is_affiliate_order") ? "ดูคำสั่งซื้อ" : "ไปกรอกข้อมูล"
        } else if (get(props.data, "info.state") === "doc_attaching") {
          textButton = "ไปแนบเอกสาร"
        } else if (get(props.data, "info.state") === "doc_verifying") {
          textButton = "ไปตรวจเอกสาร"
        }
        break
      case "action_code":
      case "reply_action_code":
        textButton = "ไปตรวจสอบ"
        break
      case "draft_order":
        textButton = "ดูรายละเอียด"
        break
      case "lead_appointment":
        textButton = "ไปหน้านัดหมาย"
        break
      case "notify_document":
        textButton = "ไปตรวจเอกสาร"
        break
      case "insurer_commission_edited":
      case "insurer_commission_editing_approved":
        textButton = "ไปคอมมิชชั่นรับ"
        break
      case "lead_admin_assign_lead_to_sales_admin":
      case "sales_admin_assign_lead_to_sales":
      case "lead_admin_assign_lead":
      case "sales_leader_assign_lead":
        textButton = "ไปหน้าลีดทีคิวเอ็ม"
        break
      case "system_unassign_lead":
        textButton = "ไปหน้าจัดการลีด"
        break
      case "affiliate_order":
        textButton = "ไปมอบหมาย"
        break
      default:
        break
    }

    return {
      timeStamp: timeStamp,
      textButton: textButton,
    }
  }),
  withProps((props) => ({
    hiddenButton: !props.textButton,
  })),
  withHandlers({
    onClick: (props) => async () => {
      switch (get(props.data, "notificationType")) {
        case "order":
          if (get(props.data, "info.state") === "new") {
            props.history.push(path.orderHolderInfoPath(get(props.data, "info.orderId")))
            await props.updateNotifications(props.data.id)
          } else if (get(props.data, "info.state") === "doc_attaching") {
            props.history.push(path.orderDocumentInfoPath(get(props.data, "info.orderId")))
            await props.updateNotifications(props.data.id)
          } else if (get(props.data, "info.state") === "doc_verifying") {
            props.history.push(path.orderDocumentInfoPath(get(props.data, "info.orderId")))
            await props.updateNotifications(props.data.id)
          }
          break
        case "action_code":
        case "reply_action_code":
          props.history.push(path.orderActionCodeInfoPath(get(props.data, "info.orderId")))
          await props.updateNotifications(props.data.id)
          break
        case "draft_order":
          props.history.push(
            path.productCustomEditPath(get(props.data, "info.policyId"), {
              leadId: get(props.data, "info.leadId"),
              orderId: get(props.data, "info.orderId"),
              id: get(props.data, "info.policyId"),
              age: get(props.data, "info.age"),
              gender: get(props.data, "info.gender"),
              sumInsured: get(props.data, "info.sumInsured"),
              premium: get(props.data, "info.premium"),
              paymentPeriod: get(props.data, "info.paymentPeriod"),
              birthdate: get(props.data, "info.birthdate"),
              from: "order",
            })
          )
          await props.updateNotifications(props.data.id)
          break
        case "lead_appointment":
          props.history.push(path.leadPath(get(props.data, "info.leadId")))
          break
        case "notify_document":
          props.history.push(path.orderDocumentInfoPath(get(props.data, "info.orderId")))
          await props.updateNotifications(props.data.id)
          break
        case "insurer_commission_edited":
        case "insurer_commission_editing_approved":
          props.history.push(
            path.insurerCommissionPath(get(props.data, "info.insurerCommissionId"))
          )
          await props.updateNotifications(props.data.id)
          break
        case "lead_admin_assign_lead_to_sales_admin":
        case "sales_admin_assign_lead_to_sales":
        case "lead_admin_assign_lead":
        case "sales_leader_assign_lead":
          props.history.push(path.leadsPath("all"))
          await props.updateNotifications(props.data.id)
          break
        case "system_unassign_lead":
          props.history.push(path.batchLeadsPath(get(props.data, "info.leadBatchId")))
          await props.updateNotifications(props.data.id)
          break
        case "affiliate_order":
          props.history.push(path.leadPoolsPath())
          await props.updateNotifications(props.data.id)
          break
        default:
          await props.updateNotifications(props.data.id)
          props.fetchNotifications()
          break
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      if (this.props.hiddenButton && !this.props.data.isRead) {
        this.props.updateNotifications(this.props.data.id)
      }
    },
  })
)

export default enhancer(NotificationCard)
