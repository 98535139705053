import React, { useEffect, useState, useCallback } from "react"
import styled from "@emotion/styled"
import { compose } from "recompose"
import { withStores, withHooks, withRouter } from "@enhancers"
import * as path from "@common/path"
import {
  makeCell,
  Grid,
  GridColumn,
  LoadingScope,
  Input,
  FilterButton,
  Dropdown,
} from "@components"
import FilterPanel from "./FilterPanel"
import { call } from "@common/helper"

const Layout = styled.div`
  margin: 20px auto 25px auto;
`
const Table = styled(Grid)`
  .k-grid-toolbar {
    background-color: transparent;
  }

  td {
    cursor: pointer;
    vertical-align: top;
  }
`
const CustomFilterButton = styled(FilterButton)`
  margin-bottom: 10px;
`
const PackagePaymentMappingsIndex = (props) => (
  <React.Fragment>
    {props.loading ? (
      <LoadingScope />
    ) : (
      <Layout>
        <CustomFilterButton
          expanded={props.filterExpandedValue}
          onExpand={props.toggleExpandFilter}
        >
          <FilterPanel />
        </CustomFilterButton>
        <Table data={props.data} onRowClick={props.rowClick}>
          <GridColumn title="บริษัทประกัน" width="180px" field="companyName" />
          <GridColumn title="แพ็กเกจ" width="390px" field="packageName" />
          <GridColumn
            title="โหมดการชำระเงิน"
            width="145px"
            cell={makeCell(Input, (cellProps) => ({
              readOnly: true,
              value: cellProps.dataItem.payments.length,
            }))}
          />
          <GridColumn
            title="สถานะ"
            width="110px"
            cell={makeCell(Dropdown, (cellProps) => ({
              readOnly: true,
              options: props.stateOptions,
              value: cellProps.dataItem.state,
            }))}
          />
        </Table>
      </Layout>
    )}
  </React.Fragment>
)

const enhancer = compose(
  withRouter(),
  withStores((stores) => ({
    data: stores.paymentMappingStore.all,
    fetchData: stores.paymentMappingStore.fetchPackage,
    fetchConfigs: stores.paymentMappingStore.fetchConfigs,
    stateOptions: stores.paymentMappingStore.$("configs.filters.packageState.options", []),
  })),
  withHooks((props) => {
    const { query } = props.location || {}
    const [loading, setLoading] = useState(false)
    const [filterExpandedValue, setFilterExpandedValue] = useState(true)
    const [fetchDataTask, setFetchDataTask] = useState(null)

    const rowClick = useCallback((event) => {
      props.history.push(path.packagePaymentMappingPath(event.dataItem.packageId))
    })

    const toggleExpandFilter = useCallback(() => {
      setFilterExpandedValue(!filterExpandedValue)
    })

    useEffect(() => {
      setLoading(true)
      call(async () => {
        await Promise.all([props.fetchData(query), props.fetchConfigs()])
      })
      setLoading(false)
    }, [])

    useEffect(() => {
      if (fetchDataTask) {
        clearTimeout(fetchDataTask)
      }
      const fetchData = setTimeout(async () => {
        await props.fetchData(query)
      }, 800)
      setFetchDataTask(fetchData)
    }, [JSON.stringify(query)])

    return {
      loading,
      rowClick,
      toggleExpandFilter,
      filterExpandedValue,
    }
  })
)

export default enhancer(PackagePaymentMappingsIndex)
