import React from "react"
import { withForm, withStores } from "@enhancers"
import { compose, withState, withHandlers, withProps } from "recompose"
import {
  Input,
  Dropdown,
  Field,
  TabStrip,
  TabStripTab,
  Address,
  CurrencyInput,
  DatePicker,
} from "@components"
import { Layout, Header, LeftPanel, RightPanel, ButtonGroup, ActionButton } from "./LeadInfo"
import styled from "@emotion/styled"
import { InputName } from "@components"
import { FormSection } from "redux-form"

export const MenuTab = styled(TabStrip)``

const CustomInputName = styled(InputName)`
  > div:last-of-type {
    > div {
      max-width: 456px;
      > span {
        width: 160px;
      }
      > input {
        width: 160px;
      }
    }
  }
`

export const CustomerInfo = (props) => (
  <Layout>
    <Header>
      <LeftPanel>ข้อมูลลูกค้า</LeftPanel>
      <RightPanel>
        {props.isEdit ? (
          <ButtonGroup>
            <ActionButton icon="cancel" onClick={props.onCancel} children="ยกเลิก" />
            <ActionButton icon="floppy" onClick={props.onSubmit} children="บันทึก" />
          </ButtonGroup>
        ) : (
          <ActionButton
            icon="edit"
            onClick={props.onEdit}
            children="แก้ไข"
            authorize={{
              roles: ["member.salesman", "member.team_leader"],
            }}
          />
        )}
      </RightPanel>
    </Header>
    <MenuTab selected={props.isSelect} onSelect={props.onSelect}>
      <TabStripTab title="ข้อมูลลูกค้า">
        <FormSection name="customerInfo">
          <CustomInputName
            name="nameInfo"
            caption="ชื่อ"
            options={props.prefixNameNewOptions}
            readOnly={!props.isEdit}
            validate={props.nameRequire || null}
          />
          <Field
            name="gender"
            caption="เพศ"
            component={Dropdown}
            options={props.genderOptions}
            readOnly={!props.isEdit}
            validate={props.genderRequire || null}
          />
          <Field
            name="birthdate"
            caption="วันเกิด"
            component={DatePicker}
            max={new Date()}
            readOnly={!props.isEdit}
            validate={props.birthdateRequire || null}
          />
          <Field
            name="idCard"
            caption="เลขที่บัตรประชาชน"
            component={Input}
            readOnly={!props.isEdit}
            validate={props.idCardRequire || null}
          />
          <Field
            name="maritalStatus"
            caption="สถานะภาพ"
            component={Dropdown}
            options={props.maritalStatusOptions}
            readOnly={!props.isEdit}
            validate={props.maritalStatus || null}
          />
          <Field
            name="career"
            caption="อาชีพ"
            component={Dropdown}
            options={props.careerOptions}
            readOnly={!props.isEdit}
          />
          <Field
            name="income"
            caption="รายได้ (บาท/ปี)"
            component={CurrencyInput}
            readOnly={!props.isEdit}
            validate={props.incomeRequired || null}
          />
          <Field
            name="phoneNumber"
            caption="เบอร์โทร"
            component={Input}
            readOnly={!props.isEdit}
            validate={props.telRequired || null}
          />
          <Field name="email" caption="อีเมล" component={Input} readOnly={!props.isEdit} />
          <Field
            name="contactAddressType"
            caption="ที่อยู่ที่ติดต่อได้"
            component={Dropdown}
            options={props.contactAddressTypeOptions}
            readOnly={!props.isEdit}
          />
        </FormSection>
      </TabStripTab>

      <TabStripTab title="ที่อยู่ปัจจุบัน">
        <Field name="customerInfo.currentAddress" component={Address} readOnly={!props.isEdit} />
      </TabStripTab>
      <TabStripTab title="ที่อยู่ที่ทำงาน">
        <Field name="customerInfo.officeAddress" component={Address} readOnly={!props.isEdit} />
      </TabStripTab>
      <TabStripTab title="ที่อยู่ตามทะเบียนบ้าน">
        <Field name="customerInfo.homeAddress" component={Address} readOnly={!props.isEdit} />
      </TabStripTab>
    </MenuTab>
  </Layout>
)

const enhancer = compose(
  withState("isShowDeleteModal", "setIsShowDeleteModal", false),
  withState("isSelect", "setIsSelect", 0),
  withForm({
    form: "editLeadInfo",
    destroyOnUnmount: false,
  }),
  withStores((stores, props) => ({
    leadId: props.match.params.leadId,
    updateLeadInfo: stores.leadStore.update,
    prefixNameOptions: stores.leadStore.$("configs.attributes.prefixName.options", []),
    genderOptions: stores.leadStore.$("configs.attributes.gender.options", []),
    maritalStatusOptions: stores.leadStore.$("configs.attributes.maritalStatus.options", []),
    careerOptions: stores.leadStore.$("configs.attributes.career.options", []),
    contactAddressTypeOptions: stores.leadStore.$(
      "configs.attributes.contactAddressType.options",
      []
    ),
    currentRole: stores.appStore.currentRole,
    isEdit: stores.leadStore.isEdit,
    changeEditState: stores.leadStore.changeEditState,
  })),
  withProps((props) => {
    const newOptions = []
    props.prefixNameOptions.forEach((option) =>
      newOptions.push({
        label: option.label,
        value: option.label,
      })
    )
    return { prefixNameNewOptions: newOptions }
  }),
  withHandlers({
    onSelect: (props) => (e) => props.setIsSelect(e.selected),
    onCloseModal: (props) => () => props.setIsShowDeleteModal(false),
    onDelete: (props) => () => props.setIsShowDeleteModal(true),
    onSubmit: (props) =>
      props.handleSubmit(async (values) => {
        values = {
          ...props.values.customerInfo,
          ...props.values,
        }
        await props.updateLeadInfo(props.leadId, values)
        props.changeEditState()
      }),
    onCancel: (props) => () => {
      props.reset()
      props.changeEditState()
    },
    onEdit: (props) => () => {
      props.changeEditState()
    },
  }),
  withProps((props) => ({
    isEdit: props.isEdit,
    nameRequire: "required",
    genderRequire: "required",
    idCardRequire: "required",
    maritalStatus: "required",
    incomeRequired: "required",
    telRequired: "required",
    birthdateRequire: "required",
  }))
)

export default enhancer(CustomerInfo)
