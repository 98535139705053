import React from "react"
import styled from "@emotion/styled"
import { FieldArray } from "redux-form"
import RiderProduct from "./RiderProduct"

const CustomLayout = styled.div`
  width: 100%;
  justify-content: center;
  overflow-x: auto;
`
const RiderProducts = (props) => (
  <CustomLayout>
    <FieldArray name="riderProducts" component={RiderProduct} />
  </CustomLayout>
)

export default RiderProducts
