import { compose, withHandlers, withProps, lifecycle } from "recompose"
import { withForm, withStores, withRouter } from "@enhancers/index"
import * as path from "@common/path"
import moment from "moment"
import { CreateLeadPage } from "./CreateLeadPage"
import { SubmissionError } from "redux-form"
import { get } from "lodash"

const enhancer = compose(
  withRouter({
    queryTypes: {
      birthdate: "date",
      gender: "string",
      sumInsured: "float",
      type: "string",
      searchBy: "string",
      leadId: "integer",
      from: "string",
      isDraft: "boolean",
      maxAgeInsured: "integer",
      searchValue: "integer",
      occupation: "string",
      occupationCategory: "string",
      opportunityKey: "string",
    },
  }),
  withStores((stores) => ({
    addOrder: stores.orderStore.addOrder,
  })),
  withProps((props) => {
    const searchData = props.location.query
    const productId = props.match.params.productId
    return {
      params: {
        ...searchData,
        productId,
      },
    }
  }),
  withForm({
    form: "buyProductLeadForm",
    destroyOnUnmount: false,
  }),
  withHandlers({
    onCancel: (props) => () => {
      props.history.push(path.productCustomPath(props.params.productId, props.location.query))
    },
    onFormSubmit: (props) =>
      props.handleSubmit(async (routeParams) => {
        const { values } = props

        if (!/^\d{10}$/.test(values.mobilePhoneNumber)) {
          throw new SubmissionError({
            mobilePhoneNumber: "Field mobilePhoneNumber is required",
          })
        }

        const age = values.birthdate ? moment().diff(moment(values.birthdate), "years") : null
        try {
          const orderResponse = await props.addOrder({
            ...values,
            age,
          })

          if (props.params.isDraft) {
            props.history.push(path.ordersPath())
          } else {
            props.history.push(path.orderHolderInfoPath(orderResponse.order.id))
          }
        } catch (e) {
          if (e.message === "Submit Validation Failed" && get(e, "errors.base")) {
            alert(e.errors.base)
          } else if (e.name === "SubmissionError") {
            throw e
          }

          if (props.isDraft) {
            window.alert("สร้าง Order Save Draft ไม่สำเร็จ")
          } else {
            window.alert("สร้าง Order ไม่สำเร็จ")
          }

          if (get(e, "errors.base")) {
            throw new SubmissionError({
              birthdate: e.errors.base,
            })
          }
        }
      }),
  }),
  withProps((props) => {
    let links = [
      {
        label: "ผลิตภัณฑ์",
        path: "/",
      },
      {
        label: "ค้นหาแบบประกัน",
        path: path.productsSearchPath({
          gender: props.params.gender,
          type: props.params.type,
          sumInsured: props.params.sumInsured,
          birthdate: props.params.birthdate,
          searchValue: props.params.searchValue,
          searchBy: props.params.searchBy,
        }),
      },
      {
        label: "เลือกแบบประกัน",
        path: path.productsPath({
          gender: props.params.gender,
          type: props.params.type,
          sumInsured: props.params.sumInsured,
          birthdate: props.params.birthdate,
          searchValue: props.params.searchValue,
          searchBy: props.params.searchBy,
        }),
      },
      {
        label: "คำนวณเบี้ย",
        path: path.productCustomEditPath(props.match.params.productId, props.params),
      },
      {
        label: "สรุปแบบประกัน",
        path: path.productCustomPath(props.match.params.productId, props.params),
      },
      {
        label: "กรอกข้อมูลลีด",
      },
    ]
    return {
      links,
    }
  }),
  lifecycle({
    componentWillMount() {
      const {
        gender,
        birthdate,
        isDraft,
        riders = [],
        sumInsured,
        premium,
        paymentPeriod,
        occupationLevel,
        searchValue,
        searchBy,
        occupation,
        occupationCategory,
        opportunityKey,
      } = this.props.location.query
      const { productId } = this.props.match.params
      const age = birthdate ? moment().diff(moment(birthdate), "years") : null
      this.props.initialize({
        policyId: productId,
        policyPremium: premium,
        policySumInsured: sumInsured,
        insuranceHolderType: "self",
        age,
        searchValue: searchValue,
        searchBy: searchBy,
        isDraft,
        gender,
        birthdate,
        paymentPeriod,
        occupationLevel: occupationLevel,
        occupation,
        occupationCategory,
        opportunityKey,
        riders: riders.map((rider) => ({
          ...rider,
          riderPolicyId: rider.id,
          riderSumInsured: rider.sumInsured || 0,
          riderPremium: rider.premium || 0,
        })),
      })
    },
  })
)

export default enhancer(CreateLeadPage)
