import React, { Fragment, useCallback, useEffect, useState } from "react"
import { compose, withProps } from "recompose"
import { withStores, withRouter, withHooks } from "@enhancers/index"
import styled from "@emotion/styled"
import { map } from "lodash"
import { exceptNullKeys, call } from "@common/helper"
import * as path from "@common/path"

import Table from "@pages/lead_pool/index/Table"
import Header from "@pages/lead_pool/index/Header"
import Loading from "@pages/index/Loading"

const Layout = styled.div`
  margin: ${(props) => (props.shouldMarginTop ? "60px auto 25px auto" : "0px auto 25px auto")};
`

const LeadPoolIndexPage = (props) => {
  return (
    <Fragment>
      {props.loading ? (
        <Loading />
      ) : (
        <Layout>
          <Header
            selectedAffiliateOrderIds={props.selectedAffiliateOrderIds}
            setSelectedAffiliateOrderIds={props.setSelectedAffiliateOrderIds}
            disabledStateFilter={props.disabledStateFilter}
            teams={props.teams}
          />
          <Table
            loadingTable={props.loadingTable}
            data={props.affiliateOrders}
            paging={props.paging}
            teams={props.teams}
            onPageChange={props.changePage}
            showSelectColumn={props.selectingLeadPool}
            onSelectRow={props.selectRow}
            onSelectAll={props.selectAll}
          />
        </Layout>
      )}
    </Fragment>
  )
}

const enhancer = compose(
  withRouter(),
  withStores((stores, props) => ({
    fetchConfigs: stores.leadPoolStore.fetchConfigs,
    fetchTeamConfigs: stores.leadPoolStore.fetchTeamConfigs,
    fetchLeadPools: stores.leadPoolStore.fetch,
    paging: stores.leadPoolStore.paging,
    query: props.location.query,
    mode: props.location.query.mode,
    currentRole: stores.appStore.currentRole,
    teamId: stores.appStore.$("currentSale.team.id"),
  })),
  withProps((props) => {
    const newProps = {}
    newProps.initialState = "new"

    return {
      initialState: newProps.initialState,
      ...props,
    }
  }),
  withHooks((props) => {
    const [affiliateOrders, setAffiliateOrders] = useState([])
    const [teams, setTeams] = useState([])
    const [paging, setPaging] = useState({})
    const [selectedAffiliateOrderIds, setSelectedAffiliateOrderIds] = useState([])

    const [initialized, setInitialized] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingTable, setLoadingTable] = useState(false)
    const [toggleAssignedSuccessModalValue, setToggleAssignedSuccessModal] = useState(false)

    let customAffiliateOrders = affiliateOrders
    switch (props.mode) {
      case "assigning":
        customAffiliateOrders = affiliateOrders.filter(
          (l) =>
            l.affiliateOrderStatus ===
            (props.currentRole === "member.team_leader" ? "team_assigned" : "new")
        )
        break
      case "unAssigning":
        customAffiliateOrders = affiliateOrders.filter(
          (l) => l.affiliateOrderStatus === "team_assigned" || l.affiliateOrderStatus === "assigned"
        )
        break
      default:
        break
    }
    customAffiliateOrders.forEach((order) => {
      order.selected = selectedAffiliateOrderIds.includes(order.id)
      switch (props.mode) {
        case "assigning":
          order.disabled = false
          order.hasSelect =
            order.affiliateOrderStatus ===
            (props.currentRole === "member.team_leader" ? "team_assigned" : "new")
          break
        case "unAssigning":
          order.disabled = false
          order.hasSelect =
            order.affiliateOrderStatus === "team_assigned" ||
            order.affiliateOrderStatus === "assigned"
          break
        default:
          order.disabled = false
          order.hasSelect = false
          break
      }
    })

    const changePage = useCallback(async (page) => {
      props.history.push(
        path.leadPoolsPath({
          ...props.query,
          page: page,
        })
      )
    })

    const selectAll = useCallback((selected) => {
      if (selected) {
        setSelectedAffiliateOrderIds(map(customAffiliateOrders, "id"))
      } else {
        setSelectedAffiliateOrderIds([])
      }
    })

    const selectRow = useCallback((value, dataItem) => {
      if (value) {
        setSelectedAffiliateOrderIds([...selectedAffiliateOrderIds, dataItem.id])
      } else {
        setSelectedAffiliateOrderIds(selectedAffiliateOrderIds.filter((id) => id !== dataItem.id))
      }
    })

    const closeAssignSuccessModal = useCallback(() => {
      setToggleAssignedSuccessModal(!props.toggleAssignedSuccessModal)
    })

    const fetchLeadPools = useCallback(async (params) => {
      const exceptNullParams = exceptNullKeys(
        params,
        "age.min",
        "age.max",
        "affiliateOrderStatus",
        "gender",
        "province",
        "district",
        "subDistrict",
        "teamId"
      )

      return await props.fetchLeadPools({ ...exceptNullParams })
    })

    useEffect(() => {
      call(async () => {
        try {
          setLoadingTable(true)
          const [{ affiliateOrders, paging }, teams, _] = await Promise.all([
            fetchLeadPools({ ...props.query }),
            props.fetchTeamConfigs(),
            props.fetchConfigs(),
          ])

          setAffiliateOrders(affiliateOrders)
          setPaging(paging)
          setTeams(teams)

          if (["assigning", "unAssigning"].includes(props.mode)) {
            setSelectedAffiliateOrderIds(affiliateOrders.map((order) => order.id))
          }

          setInitialized(true)
          setLoadingTable(false)
        } catch (error) {
          alert(error.errors?.base || "มีข้อผิดพลาดบางอย่างเกิดขึ้น")
        }
      })
    }, [JSON.stringify(props.query)])

    return {
      selectedAffiliateOrderIds,
      initialized,
      loading,
      affiliateOrders: customAffiliateOrders,
      paging,
      loadingTable,
      toggleAssignedSuccessModalValue,
      teams,
      selectedAffiliateOrderIds,

      selectAll,
      selectRow,
      changePage,
      closeAssignSuccessModal,
      setToggleAssignedSuccessModal,

      selectingLeadPool: ["assigning", "unAssigning"].includes(props.mode),
      disabledFilter: [].includes(props.mode) || !initialized,
      disabledStateFilter: ["assigning"].includes(props.mode) || !initialized,
    }
  })
)

export default enhancer(LeadPoolIndexPage)
