import { Form } from "redux-form"
import { withProps, compose, defaultProps } from "recompose"
import classnames from "classnames"
import styled from "@emotion/styled"
import { Layout } from "@enhancers/withCaption"

const CustomForm = styled(Form)`
  ${Layout} {
    margin-top: 10px;

    &:nth-of-type(1) {
      margin-top: 0px;
    }
  }
`

const enhancer = compose(
  withProps((props) => ({
    className: classnames({ [props.className]: props.className }),
  })),
  defaultProps({ onSubmit: () => {} })
)

export default enhancer(CustomForm)
