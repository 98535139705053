import React, { useCallback } from "react"
import { Grid, GridColumn, Button, Icon, CurrencyInput, Field, Form } from "@components"
import styled from "@emotion/styled"
import { compose, withHandlers } from "recompose"
import { withHooks } from "@enhancers/index"

export const Layout = styled(Form)`
  margin-top: 8px;
`
export const Table = styled(Grid)`
  table {
    width: 100% !important;
  }

  .k-header {
    border-width: 0 0 1px 1px !important;
    border-style: solid !important;
    border-color: inherit !important;
    vertical-align: middle;
    white-space: unset;
    text-align: center;
  }

  .k-grid-header {
    height: 100% !important;
    padding: 0px !important;
  }

  .k-header.k-link {
    padding: 17px 8px !important;
  }

  .k-master-row > td {
    padding: 8px !important;
    line-height: 21px;
    text-align: center !important;
    align-items: center;
    min-height: 56px;

    :nth-of-type(5) {
      margin: 0 !important;

      > div {
        width: 100%;
      }

      .k-widget.k-combobox.k-header.k-combobox-clearable {
        padding: unset !important;
        margin: 0 !important;
      }
    }
  }

  .k-master-row.k-alt {
    > td:nth-of-type(2) {
      line-height: 21px;
    }
  }

  [class*="ReadOnly"] {
    justify-content: center;
  }
`
export const CustomInput = styled(CurrencyInput)`
  width: 100%;
  height: 56px;
`
export const CustomButton = styled(Button)`
  width: 35px;
  border: 0;
  background-color: transparent;

  :hover {
    border: 0px;
    background-color: transparent;
  }
`
export const CustomIcon = styled(Icon)`
  font-size: 20px;
  color: #666666 !important;
`
export const BonusContainerTd = styled.td`
  display: flex;
  justify-content: center;
`
export const TitleContainer = styled.span`
  display: flex;
  flex-direction: column;
`

const MonthlyBonusTable = (props) => {
  return (
    <Layout>
      <Table data={props.data} readOnly={props.readOnly}>
        <GridColumn title="เบี้ยประกันปีแรกที่ปรับปรุงแล้ว (AFYP) (บาท)">
          <GridColumn title="ต่ำสุด" cell={props.afypMinCell} width="100px" />
          <GridColumn title="สูงสุด" cell={props.afypMaxCell} width="100px" />
        </GridColumn>
        <GridColumn
          title={
            <TitleContainer>
              <a>อัตราโบนัส</a>
              <a>(% ต่อ AFYP ตั้งแต่บาทแรก)</a>
            </TitleContainer>
          }
          cell={props.bonusPercentCell}
          width="100px"
        />
      </Table>
    </Layout>
  )
}

const enhancer = compose(
  withHandlers({
    addItem: (props) => () => {
      props.change("companyMonthlyBonusRates", [
        ...props.data,
        {
          afypMin: null,
          afypMax: null,
          bonusPercent: null,
        },
      ])
    },

    removeItem: (props) => (index) => () => {
      let array = props.data
      if (index > -1) {
        array.splice(index, 1)
      }

      props.change("companyMonthlyBonusRates", array)
    },
  }),
  withHooks((props) => {
    const afypMinCell = useCallback(
      (cell) => {
        return (
          <td>
            <Field
              name={`companyMonthlyBonusRates[${cell.dataIndex}].afypMin`}
              component={CustomInput}
              onBlur={(e) => {
                e.preventDefault()
              }}
              placeholder="โปรดระบุ"
              readOnly={!props.readOnly}
            />
          </td>
        )
      },
      [props.readOnly, props.data.length]
    )

    const afypMaxCell = useCallback(
      (cell) => {
        return (
          <td>
            <Field
              name={`companyMonthlyBonusRates[${cell.dataIndex}].afypMax`}
              component={CustomInput}
              onBlur={(e) => {
                e.preventDefault()
              }}
              placeholder="โปรดระบุ"
              readOnly={!props.readOnly}
            />
          </td>
        )
      },
      [props.readOnly, props.data.length]
    )

    const bonusPercentCell = useCallback(
      (cell) => {
        return (
          <BonusContainerTd>
            <Field
              name={`companyMonthlyBonusRates[${cell.dataIndex}].bonusPercent`}
              component={CustomInput}
              onBlur={(e) => {
                e.preventDefault()
              }}
              placeholder="โปรดระบุ"
              readOnly={!props.readOnly}
            />

            {props.readOnly && (
              <>
                {cell.dataIndex + 1 !== props.data.length ? (
                  <CustomButton onClick={props.removeItem(cell.dataIndex)} styletype="delete">
                    <CustomIcon icon="minus" />
                  </CustomButton>
                ) : (
                  <CustomButton
                    onClick={() => props.addItem()}
                    hidden={cell.dataIndex + 1 !== props.data.length}
                  >
                    <CustomIcon icon="plus" />
                  </CustomButton>
                )}
              </>
            )}
          </BonusContainerTd>
        )
      },
      [props.readOnly, props.data.length]
    )

    return {
      afypMinCell,
      afypMaxCell,
      bonusPercentCell,
    }
  })
)

export default enhancer(MonthlyBonusTable)
