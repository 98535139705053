import React from "react"
import styled from "@emotion/styled"
import { withStores } from "@enhancers"
import { compose, withProps } from "recompose"
import { FieldArray, Field } from "redux-form"
import BeneficiaryField from "./BeneficiaryField"
import { Checkbox } from "@components/index"

const CustomLayout = styled.div`
  width: 100%;
  justify-content: center;
  overflow-x: auto;
`
const ErrorMessage = styled.span`
  display: flex;
  font-size: 12px;
  color: red;
  text-align: center;
`

const Beneficiary = (props) => (
  <CustomLayout>
    <FieldArray
      name="insuranceBeneficiaries"
      component={BeneficiaryField}
      genderOptions={props.genderOptions}
      relationshipOptions={props.relationshipOptions}
      isEdit={props.isEdit}
      readOnly={props.readOnly}
      change={props.change}
      onItemCountChange={props.onItemCountChange}
      isStatutoryHeir={props.isStatutoryHeir}
    />

    <ErrorMessage>{props.submitFailed && props.error}</ErrorMessage>
  </CustomLayout>
)

const enhancer = compose(
  withStores((stores, props) => ({
    orderConfigs: stores.orderStore.orderConfigs,
    initialValues: [{ name: "XXXX", gender: "male" }],
  })),
  withProps((props) => {
    const { submitFailed, error } = props.meta || {}
    return {
      submitFailed: submitFailed,
      error: error && error.error,
    }
  })
)

export default enhancer(Beneficiary)
