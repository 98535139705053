import React from "react"
import styled from "@emotion/styled"
import { compose, withHandlers } from "recompose"
import { withForm, withStores } from "@enhancers"
import { Input, Form, Field, DialogActionsBar, InputFile, Dropdown } from "@components"

import { CustomDialog, CloseButton, SubmitButton } from "./DeleteModal"

const Layout = styled(Form)`
  padding: 15px;
`

const Note = styled.div`
  margin-top: 10px;
  text-align: center;
  font-size: 10px;
`
const CustomDropdown = styled(Dropdown)`
  > div:nth-of-type(2) {
    > span {
      padding: 0px;
      background-color: transparent;
    }
  }
`

export const AddDocumentModal = (props) => (
  <CustomDialog title={props.title} onClose={props.onClose} isShowModal={props.open}>
    <Layout>
      <Field
        name="documentType"
        caption="ประเภทเอกสาร"
        component={CustomDropdown}
        options={props.documentTypeOptions}
        validate="required"
        readOnly={props.readNameOnly}
      />
      <Field
        name="sourceType"
        caption="แหล่งที่มา"
        component={CustomDropdown}
        options={props.sourceTypeOptions}
        readOnly={props.readNameOnly}
      />
      <Field
        name="name"
        caption="ชื่อเอกสาร"
        component={Input}
        validate="required"
        readOnly={props.readNameOnly}
      />
      <Field
        name="file"
        caption="เอกสาร"
        component={InputFile}
        readOnly={props.readFileOnly}
        hidden={props.authorized}
        children="อัพโหลด"
        {...props.fileProps}
      />
      {!props.authorized && (
        <Note>
          หมายเหตุ: รับไฟล์สกุล .doc, .xls, .pdf, .jpeg, ,.jpg .png เท่านั้น ขนาดไม่เกินไฟล์ละ 2 MB
        </Note>
      )}
    </Layout>

    <DialogActionsBar>
      <CloseButton type="button" onClick={props.onClose} children="ยกเลิก" styletype="delete" />
      <SubmitButton
        type="button"
        onClick={props.onSubmit}
        children="ยืนยัน"
        disabled={props.invalid}
      />
    </DialogActionsBar>
  </CustomDialog>
)

const enhancer = compose(
  withStores((stores, props) => ({
    documentTypeOptions: stores.insurerStore.$("configs.attributes.documentType.options", []),
    sourceTypeOptions: stores.orderStore.$(
      "orderDocumentConfigs.attributes.sourceType.options",
      []
    ),
  })),
  withForm({ form: "AddDocumentForm" }),
  withHandlers({
    onClose: (props) => () => {
      props.reset(props.from)
      props.onClose()
    },
    onSubmit: (props) =>
      props.handleSubmit(async (values) => {
        await props.onAfterAddSubmit(values)
        await props.reset(props.from)
        await props.onClose()
      }),
  })
)
export default enhancer(AddDocumentModal)
