import React from "react"
import styled from "@styled"
import { Field, DatePicker, Dropdown } from "@components"
import { CustomInput } from "../show/index"
import { compose } from "recompose"
import { withStores } from "@enhancers/index"

export const Row = styled.div`
  display: flex;
  margin-top: 20px;

  &:nth-of-type(1) {
    margin-top: 0;
  }

  > div {
    margin-left: 16px;
    &:nth-of-type(1) {
      margin-left: 0;
    }

    > div {
      > div {
        font-weight: normal;
      }
    }
  }
`

const FilterPanel = (props) => (
  <div>
    <Row>
      <Field
        component={CustomInput}
        name="customerName"
        caption="ชื่อลูกค้า"
        captionStyle={{ fontWeight: "normal" }}
        placeholder="กรุณาระบุ"
        leftWidth="267"
        rightWidth="344"
      />
    </Row>
    <Row>
      <Field
        component={CustomInput}
        name="tqmOrderId"
        caption="รหัสคำสั่งซื้อ"
        captionStyle={{ fontWeight: "normal" }}
        placeholder="กรุณาระบุ"
        leftWidth="267"
        rightWidth="344"
      />
    </Row>
    <Row>
      <Field
        component={CustomInput}
        name="policyNumber"
        caption="เลขกรมธรรม์"
        captionStyle={{ fontWeight: "normal" }}
        placeholder="กรุณาระบุ"
        leftWidth="267"
        rightWidth="344"
      />
    </Row>
    <Row>
      <Field
        component={Dropdown}
        name="insuranceCompanyId"
        caption="บริษัทประกัน"
        captionStyle={{ fontWeight: "normal" }}
        options={props.insuranceCompanyOptions}
        leftWidth="267"
        rightWidth="344"
        placeholder="กรุณาเลือก"
      />
    </Row>

    {["estimated_income", "remaining_income"].includes(props.incomeType) && (
      <Row>
        <Field
          component={DatePicker}
          name="estimatedIncomeExportedDate.min"
          caption="ช่วงวันที่นำออกประมาณการรายได้"
          captionStyle={{ fontWeight: "normal" }}
          leftWidth="267"
          rightWidth="155"
          disabled={props.disabled}
          placeholder="กรุณาเลือก"
          showErrorMessage={true}
        />
        <Field
          component={DatePicker}
          name="estimatedIncomeExportedDate.max"
          caption="ถึง"
          captionStyle={{ fontWeight: "normal" }}
          leftWidth="35"
          rightWidth="138"
          disabled={props.disabled}
          placeholder="กรุณาเลือก"
          customValidate={true}
          showErrorMessage={true}
        />
      </Row>
    )}
    {["received_income"].includes(props.incomeType) && (
      <Row>
        <Field
          component={DatePicker}
          name="receivedIncomeExportedDate.min"
          caption="ช่วงวันที่นำออกรายได้รับ"
          captionStyle={{ fontWeight: "normal" }}
          leftWidth="267"
          rightWidth="155"
          disabled={props.disabled}
          placeholder="กรุณาเลือก"
          showErrorMessage={true}
        />
        <Field
          component={DatePicker}
          name="receivedIncomeExportedDate.max"
          caption="ถึง"
          captionStyle={{ fontWeight: "normal" }}
          leftWidth="35"
          rightWidth="138"
          disabled={props.disabled}
          placeholder="กรุณาเลือก"
          customValidate={true}
          showErrorMessage={true}
        />
      </Row>
    )}
    {["remaining_income"].includes(props.incomeType) && (
      <Row>
        <Field
          component={DatePicker}
          name="remainingIncomeExportedDate.min"
          caption="ช่วงวันที่นำออกรายได้ค้างรับ"
          captionStyle={{ fontWeight: "normal" }}
          leftWidth="267"
          rightWidth="155"
          disabled={props.disabled}
          placeholder="กรุณาเลือก"
          showErrorMessage={true}
        />
        <Field
          component={DatePicker}
          name="remainingIncomeExportedDate.max"
          caption="ถึง"
          captionStyle={{ fontWeight: "normal" }}
          leftWidth="35"
          rightWidth="138"
          disabled={props.disabled}
          placeholder="กรุณาเลือก"
          customValidate={true}
          showErrorMessage={true}
        />
      </Row>
    )}
  </div>
)

const enhancer = compose(
  withStores((stores) => ({
    insuranceCompanyOptions: stores.insurerCommissionStore.$(
      "configs.filters.insuranceCompany.options",
      []
    ),
  }))
)

export default enhancer(FilterPanel)
