import React, { useCallback } from "react"
import { GridColumn, Field } from "@components"
import { compose, withHandlers } from "recompose"
import { withHooks } from "@enhancers/index"

import {
  Layout,
  Table,
  CustomInput as Input,
  CustomButton as Button,
  CustomIcon as Icon,
  BonusContainerTd,
  TitleContainer,
} from "./MonthlyBonusTable"

const ExperienceBonusRatesTable = (props) => {
  return (
    <Layout>
      <Table data={props.data} readOnly={props.readOnly} style={{ marginTop: 7 }}>
        <GridColumn title="อายุงาน (ปี)">
          <GridColumn title="ต่ำสุด" cell={props.experienceMinCell} width="100px" />
          <GridColumn title="สูงสุด" cell={props.experienceMaxCell} width="100px" />
        </GridColumn>
        <GridColumn
          title={
            <TitleContainer>
              <a>อัตราโบนัส (%)</a>
            </TitleContainer>
          }
          cell={props.bonusPercentCell}
          width="100px"
        />
      </Table>
    </Layout>
  )
}

const enhancer = compose(
  withHandlers({
    addItem: (props) => ({ name }) => {
      props.change(name, [
        ...props.data,
        {
          experienceMin: null,
          experienceMax: null,
          bonusPercent: null,
        },
      ])
    },

    removeItem: (props) => ({ index, name }) => () => {
      let array = props.data
      if (index > -1) {
        array.splice(index, 1)
      }

      props.change(name, array)
    },
  }),
  withHooks((props) => {
    const experienceMinCell = useCallback(
      (cell) => {
        return (
          <td>
            <Field
              name={`${props.name}[${cell.dataIndex}].experienceMin`}
              component={Input}
              onBlur={(e) => {
                e.preventDefault()
              }}
              placeholder="โปรดระบุ"
              readOnly={!props.readOnly}
            />
          </td>
        )
      },
      [props.readOnly]
    )

    const experienceMaxCell = useCallback(
      (cell) => {
        return (
          <td>
            <Field
              name={`${props.name}[${cell.dataIndex}].experienceMax`}
              component={Input}
              onBlur={(e) => {
                e.preventDefault()
              }}
              placeholder="โปรดระบุ"
              readOnly={!props.readOnly}
            />
          </td>
        )
      },
      [props.readOnly]
    )

    const bonusPercentCell = useCallback(
      (cell) => {
        return (
          <BonusContainerTd>
            <Field
              name={`${props.name}[${cell.dataIndex}].bonusPercent`}
              component={Input}
              onBlur={(e) => {
                e.preventDefault()
              }}
              placeholder="โปรดระบุ"
              readOnly={!props.readOnly}
            />
            {props.readOnly && (
              <>
                {cell.dataIndex + 1 !== props.data.length ? (
                  <Button
                    onClick={props.removeItem({ index: cell.dataIndex, name: props.name })}
                    styletype="delete"
                  >
                    <Icon icon="minus" />
                  </Button>
                ) : (
                  <Button
                    onClick={() => props.addItem({ name: props.name })}
                    hidden={cell.dataIndex + 1 !== props.data.length}
                  >
                    <Icon icon="plus" />
                  </Button>
                )}
              </>
            )}
          </BonusContainerTd>
        )
      },
      [props.readOnly, props.data.length]
    )

    return {
      experienceMinCell,
      experienceMaxCell,
      bonusPercentCell,
    }
  })
)

export default enhancer(ExperienceBonusRatesTable)
