import React from "react"
import { NumericTextBox } from "@progress/kendo-react-inputs"
import Button from "../Button"
import styled from "@emotion/styled"

const Layout = styled.div``

const InputLayout = styled.div`
  display: flex;
  align-items: center;

  > .k-widget.k-numerictextbox {
    margin: 0 0.375rem;
  }
`

const FilterLayout = styled.div``

export default class RangeFilterCell extends React.Component {
  minTextBox
  maxTextBox

  inRange = (current, { min, max }) =>
    (min === null || current >= min) && (max === null || current <= max)

  onChange = (event) => {
    this.props.onChange({
      value: { min: this.minTextBox.value, max: this.maxTextBox.value },
      operator: this.inRange,
      syntheticEvent: event.syntheticEvent,
    })
  }

  onClearButtonClick = (event) => {
    event.preventDefault()
    this.props.onChange({
      value: null,
      operator: "",
      syntheticEvent: event,
    })
  }

  render() {
    const value = this.props.value || null

    return (
      <Layout className="k-filtercell">
        <InputLayout>
          Min:
          <NumericTextBox
            width="70px"
            value={value && value.min}
            ref={(numeric) => {
              this.minTextBox = numeric
            }}
            onChange={this.onChange}
          />
          Max:
          <NumericTextBox
            width="70px"
            value={value && value.max}
            ref={(numeric) => {
              this.maxTextBox = numeric
            }}
            onChange={this.onChange}
          />
        </InputLayout>
        <FilterLayout className="k-filtercell-wrapper">
          <Button
            title="Clear"
            icon="filter-clear"
            onClick={this.onClearButtonClick}
            hidden={!value}
          />
        </FilterLayout>
      </Layout>
    )
  }
}
