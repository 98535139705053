import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { Icon } from "@components/index"
import { compose, withProps, withHandlers } from "recompose"
import defaultLogo from "@assets/images/img-no-insurance.png"

const Layout = styled.div`
  display: flex;
  width: 160px;
  height: 71px;
  background-color: #fff;
  border-radius: 4px;
  margin-right: 20px;
  align-items: flex-start;
  padding: 15px 8px;
  position: relative;

  ${(props) =>
    props.isSelect &&
    css`
      border: 1px solid #229ec0;
    `}
`
const CompanyLogo = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 4px;
  margin-right: 5px;
`
const PoductrPremium = styled.span`
  font-size: 12px;
  color: #666666;
  font-family: Sarabun, sans-serif;
  width: 90px;
`
const DeleteIconContainer = styled.div`
  display: flex;
`
const DeleteIcon = styled(Icon)`
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
`

const WrapperRight = styled.div`
  display: flex;
  flex-direction: column;
`

const Name = styled.div`
  width: 90px;
  font-size: 12px;
  color: #666666;
  font-family: Sarabun, sans-serif;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 8px;
`
const displayNumber = (value) => {
  let nums = ("" + value).split(".")
  const onlyNums = nums[0].replace(/[^-\d]/g, "")
  let rgx = /(\d+)(\d{3})/
  let x1 = onlyNums
  while (rgx.test(x1)) {
    // eslint-disable-next-line no-useless-concat
    x1 = x1.replace(rgx, "$1" + "," + "$2")
  }
  if (nums.length > 1) x1 = x1 + "." + nums[1]
  return x1
}

const SelectedCompareProduct = ({ id, name, companyLogoUrl, premium, onDeleteIconClick }) => (
  <Layout isSelect={id}>
    {id ? (
      <>
        <CompanyLogo src={companyLogoUrl ? companyLogoUrl : defaultLogo} />
        <WrapperRight>
          {id && <Name>{name}</Name>}
          {premium && (
            <PoductrPremium>{`${displayNumber(Math.round(premium))} บาท/ปี`}</PoductrPremium>
          )}
          {premium && (
            <DeleteIconContainer onClick={() => onDeleteIconClick(id)}>
              <DeleteIcon icon="close" />
            </DeleteIconContainer>
          )}
        </WrapperRight>
      </>
    ) : null}
  </Layout>
)

const enhancer = compose(
  withProps((props) => {
    const { id, companyLogoUrl, premium, ...rest } = props.data || {}
    return {
      id: id,
      companyLogoUrl: companyLogoUrl,
      premium: premium,
      ...rest,
    }
  }),
  withHandlers({
    onDeleteIconClick: (props) => (id) => {
      props.setDeselectCompareProduct(id)
    },
  })
)

export default enhancer(SelectedCompareProduct)
