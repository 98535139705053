export class Cache {
  storage = {}

  async fetch(key, value) {
    if (!(key in this.storage)) {
      if (typeof value === "function") {
        value = await value()
      }
      this.storage[key] = value
    }
    return this.storage[key]
  }

  fetchWithoutAsync(key, value) {
    if (!(key in this.storage)) {
      if (typeof value === "function") {
        value = value()
      }
      this.storage[key] = value
    }
    return this.storage[key]
  }
}

export default new Cache()
