import React from "react"
import { compose, withProps } from "recompose"
import styled from "@styled"
import { Field, Dropdown, CurrencyInput, Input, Authorize } from "@components"
import { withStores, withForm, withHooks, withRouter } from "@enhancers/index"
import { safe } from "@common/helper"
import { find } from "lodash"

export const Row = styled.div`
  display: flex;
  margin-top: 12px;

  &:nth-of-type(1) {
    margin-top: 0;
  }

  > div {
    margin-left: 12px;

    &:nth-of-type(1) {
      margin-left: 0;
    }
  }
`

const FilterPanel = (props) => {
  return (
    <div className={props.className || "filterPanel"}>
      <Authorize roles={["member.lead_admin", "member.team_leader", "member.brokerage_admin"]}>
        <Row>
          <Field
            component={Dropdown}
            name="province"
            caption="จังหวัด"
            captionStyle={props.captionStyle}
            options={props.provinceOptions}
            leftWidth="55"
            rightWidth="138"
            disabled={props.disabled}
            placeholder="กรุณาเลือก"
          />
          <Field
            component={Dropdown}
            name="district"
            caption="อำเภอ"
            captionStyle={props.captionStyle}
            options={props.districtOptions}
            leftWidth="57"
            rightWidth="138"
            disabled={props.disabled}
            placeholder="กรุณาเลือก"
          />
          <Field
            component={Dropdown}
            name="subDistrict"
            caption="ตำบล"
            captionStyle={props.captionStyle}
            options={props.subDistrictOptions}
            leftWidth="94"
            rightWidth="138"
            disabled={props.disabled}
            placeholder="กรุณาเลือก"
          />
        </Row>
        <Row>
          <Field
            component={Dropdown}
            name="gender"
            caption="เพศ"
            captionStyle={props.captionStyle}
            options={props.genderOptions}
            leftWidth="55"
            rightWidth="138"
            disabled={props.disabled}
            placeholder="กรุณาเลือก"
          />
          <Field
            component={CurrencyInput}
            name="age.min"
            caption="อายุ"
            captionStyle={props.captionStyle}
            leftWidth="43"
            rightWidth="64"
            disabled={props.disabled}
            placeholder="ระบุ"
            min={0}
            max={99}
          />
          <Field
            component={CurrencyInput}
            name="age.max"
            caption="-"
            captionStyle={props.captionStyle}
            leftWidth="12"
            rightWidth="64"
            placeholder="ระบุ"
            min={0}
            max={99}
          />
          <Field
            component={CurrencyInput}
            name="limit"
            caption="จำนวน"
            captionStyle={props.captionStyle}
            leftWidth="94"
            rightWidth="138"
            disabled={props.disabled}
            placeholder="กรุณาระบุ"
            min={0}
          />
        </Row>
        <Row>
          <Field
            component={Dropdown}
            name="teamId"
            caption="ทีม"
            captionStyle={props.captionStyle}
            options={props.teamIdOptions}
            leftWidth="55"
            rightWidth="138"
            disabled={props.disabled}
            placeholder="กรุณาเลือก"
          />
          <Field
            component={Dropdown}
            name="affiliateOrderStatus"
            caption="สถานะ"
            captionStyle={props.captionStyle}
            options={props.stateOptions}
            leftWidth="57"
            rightWidth="138"
            placeholder="กรุณาเลือก"
            disabled={props.disabled || props.disabledStateFilter}
          />
          <Field
            component={Input}
            name="orderNo"
            caption="รหัสคำสั่งซื้อ"
            captionStyle={props.captionStyle}
            leftWidth="94"
            rightWidth="138"
            placeholder="กรุณาระบุ"
            disabled={props.disabled}
          />
        </Row>
        <Row>
          <Field
            component={Input}
            name="agtOpportunityKey"
            caption="รหัสโอกาสในการขาย"
            captionStyle={props.captionStyle}
            leftWidth="152"
            rightWidth="138"
            disabled={props.disabled}
            placeholder="กรุณาระบุ"
          />
          <Field
            component={Dropdown}
            name="affiliateId"
            caption="ชื่อผู้แนะนำ"
            captionStyle={props.captionStyle}
            options={props.affiliateOptions}
            leftWidth="88"
            rightWidth="254"
            disabled={props.disabled}
            placeholder="กรุณาเลือก"
          />
        </Row>
      </Authorize>
    </div>
  )
}

const enhancer = compose(
  withRouter({
    queryTypes: {
      teamId: "integer",
      "startDate.min": "date",
      "expiredDate.max": "date",
    },
  }),
  withStores((stores, props) => ({
    provinceOptions: stores.leadPoolStore.$("configs.filters.address.options", []),
    genderOptions: stores.leadPoolStore.$("configs.filters.gender.options", []),
    teamIdOptions: stores.leadPoolStore.$("configs.filters.team.options", []),
    stateOptions: stores.leadPoolStore.$("configs.filters.affiliateOrderStatus.options", []),
    affiliateOptions: stores.leadPoolStore.$("configs.filters.affiliate.options", []),
    query: props.location.query,
    currentRole: stores.appStore.currentRole,
    saleId: stores.appStore.$("currentSale.id"),
  })),
  withForm({
    form: "LeadPoolFilterForm",
  }),
  withProps((props) => {
    const captionStyle = {
      fontWeight: "normal",
      textAlign: "end",
      paddingRight: "10px",
    }
    return {
      initialValues: props.query,
      captionStyle,
    }
  }),
  withHooks((props) => {
    const provinceOptions = props.provinceOptions.filter(({ label }) => label)
    const districtOptions = safe(
      () =>
        find(provinceOptions, {
          value: props.values.province || props.query.province,
        }).districts.filter(({ label }) => label),
      []
    )
    const subDistrictOptions = safe(
      () =>
        find(districtOptions, {
          value: props.values.district || props.query.district,
        }).subDistricts.filter(({ label }) => label),
      []
    )

    let stateOptions = props.stateOptions
    if (props.mode === "unAssigning") {
      stateOptions = stateOptions.filter(
        (stateOption) => stateOption.value === "team_assigned" || stateOption.value === "assigned"
      )
    }

    return {
      provinceOptions,
      districtOptions,
      subDistrictOptions,
      stateOptions,
      initialValues: props.initialValues,
    }
  })
)
export default enhancer(FilterPanel)
