import React from "react"
import { Provider as MobxProvider } from "mobx-react"
import { Provider as ReduxProvider } from "react-redux"
import { default as reduxStore } from "@stores/redux/index"
import * as mobxStore from "@stores/mobx/index"

export const Provider = ({ children }) => (
  <MobxProvider {...mobxStore}>
    <ReduxProvider store={reduxStore}>{children}</ReduxProvider>
  </MobxProvider>
)
