import React from "react"
import { Grid, GridColumn, DatePicker, makeCell } from "@components"
import styled from "@emotion/styled"
import { compose, lifecycle, withHandlers, withProps } from "recompose"
import { withStores } from "@enhancers/index"
import { isEmpty } from "lodash"

const Layout = styled.div``
const Table = styled(Grid)`
  .k-grid-header {
    height: 100% !important;
    padding: 0px !important;
  }

  .k-header.k-link {
    padding: 17px 8px !important;
  }

  .k-master-row > td {
    padding: 8px !important;
    line-height: 21px;

    :nth-of-type(5) {
      margin: 0 !important;

      > div {
        width: 100%;
      }

      .k-widget.k-combobox.k-header.k-combobox-clearable {
        padding: unset !important;
        margin: 0 !important;
      }
    }
  }

  .k-master-row.k-alt {
    > td:nth-of-type(2) {
      line-height: 21px;
    }
  }
`

const HistoryTable = (props) => {
  return (
    <Layout>
      <Table
        data={props.teamHistories}
        readOnly={props.readOnly}
        pageable={!props.pageable}
        skip={props.paging.skip}
        take={props.paging.perPage}
        total={props.paging.totalCount}
        onPageChange={props.onPageChange}
      >
        <GridColumn title="รายละเอียดการแก้ไข" field="historyAction" width="400px" />
        <GridColumn
          title="วันที่แก้ไขล่าสุด"
          cell={makeCell(DatePicker, (props) => ({
            readOnly: true,
            value: props.dataItem.updatedAt,
          }))}
          width="95px"
        />
      </Table>
    </Layout>
  )
}

const enhancer = compose(
  withStores((stores) => ({
    fetchHistory: stores.teamStore.fetchHistory,
    teamHistories: stores.teamStore.teamHistories,
    paging: stores.teamStore.paging,
  })),
  withProps((props) => {
    return {
      paging: {
        ...props.paging,
        skip: (props.paging.page - 1) * props.paging.perPage,
      },
    }
  }),
  withHandlers({
    onPageChange: (props) => async (event) => {
      const page = event.page.skip / event.page.take + 1
      await props.fetchHistory(props.teamId, { page, perPage: 10 })
    },
  }),
  lifecycle({
    async componentDidMount() {
      if (isEmpty(this.props.data) && !isEmpty(this.props.teamId)) {
        await this.props.fetchHistory(this.props.teamId, { perPage: 10 })
      }
    },
  })
)

export default enhancer(HistoryTable)
