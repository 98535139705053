import React, { useCallback, useState, useEffect } from "react"
import styled from "@emotion/styled"
import { Field, Dropdown, CurrencyInput, Checkbox } from "@components"
import { compose } from "recompose"
import { withHooks } from "@enhancers/index"
import { toCurrency } from "@common/helper"

const Layout = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    margin-top: 16px;
  }
  > div:nth-of-type(1) {
    margin-top: 0;
  }
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
`
const LockableRow = styled(Row)`
  margin-top: 16px;
  margin-left: 150px;

  > div {
    > div:first-of-type > div {
      font-weight: 400;
    }
  }
`

const RiderComponent = (props) => (
  <Layout>
    <Field
      caption="สัญญาเพิ่มเติม"
      name={`${props.RiderComponent}riderPolicyId`}
      component={Dropdown}
      options={props.customOptions}
      isFirstChild={props.index === 0 ? true : false}
      readOnly={props.readOnly}
      disabled={props.disabled}
      placeholder="กรุณาเลือก"
      leftWidth="150"
      rightWidth={props.readOnly ? "480" : "245"}
      onChange={props.filterMinSumInsured(props.index)}
    />
    <Field
      caption="ทุนประกัน"
      name={`${props.RiderComponent}riderSumInsured`}
      component={CurrencyInput}
      readOnly={props.readOnly}
      disabled={props.disabled}
      placeholder="โปรดระบุ"
      leftWidth="150"
      rightWidth="245"
    />
    <Field
      caption="คอมมิชชั่น"
      name={`${props.RiderComponent}riderCommission`}
      component={Dropdown}
      options={props.commissionOptions}
      readOnly={props.readOnly}
      disabled={props.disabled}
      placeholder="กรุณาเลือก"
      leftWidth="150"
      rightWidth={props.readOnly ? "480" : "245"}
    />
    <LockableRow>
      <Field
        caption="สัญญาเพิ่มเติมสำหรับแพ็กเกจ"
        name={`${props.RiderComponent}lockable`}
        component={Checkbox}
        leftWidth="210"
        readOnly={props.readOnly}
      />
    </LockableRow>
  </Layout>
)

const enhancer = compose(
  withHooks((props) => {
    const [sumInsureWording, setSumInsureWording] = useState("")
    const filterMinSumInsured = useCallback(
      (index) => () => {
        setTimeout(() => {
          const riderPolicyId = props.fields.get(index).riderPolicyId
          const riderData = props.baseOptions.find((item) => item.value === riderPolicyId)
          let wording = ""

          if (riderData) {
            if (riderData.minSumInsured && riderData.maxSumInsured) {
              wording = `(${toCurrency(riderData.minSumInsured)} - ${toCurrency(
                riderData.maxSumInsured
              )} บาท)`
            } else if (riderData.minSumInsured) {
              wording = `(${toCurrency(riderData.minSumInsured)} บาทขึ้นไป)`
            } else if (riderData.maxSumInsured) {
              wording = `(ไม่เกิน ${toCurrency(riderData.maxSumInsured)} บาท)`
            }

            props.change(`${props.RiderComponent}riderCommission`, riderData.commissionId)
          }
          setSumInsureWording(wording)
          props.change(`${props.RiderComponent}riderSumInsured`, null)
          props.change(`${props.RiderComponent}riderCommission`, null)
        })
      },
      [props.fields]
    )
    useEffect(() => {
      setSumInsureWording("")
    }, [props.insurer])

    return { filterMinSumInsured, sumInsureWording }
  })
)

export default enhancer(RiderComponent)
