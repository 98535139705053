import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
import { compose, withHandlers, withProps, withState } from "recompose"
import { withRouter, withStores, withForm, withHooks } from "@enhancers/index"
import {
  Grid,
  GridColumn,
  makeCell,
  InputName,
  FilterButton,
  Button,
  DatePicker,
  LoadingScope,
} from "@components"

import { isEmpty } from "lodash"
import * as path from "@common/path"
import FilterPanel from "./FilterPanel"
import { safe, call, exceptNullKeys } from "@common/helper"
import { find, get } from "lodash"
import Loading from "@pages/index/Loading"
import moment from "moment"
import StateButton from "./StateButton"
import UploadDocumentModal from "./UploadDocumentModal"

const Layout = styled.div`
  margin: 0px auto 25px auto;
`
const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 12px 0;
  margin-top: 20px;
`
const Left = styled.div``
const Right = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`

const Table = styled(Grid)`
  .k-grid-container {
    .k-grid-content.k-virtual-content {
      > td {
        .k-grid-table {
          > tbody {
            .k-master-row {
              > td {
                cursor: pointer;
                padding-top: 0px !important;
                padding-bottom: 0px !important;
              }
            }
            .k-master-row.k-alt {
              > td {
                cursor: pointer;
                padding-top: 0px !important;
                padding-bottom: 0px !important;
              }
            }
          }
        }
      }
    }
  }
`
const ShowDetailButton = styled(Button)`
  width: 100px;
  border: 1px solid #627b8b;
  color: #627b8b;
  margin-right: 200px;
`

const CustomTd = styled.td`
  position: sticky;
  right: 0;
  padding: 12px;
  border-right-width: 1px;
  background-color: #f8f9fa;
  text-overflow: unset !important;

  > div {
    justify-content: center;
  }
`

const TqmOrderIdTd = styled.td`
  color: #0275d8 !important;
`
const ImportFileButton = styled(Button)``

const OrderIndex = (props) =>
  props.pageLoading ? (
    <LoadingScope />
  ) : (
    <Layout>
      <Header>
        <Left>
          <FilterButton
            expanded={props.filterExpanded}
            onExpand={props.onExpandFilter}
            disabled={props.disabledFilter}
          >
            <FilterPanel
              values={props.values}
              disabled={props.disabledFilter}
              currentRole={props.currentRole}
            />
          </FilterButton>
        </Left>
        {["member.biz_dev"].includes(props.currentRole) ?
          <Right>
            <ImportFileButton
              awesomeIcon="upload"
              onClick={props.onShowUploadModal}
              children="นำเข้าไฟล์"
            />
          </Right>
          : null
        }
      </Header>
      <Table
        data={props.orders}
        onRowClick={props.clickRow}
        pageable={{
          buttonCount: 5,
        }}
        skip={props.paging.skip}
        take={props.paging.perPage}
        total={props.paging.totalCount}
        onPageChange={props.onPageChange}
        footer={props.footerDescription}
        loadingTable={props.loading}
      >
        <GridColumn
          title="รหัสคำสั่งซื้อ"
          width="165px"
          cell={(cellProps) => {
            return cellProps.dataItem.tqmOrderId ? (
              <TqmOrderIdTd>{cellProps.dataItem.tqmOrderId}</TqmOrderIdTd>
            ) : (
              <td>{cellProps.dataItem.orderNo}</td>
            )
          }}
        />
        <GridColumn
          field="insuranceHolderNameInfo"
          title="ชื่อลูกค้า"
          width="165px"
          cell={makeCell(InputName, (cellProps) => ({
            readOnly: true,
            value: cellProps.dataItem.insuranceHolderNameInfo,
          }))}
        />
        <GridColumn
          field="salesmanNameInfo"
          title="ชื่อพนักงาน"
          width="165px"
          cell={makeCell(InputName, (cellProps) => ({
            readOnly: true,
            value: cellProps.dataItem.salesmanNameInfo,
          }))}
        />
        <GridColumn
          field="affiliateNameInfo"
          title="ชื่อผู้แนะนำ"
          width="165px"
          cell={makeCell(InputName, (cellProps) => ({
            readOnly: true,
            value: cellProps.dataItem.affiliateNameInfo,
          }))}
        />
        <GridColumn
          field="submittedAt"
          title="วันที่แจ้ง"
          width="110px"
          filterable={false}
          cell={makeCell(DatePicker, (props) => ({
            readOnly: true,
            value: props.dataItem.submittedAt,
          }))}
        />
        <GridColumn field="policyNumber" title="เลขกรมธรรม์" width="165px" />
        {["member.salesman", "member.team_leader", "member.biz_dev"].includes(
          props.currentRole
        ) && <GridColumn field="companyName" title="บริษัทประกัน" width="190px" resizable={true} />}

        {["member.salesman", "member.team_leader", "member.biz_dev", "member.affiliate"].includes(
          props.currentRole
        ) && (
          <GridColumn
            field="insurance"
            title="แบบประกัน"
            width="126px"
            resizable={true}
            cell={(cell) => {
              return (
                <td>
                  <ShowDetailButton
                    onClick={(e) => props.clickDetailButton(e, cell)}
                    disabled={cell.dataItem.state === "rejected"}
                  >
                    ดูรายละเอียด
                  </ShowDetailButton>
                </td>
              )
            }}
          />
        )}

        {/* {["member.salesman", "member.team_leader"].includes(props.currentRole) && (
          <GridColumn field="commission" title="คอมมิชชั่น" width="226px" resizable={true} />
        )} */}
        <GridColumn
          field="paymentStatus"
          title="สถานะการชำระเงิน"
          width="150px"
          filterable={false}
          cell={(cell) => {
            const state = props.paymentStatusOptions.find(
              ({ label, value }) => cell.dataItem.paymentStatus === value
            )
            return <td className={`paymentStatus-${cell.tdId}`}>{state && state.label}</td>
          }}
        />
        <GridColumn
          field="stateName"
          title="สถานะ"
          width={["member.biz_dev"].includes(props.currentRole) ? "145px" : "125px"}
          locked
          cell={(cell) => {
            const state = props.stateOptions.find(
              ({ label, value }) => cell.dataItem.state === value
            )
            return (
              <CustomTd
                className={`k-grid-content-sticky  ${cell.tdId}`}
                onClick={(e) => props.clickStateRow(e, cell)}
              >
                <StateButton onClick={(e) => props.clickStateButton(cell, e)} state={state} />
              </CustomTd>
            )
          }}
          disabled
        />
      </Table>
      <UploadDocumentModal
        open={props.isShowUploadModal}
        onClose={props.onCloseUploadModal}
        title="นำเข้าไฟล์"
        onSubmitImportFile={props.onSubmitImportFile}
      />
    </Layout>
  )

const enhancer = compose(
  withRouter(),
  withState("loading", "setLoading", false),
  withState("filterExpanded", "setFilterExpanded", false),
  withState("isShowUploadModal", "setIsShowUploadModal", false),
  withStores((stores, props) => ({
    fetchOrders: stores.orderStore.fetch,
    fetchOrder: stores.orderStore.fetchOrder,
    orders: stores.orderStore.all,
    paging: stores.orderStore.paging,
    currentRole: stores.appStore.currentRole,
    teamId: stores.appStore.$("currentSale.team.id"),
    saleId: stores.appStore.$("currentSale.id"),
    currentSale: stores.appStore.currentSale,
    fetchOrderConfigs: stores.orderStore.fetchOrderConfigs,
    stateOptions: stores.orderStore.$("orderConfigs.filters.state.options", []),
    paymentStatusOptions: stores.orderStore.$("orderConfigs.attributes.paymentStatus.options", []),
    footer: stores.orderStore.footer,
    importOrder: stores.orderStore.importOrder,
  })),
  withHandlers({
    fetchOrders: (props) => async (params) => {
      const exceptNullParams = exceptNullKeys(params, "insuranceCompanyId.eq", "state.eq")

      props.setLoading(true)
      switch (props.currentRole) {
        case "member.team_leader":
          await props.fetchOrders({
            branchId: { eq: props.teamId },
            ...exceptNullParams,
          })
          break
        case "member.salesman":
          await props.fetchOrders({
            assigneeId: props.saleId,
            ...exceptNullParams,
          })
          break
        case "member.biz_dev":
          await props.fetchOrders({
            ...exceptNullParams,
          })
          break
        default:
          await props.fetchOrders({
            ...exceptNullParams,
          })
          break
      }
      props.setLoading(false)
    },
  }),
  withState("filters", "setFilters", {}),
  withForm({
    form: "OrderFilterForm",
    onChange: async (filters, _, props) => {
      if (props.filters) {
        clearTimeout(props.filters)
      }

      const exceptNullParams = exceptNullKeys(
        filters,
        "orderNo",
        "insuranceCompanyId",
        "name",
        "state",
        "submittedAt"
      )

      await setTimeout(
        () =>
          props.fetchOrders({
            ...exceptNullParams,
            page: 1,
            perPage: props.paging.perPage,
          }),
        600
      )

      props.setFilters(exceptNullParams)
    },
  }),
  withProps((props) => {
    let footerDescription

    if (props.currentRole === "member.salesman") {
      footerDescription = `ขายได้ : ${props.footer.completedOrdersCount} จากทั้งหมด ${props.footer.ordersCount}`
    } else if (props.currentRole === "member.biz_dev") {
      footerDescription = `อนุมัติแล้ว : ${props.footer.completedOrdersCount} จากทั้งหมด ${props.footer.ordersCount}`
    }

    const orders = props.orders.map((order) => ({
      ...order,
      stateName: safe(() => find(props.stateOptions, { value: order.state }).label),
    }))

    const paging = {
      ...props.paging,
      skip: (props.paging.page - 1) * props.paging.perPage,
    }

    return {
      footerDescription,
      orders,
      paging,
    }
  }),
  withHandlers({
    onExpandFilter: (props) => (expanded) => {
      props.setFilterExpanded(expanded)
      if (!expanded) {
        props.reset()
      }
    },
    onPageChange: (props) => async (event) => {
      const page = event.page.skip / event.page.take + 1
      props.setLoading(false)
      await props.fetchOrders({ page, perPage: props.paging.perPage, ...props.filters })
      props.setLoading(false)
    },
    clickStateRow: (props) => (event, item) => {
      // if (item.dataItem.state !== "rejected") {
      props.history.push(path.orderHolderInfoPath(item.dataItem.id), {
        from: "Orders",
        paymentStatus: item.dataItem.paymentStatus,
      })
      // }
    },
    clickDetailButton: (props) => async (event, item) => {
      event.stopPropagation()

      const { order } = await props.fetchOrder(item.dataItem.id)

      const gender = get(order, "insuranceHolder.gender", null)
      const sumInsured = get(order, "sumInsured", null)
      const premium = get(order, "premium", null)
      const searchBy = get(order, "searchBy", "sum_insured")
      const birthdate = moment(get(order, "insuranceHolder.birthdate", null)).format("DD/MM/YYYY", {
        locale: "en",
      })
      const occupation = get(order, "occupation", null)
      const occupationCategory = get(order, "occupationCategory", null)

      if (props.currentRole === "member.biz_dev") {
        props.history.push(
          path.newOrderProductInfoPath(item.dataItem.id, {
            leadId: item.dataItem.leadId,
            orderId: item.dataItem.id,
            gender,
            sumInsured,
            birthdate,
            occupation,
            occupationCategory,
            state: "new",
            from: "order",
          }),
          {
            from: "Orders",
          }
        )
      } else {
        if (get(order, "packageDetail.id", null)) {
          props.history.push(
            path.productPackagePath(get(order, "packageDetail.id", null), {
              leadId: item.dataItem.leadId,
              orderId: item.dataItem.id,
              gender,
              sumInsured,
              searchBy,
              searchValue: sumInsured,
              birthdate,
              state: "new",
              from: "order",

              premium: order.premium,
              sumInsured: order.sumInsured,
              paymentPeriod: order.paymentPeriod,
              occupationLevel: order.occupationLevel,
              occupation: order.occupation,
              occupationCategory: order.occupationCategory,
              riders: order.riderProducts.map((r) => ({
                id: r.policyId,
                premium: r.premium,
                sumInsured: r.sumInsured,
              })),
            }),
            {
              from: "Orders",
            }
          )
        } else {
          props.history.push(
            path.productCustomPath(get(order, "insurancePolicyId", null), {
              leadId: item.dataItem.leadId,
              orderId: item.dataItem.id,
              id: get(order, "insurancePolicyId", null),
              gender,
              paymentPeriod: get(order, "productDetail.paymentPeriod", null),
              sumInsured,
              birthdate,
              searchBy,
              searchValue: searchBy === "sum_insured" ? sumInsured : premium,
              premium,
              state: "new",
              from: "order",

              premium: order.premium,
              sumInsured: order.sumInsured,
              paymentPeriod: order.paymentPeriod,
              occupation: order.occupation,
              occupationCategory: order.occupationCategory,
              riders: order.riderProducts.map((r) => ({
                id: r.policyId,
                premium: r.premium,
                sumInsured: r.sumInsured,
              })),
            }),
            {
              from: "Orders",
            }
          )
        }
      }
    },
    clickStateButton: (props) => async (item, event) => {
      if (event) {
        event.stopPropagation()
      }
      if (item.dataItem.state) {
        if (item.dataItem.state === "new") {
          props.history.push(path.orderHolderInfoPath(item.dataItem.id), {
            from: "Orders",
            paymentStatus: item.dataItem.paymentStatus,
          })
        } else if (item.dataItem.state === "drafting") {
          const { order } = await props.fetchOrder(item.dataItem.id)
          const gender = get(order, "insuranceHolder.gender", null)
          const searchValue = get(order, "sumInsured", null)
          const birthdate = moment(get(order, "insuranceHolder.birthdate", null)).format(
            "DD/MM/YYYY",
            {
              locale: "en",
            }
          )
          if (get(order, "packageDetail.id", null)) {
            props.history.push(
              path.productPackageEditPath(get(order, "packageDetail.id", null), {
                leadId: item.dataItem.leadId,
                orderId: item.dataItem.id,
                gender,
                searchBy: "sum_insured",
                searchValue,
                birthdate,
                from: "order",

                premium: order.premium,
                sumInsured: order.sumInsured,
                paymentPeriod: order.paymentPeriod,
                occupation: order.occupation,
                occupationCategory: order.occupationCategory,
                riders: order.riderProducts.map((r) => ({
                  id: r.policyId,
                  premium: r.premium,
                  sumInsured: r.sumInsured,
                })),
              }),
              {
                from: "Orders",
              }
            )
          } else {
            props.history.push(
              path.productCustomEditPath(get(order, "insurancePolicyId", null), {
                leadId: item.dataItem.leadId,
                orderId: item.dataItem.id,
                id: get(order, "insurancePolicyId", null),
                gender,
                searchBy: "sum_insured",
                searchValue,
                birthdate,
                from: "order",

                premium: order.premium,
                sumInsured: order.sumInsured,
                paymentPeriod: order.paymentPeriod,
                occupation: order.occupation,
                occupationCategory: order.occupationCategory,
                riders: order.riderProducts.map((r) => ({
                  id: r.policyId,
                  premium: r.premium,
                  sumInsured: r.sumInsured,
                })),
              }),
              {
                from: "Orders",
              }
            )
          }
        } else if (item.dataItem.state === "doc_approved") {
          props.history.push(path.orderActionCodeInfoPath(item.dataItem.id), {
            from: "Orders",
            paymentStatus: item.dataItem.paymentStatus,
          })
        } else if (
          item.dataItem.state === "salesman_rejected" ||
          item.dataItem.state === "rejected"
        ) {
          props.history.push(path.orderHolderInfoPath(item.dataItem.id), {
            from: "Orders",
          })
        } else {
          if (props.currentRole === "member.affiliate") {
            props.history.push(path.orderHolderInfoPath(item.dataItem.id), {
              from: "Orders",
            })
          } else if (props.currentRole === "member.salesman" && item.dataItem.affiliateId === props.saleId) {
            props.history.push(path.orderHolderInfoPath(item.dataItem.id), {
              from: "Orders",
            })
          } else {
            props.history.push(path.orderDocumentInfoPath(item.dataItem.id), {
              from: "Orders",
              paymentStatus: item.dataItem.paymentStatus,
            })
          }
        }
      }
    },
    onShowUploadModal: (props) => () => {
      props.setIsShowUploadModal(true)
    },
    onCloseUploadModal: (props) => () => {
      props.setIsShowUploadModal(false)
    },
    onSubmitImportFile: (props) => async (values) => {
      try {
        props.setLoading(true)
        // import
        await props.importOrder({
          file: values.file,
          template: values.documentType
        })

        // fetch orders
        await props.fetchOrders({
          ...props.filters,
          page: 1,
          perPage: props.paging.perPage,
        })

      } catch (error) {
        console.error('error======', error.message)
        if (error.message === "Submit Validation Failed") {
          alert(error.errors.base)
        } else {
          alert("การนำเข้าไฟล์ล้มเหลว โปรดตรวจสอบอีกครั้ง")
        }
      } finally {
        props.setLoading(false)
      }
    },
  }),
  withHandlers({
    clickRow: (props) => async (item) => {
      // if (item.dataItem.state !== "rejected") {
      props.clickStateButton(item)
      // }
    },
  }),
  withHooks((props) => {
    const [pageLoading, setPageLoading] = useState(false)

    useEffect(() => {
      call(async () => {
        setPageLoading(true)
        await Promise.all([props.fetchOrderConfigs(), props.fetchOrders()])
        setPageLoading(false)
      })
      // eslint-disable-next-line
    }, [])

    return { pageLoading, setPageLoading }
  })
)

export default enhancer(OrderIndex)
