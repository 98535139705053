import React from "react"
import { compose, lifecycle } from "recompose"
import { withRouter, withStores } from "@enhancers"
import styled from "@styled"
import Field from "@components/Field"
import { CurrencyInput, Checkbox, Tooltip } from "@components/index"

const Layout = styled.div``

const Body = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const InflationContainer = styled.div`
  display: flex;
`
const InflationUnit = styled.div`
  width: 20%;
  margin-left: 8px;
  display: flex;
  align-items: center;
`

const PlanningLayout = styled.div`
  width: 100%;
`

const TitleFieldContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 16px;
`

const CurretnText = styled.div`
  width: 17%;
`

const FeatureText = styled.div`
  width: 15%;
`

const FieldContainer = styled.div`
  display: grid;
  width: 100%;
  margin-top: 15px;
  align-items: center;
  grid-template-columns: 21% 79%;
`
const TermPriceContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  > div {
    opacity: ${(props) => (props.disabled ? 0.3 : 1)};
  }

  > input:first-of-type {
    width: 45px;
  }
  > input:nth-of-type(2) {
    width: 50px;
  }
  > input:nth-of-type(3) {
    width: 100px;
  }

  > div:nth-of-type(2) {
    width: 20px;
  }
  > div:nth-of-type(3) {
    width: 45px;
  }

  > div:nth-of-type(4) {
    width: 15px;
  }
  > div:nth-of-type(5) {
    width: 70px;
  }
  > div:nth-of-type(6) {
    width: 50px;
  }
`

const Unit = styled.div`
  white-space: nowrap;
`

const TitleTerm = styled.div`
  white-space: nowrap;
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
`

const level = [
  {
    label: "ประถม",
    info: "6-11 ปี",
  },
  {
    label: "มัธยมต้น",
    info: "12-14 ปี",
  },
  {
    label: "มัธยมปลาย",
    info: "15-17 ปี",
  },
  {
    label: "ปริญญาตรี",
    info: "18-21 ปี",
  },
  {
    label: "ปริญญาโท",
    info: "22-23 ปี",
  },
  {
    label: "ปริญญาเอก",
    info: "24-25 ปี",
  },
]

const AgeInput = styled(CurrencyInput)`
  width: 45px;
`

const TermPricePerYear = styled(CurrencyInput)`
  width: 100px;
`

const ChooseForm = (props) => (
  <Layout>
    <Body>
      <InflationContainer>
        <Field
          component={CurrencyInput}
          caption="ณ อัตราเงินเฟ้อ"
          name="inflationPerYear"
          min={0}
          max={100}
        />
        <InflationUnit>% ต่อปี</InflationUnit>
      </InflationContainer>

      <TitleFieldContainer>
        <CurretnText>ปัจจุบัน</CurretnText>
        <FeatureText>อนาคต</FeatureText>
        <Tooltip position="right" anchorElement="target">
          <span
            class="k-icon k-i-information"
            title="คำนวณเป็นมูลค่าในอนาคต ด้วยอัตราเงินเฟ้อที่กำหนด"
          />
        </Tooltip>
      </TitleFieldContainer>

      <PlanningLayout>
        {level.map((value, index) => (
          <FieldContainer key={index}>
            <Field component={Checkbox} name={`level${index}`} label={level[index].label} />
            <TermPriceContainer disabled={!props.values[`level${index}`]}>
              <Field component={AgeInput} name={`yearStart${index}`} min={0} max={99} />
              <TitleTerm>-</TitleTerm>
              <Field
                component={AgeInput}
                name={`yearEnd${index}`}
                disabled={index < 3}
                min={0}
                max={99}
              />
              <Unit>ปี</Unit>

              <TitleTerm>ปีละ</TitleTerm>
              <Field component={TermPricePerYear} name={`termPricePerYear${index}`} min={0} />
              <TitleTerm>=</TitleTerm>
              <Field component={CurrencyInput} name={`sumTermPricePerYear${index}`} readOnly />
              <Unit>บาท/ปี</Unit>
            </TermPriceContainer>
          </FieldContainer>
        ))}
      </PlanningLayout>
    </Body>
  </Layout>
)

const enhancer = compose(
  withRouter(),
  withStores((stores, props) => ({})),
  lifecycle({})
)

export default enhancer(ChooseForm)
