import React, { Fragment } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { compose, withProps } from "recompose"
import { Grid, GridColumn, makeCell, Dropdown, DatePicker } from "@components"

const Table = styled(Grid)`
  margin-bottom: 50px;

  .k-grid-header {
    height: 50px !important;
    border-radius: 0px 0px 0px 0px !important;
    border-color: #eeeef2 !important;
    background-color: #627b8b !important;
    .k-grid-header-wrap {
      border-radius: 0px 0px 0px 0px !important;
      border-color: #eeeef2 !important;
      background-color: #627b8b !important;

      .k-header {
        background-color: #627b8b !important;
        .k-link {
          display: flex !important;
          height: 50px !important;
          padding-left: 20px !important;
          text-decoration: none !important;
          background-color: #627b8b !important;
          color: white !important;
        }
      }
    }
  }

  .k-grid-container {
    .k-grid-content.k-virtual-content {
      > div {
        .k-grid-table {
          pointer-events: none !important;
          > tbody {
            .k-master-row {
              > td {
                padding-left: 20px !important;
                cursor: pointer;
              }
            }
            .wrap-text {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
`
const SectionHeader = styled.span`
  font-size: 16px;
  color: #0275d8;
  font-weight: bold;
  margin-bottom: 20px;
  font-family: Sarabun, sans-serif;
  margin-left: 20px;

  ${(props) =>
    props.readOnly &&
    css`
      height: 40px;
      line-height: 2.5;
      color: white;
      background-color: #294558;
      padding-left: 16px;
    `};
`

const BeneficiaryReadOnlyTable = (props) => (
  <Fragment>
    <SectionHeader>ข้อมูลผู้รับผลประโยชน์</SectionHeader>
    <Table data={props.data}>
      <GridColumn field="beneficiaryName" title="ชื่อผู้รับผลประโยชน์" width="165px" />
      <GridColumn
        field="gender"
        title="เพศ"
        width="60px"
        cell={makeCell(Dropdown, (cellProps) => ({
          options: props.genderOptions,
          readOnly: true,
          value: cellProps.dataItem.gender,
        }))}
      />
      <GridColumn
        field="birthdate"
        title="วันเกิด"
        width="110px"
        cell={makeCell(DatePicker, (props) => ({
          readOnly: true,
          value: props.dataItem.birthdate,
        }))}
      />
      <GridColumn
        field="relationship"
        title="ความสัมพันธ์"
        width="125px"
        cell={makeCell(Dropdown, (cellProps) => ({
          options: props.relationshipOptions,
          readOnly: true,
          value: cellProps.dataItem.relationship,
        }))}
        filterable={false}
      />
      <GridColumn field="percent" title="ร้อยละ" filterable={false} width="214px" />
    </Table>
  </Fragment>
)

const enhance = compose(
  withProps((props) => {
    const data = props.data

    data.forEach((row) => {
      row.beneficiaryName = `${row.nameInfo.firstName || ""} ${row.nameInfo.lastName || ""}`
    })

    return { data }
  })
)

export default enhance(BeneficiaryReadOnlyTable)
