import React from "react"
import styled from "@styled"
import { Field, Dropdown, Input, DatePicker } from "@components"
import { compose } from "recompose"
import { withStores } from "@enhancers/index"

export const Row = styled.div`
  display: flex;
  margin-top: 12px;

  &:nth-of-type(1) {
    margin-top: 0;
  }

  > div {
    margin-left: 16px;
    &:nth-of-type(1) {
      margin-left: 0;
    }

    > div {
      > div {
        font-weight: normal;
      }
    }
  }
`

const FilterPanel = (props) => (
  <div>
    <Row>
      <Field
        component={Dropdown}
        name="insuranceCompanyId"
        caption="บริษัทประกัน"
        captionStyle={{ fontWeight: "normal" }}
        options={props.insuranceCompanyOptions}
        leftWidth="100"
        rightWidth="250"
        disabled={props.disabled}
        placeholder="กรุณาเลือก"
      />
      <Field
        component={Dropdown}
        name="policySaleType"
        caption="ประเภท"
        captionStyle={{ fontWeight: "normal" }}
        options={props.policySaleTypeOptions}
        leftWidth="80"
        rightWidth="170"
        disabled={props.disabled}
        placeholder="กรุณาเลือก"
      />
    </Row>
    <Row>
      <Field
        component={Input}
        name="tqmOrderId"
        caption="รหัสคำสั่งซื้อ"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="100"
        rightWidth="140"
        placeholder="กรุณาระบุ"
        disabled={props.disabled}
      />
      <Field
        component={Input}
        name="customerName"
        caption="ชื่อลูกค้า"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="76"
        rightWidth="283"
        placeholder="กรุณาระบุ"
        disabled={props.disabled}
      />
    </Row>
    <Row>
      <Field
        component={Dropdown}
        name="state"
        caption="สถานะ"
        captionStyle={{ fontWeight: "normal" }}
        options={props.stateOptions}
        leftWidth="125"
        rightWidth="173"
        placeholder="กรุณาเลือก"
        disabled={props.disabled}
      />
      <Field
        component={Dropdown}
        name="editingStatus"
        caption="สถานะการแก้ไข"
        captionStyle={{ fontWeight: "normal" }}
        options={props.editingStatusOptions}
        leftWidth="125"
        rightWidth="173"
        placeholder="กรุณาเลือก"
        disabled={props.disabled}
      />
    </Row>
    <Row>
      <Field
        component={DatePicker}
        name="coverageDate.min"
        caption="ช่วงวันที่คุ้มครอง"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="125"
        rightWidth="155"
        disabled={props.disabled}
        placeholder="กรุณาเลือก"
        customValidate={true}
        showErrorMessage={true}
      />
      <Field
        component={DatePicker}
        name="coverageDate.max"
        caption="ถึง"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="35"
        rightWidth="138"
        disabled={props.disabled}
        placeholder="กรุณาเลือก"
        customValidate={true}
        showErrorMessage={true}
      />
    </Row>
    <Row>
      <Field
        component={DatePicker}
        name="premiumPaidDate.min"
        caption="ช่วงวันชำระเงิน"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="125"
        rightWidth="155"
        disabled={props.disabled}
        placeholder="กรุณาเลือก"
        customValidate={true}
        showErrorMessage={true}
      />
      <Field
        component={DatePicker}
        name="premiumPaidDate.max"
        caption="ถึง"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="35"
        rightWidth="138"
        disabled={props.disabled}
        placeholder="กรุณาเลือก"
        customValidate={true}
        showErrorMessage={true}
      />
    </Row>
    <Row>
      <Field
        component={DatePicker}
        name="receivedDate.min"
        caption="ช่วงวันที่รับเงิน"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="125"
        rightWidth="155"
        disabled={props.disabled}
        placeholder="กรุณาเลือก"
        customValidate={true}
        showErrorMessage={true}
      />
      <Field
        component={DatePicker}
        name="receivedDate.max"
        caption="ถึง"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="35"
        rightWidth="138"
        disabled={props.disabled}
        placeholder="กรุณาเลือก"
        customValidate={true}
        showErrorMessage={true}
      />
    </Row>
    <Row>
      <Field
        component={DatePicker}
        name="policyApprovedDate.min"
        caption="ช่วงวันอนุมัติกรมธรรม์"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="277"
        rightWidth="155"
        disabled={props.disabled}
        placeholder="กรุณาเลือก"
        showErrorMessage={true}
      />
      <Field
        component={DatePicker}
        name="policyApprovedDate.max"
        caption="ถึง"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="35"
        rightWidth="138"
        disabled={props.disabled}
        placeholder="กรุณาเลือก"
        customValidate={true}
        showErrorMessage={true}
      />
    </Row>
    <Row>
      <Field
        component={DatePicker}
        name="estimatedIncomeExportedDate.min"
        caption="ช่วงวันที่นำออกประมาณการรายได้"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="277"
        rightWidth="155"
        disabled={props.disabled}
        placeholder="กรุณาเลือก"
        showErrorMessage={true}
      />
      <Field
        component={DatePicker}
        name="estimatedIncomeExportedDate.max"
        caption="ถึง"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="35"
        rightWidth="138"
        disabled={props.disabled}
        placeholder="กรุณาเลือก"
        customValidate={true}
        showErrorMessage={true}
      />
    </Row>
    <Row>
      <Field
        component={DatePicker}
        name="receivedIncomeExportedDate.min"
        caption="ช่วงวันที่นำออกรายได้รับ"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="277"
        rightWidth="155"
        disabled={props.disabled}
        placeholder="กรุณาเลือก"
        showErrorMessage={true}
      />
      <Field
        component={DatePicker}
        name="receivedIncomeExportedDate.max"
        caption="ถึง"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="35"
        rightWidth="138"
        disabled={props.disabled}
        placeholder="กรุณาเลือก"
        customValidate={true}
        showErrorMessage={true}
      />
    </Row>
    <Row>
      <Field
        component={DatePicker}
        name="remainingIncomeExportedDate.min"
        caption="ช่วงวันที่นำออกรายได้ค้างรับ"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="277"
        rightWidth="155"
        disabled={props.disabled}
        placeholder="กรุณาเลือก"
        showErrorMessage={true}
      />
      <Field
        component={DatePicker}
        name="remainingIncomeExportedDate.max"
        caption="ถึง"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="35"
        rightWidth="138"
        disabled={props.disabled}
        placeholder="กรุณาเลือก"
        customValidate={true}
        showErrorMessage={true}
      />
    </Row>
  </div>
)

const enhancer = compose(
  withStores((stores) => ({
    fetchInsurerCommission: stores.insurerCommissionStore.fetch,
    fetchConfigs: stores.insurerCommissionStore.fetchConfigs,
    insuranceCompanyOptions: stores.insurerCommissionStore.$(
      "configs.filters.insuranceCompany.options",
      []
    ),
    //Note: WorkAround
    policySaleTypeOptions: stores.insurerCommissionStore.$(
      "configs.attributes.policySaleType.options",
      []
    ),
    companyTypeOptions: stores.insurerCommissionStore.$("configs.filters.companyType.options", []),
    stateOptions: stores.insurerCommissionStore.$("configs.attributes.state.options", []),
    editingStatusOptions: stores.insurerCommissionStore.$(
      "configs.attributes.editingStatus.options",
      []
    ),
  }))
)

export default enhancer(FilterPanel)
