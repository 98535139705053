import React from "react"
import styled from "@emotion/styled"
import { compose, withHandlers, lifecycle, withState, withProps } from "recompose"
import { withStores, withForm, withRouter } from "@enhancers"
import * as path from "@common/path"
import {
  Button,
  makeCell,
  Grid as Table,
  GridColumn,
  DatePicker,
  CurrencyInput,
  FilterButton,
} from "@components"
import { exceptNullKeys } from "@common/helper"

import Loading from "@pages/index/Loading"
import Filter from "./Filter"

const Layout = styled.div`
  margin: 0px auto 25px auto;
`
const Header = styled.div`
  display: flex;
  margin: 20px 0 12px 0;
  flex-direction: ${(props) => (props.direction === "true" ? "column" : "row")};
  flex-wrap: wrap;
`
const Left = styled.div``
const Right = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  > div {
    margin-left: 6px;
  }
`
const CustomButton = styled(Button)`
  width: 96px;
`

const Package = (props) => (
  <React.Fragment>
    {props.loading ? (
      <Loading />
    ) : (
      <Layout>
        <Header direction={`${props.filterExpanded}`}>
          <Left>
            <FilterButton
              expanded={props.filterExpanded}
              onExpand={props.onExpandFilter}
              disabled={props.disabledFilter}
            >
              <Filter values={props.values} />
            </FilterButton>
          </Left>
          <Right>
            <CustomButton awesomeIcon="plus" onClick={props.createPackage} children="สร้าง" />
          </Right>
        </Header>
        <Table
          data={props.data}
          onRowClick={props.onRowClick}
          pageable
          skip={props.paging.skip}
          take={props.paging.perPage}
          total={props.paging.totalCount}
          onPageChange={props.onPageChange}
          loading={props.tableLoading}
        >
          <GridColumn title="ชื่อแพ็กเกจ" field="name" width="390px" />
          <GridColumn title="ชื่อบริษัท" field="companyName" width="200px" />
          <GridColumn
            title="ทุนประกันรวม"
            field="totalSumInsured"
            cell={makeCell(CurrencyInput, (props) => ({
              readOnly: true,
              value: props.dataItem.totalSumInsured,
            }))}
            width="120px"
          />
          <GridColumn
            title="วันที่สร้าง"
            cell={makeCell(DatePicker, (props) => ({
              readOnly: true,
              value: props.dataItem.createdAt,
            }))}
            width="100px"
          />
          <GridColumn
            title="วันที่ออนไลน์"
            cell={makeCell(DatePicker, (props) => ({
              readOnly: true,
              value: props.dataItem.onlineAt,
            }))}
            width="100px"
          />
          <GridColumn
            title="สถานะ"
            field="state"
            width="80px"
            cell={(cell) => {
              const state = props.stateOptions.find(
                ({ label, value }) => cell.dataItem.state === value
              )
              return <td className={`packageState-${cell.tdId}`}>{state && state.label}</td>
            }}
          />
        </Table>
      </Layout>
    )}
  </React.Fragment>
)

const enhancer = compose(
  withState("isShowModal", "setIsShowModal", false),
  withState("loading", "setLoading", false),
  withState("tableLoading", "setTableLoading", false),
  withState("filterExpanded", "setFilterExpanded", true),
  withStores((stores, props) => ({
    data: stores.packageStore.packages,
    fetchData: stores.packageStore.fetch,
    paging: stores.packageStore.paging,
    fetchConfigFilter: stores.packageStore.fetchConfigFilter,
    stateOptions: stores.packageStore.$("configs.attributes.state.options", [])
  })),
  withRouter(),
  withProps((props) => {
    const { query } = props.location || {}
    return {
      paging: {
        ...props.paging,
        skip: (props.paging.page - 1) * props.paging.perPage,
      },
      initialValues: {
        insuranceCompanyId: !isNaN(parseInt(query.insuranceCompanyId))
          ? parseInt(query.insuranceCompanyId)
          : "",
      },
    }
  }),
  withForm({
    form: "FilterForm",
    onChange: async (filters, _, props) => {
      props.setTableLoading(true)
      filters = exceptNullKeys(filters, "insuranceCompanyId")
      props.history.push(path.packagesPath(filters))
      await props.fetchData(filters)
      props.setTableLoading(false)
    },
  }),
  withHandlers({
    onRowClick: (props) => (event) => {
      props.history.push(path.packagePath(event.dataItem.id))
    },
    onPageChange: (props) => async (event) => {
      const filters = exceptNullKeys(props.values, "insuranceCompanyId")
      const page = event.page.skip / event.page.take + 1
      await props.fetchData({ ...filters, page })
    },
    createPackage: (props) => () => {
      props.history.push(path.newPackagePath())
    },
    onExpandFilter: (props) => (expanded) => {
      props.setFilterExpanded(expanded)
      if (!expanded) {
        props.reset()
      }
    },
  }),
  lifecycle({
    async componentDidMount() {
      this.props.setLoading(true)
      await Promise.all([this.props.fetchConfigFilter()])
      this.props.setLoading(false)
    },
  })
)

export default enhancer(Package)
