import React from "react"
import styled from "@emotion/styled"
import { compose, withHandlers, lifecycle, withProps, withState } from "recompose"
import { withStores, withForm } from "@enhancers"
import {
  Button,
  Grid as Table,
  GridColumn,
  makeCell,
  InputName,
  FilterButton,
  Dropdown,
  LoadingScope,
  PhoneNumberInput,
} from "@components"
import * as path from "@common/path"
import Filter from "./Filter"
import AlertModal from "./AlertModal"
import { exceptNullKeys } from "@common/helper"
import UploadDocumentModal from "./UploadDocumentModal"

const Layout = styled.div`
  margin: 0px auto 25px auto;
`
const Header = styled.div`
  display: flex;
  margin: 20px 0 12px 0;
  flex-direction: ${(props) => (props.direction === "true" ? "column" : "row")};
  flex-wrap: wrap;
`
const Left = styled.div``
const Right = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  > div {
    margin-left: 6px;
  }
`
const CustomButton = styled(Button)`
  width: 96px;
`
const ImportFileButton = styled(Button)`
  margin-right: 10px;
`

const TqmMemberIndex = (props) => (
  <React.Fragment>
    {props.loading ? (
      <LoadingScope />
    ) : (
      <Layout>
        <Header direction={`${props.filterExpanded}`}>
          <Left>
            <FilterButton
              expanded={props.filterExpanded}
              onExpand={props.onExpandFilter}
              disabled={props.disabledFilter}
            >
              <Filter values={props.values} />
            </FilterButton>
          </Left>
          {props.currentRole !== "member.accountant" &&
            props.currentRole !== "member.accountant_approver" && (
              <Right>
                <ImportFileButton
                  awesomeIcon="upload"
                  onClick={props.onShowUploadModal}
                  children="นำเข้าไฟล์"
                />
                <CustomButton awesomeIcon="plus" onClick={props.onCreate} children="สร้าง" />
              </Right>
            )}
        </Header>
        <Table
          loading={props.loadingTable}
          data={props.data}
          pageable
          skip={props.paging.skip}
          take={props.paging.perPage}
          total={props.paging.totalCount}
          onPageChange={props.onPageChange}
          onRowClick={props.onRowClick}
          footer={`ทั้งหมด ${props.paging.totalCount} รายการ`}
        >
          <GridColumn title="รหัสพนักงาน" field="memberInfo.staffCode" width="100px" />
          <GridColumn
            title="ชื่อภาษาไทย"
            cell={makeCell(InputName, (cellProps) => ({
              readOnly: true,
              value: cellProps.dataItem.nameThInfo,
            }))}
            width="130px"
            filterable={false}
          />
          <GridColumn
            title="ชื่อภาษาอังกฤษ"
            cell={makeCell(InputName, (cellProps) => ({
              readOnly: true,
              value: cellProps.dataItem.nameEnInfo,
            }))}
            width="130px"
            filterable={false}
          />
          <GridColumn
            title="เบอร์"
            field="phoneNumber"
            cell={makeCell(PhoneNumberInput, (cellProps) => ({
              readOnly: true,
              value: cellProps.dataItem.phoneNumber,
            }))}
            width="100px"
          />
          <GridColumn
            title="ทีม"
            cell={makeCell(Dropdown, (cellProps) => ({
              readOnly: true,
              value: cellProps.dataItem.memberInfo.teamId,
              options: props.teamOptions,
            }))}
            width="140px"
          />
          <GridColumn
            title="ตำแหน่งในระบบ"
            cell={makeCell(Dropdown, (cellProps) => ({
              readOnly: true,
              value: cellProps.dataItem.memberInfo.memberType,
              options: props.memberTypeOptions,
            }))}
            width="128px"
          />
          <GridColumn title="ตำแหน่งงาน" field="memberInfo.position" width="100px" />
          <GridColumn
            title="สถานะ"
            cell={makeCell(Dropdown, (cellProps) => ({
              readOnly: true,
              value: cellProps.dataItem.memberInfo.memberStatus,
              options: props.memberStatusOptions,
            }))}
            width="140px"
          />
        </Table>
        <AlertModal
          title={props.title}
          message={props.message}
          show={props.isShowAlertModal}
          onOk={props.closeAlertModal}
          onCancel={props.closeAlertModal}
        />
        <UploadDocumentModal
          open={props.isShowUploadModal}
          onClose={props.onCloseUploadModal}
          title="นำเข้าไฟล์"
          onAfterAddSubmit={props.onAfterAddDocSubmit}
        />
      </Layout>
    )}
  </React.Fragment>
)

const enhancer = compose(
  withState("loading", "setLoading", false),
  withState("loadingTable", "setLoadingTable", false),
  withState("filterExpanded", "setFilterExpanded", true),
  withState("isShowAlertModal", "setIsShowAlertModal", false),
  withState("title", "setTitle", "นำเข้าไฟล์"),
  withState("isShowUploadModal", "setIsShowUploadModal", false),
  withState("message", "setMessage"),
  withStores((stores) => ({
    fetch: stores.tqmUserStore.fetch,
    data: stores.tqmUserStore.all,
    importUsers: stores.tqmUserStore.importTqmUser,
    importMarketPlans: stores.tqmUserStore.importMarketPlan,
    importAffiliate: stores.tqmUserStore.importAffiliate,
    paging: stores.tqmUserStore.paging,
    fetchConfig: stores.tqmUserStore.fetchConfig,
    memberTypeOptions: stores.tqmUserStore.$("configs.attributes.memberType.options", []),
    teamOptions: stores.tqmUserStore.$("configs.attributes.team.options", []),
    memberStatusOptions: stores.tqmUserStore.$("configs.attributes.memberStatus.options", []),
    currentRole: stores.appStore.currentRole,
  })),
  withState("filterParams", "setFilterParams", null),
  withForm({
    form: "FilterForm",
    onChange: (filters, _, props) => {
      if (props.filterParams) {
        clearTimeout(props.filterParams)
      }

      const exceptNullParams = exceptNullKeys(
        filters,
        "name",
        "staffCode",
        "teamId",
        "memberType",
        "memberStatus",
        "position",
      )

      const filterParams = setTimeout(async () => {
        props.setLoadingTable(true)
        await setTimeout(() => props.fetch({ ...exceptNullParams, page: 1, perPage: 10 }))
        props.setLoadingTable(false)
      }, 600)

      props.setFilterParams(filterParams)
    },
  }),
  withProps((props) => {
    return {
      paging: {
        ...props.paging,
        skip: (props.paging.page - 1) * props.paging.perPage,
      },
    }
  }),
  withHandlers({
    onCreate: (props) => () => {
      props.history.push(path.newTqmMembersPath())
    },
    onRowClick: (props) => (event) => {
      props.history.push(path.tqmMemberPath(event.dataItem.id))
    },
    onPageChange: (props) => async (event) => {
      const page = event.page.skip / event.page.take + 1
      props.setLoadingTable(true)
      await props.fetch({ ...props.values, page, perPage: 10 })
      props.setLoadingTable(false)
    },
    onExpandFilter: (props) => (expanded) => {
      props.setFilterExpanded(expanded)
      if (!expanded) {
        props.reset()
      }
    },
    closeAlertModal: (props) => () => {
      props.setIsShowAlertModal(false)
    },
    onShowUploadModal: (props) => () => {
      props.setIsShowUploadModal(true)
    },
    onCloseUploadModal: (props) => () => {
      props.setIsShowUploadModal(false)
    },
    onAfterAddDocSubmit: (props) => async (values) => {
      if (values.documentType === "salesman") {
        try {
          await props.importUsers({
            userFile: values.file,
            salesGroup: values.salesGroup,
          })
          await props.fetch({ page: 1, perPage: 10 })

          props.setTitle("นำเข้าไฟล์สำเร็จ")
          props.setMessage("การนำเข้าไฟล์สำเร็จ")
          props.setIsShowAlertModal(true)
        } catch (e) {
          if (e.message === "Submit Validation Failed") {
            alert(e.errors.base)
          } else {
            alert("การนำเข้าไฟล์ล้มเหลว โปรดตรวจสอบอีกครั้ง")
          }
        }
      } else if (values.documentType === "marketPlan") {
        try {
          await props.importMarketPlans({
            file: values.file,
            mkPlanId: values.mkPlanId,
          })

          props.setTitle("นำเข้าไฟล์สำเร็จ")
          props.setMessage("การนำเข้าไฟล์สำเร็จ")
          props.setIsShowAlertModal(true)
        } catch (e) {
          if (e.message === "Submit Validation Failed") {
            alert(e.errors.base)
          } else if (e.response.status === 404) {
            alert(e.response.data.message)
          } else {
            alert("การนำเข้าไฟล์ล้มเหลว โปรดตรวจสอบอีกครั้ง")
          }
        }
      } else if (values.documentType === "affiliates") {
        try {
          await props.importAffiliate({
            file: values.file,
          })
          await props.fetch({ page: 1, perPage: 10 })

          props.setTitle("นำเข้าไฟล์สำเร็จ")
          props.setMessage("การนำเข้าไฟล์สำเร็จ")
          props.setIsShowAlertModal(true)
        } catch (e) {
          if (e.message === "Submit Validation Failed") {
            alert(e.errors.base)
          } else if (e.response.status === 404) {
            alert(e.response.data.message)
          } else {
            alert("การนำเข้าไฟล์ล้มเหลว โปรดตรวจสอบอีกครั้ง")
          }
        }
      }
    },
  }),
  lifecycle({
    async componentDidMount() {
      this.props.setLoading(true)
      await Promise.all([this.props.fetch({ page: 1, perPage: 10 }), this.props.fetchConfig()])
      this.props.setLoading(false)
    },
  })
)

export default enhancer(TqmMemberIndex)
