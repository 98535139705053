import React from "react"
import styled from "@emotion/styled"
import { compose, withHandlers, withProps } from "recompose"
import { withForm, withStores, withRouter } from "@enhancers/index"
import { Button, Field, CurrencyInput, Dropdown, GenderInput, DatePicker } from "@components"
import { PanelBarItem } from "@progress/kendo-react-layout"
import * as path from "@common/path"
import { toCurrency, convertNaNtoZero, convertMinustoZero } from "@common/helper"

import {
  CustomPanelBar,
  Body,
  Layout,
  BoldText,
  Footer,
  ResultContainer,
  TextResult,
  ValueResult,
  AgeAndGenderLayout,
} from "../DebtProtectionForm/AdvanceForm"
import { WrapInputDate, AgeLabel } from "../DebtProtectionForm/SimpleForm"
import moment from "moment"

const ConfirmButton = styled(Button)`
  display: flex;
  margin: auto;
  width: fit-content;
  margin-top: 16px;
`

const Unit = styled.div``

const AgeUnit = styled.div`
  width: 29px;
`

const UnitWrap = styled.div`
  word-wrap: break-word;
  font-size: 12px;
  margin-top: 6px !important;
  width: 69px;
`

const Planning = (props) => (
  <Layout>
    <Body>
      <AgeAndGenderLayout>
        <Field
          component={GenderInput}
          name="gender"
          caption="เพศ"
          rightWidth="200"
          placeholder="กรุณาระบุ"
          validate="required"
        />
        <AgeUnit />
        <WrapInputDate>
          {props.values.birthdate && (
            <AgeLabel show={props.values.birthdate !== null}>
              (อายุ {moment().diff(moment(props.values.birthdate), "years")} ปี)
            </AgeLabel>
          )}
          <Field
            caption="วันเกิด"
            name="birthdate"
            component={DatePicker}
            placeholder="กรุณาระบุ"
            validate="required"
            rightWidth="200"
            leftWidth="225"
            max={new Date()}
          />
        </WrapInputDate>
        <AgeUnit />
      </AgeAndGenderLayout>
      <CustomPanelBar>
        <PanelBarItem expanded={true} title="สมมติฐาน">
          <Field
            component={CurrencyInput}
            name="inflationRate"
            caption="ณ อัตราเงินเฟ้อ"
            rightWidth="200"
            placeholder="กรุณาระบุ"
            min={0}
            max={100}
          />
          <Unit>% ต่อปี</Unit>
          <Field
            component={CurrencyInput}
            name="expectedSaving"
            caption="จำนวนเงินที่ต้องการเก็บออม"
            rightWidth="200"
            placeholder="กรุณาระบุ"
            min={0}
          />
          <Unit>บาท</Unit>
          <Field
            component={CurrencyInput}
            name="spendTime"
            caption="จะใช้เงินออมนี้ในอีก"
            rightWidth="200"
            placeholder="กรุณาระบุ"
            min={0}
            max={100}
          />
          <Unit>ปีข้างหน้า</Unit>
          <Field
            component={Dropdown}
            name="getMoneyWhenDead"
            caption="ถ้าตายวันนี้
            อยากได้เงินออมดังกล่าวทันทีเลยหรือไม่"
            rightWidth="200"
            options={props.yesNoOptions}
            placeholder="กรุณาระบุ"
          />
          <Unit />
          <Field
            component={Dropdown}
            name="getRebate"
            caption="ต้องการเงินคืนระหว่างทาง"
            rightWidth="200"
            options={props.yesNoOptions}
            placeholder="กรุณาระบุ"
          />
          <Unit />
          <Field
            component={CurrencyInput}
            name="rebateMoney"
            caption="จำนวนเงินคืนระหว่างทางที่ต้องการ"
            rightWidth="200"
            placeholder="กรุณาระบุ"
            min={0}
            hidden={!props.values.getRebate}
          />
          {props.values.getRebate ? <UnitWrap>ภายในอีก 15 ปีข้างหน้า</UnitWrap> : ""}
        </PanelBarItem>
      </CustomPanelBar>
    </Body>
    <Footer>
      <ResultContainer>
        <TextResult>
          คุณสามารถใช้แบบประกันประเภท <BoldText>สะสมทรัพย์</BoldText>
          ในการสร้างความคุ้มครองนี้โดยทุนประกันเริ่มต้นจะอยู่ในช่วง
        </TextResult>
        <ValueResult>
          {props.values.getMoneyWhenDead
            ? toCurrency(convertMinustoZero(props.values.sumInsured)) + " บาท"
            : "ไม่กำหนด"}
        </ValueResult>
      </ResultContainer>

      <ResultContainer>
        <TextResult>รวมเงินคืนที่ต้องการทั้งหมด</TextResult>
        <ValueResult>{toCurrency(convertMinustoZero(props.values.totalSummary))} บาท</ValueResult>
      </ResultContainer>

      <ConfirmButton
        icon="arrow-right"
        onClick={props.onSubmit}
        disabled={props.values.totalSummary < 0}
      >
        ถัดไป
      </ConfirmButton>
    </Footer>
  </Layout>
)

const enhancer = compose(
  withRouter({
    queryTypes: {
      birthdate: "date",
    },
  }),
  withProps((props) => ({
    yesNoOptions: [
      { label: "ใช่", value: true },
      { label: "ไม่ใช่", value: false },
    ],
  })),
  withStores((stores, props) => ({
    fetchById: stores.leadStore.fetchById,
    initialValues: {
      birthdate: props.location.query.birthdate,
      gender: props.location.query.gender,
      getMoneyWhenDead: true,
      getRebate: true,
    },
  })),
  withForm({
    form: "planningSavingForm",
    enableReinitialize: true,
    onChange: (values, _, props) => {
      let inflationRateCal = convertNaNtoZero(values.inflationRate)
      let expectedSavingCal = convertNaNtoZero(values.expectedSaving)
      let spendTimeCal = convertNaNtoZero(values.spendTime)
      let rebateMoneyCal = convertNaNtoZero(values.rebateMoney)

      let summary = convertNaNtoZero(
        expectedSavingCal * Math.pow(1 + inflationRateCal / 100, spendTimeCal)
      )
      let totalSummary = convertNaNtoZero(values.getRebate ? summary + rebateMoneyCal : summary)

      values.sumInsured = convertNaNtoZero(
        values.getMoneyWhenDead ? Math.round(expectedSavingCal) : 0
      )
      values.totalSummary = convertNaNtoZero(Math.round(totalSummary))
    },
  }),
  withHandlers({
    onSubmit: (props) =>
      props.handleSubmit(async (values) => {
        let totalSummary = convertNaNtoZero(values.totalSummary)

        const { query } = props.location || {}
        const { leadId } = query || {}

        props.history.push(
          path.productsPath(leadId, {
            ...props.location.query,
            birthdate: values.birthdate,
            gender: values.gender,
            searchValue: totalSummary,
            type: "whole_life",
            searchBy: "sum_insured",
          })
        )
      }),
  })
)
export default enhancer(Planning)
