import React from "react"
import styled from "@emotion/styled"
import { compose, withHandlers, withState, withProps } from "recompose"
import { Dropdown, Authorize } from "@components"
import { withRouter, withStores } from "@enhancers/index"
import VerifyModal from "../VerifyModal"

const LayoutState = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > div {
    font-size: 16px;
    font-family: Sarabun, sans-serif;
    color: #666666;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 104px;

    .k-dropdown-wrap {
      max-width: 104px;
    }
  }
`

const ComponentState = (props) => (
  <Authorize roles={["user.admin", "member.biz_dev", "member.salesman"]}>
    {({ authorized }) => (
      <LayoutState>
        <Dropdown options={props.stateOptions} value={props.dataItem.state} readOnly />
        <VerifyModal
          open={props.isShowVerifyModal}
          onClose={props.onCloseVerifyModal}
          onAfterReject={props.onAfterReject}
          onAfterApprove={props.onAfterApprove}
          state={props.value}
        />
      </LayoutState>
    )}
  </Authorize>
)
const enhancer = compose(
  withRouter(),
  withStores((stores, props) => ({
    approveOrderDocumentByDocumentId: stores.orderStore.approveOrderDocumentByDocumentId,
    rejectOrderDocumentByDocumentId: stores.orderStore.rejectOrderDocumentByDocumentId,
    orderId: props.match.params.orderId,
  })),
  withState("value", "setValue", null),
  withState("isShowVerifyModal", "setIsShowVerifyModal", false),
  withProps(() => {
    const options = [
      { label: "อนุมัติ", value: "approved" },
      { label: "ไม่อนุมัติ", value: "rejected" },
    ]
    const stateOptions = [
      { label: "รอการนำเข้าไฟล์", value: "new" },
      { label: "นำเข้าไฟล์แล้ว", value: "uploaded" },
      { label: "รอตรวจสอบ", value: "verifying" },
      { label: "อนุมัติ", value: "approved" },
      { label: "ส่งเอกสารแล้ว", value: "submitted" },
    ]
    return {
      options: options,
      stateOptions: stateOptions,
    }
  }),
  withHandlers({
    onCloseVerifyModal: (props) => () => {
      props.setIsShowVerifyModal(false)
    },
    onAfterReject: (props) => async () => {
      await props.rejectOrderDocumentByDocumentId(props.orderId, props.dataItem.id)
    },
    onAfterApprove: (props) => async () => {
      await props.approveOrderDocumentByDocumentId(props.orderId, props.dataItem.id)
    },
    onChange: (props) => (event) => {
      if (event) {
        props.setValue(event)
        props.setIsShowVerifyModal(true)
      }
    },
  })
)

export default enhancer(ComponentState)
