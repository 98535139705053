import { compose, mapProps } from "recompose"

export const Case = (props) => props.children

const Switch = (props) => (props.children ? props.children : null)

const enhancer = compose(
  mapProps((props) => {
    const children = props.children.find((child) => child.props.when !== false)
    return {
      children,
    }
  })
)

export default enhancer(Switch)
