/* eslint-disable no-undef */
import React from "react"
import styled, { css } from "@styled"
import { compose, withState, withHandlers, lifecycle, withProps } from "recompose"
import { SearchBox, Button, TreeView, DatePicker, Field } from "@components"
import { withStores, withForm, withCypress } from "@enhancers/index"
import { get, lowerCase, cloneDeep, filter } from "lodash"
import { formatFullName, safe, enableBodyScrolling } from "@common/helper"

const Layout = styled.div`
  position: fixed;
  top: 0;
  bottom: 40px;
  right: -100%;
  background: white;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e6e6e6;
  z-index: 51;
  min-width: 340px;
  display: flex;
  flex-direction: column;
  transition: all 0.5s;
`
const MainLayout = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 52;
  transition: all 0.5s;
  visibility: hidden;

  ${(props) =>
    props.open &&
    css`
      background: rgba(0, 0, 0, 0.4);
      visibility: visible;

      > div {
        right: 0;
      }
    `}
`
const SearchSale = styled(SearchBox)`
  width: 100%;
`
const CloseButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  width: 30px;

  :hover {
    border: none;
  }
`
const Body = styled.div`
  padding: 85px 15px 0 15px;
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
`
const Footer = styled.div`
  height: 52px;
  box-shadow: 0px 3px 4px #0000000f;
  border: 1px solid #e6e6e6;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
const SubmitButton = styled(Button)`
  border: none;
  width: 100%;
  height: 100%;
  border-radius: 0;
  background-color: #0275d8;
  color: white;
  opacity: 1 !important;

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.5 !important;
      cursor: default;
    `};

  :hover {
    background-color: #ffffff;
    color: #0275d8;
  }
`

const WrapperField = styled.div`
  display: flex;
  margin-bottom: 20px;
  justify-content: space-evenly;
  overflow: hidden;
`
const CustomDatePicker = styled(DatePicker)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 150px;
  > div:first-of-type {
    width: 148px;
  }
  > div:last-of-type {
    width: 148px;
    .k-dateinput-wrap {
      > input {
        padding-right: 10px;
      }
    }
  }
`

const CustomTreeView = styled(TreeView)`
  flex: 1;
  margin-top: 15px;
  margin-bottom: 25px;
`
const SalePanel = (props) => (
  <MainLayout open={props.open} onClick={props.onCancel}>
    <Layout onClick={(e) => e.stopPropagation()} className={`${props.className || ""} SalePanel`}>
      <Body>
        <CloseButton icon="close" onClick={props.onCancel} styletype="delete" />
        <SearchSale onSearch={props.onSearch} placeholder="กรุณาระบุ" />
        <CustomTreeView
          data={props.data}
          expandIcons={true}
          checkboxes={true}
          onChange={props.onChange}
        />
        {props.requiredDate && (
          <WrapperField>
            <Field
              name="startDate"
              component={CustomDatePicker}
              caption="วันเริ่มขาย"
              validate="required"
              placeholder="กรุณาเลือก"
            />
            <Field
              name="expiredDate"
              component={CustomDatePicker}
              caption="วันหมดอายุลีด"
              validate="required"
              placeholder="กรุณาเลือก"
            />
          </WrapperField>
        )}
      </Body>
      <Footer>
        <SubmitButton
          onClick={props.onSubmit}
          disabled={props.submitButtonDisabled}
          children="ยืนยัน"
        />
      </Footer>
    </Layout>
  </MainLayout>
)

const enhancer = compose(
  withCypress("SalePanel", { inspect: true }),
  withForm({ form: "SalePanelForm" }),
  withStores((stores) => ({
    fetchTeamConfigs: stores.leadStore.fetchTeamConfigs,
    currentRole: stores.appStore.currentRole,
    teamId: stores.appStore.$("currentSale.team.id"),
    saleId: stores.appStore.$("currentSale.id"),
  })),
  withState("originalData", "setOriginalData", []),
  withState("data", "setData", []),
  withState("selectedSaleIds", "setSelectedSaleIds", []),
  withState("selectedSaleLeaderIds", "setSelectedSaleLeaderIds", []),
  withState("selectedTeamIds", "setSelectedTeamIds", []),
  withState("selectedSalesName", "setSelectedSalesName", []),
  withProps((props) => ({
    invalid: props.invalid || props.selectedSaleIds.length === 0,
  })),
  withProps((props) => {
    let data = props.data

    if (props.currentRole === "member.team_leader") {
      data = safe(() => data.find((team) => team.id === props.saleId).items, [])
    } else {
      data.forEach((team) => {
        if (team.checked) {
          team.expanded = false
          team.items.forEach((sale) => (sale.checked = false))
        } else {
          team.expanded = true
        }
      })
    }

    const selectedTeamIdsCount = get(props.selectedTeamIds, "length")
    const selectedSaleIdsCount = get(props.selectedSaleIds, "length")
    const selectedSaleLeaderIdsCount = get(props.selectedSaleLeaderIds, "length")
    const selectedLeadIdsCount = get(props.selectedLeadIds, "length")
    const cannotSelectMore =
      selectedTeamIdsCount + selectedSaleIdsCount + selectedSaleLeaderIdsCount >=
      selectedLeadIdsCount

    data.forEach((team) => {
      if (!team.checked) {
        team.disabled = cannotSelectMore
      }
      if (team.items) {
        team.items.forEach((sale) => {
          if (sale.checked) {
            team.disabled = false
          } else {
            sale.disabled = cannotSelectMore
          }
        })
      }
    })

    let requiredDate = true

    if (props.currentRole === "member.team_leader") {
      requiredDate = false
    }

    let valid = true

    valid =
      valid &&
      props.selectedSaleLeaderIds.length +
        props.selectedSaleIds.length +
        props.selectedTeamIds.length >
        0
    if (props.currentRole === "member.lead_admin") {
      valid = valid && !!props.values.startDate
      valid = valid && !!props.values.expiredDate
    }

    return {
      data,
      requiredDate,
      submitButtonDisabled: !valid,
    }
  }),
  withHandlers({
    onSearch: (props) => async (q) => {
      const data = []

      q = lowerCase(q)

      cloneDeep(props.originalData).forEach((team) => {
        const sales = team.items.filter((sale) => lowerCase(sale.text).includes(q))
        if (lowerCase(team.text).includes(q) || sales.length > 0) {
          if (sales.length > 0) {
            data.push({ ...team, items: sales })
          } else {
            data.push({ ...team })
          }
        }
      })

      props.setSelectedSaleIds([])
      props.setData(data)
    },
    onChange: (props) => (data) => {
      const selectedSaleLeaderIds = []
      const selectedSaleIds = []
      const selectedTeamIds = []
      const selectedSalesName = []

      if (props.currentRole === "member.team_leader") {
        data.forEach((sale) => {
          if (sale.checked) {
            if (sale.isLeader) {
              selectedSaleLeaderIds.push(sale.id)
              selectedSalesName.push(sale.text)
            } else {
              selectedSaleIds.push(sale.id)
              selectedSalesName.push(sale.text)
            }
          }
        })
      } else {
        data.forEach((team) => {
          if (team.checked) {
            selectedTeamIds.push(team.teamId)
          } else {
            team.items.forEach((sale) => {
              if (sale.checked) {
                selectedSaleIds.push(sale.id)
                selectedSalesName.push(sale.text)
              }
            })
          }
        })
      }

      props.setSelectedSaleLeaderIds(selectedSaleLeaderIds)
      props.setSelectedSaleIds(selectedSaleIds)
      props.setSelectedTeamIds(selectedTeamIds)
      props.setSelectedSalesName(selectedSalesName)
    },
    onSubmit: (props) => async () => {
      props.onSubmit &&
        (await props.onSubmit(
          props.selectedSaleIds,
          props.selectedSalesName,
          props.selectedTeamIds,
          { ...props.values, salesLeaderIds: props.selectedSaleLeaderIds }
        ))
      props.setSelectedSaleLeaderIds([])
      props.setSelectedSaleIds([])
      props.setSelectedTeamIds([])
      props.setSelectedSalesName([])
      props.setData(cloneDeep(props.originalData))
    },
  }),
  lifecycle({
    async componentDidMount() {
      const teams = await this.props.fetchTeamConfigs()
      const data = teams.map((team) => {
        let sales = []

        if (this.props.currentRole === "member.team_leader") {
          sales = [team.salesLeader, ...team.salesmen]
        } else {
          sales = [...team.salesmen]
        }

        return {
          id: get(team, "salesLeader.id") || undefined,
          text: team.salesLeader
            ? `${team.name} : ${team.salesLeader.staffCode} ${formatFullName(
                team.salesLeader.nameInfo
              )}`
            : team.name,
          items: sales.map((sale) => ({
            id: sale.id,
            text: `${sale.staffCode} ${formatFullName(sale.nameInfo)}`,
            isLeader: sale.isLeader,
          })),
          teamId: get(team, "id"),
        }
      })
      this.props.setOriginalData(cloneDeep(data))
      this.props.setData(cloneDeep(data))

      if (this.props.open) {
        enableBodyScrolling(false)
      }
    },
    componentDidUpdate(prevProps) {
      if (prevProps.open !== this.props.open) {
        enableBodyScrolling(!this.props.open)
      }
    },
    componentWillUnmount() {
      if (this.props.open) {
        enableBodyScrolling(true)
      }
    },
  })
)

export default enhancer(SalePanel)
