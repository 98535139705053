import React from "react"
import { compose, withHandlers } from "recompose"
import { withForm, withStores } from "@enhancers"
// import { Authorize } from "@components/index"
import { AddDocumentModal } from "../../insurers/show/AddDocumentModal"

const AddDocumentOrderModal = (props) => (
  // <Authorize roles={["member.biz_dev"]}>
  //   {({ authorized }) => (
  //     <AddDocumentModal {...props} authorized={authorized} />
  //   )}
  // </Authorize>
  <AddDocumentModal {...props} />
)

const enhancer = compose(
  withStores((stores, props) => ({
    documentTypeOptions: stores.orderStore.$("orderConfigs.attributes.documentType.options", []),
    sourceTypeOptions: stores.orderStore.$(
      "orderDocumentConfigs.attributes.sourceType.options",
      []
    ),
  })),
  withForm(),
  withHandlers({
    onClose: (props) => () => {
      props.reset(props.from)
      props.onClose()
    },
    onSubmit: (props) =>
      props.handleSubmit(async (values) => {
        await props.onAfterAddSubmit(values)
        await props.reset(props.from)
        await props.onClose()
      }),
  })
)
export default enhancer(AddDocumentOrderModal)
