import React from "react"
import { compose, withHandlers, withProps, withState } from "recompose"
import {
  withField,
  withCaption,
  withReadOnly,
  withHidden,
  acceptProps,
  withCypress,
} from "@enhancers/index"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import classnames from "classnames"
import Cleave from "cleave.js/react"
import formatStringByPattern from "format-string-by-pattern"

const ReadOnly = styled.div`
  min-height: 33.5px;
  display: flex;
  align-items: center;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  color: #666666;
  word-break: break-all;
`
const CaptionStyle = css`
  > * :nth-of-type(2) > div {
    padding-top: 6px;
  }
`
const CustomCleave = styled(Cleave)`
  height: 36px;
  border-color: #eeeef2;
  font-size: 16px;
  font-family: Sarabun, sans-serif;
  color: #000;
`

const CreditCardInput = (props) => (
  <CustomCleave
    autoComplete="off"
    {...props}
    numeral={true}
    className={classnames({
      "k-textbox": true,
      [props.className]: props.className,
      "k-state-invalid": props.valid === false,
    })}
    onChange={props.onChange}
    options={{ blocks: [4, 4, 4, 4], delimiter: "-", numericOnly: true }}
  />
)

const enhancer = compose(
  withCypress("Input"),
  withHidden(),
  withField(),
  withCaption({
    className: CaptionStyle,
  }),
  withProps((props) => {
    const className = props.className || "" + props.name
    return { className }
  }),
  withReadOnly((props) => (
    <ReadOnly
      style={{ paddingTop: props.caption ? 6 : 0, ...props.readOnlyStyle }}
      className={props.className}
    >
      {formatStringByPattern("dddd-dddd-dddd-dddd", props.value)}
    </ReadOnly>
  )),
  withState("displayValue", "setDisplayValue", (props) => props.value),
  withHandlers({
    onChange: (props) => (e) => {
      let value = e.target.value ? e.target.value : ""
      let prevValue = value

      props.onChange && props.onChange(value.replace(/-/g, ""))

      if (prevValue !== value) {
        props.setDisplayValue(value)
      }
    },
    onBlur: (props) => (e) => {
      props.setDisplayValue(props.value)
      props.onBlur && props.onBlur(props.value)
    },
  }),
  withProps((props) => {
    let value = ""
    if (props.active) {
      value = props.displayValue
    } else {
      value = props.value || props.defaultValue
    }

    const valid = !props.touched || props.valid

    return { value, valid }
  }),
  acceptProps(
    "value",
    "onChange",
    "onBlur",
    "valid",
    "className",
    "disabled",
    "type",
    "placeholder",
    "name"
  )
)

export default enhancer(CreditCardInput)
