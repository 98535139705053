import React from "react"
import styled from "@emotion/styled"
import { LeftPanel } from "@pages/leads/show/LeadInfo"
import {
  Header,
  TabContainer,
  TabHeader,
  TabHeaderCaption,
  ContainerInfo,
  Row,
  CustomInput,
  Container,
  MainLayout,
} from "@pages/insurer_commissions/show/index"
import { compose, defaultProps, lifecycle } from "recompose"
import { withForm, withStores } from "@enhancers/index"
import { Field, DatePicker, Dropdown, StickyTab } from "@components"
import { CustomCurrencyInput } from "@pages/insurer_commissions/show/CommissionTabDetail"
import { get, isEqual } from "lodash"

const CustomDropdown = styled(Dropdown)`
  .k-widget.k-combobox {
    width: 133px !important;
  }
`
const CustomMainLayout = styled(MainLayout)`
  position: relative !important;
  margin-top: -30px !important;
`

const CustomStickyTab = styled(StickyTab)`
  #stickyTab {
    width: 100%;
  }
`

const CommissionInfo = (props) => {
  return (
    <CustomMainLayout>
      <CustomStickyTab
        summary={props.totalSalesCommission}
        tabMessage="ค่าคอมมิชชั่นรวม"
        sticky={true}
      />

      <Header>
        <LeftPanel>คอมมิชชั่น</LeftPanel>
      </Header>

      <TabContainer>
        <TabHeader>
          <TabHeaderCaption>ข้อมูลคำสั่งซื้อ</TabHeaderCaption>
        </TabHeader>
        <ContainerInfo>
          <Row>
            <Field
              name="tqmOrderId"
              caption="รหัสคำสั่งซื้อ"
              component={CustomInput}
              readOnly={props.defaultReadOnly}
              leftWidth="150"
              rightWidth="188"
            />
            <Field
              name="submittedAt"
              caption="วันที่แจ้งงาน"
              component={DatePicker}
              readOnly={props.defaultReadOnly}
              hidden={props.hidden}
              leftWidth="157"
            />
          </Row>
          <Row>
            <Field
              name="fullName"
              caption="ชื่อลูกค้า"
              component={CustomInput}
              readOnly={props.defaultReadOnly}
              leftWidth="150"
              rightWidth="188"
            />
            <Field
              name="state"
              caption="สถานะ"
              component={CustomInput}
              readOnly={props.defaultReadOnly}
              hidden={props.hidden}
              leftWidth="157"
              rightWidth="200"
            />
          </Row>
          <Row>
            <Field
              name="policyName"
              caption="แบบประกัน"
              component={CustomInput}
              readOnly={props.defaultReadOnly}
              leftWidth="150"
              rightWidth="188"
            />
            <Field
              name="productDetail.insuranceCompanyName"
              caption="บริษัทประกัน"
              component={CustomInput}
              readOnly={props.defaultReadOnly}
              hidden={props.hidden}
              leftWidth="157"
              rightWidth="120"
            />
          </Row>
          <Row>
            <Field
              name="coverageDate"
              caption="วันที่คุ้มครอง"
              component={DatePicker}
              readOnly={props.defaultReadOnly}
              hidden={props.hidden}
              leftWidth="150"
              rightWidth="188"
            />
          </Row>
        </ContainerInfo>

        <TabHeader>
          <TabHeaderCaption>ข้อมูลคอมมิชชั่น</TabHeaderCaption>
        </TabHeader>

        <Container>
          <Row>
            <Field
              name="paymentInstallment"
              caption="ประจำปี/งวด"
              component={CustomDropdown}
              options={props.salesCommissionMasterOptions}
              leftWidth="150"
              rightWidth="188"
            />
            <Field
              name="commissionAmount"
              caption="ค่าคอมมิชชั่นจ่าย"
              component={CustomCurrencyInput}
              readOnly={props.defaultReadOnly}
              leftWidth="157"
              unit="บาท"
              customStyles={{
                color: "#666",
                fontFamily: "Sarabun, sans-serif",
                fontSize: "20px",
                fontWeight: "bold",
                alignSelf: "center",
              }}
            />
          </Row>
          <Row>
            <Field
              name="netPremium"
              caption="​เบี้ยสุทธิ"
              component={CustomCurrencyInput}
              options={props.salesCommissionMasterOptions}
              readOnly={props.defaultReadOnly}
              leftWidth="150"
              rightWidth="188"
              unit="บาท"
              customStyles={{ color: "#666", fontFamily: "Sarabun, sans-serif", fontSize: "16px" }}
            />
            <Field
              name="commissionReceivedDate"
              caption="วันที่จ่ายเงิน"
              component={DatePicker}
              readOnly={props.defaultReadOnly}
              leftWidth="157"
            />
          </Row>
          <Row>
            <Field
              name="staffCode"
              caption="รหัสพนักงานขาย"
              component={CustomInput}
              readOnly={props.defaultReadOnly}
              leftWidth="150"
              rightWidth="188"
            />
          </Row>
        </Container>
      </TabContainer>
    </CustomMainLayout>
  )
}

const enhance = compose(
  withStores((stores, props) => {
    let current = stores.orderStore.$("current", {})
    let salesCommissionMaster = stores.orderStore.$("salesCommissionMaster", {})
    let orderState = stores.orderStore.$("current.state", {})
    let stateOptions = stores.orderStore.$("orderConfigs.filters.state.options", [])
    let tqmOrderId = current.tqmOrderId || current.orderNo || ""
    let fullName = Object.entries(
      stores.orderStore.$("current.insuranceHolder.nameInfo", {})
    ).reduce((prev, [key, val]) => {
      return prev + (key === "lastName" ? ` ${val}` : val)
    }, "")
    let policyName = current.packageDetail.name || current.productDetail.policyName || ""

    const state = stateOptions.find(({ label, value }) => isEqual(orderState, value))

    return {
      orderId: props.match.params.orderId,
      current: stores.orderStore.$("current"),
      fetchCommissionOrderById: stores.orderStore.fetchCommissionOrderById,
      clearSalesCommission: stores.orderStore.clearSalesCommission,
      salesCommissionMasterOptions: stores.orderStore.$("current.salesCommissionMasterOptions", []),
      currentInsurerCommissionId: stores.orderStore.$("current.currentSalesCommissionMasterId", {}),
      totalSalesCommission: stores.orderStore.$("current.totalSalesCommission", {}),
      initialValues: {
        ...current,
        ...salesCommissionMaster,
        tqmOrderId,
        fullName,
        policyName,
        state: (state && state.label) || "",
        paymentInstallment: salesCommissionMaster.id || {},
      },
    }
  }),
  withForm({
    form: "OrderInsuranceCommissionDetail",
  }),
  defaultProps({ defaultReadOnly: true }),
  lifecycle({
    async componentDidMount() {
      await this.props.fetchCommissionOrderById(this.props.orderId, {
        salesCommissionMasterId: this.props.currentInsurerCommissionId,
      })
    },
    async componentDidUpdate(prevProps) {
      const prevPaymentInstallmentValue = get(prevProps, "values.paymentInstallment")
      const paymentInstallment = get(this.props, "values.paymentInstallment")

      if (!isEqual(prevPaymentInstallmentValue, paymentInstallment)) {
        if (paymentInstallment) {
          await this.props.fetchCommissionOrderById(this.props.orderId, {
            salesCommissionMasterId: paymentInstallment,
          })
        }
      }
    },
    componentWillUnmount() {
      this.props.clearSalesCommission()
    },
  })
)

export default enhance(CommissionInfo)
