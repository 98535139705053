import React from "react"
import styled from "@emotion/styled"

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: 331px;
  background-color: #ffffff;
  box-shadow: 2px 2px 6px #00000029;
  border-radius: 4px;
  opacity: 1;
  padding: 24px 16px 32px 16px;
`

const Title = styled.div`
  text-align: left;
  font: Bold 16px/21px Sarabun;
  color: #1d1e1f;
  opacity: 1;
  margin-bottom: 16px;
`

const TableBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const TableRow = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  background-color: ${(props) => (props.selectBackground ? "#eeeef2" : "#ffffff")};
  padding: 0px 15px;
`

const TableColumn = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  &.tqm-table-column {
    flex: 1.5;
    > div > div:nth-of-type(2) {
      border-radius: unset;
    }
  }
  &.tqm-header-table {
    font: Bold 16px/21px Sarabun;
    color: #1d1e1f;
    opacity: 1;
  }

  &.tqm-table-left {
    justify-content: flex-start;
  }
  &.tqm-table-center {
    justify-content: center;
  }
  &.tqm-table-right {
    justify-content: flex-end;
  }
`

const CloseTheDeal = (props) => (
  <Layout>
    <Title>{props.title}</Title>
    <TableBody>
      <TableRow selectBackground={true}>
        <TableColumn className="tqm-header-table tqm-table-left tqm-table-column"></TableColumn>
        <TableColumn className="tqm-header-table tqm-table-center">ปิดได้</TableColumn>
        <TableColumn className="tqm-header-table tqm-table-right">ปิดไม่ได้</TableColumn>
      </TableRow>
      {props.data.map((item, index) => (
        <TableRow key={index} selectBackground={index % 2 !== 0}>
          <TableColumn className="tqm-table-left tqm-table-column">{item.label}</TableColumn>
          <TableColumn className="tqm-table-center" style={{ color: `${item.colorClose}` }}>
            {item.salablePolicy}
          </TableColumn>
          <TableColumn className="tqm-table-right" style={{ color: `${item.colorUnclose}` }}>
            {item.rejectedPolicy}
          </TableColumn>
        </TableRow>
      ))}
    </TableBody>
  </Layout>
)

export default CloseTheDeal
