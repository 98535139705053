import React, { useCallback, useMemo } from "react"
import { Grid as Table, GridColumn, Button, Input, makeCell, DatePicker } from "@components"
import styled from "@emotion/styled"
import { compose, lifecycle } from "recompose"
import { withHooks, withStores } from "@enhancers/index"
import * as path from "@common/path"

const Layout = styled.div``
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  margin-bottom: 16px;
`
const CreateButton = styled(Button)`
  display: flex;
  width: 90px;
`

const MarketPlanIndexPage = (props) => {
  return (
    <Layout>
      <ButtonContainer>
        {props.currentRole !== "member.accountant" &&
          props.currentRole !== "member.accountant_approver" && (
            <CreateButton awesomeIcon="plus" onClick={props.gotoCreatePage} children="สร้าง" />
          )}
      </ButtonContainer>
      <Table
        data={props.marketPlans}
        onRowClick={props.gotoRowDetail}
        pageable={{
          buttonCount: 5,
        }}
        skip={props.paging.skip}
        take={props.paging.perPage}
        total={props.paging.totalCount}
        onPageChange={props.onPageChange}
        paging={props.paging}
      >
        <GridColumn
          title="ชื่อแผน"
          name="name"
          cell={makeCell(Input, (props) => ({
            readOnly: true,
            value: props.dataItem.name,
          }))}
        />
        <GridColumn
          title="จำนวนระดับ"
          name="numberOfLevel"
          cell={makeCell(Input, (props) => ({
            readOnly: true,
            value: props.dataItem.numberOfLevel,
          }))}
        />
        <GridColumn
          title="วันที่แก้ไขล่าสุด"
          name="updatedAt"
          cell={makeCell(DatePicker, (props) => ({
            readOnly: true,
            value: props.dataItem.updatedAt,
          }))}
        />
      </Table>
    </Layout>
  )
}

const enhancer = compose(
  withStores((stores) => ({
    fetchMarketPlans: stores.marketPlanStore.fetch,
    marketPlans: stores.marketPlanStore.all,
    paging: stores.marketPlanStore.paging,
    currentRole: stores.appStore.currentRole,
  })),
  lifecycle({
    async componentDidMount() {
      await this.props.fetchMarketPlans()
    },
  }),
  withHooks((props) => {
    const gotoRowDetail = useCallback((event) => {
      props.history.push(path.marketPlanPath(event.dataItem.id))
    })

    const gotoCreatePage = useCallback(() => {
      props.history.push(path.newMarketPlanPath())
    })

    const onPageChange = useCallback(async (event) => {
      const page = event.page.skip / event.page.take + 1
      await props.fetchMarketPlans({ page })
    })

    const paging = useMemo(() => {
      return {
        ...props.paging,
        skip: (props.paging.page - 1) * props.paging.perPage,
      }
    }, [props.paging])

    return {
      gotoRowDetail,
      gotoCreatePage,
      onPageChange,
      paging,
    }
  })
)

export default enhancer(MarketPlanIndexPage)
