import { WithCaption } from "@enhancers/withCaption"
import styled from "@emotion/styled"

const Info = styled(WithCaption)`
  margin-top: ${(props) => props.marginTop || 0}px !important;

  > div:nth-of-type(1) {
    height: auto;
  }
`

Info.Caption = WithCaption.LeftPanel

export default Info
