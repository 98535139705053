import styled from "@common/styled"

const Header = styled.div`
  font-size: 30px;
  font-family: "ThaiSans Neue";
  font-weight: bold;
  color: #333333;
`

export default Header
