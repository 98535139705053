import React, { Fragment } from "react"
import styled from "@emotion/styled"
import { css } from "@styled"
import { compose, withHandlers, withProps, lifecycle, withState, defaultProps } from "recompose"
import {
  BreadCrumb,
  Button,
  CurrencyInput,
  GenderInput,
  Field,
  DateInput,
  Dropdown,
} from "@components"
import { withRouter, withStores, withForm } from "@enhancers/index"
import * as path from "@common/path"
import manWithCane from "@assets/images/man-with-cane.svg"
import moment from "moment"

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`
const BreadCrumbLayout = styled.div`
  width: 100%;
`
const Body = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 22px;
`
const TypeText = styled.div`
  letter-spacing: 0;
  text-align: center;
  color: #666666;
  font-size: 30px;
  margin-top: 10px;
  margin-bottom: 30px;
`
const AllContainer = styled.div`
  width: 565px;
  border: 1px solid #e6e6e6;
  padding: 15px;
  background-color: #ffffff;
`
const ContainerButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 16px;
  > button {
    display: flex;
    justify-content: space-around;
    width: 84px;
    height: 76px;
    padding: 10px;
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }

    > span {
      margin: 0px !important;
    }
  }
`
const ImageIcon = styled.img`
  width: 23px;
  height: 21px;
`
const CustomCurrencyInput = styled(CurrencyInput)`
  width: 80%;
  margin-bottom: 16px;
`
const CustomDatePicker = styled(DateInput)`
  width: 80%;
  margin-bottom: 16px;
`
const CustomGenderInput = styled(GenderInput)`
  width: 80%;
  margin: 0px 130px 16px 0px;

  > div:last-of-type {
    > div {
      > button {
        width: 100%;
        max-width: 87.5px;
        border-radius: 4px 0 0 4px;
      }
      > button:last-of-type {
        border-radius: 0 4px 4px 0;
      }
    }
  }
`
const ConfirmButton = styled(Button)`
  width: 173px;
  align-self: flex-end;
  margin: auto;
  margin-top: 44px;
  margin-bottom: 16px;

  &:disabled {
    opacity: 1;
  }
`
const ProductTypeText = styled.div`
  width: 100%;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  color: #666666;
  font-weight: bold;
  margin-top: 24px;
`
const ProductTypeButton = styled(Button)`
  display: flex;
  flex-direction: column;
  opacity: 1;
  color: #0275d8;
  background: #ffffff;
  border: 1px solid #0275d8;
  font-size: 12px;

  span {
    font-size: 20px;
  }

  :active,
  :hover {
    color: white;
    background-color: #0275d8;
    border: 1px solid #0275d8;

    > img {
      opacity: 1;
      filter: brightness(0) invert(1);
    }
  }

  ${(props) =>
    props.isactive &&
    css`
      color: white;
      background-color: #0275d8;
      border: 1px solid #0275d8;

      > img {
        opacity: 1;
        filter: brightness(0) invert(1);
      }
    `}
`
const ConfirmButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`
const AgeLabel = styled.div`
  margin-left: 185px;
  margin-top: ${(props) => props.show && "-8px"};
  font-size: 14px;
  margin-bottom: 16px;
  color: #666666;
`
const Span = styled.span`
  color: #d9534f;
`
const ProductsearchByContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const searchByDropdown = styled(Dropdown)`
  width: 80%;
  margin-bottom: 16px;
`
const ProductSelection = (props) => (
  <Layout>
    <BreadCrumbLayout>
      <BreadCrumb links={props.links} />
    </BreadCrumbLayout>
    <Body>
      <AllContainer>
        <TypeText>กรุณากรอกข้อมูล</TypeText>
        <Field name="gender" component={CustomGenderInput} caption="เพศ" validate="required" />
        <Field
          name="birthdate"
          component={CustomDatePicker}
          caption="วันเกิด"
          validate="required"
          placeholder="กรุณาเลือก"
        />
        {/* {props.values.birthdate && (
          <AgeLabel show={props.values.birthdate !== null}>
            (อายุ {moment().diff(moment(props.values.birthdate), "years")} ปี)
          </AgeLabel>
        )} */}
        <ProductTypeText>
          เลือกแบบประกันที่ต้องการ <Span>*</Span>
        </ProductTypeText>
        <ContainerButton>
          {props.productTypesOptions.map(({ label, value, icon }, index) => (
            <Fragment>
              {label === "บำนาญ" ? (
                <ProductTypeButton
                  key={value}
                  onClick={() => props.selectProductType(value)}
                  isactive={value === props.productType}
                >
                  <ImageIcon src={manWithCane} />
                  บำนาญ
                </ProductTypeButton>
              ) : (
                <ProductTypeButton
                  key={value}
                  awesomeIcon={icon}
                  onClick={() => props.selectProductType(value)}
                  children={label}
                  isactive={value === props.productType}
                />
              )}
            </Fragment>
          ))}
        </ContainerButton>
        {props.productType !== "all" && props.productType !== "package" && (
          <ProductsearchByContainer>
            {/* <Field
              name="searchBy"
              component={searchByDropdown}
              options={props.searchByOptions}
              caption="ค้นหาโดย"
              placeholder="กรุณาเลือก"
              validate="required"
            /> */}
            <Field
              name="searchValue"
              component={CustomCurrencyInput}
              caption="ทุนประกัน" //"จำนวนเงิน"
              min={0}
              placeholder="กรุณาระบุ"
              validate="required"
            />
          </ProductsearchByContainer>
        )}
        <ConfirmButtonContainer>
          <ConfirmButton
            icon="search"
            onClick={props.searchProduct}
            disabled={props.invalid}
            children="เลือกแบบประกัน"
          />
        </ConfirmButtonContainer>
      </AllContainer>
    </Body>
  </Layout>
)

const enhancer = compose(
  withRouter({
    queryTypes: {
      birthdate: "date",
      gender: "string",
      leadId: "integer",
      age: "integer",
      from: "string",
      searchBy: "string",
      searchValue: "integer",
      opportunityKey: "string",
    },
  }),
  withStores((stores, props) => ({
    batchId: stores.leadStore.$("current.leadBatchId"),
    fetchById: stores.leadStore.fetchById,
  })),
  defaultProps({
    searchByOptions: [
      { label: "ทุนประกันขั้นต่ำ", value: "sum_insured" },
      { label: "เบี้ยประกันที่ต้องการซื้อ", value: "premium" },
    ],
  }),
  withForm({ form: "PlanningFormShort" }),
  withProps((props) => {
    const productTypes = [
      {
        label: "ตลอดชีพ",
        value: "whole_life",
        icon: "umbrella",
      },
      {
        label: "สะสมทรัพย์",
        value: "saving",
        icon: "coins",
      },
      {
        label: "บำนาญ",
        value: "annuity",
        icon: "man-with-cane",
      },
      {
        label: "แพ็กเกจ",
        value: "package",
        icon: "box-open",
      },
      {
        label: "ทั้งหมด",
        value: "all",
        icon: "list",
      },
    ]

    return {
      ...props.location.query,
      searchBy: "sum_insured",
      productTypesOptions: productTypes,
    }
  }),
  withProps((props) => {
    const newProps = {}
    newProps.type = props.type || props.match.params.type
    newProps.leadId = props.location.query.leadId
    switch (newProps.type) {
      case "whole_life":
        newProps.label = "ตลอดชีพ"
        newProps.icon = "clock"
        break
      case "saving":
        newProps.label = "สะสมทรัพย์"
        newProps.icon = "clock"
        break
      case "term":
        newProps.label = "ชั่วระยะเวลา"
        newProps.icon = "clock"
        break
      case "annuity":
        newProps.label = "บำนาญ"
        newProps.icon = "clock"
        break
      case "package":
        newProps.label = "แพ็กเกจ"
        newProps.icon = "clock"
        break
      default:
        newProps.label = "ตลอดชีพ"
        newProps.icon = "clock"
    }
    return {
      ...newProps,
    }
  }),
  withProps((props) => {
    const { from = "" } = props
    let links = []
    switch (from) {
      case "custom_lead":
        links = [
          { label: "ลูกค้าใหม่", path: path.leadsPath("custom") },
          {
            label: "รายละเอียดลีด",
            path: path.leadOrderInfoPath(props.leadId),
          },
          {
            label: "ค้นหาแบบประกัน",
          },
        ]
        break
      case "batch_lead":
        links = [
          { label: "ลีดทีคิวเอ็ม", path: path.leadsPath("all") },
          {
            label: "รายละเอียดลีด",
            path: path.leadOrderInfoPath(props.leadId),
          },
          {
            label: "ค้นหาแบบประกัน",
          },
        ]
        break
      default:
        links = [
          {
            label: "ผลิตภัณฑ์",
            path: "/",
          },
          {
            label: "ค้นหาแบบประกัน",
          },
        ]
        break
    }
    return { links }
  }),
  withState("productType", "setProductType", "whole_life"),
  withHandlers({
    searchProduct: (props) =>
      props.handleSubmit(async (values) => {
        const newProps = {}
        newProps.gender = values.gender || "male"
        newProps.type = props.productType
        newProps.birthdate = moment(values.birthdate).format("DD/MM/YYYY", {
          locale: "en",
        })

        if (props.productType !== "all" && props.productType !== "package") {
          newProps.searchValue = values.searchValue
          newProps.searchBy = values.searchBy || "sum_insured"
        }

        props.history.push(
          path.productsSearchPath({
            ...newProps,
            from: props.from,
            leadId: props.leadId,
            opportunityKey: props.location.query.opportunityKey,
          })
        )

        props.history.push(
          path.productsPath(props.leadId, {
            ...newProps,
            from: props.from,
            leadId: props.leadId,
            opportunityKey: props.location.query.opportunityKey,
          })
        )
      }),
    selectProductType: (props) => (value) => {
      if (value === "all" || value === "package") {
        props.change("searchBy", null)
        props.change("searchValue", null)
      }
      props.setProductType(value)
    },
  }),
  lifecycle({
    async componentDidMount() {
      let {
        gender,
        birthdate,
        searchValue,
        type,
        searchBy = "sum_insured",
        sumInsured,
      } = this.props
      let age = null
      if (this.props.location.query.leadId) {
        const response = await this.props.fetchById(this.props.location.query.leadId)
        birthdate = response.customerInfo.birthdate || birthdate
        age = birthdate ? moment().diff(moment(birthdate), "years") : age
        gender = response.customerInfo.gender || gender
      }
      if (type) {
        this.props.setProductType(type)
      }
      this.props.change("age", age)
      this.props.change("gender", gender)
      this.props.change("birthdate", birthdate ? moment(birthdate, "DD/MM/YYYY").toDate() : null)
      this.props.change("searchValue", searchValue)
      this.props.change("searchBy", searchBy)
      this.props.change("sumInsured", sumInsured)
    },
  })
)

export default enhancer(ProductSelection)
