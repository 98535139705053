import React from "react"
import { compose, withState, lifecycle, defaultProps, withProps } from "recompose"
import styled from "@emotion/styled"
import { Dropdown } from "@components"
import { withField, withCaption, withReadOnly } from "@enhancers/index"
import { range, includes, xor, last } from "lodash"
import moment from "moment"

const Layout = styled.div`
  display: flex;

  .k-widget.k-combobox:first-of-type {
    width: 80px;
    margin-left: 0px;
  }
  .k-widget.k-combobox {
    width: 150px;
    margin-left: 10px;
  }
  .k-widget.k-combobox:last-of-type {
    width: 100px;
    margin-left: 10px;
  }
`
const ReadOnly = styled.div`
  min-height: 33.5px;
  display: flex;
  align-items: center;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  color: #666666;
`
const Error = styled.div`
  font-family: Sarabun, sans-serif;
  font-size: 12px;
  color: #dc3545;
  margin-top: 4px;
`
const DateInput = (props) => (
  <>
    <Layout name={props.name}>
      <Dropdown
        placeholder="วัน"
        value={props.day}
        onChange={props.setDay}
        options={props.dayOptions}
        readOnly={props.readOnly}
        touched={props.touched}
        valid={props.valid}
        disabled={props.disabled}
      />
      <Dropdown
        placeholder="เดือน"
        value={props.month}
        onChange={props.setMonth}
        options={props.monthOptions}
        readOnly={props.readOnly}
        touched={props.touched}
        valid={props.valid}
        disabled={props.disabled}
      />
      <Dropdown
        placeholder="ปี"
        value={props.year}
        onChange={props.setYear}
        options={props.yearOptions}
        readOnly={props.readOnly}
        touched={props.touched}
        valid={props.valid}
        disabled={props.disabled}
      />
    </Layout>
    {props.showErrorMessage && props.error && <Error>{props.error}</Error>}
  </>
)

const enhance = compose(
  withField(),
  withCaption(),
  withProps((props) => ({
    yearOptions: range(
      new Date().getFullYear() - 100,
      props.maxYear ? new Date().getFullYear() + props.maxYear : new Date().getFullYear() + 1
    )
      .map((v) => ({ label: `${v + 543}`, value: `${v}` }))
      .reverse(),
    monthOptions: [
      { label: "มกราคม", value: "1" },
      { label: "กุมภาพันธ์", value: "2" },
      { label: "มีนาคม", value: "3" },
      { label: "เมษายน", value: "4" },
      { label: "พฤษภาคม", value: "5" },
      { label: "มิถุนายน", value: "6" },
      { label: "กรกฎาคม", value: "7" },
      { label: "สิงหาคม", value: "8" },
      { label: "กันยายน", value: "9" },
      { label: "ตุลาคม", value: "10" },
      { label: "พฤศจิกายน", value: "11" },
      { label: "ธันวาคม", value: "12" },
    ],
    dayOptions: range(1, 32).map((v) => `${v}`),
  })),
  withState("year", "setYear", (props) => props.value && `${props.value.getFullYear()}`),
  withState("month", "setMonth", (props) => props.value && `${props.value.getMonth() + 1}`),
  withState("day", "setDay", (props) => props.value && `${props.value.getDate()}`),
  withProps((props) => {
    let dayOptions = props.dayOptions

    if (includes(["1", "3", "5", "7", "8", "10", "12"], props.month)) {
      dayOptions = range(1, 32).map((v) => `${v}`)
    } else if (includes(["4", "6", "9", "11"], props.month)) {
      dayOptions = range(1, 31).map((v) => `${v}`)
    } else if (includes(["2"], props.month)) {
      if (moment([props.year]).isLeapYear()) {
        dayOptions = range(1, 30).map((v) => `${v}`)
      } else {
        dayOptions = range(1, 29).map((v) => `${v}`)
      }
    } else {
      dayOptions = range(1, 32).map((v) => `${v}`)
    }

    return { dayOptions }
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (
        this.props.year !== prevProps.year ||
        this.props.month !== prevProps.month ||
        this.props.day !== prevProps.day
      ) {
        if (this.props.year && this.props.month && this.props.day) {
          let year = this.props.year
          let month = this.props.month
          let day = this.props.day

          this.props.onChange(new Date(year, month - 1, day))
        } else {
          this.props.onChange(null)
        }
      }

      if (this.props.value !== prevProps.value) {
        if (this.props.value) {
          this.props.setYear(`${this.props.value.getFullYear()}`)
          this.props.setMonth(`${this.props.value.getMonth() + 1}`)
          this.props.setDay(`${this.props.value.getDate()}`)
        } else {
          if (this.props.year && this.props.month && this.props.day) {
            this.props.setYear(null)
            this.props.setMonth(null)
            this.props.setDay(null)
          }
        }
      }

      if (xor(this.props.dayOptions, prevProps.dayOptions).length > 0) {
        if (!includes(this.props.dayOptions, this.props.day)) {
          this.props.setDay(last(this.props.dayOptions))
        }
      }
    },
  }),
  withReadOnly((props) => (
    <ReadOnly>{props.value && moment(props.value).format("DD/MM/YYYY")}</ReadOnly>
  ))
)

export default enhance(DateInput)
