import React from "react"
import { compose, withHandlers, withProps } from "recompose"
import { withHidden, withField, withCaption, withReadOnly } from "@enhancers/index"
import withOptions from "@enhancers/withOptions"
import styled from "@emotion/styled"
import { getOptionLabel } from "@common/helper"

const Layout = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
`
const Item = styled.div`
  height: 35px;
  display: flex;
  align-items: center;
  min-width: 256px;
  min-height: 56px;
  border: 1px solid #eeeef2;
  box-sizing: border-box;
  border-radius: 5px;
  padding-left: 12px;
  margin-top: 8px;

  ${(props) => (props.disabled === false ? "cursor: pointer" : "pointer-events: none")}
`
const Input = styled.input`
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  :checked {
    border-color: #0275d8;
    color: #0275d8;
    background-color: white;
  }
`
const Label = styled.div`
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  margin-left: 12px;
`

const ReadOnly = styled.div`
  min-height: 33.5px;
  display: flex;
  align-items: center;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
`
const RadioContainer = styled.div`
  &:nth-of-type(2) {
    padding-left: 32px;
  }
`

const Radio = (props) => (
  <Layout className={props.className + " " + props.name || "" + props.name}>
    <RadioContainer>
      {props.firstColumnOptions.map(({ label, value }) => {
        const id = `Radio-${Math.random()}-${value}`
        return (
          <Item
            key={id}
            onClick={(e) => !props.disabled && props.onChange(value, label, e)}
            disabled={props.disabled}
          >
            <Input
              className="k-radio"
              type="radio"
              id={id}
              onClick={(e) => props.onChange(value, label, e)}
              onChange={() => {}}
              checked={value === props.value}
              disabled={props.disabled}
            />
            <Label
              className="k-radio-label"
              htmlFor={id}
              onClick={(e) => props.onChange(value, label, e)}
            >
              {label}
            </Label>
          </Item>
        )
      })}
    </RadioContainer>

    <RadioContainer>
      {props.secondColumnOptions.map(({ label, value }) => {
        const id = `Radio-${Math.random()}-${value}`
        return (
          <Item
            key={id}
            onClick={(e) => !props.disabled && props.onChange(value, label, e)}
            disabled={props.disabled}
          >
            <Input
              className="k-radio"
              type="radio"
              id={id}
              onClick={(e) => props.onChange(value, label, e)}
              onChange={() => {}}
              checked={value === props.value}
              disabled={props.disabled}
            />
            <Label
              className="k-radio-label"
              htmlFor={id}
              onClick={(e) => props.onChange(value, label, e)}
            >
              {label}
            </Label>
          </Item>
        )
      })}
    </RadioContainer>
  </Layout>
)

const enhancer = compose(
  withHidden(),
  withField(),
  withOptions(),
  withCaption(),
  withReadOnly((props) => <ReadOnly>{getOptionLabel(props.options, props.value)}</ReadOnly>),
  withProps((props) => {
    const length = props.options.length / 2

    const firstColumnOptions = props.options.slice(0, length)
    const secondColumnOptions = props.options.slice(length)

    return {
      firstColumnOptions,
      secondColumnOptions,
    }
  }),
  withHandlers({
    onChange: (props) => (value, label, e) => {
      props.onChange && props.onChange(value)
    },
  })
)

export default styled(enhancer(Radio))`
  > * :nth-of-type(1) {
    margin-bottom: auto;
    height: 33.5px;
    display: flex;
    align-items: center;
  }
`
