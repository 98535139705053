import React, { useEffect } from "react"
import { Link, Icon } from "@components"
import * as path from "@common/path"
import { compose, withProps, branch, withHandlers } from "recompose"
import { withRouter, withHooks } from "@enhancers"
import styled from "@emotion/styled"
import { matchPath } from "react-router"
import { get } from "lodash"

const SITE_MAP = {
  [path.leadPath(":leadId")]: (params) => [
    { label: "Lead", path: path.batchesPath() },
    { label: "Lead Detail", path: path.leadPath(params.leadId) },
  ],
  [path.leadInfoPath(":leadId")]: (params) => [
    { label: "Lead", path: path.batchesPath() },
    { label: "Lead Detail", path: path.leadInfoPath(params.leadId) },
  ],
  [path.leadCustomerInfoPath(":leadId")]: (params) => [
    { label: "Lead", path: path.batchesPath() },
    { label: "Lead Detail", path: path.leadCustomerInfoPath(params.leadId) },
  ],
  [path.leadOrderInfoPath(":leadId")]: (params) => [
    { label: "Lead", path: path.batchesPath() },
    { label: "Lead Detail", path: path.leadOrderInfoPath(params.leadId) },
  ],
  [path.teamPath(":teamId")]: (params) => [
    { label: "การบริหารจัดการทีม", path: path.teamsPath() },
    { label: "รายละเอียดทีม", path: path.teamPath(params.teamId) },
  ],
  [path.newTeamPath()]: () => [
    { label: "การบริหารจัดการทีม", path: path.teamsPath() },
    { label: "สร้างทีม", path: path.newTeamPath() },
  ],
  [path.insurerPath(":insurerId")]: (params, props) => [
    { label: "บริษัทประกัน", path: path.insurersPath() },
    {
      label: `รายละเอียดบริษัทประกัน (${get(props, "insurer.shortName", "")})`.replace("()", ""),
      path: path.insurerPath(params.insurerId),
    },
  ],
  [path.insurerInfoPath(":insurerId")]: (params) => [
    { label: "บริษัทประกัน", path: path.insurersPath() },
    {
      label: "รายละเอียดบริษัทประกัน",
      path: path.insurerInfoPath(params.insurerId),
    },
  ],
  [path.insurerDocumentInfoPath(":insurerId")]: (params) => [
    { label: "บริษัทประกัน", path: path.insurersPath() },
    {
      label: "รายละเอียดบริษัทประกัน",
      path: path.insurerDocumentInfoPath(params.insurerId),
    },
  ],
  [path.riderPath(":riderId")]: (params) => [
    { label: "สัญญาเพิ่มเติม", path: path.healthInsurancePoliciesPath() },
    { label: "ตั้งค่าสัญญาเพิ่มเติม", path: path.ridersPath() },
    { label: "รายละเอียดการตั้งค่าสัญญาเพิ่มเติม", path: path.riderPath(params.riderId) },
  ],
}

const Layout = styled.div`
  display: flex;
  align-items: center;
  height: 47px;

  font-family: Sarabun, sans-serif;
  font-size: 16px !important;
  color: #666666;
`

const BreadCrumbLink = styled(Link)`
  color: #666666;
  font-size: 16px !important;

  &.active {
    opacity: 0.4;
    pointer-events: none;
  }
`

const BreadCrumbDisabledLink = styled.div`
  color: #666666;
  font-size: 16px !important;
  &.active {
    opacity: 0.4;
    pointer-events: none;
  }
`

const Arrow = styled.span`
  margin: 0 5px;
  .fa,
  .fas {
    font-size: 7px;
    margin-top: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const BreadCrumb = (props) => (
  <Layout className={props.className} id="breadCrumb">
    {props.links.map(({ label, path, func }, index) => (
      <React.Fragment key={index}>
        <Arrow hidden={index === 0}>
          <Icon awesomeIcon="chevron-right" />
        </Arrow>
        {path ? (
          <BreadCrumbLink to={path} className={index === props.links.length - 1 && "active"} exact>
            {label}
          </BreadCrumbLink>
        ) : (
          <BreadCrumbDisabledLink
            onClick={() => props.onAction(func)}
            className={index === props.links.length - 1 && "active"}
            exact
          >
            {label}
          </BreadCrumbDisabledLink>
        )}
      </React.Fragment>
    ))}
  </Layout>
)

const enhancer = compose(
  withRouter(),
  branch(
    (props) => !props.links,
    withProps((props) => {
      const activePath = Object.keys(SITE_MAP).find((path) =>
        matchPath(props.location.pathname, path)
      )
      return {
        links: activePath ? SITE_MAP[activePath](props.match.params, props) : [],
      }
    })
  ),
  withHandlers({
    onAction: (props) => (func) => {
      func && func()
    },
  }),
  withHooks((props) => {
    useEffect(() => {
      if (
        sessionStorage.getItem("fromAGT") === "true" &&
        sessionStorage.getItem("fromPortal") === "true"
      ) {
      } else if (sessionStorage.getItem("fromAGT") === "true") {
        document.getElementById("breadCrumb").style.visibility = "hidden"
      }
    }, [])
  })
)

export default enhancer(BreadCrumb)
