import React from "react"
import styled from "@emotion/styled"
import { compose, withHandlers } from "recompose"
import { withForm, withStores } from "@enhancers"
import { Form, Field, DialogActionsBar, InputFile, Dropdown } from "@components"

import { CustomDialog as Dialog, CloseButton, SubmitButton } from "@pages/insurers/show/DeleteModal"

const Layout = styled(Form)`
  padding: 17px 62px 40px;

  > div {
    margin-top: 16px !important;
    :first-of-type {
      margin-top: 0 !important;
    }
    :last-of-type {
      margin-top: 8px !important;
    }
  }
`
const CustomDialog = styled(Dialog)`
  .k-widget.k-window.k-dialog {
    width: 544px;
  }
`
const Note = styled.div`
  font-size: 12px;
  width: 180px;
  margin-left: 182px;
`
const CustomDropdown = styled(Dropdown)`
  > div:nth-of-type(2) {
    > span {
      padding: 0px;
      background-color: transparent;
    }
  }
`
const CustomField = styled(Field)`
  [class*="LeftPanel"] > div {
    font-weight: 400;
  }
`

const AddDocumentModal = (props) => (
  <CustomDialog title={props.title} onClose={props.onClose} isShowModal={props.open}>
    <Layout>
      <CustomField
        name="documentType"
        caption="ประเภทเอกสาร"
        component={CustomDropdown}
        options={props.importOrderTemplateOptions}
        validate="required"
        placeholder="โปรดระบุ"
      />
      <CustomField
        name="file"
        caption="ไฟล์เอกสาร"
        component={InputFile}
        readOnly={props.readFileOnly}
        validate="required"
        children="อัพโหลด"
        accept=".xlsx"
      />
      <Note>หมายเหตุ: รับไฟล์สกุล .xlsx เท่านั้น ขนาดไม่เกินไฟล์ละ 2 MB</Note>
    </Layout>

    <DialogActionsBar>
      <CloseButton type="button" onClick={props.onClose} children="ยกเลิก" styletype="delete" />
      <SubmitButton
        type="button"
        onClick={props.onSubmit}
        children="ยืนยัน"
        disabled={props.invalid}
      />
    </DialogActionsBar>
  </CustomDialog>
)

const enhancer = compose(
  withStores((stores) => ({
    importOrderTemplateOptions: stores.orderStore.$("orderConfigs.attributes.importOrderTemplate.options", []),
  })),
  withForm({ form: "ImportOrderForm" }),
  withHandlers({
    onClose: (props) => () => {
      props.reset(props.from)
      props.onClose()
    },
    onSubmit: (props) =>
      props.handleSubmit(async (values) => {
        await props.onSubmitImportFile(values)
        await props.reset(props.from)
        await props.onClose()
      }),
  })
)
export default enhancer(AddDocumentModal)
