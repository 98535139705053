import React from "react"
import styled from "@emotion/styled"
import { Button } from "@components"
import { compose, lifecycle, withHandlers } from "recompose"
import CommissionStructureCard from "./CommissionStructureCard"
import { isEqual } from "lodash"

const Layout = styled.div`
  width: 100%;
  justify-content: center;
`
const AddStuctureButton = styled(Button)`
  max-width: 160px;
  width: 100%;
  font-family: Sarabun, sans-serif;
`
const SectionHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const TextHeaderSection = styled.span`
  display: flex;
  flex-direction: column;
`
const SectionHeader = styled.span`
  font-size: 16px;
  font-weight: bold;
  font-family: Sarabun, sans-serif;
  margin-bottom: 8px;
  line-height: 21px;
`
const SectionSubHeader = styled.span`
  font-size: 12px;
  font-family: Sarabun, sans-serif;
  line-height: 16px;
`
const ValidateStar = styled.span`
  color: red;
`

const CommissionStructureField = (props) => {
  return (
    <Layout>
      <SectionHeaderContainer>
        <TextHeaderSection>
          <SectionHeader>
            โครงสร้างคอมมิชชั่นจ่าย
            <ValidateStar> *</ValidateStar>
          </SectionHeader>
          <SectionSubHeader>โครงสร้างสำหรับใช้ในการคำนวณคอมมิชชั่นจ่าย</SectionSubHeader>
        </TextHeaderSection>

        {props.from === "show" && (
          <AddStuctureButton icon="plus" onClick={props.onAddStucture} hidden={props.readOnly}>
            เพิ่มโครงสร้าง
          </AddStuctureButton>
        )}
      </SectionHeaderContainer>

      {props.fields.map((stucture, index) => (
        <CommissionStructureCard
          stucture={stucture}
          index={index}
          onDeleteStucture={props.onDeleteStucture}
          fields={props.fields}
          readOnly={props.readOnly}
          commissionStructure={props.commissionStructure}
          from={props.from}
          reserveForLevelOptions={props.reserveForLevelOptions}
        />
      ))}
    </Layout>
  )
}

const enhancer = compose(
  withHandlers({
    onAddStucture: (props) => () => {
      props.fields.insert(0, {
        mkCommissionRates: props.commissionStructure,
      })
    },
    onDeleteStucture: (props) => (index) => {
      if (props.fields.length > 1) {
        props.fields.remove(index)
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      if (this.props.fields.length === 0) {
        this.props.fields.push({
          mkCommissionRates: this.props.commissionStructure,
        })
      }
    },

    componentDidUpdate(prevState) {
      if (
        !isEqual(prevState.commissionStructure, this.props.commissionStructure) &&
        this.props.from === "new"
      ) {
        this.props.fields.removeAll()
        this.props.fields.push({
          mkCommissionRates: this.props.commissionStructure,
        })
      }
    },
  })
)

export default enhancer(CommissionStructureField)
