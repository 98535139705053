import React, { Fragment } from "react"
import styled from "@emotion/styled"
import { compose, withHandlers, lifecycle, withState, withProps } from "recompose"
import InputName from "./InputName"
import { FormSection } from "redux-form"
import { Field, Dropdown, Button, DateInput, CurrencyInput } from "@components/index"
import { withStores } from "@enhancers/index"

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: #eeeef2 solid 1px;
  margin-top: 20px;
`
const Header = styled.div`
  display: flex;
  padding: 10px 12px 10px 12px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  background-color: #deeaf6;
`
const HeaderCaption = styled.span`
  font-size: 12px;
  color: #0275d8;
  font-family: Sarabun, sans-serif;
`
const Body = styled.div`
  display: flex;
  flex-direction: column;
`
const Row = styled.div`
  display: flex;
  margin: 10px 10px;
  align-items: center;

  > div:nth-of-type(2) {
    margin-top: 0px;
  }

  > div:last-of-type {
    margin-top: 0px;
  }
`
const CustomInputName = styled(InputName)`
  > div:last-of-type {
    > div {
      > span {
        min-width: 123px;
      }
      > input {
        min-width: 138px;
      }
    }
  }
`
const CustomDropdown = styled(Dropdown)`
  > div:last-of-type {
    width: 123px;
  }
`
const RelationshipDropdown = styled(Dropdown)`
  margin-left: 12px;
  margin-top: 0px;
  > div:last-of-type {
    width: 138px;
  }
`
const PercentInput = styled(CurrencyInput)`
  margin-left: 12px;
  margin-top: 0px;
  > div:last-of-type {
    width: 110px;
  }
`
const CustomDateInput = styled(DateInput)`
  .k-widget.k-combobox:first-of-type {
    width: 123px;
    margin-left: 0px;
  }
  .k-widget.k-combobox {
    width: 138px;
    margin-left: 10px;
  }
  .k-widget.k-combobox:last-of-type {
    width: 138px;
    margin-left: 10px;
  }
`
const TrashButton = styled(Button)`
  width: 85px;
  margin-left: 45px;
`

const BeneficiaryCard = (props) => (
  <Layout>
    <Header>
      <HeaderCaption>{`ผู้รับผลประโยชน์คนที่ ${props.index + 1}`}</HeaderCaption>
    </Header>
    <Body>
      <Fragment>
        <Row>
          <FormSection name={`${props.beneficiary}`}>
            <CustomInputName
              name="nameInfo"
              config="prefixName|firstName|lastName"
              {...props}
              values={props.fields.get(props.index).nameInfo}
              required={props.currentRole === "member.affiliate" ? false : true}
              caption="ชื่อ"
              leftWidth="60"
              readOnly={props.readOnly}
              onPrefixNameChange={props.setBeneficiaryPrefixName}
              validated={props.currentRole === "member.affiliate" ? false : true}
            />
          </FormSection>
          <TrashButton
            icon="trash"
            onClick={() => props.onDeleteBeneficiary(props.index)}
            styletype="delete"
            mergebutton={props.isBenificiariesGreaterThanOne}
            hidden={props.readOnly || !props.moreThanOneBeneficiary}
          >
            ลบ
          </TrashButton>
        </Row>
        <Row>
          <Field
            component={CustomDateInput}
            name={`${props.beneficiary}.birthdate`}
            caption="วันเกิด"
            leftWidth="60"
            readOnly={props.readOnly}
          />
        </Row>
        <Row>
          <Field
            component={CustomDropdown}
            name={`${props.beneficiary}.gender`}
            options={props.genderOptions}
            caption="เพศ"
            leftWidth="60"
            readOnly={props.readOnly}
          />
          <Field
            component={RelationshipDropdown}
            name={`${props.beneficiary}.relationship`}
            options={props.relationshipOptions}
            validate={props.currentRole === "member.affiliate" ? null : "required"}
            caption="ความสัมพันธ์"
            leftWidth="105"
            readOnly={props.readOnly}
          />
          <Field
            component={PercentInput}
            name={`${props.beneficiary}.percent`}
            caption="ร้อยละ"
            leftWidth="57"
            readOnly={props.readOnly}
            max={100}
          />
        </Row>
      </Fragment>
    </Body>
  </Layout>
)

const enhance = compose(
  withStores((stores) => ({
    currentRole: stores.appStore.currentRole,
  })),
  withState("beneficiaryPrefixName", "setBeneficiaryPrefixName", null),
  withProps((props) => {
    return {
      moreThanOneBeneficiary: props.fields.length > 1,
    }
  }),
  withHandlers({
    onDeleteBeneficiary: (props) => (index) => {
      props.onDeleteBeneficiary(index)
    },
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (prevProps.beneficiaryPrefixName !== this.props.beneficiaryPrefixName) {
        if (["นาย", "ด.ช."].includes(this.props.beneficiaryPrefixName)) {
          this.props.change(`insuranceHolder.${this.props.beneficiary}.gender`, "male")
        } else if (["นาง", "นางสาว", "ด.ญ."].includes(this.props.beneficiaryPrefixName)) {
          this.props.change(`insuranceHolder.${this.props.beneficiary}.gender`, "female")
        }
      }
    },
  })
)

export default enhance(BeneficiaryCard)
