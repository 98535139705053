import React from "react"
import { BreadCrumb } from "@components"
import { compose, withProps } from "recompose"
import { withStores } from "@enhancers/index"
import Table from "./Table"
import * as path from "@common/path"

const TeamHistoryPage = (props) => {
  return (
    <>
      <BreadCrumb links={props.links} />
      <Table teamId={props.teamId} />
    </>
  )
}

const enhancer = compose(
  withStores((stores, props) => ({
    teamId: props.match.params.teamId,
  })),
  withProps((props) => {
    const links = [
      { label: "การบริหารจัดการทีม", path: path.saleCommissionsPath() },
      {
        label: "รายละเอียดทีม",
        path: path.teamPath(props.teamId),
      },
      {
        label: "ประวัติการแก้ไข",
        path: path.teamHistoryPath(props.teamId),
      },
    ]

    return {
      links,
    }
  })
)

export default enhancer(TeamHistoryPage)
