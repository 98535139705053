import React from "react"
import { Dialog as KendoDialog } from "@progress/kendo-react-dialogs"
import { compose, lifecycle } from "recompose"
import { Portal } from "react-portal"
import styled from "@common/styled"
import { enableBodyScrolling } from "@common/helper"
import Form from "./Form"

const CustomDialog = styled(KendoDialog)`
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);

  .k-overlay {
    width: unset;
    height: unset;
  }

  .k-dialog {
    background: #ffffff;
    box-shadow: 0px 3px 4px #0000000f;
    border: 1px solid #e6e6e6;
  }

  .k-window-titlebar {
    background: #294558;
    font-weight: bold;
    font-size: 16px;
    font-family: Sarabun, sans-serif;
    color: #ffffff;
    padding: 10px 15px;
    min-height: 62px;
    .k-dialog-title {
      font-size: 16px;
    }
    .k-dialog-close {
      opacity: 1;
    }
    .k-icon {
      color: #ffffff;
      font-size: 20px;
    }
  }

  .k-window {
    border: 1px solid #e6e6e6;
  }

  .k-window-titlebar + .k-window-content {
    margin-top: unset;
    padding-top: unset;
  }

  .k-window-content {
    padding: unset;
  }

  display: ${(props) => (props.isShowModal ? "flex" : "none")};
`

const Dialog = (props) => (
  <Portal node={document && document.getElementById("kendo_dialog_portal")}>
    {props.onSubmit ? (
      <Form onSubmit={props.onSubmit}>
        <CustomDialog {...props} />
      </Form>
    ) : (
      <CustomDialog {...props} />
    )}
  </Portal>
)

const enhancer = compose(
  lifecycle({
    componentDidMount() {
      if (this.props.isShowModal) {
        enableBodyScrolling(false)
      }
    },
    componentDidUpdate(prevProps) {
      if (prevProps.isShowModal !== this.props.isShowModal) {
        enableBodyScrolling(!this.props.isShowModal)
      }
    },
    componentWillUnmount() {
      if (this.props.isShowModal) {
        enableBodyScrolling(true)
      }
    },
  })
)

export default enhancer(Dialog)
