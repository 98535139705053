import React from "react"
import styled from "@emotion/styled"
import { Dialog, DialogActionsBar, Button, CurrencyInput } from "@components/index"
import { compose, withHandlers, withProps } from "recompose"
import { withRouter, omitProps } from "@enhancers/index"
import * as path from "@common/path"

const CustomDialog = styled(Dialog)`
  .k-window-titlebar {
    min-height: 62px;
    .k-dialog-title {
      font-size: 16px;
    }
  }
`
const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 350px;
  align-items: center;
  padding: 27px 34px 50px 34px;
`
const Caption = styled.span`
  font-size: 16px;
  color: #5cb85c;
  font-weight: bold;
  margin-top: 7px;
  font-family: Sarabun, sans-serif;
`
const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Sarabun, sans-serif;
  margin-top: 8px;
`
const BoldDetailCaption = styled(Caption)`
  color: #666666;
  margin-top: 0px;
`
const LightDetailCaption = styled(Caption)`
  color: #666666;
  margin-top: 0px;
  font-weight: normal;
`
const DetailInfo = styled(Caption)`
  display: flex;
  color: #0275d8;
  margin-top: 0px;
  align-items: center;
`
const NotFoundInfo = styled(DetailInfo)`
  height: 33.5px;
`
const InsuranceContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 30px;
`
const SumInsuredAndPremiumContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`
const RiderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
`
const RiderCardDetail = styled.div`
  width: 100%;
  border: 1px #0275d8 solid;
  border-radius: 4px;
  padding: 10px 13px;
  margin-top: 10px;
`
const RiderCaption = styled(Caption)`
  color: #0275d8;
  margin-top: 0px;
`
const Unit = styled(DetailInfo)`
  color: #0275d8;
  margin-left: 5px;
`
const DialogFooter = styled(DialogActionsBar)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 20px;
`
const AssignButton = styled(Button)`
  border-left: 0.5px solid #ccc;
  color: white;
  background-color: #0275d8;
  min-height: 52px;
  text-overflow: unset;
  :hover {
    background-color: #0275d8;
    color: white;
  }
`
const CancelButton = styled(Button)`
  border-left: 0.5px solid #ccc;
  color: #666;
  min-height: 52px;
`
const WrapRiders = styled.div`
  max-height: 300px;
  overflow-y: scroll;
`
const BoldDetail = ({ caption, info }) => (
  <DetailContainer style={info ? undefined : { display: "none" }}>
    <BoldDetailCaption>{caption}</BoldDetailCaption>
    {info ? (
      typeof info === "string" ? (
        <DetailInfo>{info}</DetailInfo>
      ) : (
        <DetailInfo>
          <CurrencyInput value={info} readOnly />
          <Unit>บาท/ปี</Unit>
        </DetailInfo>
      )
    ) : (
      <NotFoundInfo>n/a</NotFoundInfo>
    )}
  </DetailContainer>
)
const LightDetail = ({ caption, info }) => (
  <DetailContainer style={info ? undefined : { display: "none" }}>
    <LightDetailCaption>{caption}</LightDetailCaption>
    {info ? (
      typeof info === "string" ? (
        <DetailInfo>{info}</DetailInfo>
      ) : (
        <>
          {caption === "ค่าเบี้ย" ? (
            <DetailInfo>
              <CurrencyInput value={info} readOnly />
              <Unit>บาท/งวด</Unit>
            </DetailInfo>
          ) : (
            <DetailInfo>
              <CurrencyInput value={info} readOnly />
              <Unit>บาท/ปี</Unit>
            </DetailInfo>
          )}
        </>
      )
    ) : (
      <NotFoundInfo>n/a</NotFoundInfo>
    )}
  </DetailContainer>
)
const RiderDetail = ({ riders }) => {
  return (
    <RiderContainer>
      <BoldDetailCaption>สัญญาเพิ่มเติม</BoldDetailCaption>
      <WrapRiders>
        {riders &&
          riders.map((rider) => (
            <RiderCardDetail>
              <RiderCaption>{rider.riderName}</RiderCaption>
              <SumInsuredAndPremiumContainer>
                <LightDetail caption="ค่าเบี้ย" info={parseInt(rider.riderPremium)} />
                <LightDetail caption="ทุนประกัน" info={rider.riderSumInsured} />
              </SumInsuredAndPremiumContainer>
            </RiderCardDetail>
          ))}
      </WrapRiders>
    </RiderContainer>
  )
}

const SummaryModal = (props) => (
  <CustomDialog title="สั่งซื้อ" onClose={props.onClose} isShowModal={props.open}>
    <Layout>
      <Caption>สร้างคำสั่งซื้อสำเร็จ</Caption>
      <InsuranceContainer>
        <BoldDetail caption="แบบประกันหลัก" info={(props.values.productDetail || {}).policyName} />
      </InsuranceContainer>
      <SumInsuredAndPremiumContainer>
        <LightDetail caption="ค่าเบี้ย" info={parseInt(props.values.premium)} />
        <LightDetail caption="ทุนประกัน" info={parseInt(props.values.sumInsured)} />
      </SumInsuredAndPremiumContainer>
      {props.riders && props.riders.length > 0 && <RiderDetail riders={props.riders} />}
    </Layout>
    <DialogFooter>
      <CancelButton styletype="delete" onClick={props.gotoLeadPage}>
        ไปหน้าลีด
      </CancelButton>
      <AssignButton styletype="primary" onClick={props.gotoInsuranceHolder}>
        กรอกข้อมูลผู้เอาประกัน
      </AssignButton>
    </DialogFooter>
  </CustomDialog>
)

const enhance = compose(
  withRouter(),
  withProps((props) => {
    const { leadId } = props.location.query

    const riders = []
    props.riders &&
      props.riders.map((rider) => {
        if (rider.selected) {
          if (rider.hasSumInsured) {
            riders.push({
              riderPolicyId: rider.id,
              riderSumInsured: parseInt(rider.sumInsured),
              riderName: rider.name,
              riderPremium: rider.premium,
            })
          } else {
            riders.push({
              riderPolicyId: rider.id,
              riderName: rider.name,
              riderPremium: rider.premium,
            })
          }
        }
        return null
      })

    return { riders, leadId }
  }),
  withHandlers({
    onClose: (props) => () => {
      props.onClose()
    },
    gotoLeadPage: (props) => () => {
      props.history.push(path.leadsPath("custom"))
      props.onClose()
    },
    gotoInsuranceHolder: (props) => () => {
      props.history.push(path.orderHolderInfoPath(props.orderId))
      props.onClose()
    },
  }),
  omitProps("leadId")
)

export default enhance(SummaryModal)
