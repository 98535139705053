import React, { useEffect } from "react"
import styled from "@emotion/styled"
import { compose, withState, withHandlers, lifecycle, withProps } from "recompose"
import { withForm, withStores, withHooks } from "@enhancers"
import { Field, BreadCrumb, Button, Input, Checkbox, Dropdown, DatePicker, TextArea, Radio } from "@components"
import { Body, Header, RightPanel, ButtonGroup } from "@pages/leads/show/LeadInfo"
import { FieldArray } from "redux-form"
import { isEmpty } from "lodash"
import PolicyComponent from "./policy"
import RiderComponent from "./rider"
import DeletePackageModal from "../show/DeleteModal"
import { toCurrency } from "@common/helper"
import * as path from "@common/path"

const Layout = styled.div``
const BreadCrumbLayout = styled.div`
  width: 100%;
`
const CustomHeader = styled(Header)`
  justify-content: flex-end;
`
const CustomRightPanel = styled(RightPanel)`
  display: flex;
`
const CustomBody = styled(Body)`
  justify-content: flex-end;
  > div {
    margin-top: 16px;
  }
  > div:nth-of-type(1) {
    margin-top: 0;
  }
`
const SubmitButton = styled(Button)`
  width: 100px;
`
const CancelButton = styled(Button)`
  width: 90px;
`
const TopicContainer = styled.div`
  width: 100%;
  height: 40px;
  background: #294558;
  opacity: 1;
`
const FirstTopicContainer = styled(TopicContainer)`
  border-radius: 4px 4px 0px 0px;
`
const TextTopic = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  font-size: 16px;
  padding-left: 16px;
  color: #ffffff;
  font-family: Sarabun, sans-serif;
  font-weight: bold;
`
const CustomTextArea = styled(TextArea)`
  white-space: pre;
  align-items: unset;
  textarea {
    width: 480px;
    resize: vertical;
  }
  > div {
    :nth-of-type(1) {
      margin-top: -6px;
    }
  }
`
const CustomRadio = styled(Radio)`
  > div {
    :nth-of-type(2) {
      > div {
        display: flex;
        > div:last-of-type {
          margin-left: 35px;
        }
      }
    }
  }
`
export const PackageNewPage = (props) => (
  <Layout>
    <BreadCrumbLayout>
      <BreadCrumb links={props.breadCrumbPackage} />
    </BreadCrumbLayout>
    <CustomHeader>
      <CustomRightPanel>
        {props.match.params.packageId && (
          <ButtonGroup>
            <CancelButton
              icon="delete"
              onClick={props.openDeleteModal}
              children="ลบ"
              styletype="delete"
            />
          </ButtonGroup>
        )}
        {props.isEdit ? (
          <ButtonGroup>
            <CancelButton
              icon="cancel"
              onClick={props.cancel}
              children="ยกเลิก"
              styletype="delete"
            />
            <SubmitButton icon="floppy" onClick={props.save} children="บันทึก" styletype="agree" />
          </ButtonGroup>
        ) : (
          <ButtonGroup>
            <SubmitButton icon="edit" onClick={props.edit} children="แก้ไข" />
          </ButtonGroup>
        )}
      </CustomRightPanel>
    </CustomHeader>
    <FirstTopicContainer>
      <TextTopic>ข้อมูลแพ็กเกจ</TextTopic>
    </FirstTopicContainer>
    <CustomBody>
      <Field
        caption="ชื่อ"
        name="name"
        component={Input}
        readOnly={!props.isEdit}
        validate="required"
        placeholder="กรุณาระบุ"
        leftWidth="150"
        rightWidth={props.isEdit ? "245" : "480"}
      />
      <Field
        caption="รหัสอ้างอิง"
        name="referenceCode"
        component={Input}
        readOnly={!props.isEdit}
        validate="required"
        placeholder="กรุณาระบุ"
        leftWidth="150"
        rightWidth="245"
      />
      <Field
        caption="บริษัทประกัน"
        name="insurer"
        component={Dropdown}
        options={props.insurerOptions}
        readOnly={!props.isEdit}
        validate="required"
        placeholder="กรุณาระบุ"
        leftWidth="150"
        rightWidth={!props.isEdit ? "245" : "480"}
        onChange={() => {
          props.change("riders", [{}])
          props.change("policyId", null)
          props.change("policySumInsured", null)
          props.change("policyCommission", null)
        }}
      />
      <Field
        caption="สถานะ"
        name="state"
        component={CustomRadio}
        readOnly={!props.isEdit}
        options={props.stateOptions}
        leftWidth="150"
        validate="required"
      />
    </CustomBody>
    <TopicContainer>
      <TextTopic>ข้อมูลประกันหลัก</TextTopic>
    </TopicContainer>
    <CustomBody>
      <Field
        caption="แบบประกันชีวิต"
        name="policy"
        component={PolicyComponent}
        policyOptions={props.policyOptions}
        commissionOptions={props.commissionOptions}
        sumInsuredWording={props.wordingPolicySumInsure}
        readOnly={!props.isEdit}
        change={props.change}
        disabled={!props.values.insurer}
      />
    </CustomBody>
    <TopicContainer>
      <TextTopic>ข้อมูลสัญญาเพิ่มเติม</TextTopic>
    </TopicContainer>
    <CustomBody>
      <FieldArray
        caption="สัญญาเพิ่มเติม"
        name="riders"
        component={RiderComponent}
        riderOptions={props.riderOptions}
        commissionOptions={props.commissionOptions}
        sumInsuredWording={props.wordingPolicySumInsure}
        readOnly={!props.isEdit}
        insurer={props.values.insurer}
        change={props.change}
        disabled={!props.values.insurer}
      />
    </CustomBody>
    <TopicContainer>
      <TextTopic>ช่องทางการขาย</TextTopic>
    </TopicContainer>
    <CustomBody>
      <Field
        caption="ฝ่ายขาย-ตัวแทน"
        name="isF2fChannel"
        component={Checkbox}
        readOnly={!props.isEdit}
        leftWidth="150"
      />
      <Field
        caption="ขายออนไลน์"
        name="isOnlineChannel"
        component={Checkbox}
        readOnly={!props.isEdit}
        leftWidth="150"
      />
      <Field
        caption="วันที่มีผลใช้งาน"
        name="onlineAt"
        component={DatePicker}
        readOnly={!props.isEdit}
        placeholder="กรุณาระบุ"
        leftWidth="150"
        rightWidth="245"
      />
      <Field
        caption="หมายเหตุ"
        name="comment"
        component={CustomTextArea}
        readOnly={!props.isEdit}
        placeholder="กรุณาระบุ"
        leftWidth="150"
        rightWidth="480"
      />
    </CustomBody>
    <DeletePackageModal
      open={props.showDeleteModal}
      onClose={props.openDeleteModal}
      onAfterDeleteSubmit={props.delete}
    />
  </Layout>
)
export const validate = (values, props) => {
  const errors = {}

  const policy = props.policyOptions.find((policy) => policy.value === values.policyId) || {}

  if (policy.minSumInsured && policy.maxSumInsured) {
    if (
      values.policySumInsured < policy.minSumInsured &&
      values.policySumInsured > policy.maxSumInsured
    ) {
      errors.policySumInsured = "min-max"
    }
  } else if (policy.minSumInsured) {
    if (values.policySumInsured < policy.minSumInsured) {
      errors.policySumInsured = "min"
    }
  } else if (policy.maxSumInsured) {
    if (values.policySumInsured > policy.maxSumInsured) {
      errors.policySumInsured = "max"
    }
  }

  errors.riders = []

  values.riders &&
    values.riders.forEach((itemRider, index) => {
      const riderError = {}
      if (itemRider.riderPolicyId) {
        const riderData = props.riderOptions.find((item) => item.value === itemRider.riderPolicyId)

        if (itemRider.riderPolicyId && !itemRider.riderCommission) {
          riderError.riderCommission = "require"
        }

        if (itemRider.riderSumInsured) {
          if (riderData) {
            if (riderData.minSumInsured && riderData.maxSumInsured) {
              if (
                itemRider.riderSumInsured < riderData.minSumInsured ||
                itemRider.riderSumInsured > riderData.maxSumInsured
              ) {
                riderError.riderSumInsured = "min-max"
              }
            } else if (riderData.minSumInsured) {
              if (itemRider.riderSumInsured < riderData.minSumInsured) {
                riderError.riderSumInsured = "min"
              }
            } else if (riderData.maxSumInsured) {
              if (itemRider.riderSumInsured > riderData.maxSumInsured) {
                riderError.riderSumInsured = "max"
              }
            }
          }
        }
      }
      if (!isEmpty(riderError)) {
        errors.riders[index] = riderError
      }
    })

  if (errors.riders.length === 0) {
    delete errors.riders
  }
  return errors
}

const enhancer = compose(
  withState("isEdit", "setIsEdit", false),
  withStores((stores, props) => ({
    fetchConfig: stores.packageStore.fetchConfig,
    originalPolicyOptions: stores.packageStore.$("configs.attributes.lifeId.options", []),
    originalRiderOptions: stores.packageStore.$("configs.attributes.riderId.options", []),
    originalCommissionOptions: stores.packageStore.$("configs.attributes.commission.options", []),
    insurerOptions: stores.packageStore.$("configs.attributes.insuranceCompany.options", []),
    stateOptions: stores.packageStore.$("configs.attributes.state.options", []),
    createPackage: stores.packageStore.addPackage,
  })),
  withProps(() => {
    return {
      breadCrumbPackage: [
        { label: "แพ็กเก็จ", path: path.packagesPath() },
        {
          label: "สร้างแพ็กเก็จ",
          path: path.newPackagePath(),
        },
      ],
    }
  }),
  withState("policyOptions", "setPolicyOptions", []),
  withState("riderOptions", "setRiderOptions", []),
  withState("commissionOptions", "setCommissionOptions", []),
  withForm({
    form: "NewPackageForm",
    validate,
  }),
  withHandlers({
    cancel: (props) => () => {
      props.history.push(path.packagesPath())
    },
    save: (props) =>
      props.handleSubmit(async (values) => {
        let riders = values.riders.filter(
          (rider) => !isEmpty(rider) && rider.riderPolicyId !== null
        )

        values = {
          name: values.name,
          referenceCode: values.referenceCode,
          policyId: values.policyId,
          policySumInsured: values.policySumInsured,
          isF2fChannel: values.isF2fChannel,
          isOnlineChannel: values.isOnlineChannel,
          onlineAt: values.onlineAt,
          comment: values.comment,
          riders: riders.map((rider) => {
            return {
              riderPolicyId: rider.riderPolicyId,
              riderSumInsured: rider.riderSumInsured,
              lockable: rider.lockable,
            }
          }),
          policyCommissions: [
            { policyId: values.policyId, commissionId: values.policyCommission },
            ...riders.map((rider) => {
              return {
                policyId: rider.riderPolicyId,
                commissionId: rider.riderCommission,
              }
            }),
          ],
          state: values.state,
        }
        try {
          await props.createPackage(values)
          props.history.push(path.packagesPath())
        } catch (error) {
          const errMsg = error.errors?.base || "ไม่สามารถบันทึกแพ็กเกจได้"
          window.alert(errMsg)
          if (error.name === "SubmissionError") {
            throw error
          }
        }
      }),
  }),
  lifecycle({
    async componentDidMount() {
      this.props.setIsEdit(true)
      await Promise.all([this.props.fetchConfig()])
    },
  }),
  withState("wordingPolicySumInsure", "setWordingPolicySumInsure", ""),
  withHooks((props) => {
    useEffect(() => {
      const policyOptions = props.originalPolicyOptions.filter(
        (policy) => policy.insuranceCompanyId === props.values.insurer
      )
      props.setPolicyOptions(policyOptions)
      const policyInsurerId = (
        policyOptions.find((policy) => policy.value === props.values.policyId) || {}
      ).insuranceCompanyId
      const riderOptions = props.originalRiderOptions.filter(
        (rider) => rider.insuranceCompanyId === policyInsurerId
      )
      const commissionOptions = props.originalCommissionOptions.filter(
        (commission) => commission.insuranceCompanyId === policyInsurerId
      )
      props.setRiderOptions(riderOptions)
      props.setCommissionOptions(commissionOptions)
    }, [props.values.policyId, props.values.insurer, props.riderOptions, props.policyOptions])
    useEffect(() => {
      let wording = ""
      const policy =
        props.policyOptions.find((policy) => policy.value === props.values.policyId) || {}
      if (policy.minSumInsured && policy.maxSumInsured) {
        wording = `(${toCurrency(policy.minSumInsured)} - ${toCurrency(policy.maxSumInsured)} บาท)`
      } else if (policy.minSumInsured) {
        wording = `(${toCurrency(policy.minSumInsured)} บาทขึ้นไป)`
      } else if (policy.maxSumInsured) {
        wording = `(ไม่เกิน ${toCurrency(policy.maxSumInsured)} บาท)`
      }
      props.change("policyCommission", policy.commissionId)
      props.setWordingPolicySumInsure(wording)
    }, [props.values.policyId])
    return
  })
)

export default enhancer(PackageNewPage)
