import React from "react"
import styled from "@styled"
import { Field, Dropdown } from "@components"
import { compose, withProps } from "recompose"
import { withStores } from "@enhancers/index"

const Row = styled.div`
  display: flex;
  margin-top: 12px;

  &:nth-of-type(1) {
    margin-top: 0;
  }

  > div {
    margin-left: 12px;

    &:nth-of-type(1) {
      margin-left: 0;
    }
  }
`

const FilterPanel = (props) => (
  <React.Fragment>
    <Row>
      <Field
        component={Dropdown}
        name="insuranceCompanyId"
        caption="บริษัทประกัน"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="130"
        rightWidth="230"
        options={props.insurerOptions}
        placeholder="กรุณาเลือก"
      />
    </Row>
  </React.Fragment>
)

const enhancer = compose(
  withStores((stores) => ({
    insurerOptions: stores.packageStore.$("configs.filters.insuranceCompany.options", []),
  }))
)

export default enhancer(FilterPanel)
