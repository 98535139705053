import React from "react"
import styled from "@emotion/styled"
import { compose, withProps } from "recompose"
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout"

const Layout = styled.div`
  flex: 1;
  width: 100%;
`
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
`

const Title = styled.div`
  font-family: Sarabun, sans-serif;
  font-size: 16px;
`

const Cr = (props) => (
  <Layout>
    {props.riderPolicyDetail.protectionDetail !== undefined && (
      <PanelBar>
        {/* <PanelBarItem expanded={true} title="สูญเสียอวัยวะ"> */}
        {/* {props.lossOfOrgans.map(lossOfOrgan => (
            <React.Fragment>
              {Object.keys(lossOfOrgan).map(key => (
                <ContentContainer>
                  <Title>{lossOfOrgan[key].label}</Title>
                  <Detail>{lossOfOrgan[key].value}</Detail>
                </ContentContainer>
              ))}
            </React.Fragment>
          ))} */}
        {/* </PanelBarItem> */}

        {Object.keys(props.riderPolicyDetail.protectionDetail).map((key) =>
          props.riderPolicyDetail.protectionDetail[key].value !== null &&
          props.riderPolicyDetail.protectionDetail[key].value.length !== 0 ? (
            <PanelBarItem
              expanded={true}
              title={props.riderPolicyDetail.protectionDetail[key].label}
            >
              {props.riderPolicyDetail.protectionDetail[key].value !== null &&
                props.riderPolicyDetail.protectionDetail[key].value.length !== 0 &&
                Object.keys(props.riderPolicyDetail.protectionDetail[key].value).map((keyValue) => (
                  <ContentContainer>
                    <Title>
                      {props.riderPolicyDetail.protectionDetail[key].value[keyValue].label}
                    </Title>

                    {props.riderPolicyDetail.protectionDetail[key].value[keyValue].value !== null &&
                    props.riderPolicyDetail.protectionDetail[key].value[keyValue].value.length !==
                      0 ? (
                      <ContentContainer>
                        {props.riderPolicyDetail.protectionDetail[key].value[keyValue].value !==
                          null &&
                          props.riderPolicyDetail.protectionDetail[key].value[keyValue].value
                            .length !== 0 &&
                          Object.keys(
                            props.riderPolicyDetail.protectionDetail[key].value[keyValue].value
                          ).map((keySubValue) => (
                            <Title>
                              -{" "}
                              {
                                props.riderPolicyDetail.protectionDetail[key].value[keyValue].value[
                                  keySubValue
                                ].label
                              }
                            </Title>
                          ))}
                      </ContentContainer>
                    ) : (
                      <></>
                    )}
                  </ContentContainer>
                ))}
            </PanelBarItem>
          ) : (
            <></>
          )
        )}

        {/* <PanelBarItem expanded={true} title="ผลประโยชน์อื่นๆ เพิ่มเติม">
          <ContentContainer>
            <Title>
              {props.riderPolicyDetail.protectionDetail.other.label}
            </Title>
            <Detail>
              {props.riderPolicyDetail.protectionDetail.other.value}
            </Detail>
          </ContentContainer>
        </PanelBarItem> */}
      </PanelBar>
    )}
  </Layout>
)

const enhancer = compose(withProps((props) => {}))
export default enhancer(Cr)
