import React, { Fragment } from "react"
import styled from "@emotion/styled"
import { compose, lifecycle, withHandlers, withProps } from "recompose"
import { Button, Star, Avatar, Authorize } from "@components"
import { withStores, withForm, withRouter, omitProps } from "@enhancers"
import AdditionalContract from "./additionalContract.js"
import * as path from "@common/path"
import TableKendo from "./TableKendo"
import { url2Obj, convertNaNtoZero, toCurrency, toPercent } from "@common/helper"
import { FieldArray } from "redux-form"
import { round } from "lodash"

import validate from "../../../packages/new/validate"
import StickyTab from "@components/StickyTab.js"
import defaultLogo from "@assets/images/img-no-insurance.png"
import ControlTableUpDownStickyTab from "./ControlTableUpDownStickyTab.js"

const MainLayout = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const LogoCompany = styled.div`
  width: 101px;
  height: 101px;

  > img {
    width: 101px;
    object-fit: contain;
  }
`
const InsurancePlan = styled.div`
  font-size: 25px;
  font-weight: bold;
  height: 39px;
  width: max-content;
  color: #666666;
`

const CompanyName = styled.div`
  font-size: 20px;
  height: 33px;
  color: #666666;
`

const HeadContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  width: 550px;
`

const HeaderDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 11px;
  width: 100%;
  justify-content: center;
`

const ButtonHead = styled(Button)`
  display: ${(props) => (props.readOnly ? "none" : "flex")};
  width: 118px;
  height: 38px;
  box-shadow: unset;
  position: unset;
  align-content: flex-end;

  span.k-icon.k-i-cart {
    position: unset;
    font-size: 18px;
  }
`

const ContainerButtonHead = styled.div`
  display: ${(props) => (props.readOnly ? "none" : "flex")};
  align-content: flex-end;
  align-items: flex-end;
  > span {
    width: 111px;
    height: 38px;
    margin-left: 6px;
  }
`

const TopicContainerFirst = styled.div`
  background-color: #f7f7f9;
  width: 100%;
  height: 40px;
  text-align: center;
  margin-bottom: 16px;
  margin-top: 30px;
`

const TextTopic = styled.div`
  height: 100%;
  display: flex;
  font-family: Sarabun, sans-serif;
  color: #ffffff;
  font-size: 16px;
  justify-content: center;
  align-items: center;
`

const TopicContainer = styled(TopicContainerFirst)`
  margin-top: 16px;
  margin-bottom: 30px;
  width: 768px;
  height: 40px;
  background: #294558;
  opacity: 1;
`

const SubDetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 6px;
`

const SubToppic = styled.div`
  height: 23px;
  color: #666666;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
`
const ContentContainer = styled.div`
  width: 550px;
  min-height: 50px;
`

const RiderContainer = styled.div`
  min-height: 50px;
`

const SubToppicBigSize = styled.div`
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-self: flex-start;
  margin-bottom: 12px;
  font-family: Sarabun, sans-serif;
  color: #0275d8;
  margin-top: ${(props) => props.marginTop};
`

const SubDetail = styled.div`
  height: 23px;
  color: #666666;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
`

const ConfirmBuyButton = styled(Button)`
  display: ${(props) => (props.readOnly ? "none" : "flex")};
  background: #ffffff;
  border-radius: 4px;
  width: 147px;
  height: 38px;
  margin-bottom: 20px;
  box-shadow: unset;

  span.k-icon.k-i-cart {
    font-size: 18px;
  }
`

const HeaderSubDetailContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const ContainerLeft = styled.div``

const NoContent = styled.div`
  display: flex;
  justify-content: center;
`
const StickyTabLayout = styled.div`
  width: 768px;
  height: 38px;
  background: #229ec0;
  z-index: 8;

  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
`
const StickyTabTextLayout = styled.div`
  display: flex;
  justify-content: space-between;

  width: 550px;
`
const StickyTabTextLeft = styled.div`
  color: white;
`

const StickyTabTextRight = styled.div`
  color: white;
`
const CancelButton = styled(Button)`
  display: flex;
  background: #ffffff;
  border-radius: 4px;
  width: 117px;
  height: 38px;
  margin-bottom: 20px;
  box-shadow: unset;
  margin-right: 6px;
  span.k-icon.k-i-cart {
    font-size: 18px;
  }
`
const WrapButton = styled.div`
  display: flex;
  margin-top: 28px;
`
const DocVerAvatar = styled(Avatar)`
  border-radius: 5%;
`
const DocVerHeadContainer = styled(HeadContainer)`
  margin-bottom: 24px;
`
const DocVerInsurancePlan = styled(InsurancePlan)`
  font-family: "ThaiSans Neue";
  font-size: 39px;
`
const DocVerCompanyName = styled(CompanyName)`
  font-family: "ThaiSans Neue";
  margin-top: 5px;
  font-size: 26px;
`
const DocVerTextTopic = styled(TextTopic)`
  padding-left: 16px;
  justify-content: flex-start;
  font-weight: bold;
`
const WrapGraph = styled.div`
  padding: 30px 59px 35px 59px;
`
const GraphImg = styled.img`
  width: 100%;
  min-width: 650px;
  min-height: 249px;
  height: 100%;
`
const Description = styled.div`
  color: #d9534f;
  font-size: 14px;
  margin-bottom: 8px;
`

export const ProductShowPage = (props) => (
  <MainLayout>
    <Authorize roles={["member.biz_dev"]}>
      <DocVerHeadContainer>
        <LogoCompany>
          <DocVerAvatar
            src={(props.productDetail && props.productDetail.companyLogoUrl) || defaultLogo}
            size="100"
            square
          />
        </LogoCompany>
        <HeaderDetailContainer>
          <DocVerInsurancePlan>
            {props.productDetail && props.productDetail.name}
          </DocVerInsurancePlan>
          <DocVerCompanyName>
            {props.productDetail && props.productDetail.companyName}
          </DocVerCompanyName>
        </HeaderDetailContainer>
      </DocVerHeadContainer>

      <StickyTabLayout id="stickyTab-1">
        <StickyTabTextLayout>
          <StickyTabTextLeft>ทุนประกันหลักและสัญญาเพิ่มเติม</StickyTabTextLeft>
          <StickyTabTextRight>
            {props.productDetail &&
              toCurrency(parseInt(convertNaNtoZero(props.productDetail.sumInsuredSummary)))}{" "}
            บาท
          </StickyTabTextRight>
        </StickyTabTextLayout>
      </StickyTabLayout>
      <StickyTabLayout id="stickyTab-2">
        <StickyTabTextLayout>
          <StickyTabTextLeft>เบี้ยประกันหลักและสัญญาเพิ่มเติม</StickyTabTextLeft>
          <StickyTabTextRight>
            {props.productDetail &&
              toCurrency(parseInt(convertNaNtoZero(props.productDetail.premiumSummary)))}{" "}
            บาท/งวด
          </StickyTabTextRight>
        </StickyTabTextLayout>
      </StickyTabLayout>
      <ContentContainer>
        <br />
        <SubDetailContainer>
          <SubToppic>ทุนประกันหลัก</SubToppic>
          <SubDetail>
            {props.productDetail && toCurrency(props.productDetail.sumInsured)} บาท
          </SubDetail>
        </SubDetailContainer>
        <SubDetailContainer>
          <SubToppic>ค่าเบี้ยประกันหลัก</SubToppic>
          <SubDetail>
            {props.productDetail && toCurrency(props.productDetail.premium)} บาท/ งวด
          </SubDetail>
        </SubDetailContainer>
        <br />
        <SubDetailContainer>
          <SubToppic>ทุนประกันสัญญาเพิ่มเติมรวม</SubToppic>
          <SubDetail>
            {props.productDetail && toCurrency(props.productDetail.riderSumInsurerSummary)} บาท
          </SubDetail>
        </SubDetailContainer>
        <SubDetailContainer>
          <SubToppic>ค่าเบี้ยประกันสัญญาเพิ่มเติมรวม</SubToppic>
          <SubDetail>
            {props.productDetail && toCurrency(props.productDetail.riderPremiumSummary)} บาท/ งวด
          </SubDetail>
        </SubDetailContainer>
      </ContentContainer>
      <br />
      <TopicContainer>
        <DocVerTextTopic>รายละเอียดสัญญาเพิ่มเติม</DocVerTextTopic>
      </TopicContainer>
      <RiderContainer>
        {props.riderPolicies.length > 0 ? (
          <FieldArray
            name="riders"
            component={AdditionalContract}
            readOnly={props.readOnly}
            editPackagePage={props.editPackagePage}
            productDetail={props.productDetail}
          />
        ) : (
          <NoContent>ไม่มีสัญญาเพิ่มเติม</NoContent>
        )}
      </RiderContainer>
      <WrapButton>
        <CancelButton
          icon="undo"
          onClick={() => {
            props.history.push(path.ordersPath())
          }}
          styletype="delete"
        >
          คำสั่งซื้อ
        </CancelButton>
        <ConfirmBuyButton
          type="button"
          onClick={() =>
            props.history.push(path.orderDocumentInfoPath(props.id), {
              from: "Orders",
            })
          }
          icon="table-align-top-left"
        >
          ตรวจเอกสาร
        </ConfirmBuyButton>
      </WrapButton>
    </Authorize>
    <Authorize notRoles={["member.biz_dev"]}>
      {/* <FixStickyTab> */}
      <StickyTab summary={props.stickySummary} />
      {/* </FixStickyTab> */}
      <HeadContainer>
        <LogoCompany>
          <Avatar
            src={(props.productDetail && props.productDetail.companyLogoUrl) || defaultLogo}
            size="100"
          />
        </LogoCompany>
        <HeaderDetailContainer>
          <InsurancePlan>{props.productDetail && props.productDetail.name}</InsurancePlan>
          <HeaderSubDetailContainer>
            <ContainerLeft>
              <CompanyName>{props.productDetail && props.productDetail.companyName}</CompanyName>
              <Star value={props.productDetail && props.productDetail.noonStar} />
            </ContainerLeft>
            <ContainerButtonHead readOnly={props.readOnly}>
              <ButtonHead
                icon={props.iconButton}
                onClick={props.onFormSubmit}
                readOnly={props.readOnly}
              >
                {props.titleButton}
              </ButtonHead>
            </ContainerButtonHead>
          </HeaderSubDetailContainer>
        </HeaderDetailContainer>
      </HeadContainer>

      <TopicContainer>
        <TextTopic>รายละเอียดแบบประกัน</TextTopic>
      </TopicContainer>
      <ContentContainer>
        <SubDetailContainer>
          <SubToppic>ค่าเบี้ยประกัน</SubToppic>
          <SubDetail>
            {props.productDetail && toCurrency(props.productDetail.premium)} บาท/ งวด
          </SubDetail>
        </SubDetailContainer>
        <SubDetailContainer>
          <SubToppic>ทุนประกัน</SubToppic>
          <SubDetail>
            {props.productDetail && toCurrency(props.productDetail.sumInsured)} บาท
          </SubDetail>
        </SubDetailContainer>
        <SubDetailContainer>
          <SubToppic>ผลตอบแทนเฉลี่ย</SubToppic>
          <SubDetail>{toPercent(props.productDetail && props.productDetail.irr)}</SubDetail>
        </SubDetailContainer>
        {props.productDetail && props.productDetail.insuranceType === "annuity" && (
          <SubDetailContainer>
            <SubToppic>เงินบำนาญ</SubToppic>
            <SubDetail>
              {toCurrency((props.productDetail.avgRefund / 100) * props.productDetail.sumInsured)}{" "}
              บาท
            </SubDetail>
          </SubDetailContainer>
        )}
        <SubDetailContainer>
          <SubToppic>ระยะเวลาจ่ายเบี้ย</SubToppic>
          <SubDetail>{props.productDetail && props.productDetail.payPeriodText}</SubDetail>
        </SubDetailContainer>
      </ContentContainer>

      <TopicContainer>
        <TextTopic>ผลประโยชน์ที่ได้รับ</TextTopic>
      </TopicContainer>
      <ContentContainer>
        {/* <SubToppicBigSizeFirst>ภาษี</SubToppicBigSizeFirst>
      <SubDetailContainer>
        <SubToppic>ลดหย่อนได้</SubToppic>
        <SubDetail>2,000 - 15,000 บาท</SubDetail>
      </SubDetailContainer>
      <SubDetailContainer>
        <SubToppic>ประหยัดภาษีเพิ่มขึ้นตามรายได้</SubToppic>
        <SubDetail>ถึงอายุ 90 ปี</SubDetail>
      </SubDetailContainer> */}
        <SubToppicBigSize>ความคุ้มครอง</SubToppicBigSize>
        <SubDetailContainer>
          <SubToppic>ทุนประกัน</SubToppic>
          <SubDetail>
            {props.productDetail && toCurrency(props.productDetail.sumInsured)} บาท
          </SubDetail>
        </SubDetailContainer>
        <SubDetailContainer>
          <SubToppic>ระดับความคุ้มครอง</SubToppic>
          <SubDetail>
            {props.productDetail &&
              round(props.productDetail.sumInsured / props.productDetail.premium)}{" "}
            เท่าของเบี้ยที่จ่าย
          </SubDetail>
        </SubDetailContainer>
        <SubDetailContainer>
          <SubToppic>ระยะเวลาคุ้มครอง</SubToppic>
          {props.productDetail.protectionType === "period" ? (
            <SubDetail>{props.productDetail && props.productDetail.protectionPeriod} ปี</SubDetail>
          ) : (
            <SubDetail>
              ถึงอายุ {props.productDetail && props.productDetail.protectEndAge} ปี
            </SubDetail>
          )}
        </SubDetailContainer>
        <SubToppicBigSize marginTop="20px">ผลตอบแทน</SubToppicBigSize>
        <SubDetailContainer>
          <SubToppic>ผลประโยชน์เมื่อครบสัญญา</SubToppic>
          <SubDetail>
            {toPercent(props.productDetail && props.productDetail.yieldToMaturity)}
          </SubDetail>
        </SubDetailContainer>
        <SubDetailContainer>
          <SubToppic>ผลตอบแทนเฉลี่ย/ปี ตลอดสัญญา</SubToppic>
          <SubDetail>{toPercent(props.productDetail && props.productDetail.irr)}</SubDetail>
        </SubDetailContainer>
        {props.productDetail && props.productDetail.insuranceType === "annuity" && (
          <SubDetailContainer>
            <SubToppic>เงินบำนาญต่อปี</SubToppic>
            <SubDetail>
              {toCurrency((props.productDetail.avgRefund / 100) * props.productDetail.sumInsured)}{" "}
              บาท
            </SubDetail>
          </SubDetailContainer>
        )}
        {props.productDetail && props.productDetail.insuranceType === "annuity" && (
          <SubDetailContainer>
            <SubToppic>ช่วงเวลารับบำนาญ</SubToppic>
            <SubDetail>
              {`${props.productDetail.minAgeAnnuity}-${props.productDetail.maxAgeAnnuity}`} ปี
            </SubDetail>
          </SubDetailContainer>
        )}
        {/* <SubDetailContainer>
        <SubToppic>สัดส่วนเงินคืนเมื่อเริ่มรับบำนาญ</SubToppic>
        <SubDetail>-</SubDetail>

      </SubDetailContainer> */}
      </ContentContainer>

      {// Sprops.hasPaymentMethod &&
      !props.isCreateLead && props.productDetail && props.productDetail.protectionGraphUrl && (
        <Fragment>
          <TopicContainer>
            <TextTopic>กราฟผลประโยชน์และความคุ้มครอง</TextTopic>
          </TopicContainer>
          <WrapGraph>
            <GraphImg
              src={props.productDetail && props.productDetail.protectionGraphUrl}
              alt={props.productDetail && props.productDetail.protectionGraphUrl}
            />
          </WrapGraph>
        </Fragment>
      )}

      <TopicContainer>
        <TextTopic>ตารางความคุ้มครองจากเบี้ยมาตรฐาน</TextTopic>
      </TopicContainer>
      <Description>
        * เบี้ยประกันที่แสดงเป็นเบี้ยที่คำนวณจากขั้นอาชีพมาตรฐานเท่านั้น
        อาจไม่ตรงกับเบี้ยประกันที่ต้องจ่ายจริง
      </Description>
      <TableKendo
        displayType={props.displayType}
        fullBenefit={props.productDetail && props.productDetail.insuranceCoverageTable}
      />

      <TopicContainer>
        <TextTopic>เงื่อนไข</TextTopic>
      </TopicContainer>
      <ContentContainer>
        <SubDetailContainer>
          <SubToppic>อายุที่รับสมัคร</SubToppic>
          <SubDetail>
            {`${props.productDetail.minAgeInsured} -
            ${props.productDetail.maxAgeInsured}`}{" "}
            ปี
          </SubDetail>
        </SubDetailContainer>
        <SubDetailContainer>
          <SubToppic>ระยะเวลาจ่ายเบี้ย</SubToppic>
          <SubDetail>{props.productDetail && props.productDetail.payPeriodText}</SubDetail>
        </SubDetailContainer>
      </ContentContainer>

      <TopicContainer>
        <TextTopic>สัญญาเพิ่มเติม</TextTopic>
      </TopicContainer>
      <RiderContainer>
        {props.riderPolicies.length > 0 ? (
          <FieldArray
            name="riders"
            component={AdditionalContract}
            readOnly={props.readOnly}
            editPackagePage={props.editPackagePage}
            productDetail={props.productDetail}
          />
        ) : (
          <NoContent>ไม่มีสัญญาเพิ่มเติม</NoContent>
        )}
      </RiderContainer>
      <ConfirmBuyButton
        type="button"
        onClick={props.onFormSubmit}
        icon={props.iconButton}
        readOnly={props.readOnly}
      >
        {props.titleButton}
      </ConfirmBuyButton>
    </Authorize>
    <ControlTableUpDownStickyTab />
  </MainLayout>
)

const enhancer = compose(
  withRouter(),
  withForm({
    form: "buyProduct",
    validate,
    destroyOnUnmount: false,
  }),
  withStores((stores, props) => ({
    getDetail: stores.productStore.getDetail,
    insuranceCoverageTable: stores.productStore.portDetailTable,
    productDetail: stores.productStore.portDetail,
    postBuyProduct: stores.productStore.postBuyProduct,
    addOrder: stores.orderStore.addOrder,
    riderPolicies: stores.productStore.riderPolicies,
    currentRole: stores.appStore.currentRole,
    leadId: props.location.query.leadId,
  })),
  withProps((props) => {
    const searchData = url2Obj(props.location.search)
    return {
      leadId: searchData.leadId,
      productId: parseInt(searchData.id) || props.productId,
      paramsEncode: searchData.paramsEncode,
      titleButton: "สนใจซื้อ",
      iconButton: "cart",
      productDetail: {
        ...props.productDetail,
        insuranceCoverageTable: props.insuranceCoverageTable && props.insuranceCoverageTable,
      },
    }
  }),
  withHandlers({
    BuyProduct: (props) => (values) => {
      props.postBuyProduct({
        paramsEncode: props.productDetail.lifeInsurancePolicy.paramsEncode,
        id: props.productDetail.lifeInsurancePolicy.id,
      })
    },
    onFormSubmit: (props) => async () => {
      const riders = []
      props.values.riders.map((rider) => {
        if (rider.selected) {
          if (rider.type === "ci") {
            riders.push({
              riderPolicyId: rider.id,
              riderSumInsured: parseInt(rider.sumInsured),
            })
          } else {
            riders.push({ riderPolicyId: rider.id })
          }
        }
        return null
      })
      const newProps = {}
      newProps.policyId = props.productDetail.id
      newProps.policySumInsured = props.productDetail.sumInsured
      newProps.insuranceHolderType = "self"
      newProps.leadId = props.leadId
      newProps.paramsEncode = props.paramsEncode
      if (riders.length > 0) {
        newProps.riders = riders
      }
      await props.addOrder(newProps)
      props.history.push(path.leadOrderInfoPath(props.leadId))
    },
  }),
  lifecycle({
    async componentWillMount() {
      const response = await this.props.getDetail({
        id: this.props.productId,
        paramsEncode: this.props.paramsEncode,
      })
      const newProps = {}
      newProps.riders = response.riderPolicies

      this.props.initialize(newProps)
    },
  }),
  omitProps("productId", "paramsEncode", "addOrder", "getDetail")
)

export default enhancer(ProductShowPage)
