import React from "react"
import styled from "@styled"
import { Field, Dropdown, Input, DatePicker } from "@components"
import { compose } from "recompose"
import { withStores } from "@enhancers/index"

export const Row = styled.div`
  display: flex;
  margin-top: 12px;

  &:nth-of-type(1) {
    margin-top: 0;
  }

  > div {
    margin-left: 16px;
    &:nth-of-type(1) {
      margin-left: 0;
    }

    > div {
      > div {
        font-weight: normal;
      }
    }
  }
`

const FilterPanel = (props) => (
  <div>
    <Row>
      <Field
        component={Dropdown}
        name="insuranceCompanyId"
        caption="บริษัทประกัน"
        captionStyle={{ fontWeight: "normal" }}
        options={props.insuranceCompanyOptions}
        leftWidth="100"
        rightWidth="250"
        disabled={props.disabled}
        placeholder="กรุณาเลือก"
      />
    </Row>
    <Row>
      <Field
        component={Input}
        name="customerName"
        caption="ชื่อลูกค้า"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="100"
        rightWidth="250"
        placeholder="กรุณาระบุ"
        disabled={props.disabled}
      />
    </Row>
    <Row>
      <Field
        component={DatePicker}
        name="coverageDate.min"
        caption="ช่วงวันที่คุ้มครอง"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="125"
        rightWidth="155"
        disabled={props.disabled}
        placeholder="กรุณาเลือก"
        customValidate={true}
        showErrorMessage={true}
      />
      <Field
        component={DatePicker}
        name="coverageDate.max"
        caption="ถึง"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="35"
        rightWidth="138"
        disabled={props.disabled}
        placeholder="กรุณาเลือก"
        customValidate={true}
        showErrorMessage={true}
      />
    </Row>
    <Row>
      <Field
        component={DatePicker}
        name="premiumPaidDate.min"
        caption="ช่วงวันชำระเงิน"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="125"
        rightWidth="155"
        disabled={props.disabled}
        placeholder="กรุณาเลือก"
        customValidate={true}
        showErrorMessage={true}
      />
      <Field
        component={DatePicker}
        name="premiumPaidDate.max"
        caption="ถึง"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="35"
        rightWidth="138"
        disabled={props.disabled}
        placeholder="กรุณาเลือก"
        customValidate={true}
        showErrorMessage={true}
      />
    </Row>
    <Row>
      <Field
        component={DatePicker}
        name="commissionReceivedDate.min"
        caption="ช่วงวันที่รับเงิน"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="125"
        rightWidth="155"
        disabled={props.disabled}
        placeholder="กรุณาเลือก"
        customValidate={true}
        showErrorMessage={true}
      />
      <Field
        component={DatePicker}
        name="commissionReceivedDate.max"
        caption="ถึง"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="35"
        rightWidth="138"
        disabled={props.disabled}
        placeholder="กรุณาเลือก"
        customValidate={true}
        showErrorMessage={true}
      />
    </Row>
  </div>
)

const enhancer = compose(
  withStores((stores) => ({
    insuranceCompanyOptions: stores.insurerCommissionStore.$(
      "configs.filters.insuranceCompany.options",
      []
    ),
  }))
)

export default enhancer(FilterPanel)
