import React, { Fragment } from "react"
import styled from "@styled"
import { compose, lifecycle, withHandlers, withProps } from "recompose"
import { withStores, withForm, withRouter } from "@enhancers"
import * as path from "@common/path"
import {
  Header as TextHeader,
  Form,
  Button,
  Field,
  InputName,
  Dropdown,
  DateInput,
  Input,
  CustomAddress as Address,
  BreadCrumb,
  PhoneNumberInput,
  CitizenIdInput,
  Checkbox,
  CurrencyInput,
} from "@components"
import { FormSection } from "redux-form"
import { BreadCrumbLayout } from "../show/index"
import { get, isEmpty } from "lodash"

export const Layout = styled(Form)`
  width: 100%;
  justify-content: center;
  margin-bottom: 50px;
`
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  line-height: normal;
`
export const LeftPanel = styled(TextHeader)`
  display: flex;
  align-items: center;
  color: #666666;
`
export const RightPanel = styled.div``
export const ButtonGroup = styled.div`
  > * {
    margin-left: 10px;
  }
`
export const ActionButton = styled(Button)`
  width: 126px;

  > span {
    font-size: 22px;
  }
`
const AppointmentButton = styled(Button)`
  width: 121px;

  > span {
    font-size: 18px;
  }
`
const Body = styled.div`
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #eeeef2;
`
const TextTopic = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  font-size: 16px;
  padding-left: 16px;
  color: #ffffff;
  font-family: Sarabun, sans-serif;
  font-weight: bold;
`
const TopicContainer = styled.div`
  width: 100%;
  height: 40px;
  background: #294558;
  opacity: 1;
`
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 50px;
  padding: 30px 20px;
`
const CustomInputName = styled(InputName)`
  > div:last-of-type {
    > div {
      > span {
        width: 120px;
      }
      > input {
        width: 158px;
      }
    }
  }
`

const LeadNewPage = (props) => (
  <Layout>
    <BreadCrumbLayout>
      <BreadCrumb
        links={[
          {
            label: "ลูกค้าใหม่",
            path:
              props.currentRole === "member.salesman" || props.currentRole === "member.team_leader"
                ? path.leadsPath("all")
                : path.leadsPath(props.batchId || "custom"),
          },
          { label: "สร้างลีด", path: path.newLeadCustomerInfoPath() },
        ]}
      />
    </BreadCrumbLayout>
    <Header>
      <LeftPanel>ข้อมูลลูกค้า</LeftPanel>
      <RightPanel>
        <ButtonGroup>
          <AppointmentButton
            icon="cancel"
            onClick={props.onCancel}
            children="ยกเลิก"
            styletype="secondary"
          />

          {props.location.query.batchId === "custom" ? (
            <ActionButton icon="plus" onClick={props.onSave} children="สร้าง" />
          ) : (
            <ActionButton icon="save" onClick={props.onSave} children="บันทึก" />
          )}
        </ButtonGroup>
      </RightPanel>
    </Header>

    <Body>
      <TopicContainer>
        <TextTopic>ข้อมูลส่วนบุคคล</TextTopic>
      </TopicContainer>

      <ContentContainer>
        <Field
          name="nameInfo"
          component={CustomInputName}
          caption="ชื่อ"
          options={props.prefixNameNewOptions}
          validate="nameInfo:standard"
          required
        />
        <Field
          name="gender"
          component={Dropdown}
          options={props.genderOptions}
          caption="เพศ"
          validate="required"
          rightWidth="200"
        />
        <Field name="birthdate" component={DateInput} caption="วันเกิด" validate="required" />
        <Field
          name="idCard"
          component={CitizenIdInput}
          caption="เลขที่บัตรประชาชน"
          rightWidth="200"
          validate="required"
        />
        <Field name="career" component={Input} caption="อาชีพ" rightWidth="200" />
        <Field name="income" component={CurrencyInput} caption="รายได้ (บาท/ปี)" rightWidth="200" />
        <Field
          name="mobilePhoneNumber"
          component={PhoneNumberInput}
          caption="เบอร์มือถือ"
          rightWidth="200"
          isMobileNumber={true}
        />
        <Field
          name="alternatePhoneNumber"
          component={Input}
          caption="เบอร์โทรสำรอง"
          rightWidth="200"
        />
        <Field name="email" component={Input} caption="อีเมล" rightWidth="200" />
      </ContentContainer>

      <TopicContainer>
        <TextTopic>ที่อยู่ปัจจุบัน</TextTopic>
      </TopicContainer>
      <ContentContainer>
        <FormSection name="currentAddress">
          <Address {...props} name="currentAddress" optional />
        </FormSection>
      </ContentContainer>

      <TopicContainer>
        <TextTopic>ที่อยู่ตามทะเบียนบ้าน</TextTopic>
      </TopicContainer>
      <ContentContainer>
        <FormSection name="homeAddress">
          <Field name="isSameCurrentAddress" component={Checkbox} label="เหมือนที่อยู่ปัจจุบัน" />
          {props.values.homeAddress ? (
            <Fragment>
              {!get(props.values, "homeAddress.isSameCurrentAddress") && (
                <Address {...props} name="homeAddress" optional />
              )}
            </Fragment>
          ) : (
            <Address {...props} name="homeAddress" optional />
          )}
        </FormSection>
      </ContentContainer>

      <TopicContainer>
        <TextTopic>ที่อยู่ที่ทำงาน</TextTopic>
      </TopicContainer>
      <ContentContainer>
        <FormSection name="officeAddress">
          <Field name="isSameCurrentAddress" component={Checkbox} label="เหมือนที่อยู่ปัจจุบัน" />
          {props.values.officeAddress ? (
            <Fragment>
              {!get(props.values, "officeAddress.isSameCurrentAddress") && (
                <Address {...props} name="officeAddress" optional />
              )}
            </Fragment>
          ) : (
            <Address {...props} name="officeAddress" optional />
          )}
        </FormSection>
      </ContentContainer>
    </Body>
  </Layout>
)

const enhancer = compose(
  withRouter(),
  withForm({ form: "newLeadInfo" }),
  withStores((stores, props) => ({
    createLead: stores.leadStore.createLead,
    configs: stores.leadStore.configs,
    fetchConfigs: stores.leadStore.fetchConfigs,
    prefixNameOptions: stores.leadStore.$("configs.attributes.prefixName.options", []),
    genderOptions: stores.leadStore.$("configs.attributes.gender.options", []),
  })),
  withHandlers({
    onCancel: (props) => () => {
      props.reset("newLeadInfo")
      props.history.push(path.leadsPath("custom"))
    },
    onSave: (props) =>
      props.handleSubmit(async (values) => {
        values = {
          ...values,
          homeAddress: get(values, "homeAddress.isSameCurrentAddress")
            ? values.currentAddress
            : values.homeAddress,
          officeAddress: get(values, "officeAddress.isSameCurrentAddress")
            ? values.currentAddress
            : values.officeAddress,
        }
        try {
          const response = await props.createLead(values)
          props.reset("newLeadInfo")
          props.history.push(path.leadPath(response.lead.id))
        } catch (error) {
          if (error.name === "SubmissionError") {
            if (!isEmpty(error.errors.mobilePhoneNumber)) {
              window.alert("สร้างลีดลูกค้าใหม่ไม่สำเร็จ เบอร์มือถือไม่ถูกต้อง")
            }

            throw error
          } else {
            window.alert("สร้างลีดลูกค้าใหม่ไม่สำเร็จ")
          }
        }
      }),
  }),
  withProps((props) => {
    const newOptions = []
    props.prefixNameOptions.forEach((option) =>
      newOptions.push({
        label: option.label,
        value: option.label,
      })
    )
    return { prefixNameNewOptions: newOptions }
  }),
  lifecycle({
    async componentDidMount() {
      this.props.fetchConfigs({ leadBatchId: "" })
    },
    componentDidUpdate(prevProps) {
      if (
        get(this.props, "values.nameInfo.prefixName") !==
        get(prevProps, "values.nameInfo.prefixName")
      ) {
        if (["นาย", "ด.ช."].includes(get(this.props, "values.nameInfo.prefixName"))) {
          this.props.change("gender", "male")
        } else if (
          ["นาง", "นางสาว", "ด.ญ."].includes(get(this.props, "values.nameInfo.prefixName"))
        ) {
          this.props.change("gender", "female")
        }
      }
    },
  })
)

export default enhancer(LeadNewPage)
