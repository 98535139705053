import React from "react"
import styled from "@emotion/styled"
import { compose, withHandlers, withState, lifecycle } from "recompose"
import {
  Button,
  Grid,
  GridColumn,
  makeCell,
  DatePicker,
  Dropdown,
  Authorize,
  Header as TextHeader,
  LoadingScope,
} from "@components"
import { withRouter, withStores } from "@enhancers/index"
import ActionCodeDetailModal from "./ActionCodeDetailModal"
import ActionCodeCreateModal from "./ActionCodeCreateModal"

const Layout = styled.div`
  width: 100%;
  justify-content: center;
  position: relative;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 29px;
  margin-bottom: 12px;
  line-height: normal;
`
const Topic = styled(TextHeader)`
  display: flex;
  align-items: center;
  color: #666666;
  margin-right: 20px;
`

const LeftPanel = styled.div`
  display: flex;
  align-items: center;
  > span:first-of-type {
    width: 190px;
  }
`
const RightPanel = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin-left: 10px;
  }
`
const ActionButton = styled(Button)`
  width: fit-content;
`

const Table = styled(Grid)`
  td {
    cursor: pointer;
    border-bottom: 1px solid #eeeef2 !important;
    height: 50px;
  }
`

const RequestRemark = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const CustomDropdown = styled(Dropdown)`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: unset;
`
const Overdue = styled.tr`
  td,
  td * {
    color: #d9534f;
  }
`

const DisplayRequestRemark = (cell) => (
  <td>
    {cell.dataItem.requestRemark && `คำถาม: ${cell.dataItem.requestRemark}`}
    {/* <RequestRemark>
      {cell.dataItem.actionRemark && `คำตอบ: ${cell.dataItem.actionRemark}`}
    </RequestRemark> */}
  </td>
)

const ActionCodeInfo = (props) => (
  <Layout>
    {props.loading && <LoadingScope />}

    <Header>
      <LeftPanel>
        <Topic>Action Code</Topic>
      </LeftPanel>
      <RightPanel>
        <Authorize
          roles={["user.admin", "member.team_leader", "member.salesman", "member.biz_dev"]}
        >
          <ActionButton
            onClick={props.onOpenActionCodeCreateModal}
            awesomeIcon="plus"
            children="สร้าง Action Code"
            disabled={props.orderState === "rejected"}
          />
        </Authorize>
      </RightPanel>
    </Header>
    <Table
      data={props.actionCodes}
      onRowClick={props.onClickRow}
      pageable
      skip={props.paging.skip}
      take={props.paging.perPage}
      total={props.paging.totalCount}
      onPageChange={props.onPageChange}
      rowRender={(trElement, cell) => {
        if (cell.dataItem.isOverdue)
          return <Overdue {...trElement.props}>{trElement.props.children}</Overdue>
        else return trElement
      }}
      // footer={`จำนวนงวดทั้งหมด : ${props.footer.actionCodesCount}`}
    >
      <GridColumn field="sequence" title="ครั้งที่" width="80px" />
      <GridColumn
        field="updatedAt"
        title="วันที่แจ้ง"
        width="100px"
        cell={makeCell(DatePicker, (cellProps) => ({
          readOnly: true,
          value: cellProps.dataItem.updatedAt,
        }))}
        filterable={false}
      />
      <GridColumn
        field="actionType"
        title="การติดต่อลูกค้า"
        width="150px"
        cell={makeCell(CustomDropdown, (cellProps) => ({
          readOnly: true,
          options: props.actionCodesOptions,
          value: cellProps.dataItem.actionType,
        }))}
        filterable={false}
      />
      <GridColumn field="actionRemark" title="คำตอบ" width="130px" filterable={false} />
      <GridColumn
        field="requestRemark"
        title="หมายเหตุ"
        cell={DisplayRequestRemark}
        filterable={false}
        width="170px"
      />
      <GridColumn
        field="actionStatus"
        title="สถานะ"
        width="200px"
        cell={(cellProps) => {
          if (cellProps.dataItem.isOverdue) {
            return <td>เลยกำหนด</td>
          } else {
            return (
              <td className={props.tdId}>
                <div>
                  <CustomDropdown
                    {...cellProps}
                    readOnly={true}
                    options={props.actionStatusOptions}
                    value={cellProps.dataItem.actionStatus}
                  />
                </div>
              </td>
            )
          }
        }}
        filterable={false}
      />
    </Table>
    <ActionCodeDetailModal
      open={props.isShowModal}
      onClose={props.onCloseActionCodeModal}
      title="Action Code"
      actionCode={props.current}
      actionCodesOptions={props.actionCodesOptions}
    />
    <ActionCodeCreateModal
      open={props.isShowCreateModal}
      onClose={props.onCloseActionCodeCreateModal}
      title="Action Code"
      form="Action-Code-Form"
      actionStatusOptions={props.actionStatusOptions}
      actionCodesOptions={props.actionCodesOptions}
      onAfterActionCode={props.onAfterActionCode}
    />
  </Layout>
)

const enhancer = compose(
  withRouter(),
  withStores((stores, props) => ({
    actionCodes: stores.actionCodeStore.actionCodes,
    paging: stores.actionCodeStore.paging,
    current: stores.actionCodeStore.current,
    actionCodeLists: stores.actionCodeStore.actionCodeLists,
    fetchConfigs: stores.actionCodeStore.fetchConfigs,
    fetchById: stores.actionCodeStore.fetchById,
    addActionCode: stores.actionCodeStore.addActionCode,
    configs: stores.actionCodeStore.configs,
    actionStatusOptions: stores.actionCodeStore.$("configs.attributes.actionStatus.options", []),
    actionCodesOptions: stores.actionCodeStore.$("configs.attributes.actionCode.options", []),
    footer: stores.actionCodeStore.footer,
    orderState: props.location?.state?.orderState || stores.orderStore.$("current.state", ""),
  })),
  withState("valueActionStatus", "setValueActionStatus", null),
  withState("isShowModal", "setIsShowModal", false),
  withState("isShowCreateModal", "setIsShowCreateModal", false),
  withState("loading", "setLoading", false),
  withHandlers({
    onPageChange: (props) => async (event) => {
      const page = event.page.skip / event.page.take + 1
      const orderId = props.match.params.orderId
      const params = {
        orderId: orderId,
        page: page,
      }
      await props.actionCodeLists(params)
    },
    onFilterActionStatusChange: (props) => async (value) => {
      props.setValueActionStatus(value)
      const orderId = props.match.params.orderId
      const params = {
        orderId: orderId,
        actionStatus: value,
      }
      await props.actionCodeLists(params)
    },
    onClickRow: (props) => async (value) => {
      const orderId = props.match.params.orderId
      const params = {
        orderId: orderId,
      }
      const action_code_id = value.dataItem.id
      await props.fetchById(action_code_id, params)
      props.setIsShowModal(true)
    },
    onCloseActionCodeModal: (props) => () => {
      props.setIsShowModal(false)
    },
    onOpenActionCodeCreateModal: (props) => () => {
      props.setIsShowCreateModal(true)
    },
    onCloseActionCodeCreateModal: (props) => async () => {
      props.setIsShowCreateModal(false)
    },
    onAfterActionCode: (props) => async (values) => {
      const orderId = props.match.params.orderId
      const params = {
        orderId: orderId,
        ...values,
      }
      await props.addActionCode(params)
      await props.actionCodeLists({
        orderId: orderId,
      })
    },
  }),
  lifecycle({
    async componentDidMount() {
      this.props.setLoading(true)
      const orderId = this.props.match.params.orderId
      const params = {
        orderId: orderId,
      }
      await Promise.all([this.props.fetchConfigs(params), this.props.actionCodeLists(params)])
      this.props.setLoading(false)
    },
  })
)

export default enhancer(ActionCodeInfo)
