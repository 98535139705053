import React from "react"
import styled from "@emotion/styled"
import { compose, withHandlers } from "recompose"
import { DialogActionsBar, Dialog, Button } from "@components"

const Layout = styled.div`
  padding: 25px 15px;
  font-size: 16;
  font-family: Sarabun, sans-serif;

  > span {
    font-weight: bold;
  }
  text-align: center;
`
const CustomDialog = styled(Dialog)`
  .k-window {
    border: 1px solid #e6e6e6;
    min-width: 348px;
  }
`
const CancelButton = styled(Button)`
  min-height: 52px;
`
const SubmitButton = styled(Button)`
  min-height: 52px;
  border-color: rgba(33, 37, 41, 0.125);
`

const ApproveOrderModal = (props) => (
  <CustomDialog title="แจ้งงาน" onClose={props.onClose} isShowModal={props.open} closeIcon={false}>
    <Layout>
      หากแจ้งงานแล้วจะ<span>ไม่สามารถแก้ไขข้อมูลได้</span>
      <br />
      กรุณาตรวจสอบความถูกต้องของข้อมูล
    </Layout>
    <DialogActionsBar>
      <SubmitButton
        type="button"
        onClick={props.onApproveOrder}
        children="ยืนยันความถูกต้อง"
        disabled={props.disabled}
      />
      <CancelButton
        type="button"
        onClick={props.onClose}
        children="ย้อนกลับ"
        styletype="delete"
        disabled={props.disabled}
      />
    </DialogActionsBar>
  </CustomDialog>
)

const enhancer = compose(
  withHandlers({
    onApproveOrder: (props) => async () => {
      props.onApproveOrder && (await props.onApproveOrder())
    },
    onClose: (props) => () => {
      props.onClose && props.onClose()
    },
  })
)
export default enhancer(ApproveOrderModal)
