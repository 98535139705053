import BaseStore from "./BaseStore"
import { tqldServer } from "@common/api"

class saleCommissionStore extends BaseStore {
  constructor() {
    super({
      all: [],
      salesCommissionMember: [],
      salesCommissionOrder: [],
      salesCommissionAffiliate: [],
      salesCommissionDetails: [],
      paging: {
        page: 1,
        perPage: 10,
        totalCount: 0,
        totalPages: 1,
      },
      configsById: {},
      configs: {},
      current: {},
      currentHistory: [],
    })
  }

  fetch = async (options) => {
    const response = await tqldServer.tqmBackend.get(`sales_commission_members`, {
      ...options,
      viewType: options.viewType !== "salesman" ? "orders" : "salesman",
    })
    this.all = response.data.data.salesCommissionMembers
    this.paging = response.data.data.paging

    return response.data.data.salesCommissionMembers
  }

  fetchById = async (id, options) => {
    const response = await tqldServer.tqmBackend.get(`sales_commission_members/${id}`, {
      ...options,
    })
    this.current = response.data.data.salesCommissionMember
    this.salesCommissionDetails = response.data.data.salesCommissionDetails
    this.paging = response.data.data.paging
  }

  fetchConfigById = async (id) => {
    const response = await tqldServer.tqmBackend.get(`sales_commission_members/${id}/configs`)
    this.configsById = response.data.data.configs
  }

  fetchConfig = async () => {
    const response = await tqldServer.tqmBackend.get(`sales_commission_members/configs`)
    this.configs = response.data.data.configs
  }

  fetchCommissionOrders = async (options) => {
    const response = await tqldServer.tqmBackend.get(
      `sales_commission_members/sales_commission_orders`,
      options
    )
    this.all = response.data.data.salesCommissionOrders
    this.paging = response.data.data.paging

    return response.data.data.salesCommissionOrders
  }

  export = async (params) => {
    await tqldServer.tqmBackend.download(`sales_commission_members/export`, params)
  }

  updateFullPaid = async (isFullPaid, id) => {
    await tqldServer.tqmBackend.put(`sales_commission_members/${id}`, isFullPaid)
  }

  fetchSalesCommissionOrders = async (options) => {
    const response = await tqldServer.tqmBackend.get(`sales_commission_masters`, options)
    this.all = response.data.data.salesCommissionMasters
    this.paging = response.data.data.paging

    return response.data.data.salesCommissionMasters
  }

  fetchSalesCommissionOrdersConfig = async () => {
    const response = await tqldServer.tqmBackend.get(`sales_commission_masters/configs`)
    this.configs = response.data.data.configs
  }

  fetchSalesCommissionOrdersById = async (id) => {
    const response = await tqldServer.tqmBackend.get(`sales_commission_masters/${id}`)
    this.current = response.data.data.salesCommissionMaster
  }

  fetchSalesCommissionAffiliates = async (options) => {
    const response = await tqldServer.tqmBackend.get(`sales_commission_affiliates`, options)
    this.all = response.data.data.salesCommissionAffiliates
    this.paging = response.data.data.paging

    return response.data.data.salesCommissionAffiliates
  }

  fetchSalesCommissionAffiliatesConfig = async () => {
    const response = await tqldServer.tqmBackend.get(`sales_commission_affiliates/configs`)
    this.configs = response.data.data.configs
  }

  fetchSalesCommissionAffiliatesById = async (id) => {
    const response = await tqldServer.tqmBackend.get(`sales_commission_affiliates/${id}`)
    this.salesCommissionAffiliate = response.data.data.salesCommissionAffiliateTeam
    this.salesCommissionDetails = response.data.data.salesCommissionDetails
    this.paging = response.data.data.paging
  }

  exportToVerify = async (params) => {
    await tqldServer.tqmBackend.download(`sales_commission_masters/export_to_verify`, params)
  }

  updateSalesCommissionOrders = async (id, params) => {
    await tqldServer.tqmBackend.patch(`sales_commission_masters/${id}`, params)
  }

  editingApprove = async (id) => {
    await tqldServer.tqmBackend.patch(`sales_commission_masters/${id}/editing_approve`)
  }

  salesCommissionVerify = async (params) => {
    await tqldServer.tqmBackend.patch(`sales_commission_masters/verify`, params)
  }

  exportOrders = async (params) => {
    await tqldServer.tqmBackend.download(`sales_commission_masters/export`, params)
  }

  exportAffiliate = async (params) => {
    await tqldServer.tqmBackend.download(`sales_commission_affiliates/export`, params)
  }

  fetchHistory = async (id) => {
    const response = await tqldServer.tqmBackend.get(`sales_commission_masters/${id}/history`)
    this.currentHistory = response.data.data.histories
  }
}

export default new saleCommissionStore()
