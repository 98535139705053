import React, { Fragment } from "react"
import styled from "@emotion/styled"
import { compose, withHandlers } from "recompose"
import { DialogActionsBar, Dialog, Button } from "@components"
import { withStores } from "@enhancers/index"

const Layout = styled.div`
  padding: 35px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const SuccessLayout = styled.div`
  text-align: center;
  font-size: 16px;
  font-family: Sarabun, sans-serif;
  > span {
    color: #5cb85c;
    font-weight: bold;
  }
`
const CustomDialog = styled(Dialog)`
  .k-window {
    border: 1px solid #e6e6e6;
    min-width: 350px;
  }
`
const CancelButton = styled(Button)`
  min-height: 52px;
`
const LeadAssignDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  width: 100%;

  > span {
    font-family: Sarabun, sans-serif;
    color: #666666;
    margin-left: 10px;
  }
`
const LeadDetail = styled.div`
  display: flex;
  max-width: 450px;
  width: 100vw;

  > span:first-of-type {
    width: 50%;
    margin-left: 20px;
    font-family: Sarabun, sans-serif;
    color: #666666;
  }

  > span:last-of-type {
    width: 50%;
    font-family: Sarabun, sans-serif;
    color: #0275d8;
    font-weight: bold;
  }
`
const AssignedSuccessModal = (props) => (
  <CustomDialog title={props.modalTitle} onClose={props.onClose} isShowModal={props.open}>
    <Layout>
      <SuccessLayout>
        <span>{props.infoCaption}</span> <br />
      </SuccessLayout>
      <LeadAssignDetailContainer>
        <span>ข้อมูลการมอบหมายลีด</span>
        <LeadDetail>
          <span>จำนวน</span>
          <span>
            {props.details && props.details.selectedLeads && props.details.selectedLeads.length} ลีด
          </span>
        </LeadDetail>
        {props.details && props.details.teams && props.details.teams.haveValues && (
          <LeadDetail>
            <span>{props.details.teams.caption}</span>
            <span>
              {props.details.teams.values.map((value) => (
                <Fragment key={value}>
                  <span>{value}</span>
                  <br />
                </Fragment>
              ))}
            </span>
          </LeadDetail>
        )}
        {props.details && props.details.sales && props.details.sales.haveValues && (
          <LeadDetail>
            <span>{props.details.sales.caption}</span>
            <span>
              {props.details.sales.values.map((value) => (
                <Fragment key={value}>
                  <span>{value}</span>
                  <br />
                </Fragment>
              ))}
            </span>
          </LeadDetail>
        )}
      </LeadAssignDetailContainer>
    </Layout>
    <DialogActionsBar>
      <CancelButton
        type="button"
        onClick={props.onClose}
        children={props.buttonTitle || "ตกลง"}
        styletype="delete"
      />
    </DialogActionsBar>
  </CustomDialog>
)

const enhancer = compose(
  withStores((stores, props) => ({
    currentRole: stores.appStore.currentRole,
  })),
  withHandlers({
    onAttachDoc: (props) => async () => {
      props.onAttachDoc && props.onAttachDoc()
    },
    onClose: (props) => () => {
      props.onClose && props.onClose()
    },
  })
)
export default enhancer(AssignedSuccessModal)
