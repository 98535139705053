import React, { Fragment } from "react"
import styled from "@emotion/styled"
import { compose, withHandlers, withState } from "recompose"
import { Button, Dialog } from "@components"
import * as path from "@common/path"

import { withStores } from "@enhancers/index"

const CustomDialog = styled(Dialog)`
  .custom-title {
    font-size: 16px;
    font-weight: bold;
  }

  .k-window-titlebar {
    padding: 0 11px;
  }
`
const Body = styled.div`
  padding: 30px 0px;
`

const ContainerButton = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  > button {
    display: flex;
    justify-content: flex-start;
    width: 230px;
  }
  span {
    margin-right: 20px !important;
    font-size: 20px;
  }
  > button :last-child {
    margin-top: 20px;
  }
`

const PlanningModal = (props) => (
  <Fragment>
    <CustomDialog
      title={<p className="custom-title">โปรดเลือกวิธีค้นหาแบบประกัน</p>}
      onClose={() => props.onClose()}
      isShowModal={props.open}
      width="348px"
    >
      <Body>
        <ContainerButton>
          <Button icon="search" onClick={props.onPlanning}>
            วางแผนค้นหาแบบประกัน
          </Button>
          <Button icon="group" onClick={props.onOpenAndCloseSearchModel}>
            เลือกแบบประกัน
          </Button>
        </ContainerButton>
      </Body>
    </CustomDialog>
  </Fragment>
)

const enhancer = compose(
  withState("showSearchModal", "setShowSearchModal", false),
  withStores((stores, props) => ({
    storeSearchProductParams: stores.productStore.storeSearchProductParams,
    leadId: props.match.params.leadId,
  })),
  withHandlers({
    onPlanning: (props) => () => {
      const currentPage = props.batchId ? "batch_lead" : "custom_lead"
      const params = {}
      if (props.leadId) {
        params.leadId = props.leadId
        params.birthdate = props.lead.customerInfo.birthdate
        params.gender = props.lead.customerInfo.gender
        params.from = currentPage
      }
      props.history.push(path.planningPath(params))
      props.onClose()
    },
    onAddProduct: (props) =>
      props.handleSubmit((values) => {
        props.storeSearchProductParams({
          gender: values.gender || "male",
          age: values.age,
          sumInsured: values.sumInsured,
        })
        props.history.push(
          path.productsPath(props.leadId, {
            gender: values.gender || "male",
            age: values.age,
            sumInsured: values.sumInsured,
          })
        )
      }),
    onOpenAndCloseSearchModel: (props) => () => {
      const currentPage = props.batchId ? "batch_lead" : "custom_lead"
      const params = {}
      if (props.lead) {
        const prefixName = props.lead.customerInfo.nameInfo.prefixName
        const prefixNameToGender = {
          นาย: "male",
          นาง: "female",
          นางสาว: "female",
          "น.ส.": "female",
          "ด.ช.": "male",
          "ด.ญ.": "female",
        }
        if (props.leadId) {
          params.leadId = props.leadId
          params.gender = prefixNameToGender[prefixName]
          params.from = currentPage
        }
      }

      props.history.push(path.productsSearchPath(params))
      props.onClose()
    },
  })
)
export default enhancer(PlanningModal)
