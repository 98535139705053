import React from "react"
import styled from "@emotion/styled"
import { compose, withHandlers, withProps } from "recompose"
import { withForm, withStores, withRouter } from "@enhancers/index"
import { Button, Field, CurrencyInput, GenderInput, DatePicker } from "@components"
import { PanelBarItem } from "@progress/kendo-react-layout"
import * as path from "@common/path"
import { toCurrency, convertNaNtoZero, convertMinustoZero } from "@common/helper"

import {
  CustomPanelBar,
  Body,
  Layout,
  BoldText,
  Footer,
  ResultContainer,
  TextResult,
  ValueResult,
  AgeAndGenderLayout,
} from "../DebtProtectionForm/AdvanceForm"
import { WrapInputDate, AgeLabel } from "../DebtProtectionForm/SimpleForm"
import moment from "moment"

const ConfirmButton = styled(Button)`
  display: flex;
  margin: auto;
  width: fit-content;
  margin-top: 16px;
`
const Unit = styled.div``

const AgeUnit = styled.div`
  width: 29px;
`

const Planning = (props) => (
  <Layout>
    <Body>
      <AgeAndGenderLayout>
        <Field
          component={GenderInput}
          name="gender"
          caption="เพศ"
          rightWidth="200"
          placeholder="กรุณาระบุ"
          validate="required"
        />
        <AgeUnit />
        <WrapInputDate>
          {props.values.birthdate && (
            <AgeLabel show={props.values.birthdate !== null}>
              (อายุ {moment().diff(moment(props.values.birthdate), "years")} ปี)
            </AgeLabel>
          )}
          <Field
            caption="วันเกิด"
            name="birthdate"
            component={DatePicker}
            placeholder="กรุณาระบุ"
            validate="required"
            rightWidth="200"
            leftWidth="225"
            max={new Date()}
          />
        </WrapInputDate>
        <AgeUnit />
      </AgeAndGenderLayout>
      <CustomPanelBar>
        <PanelBarItem expanded={true} title="สมมติฐาน">
          <Field
            component={CurrencyInput}
            name="income"
            caption="รายได้พึงประเมิน"
            rightWidth="200"
            placeholder="กรุณาระบุ"
            min={0}
          />
          <Unit>บาท/ปี</Unit>
          <Field
            component={CurrencyInput}
            name="lifePremiumCost"
            caption="เบี้ยประกันชีวิตทั่วไปที่ซื้อแล้ว"
            rightWidth="200"
            placeholder="กรุณาระบุ"
            min={0}
          />
          <Unit>บาท/ปี</Unit>
          <Field
            component={CurrencyInput}
            name="healthPremiumCost"
            caption="เบี้ยประกันสุขภาพที่ซื้อแล้ว"
            rightWidth="200"
            placeholder="กรุณาระบุ"
            min={0}
          />
          <Unit>บาท/ปี</Unit>
          <Field
            component={CurrencyInput}
            name="firstSummary"
            caption="รวมสิทธิ"
            leftWidth="300"
            placeholder="กรุณาระบุ"
            readOnly
          />
          <Unit>บาท/ปี</Unit>
          <Field
            component={CurrencyInput}
            name="premiumRetire"
            caption="เบี้ยประกันประเภทบำนาญที่ซื้อแล้ว"
            rightWidth="200"
            placeholder="กรุณาระบุ"
            min={0}
          />
          <Unit>บาท/ปี</Unit>
          <Field
            component={CurrencyInput}
            name="rmf"
            caption="กองทุนรวมเพื่อการเลี้ยงชีพ (RMF) ที่ลงทุนแล้ว"
            rightWidth="200"
            placeholder="กรุณาระบุ"
            min={0}
          />
          <Unit>บาท/ปี</Unit>
          <Field
            component={CurrencyInput}
            name="providentFund"
            caption="กองทุนสำรองเลี้ยงชีพ/ กบข./ กองทุนสงเคราะห์ครูที่ลงทุนแล้ว"
            rightWidth="200"
            placeholder="กรุณาระบุ"
            min={0}
          />
          <Unit>บาท/ปี</Unit>
          <Field
            component={CurrencyInput}
            name="secondSummary"
            caption="รวมสิทธิ"
            leftWidth="300"
            placeholder="กรุณาระบุ"
            readOnly
          />
          <Unit>บาท/ปี</Unit>
        </PanelBarItem>
      </CustomPanelBar>
    </Body>
    <Footer>
      <ResultContainer>
        <TextResult>
          คุณสามารถใช้แบบประกันประเภท<BoldText> ตลอดชีพ </BoldText>หรือ{" "}
          <BoldText>สะสมพทรัพย์</BoldText> หรือ <BoldText>บำนาญ</BoldText>
          ในการสร้างความคุ้มครองนี้โดยเบี้ยประกันจะอยู่ในช่วง
        </TextResult>

        <ValueResult>
          {toCurrency(convertMinustoZero(props.values.health))} บาท สำหรับประกันสุขภาพ
        </ValueResult>
        <ValueResult>
          {toCurrency(convertMinustoZero(props.values.life))} บาท สำหรับประกันชีวิต
        </ValueResult>
        <ValueResult>
          {toCurrency(convertMinustoZero(props.values.retire))} บาท สำหรับประกันประเภทบำนาญ
        </ValueResult>
      </ResultContainer>

      <ConfirmButton icon="arrow-right" onClick={props.onSubmit} disabled={props.values.life <= 0}>
        ถัดไป
      </ConfirmButton>
    </Footer>
  </Layout>
)

const enhancer = compose(
  withRouter({
    queryTypes: {
      birthdate: "date",
    },
  }),
  withProps((props) => ({
    yesNoOptions: [
      { label: "ใช่", value: true },
      { label: "ไม่ใช่", value: false },
    ],
  })),
  withStores((stores, props) => ({
    fetchById: stores.leadStore.fetchById,
    initialValues: {
      birthdate: props.location.query.birthdate,
      gender: props.location.query.gender,
      getMoneyWhenDead: true,
      getRebate: true,
    },
  })),
  withForm({
    form: "planningSavingForm",
    enableReinitialize: true,
    onChange: (values, _, props) => {
      let incomeCal = convertNaNtoZero(values.income)
      let lifePremiumCostCal = convertNaNtoZero(values.lifePremiumCost)
      let healthPremiumCostCal = convertNaNtoZero(values.healthPremiumCost)
      let premiumRetireCal = convertNaNtoZero(values.premiumRetire)
      let rmfCal = convertNaNtoZero(values.rmf)
      let providentFundCal = convertNaNtoZero(values.providentFund)

      let totalHealth = convertNaNtoZero(lifePremiumCostCal + healthPremiumCostCal)
      let tooltipAmount = convertNaNtoZero(incomeCal * 0.15 > 200000 ? 200000 : incomeCal * 0.15)
      let secondSummary = convertNaNtoZero(premiumRetireCal + rmfCal + providentFundCal)

      values.firstSummary = totalHealth
      values.secondSummary = secondSummary
      values.health = convertNaNtoZero(
        totalHealth >= 100000 ? 0 : Math.round(15000 - healthPremiumCostCal)
      )
      values.life = convertNaNtoZero(totalHealth >= 100000 ? 0 : Math.round(100000 - totalHealth))
      values.retire = convertNaNtoZero(
        Math.round(tooltipAmount + 100000 - totalHealth - premiumRetireCal) <= 0
          ? 0
          : Math.round(tooltipAmount + 100000 - totalHealth - premiumRetireCal)
      )
    },
  }),
  withHandlers({
    onSubmit: (props) =>
      props.handleSubmit(async (values) => {
        let life = convertNaNtoZero(values.life)

        const { query } = props.location || {}
        const { leadId } = query || {}

        props.history.push(
          path.productsPath(leadId, {
            ...props.location.query,
            birthdate: values.birthdate,
            gender: values.gender,
            searchValue: life,
            type: "whole_life",
            searchBy: "sum_insured",
          })
        )
      }),
  })
)
export default enhancer(Planning)
