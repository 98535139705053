import React from "react"
import styled from "@emotion/styled"
import { compose, withState, withHandlers } from "recompose"
import { withForm, withStores, withRouter } from "@enhancers"
import {
  Button,
  Input,
  Field,
  Dropdown,
  TabStripTab,
  Avatar,
  Address,
  Grid as Table,
  GridColumn,
  BrowseButton,
} from "@components"
import * as path from "@common/path"
import { Layout, Header } from "../show/InsurerInfo"
import { MenuTab } from "../../leads/show/CustomerInfo"
import { exceptNullKeys } from "@common/helper"

const LeftPanel = styled.div`
  font-size: 30px;
  font-weight: bold;
  font-family: ThaiSans Neue;
  display: flex;
  align-items: center;
  color: #666666;
`
const RightPanel = styled.div``
const ButtonGroup = styled.div`
  > * {
    margin-left: 10px;
  }
`
const DetailContainer = styled.div`
  opacity: 1;
  padding: 10px 15px;
`

const CancelButton = styled(Button)`
  width: 90px;
`
const SubmitButton = styled(Button)`
  width: 100px;
`
const HeadButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const InsurerInfo = (props) => (
  <Layout>
    <Header>
      <LeftPanel>ข้อมูลบริษัทประกัน</LeftPanel>
      <RightPanel>
        <ButtonGroup>
          <CancelButton icon="cancel" onClick={props.onBack} children="ยกเลิก" styletype="delete" />
          <SubmitButton
            icon="floppy"
            onClick={props.onCreate}
            children="บันทึก"
            styletype="agree"
          />
        </ButtonGroup>
      </RightPanel>
    </Header>

    <MenuTab selected={props.tabSelected} onSelect={props.onTabChange}>
      <TabStripTab title="ข้อมูลบริษัทประกัน">
        <DetailContainer>
          <Field name="logo" caption="โลโก้" component={Avatar} leftWidth="200" />
          <Field name="name" caption="ชื่อ" component={Input} validate="required" leftWidth="200" />
          <Field
            name="shortName"
            caption="ชื่อย่อ"
            component={Input}
            validate="required"
            leftWidth="200"
          />
          <Field
            name="referenceCode"
            caption="รหัสอ้างอิง (สมุดคุม) TQM"
            component={Input}
            validate="required"
            leftWidth="200"
          />
          <Field name="website" caption="เว็บไซต์" component={Input} leftWidth="200" />
          <Field
            name="companyType"
            caption="ประเภท"
            component={Dropdown}
            options={props.typeOptions}
            validate="required"
            leftWidth="200"
          />
          <Field
            name="ageCalculationType"
            caption="การคำนวณอายุ"
            component={Dropdown}
            options={props.ageCalculationTypeOptions}
            validate="required"
            leftWidth="200"
          />
        </DetailContainer>
      </TabStripTab>

      <TabStripTab title="ที่อยู่บริษัทประกัน">
        <DetailContainer>
          <Field name="address" component={Address} />
        </DetailContainer>
      </TabStripTab>

      <TabStripTab title="ขั้นอาชีพ">
        <DetailContainer>
          <HeadButtonContainer>
            <BrowseButton
              accept=".csv, .xls, .xlsx"
              onClick={props.onImport}
              multiple={false}
              awesomeIcon="upload"
              children="นำเข้าไฟล์"
            />
          </HeadButtonContainer>

          <Table
            data={props.occupations.slice(props.skip, props.take + props.skip)}
            skip={props.skip}
            take={props.take}
            total={props.occupations.length}
            pageable={{
              buttonCount: 5,
            }}
            onPageChange={props.pageChange}
            style={{ marginTop: 27 }}
          >
            <GridColumn title="กลุ่มอาชีพ" field="category" width="200" />
            <GridColumn title="อาชีพ" field="name" width="200" />
            <GridColumn title="ขั้นอาชีพ" field="level" width="100" />
            <GridColumn title="เพิ่มเบี้ยพิเศษ (ต่อทุน1000)" field="specialPremium" width="205" />
          </Table>
        </DetailContainer>
      </TabStripTab>
    </MenuTab>
  </Layout>
)

const enhancer = compose(
  withRouter(),
  withStores((stores, props) => ({
    createInsurer: stores.insurerStore.create,
    insurerId: props.match.params.insurerId,
    initialValues: stores.insurerStore.current,
    fetchPreviewOccupations: stores.insurerStore.fetchPreviewOccupations,
    downloadOccupation: stores.insurerStore.downloadOccupation,
    typeOptions: stores.insurerStore.$("configs.attributes.companyType", []),
    stateOptions: stores.insurerStore.$("configs.attributes.state.options", []),
    ageCalculationTypeOptions: stores.insurerStore.$(
      "configs.attributes.ageCalculationType.options",
      []
    ),
  })),
  withState("tabSelected", "setTabSelected", 0),
  withState("occupations", "setOccupations", []),
  withState("skip", "setSkip", 0),
  withState("take", "setTake", 10),
  withForm({ form: "newInsurerInfo" }),
  withHandlers({
    onTabChange: (props) => (event) => {
      props.setTabSelected(event.selected)
    },
    onBack: (props) => () => {
      props.history.push(path.insurersPath())
    },
    onCreate: (props) =>
      props.handleSubmit(async (values) => {
        const exceptNullParams = exceptNullKeys(values, "address")

        const insurer = await props.createInsurer({
          ...exceptNullParams,
          occupations: props.occupations,
        })
        props.history.push(path.insurerPath(insurer.id))
      }),
    onImport: (props) => async (file) => {
      const occupations = await props.fetchPreviewOccupations({ file })
      props.setOccupations(occupations)
    },
    pageChange: (props) => (event) => {
      props.setSkip(event.page.skip)
      props.setTake(event.page.take)
    },
  })
)

export default enhancer(InsurerInfo)
