import React from "react"
import { Switch, Route } from "react-router-dom"
import { compose, lifecycle } from "recompose"
import { withRouter, withStores } from "@enhancers"
import * as path from "@common/path"
import { Link, Icon } from "@components"
import styled, { css } from "@styled"

import Full from "./AdvanceForm"
import Short from "./SimpleForm"

const Layout = styled.div``

const AllContainer = styled.div`
  width: 532px;
  margin-top: 16px;
  border: 1px solid #e6e6e6;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 16px 0px;
  > div {
    :last-child {
      margin: unset;
    }
  }
`

const TextHeader = styled.div`
  font-size: 20px;
  text-align: center;
`
const IconHeader = styled.div`
  color: #666666;
  text-align: center;
  .k-icon {
    font-size: 50px;
    margin-bottom: 13px;
  }
`

const Menu = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
  > a:first-of-type {
    border-radius: 4px 0px 0px 4px;
  }
  > a:last-child {
    width: 113px;
    border-radius: 0px 4px 4px 0px;
  }
`
const MenuItem = styled(Link)`
  background: #ffffff;
  border: 1px solid #deeaf6;
  opacity: 1;
  width: 88px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #deeaf6;
  font-size: 16px;
  font-family: regular;
  :active,
  :hover {
    opacity: 0.4;
    color: #0275d8;
  }

  ${(props) =>
    props.active &&
    css`
      border: 1px solid #0275d8;
      pointer-events: none;

      background: white;
      color: #0275d8;
      font-family: Sarabun, sans-serif;
      font-weight: bold;
    `}
`
const Body = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

const LeadShow = (props) => (
  <Layout>
    <Body>
      <AllContainer>
        <IconHeader>
          <Icon awesomeIcon="coins" />
        </IconHeader>
        <TextHeader>วางแผนเพื่อสร้างความคุ้มครองด้านรายได้ของครอบครัว</TextHeader>
        <Menu>
          <MenuItem
            to={path.planningTypeShortPath(props.match.params.type, {
              leadId: props.location.query.leadId,
              birthdate: props.location.query.birthdate,
              gender: props.location.query.gender,
              from: props.location.query.from,
            })}
            active={props.location.pathname === "/products/planning/protectIncome/short"}
          >
            แบบง่าย
          </MenuItem>
          <MenuItem
            to={path.planningTypeFullPath(props.match.params.type, {
              leadId: props.location.query.leadId,
              birthdate: props.location.query.birthdate,
              gender: props.location.query.gender,
              from: props.location.query.from,
            })}
            active={props.location.pathname === "/products/planning/protectIncome/full"}
          >
            แบบละเอียด
          </MenuItem>
        </Menu>
        <Switch>
          <Route path={path.planningTypeShortPath(":type")} component={Short} />
          <Route path={path.planningTypeFullPath(":type")} component={Full} />
          />
        </Switch>
      </AllContainer>
    </Body>
  </Layout>
)

const enhancer = compose(
  withRouter(),
  withStores((stores, props) => ({})),
  lifecycle({})
)

export default enhancer(LeadShow)
