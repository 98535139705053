import React from "react"
import { compose, lifecycle, withHandlers, withProps, withState } from "recompose"
import { withRouter, withStores } from "@enhancers/index"
import { Icon, GridColumn } from "@components"
import { exceptNullKeys } from "@common/helper"
import {
  Layout,
  TitleText,
  Body,
  Header,
  HeaderColumn,
  CustomTable as Table,
} from "./InsuranceCompany"
import FilterPanel from "./FilterPanel"
import { InsuranceCompany, CompanyLogo, CatagoryText } from "../index/InfoCard/InsuranceCompanyCard"
import FilterDate from "../index/FilterDate"
import { isEmpty } from "lodash"

const InsurerCommissionDashboard = (props) => {
  return (
    <Layout>
      <TitleText>คอมมิชชันแยกตามบริษัทประกัน</TitleText>
      <Body>
        <Header>
          <FilterDate onSubmit={props.onDateFilterSubmit} />
        </Header>
        <Table
          pageable
          data={props.insurerCommission}
          loadingTable={props.isLoading}
          filterIndex={props.filterIndex}
          skip={props.paging.skip}
          take={props.paging.perPage}
          total={props.paging.totalCount}
          onPageChange={props.onPageChange}
        >
          <GridColumn
            title="บริษัทประกัน"
            field="companyName"
            cell={(cell) => {
              return (
                <td>
                  <InsuranceCompany>
                    <CompanyLogo src={cell.dataItem.logo} />
                    <CatagoryText>{cell.dataItem.name}</CatagoryText>
                  </InsuranceCompany>
                </td>
              )
            }}
            columnMenu={(columnProps) => (
              <FilterPanel
                {...columnProps}
                onSubmit={props.filterChange}
                options={props.insuranceCompanyOptions}
                initialValues={props.filterCompanyColumn}
              />
            )}
            width="144"
          />
          <GridColumn
            title={
              <HeaderColumn
                onClick={props.sortingChange(
                  "net_premium",
                  props.sortTotalPremium,
                  props.setSortTotalPremium
                )}
                isactive={props.sortTotalPremium !== null}
              >
                เบี้ยประกัน
                <div>
                  <Icon awesomeIcon="sort" />
                </div>
              </HeaderColumn>
            }
            field="netPremium"
            width="144"
          />
          <GridColumn
            title={
              <HeaderColumn
                onClick={props.sortingChange(
                  "commission_amount",
                  props.sortCommission,
                  props.setSortCommission
                )}
                isactive={props.sortCommission !== null}
              >
                คอมมิชชัน
                <div>
                  <Icon awesomeIcon="sort" />
                </div>
              </HeaderColumn>
            }
            field="commissionAmount"
            width="144"
          />
        </Table>
      </Body>
    </Layout>
  )
}

const enhancer = compose(
  withRouter(),
  withStores((stores) => ({
    paging: stores.dashboardStore.paging,
    fetchConfig: stores.dashboardStore.fetchConfig,
    fetchDashboardInsurerCommission: stores.dashboardStore.fetchDashboardInsurerCommission,
    insurerCommission: stores.dashboardStore.insurerCommission,
    insuranceCompanyOptions: stores.dashboardStore.$("configs.filter.insuranceCompany.options", []),
  })),
  withState("isLoading", "setIsLoading", false),
  withState("sortCompanyName", "setSortCompanyName", null),
  withState("sortTotalPremium", "setSortTotalPremium", null),
  withState("sortCommission", "setSortCommission", null),
  withState("filterIndex", "setFilterIndex", null),
  withState("filterTable", "setFilterTable", null),
  withState("filterCompanyColumn", "setFilterCompanyColumn", {}),
  withState("exportFilter", "setExportFilter", null),
  withHandlers({
    resetFiltering: (props) => () => {
      props.setSortCompanyName(null)
      props.setSortTotalPremium(null)
      props.setSortCommission(null)
      props.setFilterIndex(null)
      props.setFilterTable({})
      props.setFilterCompanyColumn({})
    },
  }),
  withProps((props) => ({
    paging: {
      ...props.paging,
      skip: (props.paging.page - 1) * props.paging.perPage,
    },
    defaultFilterDate: {
      periodType: "yearly",
      submittedAt: {
        min: new Date(new Date().getFullYear(), 0, 1),
        max: new Date(new Date().getFullYear(), 11, 31),
      },
    },
  })),
  withHandlers({
    onPageChange: (props) => async (event) => {
      const page = event.page.skip / event.page.take + 1
      props.setIsLoading(true)
      await props.fetchDashboardInsurerCommission({
        ...props.filterTable,
        page,
        perPage: 10,
      })
      props.setIsLoading(false)
    },
    filterChange: (props) => async (filter) => {
      const filterValues = []

      filter.selectedValues.find((item) => {
        if (item.selected === true) {
          filterValues.push(item.value)
        }
      })

      if (!isEmpty(filterValues)) {
        props.resetFiltering()
      }

      let params = {}

      switch (filter.orderBy) {
        case "companyName":
          params = {
            insuranceCompanyIds: filterValues,
            orderBy: "insurance_company_name",
            order: filter.order,
          }
          props.setFilterCompanyColumn(filter)
          props.setFilterIndex(1)
          break

        default:
          break
      }

      const exceptNullParams = exceptNullKeys(params, "orderBy", "order")
      const dateFilter = !isEmpty(props.exportFilter) ? props.exportFilter : props.defaultFilterDate

      props.setFilterTable({ ...exceptNullParams, ...dateFilter })
      props.setIsLoading(true)
      await props.fetchDashboardInsurerCommission({ ...dateFilter, ...exceptNullParams })
      props.setIsLoading(false)
    },
    sortingChange: (props) => (column, orderType, setSortValue) => async () => {
      let orderTypeFilter = null
      if (orderType === null) {
        orderTypeFilter = "desc"
      } else if (orderType === "desc") {
        orderTypeFilter = "asc"
      } else {
        orderTypeFilter = null
      }
      const params = {
        orderBy: column,
        order: orderTypeFilter,
      }
      const exceptNullParams = exceptNullKeys(params, "orderBy", "order")
      const dateFilter = !isEmpty(props.exportFilter) ? props.exportFilter : props.defaultFilterDate

      props.setFilterTable({ ...exceptNullParams, ...dateFilter })

      props.setIsLoading(true)
      try {
        if (orderTypeFilter !== null) {
          await props.fetchDashboardInsurerCommission({ ...dateFilter, ...exceptNullParams })
        } else {
          await props.fetchDashboardInsurerCommission(dateFilter)
        }
      } catch (error) {}

      props.resetFiltering()
      setSortValue(orderTypeFilter)
      props.setIsLoading(false)
    },
    onDateFilterSubmit: (props) => async (values) => {
      props.setIsLoading(true)
      try {
        await props.fetchDashboardInsurerCommission(values)
      } catch (error) {}
      props.setIsLoading(false)

      props.setExportFilter(values)
      props.resetFiltering()
    },
  }),
  lifecycle({
    async componentDidMount() {
      this.props.setIsLoading(true)
      await Promise.all([this.props.fetchDashboardInsurerCommission(), this.props.fetchConfig()])
      this.props.setIsLoading(false)
    },
  })
)

export default enhancer(InsurerCommissionDashboard)
