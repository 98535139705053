import React from "react"
import styled from "@emotion/styled"
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout"

const Layout = styled.div`
  flex: 1;
  width: 100%;
`
const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
`
const Title = styled.div`
  width: 300px;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
`
const Detail = styled.div`
  /* padding: 10px; */
  font-family: Sarabun, sans-serif;
  font-size: 16px;
`

const Pb = (props) => (
  <Layout>
    <PanelBar>
      <PanelBarItem expanded={true} title="รายละเอียด">
        {Object.keys(props.riderPolicyDetail.protectionDetail.pb).map((key) => (
          <ContentContainer>
            <Title>{props.riderPolicyDetail.protectionDetail.pb[key].label}</Title>
            <Detail>{props.riderPolicyDetail.protectionDetail.pb[key].value}</Detail>
          </ContentContainer>
        ))}
      </PanelBarItem>

      <PanelBarItem expanded={true} title="ผลประโยชน์อื่น ๆ เพิ่มเติม">
        <ContentContainer>
          <Title>{props.riderPolicyDetail.protectionDetail.other.label}</Title>
          <Detail>{props.riderPolicyDetail.protectionDetail.other.value}</Detail>
        </ContentContainer>
      </PanelBarItem>
    </PanelBar>
  </Layout>
)

export default Pb
