import React from "react"
import styled from "@emotion/styled"
import { Button, Field } from "@components"
import { compose, withHandlers } from "recompose"
import InputQuarterlyDate from "./InputQuarterlyDate"
import { CustomIcon } from "./MonthlyBonusTable"

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
const CustomField = styled(Field)`
  width: 86%;
  .k-widget {
    height: 56px;
    .k-dropdown-wrap {
      align-items: center;
      .k-clear-value {
        top: initial;
        display: none;
      }
    }
  }
`
const CustomButton = styled(Button)`
  width: 35px;
  border: 0;
  background-color: transparent;
  visibility: ${(props) => (props.visibility ? "hidden" : "unset")};

  :hover {
    border: 0px;
    background-color: transparent;
  }
`
const CustomInputQuarterlyDate = styled(InputQuarterlyDate)`
  .k-widget {
    width: 150px !important;
  }
`

const PersistentPayDaysInput = (props) => {
  const initialData = props.data.length > 0 ? [...props.data] : [{ day: null, month: null }]
  return (
    <>
      {initialData.map((element, index) => {
        return (
          <Container key={index}>
            <CustomField
              name={`${props.fieldName}persistentPayDays[${index}]`}
              component={CustomInputQuarterlyDate}
              caption={index ? "" : "วันที่จ่ายเงิน"}
              readOnly={!props.readOnly}
            />

            <div>
              <CustomButton
                onClick={() => props.removeField(index)}
                hidden={!props.readOnly}
                visibility={initialData.length <= 1}
              >
                <CustomIcon icon="minus" />
              </CustomButton>
              <CustomButton
                onClick={() => props.addFields()}
                hidden={!props.readOnly}
                visibility={index !== initialData.length - 1}
              >
                <CustomIcon icon="plus" />
              </CustomButton>
            </div>
          </Container>
        )
      })}
    </>
  )
}

const enhancer = compose(
  withHandlers({
    addFields: (props) => () => {
      props.change(`${props.fieldName}persistentPayDays`, [
        ...props.data,
        { day: null, month: null },
      ])
    },
    removeField: (props) => (index) => {
      let newValues = [...props.data]
      newValues.splice(index, 1)
      props.change(`${props.fieldName}persistentPayDays`, newValues)
    },
  })
)

export default enhancer(PersistentPayDaysInput)
