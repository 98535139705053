import React from "react"
import { compose, lifecycle } from "recompose"
import { withRouter, withStores } from "@enhancers"
import styled from "@styled"
import Field from "@components/Field"
import { Dropdown, Checkbox, CurrencyInput, Tooltip } from "@components/index"

const Layout = styled.div``

const Body = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
`
const FieldContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 15px;
  > :first-of-type {
    width: 22%;
  }

  > :last-child {
    display: flex;
    align-items: center;
    margin-left: 8px;
  }
`

const TermPriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 8px;
  width: 40%;

  > div {
    opacity: ${(props) => (props.disabled ? 0.3 : 1)};
  }
`

const Unit = styled.div``

const TitleTerm = styled.div``

const termTypeOptions = [
  {
    label: "ภาคปกติ",
    value: "normal",
  },
  {
    label: "ภาคอินเตอร์",
    value: "national",
  },
  {
    label: "ต่างประเทศ",
    value: "abroad",
  },
]

const level = [
  {
    label: "ประถม",
    info: "6-11 ปี",
  },
  {
    label: "มัธยมต้น",
    info: "12-14 ปี",
  },
  {
    label: "มัธยมปลาย",
    info: "15-17 ปี",
  },
  {
    label: "ปริญญาตรี",
    info: "18-21 ปี",
  },
  {
    label: "ปริญญาโท",
    info: "22-23 ปี",
  },
  {
    label: "ปริญญาเอก",
    info: "24-25 ปี",
  },
]

const SuggestForm = (props) => (
  <Layout>
    <Body>
      {level.map((value, index) => (
        <FieldContainer key={index}>
          <Field component={Checkbox} name={`level${index}`} label={level[index].label} />
          <Field
            component={Dropdown}
            name={`termType${index}`}
            placeholder="กรุณาระบุ"
            options={termTypeOptions}
            disabled={!props.values[`level${index}`]}
            onChange={props.onTermTypeChange}
          />
          <TermPriceContainer disabled={!props.values[`level${index}`]}>
            <TitleTerm disabled={!props.values[`level${index}`]}>ค่าเทอม</TitleTerm>
            <Field component={CurrencyInput} name={`termPrice${index}`} readOnly />
            <Unit disabled={!props.values[`level${index}`]}>บาท</Unit>
          </TermPriceContainer>
          <Tooltip position="right" anchorElement="target">
            <span class="k-icon k-i-information" title={level[index].info} />
          </Tooltip>
        </FieldContainer>
      ))}
    </Body>
  </Layout>
)

const enhancer = compose(
  withRouter(),
  withStores((stores, props) => ({})),
  lifecycle({})
)

export default enhancer(SuggestForm)
