import PropTypes from "prop-types"
import { compose, withContext } from "recompose"
import { connect } from "react-redux"
import { reduxForm, getFormValues } from "redux-form"

const withForm = (config = {}) => {
  const {
    form = `form-${Math.random() * 100000}`,
    valuesName = "values",
    enableReinitialize = true,
    ...rest
  } = config

  return compose(
    reduxForm({ form, enableReinitialize, ...rest }),
    connect((state, props) => ({
      [valuesName]: getFormValues(props.form)(state) || {},
    })),
    withContext(
      {
        formSubmitting: PropTypes.bool,
        formInvalid: PropTypes.bool,
        formAlwaysValidate: PropTypes.bool,
      },
      ({ submitting, invalid, alwaysValidate }) => ({
        formSubmitting: submitting,
        formInvalid: invalid,
        formAlwaysValidate: alwaysValidate,
      })
    )
  )
}

export default withForm
