import BaseStore from "./BaseStore"
import { tqldServer } from "@common/api"

class TqmUserStore extends BaseStore {
  constructor() {
    super({
      all: [],
      current: {},
      configs: {},
      paging: {
        page: 1,
        perPage: 10,
        totalCount: 0,
        totalPages: 1,
      },
    })
  }

  fetchConfig = async () => {
    const response = await tqldServer.tqmBackend.get(`/tqm_users/configs`)
    this.configs = response.data.data.configs
  }

  fetchConfigAttributes = async () => {
    const response = await tqldServer.tqmBackend.get(`/tqm_users/config_attributes`)
    this.configs = response.data.data.configs
  }

  fetch = async (params) => {
    const response = await tqldServer.tqmBackend.get("/tqm_users", params)
    this.all = response.data.data.tqmUsers
    this.paging = response.data.data.paging
  }

  fetchOne = async (params) => {
    const response = await tqldServer.tqmBackend.get(`/tqm_users/${params}`)
    this.current = response.data.data.tqmUser
  }

  update = async (params) => {
    const response = await tqldServer.tqmBackend.put(`/tqm_users/${params.id}`, params)

    this.current = response.data.data.tqmUser
  }

  create = async (params) => {
    await tqldServer.tqmBackend.post("/tqm_users", params)
  }

  delete = async (params) => {
    await tqldServer.tqmBackend.delete(`/tqm_users/${params}`)
  }

  clearData = async () => {
    this.current = {}
  }

  importTqmUser = async (params) => {
    return await tqldServer.tqmBackend.post(`tqm_users/import_tqm_user`, params)
  }

  importMarketPlan = async (params) => {
    return await tqldServer.tqmBackend.post(`tqm_users/import_tqm_user_market_plan`, params)
  }

  importAffiliate = async (params) => {
    return await tqldServer.tqmBackend.post(`tqm_users/import_affiliate`, params)
  }

  fetchUplineTreePlan = async (params) => {
    const response = await tqldServer.tqmBackend.get(`tqm_users/upline_tree_plan`, params)

    return response.data.data.uplineTreePlan
  }
}

export default new TqmUserStore()
