import React from "react"
import styled from "@styled"
import { GridColumn } from "@components/index"
import { Icon } from "@components"
import * as path from "@common/path"
import {
  Layout,
  ReportCardTitle,
  InsuranceCompany,
  CatagoryText,
  FooterContainer,
  ShowMoreDetailContainer,
  ShowMoreDetailText,
  CaretRightIcon,
  Table as DefaultTable,
} from "./InsuranceCompanyCard"

export const Table = styled(DefaultTable)`
  .k-master-row > td {
    text-align: end;

    :nth-of-type(1) {
      text-align: start;
      padding: 0 12px;
    }

    :nth-of-type(2) {
      text-align: start;
      padding: 0 12px;
    }

    :nth-of-type(3) {
      text-align: start;
      padding: 0 12px;
    }
  }
`
export const StateIcon = styled(Icon)`
  font-size: 12px;
  margin-right: 8px;
  color: ${(props) => props.color};
`

const SalesmanCard = (props) => {
  return (
    <Layout>
      <ReportCardTitle>
        {props.dashboardType === "affiliate" ? "ยอดขายรายบุคคลจากผู้แนะนำ" : "ยอดขายรายบุคคล"}
      </ReportCardTitle>
      <Table data={props.data} style={{ marginTop: 27 }}>
        <GridColumn title="สาขา" field="teamName" width="130" />
        <GridColumn title="พนักงานขาย" field="salesmanName" width="170" />
        <GridColumn
          title="สถานะพนักงาน"
          field="memberStatus"
          cell={(cell) => {
            return (
              <td>
                <InsuranceCompany>
                  <StateIcon awesomeIcon="circle" color={cell.dataItem.memberStatus.color} />
                  <CatagoryText>{cell.dataItem.memberStatus.status}</CatagoryText>
                </InsuranceCompany>
              </td>
            )
          }}
          width="150"
        />
        <GridColumn title="ยอดเบี้ยที่ขายได้" field="salablePremium" width="136" />
        <GridColumn title="ยอดเบี้ยที่ยกเลิก" field="rejectedPremium" width="133" />
        <GridColumn title="ยอดเบี้ยรวม" field="totalPremium" width="106" />
        <GridColumn title="กรมธรรม์ที่ขายได้" field="salablePolicy" width="144" />
        <GridColumn title="กรมธรรม์ที่ยกเลิก" field="rejectedPolicy" width="144" />
        <GridColumn title="กรมธรรม์รวม" field="totalPolicy" width="115" />
      </Table>

      <FooterContainer>
        <ShowMoreDetailContainer
          to={
            props.dashboardType === "affiliate"
              ? path.dashboardAffSalesmanPath()
              : path.dashboardSalesmanPath()
          }
        >
          <ShowMoreDetailText>ดูยอดขายรายบุคคลทั้งหมด</ShowMoreDetailText>
          <CaretRightIcon awesomeIcon={"caret-right"} />
        </ShowMoreDetailContainer>
      </FooterContainer>
    </Layout>
  )
}

export default SalesmanCard
