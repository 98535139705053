import React from "react"
import styled from "@styled"
import { GridColumn, Link } from "@components/index"
import { Icon } from "@components"
import { Table as DefaultTable } from "@pages/sale_commissions/show/ViewAsOrderDetail"
import * as path from "@common/path"
import GraphContainer from "./GraphContainer"

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 27px 15px 24px 16px;
  margin-top: 31px;
  border-radius: 5px;
`
export const CatagoryText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
`
export const ReportCardTitle = styled.div`
  font-family: ThaiSans Neue;
  font-weight: bold;
  font-size: 26px;
  line-height: 34px;
`
export const Table = styled(DefaultTable)`
  .k-widget {
    border-style: unset;
  }

  .k-grid-header {
    thead {
      background-color: unset;
    }
  }

  .k-header {
    font-weight: bold;
    pointer-events: none;
  }

  .k-master-row {
    :hover {
      background-color: #ffffff;
    }

    > td {
      text-align: end;

      :nth-of-type(1) {
        text-align: start;
      }

      :nth-of-type(4) {
        color: #666666;
      }
    }
  }

  .k-master-row.k-alt {
    background-color: #f7f7f9;

    :hover {
      background-color: #f7f7f9;
    }
  }

  .k-grid-content.k-virtual-content {
    overflow-y: hidden;
  }
`
export const InsuranceCompany = styled.div`
  display: flex;
  align-items: center;
`
export const CompanyLogo = styled.img`
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  margin-right: 8px;
`
export const FooterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`
export const ShowMoreDetailContainer = styled(Link)`
  cursor: pointer;
  display: flex;
`
export const ShowMoreDetailText = styled.div`
  font-family: Sarabun;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #0275d8;
`
export const CaretRightIcon = styled(Icon)`
  font-size: 16px;
  margin-left: 8px;
  color: #0275d8;
`

const InsuranceCompanyCard = (props) => {
  return (
    <Layout>
      <ReportCardTitle>
        {props.dashboardType === "affiliate"
          ? "ยอดขายแยกตามบริษัทประกันจากผู้แนะนำ"
          : "ยอดขายแยกตามบริษัทประกัน"}
      </ReportCardTitle>
      <GraphContainer graph={props.graph} />
      <Table data={props.data} style={{ marginTop: 27 }}>
        <GridColumn
          title="บริษัทประกัน"
          field="name"
          cell={(cell) => {
            return (
              <td>
                <InsuranceCompany>
                  <CompanyLogo src={cell.dataItem.logo} />
                  <CatagoryText>{cell.dataItem.name}</CatagoryText>
                </InsuranceCompany>
              </td>
            )
          }}
          width="115"
        />
        <GridColumn title="ยอดเบี้ยที่ขายได้" field="salablePremium" width="136" />
        <GridColumn title="ยอดเบี้ยที่ยกเลิก" field="rejectedPremium" width="133" />
        <GridColumn title="ยอดเบี้ยรวม" field="totalPremium" width="106" />
        <GridColumn title="กรมธรรม์ที่ขายได้" field="salablePolicy" width="144" />
        <GridColumn title="กรมธรรม์ที่ยกเลิก" field="rejectedPolicy" width="144" />
        <GridColumn title="กรมธรรม์รวม" field="totalPolicy" width="115" />
      </Table>

      <FooterContainer>
        <ShowMoreDetailContainer
          to={
            props.dashboardType === "affiliate"
              ? path.dashboardAffInsuranceCompanyPath()
              : path.dashboardInsuranceCompanyPath()
          }
        >
          <ShowMoreDetailText>ดูยอดขายแยกตามบริษัทประกันทั้งหมด</ShowMoreDetailText>
          <CaretRightIcon awesomeIcon={"caret-right"} />
        </ShowMoreDetailContainer>
      </FooterContainer>
    </Layout>
  )
}

export default InsuranceCompanyCard
