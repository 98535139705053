import React, { Fragment } from "react"
import { compose, lifecycle, withHandlers, withState, withProps } from "recompose"
import { withRouter, withStores, withForm } from "@enhancers"
import * as path from "@common/path"
import {
  Link,
  BreadCrumb,
  StatusBar,
  Form,
  Header as TextHeader,
  Button,
  Field,
  InputName,
  Dropdown,
  DateInput,
  Input,
  CustomAddress as Address,
  PhoneNumberInput,
  CitizenIdInput,
  Checkbox,
  Authorize,
  CurrencyInput,
} from "@components"
import styled, { css } from "@styled"
import PlanningModal from "./PlanningModal"
import AppointmentModal from "./AppointmentModal"
import {
  formatPhoneNumber,
  formatIdCardNumber,
  formatTime,
  formatDate,
  exceptNullKeys,
} from "@common/helper"
import Loading from "@pages/index/Loading"
import { FormSection } from "redux-form"
import { isEqual, omit, isEmpty, get } from "lodash"

import { desktopSize } from "@common/styled"

export { default as DeleteModal } from "./DeleteModal"

export const Menu = styled.div`
  display: flex;
  position: absolute;
  max-width: 768px;
  width: 100%;

  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;

  ${(props) =>
    desktopSize(`
    max-width: 1216px;
    // padding: 0px 96px 45px 96px;
  `)}
`
export const MenuItem = styled(Link)`
  flex: 1;
  height: 40px;
  display: ${(props) => (props.hidden ? "none" : "flex")};
  align-items: center;
  justify-content: center;
  color: #666666;
  text-decoration: none;
  font-size: 16px;
  background: #e2e5e9;
  font-size: 16px;
  font-family: Sarabun, sans-serif;

  :hover,
  &.active {
    background: #294558;
    color: white;
    font-weight: bold;
    color: #ffffff;
  }

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`
export const BreadCrumbLayout = styled.div``
export const State = styled(StatusBar)`
  display: flex;
  height: 100px;
  width: 100%;
  justify-content: center;
  padding-top: 30px;
  margin-bottom: 30px;
`
export const Layout = styled(Form)`
  width: 100%;
  justify-content: center;
`
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  line-height: normal;
`
export const LeftPanel = styled(TextHeader)`
  display: flex;
  align-items: center;
  color: #666666;
`
export const RightPanel = styled.div``
export const ButtonGroup = styled.div`
  display: flex;
  > * {
    margin-left: 10px;
  }
`
export const ActionButton = styled(Button)`
  width: 126px;

  > span {
    font-size: 22px;
  }
`
const AppointmentButton = styled(Button)`
  width: 121px;

  > span {
    font-size: 18px;
  }
`
const EditButton = styled(Button)`
  width: 98px;
`
export const Body = styled.div`
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #eeeef2;
  margin-bottom: 50px;
  > div:first-of-type {
    border-radius: 4px 4px 0px 0px;
  }
`
const TextTopic = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  font-size: 16px;
  padding-left: 16px;
  color: #ffffff;
  font-family: Sarabun, sans-serif;
  font-weight: bold;
`
const TopicContainer = styled.div`
  width: 100%;
  height: 40px;
  background: #294558;
  opacity: 1;
`
const TopicAppointmentContainer = styled.div`
  width: 100%;
  height: 40px;
  background: #229ec0;
  opacity: 1;
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
`
const TextTitle = styled.div`
  height: 100%;
  font-size: 16px;
  color: #ffffff;
  font-family: Sarabun, sans-serif;
  font-weight: bold;
  display: flex;
  align-items: center;
`
const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 50px;
  padding: 30px 20px 13px 20px;
`
const ContentAddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 50px;
  padding: 30px 0 13px 20px;
`
const SubContentAddressContainer = styled(ContentContainer)`
  padding: 0;
`
const SubDetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 17px;
  min-width: 210px;
`
const SubDetailAddressContainer = styled(SubDetailContainer)`
  margin-left: 72px;
  min-width: 0;
`
const SubDetailAddressContainerSecondCol = styled(SubDetailContainer)`
  margin-left: 35px;
  min-width: 0;
`
const SubToppic = styled.div`
  min-width: 45px;
  height: 23px;
  color: #666666;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  font-weight: bold;
`
const SubToppicSecondCol = styled(SubToppic)`
  min-width: 132px;
`
const SubToppicAddress = styled.div`
  min-width: 85px;
  height: 23px;
  color: #666666;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
`
const SubDetail = styled.div`
  height: 23px;
  color: #666666;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  margin-left: 26px;

  &.noWrapClass {
    white-space: nowrap;
  }
`
const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 60px;
`
const HeadContentAppointment = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 11px;
`
const ContentAppointmentContainer = styled.div`
  width: 100%;
  min-height: 50px;
  padding: 20px 20px 13px 20px;

  > div:nth-of-type(3) {
    > div:first-of-type {
      min-width: 100px;
    }
    > div:last-child {
      margin-left: 0px;
    }
  }

  > div:nth-of-type(4) {
    > div:first-of-type {
      min-width: 100px;
    }
    > div:last-child {
      margin-left: 0px;
    }
  }
`
const SubContentAppointment = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > div {
    > div:first-of-type {
      min-width: 0px;
    }
    > div:last-of-type {
      margin-left: 16px;
    }
  }

  > div:first-of-type {
    min-width: 171px;
    max-width: 171px;
  }

  > div:nth-of-type(2) {
    min-width: 153px;
    max-width: 153px;
  }

  > div:nth-of-type(3) {
  }
`
const CustomInputName = styled(InputName)`
  > div:last-of-type {
    > div {
      > span {
        width: 120px;
      }
      > input {
        width: 158px;
      }
    }
  }
`
const ContentEditingContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 50px;
  padding: 30px 20px;
`

const LeadShow = (props) => (
  <>
    {props.loading ? (
      <Loading />
    ) : (
      <Layout>
        <BreadCrumbLayout>
          <BreadCrumb
            links={[
              {
                label:
                  props.currentRole === "member.salesman" ||
                  props.currentRole === "member.team_leader"
                    ? props.batchId
                      ? "ลีดทีคิวเอ็ม"
                      : "ลูกค้าใหม่"
                    : "ลีด",
                path:
                  props.currentRole === "member.salesman" ||
                  props.currentRole === "member.team_leader"
                    ? props.batchId
                      ? path.leadsPath("all")
                      : path.leadsPath("custom")
                    : path.batchLeadsPath(props.batchId || "custom"),
              },
              { label: "รายละเอียดลีด", path: path.leadPath(props.leadId) },
            ]}
          />
        </BreadCrumbLayout>

        <Header>
          <LeftPanel>ข้อมูลลูกค้า</LeftPanel>
          <RightPanel>
            {props.isEdit ? (
              <ButtonGroup>
                <ActionButton
                  icon="cancel"
                  onClick={props.onCancel}
                  children="ยกเลิก"
                  styletype="secondary"
                />
                <ActionButton icon="floppy" onClick={props.onSave} children="บันทึก" />
              </ButtonGroup>
            ) : (
              <ButtonGroup>
                <ActionButton
                  icon="edit"
                  onClick={props.onEdit}
                  children="แก้ไข"
                  hidden={props.lead && props.lead.leadBatchId}
                  authorize={{
                    roles: ["member.salesman", "member.team_leader"],
                  }}
                />

                {props.lead && !props.lead.appointed && (
                  <AppointmentButton
                    icon="calendar"
                    onClick={props.openAppointmentModal}
                    children="นัดหมาย"
                    styletype="secondary"
                    hidden={props.isEdit}
                    authorize={{
                      roles: ["member.salesman", "member.team_leader"],
                    }}
                  />
                )}
                <Authorize roles={["member.lead_admin", "member.biz_dev", "member.admin"]}>
                  {({ authorized }) => (
                    <ActionButton
                      icon="shopping-cart"
                      onClick={props.openPlanningModal}
                      children="เสนอขาย"
                      hidden={props.isEdit || authorized}
                    />
                  )}
                </Authorize>
              </ButtonGroup>
            )}
          </RightPanel>
        </Header>

        <Body>
          {!props.isEdit && (
            <>
              {props.lead && props.lead.appointed && (
                <Fragment>
                  <TopicAppointmentContainer>
                    <TextTitle>นัดหมาย</TextTitle>
                    {props.lead && props.lead.appointmentStatus && (
                      <TextTitle>สถานะนัดหมาย: {props.lead.appointmentStatus}</TextTitle>
                    )}
                  </TopicAppointmentContainer>

                  <ContentAppointmentContainer>
                    <HeadContentAppointment>
                      <SubToppic>นัดหมายล่าสุด</SubToppic>

                      <EditButton
                        icon="pencil"
                        onClick={props.openAppointmentModal}
                        children="แก้ไข"
                        styletype="secondary"
                        authorize={{
                          roles: ["member.salesman", "member.team_leader", "member.lead_admin"],
                        }}
                      />
                    </HeadContentAppointment>

                    <SubContentAppointment>
                      <SubDetailContainer>
                        <SubToppicSecondCol>วันที่</SubToppicSecondCol>
                        <SubDetail>{props.lead && formatDate(props.lead.appointmentAt)}</SubDetail>
                      </SubDetailContainer>
                      <SubDetailContainer>
                        <SubToppicSecondCol>เวลา</SubToppicSecondCol>
                        <SubDetail>{props.lead && formatTime(props.lead.appointmentAt)}</SubDetail>
                      </SubDetailContainer>
                      <SubDetailContainer>
                        <SubToppicSecondCol>สถานที่</SubToppicSecondCol>
                        <SubDetail>{props.lead && props.lead.appointmentLocation}</SubDetail>
                      </SubDetailContainer>
                    </SubContentAppointment>

                    <SubDetailContainer>
                      <SubToppicSecondCol>แบบประกัน</SubToppicSecondCol>
                      <SubDetail>{props.lead && props.lead.appointmentPolicy}</SubDetail>
                    </SubDetailContainer>
                    <SubDetailContainer>
                      <SubToppicSecondCol>เพิ่มเติม</SubToppicSecondCol>
                      <SubDetail>{props.lead && props.lead.appointmentRemark}</SubDetail>
                    </SubDetailContainer>
                  </ContentAppointmentContainer>
                </Fragment>
              )}
            </>
          )}

          <TopicContainer>
            <TextTopic>ข้อมูลส่วนบุคคล</TextTopic>
          </TopicContainer>

          {!props.isEdit ? (
            <ContentContainer>
              <ColumnContainer>
                <SubDetailContainer>
                  <SubToppic>ชื่อ</SubToppic>
                  <SubDetail className="noWrapClass">
                    {props.lead && props.lead.customerInfo.nameInfo.prefixName}
                    {props.lead && props.lead.customerInfo.nameInfo.firstName}{" "}
                    {props.lead && props.lead.customerInfo.nameInfo.lastName}{" "}
                  </SubDetail>
                </SubDetailContainer>
                <SubDetailContainer>
                  <SubToppic>วันเกิด</SubToppic>
                  <SubDetail>
                    {props.lead &&
                      props.lead.customerInfo &&
                      props.lead.customerInfo.birthdate &&
                      formatDate(props.lead.customerInfo.birthdate)}
                  </SubDetail>
                </SubDetailContainer>
                <SubDetailContainer>
                  <SubToppic>อาชีพ</SubToppic>
                  <SubDetail>{props.lead && props.lead.customerInfo.career}</SubDetail>
                </SubDetailContainer>
              </ColumnContainer>
              <ColumnContainer>
                <SubDetailContainer>
                  <SubToppicSecondCol>เบอร์มือถือ</SubToppicSecondCol>
                  <SubDetail>
                    {props.lead &&
                      props.lead.customerInfo.mobilePhoneNumber &&
                      formatPhoneNumber(props.lead.customerInfo.mobilePhoneNumber)}
                  </SubDetail>
                </SubDetailContainer>
                <SubDetailContainer>
                  <SubToppicSecondCol>เลขที่บัตรประชาชน</SubToppicSecondCol>
                  <SubDetail className="noWrapClass">
                    {props.lead && formatIdCardNumber(props.lead.customerInfo.idCard)}
                  </SubDetail>
                </SubDetailContainer>
                <SubDetailContainer>
                  <SubToppicSecondCol>รายได้ (บาท/ปี)</SubToppicSecondCol>
                  <SubDetail>{props.lead && props.lead.customerInfo.income}</SubDetail>
                </SubDetailContainer>
              </ColumnContainer>
            </ContentContainer>
          ) : (
            <ContentEditingContainer>
              <Field
                name="nameInfo"
                component={CustomInputName}
                caption="ชื่อ"
                options={props.prefixNameNewOptions}
                validate="nameInfo:standard"
                required
              />
              <Field
                name="gender"
                component={Dropdown}
                options={props.genderOptions}
                caption="เพศ"
                validate="required"
                rightWidth="200"
              />
              <Field name="birthdate" component={DateInput} caption="วันเกิด" validate="required" />
              <Field
                name="idCard"
                component={CitizenIdInput}
                caption="เลขที่บัตรประชาชน"
                rightWidth="200"
                validate="required"
              />
              <Field name="career" component={Input} caption="อาชีพ" rightWidth="200" />
              <Field
                name="income"
                component={CurrencyInput}
                caption="รายได้ (บาท/ปี)"
                rightWidth="200"
              />
              <Field
                name="mobilePhoneNumber"
                component={PhoneNumberInput}
                caption="เบอร์มือถือ"
                rightWidth="200"
                isMobileNumber={true}
              />
              <Field
                name="alternatePhoneNumber"
                component={Input}
                caption="เบอร์โทรสำรอง"
                rightWidth="200"
              />
              <Field name="email" component={Input} caption="อีเมล" rightWidth="200" />
            </ContentEditingContainer>
          )}

          {!props.isEdit ? (
            <>
              {props.lead && props.lead.customerInfo.currentAddress && (
                <>
                  <TopicContainer>
                    <TextTopic>ที่อยู่ปัจจุบัน</TextTopic>
                  </TopicContainer>

                  <ContentAddressContainer>
                    <SubDetailContainer>
                      <SubToppic>ที่อยู่</SubToppic>
                      <SubDetail>
                        {props.lead && props.lead.customerInfo.currentAddress.housingNo}
                        {props.lead &&
                          props.lead.customerInfo.currentAddress.villageNo &&
                          " หมู่ " + props.lead.customerInfo.currentAddress.villageNo}
                        {props.lead &&
                          props.lead.customerInfo.currentAddress.building &&
                          " " + props.lead.customerInfo.currentAddress.building}
                        {props.lead &&
                          props.lead.customerInfo.currentAddress.roomNo &&
                          " ห้องเลขที่ " + props.lead.customerInfo.currentAddress.roomNo}
                        {props.lead &&
                          props.lead.customerInfo.currentAddress.floor &&
                          " ชั้น " + props.lead.customerInfo.currentAddress.floor}
                        {props.lead &&
                          props.lead.customerInfo.currentAddress.housingEstate &&
                          " หมู่บ้าน" + props.lead.customerInfo.currentAddress.housingEstate}
                        {props.lead &&
                          props.lead.customerInfo.currentAddress.lane &&
                          " ซอย" + props.lead.customerInfo.currentAddress.lane}
                        {props.lead &&
                          props.lead.customerInfo.currentAddress.road &&
                          " ถนน" + props.lead.customerInfo.currentAddress.road}
                      </SubDetail>
                    </SubDetailContainer>
                    <SubContentAddressContainer>
                      <ColumnContainer>
                        <SubDetailAddressContainer>
                          <SubToppicAddress>ตำบล/แขวง</SubToppicAddress>
                          <SubDetail>
                            {props.lead && props.lead.customerInfo.currentAddress.subDistrict}
                          </SubDetail>
                        </SubDetailAddressContainer>
                        <SubDetailAddressContainer>
                          <SubToppicAddress>จังหวัด</SubToppicAddress>
                          <SubDetail>
                            {props.lead && props.lead.customerInfo.currentAddress.province}
                          </SubDetail>
                        </SubDetailAddressContainer>
                      </ColumnContainer>
                      <ColumnContainer>
                        <SubDetailAddressContainerSecondCol>
                          <SubToppicAddress>อำเภอ/เขต</SubToppicAddress>
                          <SubDetail>
                            {props.lead && props.lead.customerInfo.currentAddress.district}
                          </SubDetail>
                        </SubDetailAddressContainerSecondCol>
                        <SubDetailAddressContainerSecondCol>
                          <SubToppicAddress>รหัสไปรษณีย์</SubToppicAddress>
                          <SubDetail>
                            {props.lead && props.lead.customerInfo.currentAddress.postcode}
                          </SubDetail>
                        </SubDetailAddressContainerSecondCol>
                      </ColumnContainer>
                    </SubContentAddressContainer>
                  </ContentAddressContainer>
                </>
              )}
            </>
          ) : (
            <>
              <TopicContainer>
                <TextTopic>ที่อยู่ปัจจุบัน</TextTopic>
              </TopicContainer>
              <ContentEditingContainer>
                <FormSection name="currentAddress">
                  <Address {...props} name="currentAddress" optional />
                </FormSection>
              </ContentEditingContainer>
            </>
          )}

          {!props.isEdit ? (
            <>
              {props.lead && props.lead.customerInfo.homeAddress && (
                <>
                  <TopicContainer>
                    <TextTopic>ที่อยู่ตามทะเบียนบ้าน</TextTopic>
                  </TopicContainer>

                  <ContentAddressContainer>
                    <SubDetailContainer>
                      <SubToppic>ที่อยู่</SubToppic>
                      <SubDetail>
                        {props.lead && props.lead.customerInfo.homeAddress.housingNo}
                        {props.lead &&
                          props.lead.customerInfo.homeAddress.villageNo &&
                          " หมู่ " + props.lead.customerInfo.homeAddress.villageNo}
                        {props.lead &&
                          props.lead.customerInfo.homeAddress.building &&
                          " " + props.lead.customerInfo.homeAddress.building}
                        {props.lead &&
                          props.lead.customerInfo.homeAddress.roomNo &&
                          " ห้องเลขที่ " + props.lead.customerInfo.homeAddress.roomNo}
                        {props.lead &&
                          props.lead.customerInfo.homeAddress.floor &&
                          " ชั้น " + props.lead.customerInfo.homeAddress.floor}
                        {props.lead &&
                          props.lead.customerInfo.homeAddress.housingEstate &&
                          " หมู่บ้าน" + props.lead.customerInfo.homeAddress.housingEstate}
                        {props.lead &&
                          props.lead.customerInfo.homeAddress.lane &&
                          " ซอย" + props.lead.customerInfo.homeAddress.lane}
                        {props.lead &&
                          props.lead.customerInfo.homeAddress.road &&
                          " ถนน" + props.lead.customerInfo.homeAddress.road}
                      </SubDetail>
                    </SubDetailContainer>
                    <SubContentAddressContainer>
                      <ColumnContainer>
                        <SubDetailAddressContainer>
                          <SubToppicAddress>ตำบล/แขวง</SubToppicAddress>
                          <SubDetail>
                            {props.lead && props.lead.customerInfo.homeAddress.subDistrict}
                          </SubDetail>
                        </SubDetailAddressContainer>
                        <SubDetailAddressContainer>
                          <SubToppicAddress>จังหวัด</SubToppicAddress>
                          <SubDetail>
                            {props.lead && props.lead.customerInfo.homeAddress.province}
                          </SubDetail>
                        </SubDetailAddressContainer>
                      </ColumnContainer>
                      <ColumnContainer>
                        <SubDetailAddressContainerSecondCol>
                          <SubToppicAddress>อำเภอ/เขต</SubToppicAddress>
                          <SubDetail>
                            {props.lead && props.lead.customerInfo.homeAddress.district}
                          </SubDetail>
                        </SubDetailAddressContainerSecondCol>
                        <SubDetailAddressContainerSecondCol>
                          <SubToppicAddress>รหัสไปรษณีย์</SubToppicAddress>
                          <SubDetail>
                            {props.lead && props.lead.customerInfo.homeAddress.postcode}
                          </SubDetail>
                        </SubDetailAddressContainerSecondCol>
                      </ColumnContainer>
                    </SubContentAddressContainer>
                  </ContentAddressContainer>
                </>
              )}
            </>
          ) : (
            <>
              <TopicContainer>
                <TextTopic>ที่อยู่ตามทะเบียนบ้าน</TextTopic>
              </TopicContainer>
              <ContentEditingContainer>
                <FormSection name="homeAddress">
                  <Field
                    name="isSameCurrentAddress"
                    component={Checkbox}
                    label="เหมือนที่อยู่ปัจจุบัน"
                  />
                  {!props.values.homeAddress.isSameCurrentAddress && (
                    <Address {...props} name="homeAddress" optional />
                  )}
                </FormSection>
              </ContentEditingContainer>
            </>
          )}

          {!props.isEdit ? (
            <>
              {props.lead && props.lead.customerInfo.officeAddress && (
                <>
                  <TopicContainer>
                    <TextTopic>ที่อยู่ที่ทำงาน</TextTopic>
                  </TopicContainer>

                  <ContentAddressContainer>
                    <SubDetailContainer>
                      <SubToppic>ที่อยู่</SubToppic>
                      <SubDetail>
                        {props.lead && props.lead.customerInfo.officeAddress.housingNo}
                        {props.lead &&
                          props.lead.customerInfo.officeAddress.villageNo &&
                          " หมู่ " + props.lead.customerInfo.officeAddress.villageNo}
                        {props.lead &&
                          props.lead.customerInfo.officeAddress.building &&
                          " " + props.lead.customerInfo.officeAddress.building}
                        {props.lead &&
                          props.lead.customerInfo.officeAddress.roomNo &&
                          " ห้องเลขที่ " + props.lead.customerInfo.officeAddress.roomNo}
                        {props.lead &&
                          props.lead.customerInfo.officeAddress.floor &&
                          " ชั้น " + props.lead.customerInfo.officeAddress.floor}
                        {props.lead &&
                          props.lead.customerInfo.officeAddress.housingEstate &&
                          " หมู่บ้าน" + props.lead.customerInfo.officeAddress.housingEstate}
                        {props.lead &&
                          props.lead.customerInfo.officeAddress.lane &&
                          " ซอย" + props.lead.customerInfo.officeAddress.lane}
                        {props.lead &&
                          props.lead.customerInfo.officeAddress.road &&
                          " ถนน" + props.lead.customerInfo.officeAddress.road}
                      </SubDetail>
                    </SubDetailContainer>
                    <SubContentAddressContainer>
                      <ColumnContainer>
                        <SubDetailAddressContainer>
                          <SubToppicAddress>ตำบล/แขวง</SubToppicAddress>
                          <SubDetail>
                            {props.lead && props.lead.customerInfo.officeAddress.subDistrict}
                          </SubDetail>
                        </SubDetailAddressContainer>
                        <SubDetailAddressContainer>
                          <SubToppicAddress>จังหวัด</SubToppicAddress>
                          <SubDetail>
                            {props.lead && props.lead.customerInfo.officeAddress.province}
                          </SubDetail>
                        </SubDetailAddressContainer>
                      </ColumnContainer>
                      <ColumnContainer>
                        <SubDetailAddressContainerSecondCol>
                          <SubToppicAddress>อำเภอ/เขต</SubToppicAddress>
                          <SubDetail>
                            {props.lead && props.lead.customerInfo.officeAddress.district}
                          </SubDetail>
                        </SubDetailAddressContainerSecondCol>
                        <SubDetailAddressContainerSecondCol>
                          <SubToppicAddress>รหัสไปรษณีย์</SubToppicAddress>
                          <SubDetail>
                            {props.lead && props.lead.customerInfo.officeAddress.postcode}
                          </SubDetail>
                        </SubDetailAddressContainerSecondCol>
                      </ColumnContainer>
                    </SubContentAddressContainer>
                  </ContentAddressContainer>
                </>
              )}
            </>
          ) : (
            <>
              <TopicContainer>
                <TextTopic>ที่อยู่ที่ทำงาน</TextTopic>
              </TopicContainer>
              <ContentEditingContainer>
                <FormSection name="officeAddress">
                  <Field
                    name="isSameCurrentAddress"
                    component={Checkbox}
                    label="เหมือนที่อยู่ปัจจุบัน"
                  />
                  {!props.values.officeAddress.isSameCurrentAddress && (
                    <Address {...props} name="officeAddress" optional />
                  )}
                </FormSection>
              </ContentEditingContainer>
            </>
          )}
        </Body>
        <AppointmentModal
          leadId={props.leadId}
          initialValues={props.initialValues}
          open={props.showAppointmentModal}
          onClose={props.openAppointmentModal}
        />
        <PlanningModal
          {...props}
          open={props.showPlanningModal}
          onClose={props.openPlanningModal}
        />
      </Layout>
    )}
  </>
)

const enhancer = compose(
  withRouter(),
  withState("showPlanningModal", "setShowPlanningModal", false),
  withState("showAppointmentModal", "setShowAppointmentModal", false),
  withState("loading", "setLoading"),
  withState("isEdit", "setIsEdit", false),
  withStores((stores, props) => ({
    fetchConfigs: stores.leadStore.fetchConfigs,
    fetchLead: stores.leadStore.fetchById,
    lead: stores.leadStore.current,
    leadId: props.match.params.leadId,
    batchId: stores.leadStore.$("current.leadBatchId"),
    stateOptions: stores.leadStore.$("configs.attributes.state.options"),
    genderOptions: stores.leadStore.$("configs.attributes.gender.options", []),
    currentRole: stores.appStore.currentRole,
    update: stores.leadStore.update,
  })),
  withProps((props) => {
    let initialValues = { ...props.lead, ...(props.lead || {}).customerInfo }

    initialValues = {
      ...initialValues,
      homeAddress: {
        ...initialValues.homeAddress,
        isSameCurrentAddress: false,
      },
      officeAddress: {
        ...initialValues.officeAddress,
        isSameCurrentAddress: false,
      },
    }

    const currentAddress = omit(initialValues.currentAddress, [
      "id",
      "addressType",
      "isSameCurrentAddress",
    ])
    const homeAddress = omit(initialValues.homeAddress, [
      "id",
      "addressType",
      "isSameCurrentAddress",
    ])
    const officeAddress = omit(initialValues.officeAddress, [
      "id",
      "addressType",
      "isSameCurrentAddress",
    ])

    if (!isEmpty(currentAddress)) {
      if (isEqual(homeAddress, currentAddress)) {
        initialValues = {
          ...initialValues,
          homeAddress: {
            ...initialValues.homeAddress,
            isSameCurrentAddress: true,
          },
        }
      }
      if (isEqual(officeAddress, currentAddress)) {
        initialValues = {
          ...initialValues,
          officeAddress: {
            ...initialValues.officeAddress,
            isSameCurrentAddress: true,
          },
        }
      }
    }

    return {
      initialValues,
    }
  }),
  withForm({ form: "editLeadInfoForm" }),
  withHandlers({
    openAppointmentModal: (props) => async () => {
      if (props.showAppointmentModal) {
        props.setShowAppointmentModal(!props.showAppointmentModal)
        await props.fetchLead(props.leadId)
        return
      }
      props.setShowAppointmentModal(!props.showAppointmentModal)
      props.reset()
    },
    openPlanningModal: (props) => () => {
      props.setShowPlanningModal(!props.showPlanningModal)
    },
    onEdit: (props) => () => {
      props.setIsEdit(!props.isEdit)
    },
    onCancel: (props) => () => {
      props.setIsEdit(!props.isEdit)
      props.reset(props.form)
    },
    onSave: (props) =>
      props.handleSubmit(async (values) => {
        values = {
          ...values,
          homeAddress: values.homeAddress.isSameCurrentAddress
            ? values.currentAddress
            : values.homeAddress,
          officeAddress: values.officeAddress.isSameCurrentAddress
            ? values.currentAddress
            : values.officeAddress,
        }

        values = exceptNullKeys(values, "email")
        try {
          await props.update(props.leadId, values)
          props.reset("editLeadInfoForm")
          props.setIsEdit(false)
        } catch (error) {
          if (error.name === "SubmissionError") {
            if (!isEmpty(error.errors.mobilePhoneNumber)) {
              window.alert("แก้ไขลีดลูกค้าใหม่ไม่สำเร็จ เบอร์มือถือไม่ถูกต้อง")
            }

            throw error
          } else {
            window.alert("แก้ไขลีดลูกค้าใหม่ไม่สำเร็จ")
          }
        }
      }),
  }),
  lifecycle({
    async componentDidMount() {
      this.props.setLoading(true)
      await this.props.fetchLead(this.props.leadId)
      this.props.fetchConfigs({ leadBatchId: this.props.batchId })
      this.props.setLoading(false)
    },
    componentDidUpdate(prevProps) {
      if (
        get(this.props, "values.nameInfo.prefixName") !==
        get(prevProps, "values.nameInfo.prefixName")
      ) {
        if (["นาย", "ด.ช."].includes(get(this.props, "values.nameInfo.prefixName"))) {
          this.props.change("gender", "male")
        } else if (
          ["นาง", "นางสาว", "ด.ญ."].includes(get(this.props, "values.nameInfo.prefixName"))
        ) {
          this.props.change("gender", "female")
        }
      }
    },
  })
)

export default enhancer(LeadShow)
