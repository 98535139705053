import { compose, withHandlers, lifecycle, withProps } from "recompose"
import { withForm, withStores, withRouter } from "@enhancers/index"
import * as path from "@common/path"
import moment from "moment"
import { CreateLeadPage } from "@pages/products/custom/show/CreateLeadPage"
import { SubmissionError } from "redux-form"
import { get } from "lodash"

const enhancer = compose(
  withRouter({
    queryTypes: {
      birthdate: "date",
      gender: "string",
      sumInsured: "integer",
      type: "string",
      searchBy: "string",
      leadId: "integer",
      from: "string",
      isDraft: "boolean",
      maxAgeInsured: "integer",
      occupation: "string",
      occupationCategory: "string",
    },
  }),
  withStores((stores, props) => ({
    addOrderPackage: stores.orderStore.addOrderPackage,
    params: { ...props.location.query, type: "package" },
  })),
  withForm({
    form: "buyPackageLeadForm",
    destroyOnUnmount: false,
  }),
  withHandlers({
    onCancel: (props) => () => {
      props.history.push(path.productPackagePath(props.params.packageId, props.location.query))
    },
    onFormSubmit: (props) =>
      props.handleSubmit(async (urlValues) => {
        const { values } = props

        if (!/^\d{10}$/.test(values.mobilePhoneNumber)) {
          throw new SubmissionError({
            mobilePhoneNumber: "Field mobilePhoneNumber is required",
          })
        }

        try {
          const packageOrderResponse = await props.addOrderPackage(values)
          if (values.isDraft) {
            props.history.push(path.ordersPath())
          } else {
            props.history.push(path.orderHolderInfoPath(packageOrderResponse.order.id))
          }
        } catch (e) {
          if (e.message === "Submit Validation Failed" && get(e, "errors.base")) {
            alert(e.errors.base)
          } else if (e.name === "SubmissionError") {
            throw e
          }

          if (values.isDraft) {
            window.alert("สร้าง Order Save Draft ไม่สำเร็จ")
          } else {
            window.alert("สร้าง Order ไม่สำเร็จ")
          }

          const error = get(e, "errors.base", null)
          if (error) {
            throw new SubmissionError({
              birthdate: error,
            })
          }
        }
      }),
  }),
  withProps((props) => {
    const { params = {} } = props
    let links = []
    switch (params.from) {
      case "custom_lead":
        links = [
          { label: "ลูกค้าใหม่", path: path.leadsPath("custom") },
          {
            label: "รายละเอียดลีด",
            path: path.leadOrderInfoPath(params.leadId),
          },
          {
            label: "ค้นหาแบบประกัน",
            path: path.productsSearchPath(params.leadId, {
              leadId: params.leadId,
              gender: params.gender,
              sumInsured: params.sumInsured,
              type: params.type,
              birthdate: params.birthdate,
              from: params.from,
              type: "package",
            }),
          },
          {
            label: "เลือกแบบประกัน",
            path: path.productsPath(params.leadId, {
              gender: params.gender,
              type: params.type,
              sumInsured: params.sumInsured,
              leadId: params.leadId,
              birthdate: params.birthdate,
              from: params.from,
              type: "package",
            }),
          },
          {
            label: "สรุปแบบประกัน",
            path: path.productPackagePath(params.packageId, params),
          },
          {
            label: "กรอกข้อมูลลีด",
          },
        ]
        break
      case "batch_lead":
        links = [
          { label: "ลีดทีคิวเอ็ม", path: path.leadsPath("all") },
          {
            label: "รายละเอียดลีด",
            path: path.leadOrderInfoPath(params.leadId),
          },
          {
            label: "ค้นหาแบบประกัน",
            path: path.productsSearchPath(params.leadId, {
              leadId: params.leadId,
              gender: params.gender,
              sumInsured: params.sumInsured,
              type: params.type,
              birthdate: params.birthdate,
              from: params.from,
              type: "package",
            }),
          },
          {
            label: "เลือกแบบประกัน",
            path: path.productsPath(params.leadId, {
              gender: params.gender,
              type: params.type,
              sumInsured: params.sumInsured,
              leadId: params.leadId,
              birthdate: params.birthdate,
              from: params.from,
              type: "package",
            }),
          },
          {
            label: "สรุปแบบประกัน",
            path: path.productPackagePath(params.packageId, params),
          },
          {
            label: "กรอกข้อมูลลีด",
          },
        ]
        break
      case "order":
        links = [
          {
            label: "คำสั่งซื้อ",
            path: path.ordersPath(),
          },
          {
            label: "สรุปแบบประกัน",
            path: path.productPackagePath(params.packageId, params),
          },
          {
            label: "กรอกข้อมูลลีด",
          },
        ]
        break
      default:
        links = [
          {
            label: "ผลิตภัณฑ์",
            path: "/",
          },
          {
            label: "ค้นหาแบบประกัน",
            path: path.productsSearchPath({
              gender: params.gender,
              sumInsured: params.sumInsured,
              type: params.type,
              birthdate: params.birthdate,
            }),
          },
          {
            label: "เลือกแบบประกัน",
            path: path.productsPath({
              gender: params.gender,
              type: params.type,
              sumInsured: params.sumInsured,
              birthdate: params.birthdate,
              from: params.from,
            }),
          },
          {
            label: "สรุปแบบประกัน",
            path: path.productPackagePath(params.packageId, params),
          },
          {
            label: "กรอกข้อมูลลีด",
          },
        ]
        break
    }
    return { links }
  }),
  lifecycle({
    componentWillMount() {
      const {
        gender,
        birthdate,
        packageId,
        isDraft,
        occupation,
        occupationCategory,
        opportunityKey,
        ...params
      } = this.props.location.query

      this.props.initialize({
        packageId,
        birthdate: moment(birthdate, "DD/MM/YYYY").toDate(),
        gender,
        isDraft,
        occupation,
        occupationCategory,
        opportunityKey,
        policyPremium: params.premium,
        paymentPeriod: params.paymentPeriod,
        riders: (params.riders || []).map((r) => ({
          riderPolicyId: r.id,
          riderPremium: r.premium,
        })),
      })
    },
  })
)

export default enhancer(CreateLeadPage)
