import React, { Fragment } from "react"
import styled from "@emotion/styled"
import { compose, withProps } from "recompose"
import { toCurrency } from "@common/helper"
import { Grid, GridColumn as Column } from "@components"
import { mapKeys } from "lodash"
import numeral from "numeral"

const GridTable = styled(Grid)`
  overflow: auto;
  width: 100%;
  border-width: 0px !important;

  .k-widget.k-grid .k-grid-header .k-grid-header-wrap thead tr {
    height: 32px !important;
  }

  thead {
    background-color: #627b8b !important;
    font-size: 10px !important;
    font-weight: bold;
    > tr {
      > th:last-of-type {
        border-right: none !important;
      }
    }
  }

  .k-link {
    font-size: 10px !important;
  }
  .k-header {
    background-color: #627b8b !important;
    border: 0.5px solid #eeeef2 !important;
    vertical-align: middle;
    white-space: unset;
    text-align: center;
    border-width: 0px 1px 1px 0px !important;
  }
  .k-grid-header {
    background-color: #627b8b !important;
    border-color: #627b8b !important;
    height: unset !important;
    padding: 0px !important;

    > div {
      table {
        > thead {
          > tr {
            th {
              padding: 8px 2px !important;
              margin: -8px -2px !important;
              a {
                padding: 8px 2px !important;
                margin: -8px -2px !important;
              }
            }
          }
        }
      }
    }
  }

  .k-grid-container {
    table {
      tbody {
        tr {
          background-color: white;
          td {
            border-bottom: 1px solid #eeeef2;
            text-align: center;
          }
        }
      }
    }
  }

  td {
    font-size: 10px !important;
  }
`

const CumtomFooterTable = styled.td`
  background-color: #627b8b;
  font-size: 10px !important;
  font-weight: bold;
  color: #ffffff !important;
  border: 0.5px solid #eeeef2 !important;
  border-width: 0px 1px 1px 0px !important;
  text-align: ${(props) => props.textAlign};
`

const FooterTableTraparent = styled.td`
  background-color: #f7f7f9;
  border-bottom: none !important;
  border-left: none !important;
`

const cellRender = (cell, props) => {
  if (
    props.field === "age" &&
    (props.dataItem.special === true || props.dataItem.special2 === true)
  ) {
    if (props.dataItem.age) {
      if (props.dataItem.special === true) {
        return (
          <Fragment>
            <CumtomFooterTable colSpan={4} style={{ textAlign: "right" }}>
              {props.dataItem.text}
            </CumtomFooterTable>
            <CumtomFooterTable colSpan={1} textAlign="center !important">
              {numeral(props.dataItem.age[0][1]).format("0.00")}
            </CumtomFooterTable>
            <CumtomFooterTable colSpan={1} textAlign="center !important">
              {toCurrency(props.dataItem.age[1][1])}
            </CumtomFooterTable>
            <FooterTableTraparent />
            <FooterTableTraparent />
            <FooterTableTraparent />
          </Fragment>
        )
      }
      if (props.dataItem.special2 === true) {
        return (
          <Fragment>
            <CumtomFooterTable colSpan={4} style={{ textAlign: "right" }}>
              {props.dataItem.text}
            </CumtomFooterTable>
            <CumtomFooterTable colSpan={2} textAlign="center !important">
              {numeral(props.dataItem.age[1]).format("0.00")}
            </CumtomFooterTable>
            <FooterTableTraparent />
            <FooterTableTraparent />
            <FooterTableTraparent />
          </Fragment>
        )
      }
    } else {
      return null
    }
  }

  if (!props.dataItem[props.field] && props.dataItem[props.field] !== 0) {
    return null
  }

  return cell
}

const Layout = styled.div`
  overflow: auto;
  width: 100%;
  max-width: 678px !important;
`

const Table = (props) => (
  <Fragment>
    <Layout>
      <GridTable data={props.data} cellRender={cellRender}>
        <Column title="อายุ" field="age" width="40px" />
        <Column title="สิ้นปีกรมธรรม์ประกันภัยที่" field="year" width="70px" />
        <Column
          title="เบี้ยประกันรายปี"
          field="premiumPerYear"
          width="80px"
          cell={(cell) => (
            <td colspan="1">
              {cell.dataItem.premiumPerYear !== 0 ? cell.dataItem.premiumPerYear : ""}
            </td>
          )}
        />
        <Column
          title="เบี้ยประกันสะสม"
          field="cumulativePremiumPerYear"
          width="80px"
          cell={(cell) => (
            <td colspan="1">
              {cell.dataItem.cumulativePremiumPerYear !== 0
                ? cell.dataItem.premiumcumulativePremiumPerYearPerYear
                : ""}
            </td>
          )}
        />
        <Column title="เงินคืนรายงวด">
          <Column
            title="อัตรา (%)"
            field="annuityRate"
            width="60px"
            cell={(cell) => (
              <td colspan="1">
                {cell.dataItem.annuityRate !== 0 ? cell.dataItem.annuityRate : ""}
              </td>
            )}
          />
          <Column
            title="จำนวนเงิน"
            field="annuityValue"
            width="70px"
            cell={(cell) => (
              <td colspan="1">
                {cell.dataItem.annuityValue !== 0 ? cell.dataItem.annuityValue : ""}
              </td>
            )}
          />
          <Column
            title="จำนวนเงินสะสม"
            field="annuityValue"
            width="90px"
            cell={(cell) => (
              <td colspan="1">
                {cell.dataItem.annuityValue !== 0 ? cell.dataItem.annuityValue : ""}
              </td>
            )}
          />
        </Column>
        <Column title="ความคุ้มครองชีวิต">
          <Column
            title="อัตรา (%)"
            field="protectionRate"
            width="60px"
            cell={(cell) => (
              <td colspan="1">
                {cell.dataItem.protectionRate !== 0 ? cell.dataItem.protectionRate : ""}
              </td>
            )}
          />
          <Column
            title="จำนวนเงินประกันภัย"
            field="protectionValue"
            width="125px"
            cell={(cell) => (
              <td colspan="1">
                {cell.dataItem.protectionValue !== 0 ? cell.dataItem.protectionValue : ""}
              </td>
            )}
          />
        </Column>
      </GridTable>
    </Layout>
  </Fragment>
)

const enhancer = compose(
  withProps((props) => {
    const obj2arr = []

    if (props.fullBenefit.fullBenefit) {
      // eslint-disable-next-line no-unused-vars
      for (let [key, value] of Object.entries(props.fullBenefit.fullBenefit)) {
        const newValue = mapKeys(value, (val, key) => {
          switch (key) {
            case "premium":
              return "premiumPerYear"
            case "cumulativePremium":
              return "cumulativePremiumPerYear"
            case "protectionPercent":
              return "protectionRate"
            case "protectionAmount":
              return "protectionValue"
            case "cashBackPercent":
              return "annuityRate"
            case "cashBackAmount":
              return "annuityValue"
            case "cumulativeCashBack":
              return "annuityValue"
            default:
              return key
          }
        })

        obj2arr.push({ ...newValue, age: key })
      }

      if (props.displayType !== "Product") {
        obj2arr.push({
          age: [Object.entries(props.fullBenefit)[1], Object.entries(props.fullBenefit)[2]],
          special: true,
          text: "รวม",
        })
        obj2arr.push({
          age: [Object.entries(props.fullBenefit)[3], Object.entries(props.fullBenefit)[4]],
          special: true,
          text: "ผลประโยชน์เมื่อครบสัญญา",
        })
        obj2arr.push({
          age: [Object.entries(props.fullBenefit)[5], Object.entries(props.fullBenefit)[6]],
          special: true,
          text: "สรุปผลประโยชน์ตลอดสัญญา",
        })
        obj2arr.push({
          age: Object.entries(props.fullBenefit)[7],
          special2: true,
          text: "IRR",
        })
      }
    }
    return { obj2arr: obj2arr }
  }),
  withProps((props) => {
    const data = props.obj2arr
    data.forEach((row) => {
      if (row.premiumPerYear) {
        row.premiumPerYear = toCurrency(row.premiumPerYear)
      }

      if (row.cumulativePremiumPerYear) {
        row.cumulativePremiumPerYear = toCurrency(row.cumulativePremiumPerYear)
      }

      if (row.protectionValue) {
        row.protectionValue = toCurrency(row.protectionValue)
      }

      if (row.annuityValue) {
        row.annuityValue = toCurrency(row.annuityValue)
      }

      if (row.annuityRate) {
        row.annuityRate = numeral(row.annuityRate).format("0.00")
      }

      if (row.protectionRate) {
        row.protectionRate = numeral(row.protectionRate).format("0.00")
      }
    })
    return { data }
  })
)

export default enhancer(Table)
