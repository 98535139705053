import React from "react"
import { TreeView as KendoTreeView } from "@progress/kendo-react-treeview"
import { compose, withHandlers } from "recompose"
import styled from "@emotion/styled"

class TreeView extends React.Component {
  render() {
    return (
      <KendoTreeView
        {...this.props}
        onExpandChange={this.onExpandChange}
        onItemClick={this.onItemClick}
        onCheckChange={this.onCheckChange}
      />
    )
  }
  onCheckChange = (event) => {
    event.item.checked = !event.item.checked
    this.forceUpdate()
    this.props.onChange(this.props.data)
  }
  onItemClick = (event) => {
    event.item.checked = !event.item.checked
    this.forceUpdate()
    this.props.onChange(this.props.data)
  }
  onExpandChange = (event) => {
    event.item.expanded = !event.item.expanded
    this.forceUpdate()
    this.props.onChange(this.props.data)
  }
}

const CustomTreeView = styled(TreeView)`
  .k-treeview-lines {
    padding: 0;
  }
`

const enhancer = compose(
  withHandlers({
    onChange: (props) => (data) => {
      props.onChange && props.onChange(data)
    },
  })
)

export default enhancer(CustomTreeView)
