import React from "react"
import styled from "@emotion/styled"
import {
  Form,
  Field,
  Header as TextHeader,
  InputName,
  GenderInput,
  Button,
  BreadCrumb,
  DateInput,
  PhoneNumberInput,
} from "@components"
import moment from "moment"
import Loading from "@pages/index/Loading.js"
const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 592px;
  background-color: white;
  padding: 30px;
  border-radius: 4px;
`
const FormLayout = styled(Form)`
  width: 100%;
  max-width: 532px;
  justify-content: center;
  margin: 20px 0px;
`
const CustomInputName = styled(InputName)`
  > div:last-of-type {
    > div {
      max-width: 380px;
      > span {
        width: 110px;
      }
      > input {
        width: 110px;
      }
    }
  }
`
const LeadFillingCaption = styled(TextHeader)`
  text-align: center;
`
const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: center;

  > button:first-of-type {
    margin-right: 10px;
  }

  > button:last-of-type {
    border-color: #5cb85c;
    color: #5cb85c;
  }
`
const ConfirmBuyButton = styled(Button)`
  display: ${(props) => (props.readOnly ? "none" : "flex")};
  background: #ffffff;
  border-radius: 4px;
  width: 168px;
  height: 38px;
  margin-bottom: 20px;
  box-shadow: unset;

  span.k-icon.k-i-cart {
    font-size: 18px;
  }
`
const AgeLabel = styled.div`
  margin-left: 185px;
  font-size: 14px;
  color: #666666;
`
const CancelButton = styled(Button)`
  display: flex;
  background: #ffffff;
  border-radius: 4px;
  width: 168px;
  height: 38px;
  margin-bottom: 20px;
  box-shadow: unset;

  span.k-icon.k-i-cart {
    font-size: 18px;
  }
`
// const ErrorAge = styled.span`
//   font-size: 12px;
//   color: #d9534f;
//   margin-left: 180px;
// `
const BreadCrumbLayout = styled.div`
  width: 100%;
  /* margin-top: 30px; */
  a,
  div {
    font-size: 10px;
  }
`
export const CreateLeadPage = (props) => (
  <React.Fragment>
    <BreadCrumbLayout>
      <BreadCrumb links={props.links} />
    </BreadCrumbLayout>
    {props.loading ? (
      <Loading />
    ) : (
      <>
        <Layout>
          <LeadFillingCaption>
            {props.params.isDraft
              ? "กรุณากรอกข้อมูลลูกค้าเพื่อบันทึกร่าง"
              : "กรุณากรอกข้อมูลลูกค้า"}
          </LeadFillingCaption>
          <FormLayout>
            <Field name="nameInfo" component={CustomInputName} caption="ชื่อ" />
            <Field
              name="gender"
              caption="เพศ"
              component={GenderInput}
              options={props.genderOptions}
              readOnly
            />
            <Field
              name="birthdate"
              showErrorMessage
              caption="วันเกิด"
              component={DateInput}
              readOnly
            />
            {props.values.birthdate && (
              <AgeLabel show={props.values.birthdate !== null}>
                (อายุ {moment().diff(moment(props.values.birthdate), "years")} ปี)
              </AgeLabel>
            )}
            <Field
              name="mobilePhoneNumber"
              caption="เบอร์โทรศัพท์"
              component={PhoneNumberInput}
              isMobileNumber={true}
              required
            />
            <ButtonsContainer
              hasPaymentMethod={props.hasPaymentMethod}
              isCreateLead={props.isCreateLead}
            >
              <CancelButton icon="cancel" onClick={props.onCancel} styletype="delete">
                ยกเลิก
              </CancelButton>
              <ConfirmBuyButton
                type="button"
                onClick={props.onFormSubmit}
                icon="check"
                readOnly={props.readOnly}
              >
                ยืนยัน
              </ConfirmBuyButton>
            </ButtonsContainer>
          </FormLayout>
        </Layout>
      </>
    )}
  </React.Fragment>
)
