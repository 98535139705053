import React from "react"
import { compose, withHandlers, withState, defaultProps, withProps } from "recompose"
import { withStores } from "@enhancers/index"
import {
  Field,
  Input,
  Button,
  CurrencyInput,
  CurrencyInputWithPecision,
  Authorize,
} from "@components/index"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import Card from "./Card"
import RiderCard from "./RiderCard"
import Modal from "./Modal"
import { FormSection, FieldArray } from "redux-form"
import { displayNumber } from "@common/helper"
import * as path from "@common/path"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #eeeef2;
`
const Row = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 40px 39px 40px;

  > div:last-of-type {
    margin-bottom: 0px;
  }
`

const RowStatus = styled(Row)`
  padding: 30px 25px;
`
export const CustomCurrencyInput = styled(CurrencyInput)`
  > div:last-of-type {
    width: 150px;
    > div {
      width: 150px;
    }
    > input {
      width: 150px;
    }
  }
`
const DetailContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`
const DetailHeader = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  background-color: #e2e5e9;
  align-items: center;
`
const DetailHeaderCaption = styled.span`
  color: #666666;
  font-size: 16px;
  font-family: Sarabun, sans-serif;
  font-weight: bold;
  margin-left: 13px;
`
const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px 20px 40px 20px;
`
const CustomButton = styled(Button)`
  display: flex;
  max-width: 191px;
  width: 100%;
  border-color: #5cb85c;
  color: #5cb85c;
  align-self: center;
  margin-bottom: 32px;
`
const EditButton = styled(CustomButton)`
  border-color: #0275d8;
  color: #0275d8;
  margin-left: 8px;
  width: 104px;
`
const MockVerifyingButton = styled(CustomButton)`
  max-width: 211px;
`
const FooterButtonContainer = styled.div`
  display: ${(props) => (props.hidden ? "none" : "flex")};
  justify-content: center;
  align-items: center;
  align-self: center;
`

const ApprovedButton = styled(CustomButton)``
const CloseButton = styled(CustomButton)`
  color: #666666;
  border-color: #666666;
  width: 106px;
`
export const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  width: 100%;

  > div {
    > div {
      align-items: flex-start !important;
      margin-bottom: unset !important;
      > div {
        align-items: flex-start;
      }
      > *:nth-of-type(1) {
      }
    }
  }
  > div:nth-of-type(2) {
    justify-content: flex-start;
  }

  ${(props) =>
    props.isReceived &&
    css`
      margin-bottom: 16px;
    `}
`

const SpecialFieldContainer = styled(FieldContainer)`
  margin-bottom: 25px;

  ${(props) =>
    props.isSpecial &&
    css`
      margin-bottom: 30px;
    `}
`
export const RemarkContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
`
const UnitContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-left: 10px;
`
export const Premium = styled.span`
  font-family: Sarabun, sans-serif;
  font-size: 14px;
  color: #666666;
`
export const Remain = styled.span`
  font-family: Sarabun, sans-serif;
  font-size: 14px;
  color: #0275d8;
`
const ApproveModal = styled(Modal)``
const CustomInput = styled(Input)`
  > div:nth-of-type(2) {
    > div {
      padding-top: 0px !important;
    }
  }
`

export const NoDataContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
const MessageModal = styled.div`
  color: #666666;
  > div {
    > span {
      font-weight: bold;
    }
  }
`
const RiderProducts = (props) =>
  props.fields.map((riderProduct, index) => (
    <RiderCard
      initialValues={props.fields.get(index)}
      key={index}
      data={riderProduct}
      readOnly={props.readOnly}
      defaultReadOnly={props.defaultReadOnly}
      isCommissionIsReceivedOrApprove={props.isCommissionIsReceivedOrApprove}
    />
  ))

const CommissionTabDetail = (props) => (
  <Container>
    <RowStatus>
      <Field
        name="state"
        caption="สถานะ"
        component={CustomInput}
        readOnly={props.defaultReadOnly}
        leftWidth="152"
      />
    </RowStatus>

    <DetailContainer>
      <DetailHeader>
        <DetailHeaderCaption>รวมทั้งหมด</DetailHeaderCaption>
      </DetailHeader>
      <Row>
        <FieldContainer>
          <Field
            name="totalYearlyPremium"
            caption="เบี้ยรวมทั้งปี"
            component={CustomCurrencyInput}
            readOnly={props.defaultReadOnly}
            leftWidth="152"
            unit="บาท"
            precision={2}
          />
        </FieldContainer>
        <SpecialFieldContainer isSpecial={!props.isCommissionIsReceivedOrApprove}>
          <Field
            name="totalPremium"
            caption="เบี้ยสุทธิ"
            component={CustomCurrencyInput}
            readOnly={props.defaultReadOnly}
            leftWidth="152"
            unit="บาท"
            precision={2}
          />

          {props.isCommissionIsReceivedOrApprove ? (
            <RemarkContainer>
              <Premium>{`*รับจริง ${
                displayNumber(props.initialValues.totalRealPremium) || "-"
              } บาท`}</Premium>
              <Remain>{`คงเหลือ ${
                displayNumber(
                  parseFloat(
                    parseFloat(props.initialValues.totalPremium || 0).toFixed(2) -
                      parseFloat(props.initialValues.totalRealPremium || 0).toFixed(2)
                  ).toFixed(2)
                ) || "-"
              } บาท`}</Remain>
            </RemarkContainer>
          ) : null}
        </SpecialFieldContainer>

        <FieldContainer>
          <Field
            name="interestAmount"
            caption="ดอกเบี้ย"
            component={CurrencyInputWithPecision}
            readOnly={props.readOnly}
            leftWidth="152"
            unit="บาท"
            precision={2}
          />
          {!props.readOnly && (
            <UnitContainer>
              <Premium>บาท</Premium>
            </UnitContainer>
          )}
        </FieldContainer>

        <FieldContainer isReceived={props.isCommissionIsReceivedOrApprove}>
          <Field
            name="totalCommission"
            caption="ค่านายหน้า"
            component={CustomCurrencyInput}
            readOnly={props.defaultReadOnly}
            leftWidth="152"
            unit="บาท"
            precision={2}
          />

          {props.isCommissionIsReceivedOrApprove ? (
            <RemarkContainer>
              <Premium>{`*รับจริง ${
                displayNumber(props.initialValues.totalRealCommission) || "-"
              } บาท`}</Premium>
              <Remain>{`คงเหลือ ${
                displayNumber(
                  parseFloat(
                    parseFloat(props.initialValues.totalCommission || 0).toFixed(2) -
                      parseFloat(props.initialValues.totalRealCommission || 0).toFixed(2)
                  ).toFixed(2)
                ) || "-"
              } บาท`}</Remain>
            </RemarkContainer>
          ) : null}
        </FieldContainer>

        <FieldContainer isReceived={props.isCommissionIsReceivedOrApprove}>
          <Field
            name="totalServiceCharge"
            caption="ค่าบริการ"
            component={CustomCurrencyInput}
            readOnly={props.defaultReadOnly}
            leftWidth="152"
            unit="บาท"
            precision={2}
          />
          {props.isCommissionIsReceivedOrApprove ? (
            <RemarkContainer>
              <Premium>{`*รับจริง ${
                displayNumber(props.initialValues.totalRealServiceCharge) || "-"
              } บาท`}</Premium>
              <Remain>{`คงเหลือ ${
                displayNumber(
                  parseFloat(
                    parseFloat(props.initialValues.totalServiceCharge || 0).toFixed(2) -
                      parseFloat(props.initialValues.totalRealServiceCharge || 0).toFixed(2)
                  ).toFixed(2)
                ) || "-"
              } บาท`}</Remain>
            </RemarkContainer>
          ) : null}
        </FieldContainer>
      </Row>

      <DetailHeader>
        <DetailHeaderCaption>แบบประกันหลัก</DetailHeaderCaption>
      </DetailHeader>

      <CardContainer>
        <FormSection name="lifeProduct">
          <Card
            initialValues={props.lifeProduct}
            readOnly={props.readOnly}
            defaultReadOnly={props.defaultReadOnly}
            isCommissionIsReceivedOrApprove={props.isCommissionIsReceivedOrApprove}
          />
        </FormSection>
      </CardContainer>

      {props.riderProducts && props.riderProducts.length > 0 ? (
        <React.Fragment>
          <DetailHeader>
            <DetailHeaderCaption>สัญญาเพิ่มเติม</DetailHeaderCaption>
          </DetailHeader>

          <CardContainer>
            <FieldArray
              name="riderProducts"
              component={RiderProducts}
              readOnly={props.readOnly}
              defaultReadOnly={props.defaultReadOnly}
              isCommissionIsReceivedOrApprove={props.isCommissionIsReceivedOrApprove}
            />
          </CardContainer>
        </React.Fragment>
      ) : null}
    </DetailContainer>

    <Authorize roles={["member.accountant", "member.accountant_approver"]}>
      <CustomButton
        icon="check"
        onClick={props.onConfirmVerifying}
        children="ยืนยันการตรวจสอบ"
        hidden={props.insurerCommissionState !== "verifying"}
      />
    </Authorize>

    {/* <MockVerifyingButton
      onClick={props.onChangeToVerifying}
      children="จำลองการอนุมัติกรมธรรม์"
      hidden={props.insurerCommissionState !== "new"}
    /> */}

    <FooterButtonContainer
      hidden={
        props.insurerCommissionState !== "real_commission_received" &&
        props.insurerCommissionState !== "real_commission_waiting"
      }
    >
      {props.readOnly ? (
        <Authorize roles={["member.accountant_approver"]}>
          <ApprovedButton icon="check" onClick={props.onApproved} children="อนุมัติการแก้ไข" />
        </Authorize>
      ) : (
        <CloseButton icon="cancel" onClick={props.onCancel} children="ยกเลิก" styletype="delete" />
      )}
      <EditButton
        icon={props.readOnly ? "pencil" : "floppy"}
        onClick={props.readOnly ? props.onEdit : props.handleSubmit(props.onSave)}
        children={props.readOnly ? "แก้ไข" : "บันทึก"}
        hidden={props.readOnly && props.isObsoleted}
      />
    </FooterButtonContainer>
    <Modal
      open={props.toggleModal}
      onClose={props.onCloseModal}
      message={props.modalMessage}
      onApproved={props.onVerify}
    />
    <ApproveModal
      open={props.toggleApproveModal}
      onClose={props.onCloseApproveModal}
      message={props.modalMessage}
      onApproved={props.onApprove}
    />
  </Container>
)

const enhance = compose(
  withStores((stores, props) => ({
    insurerCommissionState: stores.insurerCommissionStore.$("current.state"),
    isObsoleted: stores.insurerCommissionStore.$("current.isObsoleted"),
    id: stores.insurerCommissionStore.$("current.id"),
    update: stores.insurerCommissionStore.update,
    verify: stores.insurerCommissionStore.verify,
    approve: stores.insurerCommissionStore.approve,
    mockChangeToVerifying: stores.insurerCommissionStore.mockChangeToVerifying,
    approveEditing: stores.insurerCommissionStore.approveEditing,
  })),
  withState("readOnly", "setReadOnly", true),
  withState("modalMessage", "setModalMessage", {
    title: "",
    message: "",
    leftButtonCaption: "",
    rightButtonCaption: "",
  }),
  withState("toggleModal", "setToggleModal", false),
  withState("toggleApproveModal", "setToggleApproveModal", false),
  defaultProps({ defaultReadOnly: true }),
  withHandlers({
    onCloseModal: (props) => () => {
      props.setToggleModal(false)
    },
    onCloseApproveModal: (props) => () => {
      props.setToggleApproveModal(false)
    },
    onConfirmVerifying: (props) => async () => {
      props.setToggleModal(!props.toggleModal)
      props.setModalMessage({
        title: "ตรวจสอบคอมมิชชั่น",
        message: (
          <MessageModal>
            ยืนยันการตรวจสอบข้อมูลคอมมิชชั่นรับหรือไม่
            <div>
              (สถานะจะถูกเปลี่ยนเป็น <span>รอรับจริง</span>)
            </div>
          </MessageModal>
        ),
        leftButtonCaption: "ยืนยันความถูกต้อง",
        rightButtonCaption: "ย้อนกลับ",
      })
    },
    onApproved: (props) => async () => {
      props.setToggleApproveModal(!props.toggleApproveModal)
      props.setModalMessage({
        title: "อนุมัติคอมมิชชั่น",
        message: (
          <MessageModal>
            อนุมัติการแก้ไขคอมมิชชั่นรับหรือไม่
            {/*<div>
              (สถานะจะถูกเปลี่ยนเป็น <span>อนุมัติ</span>)
            </div>*/}
          </MessageModal>
        ),
        leftButtonCaption: "ยืนยันความถูกต้อง",
        rightButtonCaption: "ย้อนกลับ",
      })
    },
    onVerify: (props) => async () => {
      try {
        await props.verify(props.id)
        props.setToggleModal(!props.toggleModal)
      } catch (error) {
        const errorMsg = error.errors?.base || "มีข้อผิดพลาดบางอย่างเกิดขึ้น"
        alert(errorMsg)
      }
    },
    onSave: (props) => async (values) => {
      const {
        insurerCommissionDetail,
        estimatedIncomeExportedDate,
        receivedIncomeExportedDate,
        remainingIncomeExportedDate,
      } = values || {}
      const { interestAmount, lifeProduct, riderProducts } = insurerCommissionDetail || {}
      const { id, commissionAmount, serviceCharge } = lifeProduct || {}

      const newProps = {}
      const riders = []

      newProps.interestAmount = interestAmount
      newProps.estimatedIncomeExportedDate = estimatedIncomeExportedDate
      newProps.receivedIncomeExportedDate = receivedIncomeExportedDate
      newProps.remainingIncomeExportedDate = remainingIncomeExportedDate
      newProps.lifeProduct = {
        id,
        commissionAmount,
        serviceCharge,
      }

      riderProducts.forEach((riderProduct) => {
        riders.push({
          id: riderProduct.id,
          commissionAmount: riderProduct.commissionAmount,
          serviceCharge: riderProduct.serviceCharge,
        })
      })

      newProps.riderProducts = riders

      try {
        await props.update(props.id, newProps)
        props.setReadOnly(!props.readOnly)
        props.setInsurerCommissionReadOnly && props.setInsurerCommissionReadOnly(!props.readOnly)
      } catch (error) {
        alert(error.message || "Error")
      }
    },
    onApprove: (props) => async () => {
      try {
        await props.approveEditing({ insurer_commission_ids: [props.id] })
        props.setToggleApproveModal(!props.toggleApproveModal)
        props.history.push(path.insurerCommissionsPath())
      } catch (error) {
        alert(error.message || "Error")
      }
    },
    onCancel: (props) => () => {
      props.setReadOnly(!props.readOnly)
      props.setInsurerCommissionReadOnly && props.setInsurerCommissionReadOnly(!props.readOnly)
    },
    onEdit: (props) => () => {
      props.setReadOnly(!props.readOnly)
      props.setInsurerCommissionReadOnly && props.setInsurerCommissionReadOnly(!props.readOnly)
    },
    onChangeToVerifying: (props) => async () => {
      try {
        await props.mockChangeToVerifying(props.id)
      } catch (error) {
        alert(error.message || "ผิดพลาด อาจเกิดจากยังไม่ได้ตั้งค่าคอมมิชชั่น")
      }
    },
  }),
  withProps((props) => {
    return {
      isCommissionIsReceivedOrApprove:
        props.insurerCommissionState === "real_commission_waiting" ||
        props.insurerCommissionState === "real_commission_received" ||
        props.insurerCommissionState === "approved"
          ? true
          : false,
    }
  })
)

export default enhance(CommissionTabDetail)
