import React from "react"
import styled from "@emotion/styled"
import { compose, withHandlers, withProps } from "recompose"
import { DialogActionsBar, Dialog, Button } from "@components"

const Layout = styled.div`
  padding: 15px;
`
const CustomDialog = styled(Dialog)`
  .k-window {
    border: 1px solid #e6e6e6;
    min-width: 348px;
  }
`
const CancelButton = styled(Button)`
  min-height: 52px;
`
const SubmitButton = styled(Button)`
  min-height: 52px;
  border-color: rgba(33, 37, 41, 0.125);
`

const VerifyModal = (props) => (
  <CustomDialog title="ยืนยันการตรวจสอบ" onClose={props.onClose} isShowModal={props.open}>
    <Layout>{props.textInSubmit} เอกสาร</Layout>
    <DialogActionsBar>
      <CancelButton type="button" onClick={props.onClose} children="ยกเลิก" styletype="delete" />
      <SubmitButton type="button" onClick={props.onSubmit} children="บันทึก" />
    </DialogActionsBar>
  </CustomDialog>
)

const enhancer = compose(
  withProps((props) => {
    const textInSubmit = props.state === "approved" ? "อนุมัติ" : "ไม่อนุมัติ"
    return {
      textInSubmit: textInSubmit,
    }
  }),
  withHandlers({
    onSubmit: (props) => () => {
      if (props.state === "approved") {
        props.onAfterApprove()
      } else {
        props.onAfterReject()
      }

      props.onClose()
    },
  })
)
export default enhancer(VerifyModal)
