import React from "react"
import styled from "@emotion/styled"
import { compose } from "recompose"
import { Button, Dialog, DialogActionsBar } from "@components"

export const CustomDialog = styled(Dialog)`
  .k-window {
    border: 1px solid #e6e6e6;
    min-width: 348px;
  }
`

const Body = styled.div`
  font-size: 16px;
  color: #666666;
  line-height: normal;
  padding: 20px;
`

export const Title = styled.div`
  font-size: 16px;
  padding: 20px 15px;
  font-family: Sarabun, sans-serif;
  color: #666666;
`

export const CloseButton = styled(Button)`
  height: 52px;
  color: #666666;
`

export const SubmitButton = styled(Button)`
  height: 52px;
  background: #0275d8;
  border: 1px solid #eeeef2;
  color: #ffffff;
`

const Modal = (props) => (
  <CustomDialog title={props.title} onClose={props.onCancel} isShowModal={props.show}>
    <Body>{props.message || props.children}</Body>
    <DialogActionsBar>
      <SubmitButton type="button" onClick={props.onOk} children="ยืนยัน" />
    </DialogActionsBar>
  </CustomDialog>
)

const enhancer = compose()
export default enhancer(Modal)
