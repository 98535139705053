import BaseStore from "./BaseStore"
import { tqldServer } from "@common/api"

class LeadBatchStore extends BaseStore {
  constructor() {
    super({
      all: [],
      paging: {
        page: 1,
        perPage: 10,
        totalCount: 0,
        totalPages: 1,
      },
      configs: {
        filter: {},
      },
      footer: {
        assignedLeadsCount: 0,
        orderedLeadsCount: 0,
        unorderedLeadsCount: 0,
      },
    })
  }

  fetchConfigs = async () => {
    const response = await tqldServer.tqmBackend.get(`/lead_batches/configs`)
    this.configs = response.data.data.configs
  }

  import = async (params) => {
    await tqldServer.tqmBackend.post(`lead_batches/import`, {
      leadFile: params.leadFile,
      batchCode: params.batchCode,
    })
  }

  fetch = async (options = {}) => {
    const response = await tqldServer.tqmBackend.get(`/lead_batches`, options)
    this.all = response.data.data.leadBatches
    this.paging = response.data.data.paging

    this.footer.assignedLeadsCount = response.data.data.assignedLeadsCount || 0
    this.footer.orderedLeadsCount = response.data.data.orderedLeadsCount || 0
    this.footer.unorderedLeadsCount = response.data.data.unorderedLeadsCount || 0

    return this.all
  }

  deleteBatch = async (id) => {
    await tqldServer.tqmBackend.delete(`/lead_batches/${id}`)
  }
}

export default new LeadBatchStore()
