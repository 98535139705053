import React, { useCallback } from "react"
import { Grid, GridColumn, Button, Icon, DatePicker } from "@components"
import styled from "@emotion/styled"
import { compose } from "recompose"
import { withHooks } from "@enhancers/index"

const Layout = styled.div`
  margin-top: 8px;
`
const Table = styled(Grid)`
  .k-grid-header {
    height: 100% !important;
    padding: 0px !important;
  }

  .k-header.k-link {
    padding: 17px 8px !important;
  }

  .k-master-row > td {
    padding: 8px !important;
    line-height: 21px;

    :nth-of-type(5) {
      margin: 0 !important;

      > div {
        width: 100%;
      }

      .k-widget.k-combobox.k-header.k-combobox-clearable {
        padding: unset !important;
        margin: 0 !important;
      }
    }
  }

  .k-master-row.k-alt {
    > td:nth-of-type(2) {
      line-height: 21px;
    }
  }
`
const CustomDatePicker = styled(DatePicker)`
  width: 100%;
`
const CustomButton = styled(Button)`
  width: 100%;
  border: 0;
  background-color: transparent;

  :hover {
    border: 0px;
    background-color: transparent;
  }
`
const CustomIcon = styled(Icon)`
  font-size: 20px;
`

const CommissionStructureTable = (props) => {
  return (
    <Layout>
      <Table data={props.data} readOnly={props.readOnly}>
        <GridColumn
          title="รหัสพนักงาน"
          field="staffCode"
          width={props.readOnly ? "120px" : "100px"}
        />
        <GridColumn title="ชื่อสมาชิก" field="name" width={props.readOnly ? "200px" : "160px"} />
        <GridColumn title="วันที่เริ่มงาน" cell={props.startJobDateCell} width="100px" />
        <GridColumn
          title="วันที่เข้าทีม"
          cell={props.joinTeamAtCell}
          width={props.readOnly ? "100px" : "155px"}
        />
        <GridColumn
          title="วันที่สิ้นสุด"
          cell={props.exitTeamAtCell}
          width={props.readOnly ? "100px" : "155px"}
        />
        {!props.readOnly && <GridColumn title="" cell={props.deleteButtonCell} />}
      </Table>
    </Layout>
  )
}

const enhancer = compose(
  withHooks((props) => {
    const startJobDateCell = useCallback((cell) => {
      return (
        <td>
          <CustomDatePicker
            onChange={(event) => props.changeDate(event, cell.dataIndex, "startJobDate")}
            value={cell.dataItem.startJobDate}
            readOnly={true}
          />
        </td>
      )
    })

    const joinTeamAtCell = useCallback((cell) => {
      return (
        <td>
          <CustomDatePicker
            onChange={(event) => props.changeDate(event, cell.dataIndex, "joinTeamAt")}
            value={cell.dataItem.joinTeamAt}
            placeholder="กรุณาเลือก"
            readOnly={props.readOnly}
          />
        </td>
      )
    })

    const exitTeamAtCell = useCallback((cell) => {
      return (
        <td>
          <CustomDatePicker
            onChange={(event) => props.changeDate(event, cell.dataIndex, "exitTeamAt")}
            value={cell.dataItem.exitTeamAt}
            placeholder="กรุณาเลือก"
            readOnly={props.readOnly}
          />
        </td>
      )
    })

    const deleteButtonCell = useCallback((cell) => {
      return (
        <td>
          <CustomButton onClick={props.onRemove(cell.dataItem.salesmanId)} styletype="delete">
            <CustomIcon icon="trash" />
          </CustomButton>
        </td>
      )
    })

    return {
      joinTeamAtCell,
      exitTeamAtCell,
      deleteButtonCell,
      startJobDateCell,
    }
  })
)

export default enhancer(CommissionStructureTable)
