import { flatten, uniq } from "lodash"
import { compose, withState, withHandlers, defaultProps, withProps } from "recompose"
import { AutoComplete } from "@components/index"
import addressData from "./address_data.json"
import Fuse from "fuse.js"

const districtList = uniq(
  flatten(addressData.map(([_, districtData]) => districtData.map(([district]) => district)))
).map((district) => ({
  label: district,
  tag: district,
}))

const districtFuse = new Fuse(districtList, {
  shouldSort: true,
  threshold: 0.6,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ["tag"],
  id: "label",
})

const enhancer = compose(
  defaultProps({
    delay: 400,
  }),
  withState("loading", "setLoading", false),
  withState("searchTask", "setSearchTask", null),
  withState("options", "setOptions", (props) =>
    districtFuse.search(props.value || "", { limit: 5 })
  ),
  withProps((props) => {
    const { name } = props.input || {}
    const className = props.className + " " + name || "" + name
    return { className }
  }),
  withHandlers({
    onChange: (props) => (value) => {
      if (props.searchTask) {
        clearTimeout(props.searchTask)
      }
      props.setLoading(true)
      props.setSearchTask(
        setTimeout(() => {
          props.setOptions(districtFuse.search(value, { limit: 5 }))
          props.setLoading(false)
        }, props.delay)
      )

      props.onChange && props.onChange(value)
    },
  })
)

export default enhancer(AutoComplete)
