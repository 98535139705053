import React from "react"
import styled from "@emotion/styled"
import { css } from "@styled"
import { compose, withHandlers, lifecycle, withState, withProps, defaultProps } from "recompose"
import { withStores, withRouter } from "@enhancers"
import {
  Button,
  Grid,
  GridColumn,
  makeCell,
  FilterButton,
  DatePicker,
  LoadingScope,
  Dropdown,
  Link,
  Checkbox,
  Icon,
  makeHeaderCell,
} from "@components"
import * as path from "@common/path"
import { isEmpty, get, isEqual, cloneDeep } from "lodash"
import moment from "moment"
import { reduxForm } from "redux-form"
import ExportModal from "../ExportModal"
import { toCurrency, exceptNullKeys } from "@common/helper"
import { toDate } from "date-fns"

const Layout = styled.div`
  margin: 0px auto 25px auto;
`
const Header = styled.div`
  display: flex;
  margin: 21px 0 12px 0;
  flex-direction: ${(props) => (props.direction === "true" ? "column" : "row")};
  flex-wrap: wrap;
`
const Left = styled.div``
const Right = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  > div {
    margin-left: 6px;
  }
`
const TableContainer = styled.div`
  display: flex;

  > span {
    max-width: 120px;
    margin-left: 0;
  }

  > input {
    flex: 1;
    margin-top: 0px !important;
    margin-left: 10px;
    font-family: Sarabun, sans-serif;
    font-size: 16px;
  }
`

const ViewTab = styled(Button)`
  width: 100px;
  height: 38px;
  opacity: 1 !important;
  color: #0275d8;
  background: #ffffff;
  border: 1px solid #0275d8;

  :active,
  :hover {
    color: white;
    /* border: 1px solid #0275d8; */
    background-color: #0275d8;
  }

  ${(props) =>
    props.isactive &&
    css`
      pointer-events: none;
      border: 1px solid #0275d8;
      color: white;
      background-color: #0275d8;
    `}
`
const CustomFilterButton = styled(FilterButton)`
  > button {
    width: 143px;

    > .k-icon {
      margin: unset;
      margin-right: 12px;
      font-size: 19px;
    }
  }
`
const ExportButton = styled(Button)`
  width: 116px;

  > .k-icon {
    margin: unset;
    margin-right: 12px;
    font-size: 20px;
  }
`
const Table = styled(Grid)`
  .k-grid-header .k-header {
    font-weight: bold;
  }

  ${(props) =>
    props.isactive
      ? css`
          .k-grid-header .k-header {
            :nth-of-type(4) {
              text-align: end;
            }
            :nth-of-type(5) {
              text-align: end;
            }
            :nth-of-type(6) {
              text-align: end;
            }
          }

          .k-master-row > td {
            :nth-of-type(4) {
              text-align: end;
            }
            :nth-of-type(5) {
              text-align: end;
            }
            :nth-of-type(6) {
              text-align: end;
            }
          }
        `
      : css`
          .k-grid-header .k-header {
            :nth-of-type(3) {
              text-align: end;
            }
            :nth-of-type(4) {
              text-align: end;
            }
            :nth-of-type(5) {
              text-align: end;
            }
          }

          .k-master-row > td {
            :nth-of-type(3) {
              text-align: end;
            }
            :nth-of-type(4) {
              text-align: end;
            }
            :nth-of-type(5) {
              text-align: end;
            }
          }
        `}
`
const CustomCheckbox = styled(Checkbox)`
  .k-item.k-treeview-item {
    height: 26px !important;
    padding-bottom: 5px;
  }
`
const CheckboxTd = styled.td`
  position: sticky;
  left: 0;
  border-right-width: 1px;
  background-color: #ffffff;
`
const HistoryButton = styled(Button)`
  width: 96px;
  border: 1px solid #627b8b;
  color: #627b8b;
`
const IconCheckCircle = styled(Icon)`
  color: #5cb85c;
`
const IconTimesCircle = styled(Icon)`
  color: #d9534f;
`

const ViewAsOrder = (props) => (
  <Table
    loadingTable={props.loadingTable}
    data={props.data}
    pageable
    skip={props.paging.skip}
    take={props.paging.perPage}
    total={props.paging.totalCount}
    onPageChange={props.onPageChange}
    onRowClick={props.onRowClick}
    isactive={props.verification}
  >
    {props.verification && (
      <GridColumn
        title=""
        width="42px"
        locked
        headerCell={makeHeaderCell(CustomCheckbox, () => ({
          value: props.isSelectedAll,
          onChange: (checked) => props.onSelectAll(checked),
        }))}
        cell={(cell) => {
          return (
            <CheckboxTd className={`k-grid-content-sticky ${cell.field}`}>
              <Checkbox
                value={cell.dataItem.selected}
                onChange={(checked) => props.onSelectRow(checked, cell.dataItem)}
              />
            </CheckboxTd>
          )
        }}
      />
    )}
    <GridColumn title="รหัสคำสั่งซื้อ" field="tqmOrderId" width="130px" />
    <GridColumn
      title="วันที่คุ้มครอง"
      cell={makeCell(DatePicker, (cell) => ({
        readOnly: true,
        value: cell.dataItem.coverageDate,
      }))}
      width="115px"
    />
    <GridColumn
      title="เบี้ยประกัน"
      cell={(cell) => <td>{toCurrency(cell.dataItem.premium)}</td>}
      width="100px"
    />
    <GridColumn
      title="ยอดจ่าย"
      cell={(cell) => <td>{toCurrency(cell.dataItem.commissionAmount)}</td>}
      width="94px"
    />
    <GridColumn
      title="เรียกคืน"
      cell={(cell) => <td>{toCurrency(cell.dataItem.reclaimAmount)}</td>}
      width="77px"
    />
    <GridColumn
      title="วันที่จ่าย"
      cell={makeCell(DatePicker, (cell) => ({
        readOnly: true,
        value: cell.dataItem.commissionReceivedDate,
      }))}
      width="100px"
    />
    <GridColumn
      field="yearPeriod"
      title="ปี/งวด"
      width="80px"
      resizable={true}
      cell={(cell) => {
        return <td>{`${cell.dataItem.policyYear}/${cell.dataItem.paymentInstallment}`}</td>
      }}
    />
    <GridColumn field="staffCode" title="รหัสพนักงาน" width="110px" />
    <GridColumn field="salesmanName" title="ชื่อพนักงาน" width="180px" />
    <GridColumn
      title="ตำแหน่ง"
      field="salesmanPosition"
      cell={makeCell(Dropdown, (cell) => ({
        readOnly: true,
        value: cell.dataItem.salesmanPosition,
        options: props.salesmanPositionOptions,
      }))}
      width="140px"
    />
    <GridColumn field="teamName" title="ทีมขาย" width="140px" />
    <GridColumn
      field="memberStatus"
      title="สถานะพนักงาน"
      width="130px"
      cell={makeCell(Dropdown, (cell) => ({
        readOnly: true,
        value: cell.dataItem.memberStatus,
        options: props.memberStatusOptions,
      }))}
    />
    <GridColumn
      field="affiliateTeamId"
      title="ชื่อทีมผู้แนะนำ"
      width="160px"
      cell={makeCell(Dropdown, (cell) => ({
        readOnly: true,
        value: cell.dataItem.affiliateTeamId,
        options: props.affiliateTeamOptions,
      }))}
    />
    <GridColumn field="affiliateStaffCode" title="รหัสผู้แนะนำ" width="110px" />
    <GridColumn field="affiliateName" title="ชื่อผู้แนะนำ" width="180px" />
    <GridColumn field="insuranceCompanyName" title="บริษัทประกัน" width="182px" />
    <GridColumn field="paymentMethod" title="การชำระเงิน" width="115px" />
    <GridColumn field="policyNumber" title="เลขกรมธรรม์" width="160px" />
    <GridColumn field="customerName" title="ชื่อลูกค้า" width="187px" />
    <GridColumn
      field="affiliateSubmittedAt"
      title="วันที่แจ้งแนะนำ"
      cell={makeCell(DatePicker, (cell) => ({
        readOnly: true,
        value: cell.dataItem.affiliateSubmittedAt,
      }))}
      width="130px"
    />
    <GridColumn
      title="วันที่แจ้งงาน"
      cell={makeCell(DatePicker, (cell) => ({
        readOnly: true,
        value: cell.dataItem.submittedAt,
      }))}
      width="107px"
    />
    <GridColumn field="policyStatus" title="สถานะกรมธรรม์" width="134px" />

    <GridColumn
      title="วันนำออกคำนวณคอมมิชชั่น"
      cell={makeCell(DatePicker, (cell) => ({
        readOnly: true,
        value: cell.dataItem.calculateCommissionExportedDate,
      }))}
      width="213px"
    />
    <GridColumn
      title="วันนำออกคอมมิชชั่นจ่าย"
      cell={makeCell(DatePicker, (cell) => ({
        readOnly: true,
        value: cell.dataItem.salesCommissionExportedDate,
      }))}
      width="192px"
    />
    <GridColumn
      title="วันนำออกคอมมิชชั่นค้างจ่าย"
      cell={makeCell(DatePicker, (cell) => ({
        readOnly: true,
        value: cell.dataItem.pendingCommissionExportedDate,
      }))}
      width="214px"
    />
    <GridColumn field="executionCode" title="รหัสรายการ" width="105px" />
    <GridColumn
      field="history"
      title="ข้อมูลย้อนหลัง"
      width="115px"
      resizable={true}
      cell={(cell) => (
        <td>
          <HistoryButton
            onClick={() => props.history.push(path.saleCommissionHistoryPath(cell.dataItem.id))}
          >
            ตรวจสอบ
          </HistoryButton>
        </td>
      )}
    />
    <GridColumn
      field="editingStatus"
      title="การแก้ไข"
      cell={makeCell(Dropdown, (cell) => ({
        readOnly: true,
        value: cell.dataItem.editingStatus,
        options: props.editingStatusOptions,
      }))}
      width="120px"
    />
    <GridColumn
      title="สถานะ"
      field="state"
      cell={(cell) => {
        return (
          <td>
            <Dropdown value={cell.dataItem.state} options={props.stateOptions} readOnly />
          </td>
        )
      }}
      width="141px"
    />
  </Table>
)

const enhancer = compose(
  withRouter(),
  withStores((stores) => ({
    paging: stores.saleCommissionStore.paging,
    salesmanPositionOptions: stores.saleCommissionStore.$(
      "configs.filters.salesmanPosition.options",
      []
    ),
    stateOptions: stores.saleCommissionStore.$("configs.attributes.state.options", []),
    editingStatusOptions: stores.saleCommissionStore.$(
      "configs.attributes.editingStatus.options",
      []
    ),
    memberStatusOptions: stores.saleCommissionStore.$(
      "configs.attributes.memberStatus.options",
      []
    ),
    affiliateTeamOptions: stores.saleCommissionStore.$(
      "configs.attributes.affiliateTeam.options",
      []
    ),
  })),
  defaultProps({
    initialValues: {},
  }),
  withProps((props) => ({
    paging: {
      ...props.paging,
      skip: (props.paging.page - 1) * props.paging.perPage,
    },
  })),
  withHandlers({
    onRowClick: (props) => (event) => {
      if (!props.verification) {
        props.history.push(path.saleCommissionPath(event.dataItem.id, { viewType: "orders" }))
      }
    },
  })
)

export default enhancer(ViewAsOrder)
