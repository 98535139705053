import { compose, withProps, withHandlers } from "recompose"
import { omitProps } from "./index"

const withValues = (...names) =>
  compose(
    withProps((props) => ({
      value: props.value || {},
    })),
    withHandlers(
      names.reduce(
        (memo, name) => ({
          ...memo,
          [`${name}OnChange`]: (props) => (value) => {
            props.onChange && props.onChange({ ...props.value, [name]: value })
          },
        }),
        {}
      )
    ),
    withProps((props) => ({
      inputs: names.reduce(
        (memo, name) => ({
          ...memo,
          [name]: {
            onChange: props[`${name}OnChange`],
            value: props.value[name],
          },
        }),
        {}
      ),
    })),
    omitProps(...names.reduce((memo, name) => [...memo, `${name}OnChange`], []))
  )

export default withValues
