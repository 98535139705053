import React, { useEffect, useState, useCallback } from "react"
import styled from "@emotion/styled"
import { compose } from "recompose"
import { withStores, withHooks, withRouter } from "@enhancers"
import * as path from "@common/path"
import {
  Button,
  makeCell,
  Grid,
  GridColumn,
  Dropdown,
  LoadingScope,
  BreadCrumb,
  FilterButton,
  CurrencyInput,
} from "@components"
import { call } from "@common/helper"
import FilterPanel from "./FilterPanel"

const Layout = styled.div`
  margin: 0px auto 25px auto;
`
const Header = styled.div`
  display: flex;
  margin: 0px 0 12px 0;
  flex-wrap: wrap;
`
const Left = styled.div``
const Right = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  > div {
    margin-left: 6px;
  }
`
const Table = styled(Grid)`
  .k-grid-toolbar {
    background-color: transparent;
  }

  td {
    cursor: pointer;
    vertical-align: top;
  }

  tr {
    > div {
    }
  }
`
const CustomButton = styled(Button)`
  width: 96px;
`
const Rider = (props) => (
  <React.Fragment>
    {props.loading ? (
      <LoadingScope />
    ) : (
      <Layout>
        <BreadCrumb
          links={[
            { label: "สัญญาเพิ่มเติม", path: path.healthInsurancePoliciesPath() },
            { label: "ตั้งค่าสัญญาเพิ่มเติม", path: path.riderPath() },
          ]}
        />
        <Header>
          <Left>
            <FilterButton expanded={props.filterExpandedValue} onExpand={props.toggleExpandFilter}>
              <FilterPanel />
            </FilterButton>
          </Left>
          <Right>
            {/* <CustomButton awesomeIcon="plus" onClick={props.create} children="จับคู่" /> */}
          </Right>
        </Header>
        <Table loading={props.loadingTable} data={props.data} onRowClick={props.clickRow}>
          <GridColumn title="บริษัทประกัน" field="companyName" width="200" />
          <GridColumn
            title="แบบประกันชีวิต"
            cell={makeCell(Dropdown, (cellProps) => ({
              readOnly: true,
              value: cellProps.dataItem.lifeId,
              options: props.leadIdOptions,
            }))}
            width="330"
          />
          <GridColumn
            title="สัญญาเพิ่มเติม"
            cell={makeCell(CurrencyInput, (cellProps) => ({
              value: cellProps.dataItem.riderIds.length,
              readOnly: true,
            }))}
          />
        </Table>
      </Layout>
    )}
  </React.Fragment>
)

const enhancer = compose(
  withRouter(),
  withStores((stores, props) => ({
    data: stores.riderStore.all,
    fetchData: stores.riderStore.fetch,
    leadIdOptions: stores.riderStore.$("configs.configs.attributes.lifeId.options", []),
    riderIdOptions: stores.riderStore.$("configs.configs.attributes.riderId.options", []),
  })),
  withHooks((props) => {
    const { query } = props.location || {}
    const [loading, setLoading] = useState(false)
    const [loadingTable, setLoadingTable] = useState(false)
    const [filterExpandedValue, setFilterExpandedValue] = useState(false)
    const [fetchDataTask, setFetchDataTask] = useState(null)

    const toggleExpandFilter = useCallback(() => {
      setFilterExpandedValue(!filterExpandedValue)
    })

    const clickRow = useCallback((event) => {
      props.history.push(path.riderPath(event.dataItem.lifeId))
    })

    const create = useCallback(() => {
      props.history.push(path.newRiderPath())
    })

    useEffect(() => {
      call(async () => {
        setLoading(true)
        await props.fetchData(query)
        setLoading(false)
      })
    }, [])

    useEffect(() => {
      setLoadingTable(true)
      if (fetchDataTask) {
        clearTimeout(fetchDataTask)
      }
      const fetchData = setTimeout(async () => {
        await props.fetchData(query)
        setLoadingTable(false)
      }, 800)
      setFetchDataTask(fetchData)
    }, [JSON.stringify(query)])

    return {
      loading,
      loadingTable,
      clickRow,
      create,
      filterExpandedValue,
      toggleExpandFilter,
    }
  })
)

export default enhancer(Rider)
