import React, { useEffect } from "react"
import { compose } from "recompose"
import { withHooks } from "@enhancers/index"
import styled from "@emotion/styled"
import { desktopSize } from "@common/styled"

const MainLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40px;
  background: #ffffff;
  padding: 0 45px;
  z-index: 10001;

  ${(props) =>
    desktopSize(`
    padding: 0px 22px;
  `)}
`

const Layout = styled.div`
  max-width: 678px;
  width: 100%;
  display: flex;
  align-items: center;

  ${(props) =>
    desktopSize(`
    max-width: unset;
  `)}
`
const Copyright = styled.div`
  font-size: 10px;
  color: #666666;
  font-family: Sarabun, sans-serif;
`

const Footer = (props) => (
  <MainLayout id="footer">
    <Layout>
      <Copyright>COPYRIGHT © 2019 ALL RIGHT RESERVED</Copyright>
    </Layout>
  </MainLayout>
)

const enhancer = compose(
  withHooks((props) => {
    useEffect(() => {
      if (
        sessionStorage.getItem("fromAGT") === "true" &&
        sessionStorage.getItem("fromPortal") === "true"
      ) {
      } else if (sessionStorage.getItem("fromAGT") === "true") {
        document.getElementById("footer").style.visibility = "hidden"
      }
    }, [])
  })
)

export default enhancer(Footer)
