import React from "react"
import styled from "@emotion/styled"
import { compose, withHandlers } from "recompose"
import { Button, Dialog, DialogActionsBar, Field, TextArea } from "@components"
import { withForm } from "@enhancers/index"

const Modal = styled(Dialog)`
  .k-widget.k-window.k-dialog {
    min-width: 348px;
  }
`

const Body = styled.div`
  padding: 15px;
`

const CustomTextArea = styled(TextArea)`
  flex-direction: column;

  textarea {
    min-height: 86px;
  }

  > div {
    width: 100%;
    color: #292b2c;

    :nth-of-type(1) {
      margin-bottom: 6px;
    }
  }
`
export const CloseButton = styled(Button)`
  height: 52px;
  color: #666666;
`
export const SubmitButton = styled(Button)`
  height: 52px;
  background: #0275d8;
  border: 1px solid #eeeef2;
  color: #ffffff;
`

const RejectModal = (props) => (
  <Modal title="หมายเหตุ" isShowModal={props.open} onClose={props.onClose}>
    <Body>
      <Field name="remark" caption="เหตุผลการยกเลิก" component={CustomTextArea} />
    </Body>
    <DialogActionsBar>
      <CloseButton type="button" onClick={props.onClose} children="ยกเลิก" styletype="delete" />
      <SubmitButton type="button" onClick={props.onSubmit} children="ยืนยัน" />
    </DialogActionsBar>
  </Modal>
)

const enhancer = compose(
  withForm({ form: "RejectModalForm" }),
  withHandlers({
    onSubmit: (props) =>
      props.handleSubmit(async (values) => {
        await props.onSubmit(values)
        await props.onClose()
      }),
  })
)
export default enhancer(RejectModal)
