import qs from "qs"
import env from "@env"
import { mapValues, last } from "lodash"
import moment from "moment"

export const rootPath = () => ""

const deepTransformMomentToDate = (value) => {
  if (value instanceof Array) {
    return value.map(deepTransformMomentToDate)
  } else if (value && value._isAMomentObject) {
    return value.toDate()
  } else if (value instanceof Date) {
    return value
  } else if (value instanceof Object) {
    return mapValues(value, deepTransformMomentToDate)
  } else {
    return value
  }
}

const makePath = (path) => (...args) => {
  let pathMethod = typeof path === "string" ? () => path : path

  if (last(args) instanceof Object) {
    const params = args.slice(0, args.length - 1)
    const query = last(args)

    const queryString = qs.stringify(deepTransformMomentToDate(query), {
      serializeDate: (date) => moment(date).format("DD/MM/YYYY", { locale: "en" }),
    })

    return `${rootPath()}/${pathMethod(...params)}?${queryString}`
  } else {
    return `${rootPath()}/${pathMethod(...args)}`
  }
}

export const batchesPath = makePath("batches")
export const batchLeadsPath = makePath((batchCode) => `batches/${batchCode}/batchLeads`)

export const leadsPath = makePath((batchCode) => `batches/${batchCode}/leads`)
export const leadPath = makePath((id) => `leads/${id}`)
export const leadInfoPath = makePath((id) => `leads/${id}/lead_info`)
export const leadCustomerInfoPath = makePath((id) => `leads/${id}/customer_info`)
export const leadProductPath = makePath((id) => `leads/${id}/product`)
export const leadOrderInfoPath = makePath((id) => `leads/${id}/order_info`)

export const leadPoolsPath = makePath("lead_pool")

export const newLeadPath = makePath("leads/new")
export const newLeadInfoPath = makePath("leads/new/lead_info")
export const newLeadCustomerInfoPath = makePath("leads/new/customer_info")
export const newLeadOrderInfoPath = makePath("leads/new/order_info")

export const ordersPath = makePath("orders")
export const orderPath = makePath((id) => `orders/${id}`)
export const orderDraftProductPath = makePath((id) => `orders/${id}/draft_product`)
export const orderHolderInfoPath = makePath((id) => `orders/${id}/holder_info`)
export const orderPaymentInfoPath = makePath((id) => `orders/${id}/payment_info`)
export const orderDocumentInfoPath = makePath((id) => `orders/${id}/document_info`)
export const orderActionCodeInfoPath = makePath((id) => `orders/${id}/action_code_info`)
export const newOrderProductInfoPath = makePath((id) => `orders/${id}/product_info`)
export const orderCommissionInfoPath = makePath((id) => `orders/${id}/order_info`)

export const planningPath = makePath("products/planning")
export const planningTypePath = makePath((type) => `products/planning/${type}`)
export const planningTypeShortPath = makePath((type) => `products/planning/${type}/short`)
export const planningTypeFullPath = makePath((type) => `products/planning/${type}/full`)
export const planningTypeFullSuggestPath = makePath(
  (type) => `products/planning/${type}/full/suggest`
)
export const planningTypeFullChoosePath = makePath(
  (type) => `products/planning/${type}/full/choose`
)

export const productPackageCreateLeadPath = makePath((id) => `products/package/${id}/create_lead`)
export const productPackageEditPath = makePath((id) => `products/package/${id}/edit`)
export const productPackageShowDetailPath = makePath((id) => `products/package/${id}/show_detail`)
export const productPackagePath = makePath((id) => `products/package/${id}`)

export const productCustomCreateLeadPath = makePath((id) => `products/custom/${id}/create_lead`)
export const productCustomEditPath = makePath((id) => `products/custom/${id}/edit`)
export const productCustomShowDetailPath = makePath((id) => `products/custom/${id}/show_detail`)
export const productCustomPath = makePath((id) => `products/custom/${id}`)

export const productsComparePath = makePath("products/compare")
export const productsSearchPath = makePath("products/search")
export const productsPath = makePath("products")

export const insurerCommissionsPath = makePath((id) => `insurer_commissions`)
export const insurerCommissionsReportPreparationPath = makePath(
  `insurer_commissions/report_preparation`
)
export const insurerCommissionPath = makePath((id) => `insurer_commissions/${id}`)
export const insurerCommissionHistoryPath = makePath((id) => `insurer_commissions/${id}/history`)
export const commissionVerification = makePath("commission_verification")
export const commissionEditApprove = makePath("commission_edit_approve")

export const saleCommissionsPath = makePath("sale_commissions")
export const saleCommissionPath = makePath((id) => `sale_commissions/${id}`)
export const saleCommissionHistoryPath = makePath((id) => `sale_commissions/${id}/history`)
export const saleCommissionsReportPreparationPath = makePath(`sale_commissions/report_preparation`)
export const saleCommissionVerification = makePath("sale_commission_verification")

export const commissionEditSettingPath = makePath("commission_edit_setting")

export const insurersPath = makePath("insurers")
export const insurerPath = makePath((id) => `insurers/${id}/`)
export const insurerInfoPath = makePath((id) => `insurers/${id}/insurer_info`)
export const insurerDocumentInfoPath = makePath((id) => `insurers/${id}/document_info`)
export const insurerInfoNewPath = makePath((id) => `insurers/new/insurer_info`)

export const insurancePoliciesPath = () => `${env.oldSiteRoot}/insurance_policies`
export const lifeInsurancePoliciesPath = makePath("insurance_policies")

export const healthInsurancePoliciesPath = makePath("health_insurance_policies")

export const ridersPath = makePath("rider_mappings")
export const newRiderPath = makePath("rider_mappings/new")
export const riderPath = makePath((id) => `rider_mappings/${id}`)

export const packagesPath = makePath("packages")
export const newPackagePath = makePath(`packages/new`)
export const packagePath = makePath((id) => `packages/${id}`)

export const paymentMappingsPath = makePath("payment_mappings")
export const paymentMappingPath = makePath((id) => `payment_mapping/${id}`)

export const packagePaymentMappingsPath = makePath("package_payment_mappings")
export const packagePaymentMappingPath = makePath((id) => `package_payment_mapping/${id}`)

export const commissisonPath = makePath("commissions")

export const commissionMappingsPath = makePath("commission_mapping")
export const newCommissionMappingPath = makePath("commission_mapping/new")
export const commissionMappingPath = makePath((id) => `commission_mapping/${id}`)

export const bonusCalculationPath = makePath(`bonus_calculation`)
export const showBonusCalculationPath = makePath((id) => `bonus_calculation/${id}`)

export const teamsPath = makePath("teams")
export const newTeamPath = makePath("teams/new")
export const teamPath = makePath((id) => `teams/${id}`)
export const teamHistoryPath = makePath((id) => `teams/${id}/history`)

export const tqmMembersPath = makePath("tqm_members")
export const newTqmMembersPath = makePath("tqm_members/new")
export const tqmMemberPath = makePath((id) => `tqm_members/${id}`)

export const marketPlansPath = makePath("market_plans")
export const newMarketPlanPath = makePath("market_plans/new")
export const marketPlanPath = makePath((id) => `market_plans/${id}`)

export const notificationPath = makePath("notification")
export const notFoundPath = makePath("404")

export const dashboardPath = makePath("dashboard")
export const statsPath = makePath("dashboard/order_statistic")
export const dashboardInsuranceCompanyPath = makePath("dashboard/insurance_company")
export const dashboardInsurancePolicyPath = makePath("dashboard/insurance_policy")
export const dashboardTeamPath = makePath("dashboard/team")
export const dashboardSalesmanPath = makePath("dashboard/salesman")
export const dashboardPaymentPeriodPath = makePath("dashboard/payment_period")
export const dashboardAffPath = makePath("dashboard/affiliate")
export const dashboardAffInsuranceCompanyPath = makePath("dashboard/affiliate/insurance_company")
export const dashboardAffInsurancePolicyPath = makePath("dashboard/affiliate/insurance_policy")
export const dashboardAffTeamPath = makePath("dashboard/affiliate/team")
export const dashboardAffSalesmanPath = makePath("dashboard/affiliate/salesman")
export const dashboardAffPaymentPeriodPath = makePath("dashboard/affiliate/payment_period")
export const dashboardInsurerCommissionPath = makePath("dashboard/insurer_commission")
export const dashboardOrdersCommissionPath = makePath("dashboard/orders_commission")

export const agtPath = makePath((token) => `auth/agt/${token}`)

export const homePath = () => {
  return `${rootPath()}/`
}
