import React from "react"
import styled, { css } from "@styled"
import { compose, withProps, withHandlers, withState, lifecycle } from "recompose"
import moment from "moment"
import * as path from "@common/path"
import {
  BreadCrumb,
  Button,
  Dropdown,
  Field,
  DatePicker,
  Input,
  Grid,
  GridColumn,
  makeCell,
  CurrencyInput,
} from "@components/index"
import { LoadingScope } from "@components"
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout"
import { get, isEqual } from "lodash"

import { withForm, withStores, withRouter } from "@enhancers/index"
import { displayNumber, toCurrency } from "@common/helper"

const Layout = styled.div`
  display: flex;
  flex-direction: column;
`
const BreadCrumbLayout = styled.div`
  width: 100%;
  margin-bottom: 37px;
`
const HeaderBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`
const Topic = styled.div`
  font-family: ThaiSans Neue;
  font-size: 30px;
  font-weight: normal;
  color: #666666;
  margin-bottom: 20px;
`
const WrapperHeader = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;

  > div:first-of-type {
    margin-right: 16px;
  }
`
const CustomDropdown = styled(Dropdown)`
  > div:first-of-type {
    height: 100%;
  }
`
const CustomButton = styled(Button)`
  width: 116px;
`

const NotFound = styled.div`
  font-size: 16px;
  font-family: Sarabun, sans-serif;
  color: #333333;
  color: #666666;
  width: 678px;
  height: 128px;
  background-color: #eeeef2;
  border: 1px solid #eeeef2;
  display: flex;
  justify-content: center;
  padding-top: 40px;
`

const Body = styled.div`
  font-size: 16px;
  font-family: Sarabun, sans-serif;
  color: #333333;
  > div:first-of-type {
    .k-link.k-header {
      border-radius: 4px 4px 0px 0px;
    }
  }
`
const CustomPanelBar = styled(PanelBar)`
  .k-item.k-state-default {
    .k-link.k-header {
      background-color: #294558;
      color: #fff;
      font-family: Sarabun, sans-serif;
      font-weight: normal;
      text-decoration: none;

      :hover {
        background-color: #294558;
        color: #fff;
      }
    }
  }
  .k-link.k-state-focused.k-state-selected {
    box-shadow: none !important;
  }
  .k-item.k-state-default.k-state-expanded {
    .k-link.k-header {
      > div {
        > div:nth-of-type(2) {
          display: none;
        }
      }
    }
  }
  .k-animation-container {
    z-index: 1;
  }
`
const Section = styled.div`
  display: flex;
  flex-direction: column;
`
const SectionHeader = styled.div`
  font-size: 16px;
  color: #666666;
  font-weight: normal;
  font-family: Sarabun, sans-serif;
  background-color: #e2e5e9;
  height: 40px;
  padding: 0 20px;
  display: flex;
  align-items: center;
`
const SectionBody = styled.div`
  background-color: #ffffff;
  border: 1px solid #eeeef2;
  padding: 30px 40px;
`
const TitlePanel = styled.div`
  width: calc(100% - 26px);
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
`
const EditBy = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  font-weight: normal;
  font-family: Sarabun, sans-serif;
  font-size: 10px;

  > div {
    font-size: 10px;
  }

  > div:first-of-type {
    margin-right: 22px;
  }
`
const FooterEditBy = styled.div`
  height: 30px;
  padding-right: 46px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #e2e5e9;
  border: 1px solid #eeeef2;
  > div {
    color: #666666;
  }
`
const SubContainer = styled.div`
  /* margin-bottom: 24px; */
  /*
  &:last-of-type {
    margin-bottom: 0;
  } */
`
const Caption = styled.div`
  font-weight: normal;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  width: 110px;
  color: #666666;
`
const Description = styled.div`
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  color: #666666;
  white-space: nowrap;
`
const SectionRow = styled.div`
  display: flex;
  align-items: flex-start;
`

const WrapperDetail = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
`
const DateSectionRow = styled(SectionRow)`
  display: flex;
  flex-direction: column;
`
const DateCaption = styled(Caption)`
  width: 300px;
`
const BlueDescription = styled(Description)`
  color: #0275d8;
`
const Table = styled(Grid)`
  .k-grid-header {
    background-color: #e2e5e9 !important;
    border-color: #e2e5e9 !important;
    padding: 0 !important;
    .k-header {
      /* font-weight: normal; */
      > a {
        padding: 12px !important;
      }
    }
  }

  .k-grid-header-wrap > table > thead > tr {
    background-color: #e2e5e9;
    border-color: #e2e5e9;
    > th > a {
      color: #666666;
    }
  }

  /* .k-grid-content.k-virtual-content > div {
    background-color: #eeeef2;
  } */

  .k-master-row {
    > td {
      flex-direction: row;
      padding: 0 12px;
    }

    > td:nth-of-type(4) {
      color: #5cb85c;
      text-align: end;
    }
    > td:nth-of-type(5) > div > div {
      color: #5cb85c;
      justify-content: flex-end;
    }
  }
`
const DetailHeader = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  background-color: #e2e5e9;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
`
const DetailHeaderCaption = styled.span`
  color: #666666;
  font-size: 16px;
  font-family: Sarabun, sans-serif;
  font-weight: normal;
`

const History = (props) => (
  <Layout>
    {props.loading && <LoadingScope />}

    <BreadCrumbLayout>
      <BreadCrumb links={props.links} />
    </BreadCrumbLayout>

    <HeaderBody>
      <Topic>ประวัติคอมมิชชั่นจ่าย</Topic>
      <WrapperHeader>
        <Field
          name="installment"
          caption="ประจำปี/งวด"
          component={CustomDropdown}
          options={props.installmentOptions}
          leftWidth="100"
          rightWidth="196"
        />

        <CustomButton icon="arrow-chevron-left" onClick={props.onBack} children="ย้อนกลับ" />
      </WrapperHeader>
    </HeaderBody>

    {props.currentHistory.length !== 0 ? (
      <Body>
        {props.currentHistory.reverse().map((history, index) => (
          <CustomPanelBar key={index}>
            <PanelBarItem
              expanded={index === 0 ? true : false}
              title={
                <TitlePanel>
                  <div>
                    {history.event === "create"
                      ? `สร้างเมื่อ ${moment(history.eventDatedAt).format(
                          "DD/MM/YYYY เวลา HH:mm น."
                        )}`
                      : history.event === "import"
                      ? `แก้ไขเมื่อ ${moment(history.eventDatedAt).format(
                          "DD/MM/YYYY เวลา HH:mm น. (นำเข้าไฟล์)"
                        )}`
                      : `แก้ไขเมื่อ ${moment(history.eventDatedAt).format(
                          "DD/MM/YYYY เวลา HH:mm น."
                        )}`}
                  </div>
                  {history.updatedBy ? (
                    <EditBy>
                      <div>{history.event === "import" ? "นำเข้าโดย" : "แก้ไขโดย"}</div>
                      <div>{history.updatedBy}</div>
                    </EditBy>
                  ) : null}
                </TitlePanel>
              }
            >
              {history.salesCommissionMaster ? (
                <>
                  <Section>
                    <SectionHeader>ข้อมูลคำสั่งซื้อ</SectionHeader>
                    <SectionBody>
                      <SubContainer style={{ marginBottom: 24 }}>
                        <SectionRow>
                          <WrapperDetail>
                            <Caption>รหัสคำสั่งซื้อ</Caption>
                            <Description>{history.salesCommissionMaster.tqmOrderId}</Description>
                          </WrapperDetail>
                          <WrapperDetail>
                            <Caption>วันที่แจ้งงาน</Caption>
                            <Description>
                              {`${moment(history.salesCommissionMaster.submittedAt).format(
                                "DD/MM/YYYY"
                              )}`}
                            </Description>
                          </WrapperDetail>
                        </SectionRow>

                        <SectionRow>
                          <WrapperDetail>
                            <Caption>ชื่อลูกค้า</Caption>
                            <Description>{history.salesCommissionMaster.customerName}</Description>
                          </WrapperDetail>
                        </SectionRow>
                        <SectionRow>
                          <WrapperDetail>
                            <Caption>แบบประกัน</Caption>
                            <Description>{history.salesCommissionMaster.policyName}</Description>
                          </WrapperDetail>
                        </SectionRow>
                        <SectionRow>
                          <WrapperDetail>
                            <Caption>บริษัทประกัน</Caption>
                            <Description>
                              {history.salesCommissionMaster.insuranceCompanyName}
                            </Description>
                          </WrapperDetail>
                        </SectionRow>
                      </SubContainer>

                      <SubContainer>
                        <DateSectionRow>
                          <WrapperDetail>
                            <DateCaption>วันนำออกคำนวณคอมมิชชั่น</DateCaption>
                            {history.salesCommissionMaster.calculateCommissionExportedDate && (
                              <BlueDescription>
                                {`${moment(
                                  history.salesCommissionMaster.calculateCommissionExportedDate
                                ).format("DD/MM/YYYY")}`}
                              </BlueDescription>
                            )}
                          </WrapperDetail>
                        </DateSectionRow>
                        <DateSectionRow>
                          <WrapperDetail>
                            <DateCaption>วันนำออกคอมมิชชั่นจ่าย</DateCaption>
                            {history.salesCommissionMaster.salesCommissionExportedDate && (
                              <Description>
                                {`${moment(
                                  history.salesCommissionMaster.salesCommissionExportedDate
                                ).format("DD/MM/YYYY")}`}
                              </Description>
                            )}
                          </WrapperDetail>
                        </DateSectionRow>
                        <DateSectionRow>
                          <WrapperDetail style={{ marginBottom: 0 }}>
                            <DateCaption>วันนำออกคอมมิชชั่นค้างจ่าย</DateCaption>
                            {history.salesCommissionMaster.pendingCommissionExportedDate && (
                              <BlueDescription>
                                {`${moment(
                                  history.salesCommissionMaster.pendingCommissionExportedDate
                                ).format("DD/MM/YYYY")}`}
                              </BlueDescription>
                            )}
                          </WrapperDetail>
                        </DateSectionRow>
                      </SubContainer>
                    </SectionBody>
                  </Section>

                  <Section>
                    <DetailHeader>
                      <DetailHeaderCaption>ข้อมูลคอมมิชชั่นจ่าย</DetailHeaderCaption>
                      {history.salesCommissionMaster.totalSalesCommission && (
                        <DetailHeaderCaption>{`รวม ${displayNumber(
                          parseFloat(history.salesCommissionMaster.totalSalesCommission)
                        )} บาท`}</DetailHeaderCaption>
                      )}
                    </DetailHeader>
                    <SectionBody>
                      <SubContainer>
                        <SectionRow>
                          <WrapperDetail>
                            <Caption>ทีมขาย</Caption>
                            <Description>{history.salesCommissionMaster.teamName}</Description>
                          </WrapperDetail>
                          <WrapperDetail style={{ textAlign: "end" }}>
                            <Caption>วันที่จ่าย</Caption>
                            <Description>
                              {history.salesCommissionMaster.salesCommissionExportedDate}
                            </Description>
                          </WrapperDetail>
                        </SectionRow>
                      </SubContainer>

                      <Table data={history.salesCommissionMaster.salesCommissionDetails}>
                        <GridColumn title="รหัสพนักงาน" field="staffCode" width="108px" />
                        <GridColumn title="ชื่อพนักงาน" field="fullName" width="149px" />
                        <GridColumn title="ตำแหน่ง" field="positionName" width="120px" />
                        <GridColumn
                          title="สัดส่วน(%)"
                          field="commissionPercent"
                          // cell={makeCell(CurrencyInput, (cell) => ({
                          //   readOnly: true,
                          //   value: cell.dataItem.percent,
                          //   // style: { color: "#5CB85C", display: "flex", justifyContent: "flex-end" },
                          //   // style: {
                          //   //   color: props.dataItem.percent >= 0 ? "#5CB85C" : "#D9534F",
                          //   // },
                          // }))}
                          width="96px"
                        />

                        <GridColumn
                          title="คอมมิชชั่นจ่าย"
                          field="commissionAmount"
                          cell={makeCell(CurrencyInput, (cell) => ({
                            readOnly: true,
                            value: cell.dataItem.commissionAmount,
                          }))}
                          width="119px"
                        />
                      </Table>
                    </SectionBody>
                  </Section>
                </>
              ) : null}

              {history.updatedBy ? (
                <FooterEditBy>
                  <EditBy>
                    <div>{history.event === "import" ? "นำเข้าโดย" : "แก้ไขโดย"}</div>
                    <div>{history.updatedBy}</div>
                  </EditBy>
                </FooterEditBy>
              ) : null}
            </PanelBarItem>
          </CustomPanelBar>
        ))}
      </Body>
    ) : (
      <NotFound>ไม่พบข้อมูล</NotFound>
    )}
  </Layout>
)

const enhancer = compose(
  withRouter(),
  withState("loading", "setLoading", false),
  withStores((stores, props) => {
    const saleCommissionId = props.match.params.saleCommissionId

    return {
      fetchConfigsById: stores.insurerCommissionStore.fetchConfigsById,
      fetchHistoryById: stores.saleCommissionStore.fetchHistory,
      currentHistory: stores.saleCommissionStore.currentHistory,
      installmentOptions: stores.insurerCommissionStore.$(
        "detailConfigs.attributes.installment.options",
        []
      ),
      saleCommissionId: saleCommissionId,
      initialValues: {
        installment: parseInt(saleCommissionId),
      },
    }
  }),
  withForm({
    form: "SalesCommissionHistory",
  }),
  withProps((props) => {
    let links = []
    links = [
      { label: "คอมมิชชั่นจ่าย", path: path.saleCommissionsPath() },
      {
        label: "ประวัติการแก้ไข",
        path: path.insurerCommissionHistoryPath(props.insurerCommissionId),
      },
    ]

    return {
      links,
    }
  }),
  withHandlers({
    onBack: (props) => () => {
      props.history.push(path.saleCommissionsPath())
    },
  }),
  lifecycle({
    async componentDidMount() {
      this.props.setLoading(true)
      await Promise.all([
        // this.props.fetchConfigsById(this.props.saleCommissionId),
        this.props.fetchHistoryById(this.props.saleCommissionId),
      ])
      this.props.setLoading(false)
    },
    async componentDidUpdate(prevProps) {
      const prevInstallmentValue = get(prevProps, "values.installment")
      const installmentValue = get(this.props, "values.installment")

      if (!isEqual(prevInstallmentValue, installmentValue)) {
        this.props.setLoading(true)
        await this.props.fetchHistoryById(installmentValue)
        this.props.setLoading(false)
      }
    },
  })
)

export default enhancer(History)
