import { compose, defaultProps, withProps } from "recompose"
import addressData from "./address_data.json"
import Dropdown from "@components/Dropdown.js"

const provinceList = addressData.map(([province]) => province)

const enhancer = compose(
  defaultProps({
    options: provinceList,
  }),
  withProps((props) => {
    const { name } = props.input || {}
    const className = props.className + " " + name || "" + name
    return { className }
  })
)

export default enhancer(Dropdown)
