import React, { useCallback, useEffect } from "react"
import styled from "@emotion/styled"
import { Field, Input, BreadCrumb, Dropdown, Button, Icon, Checkbox } from "@components"
import { compose, withState, withHandlers, lifecycle } from "recompose"
import { withForm, withStores, withRouter, withHooks } from "@enhancers/index"
import { Layout as LeadInfoLayout, Header, ButtonGroup } from "@pages/leads/show/LeadInfo"
import * as path from "@common/path"
import DeleteModal from "@pages/insurers/show/DeleteModal"
import Table from "./Table"
import HistoryTable from "./History/Table"

import { TopicContainer, TextTopic } from "@pages/market_plan/show/index"
import { isEmpty } from "lodash"

const Layout = styled(LeadInfoLayout)`
  margin: 0px auto 25px auto;
`
const CustomHeader = styled(Header)`
  justify-content: flex-end;
`
export const RightPanel = styled.div`
  display: flex;
  align-items: center;
`
const Body = styled.div`
  padding: 16px 24px;
  background-color: #ffffff;

  [class*="LeftPanel"] > div {
    font-weight: ${(props) => (props.readOnly ? "bolder" : "normal")};
  }

  [class*="RightPanel"] > span,
  input {
    width: 352px;
  }
`
const TrashButton = styled(Button)`
  width: 84px;
`
const CancelButton = styled(Button)`
  width: 90px;
`
const SubmitButton = styled(Button)`
  width: 100px;
`
const ButtonGroupContainer = styled(ButtonGroup)`
  display: flex;
`
const HistoryContainer = styled.div`
  display: ${(props) => (props.hidden ? "none" : "flex")};
  flex-direction: column;
`
const HistoryHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0 8px;
`
const HistoryHeaderText = styled.div`
  font-weight: bold;
`
const HistoryHeaderButton = styled.div`
  cursor: pointer;
  color: #0275d8;
`
const ChevronRightIcon = styled(Icon)`
  font-size: 12px;
  margin-left: 8px;
  color: #0275d8;
`
const InputAreaCode = styled(Input)`
  input {
    color: #666666;
  }
`
const CheckBoxField = styled(Field)`
  input {
    width: 16px !important;
  }
`

export const TeamInfo = (props) => (
  <Layout>
    <BreadCrumb />
    <CustomHeader>
      <RightPanel>
        {props.isEdit ? (
          <ButtonGroupContainer>
            <CancelButton
              icon="cancel"
              onClick={props.onCancel}
              children="ยกเลิก"
              styletype="delete"
            />
            <SubmitButton
              icon="floppy"
              onClick={props.onSave}
              children="บันทึก"
              styletype="agree"
            />
          </ButtonGroupContainer>
        ) : (
          <ButtonGroupContainer>
            <TrashButton icon="delete" onClick={props.onDelete} children="ลบ" styletype="delete" />
            <SubmitButton icon="edit" onClick={props.onEdit} children="แก้ไข" />
          </ButtonGroupContainer>
        )}
      </RightPanel>
    </CustomHeader>

    <TopicContainer>
      {props.showPage ? <TextTopic>รายละเอียดทีม</TextTopic> : <TextTopic>สร้างทีม</TextTopic>}
    </TopicContainer>

    <Body readOnly={!props.isEdit}>
      <Field
        name="name"
        caption="ชื่อทีม"
        component={Input}
        readOnly={!props.isEdit}
        placeholder="โปรดระบุ"
        validate={props.isEdit && "required"}
      />
      <Field
        name="areaCode"
        caption="พื้นที่"
        component={InputAreaCode}
        readOnly={!props.isEdit}
        placeholder="โปรดเลือก"
        validate={props.isEdit && "required"}
        disabled
      />
      <Field
        name="branchCode"
        caption="สาขา"
        component={Dropdown}
        options={props.branchOptions}
        readOnly={!props.isEdit}
        placeholder="โปรดเลือก"
        validate={props.isEdit && "required"}
        onChange={(val) => {
          props.addAreaCode(val)
        }}
      />

      <Field
        name="salesGroup"
        caption="กลุ่มงานขาย"
        component={Dropdown}
        options={props.salesGroupOptions}
        readOnly={!props.isEdit}
        placeholder="โปรดเลือก"
        validate={props.isEdit && "required"}
      />
      <Field
        name="regionalManagerId"
        caption="ผู้จัดการภาค"
        component={Dropdown}
        options={props.managerOptions}
        readOnly={!props.isEdit}
        placeholder="ค้นหา"
        validate={props.isEdit && "required"}
      />
      <Field
        name="areaManagerId"
        caption="ผู้จัดการเขต"
        component={Dropdown}
        options={props.managerOptions}
        readOnly={!props.isEdit}
        placeholder="ค้นหา"
        validate={props.isEdit && "required"}
      />
      <Field
        name="salesLeaderId"
        caption="หัวหน้าทีม"
        component={Dropdown}
        options={props.salesLeaderOptions}
        readOnly={!props.isEdit}
        placeholder="ค้นหา"
      />
      <Field
        name="agtTeamId"
        caption="ทีมผู้แนะนำ"
        component={Dropdown}
        options={props.affiliateTeamOptions}
        readOnly={!props.isEdit}
        placeholder="โปรดเลือก"
      />
      <CheckBoxField
        name="isBrokerageTeam"
        caption="ทีมนายหน้า"
        component={Checkbox}
        readOnly={!props.isEdit}
      />
      <Field
        name="salesmen"
        caption="สมาชิก"
        component={Dropdown}
        options={props.salesmanOptions}
        readOnly={!props.isEdit}
        onChange={props.addSalesToTeam}
        placeholder="ค้นหา"
      />
      <Table
        data={props.memberData}
        onRemove={props.removeSalesFromTeam}
        changeDate={props.changeDate}
        readOnly={!props.isEdit}
      />

      <HistoryContainer hidden={!props.showPage || props.isEdit}>
        <HistoryHeaderContainer>
          <HistoryHeaderText>ประวัติการแก้ไข</HistoryHeaderText>
          <HistoryHeaderButton onClick={props.gotoMoreHistory}>
            {`ดูประวัติการแก้ไขทั้งหมด`}
            <ChevronRightIcon awesomeIcon="chevron-right" />
          </HistoryHeaderButton>
        </HistoryHeaderContainer>
        <HistoryTable data={props.teamHistories} pageable={props.showPage} />
      </HistoryContainer>
    </Body>
    <DeleteModal
      open={props.isShowDeleteModal}
      onClose={props.onCloseModal}
      onAfterDeleteSubmit={props.onConfirmDelete}
      caption="Team"
    />
  </Layout>
)

const enhancer = compose(
  withRouter(),
  withStores((stores, props) => {
    const teamInfo = stores.teamStore.current
    let initialValues = {}

    if (!isEmpty(teamInfo)) {
      initialValues = {
        name: teamInfo.name,
        areaCode: teamInfo.areaCode,
        branchCode: teamInfo.branchCode,
        agtTeamId: teamInfo.agtTeamId,
        areaManagerId: (teamInfo.areaManager || {}).id,
        regionalManagerId: (teamInfo.regionalManager || {}).id,
        salesGroup: teamInfo.salesGroup,
        salesLeaderId: (teamInfo.salesLeader || {}).id,
        isBrokerageTeam: teamInfo.isBrokerageTeam,
        salesmen: teamInfo.salesmen.map((item) => {
          return {
            ...item,
            name: `${item.nameInfo.firstName} ${item.nameInfo.lastName}`,
            staffCode: item.staffCode,
            salesmanId: item.id,
          }
        }),
      }
    }

    return {
      fetchConfigs: stores.teamStore.fetchConfigs,
      fetchTeam: stores.teamStore.fetchById,
      teamId: props.match.params.teamId,
      initialValues: initialValues,
      teamInfo: stores.teamStore.current,
      updateTeamInfo: stores.teamStore.update,
      deleteTeam: stores.teamStore.delete,
      salesGroupOptions: stores.teamStore.$("configs.attributes.salesGroup.options", []),
      managerOptions: stores.teamStore.$("configs.attributes.manager.options", []),
      salesLeaderOptions: stores.teamStore.$("configs.attributes.salesLeader.options", []),
      salesmanOptions: stores.teamStore.$("configs.attributes.salesman.options", []),
      branchOptions: stores.teamStore.$("configs.attributes.branch.options", []),
      affiliateTeamOptions: stores.teamStore.$("configs.attributes.affiliateTeam.options", []),
      showPage: true,
      fetchHistory: stores.teamStore.fetchHistory,
      teamHistories: stores.teamStore.teamHistories,
    }
  }),
  lifecycle({
    async componentDidMount() {
      await Promise.all([
        this.props.fetchConfigs(),
        this.props.fetchTeam(this.props.teamId),
        this.props.fetchHistory(this.props.teamId, { perPage: 5 }),
      ])
    },
  }),
  withState("isEdit", "setIsEdit", false),
  withState("isShowDeleteModal", "setIsShowDeleteModal", false),
  withState("memberData", "setMemberData", []),
  withForm({ form: "TeamInfoForm" }),
  withHandlers({
    onCloseModal: (props) => () => props.setIsShowDeleteModal(false),
    onDelete: (props) => () => props.setIsShowDeleteModal(true),
    onEdit: (props) => () => {
      props.setIsEdit(true)
    },
    onCancel: (props) => () => {
      props.reset()
      props.setMemberData(props.initialValues.salesmen)
      props.setIsEdit(false)
    },
    onSave: (props) =>
      props.handleSubmit(async (values) => {
        let errorJoinTeamAt = false
        const newProps = {
          ...values,
          salesmen: props.memberData.map((item) => {
            if (item.joinTeamAt === null) {
              errorJoinTeamAt = true
            }
            return {
              salesmanId: item.salesmanId,
              joinTeamAt: item.joinTeamAt,
              exitTeamAt: item.exitTeamAt,
            }
          }),
        }
        if (errorJoinTeamAt) {
          alert("กรุณากรอกวันที่เริ่มต้นของสมาชิกทุกคนให้ครบ")
        } else {
          try {
            await props.updateTeamInfo(props.teamId, newProps)
            await props.fetchHistory(props.teamId, { perPage: 5 })
            props.setIsEdit(false)
          } catch (e) {
            if (e.name === "SubmissionError") {
              alert(e.errors.base)
            }
          }
        }
      }),
    onConfirmDelete: (props) => async () => {
      await props.deleteTeam(props.teamId)
      props.history.push(path.teamsPath())
    },
    gotoMoreHistory: (props) => () => {
      props.history.push(path.teamHistoryPath(props.teamId))
    },
  }),
  withHooks((props) => {
    useEffect(() => {
      props.setMemberData(props.initialValues.salesmen)
    }, [props.initialValues.salesmen])

    const addSalesToTeam = useCallback(
      (id) => {
        const salesman = props.salesmanOptions.find((item) => item.value === id)
        if (!isEmpty(salesman)) {
          props.setMemberData([
            ...props.memberData,
            {
              name: salesman.label.substring(salesman.label.indexOf(" ") + 1),
              staffCode: salesman.staffCode,
              salesmanId: salesman.value,
              startJobDate: salesman.startJobDate,
              joinTeamAt: null,
              exitTeamAt: null,
            },
          ])
          setTimeout(() => {
            props.change("salesmen", null)
          }, 200)
        }
      },
      [props.salesmanOptions, props.memberData]
    )

    const removeSalesFromTeam = useCallback(
      (id) => () => {
        const filterdMember = props.memberData.filter((item) => item.salesmanId !== id)
        props.setMemberData(filterdMember)
      },
      [props.memberData]
    )

    const changeDate = useCallback(
      (value, valueIndex, type) => {
        const tempMemberData = props.memberData.map((item, index) => {
          if (valueIndex === index) {
            return {
              ...item,
              joinTeamAt: type === "joinTeamAt" ? value : item.joinTeamAt,
              exitTeamAt: type === "exitTeamAt" ? value : item.exitTeamAt,
            }
          } else {
            return {
              ...item,
            }
          }
        })
        props.setMemberData(tempMemberData)
      },
      [props.memberData]
    )

    const addAreaCode = useCallback(
      (val) => {
        const areaCode = props.branchOptions.find((item) => item.value === val)
        if (!isEmpty(areaCode)) {
          props.change("areaCode", areaCode.areaCode)
        }
      },
      [props.branchOptions, props.branchCode]
    )

    return {
      addSalesToTeam,
      removeSalesFromTeam,
      changeDate,
      addAreaCode,
    }
  })
)

export default enhancer(TeamInfo)
