import React from "react"
import styled from "@emotion/styled"
import { Field, CurrencyInput, Input } from "@components/index"
import { FieldContainer, RemarkContainer, Premium, Remain } from "./CommissionTabDetail"
import { displayNumber } from "@common/helper"

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 20px 24px 20px;
  border: 1px solid #eeeef2;
  border-radius: 4px;
  background-color: #fff;
  margin-top: 10px;
`
const Row = styled.div`
  display: flex;
`
const CustomFieldContainer = styled(FieldContainer)`
  > div {
    > div {
      > div {
        padding-top: 0px !important;
      }
    }
  }
`
const Caption = styled.span`
  color: #666666;
  font-size: 16px;
  font-family: Sarabun, sans-serif;
  margin-left: 13px;
  font-weight: bold;
`
const CustomCurrencyInput = styled(CurrencyInput)`
  > div:first-of-type {
    width: 152px;
  }
  > div:last-of-type {
    width: 150px;
    > div {
      max-width: 220px;
      width: 100%;
    }
    > input {
      max-width: 220px;
      width: 100%;
    }
  }
`
const CustomInput = styled(Input)`
  > div:first-of-type {
    width: 152px;
  }
  > div:last-of-type {
    width: 150px;
    > div {
      max-width: 220px;
      width: 100%;
    }
    > input {
      max-width: 220px;
      width: 100%;
    }
  }
`

export const TeamCard = (props) => (
  <Layout>
    <Row>
      <Caption>{props.name}</Caption>
    </Row>
    <Row>
      <CustomFieldContainer>
        <Field
          name="teamName"
          caption="ชื่อทีม"
          component={CustomInput}
          readOnly={props.defaultReadOnly}
          leftWidth="152"
        />
      </CustomFieldContainer>
    </Row>
    <Row>
      <FieldContainer>
        <Field
          name="teamManagementPercent"
          caption="ค่าบริหารทีม"
          component={CustomCurrencyInput}
          readOnly={props.readOnly}
          leftWidth="152"
          d
          unit="%"
        />
      </FieldContainer>
    </Row>
    <Row>
      {props.isReceivedCommissionState ? (
        <FieldContainer>
          <Field
            name="teamManagementFee"
            caption="คิดเป็นเงิน"
            component={CustomCurrencyInput}
            readOnly={props.readOnly}
            leftWidth="152"
            unit="บาท"
            precision={2}
          />
          <RemarkContainer>
            <Premium>{`*จ่ายจริง ${
              displayNumber(parseFloat(props.initialValues.realTeamManagementFee)) || "-"
            } บาท`}</Premium>
            <Remain>{`คงเหลือ ${
              displayNumber(
                parseFloat(
                  parseFloat(props.initialValues.teamManagementFee || 0).toFixed(2) -
                    parseFloat(props.initialValues.realTeamManagementFee || 0).toFixed(2)
                ).toFixed(2)
              ) || "-"
            } บาท`}</Remain>
          </RemarkContainer>
        </FieldContainer>
      ) : (
        <Field
          name="teamManagementFee"
          caption="คิดเป็นเงิน"
          component={CustomCurrencyInput}
          readOnly={props.readOnly}
          leftWidth="152"
          unit="บาท"
          precision={2}
        />
      )}
    </Row>
  </Layout>
)

export default TeamCard
