import React, { useEffect } from "react"
import styled from "@emotion/styled"
import { compose, withState, withHandlers, lifecycle, withProps } from "recompose"
import { withForm, withStores, withHooks } from "@enhancers"
import { Field, BreadCrumb, Button, Dropdown, Checkbox, LoadingScope } from "@components"
import { Body, Header, RightPanel, ButtonGroup } from "@pages/leads/show/LeadInfo"
import * as path from "@common/path"

const Layout = styled.div``
const CustomHeader = styled(Header)`
  justify-content: flex-end;
`
const CustomBody = styled(Body)`
  > div:first-of-type {
    margin-bottom: 16px;
  }
`
const SubmitButton = styled(Button)`
  width: 100px;
`
const CancelButton = styled(Button)`
  width: 90px;
`
const RiderContainer = styled.div`
  display: flex;
  flex-direction: row;
`
const RiderCaption = styled.div`
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  color: #666666;
  font-weight: 600;
  width: 180px;
  padding-top: 7px;
`
const RiderCheckbox = styled.div`
  display: flex;
  flex-direction: column;
`

export const Detail = (props) => (
  <React.Fragment>
    {props.loading ? (
      <LoadingScope />
    ) : (
      <Layout>
        <BreadCrumb />
        <CustomHeader>
          <RightPanel>
            {props.isEdit ? (
              <ButtonGroup>
                <CancelButton
                  icon="cancel"
                  onClick={props.toggleEditing}
                  children="ยกเลิก"
                  styletype="delete"
                />
                <SubmitButton
                  icon="floppy"
                  onClick={props.onSave}
                  children="บันทึก"
                  styletype="agree"
                />
              </ButtonGroup>
            ) : (
              <ButtonGroup>
                <SubmitButton icon="edit" onClick={props.toggleEditing} children="แก้ไข" />
              </ButtonGroup>
            )}
          </RightPanel>
        </CustomHeader>
        <CustomBody>
          <Field
            caption="แบบประกันชีวิต"
            name="lifeId"
            component={Dropdown}
            options={props.policyOptions}
            onChange={props.lifeItemChange}
            readOnly
            validate="required"
          />

          <RiderContainer>
            <RiderCaption>สัญญาเพิ่มเติม</RiderCaption>
            <RiderCheckbox>
              {props.riderOptions.map((rider, index) => (
                <Checkbox
                  label={rider.label}
                  value={props.riderData.includes(rider.value)}
                  onChange={(checked) => props.selectRider(rider, checked)}
                  readOnly={!props.isEdit}
                  hidden={!props.riderData.includes(rider.value) && !props.isEdit}
                />
              ))}
            </RiderCheckbox>
          </RiderContainer>
        </CustomBody>
      </Layout>
    )}
  </React.Fragment>
)

const enhencer = compose(
  withState("item", "setItem", 0),
  withState("lifeIdItem", "setLifeIdItem", 0),
  withState("itemList", "setItemList", []),
  withState("isEdit", "setIsEdit", false),
  withState("isShowModalAdd", "setIsShowModalAdd", false),
  withState("riderData", "setRiderData", []),
  withState("loading", "setLoading", false),
  withStores((stores, props) => {
    let CustomCurrent = stores.riderStore.current
    let riderIdOptions = CustomCurrent.riderIds
    let CustomOptionRider = stores.riderStore.$("configs.configs.attributes.riderId.options", [])
    for (let index = 0; index < riderIdOptions.length; index++) {
      CustomOptionRider = CustomOptionRider.filter((x) => x.value !== riderIdOptions[index])
    }
    for (let index = 0; index < CustomCurrent.riderIds.length; index++) {
      CustomCurrent = {
        ...CustomCurrent,
        [`index${index}`]: stores.riderStore.current.riderIds[index],
      }
    }
    return {
      current: stores.riderStore.current,
      fetchData: stores.riderStore.fetch,
      fetchOne: stores.riderStore.fetchOne,
      leadIdOptions: stores.riderStore.$("configs.configs.attributes.lifeId.options", []),
      riderIdOptionsClassic: stores.riderStore.$("configs.configs.attributes.riderId.options", []),
      riderIdOptions: CustomOptionRider,
      initialValues: CustomCurrent,
      addItemMobx: stores.riderStore.addItemRider,
      deleteItemMobx: stores.riderStore.deleteItemRider,
      cancelForm: stores.riderStore.cancelForm,
      update: stores.riderStore.updateRider,
      originalPolicyOptions: stores.riderStore.$("configs.configs.attributes.lifeId.options", []),
      originalRiderOptions: stores.riderStore.$("configs.configs.attributes.riderId.options", []),
    }
  }),
  withProps((props) => {
    const policyOptions = props.originalPolicyOptions.filter((policy) => policy.state === "online")
    return {
      policyOptions,
    }
  }),
  withForm({ form: "EditRiderInfoForm", enableReinitialize: true }),
  withHandlers({
    onCloseAdd: (props) => () => {
      props.setIsShowModalAdd(!props.isShowModalAdd)
    },
    onDelete: (props) => (e) => () => {
      let newItemList = props.itemList
      newItemList.splice(e, 1)
      props.setItemList(newItemList)
    },
    addItem: (props) => () => {
      if (props.item) {
        let newItemList = props.itemList
        newItemList.push(props.item)
        props.setItemList(newItemList)
        props.setItem("")
        props.setIsShowModalAdd(!props.isShowModalAdd)
      }
    },
    onCancel: (props) => () => {
      props.history.goBack()
    },
    toggleEditing: (props) => () => {
      props.setIsEdit(!props.isEdit)
    },
    onSave: (props) =>
      props.handleSubmit(async (values) => {
        values = { lifeId: values.lifeId, riderIds: props.riderData }
        try {
          await props.update(values)
          props.setIsEdit(!props.isEdit)
        } catch (error) {
          if (error) {
            window.alert(error)
          } else {
            window.alert("จับคู่สัญญาเพิ่มเติมไม่สำเร็จ")
          }
        }
      }),
    selectRider: (props) => (rider, checked) => {
      const data = props.riderData.filter((item) => item !== rider.value)
      if (checked) {
        data.push(rider.value)
      }
      props.setRiderData(data)
    },
  }),
  lifecycle({
    async componentDidMount() {
      this.props.setLoading(true)
      const response = await this.props.fetchOne(this.props.match.params.riderId)
      this.props.setRiderData(response.riderIds)
      this.props.setLoading(false)
    },
  }),
  withState("riderOptions", "setRiderOptions", []),
  withHooks((props) => {
    useEffect(() => {
      const policyInsurerId = (
        props.policyOptions.find((policy) => policy.value === props.values.lifeId) || {}
      ).insuranceCompanyId
      const riderOptions = props.originalRiderOptions.filter(
        (rider) => rider.insuranceCompanyId === policyInsurerId && rider.state === "online"
      )
      props.setRiderData([])
      props.setRiderOptions(riderOptions)
    }, [props.values.lifeId])
  })
)

export default enhencer(Detail)
