import React from "react"
import styled from "@styled"
import { GridColumn } from "@components/index"
import * as path from "@common/path"
import GraphContainer from "./GraphContainer"
import {
  Layout,
  ReportCardTitle,
  InsuranceCompany,
  CompanyLogo,
  FooterContainer,
  ShowMoreDetailContainer,
  ShowMoreDetailText,
  CaretRightIcon,
  Table as DefaultTable,
} from "./InsuranceCompanyCard"

export const CatagoryText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
  color: ${(props) => props.color};
`
export const Table = styled(DefaultTable)`
  .k-master-row > td {
    text-align: end;

    :nth-of-type(1) {
      text-align: start;
      padding: 0 12px;
    }

    :nth-of-type(2) {
      text-align: start;
      padding: 0 12px;
    }

    :nth-of-type(3) {
      text-align: start;
      padding: 0 12px;
    }
  }
`

const InsurancePolicyCard = (props) => {
  return (
    <Layout>
      <ReportCardTitle>
        {props.dashboardType === "affiliate"
          ? "ยอดขายแยกตามแบบประกันจากผู้แนะนำ"
          : "ยอดขายแยกตามแบบประกัน"}
      </ReportCardTitle>
      <GraphContainer graph={props.graph} />
      <Table data={props.data} style={{ marginTop: 27 }}>
        <GridColumn
          title="บริษัทประกัน"
          field="name"
          cell={(cell) => {
            return (
              <td>
                <InsuranceCompany>
                  <CompanyLogo src={cell.dataItem.companyLogo} />
                  <CatagoryText>{cell.dataItem.companyName}</CatagoryText>
                </InsuranceCompany>
              </td>
            )
          }}
          width="115"
        />
        <GridColumn title="แบบประกัน" field="policyName" width="300" />
        <GridColumn
          title="สถานะแบบประกัน"
          field="policyState"
          cell={(cell) => {
            return (
              <td>
                <CatagoryText color={cell.dataItem.policyState.color}>
                  {cell.dataItem.policyState.state}
                </CatagoryText>
              </td>
            )
          }}
          width="170"
        />
        <GridColumn title="ยอดเบี้ยที่ขายได้" field="salablePremium" width="136" />
        <GridColumn title="ยอดเบี้ยที่ยกเลิก" field="rejectedPremium" width="133" />
        <GridColumn title="ยอดเบี้ยรวม" field="totalPremium" width="106" />
        <GridColumn title="กรมธรรม์ที่ขายได้" field="salablePolicy" width="144" />
        <GridColumn title="กรมธรรม์ที่ยกเลิก" field="rejectedPolicy" width="144" />
        <GridColumn title="กรมธรรม์รวม" field="totalPolicy" width="115" />
      </Table>

      <FooterContainer>
        <ShowMoreDetailContainer
          to={
            props.dashboardType === "affiliate"
              ? path.dashboardAffInsurancePolicyPath()
              : path.dashboardInsurancePolicyPath()
          }
        >
          <ShowMoreDetailText>ดูยอดขายแยกตามแบบประกันทั้งหมด</ShowMoreDetailText>
          <CaretRightIcon awesomeIcon={"caret-right"} />
        </ShowMoreDetailContainer>
      </FooterContainer>
    </Layout>
  )
}

export default InsurancePolicyCard
