import React, { useCallback, useState } from "react"
import { compose, withHandlers, withState, withProps } from "recompose"
import { reduxForm } from "redux-form"
import { withStores, withRouter, withHooks } from "@enhancers/index"
import styled from "@styled"
import { Button, FilterButton, Switch, Case } from "@components/index"
import * as path from "@common/path"
import { exceptNullKeys } from "@common/helper"

import FilterPanel from "./FilterPanel"
import AssignModal from "./AssignModal"
import SalePanel from "./SalePanel"
import AssignedSuccessModal from "../../leads/index/AssignedSuccessModal"

const Layout = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 12px 0;
  margin-top: 15px;
`
const ButtonGroup = styled.div`
  display: ${(props) => (props.disabled ? "none" : "flex")};

  flex: 1;
  justify-content: flex-end;

  > *:not(:nth-of-type(1)) {
    margin-left: 10px;
  }
`
const UnAssignButton = styled(Button)`
  width: 151px;
`
const AssignButton = styled(Button)`
  width: 130px;
`
const WarningWording = styled.p`
  padding: 11.5px 54px;
  width: 100%;
  color: #fff;
  background-color: #025aa5;
  margin-bottom: 0;
`

const Header = (props) => {
  return (
    <Layout>
      <FilterButton
        expanded={props.filterExpandedValue}
        onExpand={props.expandFilter}
        disabled={props.disabledFilter}
      >
        <FilterPanel
          disabled={props.disabledFilter}
          disabledStateFilter={props.disabledStateFilter}
          mode={props.mode}
        />
      </FilterButton>
      <ButtonGroup>
        <Switch>
          <Case when={props.mode === null}>
            <UnAssignButton
              icon="undo"
              onClick={props.unassignLead}
              children="ดึงลีดกลับ"
              authorize={{
                roles: [
                  "user.admin",
                  "member.lead_admin",
                  "member.team_leader",
                  "member.brokerage_admin",
                ],
              }}
              styletype="secondary"
            />
            <AssignButton
              awesomeIcon="user"
              onClick={props.assignLead}
              children="มอบหมาย"
              authorize={{
                roles: [
                  "user.admin",
                  "member.lead_admin",
                  "member.team_leader",
                  "member.brokerage_admin",
                ],
              }}
              styletype="normal"
            />
          </Case>
          <Case when={["assigning", "unAssigning"].includes(props.mode)}>
            <Button icon="cancel" onClick={props.cancel} children="ยกเลิก" styletype="delete" />
            <Button
              icon="check"
              onClick={props.confirm}
              children="ยืนยัน"
              styletype="agree"
              disabled={props.selectedAffiliateOrderIds.length === 0}
            />
          </Case>
        </Switch>
      </ButtonGroup>
      {props.mode === "assigning" && (
        <WarningWording>
          * ควรมอบหมายคำสั่งซื้อทั้งหมดของโอกาสในการขายหนึ่งๆ ให้กับพนักงานขายคนเดียวกัน
        </WarningWording>
      )}
      {props.toggleAssignModalValue && props.currentRole !== "member.team_leader" && (
        <AssignModal
          form="LeadPoolModalFilterForm"
          toggleValue={props.toggleAssignModalValue}
          setToggleModalValue={props.setToggleAssignModalValue}
        />
      )}
      <SalePanel
        open={props.toggleSalePanelValue}
        onCancel={props.toggleSalePanel}
        onSubmit={props.confirmSalePanel}
        selectedAffiliateOrderIds={props.selectedAffiliateOrderIds}
      />
      <AssignedSuccessModal
        modalTitle="มอบหมายลีด"
        infoCaption="มอบหมายลีดสำเร็จ"
        buttonTitle="ปิด"
        open={props.toggleAssignedSuccessModalValue}
        onClose={props.toggleAssignedSuccessModal}
        details={props.successAssignDetail}
      />
    </Layout>
  )
}

const enhancer = compose(
  withRouter(),
  withStores((stores, props) => ({
    query: props.location.query,
    mode: props.location.query.mode,
    currentRole: stores.appStore.currentRole,
    unassignLeadPools: stores.leadPoolStore.unassign,
    assignLeadPools: stores.leadPoolStore.assign,
    fetchLeadPools: stores.leadPoolStore.fetch,
  })),
  withHooks((props) => {
    const [filterExpandedValue, setFilterExpandedValue] = useState(false)
    const [toggleAssignModalValue, setToggleAssignModalValue] = useState(false)
    const [toggleSalePanelValue, setToggleSalePanelValue] = useState(false)
    const [toggleAssignedSuccessModalValue, setToggleAssignedSuccessModalValue] = useState(false)
    const [successAssignDetail, setSuccessAssignDetail] = useState({})

    const expandFilter = useCallback(() => {
      setFilterExpandedValue(!filterExpandedValue)
    })

    const toggleSalePanel = useCallback(() => {
      setToggleSalePanelValue(!toggleSalePanelValue)
    })

    const toggleAssignedSuccessModal = useCallback(() => {
      setToggleAssignedSuccessModalValue(!toggleAssignedSuccessModalValue)
    })

    const assignLead = useCallback(async () => {
      if (props.currentRole !== "member.team_leader") {
        setToggleAssignModalValue(true)
        return
      }

      props.history.push(
        path.leadPoolsPath({
          ...props.query,
          affiliateOrderStatus: "team_assigned",
          mode: "assigning",
          page: 1,
          perPage: 10000,
        })
      )
      return
    })

    const unassignLead = useCallback(async () => {
      props.history.push(
        path.leadPoolsPath({
          ...props.query,
          mode: "unAssigning",
          page: 1,
          perPage: 10000,
        })
      )
    })

    const confirm = useCallback(async () => {
      switch (props.mode) {
        case "assigning":
          setToggleSalePanelValue(!toggleSalePanelValue)
          break
        case "unAssigning":
          await props.unassignLeadPools(props.selectedAffiliateOrderIds)
          props.history.push(path.leadPoolsPath())
          break
        default:
          break
      }
    })

    const confirmSalePanel = useCallback(
      async (selectedSaleIds, selectedSalesName, selectedTeamIds, options) => {
        const selectedAffiliateOrderIds = props.selectedAffiliateOrderIds
        const startSaleDate = options.startDate
        const expireSaleDate = options.expiredDate
        try {
          await props.assignLeadPools(
            selectedAffiliateOrderIds,
            selectedSaleIds,
            selectedTeamIds,
            startSaleDate,
            expireSaleDate,
            options
          )
          setToggleSalePanelValue(!toggleSalePanelValue)

          let selectedTeamsName = []
          if (selectedTeamIds.length > 0) {
            selectedTeamsName = selectedTeamIds.map(
              (selectedSaleId) => props.teams.find((team) => team.id === selectedSaleId).name
            )
          }

          setSuccessAssignDetail({
            selectedLeads: selectedAffiliateOrderIds,
            salesLeader: {
              caption: "พนักงานขาย",
              values: selectedSalesName,
              haveValues: selectedSalesName.length > 0,
            },
            sales:
              {
                caption: "พนักงานขาย",
                values: selectedSalesName,
                haveValues: selectedSalesName.length > 0,
              } || {},
            teams:
              {
                caption: "ทีม",
                values: selectedTeamsName,
                haveValues: selectedTeamsName.length > 0,
              } || {},
          })
          setToggleAssignedSuccessModalValue(!toggleAssignedSuccessModalValue)
          props.history.push(path.leadPoolsPath())
        } catch (e) {
          const errorMsg = e.errors?.base || "เกิดข้อผิดพลาดบางอย่างขึ้น"
          alert(errorMsg)
        }
      }
    )

    const cancel = useCallback(() => {
      props.history.push(path.leadPoolsPath())
    })

    return {
      filterExpandedValue,
      expandFilter,
      assignLead,
      unassignLead,
      confirmSalePanel,

      toggleAssignModalValue,
      setToggleAssignModalValue,
      toggleAssignedSuccessModalValue,
      toggleAssignedSuccessModal,
      toggleSalePanelValue,
      toggleSalePanel,

      mode: props.mode || null,
      confirm,
      cancel,
      successAssignDetail,
    }
  }),
  withProps((props) => {
    return {
      initialValues: props.location.query,
    }
  }),
  withState("fetchLeadPoolTask", "setFetchLeadPoolTask", null),
  withHandlers({
    onUpdateFilterParams: (props) => async (values, delay = 0) => {
      const exceptNullFilterParams = exceptNullKeys(
        values,
        "age.min",
        "age.max",
        "affiliateOrderStatus",
        "gender",
        "province",
        "district",
        "subDistrict",
        "teamId",
        "orderNo",
        "agtOpportunityKey",
        "affiliateId",
        "limit"
      )

      if (props.fetchLeadPoolTask) {
        clearTimeout(props.fetchLeadPoolTask)
      }
      await props.setFetchLeadPoolTask(
        setTimeout(() => {
          if (["assigning", "unAssigning"].includes(props.mode)) {
            props.history.push(
              path.leadPoolsPath({
                ...values,
                page: 1,
                perPage: 10000,
                ...exceptNullFilterParams,
              })
            )
          } else {
            props.history.push(
              path.leadPoolsPath({
                ...values,
                ...exceptNullFilterParams,
              })
            )
          }
        }, delay)
      )
    },
  }),
  reduxForm({
    form: "LeadPoolFilterForm",
    enableReinitialize: true,
    onChange: (filters, _, props) => {
      props.onUpdateFilterParams(filters, 400)
    },
  })
)

export default enhancer(Header)
