import React, { useEffect, useMemo } from "react"
import styled from "@emotion/styled"
import { compose, withState, withProps, withHandlers } from "recompose"
import * as path from "@common/path"
import { BreadCrumb } from "@components"
import { withStores, withRouter, withHooks } from "@enhancers/index"
import FilterButton from "./FilterButton"
import Loading from "@pages/index/Loading.js"
import PackageCard from "./PackageCard"
import ProductCard from "./ProductCard"
import CompareFooter from "./CompareFooter"
import moment from "moment"
import BodyForAllProduct from "./BodyForAllProduct"

const MainLayout = styled.div`
  width: 100%;
`
const Body = styled.div`
  width: 100%;
  margin: 20px auto;
  position: relative;
`
const ProductContainer = styled.div`
  display: flex;
  margin-top: 16px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  > div {
    max-width: 580px;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`
const NoProduct = styled.div`
  margin-top: 50px;
  min-width: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  > div {
    background-color: #eeeef2;
    border: 1px solid #eeeef2;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    width: 581px;
    height: 178px;
    padding-top: 42px;
  }
`
export const ProductList = (props) => (
  <MainLayout>
    <BreadCrumb links={props.links} />
    <Body>
      <FilterButton icon="filter">ปรับตัวเลือก</FilterButton>
      {props.loading ? (
        <Loading />
      ) : (
        <>
          {props.newProducts.length > 0 ? (
            props.type === "all" ? (
              <BodyForAllProduct
                dataPolicies={props.newProducts}
                search={props.location.query}
                leadId={props.leadId}
              />
            ) : (
              <ProductContainer>
                <div>
                  {props.newProducts.map((product, index) => (
                    <>
                      {product.type === "product" && (
                        <ProductCard
                          key={index}
                          product={product}
                          search={props.location.query}
                          onSelect={props.setSelectedCompare}
                          compareProduct={props.compareProduct}
                        />
                      )}
                      {product.type === "package" && (
                        <PackageCard
                          key={index}
                          product={product}
                          search={props.location.query}
                          leadId={props.leadId}
                        />
                      )}
                    </>
                  ))}
                </div>
              </ProductContainer>
            )
          ) : (
            <NoProduct>
              <div>ไม่พบข้อมูลผลิตภัณฑ์</div>
            </NoProduct>
          )}
        </>
      )}
    </Body>
    {props.compareProduct.length > 0 && (
      <CompareFooter
        selectedCompareProduct={props.compareProduct}
        setDeselectCompareProduct={props.setDeselectCompareProduct}
        leadId={props.leadId}
      />
    )}
  </MainLayout>
)

const enhancer = compose(
  withRouter({
    queryTypes: {
      birthdate: "date",
      gender: "string",
      searchValue: "integer",
      type: "string",
      searchBy: "string",
      leadId: "integer",
      from: "string",
      sumInsured: "integer",
    },
  }),
  withProps((props) => {
    const routeParams = props.location.query
    const age = routeParams.birthdate ? moment().diff(moment(routeParams.birthdate), "years") : null
    if (routeParams.type === "all" || routeParams.type === "package") {
      delete routeParams.searchValue
    }

    return { ...routeParams, searchBy: routeParams.searchBy || "sum_insured", age }
  }),
  withProps((props) => {
    const { from = "" } = props
    let links = []
    switch (from) {
      case "custom_lead":
        links = [
          { label: "ลูกค้าใหม่", path: path.leadsPath("custom") },
          {
            label: "รายละเอียดลีด",
            path: path.leadOrderInfoPath(props.leadId),
          },
          {
            label: "ค้นหาแบบประกัน",
            path: path.productsSearchPath(props.leadId, {
              leadId: props.leadId,
              gender: props.gender,
              searchValue: props.searchValue,
              type: props.type,
              birthdate: props.birthdate,
              from: props.from,
              searchBy: props.searchBy,
              sumInsured: props.sumInsured,
            }),
          },
          {
            label: "เลือกแบบประกัน",
          },
        ]
        break
      case "batch_lead":
        links = [
          { label: "ลีดทีคิวเอ็ม", path: path.leadsPath("all") },
          {
            label: "รายละเอียดลีด",
            path: path.leadOrderInfoPath(props.leadId),
          },
          {
            label: "ค้นหาแบบประกัน",
            path: path.productsSearchPath(props.leadId, {
              leadId: props.leadId,
              gender: props.gender,
              searchValue: props.searchValue,
              type: props.type,
              birthdate: props.birthdate,
              from: props.from,
              searchBy: props.searchBy,
              sumInsured: props.sumInsured,
            }),
          },
          {
            label: "เลือกแบบประกัน",
          },
        ]
        break
      default:
        links = [
          {
            label: "ผลิตภัณฑ์",
            path: "/",
          },
          {
            label: "ค้นหาแบบประกัน",
            path: path.productsSearchPath({
              gender: props.gender,
              searchValue: props.searchValue,
              type: props.type,
              birthdate: props.birthdate,
              searchBy: props.searchBy,
              sumInsured: props.sumInsured,
            }),
          },
          {
            label: "เลือกแบบประกัน",
          },
        ]
        break
    }
    return { links }
  }),
  withState("loading", "setLoading", false),
  withState("compareProduct", "setCompareProduct", []),
  withStores((stores, props) => ({
    fetchConfigs: stores.productStore.fetchConfigs,
    fetchProducts: stores.productStore.fetchProducts,
    searchPackages: stores.packageStore.searchPackages,
    params: props.location.query,
    leadId: props.match.params.leadId,
    products: stores.productStore.$("products", []),
    packages: stores.packageStore.$("packages" || []),
    clearPackage: stores.packageStore.clearPackage,
    clearProduct: stores.productStore.clearProduct,
  })),
  withHandlers({
    clearCompareProduct: (props) => () => {
      props.setCompareProduct([])
    },
  }),
  withHandlers({
    onAfterOptionsSubmit: (props) => () => {
      props.setCompareProduct([])
    },
    setSelectedCompare: (props) => (value, selectedValues) => {
      if (value) {
        if (props.compareProduct.length < 3) {
          if (props.compareProduct.length === 0) {
            const selectedCompareProduct = props.compareProduct
            selectedCompareProduct.push(selectedValues)
            props.setCompareProduct(selectedCompareProduct)
          } else {
            const isDuplicatedValue = props.compareProduct.find(
              ({ id }) => selectedValues.id === id
            )
            if (!isDuplicatedValue) {
              const selectedCompareProduct = props.compareProduct
              selectedCompareProduct.push(selectedValues)
              props.setCompareProduct(selectedCompareProduct)
            }
          }
        }
      } else {
        const compareProduct = props.compareProduct.filter(({ id }) => {
          return selectedValues.id !== id
        })
        props.setCompareProduct(compareProduct)
      }
    },
    setDeselectCompareProduct: (props) => (deSelectedId) => {
      const compareProduct = props.compareProduct.filter(({ id }) => {
        return deSelectedId !== id
      })
      props.setCompareProduct(compareProduct)
    },
    searchProducts: (props) => async () => {
      props.setLoading(true)
      props.clearPackage()
      props.clearProduct()
      props.clearCompareProduct()

      if (props.location.search) {
        const { type, birthdate, gender, searchValue, searchBy, sumInsured } = props.location.query

        switch (type) {
          case "all":
            await Promise.all([
              props.fetchProducts({ birthdate, gender }),
              props.searchPackages({ birthdate, gender }),
            ])
            break
          case "package":
            await Promise.all([
              props.searchPackages({ birthdate, gender, searchValue, searchBy, sumInsured }),
            ])
            break
          default:
            await Promise.all([
              props.fetchProducts({ birthdate, gender, searchValue, type, searchBy }),
            ])
        }
      }
      props.setLoading(false)
    },
  }),
  withProps((props) => {
    const newProducts = []
    if (props.products) {
      newProducts.push(
        ...props.products.map((product) => {
          return {
            ...product,
            type: "product",
          }
        })
      )
    }
    if (props.packages) {
      newProducts.push(
        ...props.packages.map((pack) => {
          return {
            ...pack,
            type: "package",
          }
        })
      )
    }

    return {
      newProducts: newProducts,
    }
  }),
  withHooks((props) => {
    const routeParams = useMemo(() => {
      return props.location.query
    }, [JSON.stringify(props.location.query)])

    useEffect(() => {
      props.fetchConfigs()
    }, [])

    useEffect(() => {
      props.searchProducts()
    }, [JSON.stringify(routeParams)])
  })
)

export default enhancer(ProductList)
