import React from "react"
import styled from "@emotion/styled"
import { compose, withProps } from "recompose"
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout"
import { isEmpty } from "lodash"

const Layout = styled.div`
  flex: 1;
  width: 100%;
`
const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
`
const Title = styled.div`
  width: 350px;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
`
const Detail = styled.div`
  display: flex;
  justify-content: flex-end;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
`

const Ai = (props) => (
  <Layout>
    {props.riderPolicyDetail.protectionDetail !== undefined && (
      <PanelBar>
        {!isEmpty(props.deadByAccidents) ? (
          <PanelBarItem expanded={true} title="กรณีเสียชีวิต">
            {props.deadByAccidents.map((deadByAccident) => (
              <React.Fragment>
                {Object.keys(deadByAccident).map((key) => (
                  <ContentContainer>
                    <Title>{deadByAccident[key].label}</Title>
                    <Detail>{deadByAccident[key].value}</Detail>
                  </ContentContainer>
                ))}
              </React.Fragment>
            ))}
          </PanelBarItem>
        ) : null}

        {!isEmpty(props.lossOfOrgans) ? (
          <PanelBarItem expanded={true} title="สูญเสียอวัยวะ">
            {props.lossOfOrgans.map((lossOfOrgan) => (
              <React.Fragment>
                {Object.keys(lossOfOrgan).map((key) => (
                  <ContentContainer>
                    <Title>{lossOfOrgan[key].label}</Title>
                    <Detail>{lossOfOrgan[key].value}</Detail>
                  </ContentContainer>
                ))}
              </React.Fragment>
            ))}
          </PanelBarItem>
        ) : null}

        {!isEmpty(props.disabledDatas) ? (
          <PanelBarItem expanded={true} title="ทุพพลภาพ">
            {props.disabledDatas.map((disabledData) => (
              <React.Fragment>
                {Object.keys(disabledData).map((key) => (
                  <ContentContainer>
                    <Title>{disabledData[key].label}</Title>
                    {disabledData[key].value ? (
                      typeof disabledData[key].value === "string" ? (
                        <Detail>{disabledData[key].value}</Detail>
                      ) : (
                        <Detail>{disabledData[key].value.value}</Detail>
                      )
                    ) : null}
                  </ContentContainer>
                ))}
              </React.Fragment>
            ))}
          </PanelBarItem>
        ) : null}

        {!isEmpty(props.treatments) ? (
          <PanelBarItem expanded={true} title="การรักษาพยาบาล">
            {props.treatments.map((treatment) => (
              <React.Fragment>
                {Object.keys(treatment).map((key) => (
                  <ContentContainer>
                    <Title>{treatment[key].label}</Title>
                    {treatment[key].value ? (
                      typeof treatment[key].value === "string" ? (
                        <Detail>{treatment[key].value}</Detail>
                      ) : (
                        <Detail>{treatment[key].value.value}</Detail>
                      )
                    ) : null}
                  </ContentContainer>
                ))}
              </React.Fragment>
            ))}
          </PanelBarItem>
        ) : null}

        <PanelBarItem expanded={true} title="ผลประโยชน์อื่นๆ เพิ่มเติม">
          <ContentContainer>
            <Title>{props.riderPolicyDetail.protectionDetail.other.label}</Title>
            <Detail>
              {props.riderPolicyDetail.protectionDetail.other.value === null
                ? "-"
                : props.riderPolicyDetail.protectionDetail.other.value}
            </Detail>
          </ContentContainer>
        </PanelBarItem>
      </PanelBar>
    )}
  </Layout>
)

const enhancer = compose(
  withProps((props) => {
    let deadByAccidents = []
    let lossOfOrgans = []
    let disabledDatas = []
    let treatments = []
    let compensationFromPublicAccidents = []

    props.riderPolicyDetail.protectionDetail.aiAddAdb.map((item) => (
      <React.Fragment>
        {item.deadByAccident
          ? deadByAccidents.push({ deadByAccidents: item.deadByAccident })
          : null}

        {item.lossOfOrgans ? (lossOfOrgans = item.lossOfOrgans) : null}

        {item.disabled ? (disabledDatas = item.disabled) : null}

        {item.treatment ? (treatments = item.treatment) : null}

        {item.compensationFromPublicAccidents
          ? (treatments = [
              ...treatments,
              { compensationFromPublicAccidents: item.compensationFromPublicAccidents },
            ])
          : null}
      </React.Fragment>
    ))

    return {
      deadByAccidents,
      lossOfOrgans,
      disabledDatas,
      treatments,
    }
  })
)
export default enhancer(Ai)
