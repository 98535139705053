import React from "react"
import Spinner from "react-spinkit"
import styled from "@styled"

const Layout = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #eeeef2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Title = styled.div`
  margin-bottom: 8px;
`

const Loading = (props) => (
  <Layout {...props}>
    <Title>Loading</Title>
    <Spinner name="ball-beat" />
  </Layout>
)

export default Loading
