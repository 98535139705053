import React from "react"
import styled, { css } from "@styled"
import { compose, withHandlers } from "recompose"
import { Button } from "@components/index"

const Layout = styled.div`
  overflow: auto;

  > button {
    border: 1px solid #eeeef2;
    color: #666666;
  }

  > div {
    display: none;
  }

  ${(props) =>
    props.opened &&
    css`
      position: relative;
      width: 100%;
      background: #ffffff;
      border: 1px solid #eeeef2;
      border-radius: 4px;
      margin-bottom: 20px;

      > button {
        position: absolute;
        top: 0;
        left: 0;
        border: none;
        background: transparent;
      }

      > div {
        display: block;
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
    `}
`

const Body = styled.div`
  margin: 48px 16px 10px 16px;
`

const FilterButton = (props) => (
  <Layout className={props.className} opened={props.expanded} disabled={props.disabled}>
    <Button icon="filter" onClick={props.onExpand} styletype="secondary">
      {props.buttonText || "ปรับตัวเลือก"}
    </Button>
    <Body>{props.children}</Body>
  </Layout>
)

const enhancer = compose(
  withHandlers({
    onExpand: (props) => () => {
      props.onExpand && props.onExpand(!props.expanded)
    },
  })
)

export default enhancer(FilterButton)
