import BaseStore from "./BaseStore"
import { tqldServer } from "@common/api"

class LeadStore extends BaseStore {
  constructor() {
    super({
      all: [],
      paging: {
        page: 1,
        perPage: 10,
        totalCount: 0,
        totalPages: 1,
      },
      filter: {},
      current: null,
      configs: {
        filter: {},
      },
      isEdit: false,
      footer: {
        unassignableLeadsCount: 0,
        completedLeadsCount: 0,
        leadsCount: 0,
        assignedLeadsCount: 0,
        teamAssignedLeadsCount: 0,
      },
      countInfo: {
        unassignableLeadsCount: 0,
        completedLeadsCount: 0,
        leadsCount: 0,
        assignedLeadsCount: 0,
        teamAssignedLeadsCount: 0,
      },
    })
  }

  changeEditState = () => {
    this.isEdit = !this.isEdit
  }

  createLead = async (params) => {
    const response = await tqldServer.tqmBackend.post(`/leads`, params)

    return response.data.data
  }

  fetchConfigs = async (params) => {
    const response = await tqldServer.tqmBackend.get(`/leads/configs`, params)
    this.configs = response.data.data.configs
  }

  fetch = async (options = {}) => {
    const response = await tqldServer.tqmBackend.get(`/leads`, options)

    return {
      leads: response.data.data.leads,
      paging: response.data.data.paging,
      footer: {
        leadsCount: response.data.data.leadsCount,
        assignedLeadsCount: response.data.data.assignedLeadsCount,
        unassignableLeadsCount: response.data.data.unassignableLeadsCount,
        teamAssignedLeadsCount: response.data.data.teamAssignedLeadsCount,
        completedLeadsCount: response.data.data.completedLeadsCount,
      },
    }
  }

  fetchCountInfo = async (options = {}) => {
    const response = await tqldServer.tqmBackend.get(`/leads/lead_count`, options)

    return {
      completedLeadsCount: response.data.data.completedLeadsCount,
      leadsCount: response.data.data.leadsCount,
      assignedLeadsCount: response.data.data.assignedLeadsCount,
      unassignableLeadsCount: response.data.data.unassignableLeadsCount,
      teamAssignedLeadsCount: response.data.data.teamAssignedLeadsCount,
    }
  }

  fetchById = async (id) => {
    const response = await tqldServer.tqmBackend.get(`/leads/${id}`)
    this.current = response.data.data.lead
    return this.current
  }

  import = async (file) => {
    await tqldServer.tqmBackend.post(`/leads/import`, {
      leadFile: file,
    })
  }

  assign = async (leadIds, salesmanIds, teamIds, options) => {
    await tqldServer.tqmBackend.put(`/leads/assign`, {
      leadIds,
      salesmanIds,
      teamIds,
      ...options,
    })
  }

  unassign = async (leadIds) => {
    await tqldServer.tqmBackend.put(`/leads/unassign`, {
      leadIds,
    })
  }

  unassignToTeam = async (leadIds) => {
    await tqldServer.tqmBackend.put(`/leads/unassign_to_team`, {
      leadIds,
    })
  }

  update = async (id, values) => {
    const response = await tqldServer.tqmBackend.put(`/leads/${id}`, values)
    this.current = response.data.data.lead
  }

  reject = async (id, params) => {
    await tqldServer.tqmBackend.put(`/leads/${id}/reject`, params)
  }

  updateCustomerInfo = async (id, values) => {
    const response = await tqldServer.tqmBackend.put(`/leads/${id}/customer_info`, values)

    this.current = {
      ...this.current,
      customerInfo: response.data.data.customer,
    }
  }

  deleteAll = async (params) => {
    await tqldServer.tqmBackend.delete(`/leads`, params)
  }

  updateAppointment = async (id, params) => {
    await tqldServer.tqmBackend.patch(`/leads/${id}/appointment`, params)
  }

  fetchTeamConfigs = async () => {
    const response = await tqldServer.tqmBackend.get(`/leads/teams`)

    return response.data.data.teams
  }
}

export default new LeadStore()
