import React, { useCallback, useEffect, useState } from "react"
import { Button, Input, Field, BreadCrumb, CurrencyInput, Dropdown } from "@components"
import styled from "@emotion/styled"
import { compose, lifecycle, withProps } from "recompose"
import { withForm, withHooks, withRouter, withStores } from "@enhancers/index"
import * as path from "@common/path"
import { FieldArray } from "redux-form"
import { convertNaNtoZero } from "@common/helper"
import { isEmpty } from "lodash"
import CommissionStructureField from "./CommissionStructureField"
import DeleteModal from "./DeleteModal"

const Layout = styled.div``
const ButtonContainer = styled.div`
  display: ${(props) => (props.show ? "flex" : "none")};
  justify-content: ${(props) => (props.show ? "flex-end" : "none")};
  margin-bottom: 16px;
`
const ActionButton = styled(Button)`
  display: flex;
  margin-left: 10px;
  width: 90px;
`
export const TopicContainer = styled.div`
  width: 100%;
  height: 56px;
  background: #294558;
  opacity: 1;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`
export const TextTopic = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  font-size: 16px;
  padding: 18px 24px;
  color: #ffffff;
  font-family: Sarabun, sans-serif;
`
const Body = styled.div`
  border-radius: 4px;
  padding: 16px 32px 32px 32px;
  background: #ffffff;
  border: 1px solid #eeeef2;
`
const StructureContainer = styled.div`
  background: #eeeef2;
  padding: 24px 31px 32px 31px;
`
const BreadCrumbLayout = styled.div`
  margin: 0px;
`
const CreateMkPlanButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`
const CreateMkPlanButton = styled(Button)`
  display: flex;
  margin-top: 24px;
  width: 160px;
`

export const MarketPlanShowPage = (props) => {
  return (
    <Layout>
      <BreadCrumbLayout>
        <BreadCrumb links={props.breadCrumbLinks} />
      </BreadCrumbLayout>
      <ButtonContainer
        show={
          !props.isEdit &&
          props.currentRole !== "member.accountant" &&
          props.currentRole !== "member.accountant_approver"
        }
      >
        <ActionButton
          icon="delete"
          onClick={props.toggleDeleteModal}
          readOnly={props.readOnly}
          children="ลบ"
          styletype="delete"
        />
        <ActionButton icon="edit" onClick={props.onEdit} children="แก้ไข" />
      </ButtonContainer>

      <ButtonContainer
        show={
          props.isEdit &&
          props.currentRole !== "member.accountant" &&
          props.currentRole !== "member.accountant_approver"
        }
      >
        <ActionButton icon="cancel" onClick={props.onCancel} children="ยกเลิก" styletype="delete" />
        <ActionButton
          icon="check"
          onClick={props.handleSubmit(props.onSubmit)}
          children="ยืนยัน"
          styletype="agree"
        />
      </ButtonContainer>

      <TopicContainer>
        <TextTopic>แผนการตลาด</TextTopic>
      </TopicContainer>

      <Body>
        <Field
          name="name"
          component={Input}
          caption="ชื่อแผน"
          placeholder="โปรดระบุ"
          readOnly={!props.isEdit}
          validate="required"
          leftWidth="192"
          rightWidth="256"
          style={{ marginBottom: "16px" }}
        />
        <Field
          name="calculateType"
          component={Dropdown}
          caption="วิธีคำนวณ"
          options={props.calculateTypeOptions}
          placeholder="โปรดเลือก"
          readOnly={!props.isEdit}
          validate="required"
          leftWidth="192"
          rightWidth="256"
          style={{ marginBottom: "16px" }}
        />
        <Field
          name="calculatePercent"
          component={CurrencyInput}
          caption="เปอร์เซ็น"
          placeholder="โปรดระบุ"
          readOnly={!props.isEdit}
          validate="required"
          leftWidth="192"
          rightWidth="256"
          style={{ marginBottom: "16px" }}
          min={0}
          max={100}
        />
        <Field
          name="numberOfLevel"
          component={CurrencyInput}
          caption="จำนวนระดับ"
          placeholder="โปรดระบุ"
          readOnly={!props.isEdit || props.numberOfLevelReadOnly}
          validate="required"
          leftWidth="192"
          rightWidth="256"
          min={0}
        />
        <CreateMkPlanButtonContainer>
          <CreateMkPlanButton
            onClick={props.createMkPlanStructure}
            children="สร้างตาราง"
            hidden={props.from === "show"}
          />
        </CreateMkPlanButtonContainer>
      </Body>

      {props.isShowMkPlan && (
        <StructureContainer>
          <FieldArray
            name="mkCommissionStructures"
            component={CommissionStructureField}
            commissionStructure={props.commissionStructure}
            readOnly={!props.isEdit}
            from={props.from}
            reserveForLevelOptions={props.reserveForLevelOptions}
            rerenderOnEveryChange
          />
        </StructureContainer>
      )}

      <DeleteModal
        open={props.showDeleteModal}
        onClose={props.toggleDeleteModal}
        onDelete={props.onDelete}
      />
    </Layout>
  )
}

export const validate = (values) => {
  const errors = {}
  const mkCommissionStructuresError = []
  if (values.mkCommissionStructures) {
    values.mkCommissionStructures.forEach((structure, index) => {
      const mkCommissionStructureError = {}
      const mkCommissionRatesError = []
      // let countPercent = 0
      if (structure.beginDate === undefined || structure.beginDate === null) {
        mkCommissionStructureError.beginDate = "require"
      }

      // structure.mkCommissionRates.forEach((item) => {
      //   countPercent += convertNaNtoZero(item.percent)
      // })

      structure.mkCommissionRates.forEach((item, itemIndex) => {
        const mkCommissionRateObjectError = {}

        // if (countPercent > 100 && item.percent !== 0) {
        //   mkCommissionRateObjectError.percent = "maximum"
        // }

        if (!item.positionName) {
          mkCommissionRateObjectError.positionName = "require"
        }

        if (
          item.commissionPercent === undefined ||
          item.commissionPercent === null ||
          item.commissionPercent === ""
        ) {
          mkCommissionRateObjectError.commissionPercent = "require"
        }

        if (
          item.managementPercent === undefined ||
          item.managementPercent === null ||
          item.managementPercent === ""
        ) {
          mkCommissionRateObjectError.managementPercent = "require"
        }

        if (!isEmpty(mkCommissionRateObjectError)) {
          mkCommissionRatesError[itemIndex] = mkCommissionRateObjectError
        }
      })

      if (!isEmpty(mkCommissionRatesError)) {
        mkCommissionStructureError.mkCommissionRates = mkCommissionRatesError
      }

      if (!isEmpty(mkCommissionStructureError)) {
        mkCommissionStructuresError[index] = mkCommissionStructureError
      }
    })
  }

  if (!isEmpty(mkCommissionStructuresError)) {
    errors.mkCommissionStructures = mkCommissionStructuresError
  }
  return errors
}

const enhancer = compose(
  withRouter(),
  withStores((stores) => ({
    fetchMarketPlans: stores.marketPlanStore.fetchById,
    fetchMarketPlansConfigs: stores.marketPlanStore.fetchConfigs,
    marketPlan: stores.marketPlanStore.current,
    update: stores.marketPlanStore.update,
    delete: stores.marketPlanStore.delete,
    currentRole: stores.appStore.currentRole,
    calculateTypeOptions: stores.marketPlanStore.$("configs.filters.calculateType.options", []),
  })),
  withProps((props) => ({
    marketPlanid: props.match.params.id,
    initialValues: {
      ...props.marketPlan,
    },
    breadCrumbLinks: [
      { label: "จัดการแผนการตลาด", path: path.marketPlansPath() },
      {
        label: "รายละเอียดแผนการตลาด",
        path: path.marketPlanPath(props.marketPlanid),
      },
    ],
  })),
  withForm({ form: "editMarketPlanForm", validate }),
  lifecycle({
    async componentDidMount() {
      await Promise.all([
        this.props.fetchMarketPlans(this.props.marketPlanid),
        this.props.fetchMarketPlansConfigs(),
      ])
    },
  }),
  withHooks((props) => {
    const [isEdit, setIsEdit] = useState(false)
    const [from, setFrom] = useState("show")
    const [numberOfLevelReadOnly, setConditionTypeReadOnly] = useState(true)
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    const onCancel = useCallback(() => {
      props.reset(props.form)
      setIsEdit(false)
    }, [])

    const onEdit = useCallback(() => {
      setIsEdit(!isEdit)
    }, [isEdit])
    const onSubmit = useCallback(async (values) => {
      try {
        await props.update(props.marketPlanid, values)
        setIsEdit(false)
      } catch (e) {
        if (e.message === "Submit Validation Failed" && e.errors.commissionPercent) {
          alert(e.errors.commissionPercent)
        } else if (e.message === "Submit Validation Failed" && e.errors.beginDate) {
          alert(e.errors.beginDate)
        } else {
          alert(
            "แก้ไขแผนการตลาดไม่สำเร็จ เนื่องจากเกิดข้อผิดผลาดบางอย่าง กรุณาลองใหม่อีกครั้งในภายหลัง"
          )
        }
      }
    }, [])

    const toggleDeleteModal = useCallback(() => {
      setShowDeleteModal(!showDeleteModal)
    }, [showDeleteModal])

    const onDelete = useCallback(async () => {
      try {
        await props.delete(props.marketPlanid)
        props.history.push(path.marketPlansPath())
      } catch (e) {
        if (e.message === "Submit Validation Failed") {
          alert(e.errors.base)
        } else {
          alert(
            "ลบแผนการตลาดไม่สำเร็จ เนื่องจากเกิดข้อผิดผลาดบางอย่าง กรุณาลองใหม่อีกครั้งในภายหลัง"
          )
        }
      }
    }, [])

    const [commissionStructure, setCommissionStructure] = useState([])
    const [reserveForLevelOptions, setReserveForLevelOptions] = useState([])

    useEffect(() => {
      const mkPlanStructure = []
      const tempReserveForLevelOptions = [{ label: "-", value: "" }]

      for (var index = 1; index <= props.values.numberOfLevel; index++) {
        mkPlanStructure.push({
          positionLevel: index,
          positionName: "",
          commissionPercent: 0,
          managementPercent: 0,
          reserveForLevel: "",
        })

        tempReserveForLevelOptions.push({ label: index, value: index })
      }
      setCommissionStructure(mkPlanStructure)
      setReserveForLevelOptions(tempReserveForLevelOptions)
    }, [props.values.numberOfLevel, props.values.mkCommissionStructures])

    let isShowMkPlan = false

    if (props.values.mkCommissionStructures) {
      isShowMkPlan = true
    } else if (commissionStructure.length !== 0) {
      isShowMkPlan = true
    }

    return {
      isEdit,
      from,
      showDeleteModal,
      numberOfLevelReadOnly,
      commissionStructure,
      reserveForLevelOptions,
      isShowMkPlan,

      onDelete,
      toggleDeleteModal,
      onCancel,
      onEdit,
      onSubmit,
    }
  })
)

export default enhancer(MarketPlanShowPage)
