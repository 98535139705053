import React from "react"
import styled from "@emotion/styled"
import { compose, withState, withHandlers } from "recompose"
import { Button, Input, Avatar, Field, CurrencyInput } from "@components"
import { withStores } from "@enhancers/index"

import DefaultDetailModal from "./DefaultDetailModal/index"
import defaultLogo from "@assets/images/img-no-insurance.png"

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 678px;
  height: auto;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  margin-bottom: 20px;
  background: #fff;
  border: 1px solid #eeeef2;
  border-radius: 4px;
  padding: 15px 15px 15px 25px;
`

const DetailButton = styled(Button)`
  display: ${(props) => (props.readOnly ? "none" : "flex")};
  background: #ffffff;
  border-radius: 4px;
  width: 139px;
  height: 38px;
  margin-top: 5px;
  align-self: flex-end;
`
const CompanyField = styled(Field)`
  height: 30px;
  font-size: 15px;
  font-weight: bold;
  > div:first-of-type {
    min-width: 106px;
    text-align: left;
    color: #666666;
  }
  > div:last-of-type {
    min-width: 50px;
    margin-left: 24px;
    > div {
      padding-top: 3.5px !important;
      justify-content: flex-end;
    }
  }
`
const DetailField = styled(Field)`
  font-size: 15px;
  .company {
    font-weight: bold;
  }
  > div:first-of-type {
    min-width: 106px;
    text-align: left;
    color: #666666;
  }
  > div:last-of-type {
    min-width: 50px;
    margin-left: 24px;
    > div {
      justify-content: flex-end;
    }
  }
`

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .k-mid {
    display: flex;
    align-items: center;
    > .k-checkbox-wrapper {
      display: flex;
    }
    > span {
      font-size: 12px !important ;
      color: ${(props) => (props.noSelected ? "#0275d8" : "#ffffff")};
    }
  }
`
const ProductNameContainer = styled.div`
  display: flex;
`
const BodyContainer = styled.div`
  display: flex;
  height: auto;
  justify-content: flex-end;
  margin-top: 10px;
`
const SumInsuredLayout = styled.div`
  display: flex;
  flex: 1;
`

const PremiumLayout = styled.div`
  display: flex;
  flex: 1;
  padding-left: 20px;
`
const UnitBaht = styled.div`
  margin-left: 4px;
  align-self: center;
`
const Unit = styled.div`
  min-width: 61px;
  margin-left: 4px;
  align-self: center;
`

const SumInsuredField = styled(Field)`
  > div:last-of-type {
    > div {
      justify-content: flex-end;
    }
  }
`
const CompanyAvatar = styled(Avatar)`
  display: flex;
  margin-right: 10px;
`

const additionalContract = (props) => (
  <React.Fragment>
    {props.fields.map((rider, index) => {
      return (
        <Layout key={index} isEdit={props.isEdit}>
          <HeaderContainer>
            <ProductNameContainer>
              <CompanyAvatar
                src={(props.productDetail && props.productDetail.companyLogoUrl) || defaultLogo}
                size="38"
              />
              <CompanyField name={`${rider}.name`} component={Input} readOnly={true} />
            </ProductNameContainer>

            <DetailButton
              awesomeIcon="search"
              onClick={() => props.onShowdetail(props.fields.get(index), index)}
              children="รายละเอียด"
              authorize={{
                notRoles: ["member.biz_dev"],
              }}
              disabled={
                props.fields.get(index).hasSumInsured && !props.fields.get(index).sumInsured
              }
            />
          </HeaderContainer>
          <BodyContainer>
            <SumInsuredLayout>
              <SumInsuredField
                name={`${rider}.sumInsured`}
                readOnly={true}
                component={CurrencyInput}
                caption="จำนวนเงินเอาประกันภัย"
              />
              <UnitBaht>บาท</UnitBaht>
            </SumInsuredLayout>

            <PremiumLayout>
              <DetailField
                name={`${rider}.premium`}
                component={Input}
                readOnly={true}
                caption="ค่าเบี้ยประกัน"
              />
              <Unit>บาท/งวด</Unit>
            </PremiumLayout>
          </BodyContainer>

          <DefaultDetailModal
            isShowModal={props.isShowModal}
            onClose={props.onCloseModal}
            riderPolicyDetail={props.riderPolicyDetail}
          />
        </Layout>
      )
    })}
  </React.Fragment>
)

const enhancer = compose(
  withState("selectedValues", "setSelectedValues", []),
  withState("isShowModal", "setIsShowModal", false),
  withState("riderPolicyDetail", "setRiderPolicyDetail", {}),

  withStores((stores) => ({
    getCiSearchDetail: stores.productStore.getCiSearchDetail,
    getHsSearchDetail: stores.productStore.getHsSearchDetail,
  })),
  withHandlers({
    onShowModal: (props) => () => {
      props.setIsShowModal(true)
    },
    onCloseModal: (props) => () => {
      props.setIsShowModal(false)
    },
    onShowdetail: (props) => async (values, index) => {
      await props.setRiderPolicyDetail(props.productDetail.orderProduct.riderProducts[index])
      props.setIsShowModal(true)
    },
    onBlurSumInsuredRider: (props) => (name, index) => async () => {
      const { id, sumInsured } = props.fields.get(index) || {}

      const response = await props.getCiSearchDetail({
        id: id,
        age: 27,
        gender: "male",
        sumInsured: sumInsured || 0,
      })

      props.change(`${name}.premium`, response.premium)
    },
  })
)

export default enhancer(additionalContract)
