import React from "react"
import styled from "@emotion/styled"
import { compose, withState, withHandlers, lifecycle, withProps } from "recompose"
import { withForm, withStores, withRouter } from "@enhancers"

import { Field, BreadCrumb, Button, Input, LoadingScope } from "@components"
import { Body, Header, RightPanel, ButtonGroup } from "@pages/leads/show/LeadInfo"

import PaymentMappingInput from "./PaymentMappingInput"

import { isEmpty, flatten } from "lodash"
import * as path from "@common/path"

const Layout = styled.div``
const CustomHeader = styled(Header)`
  justify-content: flex-end;
`
const BreadCrumbLayout = styled.div``
const CustomBody = styled(Body)`
  > div:first-of-type {
    margin-bottom: 10px;
  }
`
const SubmitButton = styled(Button)`
  width: 100px;
`
export const PaymentMappingsShow = (props) => (
  <React.Fragment>
    {props.loading ? (
      <LoadingScope />
    ) : (
      <Layout>
        <BreadCrumbLayout>
          <BreadCrumb
            links={[
              {
                label: "ตั้งค่าวิธีจ่ายเงิน",
                path: path.paymentMappingsPath(),
              },
              {
                label: "รายละเอียดการจ่ายเงิน",
                path: path.paymentMappingPath(props.match.params.id),
              },
            ]}
          />
        </BreadCrumbLayout>
        <CustomHeader>
          <RightPanel>
            <ButtonGroup>
              <SubmitButton icon="save" onClick={props.onSave} children="บันทึก" />
            </ButtonGroup>
          </RightPanel>
        </CustomHeader>
        <CustomBody>
          <Field component={Input} name="policyName" caption="แบบประกัน" readOnly />

          <Field
            component={PaymentMappingInput}
            name="payments"
            caption="โหมดการชำระเงิน"
            options={props.paymentPeriodOptions}
            readOnly
          />
        </CustomBody>
      </Layout>
    )}
  </React.Fragment>
)

const enhencer = compose(
  withRouter(),
  withState("loading", "setLoading", false),
  withStores((stores) => ({
    data: stores.paymentMappingStore.all,
    fetchData: stores.paymentMappingStore.fetch,
    fetchConfigs: stores.paymentMappingStore.fetchConfigs,
    create: stores.paymentMappingStore.create,
    delete: stores.paymentMappingStore.delete,
    paymentPeriodOptions: stores.paymentMappingStore.$(
      "configs.attributes.paymentPeriod.options",
      []
    ),
  })),
  withProps((props) => {
    const paymentDetail = props.data.find(
      (payment) => payment.policyId === parseInt(props.match.params.id)
    )
    return { paymentDetail }
  }),
  withProps((props) => {
    if (props.paymentDetail) {
      let paymentMappingOptions = []
      props.paymentPeriodOptions.forEach((paymentPeriod) => {
        props.paymentDetail.payments.forEach((paymentData) => {
          if (paymentPeriod.value === paymentData.paymentPeriod) {
            const newPaymentPeriod = []
            paymentPeriod.paymentMethods.forEach((paymentMethod) => {
              if (paymentMethod.value === paymentData.paymentMethod) {
                newPaymentPeriod.push({
                  value: paymentMethod.value,
                })
              }
            })

            const index = paymentMappingOptions.findIndex(
              (item) => item.value === paymentPeriod.value
            )
            if (index > -1) {
              paymentMappingOptions[index].paymentMethods = [
                ...paymentMappingOptions[index].paymentMethods,
                ...newPaymentPeriod,
              ]
            } else {
              paymentMappingOptions.push({
                value: paymentPeriod.value,
                paymentMethods: newPaymentPeriod,
              })
            }
          }
        })
      })

      return { paymentMappingOptions }
    }
  }),
  withProps((props) => {
    const paymentDetail = { ...props.paymentDetail }
    if (!isEmpty(paymentDetail)) {
      const paymentMappingIds = paymentDetail.payments.map((payment) => {
        return payment.id
      })
      return { paymentMappingIds }
    }
  }),
  withProps((props) => {
    return {
      initialValues: { ...props.paymentDetail, payments: props.paymentMappingOptions },
    }
  }),
  withForm({ form: "ShowPaymentMappingDetail" }),
  withHandlers({
    onSave: (props) =>
      props.handleSubmit(async (value) => {
        try {
          await Promise.all(props.paymentMappingIds.map((id) => props.delete(id)))
          await Promise.all(
            flatten(
              value.payments.map((paymentPeriod) =>
                paymentPeriod.paymentMethods.map((paymentMethod) =>
                  props.create({
                    insurancePolicyId: value.policyId,
                    paymentPeriod: paymentPeriod.value,
                    paymentMethod: paymentMethod.value,
                  })
                )
              )
            )
          )
          props.history.push(path.paymentMappingsPath())
        } catch (error) {
          window.alert("บันทึกไม่สำเร็จ")
        }
      }),
  }),
  lifecycle({
    async componentDidMount() {
      this.props.setLoading(true)
      await Promise.all([
        this.props.fetchData(),
        this.props.fetchConfigs({ insurancePolicyId: this.props.match.params.id }),
      ])
      this.props.setLoading(false)
    },
  })
)

export default enhencer(PaymentMappingsShow)
