import React from "react"
import styled from "@emotion/styled"
import { compose, lifecycle, withProps, withState } from "recompose"
import { withStores, withForm, withRouter } from "@enhancers"
import * as path from "@common/path"
import { ProductShowDetail } from "../../custom/show/ShowDetail"

const Note = styled.div`
  font-size: 16px;
  max-width: 666px;
  margin: 30px 20px;
`

const enhancer = compose(
  withRouter({
    queryTypes: {
      birthdate: "date",
      gender: "string",
      sumInsured: "integer",
      type: "string",
      searchBy: "string",
      leadId: "integer",
      from: "string",
      occupationCategory: "string",
      occupation: "string",
      maxAgeInsured: "integer",
      searchValue: "integer",
      enablePeriod: "boolean",
      opportunityKey: "string",
    },
  }),
  withState("loading", "setLoading", false),
  withStores((stores) => {
    return {
      getDetail: stores.packageStore.fetchPackagePolicyById,
      insuranceCoverageTable: stores.packageStore.portDetailTable,
      productDetail: stores.packageStore.portDetail,
      fetchOrder: stores.orderStore.fetchOrder,
    }
  }),
  withForm({
    form: "showDetailProduct",
  }),
  withProps((props) => {
    const searchData = props.location.query
    const { packageId } = props.match.params

    const note = (
      <Note>
        *เบี้ยประกันที่แสดง เป็นเบี้ยประกันมาตรฐาน
        สำหรับผู้ที่มีอาชีพและประวัติด้านสุขภาพในเกณฑ์ความเสี่ยงปกติ
        ซึ่งอาจไม่ตรงกับเบี้ยประกันที่คุณต้องจ่ายจริง
        ซึ่งต้องพิจารณาความเสี่ยงของคุณอย่างละเอียดจากตัวแทนและบริษัทประกันชีวิตอีกครั้งหนึ่ง
        <br />
        <br />
        คำอธิบายเกี่ยวกับแบบกรมธรรม์ประกันชีวิตที่เสนอนั้น ไม่ใช่คำแนะนำให้ลงทุนหรือเอาประกันภัยตาม
        เนื่องจากการจัดแบบกรมธรรม์ประกันชีวิตให้เหมาะสมกับผู้เอาประกันภัยแต่ละรายนั้น
        นอกจากจะขึ้นอยู่กับเงื่อนไขรายบุคคลเกี่ยวกับสถานภาพทางการเงิน
        ความมั่นคงของรายได้แล้วยังขึ้นอยู่กับเงื่อนไขอื่นๆ อีก เช่น สุขภาพของผู้เอาประกันภัย
        ข้อมูลที่ต้องมีการรับรองเพื่อให้ผู้รับประกันภัยประกอบการตัดสินใจรับประกันภัย
        การคาดการณ์อัตราผลตอบแทนและความเสี่ยงต่างๆ
        <br />
        <br />
        คำอธิบายเกี่ยวกับแบบกรมธรรม์ประกันชีวิตที่เสนอนั้น ผู้จัดทำได้รับข้อมูลจากบุคคลที่สาม
        ผู้จัดทำจึงไม่สามารถรับประกันความสมบูรณ์ และถูกต้องของข้อมูล ซึ่งอาจจะมีการเปลี่ยนแปลงได้
        และทางผู้จัดทำจะไม่รับผิดชอบต่อความเสียหายใดๆ อันเกิดขึ้นจากการใช้งานของโปรแกรมนี้
        ไม่ว่าโดยทางตรงหรือทางอ้อม
        ดังนั้นการตัดสินใจเอาประกันภัยจึงขึ้นกับวิจารณญาณของผู้เอาประกันภัยเป็นสำคัญ
        <br />
        <br />
        กรุณาติดต่อสอบถามตัวแทนประกันชีวิต นายหน้าประกันชีวิต หรือบริษัทประกันชีวิต
        เพื่อตรวจสอบความถูกต้องสมบูรณ์ของข้อมูลแบบกรมธรรม์ประกันชีวิตก่อนการเอาประกันภัย
        และโปรดศึกษาข้อมูล นโยบาย ความเสี่ยง ปัจจัยที่เกี่ยวข้อง ก่อนการเอาประกันภัย
      </Note>
    )

    return {
      params: {
        ...searchData,
        packageId,
      },
      productDetail: {
        ...props.productDetail,
        insuranceCoverageTable: props.insuranceCoverageTable && props.insuranceCoverageTable,
      },
      note,
    }
  }),
  lifecycle({
    async componentWillMount() {
      this.props.setLoading(true)

      const response = await this.props.getDetail({
        packageId: this.props.params.packageId,
        birthdate: this.props.params.birthdate,
        gender: this.props.params.gender,
      })

      this.props.initialize(response)
      this.props.setLoading(false)
    },
  }),
  withProps((props) => {
    const { params } = props
    let links = [
      {
        label: "ผลิตภัณฑ์",
        path: "/",
      },
      {
        label: "ค้นหาแบบประกัน",
        path: path.productsSearchPath({
          gender: params.gender,
          type: params.type,
          birthdate: params.birthdate,
          searchValue: params.searchValue,
          searchBy: params.searchBy,
          opportunityKey: params.opportunityKey,
        }),
      },
      {
        label: "เลือกแบบประกัน",
        path: path.productsPath({
          gender: params.gender,
          type: params.type,
          birthdate: params.birthdate,
          from: params.from,
          searchValue: params.searchValue,
          searchBy: params.searchBy,
          opportunityKey: params.opportunityKey,
        }),
      },
      {
        label: "รายละเอียด",
      },
    ]

    return { links }
  })
)

export default enhancer(ProductShowDetail)
