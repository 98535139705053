import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { compose, withProps } from "recompose"
import { Dialog, Button } from "@components"
import { withStores } from "@enhancers/index"

import Ai from "./Ai"
import Ci from "./Ci"
import Cr from "./Cr"
import Hb from "./Hb"
import Hs from "./Hs"
import NoProduct from "./NoProduct"
import Wp from "./Wp"
import Pb from "./Pb"

export const CustomDialog = styled(Dialog)`
  .k-widget.k-panelbar {
    border-left: 0px;
    border-right: 0px;
  }

  div.k-widget.k-window.k-dialog {
    margin: 16px 0px;
    width: 570px;
    max-height: 500px;

    font-family: Sarabun, sans-serif;
    font-size: 16px;

    @media only screen and (max-height: 850px) {
      margin: 20px 0px;
      height: 94%;
    }

    .k-window-titlebar {
      > div {
        color: white;
      }
      background-color: #294558;
    }

    div.k-content.k-window-content.k-dialog-content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .css-1an6peg--ShowHsDetailModal--Layout {
      margin-top: unset;
    }
  }

  .k-panelbar > .k-item > .k-link {
    background-color: #627b8b;
    border-color: #627b8b;
    color: #fff;
  }

  .k-panelbar > .k-item > .k-link.k-state-focused.k-state-selected {
    box-shadow: none;
  }
  .k-header {
    font-family: Sarabun, sans-serif;
    font-size: 16px;
  }
  display: ${(props) => (props.isShowModal ? "flex" : "none")};

  ${(props) =>
    props.hidden &&
    css`
      display: none;
    `}
`

const CloseButton = styled(Button)`
  background: #ffffff 0% 0% no-repeat padding-box;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  width: 100%;
  border: white;
  min-height: 52px;
  box-shadow: unset;
  text-decoration: none;

  :hover {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-color: #0275d8;
  }

  span.k-icon.k-i-cart {
    font-size: 18px;
  }
`

export const DefaultDetailModal = (props) => (
  <CustomDialog
    title={"สัญญาเพิ่มเติม"}
    onClose={props.onClose}
    isShowModal={props.isShowModal}
    key={props.riderPolicyDetail.id}
  >
    <props.BodyComponent riderPolicyDetail={props.riderPolicyDetail} {...props} />
    <CloseButton onClick={props.onClose}>ปิด</CloseButton>
  </CustomDialog>
)

const enhancer = compose(
  withStores((stores) => ({
    riderPolicyDetail: stores.productStore.riderPolicyDetail,
  })),
  withProps((props) => {
    return {
      BodyComponent:
        {
          ai: Ai,
          ci: Ci,
          cr: Cr,
          hb: Hb,
          hs: Hs,
          wp: Wp,
          pb: Pb,
        }[props.riderPolicyDetail.subType] || NoProduct,
    }
  })
)

export default enhancer(DefaultDetailModal)
