import React from "react"
import styled from "@emotion/styled"
import { compose, withHandlers } from "recompose"
import { DialogActionsBar, Dialog, Button } from "@components"

const Layout = styled.div`
  padding: 35px 15px;
`
const SuccessLayout = styled.div`
  text-align: center;
  font-size: 16px;
  font-family: Sarabun, sans-serif;
  > span {
    color: #5cb85c;
    font-weight: bold;
  }
`
const CustomDialog = styled(Dialog)`
  .k-window {
    border: 1px solid #e6e6e6;
    min-width: 348px;
  }
`
const CancelButton = styled(Button)`
  min-height: 52px;
`
const SubmitButton = styled(Button)`
  min-height: 52px;
  border-color: rgba(33, 37, 41, 0.125);
`

const ApproveOrderModal = (props) => (
  <CustomDialog
    title={props.modalTitle || "แจ้งงาน"}
    onClose={props.onClose}
    isShowModal={props.open}
  >
    <Layout>
      {props.validateFailModalMessage.title || (
        <SuccessLayout>
          {props.validateFailModalMessage.affiliate ? (
            <span>แจ้งงานสำเร็จ</span>
          ) : (
            <>
              <span>{props.validateFailModalMessage.info || "แจ้งงานสำเร็จ"}</span> <br />
              {props.validateFailModalMessage.message || "กรุณาแนบเอกสารเพิ่ม"}
            </>
          )}
        </SuccessLayout>
      )}
    </Layout>
    {props.validateFailModalMessage.title || props.validateFailModalMessage.info ? (
      <DialogActionsBar>
        <CancelButton
          type="button"
          onClick={props.onCloseModal}
          children={props.buttonTitle || "ตกลง"}
          styletype="delete"
        />
      </DialogActionsBar>
    ) : props.validateFailModalMessage.affiliate ? (
      <DialogActionsBar>
        <CancelButton
          type="button"
          onClick={props.onClose}
          children={props.buttonTitle || "ไปหน้าคำสั่งซื้อ"}
          styletype="delete"
        />
      </DialogActionsBar>
    ) : (
      <DialogActionsBar>
        <CancelButton
          type="button"
          onClick={props.onClose}
          children="ไปหน้าคำสั่งซื้อ"
          styletype="delete"
        />
        <SubmitButton type="button" onClick={props.onAttachDoc} children="แนบเอกสาร" />
      </DialogActionsBar>
    )}
  </CustomDialog>
)

const enhancer = compose(
  withHandlers({
    onAttachDoc: (props) => async () => {
      props.onAttachDoc && props.onAttachDoc()
    },
    onClose: (props) => () => {
      props.onClose && props.onClose()
    },
    onCloseModal: (props) => () => {
      props.onCloseModal && props.onCloseModal()
    },
  })
)
export default enhancer(ApproveOrderModal)
