import React from "react"
import styled from "@emotion/styled"
import { compose, withHandlers, lifecycle, withState, withProps } from "recompose"
import { withStores, withForm, withRouter } from "@enhancers"
import {
  Grid,
  GridColumn,
  makeCell,
  Input,
  Field,
  DatePicker,
  Dropdown,
  CurrencyInput,
} from "@components"
import { toCurrency } from "@common/helper"
import { cloneDeep, isEqual } from "lodash"
import { css } from "@styled"

const Body = styled.div`
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #eeeef2;
`
const TextTopic = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  font-size: 16px;
  color: #ffffff;
  font-family: Sarabun, sans-serif;
  font-weight: bold;
`
const TopicContainer = styled.div`
  width: 100%;
  height: 40px;
  background: #294558;
  opacity: 1;
  display: flex;
  justify-content: space-between;

  > div:first-of-type {
    padding-left: 16px;
  }

  > div:last-of-type {
    padding-right: 16px;
  }
`
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 50px;
  padding: 30px 40px;

  > div {
    > div {
      min-width: 314px;
      > div:last-of-type {
        > div {
          padding-top: 0 !important;
        }
      }
    }
  }
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`
const Table = styled(Grid)`
  .k-grid-header .k-header {
    font-weight: bold;
  }
  ${(props) => css`
    .k-grid-header .k-header {
      :nth-of-type(9) {
        text-align: end;
      }
      :nth-of-type(10) {
        text-align: end;
      }
      :nth-of-type(19) {
        text-align: end;
      }
    }

    .k-master-row > td {
      :nth-of-type(9) {
        text-align: end;
      }
      :nth-of-type(10) {
        text-align: end;
      }
      :nth-of-type(19) {
        text-align: end;
      }
    }
  `}
`
const CheckboxTd = styled.td`
  position: sticky;
  left: 0;
  border-right-width: 1px;
  background-color: #ffffff;
`
const StatusTd = styled(CheckboxTd)`
  right: 0;
`
const CustomCurrencyInput = styled(CurrencyInput)`
  span {
    color: #666666;
  }
`

const SaleCommissions = (props) => {
  return (
    <Body>
      <TopicContainer>
        <TextTopic>ข้อมูลทีมผู้แนะนำ</TextTopic>
      </TopicContainer>

      <ContentContainer>
        <Row>
          <Field
            name="affiliateTeamName"
            component={Input}
            caption="ชื่อทีม"
            leftWidth="200"
            readOnly
          />
          <Field
            name="areaCode"
            component={Input}
            caption="พื้นที่สังกัด"
            leftWidth="200"
            readOnly
          />
        </Row>
        <Row>
          <Field
            name="branchCode"
            component={Input}
            caption="สาขาสังกัด"
            leftWidth="200"
            readOnly
          />
          <Field
            name="teamName"
            component={Input}
            caption="ทีมหลักที่ดูแล"
            leftWidth="200"
            readOnly
          />
        </Row>
        <Row>
          <Field
            name="leaderStaffCode"
            component={Input}
            caption="รหัสหัวหน้าทีมผู้แนะนำ"
            leftWidth="200"
            readOnly
          />
          <Field
            name="leaderName"
            component={Input}
            caption="ชื่อหัวหน้าทีมผู้แนะนำ"
            leftWidth="200"
            readOnly
          />
        </Row>
        <Row>
          <Field
            name="memberCount"
            component={CustomCurrencyInput}
            caption="จำนวนสมาชิก"
            unit="คน"
            leftWidth="200"
            readOnly
          />
        </Row>
      </ContentContainer>

      <TopicContainer>
        <TextTopic>ข้อมูลคอมมิชชั่น</TextTopic>
      </TopicContainer>

      <ContentContainer>
        <Table
          loadingTable={props.loadingTable}
          data={props.salesCommissionDetails}
          pageable
          skip={props.paging.skip}
          take={props.paging.perPage}
          total={props.paging.totalCount}
          onPageChange={props.onPageChange}
        >
          <GridColumn title="รหัสโอกาสในการขาย" field="agtOpportunityKey" width="170px" />
          <GridColumn title="รหัสคำสั่งซื้อ" field="tqmOrderId" width="110px" />
          <GridColumn title="ชื่อลูกค้า" field="cutomerName" width="190px" />
          <GridColumn title="แบบประกัน" field="policyName" width="120px" />
          <GridColumn title="บริษัทประกัน" field="insuranceCompanyName" width="190px" />
          <GridColumn
            title="วันที่แจ้งแนะนำ"
            width="140px"
            cell={makeCell(DatePicker, (props) => ({
              readOnly: true,
              value: props.dataItem.affiliateSubmittedAt,
            }))}
          />
          <GridColumn
            title="วันที่แจ้งงาน"
            width="130px"
            cell={makeCell(DatePicker, (props) => ({
              readOnly: true,
              value: props.dataItem.submittedAt,
            }))}
          />
          <GridColumn
            title="รายได้"
            width="110px"
            cell={(cell) => <td>{toCurrency(cell.dataItem.totalInsurerCommission)}</td>}
          />
          <GridColumn
            title="เบี้ยสุทธิ"
            width="110px"
            cell={(cell) => <td>{toCurrency(cell.dataItem.totalPremium)}</td>}
          />
          <GridColumn
            title="สัดส่วนการจ่าย"
            width="120px"
            cell={(cell) => <td>{toCurrency(cell.dataItem.commissionPercent)}</td>}
          />
          <GridColumn title="ค่าแนะนำ" field="commissionAmount" width="110px" />
          <GridColumn
            title="วันที่รับเงิน"
            cell={makeCell(DatePicker, (props) => ({
              readOnly: true,
              value: props.dataItem.receivedDate,
            }))}
            width="120px"
          />
          <GridColumn
            title="วันที่จ่าย"
            cell={makeCell(DatePicker, (cell) => ({
              readOnly: true,
              value: cell.dataItem.commission_receivedDate,
            }))}
            width="120px"
          />
          <GridColumn title="รหัสผู้แนะนำ" field="affiliateStaffCode" width="120px" />
          <GridColumn title="ชื่อผู้แนะนำ" field="affiliateName" width="190px" />
          <GridColumn title="รหัสพนักงาน" field="salesmanStaffCode" width="120px" />
          <GridColumn title="ชื่อพนักงานขาย" field="salesmanName" width="190px" />
          <GridColumn title="ทีมขาย" field="teamName" width="80px" />
          <GridColumn title="งวด" field="installment" width="60px" />
          <GridColumn
            title="สถานะ"
            field="commissionStatus"
            cell={(cell) => {
              return (
                <StatusTd className={"k-grid-content-sticky"}>
                  <Dropdown
                    value={cell.dataItem.commissionStatus}
                    options={props.statusOptions}
                    readOnly
                  />
                </StatusTd>
              )
            }}
            width="130px"
            locked
          />
        </Table>
      </ContentContainer>
    </Body>
  )
}

const enhancer = compose(
  withRouter(),
  withState("loadingTable", "setLoadingTable", false),

  withStores((stores) => ({
    fetchAffiliatesById: stores.saleCommissionStore.fetchSalesCommissionAffiliatesById,
    fetchAffiliatesConfig: stores.saleCommissionStore.fetchSalesCommissionAffiliatesConfig,
    salesCommissionAffiliate: stores.saleCommissionStore.salesCommissionAffiliate,
    salesCommissionDetails: stores.saleCommissionStore.salesCommissionDetails,
    paging: stores.saleCommissionStore.paging,
    statusOptions: stores.saleCommissionStore.$(
      "configs.attributes.salesCommissionDetailStatus.options",
      []
    ),
  })),
  withProps((props) => ({
    initialValues: { ...props.salesCommissionAffiliate },
    paging: {
      ...props.paging,
      skip: (props.paging.page - 1) * props.paging.perPage,
    },
  })),
  withForm({
    form: "ShowSalesCommissionAffiliateDetail",
  }),
  withHandlers({
    onPageChange: (props) => async (event) => {
      const page = event.page.skip / event.page.take + 1
      await props.fetchAffiliatesById(props.match.params.saleCommissionId, {
        ...props.filterParams,
        page,
        viewType: props.location.query.viewType,
      })
    },
  }),
  lifecycle({
    async componentDidMount() {
      this.props.setLoadingTable(true)
      await Promise.all([
        this.props.fetchAffiliatesById(this.props.match.params.saleCommissionId, {
          ...this.props.filterParams,
          viewType: this.props.location.query.viewType,
        }),
        this.props.fetchAffiliatesConfig(),
      ])
      this.props.setLoadingTable(false)
    },
    async componentDidUpdate(prevProps) {
      if (!isEqual(this.props.filterParams, prevProps.filterParams)) {
        this.props.setLoadingTable(true)
        const newParams = cloneDeep({
          ...this.props.filterParams,
          viewType: this.props.location.query.viewType,
        })
        await Promise.all([
          this.props.fetchAffiliatesById(this.props.match.params.saleCommissionId, newParams),
          this.props.fetchAffiliatesConfig(),
        ])
        this.props.setLoadingTable(false)
      }
    },
  })
)

export default enhancer(SaleCommissions)
