import { compose, branch } from "recompose"
import { omitProps } from "./index"

const withReadOnly = (ReadOnlyComponent) =>
  compose(
    branch(
      (props) => props.readOnly,
      () => ReadOnlyComponent
    ),
    omitProps("readOnly")
  )

export default withReadOnly
