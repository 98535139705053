import React from "react"
import styled from "@emotion/styled"
import { compose, withHandlers } from "recompose"
import { DialogActionsBar, Dialog, Button } from "@components"

const CustomDialog = styled(Dialog)`
  .k-window {
    border: 1px solid #e6e6e6;
    min-width: 398px;
  }
`
const Layout = styled.div`
  padding: 24px 28px 32px 36px;

  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 16px;
  font-family: Sarabun, sans-serif;

  > span {
    margin-bottom: 11px;

    :first-of-type {
      color: #5cb85c;
      font-weight: bold;
    }
  }
`
const DetailContainer = styled.div``
const HeaderDetail = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 8px;
  color: #666666;
`
const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  margin-bottom: 8px;

  > span {
    display: flex;
    justify-content: flex-start;
    min-width: 114px;
    color: #666666;
    :last-of-type {
      color: #0275d8;
      font-weight: bold;
    }
  }
`

const CancelButton = styled(Button)`
  min-height: 52px;
`
const SuccessModal = (props) => (
  <CustomDialog title={"ออกรายงานคอมมิชชั่นรับ"} onClose={props.onClose} isShowModal={props.open}>
    <Layout>
      <span>{"ออกรายงานสำเร็จ"}</span>
      <DetailContainer>
        <HeaderDetail>
          <span>{"ข้อมูลการออกรายงาน"}</span>
        </HeaderDetail>
        <RowContainer>
          <span>{"วันที่นำออก"}</span>
          <span>{props.data.reportDate}</span>
        </RowContainer>
        <RowContainer>
          <span>{"ประเภทข้อมูล"}</span>
          <span>{props.data.dataType}</span>
        </RowContainer>
        <RowContainer>
          <span>{"ชื่อไฟล์"}</span>
          <span>{props.data.fileName}</span>
        </RowContainer>
      </DetailContainer>
    </Layout>
    <DialogActionsBar>
      <CancelButton type="button" onClick={props.onClose} children="ปิด" styletype="secondary" />
    </DialogActionsBar>
  </CustomDialog>
)

const enhancer = compose(
  withHandlers({
    onClose: (props) => () => {
      props.onClose && props.onClose()
    },
  })
)
export default enhancer(SuccessModal)
