import React from "react"
import styled from "@emotion/styled"
import { Input } from "./index"
import { compose, withHandlers, withProps } from "recompose"
import { get } from "lodash"
import { withField, withCaption, withReadOnly } from "@enhancers/index"
import moment from "moment"
import Dropdown from "./Dropdown"

const ReadOnly = styled.div`
  min-height: 33.5px;
  display: flex;
  align-items: center;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  color: #666666;
`

const Layout = styled.div`
  display: flex;
  align-items: center;

  > span {
    max-width: 120px;
    margin-left: 0;
  }

  > input {
    flex: 1;
    max-width: 55px;
    margin-top: 0px !important;
    margin-left: 0px;
    font-family: Sarabun, sans-serif;
    font-size: 16px;
    margin-right: 10px;
  }

  > input:last-of-type {
    flex: 1;
    max-width: 65px;
    margin-top: 0px !important;
    margin-left: 10px;
    font-family: Sarabun, sans-serif;
    font-size: 16px;
  }
`
const DateSeperator = styled.span`
  margin: 0 5px !important;
`

const CustomInput = styled(Input)`
  :hover {
    ${(props) => (!props.valid ? "border: 1px solid #dc3545 !important;" : "")}
    ${(props) => (!props.valid ? "color: #dc3545 !important;" : "")}
  }

  :focus {
    ${(props) => (!props.valid ? "border: 1px solid #dc3545 !important;" : "")}
    ${(props) => (!props.valid ? "color: #dc3545 !important;" : "")}
  }

  ${(props) => (!props.valid ? "border: 1px solid #dc3545;" : "")}
  ${(props) => (!props.valid ? "color: #dc3545 !important;" : "")}
`

const CustomDropdown = styled(Dropdown)`
  max-width: 92px !important;
`

const CreditCardExpireInput = (props) => (
  <Layout>
    <CustomDropdown
      value={get(props.value, "paymentCreditCardExpiryMonth")}
      onChange={props.makeOnChange("paymentCreditCardExpiryMonth")}
      options={props.monthOptions}
      disabled={props.disabled}
      name="paymentCreditCardExpiryMonth"
      className={!props.valid ? "k-state-invalid" : ""}
      valid={props.valid}
      placeholder="เดือน"
    />
    <DateSeperator>/</DateSeperator>
    <CustomDropdown
      value={get(props.value, "paymentCreditCardExpiryYear")}
      onChange={props.makeOnChange("paymentCreditCardExpiryYear")}
      options={props.yearOptions}
      disabled={props.disabled}
      className={!props.valid ? "k-state-invalid" : ""}
      name="paymentCreditCardExpiryYear"
      valid={props.valid}
      placeholder="ปี"
    />

    {/* <CustomInput
        value={get(props.value, "paymentCreditCardExpiryMonth")}
        onChange={props.makeOnChange("paymentCreditCardExpiryMonth")}
        disabled={props.disabled}
        name="paymentCreditCardExpiryMonth"
        valid={props.valid}
        maxLength={2}
        className={!props.valid ? "k-state-invalid" : ""}
        number
        placeholder="01"
      />
      <DateSeperator>/</DateSeperator>
      <CustomInput
        value={get(props.value, "paymentCreditCardExpiryYear")}
        onChange={props.makeOnChange("paymentCreditCardExpiryYear")}
        disabled={props.disabled}
        name="paymentCreditCardExpiryYear"
        valid={props.valid}
        maxLength={4}
        number
        placeholder="2000"
      /> */}
  </Layout>
)

const enhance = compose(
  withField(),
  withCaption(),
  withHandlers({
    makeOnChange: (props) => (fieldName) => (value) => {
      props.onChange({ ...props.value, [fieldName]: value })
    },
  }),
  withProps((props) => ({
    value: props.value || {},
    valid: !props.isRequire
      ? true
      : !props.touched ||
        (props.value.paymentCreditCardExpiryMonth && props.value.paymentCreditCardExpiryYear),
  })),
  // withProps((props) => ({
  //   value: props.value || {},
  //   valid: !props.isRequire
  //     ? true
  //     : !props.touched ||
  //       (props.value.paymentCreditCardExpiryMonth &&
  //         props.value.paymentCreditCardExpiryYear &&
  //         props.value.paymentCreditCardExpiryMonth !== "0" &&
  //         props.value.paymentCreditCardExpiryMonth !== "00" &&
  //         props.value.paymentCreditCardExpiryMonth < 13 &&
  //         props.value.paymentCreditCardExpiryYear <= moment().year() + 10 &&
  //         props.value.paymentCreditCardExpiryYear >= moment().year() &&
  //         props.value.paymentCreditCardExpiryMonth.length == 2 &&
  //         props.value.paymentCreditCardExpiryYear.length === 4),
  // })),
  withProps(() => {
    const monthOptions = []
    const yearOptions = []

    for (let index = 1; index < 13; index++) {
      monthOptions.push({
        label: index < 10 ? `0${index}` : `${index}`,
        value: index,
      })
    }

    for (let index = moment().year(); index <= moment().year() + 10; index++) {
      yearOptions.push({ label: `${index}`, value: index })
    }

    return { monthOptions, yearOptions }
  }),
  withReadOnly((props) => (
    <ReadOnly>
      {`${get(props.value, "paymentCreditCardExpiryMonth") || ""}/${
        get(props.value, "paymentCreditCardExpiryYear") || ""
      }`.replace(/^\/$/, "")}
    </ReadOnly>
  ))
)

export default enhance(CreditCardExpireInput)
