import React from "react"
import { compose, withHandlers, withProps } from "recompose"
import { withHidden, withField, withCaption, omitProps } from "@enhancers/index"
import styled from "@emotion/styled"
import { prepareOptions } from "@common/helper"
import Checkbox from "./Checkbox"

const Layout = styled.div``

const CheckboxGroup = (props) => (
  <Layout>
    {props.options.map(({ label, value }) => (
      <Checkbox
        key={value}
        label={label}
        value={props.value.includes(value)}
        onChange={(checked) => props.onChange(value, checked)}
        readOnly={props.readOnly}
        hidden={props.readOnly && !props.value.includes(value)}
      />
    ))}
  </Layout>
)

const enhancer = compose(
  withHidden(),
  withField(),
  withCaption(),
  withProps((props) => ({
    options: prepareOptions(props.options),
    value: props.value || [],
  })),
  withHandlers({
    onChange: (props) => (value, checked) => {
      const data = props.value.filter((v) => v !== value)
      if (checked) {
        data.push(value)
      }
      props.onChange(data)
    },
  }),
  omitProps()
)

const EnhanceCheckboxGroup = enhancer(CheckboxGroup)

export default styled(EnhanceCheckboxGroup)`
  > * :nth-of-type(1) {
    margin-bottom: auto;
    height: 33.5px;
    display: flex;
    align-items: center;
  }
`
