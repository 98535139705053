import React, { useEffect } from "react"
import { compose, withHandlers, withState, lifecycle } from "recompose"
import styled from "@emotion/styled"
import { Body } from "@pages/leads/show/LeadInfo"
import { Field, Dropdown, BreadCrumb, Form, Button } from "@components/index"
import * as path from "@common/path"
import { withForm, withStores, withHooks, withRouter } from "@enhancers/index"
import DeleteModal from "@pages/insurers/show/DeleteModal"

const Layout = styled(Form)`
  margin: 0px auto 25px auto;
`
const Header = styled.div`
  display: flex;
  margin: 20px 0 12px 0;
  flex-wrap: wrap;
`
const RightPanel = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  > div {
    margin-left: 6px;
  }
`
const EditButton = styled(Button)`
  margin-left: 10px;
  width: 90px;
`
const SaveButton = styled(Button)`
  display: flex;
  margin-left: 10px;
  width: 90px;
`
const TrashButton = styled(Button)`
  width: 84px;
`
const CancelButton = styled(SaveButton)`
  width: 90px;
`
const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  > * {
    margin-left: 10px;
  }
`

export const CommissionMappingInfoPage = (props) => (
  <Layout>
    <BreadCrumb
      links={[
        { label: "คอมมิชชั่นรับ", path: path.commissisonPath() },
        { label: "ตั้งค่าคอมมิชชั่น", path: path.commissionMappingsPath() },
        {
          label: "รายละเอียดการตั้งค่าคอมมิชชั่น",
          path: path.commissionMappingsPath(props.match.params.commissionId),
        },
      ]}
    />
    <Header>
      <RightPanel>
        {props.isEdit ? (
          <ButtonGroup>
            <CancelButton
              icon="cancel"
              onClick={props.onCancel}
              children="ยกเลิก"
              styletype="delete"
            />
            <SaveButton
              icon="floppy"
              onClick={props.onSave || props.onCreate}
              children="บันทึก"
              styletype="agree"
            />
          </ButtonGroup>
        ) : (
          <ButtonGroup>
            <TrashButton icon="delete" onClick={props.onDelete} children="ลบ" styletype="delete" />
            <EditButton icon="edit" onClick={props.onEdit} children="แก้ไข" />
          </ButtonGroup>
        )}
      </RightPanel>
    </Header>
    <Body>
      {/* <Field
          component={Dropdown}
          name="broker"
          caption="Broker"
          readOnly={!props.isEdit}
          hidden
        /> */}
      <Field
        component={Dropdown}
        name="insurancePolicyId"
        caption="แบบประกัน"
        options={props.filteredPolicyOptions || props.policyOptions}
        readOnly={!props.isEdit}
        validate="required"
      />
      <Field
        component={Dropdown}
        name="commissionId"
        caption="คอมมิชชั่น"
        options={props.selectedCommissionOptions}
        readOnly={!props.isEdit}
        validate="required"
      />
    </Body>

    <DeleteModal
      open={props.isShowDeleteModal}
      onClose={props.onCloseModal}
      caption="Commission"
      onAfterDeleteSubmit={props.onConfirmDelete}
    />
  </Layout>
)

const enhancer = compose(
  withRouter(),
  withState("isEdit", "setIsEdit", false),
  withState("isShowDeleteModal", "setIsShowDeleteModal", false),
  withState("selectedCommissionOptions", "setSelectedCommissionOptions", []),
  withStores((stores) => ({
    fetchById: stores.commissionMappingStore.fetchById,
    fetchConfig: stores.commissionMappingStore.fetchConfig,
    removeCommission: stores.commissionMappingStore.removeCommission,
    updateCommission: stores.commissionMappingStore.updateCommission,
    policyOptions: [
      ...stores.commissionMappingStore.$("configs.attributes.lifeInsurancePolicy.options", []),
      ...stores.commissionMappingStore.$("configs.attributes.healthInsurancePolicy.options", []),
    ],
    commissionOptions: stores.commissionMappingStore.$("configs.attributes.commission.options", []),
    comissionMappings: stores.commissionMappingStore.all,
    initialValues: stores.commissionMappingStore.current,
  })),
  withForm({
    form: "editCommissionMappingForm",
  }),
  withHandlers({
    onEdit: (props) => () => {
      props.setIsEdit(true)
    },
    onCancel: (props) => () => {
      props.reset()
      props.setIsEdit(false)
    },
    onSave: (props) =>
      props.handleSubmit(async (values) => {
        await props.updateCommission(values.insurancePolicyId, values.commissionId)
        props.setIsEdit(!props.isEdit)
      }),
    onDelete: (props) => () => {
      props.setIsShowDeleteModal(true)
    },
    onCloseModal: (props) => () => props.setIsShowDeleteModal(false),
    onConfirmDelete: (props) => async () => {
      await props.removeCommission(props.match.params.commissionId)
      props.history.push(path.commissionMappingsPath())
    },
  }),
  withState("filteredPolicyOptions", "setFilteredPolicyOptions", null),
  lifecycle({
    async componentDidMount() {
      await Promise.all([
        this.props.fetchConfig(),
        this.props.fetchById(this.props.match.params.commissionId),
      ])
      const policyInsurerId = this.props.policyOptions.find(
        (option) => option.value === parseInt(this.props.match.params.commissionId)
      )
      const selectedCommissionOptions = this.props.commissionOptions.filter(
        (option) => option.insurerId === policyInsurerId.insurerId
      )
      this.props.setSelectedCommissionOptions(selectedCommissionOptions)
    },
  }),
  withHooks((props) => {
    useEffect(() => {
      if (props.location.query.insuranceCompanyId) {
        const filteredPolicyOptions = props.policyOptions.filter(
          (policy) => policy.insurerId === parseInt(props.location.query.insuranceCompanyId)
        )
        props.setFilteredPolicyOptions(filteredPolicyOptions)
      }
    }, [])

    useEffect(() => {
      if (props.values.insurancePolicyId) {
        const policyInsurerId = props.policyOptions.find(
          (option) => option.value === props.values.insurancePolicyId
        )
        const selectedCommissionOptions = props.commissionOptions.filter(
          (option) => option.insurerId === policyInsurerId.insurerId
        )
        props.setSelectedCommissionOptions(selectedCommissionOptions)
      }
    }, [props.values.insurancePolicyId])
  })
)

export default enhancer(CommissionMappingInfoPage)
