import React, { Fragment } from "react"
import styled from "@emotion/styled"
import { compose, withHandlers, withState } from "recompose"
import { Button, Authorize } from "@components"
import { withRouter, withStores } from "@enhancers/index"
import UploadDocumentModal from "../UploadDocumentModal"
import DeleteModal from "@pages/insurers/show/DeleteModal"

const LayoutAnnotation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const ViewButton = styled(Button)`
  margin-left: 6px;
  height: 38px;
  width: 90px;

  > img {
    filter: invert(1);
  }
`
const DownloadButton = styled(ViewButton)`
  width: 68px;
`
const AttachmentButton = styled(Button)`
  margin-left: 6px;
  height: 38px;
  width: 110px;

  > img {
    filter: invert(1);
  }
`
const DeleteButton = styled(Button)`
  margin-left: 6px;
  height: 38px;
  width: 52px;

  > img {
    filter: invert(1);
  }
`
const ApproveDocumentButton = styled(Button)`
  max-width: 103px;
  width: 100%;
  margin-left: 6px;
`

const Annotation = (props) => (
  <Fragment>
    <LayoutAnnotation>
      {/*new uploaded verifying approved rejected*/}

      <Authorize roles={["user.admin", "member.team_leader", "member.salesman", "member.biz_dev"]}>
        <ViewButton
          children="ดูเอกสาร"
          onClick={props.onView}
          disabled={["new", "rejected"].includes(props.dataItem.state)}
          styletype="secondary"
        />
      </Authorize>

      {/*SHow ทุก state ยกเว้น ยังไม่อัพโหลด  */}
      <Authorize roles={["user.admin", "member.team_leader", "member.salesman", "member.biz_dev"]}>
        <DownloadButton
          children="โหลด"
          onClick={props.onDownload}
          disabled={["new", "rejected"].includes(props.dataItem.state)}
          styletype="secondary"
        />
      </Authorize>

      {/*SHow ยกเว้น state [รอการตรวจสอบ, ผ่าน]  */}
      <Authorize roles={["user.admin", "member.team_leader", "member.salesman"]}>
        <AttachmentButton
          children="แนบเอกสาร"
          onClick={props.onClickUploadDocumentButton}
          styletype="secondary"
          disabled={props.dataItem.state !== "new" || props.orderState === "rejected"}
        />
      </Authorize>

      <Authorize roles={["member.biz_dev"]}>
        <ApproveDocumentButton
          icon="check"
          onClick={() => props.approveDocument(props.dataItem)}
          disabled={props.dataItem.state !== "verifying" || props.orderState === "rejected"}
          children="อนุมัติ"
        />
      </Authorize>

      <Authorize roles={["user.admin", "member.team_leader", "member.salesman", "member.biz_dev"]}>
        <DeleteButton
          icon="delete"
          onClick={props.onShowDeleteModal}
          disabled={
            props.orderState === "new" ||
            props.dataItem.state === "approved" ||
            !props.dataItem.canDelete ||
            props.orderState === "rejected"
          }
          styletype="delete"
        />
      </Authorize>

      {/* <UploadDocumentModal
        open={props.isShowUploadModal}
        onClose={props.onCloseUploadModal}
        title="แก้ไขไฟล์"
        onAfterAddSubmit={props.onAfterEditSubmit}
        form={`Upload-${props.dataItem.id}-Form`}
        initialValues={props.dataItem}
        readNameOnly={false}
      /> */}
      <DeleteModal
        open={props.isShowDeleteModal}
        onClose={props.onCloseDeleteModal}
        caption="Document"
        onAfterDeleteSubmit={props.onAfterDeleteSubmit}
      />
    </LayoutAnnotation>
  </Fragment>
)

const enhancer = compose(
  withRouter(),
  withStores((stores, props) => ({
    fetchOrder: stores.orderStore.fetchOrder,
    fetchOrderDocumentsByOrderId: stores.orderStore.fetchOrderDocumentsByOrderId,
    updateOrderDocumentByDocumentId: stores.orderStore.updateOrderDocumentByDocumentId,
    destroyOrderDocumentByDocumentId: stores.orderStore.destroyOrderDocumentByDocumentId,
    remarkOrderDocumentByDocumentId: stores.orderStore.remarkOrderDocumentByDocumentId,
    downloadOrderDocumentByDocumentId: stores.orderStore.downloadOrderDocumentByDocumentId,
    approveOrderDocumentByDocumentId: stores.orderStore.approveOrderDocumentByDocumentId,
    viewDocumentInNewTab: stores.orderStore.viewDocumentInNewTab,
    orderId: props.match.params.orderId,
  })),
  withState("isShowUploadModal", "setIsShowUploadModal", false),
  withState("isShowDeleteModal", "setIsShowDeleteModal", false),
  withState("toggleDocumentApproval", "setToggleDocumentApproval", false),
  withState("successApprovalModalMessage", "setSuccessApprovalModalMessage", {
    info: null,
    message: null,
  }),
  withHandlers({
    onDownload: (props) => async () => {
      await props.downloadOrderDocumentByDocumentId(props.orderId, props.dataItem.id)
    },
    closeApprovalDocumentModal: (props) => async () => {
      props.setToggleDocumentApproval(!props.toggleDocumentApproval)
    },
    onView: (props) => async () => {
      await props.viewDocumentInNewTab(props.orderId, props.dataItem.id)
    },
    onShowUploadModal: (props) => () => {
      props.setIsShowUploadModal(true)
    },
    onCloseUploadModal: (props) => () => {
      props.setIsShowUploadModal(false)
    },
    onAfterEditSubmit: (props) => async (values) => {
      await props.updateOrderDocumentByDocumentId(props.orderId, props.dataItem.id, values)
    },
    approveDocument: (props) => async (dataItem) => {
      try {
        await props.approveOrderDocumentByDocumentId(props.orderId, props.dataItem.id)
        await props.fetchOrderDocumentsByOrderId(props.orderId)
        // props.onAfterApproveDoc && props.onAfterApproveDoc(dataItem)
      } catch (e) {
        window.alert("อนุมัติเอกสารไม่สำเร็จ")
      }
    },
    onShowDeleteModal: (props) => () => {
      props.setIsShowDeleteModal(true)
    },
    onCloseDeleteModal: (props) => () => {
      props.setIsShowDeleteModal(false)
    },
    onAfterDeleteSubmit: (props) => async () => {
      await props.destroyOrderDocumentByDocumentId(props.orderId, props.dataItem.id)
      props.onAfterDeleteDocument && props.onAfterDeleteDocument()
    },
  })
)

export default enhancer(Annotation)
