import Validator from "validatorjs"
import cache from "@common/cache"
import thaiNationalID from "./thaiNationalId"

Validator.registerAsync("citizenId", function (citizenId, attribute, req, passes) {
  const validator = new Validator({ citizenId: "required" })
  if (thaiNationalID(citizenId)) {
    passes()
  } else {
    passes(false, validator.errors.all())
  }
})

Validator.registerAsync("address", function (address, attribute, req, passes) {
  const validator = new Validator(
    address,
    {
      standard: {
        // building: '',
        // roomNo: '',
        // floor: '',
        // housingEstate: '',
        housingNo: "required",
        // villageNo: '',
        // street: '',
        // lane: '',
        // road: '',
        subDistrict: "required",
        district: "required",
        province: "required",
        postcode: "required",
      },
    }[attribute]
  )
  if (validator.passes()) {
    passes()
  } else {
    passes(false, validator.errors.all())
  }
})

Validator.registerAsync("currentcyWithPeriod", function (address, attribute, req, passes) {
  const validator = new Validator(
    address,
    {
      standard: {
        amount: "required",
        period: "required",
      },
    }[attribute]
  )
  if (validator.passes()) {
    passes()
  } else {
    passes(false, validator.errors.all())
  }
})

Validator.registerAsync("nameInfo", function (nameInfo, attribute, req, passes) {
  const validator = new Validator(
    nameInfo,
    {
      standard: {
        prefixName: "required",
        firstName: "required",
        lastName: "required",
      },
    }[attribute]
  )

  if (validator.passes()) {
    passes()
  } else {
    passes(false, validator.errors.all())
  }
})

export const validator = (...rules) =>
  cache.fetchWithoutAsync(`validator:${JSON.stringify(rules)}`, () => (value) => {
    const ruleList = rules.reduce(
      (memo, rule) => [...memo, ...(typeof rule === "string" ? rule.split("|") : [rule])],
      []
    )
    const validation = new Validator({ value }, { value: ruleList })
    if (validation.passes(() => {})) {
      return null
    } else {
      return validation.errors.first("value")
    }
  })

export default validator
