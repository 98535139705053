import React, { Fragment } from "react"
import styled from "@emotion/styled"
import { withStores, withRouter, withForm } from "@enhancers"
import {
  Grid,
  GridColumn,
  makeCell,
  LoadingScope,
  Button,
  DateInput,
  Dropdown,
  BreadCrumb,
  Header as TextHeader,
  Checkbox,
  FilterButton,
  makeHeaderCell,
} from "@components"
import { compose, withHandlers, withState, lifecycle, withProps } from "recompose"
import * as path from "@common/path"
import defaultLogo from "@assets/images/img-no-insurance.png"
import { isEqual, get, cloneDeep, omit } from "lodash"
import SuccessModal from "./SuccessModal"
import { toCurrency, exceptNullKeys } from "@common/helper"
import { validate, convertDateToString } from "../../index/index"
import { FieldArray } from "redux-form"
import SalesmanExcepted from "@pages/sale_commissions/index/SalesmanExcepted"
import FilterPanel from "./FilterPanel"

const Layout = styled.div`
  margin: 0px auto 60px auto;
`
const BreadCrumbLayout = styled.div`
  width: 100%;
  margin-bottom: 30px;
`
const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 12px 0;
  margin-top: 20px;
  justify-content: space-between;
`
const CustomFilterButton = styled(FilterButton)`
  margin-bottom: 20px;
`
const SalesmanExceptContainer = styled.div`
  background-color: white;
  min-width: 678px;
  margin-bottom: 24px;
  border: 1px solid #eeeef2;
  border-radius: 4px;
  opacity: 1;
`

const Table = styled(Grid)`
  .k-grid-header {
    .k-header {
      font-weight: bold;
    }
  }

  .k-grid-container {
    .k-grid-content.k-virtual-content {
      > div {
        .k-grid-table {
          > tbody {
            .k-master-row {
              > td {
                cursor: pointer;
              }
            }
            .wrap-text {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
`
const CustomCheckbox = styled(Checkbox)`
  .k-item.k-treeview-item {
    height: 26px !important;
    padding-bottom: 5px;
  }
`
const LeftPanel = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-start;
  > div {
    display: block;
    width: 225px;
    font-family: "ThaiSans Neue";
    font-size: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
const Title = styled(TextHeader)`
  font-family: "ThaiSans Neue";
  font-size: 30px;
  color: #666666;
  display: flex;
  align-items: center;
`
const CustomTd = styled.td`
  position: sticky;
  left: 0;
  border-right-width: 1px;
  background-color: #ffffff;

  > div {
    > div {
      justify-content: center;
    }
  }
`
const Logo = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 5px;
  vertical-align: middle;
`
const BackButton = styled(Button)`
  height: 38px;
  color: #627b8b;
  border-color: #627b8b;
`
const ComfirmVerifyingCommissions = styled(Button)`
  min-width: 191px;
  height: 38px;
  margin-left: 6px;
`
const ButtonContainer = styled.div`
  display: flex;
`
const CommissionVerification = (props) => (
  <Fragment>
    {props.loading ? (
      <LoadingScope />
    ) : (
      <Layout>
        <BreadCrumbLayout>
          <BreadCrumb links={props.links} />
        </BreadCrumbLayout>

        <Header>
          <LeftPanel>
            <Title>คอมมิชชั่นรับ</Title>
          </LeftPanel>

          <ButtonContainer>
            <BackButton
              awesomeIcon="chevron-left"
              onClick={props.back}
              children="ย้อนกลับ"
              type="disbled"
            />
            <ComfirmVerifyingCommissions
              awesomeIcon="check"
              onClick={props.verifyCommissions}
              children="ยืนยันการตรวจสอบ"
            />
          </ButtonContainer>
        </Header>

        <CustomFilterButton expanded={props.filterExpanded} onExpand={props.onExpandFilter}>
          <FilterPanel />
        </CustomFilterButton>

        <SalesmanExceptContainer>
          <FieldArray
            name="ignoreCommissionIds"
            component={SalesmanExcepted}
            options={props.commissionIdOptions}
            isOrder={true}
          />
        </SalesmanExceptContainer>
        <Table loading={props.loadingTable} data={props.insurerCommissions}>
          <GridColumn
            title=""
            width="42px"
            locked
            headerCell={makeHeaderCell(CustomCheckbox, () => ({
              value: props.isSelectedAll,
              onChange: (checked) => props.onSelectAll(checked),
            }))}
            cell={(cellProps) => {
              return (
                <CustomTd className={`k-grid-content-sticky ${cellProps.field}`}>
                  <Checkbox
                    value={cellProps.dataItem.selected}
                    onChange={(checked) => props.onSelectRow(checked, cellProps.dataItem)}
                  />
                </CustomTd>
              )
            }}
            // cell={makeCell(Checkbox, (cellProps) => ({
            //   value: cellProps.dataItem.selected,
            //   preventClick: true,
            //   onChange: (checked) => props.onSelectRow(checked, cellProps.dataItem),
            // }))}
          />
          <GridColumn field="tqmOrderId" title="รหัสคำสั่งซื้อ" width="110px" />
          <GridColumn field="fullName" className="wrap-text" title="ชื่อลูกค้า" width="155px" />
          <GridColumn
            field="policyName"
            title="แบบประกัน"
            width="120px"
            filterable={false}
            cell={(cell) => (
              <td className="wrap-text">
                <Logo src={cell.dataItem.logoPath || defaultLogo} /> {cell.dataItem.policyName}
              </td>
            )}
          />
          <GridColumn
            field="yearPeriod"
            title="ปี / งวด"
            width="80px"
            resizable={true}
            cell={(cell) => {
              return (
                <td>
                  {cell.dataItem.policyYear
                    ? `${cell.dataItem.policyYear}/${cell.dataItem.paymentSequence}`
                    : ""}
                </td>
              )
            }}
          />
          <GridColumn
            field="totalPremium"
            title="เบี้ยสุทธิ"
            width="100px"
            resizable={true}
            cell={(cell) => <td>{toCurrency(cell.dataItem.totalPremium)}</td>}
          />
          <GridColumn field="totalCommission" title="ค่านายหน้า" width="100px" resizable={true} />
          <GridColumn
            field="totalServiceCharge"
            title="ค่าบริการ"
            width="100px"
            resizable={true}
            cell={(cell) => <td>{toCurrency(cell.dataItem.totalServiceCharge)}</td>}
          />
          <GridColumn
            field="coverageDate"
            title="วันที่คุ้มครอง"
            width="120px"
            resizable={true}
            cell={makeCell(DateInput, (cellProps) => ({
              readOnly: true,
              value: cellProps.dataItem.coverageDate,
            }))}
          />
          <GridColumn
            field="paymentStatus"
            title="สถานะการชำระเงิน"
            width="155px"
            filterable={false}
            cell={makeCell(Dropdown, (cellProps) => ({
              options: props.paymentStatusOptions,
              readOnly: true,
              value: cellProps.dataItem.paymentStatus,
            }))}
          />
          <GridColumn
            field="premiumPaidDate"
            title="วันชำระเงิน"
            width="120px"
            resizable={true}
            cell={makeCell(DateInput, (cellProps) => ({
              readOnly: true,
              value: cellProps.dataItem.premiumPaidDate,
            }))}
          />
          <GridColumn
            field="receivedDate"
            title="วันที่รับเงิน"
            width="120px"
            resizable={true}
            cell={makeCell(DateInput, (cellProps) => ({
              readOnly: true,
              value: cellProps.dataItem.receivedDate,
            }))}
          />
          <GridColumn
            field="exportedAt"
            title="วันที่นำออก"
            width="120px"
            resizable={true}
            cell={makeCell(DateInput, (cellProps) => ({
              readOnly: true,
              value: cellProps.dataItem.exportedAt,
            }))}
          />
          <GridColumn field="policySaleType" title="ประเภท" width="120px" resizable={true} />
          <GridColumn field="policyNumber" title="เลขกรมธรรม์" width="140px" resizable={true} />
          <GridColumn field="policyStatus" title="สถานะกรมธรรม์" width="135px" resizable={true} />
          <GridColumn
            field="state"
            title="สถานะ"
            width="110px"
            cell={makeCell(Dropdown, (cell) => ({
              value: cell.dataItem.state,
              options: props.stateOptions,
              readOnly: true,
            }))}
          />
        </Table>
      </Layout>
    )}
    <SuccessModal
      open={props.openSuccessModel}
      onClose={props.closeSuccessModel}
      verificationAmount={props.verificationAmount}
    />
  </Fragment>
)

const enhance = compose(
  withRouter({
    queryTypes: {
      insuranceCompanyId: "integer",
      customerName: "string",
      "coverageDate.min": "date",
      "coverageDate.max": "date",
      "premiumPaidDate.min": "date",
      "premiumPaidDate.max": "date",
      "receivedDate.min": "date",
      "receivedDate.max": "date",
    },
  }),
  withState("loading", "setLoading", false),
  withState("loadingTable", "setLoadingTable", false),
  withState("isShowModal", "setIsShowModal", false),
  withState("selectedCommissionIds", "setSelectedCommissionIds", []),
  withState("openSuccessModel", "setOpenSuccessModel", false),
  withState("verificationAmount", "setVerificationAmount", 0),
  withStores((stores, props) => ({
    fetchInsurerCommissions: stores.insurerCommissionStore.fetch,
    fetchConfigs: stores.insurerCommissionStore.fetchConfigs,
    insurerCommissions: stores.insurerCommissionStore.all,
    paging: stores.insurerCommissionStore.paging,
    stateOptions: stores.insurerCommissionStore.$("configs.attributes.state.options", []),
    paymentStatusOptions: stores.insurerCommissionStore.$(
      "configs.attributes.paymentStatus.options",
      []
    ),
    importInsurerCommission: stores.insurerCommissionStore.import,
    verifyMultipleInsuranceCommission:
      stores.insurerCommissionStore.verifyMultipleInsuranceCommission,
    currentRole: stores.appStore.currentRole,
  })),
  withProps((props) => {
    let insurerCommissions = props.insurerCommissions
    insurerCommissions.forEach(
      (commission) => (commission.selected = props.selectedCommissionIds.includes(commission.id))
    )
    const initialValues = props.location.query
    return {
      insurerCommissions,
      initialValues,
    }
  }),
  withState("errors", "setErrors", null),
  withState("fetchInsurerCommissionsTask", "setFetchInsurerCommissionsTask", null),
  withHandlers({
    back: (props) => () => {
      props.history.push(path.insurerCommissionsPath())
    },
    closeSuccessModel: (props) => () => {
      props.setOpenSuccessModel(!props.openSuccessModel)
      props.history.push(path.insurerCommissionsPath())
    },
    verifyCommissions: (props) => async () => {
      try {
        await props.verifyMultipleInsuranceCommission({
          insurerCommissionIds: props.selectedCommissionIds,
        })

        props.setVerificationAmount(props.selectedCommissionIds.length)
        props.setOpenSuccessModel(!props.openSuccessModel)
      } catch (e) {
        window.alert("เกิดข้อผิดพลาดบางอย่าง")
      }

      const insurerCommissions = await props.fetchInsurerCommissions({
        perPage: 500,
        state: "verifying",
      })
      const insurerCommissionIds = insurerCommissions.map((commission) => commission.id)
      props.setSelectedCommissionIds(insurerCommissionIds)
    },
    onSelectAll: (props) => (selected) => {
      if (selected) {
        props.setSelectedCommissionIds(props.insurerCommissions.map((commission) => commission.id))
      } else {
        props.setSelectedCommissionIds([])
      }
    },
    onSelectRow: (props) => (value, dataItem) => {
      if (value) {
        props.setSelectedCommissionIds([...props.selectedCommissionIds, dataItem.id])
      } else {
        props.setSelectedCommissionIds(
          props.selectedCommissionIds.filter((id) => id !== dataItem.id)
        )
      }
    },
    onUpdateFilterParams: (props) => async (values, delay = 0) => {
      let filterParams = cloneDeep(values)
      props.setLoadingTable(true)

      if (get(filterParams, "coverageDate.min")) {
        filterParams.coverageDate.min = convertDateToString(filterParams.coverageDate.min)
      }
      if (get(filterParams, "coverageDate.max")) {
        filterParams.coverageDate.max = convertDateToString(filterParams.coverageDate.max)
      }
      if (get(filterParams, "premiumPaidDate.min")) {
        filterParams.premiumPaidDate.min = convertDateToString(filterParams.premiumPaidDate.min)
      }
      if (get(filterParams, "premiumPaidDate.max")) {
        filterParams.premiumPaidDate.max = convertDateToString(filterParams.premiumPaidDate.max)
      }
      if (get(filterParams, "receivedDate.min")) {
        filterParams.receivedDate.min = convertDateToString(filterParams.receivedDate.min)
      }
      if (get(filterParams, "receivedDate.max")) {
        filterParams.receivedDate.max = convertDateToString(filterParams.receivedDate.max)
      }

      if (props.fetchInsurerCommissionsTask) {
        clearTimeout(props.fetchInsurerCommissionsTask)
      }

      await props.setFetchInsurerCommissionsTask(
        setTimeout(() => {
          props.history.push(path.commissionVerification(filterParams))
        }, delay)
      )

      props.setLoadingTable(false)
    },
  }),

  withForm({
    form: "InsurerCommissionVerificationForm",
    validate,
    enableReinitialize: false,
    onChange: (filters, _, props) => {
      props.onUpdateFilterParams(filters, 400)
    },
  }),
  withState("loadingImport", "setLoadingImport", false),
  withState("filterExpanded", "setFilterExpanded", false),
  withHandlers({
    onExpandFilter: (props) => (expanded) => {
      props.setFilterExpanded(expanded)
    },
    onCloseAndOpenModal: (props) => () => {
      props.setIsShowModal(!props.isShowModal)
    },
  }),
  withProps((props) => {
    const data = props.insurerCommissions
    const isSelectedAll = data.length > 0 ? data.every((row) => row.selected) : false

    return {
      isSelectedAll,
    }
  }),
  withProps((props) => {
    const { ignoreCommissionIds } = props.values || {}
    const ignoreCommissionIdArray = ignoreCommissionIds || []

    const commissionIdOptions = props.insurerCommissions.map((commission) => {
      return { label: commission.tqmOrderId, value: commission.tqmOrderId }
    })
    const filterInsurerCommissions = props.insurerCommissions.filter(
      (commission) => !ignoreCommissionIdArray.includes(commission.tqmOrderId)
    )

    const uniqueCommissionIdOptions = Array.from(
      new Set(commissionIdOptions.map((option) => option.label))
    ).map((label) => {
      return commissionIdOptions.find((option) => option.label === label)
    })

    return {
      commissionIdOptions: uniqueCommissionIdOptions,
      insurerCommissions: filterInsurerCommissions,
    }
  }),
  withProps((props) => {
    let links = []

    links = [
      { label: "คอมมิชชั่นรับ", path: path.insurerCommissionsPath() },
      {
        label: "ยืนยันรายการตรวจสอบ",
      },
    ]

    return {
      links,
    }
  }),
  withProps((props) => {
    let filterParams = cloneDeep(props.location.query)
    if (
      get(filterParams, "coverageDate.max") &&
      get(filterParams, "coverageDate.min") &&
      filterParams.coverageDate.min > filterParams.coverageDate.max
    ) {
      delete filterParams.coverageDate
    }
    if (
      get(filterParams, "premiumPaidDate.max") &&
      get(filterParams, "premiumPaidDate.min") &&
      filterParams.premiumPaidDate.min > filterParams.premiumPaidDate.max
    ) {
      delete filterParams.premiumPaidDate
    }
    if (
      get(filterParams, "receivedDate.max") &&
      get(filterParams, "receivedDate.min") &&
      filterParams.receivedDate.min > filterParams.receivedDate.max
    ) {
      delete filterParams.receivedDate
    }
    if (filterParams.customerName && filterParams.customerName.length < 4) {
      delete filterParams.customerName
    }

    filterParams = omit(filterParams, ["ignoreCommissionIds"])

    return { filterParams }
  }),

  lifecycle({
    async componentDidMount() {
      this.props.setLoading(true)
      await this.props.fetchConfigs()
      const exceptNullParams = exceptNullKeys(this.props.filterParams, "insuranceCompanyId")
      const insurerCommissions = await this.props.fetchInsurerCommissions({
        ...exceptNullParams,
        perPage: 500,
        state: "verifying",
      })
      const insurerCommissionIds = insurerCommissions.map((commission) => commission.id)
      this.props.setSelectedCommissionIds(insurerCommissionIds)
      this.props.setLoading(false)
    },
    async componentDidUpdate(prevProps) {
      if (!isEqual(this.props.filterParams, prevProps.filterParams)) {
        this.props.setLoadingTable(true)
        const exceptNullParams = exceptNullKeys(this.props.filterParams, "insuranceCompanyId")
        const insurerCommissions = await this.props.fetchInsurerCommissions({
          ...exceptNullParams,
          perPage: 500,
          state: "verifying",
        })

        const existIgnoreCommissionIds = this.props.values.ignoreCommissionIds.filter(
          (tqmOrderId) => {
            return !!insurerCommissions.find((commission) => commission.tqmOrderId === tqmOrderId)
          }
        )
        this.props.change(
          "ignoreCommissionIds",
          existIgnoreCommissionIds.length > 0 ? existIgnoreCommissionIds : [{}]
        )

        this.props.setLoadingTable(false)
      }

      if (!isEqual(this.props.insurerCommissions, prevProps.insurerCommissions)) {
        const insurerCommissionIds = this.props.insurerCommissions.map(
          (commission) => commission.id
        )
        this.props.setSelectedCommissionIds(insurerCommissionIds)
      }
    },
  })
)

export default enhance(CommissionVerification)
