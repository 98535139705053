import React from "react"
import styled from "@emotion/styled"
import { compose, withHandlers, withProps } from "recompose"
import { DialogActionsBar, Dialog, Button } from "@components"

const Layout = styled.div`
  padding: 15px;
`
const CustomDialog = styled(Dialog)``

export const CancelButton = styled(Button)`
  height: 52px;
  color: #666666;
`

export const SubmitButton = styled(Button)`
  height: 52px;

  background: #0275d8;
  border: 1px solid #eeeef2;
  color: #ffffff;
`

const ConfirmModal = (props) => (
  <CustomDialog title="ยืนยันการส่งเอกสาร" onClose={props.onClose} isShowModal={props.open}>
    <Layout>กรุณากดยืนยันเพื่อส่งเอกสารทั้งหมด</Layout>
    <DialogActionsBar>
      <CancelButton type="button" onClick={props.onClose} children="ยกเลิก" />
      <SubmitButton type="button" onClick={props.onSubmit} children="ยืนยัน" />
    </DialogActionsBar>
  </CustomDialog>
)

const enhancer = compose(
  withProps((props) => {}),
  withHandlers({
    onSubmit: (props) => () => {
      props.onWorkNotiDoc()
      props.onClose()
    },
  })
)
export default enhancer(ConfirmModal)
