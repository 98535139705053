import React from "react"
import styled from "@emotion/styled"
import { compose, withProps } from "recompose"
import { withRouter, withStores } from "@enhancers/index"
import DebtProtectionForm from "./DebtProtectionForm/index"
import EducationForm from "./EducationForm/index"
import SavingForm from "./SavingForm/index"
import TaxReducerForm from "./TaxReducerForm/index"
import RetirementForm from "./RetirementForm/index"
import ProtectIncom from "./FamilyIncomeProtectionForm/index"
import { BreadCrumb } from "@components"
import * as path from "@common/path"

const Layout = styled.div`
  margin: 0px auto 25px auto;
`
const BreadCrumbLayout = styled.div`
  width: 110%;
`
const Planning = (props) => (
  <Layout>
    <BreadCrumbLayout>
      <BreadCrumb links={props.links} />
    </BreadCrumbLayout>
    {props.match.params.type === "family" && <DebtProtectionForm />}
    {props.match.params.type === "education" && <EducationForm />}
    {props.match.params.type === "protectIncome" && <ProtectIncom />}
    {props.match.params.type === "saving" && <SavingForm />}
    {props.match.params.type === "tax" && <TaxReducerForm />}
    {props.match.params.type === "retirement" && <RetirementForm />}
  </Layout>
)

const enhancer = compose(
  withRouter(),
  withStores((stores) => ({
    batchId: stores.leadStore.$("current.leadBatchId"),
  })),
  withProps((props) => ({
    ...props.location.query,
    leadId: props.location.query.leadId,
    linkLabel: props.match.params.type,
  })),
  withProps((props) => {
    let planningLabel = ""

    if (props.linkLabel === "family") {
      planningLabel = "วางแผนปกป้องภาระที่อาจจะตกถึงครอบครัว"
    } else if (props.linkLabel === "protectIncome") {
      planningLabel = "วางแผนเพื่อสร้างความคุ้มครองด้านรายได้ของครอบครัว"
    } else if (props.linkLabel === "retirement") {
      planningLabel = "วางแผนเงินเกษียณที่ใช้อยู่ดูแลเราไปจนแก่เฒ่า"
    } else if (props.linkLabel === "education") {
      planningLabel = "วางแผนคุ้มครองค่าใช้จ่ายการศึกษาบุตร"
    } else if (props.linkLabel === "tax") {
      planningLabel = "วางแผนลดหย่อนภาษีด้วยประกัน"
    } else if (props.linkLabel === "saving") {
      planningLabel = "วางแผนสะสมเงินพร้อมทุนประกัน"
    }

    return { planningLabel: planningLabel }
  }),
  withProps((props) => {
    const { from = "" } = props
    let links = []
    switch (from) {
      case "custom_lead":
        links = [
          { label: "ลูกค้าใหม่", path: path.leadsPath("custom") },
          {
            label: "รายละเอียดลีด",
            path: path.leadOrderInfoPath(props.leadId),
          },
          {
            label: "วางแผนค้นหาแบบประกัน",
            path: path.planningPath({
              leadId: props.leadId,
              birthdate: props.birthdate,
              gender: props.gender,
              from: props.from,
            }),
          },
          {
            label: props.planningLabel,
          },
        ]
        break
      case "batch_lead":
        links = [
          { label: "ลีดทีคิวเอ็ม", path: path.leadsPath("all") },
          {
            label: "รายละเอียดลีด",
            path: path.leadOrderInfoPath(props.leadId),
          },
          {
            label: "วางแผนค้นหาแบบประกัน",
            path: path.planningPath({
              leadId: props.leadId,
              birthdate: props.birthdate,
              gender: props.gender,
              from: props.from,
            }),
          },
          {
            label: props.planningLabel,
          },
        ]
        break
      default:
        links = [
          {
            label: "ผลิตภัณฑ์",
            path: "/",
          },
          {
            label: "วางแผนค้นหาแบบประกัน",
            path: path.planningPath({ from: props.from }),
          },
          {
            label: props.planningLabel,
          },
        ]
        break
    }
    return { links }
  })
)
export default enhancer(Planning)
