import React, { Fragment } from "react"
import { compose, withHandlers, lifecycle, withProps, withState } from "recompose"
import styled from "@emotion/styled"
import { Grid, GridColumn, DatePicker, makeCell, Field, Dropdown, Button, Input } from "@components"
import { withStores, withForm } from "@enhancers/index"
import * as path from "@common/path"
import DeleteModal from "./DeleteModal"
import AlertModal from "./AlertModal"
import ImportFileMoal from "./ImportFileModal"
import { Line } from "rc-progress"
import LeadMenuModal from "@pages/leads/index/LeadMenuModal"
import AssignModal from "@pages/leads/index/AssignModal"
import { isEqual, isEmpty, includes } from "lodash"
import { exceptNullKeys } from "@common/helper"

const Layout = styled.div`
  width: 100%;
  margin: 60px auto;
  overflow-x: hidden;
`
const Header = styled.div`
  display: flex;
  margin: 20px 0 12px 0;
  flex-wrap: wrap;
  width: 100%;
`
const Left = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;

  > div:not(:nth-of-type(1)) {
    margin-left: 16px;
  }

  > div > div {
    margin-bottom: unset !important;
  }
`
const Right = styled.div``
const Table = styled(Grid)`
  width: 100%;
  table {
    width: 100% !important;
  }
`
const LoadingRow = styled.tr``
const LoadingCol = styled.td`
  text-align: center !important;
  color: #007bff !important;
  width: 100%;
  > span {
    font-size: 22px;
    margin-left: 5px;
    margin-right: 95px;
  }
`
const WrapLoading = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const LableLoading = styled.div`
  color: #666666;
  height: 40px;
`
const LineCustom = styled(Line)`
  width: 55%;
  margin-bottom: 12px;
`
const DeleteButton = styled(Button)`
  margin-left: 6px;
  height: 38px;
  width: 52px;

  > img {
    filter: invert(1);
  }
`

const renderRow = (row, props) => {
  if (props.dataItem.state === "processing") {
    return (
      <LoadingRow>
        <LoadingCol colSpan={6}>
          <WrapLoading>
            <LableLoading>กำลังนำเข้าไฟล์</LableLoading>
            <LineCustom
              percent={props.dataItem.importedPercent}
              strokeWidth="2"
              strokeColor="#5cb85c"
            />
          </WrapLoading>
        </LoadingCol>
      </LoadingRow>
    )
  } else {
    return row
  }
}

const BatchIndexPage = (props) => (
  <Fragment>
    <Layout>
      <Header>
        <Left>
          <Field
            component={Dropdown}
            name="batchCode"
            caption="รหัสแบทช์"
            captionStyle={{ fontWeight: "normal" }}
            options={props.batchCodeOptions}
            leftWidth="72"
            rightWidth="200"
            placeholder="กรุณาเลือก"
          />
        </Left>
        <Right>
          <Button
            awesomeIcon="upload"
            onClick={props.onShowImportFileModal}
            children="นำเข้าไฟล์"
          />
        </Right>
      </Header>
      <Table
        // loading={props.loading}
        data={props.batches}
        onRowClick={props.onRowClick}
        pageable={{
          buttonCount: 5,
        }}
        skip={props.paging.skip}
        take={props.paging.perPage}
        total={props.paging.totalCount}
        onPageChange={props.onPageChange}
        rowRender={renderRow}
        // footer={`จ่ายแล้ว : ${props.footer.assignedLeadsCount} จากทั้งหมด ${props.paging.totalCount} ขายได้ : ${props.footer.orderedLeadsCount} ขายไม่ได้ : ${props.footer.unorderedLeadsCount}`}
      >
        <GridColumn
          field="batchCode"
          title="รหัสแบทช์"
          width="230px"
          cell={makeCell(Input, (cellProps) => ({
            readOnly: true,
            value: cellProps.dataItem.batchCode || "N/A",
            readOnlyStyle: {
              overflow: "hidden",
              display: "inline-block",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              verticalAlign: "middle",
              lineHeight: "33.5px",
            },
          }))}
        />
        <GridColumn
          field="state"
          title="สถานะ"
          width="95px"
          cell={(cell) => {
            const state = props.stateOptions.find(
              ({ label, value }) => cell.dataItem.state === value
            )
            return <td>{state.label}</td>
          }}
        />
        <GridColumn field="assignedNo" title="จ่ายครั้งที่" width="95px" />
        <GridColumn
          field="createdAt"
          title="วันที่นำเข้า"
          width="110px"
          cell={makeCell(DatePicker, (cellProps) => ({
            readOnly: true,
            value: cellProps.dataItem.createdAt,
          }))}
        />
        <GridColumn field="leadsCount" title="ลีดทั้งหมด" width="100px" />
        <GridColumn field="completedLeadsCount" title="ลีดที่ขายได้" width="110px" />
        <GridColumn field="assignedLeadsCount" title="ลีดที่มอบหมายแล้ว" width="150px" />
        <GridColumn field="unassignedLeadsCount" title="ลีดที่ยังไม่มอบหมาย" width="160px" />
        <GridColumn
          title=""
          width="80px"
          cell={(cell) => {
            return (
              <td>
                {cell.dataItem.canDelete && (
                  <DeleteButton
                    icon="delete"
                    onClick={() => props.toggleDeleteModal(cell.dataItem.id)}
                    styletype="delete"
                  />
                )}
              </td>
            )
          }}
        />
      </Table>
      <DeleteModal
        open={props.showDeleteModal}
        onClose={props.toggleDeleteModal}
        batchId={props.deleteBatchId}
      />
      <AlertModal
        title="นำเข้าไฟล์ล้มเหลว"
        message="การนำเข้าไฟล์ล้มเหลว โปรดตรวจสอบอีกครั้ง"
        show={props.isShowAlertModal}
        onOk={props.closeAlertModal}
        onCancel={props.closeAlertModal}
      />
      <ImportFileMoal
        open={props.isShowImportFileModal}
        onClose={props.onShowImportFileModal}
        onImport={props.onImport}
      />
      <LeadMenuModal
        open={props.toggleLeadModalValue}
        onClose={props.onCloseLeadMenuModal}
        gotoLead={props.gotoLead}
        gotoAssignLead={props.gotoAssignLead}
      />
      {props.toggleAssignModalValue && (
        <AssignModal
          form="LeadFilterForm"
          toggleValue={props.toggleAssignModalValue}
          setToggleModalValue={props.setToggleAssignModalValue}
          setMode={props.setMode}
          setSelectedLeadIds={props.setSelectedLeadIds}
          gotoLead={() => props.history.push(path.leadsPath(props.itemId))}
        />
      )}
    </Layout>
  </Fragment>
)

const enhancer = compose(
  withStores((stores) => ({
    importBatches: stores.batchStore.import,
    currentRole: stores.appStore.currentRole,
    fetchBatches: stores.batchStore.fetch,
    batches: stores.batchStore.all,
    fetchConfigs: stores.batchStore.fetchConfigs,
    paging: stores.batchStore.paging,
    batchCodeOptions: stores.batchStore.$("configs.filters.batchCode.options", []),
    stateOptions: stores.batchStore.$("configs.attributes.state.options", []),
    deleteBatch: stores.batchStore.deleteBatch,
    footer: stores.batchStore.footer,
    teamId: stores.appStore.$("currentSale.team.id"),
    saleId: stores.appStore.$("currentSale.id"),
  })),
  withState("batchId", "setBatchId", null),
  withState("intervalId", "setIntervalId", 0),
  withState("intervalTask", "setIntervalTask", 0),
  withState("isShowDeleteModal", "setIsShowDeleteModal", false),
  withState("isShowAlertModal", "setIsShowAlertModal", false),
  withState("loading", "setLoading", false),
  withState("isShowImportFileModal", "setIsShowImportFileModal", false),
  withState("toggleLeadModalValue", "setToggleLeadModalValue", false),
  withState("toggleAssignModalValue", "setToggleAssignModalValue", false),
  withState("itemId", "setItemId", null),
  withState("showDeleteModal", "setShowDeleteModal", false),
  withState("deleteBatchId", "setDeleteBatchId", 0),
  withProps((props) => ({
    paging: {
      ...props.paging,
      skip: (props.paging.page - 1) * props.paging.perPage,
    },
  })),
  withForm({ form: "batchForm" }),
  withHandlers({
    fetch: (props) => async () => {
      props.setLoading(true)
      const exceptNullParams = exceptNullKeys(props.values, "batchCode")
      await props.fetchBatches(exceptNullParams)
      props.setLoading(false)
    },
    unsubscribeProcessingBatches: (props) => () => {
      if (props.intervalTask) {
        clearInterval(props.intervalTask)
      }
      props.setIntervalTask(null)
    },
  }),
  withHandlers({
    subscribeProcessingBatches: (props) => () => {
      props.unsubscribeProcessingBatches()
      props.setIntervalTask(
        setInterval(() => {
          props.fetch()
        }, 2000)
      )
    },
    onRowClick: (props) => (event) => {
      if (event.dataItem.state === "completed") {
        if (["member.lead_admin", "member.biz_dev", "member.admin"].includes(props.currentRole)) {
          props.history.push(path.batchLeadsPath(event.dataItem.id))
        } else if (["member.team_leader", "member.salesman"].includes(props.currentRole)) {
          props.history.push(path.leadsPath(event.dataItem.id))
        }
      }
    },
    gotoLead: (props) => () => {
      props.history.push(path.leadsPath())
    },
    gotoAssignLead: (props) => () => {
      props.setToggleLeadModalValue(!props.toggleLeadModalValue)
      props.setToggleAssignModalValue(!props.toggleAssignModalValue)
    },
    onCloseLeadMenuModal: (props) => () => {
      props.setBatchId(null)
      props.setToggleLeadModalValue(!props.toggleLeadModalValue)
    },
    onCloseDeleteModal: (props) => (event) => {
      props.setIsShowDeleteModal(false)
    },
    closeAlertModal: (props) => () => {
      props.setIsShowAlertModal(false)
    },
    onShowImportFileModal: (props) => () => {
      props.setIsShowImportFileModal(!props.isShowImportFileModal)
    },
    onImport: (props) => async (params) => {
      await props.importBatches(params)
      await Promise.all([props.fetchConfigs(), props.fetch()])
    },
    onPageChange: (props) => async (event) => {
      const page = event.page.skip / event.page.take + 1
      props.setLoading(true)
      await props.fetchBatches({ ...props.values, page })
      props.setLoading(false)
    },
    toggleDeleteModal: (props) => (id) => {
      props.setDeleteBatchId(id)
      props.setShowDeleteModal(!props.showDeleteModal)
    },
  }),
  lifecycle({
    async componentDidMount() {
      await Promise.all([this.props.fetchConfigs(), this.props.fetch()])
    },
    componentDidUpdate(prevProps) {
      if (!isEmpty(this.props.batches)) {
        const hasProcessingBatch = !!this.props.batches.find((batch) =>
          includes(["new", "processing"], batch.state)
        )
        const hasSubscribeProcessingBatch = !!this.props.intervalTask
        const filterChanged = !isEqual(this.props.values, prevProps.values)

        if (filterChanged) {
          this.props.fetch()
        }

        if (hasProcessingBatch) {
          if (!hasSubscribeProcessingBatch || filterChanged) {
            this.props.subscribeProcessingBatches()
          }
        } else {
          this.props.unsubscribeProcessingBatches()
          if (hasSubscribeProcessingBatch) this.props.fetchConfigs()
        }
      }
    },
    componentWillUnmount() {
      this.props.unsubscribeProcessingBatches()
    },
  })
)

export default enhancer(BatchIndexPage)
