import React from "react"
import styled from "@emotion/styled"
import { compose, withHandlers, withState } from "recompose"
import { withForm, withRouter, withStores } from "@enhancers/index"
import { Button, Field, CurrencyInput, GenderInput, DatePicker } from "@components"
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout"
import * as path from "@common/path"
import { toCurrency, convertNaNtoZero, convertMinustoZero } from "@common/helper"

import {
  Body,
  Layout,
  Footer,
  ResultContainer,
  TextResult,
  ValueResult,
} from "../DebtProtectionForm/AdvanceForm"
import { WrapInputDate, AgeLabel } from "../DebtProtectionForm/SimpleForm"
import moment from "moment"

const CustomPanelBar = styled(PanelBar)`
  width: 100%;
  margin-top: 16px;
  border: unset;

  > div {
    > span.k-link.k-header {
      background-color: #627b8b;
      border-color: #627b8b;
      color: #fff;
      :hover {
        background-color: #627b8b;
        border-color: #627b8b;
        color: #fff;
      }
    }
    > span.k-link.k-header.k-state-selected {
      border: unset;
      box-shadow: unset;
      background-color: #627b8b;
      border-color: #627b8b;
      color: #fff;
    }
    > span.k-link.k-header.k-state-selected.k-state-focused {
      border: unset;
      box-shadow: unset;
      background-color: #627b8b;
      border-color: #627b8b;
      color: #fff;
    }
    > div {
      z-index: unset;
      > div {
        margin: 16px 15px 12px 15px;
        > div {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          > div {
            > div {
              display: flex;
              justify-content: space-between;
              margin-top: 16px;
            }
            :nth-of-type(odd) {
              width: 80%;
              > div {
                > div:first-of-type {
                  height: 50px;
                  margin-right: 18px;
                }
              }
            }
            :nth-of-type(even) {
              padding-top: 16px;
              margin-left: 6px;
            }
          }
        }
      }
    }
  }
`

const AgeAndGenderLayout = styled.div`
  display: flex;
  width: 500px;
  padding: 16px 15px 12px 15px;
  flex-wrap: wrap;
  > div {
    :nth-of-type(odd) {
      display: flex;
      justify-content: space-between;
      width: 80%;
      margin-top: 16px;
      > div {
        > div {
          line-height: 24px;
        }
      }
    }
    :nth-of-type(even) {
      margin-top: 16px;
      display: flex;
      align-items: center;
      margin-left: 6px;
    }
  }

  width: 100%;
`

const ConfirmButton = styled(Button)`
  display: flex;
  margin: auto;
  width: fit-content;
  margin-top: 16px;
`
const Unit = styled.div``

const AgenUnit = styled.div`
  width: 29px;
`
const UnitWrap = styled.div`
  word-wrap: break-word;
  width: 91px;
  margin-top: unset !important;
`
const UntiContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: -3px;
`

const Planning = (props) => (
  <Layout>
    <Body>
      <AgeAndGenderLayout>
        <Field
          component={GenderInput}
          name="gender"
          caption="เพศ"
          rightWidth="200"
          placeholder="กรุณาระบุ"
          validate="required"
        />
        <AgenUnit />
        <WrapInputDate>
          {props.values.birthdate && (
            <AgeLabel show={props.values.birthdate !== null}>
              (อายุ {moment().diff(moment(props.values.birthdate), "years")} ปี)
            </AgeLabel>
          )}
          <Field
            caption="วันเกิด"
            name="birthdate"
            component={DatePicker}
            placeholder="กรุณาระบุ"
            validate="required"
            rightWidth="200"
            leftWidth="200"
            max={new Date()}
          />
        </WrapInputDate>
        <AgenUnit />
      </AgeAndGenderLayout>
      <CustomPanelBar>
        <PanelBarItem expanded={true} title="สมมติฐาน">
          <Field
            component={CurrencyInput}
            name="inflationRate"
            caption="ณ อัตราเงินเฟ้อ"
            rightWidth="200"
            placeholder="กรุณาระบุ"
            min={0}
            max={100}
            onChange={props.makeOnCalculate("inflationRate")}
          />
          <Unit>% ต่อปี</Unit>
          <Field
            component={CurrencyInput}
            name="presentCost"
            caption="ค่าใช้จ่ายปัจจุบัน"
            rightWidth="200"
            placeholder="กรุณาระบุ"
            min={0}
            onChange={props.makeOnCalculate("presentCost")}
          />
          <Unit>บาท/เดือน</Unit>
          <Field
            component={CurrencyInput}
            name="expectedRetireAge"
            caption="อายุที่คาดว่าจะเกษียณ"
            rightWidth="200"
            placeholder="กรุณาระบุ"
            min={0}
            max={100}
            onChange={props.makeOnCalculate("expectedRetireAge")}
          />
          <Unit>ปี</Unit>
          <Field
            component={CurrencyInput}
            name="costRateAfterRetire"
            caption="อัตราส่วนการใช้จ่ายหลังเกษียณ เทียบกับปัจจุบัน"
            rightWidth="200"
            placeholder="กรุณาระบุ"
            min={0}
            max={100}
            onChange={props.makeOnCalculate("costRateAfterRetire")}
          />
          <UnitWrap>% ของค่าใช้จ่ายในปัจจุบัน</UnitWrap>
          <Field
            component={CurrencyInput}
            name="yearBeforeRetire"
            caption="จำนวนปีก่อนเกษียณ"
            rightWidth="200"
            readOnly
          />
          <Unit>ปี</Unit>
          <Field
            component={CurrencyInput}
            name="expectedDeadAge"
            caption="อายุคาดหมาย"
            rightWidth="200"
            placeholder="กรุณาระบุ"
            min={0}
            max={100}
            onChange={props.makeOnCalculate("expectedDeadAge")}
          />
          <Unit>ปี</Unit>
        </PanelBarItem>
        <PanelBarItem title={"คาดการณ์ช่วงเกษียณ"}>
          <Field
            component={CurrencyInput}
            name="costAfterRetire"
            caption="ค่าใช้จ่ายในช่วงเกษียณอายุ ณ วันที่เริ่มเกษียณ"
            rightWidth="200"
            readOnly
          />
          <UntiContainer>
            <Unit>บาท/เดือน</Unit>
            {/* <SmallUnit>ที่อัตราเงินเฟ้อ 2% ต่อปี</SmallUnit> */}
          </UntiContainer>
          <Field
            component={CurrencyInput}
            name="incomeRate"
            caption="สัดส่วนรายได้ที่จำเป็นต่อรายได้ทั้งหมด"
            rightWidth="200"
            placeholder="กรุณาระบุ"
            min={0}
            max={100}
            onChange={props.makeOnCalculate("incomeRate")}
          />
          <Unit>%</Unit>
          <Field
            component={CurrencyInput}
            name="costRequired"
            caption="ค่าใช้จ่ายจำเป็น"
            rightWidth="200"
            placeholder="กรุณาระบุ"
            min={0}
            max={props.costRequiredHighest}
            onChange={props.makeOnCalculate("costRequired")}
          />
          <Unit>บาท/เดือน</Unit>
          <Field
            component={CurrencyInput}
            name="costAtDeadAge"
            caption="ค่าใช้จ่ายจำเป็น ณ อายุคาดหมาย"
            rightWidth="200"
            readOnly
          />
          <Unit>บาท/เดือน</Unit>
        </PanelBarItem>
        <PanelBarItem title={"เตรียมพร้อมค่าใช้จ่าย"}>
          <Field
            component={CurrencyInput}
            name="costShouldPrepared"
            caption="ค่าใช้จ่ายจำเป็นที่ควรเตรียมไว้"
            rightWidth="200"
            readOnly
          />
          <Unit>บาท/เดือน</Unit>
          <Field
            component={CurrencyInput}
            name="pension"
            caption="เงินบำนาญที่คาดว่าจะได้รับ"
            rightWidth="200"
            placeholder="กรุณาระบุ"
            min={0}
            onChange={props.makeOnCalculate("pension")}
          />
          <Unit>บาท/เดือน</Unit>
          <Field
            component={CurrencyInput}
            name="etcIncome"
            caption="เงินได้ประจำอื่นที่คาดว่าจะได้รับ"
            rightWidth="200"
            placeholder="กรุณาระบุ"
            min={0}
            onChange={props.makeOnCalculate("etcIncome")}
          />
          <Unit>บาท/เดือน</Unit>
        </PanelBarItem>
      </CustomPanelBar>
    </Body>
    <Footer>
      <ResultContainer>
        <TextResult>
          เงินบำนาญที่คุณต้องการน้อยกว่าขั้นต่ำของแบบประกันที่สามารถทำได้โดยทั่วไป
          เราจะค้นหาแบบประกันให้คุณโดยเลือกจากเงินบำนาญขั้นต่ำที่ 60,000 บาท/ปี แทน
        </TextResult>
        <ValueResult>
          {toCurrency(convertMinustoZero(convertNaNtoZero(props.values.finalSummary)))} บาท
        </ValueResult>
      </ResultContainer>
      <ConfirmButton
        icon="arrow-right"
        onClick={props.onSubmit}
        disabled={props.values.finalSummary < 0}
      >
        ถัดไป
      </ConfirmButton>
    </Footer>
  </Layout>
)

const enhancer = compose(
  withRouter({
    queryTypes: {
      birthdate: "date",
    },
  }),
  withStores((stores, props) => ({
    fetchById: stores.leadStore.fetchById,
    initialValues: {
      birthdate: props.location.query.birthdate,
      gender: props.location.query.gender,
    },
  })),
  withForm({
    form: "planningRetireAdvanceForm",
    enableReinitialize: true,
  }),
  withState("costRequiredHighest", "setCostRequiredHighest", 0),
  withHandlers({
    makeOnCalculate: (props) => (name) => (value) => {
      const values = props.values
      values[name] = value

      let inflationRateCal = convertNaNtoZero(values.inflationRate)
      let presentCostCal = convertNaNtoZero(values.presentCost)
      let expectedRetireAgeCal = convertNaNtoZero(values.expectedRetireAge)
      let costRateAfterRetireCal = convertNaNtoZero(values.costRateAfterRetire)
      let expectedDeadAgeCal = convertNaNtoZero(values.expectedDeadAge)
      let incomeRateCal = convertNaNtoZero(values.incomeRate)
      let costRequiredCal = convertNaNtoZero(values.costRequired)
      let pensionCal = convertNaNtoZero(values.pension)
      let etcIncomeCal = convertNaNtoZero(values.etcIncome)

      let firtSummary = expectedRetireAgeCal - 25
      let secondSummary = Math.round(
        ((presentCostCal * costRateAfterRetireCal) / 100) *
          Math.pow(1 + inflationRateCal / 100, firtSummary)
      )
      let costRequiredResult = (secondSummary * incomeRateCal) / 100
      let thirdSummary = parseFloat(
        costRequiredResult *
          Math.pow(1 + inflationRateCal / 100, expectedDeadAgeCal - expectedRetireAgeCal)
      )
      let forthSummary =
        ((secondSummary * (Math.round((costRequiredCal * 100) / secondSummary) * 100)) /
          100 /
          100) *
        Math.pow(1 + inflationRateCal / 100, expectedDeadAgeCal - expectedRetireAgeCal)
      let forthSummaryHighest = convertNaNtoZero(
        ((secondSummary *
          (Math.round((((secondSummary * 100) / 100) * 100) / secondSummary) * 100)) /
          100 /
          100) *
          Math.pow(1 + inflationRateCal / 100, expectedDeadAgeCal - expectedRetireAgeCal)
      )

      let percentSummary = convertNaNtoZero(((costRequiredCal * 100) / secondSummary) * 100) / 100
      let tempSummary = thirdSummary
      props.setCostRequiredHighest(Math.round(secondSummary))
      props.change("yearBeforeRetire", convertNaNtoZero(firtSummary < 0 ? 0 : firtSummary))
      props.change("costAfterRetire", convertNaNtoZero(secondSummary))

      if (name !== "incomeRate" && name === "costRequired") {
        if (percentSummary > 100) {
          props.change("incomeRate", 100)
          props.change("costAtDeadAge", forthSummaryHighest)
          props.change("costShouldPrepared", forthSummaryHighest)
        } else {
          props.change("incomeRate", percentSummary)
          props.change("costAtDeadAge", convertNaNtoZero(forthSummary))
          props.change("costShouldPrepared", convertNaNtoZero(forthSummary))
        }
        tempSummary = forthSummary
      }
      if (name !== "costRequired" && name === "incomeRate") {
        props.change("costRequired", convertNaNtoZero(costRequiredResult))
        props.change("costAtDeadAge", convertNaNtoZero(thirdSummary))
        props.change("costShouldPrepared", convertNaNtoZero(thirdSummary))
        tempSummary = thirdSummary
      }
      props.change("finalSummary", convertNaNtoZero((tempSummary - pensionCal - etcIncomeCal) * 12))
    },
    onSubmit: (props) =>
      props.handleSubmit(async (values) => {
        let finalSummary = convertNaNtoZero(values.finalSummary)

        const { query } = props.location || {}
        const { leadId } = query || {}

        props.history.push(
          path.productsPath(leadId, {
            ...props.location.query,
            birthdate: values.birthdate,
            gender: values.gender,
            searchValue: finalSummary <= 60000 ? 60000 : finalSummary,
            type: "whole_life",
            searchBy: "sum_insured",
          })
        )
      }),
  })
)
export default enhancer(Planning)
