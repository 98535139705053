import BaseStore from "./BaseStore"
import { tqldServer, TQLD_AUTH_TOKEN_KEY, TQLD_CURRENT_MEMBER_ID_KEY } from "@common/api"
import { safe } from "@common/helper"
import LogRocket from "logrocket"
import { env } from "@env"
import orderStore from "./orderStore"

export const rolesInfo = {
  user: {
    admin: {
      name: "Super Admin",
    },
  },
  member: {
    admin: { name: "ผู้ดูแลส่วนผู้ใช้งาน" },
    policy_admin: { name: "ผู้ดูแลส่วนแบบประกัน" },
    lead_admin: { name: "ผู้ดูแลลีด" },
    biz_dev: { name: "ผู้ดูแลส่วนเอกสาร" },
    team_leader: { name: "หัวหน้าพนักงานขาย" },
    salesman: { name: "พนักงานขาย" },
    accountant: { name: "บัญชีการเงิน" },
    accountant_approver: { name: "บัญชีการเงิน" },
    affiliate: { name: "ผู้แนะนำ" },
    brokerage_admin: { name: "ผู้ดูแลนายหน้า" },
  },
  notifications: [],
}

class AppStore extends BaseStore {
  constructor() {
    super({
      token: localStorage.getItem(TQLD_AUTH_TOKEN_KEY),
      currentMemberId: localStorage.getItem(TQLD_CURRENT_MEMBER_ID_KEY),
      currentUser: {},
      roles: [],
      currentRole: null,
      currentSale: null,
      notifications: [],
    })
  }

  changeCurrentMember = async (memberId) => {
    const currentRole = this.currentUser.brokers[0].members.find((member) => member.id === memberId)
    localStorage.setItem(`${TQLD_AUTH_TOKEN_KEY}-CURRENT_ROLE`, currentRole.type)

    this.currentMemberId = memberId
    localStorage.setItem(TQLD_CURRENT_MEMBER_ID_KEY, memberId)

    if (currentRole.type === "member.team_leader" || currentRole.type === "member.salesman") {
      this.currentSale = safe(() =>
        this.currentUser.brokers[0].members.find(
          (member) =>
            (currentRole.type === "member.team_leader" || currentRole.type === "member.salesman") &&
            member.type === currentRole.type
        )
      )
    }

    window.location.reload()
  }

  changeCurrentRole = async (role) => {
    this.currentRole = role
    localStorage.setItem(`${TQLD_AUTH_TOKEN_KEY}-CURRENT_ROLE`, role)

    const currentMember = safe(() =>
      this.currentUser.brokers[0].members.find((member) => member.type === role)
    )

    if (currentMember) {
      localStorage.setItem(TQLD_CURRENT_MEMBER_ID_KEY, currentMember.id)
      this.currentMemberId = currentMember.id
    } else {
      localStorage.setItem(TQLD_CURRENT_MEMBER_ID_KEY, null)
      this.currentMemberId = null
    }

    if (
      role === "member.team_leader" ||
      role === "member.salesman" ||
      role === "member.affiliate"
    ) {
      this.currentSale = safe(() =>
        this.currentUser.brokers[0].members.find(
          (member) =>
            (role === "member.team_leader" ||
              role === "member.salesman" ||
              role === "member.affiliate") &&
            member.type === role
        )
      )
    }
  }

  authenticate = async (values, callback = () => {}) => {
    let response = null

    if (!values) {
      const storageToken = localStorage.getItem(TQLD_AUTH_TOKEN_KEY)
      const token = this.token || storageToken
      if (token) {
        response = await tqldServer.tqmBackend.get("/auth/current_user")
      } else {
        return this.logout()
      }
    } else {
      response = await tqldServer.tqmBackend.post("/auth/sign_in", values)
    }

    this.token = response.data.data.token
    this.currentUser = response.data.data.currentUser

    localStorage.setItem(TQLD_AUTH_TOKEN_KEY, this.token)

    // NOTE: ถ้าเอาออกต้องบอก Mewshi ด้วย

    // NOTE: Hot Fix when api change role name not expose isLeader
    // const sales = await saleStore.fetchSaleList()
    const typeRoleMapping = {
      Salesman: "member.salesman",
      SalesLeader: "member.team_leader",
      PolicyAdmin: "member.policy_admin",
      MemberAdmin: "member.admin",
      LeadAdmin: "member.lead_admin",
      BizDev: "member.biz_dev",
      Accountant: "member.accountant",
      AccountantApprover: "member.accountant_approver",
      Affiliate: "member.affiliate",
      BrokerageAdmin: "member.brokerage_admin",
    }

    const roles = []

    if (this.currentUser.roles) {
      this.currentUser.roles.forEach((role) => {
        roles.push(`user.${role}`)
      })
    }

    if (this.currentUser.brokers) {
      this.currentUser.brokers.forEach((broker) => {
        broker.members.forEach((member) => {
          // const salesman = sales.find(sale => sale.id === member.id)
          const salesman = {
            isLeader: this.currentUser.memberInfo.isLeader,
            team: {
              id: this.currentUser.memberInfo.teamId,
            },
          }

          if (
            member.type === "Salesman" ||
            member.type === "SalesLeader" ||
            member.type === "Affiliate"
          ) {
            if (salesman.isLeader) {
              member.isLeader = salesman.isLeader
              member.type = "member.team_leader"
            } else {
              member.type = typeRoleMapping[member.type]
            }
            member.team = salesman.team
          } else {
            member.type = typeRoleMapping[member.type]
          }
          roles.push(member.type)
        })
      })
    }

    if (!this.currentRole) {
      this.currentRole = localStorage.getItem(`${TQLD_AUTH_TOKEN_KEY}-CURRENT_ROLE`)
    }

    this.roles = roles
    if (!this.roles.includes(this.currentRole)) {
      this.changeCurrentRole(this.roles[0])
    } else {
      this.changeCurrentRole(this.currentRole)
    }

    LogRocket.identify(this.currentUser.id, {
      username: this.currentUser.username,
      name: this.currentUser.nameThInfo.firstName,
      role: this.currentRole,
      environment: env,
    })

    callback()
  }

  fetchNotifications = async () => {
    const response = await tqldServer.tqmBackend.get("/notifications")
    this.notifications = response.data.data.notifications
    return response.data.data.notifications
  }

  updateNotifications = async (id) => {
    await tqldServer.tqmBackend.patch(`/notifications/${id}/read`)
  }

  viewNotifications = async (ids) => {
    await tqldServer.tqmBackend.patch(`/notifications/view`, { ids })
  }

  logout = async () => {
    localStorage.removeItem(TQLD_AUTH_TOKEN_KEY)
    localStorage.removeItem(TQLD_CURRENT_MEMBER_ID_KEY)
    localStorage.removeItem(`${TQLD_AUTH_TOKEN_KEY}-EMAIL`)
    localStorage.removeItem(`${TQLD_AUTH_TOKEN_KEY}-PASSWORD`)
    localStorage.removeItem(`${TQLD_AUTH_TOKEN_KEY}-CURRENT_ROLE`)
    sessionStorage.removeItem("formAGT")

    this.token = null
    this.currentMemberId = null
    this.currentUser = {}
    this.roles = []
    this.currentRole = null
    this.currentSale = null

    orderStore.clearStore()
  }
}

export default new AppStore()
