/* eslint-disable no-dupe-keys */
import React, { useMemo } from "react"
import styled from "@emotion/styled"
import { withStores, withRouter, withForm } from "@enhancers"
import {
  Grid,
  GridColumn,
  Button,
  Header as TextHeader,
  Checkbox,
  BreadCrumb,
  DatePicker,
  makeCell,
  Dropdown,
  makeHeaderCell,
  FilterButton,
} from "@components"
import { compose, withHandlers, withState, lifecycle, withProps } from "recompose"
import * as path from "@common/path"
import SuccessModal from "./SuccessModal"
import { FieldArray } from "redux-form"
import SalesmanExcepted from "./SalesmanExcepted"
import { isEqual, isEmpty, cloneDeep } from "lodash"
import { exceptNullKeys, toCurrency } from "../../../common/helper"
import { Layout } from "./index"
import OrderReportFilter from "./Filter/OrderReportFilter"
import MemberReportFilter from "./Filter/MemberReportFilter"
import AffiliateReportFilter from "./Filter/AffiliateReportFilter"

const BreadCrumbLayout = styled.div``
const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 12px;
  margin-top: 24px;
  justify-content: space-between;
`
const CustomTextHeader = styled(TextHeader)`
  display: flex;
  align-items: center;
  color: #666666;
`
const WrapButton = styled.div`
  display: flex;
  button {
    :first-of-type {
      margin-right: 6px;
    }
  }
`
const BackButton = styled(Button)`
  width: 116px;

  .k-icon {
    font-size: 20px;
    margin-right: 6px;
  }
`
const ReportButton = styled(Button)`
  width: 180px;
  justify-content: flex-end;

  .k-icon {
    font-size: 20px;
    margin-right: 8px;
  }
`
const Table = styled(Grid)`
  .k-grid-header {
    .k-header {
      font-weight: bold;
      > a {
        padding: 12px !important;
      }
    }
  }

  .k-grid-container {
    .k-grid-content.k-virtual-content {
      > div {
        .k-grid-table {
          > tbody {
            .k-master-row {
              height: 50px;
              > td {
                cursor: pointer;
                padding: 0 12px;
              }
            }
            .k-master-row.k-alt {
              > td {
                cursor: pointer;
                padding: 0 12px;
              }
            }
          }
        }
      }
    }
  }
  .wrap-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
const CustomCheckbox = styled(Checkbox)`
  .k-item.k-treeview-item {
    height: 26px !important;
    padding-left: 5px;
    padding-bottom: 5px;
  }
`
const CustomTd = styled.td`
  position: sticky;
  left: 0;
  padding: 12px;
  border-right-width: 1px;
  background-color: #f8f9fa;
`
const CustomFilterButton = styled(FilterButton)`
  > button {
    width: 143px;
    margin-bottom: 20px;
    > .k-icon {
      margin: unset;
      margin-right: 12px;
      font-size: 19px;
    }
  }
`
const SalesmanExceptContainer = styled.div`
  background-color: white;
  min-width: 678px;
  margin-bottom: 24px;
  border: 1px solid #eeeef2;
  border-radius: 4px;
  opacity: 1;
`

const SaleCommissionsReportPreparation = (props) => {
  const renderTextHeader = useMemo(() => {
    if (props.groupBy === "order")
      return <CustomTextHeader>คอมมิชชั่นจ่ายแยกตามรหัสคำสั่งซื้อ</CustomTextHeader>
    else if (props.groupBy === "salesman")
      return <CustomTextHeader>คอมมิชชั่นจ่ายแยกตามพนักงาน</CustomTextHeader>
    else if (props.groupBy === "affiliate")
      return <CustomTextHeader>คอมมิชชั่นจ่ายแยกตามทีมผู้แนะนำ</CustomTextHeader>
  }, [props.groupBy])

  const renderReportFilter = useMemo(() => {
    if (props.groupBy === "order") return <OrderReportFilter values={props.values} />
    else if (props.groupBy === "salesman") return <MemberReportFilter values={props.values} />
    else if (props.groupBy === "affiliate") return <AffiliateReportFilter values={props.values} />
  }, [props.groupBy, props.values])

  const renderTable = useMemo(() => {
    if (props.groupBy === "order")
      return (
        <Table loading={props.loading} data={props.salesCommissions} total={1} pageable>
          <GridColumn
            title=""
            width="42px"
            locked
            headerCell={makeHeaderCell(CustomCheckbox, () => ({
              value: props.isSelectedAll,
              onChange: (checked) => props.onSelectAll(checked),
            }))}
            cell={(cellProps) => {
              return (
                <CustomTd className={`k-grid-content-sticky`}>
                  <Checkbox
                    value={cellProps.dataItem.selected}
                    onChange={(checked) => props.onSelectRow(checked, cellProps.dataItem)}
                  />
                </CustomTd>
              )
            }}
          />
          <GridColumn
            title="วันที่จ่าย"
            cell={makeCell(DatePicker, (props) => ({
              readOnly: true,
              value: props.dataItem.commissionReceivedDate,
            }))}
            width="108px"
          />
          <GridColumn field="staffCode" title="รหัสพนักงาน" width="110px" />
          <GridColumn field="salesmanName" title="ชื่อพนักงาน" width="113px" />
          <GridColumn field="teamName" title="ทีมขาย" width="102px" />
          <GridColumn field="tqmOrderId" title="รหัสคำสั่งซื้อ" width="108px" />
          <GridColumn field="insuranceCompanyName" title="บริษัทประกัน" width="182px" />
          <GridColumn field="policyName" title="แบบประกัน" width="197px" />
          <GridColumn field="paymentMethod" title="การชำระเงิน" width="115px" />
          <GridColumn field="policyNumber" title="เลขกรมธรรม์" width="112px" />
          <GridColumn field="customerName" title="ชื่อลูกค้า" width="187px" />
          <GridColumn
            title="วันที่แจ้งงาน"
            cell={makeCell(DatePicker, (props) => ({
              readOnly: true,
              value: props.dataItem.submittedAt,
            }))}
            width="108px"
          />
          <GridColumn
            title="วันที่คุ้มครอง"
            cell={makeCell(DatePicker, (props) => ({
              readOnly: true,
              value: props.dataItem.coverageDate,
            }))}
            width="115px"
          />
          <GridColumn
            title="เบี้ยประกัน"
            cell={(cell) => <td>{toCurrency(cell.dataItem.premium)}</td>}
            width="100px"
          />
          <GridColumn field="policyStatus" title="สถานะกรมธรรม์" width="144px" />
          <GridColumn field="executionCode" title="รหัสรายการ" width="105px" />
          <GridColumn
            title="ยอดจ่าย"
            cell={(cell) => <td>{toCurrency(cell.dataItem.commissionAmount)}</td>}
            width="94px"
          />
          <GridColumn field="reclaimAmount" title="เรียกคืน" width="97px" />
        </Table>
      )
    else if (props.groupBy === "salesman")
      return (
        <Table loading={props.loading} data={props.salesCommissions} total={1} pageable>
          <GridColumn
            title=""
            width="42px"
            locked
            headerCell={makeHeaderCell(CustomCheckbox, () => ({
              value: props.isSelectedAll,
              onChange: (checked) => props.onSelectAll(checked),
            }))}
            cell={(cellProps) => {
              return (
                <CustomTd className={`k-grid-content-sticky`}>
                  <Checkbox
                    value={cellProps.dataItem.selected}
                    onChange={(checked) => props.onSelectRow(checked, cellProps.dataItem)}
                  />
                </CustomTd>
              )
            }}
          />
          <GridColumn field="staffCode" title="รหัสพนักงาน" width="110px" />
          <GridColumn field="fullName" title="ชื่อพนักงาน" width="113px" />
          <GridColumn field="teamName" title="ทีมขาย" width="119px" />
          <GridColumn
            title="คอมมิชชั่น"
            cell={(cell) => <td>{toCurrency(cell.dataItem.totalCommissionAmountWaiting)}</td>}
            width="100px"
          />
          <GridColumn
            title="Incentive"
            cell={(cell) => <td>{toCurrency(cell.dataItem.incentive)}</td>}
            width="92px"
          />
          <GridColumn
            title="รายได้รวม"
            cell={(cell) => <td>{toCurrency(cell.dataItem.totalCommissionAmount)}</td>}
            width="120px"
          />
          <GridColumn
            title="สถานะพนักงาน"
            cell={makeCell(Dropdown, (cellProps) => ({
              readOnly: true,
              value: cellProps.dataItem.memberStatus,
              options: props.memberStatusOptions,
            }))}
            width="129px"
          />
        </Table>
      )
    else if (props.groupBy === "affiliate")
      return (
        <Table loading={props.loading} data={props.salesCommissions} total={1} pageable>
          <GridColumn
            title=""
            width="42px"
            locked
            headerCell={makeHeaderCell(CustomCheckbox, () => ({
              value: props.isSelectedAll,
              onChange: (checked) => props.onSelectAll(checked),
            }))}
            cell={(cellProps) => {
              return (
                <CustomTd className={`k-grid-content-sticky`}>
                  <Checkbox
                    value={cellProps.dataItem.selected}
                    onChange={(checked) => props.onSelectRow(checked, cellProps.dataItem)}
                  />
                </CustomTd>
              )
            }}
          />
          <GridColumn title="ชื่อทีมผู้แนะนำ" field="affiliateTeamName" width="150px" />
          <GridColumn title="พื้นที่" field="areaCode" width="90px" />
          <GridColumn
            title="สาขา"
            width="120px"
            cell={makeCell(Dropdown, (cell) => ({
              readOnly: true,
              value: cell.dataItem.branchCode,
              options: props.branchOptions,
            }))}
          />
          <GridColumn title="รหัสหัวหน้าผู้แนะนำ" field="leaderStaffCode" width="160px" />
          <GridColumn title="ชื่อหัวหน้าผู้แนะนำ" field="leaderName" width="180px" />
          <GridColumn
            title="วันที่รับเงิน (ล่าสุด)"
            cell={makeCell(DatePicker, (cell) => ({
              readOnly: true,
              value: cell.dataItem.receivedDate,
            }))}
            width="150px"
          />
          <GridColumn
            title="ค่าแนะนำรวม"
            cell={(cell) => <td>{toCurrency(cell.dataItem.totalCommissionAmount)}</td>}
            width="120px"
          />
          <GridColumn
            title="ค่าแนะนำจ่ายแล้ว"
            cell={(cell) => <td>{toCurrency(cell.dataItem.totalCommissionAmountPaid)}</td>}
            width="150px"
          />
          <GridColumn
            title="ค่าแนะนำรอทำจ่าย"
            cell={(cell) => <td>{toCurrency(cell.dataItem.totalCommissionAmountWaiting)}</td>}
            width="160px"
          />
        </Table>
      )
  }, [props.groupBy, props.salesCommissions])

  return (
    <Layout>
      <BreadCrumbLayout>
        <BreadCrumb
          links={[
            {
              label: "คอมมิชชั่นจ่าย",
              path: path.saleCommissionsPath(),
            },
            { label: "ออกรายงานคอมมิชชั่นจ่าย", path: path.saleCommissionsReportPreparationPath() },
          ]}
        />
      </BreadCrumbLayout>
      <Header>
        {renderTextHeader}
        <WrapButton>
          <BackButton
            icon="arrow-chevron-left"
            onClick={props.onBack}
            children="ย้อนกลับ"
            styletype="secondary"
          />
          <ReportButton
            awesomeIcon="file-export"
            onClick={props.onReport}
            children="ยืนยันออกรายงาน"
            disabled={props.salesCommissions.length === 0}
          />
        </WrapButton>
      </Header>

      <CustomFilterButton
        expanded={props.filterExpanded}
        onExpand={props.onExpandFilter}
        disabled={props.disabledFilter}
      >
        {renderReportFilter}
      </CustomFilterButton>

      {/* <SalesmanExceptContainer>
      <FieldArray
        name="ignoreSalesNames"
        component={SalesmanExcepted}
        options={props.salesNameOptions}
        isOrder={props.location.query.groupBy === "order" ? true : false}
      />
    </SalesmanExceptContainer> */}
      {renderTable}

      <SuccessModal
        open={props.openSuccessModal}
        onClose={props.closeModal}
        data={props.reportData}
      />
    </Layout>
  )
}

const enhancer = compose(
  withRouter(),
  withState("fetchLeadTask", "setFetchLeadTask", null),
  withState("openSuccessModal", "setOpenSuccessModal", false),
  withState("selectedLeadIds", "setSelectedLeadIds", []),
  withState("filterExpanded", "setFilterExpanded", true),
  withState("filterParams", "setFilterParams", null),
  withStores((stores) => ({
    salesCommissions: stores.saleCommissionStore.all,
    fetchCommissionOrders: stores.saleCommissionStore.fetchSalesCommissionOrders,
    fetchCommission: stores.saleCommissionStore.fetch,
    fetchAffiliates: stores.saleCommissionStore.fetchSalesCommissionAffiliates,
    export: stores.saleCommissionStore.export,
    exportOrders: stores.saleCommissionStore.exportOrders,
    exportAffiliate: stores.saleCommissionStore.exportAffiliate,
    fetchConfig: stores.saleCommissionStore.fetchConfig,
    fetchConfigOrders: stores.saleCommissionStore.fetchSalesCommissionOrdersConfig,
    fetchAffiliatesConfig: stores.saleCommissionStore.fetchSalesCommissionAffiliatesConfig,
    memberStatusOptions: stores.saleCommissionStore.$(
      "configs.attributes.memberStatus.options",
      []
    ),
    insuranceCompanyOptions: stores.saleCommissionStore.$(
      "configs.filters.insuranceCompany.options",
      []
    ),
    branchOptions: stores.saleCommissionStore.$("configs.attributes.branch.options", []),
  })),
  withProps((props) => {
    let salesCommissions = props.salesCommissions
    salesCommissions.forEach(
      (commission) => (commission.selected = props.selectedLeadIds.includes(commission.id))
    )
    return {
      salesCommissions,
      exportedDate: props.location.query.exportedDate,
      exportedType: props.location.query.exportedType,
      groupBy: props.location.query.groupBy,
    }
  }),
  withForm({
    form: "SalesCommissionReportPreparationForm",
    onChange: (filters, _, props) => {
      if (props.filterParams) {
        clearTimeout(props.filterParams)
      }

      const exceptNullParams = exceptNullKeys(
        filters,
        "tqmOrderId",
        "staffCode",
        "salesmanName",
        "customerName",
        "insuranceCompanyId",
        "submittedAt.min",
        "submittedAt.max",
        "coverageDate.min",
        "coverageDate.max",
        "memberStatus",
        "fullName"
      )

      const filterParams = setTimeout(async () => {
        await setTimeout(() => {
          if (props.groupBy === "order")
            props.fetchCommissionOrders({
              ...exceptNullParams,
              page: 1,
              perPage: 10000,
              exportedType: props.exportedType,
              groupBy: props.groupBy,
            })
          else if (props.groupBy === "salesman")
            props.fetchCommission({
              ...exceptNullParams,
              page: 1,
              perPage: 10000,
              exportedType: props.exportedType,
              groupBy: props.groupBy,
            })
          else if (props.groupBy === "affiliate")
            props.fetchAffiliates({
              ...exceptNullParams,
              page: 1,
              perPage: 10000,
              exportedType: props.exportedType,
              groupBy: props.groupBy,
            })
        })
      }, 600)

      props.setFilterParams(filterParams)
    },
  }),
  withProps((props) => {
    const reportDate = props.exportedDate
    let dataType = ""
    if (props.exportedType === "calculate_commission") {
      dataType = "คำนวณคอมมิชชั่น"
    } else if (props.exportedType === "sales_commission") {
      if (props.groupBy === "affiliate") {
        dataType = "คอมมิชชั่นจ่ายทีมผู้แนะนำ"
      } else dataType = "คอมมิชชั่นจ่าย"
    } else {
      dataType = "คอมมิชชั่นค้างจ่าย/จ่ายล่วงหน้า"
    }

    let groupBy = ""
    if (props.groupBy === "order") {
      groupBy = "รหัสคำสั่งซื้อ"
    } else if (props.groupBy === "salesman") {
      groupBy = "พนักงาน"
    } else if (props.groupBy === "affiliate") {
      groupBy = "ทีมผู้แนะนำ"
    }

    let fileName = ""
    if (reportDate) {
      fileName =
        dataType + reportDate.split("/")[0] + reportDate.split("/")[1] + reportDate.split("/")[2]
    } else {
      fileName = dataType
    }

    const reportData = {
      reportDate: reportDate,
      dataType: dataType,
      groupBy: groupBy,
      fileName: fileName,
    }
    return { reportData }
  }),
  withHandlers({
    onExpandFilter: (props) => (expanded) => {
      props.setFilterExpanded(expanded)
    },
    onSelectAll: (props) => (selected) => {
      if (selected) {
        props.setSelectedLeadIds(props.salesCommissions.map((commission) => commission.id))
      } else {
        props.setSelectedLeadIds([])
      }
    },
    onSelectRow: (props) => (value, dataItem) => {
      if (value) {
        props.setSelectedLeadIds([...props.selectedLeadIds, dataItem.id])
      } else {
        props.setSelectedLeadIds(props.selectedLeadIds.filter((id) => id !== dataItem.id))
      }
    },
    onBack: (props) => () => {
      props.history.push(path.saleCommissionsPath())
    },
    closeModal: (props) => () => {
      props.setOpenSuccessModal(!props.openSuccessModal)
      props.history.push(path.saleCommissionsPath())
    },
    onReport: (props) => async () => {
      try {
        if (props.groupBy === "order") {
          await props.exportOrders({
            exportedDate: props.exportedDate,
            exportedType: props.exportedType,
            ids: props.selectedLeadIds,
          })
        } else if (props.groupBy === "salesman") {
          await props.export({
            exportedDate: props.exportedDate,
            exportedType: props.exportedType,
            ids: props.selectedLeadIds,
          })
        } else if (props.groupBy === "affiliate") {
          await props.exportAffiliate({
            exportedDate: props.exportedDate,
            exportedType: props.exportedType,
            ids: props.selectedLeadIds,
          })
        }

        props.setOpenSuccessModal(true)
      } catch {
        window.alert("ออกรายงานไม่สำเร็จ")
      }
    },
  }),
  withProps((props) => {
    const data = props.salesCommissions
    const isSelectedAll = data.length > 0 ? data.every((row) => row.selected) : false

    return {
      isSelectedAll,
    }
  }),
  withProps((props) => {
    const ignoreSalesNames = props.values.ignoreSalesNames || []

    if (props.groupBy === "order") {
      const salesNameOptions = props.salesCommissions.map((commission) => {
        return { label: commission.tqmOrderId, value: commission.tqmOrderId }
      })
      const filterSalesCommission = props.salesCommissions.filter(
        (commission) => !ignoreSalesNames.includes(commission.tqmOrderId)
      )

      const uniqueSalesNameOptions = Array.from(
        new Set(salesNameOptions.map((option) => option.label))
      ).map((label) => {
        return salesNameOptions.find((option) => option.label === label)
      })

      return {
        salesNameOptions: uniqueSalesNameOptions,
        salesCommissions: filterSalesCommission,
      }
    } else {
      const salesNameOptions = props.salesCommissions.map((commission) => {
        return { label: commission.fullName, value: commission.fullName }
      })
      const filterSalesCommission = props.salesCommissions.filter(
        (commission) => !ignoreSalesNames.includes(commission.fullName)
      )

      const uniqueSalesNameOptions = Array.from(
        new Set(salesNameOptions.map((option) => option.label))
      ).map((label) => {
        return salesNameOptions.find((option) => option.label === label)
      })

      return {
        salesNameOptions: uniqueSalesNameOptions,
        salesCommissions: filterSalesCommission,
      }
    }
  }),
  withProps(() => {
    const paymentMethodOptions = [
      {
        label: "Bill Payment",
        value: "bill_payment",
      },
      {
        label: "บัตรเครดิต",
        value: "credit_card",
      },
      {
        label: "บัตรเครดิต ครั้งแรกเงินสด",
        value: "credit_card_cash",
      },
      {
        label: "Direct Debit ครั้งแรกเงินสด",
        value: "direct_debit_cash",
      },
      {
        label: "เงินสด",
        value: "cash",
      },
    ]

    return { paymentMethodOptions }
  }),
  lifecycle({
    async componentDidMount() {
      if (this.props.groupBy === "order") {
        let salesCommissions = {}
        await Promise.all([
          this.props.fetchConfigOrders(),
          this.props.fetchCommissionOrders({
            page: 1,
            perPage: 10000,
            exportedType: this.props.exportedType,
            exportedDate: this.props.exportedDate,
          }),
        ]).then(function (value) {
          salesCommissions = value[1]
        })

        if (!isEmpty(salesCommissions)) {
          const salesCommissionIds = salesCommissions.map((commission) => commission.id)
          this.props.setSelectedLeadIds(salesCommissionIds)
        }
      } else if (this.props.groupBy === "salesman") {
        let salesCommissions = {}
        await Promise.all([
          this.props.fetchConfig(),
          this.props.fetchCommission({
            page: 1,
            perPage: 10000,
            groupBy: this.props.groupBy,
            exportedDate: this.props.exportedDate,
            exportedType: this.props.exportedType,
          }),
        ]).then(function (value) {
          salesCommissions = value[1]
        })

        if (!isEmpty(salesCommissions)) {
          const salesCommissionIds = salesCommissions.map((commission) => commission.id)
          this.props.setSelectedLeadIds(salesCommissionIds)
        }
      } else if (this.props.groupBy === "affiliate") {
        let salesCommissions = {}
        await Promise.all([
          this.props.fetchAffiliatesConfig(),
          this.props.fetchAffiliates({
            page: 1,
            perPage: 10000,
            groupBy: this.props.groupBy,
            exportedDate: this.props.exportedDate,
            exportedType: this.props.exportedType,
          }),
        ]).then(function (value) {
          salesCommissions = value[1]
        })

        if (!isEmpty(salesCommissions)) {
          const salesCommissionIds = salesCommissions.map((commission) => commission.id)
          this.props.setSelectedLeadIds(salesCommissionIds)
        }
      }
    },
    componentDidUpdate(prevProps) {
      if (!isEqual(this.props.salesCommissions, prevProps.salesCommissions)) {
        const salesCommissionIds = this.props.salesCommissions.map((commission) => commission.id)
        this.props.setSelectedLeadIds(salesCommissionIds)
      }
    },
  })
)

export default enhancer(SaleCommissionsReportPreparation)
