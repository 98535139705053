import React, { useMemo } from "react"
import styled from "@emotion/styled"
import { css } from "@styled"
import { compose, withHandlers, withState, withProps, defaultProps, lifecycle } from "recompose"
import { withStores, withRouter } from "@enhancers"
import { Button, FilterButton, LoadingScope } from "@components"
import * as path from "@common/path"
import { isEmpty, get, isEqual, cloneDeep } from "lodash"
import { reduxForm } from "redux-form"
import ExportModal from "./ExportModal"
import { exceptNullKeys } from "@common/helper"

import MemberFilter from "./Filter/MemberFilter"
import OrderFilter from "./Filter/OrderFilter"

import ViewAsMember from "./ViewAs/ViewAsMember"
import ViewAsOrder from "./ViewAs/ViewAsOrder"
import ViewAsAffiliate from "./ViewAs/ViewAsAffiliate"
import AffiliateFilter from "./Filter/AffiliateFilter"

export const Layout = styled.div`
  margin: 0px auto 25px auto;
  width: 100%;
`
const Header = styled.div`
  display: flex;
  margin: 21px 0 12px 0;
  flex-direction: ${(props) => (props.direction === "true" ? "column" : "row")};
  flex-wrap: wrap;
`
const Left = styled.div``
const Right = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;

  > div > div:first-of-type {
    margin-right: 13px;
  }

  > button {
    margin-left: 6px;
  }
`
const ViewContainer = styled.div`
  display: flex;

  > span {
    max-width: 120px;
    margin-left: 0;
  }

  > input {
    flex: 1;
    margin-top: 0px !important;
    margin-left: 10px;
    font-family: Sarabun, sans-serif;
    font-size: 16px;
  }
`
const ViewText = styled.div`
  align-self: center;
`

const ViewTab = styled(Button)`
  width: 100px;
  height: 38px;
  opacity: 1 !important;
  color: #0275d8;
  background: #ffffff;
  border: 1px solid #0275d8;

  &:first-of-type {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  &:nth-of-type(2) {
    border-radius: 0px;
  }
  &:nth-of-type(3) {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  :active,
  :hover {
    color: white;
    border: 1px solid #0275d8;
    background-color: #0275d8;
  }

  ${(props) =>
    props.isactive &&
    css`
      pointer-events: none;
      border: 1px solid #0275d8;
      color: white;
      background-color: #0275d8;
    `}
`
const CustomFilterButton = styled(FilterButton)`
  > button {
    width: 143px;

    > .k-icon {
      margin: unset;
      margin-right: 12px;
      font-size: 19px;
    }
  }
`
const ExportButton = styled(Button)`
  width: 116px;

  > .k-icon {
    margin: unset;
    margin-right: 12px;
    font-size: 20px;
  }
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 28px;
`
const ReportCommissions = styled(Button)`
  min-width: 231px;
  height: 38px;
`
const ConfirmVerifyingCommissions = styled(Button)`
  min-width: 216px;
  height: 38px;
  margin-left: 6px;
`
const WrapButton = styled.div`
  display: flex;

  ${(props) =>
    props.expanded &&
    css`
      width: 100%;
      justify-content: flex-end;
    `}

  button {
    color: #627b8b;
    border-color: #627b8b;
  }
`

const SaleCommissions = (props) => {
  const renderFilter = useMemo(() => {
    if (props.location.query.viewType === "salesman") return <MemberFilter values={props.values} />
    else if (props.location.query.viewType === "affiliates")
      return <AffiliateFilter values={props.values} />
    else return <OrderFilter values={props.values} />
  }, [props.location.query.viewType, props.values])

  const renderViewAs = useMemo(() => {
    if (props.location.query.viewType === "salesman")
      return (
        <ViewAsMember
          loadingTable={props.loadingTable}
          data={props.data}
          onPageChange={props.onPageChange}
        />
      )
    else if (props.location.query.viewType === "affiliates")
      return (
        <ViewAsAffiliate
          loadingTable={props.loadingTable}
          data={props.data}
          onPageChange={props.onPageChange}
        />
      )
    else
      return (
        <ViewAsOrder
          loadingTable={props.loadingTable}
          data={props.data}
          onPageChange={props.onPageChange}
        />
      )
  }, [props.location.query.viewType, props.data])

  return (
    <>
      {props.loading ? (
        <LoadingScope />
      ) : (
        <Layout>
          <Header direction={`${props.filterExpanded}`}>
            <Left>
              <CustomFilterButton
                expanded={props.filterExpanded}
                onExpand={props.onExpandFilter}
                disabled={props.disabledFilter}
              >
                {renderFilter}
              </CustomFilterButton>
            </Left>
            <Right>
              <ViewContainer>
                <ViewText>มุมมองตาราง</ViewText>

                <ViewTab
                  onClick={props.viewAsOrders}
                  isactive={
                    props.location.query.viewType === "orders" ||
                    (props.location.query.viewType !== "salesman" &&
                      props.location.query.viewType !== "affiliates")
                      ? "true"
                      : undefined
                  }
                  children="คำสั่งซื้อ"
                />

                <ViewTab
                  onClick={props.viewAsMembers}
                  isactive={props.location.query.viewType === "salesman" ? "true" : undefined}
                  children="พนักงาน"
                />

                <ViewTab
                  onClick={props.viewAsAffiliates}
                  isactive={props.location.query.viewType === "affiliates" ? "true" : undefined}
                  children="ทีมผู้แนะนำ"
                />
              </ViewContainer>

              <ExportButton
                awesomeIcon="file-export"
                onClick={props.onCloseAndOpenModal}
                children="รายงาน"
                styletype="secondary"
              />
            </Right>
          </Header>
          {renderViewAs}

          {/* <ButtonContainer>
            {props.location.query.viewType !== "salesman" && (
              <WrapButton>
                <ReportCommissions
                  awesomeIcon="file-export"
                  onClick={props.exportToVerify}
                  children="ออกรายงานเพื่อตรวจสอบ"
                  type="disabled"
                />
                <ConfirmVerifyingCommissions
                  awesomeIcon="check"
                  onClick={props.gotoCommissionsVerify}
                  children="ยืนยันรายการตรวจสอบ"
                />
              </WrapButton>
            )}
          </ButtonContainer> */}
        </Layout>
      )}
      <ExportModal open={props.isShowModal} onClose={props.onCloseAndOpenModal} />
    </>
  )
}

const validate = (values) => {
  const errors = {}

  const minSubmittedAt = get(values, "submittedAt.min")
  const maxSubmittedAt = get(values, "submittedAt.max")
  if (minSubmittedAt && maxSubmittedAt && minSubmittedAt > maxSubmittedAt) {
    errors.submittedAt = {
      min: "กรุณากรอกวันที่ให้ถูกต้อง",
      max: "กรุณากรอกวันที่ให้ถูกต้อง",
    }
  }

  const minReceivedAt = get(values, "receivedAt.min")
  const maxReceivedAt = get(values, "receivedAt.max")
  if (minReceivedAt && maxReceivedAt && minReceivedAt > maxReceivedAt) {
    errors.receivedAt = {
      min: "กรุณากรอกวันที่ให้ถูกต้อง",
      max: "กรุณากรอกวันที่ให้ถูกต้อง",
    }
  }

  const minCoverageDate = get(values, "coverageDate.min")
  const maxCoverageDate = get(values, "coverageDate.max")
  if (minCoverageDate && maxCoverageDate && minCoverageDate > maxCoverageDate) {
    errors.coverageDate = {
      min: "กรุณากรอกวันที่ให้ถูกต้อง",
      max: "กรุณากรอกวันที่ให้ถูกต้อง",
    }
  }

  const minCalculateCommissionExportedDate = get(values, "calculateCommissionExportedDate.min")
  const maxCalculateCommissionExportedDate = get(values, "calculateCommissionExportedDate.max")
  if (
    minCalculateCommissionExportedDate &&
    maxCalculateCommissionExportedDate &&
    minCalculateCommissionExportedDate > maxCalculateCommissionExportedDate
  ) {
    errors.calculateCommissionExportedDate = {
      min: "กรุณากรอกวันที่ให้ถูกต้อง",
      max: "กรุณากรอกวันที่ให้ถูกต้อง",
    }
  }

  const minSalesCommissionExportedDate = get(values, "salesCommissionExportedDate.min")
  const maxSalesCommissionExportedDate = get(values, "salesCommissionExportedDate.max")
  if (
    minSalesCommissionExportedDate &&
    maxSalesCommissionExportedDate &&
    minSalesCommissionExportedDate > maxSalesCommissionExportedDate
  ) {
    errors.salesCommissionExportedDate = {
      min: "กรุณากรอกวันที่ให้ถูกต้อง",
      max: "กรุณากรอกวันที่ให้ถูกต้อง",
    }
  }

  const minPendingCommissionExportedDate = get(values, "pendingCommissionExportedDate.min")
  const maxPendingCommissionExportedDate = get(values, "pendingCommissionExportedDate.max")
  if (
    minPendingCommissionExportedDate &&
    maxPendingCommissionExportedDate &&
    minPendingCommissionExportedDate > maxPendingCommissionExportedDate
  ) {
    errors.pendingCommissionExportedDate = {
      min: "กรุณากรอกวันที่ให้ถูกต้อง",
      max: "กรุณากรอกวันที่ให้ถูกต้อง",
    }
  }

  const minCommissionReceivedAt = get(values, "commissionReceivedAt.min")
  const maxCommissionReceivedAt = get(values, "commissionReceivedAt.max")
  if (
    minCommissionReceivedAt &&
    maxCommissionReceivedAt &&
    minCommissionReceivedAt > maxCommissionReceivedAt
  ) {
    errors.commissionReceivedAt = {
      min: "กรุณากรอกวันที่ให้ถูกต้อง",
      max: "กรุณากรอกวันที่ให้ถูกต้อง",
    }
  }

  if (!isEmpty(errors)) {
    errors._error = errors
  }

  return errors
}

const enhancer = compose(
  withRouter({
    queryTypes: {
      insuranceCompanyId: "integer",
      affiliateTeamId: "integer",
      "submittedAt.min": "date",
      "submittedAt.max": "date",
      "receivedAt.min": "date",
      "receivedAt.max": "date",
      "coverageDate.min": "date",
      "coverageDate.max": "date",
      "calculateCommissionExportedDate.min": "date",
      "calculateCommissionExportedDate.max": "date",
      "salesCommissionExportedDate.min": "date",
      "salesCommissionExportedDate.max": "date",
      "pendingCommissionExportedDate.min": "date",
      "pendingCommissionExportedDate.max": "date",
      "commissionReceivedAt.min": "date",
      "commissionReceivedAt.max": "date",
    },
  }),
  withState("filterExpanded", "setFilterExpanded", false),
  withState("loading", "setLoading", false),
  withState("loadingTable", "setLoadingTable", false),
  withState("isShowModal", "setIsShowModal", false),
  withState("errors", "setErrors", null),
  withStores((stores) => ({
    fetch: stores.saleCommissionStore.fetch,
    fetchSalesCommissionOrders: stores.saleCommissionStore.fetchSalesCommissionOrders,
    data: stores.saleCommissionStore.all,
    fetchConfig: stores.saleCommissionStore.fetchConfig,
    fetchOrdersConfig: stores.saleCommissionStore.fetchSalesCommissionOrdersConfig,
    fetchAffiliates: stores.saleCommissionStore.fetchSalesCommissionAffiliates,
    fetchAffiliatesConfig: stores.saleCommissionStore.fetchSalesCommissionAffiliatesConfig,
    exportToVerify: stores.saleCommissionStore.exportToVerify,
  })),
  defaultProps({
    initialValues: {},
  }),
  withState("fetchInsurerCommissionsTask", "setFetchInsurerCommissionsTask", null),
  withHandlers({
    onUpdateFilterParams: (props) => async (values, delay = 0) => {
      const filterParams = cloneDeep(values)
      if (props.fetchInsurerCommissionsTask) {
        clearTimeout(props.fetchInsurerCommissionsTask)
      }
      const exceptNullParams = exceptNullKeys(
        filterParams,
        "staffCode",
        "memberStatus",
        "salesmanPosition",
        "teamName",
        "fullName",
        "tqmOrderId",
        "state",
        "insuranceCompanyId",
        "memberName",
        "editingStatus",
        "affiliateTeamId",
        "affiliateStaffCode",
        "affiliateName",
        "areaCode",
        "branchCode",
        "leaderStaffCode",
        "leaderName",
        "affiliateSubmittedAt.min",
        "affiliateSubmittedAt.max",
        "commissionReceivedAt.min",
        "commissionReceivedAt.max",
        "submittedAt.min",
        "submittedAt.max",
        "receivedAt.min",
        "receivedAt.max",
        "coverageDate.min",
        "coverageDate.max"
      )
      await props.setFetchInsurerCommissionsTask(
        setTimeout(() => {
          props.history.push(
            path.saleCommissionsPath({
              ...exceptNullParams,
            })
          )
        }, delay)
      )
    },
  }),
  reduxForm({
    form: "SalesCommissionForm",
    validate,
    onChange: (filters, _, props) => {
      props.onUpdateFilterParams(filters, 400)
    },
  }),
  withProps((props) => {
    const filterParams = cloneDeep(props.location.query)
    if (!filterParams.viewType) {
      filterParams["viewType"] = "orders"
    }

    if (filterParams.staffCode && filterParams.staffCode.length < 4) {
      delete filterParams.staffCode
    }
    if (filterParams.teamName && filterParams.teamName.length < 4) {
      delete filterParams.teamName
    }
    if (filterParams.fullName && filterParams.fullName.length < 4) {
      delete filterParams.fullName
    }

    return { filterParams }
  }),
  withHandlers({
    onExpandFilter: (props) => (expanded) => {
      props.setFilterExpanded(expanded)
    },
    onPageChange: (props) => async (event) => {
      const page = event.page.skip / event.page.take + 1
      const viewType = props.filterParams.viewType || "orders"
      if (viewType === "salesman")
        await props.fetch({
          ...props.filterParams,
          page,
          viewType: props.location.query.viewType,
        })
      else if (viewType === "affiliates")
        await props.fetchAffiliates({
          ...props.filterParams,
          page,
          viewType: props.location.query.viewType,
        })
      else
        await props.fetchSalesCommissionOrders({
          ...props.filterParams,
          page,
          viewType: props.location.query.viewType,
        })
    },
    onCloseAndOpenModal: (props) => () => {
      props.setIsShowModal(!props.isShowModal)
    },
    exportToVerify: (props) => async () => {
      await props.exportToVerify(props.location.query)
    },
    gotoCommissionsVerify: (props) => () => {
      props.history.push(
        path.saleCommissionVerification({ viewType: props.location.query.viewType || "orders" })
      )
    },
    viewAsMembers: (props) => () => {
      props.reset("SalesCommissionForm")
      props.history.push(path.saleCommissionsPath({ viewType: "salesman" }))
    },
    viewAsOrders: (props) => () => {
      props.reset("SalesCommissionForm")
      props.history.push(path.saleCommissionsPath({ viewType: "orders" }))
    },
    viewAsAffiliates: (props) => () => {
      props.reset("SalesCommissionForm")
      props.history.push(path.saleCommissionsPath({ viewType: "affiliates" }))
    },
  }),
  lifecycle({
    async componentDidMount() {
      const exceptNullParams = exceptNullKeys(
        this.props.filterParams,
        "state",
        "memberStatus",
        "salesmanPosition",
        "editingStatus"
      )

      this.props.setLoading(true)
      if (this.props.location.query.viewType === "salesman") {
        await Promise.all([this.props.fetchConfig(), this.props.fetch(exceptNullParams)])
      } else if (this.props.location.query.viewType === "affiliates") {
        await Promise.all([
          this.props.fetchAffiliatesConfig(),
          this.props.fetchAffiliates(exceptNullParams),
        ])
      } else {
        await Promise.all([
          this.props.fetchOrdersConfig(),
          this.props.fetchSalesCommissionOrders(exceptNullParams),
        ])
      }

      this.props.initialize(exceptNullParams)
      this.props.setLoading(false)
    },
    async componentDidUpdate(prevProps) {
      if (!isEqual(this.props.filterParams, prevProps.filterParams)) {
        const exceptNullParams = exceptNullKeys(
          this.props.filterParams,
          "state",
          "memberStatus",
          "salesmanPosition",
          "editingStatus"
        )

        this.props.setLoadingTable(true)
        if (this.props.location.query.viewType === "salesman") {
          await Promise.all([this.props.fetchConfig(), this.props.fetch(exceptNullParams)])
        } else if (this.props.location.query.viewType === "affiliates") {
          await Promise.all([
            this.props.fetchAffiliatesConfig(),
            this.props.fetchAffiliates(exceptNullParams),
          ])
        } else {
          await Promise.all([
            this.props.fetchOrdersConfig(),
            this.props.fetchSalesCommissionOrders(exceptNullParams),
          ])
        }
        this.props.initialize(exceptNullParams)
        this.props.setLoadingTable(false)
      }
    },
  })
)

export default enhancer(SaleCommissions)
