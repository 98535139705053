import React from "react"
import styled from "@emotion/styled"
import { compose, withHandlers } from "recompose"
import {
  Button,
  Dialog,
  DialogActionsBar,
  Field,
  Input,
  Form,
  DateTimePicker,
  TextArea,
  Dropdown,
} from "@components"
import { withStores, withForm } from "@enhancers"

const CustomDialog = styled(Dialog)`
  .k-window {
    border: 1px solid #e6e6e6;
    min-width: 415px;
    margin: 200px 0;
  }
`
const Layout = styled(Form)`
  padding: 8px 48px 29px 48px;
  min-height: 0px;

  > div {
    margin-top: 16px !important;
  }
`
const CloseButton = styled(Button)`
  height: 52px;
  color: #666666;
`
const SubmitButton = styled(Button)`
  height: 52px;
  background: #0275d8;
  border: 1px solid #eeeef2;
  color: #ffffff;
`
const CustomDateTimePicker = styled(DateTimePicker)`
  > div:last-child {
    > div {
      > div:nth-of-type(2) {
        min-width: 205px;
        max-width: 205px;
      }
    }
  }
`

const CustomTextArea = styled(TextArea)`
  > div:last-child {
    > textarea {
      height: 76px;
      border: 1px solid #eeeef2;
    }
  }
`

const AppointmentModal = (props) => (
  <CustomDialog title="นัดหมาย" onClose={props.onClose} isShowModal={props.open}>
    <Layout>
      <Field
        name="appointmentAt"
        component={CustomDateTimePicker}
        caption="วัน/เวลา"
        placeholder="กรุณาเลือก"
        validate="required"
        min={new Date()}
        leftWidth="116"
        rightWidth="205"
      />
      <Field
        name="appointmentLocation"
        component={Input}
        caption="สถานที่"
        placeholder="กรุณาระบุ"
        leftWidth="116"
        rightWidth="205"
      />
      <Field
        name="appointmentPolicy"
        component={Input}
        caption="แบบประกัน"
        placeholder="กรุณาระบุ"
        leftWidth="116"
        rightWidth="205"
      />
      <Field
        name="appointmentStatus"
        component={Dropdown}
        options={props.appointmentStatusOptions}
        caption="สถานะนัดหมาย"
        placeholder="กรุณาเลือก"
        leftWidth="116"
        rightWidth="205"
      />
      <Field
        name="appointmentRemark"
        component={CustomTextArea}
        caption="เพิ่มเติม"
        placeholder="กรุณาระบุ"
        leftWidth="116"
        rightWidth="205"
      />
    </Layout>

    <DialogActionsBar>
      <CloseButton type="button" onClick={props.onClose} children="ยกเลิก" styletype="delete" />
      <SubmitButton type="button" onClick={props.onSubmit} children="บันทึก" />
    </DialogActionsBar>
  </CustomDialog>
)

const enhancer = compose(
  withStores((stores, props) => ({
    updateAppointment: stores.leadStore.updateAppointment,
    appointmentStatusOptions: stores.leadStore.$(
      "configs.attributes.appointmentStatus.options",
      []
    ),
  })),
  withForm({ form: "AppointmentModalForm" }),
  withHandlers({
    onSubmit: (props) =>
      props.handleSubmit(async (values) => {
        try {
          await props.updateAppointment(props.leadId, {
            appointmentAt: values.appointmentAt,
            appointmentLocation: values.appointmentLocation,
            appointmentPolicy: values.appointmentPolicy,
            appointmentRemark: values.appointmentRemark,
            appointmentStatus: values.appointmentStatus,
          })

          await props.onClose()
        } catch (error) {
          if (error.errors.appointmentAt === "Appointment time less than current time!!") {
            window.alert("วันที่นัดหมายต้องไม่น้อยกว่าวันที่ปัจจุบัน")
          } else {
            window.alert(
              "นัดหมายไม่สำเร็จ เนื่องจากเกิดข้อผิดผลาดบางอย่าง กรุณาลองใหม่อีกครั้งในภายหลัง"
            )
          }
        }
      }),
  })
)
export default enhancer(AppointmentModal)
