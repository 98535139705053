import { compose, withProps } from "recompose"
import { toJS, isObservable } from "mobx"
import { observer, inject } from "mobx-react"

const withStores = (...args) =>
  compose(
    inject(...args),
    observer,
    withProps((props) =>
      Object.entries(props).reduce((memo, [key, value]) => {
        if (value && isObservable(value)) {
          memo[key] = value.toJS ? value.toJS() : toJS(value)
        }
        return memo
      }, {})
    )
  )

export default withStores
