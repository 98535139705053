import React from "react"
import styled from "@emotion/styled"
import { Button } from "@components"
import { compose } from "recompose"
import { withStores } from "@enhancers/index"

const CustomButton = styled(Button)`
  width: 129px;
  border: 1px solid #627b8b;
  color: #627b8b;
`

const NormalText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #627b8b;
`

const BizDevButton = (props) => (
  <>
    {props.state.value === "doc_verifying" ? (
      <CustomButton onClick={props.onClick}>{props.state.label}</CustomButton>
    ) : (
      <NormalText>{props.state.label}</NormalText>
    )}
  </>
)

const SalesmanButton = (props) => (
  <>
    {["drafting", "new", "doc_attaching"].includes(props.state.value) ? (
      <CustomButton style={{ width: "109px" }} onClick={props.onClick}>
        {props.state.label}
      </CustomButton>
    ) : (
      <NormalText
        style={{
          color: ["rejected"].includes(props.state.value) ? "#D9534F" : "#627B8B",
        }}
      >
        {props.state.label}
      </NormalText>
    )}
  </>
)

const AffiliateButton = (props) => (
  <>
    {["drafting", "affiliate_new"].includes(props.state.value) ? (
      <CustomButton style={{ width: "109px" }} onClick={props.onClick}>
        {props.state.label}
      </CustomButton>
    ) : (
      <NormalText
        style={{
          color: ["rejected"].includes(props.state.value) ? "#D9534F" : "#627B8B",
        }}
      >
        {props.state.label}
      </NormalText>
    )}
  </>
)

// 0: {label: "รอสรุปประกัน", value: "drafting"}         ===  รอสรุปประกัน (button)
// 1: {label: "รอแจ้งงาน", value: "new"}                 === รอแจ้งงาน (button)
// 2: {label: "รอแนบเอกสาร", value: "doc_attaching"}    === รอแนบเอกสาร (button)
// 3: {label: "รอตรวจเอกสาร", value: "doc_verifying"}   === รอตรวจเอกสาร
// 4: {label: "เอกสารอนุมัติ", value: "doc_approved"}     === เอกสารอนุมัติ
// 5: {label: "รออนุมัติ", value: "approving"}           === รออนุมัติ
// 6: {label: "ยกเลิกคำสั่งซื้อ", value: "rejected"}        === ยกเลิก (สีแดง)
// 7: {label: "กรมธรรม์อนุมัติ", value: "completed"}      === อนุมัติ
//                                                    === จ่ายเงินสำเร็จ (button)
const StateButton = (props) => (
  <>
    {["member.biz_dev"].includes(props.currentRole) ? (
      <BizDevButton onClick={props.onClick} state={props.state} />
    ) : ["member.salesman"].includes(props.currentRole) ? (
      <SalesmanButton onClick={props.onClick} state={props.state} />
    ) : ["member.affiliate"].includes(props.currentRole) ? (
      <AffiliateButton onClick={props.onClick} state={props.state} />
    ) : (
      <NormalText>{props.state.label}</NormalText>
    )}
  </>
)

const enhancer = compose(
  withStores((stores) => ({
    currentRole: stores.appStore.currentRole,
  }))
)
export default enhancer(StateButton)
