import BaseStore from "./BaseStore"
import { tqldServer } from "@common/api"

class dashboardStore extends BaseStore {
  constructor() {
    super({
      ordersSummary: {
        rejectedPolicy: {},
        salablePolicy: {},
        salablePremium: {},
      },
      ordersStatistic: {
        insuranceCompany: [],
        gender: [],
        agePeriod: [],
      },
      insuranceCompany: [],
      insurancePolicy: [],
      team: [],
      salesman: [],
      paymentPeriod: [],
      insurerCommission: [],
      ordersCommission: [],
      paging: {
        page: 1,
        perPage: 10,
        totalCount: 0,
        totalPages: 1,
      },
      insuranceCompanyGraph: {
        graph: [],
        yAxis: {},
      },
      commissionGraph: {
        graph: [],
        yAxis: {},
      },
      configs: {},
    })
  }

  fetchOrdersSummary = async (params = {}) => {
    const response = await tqldServer.tqmBackend.get(`dashboard/summary`, params)
    this.ordersSummary = response.data.data.summary
  }

  fetchOrderStatistic = async (params) => {
    const response = await tqldServer.tqmBackend.get(`dashboard/order_statistic`, params)
    this.ordersStatistic = response.data.data.ordersStatistic
  }

  fetchInsuranceCompany = async (params) => {
    const response = await tqldServer.tqmBackend.get(`dashboard/insurance_companies`, params)
    this.insuranceCompany = response.data.data.insuranceCompanies
    this.paging = response.data.data.paging
  }

  fetchInsuranceCompanyGraph = async (params = {}) => {
    const response = await tqldServer.tqmBackend.get(`dashboard/graph`, params)
    this.insuranceCompanyGraph = {
      graph: response.data.data.graph,
      yAxis: response.data.data.yAxis,
    }
  }

  exportCompanyReport = async (params) => {
    await tqldServer.tqmBackend.download(`dashboard/export_company_report`, params)
  }

  exportProductReport = async (params) => {
    await tqldServer.tqmBackend.download(`dashboard/export_product_report`, params)
  }

  exportTeamReport = async (params) => {
    await tqldServer.tqmBackend.download(`dashboard/export_team_report`, params)
  }

  exportSalesmanReport = async (params) => {
    await tqldServer.tqmBackend.download(`dashboard/export_salesman_report`, params)
  }

  fetchConfig = async () => {
    const response = await tqldServer.tqmBackend.get(`dashboard/configs`)
    this.configs = response.data.data.configs
  }

  fetchInsurancePolicy = async (params) => {
    const response = await tqldServer.tqmBackend.get(`dashboard/insurance_policies`, params)
    this.insurancePolicy = response.data.data.insurancePolicies
    this.paging = response.data.data.paging
  }

  fetchDashboardTeam = async (params) => {
    const response = await tqldServer.tqmBackend.get(`dashboard/teams`, params)
    this.team = response.data.data.teams
    this.paging = response.data.data.paging
  }

  fetchDashboardSalesmen = async (params) => {
    const response = await tqldServer.tqmBackend.get(`dashboard/salesmen`, params)
    this.salesman = response.data.data.salesmen
    this.paging = response.data.data.paging
  }

  fetchDashboardPaymentPeriod = async (params) => {
    const response = await tqldServer.tqmBackend.get(`dashboard/payment_period`, params)
    this.paymentPeriod = response.data.data.paymentPeriod
  }

  fetchDashboardCommissionGraph = async (params) => {
    const response = await tqldServer.tqmBackend.get(`dashboard/sales_commission/graph`, params)
    this.commissionGraph = {
      graph: response.data.data.graph,
      yAxis: response.data.data.yAxis,
    }
  }

  fetchDashboardInsurerCommission = async (params) => {
    const response = await tqldServer.tqmBackend.get(
      `dashboard/sales_commission/insurance_companies`,
      params
    )
    this.insurerCommission = response.data.data.insuranceCompanies
    this.paging = response.data.data.paging
  }

  fetchDashboardOrdersCommission = async (params) => {
    const response = await tqldServer.tqmBackend.get(`dashboard/sales_commission/orders`, params)
    this.ordersCommission = response.data.data.orders
    this.paging = response.data.data.paging
  }
}

export default new dashboardStore()
