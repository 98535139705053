import Api from "./api"
import env from "@env"

const makeSlackApi = (configs) => (text, options) => {
  new Api().post(
    env.slackWebhookUrl,
    JSON.stringify({
      channel: "#tqm-life-backend-react",
      username: "TQM App",
      ...configs,
      ...options,
      text,
    })
  )
}

const slack = {
  bug: makeSlackApi(),
}

export default slack
