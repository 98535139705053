import React from "react"
import { AutoComplete } from "@progress/kendo-react-dropdowns"
import { compose, withHandlers, withProps } from "recompose"
import { withHidden, withReadOnly, withField, withCaption, acceptProps } from "@enhancers"
import styled from "@emotion/styled"
import withOptions from "@enhancers/withOptions"
import { get, isEqual } from "lodash"

const ReadOnly = styled.div`
  min-height: 33.5px;
  display: flex;
  align-items: center;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
`

export const enhancer = compose(
  withHidden(),
  withField(),
  withCaption(),
  withOptions(),
  withProps((props) => {
    const data = props.options.map(({ label }) => label)
    const value = get(
      props.options.find(({ label }) => isEqual(label, props.value)),
      "label",
      props.value || null
    )
    return { data, value }
  }),
  withReadOnly((props) => <ReadOnly>{props.value}</ReadOnly>),
  withHandlers({
    onChange: (props) => (e) => {
      const value = get(
        props.options.find(({ label }) => isEqual(label, e.target.value)),
        "value",
        e.target.value
      )
      props.onChange(value)
    },
  }),
  acceptProps("onChange", "value", "data", "className", "loading")
)

export default enhancer(AutoComplete)
