import React, { useCallback, useEffect, useMemo, useState } from "react"
import styled, { css } from "@styled"
import { compose, withHandlers, lifecycle, withState, withProps } from "recompose"
import { withStores, withForm, withRouter, withHooks } from "@enhancers"
import {
  Button,
  // Header as TextHeader,
  Input,
  Field,
  Icon,
  DatePicker,
  Grid,
  GridColumn,
  CurrencyInput,
  makeCell,
} from "@components"
import * as path from "@common/path"

import Spinner from "react-spinkit"
import Modal from "@pages/insurer_commissions/show/Modal"
import { toCurrency } from "@common/helper"

const Layout = styled.div`
  /* margin: 0px auto 25px auto; */
`
const Body = styled.div`
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #eeeef2;
`
const TextTopic = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  font-size: 16px;
  color: #ffffff;
  font-family: Sarabun, sans-serif;
  /* font-weight: bold; */
`
const TopicContainer = styled.div`
  width: 100%;
  height: 40px;
  background: #294558;
  opacity: 1;
  display: flex;
  justify-content: space-between;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  > div:first-of-type {
    padding-left: 16px;
  }

  > div:last-of-type {
    padding-right: 16px;
  }
`
const TopicSalesCommissionContainer = styled(TopicContainer)`
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  line-height: 21px;
  margin-bottom: 16px;

  > div > div:last-of-type > div {
    padding-top: 0 !important;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`
const RowWithState = styled(Row)`
  > div:last-of-type > div:last-of-type > div:last-of-type {
    color: ${(props) => props.children[1].props.isactive === true && "red"};
  }
`
const OrderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px 40px;
`
const SalesCommissionContainer = styled(OrderContainer)`
  padding: 24px 40px 32px 40px;
`
const SalesCommissionSubContainer = styled.div`
  margin-bottom: 40px;

  &:last-of-type {
    margin-bottom: 0;
  }
`
const IconContainer = styled.div`
  display: ${(props) => (props.hidden ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
const PencilIcon = styled(Icon)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0275d8;
`
const CheckIcon = styled(Icon)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0275d8;
`
const CustomDatePicker = styled(DatePicker)`
  width: fit-content;
  .k-picker-wrap {
    max-width: 138px !important;
  }
`
const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const DateRow = styled(Row)`
  height: 33.5px;
  margin-bottom: 33px;
  > div {
    margin-left: 12px;

    &:nth-of-type(1) {
      margin-left: 0;

      > div:nth-of-type(2) {
        max-width: 138px !important;
      }
    }
  }
`
const CustomSpinner = styled(Spinner)`
  width: 16px;
  height: 16px;
`

const RowWithUnit = styled(Row)`
  height: 33.5px;
  .css-u62uda--withCaption--Layout {
    width: unset;
  }
  > div:nth-of-type(2) > div:nth-of-type(2) > div {
    ${(props) =>
      props.color
        ? css`
            color: #5cb85c;
          `
        : css`
            color: #d9534f;
          `}
  }
`
const RowPayDate = styled(Row)`
  display: flex;
  justify-content: flex-end;
  height: 33.5px;
  margin-top: 28.5px;
  > div {
    margin-right: 36px;
  }
`
const Unit = styled.div`
  align-self: center;
  margin-left: 5px;
`

const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 314px;

  > div {
    color: #5cb85c;
    div:nth-of-type(2) > div {
      padding-top: 0 !important;
      color: #5cb85c;
    }
  }
`
const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px 0;

  > * {
    margin-left: 6px;
  }
`
const ActionButton = styled(Button)`
  width: 104px;

  > span {
    font-size: 22px;
  }
`
const ApproveButton = styled(Button)`
  width: 158px;

  > span {
    font-size: 22px;
  }
`
const VerifyButton = styled(Button)`
  width: 191px;

  > span {
    font-size: 22px;
  }
`
const MessageModal = styled.div`
  color: #666666;
  > div {
    > span {
      font-weight: bold;
    }
  }
`
const ApproveModal = styled(Modal)``

export const Table = styled(Grid)`
  .k-grid-header {
    background-color: #e2e5e9 !important;
    border-color: #e2e5e9 !important;
    padding: 0 !important;
    .k-header {
      /* font-weight: bold; */
      > a {
        padding: 12px !important;
      }
    }
  }

  .k-grid-header-wrap > table > thead > tr {
    background-color: #e2e5e9;
    border-color: #e2e5e9;
    > th > a {
      color: #666666;
    }
  }

  .k-master-row {
    > td {
      flex-direction: row;
      padding: 0 12px;
    }

    > td:nth-of-type(4) {
      color: #5cb85c;
      text-align: end;
    }
    > td:nth-of-type(5) > div > div {
      color: #5cb85c;
      justify-content: flex-end;
    }
  }
`
const CustomField = styled(Field)`
  [class*="LeftPanel"] > div {
    font-weight: 400;
  }
`

const ViewAsOrderDetail = (props) => (
  <Layout>
    <Body>
      <TopicContainer>
        <TextTopic>ข้อมูลคำสั่งซื้อ</TextTopic>
        <TextTopic>สถานะ: {props.state}</TextTopic>
      </TopicContainer>

      <OrderContainer>
        <SalesCommissionSubContainer>
          <Row>
            <CustomField
              name="tqmOrderId"
              component={Input}
              caption="รหัสคำสั่งซื้อ"
              leftWidth="110"
              rightWidth="204"
              readOnly
            />
            <CustomField
              name="submittedAt"
              component={DatePicker}
              caption="วันที่แจ้งงาน"
              leftWidth="107"
              readOnly
            />
          </Row>

          <Row>
            <CustomField
              name="customerName"
              component={Input}
              caption="ชื่อลูกค้า"
              leftWidth="110"
              readOnly
            />
          </Row>

          <Row>
            <CustomField
              name="policyName"
              component={Input}
              caption="แบบประกัน"
              leftWidth="110"
              readOnly
            />
          </Row>

          <Row>
            <CustomField
              name="insuranceCompanyName"
              component={Input}
              caption="บริษัทประกัน"
              leftWidth="110"
              readOnly
            />
          </Row>
        </SalesCommissionSubContainer>

        <DateContainer>
          <DateRow>
            <CustomField
              name="calculateCommissionExportedDate"
              caption="วันนำออกคำนวณคอมมิชชั่น"
              component={CustomDatePicker}
              readOnly={!props.toggleCalculateCommissionExportedDate}
              placeholder="กรุณาเลือก"
              leftWidth="314"
            />
            <IconContainer
              onClick={props.editCalculateCommissionExportedDate}
              hidden={!props.toggleCalculateCommissionExportedDate}
            >
              <PencilIcon icon={"pencil"} />
            </IconContainer>
            <IconContainer
              onClick={props.handleSubmit(props.onSave)}
              hidden={props.toggleCalculateCommissionExportedDate && !props.isEdit}
            >
              {props.loading ? <CustomSpinner name="circle" /> : <CheckIcon icon={"check"} />}
            </IconContainer>
          </DateRow>

          <DateRow>
            <CustomField
              name="salesCommissionExportedDate"
              caption="วันนำออกคอมมิชชั่นจ่าย"
              component={CustomDatePicker}
              readOnly={!props.toggleSalesCommissionExportedDate}
              placeholder="กรุณาเลือก"
              leftWidth="314"
            />
            <IconContainer
              onClick={props.editSalesCommissionExportedDate}
              hidden={!props.toggleSalesCommissionExportedDate}
            >
              <PencilIcon icon={"pencil"} />
            </IconContainer>
            <IconContainer
              onClick={props.handleSubmit(props.onSave)}
              hidden={props.toggleSalesCommissionExportedDate && !props.isEdit}
            >
              {props.loading ? <CustomSpinner name="circle" /> : <CheckIcon icon={"check"} />}
            </IconContainer>
          </DateRow>

          <DateRow>
            <CustomField
              name="pendingCommissionExportedDate"
              caption="วันนำออกคอมมิชชั่นค้างจ่าย"
              component={CustomDatePicker}
              readOnly={!props.togglePendingCommissionExportedDate}
              placeholder="กรุณาเลือก"
              leftWidth="314"
            />
            <IconContainer
              onClick={props.editPendingCommissionExportedDate}
              hidden={!props.togglePendingCommissionExportedDate}
            >
              <PencilIcon icon={"pencil"} />
            </IconContainer>
            <IconContainer
              onClick={props.handleSubmit(props.onSave)}
              hidden={props.togglePendingCommissionExportedDate && !props.isEdit}
            >
              {props.loading ? <CustomSpinner name="circle" /> : <CheckIcon icon={"check"} />}
            </IconContainer>
          </DateRow>
        </DateContainer>
      </OrderContainer>

      <TopicSalesCommissionContainer>
        <TextTopic>ข้อมูลคอมมิชชั่นจ่าย</TextTopic>
        <TextTopic>{`รวม ${toCurrency(props.data.totalSalesCommission)} บาท`}</TextTopic>
      </TopicSalesCommissionContainer>

      <SalesCommissionContainer>
        <Row>
          <CustomField
            caption="ทีมขาย"
            name="teamName"
            component={Input}
            leftWidth="107"
            readOnly
          />
          <CustomField
            caption="วันที่จ่าย"
            name="commissionReceivedDate"
            component={DatePicker}
            leftWidth="107"
            rightWidth="138"
            placeholder="กรุณาเลือก"
            readOnly
          />
        </Row>
        <Row>
          <CustomField
            caption="เบี้ยสุทธิ"
            name="totalPremium"
            component={CurrencyInput}
            leftWidth="107"
            readOnly
          />
        </Row>
        <Row>
          <CustomField
            caption="รายได้"
            name="totalInsurerCommission"
            component={CurrencyInput}
            leftWidth="107"
            readOnly
          />
        </Row>

        <Table data={props.data.salesCommissionDetails}>
          <GridColumn title="รหัสพนักงาน" field="staffCode" width="108px" />
          <GridColumn title="ชื่อพนักงาน" field="fullName" width="160px" />
          <GridColumn title="ตำแหน่ง" field="positionName" width="170px" />
          <GridColumn title="สัดส่วน(%)" field="commissionPercent" width="96px" />

          <GridColumn
            title="คอมมิชชั่นจ่าย"
            field="commissionAmount"
            cell={makeCell(CurrencyInput, (cell) => ({
              readOnly: true,
              value: cell.dataItem.commissionAmount,
            }))}
            width="119px"
          />
          <GridColumn title="รหัสผู้แนะนำ" field="suggestedBy" width="120px" />
          <GridColumn title="สัดส่วนค่าแนะนำ" field="suggestedPercent" width="150px" />
          <GridColumn title="ค่าแนะนำ" field="suggestedCommission" width="120px" />
          <GridColumn title="ส่วนต่างค่าแนะนำ" field="diffSuggestedCommission" width="150px" />
        </Table>

        <Row>
          <Field
            caption="ทีมผู้แนะนำ"
            name="salesCommissionAffiliateTeam.affiliateTeamName"
            component={Input}
            leftWidth="107"
            style={{ marginTop: "30px" }}
            readOnly
          />
        </Row>
        <Table data={[{ ...props.values.salesCommissionAffiliateTeam }]}>
          <GridColumn title="ตำแหน่ง" field="positionName" width="108px" />
          <GridColumn
            title="สัดส่วน (%)"
            field="commissionPercent"
            width="108px"
            cell={(props) => (
              <td style={{ color: "#5CB85C" }}>{props.dataItem.commissionPercent}</td>
            )}
          />
          <GridColumn
            title="คอมมิชชันจ่าย"
            field="commissionAmount"
            width="108px"
            cell={(props) => (
              <td style={{ color: "#5CB85C" }}>{props.dataItem.commissionAmount}</td>
            )}
          />
        </Table>
      </SalesCommissionContainer>

      {/* <SalesCommissionContainer>
        <SalesCommissionSubContainer>
          <RowWithState>
            <Field
              name="staffCode"
              component={Input}
              caption="รหัสพนักงาน"
              leftWidth="110"
              rightWidth="204"
              readOnly
            />
            <Field
              name="salesmanStatus"
              component={Input}
              caption="สถานะ"
              leftWidth="107"
              isactive={props.initialValues.salesmanStatus === "ลาออก" ? true : false}
              readOnly
            />
          </RowWithState>

          <Row>
            <Field
              caption="ชื่อพนักงาน"
              name="salesmanName"
              component={Input}
              leftWidth="110"
              rightWidth="204"
              readOnly
            />
            <Field caption="ทีมขาย" name="teamName" component={Input} leftWidth="107" readOnly />
          </Row>
        </SalesCommissionSubContainer>

        <SalesCommissionSubContainer>
          <RowWithUnit>
            <Field
              caption="รายได้"
              name="insurerCommissionAmount"
              component={Input}
              leftWidth="110"
              readOnly
            />
            {props.data.insurerCommissionAmount && <Unit>บาท</Unit>}
          </RowWithUnit>

          <RowWithUnit color={props.data.commissionAmount >= 0 ? true : false}>
            <SubContainer>
              <Field
                caption="สัดส่วนการจ่าย"
                name="commissionPercent"
                component={Input}
                leftWidth="110"
                readOnly
              />
              {props.data.commissionPercent && <Unit>%</Unit>}
            </SubContainer>
            <Field
              caption="คอมมิชชั่นจ่าย"
              name="commissionAmount"
              component={Input}
              readOnly={!props.isEdit}
              leftWidth="107"
              rightWidth={props.isEdit ? "138" : ""}
              placeholder="กรุณาระบุ"
            />
            {!props.isEdit && props.data.commissionAmount && <Unit>บาท</Unit>}
          </RowWithUnit>
          {props.data.state !== "new" && (
            <RowPayDate>
              <Field
                caption="วันที่จ่าย"
                name="commissionReceivedDate"
                component={DatePicker}
                readOnly={!props.isEdit}
                leftWidth="107"
                rightWidth="138"
                placeholder="กรุณาเลือก"
              />
            </RowPayDate>
          )}
        </SalesCommissionSubContainer>
      </SalesCommissionContainer> */}
    </Body>

    {props.data.state === "new" ? //     styletype="agree" //     children="ยืนยันการตรวจสอบ" //     onClick={props.onConfirmVerifying} //     icon="check" //   <VerifyButton // <ButtonGroup>
    //   />
    // </ButtonGroup>
    null : (
      <>
        {props.isEdit ? (
          <ButtonGroup>
            <ActionButton
              icon="cancel"
              onClick={props.onCancel}
              children="ยกเลิก"
              styletype="secondary"
            />
            <ActionButton
              icon="floppy"
              onClick={props.handleSubmit(props.onSave)}
              children="บันทึก"
            />
          </ButtonGroup>
        ) : (
          <ButtonGroup>
            <ApproveButton
              icon="check"
              onClick={props.onApproved}
              children="อนุมัติการแก้ไข"
              styletype="agree"
              authorize={{
                roles: ["member.accountant_approver"],
              }}
            />
            <ActionButton icon="edit" onClick={props.onEdit} children="แก้ไข" />
          </ButtonGroup>
        )}
      </>
    )}
    <Modal
      open={props.toggleModal}
      onClose={props.onCloseModal}
      message={props.modalMessage}
      onApproved={props.verifyCommissions}
    />
    <ApproveModal
      open={props.toggleApproveModal}
      onClose={props.onCloseApproveModal}
      message={props.modalMessage}
      onApproved={props.onApprove}
    />
  </Layout>
)

const enhancer = compose(
  withRouter(),
  withStores((stores) => ({
    fetchById: stores.saleCommissionStore.fetchSalesCommissionOrdersById,
    data: stores.saleCommissionStore.current,
    fetchConfig: stores.saleCommissionStore.fetchSalesCommissionOrdersConfig,
    updateSalesCommissionOrders: stores.saleCommissionStore.updateSalesCommissionOrders,
    editingApprove: stores.saleCommissionStore.editingApprove,
    salesCommissionVerify: stores.saleCommissionStore.salesCommissionVerify,
    initialValues: stores.saleCommissionStore.current,
    stateOptions: stores.saleCommissionStore.$("configs.attributes.state.options", []),
    memberStatusOptions: stores.saleCommissionStore.$(
      "configs.attributes.memberStatus.options",
      []
    ),
    salesCommissionAffiliateTeam: stores.saleCommissionStore.$(
      "current.salesCommissionMaster.salesCommissionAffiliateTeam"
    ),
  })),
  withForm({
    form: "showSalesCommissionOrderDetail",
  }),
  withHooks((props) => {
    const [loading, setLoading] = useState(false)
    const [
      toggleCalculateCommissionExportedDate,
      setToggleCalculateCommissionExportedDate,
    ] = useState(false)
    const [toggleSalesCommissionExportedDate, setToggleSalesCommissionExportedDate] = useState(
      false
    )
    const [togglePendingCommissionExportedDate, setTogglePendingCommissionExportedDate] = useState(
      false
    )
    const [isEdit, setIsEdit] = useState(false)

    const [modalMessage, setModalMessage] = useState({
      title: "",
      message: "",
      leftButtonCaption: "",
      rightButtonCaption: "",
    })
    const [toggleModal, setToggleModal] = useState(false)
    const [toggleApproveModal, setToggleApproveModal] = useState(false)

    useEffect(() => {
      setLoading(true)
      Promise.all([props.fetchConfig(), props.fetchById(props.match.params.saleCommissionId)])
      setLoading(false)
    }, [])

    const state = useMemo(() => {
      const state = (props.stateOptions.find((option) => option.value === props.data.state) || {})
        .label
      return state
    }, [props.data.state])

    const editCalculateCommissionExportedDate = useCallback(() => {
      setToggleCalculateCommissionExportedDate(true)
    })

    const editSalesCommissionExportedDate = useCallback(() => {
      setToggleSalesCommissionExportedDate(true)
    })

    const editPendingCommissionExportedDate = useCallback(() => {
      setTogglePendingCommissionExportedDate(true)
    })

    const onEdit = useCallback(() => {
      setIsEdit(true)
      setToggleCalculateCommissionExportedDate(true)
      setToggleSalesCommissionExportedDate(true)
      setTogglePendingCommissionExportedDate(true)
    })

    const onCancel = useCallback(() => {
      props.reset(props.form)
      setIsEdit(false)
      setToggleCalculateCommissionExportedDate(false)
      setToggleSalesCommissionExportedDate(false)
      setTogglePendingCommissionExportedDate(false)
    })

    const onSave = useCallback(
      async (values) => {
        await props.updateSalesCommissionOrders(props.data.id, {
          calculateCommissionExportedDate: values.calculateCommissionExportedDate,
          salesCommissionExportedDate: values.salesCommissionExportedDate,
          pendingCommissionExportedDate: values.pendingCommissionExportedDate,
          commissionAmount: values.commissionAmount,
          commissionReceivedDate: values.commissionReceivedDate,
        })

        setIsEdit(false)
        setToggleCalculateCommissionExportedDate(false)
        setToggleSalesCommissionExportedDate(false)
        setTogglePendingCommissionExportedDate(false)
      },
      [props.values]
    )

    const onApprove = useCallback(async () => {
      await props.editingApprove(props.data.id)
      setToggleApproveModal(false)
      props.history.push(path.saleCommissionsPath())
    }, [props.data.id])

    const verifyCommissions = useCallback(async () => {
      await props.salesCommissionVerify({
        salesCommissionMasterIds: [props.data.id],
      })
      setToggleModal(false)
      props.history.push(path.saleCommissionsPath())
    }, [props.data.id])

    const onCloseModal = useCallback(() => {
      setToggleModal(false)
    })

    const onCloseApproveModal = useCallback(() => {
      setToggleApproveModal(false)
    })

    const onConfirmVerifying = useCallback(() => {
      setToggleModal(!toggleModal)
      setModalMessage({
        title: "ตรวจสอบคอมมิชชั่น",
        message: (
          <MessageModal>
            ยืนยันการตรวจสอบข้อมูลคอมมิชชั่นจ่ายหรือไม่
            <div>
              (สถานะจะถูกเปลี่ยนเป็น <span>คำนวณคอมมิชชั่น</span>)
            </div>
          </MessageModal>
        ),
        leftButtonCaption: "ยืนยันความถูกต้อง",
        rightButtonCaption: "ย้อนกลับ",
      })
    })

    const onApproved = useCallback(() => {
      setToggleApproveModal(!toggleApproveModal)
      setModalMessage({
        title: "อนุมัติคอมมิชชั่น",
        message: <MessageModal>อนุมัติการแก้ไขคอมมิชชั่นจ่ายหรือไม่</MessageModal>,
        leftButtonCaption: "ยืนยันความถูกต้อง",
        rightButtonCaption: "ย้อนกลับ",
      })
    })

    return {
      loading,
      toggleCalculateCommissionExportedDate,
      toggleSalesCommissionExportedDate,
      togglePendingCommissionExportedDate,
      isEdit,
      modalMessage,
      state,
      toggleModal,
      toggleApproveModal,

      editCalculateCommissionExportedDate,
      editSalesCommissionExportedDate,
      editPendingCommissionExportedDate,
      onEdit,
      onCancel,
      onSave,
      onApprove,
      verifyCommissions,
      onCloseModal,
      onCloseApproveModal,
      onConfirmVerifying,
      onApproved,

      // data: {
      //   salesCommission: [
      //     {
      //       staffCode: "11824255",
      //       fullName: "ฐิตารีย์ บุบผา",
      //       positionName: "ผู้จัดการ",
      //       percent: "0.0",
      //       commissionAmount: "0.00",
      //     },
      //     {
      //       staffCode: "89677430",
      //       fullName: "วรเมธ ชูนำชัย",
      //       positionName: "รองผู้จัดการ",
      //       percent: "12.0",
      //       commissionAmount: "15,056.72",
      //     },
      //     {
      //       staffCode: "56444891",
      //       fullName: "ลาภิน สุขวาณิชวิชัย",
      //       positionName: "หัวหน้าทีมขาย",
      //       percent: "25.0",
      //       commissionAmount: "30,113.44",
      //     },
      //   ],
      // },
    }
  })
)

// const enhancer = compose(
//   withRouter(),
//   withState("loading", "setLoading", false),
//   withState(
//     "toggleCalculateCommissionExportedDate",
//     "setToggleCalculateCommissionExportedDate",
//     false
//   ),
//   withState("toggleSalesCommissionExportedDate", "setToggleSalesCommissionExportedDate", false),
//   withState("togglePendingCommissionExportedDate", "setTogglePendingCommissionExportedDate", false),
//   withState("isEdit", "setIsEdit", false),
//   withStores((stores) => ({
//     fetchById: stores.saleCommissionStore.fetchSalesCommissionOrdersById,
//     data: stores.saleCommissionStore.current,
//     fetchConfig: stores.saleCommissionStore.fetchSalesCommissionOrdersConfig,
//     updateSalesCommissionOrders: stores.saleCommissionStore.updateSalesCommissionOrders,
//     editingApprove: stores.saleCommissionStore.editingApprove,
//     salesCommissionVerify: stores.saleCommissionStore.salesCommissionVerify,
//     initialValues: stores.saleCommissionStore.current,
//     stateOptions: stores.saleCommissionStore.$("configs.attributes.state.options", []),
//     memberStatusOptions: stores.saleCommissionStore.$(
//       "configs.attributes.memberStatus.options",
//       []
//     ),
//   })),

//   withProps((props) => {
//     let state
//     if (props.data.state) {
//       state = (props.stateOptions.find((option) => option.value === props.data.state) || {}).label
//     }
//     let salesmanStatus
//     if (props.data.salesmanStatus) {
//       salesmanStatus = (
//         props.memberStatusOptions.find((option) => option.value === props.data.salesmanStatus) || {}
//       ).label
//     }
//     const initialValues = {
//       ...props.data,
//       salesmanStatus,
//     }
//     return {
//       state,
//       initialValues,
//     }
//   }),
//   withForm({
//     form: "showSalesCommissionOrderDetail",
//   }),
//   withState("modalMessage", "setModalMessage", {
//     title: "",
//     message: "",
//     leftButtonCaption: "",
//     rightButtonCaption: "",
//   }),
//   withState("toggleModal", "setToggleModal", false),
//   withState("toggleApproveModal", "setToggleApproveModal", false),
//   withHandlers({
//     editCalculateCommissionExportedDate: (props) => () => {
//       props.setToggleCalculateCommissionExportedDate(true)
//     },
//     editSalesCommissionExportedDate: (props) => () => {
//       props.setToggleSalesCommissionExportedDate(true)
//     },
//     editPendingCommissionExportedDate: (props) => () => {
//       props.setTogglePendingCommissionExportedDate(true)
//     },
//     onEdit: (props) => () => {
//       props.setIsEdit(true)
//       props.setToggleCalculateCommissionExportedDate(true)
//       props.setToggleSalesCommissionExportedDate(true)
//       props.setTogglePendingCommissionExportedDate(true)
//     },
//     onCancel: (props) => () => {
//       props.reset("showSalesCommissionOrderDetail")
//       props.setIsEdit(false)
//       props.setToggleCalculateCommissionExportedDate(false)
//       props.setToggleSalesCommissionExportedDate(false)
//       props.setTogglePendingCommissionExportedDate(false)
//     },
//     onSave: (props) =>
//       props.handleSubmit(async (values) => {
//         await props.updateSalesCommissionOrders(props.data.id, {
//           calculateCommissionExportedDate: values.calculateCommissionExportedDate,
//           salesCommissionExportedDate: values.salesCommissionExportedDate,
//           pendingCommissionExportedDate: values.pendingCommissionExportedDate,
//           commissionAmount: values.commissionAmount,
//           commissionReceivedDate: values.commissionReceivedDate,
//         })

//         props.setIsEdit(false)
//         props.setToggleCalculateCommissionExportedDate(false)
//         props.setToggleSalesCommissionExportedDate(false)
//         props.setTogglePendingCommissionExportedDate(false)
//       }),
//     onApprove: (props) => async () => {
//       await props.editingApprove(props.data.id)
//       props.setToggleApproveModal(false)
//       props.history.push(path.saleCommissionsPath())
//     },
//     verifyCommissions: (props) => async () => {
//       await props.salesCommissionVerify({
//         salesCommissionOrderIds: [props.data.id],
//       })
//       props.setToggleModal(false)
//       props.history.push(path.saleCommissionsPath())
//     },
//     onCloseModal: (props) => () => {
//       props.setToggleModal(false)
//     },
//     onCloseApproveModal: (props) => () => {
//       props.setToggleApproveModal(false)
//     },
//     onConfirmVerifying: (props) => async () => {
//       props.setToggleModal(!props.toggleModal)
//       props.setModalMessage({
//         title: "ตรวจสอบคอมมิชชั่น",
//         message: (
//           <MessageModal>
//             ยืนยันการตรวจสอบข้อมูลคอมมิชชั่นจ่ายหรือไม่
//             <div>
//               (สถานะจะถูกเปลี่ยนเป็น <span>คำนวณคอมมิชชั่น</span>)
//             </div>
//           </MessageModal>
//         ),
//         leftButtonCaption: "ยืนยันความถูกต้อง",
//         rightButtonCaption: "ย้อนกลับ",
//       })
//     },
//     onApproved: (props) => async () => {
//       props.setToggleApproveModal(!props.toggleApproveModal)
//       props.setModalMessage({
//         title: "อนุมัติคอมมิชชั่น",
//         message: <MessageModal>อนุมัติการแก้ไขคอมมิชชั่นจ่ายหรือไม่</MessageModal>,
//         leftButtonCaption: "ยืนยันความถูกต้อง",
//         rightButtonCaption: "ย้อนกลับ",
//       })
//     },
//   }),
//   lifecycle({
//     async componentDidMount() {
//       this.props.setLoading(true)
//       await Promise.all([
//         this.props.fetchConfig(),
//         this.props.fetchById(this.props.match.params.saleCommissionId),
//       ])
//       this.props.setLoading(false)
//     },
//   })
// )

export default enhancer(ViewAsOrderDetail)
