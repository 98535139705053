import { compose, withHandlers, lifecycle, withState, withProps } from "recompose"
import { withForm, withStores, withHooks } from "@enhancers/index"
import { Detail } from "../show/index"
import * as path from "@common/path"
import { useEffect } from "react"

const enhancer = compose(
  withState("isEdit", "setIsEdit", false),
  withState("item", "setItem", 0),
  withState("lifeIdItem", "setLifeIdItem", 0),
  withState("itemList", "setItemList", []),
  withState("isAdd", "setIsAdd", false),
  withState("isShowModalAdd", "setIsShowModalAdd", false),
  withStores((stores, props) => {
    let CustomCurrent = {}
    let CustomOptionRider = stores.riderStore.$("configs.configs.attributes.riderId.options", [])
    let CustomOptionLife = stores.riderStore.$("configs.configs.attributes.lifeId.options", [])
    const allData = stores.riderStore.all

    for (let index = 0; index < allData.length; index++) {
      if (allData.length !== 0) {
        CustomOptionLife = CustomOptionLife.filter((x) => x.value !== allData[index].lifeId)
      }
    }

    for (let index = 0; index < props.itemList.length; index++) {
      CustomOptionRider = CustomOptionRider.filter((x) => x.value !== props.itemList[index])
    }

    for (let index = 0; index < props.itemList.length; index++) {
      CustomCurrent = {
        ...CustomCurrent,
        [`createRider${index}`]: props.itemList[index],
      }
    }
    return {
      current: stores.riderStore.current,
      fetchData: stores.riderStore.fetch,
      fetchOne: stores.riderStore.fetchOne,
      leadIdOptions: CustomOptionLife,
      originalPolicyOptions: stores.riderStore.$("configs.configs.attributes.lifeId.options", []),
      originalRiderOptions: stores.riderStore.$("configs.configs.attributes.riderId.options", []),
      riderIdOptions: CustomOptionRider,
      initialValues: {
        ...CustomCurrent,
        RiderName: props.item,
        lifeId: props.lifeIdItem,
      },
      update: stores.riderStore.updateRider,
      riderMapping: stores.riderStore.all,
    }
  }),

  withProps((props) => {
    const policyOptions = props.originalPolicyOptions.filter((policy) => policy.state === "online")
    return {
      policyOptions,
    }
  }),
  withForm({ form: "newRiderInfoForm" }),
  withState("riderData", "setRiderData", []),
  withHandlers({
    onCloseAdd: (props) => () => {
      props.setIsShowModalAdd(!props.isShowModalAdd)
    },
    onDelete: (props) => (e) => () => {
      let newItemList = props.itemList
      newItemList.splice(e, 1)
      props.setItemList(newItemList)
    },
    addItem: (props) => () => {
      if (props.item) {
        let newItemList = props.itemList
        newItemList.push(props.item)
        props.setItemList(newItemList)
        props.setItem("")
        props.setIsShowModalAdd(!props.isShowModalAdd)
      }
    },
    onCancel: (props) => () => {
      props.history.goBack()
    },
    onSave: (props) =>
      props.handleSubmit(async (values) => {
        values = { lifeId: values.lifeId, riderIds: props.riderData }
        try {
          await props.update(values)
          props.history.push(path.ridersPath())
        } catch (error) {
          if (error) {
            window.alert(error)
          } else {
            window.alert("จับคู่สัญญาเพิ่มเติมไม่สำเร็จ")
          }
        }
      }),
    selectRider: (props) => (rider, checked) => {
      const data = props.riderData.filter((item) => item !== rider.value)
      if (checked) {
        data.push(rider.value)
      }
      props.setRiderData(data)
    },
  }),

  lifecycle({
    async componentDidMount() {
      await this.props.fetchData()
    },
  }),
  withState("riderOptions", "setRiderOptions", []),
  withHooks((props) => {
    useEffect(() => {
      const policyInsurerId = (
        props.policyOptions.find((policy) => policy.value === props.values.lifeId) || {}
      ).insuranceCompanyId
      const riderOptions = props.originalRiderOptions.filter(
        (rider) => rider.insuranceCompanyId === policyInsurerId && rider.state === "online"
      )
      const riderMapping =
        props.riderMapping.find((rider) => rider.lifeId === props.values.lifeId) || {}
      props.setRiderData(riderMapping.riderIds)
      props.setRiderOptions(riderOptions)
    }, [props.values.lifeId])
  })
)

export default enhancer(Detail)
