import React, { useState, useEffect } from "react"
import styled from "@styled"
import { Icon } from "@components"
import { Popup } from "@progress/kendo-react-popup"
import { DateRange } from "react-date-range"

import "react-date-range/dist/styles.css"
import "react-date-range/dist/theme/default.css"

const Value = styled.button`
  position: relative;

  width: 250px;
  height: 56px;
  padding: 0 52px 0 22px;

  color: #666;
  font-size: 16px;
  font-weight: normal;
  text-align: left;
  background-color: white;
  border: 1px solid #eeeef2;
  border-radius: 5px;
  white-space: nowrap;

  cursor: pointer;
  :hover {
    background-color: white;
  }
  :focus {
    outline: none;
  }
`

const CalendarIcon = styled(Icon)`
  position: absolute;
  right: 20px;

  font-size: 20px;
`

const PopupContainer = styled.div`
  position: relative;

  width: 250px;
`

function dateFormatter(input) {
  if (!input) return null
  const splitedDate = input.toLocaleDateString().split("/")
  return `${splitedDate[1]}/${splitedDate[0]}/${splitedDate[2]}`
}

function getDateRangeFromMonthAndYear(month, year) {
  var startDate = null
  var endDate = null
  if (!month || !year) return { startDate, endDate }

  switch (month) {
    case "January":
      startDate = new Date(year, 0, 1)
      endDate = new Date(year, 0, 31)
      break
    case "February":
      const isLeap = new Date(year, 1, 29).getMonth() == 1
      startDate = new Date(year, 1, 1)
      endDate = new Date(year, 1, isLeap ? 29 : 28)
      break
    case "March":
      startDate = new Date(year, 2, 1)
      endDate = new Date(year, 2, 31)
      break
    case "April":
      startDate = new Date(year, 3, 1)
      endDate = new Date(year, 3, 30)
      break
    case "May":
      startDate = new Date(year, 4, 1)
      endDate = new Date(year, 4, 31)
      break
    case "June":
      startDate = new Date(year, 5, 1)
      endDate = new Date(year, 5, 30)
      break
    case "July":
      startDate = new Date(year, 6, 1)
      endDate = new Date(year, 6, 31)
      break
    case "August":
      startDate = new Date(year, 7, 1)
      endDate = new Date(year, 7, 31)
      break
    case "September":
      startDate = new Date(year, 8, 1)
      endDate = new Date(year, 8, 30)
      break
    case "October":
      startDate = new Date(year, 9, 1)
      endDate = new Date(year, 9, 31)
      break
    case "November":
      startDate = new Date(year, 10, 1)
      endDate = new Date(year, 10, 30)
      break
    case "December":
      startDate = new Date(year, 11, 1)
      endDate = new Date(year, 11, 31)
      break
    default:
      break
  }

  return { startDate, endDate }
}

function getDateRangeFromQuarterAndYear(quarter, year) {
  var startDate = null
  var endDate = null
  if (!quarter || !year) return { startDate, endDate }

  switch (quarter) {
    case "Q1":
      startDate = new Date(year, 0, 1)
      endDate = new Date(year, 2, 31)
      break
    case "Q2":
      startDate = new Date(year, 3, 1)
      endDate = new Date(year, 5, 30)
      break
    case "Q3":
      startDate = new Date(year, 6, 1)
      endDate = new Date(year, 8, 30)
      break
    case "Q4":
      startDate = new Date(year, 9, 1)
      endDate = new Date(year, 11, 31)
      break
    default:
      break
  }

  return { startDate, endDate }
}

function getDateRangeFromYear(year) {
  var startDate = null
  var endDate = null
  if (!year) return { startDate, endDate }

  startDate = new Date(year, 0, 1)
  endDate = new Date(year, 11, 31)
  return { startDate, endDate }
}

class FilterDate extends React.Component {
  anchor = null
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      value: {
        date: {
          startDate: new Date(),
          endDate: new Date(),
        },
        month: {
          month: null,
          year: null,
        },
        quarter: {
          quarter: null,
          year: null,
        },
        year: new Date().getFullYear(),
      },
      submitValue: new Date().getFullYear(),
      submitType: "yearly",
      periodType: "yearly",
    }
  }

  onSubmit = () => {
    switch (this.state.periodType) {
      case "daily":
        this.setState({
          ...this.state,
          submitType: this.state.periodType,
          submitValue: this.state.value.date,
        })

        var { startDate, endDate } = this.state.value.date
        this.props.onSubmit({
          periodType: this.state.periodType,
          submittedAt: {
            min: startDate,
            max: endDate,
          },
        })
        break
      case "monthly":
        this.setState({
          ...this.state,
          submitType: this.state.periodType,
          submitValue: this.state.value.month,
        })

        var { month, year } = this.state.value.month
        var { startDate, endDate } = getDateRangeFromMonthAndYear(month, year)
        this.props.onSubmit({
          periodType: this.state.periodType,
          submittedAt: {
            min: startDate,
            max: endDate,
          },
        })
        break
      case "quarterly":
        this.setState({
          ...this.state,
          submitType: this.state.periodType,
          submitValue: this.state.value.quarter,
        })

        var { quarter, year } = this.state.value.quarter
        var { startDate, endDate } = getDateRangeFromQuarterAndYear(quarter, year)
        this.props.onSubmit({
          periodType: this.state.periodType,
          submittedAt: {
            min: startDate,
            max: endDate,
          },
        })
        break
      case "yearly":
        this.setState({
          ...this.state,
          submitType: this.state.periodType,
          submitValue: this.state.value.year,
        })

        var { startDate, endDate } = getDateRangeFromYear(this.state.value.year)
        this.props.onSubmit({
          periodType: this.state.periodType,
          submittedAt: {
            min: startDate,
            max: endDate,
          },
        })
        break
      default:
        break
    }
    this.setState({
      show: false,
    })
  }

  onToggle = () => {
    this.setState({ show: !this.state.show })
  }

  onValueChange = (date) => {
    this.setState({
      ...this.state,
      value: date,
    })
  }

  onPeriodChange = (value) => {
    this.setState({
      ...this.state,
      periodType: value,
    })
  }

  onDateChange = (value) => {
    this.setState({
      ...this.state,
      value: {
        ...this.state.value,
        date: value,
      },
    })
  }

  onMonthChange = (value) => {
    this.setState({
      ...this.state,
      value: {
        ...this.state.value,
        month: value,
      },
    })
  }

  onQuarterChange = (value) => {
    this.setState({
      ...this.state,
      value: {
        ...this.state.value,
        quarter: value,
      },
    })
  }

  onYearChange = (value) => {
    this.setState({
      ...this.state,
      value: {
        ...this.state.value,
        year: value,
      },
    })
  }

  displayValue = () => {
    switch (this.state.submitType) {
      case "daily":
        if (this.state.submitValue) {
          var { startDate, endDate } = this.state.submitValue
          return `${dateFormatter(startDate)} - ${dateFormatter(endDate)}`
        }
        return ""
      case "monthly":
        if (this.state.submitValue) {
          var { month, year } = this.state.submitValue
          var { startDate, endDate } = getDateRangeFromMonthAndYear(month, year)
          return `${dateFormatter(startDate)} - ${dateFormatter(endDate)}`
        }
        return ""
      case "quarterly":
        if (this.state.submitValue) {
          var { quarter, year } = this.state.submitValue
          var { startDate, endDate } = getDateRangeFromQuarterAndYear(quarter, year)
          return `${dateFormatter(startDate)} - ${dateFormatter(endDate)}`
        }
        return ""
      case "yearly":
        if (this.state.submitValue) {
          var { startDate, endDate } = getDateRangeFromYear(this.state.submitValue)
          return `${dateFormatter(startDate)} - ${dateFormatter(endDate)}`
        }
        return ""
      default:
        return ""
    }
  }

  onClickOnSide = (event) => {
    const filterValue = document.getElementById("filter-date__value")
    const filterSelector = document.getElementById("filter-date__selector")
    if (filterValue && filterSelector) {
      var isClickInside =
        filterValue.contains(event.target) || filterSelector.contains(event.target)

      if (!isClickInside) this.onToggle()
    }
  }

  componentDidMount() {
    document.addEventListener("click", this.onClickOnSide)
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onClickOnSide)
  }

  render() {
    return (
      <>
        <Value
          id="filter-date__value"
          onClick={this.onToggle}
          ref={(button) => {
            this.anchor = button
          }}
        >
          {this.displayValue()}
          <CalendarIcon icon="calendar" />
        </Value>
        <Popup anchor={this.anchor} animate={false} show={this.state.show}>
          <PopupContainer>
            <Filter
              value={this.state.value}
              onChange={this.onValueChange}
              periodType={this.state.periodType}
              onPeriodChange={this.onPeriodChange}
              onDateChange={this.onDateChange}
              onMonthChange={this.onMonthChange}
              onQuarterChange={this.onQuarterChange}
              onYearChange={this.onYearChange}
              onSubmit={this.onSubmit}
            />
          </PopupContainer>
        </Popup>
      </>
    )
  }
}

export default FilterDate

const FilterContainer = styled.div`
  position: absolute;
  right: 0;

  min-width: 377px;
  padding: 25px 20px;

  background-color: white;
  border: 1px solid #eeeef2;
  border-radius: 5px;
`

const FilterSelector = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 23px;
  overflow: hidden;

  > button {
    :first-of-type {
      border-radius: 5px 0 0 5px;
    }
    :last-of-type {
      border-radius: 0 5px 5px 0;
    }
  }
`

const Selector = styled.button`
  width: 25%;
  max-width: 84px;
  height: 38px;

  color: white;
  font-size: 16px;
  background-color: ${(props) => (props.active ? "#025AA5" : "#0275d8")};
  border: none;
  cursor: pointer;
  :focus {
    outline: none;
  }
`

const SubmitButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 166px;
  height: 56px;
  margin: 23px auto 0;

  color: ${(props) => (props.disabled ? "#eeeef2" : "white")};
  background-color: ${(props) => (props.disabled ? "white" : "#025aa5")};
  border-color: 1px solid ${(props) => (props.disabled ? "#eeeef2" : "#025aa5")};
  border-radius: 5px;
  border: none;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  :focus {
    outline: none;
  }
`

const PERIOD_OPTIONS = [
  { label: "วัน", value: "daily" },
  { label: "เดือน", value: "monthly" },
  { label: "ไตรมาส", value: "quarterly" },
  { label: "ปี", value: "yearly" },
]

const Filter = (props) => {
  var disabled = true
  switch (props.periodType) {
    case "daily":
      if (props.value.date) {
        disabled = false
      }
      break
    case "monthly":
      if (props.value.month && props.value.month.month) {
        disabled = false
      }
      break
    case "quarterly":
      if (props.value.quarter && props.value.quarter.quarter) {
        disabled = false
      }
      break
    case "yearly":
      if (props.value.year) {
        disabled = false
      }
      break
    default:
      break
  }
  return (
    <FilterContainer id="filter-date__selector">
      <FilterSelector>
        {PERIOD_OPTIONS.map((option, index) => (
          <Selector
            key={index}
            type="button"
            active={props.periodType === option.value}
            onClick={() => props.onPeriodChange(option.value)}
          >
            {option.label}
          </Selector>
        ))}
      </FilterSelector>
      {props.periodType === "daily" && (
        <DateRangePicker value={props.value.date} onChange={props.onDateChange} />
      )}
      {props.periodType === "monthly" && (
        <MonthPicker value={props.value.month} onChange={props.onMonthChange} />
      )}
      {props.periodType === "quarterly" && (
        <QuarterPicker value={props.value.quarter} onChange={props.onQuarterChange} />
      )}
      {props.periodType === "yearly" && (
        <YearlyPicker value={props.value.year} onChange={props.onYearChange} />
      )}
      <SubmitButton type="button" onClick={props.onSubmit} disabled={disabled}>
        Apply
      </SubmitButton>
    </FilterContainer>
  )
}

const DateRangeContainer = styled.div``

const StyleDateRange = styled(DateRange)`
  display: flex;
  width: 266px;
  margin: 0 auto;

  .rdrDateDisplayWrapper {
    display: none;
  }

  .rdrWeekDays {
    border-bottom: 1px solid #a7afb2;
  }

  .rdrMonth {
    width: 266px;
    padding: 0;
  }

  .rdrDay {
    width: 38px;
    height: 48px;
  }

  .rdrDay:not(.rdrDayPassive) {
    .rdrStartEdge,
    .rdrEndEdge {
      border-radius: 50%;
    }
    .rdrStartEdge ~ .rdrDayNumber {
      background-color: rgba(2, 117, 216, 0.2);
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
      left: 0;
    }
    .rdrEndEdge ~ .rdrDayNumber {
      background-color: rgba(2, 117, 216, 0.2);
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
      right: 0;
    }

    .rdrInRange {
      color: #0275d8 !important;
      opacity: 0.2;
    }
  }

  .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
    color: rgba(102, 102, 102, 1);
  }

  .rdrDayEndOfMonth .rdrInRange,
  .rdrDayEndOfMonth .rdrStartEdge,
  .rdrDayEndOfWeek .rdrInRange,
  .rdrDayEndOfWeek .rdrStartEdge {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  }
  .rdrDayStartOfMonth .rdrInRange,
  .rdrDayStartOfMonth .rdrEndEdge,
  .rdrDayStartOfWeek .rdrInRange,
  .rdrDayStartOfWeek .rdrEndEdge {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
  }

  .rdrDayNumber span {
    font-size: 12px;
  }

  .rdrWeekDay {
    font-size: 12px;
    line-height: 42px;
  }
`

const DateRangeValueContainer = styled.div`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 38px;
  padding: 0 32px;
  margin-bottom: 26px;

  color: #666;
  font-size: 16px;
  font-weight: normal;
  text-align: left;
  background-color: white;
  border: 1px solid #eeeef2;
  border-radius: 5px;
  white-space: nowrap;
`

const DateRangeIcon = styled(CalendarIcon)`
  right: 10px;
`

const DateRangePicker = (props) => {
  const [value, setValue] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ])

  const onDateChange = (item) => {
    setValue([item.selection])
    props.onChange({
      startDate: item.selection.startDate,
      endDate: item.selection.endDate,
    })
  }

  useEffect(() => {
    if (props.value) {
      setValue([
        {
          startDate: props.value.startDate,
          endDate: props.value.endDate,
          key: "selection",
        },
      ])
    }
  }, [])

  const getLabel = (date) => `${SHORT_MONTH[date.getMonth()]} ${date.getFullYear()}`

  return (
    <DateRangeContainer>
      <DateRangeValueContainer>
        {value[0].startDate ? dateFormatter(value[0].startDate) : "ตั้งแต่วันที่"}
        {" - "}
        {value[0].endDate ? dateFormatter(value[0].endDate) : "ถึงวันที่"}
        <DateRangeIcon icon="calendar" />
      </DateRangeValueContainer>
      <StyleDateRange
        editableDateInputs={true}
        onChange={onDateChange}
        moveRangeOnFirstSelection={false}
        ranges={value}
        navigatorRenderer={(curr, onChange, props) => {
          function onBackClick() {
            const newDate = new Date(curr)
            const prevMonth = new Date(newDate.setMonth(newDate.getMonth() - 1))
            onChange(prevMonth)
          }
          function onNextClick() {
            const newDate = new Date(curr)
            const nextMonth = new Date(newDate.setMonth(newDate.getMonth() + 1))
            onChange(nextMonth)
          }
          return (
            <FilterHeader
              label={getLabel(curr)}
              onBackClick={onBackClick}
              onNextClick={onNextClick}
            />
          )
        }}
        weekdayDisplayFormat="EEEEE"
      />
    </DateRangeContainer>
  )
}

const MonthContainer = styled.div`
  margin-bottom: -16px;

  > button {
    :nth-of-type(odd) {
      margin-right: 14px;
    }
  }
`

const MonthSelector = styled.button`
  width: 160px;
  height: 56px;
  margin-bottom: 16px;

  color: ${(props) => (props.active ? "white" : "#0275d8")};
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #0275d8;
  background-color: ${(props) => (props.active ? "#0275d8" : "white")};
  cursor: pointer;
  :focus {
    outline: none;
  }
`

const SHORT_MONTH = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
]

const MONTH = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

const MonthPicker = (props) => {
  const [year, setYear] = useState()

  useEffect(() => {
    if (props.value && props.value.year) {
      setYear(props.value.year)
    } else {
      const thisYear = new Date().getFullYear()
      setYear(thisYear)
    }
  }, [])

  const onBackClick = () => {
    const prevYear = year - 1
    setYear(prevYear)
    props.onChange({
      month: props.value.month,
      year: prevYear,
    })
  }
  const onNextClick = () => {
    const nextYear = year + 1
    setYear(nextYear)
    props.onChange({
      month: props.value.month,
      year: nextYear,
    })
  }
  return (
    <MonthContainer>
      <FilterHeader
        label={`${
          props.value.month ? `${SHORT_MONTH[MONTH.indexOf(props.value.month)]} : ` : ""
        }${year}`}
        onBackClick={onBackClick}
        onNextClick={onNextClick}
      />
      {MONTH.map((m, i) => (
        <MonthSelector
          key={i}
          active={props.value.month === m}
          onClick={() =>
            props.onChange({
              month: m,
              year: year,
            })
          }
        >
          {m}
        </MonthSelector>
      ))}
    </MonthContainer>
  )
}

const QuarterContainer = styled.div`
  > button {
    :last-of-type {
      margin-bottom: 0;
    }
  }
`

const QuarterSelector = styled(MonthSelector)`
  width: 100%;
`

const QUARTER = [
  { label: "January-March", value: "Q1" },
  { label: "April-June", value: "Q2" },
  { label: "July-Sebtember", value: "Q3" },
  { label: "October-December", value: "Q4" },
]

const QuarterPicker = (props) => {
  const [year, setYear] = useState()

  useEffect(() => {
    if (props.value && props.value.year) {
      setYear(props.value.year)
    } else {
      const thisYear = new Date().getFullYear()
      setYear(thisYear)
    }
  }, [])

  const onBackClick = () => {
    const prevYear = year - 1
    setYear(prevYear)
    props.onChange({
      quarter: props.value.quarter,
      year: prevYear,
    })
  }
  const onNextClick = () => {
    const nextYear = year + 1
    setYear(nextYear)
    props.onChange({
      quarter: props.value.quarter,
      year: nextYear,
    })
  }
  return (
    <QuarterContainer>
      <FilterHeader
        label={`${props.value.quarter ? `${props.value.quarter} : ` : ""}${year}`}
        onBackClick={onBackClick}
        onNextClick={onNextClick}
      />
      {QUARTER.map((m, i) => (
        <QuarterSelector
          key={i}
          active={props.value.quarter === m.value}
          onClick={() =>
            props.onChange({
              quarter: m.value,
              year: year,
            })
          }
        >
          {m.label}
        </QuarterSelector>
      ))}
    </QuarterContainer>
  )
}

const YearlyContainer = styled.div`
  > button:last-of-type {
    margin-bottom: 0;
  }
`

const YearlySelector = styled(QuarterSelector)``

const YearlyPicker = (props) => {
  const [options, setOptions] = useState([])

  useEffect(() => {
    if (props.value) {
      setOptions(getOptions(props.value))
    } else {
      const thisYear = new Date().getFullYear()
      setOptions(getOptions(thisYear))
    }
  }, [])

  const getOptions = (input) => {
    const lastDigit = (input + "")[3]
    switch (lastDigit) {
      case "1":
      case "6":
        return [input, input + 1, input + 2, input + 3, input + 4]
      case "2":
      case "7":
        return [input - 1, input, input + 1, input + 2, input + 3]
      case "3":
      case "8":
        return [input - 2, input - 1, input, input + 1, input + 2]
      case "4":
      case "9":
        return [input - 3, input - 2, input - 1, input, input + 1]
      case "5":
      case "0":
        return [input - 4, input - 3, input - 2, input - 1, input]
      default:
        break
    }
  }

  const onBackClick = () => {
    const newOptions = options.map((o) => o - 5)
    setOptions(newOptions)
  }

  const onNextClick = () => {
    const newOptions = options.map((o) => o + 5)
    setOptions(newOptions)
  }

  return (
    <YearlyContainer>
      <FilterHeader
        label={`${options[0] || ""} - ${options[4] || ""}`}
        onBackClick={onBackClick}
        onNextClick={onNextClick}
      />
      {options.map((m, i) => (
        <YearlySelector key={i} active={props.value === m} onClick={() => props.onChange(m)}>
          {m}
        </YearlySelector>
      ))}
    </YearlyContainer>
  )
}

const FilterHeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 23px;

  color: #666666;
  font-size: 16px;
`

const FilterLabel = styled.div``

const IconContainer = styled.span``

const FilterArrow = styled(Icon)`
  font-size: 12px;
  color: #0275d8;

  cursor: pointer;
`

const FilterHeader = (props) => {
  const { label, onBackClick, onNextClick } = props
  return (
    <FilterHeaderContainer>
      <IconContainer onClick={onBackClick} style={{ marginRight: "50px" }}>
        <FilterArrow awesomeIcon={["fas", "arrow-left"]} />
      </IconContainer>
      <FilterLabel>{label}</FilterLabel>
      <IconContainer onClick={onNextClick} style={{ marginLeft: "50px" }}>
        <FilterArrow awesomeIcon={["fas", "arrow-right"]} />
      </IconContainer>
    </FilterHeaderContainer>
  )
}
