import React from "react"
import { TreeView } from "@progress/kendo-react-treeview"
import { compose, mapProps, withHandlers, withProps } from "recompose"
import { withField, withCaption, withHidden, withReadOnly } from "@enhancers/index"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import Icons from "./Icon"

const ReadOnly = styled.div`
  min-height: 33.5px;
  display: flex;
  align-items: center;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
`

const ReadOnlyText = styled.span`
  font-family: Sarabun, sans-serif;
  font-size: 16px;
`

const CustomTreeView = styled(TreeView)`
  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;

      .k-checkbox:checked + .k-checkbox-label::before {
        /* border-color: ;
        background-color: #007bff; */

        opacity: 0.5;
      }
    `}

  &.k-widget.k-treeview {
    padding: 0;
    overflow: visible !important;
  }
  .k-mid {
    padding: 0;
    overflow: visible !important;

    .k-in {
      padding: 0;
      font-family: Sarabun, sans-serif;
      font-size: 16px;
    }
  }
  .k-treeview-lines {
    padding: 0;
    margin: 0;
  }

  .k-checkbox-label.k-ripple-target {
    overflow: visible !important;

    .k-ripple {
      z-index: 999;
    }
  }

  .k-item.k-treeview-item {
    padding: 0;
    height: 33.5px;
    display: flex;
  }
  .k-checkbox {
    border-color: #0275d8;
    color: #0275d8;
    background-color: #fff;
    border-radius: 0px;
  }
  .k-checkbox:checked:focus {
    box-shadow: none;
  }
  .k-checkbox::before {
    width: 13px;
    height: 13px;
    font-size: 11px;
    font-weight: bold;
    top: 56%;
    left: 56%;
  }
  .k-treeview .k-in.k-state-focused {
    box-shadow: none;
  }
  .k-ripple {
    display: none;
  }
  .k-checkbox + .k-checkbox-label {
    margin-left: 0;
  }
`

const enhancer = compose(
  withHidden(),
  withField(),
  withCaption(),
  withProps((props) => ({
    value: props.value ? props.value : props.values,
  })),
  withReadOnly((props) => (
    <ReadOnly>
      {props.value ? <Icons icon="checkbox-checked" /> : <Icons icon="checkbox" />}
      &nbsp;
      <ReadOnlyText>{props.value ? props.label : null}</ReadOnlyText>
    </ReadOnly>
  )),
  withHandlers({
    onChange: (props) => () => {
      props.onChange && props.onChange(!props.value)
    },
  }),
  mapProps((props) => ({
    data: [
      {
        text: props.label,
        checked: !!props.value,
      },
    ],
    checkboxes: true,
    onCheckChange: props.onChange,
    onItemClick: props.onChange,
    disabled: props.disabled,
    className: props.className || "" + props.name,
  }))
)

export default enhancer(CustomTreeView)
