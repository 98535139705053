import React, { Fragment } from "react"
import { compose, withHandlers, withProps } from "recompose"
import { withValues, withField, withHidden } from "@enhancers"
import { Input } from "@components"
import SubDistrict from "./SubDistrict"
import District from "./District.js"
import Province from "./Province.js"
import Postcode from "./Postcode.js"

const Address = (props) => (
  <Fragment>
    {/*<Input
      caption="บ้านเลขที่"
      readOnly={props.readOnly}
      {...props.inputs.housingNo}
    />
    <Input
      caption="หมู่ที่"
      readOnly={props.readOnly}
      {...props.inputs.villageNo}
    />
    <Input
      caption="ชื่ออาคาร"
      readOnly={props.readOnly}
      {...props.inputs.building}
    />
    <Input
      caption="ห้องเลขที่"
      readOnly={props.readOnly}
      {...props.inputs.roomNo}
    />*/}
    <Input caption="เลขที่" readOnly={props.readOnly} {...props.inputs.addressCurrentNo} />
    <Input caption="หมู่" readOnly={props.readOnly} {...props.inputs.villageNo} />
    <Input caption="หมู่บ้าน/อาคาร" readOnly={props.readOnly} {...props.inputs.housingEstate} />
    <Input caption="ตรอก/ซอย" readOnly={props.readOnly} {...props.inputs.lane} />
    <Input caption="ถนน" readOnly={props.readOnly} {...props.inputs.road} />
    <SubDistrict
      caption="ตำบล/แขวง"
      readOnly={props.readOnly}
      {...props.inputs.subDistrict}
      onAfterChange={props.onSelectSubDistrict}
    />
    <District caption="อำเภอ/เขต" readOnly={props.readOnly} {...props.inputs.district} />
    <Province caption="จังหวัด" readOnly={props.readOnly} {...props.inputs.province} />
    <Postcode caption="รหัสไปรษณีย์" readOnly={props.readOnly} {...props.inputs.postcode} />
  </Fragment>
)

Address.SubDistrict = SubDistrict
Address.District = District
Address.Province = Province

const fieldNames = [
  "housingNo",
  "villageNo",
  "building",
  "roomNo",
  "addressCurrentNo",
  "housingEstate",
  "lane",
  "road",
  "subDistrict",
  "district",
  "province",
  "postcode",
]

const enhancer = compose(
  withHidden(),
  withField(),
  withValues(...fieldNames),
  withProps((props) =>
    fieldNames.reduce(
      (memo, name) => ({
        ...memo,
        [`${name}Props`]: {
          ...props.inputs[name],
          ...props[`${name}Props`],
        },
      }),
      {}
    )
  ),
  withHandlers({
    onSelectSubDistrict: (props) => (values) => {
      props.onChange({
        ...props.value,
        subDistrict: values.subDistrict,
        district: values.district,
        province: values.province,
        postcode: values.postcode,
      })
    },
  })
)

Address.enhancer = enhancer

export default enhancer(Address)
