import React from "react"
import { compose, withProps, defaultProps } from "recompose"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

const Layout = styled.div`
  display: inline-block;
`

const Body = styled.div`
  width: ${(props) => props.width}px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  position: relative;
`

const Line = styled.div`
  height: 2px;
  position: absolute;
  background: #bec3c7;
  top: 7px;
  left: 3px;
  right: 3px;
`

const ProgressLine = styled(Line)`
  z-index: 1;
  background: #0275d8;
  right: ${(props) => 100 - props.progress}%;
`

const Spot = styled.div`
  height: 10px;
  width: 10px;
  min-width: 10px;
  min-height: 10px;
  border-radius: 50%;
  background: #bec3c7;
  margin: 2.5px;
`

const Label = styled.div`
  width: 80px;
  margin-top: 10px;
  text-align: center;
  font-size: 16px;
  font-family: Sarabun, sans-serif;
  color: #bec3c7;
  line-height: normal;
`

const SpotLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 14px;

  ${(props) =>
    props.passed &&
    css`
      ${Spot} {
        background: #0275d8;
      }
    `};

  ${(props) =>
    props.active &&
    css`
      ${Spot} {
        height: 14px;
        width: 14px;
        min-height: 14px;
        min-width: 14px;
        margin: 0;
        background: #0275d8;
      }
    `};
  z-index: 2;
`
const LabelLine = styled.div`
  text-align: center;
  font-size: 16px;
  font-family: Sarabun, sans-serif;
  color: #bec3c7;

  ${(props) =>
    props.passed &&
    css`
      color: #0275d8;
    `};
  ${(props) =>
    props.active &&
    css`
      color: #0275d8;
      font-weight: bold;
    `};
`

const StatusBar = (props) => (
  <Layout className={props.className}>
    <Body width={(props.options.length - 1) * 100}>
      <Line />
      <ProgressLine progress={props.progress} />
      {props.options.map(({ label, value, passed, active }) => (
        <SpotLayout key={value} passed={passed} active={active}>
          <Spot />
          <Label>
            {label.split(" ").map((labelLine, index) => (
              <LabelLine key={index} passed={passed} active={active}>
                {labelLine}
              </LabelLine>
            ))}
          </Label>
        </SpotLayout>
      ))}
    </Body>
  </Layout>
)

const enhancer = compose(
  defaultProps({
    options: [],
  }),
  withProps((props) => {
    const options = props.options.map((option) => ({
      ...option,
      active: option.value === props.value,
    }))

    const activeIndex = options.findIndex(({ active }) => active)

    options.forEach((option, index) => {
      option.passed = index <= activeIndex
    })

    const progress = activeIndex !== undefined ? (activeIndex / (options.length - 1)) * 100 : 0

    return { progress, options }
  })
)

export default enhancer(StatusBar)
