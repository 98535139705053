import React from "react"
import styled from "@emotion/styled"
import { Button, Field, Checkbox } from "@components"
import { compose, lifecycle, withHandlers, defaultProps } from "recompose"
import BeneficiaryCard from "./BeneficiaryCard"

const Layout = styled.div`
  width: 100%;
  justify-content: center;
`
const AddBeneficiaryButton = styled(Button)`
  max-width: 200px;
  width: 100%;
  font-family: Sarabun, sans-serif;
`
const SectionHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`
const SectionHeader = styled.span`
  font-size: 16px;
  color: #0275d8;
  font-weight: bold;
  font-family: Sarabun, sans-serif;
`

const BeneficiaryField = (props) => (
  <Layout>
    <SectionHeader>ข้อมูลผู้รับผลประโยชน์</SectionHeader>
    <SectionHeaderContainer>
      <Field name="isStatutoryHeir" component={Checkbox} label="มอบให้ทายาทโดยธรรม" />

      {props.fields.length < props.maximumBeneficiaryAmount && !props.isStatutoryHeir && (
        <AddBeneficiaryButton
          icon="plus"
          onClick={() => props.onAddBeneficiary()}
          hidden={props.readOnly}
        >
          เพิ่มผู้รับผลประโยชน์
        </AddBeneficiaryButton>
      )}
    </SectionHeaderContainer>

    {!props.isStatutoryHeir && (
      <>
        {props.fields.map((beneficiary, index) => (
          <BeneficiaryCard
            beneficiary={beneficiary}
            index={index}
            onDeleteBeneficiary={props.onDeleteBeneficiary}
            fields={props.fields}
            genderOptions={props.genderOptions}
            readOnly={props.readOnly}
            change={props.change}
            relationshipOptions={props.relationshipOptions}
          />
        ))}
      </>
    )}
  </Layout>
)

const enhancer = compose(
  defaultProps({ maximumBeneficiaryAmount: 4 }),
  withHandlers({
    onAddBeneficiary: (props) => () => {
      if (props.fields.length < props.maximumBeneficiaryAmount) {
        props.fields.push({})
      }
    },
    onDeleteBeneficiary: (props) => (index) => {
      if (props.fields.length > 1) {
        props.fields.remove(index)
      }
    },
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (this.props.fields.length === 0) {
        this.props.fields.push({})
      }
      if (this.props.fields.length !== prevProps.fields.length) {
        this.props.onItemCountChange && this.props.onItemCountChange()
      }
    },
  })
)

export default enhancer(BeneficiaryField)
