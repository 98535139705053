import React from "react"
import styled from "@emotion/styled"
import { compose, withHandlers } from "recompose"
import { withForm, withStores } from "@enhancers"
import { Form, Field, DialogActionsBar, InputFile, Dropdown } from "@components"

import { CustomDialog as Dialog, CloseButton, SubmitButton } from "@pages/insurers/show/DeleteModal"

const Layout = styled(Form)`
  padding: 17px 62px 40px;

  > div {
    margin-top: 16px !important;
    :first-of-type {
      margin-top: 0 !important;
    }
    :last-of-type {
      margin-top: 8px !important;
    }
  }
`
const CustomDialog = styled(Dialog)`
  .k-widget.k-window.k-dialog {
    width: 544px;
  }
`
const Note = styled.div`
  font-size: 12px;
  width: 180px;
  margin-left: 182px;
`
const CustomDropdown = styled(Dropdown)`
  > div:nth-of-type(2) {
    > span {
      padding: 0px;
      background-color: transparent;
    }
  }
`
const CustomField = styled(Field)`
  [class*="LeftPanel"] > div {
    font-weight: 400;
  }
`

const AddDocumentModal = (props) => (
  <CustomDialog title={props.title} onClose={props.onClose} isShowModal={props.open}>
    <Layout>
      <CustomField
        name="documentType"
        caption="ประเภทเอกสาร"
        component={CustomDropdown}
        options={FILE_TYLE_OPTIONS}
        validate="required"
        readOnly={props.readNameOnly}
        placeholder="โปรดระบุ"
      />
      {props.values.documentType === "marketPlan" && (
        <CustomField
          name="mkPlanId"
          caption="แผนการตลาด"
          component={CustomDropdown}
          options={props.marketPlanOptions}
          readOnly={props.readNameOnly}
          placeholder="โปรดระบุ"
          validate="required"
        />
      )}
      {props.values.documentType === "salesman" && (
        <CustomField
          name="salesGroup"
          caption="กลุ่มงานขาย"
          component={CustomDropdown}
          options={props.salesGroupOptions}
          readOnly={props.readNameOnly}
          placeholder="โปรดระบุ"
          validate="required"
        />
      )}
      <CustomField
        name="file"
        caption="ไฟล์เอกสาร"
        component={InputFile}
        readOnly={props.readFileOnly}
        validate="required"
        children="อัพโหลด"
        accept=".xls, .xlsx"
      />
      <Note>หมายเหตุ: รับไฟล์สกุล .xls, .xlsx เท่านั้น ขนาดไม่เกินไฟล์ละ 2 MB</Note>
    </Layout>

    <DialogActionsBar>
      <CloseButton type="button" onClick={props.onClose} children="ยกเลิก" styletype="delete" />
      <SubmitButton
        type="button"
        onClick={props.onSubmit}
        children="ยืนยัน"
        disabled={props.invalid}
      />
    </DialogActionsBar>
  </CustomDialog>
)

const FILE_TYLE_OPTIONS = [
  {
    label: "รายชื่อพนักงาน",
    value: "salesman",
  },
  {
    label: "รายชื่อผู้แนะนำ",
    value: "affiliates",
  },
  {
    label: "แผนการตลาด",
    value: "marketPlan",
  },
]

const enhancer = compose(
  withStores((stores) => ({
    salesGroupOptions: stores.tqmUserStore.$("configs.attributes.salesGroup.options", []),
    marketPlanOptions: stores.tqmUserStore.$("configs.attributes.marketPlan.options", []),
  })),
  withForm({ form: "AddDocumentForm" }),
  withHandlers({
    onClose: (props) => () => {
      props.reset(props.from)
      props.onClose()
    },
    onSubmit: (props) =>
      props.handleSubmit(async (values) => {
        await props.onAfterAddSubmit(values)
        await props.reset(props.from)
        await props.onClose()
      }),
  })
)
export default enhancer(AddDocumentModal)
