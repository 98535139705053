import BaseStore from "./BaseStore"
import { tqldServer } from "@common/api"

class ActionCodeStore extends BaseStore {
  constructor() {
    super({
      actionCodes: [],
      paging: {
        page: 1,
        perPage: 10,
        totalCount: 0,
        totalPages: 1,
      },
      configs: {},
      current: null,
      footer: {
        actionCodesCount: 0,
      },
    })
  }

  actionCodeLists = async (params) => {
    const response = await tqldServer.tqmBackend.get("/sale_action_masters", params)
    this.actionCodes = response.data.data.saleActionMasters
    this.paging = response.data.data.paging
    this.footer.actionCodesCount = response.data.data.actionCodesCount || 0
  }

  fetchConfigs = async (params) => {
    const response = await tqldServer.tqmBackend.get(`/sale_action_masters/configs`, params)
    this.configs = response.data.data.configs
  }

  fetchById = async (id, params) => {
    const response = await tqldServer.tqmBackend.get(`/sale_action_masters/${id}`, params)
    this.current = response.data.data.saleActionMaster
    return this.current
  }

  addActionCode = async (params) => {
    await tqldServer.tqmBackend.post(`/sale_action_masters`, params)
  }

  updateActionCode = async (id, params) => {
    await tqldServer.tqmBackend.put(`/sale_action_masters/${id}`, params)
  }
}

export default new ActionCodeStore()
