import React from "react"
import styled from "@emotion/styled"
import { Button } from "@components"
import { compose, lifecycle, withHandlers, withProps } from "recompose"
import { isEmpty } from "lodash"
import FieldRider from "./fieldRider"

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`
const FieldContainer = styled.div`
  position: relative;
  margin-top: 14px;

  &:first-of-type {
    margin-top: 0px;
  }
`
const ButtonContainer = styled.div`
  text-align: center;
`
const AddRiderButton = styled(Button)`
  /* width: 160px;
  height: 56px; */
  padding: 15px 20px;
  margin-top: 30px;
  text-align: center;
`
const TrashButton = styled(Button)`
  position: absolute;
  width: 20px;
  border-color: white;
  margin-left: 2px;
  right: 210px;
  top: 160px;
  .k-icon {
    font-size: 20px;
    color: gray;
    :hover {
      color: red;
    }
  }
  :hover {
    border-color: white;
  }
`

const RiderComponent = (props) => (
  <Layout>
    {props.fields.map((RiderComponent, index) => {
      const value = props.riderOptions.find((option) => option.value === props.values[index])
      let customOptions = props.baseOptions
      if (!isEmpty(value)) {
        customOptions = [value, ...props.baseOptions]
      }
      return (
        <FieldContainer key={props.index} PrevLength={props.fieldsLength}>
          <FieldRider
            RiderComponent={RiderComponent}
            index={index}
            fieldsLength={props.fields.length}
            readOnly={props.readOnly}
            customOptions={customOptions}
            commissionOptions={props.commissionOptions}
            fields={props.fields}
            onDelete={props.onDelete}
            baseOptions={props.baseOptions}
            change={props.change}
            insurer={props.insurer}
            disabled={props.disabled}
          />

          {!props.readOnly && !props.disabled && (
            <>
              {props.fields.length - 1 === index ? (
                <ButtonContainer>
                  {props.fields.length > 1 ? (
                    <TrashButton onClick={() => props.onDelete(index)} icon='trash'/>
                    ) : ""
                  }
                  <AddRiderButton onClick={props.onAdd} icon='plus'>
                    เพิ่ม
                  </AddRiderButton>
                </ButtonContainer>

              ) : (
                <TrashButton onClick={() => props.onDelete(index)} icon='trash'/>
              )}
            </>
          )}
        </FieldContainer>
      )
    })}
  </Layout>
)

const enhancer = compose(
  withHandlers({
    onAdd: (props) => () => {
      props.fields.push({})
    },
    onDelete: (props) => (index) => {
      if (props.fields.length > 1) {
        props.fields.remove(index)
      }
    },
  }),
  withProps((props) => {
    const values = (props.fields.getAll() || []).map((rider) => {
      return rider.riderPolicyId
    })
    const baseOptions = props.riderOptions.filter((option) => !values.includes(option.value))
    return { baseOptions, values }
  }),
  lifecycle({
    componentDidMount() {
      if (this.props.fields.length === 0) {
        this.props.fields.push({})
      }
    },
  })
)

export default enhancer(RiderComponent)
