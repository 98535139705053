import BaseStore from "./BaseStore"
import { tqldServer } from "@common/api"

class TeamStore extends BaseStore {
  constructor() {
    super({
      all: [],
      paging: {
        page: 1,
        perPage: 10,
        totalCount: 0,
        totalPages: 1,
      },
      current: null,
      configs: {
        filter: {},
      },
      teamHistories: [],
    })
  }

  fetchConfigs = async (teamId) => {
    if (teamId) {
      const response = await tqldServer.tqmBackend.get(`/teams/${teamId}/configs`)
      this.configs = response.data.data.configs
      return
    }

    const response = await tqldServer.tqmBackend.get(`/teams/configs`)
    this.configs = response.data.data.configs
  }

  create = async (values) => {
    const response = await tqldServer.tqmBackend.post(`/teams`, values)
    this.current = response.data.data.team
    return this.current
  }

  fetch = async (options = {}) => {
    const response = await tqldServer.tqmBackend.get(`/teams`, options)
    this.filter = options
    this.all = response.data.data.teams
    this.paging = response.data.data.paging

    return response.data.data.teams
  }

  fetchById = async (id) => {
    const response = await tqldServer.tqmBackend.get(`/teams/${id}`)
    this.current = response.data.data.team
  }

  update = async (id, values) => {
    const response = await tqldServer.tqmBackend.put(`/teams/${id}`, values)
    this.current = response.data.data.team
  }

  delete = async (id) => {
    await tqldServer.tqmBackend.delete(`/teams/${id}`)
  }

  fetchHistory = async (id, options) => {
    const response = await tqldServer.tqmBackend.get(`/teams/${id}/histories`, options)
    this.teamHistories = response.data.data.teamHistories
    this.paging = response.data.data.paging
  }
}

export default new TeamStore()
