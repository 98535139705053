import React from "react"
import styled from "@emotion/styled"
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout"

const Layout = styled.div`
  flex: 1;
  width: 100%;
`
const Detail = styled.div`
  padding: 10px 20px;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
`

const Ci = (props) => (
  <Layout>
    {props.riderPolicyDetail.totalDiseases !== undefined &&
      props.riderPolicyDetail.totalDiseases.map((title) => (
        <PanelBar>
          <PanelBarItem expanded={true} title={title.category}>
            {title.childs.map((value) => (
              <Detail>{value}</Detail>
            ))}
          </PanelBarItem>
        </PanelBar>
      ))}
  </Layout>
)

export default Ci
