import React, { useMemo, useCallback } from "react"
import styled, { css } from "@styled"
import Icon from "@components/Icon"
import { compose } from "recompose"
import useScrollPosition from "@react-hook/window-scroll"
import { withHooks } from "@enhancers/index"

const Layout = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;

  ${(props) =>
    props.hidden
      ? css`
          visibility: hidden;
          opacity: 0;
          transition: visibility 0s linear 300ms, opacity 300ms;
        `
      : css`
          visibility: visible;
          opacity: 1;
          transition: visibility 0s linear 0s, opacity 300ms;
        `}
`

const Body = styled.div`
  width: 768px;
  height: 48px;
  background-color: rgba(102, 102, 102, 0.2);
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const ScrollLink = styled.div`
  color: #0275d8;
  display: flex;
  align-items: center;
  cursor: pointer;

  :hover {
    color: #025aa5;
  }
`
const Caption = styled.div`
  font-size: 14px;
  text-decoration: underline;
`
const LinkIcon = styled(Icon)`
  font-size: 10px;
  margin-left: 4px;
  margin-bottom: -4px;
`

const ControlTableUpDownStickyTab = (props) => (
  <Layout hidden={!props.visible}>
    <Body>
      <ScrollLink onClick={props.goUp}>
        <Caption>เลื่อนไปหัวตาราง</Caption>
        <LinkIcon awesomeIcon="level-up-alt" />
      </ScrollLink>
      <ScrollLink onClick={props.goDown}>
        <Caption>เลื่อนไปท้ายตาราง</Caption>
        <LinkIcon awesomeIcon="level-down-alt" />
      </ScrollLink>
    </Body>
  </Layout>
)

const enhancer = compose(
  withHooks((props) => {
    const scrollY = useScrollPosition(60)

    const getTableVisibleTopPosition = useCallback(() => {
      const $table = document.querySelector('[data-cy="Grid"]')

      if ($table) {
        const tableRect = $table.getBoundingClientRect()
        return {
          topViewPosition: tableRect.top + window.pageYOffset - 154,
          bottomViewPosition: tableRect.bottom + window.pageYOffset - window.innerHeight + 54,
        }
      } else {
        return {
          topViewPosition: 0,
          bottomViewPosition: 0,
        }
      }
    }, [])

    const visible = useMemo(() => {
      const { topViewPosition, bottomViewPosition } = getTableVisibleTopPosition()
      if (bottomViewPosition > 0) {
        return topViewPosition - 150 < scrollY && scrollY < bottomViewPosition + 50
      } else {
        return false
      }
    }, [scrollY, getTableVisibleTopPosition])

    const goUp = useCallback(() => {
      window.scrollTo({
        top: getTableVisibleTopPosition().topViewPosition,
        left: 0,
        behavior: "smooth",
      })
    }, [getTableVisibleTopPosition])

    const goDown = useCallback(() => {
      window.scrollTo({
        top: getTableVisibleTopPosition().bottomViewPosition,
        left: 0,
        behavior: "smooth",
      })
    }, [getTableVisibleTopPosition])

    return { goUp, goDown, visible }
  })
)

export default enhancer(ControlTableUpDownStickyTab)
