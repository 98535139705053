import React from "react"
import styled from "@emotion/styled"
import { compose, lifecycle, withProps, withState } from "recompose"
import { Avatar, BreadCrumb, Icon, LoadingScope } from "@components"
import { withStores, withForm, withRouter } from "@enhancers"
import * as path from "@common/path"
import defaultLogo from "@assets/images/img-no-insurance.png"
import TableKendo from "./TableKendo"
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout"
import { displayNumber } from "@common/helper"
import moneyBag from "@assets/images/money-bag.svg"

const MainLayout = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const BreadCrumbLayout = styled.div`
  width: 100%;
  a,
  div {
    font-size: 10px;
  }
`
const CustomAvatar = styled(Avatar)`
  border-radius: 5%;
  margin-right: 21px;
`
const InsurancePlan = styled.div`
  font-size: 30px;
  height: 39px;
  font-weight: bold;
  width: max-content;
  color: #666666;
  font-family: "ThaiSans Neue";
`
const CompanyName = styled.div`
  font-size: 16px;
  height: 22px;
  font-family: Sarabun, sans-serif;
`
const HeadContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  width: 550px;
`
const HeaderDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const CustomIcon = styled(Icon)`
  color: #333333;
  font-size: 40px;
  width: 40px;
  height: 40px;
`
const ContainerSecond = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 45px;
`
const ContainerThird = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 117px;
  background-color: rgba(106, 148, 203, 0.1);
  margin-top: 44px;
  padding: 0 14px;
`
const HeaderThird = styled.div`
  font-size: 16px;
  font-weight: bold;
`
const CheckContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: 40px;
`
const CheckIconInfo = styled.div`
  font-size: 16px;
  color: ${(props) => (props.isBenefit ? "#9FD134" : "red")};
  margin-top: 15px;
`
const ContainerDetailIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: 10px;
  max-width: 100%;
  width: 100%;
`
const InfoIcon = styled.div`
  text-align: center;
  width: fit-content;
  font-weight: bold;
  height: 48px;
  font-size: 16px;
  margin-top: 15px;
`
const ImageIcon = styled.img`
  width: 40px;
  height: 40px;
  filter: invert(15%) sepia(5%) saturate(23%) hue-rotate(89deg) brightness(100%) contrast(86%);
`
const IconListLayout = styled.div`
  display: flex;
  flex-direction: row;
`
const ContainerBenefitInfo = styled.div`
  display: flex;
`
const CheckIcon = styled(Icon)`
  font-size: 30px;
  color: ${(props) => (props.isBenefit ? "#9FD134" : "red")};
`
const ContainerFourth = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 45px;
`
const HeaderFourth = styled.div`
  font-size: 16px;
  margin-bottom: 16px;
`
const Description = styled.div`
  color: #d9534f;
  font-size: 14px;
  margin-bottom: 8px;
`
const CustomPanelBar = styled(PanelBar)`
  margin-top: 30px;
  width: 100%;

  .k-animation-container {
    z-index: 10000 !important;

    .k-panel.k-group {
      .k-item.k-state-default {
        .k-link {
          .k-icon.k-i-arrow-s.k-panelbar-expand {
            right: 33.75rem;
          }

          .k-icon.k-i-arrow-n.k-panelbar-collapse {
            right: 33.75rem;
          }
        }
      }
    }
  }

  .k-item.k-state-default {
    .k-link {
      background-color: #fff;
      color: #0275d8;
      font-weight: bold;
      font-family: Sarabun, sans-serif;
      text-decoration: underline;
    }

    .k-link.k-state-selected.k-state-focused {
    }

    .k-link.k-header {
      background-color: #294558;
      color: #fff;
      font-family: Sarabun, sans-serif;
      font-weight: bold;
      text-decoration: none;

      height: 40px;
      :hover {
        background-color: #294558;
        color: #fff;
      }
    }

    .k-link.k-header.k-state-selected {
      background-color: #294558;
      color: #fff;

      :hover {
        background-color: #294558;
        color: #fff;
      }
    }
  }

  .k-item.k-state-default.k-state-expanded {
    .k-link {
      background-color: #fff;
      color: #0275d8;
      font-weight: bold;
      font-family: Sarabun, sans-serif;
      text-decoration: underline;
    }

    .k-link.k-state-selected {
    }

    .k-link.k-state-selected.k-state-focused {
      box-shadow: none;
    }

    .k-link.k-header {
      background-color: #294558;
      color: #fff;
      text-decoration: none;

      :hover {
        background-color: #294558;
        color: #fff;
      }
    }

    .k-link.k-header.k-state-selected {
      background-color: #294558;
      color: #fff;

      :hover {
        background-color: #294558;
        color: #fff;
      }
    }
  }
`
const Note = styled.div`
  font-size: 16px;
  max-width: 666px;
  margin: 30px 20px;
`

const IconList = (props) => {
  return (
    <IconListLayout>
      <ContainerDetailIcon>
        <CustomIcon awesomeIcon="shield-alt" />
        <InfoIcon>
          {`${(props.productDetail.protectionPeriodText || {}).protectPeriodYear || ""}
            ${(props.productDetail.protectionPeriodText || {}).protectPeriodAge || ""}`}
        </InfoIcon>
      </ContainerDetailIcon>

      <ContainerDetailIcon>
        <CustomIcon awesomeIcon="hand-holding-usd" />
        <InfoIcon>
          {`ได้รับเมื่อเสียชีวิต`} <br />
          {`เริ่มต้นที่ ${displayNumber(props.productDetail.firstYearSumInsured)} บาท`}
        </InfoIcon>
      </ContainerDetailIcon>

      <ContainerDetailIcon>
        <ImageIcon src={moneyBag} className={"money-bag"} />
        {/* <CustomIcon awesomeIcon="coins" /> */}
        <InfoIcon>{`${props.productDetail.payPeriodText} เบี้ยปีละ ${displayNumber(
          parseInt(props.productDetail.premium)
        )} บาท`}</InfoIcon>
      </ContainerDetailIcon>

      <ContainerDetailIcon>
        <CustomIcon awesomeIcon="exchange-alt" />
        <InfoIcon>
          {`เงินคืนระหว่างสัญญา/ปี `}
          <br />
          {` ${
            [null, undefined, ""].includes(props.productDetail.annuityRefundPerYear)
              ? "ไม่มี"
              : displayNumber(parseInt(props.productDetail.annuityRefundPerYear))
          }`}
        </InfoIcon>
      </ContainerDetailIcon>
    </IconListLayout>
  )
}

const Benefitinfo = (props) => {
  return (
    <ContainerBenefitInfo>
      <CheckContainer>
        <CheckIcon
          isBenefit={props.productDetail.hasRefund}
          awesomeIcon={props.productDetail.hasRefund ? "check-circle" : "times-circle"}
        />
        <CheckIconInfo isBenefit={props.productDetail.hasRefund}>
          เงินคืนเมื่อครบสัญญา
        </CheckIconInfo>
      </CheckContainer>
      <CheckContainer>
        <CheckIcon
          isBenefit={props.productDetail.hasDividend}
          awesomeIcon={props.productDetail.hasDividend ? "check-circle" : "times-circle"}
        />
        <CheckIconInfo isBenefit={props.productDetail.hasDividend}>เงินปันผล</CheckIconInfo>
      </CheckContainer>
      <CheckContainer>
        <CheckIcon
          isBenefit={props.productDetail.isTaxSaving}
          awesomeIcon={props.productDetail.isTaxSaving ? "check-circle" : "times-circle"}
        />
        <CheckIconInfo isBenefit={props.productDetail.isTaxSaving}>
          สิทธิในการลดหย่อนภาษี
        </CheckIconInfo>
      </CheckContainer>
    </ContainerBenefitInfo>
  )
}

export const ProductShowDetail = (props) => {
  return (
    <>
      {props.loading ? (
        <LoadingScope />
      ) : (
        <MainLayout>
          <BreadCrumbLayout>
            <BreadCrumb links={props.links} />
          </BreadCrumbLayout>
          <HeadContainer>
            <CustomAvatar
              src={(props.productDetail && props.productDetail.companyLogoUrl) || defaultLogo}
              size="100"
            />
            <HeaderDetailContainer>
              <InsurancePlan>{props.productDetail && props.productDetail.name}</InsurancePlan>
              <CompanyName>{props.productDetail.companyName}</CompanyName>
            </HeaderDetailContainer>
          </HeadContainer>

          <ContainerSecond>
            <IconList productDetail={props.productDetail} />
          </ContainerSecond>

          <ContainerThird>
            <HeaderThird>ผลประโยชน์จากกรมธรรม์</HeaderThird>
            <Benefitinfo productDetail={props.productDetail} />
          </ContainerThird>

          <ContainerFourth>
            <HeaderFourth>ตารางความคุ้มครองจากเบี้ยมาตรฐาน</HeaderFourth>
            <Description>
              * เบี้ยประกันที่แสดงเป็นเบี้ยที่คำนวณจากขั้นอาชีพมาตรฐานเท่านั้น
              อาจไม่ตรงกับเบี้ยประกันที่ต้องจ่ายจริง
            </Description>
            <TableKendo
              fullBenefit={props.productDetail && props.productDetail.insuranceCoverageTable}
            />
          </ContainerFourth>
          <CustomPanelBar>
            <PanelBarItem expanded={true} title="หมายเหตุ" className={props.className}>
              {props.note}
            </PanelBarItem>
          </CustomPanelBar>
        </MainLayout>
      )}
    </>
  )
}

const enhancer = compose(
  withRouter({
    queryTypes: {
      birthdate: "date",
      gender: "string",
      sumInsured: "integer",
      type: "string",
      searchBy: "string",
      leadId: "integer",
      from: "string",
      occupationCategory: "string",
      occupation: "string",
      maxAgeInsured: "integer",
      searchValue: "integer",
      enablePeriod: "boolean",
      opportunityKey: "string",
    },
  }),
  withState("loading", "setLoading", false),
  withStores((stores) => {
    return {
      getDetail: stores.productStore.getDetail,
      insuranceCoverageTable: stores.productStore.portDetailTable,
      productDetail: stores.productStore.portDetail,
      fetchOrder: stores.orderStore.fetchOrder,
    }
  }),
  withForm({
    form: "showDetailProduct",
  }),
  withProps((props) => {
    const searchData = props.location.query
    const { productId } = props.match.params

    const note = (
      <Note>
        *เบี้ยประกันที่แสดง เป็นเบี้ยประกันมาตรฐาน
        สำหรับผู้ที่มีอาชีพและประวัติด้านสุขภาพในเกณฑ์ความเสี่ยงปกติ
        ซึ่งอาจไม่ตรงกับเบี้ยประกันที่คุณต้องจ่ายจริง
        ซึ่งต้องพิจารณาความเสี่ยงของคุณอย่างละเอียดจากตัวแทนและบริษัทประกันชีวิตอีกครั้งหนึ่ง
        <br />
        <br />
        คำอธิบายเกี่ยวกับแบบกรมธรรม์ประกันชีวิตที่เสนอนั้น ไม่ใช่คำแนะนำให้ลงทุนหรือเอาประกันภัยตาม
        เนื่องจากการจัดแบบกรมธรรม์ประกันชีวิตให้เหมาะสมกับผู้เอาประกันภัยแต่ละรายนั้น
        นอกจากจะขึ้นอยู่กับเงื่อนไขรายบุคคลเกี่ยวกับสถานภาพทางการเงิน
        ความมั่นคงของรายได้แล้วยังขึ้นอยู่กับเงื่อนไขอื่นๆ อีก เช่น สุขภาพของผู้เอาประกันภัย
        ข้อมูลที่ต้องมีการรับรองเพื่อให้ผู้รับประกันภัยประกอบการตัดสินใจรับประกันภัย
        การคาดการณ์อัตราผลตอบแทนและความเสี่ยงต่างๆ
        <br />
        <br />
        คำอธิบายเกี่ยวกับแบบกรมธรรม์ประกันชีวิตที่เสนอนั้น ผู้จัดทำได้รับข้อมูลจากบุคคลที่สาม
        ผู้จัดทำจึงไม่สามารถรับประกันความสมบูรณ์ และถูกต้องของข้อมูล ซึ่งอาจจะมีการเปลี่ยนแปลงได้
        และทางผู้จัดทำจะไม่รับผิดชอบต่อความเสียหายใดๆ อันเกิดขึ้นจากการใช้งานของโปรแกรมนี้
        ไม่ว่าโดยทางตรงหรือทางอ้อม
        ดังนั้นการตัดสินใจเอาประกันภัยจึงขึ้นกับวิจารณญาณของผู้เอาประกันภัยเป็นสำคัญ
        <br />
        <br />
        กรุณาติดต่อสอบถามตัวแทนประกันชีวิต นายหน้าประกันชีวิต หรือบริษัทประกันชีวิต
        เพื่อตรวจสอบความถูกต้องสมบูรณ์ของข้อมูลแบบกรมธรรม์ประกันชีวิตก่อนการเอาประกันภัย
        และโปรดศึกษาข้อมูล นโยบาย ความเสี่ยง ปัจจัยที่เกี่ยวข้อง ก่อนการเอาประกันภัย
      </Note>
    )

    return {
      params: {
        ...searchData,
        productId,
      },
      productDetail: {
        ...props.productDetail,
        insuranceCoverageTable: props.insuranceCoverageTable && props.insuranceCoverageTable,
      },
      note,
    }
  }),
  lifecycle({
    async componentWillMount() {
      this.props.setLoading(true)

      const response = await this.props.getDetail({
        id: this.props.params.productId,
        birthdate: this.props.params.birthdate,
        gender: this.props.params.gender,
        sumInsured: this.props.params.sumInsured,
        searchValue: this.props.params.searchValue,
        searchBy: this.props.params.searchBy,
      })

      this.props.initialize(response)
      this.props.setLoading(false)
    },
  }),
  withProps((props) => {
    const { params } = props
    let links = [
      {
        label: "ผลิตภัณฑ์",
        path: "/",
      },
      {
        label: "ค้นหาแบบประกัน",
        path: path.productsSearchPath({
          gender: params.gender,
          type: params.type,
          birthdate: params.birthdate,
          searchValue: params.searchValue,
          searchBy: params.searchBy,
          opportunityKey: params.opportunityKey,
        }),
      },
      {
        label: "เลือกแบบประกัน",
        path: path.productsPath({
          gender: params.gender,
          type: params.type,
          birthdate: params.birthdate,
          from: params.from,
          searchValue: params.searchValue,
          searchBy: params.searchBy,
          opportunityKey: params.opportunityKey,
        }),
      },
      {
        label: "รายละเอียด",
      },
    ]

    return { links }
  })
)

export default enhancer(ProductShowDetail)
