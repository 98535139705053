import BaseStore from "./BaseStore"
import { tqldServer } from "@common/api"

class PackageStore extends BaseStore {
  constructor() {
    super({
      packages: [],
      portDetailTable: {},
      portDetail: { lifeInsurancePolicy: {}, recommendProducts: [] },
      riderPolicies: [],
      paging: {
        page: 1,
        perPage: 10,
        totalCount: 0,
        totalPages: 1,
      },
      filter: {},
      current: {},
      configs: {},
      age: null,
      gender: null,
    })
  }

  fetch = async (params) => {
    const response = await tqldServer.tqmBackend.get(`/packages`, params)
    this.packages = response.data.data.packages
    this.paging = response.data.data.paging
  }

  fetchById = async (id) => {
    const response = await tqldServer.tqmBackend.get(`/packages/${id}`)
    this.current = response.data.data.package
    return this.current
  }

  searchPackages = async (params) => {
    const response = await tqldServer.tqmBackend.get("/package_policies/search", params)
    const { packages } = response.data.data || []
    const customPackages = packages.map((data) => {
      return {
        ...data,
        age: params.age,
        gender: params.gender,
        sumInsured: params.sumInsured,
      }
    })
    this.packages = customPackages
    this.paging = response.data.data.paging
  }

  fetchPackagePolicyById = async (params) => {
    const response = await tqldServer.tqmBackend.get(
      `/package_policies/${params.packageId}`,
      params
    )

    response.data.data.package.packagesRiderInsurancePolicies.forEach((r) => {
      r.id = r.insurancePolicyId
    })

    this.portDetailTable = response.data.data.package.benefit
    this.portDetail = response.data.data.package.policy
    this.riderPolicies = response.data.data.package.packagesRiderInsurancePolicies
    this.current = response.data.data.package

    this.configs = response.data.data.configs

    return response.data.data.package
  }

  addPackage = async (params) => {
    await tqldServer.tqmBackend.post(`/packages`, {
      name: params.name,
      policyId: params.policyId,
      policySumInsured: params.policySumInsured,
      policyParamsEncode: params.paramsEncode,
      riders: params.riders,
      referenceCode: params.referenceCode,
      policyCommissions: params.policyCommissions,
      state: params.state,
      isF2fChannel: params.isF2fChannel,
      isOnlineChannel: params.isOnlineChannel,
      onlineAt: params.onlineAt,
      comment: params.comment,
    })
  }

  fetchCurrent = async (params) => {
    const response = await tqldServer.tqmBackend.get(`/packages/${params}`)

    this.portDetail = response.data.data.package.policy
    this.riderPolicies = response.data.data.package.riderPolicies
    this.current = response.data.data.package

    return response.data.data.package
  }

  updatePackage = async (params) => {
    const response = await tqldServer.tqmBackend.put(`/packages/${params.packageId}`, {
      name: params.name,
      policyId: params.policyId,
      policySumInsured: params.policySumInsured,
      policyParamsEncode: params.paramsEncode,
      riders: params.riders,
      referenceCode: params.referenceCode,
      policyCommissions: params.policyCommissions,
      state: params.state,
      isF2fChannel: params.isF2fChannel,
      isOnlineChannel: params.isOnlineChannel,
      onlineAt: params.onlineAt,
      comment: params.comment,
    })

    this.portDetail = response.data.data.package.policy
    this.riderPolicies = response.data.data.package.riderPolicies
    this.current = response.data.data.package

    return response.data.data.package
  }

  deletePackage = async (params) => {
    await tqldServer.tqmBackend.delete(`/packages/${params}`)
  }

  fetchConfig = async (params) => {
    const response = await tqldServer.tqmBackend.get(`/packages/configs`)
    this.configs = response.data.data.configs
  }

  fetchConfigFilter = async () => {
    const response = await tqldServer.tqmBackend.get(`/packages/configs_filter`)
    this.configs = response.data.data.configs
  }

  clearPackage = async (params) => {
    this.packages = []
  }
}

export default new PackageStore()
