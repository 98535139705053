import React from "react"
import { compose, withHandlers, withProps } from "recompose"
import styled from "@emotion/styled"
import { Button, Dialog, Field, Dropdown, CurrencyInput, Input } from "@components"
import { withStores, withForm, withRouter } from "@enhancers/index"
import { find } from "lodash"
import { safe, exceptNullKeys } from "@common/helper"
import { DialogActionsBar } from "@progress/kendo-react-dialogs"
import * as path from "@common/path"

import { Row } from "./FilterPanel"

const FilterRow = styled(Row)`
  > div {
    & div:nth-of-type(1) {
      margin-bottom: unset;
    }

    & div:nth-of-type(2) {
      > span {
        padding: 0px;
        background-color: white;
        > span {
          > span:nth-of-type(2) {
            > span {
              color: black;
            }
          }
        }
      }
      .k-state-invalid {
        > span {
          border-color: #dc3545 !important;
        }
      }
    }
  }
`
const CustomDialog = styled(Dialog)`
  display: flex;
  flex-direction: column;

  .k-window-titlebar {
    min-height: 62px;
    .k-dialog-title {
      font-size: 16px;
    }
  }
`
const DialogBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 30px;
`
const DialogFooter = styled(DialogActionsBar)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 20px;
`
const AssignButton = styled(Button)`
  border-left: 0.5px solid #ccc;
  color: white;
  background-color: #0275d8;

  :hover {
    background-color: #0275d8;
    color: white;
  }
  min-height: 52px;
`
const CancelButton = styled(Button)`
  border-left: 0.5px solid #ccc;
  color: #666;
  min-height: 52px;
`

const AssignModal = (props) => {
  return (
    <CustomDialog title="เลือกลีดที่จะมอบหมาย" onClose={() => props.onClose()}>
      <DialogBody>
        <FilterRow>
          <Field
            component={Dropdown}
            name="province"
            caption="จังหวัด"
            captionStyle={props.captionStyle}
            options={props.provinceOptions}
            leftWidth="67"
            rightWidth="138"
            disabled={props.disabled}
            placeholder="กรุณาเลือก"
            validate="required"
          />
          <Field
            component={Dropdown}
            name="district"
            caption="อำเภอ"
            captionStyle={props.captionStyle}
            options={props.districtOptions}
            leftWidth="57"
            rightWidth="138"
            disabled={props.disabled}
            placeholder="กรุณาเลือก"
          />
          <Field
            component={Dropdown}
            name="subDistrict"
            caption="ตำบล"
            captionStyle={props.captionStyle}
            options={props.subDistrictOptions}
            leftWidth="94"
            rightWidth="138"
            disabled={props.disabled}
            placeholder="กรุณาเลือก"
          />
        </FilterRow>
        <FilterRow>
          <Field
            component={Dropdown}
            name="gender"
            caption="เพศ"
            captionStyle={props.captionStyle}
            options={props.genderOptions}
            leftWidth="67"
            rightWidth="138"
            disabled={props.disabled}
            placeholder="กรุณาเลือก"
          />
          <Field
            component={CurrencyInput}
            name="age.min"
            caption="อายุ"
            captionStyle={props.captionStyle}
            leftWidth="43"
            rightWidth="64"
            disabled={props.disabled}
            placeholder="ระบุ"
            min={0}
            max={99}
          />
          <Field
            component={CurrencyInput}
            name="age.max"
            caption="-"
            captionStyle={props.captionStyle}
            leftWidth="12"
            rightWidth="64"
            placeholder="ระบุ"
            min={0}
            max={99}
          />
          <Field
            component={CurrencyInput}
            name="limit"
            caption="จำนวน"
            captionStyle={props.captionStyle}
            leftWidth="94"
            rightWidth="138"
            disabled={props.disabled}
            placeholder="กรุณาระบุ"
            min={0}
          />
        </FilterRow>
        <FilterRow>
          <Field
            component={Input}
            name="orderNo"
            caption="รหัสคำสั่งซื้อ"
            captionStyle={props.captionStyle}
            leftWidth="100"
            rightWidth="180"
            disabled={props.disabled}
            placeholder="กรุณาระบุ"
          />
          <Field
            component={Input}
            name="agtOpportunityKey"
            caption="รหัสโอกาสในการขาย"
            captionStyle={props.captionStyle}
            leftWidth="184"
            rightWidth="180"
            disabled={props.disabled}
            placeholder="กรุณาระบุ"
          />
        </FilterRow>
        <FilterRow>
          <Field
            component={Dropdown}
            name="affiliateId"
            caption="ชื่อผู้แนะนำ"
            options={props.affiliateOptions}
            captionStyle={props.captionStyle}
            leftWidth="90"
            rightWidth="260"
            disabled={props.disabled}
            placeholder="กรุณาเลือก"
          />
        </FilterRow>
      </DialogBody>
      <DialogFooter>
        <CancelButton styletype="delete" onClick={props.onClose}>
          ย้อนกลับ
        </CancelButton>
        <AssignButton styletype="primary" onClick={props.onAssign}>
          เลือกลีด
        </AssignButton>
      </DialogFooter>
    </CustomDialog>
  )
}

const enhancer = compose(
  withRouter(),
  withProps((props) => {
    return {
      initialValues: props.location.query,
    }
  }),
  withForm({
    form: "LeadPoolModalFilterForm",
    destroyOnUnmount: false,
  }),
  withStores((stores, props) => ({
    fetchLeadPools: stores.leadPoolStore.fetch,
    currentRole: stores.appStore.currentRole,
    saleId: stores.appStore.$("currentSale.id"),
    genderOptions: stores.leadPoolStore.$("configs.filters.gender.options", []),
    provinceOptions: stores.leadPoolStore.$("configs.filters.address.options", []),
    affiliateOptions: stores.leadPoolStore.$("configs.filters.affiliate.options", []),
  })),
  withProps((props) => {
    const provinceOptions = props.provinceOptions
    const districtOptions = safe(
      () => find(provinceOptions, { value: props.values.province }).districts,
      []
    )
    const subDistrictOptions = safe(
      () => find(districtOptions, { value: props.values.district }).subDistricts,
      []
    )

    return {
      provinceOptions,
      districtOptions,
      subDistrictOptions,
    }
  }),
  withProps((props) => {
    const captionStyle = {
      fontWeight: "normal",
      textAlign: "end",
      paddingRight: "10px",
    }
    return {
      captionStyle,
    }
  }),
  withHandlers({
    fetchLeadPools: (props) => async (params) => {
      try {
        if (props.currentRole === "member.team_leader") {
          return await props.fetchLeadPools({
            teamId: props.teamId,
            ...params,
          })
        } else if (["member.lead_admin", "member.brokerage_admin"].includes(props.currentRole)) {
          return await props.fetchLeadPools({
            affiliateOrderStatus: "new",
            ...params,
          })
        }
      } catch (error) {
        alert(error.errors?.base || "มีข้อผิดพลาดบางอย่างเกิดขึ้น")
      }
    },
  }),
  withHandlers({
    onClose: (props) => () => {
      props.setToggleModalValue(false)
    },
    onAssign: (props) =>
      props.handleSubmit(async (values) => {
        values = exceptNullKeys(
          values,
          "age.min",
          "age.max",
          "gender",
          "affiliateOrderStatus",
          "province",
          "district",
          "subDistrict",
          "orderId",
          "agtOpportunityKey",
          "affiliateId"
        )

        if (props.currentRole === "member.team_leader") {
          props.history.push(
            path.leadPoolsPath({
              ...values,
              affiliateOrderStatus: "team_assigned",
              mode: "assigning",
              page: 1,
              perPage: 10000,
            })
          )
        } else {
          props.history.push(
            path.leadPoolsPath({
              ...values,
              affiliateOrderStatus: "new",
              mode: "assigning",
              page: 1,
              perPage: 10000,
            })
          )
        }

        props.setToggleModalValue(false)
        props.gotoLead && props.gotoLead()
      }),
  })
)

export default enhancer(AssignModal)
