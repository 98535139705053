import React from "react"
import styled from "@emotion/styled"
import { compose, withProps } from "recompose"
import { withField, withCaption, withHidden } from "@enhancers/index"
import { BrowseButton, Input } from "./index"

const Layout = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  > div:first-of-type {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
const Note = styled.div`
  margin-top: 10px;
  text-align: center;
  font-size: 10px;
  color: #666666;
`
const UploadButton = styled.div`
  text-align: center;
`
const FileText = styled.div`
  display: flex;
  flex-direction: row;

  > div:first-of-type {
    margin-right: 3px;
  }
`

const InputFile = (props) => (
  <Layout>
    {props.value.name && (
      <FileText>
        <Input value="ไฟล์: " readOnly />
        <Input value={props.value.name} readOnly />
      </FileText>
    )}
    {props.value === "" && (
      <UploadButton>
        <BrowseButton
          accept=".doc, .xls, .xlsx, .pdf"
          multiple={false}
          onClick={props.onChange}
          awesomeIcon="upload"
          children="นำเข้าไฟล์"
        />
        <Note>หมายเหตุ: รับไฟล์สกุล .doc, .xls, .pdf เท่านั้น ขนาดไม่เกินไฟล์ละ 2 MB</Note>
      </UploadButton>
    )}
  </Layout>
)

const enhancer = compose(
  withField(),
  withHidden(),
  withCaption(),
  withProps((props) => ({
    readOnly: props.readOnly === undefined ? !props.onChange : props.readOnly,
  }))
)

export default enhancer(InputFile)
