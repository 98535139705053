import BaseStore from "./BaseStore"
import { tqldServer } from "@common/api"

class LeadPoolStore extends BaseStore {
  constructor() {
    super({
      all: [],
      paging: {
        page: 1,
        perPage: 10,
        totalCount: 0,
        totalPages: 1,
      },
      filter: {},
      current: null,
      configs: {
        filter: {},
        attributes: {},
      },
    })
  }

  fetchConfigs = async (params) => {
    const response = await tqldServer.tqmBackend.get(`/affiliate_orders/configs`, params)
    this.configs = response.data.data.configs
  }

  fetch = async (options = {}) => {
    const response = await tqldServer.tqmBackend.get(`/affiliate_orders`, options)
    return {
      affiliateOrders: response.data.data.affiliateOrders,
      paging: response.data.data.paging,
    }
  }

  fetchTeamConfigs = async () => {
    const response = await tqldServer.tqmBackend.get(`/affiliate_orders/teams`)

    return response.data.data.teams
  }

  assign = async (orderIds, salesmanIds, teamIds, startSaleDate, expireSaleDate, options) => {
    await tqldServer.tqmBackend.put(`/affiliate_orders/assign`, {
      orderIds,
      salesmanIds,
      teamIds,
      startSaleDate,
      expireSaleDate,
      ...options,
    })
  }

  unassign = async (leadIds) => {
    await tqldServer.tqmBackend.put(`/affiliate_orders/unassign`, {
      orderIds: [...leadIds],
    })
  }
}

export default new LeadPoolStore()
