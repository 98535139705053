import styled from "@emotion/styled"
export { css, keyframes } from "@emotion/core"

export default styled

export const desktopSize = (css) => {
  // return ""
  return `
    @media screen and (min-width: 769px) {
      ${css}
    }
  `
}
