/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { Fragment } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { withForm, withStores, withRouter, withHooks } from "@enhancers"
import { compose, withState, withHandlers, lifecycle, withProps } from "recompose"
import {
  Input,
  Dropdown,
  Field,
  CustomAddress as Address,
  InputName,
  DatePicker,
  Authorize,
  Button,
  Dialog,
  Checkbox,
  DateInput,
  CitizenIdInput,
  CurrencyInput,
  CreditCardInput,
  BankAccountInput,
} from "@components"
import { Layout as LeadInfoLayout, Header, LeftPanel } from "@pages/leads/show/LeadInfo"
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout"
import Beneficiary from "./beneficiary"
import { FormSection } from "redux-form"
import validate from "./validate"
import ApproveOrderModal from "./ApproveOrderModal"
import ApproveOrderSuccessModal from "./ApproveOrderSuccessModal"
import { find, isEqual, omit, isEmpty, get } from "lodash"
import { safe } from "@common/helper"
import * as path from "@common/path"
import { LoadingScope } from "@components"
import { Layout as withCaptionLayout } from "@enhancers/withCaption"
import BeneficiaryReadOnlyTable from "./BeneficiaryReadOnlyTable"
import PaymentDetailReadOnlyTable from "./PaymentDetailReadOnlyTable"
import RiderProducts from "./RiderProducts/index"
import useScrollPosition from "@react-hook/window-scroll"
import CreditCardExpireInput from "@components/CreditCardExpireInput"
import RejectModal from "./RejectModal"
import SuggestionModal from "./SuggestionModal"
import moment from "moment-business-days"

const Layout = styled(LeadInfoLayout)`
  flex-direction: column;
  margin-top: 31px;
  border: none;
  background: none;

  .k-state-invalid {
    > span {
      border-color: #dc3545 !important;
    }
  }

  ${(props) =>
    props.readOnly &&
    css`
      ${withCaptionLayout} {
        margin-top: 10px;

        &:nth-of-type(1) {
          margin-top: 10px;
        }
      }
    `}
`
const ErrorDialog = styled(Dialog)``
const Container = styled.div`
  display: flex;
  min-width: 400px;
  flex-direction: column;
  padding: 20px 25px;
  max-height: 500px;
`
const ErrorCaption = styled.span`
  font-size: 16px;
  color: #d9534f;
  font-weight: bold;
  margin-bottom: 10px;
  font-family: Sarabun, sans-serif;
`
const ErrorInfo = styled.span`
  font-size: 16px;
  font-weight: 800;
  margin-top: 5px;
  font-family: Sarabun, sans-serif;
`
const ErrorDescription = styled.span`
  font-size: 15px;
  font-family: Sarabun, sans-serif;
  margin-left: 8px;
`
const BeneficiaryErrorInfo = styled.span`
  font-size: 14px;
  font-weight: 600;
  margin-left: 16px;
  font-family: Sarabun, sans-serif;
`
const BeneficiaryErrorDescription = styled.span`
  font-size: 14px;
  margin-left: 24px;
  font-family: Sarabun, sans-serif;
`
const RightPanel = styled.div`
  display: flex;
  align-items: center;
`
const SaveButton = styled(Button)`
  margin-left: 10px;
  width: 104px;
`
const CustomInputName = styled(InputName)`
  > div:last-of-type {
    > div {
      > span {
        width: 120px;
      }
      > input {
        width: 158px;
      }
    }
  }
`
const DropOrderButton = styled(Button)`
  width: 100%;
  max-width: 115px;
  margin-left: 10px;
`
const SuggestionButton = styled(Button)`
  width: 100%;
  max-width: 115px;
  margin-left: 10px;
`
const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const CustomPanelBar = styled(PanelBar)`
  .k-animation-container {
    z-index: 9999 !important;

    .k-panel.k-group {
      .k-item.k-state-default {
        .k-link {
          .k-icon.k-i-arrow-s.k-panelbar-expand {
            right: 33.75rem;
          }

          .k-icon.k-i-arrow-n.k-panelbar-collapse {
            right: 33.75rem;
          }
        }
      }
    }
  }

  .k-item.k-state-default {
    .k-link {
      background-color: #fff;
      color: #0275d8;
      font-weight: bold;
      font-family: Sarabun, sans-serif;
      text-decoration: underline;
    }

    .k-link.k-state-selected.k-state-focused {
    }

    .k-link.k-header {
      background-color: #294558;
      color: #fff;
      font-family: Sarabun, sans-serif;
      font-weight: bold;
      text-decoration: none;

      height: 40px;
      :hover {
        background-color: #294558;
        color: #fff;
      }
    }

    .k-link.k-header.k-state-selected {
      background-color: #294558;
      color: #fff;

      :hover {
        background-color: #294558;
        color: #fff;
      }
    }
  }

  .k-item.k-state-default.k-state-expanded {
    .k-link {
      background-color: #fff;
      color: #0275d8;
      font-weight: bold;
      font-family: Sarabun, sans-serif;
      text-decoration: underline;
    }

    .k-link.k-state-selected {
    }

    .k-link.k-state-selected.k-state-focused {
      box-shadow: none;
    }

    .k-link.k-header {
      background-color: #294558;
      color: #fff;
      text-decoration: none;

      :hover {
        background-color: #294558;
        color: #fff;
      }
    }

    .k-link.k-header.k-state-selected {
      background-color: #294558;
      color: #fff;

      :hover {
        background-color: #294558;
        color: #fff;
      }
    }
  }
`
export const Section = styled.div`
  display: ${(props) => (props.hidden ? "none" : "flex")};
  padding: ${(props) =>
    props.addressReadOnly ? "none" : props.readOnly ? "24px 20px 8px 20px" : "24px 20px 26px 20px"};
  flex-direction: column;
  background-color: #fff;
`
export const SectionHeader = styled.span`
  font-size: 16px;
  color: #0275d8;
  font-weight: bold;
  margin-bottom: 20px;
  font-family: Sarabun, sans-serif;

  ${(props) =>
    props.readOnly &&
    css`
      margin-bottom: 0px;
    `}

  ${(props) =>
    props.addressReadOnly &&
    css`
      height: 40px;
      line-height: 2.5;
      color: white;
      background-color: #294558;
      padding-left: 16px;
      margin-bottom: 6px;
    `};
`
const CheckboxField = styled(Field)``
const ReadonlyContainer = styled.div`
  display: flex;
`
const FieldContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: ${(props) => (props.readOnly ? "row" : "column")};
`
const StickyHeader = styled(Header)`
  position: ${(props) => (props.isTabSticky ? "fixed" : "sticky")};
  top: 60px;
  z-index: 10000;
  background-color: ${(props) => (props.isTabSticky ? "#294558" : null)};

  ${(props) =>
    props.isTabSticky &&
    css`
      width: 100%;
      max-width: 768px;
      padding: 12px 45px;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
    `}
`

const RejectButton = styled(Button)`
  width: 140px;
`

const CustomInput = styled(Input)`
  [class*="ReadOnly"] {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
const SerialNumberContainer = styled.div`
  margin-bottom: 20px;
`

const HolderInfo = (props) => (
  <Layout readOnly={props.readOnly}>
    {props.loading && <LoadingScope />}
    {props.readOnly ? (
      <Header>
        <LeftPanel>ผู้เอาประกัน</LeftPanel>
      </Header>
    ) : (
      <>
        <Authorize roles={["member.salesman", "member.biz_dev", "member.team_leader"]}>
          {isEqual(props.order.salesmanId, props.currentSaleId) ? (
            <StickyHeader isTabSticky={props.isTabSticky}>
              <LeftPanel>{props.isTabSticky ? "" : "ผู้เอาประกัน"}</LeftPanel>
              <RightPanel>
                <RejectButton
                  styletype="delete"
                  icon="floppy"
                  children="ยกเลิกคำสั่งซื้อ"
                  onClick={props.openConfirmRejectModal}
                  hidden={!["new"].includes(props.orderState)}
                />
                <RejectModal
                  open={props.rejectModalConfirmOpened}
                  onClose={props.closeConfirmRejectModal}
                  onSubmit={props.rejectOrder}
                />
                <SaveButton
                  icon="floppy"
                  onClick={props.onSubmit}
                  children="บันทึก"
                  disabled={props.pristine}
                  hidden={props.readOnly}
                />
                <DropOrderButton
                  onClick={props.handleSubmit(props.onApprove)}
                  awesomeIcon="paper-plane"
                  children="แจ้งงาน"
                  hidden={!["new"].includes(props.orderState)}
                />
                <SuggestionModal
                  open={props.suggestionModalConfirmOpened}
                  onClose={props.closeConfirmSuggestionModal}
                  onSubmit={props.submitSuggestion}
                  title="แจ้งงาน"
                />
                <SuggestionButton
                  onClick={props.handleSubmit(props.openConfirmSuggestionModal)}
                  awesomeIcon="paper-plane"
                  children="แจ้งแนะนำ"
                  hidden={!["new"].includes(props.orderState) || !!props.order.affiliateId}
                />
              </RightPanel>
            </StickyHeader>
          ) : (
            <Header>
              <LeftPanel>ผู้เอาประกัน</LeftPanel>
              <RightPanel>
                <SaveButton
                  icon="floppy"
                  onClick={props.onSubmit}
                  children="บันทึก"
                  disabled={props.pristine}
                  hidden={!["new", "doc_attaching", "doc_verifying"].includes(props.orderState)}
                />
                <DropOrderButton
                  onClick={props.handleSubmit(props.onApprove)}
                  awesomeIcon="paper-plane"
                  children="แจ้งงาน"
                  hidden={!["new"].includes(props.orderState)}
                />
              </RightPanel>
            </Header>
          )}
        </Authorize>
        <Authorize roles={["member.affiliate"]}>
          {isEqual(props.order.affiliateId, props.currentSaleId) ? (
            <StickyHeader isTabSticky={props.isTabSticky}>
              <LeftPanel>{props.isTabSticky ? "" : "ผู้เอาประกัน"}</LeftPanel>
              <RightPanel>
                <RejectButton
                  styletype="delete"
                  icon="floppy"
                  children="ยกเลิกคำสั่งซื้อ"
                  onClick={props.openConfirmRejectModal}
                  hidden={!["affiliate_new"].includes(props.orderState)}
                />
                <RejectModal
                  open={props.rejectModalConfirmOpened}
                  onClose={props.closeConfirmRejectModal}
                  onSubmit={props.affiliateRejectOrder}
                />
                <SaveButton
                  icon="floppy"
                  onClick={props.onSubmit}
                  children="บันทึก"
                  disabled={props.pristine}
                  hidden={props.readOnly}
                />
                <SuggestionModal
                  open={props.suggestionModalConfirmOpened}
                  onClose={props.closeConfirmSuggestionModal}
                  onSubmit={props.submitSuggestion}
                  title="แจ้งงาน"
                />
                <SuggestionButton
                  onClick={props.handleSubmit(props.openConfirmSuggestionModal)}
                  awesomeIcon="paper-plane"
                  children="แจ้งแนะนำ"
                  disabled={!props.canAffiliateSubmit}
                />
              </RightPanel>
            </StickyHeader>
          ) : (
            <Header>
              <LeftPanel>ผู้เอาประกัน</LeftPanel>
              <RightPanel>
                <SaveButton
                  icon="floppy"
                  onClick={props.onSubmit}
                  children="บันทึก"
                  disabled={props.pristine}
                  hidden={
                    !["new", "affiliate_new", "doc_attaching", "doc_verifying"].includes(
                      props.orderState
                    )
                  }
                />
                <SuggestionModal
                  open={props.suggestionModalConfirmOpened}
                  onClose={props.closeConfirmSuggestionModal}
                  onSubmit={props.submitSuggestion}
                  title="แจ้งงาน"
                />
                <SuggestionButton
                  onClick={props.handleSubmit(props.openConfirmSuggestionModal)}
                  awesomeIcon="paper-plane"
                  children="แจ้งแนะนำ"
                  disabled={!props.canAffiliateSubmit}
                />
              </RightPanel>
            </Header>
          )}
        </Authorize>
      </>
    )}

    {props.readOnly && (
      <TabContainer>
        <CustomPanelBar>
          <PanelBarItem expanded={true} title="ข้อมูลแบบประกัน" className={props.className}>
            <Section readOnly={props.readOnly}>
              <SectionHeader readOnly={props.readOnly}>ประกันหลัก</SectionHeader>
              <Field
                name="productDetail.policyName"
                caption="ชื่อแบบ"
                component={CustomInput}
                leftWidth={props.readOnly ? "190" : null}
                readOnly={props.readOnly}
              />
              <Field
                name="productDetail.insuranceCompanyName"
                caption="บริษัทประกัน"
                component={CustomInput}
                leftWidth={props.readOnly ? "190" : null}
                readOnly={props.readOnly}
              />
              <Field
                name="policyNumber"
                caption="เลขกรมธรรม์"
                component={CustomInput}
                leftWidth={props.readOnly ? "190" : null}
                readOnly={props.readOnly}
              />
              <Field
                name="coverageDate"
                caption="วันเริ่มคุ้มครอง"
                component={DateInput}
                leftWidth={props.readOnly ? "190" : null}
                readOnly={props.readOnly}
              />
              <Field
                name="premium"
                caption="เบี้ยประกันต่องวด"
                component={CustomInput}
                leftWidth={props.readOnly ? "190" : null}
                readOnly={props.readOnly}
              />
            </Section>
            {props.renderRiderSection && (
              <Section readOnly={props.readOnly}>
                <SectionHeader readOnly={props.readOnly}>สัญญาเพิ่มเติม</SectionHeader>
                <Field name="riderProducts" component={RiderProducts} />
              </Section>
            )}

            {!isEmpty(props.affiliate) && (
              <Section
                style={{
                  padding: `${
                    props.renderRiderSection ? "0px 20px 40px 20px" : "24px 20px 40px 20px"
                  }`,
                }}
              >
                <SectionHeader readOnly={props.readOnly}>ข้อมูลผู้แนะนำ</SectionHeader>
                <Field
                  name="affiliate.agentKey"
                  caption="รหัสผู้แนะนำ"
                  component={CustomInput}
                  leftWidth={props.readOnly ? "190" : null}
                  readOnly={props.readOnly}
                />
                <Field
                  name="affiliate.name"
                  caption="ชื่อผู้แนะนำ"
                  component={CustomInput}
                  leftWidth={props.readOnly ? "190" : null}
                  readOnly={props.readOnly}
                />
                <Field
                  name="affiliate.teamName"
                  caption="ชื่อทีมแนะนำ"
                  component={CustomInput}
                  leftWidth={props.readOnly ? "190" : null}
                  readOnly={props.readOnly}
                />
                <Field
                  name="affiliate.affiliateSubmittedAt"
                  caption="วันที่แจ้งการแนะนำ"
                  component={DateInput}
                  leftWidth={props.readOnly ? "190" : null}
                  readOnly={props.readOnly}
                />
              </Section>
            )}

            <Section
              style={{
                padding: "0px 20px 40px 20px",
              }}
            >
              <SectionHeader readOnly={props.readOnly}>ข้อมูลผู้ขาย</SectionHeader>
              <Field
                name="salesman.staffCode"
                caption="รหัสพนักงาน"
                component={CustomInput}
                leftWidth={props.readOnly ? "190" : null}
                readOnly={props.readOnly}
              />
              <Field
                name="salesman.name"
                caption="ชื่อพนักงาน"
                component={CustomInput}
                leftWidth={props.readOnly ? "190" : null}
                readOnly={props.readOnly}
              />
              <Field
                name="salesman.teamName"
                caption="ชื่อทีม"
                component={CustomInput}
                leftWidth={props.readOnly ? "190" : null}
                readOnly={props.readOnly}
              />
              <Field
                name="submittedAt"
                caption="วันที่แจ้ง"
                component={DateInput}
                leftWidth={props.readOnly ? "190" : null}
                readOnly={props.readOnly}
              />
            </Section>
          </PanelBarItem>
        </CustomPanelBar>
      </TabContainer>
    )}

    {props.readOnly && (
      <TabContainer>
        <CustomPanelBar>
          <PanelBarItem expanded={true} title="ข้อมูลการชำระเงิน" className={props.className}>
            <Section>
              <FormSection name="paymentDetails">
                <Field
                  name="paymentPeriod"
                  caption="โหมดการชำระ"
                  component={Dropdown}
                  options={props.paymentPeriodOptions}
                  validate={props.readOnly ? null : "required"}
                  leftWidth={props.readOnly ? "190" : null}
                  readOnly={props.readOnly}
                  placeholder="กรุณาเลือก"
                />
                <Field
                  component={Dropdown}
                  name="paymentMethod"
                  options={props.paymentMethodOptions}
                  validate={props.readOnly ? null : "required"}
                  caption="วิธีการชำระ"
                  placeholder="กรุณาเลือก"
                  leftWidth={props.readOnly ? "190" : null}
                  readOnly={props.readOnly}
                />
                <Field
                  name="routeCode"
                  caption="เส้นวิ่ง"
                  component={Dropdown}
                  options={props.routeCodeOptions}
                  validate={props.readOnly ? null : "required"}
                  leftWidth={props.readOnly ? "190" : null}
                  readOnly={props.readOnly}
                  hidden={props.readOnly}
                />
                <Field
                  component={DatePicker}
                  name="paymentDate"
                  validate={props.readOnly ? null : "required"}
                  caption="วันกำหนดชำระ"
                  placeholder="กรุณาเลือก"
                  leftWidth={props.readOnly ? "190" : null}
                  readOnly={props.readOnly}
                />
                <Field
                  component={CurrencyInput}
                  name="paymentAmount"
                  validate={props.readOnly ? null : "required"}
                  caption="ยอดค้างชำระ (งวดถัดไป)"
                  placeholder="กรุณาเลือก"
                  leftWidth={props.readOnly ? "190" : null}
                  readOnly={props.readOnly}
                  unit={"บาท"}
                  customStyles={{ color: "#666666" }}
                  precision={2}
                />
                {!["bill_payment", "cash"].includes(props.order.paymentMethod) &&
                  !!props.order.paymentMethod && (
                    <Fragment>
                      <Field
                        name={
                          ["credit_card", "credit_card_cash"].includes(props.order.paymentMethod)
                            ? "paymentCreditCardHolderName"
                            : "paymentBankAccountName"
                        }
                        caption={
                          ["credit_card", "credit_card_cash"].includes(props.order.paymentMethod)
                            ? "ชื่อผู้ถือบัตร"
                            : "ชื่อบัญชีธนาคาร"
                        }
                        component={CustomInput}
                        leftWidth={props.readOnly ? "190" : null}
                        readOnly={props.readOnly}
                      />
                      <Field
                        name={
                          ["credit_card", "credit_card_cash"].includes(props.order.paymentMethod)
                            ? "paymentCreditCardNumber"
                            : "paymentBankAccountNumber"
                        }
                        caption={
                          ["credit_card", "credit_card_cash"].includes(props.order.paymentMethod)
                            ? "เลขที่บัตร"
                            : "เลขที่บัญชีธนาคาร"
                        }
                        component={
                          ["credit_card", "credit_card_cash"].includes(props.order.paymentMethod)
                            ? CreditCardInput
                            : BankAccountInput
                        }
                        leftWidth={props.readOnly ? "190" : null}
                        readOnly={props.readOnly}
                      />
                      <Field
                        name={
                          ["credit_card", "credit_card_cash"].includes(props.order.paymentMethod)
                            ? "paymentCreditCardType"
                            : "paymentBankAccountType"
                        }
                        caption={
                          ["credit_card", "credit_card_cash"].includes(props.order.paymentMethod)
                            ? "ประเภทบัตร"
                            : "ประเภทบัญชีธนาคาร"
                        }
                        component={Dropdown}
                        options={
                          ["credit_card", "credit_card_cash"].includes(props.order.paymentMethod)
                            ? props.paymentCreditCardTypeOptions
                            : props.paymentBankAccountTypeOptions
                        }
                        leftWidth={props.readOnly ? "190" : null}
                        readOnly={props.readOnly}
                      />
                      <Field
                        name={
                          ["credit_card", "credit_card_cash"].includes(props.order.paymentMethod)
                            ? "paymentCreditCardBankCode"
                            : "paymentBankCode"
                        }
                        caption={
                          ["credit_card", "credit_card_cash"].includes(props.order.paymentMethod)
                            ? "ธนาคารของบัตรเครดิต"
                            : "รหัสธนาคาร"
                        }
                        component={Dropdown}
                        leftWidth={props.readOnly ? "190" : null}
                        options={
                          ["credit_card", "credit_card_cash"].includes(props.order.paymentMethod)
                            ? props.paymentCreditCardBankCodeOptions
                            : props.paymentBankCodeOptions
                        }
                        readOnly={props.readOnly}
                      />
                      {!["credit_card", "credit_card_cash"].includes(props.order.paymentMethod) && (
                        <Field
                          name="paymentBankBranchName"
                          caption="สาขาธนาคาร"
                          component={CustomInput}
                          leftWidth={props.readOnly ? "190" : null}
                          readOnly={props.readOnly}
                        />
                      )}
                      <Field
                        name="paymentCreditCardExpiryDate"
                        caption="วันหมดอายุบัตร"
                        component={CreditCardExpireInput}
                        leftWidth={props.readOnly ? "190" : null}
                        readOnly={props.readOnly}
                      />
                    </Fragment>
                  )}
              </FormSection>
            </Section>
            <PaymentDetailReadOnlyTable
              data={props.initialValues.paymentInstallments}
              genderOptions={props.genderOptions}
              relationshipOptions={props.relationshipOptions}
              readOnly={props.readOnly}
            />
          </PanelBarItem>
        </CustomPanelBar>
      </TabContainer>
    )}

    <FormSection name="insuranceHolder">
      <TabContainer>
        <CustomPanelBar>
          <PanelBarItem expanded={true} title="ข้อมูลผู้เอาประกัน" className={props.className}>
            <Section>
              <SerialNumberContainer>
                <Field
                  component={DatePicker}
                  name="saleDate"
                  caption="วันที่ในใบคำขอ"
                  placeholder="กรุณาเลือก"
                  rightWidth="200"
                  readOnly={props.readOnly}
                  validate="required"
                />
                <Field
                  name="serialNumber"
                  caption="เลขอ้างอิง/เลขใบคำขอ"
                  component={CustomInput}
                  readOnly={props.readOnly}
                  rightWidth="200"
                />
                <Field
                  name="receiveTemp"
                  caption="เลขที่ใบรับเงินชั่วคราว"
                  component={CustomInput}
                  readOnly={props.readOnly}
                  rightWidth="200"
                />
                <Field
                  name="policyNumber"
                  caption="เลขกรมธรรม์"
                  component={CustomInput}
                  readOnly={props.readOnly}
                  rightWidth="200"
                />
                <Field
                  name="suggestedBy"
                  caption="รหัสผู้แนะนำ"
                  component={CustomInput}
                  readOnly={props.readOnly}
                  rightWidth="200"
                />
              </SerialNumberContainer>

              <SectionHeader readOnly={props.readOnly}>ข้อมูลส่วนตัว</SectionHeader>
              {props.readOnly ? (
                <ReadonlyContainer>
                  <Field
                    name="nameInfo"
                    caption="ชื่อ"
                    component={CustomInputName}
                    readOnly={props.readOnly}
                    leftWidth="190"
                  />
                  <Field
                    name="gender"
                    caption="เพศ"
                    component={Dropdown}
                    options={props.genderOptions}
                    leftWidth="95"
                    rightWidth="94"
                    readOnly={props.readOnly}
                  />
                </ReadonlyContainer>
              ) : (
                <Fragment>
                  <Field
                    name="nameInfo"
                    caption="ชื่อ"
                    component={CustomInputName}
                    validate="required"
                    readOnly={props.readOnly}
                  />
                  <Field
                    name="gender"
                    caption="เพศ"
                    component={Dropdown}
                    options={props.genderOptions}
                    validate="required"
                    rightWidth="200"
                    readOnly={props.readOnly}
                  />
                </Fragment>
              )}
              {props.readOnly ? (
                <ReadonlyContainer>
                  <Field
                    name="birthdate"
                    caption="วันเกิด"
                    component={DateInput}
                    readOnly={props.readOnly}
                    leftWidth="190"
                  />
                  <Field
                    name="maritalStatus"
                    caption="สถานะภาพ"
                    component={Dropdown}
                    options={props.maritalStatusOptions}
                    leftWidth="95"
                    rightWidth="94"
                    readOnly={props.readOnly}
                    idName="maritalStatus"
                  />
                </ReadonlyContainer>
              ) : (
                <Fragment>
                  <Field
                    name="birthdate"
                    caption="วันเกิด"
                    component={DateInput}
                    readOnly={props.readOnly}
                    validate="required"
                  />
                  <Field
                    name="maritalStatus"
                    caption="สถานะภาพ"
                    component={Dropdown}
                    options={props.maritalStatusOptions}
                    validate={props.currentRole === "member.affiliate" ? null : "required"}
                    rightWidth="200"
                    readOnly={props.readOnly}
                    idName="maritalStatus"
                  />
                </Fragment>
              )}
              {props.readOnly ? (
                <ReadonlyContainer>
                  <Field
                    name="race"
                    caption="เชื้อชาติ"
                    component={Dropdown}
                    options={props.raceOptions}
                    leftWidth="190"
                    readOnly={props.readOnly}
                  />
                  <Field
                    name="nationality"
                    caption="สัญชาติ"
                    component={Dropdown}
                    options={props.nationalityOptions}
                    leftWidth="95"
                    rightWidth="94"
                    readOnly={props.readOnly}
                  />
                </ReadonlyContainer>
              ) : (
                <Fragment>
                  <Field
                    name="race"
                    caption="เชื้อชาติ"
                    component={Dropdown}
                    options={props.raceOptions}
                    rightWidth="200"
                    readOnly={props.readOnly}
                  />
                  <Field
                    name="nationality"
                    caption="สัญชาติ"
                    component={Dropdown}
                    options={props.nationalityOptions}
                    rightWidth="200"
                    readOnly={props.readOnly}
                  />
                </Fragment>
              )}
              <Field
                name="religion"
                caption="ศาสนา"
                component={Dropdown}
                options={props.religionOptions}
                leftWidth={props.readOnly ? "190" : null}
                rightWidth="200"
                readOnly={props.readOnly}
              />
              <Field
                name="mobilePhoneNumber"
                caption="เบอร์มือถือ"
                component={CustomInput}
                leftWidth={props.readOnly ? "190" : null}
                rightWidth="200"
                validate={props.readOnly ? null : "required"}
                readOnly={props.readOnly}
              />
              <Field
                name="alternatePhoneNumber"
                caption="เบอร์โทรสำรอง"
                component={CustomInput}
                leftWidth={props.readOnly ? "190" : null}
                rightWidth="200"
                readOnly={props.readOnly}
              />
              <Field
                name="email"
                caption="อีเมล"
                component={CustomInput}
                leftWidth={props.readOnly ? "190" : null}
                rightWidth="200"
                readOnly={props.readOnly}
              />
              <Field
                name="idCard"
                caption="เลขที่บัตรประชาชน"
                component={CitizenIdInput}
                validate={
                  props.readOnly
                    ? null
                    : props.currentRole === "member.affiliate"
                    ? null
                    : "required"
                }
                leftWidth={props.readOnly ? "190" : null}
                rightWidth="200"
                readOnly={props.readOnly}
              />
              {props.readOnly && (
                <Fragment>
                  <Field
                    name="expiredCardDate"
                    caption="วันหมดอายุบัตรประชาชน"
                    component={DateInput}
                    leftWidth="190"
                    maxYear={100}
                    readOnly={props.readOnly}
                  />
                  <Field
                    name="phoneNumber"
                    caption="เบอร์บ้าน"
                    component={CustomInput}
                    leftWidth="190"
                    rightWidth="200"
                    readOnly={props.readOnly}
                  />
                  <Field
                    name="officePhoneNumber"
                    caption="เบอร์ที่ทำงาน"
                    component={CustomInput}
                    leftWidth="190"
                    rightWidth="200"
                    readOnly={props.readOnly}
                  />
                  <Field
                    name="height"
                    caption="ส่วนสูง"
                    component={CustomInput}
                    leftWidth="190"
                    rightWidth="200"
                    readOnly={props.readOnly}
                  />
                  <Field
                    name="weight"
                    caption="น้ำหนัก"
                    component={CustomInput}
                    leftWidth="190"
                    rightWidth="200"
                    readOnly={props.readOnly}
                  />
                  <Authorize roles={["member.biz_dev"]}>
                    <Field
                      name="children"
                      caption="บุตร"
                      component={CustomInput}
                      leftWidth="190"
                      rightWidth="200"
                      readOnly={props.readOnly}
                    />
                  </Authorize>
                </Fragment>
              )}
            </Section>
            {!props.readOnly && (
              <PanelBarItem title="กรอกละเอียด" className={props.className}>
                <Section>
                  <Field
                    name="expiredCardDate"
                    caption="วันหมดอายุบัตรประชาชน"
                    component={DateInput}
                    maxYear={100}
                    readOnly={props.readOnly}
                  />
                  <Field
                    name="phoneNumber"
                    caption="เบอร์บ้าน"
                    component={CustomInput}
                    rightWidth="200"
                    readOnly={props.readOnly}
                  />
                  <Field
                    name="officePhoneNumber"
                    caption="เบอร์ที่ทำงาน"
                    component={CustomInput}
                    rightWidth="200"
                    readOnly={props.readOnly}
                  />
                  <Field
                    name="height"
                    caption="ส่วนสูง"
                    component={CustomInput}
                    rightWidth="200"
                    readOnly={props.readOnly}
                  />
                  <Field
                    name="weight"
                    caption="น้ำหนัก"
                    component={CustomInput}
                    rightWidth="200"
                    readOnly={props.readOnly}
                  />
                  <Authorize roles={["member.biz_dev"]}>
                    <Field
                      name="children"
                      caption="บุตร"
                      component={CustomInput}
                      rightWidth="200"
                      readOnly={props.readOnly}
                    />
                  </Authorize>
                </Section>
              </PanelBarItem>
            )}
            <Section>
              <SectionHeader readOnly={props.readOnly}>ข้อมูลอาชีพ</SectionHeader>
              {props.readOnly ? (
                <ReadonlyContainer>
                  <Field
                    name="careerCode"
                    caption="อาชีพ"
                    component={Dropdown}
                    options={props.careerOptions}
                    leftWidth="190"
                    rightWidth="160"
                    readOnly={props.readOnly}
                  />
                  <Field
                    name="company"
                    caption="บริษัท"
                    component={CustomInput}
                    leftWidth="140"
                    rightWidth="178"
                    readOnly={props.readOnly}
                  />
                </ReadonlyContainer>
              ) : (
                <Fragment>
                  <Field
                    name="careerCode"
                    caption="อาชีพ"
                    component={Dropdown}
                    options={props.careerOptions}
                    rightWidth="200"
                    validate={props.currentRole === "member.affiliate" ? null : "required"}
                    readOnly={props.readOnly}
                  />
                  <Field
                    name="company"
                    caption="บริษัท"
                    component={CustomInput}
                    rightWidth="200"
                    readOnly={props.readOnly}
                  />
                </Fragment>
              )}
              {props.readOnly ? (
                <ReadonlyContainer>
                  <Field
                    name="position"
                    caption="ตำแหน่ง"
                    component={CustomInput}
                    leftWidth="190"
                    rightWidth="160"
                    readOnly={props.readOnly}
                  />
                  <Field
                    name="income"
                    caption="รายได้ต่อปี"
                    component={CurrencyInput}
                    leftWidth="140"
                    rightWidth="178"
                    readOnly={props.readOnly}
                  />
                </ReadonlyContainer>
              ) : (
                <Fragment>
                  <Field
                    name="position"
                    caption="ตำแหน่ง"
                    component={CustomInput}
                    rightWidth="200"
                    readOnly={props.readOnly}
                  />
                  <Field
                    name="income"
                    caption="รายได้ต่อปี"
                    component={CurrencyInput}
                    rightWidth="200"
                    readOnly={props.readOnly}
                  />
                </Fragment>
              )}
            </Section>
            <TabContainer>
              {props.readOnly ? (
                <BeneficiaryReadOnlyTable
                  data={props.initialValues.insuranceBeneficiaries}
                  genderOptions={props.genderOptions}
                  relationshipOptions={props.relationshipOptions}
                  readOnly={props.readOnly}
                />
              ) : (
                <Section>
                  <Field
                    name="insuranceBeneficiaries"
                    component={Beneficiary}
                    genderOptions={props.genderOptions}
                    relationshipOptions={props.relationshipOptions}
                    initialize={props.initialize}
                    change={props.change}
                    readOnly={props.readOnly}
                    onItemCountChange={props.reCalculateBeneficiaryPercents}
                    isStatutoryHeir={props.values.insuranceHolder.isStatutoryHeir}
                  />
                </Section>
              )}
            </TabContainer>
          </PanelBarItem>
        </CustomPanelBar>
      </TabContainer>
    </FormSection>

    <FormSection name="insurancePayer">
      <TabContainer>
        <CustomPanelBar>
          <PanelBarItem expanded={true} title="ข้อมูลผู้ชำระเบี้ย" className={props.className}>
            <Section>
              {props.readOnly && (
                <SectionHeader readOnly={props.readOnly}>ข้อมูลส่วนตัว</SectionHeader>
              )}
              <CheckboxField
                name="isHolderCustomerChecked"
                component={Checkbox}
                label="เป็นคนเดียวกับผู้เอาประกัน"
                readOnly={props.readOnly}
                hidden={
                  props.isMinorHolderInfo ||
                  (props.readOnly && !props.values.insurancePayer.isHolderCustomerChecked)
                }
              />
              {(!props.values.insurancePayer.isHolderCustomerChecked ||
                props.isMinorHolderInfo) && (
                <Fragment>
                  {props.readOnly ? (
                    <ReadonlyContainer>
                      <Field
                        name="nameInfo"
                        caption="ชื่อ"
                        component={CustomInputName}
                        leftWidth="190"
                        readOnly={props.readOnly}
                      />
                      <Field
                        name="gender"
                        caption="เพศ"
                        component={Dropdown}
                        options={props.genderOptions}
                        leftWidth="95"
                        rightWidth="94"
                        readOnly={props.readOnly}
                      />
                    </ReadonlyContainer>
                  ) : (
                    <Fragment>
                      <Field
                        name="nameInfo"
                        caption="ชื่อ"
                        component={CustomInputName}
                        validate={props.currentRole === "member.affiliate" ? null : "required"}
                        readOnly={props.readOnly}
                      />
                      <Field
                        name="gender"
                        caption="เพศ"
                        component={Dropdown}
                        options={props.genderOptions}
                        validate={props.currentRole === "member.affiliate" ? null : "required"}
                        rightWidth="200"
                        readOnly={props.readOnly}
                      />
                    </Fragment>
                  )}
                  {props.readOnly ? (
                    <ReadonlyContainer>
                      <Field
                        name="birthdate"
                        caption="วันเกิด"
                        component={DateInput}
                        leftWidth="190"
                        readOnly={props.readOnly}
                      />
                      <Field
                        name="maritalStatus"
                        caption="สถานะภาพ"
                        component={Dropdown}
                        options={props.maritalStatusOptions}
                        leftWidth="95"
                        rightWidth="94"
                        readOnly={props.readOnly}
                      />
                    </ReadonlyContainer>
                  ) : (
                    <Fragment>
                      <Field
                        name="birthdate"
                        caption="วันเกิด"
                        component={DateInput}
                        readOnly={props.readOnly}
                        validate={props.currentRole === "member.affiliate" ? null : "required"}
                      />
                      <Field
                        name="maritalStatus"
                        caption="สถานะภาพ"
                        component={Dropdown}
                        options={props.maritalStatusOptions}
                        validate={props.currentRole === "member.affiliate" ? null : "required"}
                        rightWidth="200"
                        readOnly={props.readOnly}
                      />
                    </Fragment>
                  )}
                  <Field
                    name="mobilePhoneNumber"
                    caption={props.readOnly ? "เบอร์โทร" : "เบอร์มือถือ"}
                    component={CustomInput}
                    leftWidth={props.readOnly ? "190" : null}
                    rightWidth="200"
                    validate={
                      props.readOnly
                        ? null
                        : props.currentRole === "member.affiliate"
                        ? null
                        : "required"
                    }
                    readOnly={props.readOnly}
                  />
                  <Field
                    name="relationship"
                    caption="ความสัมพันธ์"
                    component={Dropdown}
                    options={props.relationshipOptions}
                    leftWidth={props.readOnly ? "190" : null}
                    rightWidth="200"
                    readOnly={props.readOnly}
                  />
                  <Field
                    name="idCard"
                    caption="เลขที่บัตรประชาชน"
                    component={CitizenIdInput}
                    validate={
                      props.readOnly
                        ? null
                        : props.currentRole === "member.affiliate"
                        ? null
                        : "required"
                    }
                    leftWidth={props.readOnly ? "190" : null}
                    rightWidth="200"
                    readOnly={props.readOnly}
                  />
                  {props.readOnly && (
                    <Fragment>
                      <Field
                        name="expiredCardDate"
                        caption="วันหมดอายุบัตรประชาชน"
                        component={DateInput}
                        leftWidth="190"
                        maxYear={100}
                        readOnly={props.readOnly}
                      />
                      <Field
                        name="phoneNumber"
                        caption="เบอร์บ้าน"
                        component={CustomInput}
                        leftWidth="190"
                        rightWidth="200"
                        readOnly={props.readOnly}
                      />
                      <Field
                        name="officePhoneNumber"
                        caption="เบอร์ที่ทำงาน"
                        component={CustomInput}
                        leftWidth="190"
                        rightWidth="200"
                        readOnly={props.readOnly}
                      />
                    </Fragment>
                  )}
                </Fragment>
              )}
            </Section>
            {!props.readOnly && !props.values.insurancePayer.isHolderCustomerChecked && (
              <PanelBarItem title="กรอกละเอียด" className={props.className}>
                <Section>
                  <Field
                    name="expiredCardDate"
                    caption="วันหมดอายุบัตรประชาชน"
                    component={DateInput}
                    maxYear={100}
                    readOnly={props.readOnly}
                  />
                  <Field
                    name="phoneNumber"
                    caption="เบอร์บ้าน"
                    component={CustomInput}
                    rightWidth="200"
                    readOnly={props.readOnly}
                  />
                  <Field
                    name="officePhoneNumber"
                    caption="เบอร์ที่ทำงาน"
                    component={CustomInput}
                    rightWidth="200"
                    readOnly={props.readOnly}
                  />
                </Section>
              </PanelBarItem>
            )}
            {!props.values.insurancePayer.isHolderCustomerChecked && (
              <Section>
                <SectionHeader readOnly={props.readOnly}>ข้อมูลอาชีพ</SectionHeader>
                <FieldContainer readOnly={props.readOnly}>
                  <Field
                    name="careerCode"
                    caption="อาชีพ"
                    component={Dropdown}
                    options={props.careerOptions}
                    leftWidth="190"
                    rightWidth={props.readOnly ? "160" : "200"}
                    validate={
                      props.readOnly
                        ? null
                        : props.currentRole === "member.affiliate"
                        ? null
                        : "required"
                    }
                    readOnly={props.readOnly}
                  />
                  <Field
                    name="position"
                    caption="ตำแหน่ง"
                    component={CustomInput}
                    leftWidth={props.readOnly ? "77" : "190"}
                    rightWidth={props.readOnly ? "178" : "200"}
                    readOnly={props.readOnly}
                  />
                </FieldContainer>
                <Field
                  name="income"
                  caption="รายได้"
                  component={CurrencyInput}
                  leftWidth={props.readOnly ? "140" : "190"}
                  rightWidth="200"
                  readOnly={props.readOnly}
                />
              </Section>
            )}
          </PanelBarItem>
        </CustomPanelBar>
      </TabContainer>
    </FormSection>

    <TabContainer>
      {props.readOnly ? (
        <Fragment>
          <Section addressReadOnly={props.readOnly}>
            <SectionHeader addressReadOnly={props.readOnly}>ที่อยู่ปัจจุบัน</SectionHeader>
            <FormSection name="currentAddress">
              <Address {...props} name="currentAddress" />
            </FormSection>
          </Section>
          <Section addressReadOnly={props.readOnly}>
            <SectionHeader addressReadOnly={props.readOnly}>ที่อยู่ตามทะเบียนบ้าน</SectionHeader>
            <FormSection name="homeAddress">
              {props.readOnly ? (
                <Address {...props} name="homeAddress" />
              ) : (
                <Fragment>
                  <Field
                    name="isSameCurrentAddress"
                    component={Checkbox}
                    label="เหมือนที่อยู่ปัจจุบัน"
                  />
                  {!props.values.homeAddress.isSameCurrentAddress && (
                    <Address {...props} name="homeAddress" />
                  )}
                </Fragment>
              )}
            </FormSection>
          </Section>
          <Section addressReadOnly={props.readOnly}>
            <SectionHeader addressReadOnly={props.readOnly}>ที่อยู่ที่ทำงาน</SectionHeader>
            <FormSection name="officeAddress">
              {props.readOnly ? (
                <Address {...props} name="officeAddress" />
              ) : (
                <Fragment>
                  <Field
                    name="isSameCurrentAddress"
                    component={Checkbox}
                    label="เหมือนที่อยู่ปัจจุบัน"
                  />
                  {!props.values.officeAddress.isSameCurrentAddress && (
                    <Address {...props} name="officeAddress" />
                  )}
                </Fragment>
              )}
            </FormSection>
          </Section>
          <Section hidden={props.readOnly}>
            <Field
              name="contactAddressType"
              caption="ที่อยู่สำหรับการติดต่อ"
              component={Dropdown}
              options={props.contactAddressTypeOptions}
              validate={props.currentRole === "member.affiliate" ? null : "required"}
              rightWidth="200"
              readOnly={props.readOnly}
            />
            <Field
              name="policyAddressType"
              caption="สถานที่จัดส่งเอกสาร"
              component={Dropdown}
              options={props.contactAddressTypeOptions}
              validate={props.currentRole === "member.affiliate" ? null : "required"}
              rightWidth="200"
              readOnly={props.readOnly}
            />
          </Section>
        </Fragment>
      ) : (
        <CustomPanelBar>
          <PanelBarItem expanded={true} title="ที่อยู่" className={props.className}>
            <Section>
              <SectionHeader>ที่อยู่ปัจจุบัน</SectionHeader>
              <FormSection name="currentAddress">
                <Address {...props} name="currentAddress" />
              </FormSection>
            </Section>
            <Section>
              <SectionHeader>ที่อยู่ตามทะเบียนบ้าน</SectionHeader>
              <FormSection name="homeAddress">
                <Field
                  name="isSameCurrentAddress"
                  component={Checkbox}
                  label="เหมือนที่อยู่ปัจจุบัน"
                />
                {!props.values.homeAddress.isSameCurrentAddress && (
                  <Address
                    {...props}
                    name="homeAddress"
                    optional={props.currentRole === "member.affiliate" ? true : false}
                  />
                )}
              </FormSection>
            </Section>
            <Section>
              <SectionHeader>ที่อยู่ที่ทำงาน</SectionHeader>
              <FormSection name="officeAddress">
                <Field
                  name="isSameCurrentAddress"
                  component={Checkbox}
                  label="เหมือนที่อยู่ปัจจุบัน"
                />
                {!props.values.officeAddress.isSameCurrentAddress && (
                  <Address
                    {...props}
                    name="officeAddress"
                    optional={props.currentRole === "member.affiliate" ? true : false}
                  />
                )}
              </FormSection>
            </Section>
            <Section hidden={props.readOnly}>
              <Field
                name="contactAddressType"
                caption="ที่อยู่สำหรับการติดต่อ"
                component={Dropdown}
                options={props.contactAddressTypeOptions}
                validate={props.currentRole === "member.affiliate" ? null : "required"}
                rightWidth="200"
                readOnly={props.readOnly}
              />
              <Field
                name="policyAddressType"
                caption="สถานที่จัดส่งเอกสาร"
                component={Dropdown}
                options={props.contactAddressTypeOptions}
                validate={props.currentRole === "member.affiliate" ? null : "required"}
                rightWidth="200"
                readOnly={props.readOnly}
              />
            </Section>
          </PanelBarItem>
        </CustomPanelBar>
      )}
    </TabContainer>

    {!props.readOnly && (
      <TabContainer>
        <CustomPanelBar>
          <PanelBarItem expanded={true} title="ข้อมูลการชำระเงิน" className={props.className}>
            <Section>
              <Field
                name="paymentPeriod"
                caption="โหมดการชำระ"
                component={Dropdown}
                options={props.paymentPeriodOptions}
                validate={
                  props.readOnly
                    ? null
                    : props.currentRole === "member.affiliate"
                    ? null
                    : "required"
                }
                leftWidth={props.readOnly ? "190" : null}
                rightWidth="200"
                readOnly={props.readOnly}
                placeholder="กรุณาเลือก"
              />
              <Field
                component={Dropdown}
                name="paymentMethod"
                options={props.paymentMethodOptions}
                validate={
                  props.readOnly
                    ? null
                    : props.currentRole === "member.affiliate"
                    ? null
                    : "required"
                }
                caption="วิธีการชำระ"
                placeholder="กรุณาเลือก"
                leftWidth={props.readOnly ? "190" : null}
                rightWidth="200"
                readOnly={props.readOnly}
              />
              <Field
                name="routeCode"
                caption="เส้นวิ่ง"
                component={Dropdown}
                options={props.routeCodeOptions}
                validate={
                  props.readOnly
                    ? null
                    : props.currentRole === "member.affiliate"
                    ? null
                    : "required"
                }
                leftWidth={props.readOnly ? "190" : null}
                rightWidth="200"
                readOnly={props.readOnly}
                hidden={props.readOnly}
              />
              <Field
                component={DatePicker}
                name="firstDueDate"
                validate={
                  props.readOnly
                    ? null
                    : props.currentRole === "member.affiliate"
                    ? null
                    : "required"
                }
                caption="วันนัดชำระ"
                placeholder="กรุณาเลือก"
                leftWidth={props.readOnly ? "190" : null}
                rightWidth="200"
                readOnly={props.readOnly}
                // min={moment()._d}
              />
              {!["bill_payment", "cash"].includes(props.values.paymentMethod) &&
                !!props.values.paymentMethod && (
                  <Fragment>
                    <Field
                      name={
                        ["credit_card", "credit_card_cash"].includes(props.values.paymentMethod)
                          ? "paymentCreditCardHolderName"
                          : "paymentBankAccountName"
                      }
                      caption={
                        ["credit_card", "credit_card_cash"].includes(props.values.paymentMethod)
                          ? "ชื่อผู้ถือบัตร"
                          : "ชื่อบัญชีธนาคาร"
                      }
                      component={CustomInput}
                      leftWidth={props.readOnly ? "190" : null}
                      rightWidth="200"
                      readOnly={props.readOnly}
                      // validate={props.readOnly ? null : "required"}
                    />
                    <Field
                      name={
                        ["credit_card", "credit_card_cash"].includes(props.values.paymentMethod)
                          ? "paymentCreditCardNumber"
                          : "paymentBankAccountNumber"
                      }
                      caption={
                        ["credit_card", "credit_card_cash"].includes(props.values.paymentMethod)
                          ? "เลขที่บัตร"
                          : "เลขที่บัญชีธนาคาร"
                      }
                      component={
                        ["credit_card", "credit_card_cash"].includes(props.values.paymentMethod)
                          ? CreditCardInput
                          : BankAccountInput
                      }
                      leftWidth={props.readOnly ? "190" : null}
                      rightWidth="200"
                      readOnly={props.readOnly}
                      // validate={props.readOnly ? null : "required"}
                    />
                    <Field
                      name={
                        ["credit_card", "credit_card_cash"].includes(props.values.paymentMethod)
                          ? "paymentCreditCardType"
                          : "paymentBankAccountType"
                      }
                      caption={
                        ["credit_card", "credit_card_cash"].includes(props.values.paymentMethod)
                          ? "ประเภทบัตร"
                          : "ประเภทบัญชีธนาคาร"
                      }
                      component={Dropdown}
                      options={
                        ["credit_card", "credit_card_cash"].includes(props.values.paymentMethod)
                          ? props.paymentCreditCardTypeOptions
                          : props.paymentBankAccountTypeOptions
                      }
                      leftWidth={props.readOnly ? "190" : null}
                      rightWidth="200"
                      readOnly={props.readOnly}
                      // validate={props.readOnly ? null : "required"}
                    />
                    <Field
                      name={
                        ["credit_card", "credit_card_cash"].includes(props.values.paymentMethod)
                          ? "paymentCreditCardBankCode"
                          : "paymentBankCode"
                      }
                      caption={
                        ["credit_card", "credit_card_cash"].includes(props.values.paymentMethod)
                          ? "ธนาคารของบัตรเครดิต"
                          : "รหัสธนาคาร"
                      }
                      component={Dropdown}
                      leftWidth={props.readOnly ? "190" : null}
                      options={
                        ["credit_card", "credit_card_cash"].includes(props.values.paymentMethod)
                          ? props.paymentCreditCardBankCodeOptions
                          : props.paymentBankCodeOptions
                      }
                      rightWidth="200"
                      readOnly={props.readOnly}
                      // validate={props.readOnly ? null : "required"}
                    />
                    {!["credit_card", "credit_card_cash"].includes(props.values.paymentMethod) && (
                      <Field
                        name="paymentBankBranchName"
                        caption="สาขาธนาคาร"
                        component={CustomInput}
                        leftWidth={props.readOnly ? "190" : null}
                        rightWidth="200"
                        readOnly={props.readOnly}
                        // validate={props.readOnly ? null : "required"}
                      />
                    )}
                    <Field
                      name="paymentCreditCardExpiryDate"
                      caption="วันหมดอายุบัตรเครดิต"
                      component={CreditCardExpireInput}
                      leftWidth={props.readOnly ? "190" : null}
                      rightWidth="200"
                      readOnly={props.readOnly}
                      // validate={
                      //   props.readOnly
                      //     ? null
                      //     : ["direct_debit", "direct_debit_cash"].includes(
                      //         props.values.paymentMethod
                      //       )
                      //     ? null
                      //     : "required"
                      // }
                      // isRequire={
                      //   !["direct_debit", "direct_debit_cash"].includes(props.values.paymentMethod)
                      // }
                    />
                  </Fragment>
                )}
            </Section>
          </PanelBarItem>
        </CustomPanelBar>
      </TabContainer>
    )}

    <ErrorDialog
      title="ข้อมูลไม่ครบถ้วน"
      onClose={props.onClose}
      isShowModal={props.modalError.length > 0 && props.toggleErrorModal}
    >
      <Container>
        <ErrorCaption>ข้อมูลต่อไปนี้ไม่ถูกต้องหรือเว้นว่างไว้</ErrorCaption>
        {props.modalError.map((item, index) => (
          <Fragment>
            {item.caption && <ErrorInfo key={index}>{`${item.caption}`}</ErrorInfo>}
            {typeof item.keys === "object" && item.keys.constructor === Array ? (
              item.keys &&
              item.keys.map((key, keyIndex) =>
                typeof key === "object" ? (
                  <Fragment>
                    <BeneficiaryErrorInfo key={index}>{`${key.caption}`}</BeneficiaryErrorInfo>
                    {key.message.map((msg, msgIndex) => (
                      <BeneficiaryErrorDescription
                        key={msgIndex}
                      >{`- ${msg}`}</BeneficiaryErrorDescription>
                    ))}
                  </Fragment>
                ) : (
                  <ErrorDescription key={keyIndex}>{`- ${key}`}</ErrorDescription>
                )
              )
            ) : (
              <ErrorDescription>{`- ${item.keys}`}</ErrorDescription>
            )}
          </Fragment>
        ))}
      </Container>
    </ErrorDialog>

    <ApproveOrderModal
      open={props.toggleApproveOrderModal}
      onClose={props.onCloseApproveOrderModal}
      onApproveOrder={props.onApproveOrder}
      submitting={props.submitting}
      disabled={props.disabled}
    />

    <ApproveOrderSuccessModal
      open={props.toggleApproveOrderSuccessModal}
      onClose={props.onCloseApproveOrderSuccessModalAndGotoOrder}
      validateFailModalMessage={props.validateFailModalMessage}
      onAttachDoc={props.onAttachDoc}
      onCloseModal={props.onCloseApproveOrderSuccessModal}
    />
  </Layout>
)

const insuranceHolderRequiredFields = [
  { key: "saleDate", label: "วันที่ในใบคำขอ" },
  { key: "nameInfo", label: "ชื่อ" },
  { key: "idCard", label: "เลขที่บัตรประชาชน" },
  { key: "race", label: "เชื้อชาติ" },
  { key: "nationality", label: "สัญชาติ" },
  { key: "religion", label: "ศาสนา" },
  { key: "birthdate", label: "วันเกิด" },
  { key: "gender", label: "เพศ" },
  { key: "maritalStatus", label: "สถานะภาพ" },
  { key: "routeCode", label: "เส้นวิ่ง" },
  { key: "policyAddressType", label: "สถานที่จัดส่งกรมธรรม์" },
  { key: "mobilePhoneNumber", label: "เบอร์มือถือ" },
  { key: "careerCode", label: "อาชีพ" },
]

const insuranceBeneficiariesRequiredFields = [
  { key: "nameInfo", label: "ชื่อ" },
  { key: "relationship", label: "ความสัมพันธ์" },
  { key: "percent", label: "ร้อยละของผู้รับผลประโยชน์ต้องรวมได้ 100" },
  { key: "descriptions", label: "ร้อยละของผู้รับผลประโยชน์ต้องรวมได้ 100" },
]

const insurancePayerRequiredFields = [
  { key: "nameInfo", label: "ชื่อ" },
  { key: "idCard", label: "เลขที่บัตรประชาชน" },
  { key: "birthdate", label: "วันเกิด" },
  { key: "gender", label: "เพศ" },
  { key: "maritalStatus", label: "สถานะภาพ" },
  { key: "mobilePhoneNumber", label: "เบอร์มือถือ" },
  { key: "careerCode", label: "อาชีพ" },
]

const currentAddressRequiredFields = [
  { key: "housingNo", label: "เลขที่" },
  { key: "subDistrict", label: "ตำบล/แขวง" },
  { key: "district", label: "อำเภอ/เขต" },
  { key: "province", label: "จังหวัด" },
  { key: "postcode", label: "รหัสไปรษณีย์ ต้องไม่เว้นว่างและไม่เกิน 8 ตัวอักษร" },
  { key: "building", label: "หมู่บ้าน/อาคาร ต้องไม่เกิน 100 ตัวอักษร" },
]

const enhancer = compose(
  withState("rejectModalConfirmOpened", "setRejectModalConfirmOpened", false),
  withState("suggestionModalConfirmOpened", "setSuggestionModalConfirmOpened", false),
  withState("loading", "setLoading", false),
  withState("isSelect", "setIsSelect", 0),
  withState("isEdit", "setIsEdit", false),
  withState("percentValidate", "setpercentValidate", false),
  withState("customerInfo", "setCustomerInfo", {}),
  withState("toggleErrorModal", "setToggleErrorModal", false),
  withState("toggleApproveOrderModal", "setToggleApproveOrderModal", false),
  withState("toggleApproveOrderSuccessModal", "setToggleApproveOrderSuccessModal", false),
  withState("maximumBeneficiaryAmount", "setMaximumBeneficiaryAmount", 4),
  withState("validateFailModalMessage", "setValidateFailModalMessage", {
    title: "",
  }),
  withState("filledFields", "setFilledFields", []),
  withState("isInsuranceHolder", "setIsInsuranceHolder", false),
  withState("disabled", "setDisabled", false),
  withRouter(),
  withStores((stores, props) => {
    let initialValues = stores.orderStore.$("current", {})
    let beneficiary = stores.orderStore.$("current.insuranceBeneficiaries", [])

    const mappedRiderProducts = initialValues.riderProducts.map((riderProduct) => {
      return { ...riderProduct, coverageDate: initialValues.coverageDate }
    })

    const paymentDetail = initialValues.paymentInstallments.find(
      (paymentInstallment) => paymentInstallment.remainingAmount != 0
    )

    initialValues = {
      ...initialValues,
      paymentDetails: {
        paymentPeriod: initialValues.paymentPeriod,
        paymentMethod: initialValues.paymentMethod,
        paymentDate: get(paymentDetail, "dueDate"),
        paymentAmount: get(paymentDetail, "remainingAmount"),
        paymentCreditCardHolderName: initialValues.paymentCreditCardHolderName,
        paymentCreditCardNumber: initialValues.paymentCreditCardNumber,
        paymentBankAccountName: initialValues.paymentBankAccountName,
        paymentBankAccountNumber: initialValues.paymentBankAccountNumber,
        paymentCreditCardType: initialValues.paymentCreditCardType,
        paymentBankAccountType: initialValues.paymentBankAccountType,
        paymentCreditCardBankCode: initialValues.paymentCreditCardBankCode,
        paymentBankCode: initialValues.paymentBankCode,
        paymentBankBranchName: initialValues.paymentBankBranchName,
        paymentCreditCardExpiryDate: {
          paymentCreditCardExpiryMonth: initialValues.paymentCreditCardExpiryMonth,
          paymentCreditCardExpiryYear: initialValues.paymentCreditCardExpiryYear,
        },
      },
      insuranceHolder: {
        ...initialValues.insuranceHolder,
        insuranceBeneficiaries: beneficiary,
        isStatutoryHeir: initialValues.isStatutoryHeir,
        serialNumber: initialValues.serialNumber,
        receiveTemp: initialValues.receiveTemp,
        policyNumber: initialValues.policyNumber,
        saleDate: initialValues.saleDate,
        suggestedBy: initialValues.suggestedBy,
      },
      insurancePayer: {
        ...initialValues.insurancePayer,
        isHolderCustomerChecked: false,
      },
      paymentCreditCardExpiryDate: {
        paymentCreditCardExpiryMonth: initialValues.paymentCreditCardExpiryMonth,
        paymentCreditCardExpiryYear: initialValues.paymentCreditCardExpiryYear,
      },
      riderProducts: mappedRiderProducts,
      policyAddressType: initialValues.insuranceHolder.policyAddressType,
      contactAddressType: initialValues.insuranceHolder.contactAddressType,
      routeCode: initialValues.routeCode || "R014",
    }

    initialValues = {
      ...initialValues,
      homeAddress: {
        ...initialValues.homeAddress,
        isSameCurrentAddress: false,
      },
      officeAddress: {
        ...initialValues.officeAddress,
        isSameCurrentAddress: false,
      },
    }

    const insuranceHolderCompareData = {
      nameInfo: initialValues.insuranceHolder.nameInfo,
      gender: initialValues.insuranceHolder.gender,
      birthdate: initialValues.insuranceHolder.birthdate,
      maritalStatus: initialValues.insuranceHolder.maritalStatus,
      mobilePhoneNumber: initialValues.insuranceHolder.mobilePhoneNumber,
      idCard: initialValues.insuranceHolder.idCard,
      expiredCardDate: initialValues.insuranceHolder.expiredCardDate,
      phoneNumber: initialValues.insuranceHolder.phoneNumber,
      officePhoneNumber: initialValues.insuranceHolder.officePhoneNumber,
      careerCode: initialValues.insuranceHolder.careerCode,
      position: initialValues.insuranceHolder.position,
      income: initialValues.insuranceHolder.income,
    }

    const insurancePayerCompareData = omit(initialValues.insurancePayer, [
      "isHolderCustomerChecked",
      "isHolderCustomer",
      "remarkRelationship",
      "id",
      "age",
      "email",
      "relationship",
    ])

    if (
      isEqual(insuranceHolderCompareData, insurancePayerCompareData) &&
      initialValues.insurancePayer.relationship === "property_owner"
    ) {
      initialValues = {
        ...initialValues,
        insurancePayer: {
          ...initialValues.insurancePayer,
          isHolderCustomerChecked: true,
        },
      }
    }

    const currentAddress = omit(initialValues.currentAddress, [
      "id",
      "addressType",
      "isSameCurrentAddress",
    ])
    const homeAddress = omit(initialValues.homeAddress, [
      "id",
      "addressType",
      "isSameCurrentAddress",
    ])
    const officeAddress = omit(initialValues.officeAddress, [
      "id",
      "addressType",
      "isSameCurrentAddress",
    ])

    if (!isEmpty(currentAddress)) {
      if (isEqual(homeAddress, currentAddress)) {
        initialValues = {
          ...initialValues,
          homeAddress: {
            ...initialValues.homeAddress,
            isSameCurrentAddress: true,
          },
        }
      }
      if (isEqual(officeAddress, currentAddress)) {
        initialValues = {
          ...initialValues,
          officeAddress: {
            ...initialValues.officeAddress,
            isSameCurrentAddress: true,
          },
        }
      }
    }

    return {
      currentSaleId: stores.appStore.$("currentSale.id"),

      orderId: props.match.params.orderId,
      initialValues: initialValues,
      canAffiliateSubmit: initialValues.canAffiliateSubmit,
      affiliateReadOnly: initialValues.affiliateReadOnly,
      affiliate: initialValues.affiliate,
      submitTypeAffiliate: null,
      currentRole: stores.appStore.currentRole,

      fetchLeadById: stores.leadStore.fetchById,
      fetchLeadConfigs: stores.leadStore.fetchConfigs,

      fetchOrder: stores.orderStore.fetchOrder,
      updateOrder: stores.orderStore.updateOrder,

      order: stores.orderStore.current || {},
      orderConfigs: stores.orderStore.orderConfigs,
      orderState: stores.orderStore.$("current.state", {}),

      lead: stores.leadStore.current || {},
      leadId: stores.orderStore.$("current.leadId"),

      insurancePayer: stores.orderStore.$("insurancePayer", {}),

      paymentPeriodOptions: stores.orderStore.$("current.paymentOptions", []),

      updatePayment: stores.orderStore.updatePayment,
      submitOrder: stores.orderStore.submitOrder,
      affiliateRejectOrder: stores.orderStore.affiliateRejectOrder,
      rejectOrder: stores.orderStore.rejectOrder,
      submitOrderAffiliate: stores.orderStore.submitOrderAffiliate,
      fetchOrderDocumentsByOrderId: stores.orderStore.fetchOrderDocumentsByOrderId,
    }
  }),
  withState("isHomeAddressSameCurrentAddres", "setIsHomeAddressSameCurrentAddres", false),
  withState("isOfficeAddressSameCurrentAddres", "setIsOfficeAddressSameCurrentAddres", false),
  withForm({
    form: "Insured",
    validate,
    onSubmitFail: (errors, dispatch, submitError, props) => {
      props.setToggleErrorModal(!props.toggleErrorModal)
    },
  }),
  withHandlers({
    openConfirmRejectModal: (props) => () => {
      props.setRejectModalConfirmOpened(true)
    },
    closeConfirmRejectModal: (props) => () => {
      props.setRejectModalConfirmOpened(false)
    },
    openConfirmSuggestionModal: (props) => async () => {
      props.setSuggestionModalConfirmOpened(true)
    },
    closeConfirmSuggestionModal: (props) => () => {
      props.setSuggestionModalConfirmOpened(false)
    },
    onAddBeneficiary: (props) => () => {
      if (props.fields.length < props.maximumBeneficiaryAmount) {
        props.fields.push({})
      }
    },
    onClose: (props) => () => {
      const $target = document.querySelector('[class*="k-state-invalid"]')

      if ($target) {
        $target.scrollIntoView({ behavior: "smooth", block: "center" })
      }
      props.setToggleErrorModal(!props.toggleErrorModal)
    },
    onCloseApproveOrderModal: (props) => () => {
      props.setToggleApproveOrderModal(false)
    },
    onCloseApproveOrderSuccessModalAndGotoOrder: (props) => () => {
      props.setToggleApproveOrderSuccessModal(false)
      props.history.push(path.ordersPath())
    },
    onCloseApproveOrderSuccessModal: (props) => () => {
      props.setToggleApproveOrderSuccessModal(false)
    },
    onSelect: (props) => (e) => props.setIsSelect(e.selected),
    onSubmit: (props) => async () => {
      const params = {
        ...props.values,
        saleDate: props.values.insuranceHolder.saleDate,
        serialNumber: props.values.insuranceHolder.serialNumber,
        receiveTemp: props.values.insuranceHolder.receiveTemp,
        policyNumber: props.values.insuranceHolder.policyNumber,
        suggestedBy: props.values.insuranceHolder.suggestedBy,
        insuranceHolder: {
          ...props.values.insuranceHolder,
          policyAddressType: props.values.policyAddressType,
          contactAddressType: props.values.contactAddressType,
        },
        insuranceBeneficiaries: props.values.insuranceHolder.insuranceBeneficiaries,
        orderId: props.orderId,
        paymentPlans: {
          paymentPeriod: props.values.paymentPeriod,
          paymentMethod: props.values.paymentMethod,
          firstDueDate: props.values.firstDueDate,
          paymentCreditCardHolderName: props.values.paymentCreditCardHolderName,
          paymentCreditCardNumber: props.values.paymentCreditCardNumber,
          paymentBankAccountName: props.values.paymentBankAccountName,
          paymentBankAccountNumber: props.values.paymentBankAccountNumber,
          paymentCreditCardType: props.values.paymentCreditCardType,
          paymentBankAccountType: props.values.paymentBankAccountType,
          paymentCreditCardBankCode: props.values.paymentCreditCardBankCode,
          paymentBankCode: props.values.paymentBankCode,
          paymentBankBranchName: props.values.paymentBankBranchName,
          paymentCreditCardExpiryMonth: get(
            props.values,
            "paymentCreditCardExpiryDate.paymentCreditCardExpiryMonth"
          ),
          paymentCreditCardExpiryYear: get(
            props.values,
            "paymentCreditCardExpiryDate.paymentCreditCardExpiryYear"
          ),
          routeCode: props.values.routeCode,
        },
        paymentCreditCardExpiryMonth: get(
          props.values,
          "paymentCreditCardExpiryDate.paymentCreditCardExpiryMonth"
        ),
        paymentCreditCardExpiryYear: get(
          props.values,
          "paymentCreditCardExpiryDate.paymentCreditCardExpiryYear"
        ),
        isStatutoryHeir: props.values.insuranceHolder.isStatutoryHeir,
        homeAddress: props.values.homeAddress.isSameCurrentAddress
          ? props.values.currentAddress
          : props.values.homeAddress,
        officeAddress: props.values.officeAddress.isSameCurrentAddress
          ? props.values.currentAddress
          : props.values.officeAddress,
      }
      try {
        await props.updateOrder(params)
      } catch (errors) {
        try {
          if (
            errors.errors.paymentCreditCardExpiryMonth === "is invalid." ||
            errors.errors.paymentCreditCardExpiryYear === "is invalid."
          ) {
            alert("กรอกวันหมดอายุบัตรเครดิตไม่ถูกต้อง")
          } else if (errors.errors?.percent) {
            alert(errors.errors.percent)
          } else if (errors.errors?.building) {
            alert(`หมู่บ้าน/อาคาร ${errors.errors.building}`)
          } else if (errors.errors?.base) {
            alert(errors.errors.base)
          } else {
            alert("บันทึกไม่สำเร็จ")
          }
        } catch (_error) {
          alert("บันทึกไม่สำเร็จ")
        }
      }
    },
    onCancel: (props) => () => {
      props.reset()
      props.setIsEdit(false)
    },
    onEdit: (props) => () => {
      props.setIsEdit(true)
    },
    onHolderCheckboxChange: (props) => async () => {
      const response = await props.fetchLeadById(props.leadId)
      props.setCustomerInfo(response.customerInfo)

      let keys = []
      for (let [key, value] of Object.entries(response.customerInfo || {})) {
        if (value) {
          keys.push(key)
        }
      }

      keys.map((key) => props.change(`insuranceHolder.${key}`, response.customerInfo[key]))
    },
    onApprove: (props) => async () => {
      props.setToggleApproveOrderModal(true)
    },
    onAttachDoc: (props) => async () => {
      props.setToggleApproveOrderSuccessModal(false)
      props.history.push(path.orderDocumentInfoPath(props.orderId))
    },
    onApproveOrder: (props) => async () => {
      props.setDisabled(true)
      const params = {
        ...props.values,
        saleDate: props.values.insuranceHolder.saleDate,
        serialNumber: props.values.insuranceHolder.serialNumber,
        receiveTemp: props.values.insuranceHolder.receiveTemp,
        policyNumber: props.values.insuranceHolder.policyNumber,
        suggestedBy: props.values.insuranceHolder.suggestedBy,
        insuranceHolder: {
          ...props.values.insuranceHolder,
          policyAddressType: props.values.policyAddressType,
          contactAddressType: props.values.contactAddressType,
        },
        isStatutoryHeir: props.values.insuranceHolder.isStatutoryHeir,
        insuranceBeneficiaries: props.values.insuranceHolder.insuranceBeneficiaries,
        orderId: props.orderId,
        paymentCreditCardExpiryMonth: get(
          props.values,
          "paymentCreditCardExpiryDate.paymentCreditCardExpiryMonth"
        ),
        paymentCreditCardExpiryYear: get(
          props.values,
          "paymentCreditCardExpiryDate.paymentCreditCardExpiryYear"
        ),
        paymentPlans: {
          paymentPeriod: props.values.paymentPeriod,
          paymentMethod: props.values.paymentMethod,
          firstDueDate: props.values.firstDueDate,
          paymentCreditCardHolderName: props.values.paymentCreditCardHolderName,
          paymentCreditCardNumber: props.values.paymentCreditCardNumber,
          paymentBankAccountName: props.values.paymentBankAccountName,
          paymentBankAccountNumber: props.values.paymentBankAccountNumber,
          paymentCreditCardType: props.values.paymentCreditCardType,
          paymentBankAccountType: props.values.paymentBankAccountType,
          paymentCreditCardBankCode: props.values.paymentCreditCardBankCode,
          paymentBankCode: props.values.paymentBankCode,
          paymentBankBranchName: props.values.paymentBankBranchName,
          paymentCardExpireDate: props.values.paymentCardExpireDate,
          routeCode: props.values.routeCode,
        },
        homeAddress: props.values.homeAddress.isSameCurrentAddress
          ? props.values.currentAddress
          : props.values.homeAddress,
        officeAddress: props.values.officeAddress.isSameCurrentAddress
          ? props.values.currentAddress
          : props.values.officeAddress,
      }
      try {
        await props.submitOrder(props.orderId, params)
        if (props.order.remark) {
          props.setValidateFailModalMessage({
            title: "แจ้งงานไม่สำเร็จ",
          })
          props.setDisabled(false)
          props.setToggleApproveOrderModal(false)
          props.setToggleApproveOrderSuccessModal(true)
        } else {
          await props.fetchOrderDocumentsByOrderId(props.orderId)
          props.setDisabled(false)
          props.setToggleApproveOrderModal(false)
          props.setToggleApproveOrderSuccessModal(true)
        }
      } catch (e) {
        let title = "แจ้งงานไม่สำเร็จ"
        if (e.errors?.postcode) {
          title = `รหัสไปรษณีย์ ${e.errors.postcode}`
        } else if (e.errors?.building) {
          title = `หมู่บ้าน/อาคาร ${e.errors.postcode}`
        }

        props.setValidateFailModalMessage({
          title: title,
        })
        props.setDisabled(false)
        props.setToggleApproveOrderModal(false)
        props.setToggleApproveOrderSuccessModal(true)
      }
    },
    affiliateRejectOrder: (props) => async () => {
      try {
        await props.affiliateRejectOrder(props.values.id)
        props.history.push(path.ordersPath())
      } catch {
        alert("ไม่สามารถยกเลิกรายการได้ กรุณาลองใหม่ภายหลัง")
        props.setRejectModalConfirmOpened(false)
      }
    },
    rejectOrder: (props) => async () => {
      try {
        await props.rejectOrder(props.values.id)
        props.history.push(path.ordersPath())
      } catch {
        alert("ไม่สามารถยกเลิกรายการได้ กรุณาลองใหม่ภายหลัง")
        props.setRejectModalConfirmOpened(false)
      }
    },
    submitSuggestion: (props) => async (values) => {
      const params = {
        ...values,
        ...props.values,
        saleDate: props.values.insuranceHolder.saleDate,
        serialNumber: props.values.insuranceHolder.serialNumber,
        receiveTemp: props.values.insuranceHolder.receiveTemp,
        policyNumber: props.values.insuranceHolder.policyNumber,
        insuranceHolder: {
          ...props.values.insuranceHolder,
          policyAddressType: props.values.policyAddressType,
          contactAddressType: props.values.contactAddressType,
        },
        isStatutoryHeir: props.values.insuranceHolder.isStatutoryHeir,
        insuranceBeneficiaries: props.values.insuranceHolder.insuranceBeneficiaries,
        orderId: props.orderId,
        paymentCreditCardExpiryMonth: get(
          props.values,
          "paymentCreditCardExpiryDate.paymentCreditCardExpiryMonth"
        ),
        paymentCreditCardExpiryYear: get(
          props.values,
          "paymentCreditCardExpiryDate.paymentCreditCardExpiryYear"
        ),
        paymentPlans: {
          paymentPeriod: props.values.paymentPeriod,
          paymentMethod: props.values.paymentMethod,
          firstDueDate: props.values.firstDueDate,
          paymentCreditCardHolderName: props.values.paymentCreditCardHolderName,
          paymentCreditCardNumber: props.values.paymentCreditCardNumber,
          paymentBankAccountName: props.values.paymentBankAccountName,
          paymentBankAccountNumber: props.values.paymentBankAccountNumber,
          paymentCreditCardType: props.values.paymentCreditCardType,
          paymentBankAccountType: props.values.paymentBankAccountType,
          paymentCreditCardBankCode: props.values.paymentCreditCardBankCode,
          paymentBankCode: props.values.paymentBankCode,
          paymentBankBranchName: props.values.paymentBankBranchName,
          paymentCardExpireDate: props.values.paymentCardExpireDate,
          routeCode: props.values.routeCode,
        },
        homeAddress: props.values.homeAddress.isSameCurrentAddress
          ? props.values.currentAddress
          : props.values.homeAddress,
        officeAddress: props.values.officeAddress.isSameCurrentAddress
          ? props.values.currentAddress
          : props.values.officeAddress,
      }

      try {
        await props.submitOrderAffiliate(props.orderId, params)
        props.setValidateFailModalMessage({
          affiliate: true,
        })
      } catch (e) {
        let title = "แจ้งงานไม่สำเร็จ"
        if (e.errors?.base) {
          title = `แจ้งงานไม่สำเร็จ ${e.errors.base}`
        }
        props.setValidateFailModalMessage({
          title: title,
        })
      }
      props.setSuggestionModalConfirmOpened(false)
      props.setToggleApproveOrderSuccessModal(true)
    },
  }),
  withProps((props) => {
    const newProps = {}
    if (props.error) {
      const { insuranceHolder } = props.error || {}
      if (
        insuranceHolder &&
        typeof insuranceHolder === "object" &&
        insuranceHolder.constructor === Array
      ) {
        newProps.holderInfoError = {
          caption: "ข้อมูลผู้เอาประกัน",
          keys:
            (insuranceHolder &&
              insuranceHolder.map(
                (field) =>
                  insuranceHolderRequiredFields.find(({ key, label }) => {
                    if (field === key) {
                      return label
                    }
                  }).label
              )) ||
            [],
        }
      } else if (insuranceHolder && typeof insuranceHolder === "object") {
        const validateFailedKeys = Object.entries(insuranceHolder)
          .filter((item) => insuranceHolderRequiredFields.find((field) => item[0] === field.key))
          .map((item) =>
            insuranceHolderRequiredFields
              .map((field) => {
                if (field.key === item[0]) {
                  item[0] = field.label
                  return `${item[0]} : ${item[1]}`
                }
              })
              .filter((item) => item !== undefined)
          )
        newProps.holderInfoError = {
          caption: "ข้อมูลผู้เอาประกัน",
          keys: validateFailedKeys,
        }
      }
    }

    return {
      ...props,
      ...newProps,
    }
  }),
  withProps((props) => {
    const newProps = {}
    if (props.error) {
      const { insurancePayer } = props.error || {}
      if (
        insurancePayer &&
        typeof insurancePayer === "object" &&
        insurancePayer.constructor === Array
      ) {
        newProps.payerInfoError = {
          caption: "ข้อมูลผู้ชำระเบี้ย",
          keys:
            (insurancePayer &&
              insurancePayer.map(
                (field) =>
                  insurancePayerRequiredFields.find(({ key, label }) => {
                    if (field === key) {
                      return label
                    }
                  }).label
              )) ||
            [],
        }
      } else if (insurancePayer && typeof insurancePayer === "object") {
        const validateFailedKeys = Object.entries(insurancePayer)
          .filter((item) => insurancePayerRequiredFields.find((field) => item[0] === field.key))
          .map((item) =>
            insurancePayerRequiredFields
              .map((field) => {
                if (field.key === item[0]) {
                  item[0] = field.label
                  return `${item[0]} : ${item[1]}`
                }
              })
              .filter((item) => item !== undefined)
          )
        newProps.payerInfoError = {
          caption: "ข้อมูลผู้ชำระเบี้ย",
          keys: validateFailedKeys,
        }
      }
    }
    return {
      ...props,
      ...newProps,
    }
  }),
  withProps((props) => {
    const newProps = {}
    if (!props.error) {
      return
    }

    const { currentAddress } = props.error || {}
    if (
      currentAddress &&
      typeof currentAddress === "object" &&
      currentAddress.constructor === Array
    ) {
      newProps.currentAddressError = {
        caption: "ที่อยู่ปัจจุบัน",
        message:
          (currentAddress &&
            currentAddress.map(
              (field) =>
                currentAddressRequiredFields.find(({ key, label }) => {
                  if (field === key) {
                    return label
                  }
                }).label
            )) ||
          [],
      }
    } else if (currentAddress && typeof currentAddress === "object") {
      const validateFailedKeys = Object.entries(currentAddress)
        .filter((item) => currentAddressRequiredFields.find((field) => item[0] === field.key))
        .map((item) =>
          currentAddressRequiredFields
            .map((field) => {
              if (field.key === item[0]) {
                item[0] = field.label
                return `${item[0]} : ${item[1]}`
              }
            })
            .filter((item) => item !== undefined)
        )
      newProps.currentAddressError = {
        caption: "ที่อยู่ปัจจุบัน",
        message: validateFailedKeys,
      }
    }
    return {
      ...props,
      ...newProps,
    }
  }),
  withProps((props) => {
    const newProps = {}
    if (!props.error) {
      return
    }

    const { homeAddress } = props.error || {}
    if (homeAddress && typeof homeAddress === "object" && homeAddress.constructor === Array) {
      newProps.homeAddressError = {
        caption: "ที่อยู่ตามทะเบียนบ้าน",
        message:
          (homeAddress &&
            homeAddress.map(
              (field) =>
                currentAddressRequiredFields.find(({ key, label }) => {
                  if (field === key) {
                    return label
                  }
                }).label
            )) ||
          [],
      }
    } else if (homeAddress && typeof homeAddress === "object") {
      const validateFailedKeys = Object.entries(homeAddress)
        .filter((item) => currentAddressRequiredFields.find((field) => item[0] === field.key))
        .map((item) =>
          currentAddressRequiredFields
            .map((field) => {
              if (field.key === item[0]) {
                item[0] = field.label
                return `${item[0]} : ${item[1]}`
              }
            })
            .filter((item) => item !== undefined)
        )
      newProps.homeAddressError = {
        caption: "ที่อยู่ตามทะเบียนบ้าน",
        message: validateFailedKeys,
      }
    }
    return {
      ...props,
      ...newProps,
    }
  }),
  withProps((props) => {
    const newProps = {}
    if (!props.error) {
      return
    }

    const { officeAddress } = props.error || {}
    if (officeAddress && typeof officeAddress === "object" && officeAddress.constructor === Array) {
      newProps.officeAddressError = {
        caption: "ที่อยู่ที่ทำงาน",
        message:
          (officeAddress &&
            officeAddress.map(
              (field) =>
                currentAddressRequiredFields.find(({ key, label }) => {
                  if (field === key) {
                    return label
                  }
                }).label
            )) ||
          [],
      }
    } else if (officeAddress && typeof officeAddress === "object") {
      const validateFailedKeys = Object.entries(officeAddress)
        .filter((item) => currentAddressRequiredFields.find((field) => item[0] === field.key))
        .map((item) =>
          currentAddressRequiredFields
            .map((field) => {
              if (field.key === item[0]) {
                item[0] = field.label
                return `${item[0]} : ${item[1]}`
              }
            })
            .filter((item) => item !== undefined)
        )
      newProps.officeAddressError = {
        caption: "ที่อยู่ที่ทำงาน",
        message: validateFailedKeys,
      }
    }
    return {
      ...props,
      ...newProps,
    }
  }),
  withProps((props) => {
    const newProps = {}
    const beneficiarieError = []
    const { insuranceBeneficiaries } = props.error || []

    insuranceBeneficiaries &&
      insuranceBeneficiaries.map((beneficiary, index) => {
        const beneficiaryKeys = Object.keys(beneficiary)
        beneficiarieError.push({
          caption: `ผู้รับผลประโยชน์คนที่ ${index + 1}`,
          message: beneficiaryKeys
            .map(
              (field) =>
                insuranceBeneficiariesRequiredFields.find(({ key, label }) => {
                  if (field === key) {
                    return label
                  }
                }).label
            )
            .filter((item) => item !== undefined),
        })
      })

    newProps.beneficiariesError = {
      caption: "ข้อมูลผู้รับประโยชน์",
      keys: beneficiarieError,
    }

    return {
      ...props,
      ...newProps,
    }
  }),
  withProps((props) => {
    const newProps = {}
    if (!props.error) {
      return
    }

    const {
      paymentPeriod,
      paymentMethod,
      firstDueDate,
      routeCode,
      policyAddressType,
      contactAddressType,
      // paymentCreditCardHolderName,
      // paymentBankAccountName,
      // paymentCreditCardNumber,
      // paymentBankAccountNumber,
      // paymentCreditCardType,
      // paymentBankAccountType,
      // paymentCreditCardBankCode,
      // paymentBankCode,
      // paymentBankBranchName,
      // paymentCreditCardExpiryDate,
    } = props.error || {}
    const paymentPlansKeys = []
    const policyAddressTypeKeys = []

    if (paymentPeriod) {
      paymentPlansKeys.push("โหมดการชำระ")
    }

    if (paymentMethod) {
      paymentPlansKeys.push("วิธีการชำระ")
    }

    if (firstDueDate) {
      paymentPlansKeys.push("วันนัดชำระ")
    }

    if (routeCode) {
      paymentPlansKeys.push("เส้นวิ่ง")
    }

    // if (
    //   !["bill_payment", "cash"].includes(props.values.paymentMethod) &&
    //   !!props.values.paymentMethod
    // ) {
    //   if (["credit_card", "credit_card_cash"].includes(props.values.paymentMethod)) {
    //     if (paymentCreditCardHolderName) {
    //       paymentPlansKeys.push("ชื่อผู้ถือบัตร")
    //     }

    //     if (paymentCreditCardNumber) {
    //       paymentPlansKeys.push("เลขที่บัตร")
    //     }

    //     if (paymentCreditCardType) {
    //       paymentPlansKeys.push("ประเภทบัตร")
    //     }

    //     if (paymentCreditCardBankCode) {
    //       paymentPlansKeys.push("ธนาคารของบัตรเครดิต")
    //     }

    //     if (paymentCreditCardExpiryDate) {
    //       paymentPlansKeys.push("วันหมดอายุบัตร")
    //     }
    //   } else {
    //     if (paymentBankAccountName) {
    //       paymentPlansKeys.push("ชื่อบัญชีธนาคาร")
    //     }

    //     if (paymentBankAccountNumber) {
    //       paymentPlansKeys.push("เลขที่บัญชีธนาคาร")
    //     }

    //     if (paymentBankAccountType) {
    //       paymentPlansKeys.push("ประเภทบัญชีธนาคาร")
    //     }

    //     if (paymentBankCode) {
    //       paymentPlansKeys.push("รหัสธนาคาร")
    //     }

    //     if (paymentBankBranchName) {
    //       paymentPlansKeys.push("สาขาธนาคาร")
    //     }
    //   }
    // }

    if (policyAddressType) {
      policyAddressTypeKeys.push("สถานที่จัดส่งเอกสาร")
    }

    if (contactAddressType) {
      policyAddressTypeKeys.push("ที่อยู่สำหรับการติดต่อ")
    }

    newProps.paymentPlansError = {
      caption: "ข้อมูลการชำระเงิน",
      keys: paymentPlansKeys,
    }

    newProps.policyAddressTypeError = policyAddressTypeKeys

    return {
      ...props,
      ...newProps,
    }
  }),
  withProps((props) => {
    const modalError = []
    if (get(props.holderInfoError, "keys.length") > 0) {
      modalError.push(props.holderInfoError)
    }
    if (props.beneficiariesError && get(props.beneficiariesError, "keys.length") > 0) {
      modalError.push(props.beneficiariesError)
    }
    if (get(props.payerInfoError, "keys.length") > 0) {
      modalError.push(props.payerInfoError)
    }

    const addressError = []
    if (get(props.currentAddressError, "message.length") > 0) {
      addressError.push(props.currentAddressError)
    }
    if (get(props.homeAddressError, "message.length") > 0) {
      addressError.push(props.homeAddressError)
    }
    if (get(props.officeAddressError, "message.length") > 0) {
      addressError.push(props.officeAddressError)
    }
    if (get(addressError, "length") > 0) {
      const addressRequiredFields = []
      addressError.forEach((error) => addressRequiredFields.push(error))
      if (get(props.policyAddressTypeError, "length") > 0) {
        props.policyAddressTypeError.forEach((error) => addressRequiredFields.push(error))
      }
      modalError.push({
        caption: "ที่อยู่",
        keys: addressRequiredFields,
      })
    }
    if (get(addressError, "length") === 0 && get(props.policyAddressTypeError, "length") > 0) {
      modalError.push({
        caption: "ที่อยู่",
        keys: props.policyAddressTypeError,
      })
    }
    if (get(props.paymentPlansError, "keys.length") > 0) {
      modalError.push(props.paymentPlansError)
    }
    const currentAddress = Object.values(props.values.currentAddress || {})
      .filter((item) => item !== "current")
      .filter((item) => item !== null)
      .filter((item) => typeof item === "string")
      .join(" ")
    const officeAddress = Object.values(props.values.officeAddress || {})
      .filter((item) => item !== "office")
      .filter((item) => item !== null)
      .filter((item) => typeof item === "string")
      .join(" ")
    const homeAddress = Object.values(props.values.homeAddress || {})
      .filter((item) => item !== "home")
      .filter((item) => item !== null)
      .filter((item) => typeof item === "string")
      .join(" ")
    const { attributes: orderConfigAttributes } = props.orderConfigs || {}
    const {
      career,
      gender,
      maritalStatus,
      nationality,
      race,
      relationship,
      religion,
      inTheNameOf,
      priority,
      routeCode,
      paymentCreditCardType,
      paymentCreditCardBankCode,
      paymentBankCode,
      paymentBankAccountType,
      contactAddressType,
    } = orderConfigAttributes || {}
    const { options: careerOptions } = career || []
    const { options: genderOptions } = gender || []
    const { options: maritalStatusOptions } = maritalStatus || []
    const { options: nationalityOptions } = nationality || []
    const { options: raceOptions } = race || []
    const { options: relationshipOptions } = relationship || []
    const { options: religionOptions } = religion || []
    const { options: contactAddressTypeOptions } = contactAddressType || []
    const { options: inTheNameOfOptions } = inTheNameOf || []
    const { options: priorityOptions } = priority || []
    const { options: routeCodeOptions } = routeCode || []
    const { options: paymentCreditCardTypeOptions } = paymentCreditCardType || []
    const { options: paymentCreditCardBankCodeOptions } = paymentCreditCardBankCode || []
    const { options: paymentBankCodeOptions } = paymentBankCode || []
    const { options: paymentBankAccountTypeOptions } = paymentBankAccountType || []
    return {
      genderOptions: genderOptions,
      careerOptions: careerOptions,
      maritalStatusOptions: maritalStatusOptions,
      nationalityOptions: nationalityOptions,
      raceOptions: raceOptions,
      relationshipOptions: relationshipOptions,
      religionOptions: religionOptions,
      contactAddressTypeOptions: contactAddressTypeOptions,
      inTheNameOfOptions: inTheNameOfOptions,
      priorityOptions: priorityOptions,
      routeCodeOptions: routeCodeOptions,
      paymentCreditCardTypeOptions,
      paymentBankAccountTypeOptions,
      paymentCreditCardBankCodeOptions,
      paymentBankCodeOptions,
      currentAddress,
      officeAddress,
      homeAddress,
      modalError: modalError,
    }
  }),
  withProps((props) => {
    const isMinorHolderInfo = props.values.insuranceHolder.birthdate
      ? moment().diff(moment(props.values.insuranceHolder.birthdate), "years") < 20
      : false

    // set readOnly
    let readOnly = false
    if (props.order.tqmOrderId) {
      readOnly = true
    } else if (
      !["new", "affiliate_new", "doc_attaching", "doc_verifying"].includes(props.orderState)
    ) {
      readOnly = true
    } else if (["member.affiliate", "member.salesman"].includes(props.currentRole)) {
      readOnly = !!props.affiliateReadOnly
    } else if (
      !["member.team_leader", "member.biz_dev"].includes(
        props.currentRole
      )
    ) {
      readOnly = true
    }

    return {
      paymentMethodOptions: safe(
        () =>
          find(props.paymentPeriodOptions, {
            value: props.values.paymentPeriod,
          }).paymentMethods,
        []
      ),
      hiddenTable: props.paymentPlans && props.paymentPlans.length > 0 ? false : true,
      readOnly: readOnly,
      renderRiderSection: props.order.riderProducts.length > 0,
      isMinorHolderInfo,
    }
  }),
  withHooks(() => {
    const scrollY = useScrollPosition(60)

    return {
      isTabSticky: scrollY >= 98,
    }
  }),
  withHandlers({
    tqmNextBusinessDay: () => () => {
      const weekday = moment().weekday()

      switch (weekday) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
          return moment().add(1, "days")._d
        case 6:
          return moment().add(2, "days")._d
        default:
          return moment().add(1, "days")._d
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.setLoading(true)

      const { insuranceHolder, state } = this.props.order || {}
      const insuranceholderKeys = Object.keys(insuranceHolder || {})
      const filteredKeys = insuranceholderKeys
        .map((insuranceholderKey) =>
          insuranceHolderRequiredFields.find((requiredField) => {
            if (
              insuranceholderKey === requiredField.key &&
              insuranceHolder[requiredField.key] === null
            ) {
              return requiredField
            }
          })
        )
        .filter((item) => item !== undefined)
      if (filteredKeys.length > 0 && state === "confirmed") {
        this.props.setIsEdit(true)
      }

      // this.props.change("firstDueDate", this.props.tqmNextBusinessDay())
      this.props.setLoading(false)
    },
    componentDidUpdate(prevProps) {
      if (
        get(this.props, "values.insurancePayer.isHolderCustomerChecked") !==
        get(prevProps, "values.insurancePayer.isHolderCustomerChecked")
      ) {
        if (get(this.props, "values.insurancePayer.isHolderCustomerChecked")) {
          let keys = []
          for (let [key, value] of Object.entries(
            get(this.props, "values.insuranceHolder") || {}
          )) {
            if (value) {
              keys.push(key)
            }
          }
          const filledFields = []

          keys.map((key) => {
            if (
              get(this.props, `values.insuranceHolder[${key}]`) !==
              get(this.props, `values.insurancePayer[${key}]`)
            ) {
              this.props.change(`insurancePayer.${key}`, this.props.values.insuranceHolder[key])
              filledFields.push(key)
            }
          })
          const FilteredArray = filledFields.filter((field) => field !== "nameInfo")
          this.props.setFilledFields(FilteredArray)
          this.props.change("insurancePayer.relationship", "property_owner")
        } else {
          this.props.change("insurancePayer.isHolderCustomerChecked", false)
          this.props.change("insurancePayer.relationship", null)
        }
      }

      if (
        get(this.props, "values.homeAddress.isSameCurrentAddress") !==
          get(prevProps, "values.homeAddress.isSameCurrentAddress") ||
        (get(this.props, "values.homeAddress.isSameCurrentAddress") &&
          get(this.props, "values.currentAddress") !== get(prevProps, "values.currentAddress"))
      ) {
        let keys = []
        for (let [key, value] of Object.entries(get(this.props, "values.currentAddress") || {})) {
          if (value) {
            keys.push(key)
          }
        }

        keys.map((key) => {
          if (
            get(this.props, `values.currentAddress[${key}]`) !==
            get(this.props, `values.homeAddress[${key}]`)
          ) {
            this.props.change(`homeAddress.${key}`, this.props.values.currentAddress[key])
          }
        })
      }

      if (
        get(this.props, "values.officeAddress.isSameCurrentAddress") !==
          get(prevProps, "values.officeAddress.isSameCurrentAddress") ||
        (get(this.props, "values.officeAddress.isSameCurrentAddress") &&
          get(this.props, "values.currentAddress") !== get(prevProps, "values.currentAddress"))
      ) {
        let keys = []
        for (let [key, value] of Object.entries(get(this.props, "values.currentAddress") || {})) {
          if (value) {
            keys.push(key)
          }
        }

        keys.map((key) => {
          if (
            get(this.props, `values.currentAddress[${key}]`) !==
            get(this.props, `values.officeAddress[${key}]`)
          ) {
            this.props.change(`officeAddress.${key}`, this.props.values.currentAddress[key])
          }
        })
      }

      if (
        get(prevProps, "values.insuranceHolder.nameInfo") !==
        get(this.props, "values.insuranceHolder.nameInfo")
      ) {
        if (get(this.props, "values.insurancePayer.isHolderCustomerChecked")) {
          this.props.change("insurancePayer.nameInfo", this.props.values.insuranceHolder.nameInfo)
        }
      }

      if (
        get(prevProps, "values.insuranceHolder.gender") !==
        get(this.props, "values.insuranceHolder.gender")
      ) {
        if (get(this.props, "values.insurancePayer.isHolderCustomerChecked")) {
          this.props.change("insurancePayer.gender", this.props.values.insuranceHolder.gender)
        }
      }

      if (
        get(prevProps, "values.insuranceHolder.birthdate") !==
        get(this.props, "values.insuranceHolder.birthdate")
      ) {
        if (get(this.props, "values.insurancePayer.isHolderCustomerChecked")) {
          this.props.change("insurancePayer.birthdate", this.props.values.insuranceHolder.birthdate)
        }
      }

      if (
        get(prevProps, "values.insuranceHolder.maritalStatus") !==
        get(this.props, "values.insuranceHolder.maritalStatus")
      ) {
        if (get(this.props, "values.insurancePayer.isHolderCustomerChecked")) {
          this.props.change(
            "insurancePayer.maritalStatus",
            this.props.values.insuranceHolder.maritalStatus
          )
        }
      }

      if (
        get(prevProps, "values.insuranceHolder.mobilePhoneNumber") !==
        get(this.props, "values.insuranceHolder.mobilePhoneNumber")
      ) {
        if (get(this.props, "values.insurancePayer.isHolderCustomerChecked")) {
          this.props.change(
            "insurancePayer.mobilePhoneNumber",
            this.props.values.insuranceHolder.mobilePhoneNumber
          )
        }
      }

      if (
        get(prevProps, "values.insuranceHolder.idCard") !==
        get(this.props, "values.insuranceHolder.idCard")
      ) {
        if (get(this.props, "values.insurancePayer.isHolderCustomerChecked")) {
          this.props.change("insurancePayer.idCard", this.props.values.insuranceHolder.idCard)
        }
      }

      if (
        get(prevProps, "values.insuranceHolder.careerCode") !==
        get(this.props, "values.insuranceHolder.careerCode")
      ) {
        if (get(this.props, "values.insurancePayer.isHolderCustomerChecked")) {
          this.props.change(
            "insurancePayer.careerCode",
            this.props.values.insuranceHolder.careerCode
          )
        }
      }

      if (get(this.props, "values.paymentPeriod") !== get(prevProps, "values.paymentPeriod")) {
        this.props.change("paymentMethod", null)
      }

      if (get(this.props, "values.paymentMethod") !== get(prevProps, "values.paymentMethod")) {
        this.props.change("paymentCreditCardHolderName", null)
        this.props.change("paymentCreditCardNumber", null)
        this.props.change("paymentBankAccountName", null)
        this.props.change("paymentBankAccountNumber", null)
        this.props.change("paymentCreditCardType", null)
        this.props.change("paymentBankAccountType", null)
        this.props.change("paymentCreditCardBankCode", null)
        this.props.change("paymentBankCode", null)
        this.props.change("paymentBankBranchName", null)
        this.props.change("paymentCreditCardExpiryDate", {
          paymentCreditCardExpiryMonth: null,
          paymentCreditCardExpiryYear: null,
        })
      }

      if (
        get(this.props, "values.insuranceHolder.nameInfo.prefixName") !==
        get(prevProps, "values.insuranceHolder.nameInfo.prefixName")
      ) {
        if (
          ["นาย", "ด.ช."].includes(get(this.props, "values.insuranceHolder.nameInfo.prefixName"))
        ) {
          this.props.change("insuranceHolder.gender", "male")
        } else if (
          ["นาง", "นางสาว", "ด.ญ."].includes(
            get(this.props, "values.insuranceHolder.nameInfo.prefixName")
          )
        ) {
          this.props.change("insuranceHolder.gender", "female")
        }
      }
    },
  }),
  withHandlers({
    reCalculateBeneficiaryPercents: (props) => () => {
      const insuranceBeneficiaries = get(props.values, "insuranceHolder.insuranceBeneficiaries")
      insuranceBeneficiaries.forEach((_, index) => {
        let newValue = Math.round((100 / insuranceBeneficiaries.length) * 100) / 100.0

        if (index === 0) {
          newValue = newValue + (100 - newValue * insuranceBeneficiaries.length)
        }

        props.change(`insuranceHolder.insuranceBeneficiaries[${index}].percent`, newValue)
      })
    },
  })
)

export default enhancer(HolderInfo)
