import React from "react"
import styled from "@emotion/styled"
import { Avatar } from "@components/index"
import { displayNumber } from "@common/helper"
import { BlueText } from "./index"

const SectionCard = styled.div`
  background-color: #ffffff;
  border: 1px solid #eeeef2;
  border-radius: 4px;
  padding: 15px 20px 0px 20px;
  margin-bottom: 24px;
`
const CardHeader = styled.div`
  display: flex;
  align-items: center;
  min-height: 38px;
  margin-bottom: 16px;
  font-weight: bold;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  color: #666666;
  > div:first-of-type {
    margin-right: 10px;
  }
`
const CardRow = styled.div`
  display: flex;
  align-items: flex-start;
`
const WrapperDetail = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`
const WrapperBefore = styled(WrapperDetail)`
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  color: #666666;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  > div:first-of-type {
    margin-bottom: 4px;
  }
`
const Caption = styled.div`
  font-weight: bold;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  width: 155px;
  color: #666666;
`
const Description = styled.div`
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  color: #666666;
  white-space: nowrap;
`

const CardProduct = (props) => (
  <SectionCard className={props.className}>
    <CardHeader>
      {props.logoPath ? <Avatar src={props.logoPath} size="38" /> : null}
      {props.product.policyName}
    </CardHeader>
    <CardRow>
      <WrapperDetail>
        <Caption>เบี้ยสุทธิ</Caption>
        <Description>
          <BlueText
            isBlue={
              props.event !== "import" &&
              props.product.previousPremium !== props.product.realPremium
            }
          >
            {displayNumber(parseFloat(props.product.premium || 0).toFixed(2))} บาท
          </BlueText>
        </Description>
      </WrapperDetail>
      <WrapperBefore>
        <BlueText isBlue={props.event === "import"}>
          *รับจริง {displayNumber(parseFloat(props.product.realPremium || 0).toFixed(2))} บาท
        </BlueText>
        <BlueText
          isBlue={
            props.event === "import" || props.product.previousPremium !== props.product.realPremium
          }
        >
          คงเหลือ{" "}
          {displayNumber(
            parseFloat(
              parseFloat(props.product.premium || 0).toFixed(2) -
                parseFloat(props.product.realPremium || 0).toFixed(2)
            ).toFixed(2)
          )}{" "}
          บาท
        </BlueText>
      </WrapperBefore>
    </CardRow>

    <CardRow>
      <WrapperDetail>
        <Caption>ค่านายหน้า</Caption>
        <Description>
          <BlueText
            isBlue={
              props.event !== "import" &&
              props.product.previousCommissionAmount !== props.product.realCommissionAmount
            }
          >
            {displayNumber(parseFloat(props.product.commissionAmount || 0).toFixed(2))} บาท
          </BlueText>
        </Description>
      </WrapperDetail>
      <WrapperBefore>
        <BlueText isBlue={props.event === "import"}>
          *รับจริง {displayNumber(parseFloat(props.product.realCommissionAmount || 0).toFixed(2))}{" "}
          บาท
        </BlueText>
        <BlueText
          isBlue={
            props.event === "import" ||
            props.product.previousCommissionAmount !== props.product.realCommissionAmount
          }
        >
          คงเหลือ{" "}
          {displayNumber(
            parseFloat(
              parseFloat(props.product.commissionAmount || 0).toFixed(2) -
                parseFloat(props.product.realCommissionAmount || 0).toFixed(2)
            ).toFixed(2)
          )}{" "}
          บาท
        </BlueText>
      </WrapperBefore>
    </CardRow>

    <CardRow>
      <WrapperDetail>
        <Caption>ค่าบริการ</Caption>
        <Description>
          <BlueText
            isBlue={
              props.event !== "import" &&
              props.product.previousServiceCharge !== props.product.realServiceCharge
            }
          >
            {displayNumber(parseFloat(props.product.serviceCharge || 0).toFixed(2))} บาท
          </BlueText>
        </Description>
      </WrapperDetail>
      <WrapperBefore>
        <BlueText isBlue={props.event === "import"}>
          *รับจริง {displayNumber(parseFloat(props.product.realServiceCharge || 0).toFixed(2))} บาท
        </BlueText>
        <BlueText
          isBlue={
            props.event === "import" ||
            props.product.previousServiceCharge !== props.product.realServiceCharge
          }
        >
          คงเหลือ{" "}
          {displayNumber(
            parseFloat(
              parseFloat(props.product.serviceCharge || 0).toFixed(2) -
                parseFloat(props.product.realServiceCharge || 0).toFixed(2)
            ).toFixed(2)
          )}{" "}
          บาท
        </BlueText>
      </WrapperBefore>
    </CardRow>
  </SectionCard>
)

export default CardProduct
