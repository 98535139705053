import React, { useState, useEffect, useMemo, useCallback } from "react"
import styled from "@emotion/styled"
import CloseTheDeal from "./CloseTheDeal"
import { compose, lifecycle } from "recompose"
import { withForm, withHooks, withRouter, withStores } from "@enhancers/index"
import { toCurrency } from "@common/helper"
import { CatagoryText } from "@pages/dashboard/index/index"
import { Dropdown, LoadingScope, Field } from "@components/index"

const Layout = styled.div`
  margin-top: 84px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > div:first-of-type {
    margin-bottom: 24px;
  }
`
const Body = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  max-width: 678px;
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  > div {
    margin-bottom: 24px;
  }
`
const InsuranceCompany = styled.div`
  display: flex;
  align-items: center;
`
const CompanyLogo = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 8px;
`

const Stats = (props) => (
  <>
    {props.isLoading ? (
      <LoadingScope />
    ) : (
      <Layout>
        <Field
          name="year"
          caption="ปี"
          component={Dropdown}
          options={props.yearOptions}
          onChange={props.changeYear}
          leftWidth="30"
          rightWidth="120"
        />
        <Body>
          <Wrapper>
            <CloseTheDeal title="ยอดปิดการขายสะสมแยกตามบริษัทประกัน" data={props.insurer} />
          </Wrapper>
          <Wrapper>
            <CloseTheDeal title="ยอดปิดการขายสะสมแยกตามเพศ" data={props.gender} />
            <CloseTheDeal title="ยอดปิดการขายสะสมแยกตามช่วงอายุ" data={props.agePeriod} />
          </Wrapper>
        </Body>
      </Layout>
    )}
  </>
)

const enhancer = compose(
  withRouter(),
  withStores((stores) => ({
    fetch: stores.dashboardStore.fetchOrderStatistic,
    ordersStatistic: stores.dashboardStore.ordersStatistic,
    initialValues: {
      year: new Date().getFullYear(),
    },
  })),
  withForm({
    form: "orderStatistic",
  }),
  withHooks((props) => {
    const [insurer, setInsurer] = useState([])
    const [gender, setGender] = useState([])
    const [agePeriod, setAgePeriod] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
      const insurerData = []

      props.ordersStatistic.insuranceCompany.map((insurer) => {
        insurerData.push({
          label: (
            <InsuranceCompany>
              <CompanyLogo src={insurer.logo} />
              <CatagoryText>{insurer.name}</CatagoryText>
            </InsuranceCompany>
          ),
          salablePolicy: toCurrency(insurer.salablePolicy),
          rejectedPolicy: toCurrency(insurer.rejectedPolicy),
        })
      })
      setInsurer(insurerData)

      const genderData = []
      props.ordersStatistic.gender.map((gender) => {
        genderData.push({
          label: gender.name,
          salablePolicy: toCurrency(gender.salablePolicy),
          rejectedPolicy: toCurrency(gender.rejectedPolicy),
        })
      })
      setGender(genderData)

      const agePeriodData = []
      props.ordersStatistic.agePeriod.map((agePeriod) => {
        agePeriodData.push({
          label: agePeriod.label,
          salablePolicy: toCurrency(agePeriod.salablePolicy),
          rejectedPolicy: toCurrency(agePeriod.rejectedPolicy),
        })
      })
      setAgePeriod(agePeriodData)
    }, [props.ordersStatistic])

    const changeYear = useCallback(
      async (year) => {
        setIsLoading(true)
        await props.fetch({ year })
        setIsLoading(false)
      },
      [props.values.year]
    )

    const yearOptions = useMemo(() => {
      const currentYear = new Date().getFullYear()
      const sinceYear = 2018
      const yearOptions = []

      for (var year = sinceYear; year <= currentYear; year++) {
        yearOptions.push({
          label: year.toString(),
          value: year,
        })
      }

      return yearOptions
    }, [])

    return {
      insurer,
      gender,
      agePeriod,
      isLoading,
      yearOptions,

      changeYear,
      setIsLoading,
    }
  }),

  lifecycle({
    async componentDidMount() {
      this.props.setIsLoading(true)
      await this.props.fetch({ year: this.props.initialValues.year })
      this.props.setIsLoading(false)
    },
  })
)

export default enhancer(Stats)
