import React from "react"
import styled from "@emotion/styled"
import { compose } from "recompose"
import { Dialog, Button } from "@components"
import { withStores } from "@enhancers/index"

const CustomDialog = styled(Dialog)`
  div.k-widget.k-window.k-dialog {
    width: 570px;
    max-height: 500px;

    font-family: Sarabun, sans-serif;
    font-size: 16px;

    div.k-content.k-window-content.k-dialog-content > div {
      padding: 0 30px;
    }
  }
`

const CloseButton = styled(Button)`
  background: #ffffff 0% 0% no-repeat padding-box;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  width: 100%;
  border: white;
  min-height: 52px;
  box-shadow: unset;
  text-decoration: none;

  :hover {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-color: #0275d8;
  }

  span.k-icon.k-i-cart {
    font-size: 18px;
  }
`

const BenefitRawModal = (props) => (
  <CustomDialog
    title={"สัญญาเพิ่มเติม"}
    onClose={props.onClose}
    isShowModal={props.isShowModal}
    key={props.riderPolicyDetail.id}
  >
    <div
      dangerouslySetInnerHTML={{
        __html: props.benefitRaw,
      }}
    ></div>
    <CloseButton onClick={props.onClose}>ปิด</CloseButton>
  </CustomDialog>
)

const enhancer = compose(
  withStores((stores) => ({
    riderPolicyDetail: stores.productStore.riderPolicyDetail,
  }))
)

export default enhancer(BenefitRawModal)
