import { TabStrip } from "@progress/kendo-react-layout"
import styled from "@common/styled"

const CustomTabStrip = styled(TabStrip)`
  .k-item {
    height: 50px;
    margin-right: 3px;
    margin-bottom: 0;
    border: none;
    font-size: 16px;
    font-family: Sarabun, sans-serif;
    color: #666666;
    background: #deeaf6;
    border-radius: 4px 4px 0px 0px;

    .k-link {
      padding-left: 10px;
      padding-right: 10px;
      font-size: 16px;
      font-family: Sarabun, sans-serif;
    }
  }

  .k-tabstrip-items {
    border: none;
  }

  .k-item.k-state-active.k-tab-on-top,
  .k-item.k-state-default:hover {
    font-size: 16px;
    font-family: Sarabun, sans-serif;
    color: #ffffff;

    background: #0275d8;
    border-radius: 4px 4px 0px 0px;
  }

  .k-animation-container.k-animation-container-relative {
    width: 100%;
  }

  .k-content {
    background: #ffffff;
    border: 1px solid #eeeef2;
    border-radius: 0px 4px 4px 4px;
    padding: 15px 20px;
  }
`

export default CustomTabStrip
