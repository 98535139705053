import BaseStore from "./BaseStore"
import { tqldServer } from "@common/api"

class OrderStore extends BaseStore {
  constructor() {
    super({
      all: [],
      paging: {
        page: 1,
        perPage: 10,
        totalCount: 0,
        totalPages: 1,
      },
      filter: {},
      current: null,
      orderDocumentLists: [],
      pagingOrderDocumentLists: {
        page: 1,
        perPage: 10,
        totalCount: 0,
        totalPages: 1,
      },
      orderList: [],
      orderConfigs: {},
      orderDocumentConfigs: {},
      insuranceHolder: {},
      insurancePayer: {},
      orderProduct: {},
      riderProducts: [],
      currentOrderNo: null,
      insuranceBeneficiaries: [],
      paymentPlans: [],
      currentAddress: {},
      homeAddress: {},
      officeAddress: {},
      allDocApproved: null,
      footer: {
        completedOrdersCount: 0,
        docApprovedOrdersCount: 0,
        ordersCount: 0,
        docApprovedAt: new Date(),
      },
      salesCommissionMaster: {},
    })
  }

  updateOrder = async (params) => {
    const response = await tqldServer.tqmBackend.patch(`/orders/${params.orderId}`, params)
    this.current = response.data.data.order
  }

  previewPaymentPlans = async (orderId, params) => {
    try {
      const response = await tqldServer.tqmBackend.get(`/orders/${orderId}/payment_plans`, params)
      this.current.paymentPlans = response.data.data.paymentPlans
    } catch (e) {
      this.current.paymentPlans = []
    }
  }

  updatePayment = async (orderId, params) => {
    const response = await tqldServer.tqmBackend.patch(`/orders/${orderId}/payment_plans`, params)
    this.current = { ...this.current, ...response.data.data.order.paymentPlans }
  }

  addOrder = async (params) => {
    const response = await tqldServer.tqmBackend.post(`/orders`, params)
    return response.data.data
  }

  updateDraftProductOrder = async (orderId, params) => {
    const response = await tqldServer.tqmBackend.patch(`/orders/${orderId}/draft`, params)
    return response.data.data
  }

  addOrderPackage = async (params) => {
    const response = await tqldServer.tqmBackend.post(`/orders/package`, {
      ...params,
    })
    return response.data.data
  }

  fetchInsuranceHolder = async (orderId) => {
    const response = await tqldServer.tqmBackend.get(`/insurance_holders`, {
      orderId: orderId,
    })

    const response1 = await tqldServer.tqmBackend.get(`/insurance_beneficiaries`, {
      orderId: orderId,
    })
    this.insuranceBeneficiaries = response1.data.data.insuranceBeneficiaries
    this.insuranceHolder = response.data.data.insuranceHolder
  }

  updateInsuranceHolder = async (params) => {
    const response = await tqldServer.tqmBackend.put(`/insurance_holders`, params)
    this.insuranceHolder = response.data.data.insuranceHolder
    this.insuranceBeneficiaries = response.data.data.insuranceBeneficiaries
  }

  fetchInsurancePayer = async (orderId) => {
    const response = await tqldServer.tqmBackend.get(`/insurance_payers`, {
      orderId: orderId,
    })
    this.insurancePayer = response.data.data.insurancePayer
  }

  updateInsurancePayer = async (params) => {
    const response = await tqldServer.tqmBackend.patch(`/insurance_payers`, params)
    this.insurancePayer = response.data.data.insurancePayer
  }

  updateInsuranceHolderAddress = async (orderId, params) => {
    const response = await tqldServer.tqmBackend.patch(`orders/${orderId}`, params)

    this.current = response.data.data.order
    this.currentAddress = response.data.data.order.currentAddress
    this.homeAddress = response.data.data.order.homeAddress
    this.officeAddress = response.data.data.order.officeAddress
  }

  fetchOrderConfigs = async () => {
    const response = await tqldServer.tqmBackend.get(`/orders/configs`)
    this.orderConfigs = response.data.data.configs
  }

  addCurrentOrderNo = (orderNo) => {
    this.currentOrderNo = orderNo
  }

  fetchOrderList = async (params) => {
    const response = await tqldServer.tqmBackend.get(`/orders`, params)
    this.orderList = response.data.data.orders
  }

  fetchOrder = async (orderId, params) => {
    const response = await tqldServer.tqmBackend.get(`/orders/${orderId}`, params)

    this.current = response.data.data.order
    this.currentAddress = response.data.data.order.currentAddress
    this.homeAddress = response.data.data.order.homeAddress
    this.officeAddress = response.data.data.order.officeAddress

    return response.data.data
  }

  confirmOrder = async (orderId, params = {}) => {
    await tqldServer.tqmBackend.put(`/orders/${orderId}/confirm`, params)
  }

  deleteOrder = async (orderId) => {
    const response = await tqldServer.tqmBackend.delete(`/orders/${orderId}`)
    const filteredArray = this.orderList.filter((order) => {
      return order.orderNo !== response.data.data.orderNo
    })
    this.orderList = filteredArray
  }

  affiliateRejectOrder = async (orderId) => {
    await tqldServer.tqmBackend.put(`/orders/${orderId}/affiliate_reject`)
  }

  rejectOrder = async (orderId) => {
    await tqldServer.tqmBackend.put(`/orders/${orderId}/salesman_reject`)
  }

  fetch = async (options = {}) => {
    const response = await tqldServer.tqmBackend.get(`/orders`, options)
    this.filter = options
    this.all = response.data.data.orders
    this.paging = response.data.data.paging

    this.footer.completedOrdersCount = response.data.data.completedOrdersCount
    this.footer.ordersCount = response.data.data.ordersCount
    this.footer.docApprovedOrdersCount = response.data.data.docApprovedOrdersCount
  }

  updateOrderPackage = async (orderId, params = {}) => {
    const response = await tqldServer.tqmBackend.patch(`orders/${orderId}/package_draft`, params)
    return response.data.data
  }

  fetchOrderProductById = async (orderId) => {
    const response = await tqldServer.tqmBackend.get(`orders/${orderId}`)
    this.orderProduct = response.data.data.order
    this.riderProducts = response.data.data.order.riderProducts

    return response.data.data.order
  }

  submitDocumentByOrderId = async (orderId) => {
    const response = await tqldServer.tqmBackend.put(`orders/${orderId}/submit_document`)

    this.current = response.data.data.order
    return response.data.data
  }

  submitOrder = async (orderId, params) => {
    const response = await tqldServer.tqmBackend.put(`orders/${orderId}/submit_order`, params)

    this.current = response.data.data.order

    return response.data.data
  }

  submitOrderAffiliate = async (orderId, params) => {
    const response = await tqldServer.tqmBackend.put(
      `orders/${orderId}/affiliate_submit_order`,
      params
    )
    return response.data.data
  }

  updateOrderDocumentVerifyFailByOrderId = async (orderId, params) => {
    const response = await tqldServer.tqmBackend.put(`orders/${orderId}/doc_verify_fail`, {
      ...params,
    })
    this.current = response.data.data.order
  }

  fetchOrderDocumentsByOrderId = async (orderId, options = {}) => {
    const response = await tqldServer.tqmBackend.get(`/order_documents`, {
      orderId: orderId,
      page: options.page,
      filter: options.filter,
    })

    let filterOrderDocument = response.data.data.documents
    if (options && options.filter) {
      filterOrderDocument = filterOrderDocument.filter((item) => {
        return item.state === options.filter
      })
    }
    this.orderDocumentLists = filterOrderDocument
    this.pagingOrderDocumentLists = response.data.data.paging
    this.allDocApproved = response.data.data.allDocApproved

    this.footer.latestDocSubmittedAt = response.data.data.latestDocSubmittedAt || new Date()

    // this.orderDocumentLists = response.data.data.documents
    // this.pagingOrderDocumentLists = response.data.data.paging
  }

  downloadAllDocumentByOrderId = async (orderId) => {
    await tqldServer.tqmBackend.download(`/order_documents/download`, {
      orderId: orderId,
    })
  }

  sendOrderDocumentByOrderId = async (orderId, id) => {
    const response = await tqldServer.tqmBackend.put(`/order_documents/send_documents`, {
      orderId: orderId,
    })
    this.orderDocumentLists = response.data.data.documents
    this.pagingOrderDocumentLists = response.data.data.paging
  }

  createOrderDocumentByOrderId = async (orderId, params) => {
    const response = await tqldServer.tqmBackend.post(`/order_documents`, {
      orderId: orderId,
      ...params,
    })
    this.orderDocumentLists = response.data.data.documents
    this.pagingOrderDocumentLists = response.data.data.paging
  }

  updateOrderDocumentByDocumentId = async (orderId, id, params) => {
    const response = await tqldServer.tqmBackend.put(`/order_documents/${id}`, {
      orderId: orderId,
      ...params,
    })
    this.orderDocumentLists = response.data.data.documents
    this.pagingOrderDocumentLists = response.data.data.paging
  }

  destroyOrderDocumentByDocumentId = async (orderId, id) => {
    const response = await tqldServer.tqmBackend.delete(`/order_documents/${id}`, {
      orderId: orderId,
    })
    this.orderDocumentLists = response.data.data.documents
    this.pagingOrderDocumentLists = response.data.data.paging
  }

  downloadOrderDocumentByDocumentId = async (orderId, id) => {
    await tqldServer.tqmBackend.download(`/order_documents/${id}/download`, {
      orderId: orderId,
    })
  }

  viewDocumentInNewTab = async (orderId, id) => {
    await tqldServer.tqmBackend.download(
      `/order_documents/${id}/download`,
      { orderId },
      { viewMode: true }
    )
  }

  approveOrderDocumentByDocumentId = async (orderId, id, params) => {
    const response = await tqldServer.tqmBackend.put(`/order_documents/${id}/approve`, {
      orderId: orderId,
      ...params,
    })
    this.orderDocumentLists = response.data.data.documents
    this.pagingOrderDocumentLists = response.data.data.paging
  }

  rejectOrderDocumentByDocumentId = async (orderId, id, params) => {
    const response = await tqldServer.tqmBackend.put(`/order_documents/${id}/rejected`, {
      orderId: orderId,
      ...params,
    })
    this.orderDocumentLists = response.data.data.documents
    this.pagingOrderDocumentLists = response.data.data.paging
  }

  remarkOrderDocumentByDocumentId = async (orderId, id, params) => {
    const response = await tqldServer.tqmBackend.put(`/order_documents/${id}/remark`, {
      orderId,
      ...params,
    })
    this.orderDocumentLists = response.data.data.documents
    this.pagingOrderDocumentLists = response.data.data.paging
  }

  fetchConfigsDocumentOrder = async (params) => {
    const response = await tqldServer.tqmBackend.get(`/order_documents/configs`, params)
    this.orderDocumentConfigs = response.data.data.configs
  }

  updateBeneficiaries = async (params) => {
    await tqldServer.tqmBackend.put(`/insurance_beneficiaries`, {
      orderId: params.orderNo,
      insuranceBeneficiaries: this.insuranceBeneficiaries,
    })
  }

  deleteBeneficiaries = async (params) => {
    this.insuranceBeneficiaries.splice(params, 1)
  }

  createBeneficiaries = async (params) => {
    this.insuranceBeneficiaries.push({
      nameInfo: params.addbenficiaryName,
      percent: params.addbenficiaryPercent,
      relationship: params.addbenficiaryRelation,
    })
  }

  cancelBeneficiaries = async (params) => {
    const response1 = await tqldServer.tqmBackend.get(`/insurance_beneficiaries`, {
      orderId: params,
    })

    this.insuranceBeneficiaries = response1.data.data.insuranceBeneficiaries
  }

  documentApprove = async (orderId) => {
    await tqldServer.tqmBackend.put(`/orders/${orderId}/doc_approve`)
  }

  submitToTqm = async (orderId) => {
    const response = await tqldServer.tqmBackend.put(`/orders/${orderId}/submit_to_tqm`)
    this.current = response.data.data.order
  }

  submitDocToTqm = async (orderId) => {
    await tqldServer.tqmBackend.put(`/orders/${orderId}/submit_doc_to_tqm`)
  }

  updatePremium = async (orderId, params) => {
    await tqldServer.tqmBackend.patch(`/orders/${orderId}/premium`, params)
  }

  clearStore = () => {
    this.current = null
  }

  fetchCommissionOrderById = async (orderId, params) => {
    const response = await tqldServer.tqmBackend.get(
      `orders/${orderId}/sales_commission_master`,
      params
    )
    this.salesCommissionMaster = response.data.data.salesCommissionMaster

    return response.data.data.salesCommissionMaster
  }

  clearSalesCommission = () => {
    this.salesCommissionMaster = {}
  }

  importOrder = async (params) => {
    return await tqldServer.tqmBackend.post(`orders/import_order`, params)
  }
}

export default new OrderStore()
