import BaseStore from "./BaseStore"
class PolicyStore extends BaseStore {
  constructor() {
    super({
      selectedProduct: [],
    })
  }

  addSelectedProduct = (product) => {
    this.selectedProduct.push(product)
  }

  deleteSelectedProduct = (id) => {
    const filterArray = this.selectedProduct.filter((product) => {
      return product.productBuyingCode !== id
    })

    this.selectedProduct = filterArray
  }
}

export default new PolicyStore()
