import { createStore, combineReducers } from "redux"
import { reducer as formReducer } from "redux-form"
import { reducer as modelReducer } from "./ModelStore"
import { isClient } from "@common/helper"

export function createReducer(asyncReducers) {
  return combineReducers({
    form: formReducer,
    model: modelReducer,
    ...asyncReducers,
  })
}

const store = createStore(
  createReducer(),
  isClient
    ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    : undefined
)

export default store
