import { withRouter as withReactRouter } from "react-router-dom"
import { compose, withProps } from "recompose"
import qs from "qs"
import humps from "humps"
import { get, set, each } from "lodash"
import moment from "moment"

const convertTypes = {
  integer: (val) => (val ? parseInt(val) : null),
  float: (val) => (val ? parseFloat(val) : null),
  date: (val) => (val ? moment(val, "DD/MM/YYYY").toDate() : null),
  moment: (val) => (val ? moment(val, "DD/MM/YYYY") : null),
  boolean: (val) => {
    switch (val) {
      case "true":
        return true
      case "false":
        return false
      default:
        return null
    }
  },
}

const withRouter = (options = {}) =>
  compose(
    withReactRouter,
    withProps((props) => {
      const query = humps.camelizeKeys(qs.parse(props.location.search, { ignoreQueryPrefix: true }))

      if (options.queryTypes) {
        each(options.queryTypes, (type, key) => {
          const value = get(query, key)
          if (value !== undefined) {
            const convert = convertTypes[type]
            if (convert) {
              set(query, key, convert(value))
            }
          }
        })
      }

      return { location: { ...props.location, query } }
    })
  )

export default withRouter
