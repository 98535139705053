import React from "react"
import { Route, Redirect } from "react-router-dom"
import * as path from "@common/path"
import { authorize as authorizeMethod } from "@enhancers/withAuthorized"

const PrivateRoute = ({ children, component: Component, authorize, ...rest }) => {
  const Children = (props) => {
    const appStore = require("../stores/mobx/appStore").default
    const authorized = authorizeMethod({
      serverRoles: appStore.roles,
      currentRole: appStore.currentRole,
      authorize,
    })

    return authorized ? (
      Component ? (
        <Component {...props} />
      ) : (
        children
      )
    ) : (
      <Redirect to={path.rootPath()} />
    )
  }

  return <Route {...rest} render={Children} />
}

export default PrivateRoute
