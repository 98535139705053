import React from "react"
import { compose, withHandlers, withProps } from "recompose"
import { CurrencyInput } from "./index"
import styled from "@emotion/styled"
import { withCaption, withField, withReadOnly } from "@enhancers/index"
import { isNaN } from "lodash"
import numeral from "numeral"

const ReadOnly = styled.div`
  min-height: 33.5px;
  display: flex;
  align-items: center;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
`
const ValueContainer = styled.div`
  display: flex;
`
const Value = styled.span`
  color: #5cb85c;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
`
const Unit = styled.span`
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  margin-left: 10px;
`

const Layout = styled.div`
  display: flex;
  align-items: flex-end;

  input {
    text-align: right;
    padding-left: 8px;
    padding-right: 8px;
  }
`

const digitStyle = { width: 114 }
const precisionStyle = { width: 39 }

const Dot = styled.div`
  display: flex;
  align-items: baseline;
  height: 38px;
  padding: 10px 6px;
  color: #627b8b;
`

const isNumber = (value) => value !== undefined && value !== null && value !== ""

const CurrencyInputWithPecision = (props) => (
  <Layout>
    <CurrencyInput
      value={props.digit}
      onChange={props.onDigitChange}
      onBlur={props.onBlur}
      style={digitStyle}
    />
    <Dot>.</Dot>
    <CurrencyInput
      value={props.precision}
      onChange={props.onPrecisionChange}
      onBlur={props.onBlur}
      style={precisionStyle}
    />
  </Layout>
)

const enhancer = compose(
  withField(),
  withCaption(),
  withReadOnly((props) => {
    let value = parseFloat(props.value)
    if (!isNaN(value)) {
      value = numeral(value).format(`0,0.${`0`.repeat(props.precision)}`)
    } else {
      value = null
    }

    return (
      <ReadOnly style={props.style}>
        {props.unit ? (
          <ValueContainer>
            <Value>{value || "-"}</Value>
            <Unit>{props.unit}</Unit>
          </ValueContainer>
        ) : (
          value
        )}
      </ReadOnly>
    )
  }),
  withProps((props) => {
    const valueString = isNumber(props.value) ? `${props.value}` : ""
    const [digit, precision] = valueString.split(".")
    return {
      digit,
      precision,
    }
  }),
  withHandlers({
    onDigitChange: (props) => (digit) => {
      const value = `${isNumber(digit) ? digit : ""}.${
        isNumber(props.precision) ? props.precision : ""
      }`
      props.onChange(value)
    },
    onPrecisionChange: (props) => (precision) => {
      const value = `${isNumber(props.digit) ? props.digit : ""}.${
        isNumber(precision) ? precision : ""
      }`
      props.onChange(value)
    },
    onBlur: (props) => () => {
      const value = isNumber(props.value) ? parseFloat(props.value) : null
      props.onBlur(value)
    },
  })
)

export default enhancer(CurrencyInputWithPecision)
