import React from "react"
import styled from "@emotion/styled"
import { compose, withProps } from "recompose"
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout"

const Layout = styled.div`
  flex: 1;
  width: 100%;
`
const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
`
const Title = styled.div`
  width: 255px;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
`
const Detail = styled.div`
  display: flex;
  justify-content: flex-end;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
`

const Wp = (props) => (
  <Layout>
    {props.protectionDetail !== undefined && (
      <PanelBar>
        <PanelBarItem expanded={true} title="ผลประโยชน์">
          {Object.keys(props.protectionDetail).map((key) => (
            <ContentContainer>
              <Title>{props.protectionDetail[key].label}</Title>
              {props.protectionDetail[key].value ? (
                typeof props.protectionDetail[key].value === "string" ? (
                  <Detail>{props.protectionDetail[key].value}</Detail>
                ) : (
                  <Detail>{props.protectionDetail[key].value.value}</Detail>
                )
              ) : null}
            </ContentContainer>
          ))}
        </PanelBarItem>
      </PanelBar>
    )}
  </Layout>
)

const enhancer = compose(
  withProps((props) => {
    const protectionDetail = props.riderPolicyDetail.protectionDetail.wp

    return {
      protectionDetail,
    }
  })
)

export default enhancer(Wp)
