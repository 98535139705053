import React from "react"
import styled from "@styled"
import { Field, Dropdown, Input, DatePicker } from "@components"
import { compose } from "recompose"
import { withStores } from "@enhancers/index"

const Row = styled.div`
  display: flex;
  margin-top: 12px;

  &:nth-of-type(1) {
    margin-top: 0;
  }

  > div {
    margin-left: 12px;

    &:nth-of-type(1) {
      margin-left: 0;
    }
  }
`

const FilterPanel = (props) => (
  <>
    <Row>
      <Field
        component={Dropdown}
        name="insuranceCompanyId.eq"
        caption="บริษัทประกัน"
        captionStyle={{ fontWeight: "normal" }}
        options={props.insuranceCompanyOptions}
        leftWidth="100"
        rightWidth="210"
        disabled={props.disabled}
        placeholder="กรุณาเลือก"
      />
      <Field
        component={Input}
        name="name"
        caption="ชื่อลูกค้า"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="100"
        rightWidth="210"
        placeholder="กรุณาระบุ"
        disabled={props.disabled}
      />
    </Row>
    <Row>
      <Field
        component={Dropdown}
        name="teamId"
        caption="ชื่อทีม"
        captionStyle={{ fontWeight: "normal" }}
        options={props.teamOptions}
        leftWidth="100"
        rightWidth="210"
        placeholder="กรุณาเลือก"
        disabled={props.disabled}
      />
      <Field
        component={Input}
        name="salesmanName"
        caption="ชื่อพนักงาน"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="100"
        rightWidth="210"
        disabled={props.disabled}
        placeholder="กรุณาระบุ"
      />
    </Row>
    {/*<Authorize notRoles={["member.team_leader", "member.salesman"]}>
      <Row>
        <Field
          component={Dropdown}
          name="branchId.eq"
          caption="สาขา"
          captionStyle={{ fontWeight: "normal" }}
          options={props.branchOptions}
          leftWidth="60"
          rightWidth="138"
          placeholder="กรุณาเลือก"
          disabled={props.disabled}
        />
      </Row>
    </Authorize>*/}
    {/*<Row>
      <Field
        component={DateTimePicker}
        name="dateTimeCreatedAt.min"
        caption="วันเวลาที่แจ้ง"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="100"
        rightWidth="210"
        disabled={props.disabled}
        placeholder="กรุณาเลือก"
      />
      <Field
        component={DateTimePicker}
        name="dateTimeCreatedAt.max"
        caption="-"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="18"
        rightWidth="210"
        disabled={props.disabled}
        placeholder="กรุณาเลือก"
      />
    </Row>*/}
    <Row>
      <Field
        component={Input}
        name="orderNo.eq"
        caption="รหัสคำสั่งซื้อ"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="100"
        rightWidth="210"
        placeholder="กรุณาระบุ"
        disabled={props.disabled}
      />
      <Field
        component={Dropdown}
        name="state.eq"
        caption="สถานะ"
        captionStyle={{ fontWeight: "normal" }}
        options={props.stateOptions}
        leftWidth="100"
        rightWidth="210"
        placeholder="กรุณาเลือก"
        disabled={props.disabled}
        // hidden={props.currentRole === "member.biz_dev"}
      />
    </Row>
    <Row>
      <Field
        component={Input}
        name="policyNumber"
        caption="เลขกรมธรรม์"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="100"
        rightWidth="210"
        placeholder="กรุณาระบุ"
        disabled={props.disabled}
      />
      <Field
        component={Input}
        name="affiliateName"
        caption="ชื่อผู้แนะนำ"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="100"
        rightWidth="210"
        placeholder="กรุณาระบุ"
        disabled={props.disabled}
      />
    </Row>
    <Row>
      <Field
        component={DatePicker}
        name="submittedAt.min"
        caption="วันที่แจ้ง"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="100"
        rightWidth="138"
        disabled={props.disabled}
        placeholder="กรุณาเลือก"
      />
      <Field
        component={DatePicker}
        name="submittedAt.max"
        caption="ถึงวันที่"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="60"
        rightWidth="138"
        disabled={props.disabled}
        placeholder="กรุณาเลือก"
      />
    </Row>
  </>
)

const enhancer = compose(
  withStores((stores) => ({
    fetchOrderConfigs: stores.orderStore.fetchOrderConfigs,
    orderConfigs: stores.orderStore.orderConfigs,
    insuranceCompanyOptions: stores.orderStore.$(
      "orderConfigs.filters.insuranceCompany.options",
      []
    ),
    stateOptions: stores.orderStore.$("orderConfigs.filters.state.options", []),
    affiliateOptions: stores.orderStore.$("orderConfigs.filters.affiliateId", []),
    teamOptions: stores.orderStore.$("orderConfigs.filters.team.options", []),
    // branchOptions: stores.orderStore.$("orderConfigs.filters.branch.options", []),
  }))
)

export default enhancer(FilterPanel)
