import styled from "@styled"
import { IFrame } from "@components/index"
import { compose, lifecycle, defaultProps } from "recompose"
import { withStores } from "@enhancers/index"
import env from "@env"

const OldSiteIFrame = styled(IFrame.OldSite)`
  width: 0;
  height: 0;
  border: 0;
  border: none;
  display: none;
`

const enhancer = compose(
  defaultProps({ id: "OldSiteAutoAuthIFrame", src: "login" }),
  withStores((stores) => ({ token: stores.appStore.token })),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (this.props.token !== prevProps.token && this.props.token) {
        var $iframe = document.getElementById(this.props.id).contentWindow
        $iframe.postMessage({ type: "auth", token: this.props.token }, env.oldSiteRoot)
      }
    },
  })
)

export default enhancer(OldSiteIFrame)
