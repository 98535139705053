import React, { useCallback, useEffect, useMemo, useState } from "react"
import styled from "@emotion/styled"
import { compose, withState, withHandlers, withProps } from "recompose"
import { withForm, withStores, withRouter, withHooks } from "@enhancers"
import { Button, Field, TabStripTab, BreadCrumb, CurrencyInput, Icon } from "@components"
import { FieldArray, FormSection } from "redux-form"

import * as path from "@common/path"
import { MenuTab } from "../../leads/show/CustomerInfo"

import InputQuarterlyDate from "./InputQuarterlyDate"
import CommissionRatesTable from "./CommissionRatesTable"
import MonthlyBonusTable from "./MonthlyBonusTable"
import QuarterlyBonusTable from "./QuarterlyBonusTable"
import YearlyBonusTable from "./YearlyBonusTable"
import { get, size, isEmpty } from "lodash"
import ExperienceBonusRatesTable from "./ExperienceBonusRatesTable"
import PersistentBonus from "./PersistentBonus"
import { toJS } from "mobx"

export const Layout = styled.div`
  width: 100%;
  justify-content: center;
`
const BreadCrumbLayout = styled.div`
  width: 100%;
`
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`
const LeftPanel = styled.div`
  font-size: 30px;
  font-weight: bold;
  font-family: ThaiSans Neue;
  display: flex;
  align-items: center;
  color: #666666;
`
const RightPanel = styled.div``
const ButtonGroup = styled.div`
  display: flex;
  > * {
    margin-left: 10px;
  }
`
const DetailContainer = styled.div`
  opacity: 1;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`
const SubmitButton = styled(Button)`
  padding: 15px 20px;
  width: 160px;
  height: 56px;
`
const CancelButton = styled(Button)`
  width: 160px;
  height: 56px;
`
const AddButton = styled(Button)`
  margin-top: 35px;
  min-width: 165px;
  width: initial;
  height: 56px;
`
const CustomMenuTab = styled(MenuTab)`
  width: 716px;
  min-height: 560px;
`
const TopicContainer = styled.div`
  width: 100%;
  height: 40px;
  background: #294558;
  opacity: 1;
`
const TextTopic = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  font-size: 16px;
  padding-left: 16px;
  color: #ffffff;
  font-family: Sarabun, sans-serif;
  font-weight: bold;
`
const CustomFormSection = styled(FormSection)`
  width: 100% !important;
`
const CustomField = styled.div`
  margin-left: 15px;
  margin-top: 10px;
  margin-bottom: 10px;

  .k-widget .k-dropdown-wrap .k-clear-value {
    display: none;
  }
`
const Text = styled.p`
  display: flex;
  align-self: end;
  cursor: pointer;
  color: #0275d8;
  visibility: ${(props) => (props.visibility ? "hidden" : "unset")};
`

const BonusCalculationShowPage = (props) => {
  return (
    <Layout>
      <BreadCrumbLayout>
        <BreadCrumb links={props.links} />
      </BreadCrumbLayout>

      <Header>
        <LeftPanel>ข้อมูลการคำนวณโบนัส</LeftPanel>
        <RightPanel>
          {props.isEdit ? (
            <ButtonGroup>
              <CancelButton
                icon="cancel"
                onClick={props.onCancel}
                children="ยกเลิก"
                styletype="delete"
                hidden={props.isHiddenEditButton}
              />
              <SubmitButton
                icon="floppy"
                onClick={props.onSave}
                children="บันทึก"
                styletype="agree"
                hidden={props.isHiddenEditButton}
              />
            </ButtonGroup>
          ) : (
            <ButtonGroup>
              <SubmitButton
                icon="edit"
                onClick={props.onEdit}
                children="แก้ไข"
                hidden={props.isHiddenEditButton}
              />
            </ButtonGroup>
          )}
        </RightPanel>
      </Header>

      <CustomMenuTab
        selected={props.tabSelected}
        onSelect={props.onTabChange}
        changeDate={props.changeDate}
      >
        <TabStripTab title="ค่าความผันแปร">
          <DetailContainer>
            {(props.values.companyCommissionRates || []).length === 0 ? (
              <AddButton
                icon="plus"
                onClick={props.newItem("companyCommissionRates")}
                children="เพิ่มรายการ"
              />
            ) : (
              <CommissionRatesTable
                data={props.values.companyCommissionRates}
                readOnly={props.isEdit}
                change={props.change}
              />
            )}
          </DetailContainer>
        </TabStripTab>

        <TabStripTab title="ตารางโบนัสรายเดือน">
          <DetailContainer>
            {(props.values.companyMonthlyBonusRates || []).length === 0 &&
            (props.values.monthlyExperienceBonusRates || []).length === 0 ? (
              <AddButton
                icon="plus"
                onClick={props.newItem("companyMonthlyBonusRates")}
                children="เพิ่มรายการ"
              />
            ) : (
              [
                (props.values.companyMonthlyBonusRates || []).length === 0 ? (
                  <AddButton
                    icon="plus"
                    onClick={props.newItem("companyMonthlyBonusRates")}
                    children="เพิ่มเบี้ยประกันปีแรกที่ปรับปรุงแล้ว"
                  />
                ) : (
                  <MonthlyBonusTable
                    data={props.values.companyMonthlyBonusRates}
                    readOnly={props.isEdit}
                    change={props.change}
                  />
                ),
                (props.values.monthlyExperienceBonusRates || []).length === 0 ? (
                  <AddButton
                    icon="plus"
                    onClick={props.newItem("monthlyExperienceBonusRates")}
                    children="เพิ่มอายุงาน"
                  />
                ) : (
                  <ExperienceBonusRatesTable
                    name="monthlyExperienceBonusRates"
                    data={props.values.monthlyExperienceBonusRates}
                    readOnly={props.isEdit}
                    change={props.change}
                  />
                ),
              ]
            )}
          </DetailContainer>
        </TabStripTab>

        <TabStripTab title="ตารางรายไตรมาส">
          <DetailContainer>
            {(props.values.companyQuarterlyBonusRates || []).length === 0 &&
            (props.values.quarterlyExperienceBonusRates || []).length === 0 &&
            size(props.values.quarterlyCalculateDetail || {}) === 0 ? (
              <AddButton
                icon="plus"
                onClick={props.newItem("companyQuarterlyBonusRates")}
                children="เพิ่มรายการ"
              />
            ) : (
              [
                <>
                  <Text onClick={props.onClearForm} visibility={!props.isEdit}>
                    ล้างข้อมูลไตรมาส
                  </Text>
                  {["firstQuarter", "secondQuarter", "thirdQuarter", "fourthQuarter"].map(
                    (value, index) => (
                      <CustomFormSection name="quarterlyCalculateDetail">
                        <TopicContainer>
                          <TextTopic>{`ไตรมาสที่ ${index + 1}`}</TextTopic>
                        </TopicContainer>
                        <CustomField>
                          <Field
                            name={`${value}.beginCal`}
                            component={InputQuarterlyDate}
                            readOnly={!props.isEdit}
                            leftWidth="190"
                            caption="วันคำนวณเริ่มต้น"
                          />
                          <Field
                            name={`${value}.endCal`}
                            component={InputQuarterlyDate}
                            readOnly={!props.isEdit}
                            leftWidth="190"
                            caption="วันคำนวณสุดท้าย"
                          />
                          <Field
                            name={`${value}.payDate`}
                            component={InputQuarterlyDate}
                            readOnly={!props.isEdit}
                            leftWidth="190"
                            caption="วันที่จ่าย"
                          />
                        </CustomField>
                      </CustomFormSection>
                    )
                  )}
                </>,
                (props.values.companyQuarterlyBonusRates || []).length === 0 ? (
                  <AddButton
                    icon="plus"
                    onClick={props.newItem("companyQuarterlyBonusRates")}
                    children="เพิ่มเบี้ยประกันปีแรกที่ปรับปรุงแล้ว"
                  />
                ) : (
                  <QuarterlyBonusTable
                    data={props.values.companyQuarterlyBonusRates}
                    readOnly={props.isEdit}
                    change={props.change}
                  />
                ),
                (props.values.quarterlyExperienceBonusRates || []).length === 0 ? (
                  <AddButton
                    icon="plus"
                    onClick={props.newItem("quarterlyExperienceBonusRates")}
                    children="เพิ่มอายุงาน"
                  />
                ) : (
                  <ExperienceBonusRatesTable
                    name="quarterlyExperienceBonusRates"
                    data={props.values.quarterlyExperienceBonusRates}
                    readOnly={props.isEdit}
                    change={props.change}
                  />
                ),
              ]
            )}
          </DetailContainer>
        </TabStripTab>

        <TabStripTab title="ตารางโบนัสสิ้นปี">
          <DetailContainer>
            {(props.values.companyYearlyBonusRates || []).length === 0 &&
            (props.values.yearlyExperienceBonusRates || []).length === 0 ? (
              <AddButton
                icon="plus"
                onClick={props.newItem("companyYearlyBonusRates")}
                children="เพิ่มรายการ"
              />
            ) : (
              [
                (props.values.companyYearlyBonusRates || []).length === 0 ? (
                  <AddButton
                    icon="plus"
                    onClick={props.newItem("companyYearlyBonusRates")}
                    children="เพิ่มเบี้ยประกันปีแรกที่ปรับปรุงแล้ว"
                  />
                ) : (
                  <YearlyBonusTable
                    data={props.values.companyYearlyBonusRates}
                    readOnly={props.isEdit}
                    change={props.change}
                  />
                ),
                (props.values.yearlyExperienceBonusRates || []).length === 0 ? (
                  <AddButton
                    icon="plus"
                    onClick={props.newItem("yearlyExperienceBonusRates")}
                    children="เพิ่มอายุงาน"
                  />
                ) : (
                  <ExperienceBonusRatesTable
                    name="yearlyExperienceBonusRates"
                    data={props.values.yearlyExperienceBonusRates}
                    readOnly={props.isEdit}
                    change={props.change}
                  />
                ),
              ]
            )}
          </DetailContainer>
        </TabStripTab>

        <TabStripTab title="ตารางโบนัสความยั่งยืน">
          <DetailContainer>
            {(props.values.companyPersistentBonuses || []).length === 0 ? (
              <AddButton
                icon="plus"
                onClick={props.newItem("companyPersistentBonuses")}
                children="เพิ่มรายการ"
              />
            ) : (
              <>
                <FieldArray
                  component={PersistentBonus}
                  data={props.values.companyPersistentBonuses}
                  name="companyPersistentBonuses"
                  readOnly={props.isEdit}
                  change={props.change}
                />
              </>
            )}
          </DetailContainer>
        </TabStripTab>
      </CustomMenuTab>
    </Layout>
  )
}

const validate = (values) => {
  const errors = {
    companyPersistentBonuses: [],
    quarterlyCalculateDetail: {
      firstQuarter: {},
      secondQuarter: {},
      thirdQuarter: {},
      fourthQuarter: {},
    },
  }
  const quarterlyField = ["firstQuarter", "secondQuarter", "thirdQuarter", "fourthQuarter"]
  const requiredQuarterlyField = ["beginCal", "endCal", "payDate"]

  const companyPersistentBonuses = get(values, "companyPersistentBonuses") || []
  // const persistentPayDays = get(values, "persistentPayDays") || []
  const quarterlyCalculateDetail = get(values, "quarterlyCalculateDetail") || {}
  // const companyPersistentBonusRates = get(values, "companyPersistentBonusRates") || []

  // persistentPayDays.forEach((val, index) => {
  //   if (isEmpty(val.day) || isEmpty(val.month))
  //     errors["companyPersistentBonuses"]["persistentPayDays"][index] = "กรุณาเลือกวันและเดือน"
  // })

  quarterlyField.forEach((q) => {
    requiredQuarterlyField.forEach((r) => {
      if (
        quarterlyCalculateDetail &&
        (!isEmpty(quarterlyCalculateDetail["firstQuarter"]) ||
          !isEmpty(quarterlyCalculateDetail["secondQuarter"]) ||
          !isEmpty(quarterlyCalculateDetail["secondQuarter"]) ||
          !isEmpty(quarterlyCalculateDetail["fourthQuarter"]))
      ) {
        if (!isEmpty(quarterlyCalculateDetail[q])) {
          if (!isEmpty(quarterlyCalculateDetail[q][r])) {
            if (
              isEmpty(quarterlyCalculateDetail[q][r]["day"]) ||
              isEmpty(quarterlyCalculateDetail[q][r]["month"])
            )
              errors["quarterlyCalculateDetail"][q][r] = "กรุณาเลือก"
          } else errors["quarterlyCalculateDetail"][q][r] = "กรุณาเลือก"
        } else errors["quarterlyCalculateDetail"][q][r] = "กรุณาเลือก"
      }
    })
  })

  // companyPersistentBonusRates.forEach((val, index) => {
  //   errors["companyPersistentBonusRates"][index] = {}
  //   const requireField = ["limraPercentMin", "limraPercentMax", "bonusPercent"]
  //   requireField.forEach((r) => {
  //     if (isEmpty(val[r])) {
  //       errors["companyPersistentBonusRates"][index][r] = "กรุณากรอก"
  //     }
  //   })
  // })

  // console.log("errors", errors)

  return errors
}

const enhancer = compose(
  withRouter(),
  withHandlers({
    makeInitialData: () => (insuranceCompany) => {
      const companyCommissionRates = insuranceCompany.companyCommissionRates.map((item) => {
        return {
          beginDate: item.beginDate,
          commissionPercent: item.commissionPercent,
        }
      })

      const companyMonthlyBonusRates = insuranceCompany.companyMonthlyBonusRates.map((item) => {
        return {
          afypMin: item.afypMin,
          afypMax: item.afypMax,
          bonusPercent: item.bonusPercent,
        }
      })

      const companyQuarterlyBonusRates = insuranceCompany.companyQuarterlyBonusRates.map((item) => {
        return {
          afypMin: item.afypMin,
          afypMax: item.afypMax,
          bonusPercent: item.bonusPercent,
        }
      })

      const quarterlyCalculateDetail = toJS(insuranceCompany.quarterlyCalculateDetail) || {}

      const companyYearlyBonusRates = insuranceCompany.companyYearlyBonusRates.map((item) => {
        return {
          afypMin: item.afypMin,
          afypMax: item.afypMax,
          bonusPercent: item.bonusPercent,
        }
      })
      const companyPersistentBonuses = insuranceCompany.companyPersistentBonuses.map((item) => {
        const data = toJS(item)
        return {
          companyPersistentBonusRatesAttributes: data.companyPersistentBonusRatesAttributes,
          id: data.id,
          limraMonthCount: data.limraMonthCount,
          persistentPayDays: data.persistentPayDays,
        }
      })

      const monthlyExperienceBonusRates = insuranceCompany.monthlyExperienceBonusRates.map(
        (item) => {
          return {
            bonusPercent: item.bonusPercent,
            experienceMin: item.experienceMin,
            experienceMax: item.experienceMax,
          }
        }
      )

      const quarterlyExperienceBonusRates = insuranceCompany.quarterlyExperienceBonusRates.map(
        (item) => {
          return {
            bonusPercent: item.bonusPercent,
            experienceMin: item.experienceMin,
            experienceMax: item.experienceMax,
          }
        }
      )

      const yearlyExperienceBonusRates = insuranceCompany.yearlyExperienceBonusRates.map((item) => {
        return {
          bonusPercent: item.bonusPercent,
          experienceMin: item.experienceMin,
          experienceMax: item.experienceMax,
        }
      })

      return {
        companyCommissionRates,
        companyMonthlyBonusRates,
        companyQuarterlyBonusRates,
        quarterlyCalculateDetail,
        companyYearlyBonusRates,
        companyPersistentBonuses,
        monthlyExperienceBonusRates,
        quarterlyExperienceBonusRates,
        yearlyExperienceBonusRates,
      }
    },
  }),
  withStores((stores, props) => {
    const insuranceCompany = props.makeInitialData(stores.insurerStore.insuranceCompany)

    const initialValues = {
      ...insuranceCompany,
    }

    return {
      fetchBonusCommission: stores.insurerStore.fetchBonusCommission,
      updateBonusCommission: stores.insurerStore.updateBonusCommission,
      commissionId: props.match.params.commissionId,
      insuranceCompany: stores.insurerStore.insuranceCompany,
      initialValues: initialValues,
    }
  }),
  withState("isEdit", "setIsEdit", false),
  withState("tabSelected", "setTabSelected", 0),
  withState("skip", "setSkip", 0),
  withState("take", "setTake", 10),
  withProps((props) => {
    let links = []
    links = [
      { label: "คอมมิชชัน", path: path.bonusCalculationPath() },
      { label: `คำนวณโบนัส (${props.insuranceCompany.shortName})` },
    ]

    return {
      links,
    }
  }),
  withForm({ form: "editBonusCalculation", validate, enableReinitialize: true }),
  withHandlers({
    onEdit: (props) => () => {
      props.setIsEdit(true)
    },
    onCancel: (props) => () => {
      props.reset("editBonusCalculation")
      props.setIsEdit(false)
    },
    onSave: (props) =>
      props.handleSubmit(async (values) => {
        const filteredCompanyCommissionRates = values.companyCommissionRates.filter(
          (item) => item.commissionPercent !== null || item.beginDate !== null
        )

        const filteredCompanyMonthlyBonusRates = values.companyMonthlyBonusRates.filter(
          (item) => item.afypMin !== null || item.afypMax !== null || item.bonusPercent !== null
        )

        const filteredCompanyQuarterlyBonusRates = values.companyQuarterlyBonusRates.filter(
          (item) => item.afypMin !== null || item.afypMax !== null || item.bonusPercent !== null
        )

        const filteredCompanyYearlyBonusRates = values.companyYearlyBonusRates.filter(
          (item) => item.afypMin !== null || item.afypMax !== null || item.bonusPercent !== null
        )

        const filteredCompanyPersistentBonuses = values.companyPersistentBonuses.filter(
          (item) =>
            item.companyPersistentBonusRatesAttributes !== [] &&
            item.limraMonthCount !== null &&
            item.persistentPayDays !== []
        )

        const filteredMonthlyExperienceBonusRates = values.monthlyExperienceBonusRates.filter(
          (item) =>
            item.experienceMin !== null || item.experienceMax !== null || item.bonusPercent !== null
        )

        const filteredQuarterlyExperienceBonusRates = values.quarterlyExperienceBonusRates.filter(
          (item) =>
            item.experienceMin !== null || item.experienceMax !== null || item.bonusPercent !== null
        )

        const filteredYearlyExperienceBonusRates = values.yearlyExperienceBonusRates.filter(
          (item) =>
            item.experienceMin !== null || item.experienceMax !== null || item.bonusPercent !== null
        )

        try {
          await props.updateBonusCommission(props.commissionId, {
            companyCommissionRates: filteredCompanyCommissionRates,
            companyMonthlyBonusRates: filteredCompanyMonthlyBonusRates,
            companyQuarterlyBonusRates: filteredCompanyQuarterlyBonusRates,
            companyYearlyBonusRates: filteredCompanyYearlyBonusRates,
            companyPersistentBonuses: filteredCompanyPersistentBonuses,
            quarterlyCalculateDetail: values.quarterlyCalculateDetail,
            monthlyExperienceBonusRates: filteredMonthlyExperienceBonusRates,
            quarterlyExperienceBonusRates: filteredQuarterlyExperienceBonusRates,
            yearlyExperienceBonusRates: filteredYearlyExperienceBonusRates,
          })

          await props.fetchBonusCommission(props.commissionId)
          props.setIsEdit(false)
        } catch (e) {
          const error = get(e, "errors.base", null)

          if (error) {
            window.alert(error)
          } else {
            alert("บันทึกคำนวณโบนัสไม่สำเร็จ ลองใหม่ภายหลัง")
          }
        }
      }),
    onTabChange: (props) => (event) => {
      props.setTabSelected(event.selected)
    },
  }),
  withHandlers({
    onClearForm: (props) => () => {
      props.change("quarterlyCalculateDetail", {})
    },
  }),
  withHooks((props) => {
    const [isHiddenEditButton, setIsHiddenEditButton] = useState(true)

    useEffect(() => {
      props.fetchBonusCommission(props.commissionId)
    }, [])

    useEffect(() => {
      if (props.values) {
        switch (props.tabSelected) {
          case 0:
            if (props.values.companyCommissionRates.length !== 0) {
              setIsHiddenEditButton(false)
            } else {
              setIsHiddenEditButton(true)
            }
            break
          case 1:
            if (
              props.values.companyMonthlyBonusRates.length !== 0 ||
              props.values.monthlyExperienceBonusRates.length !== 0
            ) {
              setIsHiddenEditButton(false)
            } else {
              setIsHiddenEditButton(true)
            }
            break
          case 2:
            if (
              props.values.companyQuarterlyBonusRates.length !== 0 ||
              props.values.quarterlyExperienceBonusRates.length !== 0 ||
              size(props.values.quarterlyCalculateDetail) !== 0
            ) {
              setIsHiddenEditButton(false)
            } else {
              setIsHiddenEditButton(true)
            }
            break
          case 3:
            if (
              props.values.companyYearlyBonusRates.length !== 0 ||
              props.values.yearlyExperienceBonusRates.length !== 0
            ) {
              setIsHiddenEditButton(false)
            } else {
              setIsHiddenEditButton(true)
            }
            break
          case 4:
            if (props.values.companyPersistentBonuses.length !== 0) {
              setIsHiddenEditButton(false)
            } else {
              setIsHiddenEditButton(true)
            }
            break
        }
      }
    }, [props.values, props.tabSelected])

    const newItem = useCallback(
      (fieldName) => () => {
        switch (fieldName) {
          case "companyCommissionRates":
            props.change("companyCommissionRates", [
              {
                commissionPercent: null,
                beginDate: null,
              },
            ])
            break
          case "companyMonthlyBonusRates":
            props.change("companyMonthlyBonusRates", [
              {
                afypMin: null,
                afypMax: null,
                bonusPercent: null,
              },
            ])
            break
          case "monthlyExperienceBonusRates":
            props.change("monthlyExperienceBonusRates", [
              {
                experienceMin: null,
                experienceMax: null,
                bonusPercent: null,
              },
            ])
            break
          case "companyQuarterlyBonusRates":
            props.change("companyQuarterlyBonusRates", [
              {
                afypMin: null,
                afypMax: null,
                bonusPercent: null,
              },
            ])
            break
          case "quarterlyExperienceBonusRates":
            props.change("quarterlyExperienceBonusRates", [
              {
                experienceMin: null,
                experienceMax: null,
                bonusPercent: null,
              },
            ])
            break
          case "companyYearlyBonusRates":
            props.change("companyYearlyBonusRates", [
              {
                afypMin: null,
                afypMax: null,
                bonusPercent: null,
              },
            ])
            break
          case "yearlyExperienceBonusRates":
            props.change("yearlyExperienceBonusRates", [
              {
                experienceMin: null,
                experienceMax: null,
                bonusPercent: null,
              },
            ])
            break
          case "companyPersistentBonuses":
            props.change("companyPersistentBonuses", [
              {
                companyPersistentBonusRatesAttributes: [],
                limraMonthCount: null,
                persistentPayDays: [],
              },
            ])
            break
        }
        props.setIsEdit(true)
      },
      []
    )

    return {
      newItem,
      isHiddenEditButton,
    }
  })
)

export default enhancer(BonusCalculationShowPage)
