import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import classnames from "classnames"
import { get } from "lodash"

export const Layout = styled.div`
  display: flex;
  align-items: center;
  width: ${(props) => (props.width ? `${props.width}px` : "100%")};
  margin-bottom: ${(props) => props.marginBottom}px;

  > * :nth-of-type(1) {
    // margin-bottom: auto;
    height: 33.5px;
    display: flex;
    align-items: center;
  }

  ${(props) =>
    props.column &&
    css`
      flex-direction: column;
    `}
`
const LeftPanel = styled.div`
  min-width: ${(props) => props.width || 180}px;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  color: #666666;
  font-weight: bold;
`
const Caption = styled.div`
  width: 100%;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  color: #666666;
  ${(props) =>
    props.captionStyles
      ? css`
          font-weight: normal;
        `
      : css`
          font-weight: 600;
        `}
`
const RightPanel = styled.div`
  flex: 1;
  width: 100%;
  min-width: ${(props) => props.width}px;
  max-width: ${(props) => props.width}px;

  .k-checkbox-wrapper {
    display: flex;
  }

  > * {
    margin-bottom: 0px;
    width: 100%;

    > ul {
      margin: 0px;
      padding: 0px;
    }
  }
`
const RemarkIcon = styled.span`
  color: red;
`

const withCaption = (options = {}) => (Component) => ({
  caption,
  width,
  leftWidth,
  rightWidth,
  marginBottom,
  className,
  required,
  captionStyle = {},
  valueStyle = {},
  style = {},
  cypress,
  isReadOnlyAddressCaption,
  ...childrenProps
}) =>
  caption !== undefined ? (
    <Layout
      className={classnames(className, get(options, "className.name", options.className))}
      marginBottom={marginBottom}
      style={style}
      width={
        leftWidth !== undefined && rightWidth !== undefined
          ? parseInt(leftWidth) + parseInt(rightWidth)
          : width || null
      }
      {...cypress}
    >
      <LeftPanel width={leftWidth} style={captionStyle}>
        <Caption captionStyles={isReadOnlyAddressCaption}>
          {caption} {caption && required && <RemarkIcon>*</RemarkIcon>}
        </Caption>
      </LeftPanel>
      <RightPanel width={rightWidth} style={valueStyle}>
        <Component {...childrenProps} caption={caption} />
      </RightPanel>
    </Layout>
  ) : (
    <Component className={className} style={style} {...childrenProps} {...cypress} />
  )

export const WithCaption = (props) =>
  props.caption !== undefined ? (
    <Layout className={props.className} marginBottom={props.marginBottom}>
      <LeftPanel width={props.leftWidth}>
        <Caption captionStyles={props.isReadOnlyAddressCaption}>{props.caption}</Caption>
      </LeftPanel>
      <RightPanel width={props.rightWidth}>{props.children}</RightPanel>
    </Layout>
  ) : (
    props.children
  )

WithCaption.LeftPanel = LeftPanel

export default withCaption
