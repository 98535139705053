import queryString from "query-string"
import { pick, get, set, cloneDeep, isNaN } from "lodash"
import numeral from "numeral"
import { matchPath } from "react-router"
import html2canvas from "html2canvas"
import downloadjs from "downloadjs"
import { v4 as uuidv4 } from "uuid"

export const isServer = typeof window === "undefined"
export const isClient = !isServer

export const url2Obj = (params) => queryString.parse(params)
export const obj2Url = (params) => queryString.stringify(params)

export const safe = (block, safeValue) => {
  try {
    return block()
  } catch (e) {
    return safeValue
  }
}

export const isCurrentPath = (path) => {
  if (typeof path === "string") {
    path = { path }
  }
  return matchPath(`${window.location.pathname}`, path)
}

export const pickByKeys = (arr, ...keys) => {
  if (arr) {
    return arr.map((a) => pick(a, keys))
  } else {
    return []
  }
}

export const formatFullName = (nameInfo) =>
  `${nameInfo.prefixName || ""}${nameInfo.firstName || ""} ${nameInfo.lastName || ""}`

export const cleanQueryParams = (params, paths) => {
  paths = paths.filter((path) => !!get(params, path))

  params = pick(params, paths)

  paths.forEach((path) => {
    const value = get(params, path)
    if (/^\d+(.\d+)?$/.test(value)) {
      set(params, path, parseFloat(value))
    }
  })

  return params
}

export const cleanQueryParamss = (params) => {
  if (params instanceof Array) {
    return params.map((param) => cleanQueryParams(param))
  } else if (params instanceof Object) {
    return Object.entries(params).reduce((memo, [key, value]) => ({
      ...memo,
      [key]: cleanQueryParams(value),
    }))
  } else if (typeof params === "string") {
    if (/^\d+(\.\d*)?$/.test(params)) {
    }
  } else {
    return params
  }
}

export const prepareOptions = (options) => {
  if (options) {
    if (typeof options[0] === "string") {
      return options.map((label) => ({ label, value: label }))
    } else {
      return options
    }
  } else {
    return []
  }
}

export const getOptionLabel = (options, targetValue) => {
  options = prepareOptions(options)
  const option = options.find(({ value }) => value === targetValue)
  return option ? option.label : null
}

export const displayNumber = (value) => {
  let nums = ("" + value).split(".")
  const onlyNums = nums[0].replace(/[^-\d]/g, "")
  let rgx = /(\d+)(\d{3})/
  let x1 = onlyNums
  while (rgx.test(x1)) {
    // eslint-disable-next-line no-useless-concat
    x1 = x1.replace(rgx, "$1" + "," + "$2")
  }
  if (nums.length > 1) x1 = x1 + "." + nums[1]
  return x1
}

export const numberParser = (string) => parseFloat(string ? string.replace(/,/g, "") : "")

export const toCurrency = (value) => {
  value = parseFloat(value)
  if (!isNaN(value)) {
    return new Intl.NumberFormat().format(value)
  } else {
    return ""
  }
}

export const toPercent = (value) => {
  return `${numeral(value).format("0.00")} %`
}

export const convertNaNtoZero = (arg) => (isNaN(parseInt(arg)) ? 0 : parseInt(arg))

export const convertNaNtoZeroFloat = (arg) => (isNaN(parseInt(arg)) ? 0 : parseFloat(arg))

export const convertMinustoZero = (arg) => (arg < 0 ? 0 : arg)

export const enableBodyScrolling = (enable) => {
  const $element = window.document.body
  if (enable) {
    $element.style.overflow = ""
  } else {
    $element.style.overflow = "hidden"
  }
}

export const formatTime = (arg) => {
  if (arg) {
    if (parseInt(arg.getMinutes()) < 10) {
      return arg.getHours() + ".0" + arg.getMinutes() + " น."
    } else {
      return arg.getHours() + "." + arg.getMinutes() + " น."
    }
  }
}

export const formatDate = (arg) => {
  if (arg) {
    return arg.getDate() + "/" + (arg.getMonth() + 1) + "/" + (arg.getFullYear() + 543)
  }
}

export const formatPhoneNumber = (arg) => {
  if (arg) {
    const formattedNumber =
      arg.length === 10
        ? arg.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
        : arg.replace(/(\d{2})(\d{3})(\d{4})/, "$1-$2-$3")
    return formattedNumber
  }
}

export const formatIdCardNumber = (arg) => {
  if (arg) {
    const formattedNumber =
      arg.length === 13 ? arg.replace(/(\d{1})(\d{4})(\d{5})(\d{2})(\d{1})/, "$1-$2-$3-$4-$5") : arg
    return formattedNumber
  }
}

export const call = (func) => func()

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const captureScreen = async ($target, filename, download = true) => {
  $target = $target || document.body
  filename = filename || `${uuidv4()}.png`

  if (typeof $target === "string") {
    $target = document.querySelector($target)
  }

  const canvas = await html2canvas($target, { useCORS: true })
  const dataUrl = canvas.toDataURL()

  if (download) {
    downloadjs(dataUrl, filename, "image/png")
  }

  return canvas
}

export const exceptNullKeys = (obj, ...keys) => {
  obj = cloneDeep(obj)
  keys.forEach((key) => {
    if (get(obj, key) === null || get(obj, key) === "") {
      set(obj, key, undefined)
    }
  })

  return obj
}

export const numberFormatter = (arg) => {
  if (Math.abs(arg) >= 1000000) {
    return Math.sign(arg) * (Math.abs(arg) / 1000000).toFixed(1) + " M"
  } else if (Math.abs(arg) >= 1000) {
    if (Math.sign(arg) * (Math.abs(arg) / 1000).toFixed(1) < 1000) {
      return Math.sign(arg) * (Math.abs(arg) / 1000).toFixed(1) + " k"
    } else {
      return "1 M"
    }
  } else {
    return Math.sign(arg) * Math.abs(arg)
  }
}

export const isAgentToolsPath = (path) => {
  if (!path) return false
  const splitedPath = path.split("/")

  return (
    splitedPath.length === 4 &&
    !!splitedPath[1] &&
    splitedPath[1] === "auth" &&
    !!splitedPath[2] &&
    splitedPath[2] === "agt" &&
    !!splitedPath[3]
  )
}

export const proxy2Obj = (prox) => JSON.parse(JSON.stringify(prox))
