import React from "react"
import styled from "@emotion/styled"
import Input from "./Input"
import Button from "./Button"
import { compose, withHandlers, withState, defaultProps } from "recompose"

const Layout = styled.div`
  position: relative;
  display: inline-block;

  input {
    width: 100%;
    padding-right: 33.5px;
  }
`

const SearchInput = styled(Input)``

const SearchButton = styled(Button)`
  position: absolute;
  right: 1px;
  top: 0;
  border: none;
  background: transparent;
  width: 30px;
  margin-top: 1px;
  height: calc(100% - 2px);

  :hover {
    border: #c7cdd5;
    color: #294558;
  }
`

const SearchBox = (props) => (
  <Layout className={props.className}>
    <SearchInput value={props.value} onChange={props.onChange} placeholder={props.placeholder} />
    <SearchButton icon="search" loading={props.loading} onClick={props.onSearch} />
  </Layout>
)

const enhancer = compose(
  withState("value", "setValue", null),
  withState("searchTask", "setSearchTask", null),
  withState("loading", "setLoading", false),
  defaultProps({
    delay: 500,
  }),
  withHandlers({
    onChange: (props) => (value) => {
      props.setValue(value)

      if (props.onSearch) {
        if (props.searchTask) {
          clearTimeout(props.searchTask)
        }
        props.setLoading(true)
        props.setSearchTask(
          setTimeout(async () => {
            await props.onSearch(value)
            props.setLoading(false)
          }, props.delay)
        )
      }
    },
  })
)

export default enhancer(SearchBox)
