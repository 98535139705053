import React, { useEffect } from "react"
import styled from "@styled"
import { Field, Dropdown } from "@components"
import { compose, withProps } from "recompose"
import { withStores, withForm, withRouter, withHooks } from "@enhancers/index"
import * as path from "@common/path"
import { exceptNullKeys } from "@common/helper"

export const Row = styled.div`
  display: flex;
  margin-top: 12px;
  width: 646px;

  &:nth-of-type(1) {
    margin-top: 0;
  }

  > div {
    margin-left: 16px;
    &:nth-of-type(1) {
      margin-left: 0;
    }

    > div {
      > div {
        font-weight: normal;
      }
    }
  }
`

const FilterPanel = (props) => (
  <div>
    <Row>
      <Field
        component={Dropdown}
        name="insuranceCompanyId"
        caption="บริษัทประกัน"
        captionStyle={{ fontWeight: "normal" }}
        options={props.companyOptions}
        leftWidth="100"
        rightWidth="250"
        disabled={props.disabled}
        placeholder="กรุณาเลือก"
      />
    </Row>
  </div>
)

const enhancer = compose(
  withRouter(),
  withStores((stores) => ({
    insuranceCompanyOptions: stores.riderStore.$(
      "configs.configs.filters.insuranceCompany.options",
      []
    ),
  })),
  withProps((props) => {
    const companyOptions = props.insuranceCompanyOptions.map((option) => {
      return {
        ...option,
        value: String(option.value),
      }
    })

    return {
      companyOptions,
    }
  }),
  withProps((props) => {
    const { query } = props.location || {}
    return {
      initialValues: query,
    }
  }),
  withForm({
    form: "riderMappingFilterForm",
  }),
  withHooks((props) => {
    const { query } = props.location || {}

    useEffect(() => {
      const params = {
        ...query,
        ...props.values,
      }

      const exceptNullParams = exceptNullKeys(params, "insuranceCompanyId", "state")

      const urlParams = { ...params, ...exceptNullParams }
      props.history.push(path.ridersPath(urlParams))
    }, [JSON.stringify(props.values)])

    return {}
  })
)

export default enhancer(FilterPanel)
