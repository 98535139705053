import BaseStore from "./BaseStore"
import { tqldServer } from "@common/api"

class RiderStore extends BaseStore {
  constructor() {
    super({
      all: [],
      paging: {
        page: 1,
        perPage: 10,
        totalCount: 0,
        totalPages: 1,
      },
      filter: {},
      current: { riderIds: [] },
      configs: {},
    })
  }

  fetch = async (params) => {
    const response = await tqldServer.tqmBackend.get(`/rider_mappings`, params)
    const response1 = await tqldServer.tqmBackend.get(`/rider_mappings/configs`)

    this.configs = response1.data.data
    this.all = response.data.data.riderMappings
  }

  fetchOne = async (params) => {
    const response = await tqldServer.tqmBackend.get(`/rider_mappings`)
    const response1 = await tqldServer.tqmBackend.get(`/rider_mappings/configs`)
    this.all = response.data.data.riderMappings
    this.configs = response1.data.data
    this.current = await this.all.find((x) => x.lifeId === parseInt(params))

    return this.all.find((x) => x.lifeId === parseInt(params))
  }

  addItemRider = async (params) => {
    this.current.riderIds.push(params)
  }

  deleteItemRider = async (params) => {
    this.current.riderIds.splice(params, 1)
  }

  cancelForm = async (params) => {
    const response = await tqldServer.tqmBackend.get(`/rider_mappings`)
    this.all = response.data.data.riderMappings
    this.current = await this.all.find((x) => x.lifeId === parseInt(params))
  }

  updateRider = async (params) => {
    await tqldServer.tqmBackend.post(`/rider_mappings`, params)
  }
}

export default new RiderStore()
