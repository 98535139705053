import React from "react"
import styled from "@styled"
import { Field, Dropdown, DatePicker, Input, CurrencyInput } from "@components"
import { compose } from "recompose"
import { withStores } from "@enhancers/index"

const Layout = styled.div`
  padding-left: 5px;
  padding-bottom: 5px;
`
const Row = styled.div`
  display: flex;
  margin-top: 20px;

  &:nth-of-type(1) {
    margin-top: 0;
    > div:nth-of-type(2) {
      margin-left: 44px;
    }
  }

  &:nth-of-type(3) {
    > div:nth-of-type(2) {
      margin-left: 44px;
    }
  }

  &:nth-of-type(4) {
    > div:nth-of-type(2) {
      margin-left: 44px;
    }
  }

  &:last-of-type {
    > div {
      margin-left: 16px;
    }
  }

  > div {
    &:nth-of-type(1) {
      margin-left: 0;
    }
    &:nth-of-type(2) {
      margin-left: 44px;
    }
  }
`
const CustomField = styled(Field)`
  > div > div {
    font-weight: unset;
  }
`

const FilterPanel = (props) => (
  <Layout>
    <Row>
      <CustomField
        component={Input}
        name="tqmOrderId"
        caption="รหัสคำสั่งซื้อ"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="130"
        rightWidth="250"
        placeholder="กรุณาระบุ"
      />
    </Row>
    <Row>
      <CustomField
        component={Dropdown}
        name="insurancePolicyId"
        caption="แบบประกัน"
        captionStyle={{ fontWeight: "normal" }}
        options={props.insurancePolicyOptions}
        leftWidth="130"
        rightWidth="250"
        placeholder="กรุณาเลือก"
      />
    </Row>
    <Row>
      <CustomField
        component={Dropdown}
        name="insuranceCompanyId"
        caption="บริษัทประกัน"
        captionStyle={{ fontWeight: "normal" }}
        options={props.insuranceCompanyOptions}
        leftWidth="130"
        rightWidth="250"
        placeholder="กรุณาระบุ"
      />
    </Row>
    <Row>
      <CustomField
        component={DatePicker}
        name="submittedAt.min"
        caption="ช่วงวันที่แจ้งงาน"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="130"
        rightWidth="155"
        placeholder="กรุณาเลือก"
        showErrorMessage={true}
      />
      <CustomField
        component={DatePicker}
        name="submittedAt.max"
        caption="ถึง"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="29"
        rightWidth="155"
        placeholder="กรุณาเลือก"
        showErrorMessage={true}
      />
    </Row>
    <Row>
      <CustomField
        component={DatePicker}
        name="receivedDate.min"
        caption="ช่วงวันที่รับเงิน"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="130"
        rightWidth="155"
        placeholder="กรุณาเลือก"
        showErrorMessage={true}
      />
      <CustomField
        component={DatePicker}
        name="receivedDate.max"
        caption="ถึง"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="29"
        rightWidth="155"
        placeholder="กรุณาเลือก"
        showErrorMessage={true}
      />
    </Row>
    <Row>
      <CustomField
        component={CurrencyInput}
        name="installment"
        caption="งวดที่"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="130"
        rightWidth="250"
        placeholder="กรุณาระบุ"
      />
    </Row>
    <Row>
      <CustomField
        component={Dropdown}
        name="commissionStatus"
        caption="สถานะ"
        captionStyle={{ fontWeight: "normal" }}
        options={props.statusOptions}
        leftWidth="130"
        rightWidth="250"
        placeholder="กรุณาเลือก"
      />
    </Row>
  </Layout>
)

const enhancer = compose(
  withStores((stores) => ({
    insurancePolicyOptions: stores.saleCommissionStore.$(
      "configsById.filters.insurancePolicy.options",
      []
    ),
    insuranceCompanyOptions: stores.saleCommissionStore.$(
      "configsById.filters.insuranceCompany.options",
      []
    ),
    statusOptions: stores.saleCommissionStore.$(
      "configsById.filters.salesCommissionDetailStatus.options",
      []
    ),
  }))
)

export default enhancer(FilterPanel)
