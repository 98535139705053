import React, { useCallback } from "react"
import styled from "@emotion/styled"
import { IFrame, Button } from "@components"
import { FullBodyLayout } from "@pages/life_insurance_policy/index"
import * as path from "@common/path"
import { compose } from "recompose"
import { withRouter, withHooks, withStores } from "@enhancers/index"
import { desktopSize } from "@common/styled"

const SettingButton = styled(Button)`
  position: absolute;
  right: 55px;
  top: 110px;
  width: 86px;
`

const OldSiteIFrame = styled(IFrame.OldSite)`
  width: 100%;
  height: 100vh;
  border: none !important;

  ${(props) =>
    desktopSize(`
      padding-left: 64px;
  `)}
`

const HealthPolicy = (props) => (
  <FullBodyLayout>
    <SettingButton awesomeIcon="gear" onClick={props.gotoRiderMapping}>
      ตั้งค่า
    </SettingButton>
    <OldSiteIFrame src={`health_insurance_policies?member_id=${props.currentMemberId}`} />
  </FullBodyLayout>
)

const enhancer = compose(
  withRouter(),
  withStores((stores) => ({
    currentMemberId: stores.appStore.currentMemberId,
  })),
  withHooks((props) => {
    const gotoRiderMapping = useCallback(() => {
      props.history.push(path.ridersPath())
    }, [props.history])
    return { gotoRiderMapping }
  })
)

export default enhancer(HealthPolicy)
