import { useCallback, useState } from "react"
import { compose, lifecycle, withProps } from "recompose"
import { withForm, withHooks, withStores } from "@enhancers/index"
import * as path from "@common/path"
import { MarketPlanShowPage } from "../show/index"
import { validate } from "../show/index"

const enhancer = compose(
  withStores((stores) => ({
    fetchMarketPlans: stores.marketPlanStore.fetchById,
    fetchMarketPlansConfigs: stores.marketPlanStore.fetchConfigs,
    marketPlans: stores.marketPlanStore.all,
    create: stores.marketPlanStore.create,
    calculateTypeOptions: stores.marketPlanStore.$("configs.filters.calculateType.options", []),
  })),
  withForm({ form: "newMarketPlanForm", validate }),
  withProps(() => {
    return {
      breadCrumbLinks: [
        { label: "จัดการแผนการตลาด", path: path.marketPlansPath() },
        {
          label: "สร้างแผนการตลาด",
          path: path.newMarketPlanPath(),
        },
      ],
    }
  }),
  withHooks((props) => {
    const [isEdit, setIsEdit] = useState(true)
    const [from, setFrom] = useState("new")

    const onCancel = useCallback(() => {
      props.history.push(path.marketPlansPath())
    }, [])

    const onSubmit = useCallback(
      async (values) => {
        try {
          await props.create(values)
          props.history.push(path.marketPlansPath())
        } catch (e) {
          if (e.name === "SubmissionError" && e.errors.commissionPercent) {
            alert(e.errors.commissionPercent)
          } else if (e.name === "SubmissionError" && e.errors.beginDate) {
            alert("วันที่มีผลบังคับต้องไม่ซ้ำกัน")
          } else {
            alert("สร้างแผนการตลาดไม่สำเร็จ กรุณาลองใหม่ภายหลัง")
          }
        }
      },
      [props.values]
    )

    const [conditionDescriptions, setConditionDescriptions] = useState([])
    const [commissionStructure, setCommissionStructure] = useState([])
    const [reserveForLevelOptions, setReserveForLevelOptions] = useState([])

    const createMkPlanStructure = useCallback(() => {
      const mkPlanStructure = []
      const tempReserveForLevelOptions = [{ label: "-", value: "" }]

      for (var index = 1; index <= props.values.numberOfLevel; index++) {
        mkPlanStructure.push({
          positionLevel: index,
          positionName: "",
          commissionPercent: 0,
          managementPercent: 0,
          reserveForLevel: "",
        })

        tempReserveForLevelOptions.push({ label: index, value: index })
      }
      setCommissionStructure(mkPlanStructure)
      setReserveForLevelOptions(tempReserveForLevelOptions)
    }, [props.values.numberOfLevel])

    let isShowMkPlan = false
    if (props.values.mkCommissionStructures) {
      isShowMkPlan = true
    } else if (commissionStructure.length !== 0) {
      isShowMkPlan = true
    }

    return {
      isEdit,
      from,
      conditionDescriptions,
      commissionStructure,
      reserveForLevelOptions,
      isShowMkPlan,

      onCancel,
      onSubmit,
      createMkPlanStructure,
    }
  }),
  lifecycle({
    async componentDidMount() {
      await this.props.fetchMarketPlansConfigs()
    },
  })
)

export default enhancer(MarketPlanShowPage)
