import React, { Fragment } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { Grid, GridColumn, makeCell, DatePicker, CurrencyInput } from "@components"

const Table = styled(Grid)`
  margin-top: 20px;
  margin-bottom: 50px;

  .k-grid-header {
    height: 50px !important;
    border-radius: 0px 0px 0px 0px !important;
    border-color: #eeeef2 !important;
    background-color: #627b8b !important;
    .k-grid-header-wrap {
      border-radius: 0px 0px 0px 0px !important;
      border-color: #eeeef2 !important;
      background-color: #627b8b !important;

      .k-header {
        background-color: #627b8b !important;
        .k-link {
          display: flex !important;
          height: 50px !important;
          padding-left: 20px !important;
          text-decoration: none !important;
          background-color: #627b8b !important;
          color: white !important;
        }
      }
    }
  }

  .k-grid-container {
    .k-grid-content.k-virtual-content {
      > div {
        .k-grid-table {
          pointer-events: none !important;
          > tbody {
            .k-master-row {
              > td {
                padding-left: 20px !important;
                cursor: pointer;
              }
            }
            .wrap-text {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
`
const SectionHeader = styled.span`
  font-size: 16px;
  color: #0275d8;
  font-weight: bold;
  font-family: Sarabun, sans-serif;
  margin-left: 20px;

  ${(props) =>
    props.readOnly &&
    css`
      height: 40px;
      line-height: 2.5;
      color: white;
      background-color: #294558;
      padding-left: 16px;
    `};
`

const PaymentDetailReadOnlyTable = (props) => (
  <Fragment>
    <SectionHeader>รายละเอียดการชำระเงิน</SectionHeader>
    <Table data={props.data}>
      <GridColumn field="installmentNo" title="งวด" width="55px" />
      <GridColumn field="paymentMethod" title="ประเภท" width="120px" />
      <GridColumn field="paymentVia" title="ชำระผ่าน" width="120px" />
      <GridColumn
        field="paymentAmount"
        title="ยอดชำระ"
        width="93px"
        cell={makeCell(CurrencyInput, (cellProps) => ({
          readOnly: true,
          value: cellProps.dataItem.paymentAmount || "-",
        }))}
      />
      <GridColumn
        field="remainingAmount"
        title="ยอดค้างชำระ"
        width="115px"
        cell={makeCell(CurrencyInput, (cellProps) => ({
          readOnly: true,
          value: cellProps.dataItem.remainingAmount || "-",
        }))}
      />
      <GridColumn
        field="dueDate"
        title="วันนัดชำระ"
        width="102px"
        cell={makeCell(DatePicker, (cellProps) => ({
          readOnly: true,
          value: cellProps.dataItem.dueDate,
        }))}
      />
      <GridColumn
        field="paymentDate"
        title="วันชำระ"
        width="118px"
        cell={makeCell(DatePicker, (cellProps) => ({
          readOnly: true,
          value: cellProps.dataItem.paymentDate,
        }))}
      />
    </Table>
  </Fragment>
)

export default PaymentDetailReadOnlyTable
