import React from "react"
import {
  compose,
  withState,
  branch,
  renderNothing,
  withHandlers,
  withProps,
  lifecycle,
} from "recompose"
import styled from "@styled"
import { withStores, withRouter } from "@enhancers/index"
import { Avatar, Header as TextHeader, Icon } from "@components/index"
import userAvatar from "@assets/images/avatar.jpg"
import OutsideClickHandler from "react-outside-click-handler"
import { get } from "lodash"
import { rolesInfo } from "@stores/mobx/appStore"
import * as path from "@common/path"
import { isEqual, reduce } from "lodash"

import { TQLD_CURRENT_MEMBER_ID_KEY } from "@common/api"

import { desktopSize } from "@common/styled"

const Layout = styled.div`
  display: flex;

  flex: 1;
  justify-content: flex-end;
  position: relative;

  > div:nth-of-type(2) {
    margin-left: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
`

const WrapperAvatar = styled.div`
  display: flex;
  position: relative;
`
const MiniNoti = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #d9534f;
  width: 12px;
  height: 12px;
  font-size: 8px;
  color: #fff;
  position: absolute;
  right: 0;
  line-height: 0;
`
const UserName = styled(TextHeader)`
  display: flex;
  align-items: center;
  > div {
    color: #666666;
    font-family: "ThaiSans Neue";
    font-size: 30px;
  }

  > div:first-of-type {
    max-width: 145px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    ${(props) =>
      desktopSize(`
      max-width: unset !important;
  `)}
  }
`
const Menu = styled.div`
  width: 188px;
  background: white;
  position: absolute;
  top: 49px;
  right: 0;
  display: ${(props) => (props.hidden ? "none" : "block")};
  overflow: hidden;
`

const RowMenu = styled.div`
  min-height: 50px;
  max-height: 50px;
  display: flex;
  align-items: center;
  padding: 0px 23px;

  :hover {
    background: #eeeef2;
  }
`
const TitleRowMenu = styled.div`
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  color: #666666;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 2;
`
const NotiWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const LogoutRowMenu = styled(RowMenu)`
  :hover {
    > div {
      color: #d9534f;
    }
  }
`

const NotificationIcon = styled.div`
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #d9534f;
  width: 20px;
  height: 20px;
  font-size: 12px;
  color: #fff;
`

const MemberWrapper = styled.div`
  border-bottom: 1px solid #eeeef2;
`
const ProfileMenu = (props) => (
  <Layout>
    {props.hasNotReadNotification ? (
      <WrapperAvatar onClick={props.gotoNotificationPage}>
        <Avatar src={userAvatar} size="38" />
        <MiniNoti>N</MiniNoti>
      </WrapperAvatar>
    ) : (
      <WrapperAvatar>
        <Avatar src={userAvatar} size="38" />
      </WrapperAvatar>
    )}

    <OutsideClickHandler onOutsideClick={props.close}>
      <UserName onClick={props.toggle}>
        <div>{props.currentUser}</div>
        &nbsp;
        {props.isOpen ? (
          <div>
            <Icon icon="arrow-chevron-up" />{" "}
          </div>
        ) : (
          <div>
            <Icon icon="arrow-chevron-down" />
          </div>
        )}
      </UserName>

      <Menu hidden={!props.isOpen}>
        {/* <RowMenu>
          <TitleRowMenu>ข้อมูลส่วนตัว</TitleRowMenu>
        </RowMenu> */}

        {props.memberOptions && props.memberOptions.length > 0 && (
          <MemberWrapper>
            {props.memberOptions.map((member, index) => (
              <RowMenu key={index} onClick={() => props.changeRole(member.id)}>
                <TitleRowMenu>{member.memberTypeLabel}</TitleRowMenu>
              </RowMenu>
            ))}
          </MemberWrapper>
        )}
        <RowMenu onClick={props.onNotification}>
          <NotiWrapper>
            <TitleRowMenu>การแจ้งเตือน</TitleRowMenu>
            {props.hasNotReadNotification && <NotificationIcon>N</NotificationIcon>}
          </NotiWrapper>
        </RowMenu>

        <LogoutRowMenu onClick={props.logout}>
          <TitleRowMenu>ออกจากระบบ</TitleRowMenu>
        </LogoutRowMenu>
      </Menu>
    </OutsideClickHandler>
  </Layout>
)

const enhancer = compose(
  withRouter(),
  withState("hasNotReadNotification", "setHasNotReadNotification", false),
  withStores((stores) => ({
    currentUser: stores.appStore.$("currentUser.nameThInfo.firstName"),
    currentUserMembers: stores.appStore.$("currentUser.members"),
    roles: stores.appStore.roles,
    currentRole: stores.appStore.currentRole,
    changeCurrentRole: stores.appStore.changeCurrentRole,
    logout: stores.appStore.logout,
    fetchNotificationsStore: stores.appStore.fetchNotifications,
    notifications: stores.appStore.notifications || [],
    changeCurrentMember: stores.appStore.changeCurrentMember,
  })),
  withProps((props) => {
    const memberId = localStorage.getItem(TQLD_CURRENT_MEMBER_ID_KEY)
    return {
      roles: props.roles.map((role) => ({
        name: get(rolesInfo, `${role}.name`) || role,
        value: role,
      })),
      memberOptions:
        props.currentUserMembers &&
        props.currentUserMembers.filter((member) => member.id !== parseInt(memberId)),
    }
  }),
  withState("isOpen", "setIsOpen", false),
  withHandlers({
    checkNotificationNotRead: (props) => () => {
      const hasNotReadNotification = props.notifications.some(
        (notification) => notification.isViewed === false || notification.isViewed === null
      )
      props.setHasNotReadNotification(hasNotReadNotification)
    },
  }),
  withHandlers({
    close: (props) => () => {
      props.setIsOpen(false)
    },
    open: (props) => () => {
      props.setIsOpen(true)
    },
    toggle: (props) => () => {
      props.setIsOpen(!props.isOpen)
    },
    fetchNotifications: (props) => async () => {
      await props.fetchNotificationsStore()
      props.checkNotificationNotRead()
    },
    gotoNotificationPage: (props) => () => {
      props.history.push(path.notificationPath())
    },
  }),

  withHandlers({
    changeRole: (props) => async (value) => {
      await props.changeCurrentMember(value)
      props.close()
      await props.fetchNotifications()
      props.setIsOpen(false)
      props.history.push(path.homePath())
    },
    onNotification: (props) => () => {
      props.setIsOpen(false)
      props.history.push(path.notificationPath())
    },
    logout: (props) => async () => {
      await props.logout()
    },
  }),
  lifecycle({
    async componentDidMount() {
      this.props.fetchNotifications()
      this.timerNoti = setInterval(() => this.props.fetchNotifications(), 10000)
    },
    async componentDidUpdate(prevProps, prevState) {
      if (this.props.isOpen && this.props.isOpen !== prevProps.isOpen) {
        this.props.fetchNotifications()
      }

      const prevIds = reduce(
        prevProps.notifications,
        (memo, item) => [...memo, { id: item.id, isViewed: item.isViewed }],
        []
      )
      const nextIds = reduce(
        this.props.notifications,
        (memo, item) => [...memo, { id: item.id, isViewed: item.isViewed }],
        []
      )

      if (!isEqual(prevIds, nextIds)) {
        this.props.checkNotificationNotRead()
      }
    },
    componentWillUnmount() {
      if (this.timerNoti) {
        clearInterval(this.timerNoti)
      }
    },
  }),
  branch((props) => props.token, renderNothing)
)

export default enhancer(ProfileMenu)
