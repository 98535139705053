import React, { Fragment } from "react"
import styled from "@emotion/styled"
import { Field, Input, DateInput } from "@components"

const Layout = styled.div`
  width: 100%;
  justify-content: center;

  .tqm-footer-productDetail {
    margin-bottom: 26px;
  }
`

const CustomInput = styled(Input)`
  [class*="ReadOnly"] {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
const RiderProduct = (props) => (
  <Layout>
    {props.fields.map((riderProduct, index) => (
      <Fragment key={index}>
        <Field
          name={`${riderProduct}.productDetail.policyName`}
          caption="ชื่อแบบ"
          component={CustomInput}
          leftWidth="190"
          readOnly={true}
        />
        <Field
          name={`${riderProduct}.productDetail.insuranceCompanyName`}
          caption="บริษัทประกัน"
          component={CustomInput}
          leftWidth="190"
          readOnly={true}
        />
        <Field
          name={`${riderProduct}.coverageDate`}
          caption="เริ่มคุ้มครอง"
          component={DateInput}
          leftWidth="190"
          readOnly={true}
        />
        <Field
          className="tqm-footer-productDetail"
          name={`${riderProduct}.premium`}
          caption="เบี้ยประกันต่องวด"
          component={CustomInput}
          leftWidth="190"
          readOnly={true}
        />
      </Fragment>
    ))}
  </Layout>
)

export default RiderProduct
