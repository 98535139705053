import React from "react"
import styled from "@emotion/styled"
import { compose, withHandlers } from "recompose"
import { Button, Dialog, DialogActionsBar } from "@components"

const CustomDialog = styled(Dialog)`
  .k-window {
    border: 1px solid #e6e6e6;
    width: 352px;
  }

  .k-content.k-window-content.k-dialog-content {
    align-self: center;
    padding: 24px 91px;
  }
`
const Title = styled.div`
  font-size: 16px;
  font-family: Sarabun, sans-serif;
  color: #666666;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 195px;
`
const CloseButton = styled(Button)`
  height: 52px;
  color: #666666;
`
const SubmitButton = styled(Button)`
  height: 52px;
  background: #0275d8;
  border: 1px solid #eeeef2;
  color: #ffffff;
`

const DeleteModal = (props) => (
  <CustomDialog title="ลบแผนการตลาด" onClose={props.onClose} isShowModal={props.open}>
    <Title>
      <div>
        ต้องการลบแผนการตลาด<span style={{ fontWeight: 600 }}> แผนมาตรฐาน </span> หรือไม่
      </div>

      <span style={{ fontWeight: 600, marginTop: "11px" }}>(ไม่สามารถกู้คืนข้อมูลได้)</span>
    </Title>

    <DialogActionsBar>
      <CloseButton type="button" onClick={props.onClose} children="ยกเลิก" styletype="delete" />
      <SubmitButton type="button" onClick={props.onSubmit} children="ยืนยัน" />
    </DialogActionsBar>
  </CustomDialog>
)

const enhancer = compose(
  withHandlers({
    onSubmit: (props) => async () => {
      await props.onDelete()
      await props.onClose()
    },
  })
)
export default enhancer(DeleteModal)
