import React from "react"
import styled from "@emotion/styled"

const Star = styled.span`
  width: 20px;
  height: 20px;
  font-size: 20px;
  color: #0275d8;
`
const ContainerStar = styled.div`
  display: flex;
`

const RenderStar = (props) => {
  const arrayStar = []

  for (var i = 1; i <= props.value; i++) {
    arrayStar.push(<Star key={i} className="k-icon k-i-star " />)
  }
  return arrayStar
}

const StarMain = (props) => (
  <ContainerStar>
    <RenderStar value={props.value} />
  </ContainerStar>
)

export default StarMain
