import React from "react"
import styled from "@emotion/styled"
import { compose, withHandlers } from "recompose"
import { DialogActionsBar, Dialog, Button } from "@components"

const Layout = styled.div`
  padding: 27px 50px 30px 50px;
`
const SuccessLayout = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 16px;
  font-family: Sarabun, sans-serif;

  > span {
    margin-bottom: 11px;

    :first-of-type {
      color: #5cb85c;
      font-weight: bold;
    }
  }
`
const CustomDialog = styled(Dialog)`
  .k-window {
    border: 1px solid #e6e6e6;
    min-width: 348px;
  }
`
const CancelButton = styled(Button)`
  min-height: 52px;
`
const SuccessModal = (props) => (
  <CustomDialog title={"ส่งตรวจเอกสาร"} onClose={props.onClose} isShowModal={props.open}>
    <Layout>
      <SuccessLayout>
        <span>{"ส่งตรวจเอกสารสำเร็จ"}</span>
        <span>{"กรุณารอการตรวจสอบเอกสาร"}</span>
      </SuccessLayout>
    </Layout>
    <DialogActionsBar>
      <CancelButton type="button" onClick={props.onClose} children="ปิด" styletype="secondary" />
    </DialogActionsBar>
  </CustomDialog>
)

const enhancer = compose(
  withHandlers({
    onClose: (props) => () => {
      props.onClose && props.onClose()
    },
  })
)
export default enhancer(SuccessModal)
