import React from "react"
import styled from "@emotion/styled"
import { compose, withProps } from "recompose"
import { withField, withCaption, withReadOnly } from "@enhancers/index"
import { BrowseButton } from "./index"

const Layout = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-right: 0.375rem;
  }
`

const AvatarImage = styled.img`
  width: ${(props) => props.size || 33.5}px !important;
  height: ${(props) => props.size || 33.5}px !important;
  border-radius: ${(props) => (props.square ? "4%" : "50%")};
  object-fit: cover;
`

const Avatar = (props) => (
  <Layout>
    <AvatarImage src={props.src} size={33.5} square={props.square} hidden={!props.src} />
    <BrowseButton
      type="image"
      multiple={false}
      onClick={props.onChange}
      awesomeIcon="upload"
      children="อัพโหลดใหม่"
    />
  </Layout>
)

const enhancer = compose(
  withField(),
  withCaption(),
  withProps((props) => ({
    readOnly: props.readOnly === undefined ? !props.onChange : props.readOnly,
  })),
  withProps((props) => ({
    src: !props.value
      ? props.src
      : typeof props.value === "string"
      ? props.value
      : props.value.preview,
  })),
  withReadOnly((props) => <AvatarImage {...props} hidden={!props.src} />)
)

export default enhancer(Avatar)
