import { Field } from "redux-form"
import { compose, withProps } from "recompose"
import validator from "@common/validator"

const enhancer = compose(
  withProps((props) => ({
    required:
      props.required === undefined
        ? props.validate && props.validate.includes("required")
        : props.required,
    validate: props.validate && validator(props.validate),
  }))
)

export default enhancer(Field)
