import React from "react"
import { compose, withState, withHandlers } from "recompose"
import styled from "@emotion/styled"
import { withStores, withRouter } from "@enhancers"
import { tqmBackendServer } from "@common/api"
import { Button, Grid, GridColumn, makeCell, DatePicker, Dropdown } from "@components"

import AddDocumentModal from "./AddDocumentModal"
import DeleteModal from "./DeleteModal"
import { Header } from "../show/InsurerInfo"

const Layout = styled.div`
  width: 100%;
  justify-content: center;
`
const LeftPanel = styled.div`
  font-size: 30px;
  font-weight: bold;
  font-family: ThaiSans Neue;
  display: flex;
  align-items: center;
  color: #666666;
`
const RightPanel = styled.div``
const ButtonGroup = styled.div`
  > * {
    margin-left: 10px;
  }
`
const CustomGrid = styled(Grid)`
  .k-grid-toolbar {
    background-color: transparent;
  }
`

const LayoutDisplayLogo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const CustomButton = styled(Button)`
  margin-right: 10px;
  width: 30px;
  height: 30px;
`
const DownloadButton = styled(Button)`
  width: 182px;
`
const Annotation = (props) => (
  <td>
    <LayoutDisplayLogo>
      <CustomButton
        icon="download"
        onClick={props.onDownload}
        disabled={!props.dataItem.attachmentPath}
      />
      <CustomButton icon="upload" onClick={props.onShowEditFile} />
      <CustomButton icon="delete" onClick={props.onShowDeleteFile} styletype="delete" />

      <DeleteModal
        open={props.isShowDeleteModal}
        onClose={props.onCloseDeleteFile}
        caption="Document"
        onAfterDeleteSubmit={props.onAfterDeleteSubmit}
      />
      <AddDocumentModal
        open={props.isShowEditModal}
        onClose={props.onCloseEditFile}
        title="แก้ไขไฟล์"
        onAfterAddSubmit={props.onAfterEditSubmit}
        initialValues={props.dataItem}
        form={`AddDoc-${props.dataItem.id}-Form`}
        readNameOnly={false}
      />
    </LayoutDisplayLogo>
  </td>
)
const enhancerAnnotation = compose(
  withRouter(),
  withStores((stores, props) => ({
    destroyDocument: stores.insurerStore.destroyDocument,
    updateDocument: stores.insurerStore.updateDocument,
    insurerId: props.match.params.insurerId,
  })),
  withState("isShowDeleteModal", "setIsShowDeleteModal", false),
  withState("isShowEditModal", "setIsShowEditModal", false),
  withHandlers({
    onShowDeleteFile: (props) => () => {
      props.setIsShowDeleteModal(true)
    },
    onCloseDeleteFile: (props) => () => {
      props.setIsShowDeleteModal(false)
    },
    onShowEditFile: (props) => () => {
      props.setIsShowEditModal(true)
    },
    onCloseEditFile: (props) => () => {
      props.setIsShowEditModal(false)
    },
    onAfterDeleteSubmit: (props) => () => {
      props.destroyDocument(props.insurerId, props.dataItem.id)
    },
    onAfterEditSubmit: (props) => (values) => {
      props.updateDocument(props.insurerId, props.dataItem.id, values)
    },
    onDownload: (props) => async () => {
      await tqmBackendServer.download(props.dataItem.attachmentPath)
    },
  })
)
const DisplayAnnotation = enhancerAnnotation(Annotation)

const DocumentInfo = (props) => (
  <Layout>
    <Header>
      <LeftPanel>เอกสาร</LeftPanel>
      <RightPanel>
        <ButtonGroup>
          <DownloadButton
            icon="download"
            onClick={props.onDownloadAll}
            children="ดาวน์โหลดทั้งหมด"
            disabled={props.documents.length <= 0}
            hidden
          />
          <Button icon="plus" onClick={props.onShowAddDocument} children="เพิ่มเอกสาร" />
        </ButtonGroup>
      </RightPanel>
    </Header>

    <CustomGrid data={props.documents}>
      <GridColumn field="name" title="ชื่อไฟล์" width="300px" />
      <GridColumn field="sourceType" title="แหล่งที่มา" width="120px" />
      <GridColumn
        field="documentType"
        title="ชนิดไฟล์"
        width="200px"
        cell={makeCell(Dropdown, (cellProps) => ({
          readOnly: true,
          options: props.documentTypeOptions,
          value: cellProps.dataItem.documentType,
        }))}
      />
      <GridColumn
        field="updatedAt"
        title="วันที่อัพโหลดล่าสุด"
        width="160px"
        cell={makeCell(DatePicker, (props) => ({
          readOnly: true,
          value: props.dataItem.updatedAt,
        }))}
        filterable={false}
      />
      <GridColumn
        field="annotation"
        title="หมายเหตุ"
        cell={DisplayAnnotation}
        filterable={false}
        width="200px"
      />
    </CustomGrid>

    <AddDocumentModal
      open={props.isShowAddModal}
      onClose={props.onCloseAddDocumentModal}
      title="อัพโหลดไฟล์"
      onAfterAddSubmit={props.onAfterAddSubmit}
      form="AddDocumentForm"
    />
  </Layout>
)

const enhancer = compose(
  withRouter(),
  withStores((stores, props) => ({
    createDocument: stores.insurerStore.createDocument,
    downloadAllDocument: stores.insurerStore.downloadAllDocument,
    documents: stores.insurerStore.documents,
    insurerId: props.match.params.insurerId,
    documentTypeOptions: stores.insurerStore.$("configs.attributes.documentType.options", []),
  })),
  withState("isShowAddModal", "setIsShowAddModal", false),
  withHandlers({
    onDownloadAll: (props) => async () => {
      await props.downloadAllDocument(props.insurerId)
    },
    onShowAddDocument: (props) => () => {
      props.setIsShowAddModal(true)
    },
    onCloseAddDocumentModal: (props) => () => {
      props.setIsShowAddModal(false)
    },
    onAfterAddSubmit: (props) => async (values) => {
      await props.createDocument(props.insurerId, values)
    },
  })
)

export default enhancer(DocumentInfo)
