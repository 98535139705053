import React from "react"
import { compose, lifecycle } from "recompose"
import { withRouter, withStores } from "@enhancers"
import { Icon } from "@components"
import styled from "@styled"

import AdvanceForm from "./AdvanceForm"

const Layout = styled.div``
const AllContainer = styled.div`
  width: 532px;
  margin-top: 16px;
  border: 1px solid #e6e6e6;
  background-color: #ffffff;
  border-radius: 4px;
  padding-top: 16px;
  > div {
    :last-child {
      margin: unset;
    }
  }
`
const TextHeader = styled.div`
  font-size: 20px;
  text-align: center;
`
const IconHeader = styled.div`
  color: #666666;
  text-align: center;
  .k-icon {
    font-size: 50px;
    margin-bottom: 13px;
  }
`
const Body = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  /* margin: 0px 45px 25px 45px; */
`

const LeadShow = (props) => (
  <Layout>
    <Body>
      <AllContainer>
        <IconHeader>
          <Icon awesomeIcon="briefcase" />
        </IconHeader>
        <TextHeader>วางแผนลดหย่อนภาษีด้วยประกัน</TextHeader>
        <AdvanceForm {...props} />
      </AllContainer>
    </Body>
  </Layout>
)

const enhancer = compose(
  withRouter(),
  withStores((stores, props) => ({})),
  lifecycle({})
)

export default enhancer(LeadShow)
