import React from "react"
import { compose, withHandlers } from "recompose"
import { withHidden, withField, withCaption, withReadOnly } from "@enhancers/index"
import withOptions from "@enhancers/withOptions"
import styled from "@emotion/styled"
import { getOptionLabel } from "@common/helper"

const Layout = styled.div``
const Item = styled.div`
  height: 35px;
  display: flex;
  align-items: center;
`
const Input = styled.input`
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  :checked {
    border-color: #0275d8;
    color: #0275d8;
    background-color: white;
  }
`
const Label = styled.div`
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  margin-left: 12px;
`

const ReadOnly = styled.div`
  min-height: 33.5px;
  display: flex;
  align-items: center;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
`

const Radio = (props) => (
  <Layout className={props.className + " " + props.name || "" + props.name}>
    {props.options.map(({ label, value }) => {
      const id = `Radio-${Math.random()}-${value}`
      return (
        <Item key={id}>
          <Input
            className="k-radio"
            type="radio"
            id={id}
            onClick={(e) => props.onChange(value, label, e)}
            onChange={() => {}}
            checked={value === props.value}
            disabled={props.disabled}
          />
          <Label
            className="k-radio-label"
            htmlFor={id}
            onClick={(e) => props.onChange(value, label, e)}
          >
            {label}
          </Label>
        </Item>
      )
    })}
  </Layout>
)

const enhancer = compose(
  withHidden(),
  withField(),
  withOptions(),
  withCaption(),
  withReadOnly((props) => <ReadOnly>{getOptionLabel(props.options, props.value)}</ReadOnly>),
  withHandlers({
    onChange: (props) => (value, label, e) => {
      props.onChange && props.onChange(value)
    },
  })
)

export default styled(enhancer(Radio))`
  > * :nth-of-type(1) {
    margin-bottom: auto;
    height: 33.5px;
    display: flex;
    align-items: center;
  }
`
