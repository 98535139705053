import React from "react"
import { GridColumn } from "@components/index"
import * as path from "@common/path"
import {
  Layout,
  ReportCardTitle,
  FooterContainer,
  ShowMoreDetailContainer,
  ShowMoreDetailText,
  CaretRightIcon,
  Table,
} from "./InsuranceCompanyCard"

const PaymentPeriodCard = (props) => {
  return (
    <Layout>
      <ReportCardTitle>
        {props.dashboardType === "affiliate"
          ? "ยอดขายตามรูปแบบการชำระเงินจากผู้แนะนำ"
          : "ยอดขายตามรูปแบบการชำระเงิน"}
      </ReportCardTitle>
      <Table data={props.data} style={{ marginTop: 27 }}>
        <GridColumn title="รูปแบบการชำระเงิน" field="paymentPeriod" width="160" />
        <GridColumn title="ยอดเบี้ยที่ขายได้" field="salablePremium" width="136" />
        <GridColumn title="ยอดเบี้ยที่ยกเลิก" field="rejectedPremium" width="133" />
        <GridColumn title="ยอดเบี้ยรวม" field="totalPremium" width="106" />
        <GridColumn title="กรมธรรม์ที่ขายได้" field="salablePolicy" width="144" />
        <GridColumn title="กรมธรรม์ที่ยกเลิก" field="rejectedPolicy" width="144" />
        <GridColumn title="กรมธรรม์รวม" field="totalPolicy" width="115" />
      </Table>

      <FooterContainer>
        <ShowMoreDetailContainer
          to={
            props.dashboardType === "affiliate"
              ? path.dashboardAffPaymentPeriodPath()
              : path.dashboardPaymentPeriodPath()
          }
        >
          <ShowMoreDetailText>ดูยอดขายแยกตามรูปแบบการชำระเงิน</ShowMoreDetailText>
          <CaretRightIcon awesomeIcon={"caret-right"} />
        </ShowMoreDetailContainer>
      </FooterContainer>
    </Layout>
  )
}

export default PaymentPeriodCard
