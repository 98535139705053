import React, { useEffect, useState } from "react"
import styled from "@styled"
import { Field, Dropdown, DatePicker, CurrencyInput, Input, Authorize } from "@components"
import { compose, withProps } from "recompose"
import { withStores, withForm, withHooks, withRouter } from "@enhancers/index"
import { safe, exceptNullKeys } from "@common/helper"
import { find } from "lodash"
import * as path from "@common/path"

export const Row = styled.div`
  display: flex;
  margin-top: 12px;

  &:nth-of-type(1) {
    margin-top: 0;
  }

  > div {
    margin-left: 12px;

    &:nth-of-type(1) {
      margin-left: 0;
    }
  }
`

const FilterPanel = (props) => (
  <div className={props.className || "filterPanel"}>
    <Authorize roles={["member.lead_admin"]}>
      <Row>
        <Field
          component={Dropdown}
          name="province"
          caption="จังหวัด"
          captionStyle={{ fontWeight: "normal" }}
          options={props.provinceOptions}
          leftWidth="95"
          rightWidth="190"
          disabled={props.disabled}
          placeholder="กรุณาเลือก"
        />
        <Field
          component={Dropdown}
          name="district"
          caption="อำเภอ"
          captionStyle={{ fontWeight: "normal" }}
          options={props.districtOptions}
          leftWidth="70"
          rightWidth="190"
          disabled={props.disabled}
          placeholder="กรุณาเลือก"
        />
        <Field
          component={Dropdown}
          name="subDistrict"
          caption="ตำบล"
          captionStyle={{ fontWeight: "normal" }}
          options={props.subDistrictOptions}
          leftWidth="55"
          rightWidth="190"
          disabled={props.disabled}
          placeholder="กรุณาเลือก"
        />
      </Row>
      <Row>
        <Field
          component={Input}
          name="fullName"
          caption="ชื่อลูกค้า"
          captionStyle={{ fontWeight: "normal" }}
          leftWidth="95"
          rightWidth="190"
          disabled={props.disabled}
          placeholder="กรุณาระบุ"
        />
        <Field
          component={CurrencyInput}
          name="age.min"
          caption="อายุ"
          captionStyle={{ fontWeight: "normal" }}
          leftWidth="70"
          rightWidth="81"
          disabled={props.disabled}
          placeholder="ระบุ"
          min={0}
          max={99}
        />
        <Field
          component={CurrencyInput}
          name="age.max"
          caption="-"
          captionStyle={{ fontWeight: "normal" }}
          leftWidth="16"
          rightWidth="81"
          placeholder="ระบุ"
          min={0}
          max={99}
        />
        <Field
          component={CurrencyInput}
          name="limit"
          caption="จำนวน"
          captionStyle={{ fontWeight: "normal" }}
          leftWidth="55"
          rightWidth="190"
          disabled={props.disabled}
          placeholder="กรุณาระบุ"
          min={0}
        />
      </Row>
      <Row>
        <Field
          component={Dropdown}
          name="teamId.eq"
          caption="ทีม"
          captionStyle={{ fontWeight: "normal" }}
          options={props.teamIdOptions}
          leftWidth="95"
          rightWidth="190"
          disabled={props.disabled}
          placeholder="กรุณาเลือก"
        />
        <Field
          component={Dropdown}
          name="state.eq"
          caption="สถานะ"
          captionStyle={{ fontWeight: "normal" }}
          options={props.stateOptions}
          leftWidth="70"
          rightWidth="190"
          placeholder="กรุณาเลือก"
          disabled={props.disabled || props.disabledStateFilter}
        />
      </Row>
      <Row>
        <Field
          component={Input}
          name="staffCode"
          caption="รหัสพนักงาน"
          captionStyle={{ fontWeight: "normal" }}
          leftWidth="95"
          rightWidth="190"
          disabled={props.disabled}
          placeholder="กรุณาระบุ"
        />
        <Field
          component={Input}
          name="salesmanName"
          caption="ผู้ดูแลลีด"
          captionStyle={{ fontWeight: "normal" }}
          leftWidth="70"
          rightWidth="190"
          disabled={props.disabled}
          placeholder="กรุณาระบุ"
        />
      </Row>
      <Row>
        <Field
          component={DatePicker}
          name="assignedAt.min"
          caption="วันที่จ่ายลีด"
          captionStyle={{ fontWeight: "normal" }}
          leftWidth="95"
          rightWidth="190"
          disabled={props.disabled}
          placeholder="กรุณาเลือก"
        />
        <Field
          component={DatePicker}
          name="assignedAt.max"
          caption="ถึงวันที่"
          captionStyle={{ fontWeight: "normal" }}
          leftWidth="70"
          rightWidth="190"
          disabled={props.disabled}
          placeholder="กรุณาเลือก"
        />
      </Row>
    </Authorize>

    <Authorize
      roles={[
        "member.team_leader",
        "member.salesman",
        "member.biz_dev",
        "member.admin",
        "member.affiliate",
      ]}
    >
      <Row>
        <Field
          component={Input}
          name="fullName"
          caption="ชื่อลูกค้า"
          captionStyle={{ fontWeight: "normal" }}
          leftWidth="98"
          rightWidth="138"
          disabled={props.disabled}
          placeholder="กรุณาระบุ"
        />
        <Field
          component={Dropdown}
          name="gender.eq"
          caption="เพศ"
          captionStyle={{ fontWeight: "normal" }}
          options={props.genderOptions}
          leftWidth="36"
          rightWidth="147"
          disabled={props.disabled}
          placeholder="กรุณาเลือก"
        />
        <Field
          component={CurrencyInput}
          name="age.min"
          caption="อายุ"
          captionStyle={{ fontWeight: "normal" }}
          leftWidth="39"
          rightWidth="62"
          disabled={props.disabled}
          placeholder="ระบุ"
          min={0}
          max={99}
        />
        <Field
          component={CurrencyInput}
          name="age.max"
          caption="-"
          captionStyle={{ fontWeight: "normal" }}
          leftWidth="17"
          rightWidth="62"
          placeholder="ระบุ"
          min={0}
          max={99}
        />
      </Row>
      <Row>
        <Field
          component={Input}
          name="phoneNumber"
          caption="เบอร์โทรศัพท์"
          captionStyle={{ fontWeight: "normal" }}
          leftWidth="98"
          rightWidth="138"
          disabled={props.disabled}
          placeholder="กรุณาระบุ"
        />
        <Field
          component={Dropdown}
          name="state.eq"
          caption="สถานะ"
          captionStyle={{ fontWeight: "normal" }}
          options={props.stateOptions}
          leftWidth="55"
          rightWidth="138"
          disabled={props.disabled || props.disabledStateFilter}
          placeholder="กรุณาเลือก"
        />
        <Field
          component={CurrencyInput}
          name="limit"
          caption="จำนวน"
          captionStyle={{ fontWeight: "normal" }}
          leftWidth="55"
          rightWidth="89"
          disabled={props.disabled}
          placeholder="กรุณาระบุ"
          min={0}
        />
      </Row>
      {props.batchId === "all" && (
        <Row>
          <Field
            component={DatePicker}
            name="startDate.min"
            caption="วันเริ่มขาย"
            captionStyle={{ fontWeight: "normal" }}
            leftWidth="98"
            rightWidth="138"
            disabled={props.disabled}
            placeholder="กรุณาเลือก"
          />
          <Field
            component={DatePicker}
            name="expiredDate.max"
            caption="วันหมดอายุขาย"
            captionStyle={{ fontWeight: "normal" }}
            leftWidth="122"
            rightWidth="138"
            disabled={props.disabled}
            placeholder="กรุณาเลือก"
          />
        </Row>
      )}
      <Row>
        <Field
          component={Input}
          name="staffCode"
          caption="รหัสพนักงาน"
          captionStyle={{ fontWeight: "normal" }}
          leftWidth="98"
          rightWidth="138"
          disabled={props.disabled}
          placeholder="กรุณาระบุ"
        />
        <Field
          component={Input}
          name="salesmanName"
          caption="ผู้ดูแลลีด"
          captionStyle={{ fontWeight: "normal" }}
          leftWidth="70"
          rightWidth="190"
          disabled={props.disabled}
          placeholder="กรุณาระบุ"
        />
      </Row>
    </Authorize>
  </div>
)

const enhancer = compose(
  withRouter({
    queryTypes: {
      "teamId.eq": "integer",
      "startDate.min": "date",
      "expiredDate.max": "date",
      "assignedAt.min": "date",
      "assignedAt.max": "date",
    },
  }),
  withStores((stores, props) => ({
    stateOptions: stores.leadStore.$("configs.filters.state.options", []),
    genderOptions: stores.leadStore.$("configs.filters.gender.options", []),
    provinceOptions: stores.leadStore.$("configs.filters.address.provinces", []),
    teamIdOptions: stores.leadStore.$("configs.filters.assignee.teams", []),

    batchId: props.match.params.batchId,
    query: props.location.query,

    teams: stores.teamStore.$("all", []),
    currentRole: stores.appStore.currentRole,
    saleId: stores.appStore.$("currentSale.id"),
  })),
  withProps((props) => {
    return {
      initialValues: props.query,
    }
  }),
  withForm({
    form: "LeadFilterForm",
  }),
  withHooks((props) => {
    const [fetchLeadTask, setFetchLeadTask] = useState(null)

    const provinceOptions = props.provinceOptions.filter(({ label }) => label)

    const districtOptions = safe(
      () =>
        find(provinceOptions, {
          value: props.values.province || props.query.province,
        }).districts.filter(({ label }) => label),
      []
    )

    const subDistrictOptions = safe(
      () =>
        find(districtOptions, {
          value: props.values.district || props.query.district,
        }).subDistricts.filter(({ label }) => label),
      []
    )
    // let assigneeIdOptions = safe(
    //   () => find(props.teamIdOptions, { value: props.values.teamId.eq }).salesmen,
    //   []
    // )

    // if (props.currentRole === "member.salesman") {
    //   assigneeIdOptions = assigneeIdOptions.filter(
    //     (assigneeIdOption) => assigneeIdOption.value === props.saleId
    //   )
    // }

    let stateOptions = props.stateOptions

    if (props.mode === "unAssigning") {
      stateOptions = stateOptions.filter(
        (stateOption) => stateOption.value === "assigned" || stateOption.value === "team_assigned"
      )
    }

    const exceptNullFilterParams = exceptNullKeys(
      props.values,
      "age.min",
      "age.max",
      "state.eq",
      "gender.eq",
      "province",
      "district",
      "subDistrict",
      "teamId.eq",
      "startDate.min",
      "expiredDate.max",
      "staffCode",
      "salesmanName",
      "assignedAt.min",
      "assignedAt.max"
    )

    useEffect(() => {
      if (fetchLeadTask) {
        clearTimeout(fetchLeadTask)
      }

      setFetchLeadTask(
        setTimeout(() => {
          if (["assigning", "unAssigning", "unAssigningToTeam", "deleting"].includes(props.mode)) {
            if (props.currentRole === "member.lead_admin") {
              props.history.push(
                path.batchLeadsPath(props.batchId, {
                  ...props.query,
                  ...props.values,
                  page: 1,
                  perPage: 10000,
                  ...exceptNullFilterParams,
                })
              )
            } else {
              props.history.push(
                path.leadsPath(props.batchId, {
                  ...props.query,
                  ...props.values,
                  page: 1,
                  perPage: 10000,
                  ...exceptNullFilterParams,
                })
              )
            }
          } else {
            if (props.currentRole === "member.lead_admin") {
              props.history.push(
                path.batchLeadsPath(props.batchId, {
                  ...props.query,
                  ...props.values,
                  ...exceptNullFilterParams,
                })
              )
            } else {
              props.history.push(
                path.leadsPath(props.batchId, {
                  ...props.query,
                  ...props.values,
                  ...exceptNullFilterParams,
                })
              )
            }
          }
        }, 800)
      )
    }, [JSON.stringify({ ...props.values, ...exceptNullFilterParams })])

    return {
      provinceOptions,
      districtOptions,
      subDistrictOptions,
      // assigneeIdOptions,
      stateOptions,
      initialValues: props.initialValues,
    }
  })
)

export default enhancer(FilterPanel)
