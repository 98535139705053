import React from "react"
import styled from "@emotion/styled"
import { Button } from "@components/index"
import { compose, withHandlers, withProps } from "recompose"
import SelectedProductCompare from "./SelectedCompareProduct"
import { withRouter } from "@enhancers/index"
import * as path from "@common/path"

const Layout = styled.div`
  display: flex;
  height: 100px;
  background-color: #e2e5e9;
  padding: 15px 35px;
  justify-content: center;
  margin: 0px -49px;
  position: fixed;
  bottom: 0;
  z-index: 1000;
  left: 0;
  right: 0;
  align-items: center;
`
const ProductCompareContainer = styled.div`
  display: flex;
  flex-direction: row;
`
const CompareButton = styled(Button)`
  width: 142px;
  height: 38px;
  margin-left: 10px;
  background-color: #0275d8;
  color: #fff;
  :disabled {
    background-color: #ffffff;
  }
`

const CompareFooter = (props) => (
  <Layout>
    <ProductCompareContainer>
      <SelectedProductCompare
        data={props.selectedCompareProduct[0]}
        setDeselectCompareProduct={props.setDeselectCompareProduct}
      />
      <SelectedProductCompare
        data={props.selectedCompareProduct[1]}
        setDeselectCompareProduct={props.setDeselectCompareProduct}
      />
      <SelectedProductCompare
        data={props.selectedCompareProduct[2]}
        setDeselectCompareProduct={props.setDeselectCompareProduct}
      />
    </ProductCompareContainer>
    <CompareButton
      disabled={!props.allowToCompare}
      icon="categorize"
      onClick={props.onCompareButtonClicked}
    >
      เปรียบเทียบ
    </CompareButton>
  </Layout>
)

const enhancer = compose(
  withRouter(),
  withProps((props) => ({
    allowToCompare: props.selectedCompareProduct && props.selectedCompareProduct.length > 1,
  })),
  withHandlers({
    onCompareButtonClicked: (props) => () => {
      const {
        leadId,
        location: { query },
      } = props
      props.history.push(
        path.productsComparePath(leadId, {
          productId1: props.selectedCompareProduct[0] && props.selectedCompareProduct[0].id,
          productId2: props.selectedCompareProduct[1] && props.selectedCompareProduct[1].id,
          productId3: props.selectedCompareProduct[2] && props.selectedCompareProduct[2].id,
          ...query,
        })
      )
    },
  })
)

export default enhancer(CompareFooter)
