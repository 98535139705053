import React from "react"
import { compose, lifecycle, withHandlers, withProps, withState } from "recompose"
import { withRouter, withStores } from "@enhancers/index"
import { Icon, GridColumn } from "@components"
import { exceptNullKeys, formatDate } from "@common/helper"
import {
  Layout,
  TitleText,
  Body,
  Header,
  HeaderColumn,
  CustomTable as Table,
} from "./InsuranceCompany"
import FilterPanel from "./FilterPanel"
import FilterDate from "../index/FilterDate"
import { isEmpty } from "lodash"

const OrdersCommissionDashboard = (props) => {
  return (
    <Layout>
      <TitleText>คอมมิชชันแยกตามคำสั่งซื้อ</TitleText>
      <Body>
        <Header>
          <FilterDate onSubmit={props.onDateFilterSubmit} />
        </Header>
        <Table
          pageable
          data={props.ordersCommission}
          loadingTable={props.isLoading}
          filterIndex={props.filterIndex}
          skip={props.paging.skip}
          take={props.paging.perPage}
          total={props.paging.totalCount}
          onPageChange={props.onPageChange}
        >
          <GridColumn
            title="รหัสคำสั่งซื้อ"
            field="tqmOrderId"
            width="150"
            columnMenu={(columnProps) => (
              <FilterPanel
                {...columnProps}
                onSubmit={props.filterChange}
                options={props.ordersOptions}
                initialValues={props.filterOrderNoColumn}
              />
            )}
          />
          <GridColumn
            title={
              <HeaderColumn
                onClick={props.sortingChange(
                  "net_premium",
                  props.sortNetPremium,
                  props.setSortNetPremium
                )}
                isactive={props.sortNetPremium !== null}
              >
                เบี้ยประกัน
                <div>
                  <Icon awesomeIcon="sort" />
                </div>
              </HeaderColumn>
            }
            field="netPremium"
            width="133"
          />
          <GridColumn
            title={
              <HeaderColumn
                onClick={props.sortingChange(
                  "commission_amount",
                  props.sortCommission,
                  props.setSortCommission
                )}
                isactive={props.sortCommission !== null}
              >
                คอมมิชชัน
                <div>
                  <Icon awesomeIcon="sort" />
                </div>
              </HeaderColumn>
            }
            field="commissionAmount"
            width="133"
          />
          <GridColumn
            title={
              <HeaderColumn
                onClick={props.sortingChange(
                  "commission_received_date",
                  props.sortPayday,
                  props.setSortPayday
                )}
                isactive={props.sortPayday !== null}
              >
                วันที่จ่าย
                <div>
                  <Icon awesomeIcon="sort" />
                </div>
              </HeaderColumn>
            }
            field="commissionReceivedDate"
            width="144"
            cell={(cell) => {
              return <td>{formatDate(cell.dataItem.commissionReceivedDate)}</td>
            }}
          />
          {props.currentRole === "member.team_leader" && (
            <GridColumn
              title="รหัสพนักงาน"
              field="staffCode"
              width="133"
              columnMenu={(columnProps) => (
                <FilterPanel
                  {...columnProps}
                  onSubmit={props.filterChange}
                  options={props.staffCodeOptions}
                  initialValues={props.filterStaffCodeColumn}
                />
              )}
            />
          )}
          {props.currentRole === "member.team_leader" && (
            <GridColumn
              title="ชื่อพนักงาน"
              field="salesmanName"
              width="233"
              columnMenu={(columnProps) => (
                <FilterPanel
                  {...columnProps}
                  onSubmit={props.filterChange}
                  options={props.salesmanOptions}
                  initialValues={props.filterStaffNameColumn}
                />
              )}
            />
          )}
          <GridColumn
            title="ทีม"
            field="teamName"
            width="133"
            columnMenu={(columnProps) => (
              <FilterPanel
                {...columnProps}
                onSubmit={props.filterChange}
                options={props.teamNameOptions}
                initialValues={props.filterTeamColumn}
              />
            )}
          />
        </Table>
      </Body>
    </Layout>
  )
}

const enhancer = compose(
  withRouter(),
  withStores((stores) => ({
    currentRole: stores.appStore.currentRole,

    paging: stores.dashboardStore.paging,
    fetchConfig: stores.dashboardStore.fetchConfig,
    fetchDashboardOrdersCommission: stores.dashboardStore.fetchDashboardOrdersCommission,
    ordersCommission: stores.dashboardStore.ordersCommission,
    ordersOptions: stores.dashboardStore.$("configs.filter.order.options", []),
    staffCodeOptions: stores.dashboardStore.$("configs.filter.staffCode.options", []),
    salesmanOptions: stores.dashboardStore.$("configs.filter.salesman.options", []),
    teamNameOptions: stores.dashboardStore.$("configs.filter.team.options", []),
  })),
  withState("isLoading", "setIsLoading", false),
  withState("sortNetPremium", "setSortNetPremium", null),
  withState("sortCommission", "setSortCommission", null),
  withState("sortPayday", "setSortPayday", null),
  withState("filterIndex", "setFilterIndex", null),
  withState("filterTable", "setFilterTable", null),
  withState("filterOrderNoColumn", "setFilterOrderNoColumn", {}),
  withState("filterStaffCodeColumn", "setFilterStaffCodeColumn", {}),
  withState("filterStaffNameColumn", "setFilterStaffNameColumn", {}),
  withState("filterTeamColumn", "setFilterTeamColumn", {}),
  withState("exportFilter", "setExportFilter", null),
  withHandlers({
    resetFiltering: (props) => () => {
      props.setSortNetPremium(null)
      props.setSortCommission(null)
      props.setSortPayday(null)
      props.setFilterIndex(null)
      props.setFilterTable({})
      props.setFilterOrderNoColumn({})
      props.setFilterStaffCodeColumn({})
      props.setFilterStaffNameColumn({})
      props.setFilterTeamColumn({})
    },
  }),
  withProps((props) => ({
    paging: {
      ...props.paging,
      skip: (props.paging.page - 1) * props.paging.perPage,
    },
    defaultFilterDate: {
      periodType: "yearly",
      submittedAt: {
        min: new Date(new Date().getFullYear(), 0, 1),
        max: new Date(new Date().getFullYear(), 11, 31),
      },
    },
  })),
  withHandlers({
    onPageChange: (props) => async (event) => {
      const page = event.page.skip / event.page.take + 1
      props.setIsLoading(true)
      await props.fetchDashboardOrdersCommission({
        ...props.filterTable,
        page,
        perPage: 10,
      })
      props.setIsLoading(false)
    },
    filterChange: (props) => async (filter) => {
      const filterValues = []

      filter.selectedValues.find((item) => {
        if (item.selected === true) {
          filterValues.push(item.value)
        }
      })

      if (!isEmpty(filterValues)) {
        props.resetFiltering()
      }

      let params = {}

      switch (filter.orderBy) {
        case "tqmOrderId":
          params = {
            orderIds: filterValues,
            orderBy: "tqm_order_id",
            order: filter.order,
          }
          props.setFilterOrderNoColumn(filter)
          props.setFilterIndex(1)
          break
        case "staffCode":
          params = {
            salesmanIds: filterValues,
            orderBy: "staff_code",
            order: filter.order,
          }
          props.setFilterStaffCodeColumn(filter)
          props.setFilterIndex(2)
          break
        case "salesmanName":
          params = {
            salesmanIds: filterValues,
            orderBy: "salesman_name",
            order: filter.order,
          }
          props.setFilterStaffNameColumn(filter)
          props.setFilterIndex(3)
          break
        case "teamName":
          params = {
            teamIds: filterValues,
            orderBy: "team_name",
            order: filter.order,
          }
          props.setFilterTeamColumn(filter)
          props.setFilterIndex(4)
          break
        default:
          break
      }

      const exceptNullParams = exceptNullKeys(params, "orderBy", "order")
      const dateFilter = !isEmpty(props.exportFilter) ? props.exportFilter : props.defaultFilterDate

      props.setFilterTable({ ...exceptNullParams, ...dateFilter })
      props.setIsLoading(true)
      try {
        await props.fetchDashboardOrdersCommission({ ...dateFilter, ...exceptNullParams })
      } catch (error) {}
      props.setIsLoading(false)
    },
    sortingChange: (props) => (column, orderType, setSortValue) => async () => {
      let orderTypeFilter = null
      if (orderType === null) {
        orderTypeFilter = "desc"
      } else if (orderType === "desc") {
        orderTypeFilter = "asc"
      } else {
        orderTypeFilter = null
      }
      const params = {
        orderBy: column,
        order: orderTypeFilter,
      }
      const exceptNullParams = exceptNullKeys(params, "orderBy", "order")
      const dateFilter = !isEmpty(props.exportFilter) ? props.exportFilter : props.defaultFilterDate

      props.setIsLoading(true)
      try {
        if (orderTypeFilter !== null) {
          await props.fetchDashboardOrdersCommission({ ...dateFilter, ...exceptNullParams })
        } else {
          await props.fetchDashboardOrdersCommission(dateFilter)
        }
      } catch (error) {}

      props.resetFiltering()
      setSortValue(orderTypeFilter)
      props.setIsLoading(false)
    },
    onDateFilterSubmit: (props) => async (values) => {
      props.setIsLoading(true)
      try {
        await props.fetchDashboardOrdersCommission(values)
      } catch (error) {}
      props.setIsLoading(false)

      props.setExportFilter(values)
      props.resetFiltering()
    },
  }),
  lifecycle({
    async componentDidMount() {
      this.props.setIsLoading(true)
      await Promise.all([this.props.fetchDashboardOrdersCommission(), this.props.fetchConfig()])
      this.props.setIsLoading(false)
    },
  })
)

export default enhancer(OrdersCommissionDashboard)
