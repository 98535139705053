import React from "react"
import { Switch, Redirect } from "react-router-dom"
import { compose, lifecycle, withHandlers, withProps, withState } from "recompose"
import { withRouter, withStores, acceptProps } from "@enhancers"
import * as path from "@common/path"
import { BreadCrumb, PrivateRoute } from "@components"
import { Menu, MenuItem } from "@pages/leads/show/index"
import HolderInfo from "./HolderInfo/index"
import DocumentInfo from "./DocumentInfo/index"
import ActionCodeInfo from "./ActionCodeInfo/index"
import CommissionInfo from "./CommissionInfo/index"
import styled from "@common/styled"
import Loading from "@pages/index/Loading"

const Layout = styled.div`
  width: 100%;
  justify-content: center;
`
const Body = styled.div`
  margin-top: 70px;
  margin-bottom: 50px;
`

const OrderShow = (props) => (
  <Layout>
    <BreadCrumb links={props.breadcrumbLinks} />

    {!props.checkRole(props.currentRole) && (
      <Menu>
        {props.menuItems.map((menuItem, index) => (
          <MenuItem
            to={menuItem.to}
            key={index}
            disabled={menuItem.disabled}
            hidden={menuItem.hidden}
          >
            {menuItem.label}
          </MenuItem>
        ))}
      </Menu>
    )}

    {props.loading ? (
      <Loading />
    ) : (
      <Body>
        <Switch>
          <PrivateRoute
            path={path.orderHolderInfoPath(":orderId")}
            component={HolderInfo}
            exact
            authorize={{
              roles: [
                "user.admin",
                "member.lead_admin",
                "member.biz_dev",
                "member.team_leader",
                "member.salesman",
                "member.accountant",
                "member.accountant_approver",
                "member.affiliate",
              ],
            }}
          />
          <PrivateRoute
            path={path.orderDocumentInfoPath(":orderId")}
            component={DocumentInfo}
            exact
            authorize={{
              roles: [
                "user.admin",
                "member.lead_admin",
                "member.biz_dev",
                "member.team_leader",
                "member.salesman",
              ],
            }}
          />
          <PrivateRoute
            path={path.orderActionCodeInfoPath(":orderId")}
            component={ActionCodeInfo}
            exact
            authorize={{
              roles: [
                "user.admin",
                "member.lead_admin",
                "member.biz_dev",
                "member.team_leader",
                "member.salesman",
              ],
            }}
          />
          {/* <PrivateRoute
            path={path.orderCommissionInfoPath(":orderId")}
            component={CommissionInfo}
            exact
          /> */}
          <Redirect
            from={path.orderPath(":orderId")}
            to={{
              pathname: path.orderHolderInfoPath(":orderId"),
            }}
            exact
          />
        </Switch>
      </Body>
    )}
  </Layout>
)

const enhancer = compose(
  withRouter(),
  withState("loading", "setLoading", true),
  withStores((stores, props) => ({
    fetchOrder: stores.orderStore.fetchOrder,
    fetchOrderDocumentsByOrderId: stores.orderStore.fetchOrderDocumentsByOrderId,
    fetchOrderConfigs: stores.orderStore.fetchOrderConfigs,
    orderId: props.match.params.orderId,
    orderState: stores.orderStore.$("current.state"),
    currentRole: stores.appStore.currentRole,
    canAffiliateSubmit: stores.orderStore.$("current.canAffiliateSubmit"),
    affiliateReadOnly: stores.orderStore.$("current.affiliateReadOnly"),
  })),
  withProps((props) => {
    let breadcrumbLinks = []
    if (
      props.currentRole === "member.accountant" ||
      props.currentRole === "member.accountant_approver"
    ) {
      breadcrumbLinks.push({
        label: "คอมมิชชันรับ",
        path: path.insurerCommissionsPath(),
      })
      breadcrumbLinks.push({
        label: "ข้อมูลลูกค้า",
        path: path.orderPath(props.orderId),
      })
    } else {
      breadcrumbLinks.push({
        label: "คำสั่งซื้อ",
        path: path.ordersPath(),
      })
      breadcrumbLinks.push({
        label: "รายละเอียดคำสั่งซื้อ",
        path: path.orderPath(props.orderId),
      })
    }

    return {
      breadcrumbLinks: breadcrumbLinks,
    }
  }),
  withProps((props) => {
    const { state } = props.location || {}
    const { paymentStatus } = state || {}

    return {
      unconfirmed: !props.orderState || ["new", "affiliate_new"].includes(props.orderState),
      completedOrder: props.orderState === "completed" && paymentStatus === "completed",
      commissionAuthorize: ["member.team_leader", "member.salesman"].includes(props.currentRole),
      paymentStatus: paymentStatus,
    }
  }),
  withProps((props) => {
    const menuItems = [
      {
        to: {
          pathname: path.orderHolderInfoPath(props.orderId),
          state: {
            paymentStatus: props.paymentStatus,
            orderState: props.orderState === "salesman_rejected" ? "rejected" : props.orderState,
          },
        },
        disabled: [""].includes(props.currentRole) || props.loading,
        label: !["new", "affiliate_new"].includes(props.orderState)
          ? "ข้อมูล"
          : props.currentRole === "member.affiliate" && props.canAffiliateSubmit
          ? "กรอกข้อมูลผู้เอาประกัน"
          : "ข้อมูล",
      },
      {
        to: {
          pathname: path.orderDocumentInfoPath(props.orderId),
          state: {
            paymentStatus: props.paymentStatus,
            orderState: props.orderState === "salesman_rejected" ? "rejected" : props.orderState,
          },
        },
        disabled: (props.unconfirmed || props.loading ||
          props.currentRole === "member.affiliate" ||
          (props.currentRole === "member.salesman" && props.affiliateReadOnly)
        ),
        label: "แนบเอกสาร",
      },
      {
        to: {
          pathname: path.orderActionCodeInfoPath(props.orderId),
          state: {
            paymentStatus: props.paymentStatus,
            orderState: props.orderState === "salesman_rejected" ? "rejected" : props.orderState,
          },
        },
        disabled: (props.unconfirmed || props.loading ||
          props.currentRole === "member.affiliate" ||
          (props.currentRole === "member.salesman" && props.affiliateReadOnly)
        ),
        label: "Action Code",
      },
      // {
      //   to: {
      //     pathname: path.orderCommissionInfoPath(props.orderId),
      //     state: {
      //       paymentStatus: props.paymentStatus,
      //     },
      //   },
      //   disabled: !props.completedOrder || props.loading,
      //   hidden: !props.commissionAuthorize,
      //   label: "คอมมิชชั่น",
      // },
    ]

    return {
      menuItems,
    }
  }),
  withHandlers({
    checkRole: () => (role) => {
      if (role === "member.accountant" || role === "member.accountant_approver") {
        return true
      } else return false
    },
  }),
  lifecycle({
    async componentDidMount() {
      try {
        await Promise.all([
          this.props.fetchOrder(this.props.orderId),
          this.props.fetchOrderDocumentsByOrderId(this.props.orderId),
          this.props.fetchOrderConfigs(),
        ])
        this.props.setLoading(false)
      } catch (e) {
        if (e.response.status === 404) {
          alert(e.response.data.message)
          this.props.history.push(path.ordersPath())
        }
      }
    },
  }),
  acceptProps("menuItems", "breadcrumbLinks", "loading", "currentRole", "checkRole")
)

export default enhancer(OrderShow)
