import React, { Fragment, useEffect, useMemo } from "react"
import styled from "@emotion/styled"
import { css } from "@styled"
import { withStores, withRouter, withForm, withHooks } from "@enhancers"
import {
  Grid,
  GridColumn,
  makeCell,
  LoadingScope,
  Button,
  DateInput,
  Dropdown,
  BreadCrumb,
  Header as TextHeader,
  Checkbox,
} from "@components"
import { compose, withHandlers, withState, lifecycle, withProps } from "recompose"
import * as path from "@common/path"
import defaultLogo from "@assets/images/img-no-insurance.png"
import { isEqual } from "lodash"
import SuccessModal from "./VerifySuccessModal"
import { toCurrency } from "@common/helper"
// import { validate } from "../../index/index"
import { FieldArray } from "redux-form"
import SalesmanExcepted from "@pages/sale_commissions/index/SalesmanExcepted"

import ViewAsMember from "./ViewAs/ViewAsMember"
import ViewAsOrder from "./ViewAs/ViewAsOrder"

const Layout = styled.div`
  margin: 0px auto 60px auto;

  transform: translate(calc((314px - (100vw - 678px)) / 2));
  width: calc(100vw - 250px);

  @media only screen and (max-width: 1024px) {
    width: 100%;
    transform: none;
  }
`
const BreadCrumbLayout = styled.div`
  width: 100%;
  margin-bottom: 30px;
`
const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 12px 0;
  margin-top: 20px;
  justify-content: space-between;
`
const ViewSection = styled.div`
  background-color: white;
  min-width: 678px;
  margin-bottom: 12px;
  border: 1px solid #eeeef2;
  border-radius: 4px;
  padding: 24px 19px;
`
const SalesmanExceptContainer = styled.div`
  background-color: white;
  min-width: 678px;
  margin-bottom: 24px;
  border: 1px solid #eeeef2;
  border-radius: 4px;
  opacity: 1;
`

const Table = styled(Grid)`
  .k-grid-header {
    .k-header {
      font-weight: bold;
    }
  }

  .k-grid-container {
    .k-grid-content.k-virtual-content {
      > div {
        .k-grid-table {
          > tbody {
            .k-master-row {
              > td {
                cursor: pointer;
              }
            }
            .wrap-text {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
`
const LeftPanel = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-start;
  > div {
    display: block;
    width: 225px;
    font-family: "ThaiSans Neue";
    font-size: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
const Title = styled(TextHeader)`
  font-family: "ThaiSans Neue";
  font-size: 30px;
  color: #666666;
  display: flex;
  align-items: center;
`
const CustomTd = styled.td`
  position: sticky;
  left: 0;
  border-right-width: 1px;
  background-color: #ffffff;

  > div {
    > div {
      justify-content: center;
    }
  }
`
const Logo = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 5px;
  vertical-align: middle;
`
const BackButton = styled(Button)`
  height: 38px;
  color: #627b8b;
  border-color: #627b8b;
`
const ComfirmVerifyingCommissions = styled(Button)`
  min-width: 191px;
  height: 38px;
  margin-left: 6px;
`
const ButtonContainer = styled.div`
  display: flex;
`
const ViewContainer = styled.div`
  display: flex;

  > span {
    max-width: 120px;
    margin-left: 0;
  }

  > input {
    flex: 1;
    margin-top: 0px !important;
    margin-left: 10px;
    font-family: Sarabun, sans-serif;
    font-size: 16px;
  }
`
const ViewText = styled.div`
  align-self: center;
  margin-right: 13px;
`

const ViewTab = styled(Button)`
  width: 100px;
  height: 38px;
  opacity: 1 !important;
  color: #0275d8;
  background: #ffffff;
  border: 1px solid #0275d8;

  &:first-of-type {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  &:nth-of-type(2) {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  :active,
  :hover {
    color: white;
    border: 1px solid #0275d8;
    background-color: #0275d8;
  }

  ${(props) =>
    props.isactive &&
    css`
      pointer-events: none;
      border: 1px solid #0275d8;
      color: white;
      background-color: #0275d8;
    `}
`

const CommissionVerification = (props) => (
  <Fragment>
    {props.loading ? (
      <LoadingScope />
    ) : (
      <Layout>
        <BreadCrumbLayout>
          <BreadCrumb links={props.links} />
        </BreadCrumbLayout>

        <Header>
          <LeftPanel>
            <Title>คอมมิชชั่นจ่าย</Title>
          </LeftPanel>

          <ButtonContainer>
            <BackButton
              awesomeIcon="chevron-left"
              onClick={props.back}
              children="ย้อนกลับ"
              type="disbled"
            />
            <ComfirmVerifyingCommissions
              awesomeIcon="check"
              onClick={props.verifyCommissions}
              children="ยืนยันการตรวจสอบ"
            />
          </ButtonContainer>
        </Header>

        {/* <ViewSection>
          <ViewContainer>
            <ViewText>มุมมองตาราง</ViewText>
            <ViewTab
              onClick={() =>
                props.history.push(path.saleCommissionVerification({ viewType: "orders" }))
              }
              isactive={
                props.location.query.viewType === "orders" ||
                props.location.query.viewType !== "salesman"
                  ? "true"
                  : undefined
              }
            >
              คำสั่งซื้อ
            </ViewTab>
            <ViewTab
              onClick={() =>
                props.history.push(path.saleCommissionVerification({ viewType: "salesman" }))
              }
              isactive={props.location.query.viewType === "salesman" ? "true" : undefined}
            >
              พนักงาน
            </ViewTab>
          </ViewContainer>
        </ViewSection> */}

        {props.location.query.viewType === "orders" ? (
          <>
            <SalesmanExceptContainer>
              <FieldArray
                name="ignoreCommissionIdOrder"
                component={SalesmanExcepted}
                options={props.commissionIdOptions}
                isOrder={true}
              />
            </SalesmanExceptContainer>

            <ViewAsOrder verification={true} data={props.saleCommissions} {...props} />
          </>
        ) : (
          <>
            <SalesmanExceptContainer>
              <FieldArray
                name="ignoreCommissionIdMember"
                component={SalesmanExcepted}
                options={props.commissionIdOptions}
                isOrder={false}
              />
            </SalesmanExceptContainer>

            <ViewAsMember verification={true} data={props.saleCommissions} {...props} />
          </>
        )}
      </Layout>
    )}
    <SuccessModal
      open={props.openSuccessModal}
      onClose={props.closeSuccessModal}
      verificationAmount={props.verificationAmount}
    />
  </Fragment>
)

const enhance = compose(
  withRouter(),
  withState("loading", "setLoading", false),
  withState("loadingTable", "setLoadingTable", false),
  withState("isShowModal", "setIsShowModal", false),
  withState("selectedCommissionIds", "setSelectedCommissionIds", []),
  withState("openSuccessModal", "setOpenSuccessModal", false),
  withState("verificationAmount", "setVerificationAmount", 0),
  withStores((stores, props) => ({
    fetch: stores.saleCommissionStore.fetch,
    fetchSalesCommissionOrders: stores.saleCommissionStore.fetchSalesCommissionOrders,
    fetchConfig: stores.saleCommissionStore.fetchConfig,
    fetchConfigOrders: stores.saleCommissionStore.fetchSalesCommissionOrdersConfig,
    saleCommissions: stores.saleCommissionStore.all,
    // paging: stores.saleCommissionStore.paging,
    stateOptions: stores.saleCommissionStore.$("configs.attributes.state.options", []),
    paymentStatusOptions: stores.saleCommissionStore.$(
      "configs.attributes.paymentStatus.options",
      []
    ),
    importSaleCommission: stores.saleCommissionStore.import,
    salesCommissionVerify: stores.saleCommissionStore.salesCommissionVerify,
    currentRole: stores.appStore.currentRole,
  })),
  withProps((props) => {
    let saleCommissions = props.saleCommissions
    saleCommissions.forEach(
      (commission) => (commission.selected = props.selectedCommissionIds.includes(commission.id))
    )

    return {
      saleCommissions,
    }
  }),
  withState("errors", "setErrors", null),
  withState("fetchSaleCommissionsTask", "setFetchSaleCommissionsTask", null),
  withHandlers({
    back: (props) => () => {
      props.history.push(path.saleCommissionsPath())
    },
    closeSuccessModal: (props) => () => {
      props.setOpenSuccessModal(!props.openSuccessModal)
      props.history.push(path.saleCommissionsPath())
    },
    verifyCommissions: (props) => async () => {
      try {
        await props.salesCommissionVerify({
          salesCommissionMasterIds: props.selectedCommissionIds,
        })
        props.setVerificationAmount(props.selectedCommissionIds.length)
        props.setOpenSuccessModal(!props.openSuccessModal)
      } catch (e) {
        window.alert("เกิดข้อผิดพลาดบางอย่าง")
      }
      // const saleCommissions = await props.fetch({
      //   perPage: 10000,
      //   // state: "verifying",
      // })
      // const saleCommissionIds = saleCommissions.map((commission) => commission.id)
      // props.setSelectedCommissionIds(saleCommissionIds)
    },
    onSelectAll: (props) => (selected) => {
      if (selected) {
        props.setSelectedCommissionIds(props.saleCommissions.map((commission) => commission.id))
      } else {
        props.setSelectedCommissionIds([])
      }
    },
    onSelectRow: (props) => (value, dataItem) => {
      if (value) {
        props.setSelectedCommissionIds([...props.selectedCommissionIds, dataItem.id])
      } else {
        props.setSelectedCommissionIds(
          props.selectedCommissionIds.filter((id) => id !== dataItem.id)
        )
      }
    },
  }),
  withForm({
    form: "SaleCommissionVerificationForm",
  }),
  withState("loadingImport", "setLoadingImport", false),
  withState("filterExpanded", "setFilterExpanded", false),
  withHandlers({
    onExpandFilter: (props) => (expanded) => {
      props.setFilterExpanded(expanded)
    },
    onCloseAndOpenModal: (props) => () => {
      props.setIsShowModal(!props.isShowModal)
    },
  }),
  withProps((props) => {
    const data = props.saleCommissions
    const isSelectedAll = data.length > 0 ? data.every((row) => row.selected) : false

    return {
      isSelectedAll,
    }
  }),
  withProps((props) => {
    const { ignoreCommissionIdOrder, ignoreCommissionIdMember } = props.values || {}
    const ignoreCommissionIdArray =
      props.location.query.viewType === "orders"
        ? ignoreCommissionIdOrder || []
        : ignoreCommissionIdMember || []
    let commissionIdOptions = []
    if (props.location.query.viewType === "orders") {
      commissionIdOptions = props.saleCommissions.map((commission) => {
        return { label: commission.tqmOrderId, value: commission.tqmOrderId }
      })
    } else {
      commissionIdOptions = props.saleCommissions.map((commission) => {
        return { label: commission.fullName, value: commission.fullName }
      })
    }

    let filterSaleCommissions = []

    if (props.location.query.viewType === "orders") {
      filterSaleCommissions = props.saleCommissions.filter(
        (commission) => !ignoreCommissionIdArray.includes(commission.tqmOrderId)
      )
    } else {
      filterSaleCommissions = props.saleCommissions.filter(
        (commission) => !ignoreCommissionIdArray.includes(commission.fullName)
      )
    }

    const uniqueCommissionIdOptions = Array.from(
      new Set(commissionIdOptions.map((option) => option.label))
    ).map((label) => {
      return commissionIdOptions.find((option) => option.label === label)
    })

    return {
      commissionIdOptions: uniqueCommissionIdOptions,
      saleCommissions: filterSaleCommissions,
    }
  }),
  withProps((props) => {
    let links = []

    links = [
      {
        label: "คอมมิชชั่นจ่าย",
        path: path.saleCommissionsPath(props.location.query),
      },
      {
        label: "ยืนยันรายการตรวจสอบ",
        path: path.saleCommissionVerification(props.location.query),
      },
    ]

    return {
      links,
    }
  }),
  lifecycle({
    // async componentDidMount() {
    //   this.props.setLoading(true)
    //   await this.props.fetchConfig()
    //   const saleCommissions = await this.props.fetch({
    //     perPage: 10000,
    //     viewType: this.props.location.query.viewType,
    //   })
    //   const saleCommissionIds = saleCommissions.map((commission) => commission.fullName)
    //   this.props.setSelectedCommissionIds(saleCommissionIds)
    //   this.props.setLoading(false)
    // },
    async componentDidUpdate(prevProps) {
      if (!isEqual(this.props.saleCommissions, prevProps.saleCommissions)) {
        const saleCommissionIds = this.props.saleCommissions.map((commission) => commission.id)
        this.props.setSelectedCommissionIds(saleCommissionIds)
      }
    },
  }),
  withHooks((props) => {
    useEffect(() => {
      if (props.location.query.viewType) {
        props.setLoading(true)
        if (props.location.query.viewType === "salesman") {
          props.fetchConfig()
          props.fetch({
            perPage: 10000,
            state: "new",
          })
        } else {
          props.fetchConfigOrders()
          props.fetchSalesCommissionOrders({
            perPage: 10000,
            state: "new",
          })
        }
        const saleCommissionIds = props.saleCommissions.map((commission) => commission.id)
        props.setSelectedCommissionIds(saleCommissionIds)
        props.setLoading(false)
      }
    }, [props.location.query.viewType])

    // const saleCommissionIds = useMemo(
    //   () => props.saleCommissions.map((commission) => commission.id),
    //   [props.location.query.viewType]
    // )

    // props.setSelectedCommissionIds(saleCommissionIds)

    return {}
  })
)

export default enhance(CommissionVerification)
