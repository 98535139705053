import React, { Fragment } from "react"
import styled from "@emotion/styled"
import { compose, withProps, withHandlers } from "recompose"
import { Icon, Button, Avatar } from "@components/index"
import { withRouter } from "@enhancers/index"
import * as path from "@common/path"
import defaultLogo from "@assets/images/img-no-insurance.png"

const Layout = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
`
const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const Caption = styled.span`
  margin-top: 15px;
  font-size: 10px;
  color: #0275d8;
  margin-bottom: 5px;
`
const Line = styled.div`
  border-bottom: 1px solid #0275d8;
`
const Detail = styled.span`
  margin-top: 5px;
  font-size: 16px;
  color: #666666;
`
const CompanyLogo = styled(Avatar)`
  display: flex;
  align-self: center;
  margin: 30px 0px 20px 0px;
`
const StarIcon = styled(Icon)`
  font-size: 15px;
  margin-top: 5px;
  color: #0275d8;
`
const IconCheckCircle = styled(Icon)`
  color: #5cb85c;
  margin-top: 5px;
`
const IconTimesCircle = styled(Icon)`
  color: #d9534f;
  margin-top: 5px;
`
const CustomButton = styled(Button)`
  display: flex;
  align-self: center;
  margin: 20px 0px 10px 0px;
`
const renderStar = (noonStar) => {
  const arrayStar = []
  for (var i = 1; i <= noonStar; i++) {
    arrayStar.push(<StarIcon key={i} awesomeIcon={["fas", "star"]} />)
  }
  return arrayStar
}

const displayNumber = (value) => {
  let nums = ("" + value).split(".")
  const onlyNums = nums[0].replace(/[^-\d]/g, "")
  let rgx = /(\d+)(\d{3})/
  let x1 = onlyNums
  while (rgx.test(x1)) {
    // eslint-disable-next-line no-useless-concat
    x1 = x1.replace(rgx, "$1" + "," + "$2")
  }
  if (nums.length > 1) x1 = x1 + "." + nums[1]
  return x1
}

const DetailComponent = ({ caption, renderDetail, ...rest }) => (
  <DetailContainer>
    <Caption>{caption}</Caption>
    <Line />
    {renderDetail(rest)}
  </DetailContainer>
)

const ProductDetail = (props) =>
  props.policy ? (
    <Layout>
      <CompanyLogo src={props.policy.companyLogoUrl || defaultLogo} size={100} square />
      <DetailComponent
        caption="ชื่อแบบประกัน"
        detail={props.policyName}
        renderDetail={props.renderDetail}
      />
      <DetailComponent
        caption="เรตติ้ง"
        noonStar={props.noonStar}
        renderDetail={props.renderDetail}
      />
      <DetailComponent
        caption="ชนิดแบบประกัน"
        insuranceType={props.insuranceType}
        renderDetail={props.renderDetail}
      />
      <DetailComponent
        caption="จำนวนเงินเอาประกัน"
        amount={displayNumber(props.sumInsured)}
        renderDetail={props.renderDetail}
      />
      <DetailComponent
        caption="เบี้ยประกันรายปี"
        amount={displayNumber(props.premium)}
        renderDetail={props.renderDetail}
      />
      <DetailComponent
        caption="ระยะเวลาชำระเบี้ย"
        payPeriod={props.payPeriod}
        renderDetail={props.renderDetail}
      />
      <DetailComponent
        caption="ระยะเวลาความคุ้มครอง"
        detail="ตามรายละเอียดในกรมธรรม์"
        renderDetail={props.renderDetail}
      />
      <DetailComponent
        caption="เงินคืนระหว่างสัญญา"
        checkedIcon="ตามรายละเอียดในกรมธรรม์"
        renderDetail={props.renderDetail}
      />
      <DetailComponent
        caption="เงินคืนเมื่อครบสัญญา"
        checkedIcon={props.policyReturnRate}
        renderDetail={props.renderDetail}
      />
      <DetailComponent
        caption="เงินปันผล"
        checkedIcon={props.hasDividend}
        renderDetail={props.renderDetail}
      />
      <DetailComponent
        caption="สิทธิในการลดหย่อนภาษี"
        checkedIcon={props.isTaxSaving}
        renderDetail={props.renderDetail}
      />
      <DetailComponent
        caption="อัตราผลตอบแทนภายในทั่วไป-IRR"
        detail={props.irr}
        renderDetail={props.renderDetail}
      />

      <CustomButton
        type="button"
        onClick={props.onBuyButtonClicked}
        icon="cart"
        readOnly={props.readOnly}
      >
        คำนวณเบี้ย
      </CustomButton>
    </Layout>
  ) : (
    <Fragment />
  )

const enhancer = compose(
  withRouter(),
  withProps((props) => {
    const { policy } = props.data || {}
    const {
      name,
      noonStar,
      insuranceType,
      premium,
      sumInsured,
      protectionPeriodText,
      policyReturnRate,
      hasDividend,
      isTaxSaving,
      irr,
    } = policy || {}
    const { protectPeriodAgeForCard } = protectionPeriodText || {}
    return {
      policy: policy,
      policyName: name,
      noonStar: noonStar,
      insuranceType: insuranceType,
      sumInsured: sumInsured,
      premium: premium,
      payPeriod: protectPeriodAgeForCard,
      policyReturnRate: policyReturnRate ? true : false,
      hasDividend: hasDividend ? true : false,
      isTaxSaving: isTaxSaving ? true : false,
      irr: irr,
    }
  }),
  withHandlers({
    onBuyButtonClicked: (props) => () => {
      const { id } = props.policy || {}
      const { age, gender, type, sumInsured, leadId, birthdate, from } = props.location.query
      props.history.push(
        path.productCustomEditPath(id, {
          leadId,
          age,
          gender,
          type,
          sumInsured,
          birthdate,
          from,
        })
      )
    },
    renderDetail: (props) => (values) => {
      if (values.noonStar) {
        return renderStar(values.noonStar)
      }

      if (values.insuranceType) {
        switch (values.insuranceType) {
          case "whole_life":
            return <Detail>แบบตลอดชีพ</Detail>
          case "saving":
            return <Detail>แบบออมทรัพย์</Detail>
          case "term":
            return <Detail>แบบช่วงระยะเวลา</Detail>
          case "annuity":
            return <Detail>แบบบำนาญ</Detail>
          default:
            return <Detail>แบบแพ็กเกจ</Detail>
        }
      }

      if (values.amount) {
        return <Detail>{`${values.amount} บาท`}</Detail>
      }

      if (values.payPeriod) {
        return <Detail>{`ถึงอายุ ${values.payPeriod} ปี`}</Detail>
      }

      if (values.detail) {
        return <Detail>{values.detail}</Detail>
      }

      if (values.checkedIcon) {
        return <IconCheckCircle awesomeIcon={["fas", "check-circle"]} />
      } else {
        return <IconTimesCircle awesomeIcon={["fas", "times-circle"]} />
      }
    },
  })
)

export default enhancer(ProductDetail)
