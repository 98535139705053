import BaseStore from "./BaseStore"
import { tqldServer } from "@common/api"

class marketPlanStore extends BaseStore {
  constructor() {
    super({
      all: [],
      configs: {},
      current: {},
      paging: {
        page: 1,
        perPage: 10,
        totalCount: 0,
        totalPages: 1,
      },
    })
  }

  fetch = async (params) => {
    const response = await tqldServer.tqmBackend.get(`market_plans`, params)
    this.all = response.data.data.marketPlans
    this.paging = response.data.data.paging
  }

  fetchConfigs = async () => {
    const response = await tqldServer.tqmBackend.get(`market_plans/configs`)
    this.configs = response.data.data.configs
  }

  create = async (params) => {
    await tqldServer.tqmBackend.post(`market_plans`, params)
  }

  fetchById = async (id) => {
    const response = await tqldServer.tqmBackend.get(`market_plans/${id}`)
    this.current = response.data.data.marketPlan
  }

  update = async (id, params) => {
    await tqldServer.tqmBackend.put(`market_plans/${id}`, params)
  }

  delete = async (id) => {
    await tqldServer.tqmBackend.delete(`market_plans/${id}`)
  }
}

export default new marketPlanStore()
