import React, { useEffect } from "react"
import { compose, withProps, withState, withHandlers, defaultProps } from "recompose"
import styled from "@emotion/styled"
import * as path from "@common/path"
import {
  BreadCrumb,
  Button,
  Field,
  Input,
  DatePicker,
  Dropdown,
  TabStrip,
  TabStripTab,
  Icon,
} from "@components/index"
import { LeftPanel } from "@pages/leads/show/LeadInfo"
import { withForm, withStores, withRouter, withHooks } from "@enhancers/index"
import { FormSection } from "redux-form"
import CommissionTabDetail from "./CommissionTabDetail"
import SaleCommissionTabDetail from "./SaleCommissionTabDetail"
import { isEqual } from "lodash"
import Spinner from "react-spinkit"

export const MainLayout = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const BreadCrumbLayout = styled.div`
  width: 100%;
`
export const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
  line-height: normal;
  margin-top: 30px;
`
export const RightPanel = styled.div`
  display: flex;
  align-items: center;
`
export const BackButton = styled(Button)`
  width: 156px;
`
export const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
`
export const Container = styled.div`
  padding: 30px 40px 39px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #eeeef2;
  background-color: #ffffff;
`
export const ContainerInfo = styled(Container)`
  padding: 30px 40px 20px 40px;
`
const TabDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 7px;
`
export const Row = styled.div`
  display: flex;
  width: 598px;
  margin-bottom: 5px;
  align-items: center;
  &:nth-of-type(1) {
    margin-top: 0;
  }

  > div {
    margin-left: 12px;

    &:nth-of-type(1) {
      margin-left: 0;
    }
  }
`
export const CustomInput = styled(Input)`
  > div:nth-of-type(2) {
    > div {
      padding-top: 0px !important;
    }
  }
`
export const DetailButton = styled.span`
  display: flex;
  width: 100%;
  font-size: 16px;
  color: #025aa5;
  text-decoration: underline;
  align-items: center;
  justify-content: flex-end;
  font-family: Sarabun, sans-serif;
  cursor: pointer;
`
const CommissionTab = styled(TabStrip)`
  width: 100%;
  max-width: 599px;
  min-width: 599px;
  display: flex;

  > ul {
    > li.k-state-active {
      display: flex;
      width: calc(50% - 3px);
      justify-content: center;
      align-items: center;
      background-color: #eeeef2 !important;
      color: #666666 !important;
      font-weight: bold;
      text-decoration: none !important;

      > span.k-link {
        display: contents;
      }
    }
    li.k-state-default {
      display: flex;
      width: calc(50% - 3px);
      justify-content: center;
      align-items: center;
      background-color: #fff !important;
      color: #666666 !important;
      text-decoration: none !important;

      > span.k-link {
        display: contents;
      }
    }
  }

  > ul {
    justify-content: space-between;
    > li.k-item {
      background-color: red;
      max-height: 38px;
      margin-right: 0px;
    }
    > li.k-state-default {
      border: 1px solid #eeeef2;
      border-radius: 4px 4px 0px 0px;
    }
  }
  .k-content {
    border-radius: unset;
  }
  > div {
    background-color: #eeeef2 !important;
    position: relative;
    padding: 0px !important;
  }
`
export const TabHeader = styled.div`
  display: flex;
  width: 100%;
  background-color: #294558;
  height: 40px;
  padding: 0px 16px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  align-items: center;
  justify-content: space-between;
`
export const TabHeaderCaption = styled.span`
  font-size: 16px;
  font-weight: bold;
  font-family: Sarabun, sans-serif;
  color: #fff;
`
const IconContainer = styled.div`
  display: ${(props) => props.display};
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
const PencilIcon = styled(Icon)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0275d8;
`
const CheckIcon = styled(Icon)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0275d8;
`
const CustomDatePicker = styled(DatePicker)`
  width: fit-content;
  .k-picker-wrap {
    max-width: 138px !important;
  }
`
const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 52px;
  margin-bottom: 5px;
`
const DateRow = styled(Row)`
  margin-bottom: 33px;
  > div {
    margin-left: 12px;

    &:nth-of-type(1) {
      margin-left: 0;

      > div:nth-of-type(2) {
        max-width: 138px !important;
      }
    }
  }
`
const CustomSpinner = styled(Spinner)`
  width: 16px;
  height: 16px;
`

export const InsurerCommissionDetail = (props) => (
  <MainLayout>
    <BreadCrumbLayout>
      <BreadCrumb links={props.links} />
    </BreadCrumbLayout>

    <Header>
      <LeftPanel>คอมมิชชั่นรับ</LeftPanel>
      <RightPanel>
        <BackButton icon="arrow-chevron-left" onClick={props.onBack} children="ย้อนกลับ" />
      </RightPanel>
    </Header>

    <TabContainer>
      <TabHeader>
        <TabHeaderCaption>ข้อมูลคำสั่งซื้อ</TabHeaderCaption>
      </TabHeader>
      <ContainerInfo>
        <Row>
          <Field
            name="tqmOrderId"
            caption="รหัสคำสั่งซื้อ"
            component={CustomInput}
            readOnly={props.defaultReadOnly}
            leftWidth="108"
          />
          <Field
            name="policyName"
            caption="แบบประกัน"
            component={CustomInput}
            readOnly={props.defaultReadOnly}
            leftWidth="102"
          />
        </Row>
        <Row>
          <Field
            name="fullName"
            caption="ชื่อลูกค้า"
            component={CustomInput}
            readOnly={props.defaultReadOnly}
            leftWidth="108"
          />
          <Field
            name="insuranceCompanyName"
            caption="บริษัทประกัน"
            component={CustomInput}
            readOnly={props.defaultReadOnly}
            hidden={props.hidden}
            leftWidth="102"
          />
          {props.hidden && (
            <DetailButton onClick={props.onDisplayDetail}>ดูรายละเอียด</DetailButton>
          )}
        </Row>
        <Row>
          <Field
            name="submittedAt"
            caption="วันที่แจ้งงาน"
            component={DatePicker}
            readOnly={props.defaultReadOnly}
            hidden={props.hidden}
            leftWidth="108"
          />
          <Field
            name="coverageDate"
            caption="วันที่คุ้มครอง"
            component={DatePicker}
            readOnly={props.defaultReadOnly}
            hidden={props.hidden}
            leftWidth="102"
          />
        </Row>
        <Row>
          <Field
            name="state"
            caption="สถานะ"
            component={CustomInput}
            readOnly={props.defaultReadOnly}
            hidden={props.hidden}
            leftWidth="108"
          />
          {!props.hidden && (
            <DetailButton onClick={props.onDisplayDetail}>ซ่อนรายละเอียด</DetailButton>
          )}
        </Row>
      </ContainerInfo>

      <TabHeader>
        <TabHeaderCaption>ข้อมูลคอมมิชชั่น</TabHeaderCaption>
        <TabHeaderCaption>ประกันใหม่</TabHeaderCaption>
      </TabHeader>

      <Container>
        <Row>
          <Field
            name="paymentSequence"
            caption="ปี/งวด"
            component={Dropdown}
            options={props.paymentSequenceOptions}
            leftWidth="138"
            onChange={(value) => {
              if (value) {
                props.history.push(path.insurerCommissionPath(value))
              }
            }}
          />
          <Field
            name="receivedDate"
            caption="วันที่รับเงิน"
            component={DatePicker}
            readOnly={props.defaultReadOnly}
            leftWidth="162"
          />
        </Row>
        <Row>
          <Field
            name="paymentVia"
            caption="ชำระเงินผ่าน"
            component={CustomInput}
            readOnly={props.defaultReadOnly}
            leftWidth="138"
          />
          <Field
            name="paymentMethod"
            caption="ประเภทการชำระเงิน"
            component={Dropdown}
            options={props.paymentMethodOptions}
            readOnly={props.defaultReadOnly}
            leftWidth="162"
          />
        </Row>
        <Row>
          <Field
            name="staffCode"
            caption="รหัสพนักงานขาย"
            component={CustomInput}
            readOnly={props.defaultReadOnly}
            leftWidth="138"
          />
        </Row>
        <DateContainer>
          <DateRow>
            <Field
              name="estimatedIncomeExportedDate"
              caption="วันนำออกประมาณการรายได้"
              component={CustomDatePicker}
              readOnly={!props.toggleEstimatedIncomeExportedDate}
              leftWidth="314"
            />
            <IconContainer
              onClick={props.editEstimatedIncomeExportedDate}
              display={props.editingEstimatedIncomeExportedDateIconDisplaying}
            >
              <PencilIcon icon={"pencil"} />
            </IconContainer>
            {props.readOnly && (
              <IconContainer
                onClick={props.handleSubmit(props.comfirmEditEstimatedIncomeExportedDate)}
                display={props.comfirmEstimatedIncomeExportedDateEditingIconDisplaying}
              >
                {props.loading ? <CustomSpinner name="circle" /> : <CheckIcon icon={"check"} />}
              </IconContainer>
            )}
          </DateRow>
          <DateRow>
            <Field
              name="receivedIncomeExportedDate"
              caption="วันนำออกรายได้รับ"
              component={CustomDatePicker}
              readOnly={!props.toggleReceivedIncomeExportedDate}
              leftWidth="314"
            />
            <IconContainer
              onClick={props.editReceivedIncomeExportedDate}
              display={props.editingReceivedIncomeExportedDateIconDisplaying}
            >
              <PencilIcon icon={"pencil"} />
            </IconContainer>
            {props.readOnly && (
              <IconContainer
                onClick={props.handleSubmit(props.comfirmEditReceivedIncomeExportedDate)}
                display={props.comfirmReceivedIncomeExportedDateEditingIconDisplaying}
              >
                {props.loading ? <CustomSpinner name="circle" /> : <CheckIcon icon={"check"} />}
              </IconContainer>
            )}
          </DateRow>
          <DateRow>
            <Field
              name="remainingIncomeExportedDate"
              caption="วันนำออกรายได้ค้างรับ"
              component={CustomDatePicker}
              readOnly={!props.toggleRemainingIncomeExportedDate}
              leftWidth="314"
            />
            <IconContainer
              onClick={props.editRemainingIncomeExportedDate}
              display={props.editingRemainingIncomeExportedDateIconDisplaying}
            >
              <PencilIcon icon={"pencil"} />
            </IconContainer>
            {props.readOnly && (
              <IconContainer
                onClick={props.handleSubmit(props.comfirmEditRemainingIncomeExportedDate)}
                display={props.comfirmRemainingIncomeExportedEditingIconDisplaying}
              >
                {props.loading ? <CustomSpinner name="circle" /> : <CheckIcon icon={"check"} />}
              </IconContainer>
            )}
          </DateRow>
        </DateContainer>

        <TabDetailContainer>
          <CommissionTab
            selected={props.tabSelected}
            onSelect={props.onSelect}
            keepTabsMounted={true}
          >
            <TabStripTab title="รายได้รับ">
              <FormSection name="insurerCommissionDetail">
                <CommissionTabDetail
                  name="insurerCommissionDetail"
                  {...props}
                  {...props.initialValues.insurerCommissionDetail}
                  setInsurerCommissionReadOnly={props.toggleReadOnly}
                />
              </FormSection>
            </TabStripTab>
            <TabStripTab title="คอมมิชชั่นจ่าย">
              <FormSection name="salesCommissionMaster">
                <SaleCommissionTabDetail
                  name="salesCommissionMaster"
                  initialValues={props.initialValues.salesCommissionMaster}
                  {...props.initialValues.salesCommissionMaster}
                />
              </FormSection>
            </TabStripTab>
          </CommissionTab>
        </TabDetailContainer>
      </Container>
    </TabContainer>
  </MainLayout>
)

const enhance = compose(
  withRouter(),
  withStores((stores, props) => {
    const current = stores.insurerCommissionStore.$("current")
    const stateOptions = stores.insurerCommissionStore.$("configs.attributes.state.options", [])
    const paymentSequenceOptions = stores.insurerCommissionStore.$(
      "detailConfigs.attributes.installment.options",
      []
    )
    const paymentMethodOptions = stores.insurerCommissionStore.$(
      "detailConfigs.attributes.paymentMethod.options",
      []
    )
    let insurerCommissionState = stores.insurerCommissionStore.$("current.state", {})

    const state = stateOptions.find((stateOption) =>
      isEqual(insurerCommissionState, stateOption.value)
    )

    let insurerCommissionDetail = {
      state: (state && state.label) || "",
      totalYearlyPremium: stores.insurerCommissionStore.$("current.totalYearlyPremium"),
      totalPremium: stores.insurerCommissionStore.$("current.totalPremium"),
      interestAmount: stores.insurerCommissionStore.$("current.interestAmount"),
      totalCommission: stores.insurerCommissionStore.$("current.totalCommission"),
      totalServiceCharge: stores.insurerCommissionStore.$("current.totalServiceCharge"),
      realTotalPremium: stores.insurerCommissionStore.$("current.realTotalPremium"),
      realTotalServiceCharge: stores.insurerCommissionStore.$("current.realTotalServiceCharge"),
      realTotalCommission: stores.insurerCommissionStore.$("current.realTotalCommission"),
      lifeProduct: stores.insurerCommissionStore.$("current.lifeProduct"),
      riderProducts: stores.insurerCommissionStore.$("current.riderProducts"),
    }

    let salesCommissionMaster = {
      salesCommissionPercent: stores.insurerCommissionStore.$(
        "current.salesCommissionMaster.commissionPercent"
      ),
      salesCommissionAmount: stores.insurerCommissionStore.$(
        "current.salesCommissionMaster.commissionAmount"
      ),
      salesCommissionDetails: stores.insurerCommissionStore.$(
        "current.salesCommissionMaster.salesCommissionDetails"
      ),
      salesCommissionAffiliateTeam: stores.insurerCommissionStore.$(
        "current.salesCommissionMaster.salesCommissionAffiliateTeam"
      ),
      totalSalesCommission: stores.insurerCommissionStore.$(
        "current.salesCommissionMaster.totalSalesCommission"
      ),
      commissionReceivedDate: stores.insurerCommissionStore.$(
        "current.salesCommissionMaster.commissionReceivedDate"
      ),
      teamName: stores.insurerCommissionStore.$("current.salesCommissionMaster.teamName"),
    }

    const newProps = {}
    newProps.initialValues = {
      ...current,
      state: (state && state.label) || "",
      paymentSequence: stores.insurerCommissionStore.$("current.id"),
      estimatedIncomeExportedDate: stores.insurerCommissionStore.$(
        "current.estimatedIncomeExportedDate"
      ),
      receivedIncomeExportedDate: stores.insurerCommissionStore.$(
        "current.receivedIncomeExportedDate"
      ),
      remainingIncomeExportedDate: stores.insurerCommissionStore.$(
        "current.remainingIncomeExportedDate"
      ),
      insurerCommissionDetail: insurerCommissionDetail,
      salesCommissionMaster: salesCommissionMaster,
    }

    return {
      fetchById: stores.insurerCommissionStore.fetchById,
      fetchConfigs: stores.insurerCommissionStore.fetchConfigs,
      fetchConfigsById: stores.insurerCommissionStore.fetchConfigsById,
      update: stores.insurerCommissionStore.update,
      paymentSequenceOptions: paymentSequenceOptions,
      paymentMethodOptions: paymentMethodOptions,
      initialValues: newProps.initialValues,
      id: stores.insurerCommissionStore.$("current.id"),
      insurerCommissionState: stores.insurerCommissionStore.$("current.state", {}),
    }
  }),
  withForm({
    form: "InsuranceCommissionDetail",
  }),
  withProps((props) => {
    const { match } = props || {}
    const { params } = match || {}
    const { insurerCommissionId } = params

    return {
      insurerCommissionId,
    }
  }),
  withState("tabSelected", "setTabSelected", 0),
  withState("readOnly", "setReadOnly", true),
  withState("toggleEstimatedIncomeExportedDate", "setToggleEstimatedIncomeExportedDate", false),
  withState("toggleReceivedIncomeExportedDate", "setToggleReceivedIncomeExportedDate", false),
  withState("toggleRemainingIncomeExportedDate", "setToggleRemainingIncomeExportedDate", false),
  withState("loading", "setLoading", false),
  withState("hidden", "setHidden", true),
  defaultProps({ defaultReadOnly: true }),
  withHandlers({
    onSelect: (props) => (e) => props.setTabSelected(e.selected),
    onDisplayDetail: (props) => () => {
      props.setHidden(!props.hidden)
    },
    onBack: (props) => () => {
      props.history.push(path.insurerCommissionsPath())
    },
    toggleReadOnly: (props) => (toggleValue) => {
      if (toggleValue) {
        props.reset()
      }
      props.setReadOnly(toggleValue)
      props.setToggleEstimatedIncomeExportedDate(!toggleValue)
      props.setToggleReceivedIncomeExportedDate(!toggleValue)
      props.setToggleRemainingIncomeExportedDate(!toggleValue)
    },
    editEstimatedIncomeExportedDate: (props) => () => {
      props.setToggleEstimatedIncomeExportedDate(!props.toggleEstimatedIncomeExportedDate)
    },
    editReceivedIncomeExportedDate: (props) => () => {
      props.setToggleReceivedIncomeExportedDate(!props.toggleReceivedIncomeExportedDate)
    },
    editRemainingIncomeExportedDate: (props) => () => {
      props.setToggleRemainingIncomeExportedDate(!props.toggleRemainingIncomeExportedDate)
    },
    comfirmEditEstimatedIncomeExportedDate: (props) => async (values) => {
      // update
      props.setLoading(true)
      try {
        const { interestAmount, lifeProduct, riderProducts, estimatedIncomeExportedDate } =
          values || {}
        const { id, commissionAmount, serviceCharge } = lifeProduct || {}
        const newProps = {}
        const riders = []
        newProps.interestAmount = interestAmount
        newProps.estimatedIncomeExportedDate = estimatedIncomeExportedDate
        newProps.lifeProduct = {
          id,
          commissionAmount,
          serviceCharge,
        }
        riderProducts.forEach((riderProduct) => {
          riders.push({
            id: riderProduct.id,
            commissionAmount: riderProduct.commissionAmount,
            serviceCharge: riderProduct.serviceCharge,
          })
        })
        newProps.riderProducts = riders
        await props.update(props.id, newProps)
        props.setToggleEstimatedIncomeExportedDate(!props.toggleEstimatedIncomeExportedDate)
        props.setLoading(false)
      } catch (e) {
        window.alert("ไม่สามารถแก้ไขข้อมูลได้")
        props.setToggleEstimatedIncomeExportedDate(!props.toggleEstimatedIncomeExportedDate)
        props.setLoading(false)
      }
    },
    comfirmEditReceivedIncomeExportedDate: (props) => async (values) => {
      // update
      try {
        const { interestAmount, lifeProduct, riderProducts, receivedIncomeExportedDate } =
          values || {}
        const { id, commissionAmount, serviceCharge } = lifeProduct || {}

        const newProps = {}
        const riders = []

        newProps.interestAmount = interestAmount
        newProps.receivedIncomeExportedDate = receivedIncomeExportedDate

        newProps.lifeProduct = {
          id,
          commissionAmount,
          serviceCharge,
        }

        riderProducts.forEach((riderProduct) => {
          riders.push({
            id: riderProduct.id,
            commissionAmount: riderProduct.commissionAmount,
            serviceCharge: riderProduct.serviceCharge,
          })
        })

        newProps.riderProducts = riders
        await props.update(props.id, newProps)
        props.setToggleReceivedIncomeExportedDate(!props.toggleReceivedIncomeExportedDate)
      } catch (e) {
        window.alert("ไม่สามารถแก้ไขข้อมูลได้")
        props.setToggleEstimatedIncomeExportedDate(!props.toggleEstimatedIncomeExportedDate)
      }
    },
    comfirmEditRemainingIncomeExportedDate: (props) => async (values) => {
      // update
      try {
        const { interestAmount, lifeProduct, riderProducts, remainingIncomeExportedDate } =
          values || {}
        const { id, commissionAmount, serviceCharge } = lifeProduct || {}

        const newProps = {}
        const riders = []

        newProps.interestAmount = interestAmount
        newProps.remainingIncomeExportedDate = remainingIncomeExportedDate

        newProps.lifeProduct = {
          id,
          commissionAmount,
          serviceCharge,
        }

        riderProducts.forEach((riderProduct) => {
          riders.push({
            id: riderProduct.id,
            commissionAmount: riderProduct.commissionAmount,
            serviceCharge: riderProduct.serviceCharge,
          })
        })

        newProps.riderProducts = riders
        await props.update(props.id, newProps)
        props.setToggleRemainingIncomeExportedDate(!props.toggleRemainingIncomeExportedDate)
      } catch (e) {
        window.alert("ไม่สามารถแก้ไขข้อมูลได้")
        props.setToggleEstimatedIncomeExportedDate(!props.toggleEstimatedIncomeExportedDate)
      }
    },
  }),
  withProps((props) => {
    let links = []

    links = [
      { label: "คอมมิชชั่นรับ", path: path.insurerCommissionsPath() },
      { label: "รายละเอียดคอมมิชชั่น" },
    ]

    return { links }
  }),
  withProps((props) => {
    const editingEstimatedIncomeExportedDateIconDisplaying =
      (props.insurerCommissionState === "real_commission_waiting" ||
        props.insurerCommissionState === "real_commission_received") &&
      !props.toggleEstimatedIncomeExportedDate
        ? "flex"
        : "none"
    const comfirmEstimatedIncomeExportedDateEditingIconDisplaying =
      (props.insurerCommissionState === "real_commission_waiting" ||
        props.insurerCommissionState === "real_commission_received") &&
      props.toggleEstimatedIncomeExportedDate
        ? "flex"
        : "none"

    const editingReceivedIncomeExportedDateIconDisplaying =
      (props.insurerCommissionState === "real_commission_waiting" ||
        props.insurerCommissionState === "real_commission_received") &&
      !props.toggleReceivedIncomeExportedDate
        ? "flex"
        : "none"
    const comfirmReceivedIncomeExportedDateEditingIconDisplaying =
      (props.insurerCommissionState === "real_commission_waiting" ||
        props.insurerCommissionState === "real_commission_received") &&
      props.toggleReceivedIncomeExportedDate
        ? "flex"
        : "none"

    const editingRemainingIncomeExportedDateIconDisplaying =
      (props.insurerCommissionState === "real_commission_waiting" ||
        props.insurerCommissionState === "real_commission_received") &&
      !props.toggleRemainingIncomeExportedDate
        ? "flex"
        : "none"
    const comfirmRemainingIncomeExportedEditingIconDisplaying =
      (props.insurerCommissionState === "real_commission_waiting" ||
        props.insurerCommissionState === "real_commission_received") &&
      props.toggleRemainingIncomeExportedDate
        ? "flex"
        : "none"
    return {
      editingEstimatedIncomeExportedDateIconDisplaying,
      comfirmEstimatedIncomeExportedDateEditingIconDisplaying,
      editingReceivedIncomeExportedDateIconDisplaying,
      comfirmReceivedIncomeExportedDateEditingIconDisplaying,
      editingRemainingIncomeExportedDateIconDisplaying,
      comfirmRemainingIncomeExportedEditingIconDisplaying,
    }
  }),
  withHooks((props) => {
    useEffect(() => {
      props.fetchConfigs()
    }, [])

    useEffect(() => {
      props.fetchById(props.insurerCommissionId)
      props.fetchConfigsById(props.insurerCommissionId)
    }, [props.insurerCommissionId])
  })
)

export default enhance(InsurerCommissionDetail)
