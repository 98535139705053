import React from "react"
import styled from "@emotion/styled"
import { Dialog } from "@components"
import { compose, withHandlers } from "recompose"

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  width: 100%;
  padding: 20px 10px;
`
const Message = styled.span`
  font-size: 16px;
`
const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`
const SubmitButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  background-color: #007bff;
  color: white;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  cursor: pointer;
`

const VerifiedFailOrSuccessModal = (props) => (
  <Dialog title={props.title} onClose={props.onClose} isShowModal={props.open}>
    <Layout>
      <InfoContainer>{props.info && <Message> {props.info} </Message>}</InfoContainer>
      {typeof props.message === "string" ? (
        <Message> {props.message} </Message>
      ) : (
        <MessageContainer>
          {props.message.map((error, index) => (
            <Message key={index}> {`- ${error}`} </Message>
          ))}
        </MessageContainer>
      )}
    </Layout>
    <SubmitButton onClick={props.onClose}>ยืนยัน</SubmitButton>
  </Dialog>
)

const enhancer = compose(
  withHandlers({
    onClose: (props) => () => {
      props.onClose()
    },
  })
)

export default enhancer(VerifiedFailOrSuccessModal)
