import { compose, lifecycle, withHandlers, withProps, withState } from "recompose"
import { withStores, withForm, withRouter } from "@enhancers"
import * as path from "@common/path"
import { convertNaNtoZero, isClient } from "@common/helper"
import { SubmissionError } from "redux-form"
import { get, find, isEqual, isNaN } from "lodash"
import validate from "@pages/packages/new/validate"
import moment from "moment"
import { SummaryPage } from "./SummaryPage"

export const enhancer = compose(
  withRouter({
    queryTypes: {
      birthdate: "date",
      gender: "string",
      sumInsured: "float",
      premium: "float",
      type: "string",
      searchBy: "string",
      searchValue: "float",
      leadId: "integer",
      from: "string",
      occupationLevel: "integer",
      maxAgeInsured: "integer",
      occupation: "string",
      occupationCategory: "string",
      opportunityKey: "string",
    },
  }),
  withState("toggleSummaryModal", "setToggleSummaryModal", false),
  withState("orderIdResponse", "setOrderId", null),
  withState("loading", "setLoading", false),
  withState("paymentPeriodConfigs", "setPaymentPeriodConfigs", []),
  withState("occupationLevelConfigs", "setOccupationLevelConfigs", []),
  withState("isDraft", "setDraft", false),
  withState("openSaveDraftModal", "setOpenSaveDraftModal", false),
  withState("haveRider", "setHaveRider", false),
  withState("isEditPremium", "setToggleEditPremium", false),
  withState("orderResponse", "setOrderResponse", {}),
  withStores((stores) => {
    return {
      getDetail: stores.productStore.getDetail,
      insuranceCoverageTable: stores.productStore.portDetailTable,
      productDetail: stores.productStore.portDetail,
      updateDraftProductOrder: stores.orderStore.updateDraftProductOrder,
      addOrder: stores.orderStore.addOrder,
      riderPolicies: stores.productStore.riderPolicies,
      createLead: stores.leadStore.createLead,
      fetchLead: stores.leadStore.fetchById,
      paymentPeriodConfigs: stores.productStore.$("configs.attributes.paymentPeriod", []),
      occupationLevelConfigs: stores.productStore.$("configs.attributes.occupationLevel", []),
      deleteOrder: stores.orderStore.deleteOrder,
      fetchOrder: stores.orderStore.fetchOrder,
      orderState: stores.orderStore.$("current.state"),
      salesmanDetail: stores.orderStore.$("current.salesman"),
      affiliateDetail: stores.orderStore.$("current.affiliate"),
      memberType: stores.appStore.$("currentUser.memberInfo.memberType"),
      currentUserName: stores.appStore.$("currentUser.nameThInfo"),
    }
  }),
  withForm({
    form: "buyProduct",
    validate,
    destroyOnUnmount: false,
  }),
  withProps((props) => {
    const searchData = props.location.query
    const { productId } = props.match.params
    const age = searchData.birthdate ? moment().diff(moment(searchData.birthdate), "years") : null

    const premium =
      searchData.searchBy === "premium" ? searchData.searchValue : searchData.premium || 0
    const sumInsured =
      searchData.searchBy === "sum_insured" ? searchData.searchValue : searchData.sumInsured || 0

    let totalPremiumRider = 0
    let totalSumInsuredRider = 0
    props.values.riders &&
      props.values.riders.forEach((item) => {
        if (item.selected) {
          totalPremiumRider += isNaN(item.premium)
            ? convertNaNtoZero(item.premium)
            : parseFloat(item.premium)
          totalSumInsuredRider += isNaN(item.sumInsured)
            ? convertNaNtoZero(item.sumInsured)
            : parseFloat(item.premium)
          props.setHaveRider(true)
        }
      })
    const totalPremium = parseFloat(premium) + parseFloat(totalPremiumRider)

    return {
      leadId: searchData.leadId,
      paramsEncode: searchData.paramsEncode,
      titleButton: "สนใจซื้อ",
      titleSaveDraftButton: "บันทึกแบบ",
      iconButton: "cart",
      params: {
        ...searchData,
        age,
        isDraft: false,
        productId,
      },
      totalPremium,
      totalPremiumRider,
      totalSumInsuredRider,
      productDetail: {
        ...props.productDetail,
        insuranceCoverageTable: props.insuranceCoverageTable && props.insuranceCoverageTable,
        premium,
        sumInsured,
      },
    }
  }),
  withState("calculatePremiumTask", "setCalculatePremiumTask", null),
  withState("calculatePremiumLoading", "setCalculatePremiumLoading", false),
  withState("calculatePremiumErrorMessage", "setCalculatePremiumErrorMessage", null),
  withHandlers({
    onClose: (props) => () => {
      props.setToggleSummaryModal(false)
    },
    onCancel: (props) => () => {
      if (props.params.leadId && props.params.orderId) {
        // props.history.push(path.ordersPath())
        props.history.push(path.productCustomEditPath(props.params.productId, props.location.query))
      } else {
        props.history.push(path.productCustomEditPath(props.params.productId, props.location.query))
      }
    },
    onDelete: (props) => async () => {
      try {
        await props.deleteOrder(props.params.orderId)
        props.history.push(path.ordersPath())
      } catch (e) {
        alert("ลบแบบร่างไม่สำเร็จ")
      }
    },
    onFormSubmit: (props) =>
      props.handleSubmit(async (values) => {
        const age = props.birthdate ? moment().diff(moment(props.birthdate), "years") : null
        if (props.leadId) {
          let error = { riders: [] }
          let checkError = false
          for (let index = 0; index < props.values.riders.length; index++) {
            if (
              !props.values.riders[index].isFree &&
              props.values.riders[index].selected &&
              props.values.riders[index].hasSumInsured &&
              [null, undefined, ""].includes(props.values.riders[index].sumInsured)
            ) {
              error.riders.push({ sumInsured: "no value" })
              checkError = true
            } else {
              error.riders.push({})
            }
          }
          if (checkError) {
            throw new SubmissionError(error)
          }
          const riders = []
          props.values.riders.map((rider) => {
            if (rider.selected) {
              if (rider.hasSumInsured) {
                riders.push({
                  riderPolicyId: rider.id,
                  riderSumInsured: parseFloat(rider.sumInsured || 0),
                  riderPremium: rider.premium || 0,
                })
              } else {
                riders.push({ riderPolicyId: rider.id, riderPremium: rider.premium || 0 })
              }
            }
            return null
          })
          const newProps = {}
          newProps.policyId = props.params.productId
          newProps.policySumInsured = props.values.sumInsured
          newProps.insuranceHolderType = "self"
          newProps.leadId = props.params.leadId
          newProps.gender = props.params.gender
          newProps.age = age
          newProps.searchValue = props.values.sumInsured
          newProps.searchBy = props.values.searchBy
          newProps.paymentPeriod = props.params.paymentPeriod
          newProps.birthdate = moment(props.params.birthdate, "DD/MM/YYYY").toDate()
          newProps.occupation = props.params.occupation
          newProps.occupationCategory = props.params.occupationCategory
          newProps.policyPremium = props.params.premium
          newProps.opportunityKey = props.params.opportunityKey
          if (riders.length > 0) {
            newProps.riders = riders
          }
          newProps.isDraft = false

          try {
            const leadInfo = await props.fetchLead(props.params.leadId)
            const nameInfo = { ...get(leadInfo, "customerInfo.nameInfo", {}) }
            const customerInfo = { ...get(leadInfo, "customerInfo", {}) }
            if (["น.ส."].includes(nameInfo.prefixName)) {
              nameInfo.prefixName = "นางสาว"
            }
            newProps.nameInfo = nameInfo
            if (customerInfo.phoneNumber) {
              newProps.phoneNumber = customerInfo.phoneNumber
            }

            if (props.params.orderId) {
              newProps.isConfirmed = true
              try {
                const orderResponse = await props.updateDraftProductOrder(
                  props.params.orderId,
                  newProps
                )
                props.setOrderId(orderResponse.order.id)
                props.setToggleSummaryModal(true)
              } catch (e) {
                alert("สรุปแบบประกันไม่สำเร็จ")
              }
            } else {
              try {
                const orderResponse = await props.addOrder(newProps)
                props.setOrderResponse(orderResponse.order)
                props.setOrderId(orderResponse.order.id)
                props.setToggleSummaryModal(true)
              } catch (e) {
                if (e.message === "Submit Validation Failed" && e.errors.base) {
                  alert(e.errors.base)
                } else {
                  alert("สร้าง Order ไม่สำเร็จ")
                }
              }
            }
          } catch (e) {
            const error = get(e, "errors.base", null)
            if (error) {
              window.alert(error)
            } else {
              window.alert("สร้าง Order ไม่สำเร็จ")
            }
            if (e.name === "SubmissionError") {
              throw e
            }
          }
        } else {
          props.history.push(
            path.productCustomCreateLeadPath(props.params.productId, {
              ...props.location.query,
              isDraft: false,
              maxAgeInsured: props.productDetail.maxAgeInsured,
            })
          )
        }
      }),
  }),
  withHandlers({
    handleSaveDraft: (props) =>
      props.handleSubmit(async (values) => {
        const age = props.values.birthdate ? moment().diff(props.values.birthdate, "years") : null

        if (props.leadId) {
          let error = { riders: [] }
          let checkError = false
          for (let index = 0; index < props.values.riders.length; index++) {
            if (
              !props.values.riders[index].isFree &&
              props.values.riders[index].selected &&
              props.values.riders[index].hasSumInsured &&
              [null, undefined, ""].includes(props.values.riders[index].sumInsured)
            ) {
              error.riders.push({ sumInsured: "no value" })
              checkError = true
            } else {
              error.riders.push({})
            }
          }
          if (checkError) {
            throw new SubmissionError(error)
          }
          const riders = []
          props.values.riders.map((rider) => {
            if (rider.selected) {
              if (rider.hasSumInsured) {
                riders.push({
                  riderPolicyId: rider.id,
                  riderSumInsured: parseFloat(rider.sumInsured || 0),
                  riderPremium: rider.premium || 0,
                })
              } else {
                riders.push({ riderPolicyId: rider.id, riderPremium: rider.premium || 0 })
              }
            }
            return null
          })
          const newProps = {}
          newProps.policyId = props.params.productId
          newProps.policySumInsured = props.values.sumInsured
          newProps.insuranceHolderType = "self"
          newProps.leadId = props.params.leadId
          newProps.gender = props.params.gender
          newProps.age = age
          newProps.searchValue = props.values.sumInsured
          newProps.searchBy = props.values.searchBy
          newProps.paymentPeriod = props.params.paymentPeriod
          newProps.birthdate = moment(props.params.birthdate, "DD/MM/YYYY").toDate()
          newProps.policyPremium = props.params.premium
          newProps.opportunityKey = props.params.opportunityKey
          if (riders.length > 0) {
            newProps.riders = riders
          }
          newProps.occupation = props.params.occupation
          newProps.occupationCategory = props.params.occupationCategory
          newProps.isDraft = true
          try {
            const leadInfo = await props.fetchLead(props.params.leadId)
            const nameInfo = { ...get(leadInfo, "customerInfo.nameInfo", {}) }
            const customerInfo = { ...get(leadInfo, "customerInfo", {}) }
            if (["น.ส."].includes(nameInfo.prefixName)) {
              nameInfo.prefixName = "นางสาว"
            }
            newProps.nameInfo = nameInfo
            if (customerInfo.phoneNumber) {
              newProps.phoneNumber = customerInfo.phoneNumber
            }

            if (props.params.orderId) {
              newProps.isConfirmed = false
              try {
                await props.updateDraftProductOrder(props.params.orderId, newProps)
              } catch (e) {
                alert("สร้าง Order Save Draft ไม่สำเร็จ")
              }
            } else {
              try {
                await props.addOrder(newProps)
              } catch (e) {
                alert("สร้าง Order ไม่สำเร็จ")
              }
            }
            props.setOpenSaveDraftModal(true)
          } catch (e) {
            const error = get(e, "errors.base", null)
            if (error) {
              window.alert(error)
            } else {
              window.alert("สร้าง Order Save Draft ไม่สำเร็จ")
            }
            if (e.name === "SubmissionError") {
              throw e
            }
          }
        } else {
          props.setDraft(true)
          props.history.push(
            path.productCustomCreateLeadPath(props.params.productId, {
              ...props.location.query,
              isDraft: true,
              maxAgeInsured: props.productDetail.maxAgeInsured,
            })
          )
        }
      }),
  }),
  lifecycle({
    async componentWillMount() {
      this.props.setLoading(true)

      const { params } = this.props

      if (this.props.params.orderId) {
        await this.props.fetchOrder(this.props.params.orderId)
      }

      const response = await this.props.getDetail({
        id: params.productId,
        birthdate: params.birthdate,
        gender: params.gender,
        searchBy: params.searchBy,
        searchValue: params.searchValue,
        premium: params.premium,
        paymentPeriod: params.paymentPeriod,
      })

      const newProps = {}
      newProps.riders = params.riders
        ? params.riders.map((rider) => {
            const selectedRider = find(response.riderPolicies, (r) => {
              return isEqual(`${r.id}`, rider.id)
            })

            return { ...selectedRider, ...rider, selected: true }
          })
        : []

      newProps.sumInsured =
        params.searchBy === "sum_insured" ? params.searchValue : response.policy.sumInsured
      newProps.premium =
        params.searchBy === "premium" ? params.searchValue : response.policy.premium
      newProps.birthdate = params.birthdate
      newProps.policyId = params.productId
      newProps.searchBy = params.searchBy
      this.props.initialize(newProps)
      this.props.setLoading(false)
      this.props.setDraft(false)
    },
    componentDidMount() {
      if (isClient) {
        window.scrollTo(0, 0)
      }
    },
  }),
  withProps((props) => {
    const { params = {} } = props
    let links = []
    switch (params.from) {
      case "custom_lead":
        links = [
          { label: "ลูกค้าใหม่", path: path.leadsPath("custom") },
          {
            label: "รายละเอียดลีด",
            path: path.leadOrderInfoPath(params.leadId),
          },
          {
            label: "ค้นหาแบบประกัน",
            path: path.productsSearchPath(params.leadId, {
              leadId: params.leadId,
              gender: params.gender,
              type: params.type,
              birthdate: params.birthdate,
              from: params.from,
              searchValue: params.searchValue,
              searchBy: params.searchBy,
              opportunityKey: params.opportunityKey,
            }),
          },
          {
            label: "เลือกแบบประกัน",
            path: path.productsPath(params.leadId, {
              gender: params.gender,
              type: params.type,
              leadId: params.leadId,
              birthdate: params.birthdate,
              from: params.from,
              searchValue: params.searchValue,
              searchBy: params.searchBy,
              opportunityKey: params.opportunityKey,
            }),
          },
          {
            label: "คำนวณเบี้ย",
            path: path.productCustomEditPath(params.productId, params),
          },
          {
            label: "สรุปแบบประกัน",
          },
        ]
        break
      case "batch_lead":
        links = [
          { label: "ลีดทีคิวเอ็ม", path: path.leadsPath("all") },
          {
            label: "รายละเอียดลีด",
            path: path.leadOrderInfoPath(params.leadId),
          },
          {
            label: "ค้นหาแบบประกัน",
            path: path.productsSearchPath(params.leadId, {
              leadId: params.leadId,
              gender: params.gender,
              type: params.type,
              birthdate: params.birthdate,
              from: params.from,
              searchValue: params.searchValue,
              searchBy: params.searchBy,
              opportunityKey: params.opportunityKey,
            }),
          },
          {
            label: "เลือกแบบประกัน",
            path: path.productsPath(params.leadId, {
              gender: params.gender,
              type: params.type,
              leadId: params.leadId,
              birthdate: params.birthdate,
              from: params.from,
              searchValue: params.searchValue,
              searchBy: params.searchBy,
              opportunityKey: params.opportunityKey,
            }),
          },
          {
            label: "คำนวณเบี้ย",
            path: path.productCustomEditPath(params.productId, params),
          },
          {
            label: "สรุปแบบประกัน",
          },
        ]
        break
      case "order":
        links = [
          {
            label: "คำสั่งซื้อ",
            path: path.ordersPath(),
          },
          {
            label: "สรุปแบบประกัน",
          },
        ]
        break
      default:
        links = [
          {
            label: "ผลิตภัณฑ์",
            path: "/",
          },
          {
            label: "ค้นหาแบบประกัน",
            path: path.productsSearchPath({
              gender: params.gender,
              type: params.type,
              birthdate: params.birthdate,
              searchValue: params.searchValue,
              searchBy: params.searchBy,
              opportunityKey: params.opportunityKey,
            }),
          },
          {
            label: "เลือกแบบประกัน",
            path: path.productsPath({
              gender: params.gender,
              type: params.type,
              birthdate: params.birthdate,
              from: params.from,
              searchValue: params.searchValue,
              searchBy: params.searchBy,
              opportunityKey: params.opportunityKey,
            }),
          },
          {
            label: "คำนวณเบี้ย",
            path: path.productCustomEditPath(params.productId, params),
          },
          {
            label: "สรุปแบบประกัน",
          },
        ]
        break
    }
    return { links }
  })
)

export default enhancer(SummaryPage)
