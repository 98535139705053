import BaseStore from "./BaseStore"
import { tqldServer } from "@common/api"

class InsurerStore extends BaseStore {
  constructor() {
    super({
      all: [],
      paging: {
        page: 1,
        perPage: 10,
        totalCount: 0,
        totalPages: 1,
      },
      filter: {},
      current: null,
      configs: {
        attributes: {
          type: {},
          state: {},
        },
        filters: {
          type: {},
          state: {},
        },
      },
      documents: [],
      currentDocument: null,
      insuranceCompany: {
        quarterlyCalculateDetail: {},
        companyCommissionRates: [],
        companyMonthlyBonusRates: [],
        companyQuarterlyBonusRates: [],
        companyYearlyBonusRates: [],
        companyPersistentBonuses: [],
        monthlyExperienceBonusRates: [],
        quarterlyExperienceBonusRates: [],
        yearlyExperienceBonusRates: [],
      },
    })
  }

  fetchConfigs = async (options = {}) => {
    const response = await tqldServer.tqmBackend.get(`/insurers/configs`, options)
    this.configs = response.data.data.configs
  }

  fetch = async (options = {}) => {
    const response = await tqldServer.tqmBackend.get(`/insurers`, options)
    this.filter = options
    this.all = response.data.data.insurers
    this.paging = response.data.data.paging
  }

  fetchById = async (id) => {
    const response = await tqldServer.tqmBackend.get(`/insurers/${id}`)
    this.current = response.data.data.insurer
  }

  create = async (params, options = {}) => {
    const response = await tqldServer.tqmBackend.post(`/insurers`, params)
    this.current = response.data.data.insurer
    return this.current
  }

  update = async (id, params) => {
    const response = await tqldServer.tqmBackend.put(`/insurers/${id}`, params)
    this.current = response.data.data.insurer
  }

  destroy = async (id) => {
    await tqldServer.tqmBackend.delete(`/insurers/${id}`)
    this.current = null
  }

  clearInsurer = async () => {
    this.current = null
  }

  fetchDocuments = async (id) => {
    const response = await tqldServer.tqmBackend.get(`/insurer_documents`, {
      insurerId: id,
    })
    this.documents = response.data.data.documents
  }

  fetchDocumentById = async (id, documentId) => {
    const response = await tqldServer.tqmBackend.get(`/insurer_documents/${documentId}`, {
      insurerId: id,
    })
    this.currentDocument = response.data.data.document
  }

  createDocument = async (id, params) => {
    const response = await tqldServer.tqmBackend.post(`/insurer_documents`, {
      insurerId: id,
      ...params,
    })
    this.documents = response.data.data.documents
  }

  updateDocument = async (id, documentId, params, options = {}) => {
    const response = await tqldServer.tqmBackend.put(`/insurer_documents/${documentId}`, {
      insurerId: id,
      ...params,
    })
    this.documents = response.data.data.documents
  }

  destroyDocument = async (id, documentId) => {
    const response = await tqldServer.tqmBackend.delete(`/insurer_documents/${documentId}`, {
      insurerId: id,
    })
    this.documents = response.data.data.documents
  }

  downloadAllDocument = async (id) => {
    await tqldServer.tqmBackend.download(`/insurer_documents/download`, {
      insurerId: id,
    })
  }

  fetchPreviewOccupations = async (file) => {
    const response = await tqldServer.tqmBackend.post(`insurers/preview_occupations`, file)

    return response.data.data.occupations
  }

  downloadOccupation = async (id) => {
    await tqldServer.tqmBackend.download(`insurers/${id}/export_occupations`)
  }

  fetchBonusCommission = async (id) => {
    const response = await tqldServer.tqmBackend.get(`insurers/${id}/commissions`)
    this.insuranceCompany = response.data.data.insuranceCompany
  }

  updateBonusCommission = async (id, params) => {
    const response = await tqldServer.tqmBackend.put(`insurers/${id}/commissions`, params)
    return response.data.data.insuranceCompany
  }
}

export default new InsurerStore()
