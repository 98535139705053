import React from "react"
import styled from "@emotion/styled"
import { compose, withHandlers } from "recompose"
import { Button, Dialog, DialogActionsBar } from "@components"

export const CustomDialog = styled(Dialog)`
  .k-window {
    border: 1px solid #e6e6e6;
    min-width: 348px;
  }
`

export const Title = styled.div`
  font-size: 16px;
  padding: 20px 15px;
  font-family: Sarabun, sans-serif;
  color: #666666;
`

export const CloseButton = styled(Button)`
  height: 52px;
  color: #666666;
`

export const SubmitButton = styled(Button)`
  height: 52px;
  background: #0275d8;
  border: 1px solid #eeeef2;
  color: #ffffff;
`

const DeleteModal = (props) => (
  <CustomDialog
    title={`ยืนยันการลบ ${props.caption}`}
    onClose={props.onClose}
    isShowModal={props.open}
  >
    <Title>กรุณากดยืนยันเพื่อลบ {props.caption}</Title>
    <DialogActionsBar>
      <CloseButton type="button" onClick={props.onClose} children="ยกเลิก" styletype="delete" />
      <SubmitButton type="button" onClick={props.onSubmit} children="ยืนยัน" />
    </DialogActionsBar>
  </CustomDialog>
)

const enhancer = compose(
  withHandlers({
    onSubmit: (props) => async () => {
      await props.onAfterDeleteSubmit()
      props.onClose()
    },
  })
)
export default enhancer(DeleteModal)
