import BaseStore from "./BaseStore"
import { tqldServer } from "@common/api"

class paymentMapping extends BaseStore {
  constructor() {
    super({
      all: [],
      configs: {},
    })
  }

  fetch = async (params) => {
    const response = await tqldServer.tqmBackend.get(`payment_mappings`, params)
    this.all = response.data.data.paymentMappings
  }

  fetchConfigs = async (params) => {
    const response = await tqldServer.tqmBackend.get(`payment_mappings/configs`, params)
    this.configs = response.data.data.configs
  }

  create = async (params) => {
    await tqldServer.tqmBackend.post(`payment_mappings`, params)
  }

  delete = async (id) => {
    await tqldServer.tqmBackend.delete(`payment_mappings/${id}`)
  }

  fetchPackage = async (params) => {
    const response = await tqldServer.tqmBackend.get(`payment_mappings/packages`, params)
    this.all = response.data.data.paymentMappings
  }

  createPackage = async (params) => {
    await tqldServer.tqmBackend.post(`payment_mappings/package`, params)
  }
}

export default new paymentMapping()
