import React from "react"
import styled from "@emotion/styled"
import { Button } from "@components"
import { compose, lifecycle, withHandlers } from "recompose"
import SalesCommissionPlansCard from "./SalesCommissionPlansCard"

const Layout = styled.div`
  width: 100%;
  justify-content: center;
`
const AddStuctureButton = styled(Button)`
  max-width: 160px;
  width: 100%;
  font-family: Sarabun, sans-serif;
`
const SectionHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const TextHeaderSection = styled.span`
  display: flex;
  flex-direction: column;
`
const SectionHeader = styled.span`
  font-size: 16px;
  font-weight: bold;
  font-family: Sarabun, sans-serif;
  margin-bottom: 8px;
  line-height: 21px;
`
const SectionSubHeader = styled.span`
  font-size: 12px;
  font-family: Sarabun, sans-serif;
  line-height: 16px;
`
const ValidateStar = styled.span`
  color: red;
`
const SalesCommissionPlansField = (props) => {
  return (
    <Layout>
      <SectionHeaderContainer>
        <TextHeaderSection>
          <SectionHeader>
            แผนการตลาด
            {props.required && <ValidateStar> *</ValidateStar>}
          </SectionHeader>
          <SectionSubHeader>ระบุแผนการตลาดสำหรับใช้ในการคำนวณคอมมิชชั่นจ่าย</SectionSubHeader>
        </TextHeaderSection>

        <AddStuctureButton icon="plus" onClick={props.onAddStucture} hidden={props.readOnly}>
          เพิ่มแผน
        </AddStuctureButton>
      </SectionHeaderContainer>

      {props.fields.map((stucture, index) => (
        <SalesCommissionPlansCard
          stucture={stucture}
          index={index}
          onDeleteStucture={props.onDeleteStucture}
          fields={props.fields}
          readOnly={props.readOnly}
          marketPlanOptions={props.marketPlanOptions}
          uplineOptions={props.uplineOptions}
          change={props.change}
          fullname={props.fullname}
          required={props.required}
        />
      ))}
    </Layout>
  )
}

const enhancer = compose(
  withHandlers({
    onAddStucture: (props) => () => {
      props.fields.insert(0, {})
    },
    onDeleteStucture: (props) => (index) => {
      if (props.fields.length >= 1) {
        props.fields.remove(index)
      }
    },
  })
)

export default enhancer(SalesCommissionPlansField)
