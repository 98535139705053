import React from "react"
import styled from "@emotion/styled"
import { compose } from "recompose"
import { convertNaNtoZero, toCurrency } from "@common/helper"

const StickyTabLayout = styled.div`
  position: sticky;
  position: -webkit-sticky;
  top: 60px;
  left: 0px;
  z-index: 3;
  width: 100%;
  min-width: 768px;
  height: 40px;
  background: #229ec0;
  border: none;
  z-index: 8;

  display: flex;
  align-items: center;
  font-weight: normal;
  justify-content: space-between;
  padding: 0 45px;
  font-size: 16px;
`
const StickyTabTextLeft = styled.div`
  color: white;
`
const StickyTabTextRight = styled.div`
  color: white;
  font-size: 20px;
  sapn {
  }
`

const StickyTab = (props) => (
  <StickyTabLayout id="stickyTab">
    <StickyTabTextLeft>
      เบี้ยสัญญาหลัก {props.haveRider && <span>+ สัญญาเพิ่มเติม</span>}
    </StickyTabTextLeft>
    <StickyTabTextRight>
      {toCurrency(props.summary)}
      <span> บาท</span>
    </StickyTabTextRight>
  </StickyTabLayout>
)

const enhancer = compose()

export default enhancer(StickyTab)
