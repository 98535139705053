import React, { Fragment } from "react"
import { compose, withHandlers, withProps } from "recompose"
import styled from "@emotion/styled"
import { Grid, GridColumn, makeCell, makeHeaderCell, Checkbox, DatePicker } from "@components"
import { withRouter, withStores } from "@enhancers/index"
import { getOptionLabel } from "@common/helper"
import { isEmpty } from "lodash"

const CustomCheckbox = styled(Checkbox)`
  .k-item.k-treeview-item {
    height: 24px !important;
  }
`

const Table = (props) => {
  return (
    <Fragment>
      <Grid
        loading={props.loading}
        loadingTable={props.loadingTable}
        data={props.data}
        pageable={{
          buttonCount: 5,
        }}
        skip={props.paging.skip}
        take={props.paging.perPage}
        total={props.paging.totalCount}
        onPageChange={props.onPageChange}
      >
        {props.showSelectColumn && (
          <GridColumn
            title=""
            headerCell={makeHeaderCell(CustomCheckbox, () => ({
              value: props.isSelectedAll,
              onChange: (checked) => props.onSelectAll(checked),
            }))}
            width="42px"
            cell={makeCell(Checkbox, (cellProps) => ({
              value: cellProps.dataItem.selected,
              disabled: cellProps.dataItem.disabled,
              hidden: !cellProps.dataItem.hasSelect,
              preventClick: true,
              onChange: (checked) => props.onSelectRow(checked, cellProps.dataItem),
            }))}
          />
        )}
        <GridColumn field="agtOpportunityKey" title="รหัสโอกาสในการขาย" width="155px" />
        <GridColumn field="orderNo" title="รหัสคำสั่งซื้อ" width="120px" />
        <GridColumn field="customerName" title="ชื่อลูกค้า" width="200px" />
        <GridColumn field="teamName" title="ทีมรับผิดชอบ" width="120px" />
        <GridColumn field="salesmanName" title="พนักงานขายรับผิดชอบ" width="200px" />
        <GridColumn field="affiliateName" title="ชื่อผู้แนะนำ" width="200px" />
        <GridColumn field="affiliateTeamName" title="ทีมผู้แนะนำ" width="120px" />
        <GridColumn field="currentAddress.subDistrict" title="ตำบล" width="100px" />
        <GridColumn field="currentAddress.district" title="อำเภอ" width="100px" />
        <GridColumn field="currentAddress.villageNo" title="หมู่" width="50px" />
        <GridColumn field="currentAddress.province" title="จังหวัด" width="140px" />
        <GridColumn field="sex" title="เพศ" width="80px" />
        <GridColumn field="age" title="อายุ" width="60px" />
        <GridColumn field="assignedCount" title="จ่ายครั้งที่" width="80px" />
        <GridColumn
          field="affiliateSubmittedAt"
          title="วันที่ได้รับแจ้ง"
          width="110px"
          cell={makeCell(DatePicker, (props) => ({
            readOnly: true,
            value: props.dataItem.affiliateSubmittedAt,
          }))}
        />
        <GridColumn
          field="assignedAt"
          title="วันที่มอบหมายล่าสุด"
          width="150px"
          cell={makeCell(DatePicker, (props) => ({
            readOnly: true,
            value: props.dataItem.assignedAt,
          }))}
        />
        <GridColumn
          field="expireSaleDate"
          title="วันหมดอายุ"
          width="110px"
          cell={makeCell(DatePicker, (props) => ({
            readOnly: true,
            value: props.dataItem.expireSaleDate,
          }))}
        />
        <GridColumn field="assignerName" title="ผู้จ่ายลีด" width="200px" />
        <GridColumn field="status" title="สถานะ" width="165px" locked />
      </Grid>
    </Fragment>
  )
}

const enhancer = compose(
  withRouter(),
  withStores((stores, props) => ({
    currentRole: stores.appStore.currentRole,
    affiliateTeamOptions: stores.leadPoolStore.$("configs.attributes.affiliateTeam.options", []),
    genderOptions: stores.leadPoolStore.$("configs.attributes.gender.options", []),
    teamOptions: stores.leadPoolStore.$("configs.attributes.team.options", []),
    affiliateOrderStatusOptions: stores.leadPoolStore.$(
      "configs.attributes.affiliateOrderStatus.options",
      []
    ),
  })),
  withProps((props) => {
    const data = props.data
    data.forEach((row) => {
      let affiliateTeamName = getOptionLabel(props.affiliateTeamOptions, row.affiliateTeamId)
      if (isEmpty(affiliateTeamName)) {
        affiliateTeamName = getOptionLabel(props.teamOptions, row.affiliateTeamId)
      }

      row.teamName = getOptionLabel(props.teamOptions, row.teamId)
      row.affiliateTeamName = affiliateTeamName
      row.status = getOptionLabel(props.affiliateOrderStatusOptions, row.affiliateOrderStatus)
      row.sex = getOptionLabel(props.genderOptions, row.gender)
    })

    return {
      paging: {
        ...props.paging,
        skip: (props.paging.page - 1) * props.paging.perPage,
      },
      data,
    }
  }),
  withProps((props) => {
    const data = props.data
    const isSelectedAll = data.length > 0 ? data.every((row) => row.selected) : false

    return {
      isSelectedAll,
    }
  }),
  withHandlers({
    onPageChange: (props) => async (event) => {
      if (props.onPageChange) {
        const page = event.page.skip / event.page.take + 1
        await props.onPageChange(page, event)
      }
    },
  })
)

export default enhancer(Table)
