import React, { useState } from "react"
import styled from "@emotion/styled"
import * as path from "@common/path"
import { BreadCrumb } from "@components/index"
import { compose, lifecycle, withState, withProps } from "recompose"
import { withStores, withRouter } from "@enhancers/index"
import ProductDetail from "./ProductDetail"
import Loading from "@pages/index/Loading.js"
import { DownloadScreenButton } from "../custom/show/SummaryPage"
import moment from "moment"
const Layout = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
`
const DetailLayout = styled(Layout)``

const HeaderCaption = styled.span`
  color: #666666;
  font-size: 30px;
  margin-top: 20px;
`
const BreadCrumbLayout = styled.div`
  width: 100%;
`
const CompareProductDetailContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
`

const CompareProductDetail = (props) => {
  const [container, setContainer] = useState(null)
  return (
    <Layout>
      <BreadCrumbLayout>
        <BreadCrumb links={props.links} />
      </BreadCrumbLayout>
      {props.loading ? (
        <Loading />
      ) : (
        <>
          <DetailLayout ref={(component) => setContainer(component)}>
            <HeaderCaption>เปรียบเทียบแบบประกัน</HeaderCaption>
            <CompareProductDetailContainer>
              <ProductDetail
                data={props.productDetail1}
                age={props.age}
                gender={props.gender}
                type={props.type}
                sumInsured={props.sumInsured}
              />
              <ProductDetail
                data={props.productDetail2}
                age={props.age}
                gender={props.gender}
                type={props.type}
                sumInsured={props.sumInsured}
              />
              {props.productDetail3 && (
                <ProductDetail
                  data={props.productDetail3}
                  age={props.age}
                  gender={props.gender}
                  type={props.type}
                  sumInsured={props.sumInsured}
                />
              )}
            </CompareProductDetailContainer>
          </DetailLayout>
          <DownloadScreenButton
            style={{ marginRight: 6 }}
            container={container}
            name="Product_compare"
          />
        </>
      )}
    </Layout>
  )
}

const enhancer = compose(
  withRouter({
    queryTypes: {
      birthdate: "date",
      gender: "string",
      sumInsured: "integer",
      type: "string",
      searchBy: "string",
    },
  }),
  withState("productDetail1", "setProductDetail1", {}),
  withState("productDetail2", "setProductDetail2", {}),
  withState("productDetail3", "setProductDetail3", {}),
  withState("loading", "setLoading", false),
  withStores((stores, props) => ({
    getDetail: stores.productStore.getDetail,
    params: props.location.search,
    leadId: props.match.params.productId,
    lead: stores.leadStore.current || {},
  })),
  withProps((props) => {
    return {
      ...props.location.query,
    }
  }),
  withProps((props) => {
    const { from = "" } = props
    let links = []
    switch (from) {
      case "custom_lead":
        links = [
          { label: "ลูกค้าใหม่", path: path.leadsPath("custom") },
          {
            label: "รายละเอียดลีด",
            path: path.leadOrderInfoPath(props.leadId),
          },
          {
            label: "ค้นหาแบบประกัน",
            path: path.productsSearchPath(props.leadId, {
              leadId: props.leadId,
              age: props.age,
              gender: props.gender,
              sumInsured: props.sumInsured,
              type: props.type,
              birthdate: props.birthdate,
              from: props.from,
            }),
          },
          {
            label: "เลือกแบบประกัน",
            path: path.productsPath(props.leadId, {
              age: props.age,
              gender: props.gender,
              type: props.type,
              sumInsured: props.sumInsured,
              leadId: props.leadId,
              birthdate: props.birthdate,
              from: props.from,
            }),
          },
          {
            label: "เปรียบเทียบผลิตภัณฑ์",
          },
        ]
        break
      case "batch_lead":
        links = [
          { label: "ลีดทีคิวเอ็ม", path: path.leadsPath("all") },
          {
            label: "รายละเอียดลีด",
            path: path.leadOrderInfoPath(props.leadId),
          },
          {
            label: "ค้นหาแบบประกัน",
            path: path.productsSearchPath(props.leadId, {
              leadId: props.leadId,
              age: props.age,
              gender: props.gender,
              sumInsured: props.sumInsured,
              type: props.type,
              birthdate: props.birthdate,
              from: props.from,
            }),
          },
          {
            label: "เลือกแบบประกัน",
            path: path.productsPath(props.leadId, {
              age: props.age,
              gender: props.gender,
              type: props.type,
              sumInsured: props.sumInsured,
              leadId: props.leadId,
              birthdate: props.birthdate,
              from: props.from,
            }),
          },
          {
            label: "เปรียบเทียบผลิตภัณฑ์",
          },
        ]
        break
      default:
        links = [
          {
            label: "ผลิตภัณฑ์",
            path: "/",
          },
          {
            label: "ค้นหาแบบประกัน",
            path: path.productsSearchPath({
              age: props.age,
              gender: props.gender,
              sumInsured: props.sumInsured,
              type: props.type,
              birthdate: props.birthdate,
            }),
          },
          {
            label: "เลือกแบบประกัน",
            path: path.productsPath({
              age: props.age,
              gender: props.gender,
              type: props.type,
              sumInsured: props.sumInsured,
              birthdate: props.birthdate,
              from: props.from,
            }),
          },
          {
            label: "เปรียบเทียบผลิตภัณฑ์",
          },
        ]
        break
    }
    return { links }
  }),
  lifecycle({
    async componentDidMount() {
      const {
        productId1,
        productId2,
        productId3,
        birthdate,
        gender,
        type,
        sumInsured,
      } = this.props.location.query
      this.props.setLoading(true)
      const firstCompareProductDetail = await this.props.getDetail({
        id: productId1,
        birthdate,
        gender,
        type,
        sumInsured,
      })
      this.props.setProductDetail1(firstCompareProductDetail)
      const secondCompareProductDetail = await this.props.getDetail({
        id: productId2,
        birthdate,
        gender,
        type,
        sumInsured,
      })
      this.props.setProductDetail2(secondCompareProductDetail)
      if (productId3) {
        const thirdCompareProductDetail = await this.props.getDetail({
          id: productId3,
          birthdate,
          gender,
          type,
          sumInsured,
        })
        this.props.setProductDetail3(thirdCompareProductDetail)
      }
      this.props.setLoading(false)
    },
  })
)

export default enhancer(CompareProductDetail)
