import React from "react"
import { BrowserRouter as Router } from "react-router-dom"
import { Provider as StoreProvider } from "@stores/index"
import { Ripple } from "@components"
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl"
import App from "./App"

const MainApp = (props) => (
  <Router>
    <StoreProvider>
      <Ripple>
        <LocalizationProvider language={"en"}>
          <IntlProvider locale={"en"}>
            <App />
          </IntlProvider>
        </LocalizationProvider>
      </Ripple>
    </StoreProvider>
  </Router>
)

export default MainApp
