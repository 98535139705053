import React from "react"
import styled from "@styled"
import { Field, Dropdown, DatePicker, Input } from "@components"
import { compose } from "recompose"
import { withStores } from "@enhancers/index"

const Layout = styled.div`
  padding-left: 5px;
  padding-bottom: 5px;
`
const Row = styled.div`
  display: flex;
  margin-top: 20px;

  &:nth-of-type(1) {
    margin-top: 0;
  }

  &:last-of-type {
    > div {
      margin-left: 16px;
    }
  }

  > div {
    margin-left: 32px;

    &:nth-of-type(1) {
      margin-left: 0;
    }
  }
`
const CustomField = styled(Field)`
  > div > div {
    font-weight: unset;
  }
`

const FilterPanel = (props) => (
  <Layout>
    <Row>
      <CustomField
        component={Input}
        name="staffCode"
        caption="รหัสพนักงาน"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="99"
        rightWidth="138"
        placeholder="กรุณาระบุ"
      />
      <CustomField
        component={Dropdown}
        name="memberStatus"
        caption="สถานะ"
        captionStyle={{ fontWeight: "normal" }}
        options={props.memberStatusOptions}
        leftWidth="61"
        rightWidth="138"
        placeholder="กรุณาเลือก"
      />
    </Row>
    <Row>
      <CustomField
        component={Dropdown}
        name="salesmanPosition"
        caption="ตำแหน่ง"
        captionStyle={{ fontWeight: "normal" }}
        options={props.salesmanPositionOptions}
        leftWidth="99"
        rightWidth="138"
        placeholder="กรุณาเลือก"
      />
      <CustomField
        component={Input}
        name="teamName"
        caption="ทีมขาย"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="61"
        rightWidth="198"
        placeholder="กรุณาระบุ"
      />
    </Row>
    <Row>
      <CustomField
        component={Input}
        name="fullName"
        caption="ชื่อพนักงาน"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="99"
        rightWidth="231"
        placeholder="กรุณาระบุ"
      />
    </Row>
    <Row>
      <CustomField
        component={DatePicker}
        name="commissionReceivedAt.min"
        caption="ช่วงวันที่รับเงิน"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="120"
        rightWidth="155"
        placeholder="กรุณาเลือก"
        showErrorMessage={true}
      />
      <CustomField
        component={DatePicker}
        name="commissionReceivedAt.max"
        caption="ถึง"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="29"
        rightWidth="155"
        placeholder="กรุณาเลือก"
        showErrorMessage={true}
      />
    </Row>
  </Layout>
)

const enhancer = compose(
  withStores((stores) => ({
    salesmanPositionOptions: stores.saleCommissionStore.$(
      "configs.filters.salesmanPosition.options",
      []
    ),
    memberStatusOptions: stores.saleCommissionStore.$("configs.filters.memberStatus.options", []),
  }))
)

export default enhancer(FilterPanel)
