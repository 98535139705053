import { defaultProps } from "recompose"

const withCypress = (name, options = {}) => {
  const cypress = { "data-cy": name }

  if (options.inspect) {
    return defaultProps(cypress)
  } else {
    return defaultProps({ cypress })
  }
}

export default withCypress
