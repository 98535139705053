import React from "react"
import styled from "@emotion/styled"
import { Field, Button, Icon, Input } from "@components"
import { compose, withHandlers } from "recompose"

import PersistentPayDaysInput from "./PersistentPayDaysInput"
import PersistentBonusTable from "./PersistentBonusTable"
import { get } from "lodash"

const Layout = styled.div`
  border: 1px solid #0275d8;
  border-radius: 10px;
  padding: 30px;
  margin: 10px;
`
const LimraMonthContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  > div:first-of-type {
    width: 430px;
    min-width: 280px;

    > div:last-of-type > div {
      justify-content: center;
    }
  }
`
const CustomInput = styled(Input)`
  > div:last-of-type {
    > div {
      font-weight: 600;
      color: #0275d8;
    }

    > input {
      margin-left: 10px;
      text-align: center;
      width: 160px;
      height: 56px;
    }
  }
`
const Unit = styled.div`
  font-weight: 600;
  color: #0275d8;
`
const AddPersistentButton = styled(Button)`
  /* width: 160px;
  height: 56px; */
  padding: 15px 20px;
  margin-top: 30px;
`
const RemovePersistentButton = styled(Button)`
  padding: 15px 20px;
  display: flex;
  margin: 30px auto 0;
  color: red;
  border-color: red;

  :hover {
    color: red;
    border-color: red;
  }
`

const PersistentBonus = (props) => (
  <>
    {props.fields.map((value, index) => {
      return (
        <Layout key={index}>
          <LimraMonthContainer>
            <Field
              name={`${value}limraMonthCount`}
              component={CustomInput}
              placeholder="กรุณาระบุ"
              readOnly={!props.readOnly}
              caption="จำนวนเดือนในการคำนวณความยั่งยืน"
              leftWidth="250"
            />
            <Unit>เดือน</Unit>
          </LimraMonthContainer>
          <PersistentPayDaysInput
            data={props.data[index].persistentPayDays}
            readOnly={props.readOnly}
            change={props.change}
            fieldName={value}
          />
          <PersistentBonusTable
            data={props.data[index].companyPersistentBonusRatesAttributes}
            readOnly={props.readOnly}
            change={props.change}
            fieldName={value}
            limraMonthCount={props.data[index].limraMonthCount}
          />

          {props.fields.length > 1 && (
            <RemovePersistentButton onClick={() => props.onDelete(index)} hidden={!props.readOnly}>
              <Icon icon="trash" />
            </RemovePersistentButton>
          )}
        </Layout>
      )
    })}

    <AddPersistentButton onClick={() => props.onAdd()} hidden={!props.readOnly}>
      <Icon icon="plus" />
    </AddPersistentButton>
  </>
)

const enhancer = compose(
  withHandlers({
    onAdd: (props) => () => {
      props.fields.push({
        limraMonthCount: null,
        persistentPayDays: [],
        companyPersistentBonusRatesAttributes: [],
      })
    },
    onDelete: (props) => (index) => {
      if (props.fields.length > 1) {
        props.fields.remove(index)
      }
    },
  })
)

export default enhancer(PersistentBonus)
