import React from "react"
import { compose, withHandlers, withProps } from "recompose"
import {
  withField,
  withCaption,
  withReadOnly,
  withHidden,
  acceptProps,
  withCypress,
} from "@enhancers/index"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import classnames from "classnames"

export const Input = ({ valid, ...props }) => (
  <>
    <input
      autoComplete="off"
      className={classnames({
        "k-textbox": true,
        [props.className]: props.className,
        "k-state-invalid": valid === false,
      })}
      {...props}
      value={props.value || ""}
    />
    <div className={valid ? "" : "k-state-invalid"} style={{ width: "0px", height: "0px" }}></div>
  </>
)
const ReadOnly = styled.div`
  min-height: 33.5px;
  display: flex;
  align-items: center;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  color: #666666;
  word-break: break-all;
`

const CustomInput = styled(Input)`
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #eeeef2;
  height: 36px;
  padding: 6px 12px !important;

  :focus:not(.k-state-invalid),
  :hover:not(.k-state-invalid) {
    font-family: Sarabun, sans-serif;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #eeeef2;
  }

  ${(props) =>
    props.disabled &&
    css`
      background: #ffffff;
      color: #eeeef2;
      pointer-events: none;
    `}

  ${(props) =>
    !props.valid &&
    css`
      border-radius: 4px;
      border: 1px solid #dc3545;

      :focus:not(.k-state-invalid),
      :hover:not(.k-state-invalid) {
        font-family: Sarabun, sans-serif;
        font-size: 16px;
        border-radius: 4px;
        border: 1px solid #dc3545;
      }
    `} ::placeholder {
    font-family: Sarabun, sans-serif;
    font-size: 16px;
    color: #bec3c7;
  }

  ::placeholder {
    color: #bec3c7;
  }
`
const CaptionStyle = css`
  > * :nth-of-type(2) > div {
    padding-top: 6px;
  }
`

const enhancer = compose(
  withCypress("Input"),
  withHidden(),
  withField(),
  withCaption({
    className: CaptionStyle,
  }),
  withProps((props) => {
    const className = props.className || "" + props.name
    return { className }
  }),
  withReadOnly((props) => (
    <ReadOnly style={props.readOnlyStyle} className={props.className}>
      {props.value}
    </ReadOnly>
  )),
  withHandlers({
    onChange: (props) => (e) => {
      const value = e.value || e.target.value

      let testValue = value
      if (testValue === undefined) {
        testValue = null
      }
      testValue = `${testValue}`

      if (props.maxLength !== undefined && testValue.length > props.maxLength) {
        return
      }

      if (props.number && !/^\d*$/.test(testValue)) {
        return
      }

      props.onChange && props.onChange(value)
    },
  }),
  withProps((props) => ({
    valid: !props.touched || props.valid,
    value: props.value || "",
  })),
  acceptProps(
    "value",
    "onChange",
    "onBlur",
    "valid",
    "className",
    "disabled",
    "type",
    "placeholder",
    "name"
  )
)

export default enhancer(CustomInput)
