import React from "react"
import styled from "@emotion/styled"
import { compose, withHandlers, withProps } from "recompose"
import { DialogActionsBar, Dialog, Button } from "@components"

const Layout = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  padding: 25px 15px;
  font-size: 16;
  font-family: Sarabun, sans-serif;
  /* max-width: 294px; */
  width: 100%;

  > span {
    font-weight: bold;
  }
  text-align: center;
`
const CustomDialog = styled(Dialog)`
  .k-window {
    border: 1px solid #e6e6e6;
    min-width: 348px;
  }
`
const CancelButton = styled(Button)`
  min-height: 52px;
`
const SubmitButton = styled(Button)`
  min-height: 52px;
  border-color: rgba(33, 37, 41, 0.125);
`

const Modal = (props) => (
  <CustomDialog
    title={props.title}
    onClose={props.onClose}
    isShowModal={props.open}
    // closeIcon={false}
  >
    <Layout>{props.message}</Layout>
    <DialogActionsBar>
      <SubmitButton
        type="button"
        onClick={props.onApprove}
        children={props.leftButtonCaption}
        disabled={props.disabled}
      />
      <CancelButton
        type="button"
        onClick={props.onClose}
        children={props.rightButtonCaption}
        styletype="delete"
        disabled={props.disabled}
      />
    </DialogActionsBar>
  </CustomDialog>
)

const enhancer = compose(
  withProps((props) => {
    const { title, message, leftButtonCaption, rightButtonCaption } = props.message || {}

    return {
      title,
      message,
      leftButtonCaption,
      rightButtonCaption,
    }
  }),
  withHandlers({
    onApprove: (props) => async () => {
      props.onApproved && (await props.onApproved())
    },
    onClose: (props) => () => {
      props.onClose && props.onClose()
    },
  })
)
export default enhancer(Modal)
