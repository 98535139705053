import React, { useCallback } from "react"
import Button from "./Button"
import { useDropzone } from "react-dropzone"
import styled from "@emotion/styled"
import { withState, compose, withProps } from "recompose"
import { withCypress } from "@enhancers/index"

const Body = styled.div`
  outline: none;
  display: inline-block;
`

const BrowseButton = (props) => {
  const { className, onClick, multiple, setLoading, accept, type, ...restProps } = props

  const onDrop = useCallback(
    async (acceptedFiles) => {
      setLoading(true)
      if (type === "image") {
        acceptedFiles.forEach((file) => {
          file.preview = URL.createObjectURL(file)
        })
      }
      if (multiple === false) {
        acceptedFiles = acceptedFiles[0]
      }
      await onClick(acceptedFiles)
      setLoading(false)
    },
    [setLoading, type, multiple, onClick]
  )
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple,
    accept,
  })

  return (
    <Body className={className} {...getRootProps()} {...props.cypress}>
      <input autoComplete="off" {...getInputProps()} />
      <Button {...restProps} />
    </Body>
  )
}

const typeToAccept = {
  excel:
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
  csv: ".csv",
  xlsx: ".xlsx",
  image: "image/*",
}

const enhancer = compose(
  withCypress("BrowseButton"),
  withState("loading", "setLoading", false),
  withProps((props) => ({
    accept: typeToAccept[props.type] || props.accept,
  }))
)

export default enhancer(BrowseButton)
