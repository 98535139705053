import { compose, withHandlers, lifecycle, withState } from "recompose"
import { withForm, withStores, withRouter, withHooks } from "@enhancers/index"
import * as path from "@common/path"
import { TeamInfo } from "../show"
import { isEmpty } from "lodash"
import { useCallback } from "react"

const enhancer = compose(
  withRouter(),
  withStores((stores) => ({
    fetchConfigs: stores.teamStore.fetchConfigs,
    createTeam: stores.teamStore.create,
    isEdit: true,
    disabledSalesLeaderId: true,
    salesGroupOptions: stores.teamStore.$("configs.attributes.salesGroup.options", []),
    managerOptions: stores.teamStore.$("configs.attributes.manager.options", []),
    salesLeaderOptions: stores.teamStore.$("configs.attributes.salesLeader.options", []),
    salesmanOptions: stores.teamStore.$("configs.attributes.salesman.options", []),
    branchOptions: stores.teamStore.$("configs.attributes.branch.options", []),
    affiliateTeamOptions: stores.teamStore.$("configs.attributes.affiliateTeam.options", []),
  })),
  withForm({ form: "NewTeamInfoForm" }),
  withState("memberData", "setMemberData", []),
  withHandlers({
    onCancel: (props) => () => {
      props.history.push(path.teamsPath())
    },
    onSave: (props) =>
      props.handleSubmit(async (values) => {
        let errorJoinTeamAt = false
        const newProps = {
          ...values,
          salesmen: props.memberData.map((item) => {
            if (item.joinTeamAt === null) {
              errorJoinTeamAt = true
            }
            return {
              salesmanId: item.salesmanId,
              joinTeamAt: item.joinTeamAt,
              exitTeamAt: item.exitTeamAt,
            }
          }),
        }
        if (errorJoinTeamAt) {
          alert("กรุณากรอกวันที่เริ่มต้นของสมาชิกทุกคนให้ครบ")
        } else {
          try {
            const team = await props.createTeam(newProps)
            props.history.push(path.teamPath(team.id))
          } catch (e) {
            if (e.name === "SubmissionError") {
              alert(e.errors.base)
            }
          }
        }
      }),
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetchConfigs()
    },
  }),
  withHooks((props) => {
    const addSalesToTeam = useCallback(
      (id) => {
        const salesman = props.salesmanOptions.find((item) => item.value === id)
        if (!isEmpty(salesman)) {
          props.memberData.push({
            name: salesman.label,
            staffCode: salesman.staffCode,
            salesmanId: salesman.value,
            startJobDate: salesman.startJobDate,
            joinTeamAt: null,
            exitTeamAt: null,
          })
          setTimeout(() => {
            props.change("salesmen", null)
          }, 200)
        }
      },
      [props.salesmanOptions, props.memberData]
    )

    const removeSalesFromTeam = useCallback(
      (id) => () => {
        const filterdMember = props.memberData.filter((item) => item.salesmanId !== id)
        props.setMemberData(filterdMember)
      },
      [props.memberData]
    )

    const changeDate = useCallback(
      (value, valueIndex, type) => {
        const tempMemberData = props.memberData.map((item, index) => {
          if (valueIndex === index) {
            return {
              ...item,
              joinTeamAt: type === "joinTeamAt" ? value : item.joinTeamAt,
              exitTeamAt: type === "exitTeamAt" ? value : item.exitTeamAt,
            }
          } else {
            return {
              ...item,
            }
          }
        })
        props.setMemberData(tempMemberData)
      },
      [props.memberData]
    )
    const addAreaCode = useCallback(
      (val) => {
        const areaCode = props.branchOptions.find((item) => item.value === val)
        if (!isEmpty(areaCode)) {
          props.change("areaCode", areaCode.areaCode)
        }
      },
      [props.branchOptions, props.branchCode]
    )

    return {
      addSalesToTeam,
      removeSalesFromTeam,
      changeDate,
      addAreaCode,
    }
  })
)

export default enhancer(TeamInfo)
