import React from "react"
import styled from "@emotion/styled"
import { Grid, GridColumn, Button, makeCell, InputName, LoadingScope, Dropdown } from "@components"
import { compose, withProps, withHandlers, lifecycle, withState } from "recompose"
import { withStores, withRouter } from "@enhancers/index"
import * as path from "@common/path"
import { get, isEmpty } from "lodash"
import FilterPanel from "@pages/dashboard/show/FilterPanel"
import { css } from "@styled"
import { exceptNullKeys } from "@common/helper"

const Layout = styled.div`
  margin: 0px auto 25px auto;
`
const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin: 20px 0 12px 0;
`
const Left = styled.div``
const Right = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  > div {
    margin-left: 6px;
  }
`
const CustomButton = styled(Button)`
  width: 96px;
`
const Body = styled.div``

const Table = styled(Grid)`
  td {
    white-space: nowrap;
    cursor: pointer;
  }

  .k-header > div > div {
    display: flex !important;
    min-width: 25px;
    min-height: 30px;
    align-items: center;
    margin-top: -4px;
    border-radius: 5px;
    justify-content: center;

    .k-icon.k-i-more-vertical {
      font-size: 20px;
      color: #ffffff;

      ::before {
        content: "\\e125";
      }
    }
  }

  .k-header:first-of-type > div > div {
    right: 50px !important;
  }
  .k-header:nth-of-type(2) > div > div {
    right: 30px !important;
  }
  .k-header:nth-of-type(3) > div > div {
    right: 70px !important;
  }
  .k-header:nth-of-type(4) > div > div {
    right: 75px !important;
  }

  ${(props) =>
    props.filterIndex &&
    css`
      .k-header:nth-of-type(${props.filterIndex}) > div > div {
        background-color: #ffffff;
        .k-icon.k-i-more-vertical {
          color: #294558;
        }
      }
    `}
`

const TeamIndexPage = (props) => (
  <React.Fragment>
    {props.loading ? (
      <LoadingScope />
    ) : (
      <Layout>
        <Header>
          <Left>{/*<SearchBox onSearch={props.onSearch} />*/}</Left>
          <Right>
            <CustomButton awesomeIcon="plus" onClick={props.onCreate} children="สร้าง" />
          </Right>
        </Header>
        <Body>
          <Table
            loading={props.loadingTable}
            data={props.teams}
            pageable
            skip={props.paging.skip}
            take={props.paging.perPage}
            total={props.paging.totalCount}
            onPageChange={props.onPageChange}
            onRowClick={props.onRowClick}
            filterIndex={props.filterIndex}
          >
            <GridColumn
              field="name"
              title="ชื่อทีม"
              columnMenu={(columnProps) => (
                <FilterPanel
                  {...columnProps}
                  onSubmit={props.filterChange}
                  options={props.teamOptions}
                  initialValues={props.filterTableColumn1}
                  defualtSelectAll={false}
                />
              )}
              width="150px"
            />
            <GridColumn
              field="salesGroup"
              title="กลุ่มงานขาย"
              cell={makeCell(Dropdown, (cellProps) => ({
                readOnly: true,
                value: get(cellProps, "dataItem.salesGroup"),
                options: props.salesGroupOptions,
              }))}
              columnMenu={(columnProps) => (
                <FilterPanel
                  {...columnProps}
                  onSubmit={props.filterChange}
                  options={props.salesGroupOptions}
                  initialValues={props.filterTableColumn2}
                  defualtSelectAll={false}
                />
              )}
              width="150px"
            />
            <GridColumn
              field="regionalManager"
              title="ผู้จัดการภาค"
              cell={makeCell(InputName, (cellProps) => ({
                readOnly: true,
                value: get(cellProps, "dataItem.regionalManager.nameInfo"),
              }))}
              columnMenu={(columnProps) => (
                <FilterPanel
                  {...columnProps}
                  onSubmit={props.filterChange}
                  options={props.managerOptions}
                  initialValues={props.filterTableColumn3}
                  defualtSelectAll={false}
                />
              )}
              width="190px"
            />
            <GridColumn
              field="areaManager"
              cell={makeCell(InputName, (cellProps) => ({
                readOnly: true,
                value: get(cellProps, "dataItem.areaManager.nameInfo"),
              }))}
              columnMenu={(columnProps) => (
                <FilterPanel
                  {...columnProps}
                  onSubmit={props.filterChange}
                  options={props.managerOptions}
                  initialValues={props.filterTableColumn4}
                  defualtSelectAll={false}
                />
              )}
              title="ผู้จัดการเขต"
              width="190px"
            />

            <GridColumn
              title="หัวหน้าทีม"
              width="175px"
              cell={makeCell(InputName, (cellProps) => ({
                readOnly: true,
                value: get(cellProps, "dataItem.salesLeader.nameInfo"),
              }))}
            />
            <GridColumn field="salesmanCount" title="จำนวนสมาชิก" width="167px" />
          </Table>
        </Body>
      </Layout>
    )}
  </React.Fragment>
)

const enhancer = compose(
  withRouter(),
  withState("loading", "setLoading", false),
  withState("loadingTable", "setLoadingTable", false),
  withStores((stores) => {
    return {
      fetchTeams: stores.teamStore.fetch,
      fetchConfigs: stores.teamStore.fetchConfigs,
      importTeams: stores.teamStore.import,
      teams: stores.teamStore.all,
      paging: stores.teamStore.paging,
      teamOptions: stores.teamStore.$("configs.filters.team.options", []),
      managerOptions: stores.teamStore.$("configs.filters.manager.options", []),
      salesGroupOptions: stores.teamStore.$("configs.filters.salesGroup.options", []),
    }
  }),
  withProps((props) => {
    return {
      paging: {
        ...props.paging,
        skip: (props.paging.page - 1) * props.paging.perPage,
      },
    }
  }),
  withState("filterTableColumn1", "setFilterTableColumn1", {}),
  withState("filterTableColumn2", "setFilterTableColumn2", {}),
  withState("filterTableColumn3", "setFilterTableColumn3", {}),
  withState("filterTableColumn4", "setFilterTableColumn4", {}),
  withState("filterIndex", "setFilterIndex", null),
  withHandlers({
    resetFiltering: (props) => () => {
      props.setFilterTableColumn1({})
      props.setFilterTableColumn2({})
      props.setFilterTableColumn3({})
      props.setFilterTableColumn4({})
      props.setFilterIndex(null)
    },
  }),
  withHandlers({
    onPageChange: (props) => async (event) => {
      const page = event.page.skip / event.page.take + 1
      props.setLoadingTable(true)
      await props.fetchTeams({ page, perPage: 10 })
      props.setLoadingTable(false)
    },
    onRowClick: (props) => (event) => {
      props.history.push(path.teamPath(event.dataItem.id))
    },
    onCreate: (props) => () => {
      props.history.push(path.newTeamPath())
    },
    onSearch: (props) => (value) => {},
    filterChange: (props) => async (filter) => {
      const filterValues = []

      filter.selectedValues.find((item) => {
        if (item.selected === true) {
          filterValues.push(item.value)
        }
      })
      if (!isEmpty(filter)) {
        props.resetFiltering()
      }

      let orderBy = ""

      switch (filter.orderBy) {
        case "name":
          orderBy = "team_name"
          props.setFilterTableColumn1(filter)
          props.setFilterIndex(1)
          break
        case "salesGroup":
          orderBy = "sales_group"
          props.setFilterTableColumn2(filter)
          props.setFilterIndex(2)
          break
        case "regionalManager":
          orderBy = "regional_manager"
          props.setFilterTableColumn3(filter)
          props.setFilterIndex(3)
          break
        case "areaManager":
          orderBy = "area_manager"
          props.setFilterTableColumn4(filter)
          props.setFilterIndex(4)
          break
        default:
          orderBy = filter.order
          break
      }

      const params = {
        filterValues,
        orderBy: orderBy,
        order: filter.order,
      }

      const exceptNullParams = exceptNullKeys(params, "orderBy", "order")

      props.setLoadingTable(true)
      await props.fetchTeams(exceptNullParams)
      props.setLoadingTable(false)
    },
  }),
  lifecycle({
    async componentDidMount() {
      this.props.setLoading(true)
      await Promise.all([this.props.fetchTeams(), this.props.fetchConfigs()])
      this.props.setLoading(false)
    },
  })
)

export default enhancer(TeamIndexPage)
