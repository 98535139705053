import React from "react"
import { compose, withHandlers, withProps } from "recompose"
import { withField, withCaption, withReadOnly } from "@enhancers"
import Button from "./Button"
import styled, { css } from "@styled"

const ReadOnly = styled.div`
  min-height: 33.5px;
  display: flex;
  align-items: center;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
`

const Layout = styled.div`
  display: flex;

  > span {
    max-width: 120px;
    margin-left: 0;
  }

  > input {
    flex: 1;
    margin-top: 0px !important;
    margin-left: 10px;
    font-family: Sarabun, sans-serif;
    font-size: 16px;
  }
`

const CustomButton = styled(Button)`
  width: 100px;
  height: 38px;
  opacity: 1;
  color: #deeaf6;
  background: #ffffff;
  border: 1px solid #deeaf6;

  :active,
  :hover {
    opacity: 0.4;
    color: black;
  }

  ${(props) =>
    props.isactive &&
    css`
      pointer-events: none;
      border: 1px solid #0275d8;
      color: #0275d8;
    `}

  ${(props) =>
    !props.valid &&
    css`
      color: #d9534f;
      border-color: #d9534f;
    `}
`

const InputGender = (props) => (
  <Layout className={props.className}>
    <CustomButton
      value="male"
      onClick={props.makeOnChange("male")}
      isactive={props.value === "male" ? "true" : undefined}
      valid={props.valid}
      disabled={props.disabled}
    >
      ชาย
    </CustomButton>
    <CustomButton
      value="female"
      onClick={props.makeOnChange("female")}
      isactive={props.value === "female" ? "true" : undefined}
      valid={props.valid}
      disabled={props.disabled}
    >
      หญิง
    </CustomButton>
  </Layout>
)

const enhancer = compose(
  withField(),
  withCaption(),
  withHandlers({
    makeOnChange: (props) => (fieldName) => (value) => {
      props.onChange(fieldName)
    },
  }),
  withProps((props) => ({
    value: props.value,
  })),
  withReadOnly((props) => <ReadOnly>{{ male: "ชาย", female: "หญิง" }[props.value]}</ReadOnly>),
  withProps((props) => ({
    valid: !props.touched || props.valid,
  }))
)

export default enhancer(InputGender)
