import React, { Fragment } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { compose, withHandlers, withState, withProps } from "recompose"
import { Button, Icon, Checkbox, Avatar } from "@components"
import { withForm } from "@enhancers/index"
import defaultLogo from "@assets/images/img-no-insurance.png"
import * as path from "@common/path"
import { find } from "lodash"

const OuterLayout = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  border-radius: 5px;

  ${(props) =>
    props.isCheck &&
    css`
      border: 1px solid #229ec0;
    `}
`
const Layout = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 318px;
  background-color: white;
  border-radius: 4px;
  width: 280px;

  padding: 16px 15px;
`
const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`
const Body = styled.div`
  display: flex;
  flex-direction: column;
`
const Title = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 20px;
`
const Logo = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin-right: 17px;
  min-width: 50px;
`
const CompanyLogo = styled(Avatar)`
  width: 100%;
  height: 100%;
`
const WrapperTitle = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 59px;
`
const ProductName = styled.div`
  font-size: 16px;
  margin-bottom: 10px;
  color: #666666;
  font-family: Sarabun, sans-serif;
  font-weight: bold;
`
const CompanyName = styled.div`
  font-size: 10px;
  font-family: Sarabun, sans-serif;
  color: #666666;
`
const RowInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  height: 22px;
`

const Content = styled.div`
  font-size: 12px;
  color: #666666;
  font-family: Sarabun, sans-serif;
  font-weight: bold;
`

const ContentRegular = styled(Content)``

const IconCheckCircle = styled(Icon)`
  color: #5cb85c;
`

const IconTimesCircle = styled(Icon)`
  color: #d9534f;
`
const PremiumContent = styled.div`
  text-align: left;
  font-size: 12px;
  color: #666666;
  font-family: Sarabun, sans-serif;
  display: flex;
  white-space: nowrap;
  align-items: center;
`

const Footer = styled.div`
  display: flex;
  justify-content: center;
`

const CustomTagA = styled.a`
  display: flex;
  flex-direction: row;
  text-decoration: none;
`
const CustomButton = styled(Button)`
  width: 120px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #0275d8 !important;
  color: #0275d8;

  .k-icon {
    font-size: 20px;
  }
`
const SpecialPremium = styled.div`
  font-size: 16px;
  font-weight: bold;
`
const CustomCheckbox = styled(Checkbox)`
  .k-checkbox:checked {
    border-color: #fff;
    color: #fff;
    background-color: #229ec0;
  }
  margin-right: 4px;
`
const CompareLayout = styled.div`
  display: flex;
  width: 100%;
  background-color: #deeaf6;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 10px 15px;
  height: 40px;
  align-items: center;

  ${(props) =>
    props.isCheck &&
    css`
      background-color: #229ec0;
    `}
`
const CompareContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  > div {
    > ul {
      > li {
        > div:first-of-type {
          > div {
            display: flex;
          }
        }
      }
    }
  }
`
const CompareCaption = styled.div`
  color: ${(props) => (props.isCheck ? "#fff" : "#666666")};
  font-size: 12px;
  font-family: Sarabun, sans-serif;
`

const displayNumber = (value) => {
  let nums = ("" + value).split(".")
  const onlyNums = nums[0].replace(/[^-\d]/g, "")
  let rgx = /(\d+)(\d{3})/
  let x1 = onlyNums
  while (rgx.test(x1)) {
    // eslint-disable-next-line no-useless-concat
    x1 = x1.replace(rgx, "$1" + "," + "$2")
  }
  if (nums.length > 1) x1 = x1 + "." + nums[1]
  return x1
}

export const Life = (props) => {
  return (
    <OuterLayout id={props.product && props.product.id} isCheck={props.checked}>
      {props.onSelect && (
        <CompareLayout isCheck={props.checked}>
          <CompareContainer>
            <CustomCheckbox
              onChange={(value) => props.onCheckChange(value, props.product)}
              value={props.checked}
            />
            <CompareCaption isCheck={props.checked}>เปรียบเทียบ</CompareCaption>
          </CompareContainer>
        </CompareLayout>
      )}
      <Layout>
        {props.tag ? <Fragment>{props.tag}</Fragment> : null}
        <DescriptionWrapper>
          <Body>
            <Title>
              <Logo>
                <CompanyLogo src={props.product.companyLogoUrl || defaultLogo} size={56} square />
              </Logo>
              <WrapperTitle>
                <ProductName>{props.product.name}</ProductName>
                <CompanyName>{props.product.companyName}</CompanyName>
              </WrapperTitle>
            </Title>
            {!props.isTypeAll && (
              <RowInfo>
                <Content>เบี้ยประกันมาตรฐาน</Content>
                <PremiumContent>
                  <SpecialPremium>
                    <span>{displayNumber(parseInt(props.product.premium))}</span>
                  </SpecialPremium>
                  &nbsp;&nbsp;บาท/ปี
                </PremiumContent>
              </RowInfo>
            )}

            <RowInfo>
              <Content>จ่ายเบี้ย</Content>
              <ContentRegular>{`${displayNumber(props.product.payPeriod)}  ปี `}</ContentRegular>
            </RowInfo>

            <RowInfo>
              <Content>คุ้มครอง</Content>
              {props.product.protectionType === "period" ? (
                <ContentRegular>{`${props.product.protectionPeriod} ปี`}</ContentRegular>
              ) : (
                <ContentRegular>{`${props.product.protectionPeriod} ปี (จนถึง อายุ ${props.product.protectEndAge} ปี)`}</ContentRegular>
              )}
            </RowInfo>

            {!props.isTypeAll && (
              <RowInfo>
                <Content>ได้รับเมื่อเสียชีวิต</Content>
                <ContentRegular>
                  {`เริ่มต้นที่ ${displayNumber(parseInt(props.product.minSumInsured))} บาท`}
                </ContentRegular>
              </RowInfo>
            )}

            <RowInfo>
              <Content>ลดหย่อนภาษี</Content>
              <ContentRegular>
                {props.product.isTaxSaving ? (
                  <IconCheckCircle awesomeIcon={["fas", "check-circle"]} />
                ) : (
                  <IconTimesCircle awesomeIcon={["fas", "times-circle"]} />
                )}
              </ContentRegular>
            </RowInfo>

            <RowInfo>
              <Content>นโยบายจ่ายปันผล</Content>
              <ContentRegular>
                {props.product.hasDividend ? (
                  <IconCheckCircle awesomeIcon={["fas", "check-circle"]} />
                ) : (
                  <IconTimesCircle awesomeIcon={["fas", "times-circle"]} />
                )}
              </ContentRegular>
            </RowInfo>
          </Body>

          <Footer>
            <CustomTagA>
              <CustomButton
                to={path.productCustomEditPath(props.product.id, {
                  leadId: props.search.leadId,
                  gender: props.search.gender,
                  searchValue: props.search.searchValue || props.product.minSumInsured,
                  searchBy: props.search.searchBy || "sum_insured",
                  type: props.search.type,
                  birthdate: props.search.birthdate,
                  from: props.search.from,
                  opportunityKey: props.search.opportunityKey,
                })}
                style={{ marginRight: 10 }}
                icon="calculator"
              >
                คำนวณเบี้ย
              </CustomButton>
              <CustomButton
                to={path.productCustomShowDetailPath(props.product.id, {
                  leadId: props.search.leadId,
                  gender: props.search.gender,
                  searchValue: props.search.searchValue || props.product.minSumInsured,
                  searchBy: props.search.searchBy || "sum_insured",
                  type: props.search.type,
                  birthdate: props.search.birthdate,
                  from: props.search.from,
                  opportunityKey: props.search.opportunityKey,
                })}
                icon="search"
              >
                รายละเอียด
              </CustomButton>
            </CustomTagA>
          </Footer>
        </DescriptionWrapper>
      </Layout>
    </OuterLayout>
  )
}

const enhancer = compose(
  withForm({ form: "PolicyInfoOptions", destroyOnUnmount: false }),
  withState("checked", "setChecked", false),
  withHandlers({
    onCheckChange: (props) => (value, data) => {
      props.onSelect(value, {
        id: data.id,
        premium: data.premium,
        name: data.name,
        companyLogoUrl: data.companyLogoUrl,
        paramsEncode: data.paramsEncode,
      })
      props.setChecked(!props.checked)
    },
  }),
  withProps((props) => {
    const isSelectedProduct = find(props.compareProduct, ({ id }) => {
      return id === props.product.id
    })

    const isTypeAll = props.search.type === "all"
    // props.compareProduct.find(({ id }) => {
    //   return id === props.product.id
    // })

    return {
      checked: isSelectedProduct ? true : false,
      isTypeAll,
    }
  })
)

export default enhancer(Life)
