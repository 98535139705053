import React from "react"
import styled from "@emotion/styled"
import { css } from "@styled"
import { compose, lifecycle, withHandlers, withProps, withState } from "recompose"
import { withRouter, withStores } from "@enhancers/index"
import { Button, Icon, GridColumn, Authorize } from "@components"
import { isEmpty } from "lodash"
import { exceptNullKeys } from "@common/helper"
import {
  Table,
  InsuranceCompany as InsuranceCompanyContainer,
  CompanyLogo,
  CatagoryText,
} from "../index/InfoCard/InsuranceCompanyCard"
import FilterPanel from "./FilterPanel"
import FilterDate from "../index/FilterDate"

export const Layout = styled.div`
  margin-top: 60px;
`
export const TitleText = styled.div`
  margin-top: 34px;
  font-family: ThaiSans Neue;
  font-weight: bold;
  font-size: 30px;
  line-height: 39px;
`
export const Body = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 16px;
  margin-top: 24px;
  border-radius: 5px;
`
export const CustomTable = styled(Table)`
  .k-grid-header {
    thead > tr > th {
      pointer-events: unset;
    }
    .k-header {
      vertical-align: unset;
    }
  }

  .k-master-row > td {
    padding-right: 45px;
  }

  .k-header > div > div {
    display: flex !important;
    min-width: 25px;
    min-height: 30px;
    align-items: center;
    margin-top: -4px;
    border-radius: 5px;
    justify-content: center;
  }

  .k-header:nth-of-type(2) > div > div {
    right: 180px !important;
  }

  .k-icon.k-i-more-vertical {
    font-size: 20px;
    color: #666666;

    ::before {
      content: "\\e125";
    }
  }

  ${(props) =>
    props.filterIndex &&
    css`
      .k-header:nth-of-type(${props.filterIndex}) > div > div {
        background-color: #627b8b;
        .k-icon.k-i-more-vertical {
          color: #ffffff;
        }
      }
    `}
`
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`
export const ReportButton = styled(Button)`
  width: 166px;
  height: 56px;

  .k-icon {
    font-size: 20px;
  }
`
export const HeaderColumn = styled.div`
  display: flex;
  align-items: center;

  > div {
    min-width: 25px;
    min-height: 30px;
    display: flex;
    text-align-last: center;
    margin-left: 5px;
    border-radius: 5px;
    justify-content: center;
    background-color: ${(props) => props.isactive && "#627b8b"};

    > span {
      align-self: center;
      display: flex;
      justify-content: center;
      font-size: 20px;
      color: ${(props) => props.isactive && "#ffffff"};
    }
  }
`

const InsuranceCompany = (props) => (
  <Layout>
    <TitleText>
      {props.dashboardType === "affiliate"
        ? "ยอดขายแยกตามบริษัทประกันจากผู้แนะนำ"
        : "ยอดขายแยกตามบริษัทประกัน"}
    </TitleText>
    <Body>
      <Header>
        <FilterDate onSubmit={props.onDateFilterSubmit} />
        {props.dashboardType !== "affiliate" ? (
          <Authorize roles={["member.biz_dev", "member.admin", "member.brokerage_admin"]}>
            <ReportButton icon="print" onClick={props.onExport} children="ออกรายงาน" />
          </Authorize>
        ) : null}
      </Header>
      <CustomTable
        data={props.insuranceCompany}
        pageable
        skip={props.paging.skip}
        take={props.paging.perPage}
        total={props.paging.totalCount}
        onPageChange={props.onPageChange}
        loadingTable={props.isLoading}
        filterIndex={props.filterIndex}
      >
        <GridColumn
          title={<span>บริษัทประกัน</span>}
          field="name"
          cell={(cell) => {
            return (
              <td>
                <InsuranceCompanyContainer>
                  <CompanyLogo src={cell.dataItem.logo} />
                  <CatagoryText>{cell.dataItem.name}</CatagoryText>
                </InsuranceCompanyContainer>
              </td>
            )
          }}
          columnMenu={(columnProps) => (
            <FilterPanel
              {...columnProps}
              onSubmit={props.filterChange}
              options={props.insuranceCompanyOptions}
              initialValues={props.filterTableColumn1}
            />
          )}
          width="135"
        />
        <GridColumn
          title={
            <HeaderColumn
              onClick={props.sortingChange(
                "salable_premium",
                props.sortSalablePremium,
                props.setSortSalablePremium
              )}
              isactive={props.sortSalablePremium !== null}
            >
              ยอดเบี้ยที่ขายได้
              <div>
                <Icon awesomeIcon="sort" />
              </div>
            </HeaderColumn>
          }
          field="salablePremium"
          width="166"
        />
        <GridColumn
          title={
            <HeaderColumn
              onClick={props.sortingChange(
                "rejected_premium",
                props.sortRejectedPremium,
                props.setSortRejectedPremium
              )}
              isactive={props.sortRejectedPremium !== null}
            >
              ยอดเบี้ยที่ยกเลิก
              <div>
                <Icon awesomeIcon="sort" />
              </div>
            </HeaderColumn>
          }
          field="rejectedPremium"
          width="163"
        />
        <GridColumn
          title={
            <HeaderColumn
              onClick={props.sortingChange(
                "total_premium",
                props.sortTotalPremium,
                props.setSortTotalPremium
              )}
              isactive={props.sortTotalPremium !== null}
            >
              ยอดเบี้ยรวม
              <div>
                <Icon awesomeIcon="sort" />
              </div>
            </HeaderColumn>
          }
          field="totalPremium"
          width="136"
        />
        <GridColumn
          title={
            <HeaderColumn
              onClick={props.sortingChange(
                "salable_policy",
                props.sortSalablePolicy,
                props.setSortSalablePolicy
              )}
              isactive={props.sortSalablePolicy !== null}
            >
              กรมธรรม์ที่ขายได้
              <div>
                <Icon awesomeIcon="sort" />
              </div>
            </HeaderColumn>
          }
          field="salablePolicy"
          width="174"
        />
        <GridColumn
          title={
            <HeaderColumn
              onClick={props.sortingChange(
                "rejected_policy",
                props.sortRejectedPolicy,
                props.setSortRejectedPolicy
              )}
              isactive={props.sortRejectedPolicy !== null}
            >
              กรมธรรม์ที่ยกเลิก
              <div>
                <Icon awesomeIcon="sort" />
              </div>
            </HeaderColumn>
          }
          field="rejectedPolicy"
          width="174"
        />
        <GridColumn
          title={
            <HeaderColumn
              onClick={props.sortingChange(
                "total_policy",
                props.sortTotalPolicy,
                props.setSortTotalPolicy
              )}
              isactive={props.sortTotalPolicy !== null}
            >
              กรมธรรม์รวม
              <div>
                <Icon awesomeIcon="sort" />
              </div>
            </HeaderColumn>
          }
          field="totalPolicy"
          width="145"
        />
      </CustomTable>
    </Body>
  </Layout>
)

const enhancer = compose(
  withRouter(),
  withStores((stores) => ({
    fetchInsuranceCompany: stores.dashboardStore.fetchInsuranceCompany,
    fetchConfig: stores.dashboardStore.fetchConfig,
    insuranceCompany: stores.dashboardStore.insuranceCompany,
    paging: stores.dashboardStore.paging,
    export: stores.dashboardStore.exportCompanyReport,
    insuranceCompanyOptions: stores.dashboardStore.$("configs.filter.insuranceCompany.options", []),
  })),
  withState("isLoading", "setIsLoading", false),
  withState("sortSalablePremium", "setSortSalablePremium", null),
  withState("sortRejectedPremium", "setSortRejectedPremium", null),
  withState("sortTotalPremium", "setSortTotalPremium", null),
  withState("sortSalablePolicy", "setSortSalablePolicy", null),
  withState("sortRejectedPolicy", "setSortRejectedPolicy", null),
  withState("sortTotalPolicy", "setSortTotalPolicy", null),
  withProps((props) => ({
    paging: {
      ...props.paging,
      skip: (props.paging.page - 1) * props.paging.perPage,
    },
    defaultFilterDate: {
      dashboardType: props.history.location.pathname.includes("affiliate") ? "affiliate" : null,
      periodType: "yearly",
      submittedAt: {
        min: new Date(new Date().getFullYear(), 0, 1),
        max: new Date(new Date().getFullYear(), 11, 31),
      },
    },
    dashboardType: props.history.location.pathname.includes("affiliate") ? "affiliate" : null,
  })),
  withState("filterIndex", "setFilterIndex", null),
  withState("exportFilter", "setExportFilter", null),
  withState("filterTable", "setFilterTable", null),
  withState("filterTableColumn1", "setFilterTableColumn1", {}),
  withHandlers({
    resetFiltering: (props) => () => {
      props.setSortSalablePremium(null)
      props.setSortRejectedPremium(null)
      props.setSortTotalPremium(null)
      props.setSortSalablePolicy(null)
      props.setSortRejectedPolicy(null)
      props.setSortTotalPolicy(null)
      props.setFilterIndex(null)
      props.setFilterTable({})
      props.setFilterTableColumn1({})
    },
  }),
  withHandlers({
    onPageChange: (props) => async (event) => {
      const page = event.page.skip / event.page.take + 1
      props.setIsLoading(true)
      await props.fetchInsuranceCompany({
        ...props.defaultFilterDate,
        ...props.filterTable,
        page,
        perPage: 10,
      })
      props.setIsLoading(false)
    },
    onExport: (props) => async () => {
      await props.export(props.exportFilter)
    },
    filterChange: (props) => async (filter) => {
      const filterValues = []

      filter.selectedValues.find((item) => {
        if (item.selected === true) {
          filterValues.push(item.value)
        }
      })

      if (!isEmpty(filterValues)) {
        props.resetFiltering()
      }

      const params = {
        insuranceCompanyIds: filterValues,
        orderBy: "insurance_company_name",
        order: filter.order,
      }
      const exceptNullParams = exceptNullKeys(params, "orderBy", "order")
      const dateFilter = !isEmpty(props.exportFilter) ? props.exportFilter : props.defaultFilterDate

      props.setFilterTableColumn1(filter)
      props.setFilterTable({ ...dateFilter, ...exceptNullParams })
      props.setFilterIndex(1)

      props.setIsLoading(true)
      await props.fetchInsuranceCompany({ ...dateFilter, ...exceptNullParams })
      props.setIsLoading(false)
    },
    sortingChange: (props) => (column, orderType, setSortValue) => async () => {
      let orderTypeFilter = null
      if (orderType === null) {
        orderTypeFilter = "desc"
      } else if (orderType === "desc") {
        orderTypeFilter = "asc"
      } else {
        orderTypeFilter = null
      }
      const params = {
        orderBy: column,
        order: orderTypeFilter,
      }
      const exceptNullParams = exceptNullKeys(params, "orderBy", "order")
      const dateFilter = !isEmpty(props.exportFilter) ? props.exportFilter : props.defaultFilterDate

      props.setFilterTable({ ...dateFilter, ...exceptNullParams })

      props.setIsLoading(true)
      if (orderTypeFilter !== null) {
        await props.fetchInsuranceCompany({ ...dateFilter, ...exceptNullParams })
      } else {
        await props.fetchInsuranceCompany(dateFilter)
      }

      props.resetFiltering()
      setSortValue(orderTypeFilter)
      props.setIsLoading(false)
    },
    onDateFilterSubmit: (props) => async (values) => {
      props.setIsLoading(true)
      const params = { ...values, dashboardType: props.dashboardType }
      await props.fetchInsuranceCompany(params)
      props.setIsLoading(false)
      props.setExportFilter(params)
      props.setFilterTable(params)
      props.resetFiltering()
    },
  }),

  lifecycle({
    async componentDidMount() {
      this.props.setIsLoading(true)
      await Promise.all([
        this.props.fetchInsuranceCompany(this.props.defaultFilterDate),
        this.props.fetchConfig(),
      ])
      this.props.setIsLoading(false)
    },
  })
)

export default enhancer(InsuranceCompany)
