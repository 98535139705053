import React from "react"
import { DateTimePicker } from "@progress/kendo-react-dateinputs"
import { compose, withHandlers, withProps, withState } from "recompose"
import { withField, withReadOnly, withCaption, omitProps } from "@enhancers/index"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import moment from "moment"

const translateToThai = () => {
  const isTH = moment.locale() === "th"

  if (isTH) {
    let $target = document.querySelector(".k-button.k-bare.k-time-now")
    if ($target) {
      $target.innerHTML = $target.innerHTML.replace("NOW", "ตอนนี้")
    }

    const $targets = document.querySelectorAll(
      ".k-time-list-container .k-time-list-wrapper .k-title"
    )
    $targets.forEach(($target) => {
      if ($target) {
        $target.innerHTML = $target.innerHTML.replace("hour", "ชั่วโมง")
        $target.innerHTML = $target.innerHTML.replace("minute", "นาที")
      }
    })

    $target = document.querySelector(".k-button-group.k-button-group-stretched")
    if ($target) {
      $target.innerHTML = $target.innerHTML.replace("Date", "วันที่")
    }

    $target = document.querySelector(".k-button-group.k-button-group-stretched")
    if ($target) {
      $target.innerHTML = $target.innerHTML.replace("Time", "เวลา")
    }
  }
}

DateTimePicker.prototype.componentDidUpdate = function (_, prevState) {
  translateToThai()
}

const Layout = styled.div`
  position: relative;
  width: 100%;
  min-height: 38px;

  .k-dateinput-wrap input {
    pointer-events: none;
    opacity: 0;
  }

  :hover {
    .k-picker-wrap {
      background-color: #eeeef2;
    }
    .k-dateinput-wrap {
      background-color: #eeeef2;
    }
  }
`

const Placeholder = styled.div`
  position: absolute;
  background-color: transparent;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  color: #666666;
  width: 183px;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  opacity: ${(props) => (props.showPlaceholder ? "0.7" : "0")};
`
const CancelButton = styled.div`
  position: absolute;
  left: 165px;
  background-color: transparent;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  opacity: 0.7;

  cursor: pointer;
  :hover {
    opacity: 1;
  }
`

const CustomDateTimePicker = styled(DateTimePicker)`
  min-height: 38px;
  .k-select {
    background-color: transparent;
    border: none;
    border-left: transparent !important;
    :hover {
      background-color: transparent;
      border: none;
    }
  }
  .k-link-date {
    border: none;
  }

  width: 100%;
  min-width: 210px;
  .k-icon {
    color: #666666;
  }
  .k-dateinput-wrap {
    > input {
      font-family: Sarabun, sans-serif;
      font-size: 16px;
    }
    > input:first-of-type {
      ${(props) =>
        props.showPlaceholder &&
        css`
          pointer-events: none;
          color: transparent;
          user-select: none;
        `};
    }

    > input:first-of-type {
      ${(props) =>
        props.value &&
        css`
          padding-right: 10px;
        `};
    }
  }
`

const ReadOnly = styled.div`
  min-height: 33.5px;
  display: flex;
  align-items: center;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
`

const DateDisplay = styled.div`
  position: absolute;
  top: 2px;
  bottom: 2px;
  left: 2px;
  display: flex;
  align-items: center;
  padding: 0px 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0 !important;
  width: 143px;
`

const TimePicker = (props) => (
  <Layout className={props.className || "" + props.name}>
    <Placeholder showPlaceholder={props.showPlaceholder} onClick={props.onPlaceholder}>
      {props.placeholder}
    </Placeholder>

    {props.value && (
      <CancelButton onClick={props.onCancel}>
        <span className="k-icon k-i-close"></span>
      </CancelButton>
    )}

    <CustomDateTimePicker showPlaceholder={props.showPlaceholder} {...props} />

    <DateDisplay>{props.dateForDisplay}</DateDisplay>
  </Layout>
)

const enhancer = compose(
  withField(),
  withState("UUID", "setUUID", () => `TimePicker-${Math.floor(Math.random() * 10000)}`),
  withProps((props) => ({
    className: `${props.className || ""} ${props.UUID}`,
  })),
  withHandlers({
    onChange: (props) => (e) => {
      props.onChange(e.target.value)
    },
    onPlaceholder: (props) => () => {
      const $dataInput = document.querySelector(`.${props.UUID} .k-dateinput`)
      const $dataSelect = document.querySelector(
        `.${props.UUID} .k-select:not(.custom-time-picker)`
      )
      const $dataSelected = document.querySelector(`.${props.UUID} .k-select.custom-time-picker`)

      if ($dataInput && $dataSelect) {
        $dataSelect.click()
        $dataSelect.classList.add("custom-time-picker")
      } else {
        if ($dataSelected) {
          $dataSelected.classList.remove("custom-time-picker")
        }
      }
    },
    onCancel: (props) => () => {
      props.onChange(null)
    },
  }),
  withProps((props) => ({
    valid: !props.touched || props.valid,
    value: props.value || null,
    showPlaceholder: props.placeholder && !props.value,
    format: "dd/MM/yyyy HH:mm",
    formatPlaceholder: {
      day: "วัน",
      month: "เดือน",
      year: "ปี",
      hour: "ชม.",
      minute: "นาที",
    },
  })),
  withCaption(),
  withProps((props) => ({
    dateForDisplay: props.value ? moment(props.value).format("DD/MM/YYYY HH:mm") : null,
  })),
  withReadOnly((props) => <ReadOnly>{props.dateForDisplay}</ReadOnly>),
  omitProps("onBlur")
)

export default enhancer(TimePicker)
