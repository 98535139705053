import React from "react"
import { compose, withHandlers, withProps, defaultProps } from "recompose"
import { withField, withCaption, withReadOnly } from "@enhancers"
import Input from "./Input"
import Dropdown from "./Dropdown"
import styled from "@emotion/styled"

const ReadOnly = styled.div`
  min-height: 33.5px;
  display: flex;
  align-items: center;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  color: #666666;
`

const Layout = styled.div`
  display: flex;

  > span {
    max-width: 120px;
    margin-left: 0;
  }

  > input {
    flex: 1;
    margin-top: 0px !important;
    margin-left: 10px;
    font-family: Sarabun, sans-serif;
    font-size: 16px;
  }
`

const CustomInput = styled(Input)`
  height: 38px;

  :hover {
    ${(props) => (props.valid === false ? "border: 1px solid #dc3545 !important;" : "")}
    ${(props) => (props.valid === false ? "color: #dc3545 !important;" : "")}
  }

  :focus {
    ${(props) => (props.valid === false ? "border: 1px solid #dc3545 !important;" : "")}
    ${(props) => (props.valid === false ? "color: #dc3545 !important;" : "")}
  }

  ${(props) => (props.valid === false ? "border: 1px solid #dc3545;" : "")}
  ${(props) => (props.valid === false ? "color: #dc3545 !important;" : "")}
`

const InputName = (props) => (
  <Layout className={props.className || "" + props.name}>
    <Dropdown
      value={props.value.prefixName}
      options={props.options}
      onChange={props.makeOnChange("prefixName")}
      disabled={props.disabled}
      placeholder="คำนำหน้า"
      name="prefixName"
      className={props.valid === false ? "k-state-invalid" : ""}
    />
    <CustomInput
      value={props.value.firstName}
      onChange={props.makeOnChange("firstName")}
      disabled={props.disabled}
      placeholder="ชื่อ"
      name="firstName"
      valid={props.valid}
    />
    <CustomInput
      value={props.value.lastName}
      onChange={props.makeOnChange("lastName")}
      disabled={props.disabled}
      placeholder="นามสกุล"
      name="lastName"
      valid={props.valid}
    />
  </Layout>
)

const enhancer = compose(
  withField(),
  withCaption(),
  defaultProps({
    options: ["คุณ", "นาย", "นาง", "นางสาว", "ด.ช.", "ด.ญ."],
  }),
  withHandlers({
    makeOnChange: (props) => (fieldName) => (value) => {
      props.onChange({ ...props.value, [fieldName]: value })
    },
  }),
  withProps((props) => ({
    value: props.value || {},
    valid: !props.touched || props.valid,
  })),
  withReadOnly((props) => (
    <ReadOnly>
      {props.value.prefixName}
      {props.value.firstName} {props.value.lastName}
    </ReadOnly>
  ))
)

export default enhancer(InputName)
