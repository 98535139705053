import { NavLink } from "react-router-dom"
import styled from "@emotion/styled"
import { defaultProps, compose } from "recompose"
import { omitProps, withCypress } from "@enhancers/index"

const CustomLink = styled(NavLink)`
  text-decoration: none;
`

const enhancer = compose(
  defaultProps({}),
  withCypress("Link", { inspect: true }),
  omitProps("disable")
)

export default enhancer(CustomLink)
