import React from "react"
import styled from "@styled"
import { Field, Dropdown, DatePicker, Input } from "@components"
import { compose } from "recompose"
import { withStores } from "@enhancers/index"

const Layout = styled.div`
  padding-left: 5px;
  padding-bottom: 5px;
`
const Row = styled.div`
  display: flex;
  margin-top: 20px;

  &:nth-of-type(1) {
    margin-top: 0;
  }

  &:last-of-type {
    > div {
      margin-left: 16px;
    }
  }

  > div {
    margin-left: 32px;

    &:nth-of-type(1) {
      margin-left: 0;
    }
  }
`
const CustomField = styled(Field)`
  > div > div {
    font-weight: unset;
  }
`

const FilterPanel = (props) => (
  <Layout>
    <Row>
      <CustomField
        component={Input}
        name="tqmOrderId"
        caption="รหัสคำสั่งซื้อ"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="99"
        rightWidth="231"
        placeholder="กรุณาระบุ"
      />
    </Row>
    <Row>
      <CustomField
        component={Input}
        name="staffCode"
        caption="รหัสพนักงาน"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="99"
        rightWidth="231"
        placeholder="กรุณาระบุ"
      />
    </Row>
    <Row>
      <CustomField
        component={Input}
        name="memberName"
        caption="ชื่อพนักงาน"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="99"
        rightWidth="231"
        placeholder="กรุณาระบุ"
      />
    </Row>
    <Row>
      <CustomField
        component={Input}
        name="customerName"
        caption="ชื่อลูกค้า"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="99"
        rightWidth="231"
        placeholder="กรุณาระบุ"
      />
    </Row>
    <Row>
      <CustomField
        component={Dropdown}
        name="insuranceCompanyId"
        caption="ชื่อบริษัท"
        captionStyle={{ fontWeight: "normal" }}
        options={props.insuranceCompanyOptions}
        leftWidth="99"
        rightWidth="231"
        placeholder="กรุณาเลือก"
      />
    </Row>
    <Row>
      <CustomField
        component={DatePicker}
        name="submittedAt.min"
        caption="ช่วงวันที่แจ้งงาน"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="120"
        rightWidth="155"
        placeholder="กรุณาเลือก"
        showErrorMessage={true}
      />
      <CustomField
        component={DatePicker}
        name="submittedAt.max"
        caption="ถึง"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="29"
        rightWidth="155"
        placeholder="กรุณาเลือก"
        showErrorMessage={true}
      />
    </Row>
    <Row>
      <CustomField
        component={DatePicker}
        name="coverageDate.min"
        caption="ช่วงวันที่คุ้มครอง"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="120"
        rightWidth="155"
        placeholder="กรุณาเลือก"
        showErrorMessage={true}
      />
      <CustomField
        component={DatePicker}
        name="coverageDate.max"
        caption="ถึง"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="29"
        rightWidth="155"
        placeholder="กรุณาเลือก"
        showErrorMessage={true}
      />
    </Row>
  </Layout>
)

const enhancer = compose(
  withStores((stores) => ({
    insuranceCompanyOptions: stores.saleCommissionStore.$(
      "configs.filters.insuranceCompany.options",
      []
    ),
  }))
)

export default enhancer(FilterPanel)
