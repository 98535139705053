import React from "react"
import { compose, withState, withHandlers } from "recompose"

const enhancer = compose(
  withState("thId", "setThId", (props) => `AA${parseInt(Math.random() * 1000000)}`),
  withHandlers({
    onClick: (props) => () => {
      const e = document.querySelector(`.${props.thId}`)
      e.click()
    },
  })
)

const makeCell = (Component, mapProps) =>
  enhancer((props) => {
    const { preventClick, ...cellProps } = mapProps(props)
    return <Component {...cellProps} />
  })

export default makeCell
