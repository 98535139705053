import { flatten } from "lodash"
import { compose, withState, withHandlers, defaultProps, lifecycle, withProps } from "recompose"
import { AutoComplete } from "@components/index"
import addressData from "./address_data.json"
import Fuse from "fuse.js"
import { withField } from "@enhancers/index.js"

const addressList = flatten(
  addressData.map(([province, districtData]) =>
    flatten(
      districtData.map(([district, subDistrictData]) =>
        flatten(
          subDistrictData.map(([subDistrict, postcode]) => ({
            label: `${subDistrict} » ${district} » ${province} » ${postcode}`,
            tag: `${subDistrict}${district}${province}${postcode}`,
          }))
        )
      )
    )
  )
)

const addressFuse = new Fuse(addressList, {
  shouldSort: true,
  threshold: 0.6,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ["tag"],
  id: "label",
})

const enhancer = compose(
  withField(),
  defaultProps({
    delay: 400,
    subDistrictOnly: false,
  }),
  withState("loading", "setLoading", false),
  withState("searchTask", "setSearchTask", null),
  withState("options", "setOptions", (props) =>
    addressFuse.search(props.value || "", { limit: 5 })
  ),
  withProps((props) => {
    const className = props.className + " " + props.name || "" + props.name
    return { className }
  }),
  withHandlers({
    onChange: (props) => (value) => {
      if (props.searchTask) {
        clearTimeout(props.searchTask)
      }
      props.setLoading(true)
      props.setSearchTask(
        setTimeout(() => {
          props.setOptions(addressFuse.search(value, { limit: 5 }))
          props.setLoading(false)
        }, props.delay)
      )

      if (props.subDistrictOnly) {
        const [subDistrict] = value.split(" » ")
        value = subDistrict
      }

      props.onChange && props.onChange(value)
    },
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (prevProps.value !== this.props.value && this.props.value) {
        const [subDistrict, district, province, postcode] = this.props.value.split(" » ")

        if (postcode !== undefined) {
          this.props.onAfterChange &&
            this.props.onAfterChange({
              subDistrict,
              district,
              province,
              postcode,
            })
        }
      }
    },
  })
)

export default enhancer(AutoComplete)
