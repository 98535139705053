import BaseStore from "./BaseStore"
import { tqldServer } from "@common/api"

class InsurerCommissionStore extends BaseStore {
  constructor() {
    super({
      all: [],
      paging: {
        page: 1,
        perPage: 2,
        totalCount: 0,
        totalPages: 1,
      },
      operationChangedCount: null,
      current: {},
      configs: [],
      detailConfigs: [],
      currentHistory: [],
    })
  }

  fetch = async (params) => {
    const response = await tqldServer.tqmBackend.get(`/insurer_commissions`, params)

    this.all = response.data.data.insurerCommissions
    this.paging = response.data.data.paging
    this.operationChangedCount = response.data.data.operationChangedCount

    return response.data.data.insurerCommissions
  }

  fetchById = async (id) => {
    const response = await tqldServer.tqmBackend.get(`insurer_commissions/${id}`)
    this.current = response.data.data.insurerCommission
  }

  fetchConfigs = async () => {
    const response = await tqldServer.tqmBackend.get(`insurer_commissions/configs`)
    this.configs = response.data.data.configs
  }

  fetchConfigsById = async (id) => {
    const response = await tqldServer.tqmBackend.get(`insurer_commissions/${id}/configs`)
    this.detailConfigs = response.data.data.configs
  }

  update = async (id, product) => {
    const response = await tqldServer.tqmBackend.put(`insurer_commissions/${id}`, product)
    this.current = response.data.data.insurerCommission
  }

  report = async (params) => {
    await tqldServer.tqmBackend.put(`insurer_commissions/report`, params)
  }

  verify = async (id) => {
    const response = await tqldServer.tqmBackend.patch(`insurer_commissions/${id}/verify`)
    this.current = response.data.data.insurerCommission
  }

  approve = async (id) => {
    const response = await tqldServer.tqmBackend.patch(`insurer_commissions/${id}/approve`)
    this.current = response.data.data.insurerCommission
  }

  fetchHistoryById = async (id) => {
    const response = await tqldServer.tqmBackend.get(`insurer_commissions/${id}/history`)
    this.currentHistory = response.data.data.history
  }

  import = async (params) => {
    return await tqldServer.tqmBackend.patch(`insurer_commissions/import`, params)
  }

  export = async (params) => {
    return await tqldServer.tqmBackend.download(`insurer_commissions/export`, params, {
      method: "post",
    })
  }

  mockChangeToVerifying = async (id) => {
    const response = await tqldServer.tqmBackend.patch(
      `insurer_commissions/${id}/mock_change_to_verifying`
    )
    this.current = response.data.data.insurerCommission
  }

  verifyMultipleInsuranceCommission = async (params) => {
    await tqldServer.tqmBackend.patch(`insurer_commissions/verify`, params)
  }

  exportInsuranceCommissionFile = async (params) => {
    await tqldServer.tqmBackend.download(`insurer_commissions/export`, params, {
      method: "post",
    })
  }

  approveEditing = async (params) => {
    await tqldServer.tqmBackend.patch(`insurer_commissions/editing_approve`, params)
  }

  approveEditingMultipleInsuranceCommission = async (params) => {
    await tqldServer.tqmBackend.patch(`insurer_commissions/editing_approve`, params)
  }
}

export default new InsurerCommissionStore()
