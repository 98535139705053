import { compose, withHandlers, lifecycle, withProps } from "recompose"
import { withStores, withRouter, withForm } from "@enhancers"
import * as path from "@common/path"
import { TqmMemberShowPage } from "../show/index"
import { isEmpty } from "lodash"

const enhancer = compose(
  withRouter(),
  withStores((stores, props) => ({
    fetchConfigAttributes: stores.tqmUserStore.fetchConfigAttributes,
    memberTypeOptions: stores.tqmUserStore.$("configs.attributes.memberType.options", []),
    prefixNameEn: stores.tqmUserStore.$("configs.attributes.prefixNameEn.options", []),
    create: stores.tqmUserStore.create,
    marketPlanOptions: stores.tqmUserStore.$("configs.attributes.marketPlan.options", []),
    uplineOptions: stores.tqmUserStore.$("configs.attributes.upline.options", []),
    teamOptions: stores.tqmUserStore.$("configs.attributes.team.options", []),
  })),
  withForm({ form: "newTqmUser" }),
  withHandlers({
    onEdit: (props) => () => {
      props.setIsEdit(true)
    },
    onCancel: (props) => () => {
      props.reset()
      props.history.push(path.tqmMembersPath())
    },
    onSave: (props) => async (values) => {
      try {
        const newValues = {
          ...values,
          salesCommissionPlans: (values.salesCommissionPlans || []).filter(
            (value) => !isEmpty(value)
          ),
          member_info: {
            staffCode: values.staffCode,
            memberType: values.memberType,
            teamId: values.teamId,
            isLeader: values.isLeader,
            parentId: values.parentId,
          },
        }
        await props.create(newValues)
        props.history.push(path.tqmMembersPath())
      } catch (error) {
        alert(error.errors?.base || error.errors?.username || "มีข้อผิดพลาดบางอย่างเกิดขึ้น")
      }
    },
  }),
  lifecycle({
    componentWillMount() {
      this.props.fetchConfigAttributes()
    },
  }),
  withProps((props) => {
    const { attributes } = props.configs || {}
    const { team } = attributes || {}
    const { options } = team || []

    const salesmanRoleSelected = ["salesman", "sales_leader"].includes(props.values.memberType)

    const fullname =
      (props.values.nameThInfo || {}).firstName + " " + (props.values.nameThInfo || {}).lastName

    return {
      options: options,
      salesmanRoleSelected: salesmanRoleSelected,
      isEdit: true,
      breadCrumbLinks: [
        { label: "จัดการผู้ใช้ของทีคิวเอ็ม", path: path.tqmMembersPath() },
        {
          label: "สร้างผู้ใช้ของทีคิวเอ็ม",
          path: path.newTqmMembersPath(),
        },
      ],
      fullname,
    }
  })
)
export default enhancer(TqmMemberShowPage)
