import React, { useMemo, useEffect, useState, useCallback } from "react"
import styled from "@emotion/styled"
import { compose, lifecycle, withHandlers, withProps, withState } from "recompose"
import {
  Button,
  Avatar,
  BreadCrumb,
  Icon,
  Field,
  PaymentPeriodInput,
  CurrencyInput,
  Dropdown,
} from "@components"
import { withStores, withForm, withRouter, withHooks } from "@enhancers"
import RiderComponent from "./RiderComponent"
import * as path from "@common/path"
import { convertNaNtoZeroFloat, isClient } from "@common/helper"
import { FieldArray, SubmissionError } from "redux-form"
import { get, isEqual, find, isEmpty, forEach, intersection } from "lodash"
import validate from "@pages/packages/new/validate"
import Loading from "@pages/index/Loading.js"
// import OccupationLevelInput from "./OccupationLevelInput"
import defaultLogo from "@assets/images/img-no-insurance.png"
import StickyTab from "./StickyTab"
import moment from "moment"
// import useScrollPosition from "@react-hook/window-scroll"
// import SearchByComponent from "./SearchByComponent"
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout"

const MainLayout = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const CustomAvatar = styled(Avatar)`
  border-radius: 5%;
  margin-right: 21px;
`
const InsurancePlan = styled.div`
  font-size: 30px;
  height: 39px;
  font-weight: normal;
  width: max-content;
  color: #666666;
  font-family: "ThaiSans Neue";
`
const CompanyName = styled.div`
  font-size: 16px;
  height: 22px;
  font-weight: bold;
  color: #0275d8;
  font-family: Sarabun, sans-serif;
`
const HeadContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  width: 550px;
`
const HeaderDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`

const TopicContainer = styled.div`
  min-width: 768px;
  margin-top: 35px;
  margin-bottom: 29px;
  width: 100%;
  height: 40px;
  background: #294558;
  display: flex;
  font-family: Sarabun, sans-serif;
  color: #ffffff;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  font-weight: normal;
`
const SubToppic = styled.div`
  height: 23px;
  color: #666666;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
`
const NoContent = styled.div`
  display: flex;
  min-height: 50px;
  justify-content: center;
`
const ConfirmBuyButton = styled(Button)`
  display: ${(props) => (props.readOnly ? "none" : "flex")};
  background: #ffffff;
  border-radius: 4px;
  width: 138px;
  height: 38px;
  margin-bottom: 20px;
  box-shadow: unset;
  border-color: #5cb85c;
  color: #5cb85c;
  span.k-icon.k-i-cart {
    font-size: 18px;
  }
`
const CancelButton = styled(Button)`
  display: flex;
  background: #ffffff;
  border-radius: 4px;
  width: 106px;
  height: 38px;
  box-shadow: unset;

  span.k-icon.k-i-cart {
    font-size: 18px;
  }
`
const BreadCrumbLayout = styled.div`
  width: 100%;
  /* margin-top: 30px; */
  a,
  div {
    font-size: 10px;
  }
`
const PaymentMethodContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`
const PaymentPeriodContainer = styled.div`
  display: flex;
  max-width: 400px;
  width: 100%;
  flex-direction: column;
  margin-bottom: 32px;
`
const OccupationLevelContainer = styled.div`
  width: 400px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
`
const OccupationLevelWarpper = styled.div`
  width: 190px;
`
const PremiumOrSumInsureContainer = styled.div`
  display: flex;
  width: 90%;
  justify-content: space-between;
  align-items: flex-end;
  /* margin-top: 10px; */
  max-width: 400px;
`
const SumInsuredComponents = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 150px;
  input {
    text-align: right;
  }
`
const PremiumComponent = styled(SumInsuredComponents)`
  align-items: flex-end;
`
const PaymentInput = styled(CurrencyInput)`
  width: 150px;

  :disabled {
    color: #666666 !important;
  }
`
const PaymentIcon = styled(Icon)`
  text-align: center;
`
const ButtonsContainer = styled.div`
  display: ${(props) => (props.isCreateLead ? "none" : "flex")};
  margin-top: 24px;

  > button:first-of-type {
    margin-right: 6px;
  }
`
export const CalculatePremiumErrorMessage = styled.div`
  height: 38px;
  display: flex;
  align-items: center;
  color: #d9534f;
  font-size: 14px;
`
const SubToppicDetails = styled(SubToppic)`
  font-weight: normal;
  margin-bottom: 16px;
  position: relative;
`
const PremiumToppicDetails = styled(SubToppicDetails)`
  margin-top: -77px;
`
const EditIcon = styled.div`
  width: 10%;
  margin: 10px 20px;
  span {
    color: #0275d8;
  }
  cursor: pointer;
`
const WrapIcon = styled.div`
  width: 50px;
  height: 38px;
  min-width: 40px;
  max-width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`
const Wraper = styled.div`
  display: ${(props) => (props.show ? "flex" : "none")};
  align-items: flex-end;
  width: 100%;
  max-width: 400px;
  margin-bottom: 7px;
`
const CalculateInputContainer = styled.div`
  display: flex;
`
const Remain = styled.span`
  color: #d9534f;
  font-size: 14px;
  position: absolute;
  min-width: 149px;
  top: 22px;
  font-weight: 400;
  left: 0;
`
const PanelLine = styled.div`
  width: 663px;
  border-top: 1px solid #eeeef2;
  position: absolute;
  top: 0;
  right: 29px;
`
const ConditionContent = styled.div`
  overflow-y: auto;
  max-height: 300px;
`
const CustomPanelBar = styled(PanelBar)`
  width: 720px;
  margin-bottom: 32px;
  border-radius: 4px;

  .k-animation-container {
    z-index: unset;

    .k-content:last-of-type {
      padding: 16px 28px 35px 26px;
    }

    .k-panel.k-group {
      .k-item.k-state-default {
        .k-link {
          .k-icon.k-i-arrow-s.k-panelbar-expand {
            right: 33.75rem;
          }

          .k-icon.k-i-arrow-n.k-panelbar-collapse {
            right: 33.75rem;
          }
        }
      }
    }
  }

  .k-item.k-state-default {
    .k-link {
      background-color: #fff;
      color: #0275d8;
      font-weight: bold;
      font-family: Sarabun, sans-serif;
      text-decoration: underline;
    }

    .k-link.k-state-selected.k-state-focused {
      box-shadow: none;
    }

    .k-link.k-header {
      background-color: #fff;
      color: #0275d8;
      font-family: Sarabun, sans-serif;
      font-weight: bold;
      text-decoration: none;
      justify-content: center;
      height: 49px;
      border-radius: 4px;
      position: relative;

      :hover {
        background-color: #fff;
        color: #0275d8;
      }
    }

    .k-link.k-header.k-state-selected {
      background-color: #fff;
      color: #0275d8;

      :hover {
        background-color: #fff;
        color: #0275d8;
      }
    }
  }

  .k-item.k-state-default.k-state-expanded {
    .k-link {
      background-color: #fff;
      color: #0275d8;
      font-weight: bold;
      font-family: Sarabun, sans-serif;
      text-decoration: underline;
    }

    .k-link.k-state-selected {
    }

    .k-link.k-state-selected.k-state-focused {
    }

    .k-link.k-header {
      background-color: #fff;
      color: #0275d8;
      text-decoration: none;
      justify-content: center;

      :hover {
        background-color: #fff;
        color: #0275d8;
      }
    }

    .k-link.k-header.k-state-selected {
      background-color: #fff;
      color: #0275d8;

      :hover {
        background-color: #fff;
        color: #0275d8;
      }
    }
  }

  .k-icon.k-i-arrow-n.k-panelbar-collapse {
    background-color: #eeeef2;
    border-radius: 50%;
    right: 13.75rem;

    ::before {
      color: #627b8b;
      content: "\\e013";
    }
  }

  .k-icon.k-i-arrow-s.k-panelbar-expand {
    background-color: #eeeef2;
    border-radius: 50%;
    right: 13.75rem;

    ::before {
      color: #627b8b;
      content: "\\e015";
    }
  }
`

export const ProductEdit = (props) => {
  return (
    <MainLayout>
      <BreadCrumbLayout>
        <BreadCrumb links={props.links} />
      </BreadCrumbLayout>
      {props.loading ? (
        <Loading />
      ) : (
        <>
          <StickyTab summary={props.stickySummary} haveRider={props.haveRider} />
          <HeadContainer>
            <CustomAvatar
              src={(props.productDetail && props.productDetail.companyLogoUrl) || defaultLogo}
              size="60"
            />
            <HeaderDetailContainer>
              <InsurancePlan>{props.productDetail && props.productDetail.name}</InsurancePlan>
              <CompanyName>
                สำหรับ เพศ {props.params.gender === "female" ? "หญิง" : "ชาย"} อายุ{" "}
                {props.productDetail.age} ปี
              </CompanyName>
            </HeaderDetailContainer>
          </HeadContainer>
          <TopicContainer>กำหนดจำนวน</TopicContainer>
          <PaymentMethodContainer>
            <PaymentPeriodContainer>
              <SubToppicDetails>รูปแบบการชำระเงิน</SubToppicDetails>
              {props.paymentPeriodConfigs !== [] && (
                <Field
                  name="paymentPeriod"
                  component={PaymentPeriodInput}
                  configs={props.paymentPeriodConfigs}
                  productId={props.params.productId}
                  onChange={() =>
                    setTimeout(() => {
                      switch (props.values.searchBy) {
                        case "sum_insured":
                          props.calculatePremium(props.values.sumInsured)
                          break
                        case "premium":
                          props.calculatePremium(props.values.premium)
                          break
                      }
                    })
                  }
                />
              )}
            </PaymentPeriodContainer>

            <>
              {props.occupationCategoryOptions.length !== 0 &&
                props.occupationCategoryOptions[0].value !== null && (
                  <PaymentPeriodContainer>
                    <SubToppicDetails>กลุ่มอาชีพของผู้เอาประกัน</SubToppicDetails>
                    <Field
                      style={{ width: "100%" }}
                      name="occupationCategory"
                      component={Dropdown}
                      options={props.occupationCategoryOptions}
                      productId={props.params.productId}
                      placeholder="โปรดระบุ"
                    />
                  </PaymentPeriodContainer>
                )}

              <PaymentPeriodContainer>
                <SubToppicDetails>อาชีพของผู้เอาประกัน</SubToppicDetails>
                <Field
                  style={{ width: "100%" }}
                  name="occupation"
                  component={Dropdown}
                  options={props.occupationOptions}
                  productId={props.params.productId}
                  placeholder="โปรดระบุ"
                  key={JSON.stringify(props.occupationOptions)}
                />
              </PaymentPeriodContainer>
            </>

            {/* <PaymentPeriodContainer>
              <SubToppicDetails>ขั้นอาชีพของผู้เอาประกัน</SubToppicDetails>
              {props.occupationLevel !== [] && (
                <Field
                  name="occupationLevel"
                  component={OccupationLevelInput}
                  configs={props.occupationCategoryOptions}
                  productId={props.params.productId}
                />
              )}
            </PaymentPeriodContainer> */}
            {/* <PaymentPeriodContainer>
              <SubToppicDetails>ค้นหาโดย</SubToppicDetails>
              <Field
                name="searchBy"
                component={SearchByComponent}
                onChange={(value) =>
                  setTimeout(() => {
                    switch (value) {
                      case "sum_insured":
                        props.calculatePremium(props.values.sumInsured)
                        break
                      case "premium":
                        props.calculatePremium(props.values.premium)
                        break
                    }
                  })
                }
              />
            </PaymentPeriodContainer> */}
            <CalculateInputContainer>
              <Wraper show={props.values.searchBy === "sum_insured"}>
                <PremiumOrSumInsureContainer>
                  <SumInsuredComponents>
                    <SubToppicDetails>จำนวนเงินเอาประกัน</SubToppicDetails>
                    <Field
                      name="sumInsured"
                      component={PaymentInput}
                      onChange={(value) => props.calculatePremium(value)}
                      disabled={props.disabledSumInsured}
                    />
                  </SumInsuredComponents>
                  <WrapIcon>
                    <PaymentIcon
                      awesomeIcon={props.calculatePremiumLoading ? "spinner" : "chevron-right"}
                      spin={props.calculatePremiumLoading}
                    />
                  </WrapIcon>
                  <PremiumComponent>
                    <PremiumToppicDetails>
                      เบี้ยสัญญาหลัก
                      {/* <Remain>(คำนวณจากขั้นอาชีพ 1)</Remain> */}
                    </PremiumToppicDetails>
                    {props.calculatePremiumErrorMessage ? (
                      <CalculatePremiumErrorMessage>
                        {props.calculatePremiumErrorMessage}
                      </CalculatePremiumErrorMessage>
                    ) : (
                      <Field
                        name="premium"
                        component={PaymentInput}
                        readOnly={!props.isEditPremium}
                      />
                    )}
                  </PremiumComponent>
                </PremiumOrSumInsureContainer>
                <EditIcon onClick={props.handleToggleEditPremium}>
                  {!props.calculatePremiumErrorMessage && (
                    <Icon awesomeIcon={props.isEditPremium ? "check" : "pencil-alt"} />
                  )}
                </EditIcon>
              </Wraper>
              <Wraper show={props.values.searchBy === "premium"}>
                <PremiumOrSumInsureContainer>
                  <PremiumComponent>
                    <SubToppicDetails>
                      จำนวนเงินเอาประกัน
                      {/* <Remain>(คำนวณจากขั้นอาชีพ 1)</Remain> */}
                    </SubToppicDetails>
                    {props.calculateSumInsuredErrorMessage ? (
                      <CalculatePremiumErrorMessage>
                        {props.calculateSumInsuredErrorMessage}
                      </CalculatePremiumErrorMessage>
                    ) : (
                      <Field
                        name="sumInsured"
                        component={PaymentInput}
                        readOnly={!props.isEditPremium}
                      />
                    )}
                  </PremiumComponent>
                  <EditIcon onClick={props.handleToggleEditPremium}>
                    {!props.calculatePremiumErrorMessage && (
                      <Icon awesomeIcon={props.isEditPremium ? "check" : "pencil-alt"} />
                    )}
                  </EditIcon>
                  <WrapIcon>
                    <PaymentIcon
                      awesomeIcon={props.calculatePremiumLoading ? "spinner" : "chevron-left"}
                      spin={props.calculatePremiumLoading}
                    />
                  </WrapIcon>
                  <PremiumComponent>
                    <PremiumToppicDetails>เบี้ยสัญญาหลัก</PremiumToppicDetails>

                    <Field
                      name="premium"
                      component={PaymentInput}
                      onChange={(value) => props.calculatePremium(value)}
                    />
                  </PremiumComponent>
                </PremiumOrSumInsureContainer>
              </Wraper>
            </CalculateInputContainer>
          </PaymentMethodContainer>
          <TopicContainer>สัญญาเพิ่มเติม</TopicContainer>

          {props.productDetail.conditionsHtml && (
            <CustomPanelBar>
              <PanelBarItem expanded={true} title="เงื่อนไขในการเลือกสัญญาเพิ่มเติม">
                <PanelLine />
                <ConditionContent
                  dangerouslySetInnerHTML={{ __html: `${props.productDetail.conditionsHtml}` }}
                />
              </PanelBarItem>
            </CustomPanelBar>
          )}

          {props.riderPolicies && props.riderPolicies.length > 0 ? (
            <FieldArray
              name="riders"
              component={RiderComponent}
              readOnly={props.readOnly}
              editPackagePage={props.editPackagePage}
              productDetail={props.productDetail}
              isEdit
              change={props.change}
              onRiderPremiumChange={props.changePremiumRider}
              paymentPeriod={props.values.paymentPeriod}
              disabledSumInsured={props.disabledSumInsured}
              preventCalculateRiderPremiumWhenPaymentPeriodChange={
                props.preventCalculateRiderPremiumWhenPaymentPeriodChange
              }
              preventDeselectRider={props.preventDeselectRider}
              forceRiderLoadingPremium={props.forceRiderLoadingPremium}
              occupationCategory={props.values.occupationCategory}
              occupation={props.values.occupation}
            />
          ) : (
            <NoContent>ไม่มีสัญญาเพิ่มเติม</NoContent>
          )}
          <ButtonsContainer
            hasPaymentMethod={props.hasPaymentMethod}
            isCreateLead={props.isCreateLead}
          >
            <CancelButton icon="cancel" onClick={props.onCancel} styletype="delete">
              ยกเลิก
            </CancelButton>
            <ConfirmBuyButton
              type="button"
              onClick={props.onFormSubmit}
              icon="check"
              readOnly={props.readOnly}
              titleButton={props.titleButton}
              disabled={props.calculatePremiumLoading}
            >
              สรุปประกัน
            </ConfirmBuyButton>
          </ButtonsContainer>
        </>
      )}
    </MainLayout>
  )
}

const goUp = (position) => {
  let $target = null

  if (position === undefined || position === null) {
    $target = document.querySelector('[class*="CalculateInputContainer"]')
  } else {
    $target = document.querySelector(`#rider_card_${position}`)
  }

  if ($target) {
    $target.scrollIntoView({ behavior: "smooth", block: "center" })
  }
}

const enhancer = compose(
  withRouter({
    queryTypes: {
      birthdate: "date",
      gender: "string",
      sumInsured: "integer",
      type: "string",
      searchBy: "string",
      leadId: "integer",
      from: "string",
      occupationCategory: "string",
      occupation: "string",
      maxAgeInsured: "integer",
      searchValue: "integer",
      enablePeriod: "boolean",
    },
  }),
  withState("toggleSummaryModal", "setToggleSummaryModal", false),
  withState("loading", "setLoading", false),
  withState("haveRider", "setHaveRider", false),
  withState("isEditPremium", "setToggleEditPremium", false),
  withStores((stores) => {
    return {
      getDetail: stores.productStore.getDetail,
      insuranceCoverageTable: stores.productStore.portDetailTable,
      productDetail: stores.productStore.portDetail,
      postBuyProduct: stores.productStore.postBuyProduct,
      riderPolicies: stores.productStore.riderPolicies,
      addOrder: stores.orderStore.addOrder,
      fetchLead: stores.leadStore.fetchById,
      paymentPeriodConfigs: stores.productStore.$("configs.attributes.paymentPeriod", []),
      occupationCategoryOptions: stores.productStore.$(
        "configs.attributes.occupationCategories",
        []
      ),
      fetchOrder: stores.orderStore.fetchOrder,
    }
  }),
  withState("selectedRiderMap", "setSelectedRidersMap", {}),

  // branch(
  //   (props) => !props.location.query.enablePeriod,
  //   withProps((props) => ({
  //     paymentPeriodConfigs: props.paymentPeriodConfigs.filter((opt) => opt.value === "yearly"),
  //   }))
  // ),
  withForm({
    form: "buyProduct",
    validate,
  }),
  withProps((props) => {
    const searchData = props.location.query
    const { productId } = props.match.params
    const { productDetail } = props
    let premium =
      searchData.searchBy === "premium" ? searchData.searchValue : productDetail.premium || 0
    const sumInsured =
      searchData.searchBy === "sum_insured" ? searchData.searchValue : productDetail.sumInsured || 0

    if (![null, undefined, ""].includes(searchData.premium)) {
      premium = searchData.premium
    }

    const age = searchData.birthdate ? moment().diff(moment(searchData.birthdate), "years") : null

    return {
      titleButton: "สรุปแบบประกัน",
      iconButton: "check",
      params: {
        ...searchData,
        age,
        productId,
        premium,
        sumInsured,
      },
      productDetail: {
        ...props.productDetail,
        premium,
        sumInsured,
        insuranceCoverageTable: props.insuranceCoverageTable && props.insuranceCoverageTable,
      },
    }
  }),
  withState("calculatePremiumTask", "setCalculatePremiumTask", null),
  withState("calculatePremiumLoading", "setCalculatePremiumLoading", false),
  withState("calculatePremiumErrorMessage", "setCalculatePremiumErrorMessage", null),
  withState("calculateSumInsuredErrorMessage", "setCalculateSumInsuredErrorMessage", null),
  withHandlers({
    makeInitialValues: (props) => async (response) => {
      const { params } = props
      const newProps = {}

      let paymentPeriodConfig = (response.configs.attributes.paymentPeriod || []).find((item) => item.value === 'yearly')
      if (!paymentPeriodConfig) paymentPeriodConfig = (response.configs.attributes.paymentPeriod || [])[0]

      const riderPolicies = response.riderPolicies.map((rider) => ({
        ...rider,
        selected: !!(rider.isFree || rider.selected),
      }))
      newProps.sumInsured =
        params.searchBy === "sum_insured" ? params.searchValue : response.policy.sumInsured
      newProps.premium =
        params.searchBy === "premium" ? params.searchValue : response.policy.premium
      newProps.paymentPeriod = props.params.paymentPeriod || paymentPeriodConfig?.value || 'yearly'
      newProps.occupationCategory = props.params.occupationCategory
      newProps.occupation = props.params.occupation
      newProps.searchBy = props.params.searchBy

      if (![null, undefined, ""].includes(params.premium)) {
        newProps.premium = params.premium
      }

      newProps.riders = props.params.riders
        ? riderPolicies.map((rider) => {
            let selectedRider = find(props.params.riders, (r) => {
              return isEqual(r.id, `${rider.id}`)
            })

            if (selectedRider) {
              return {
                ...rider,
                ...selectedRider,
                selected: true,
              }
            } else {
              return rider
            }
          })
        : riderPolicies

      // BEGIN
      const customRiders = newProps.riders.map((rider) => {
        // let [categoryName, planName] = rider.name.split(/ แผน | แบบ | Plan /)
        // const prefix = planName ? rider.name.match(/ (แผน |แบบ |Plan )/)[1] : ""
        // rider = { ...rider, planName: `${prefix}${planName}`, categoryName }

        return rider
      })

      const riderGroup = {}
      customRiders.forEach((rider) => {
        const name = rider.categoryName || rider.name

        riderGroup[name] = riderGroup[name] || []
        riderGroup[name] = [...riderGroup[name], rider]
      })

      const customRiders2 = []
      forEach(riderGroup, (riders, name) => {
        if (riders.length > 1) {
          const planOptions = riders.map((r) => ({ label: r.planName, value: r.id, rider: r }))

          const paramRiders = props.params.riders || []

          const paramRiderIds = paramRiders.map((r) => `${r.id}`)
          const groupRiderIds = riders.map((r) => `${r.id}`)

          const selectedRiderId = intersection(paramRiderIds, groupRiderIds)[0]

          const selectedParamRider = paramRiders.find((r) => r.id === selectedRiderId)
          const selectedGroupRider = riders.find((r) => r.id === selectedRiderId)

          const selected = !!selectedRiderId

          customRiders2.push({
            ...selectedGroupRider,
            ...selectedParamRider,
            name,
            planOptions,
            selected,
          })
        } else {
          customRiders2.push(riders[0])
        }
      })

      newProps.riders = customRiders2

      // END

      props.initialize(newProps)
    },
    changeRouteParams: (props) => (value) => {
      props.history.push(
        path.productCustomEditPath(props.params.productId, { ...props.params, searchBy: value })
      )
    },
  }),
  withHandlers({
    onClose: (props) => () => {
      props.setToggleSummaryModal(false)
    },
    changePremiumRider: (props) => (value) => {
      props.setCalculatePremiumLoading(value)
    },
    calculatePremium: (props) => (value) => {
      const searchBy = get(props.values, "searchBy", "sum_insured")
      if (props.calculatePremiumTask) {
        clearTimeout(props.calculatePremiumTask)
      }
      props.setToggleEditPremium(false)

      const occupationCategory = get(props.values, "occupationCategory", null)
      const occupation = get(props.values, "occupation", null)

      const calculatePremiumTask = setTimeout(async () => {
        props.setCalculatePremiumLoading(true)
        try {
          const response = await props.getDetail({
            id: props.params.productId,
            birthdate: props.params.birthdate,
            gender: props.params.gender,
            searchValue: value,
            searchBy: searchBy,
            occupationCategory: occupationCategory,
            occupation: occupation,
            paymentPeriod: props.values.paymentPeriod,
          })
          props.setCalculatePremiumErrorMessage(null)
          props.setCalculateSumInsuredErrorMessage(null)
          props.change("premium", response.policy.premium)
          props.change("sumInsured", response.policy.sumInsured)

          if (get(response.policy, "warningMessage")) {
            window.alert(response.policy.warningMessage)
          }
        } catch (e) {
          switch (props.values.searchBy) {
            case "sum_insured":
              props.setCalculatePremiumErrorMessage("เงินเอาประกันน้อยเกินไป")
              break
            case "premium":
              props.setCalculateSumInsuredErrorMessage("เบี้ยประกันน้อยเกินไป")
              break
          }

          if (e.message === "Submit Validation Failed") {
            window.alert(e.errors.occupation)
          } else if (e.response.status === 404) {
            props.setCalculatePremiumErrorMessage(e.response.data.message)
          } else {
            window.alert("เกิดข้อผิดพลาดบางอย่างขึ้น")
            console.log(e)
          }
        }
        props.setCalculatePremiumLoading(false)
      }, 1400)

      props.setCalculatePremiumTask(calculatePremiumTask)
    },
    handleToggleEditPremium: (props) => () => {
      props.setToggleEditPremium(!props.isEditPremium)
      const premium = get(props.values, "premium", 0)

      const sumInsured = get(props.values, "sumInsured", 0)
      const searchBy = get(props.values, "searchBy", "sum_insured")
      if ((!premium || !sumInsured) && props.isEditPremium) {
        props.setToggleEditPremium(true)
      } else {
        if (searchBy === "sum_insured") {
          props.change("premium", premium)
        } else {
          props.change("sumInsured", sumInsured)
        }
        props.setToggleEditPremium(!props.isEditPremium)
      }
    },
    onCancel: (props) => () => {
      const { query } = props.location || {}
      if (props.params.leadId && props.params.orderId) {
        props.history.push(path.ordersPath())
      } else {
        const params = {
          ...props.location.query,
          sumInsured: query.type === "all" ? null : query.sumInsured,
        }
        props.history.push(path.productsPath(params))
      }
    },
    onFormSubmit: (props) =>
      props.handleSubmit(async (values) => {
        let error = { riders: [] }
        let checkError = false
        const paramsRider = []

        let errorPosition = null

        for (let index = 0; index < props.values.riders.length; index++) {
          const { selected, sumInsured, premium, id, isFree, hasSumInsured } = props.values.riders[
            index
          ]
          if (!isFree && selected) {
            const e = {}
            if (hasSumInsured) {
              if ([null, undefined, ""].includes(sumInsured)) {
                e.sumInsured = "no value"
              }
            }

            if ([null, undefined, ""].includes(premium)) {
              e.premium = "no value"
            }

            if ([null, undefined, ""].includes(id)) {
              e.id = "no value"
              props.change(`riders[${index}].error`, true)
            } else {
              props.change(`riders[${index}].error`, false)
            }

            error.riders.push(e)

            if (!isEmpty(e)) {
              checkError = true
              if (errorPosition === null) {
                errorPosition = index
              }
            }
          } else {
            error.riders.push({})
          }

          if (selected) {
            paramsRider.push({
              id,
              premium,
              sumInsured,
            })
          }
        }

        if (props.calculatePremiumErrorMessage) {
          checkError = true
          error.sumInsured = "invalid"
        }
        if (props.calculateSumInsuredErrorMessage) {
          checkError = true
          error.premium = "invalid"
        }
        if (!props.values.premium) {
          error.premium = "invalid"
          checkError = true
        }
        if (!props.values.sumInsured) {
          checkError = true
          error.sumInsured = "invalid"
        }

        if (checkError) {
          if (error.sumInsured || error.premium) {
            errorPosition = null
          }

          goUp(errorPosition)

          throw new SubmissionError(error)
        } else {
          props.setToggleEditPremium(false)
          const searchBy = get(props.values, "searchBy", "sum_insured")
          const premium = get(props.values, "premium", 0)
          const sumInsured = get(props.values, "sumInsured", 0)
          const occupationCategory = get(props.values, "occupationCategory", null)
          const occupation = get(props.values, "occupation", null)

          props.history.push(
            path.productCustomPath(props.params.productId, {
              ...props.location.query,
              searchBy: searchBy,
              searchValue: searchBy === "sum_insured" ? sumInsured : premium,
              premium: premium,
              sumInsured: sumInsured,
              paymentPeriod: props.values.paymentPeriod || "yearly",
              premium: props.values.premium,
              riders: paramsRider,
              occupationCategory: occupationCategory,
              occupation: occupation,
            })
          )
        }
      }),
  }),
  lifecycle({
    async componentWillMount() {
      this.props.setLoading(true)
      try {
        const response = await this.props.getDetail({
          id: this.props.params.productId,
          birthdate: this.props.params.birthdate,
          gender: this.props.params.gender,
          sumInsured: this.props.params.sumInsured,
          searchValue: this.props.params.searchValue,
          searchBy: this.props.params.searchBy,
        })

        this.props.makeInitialValues(response)
        this.props.setLoading(false)
      } catch (error) {
        const errorMsg = error.errors?.base || "เกิดข้อผิดพลาดบางอย่างขึ้น"
        window.alert(errorMsg)
        this.props.history.push(path.productsPath(this.props.params))
      }
    },
    componentDidMount() {
      if (isClient) {
        window.scrollTo(0, 0)
      }

      if (this.props.params.searchValue === undefined && this.props.params.searchBy === undefined) {
        this.props.history.push(
          path.productCustomEditPath(this.props.params.productId, {
            ...this.props.params,
            searchBy: "sum_insured",
            searchValue: null,
          })
        )
      } else if (this.props.params.searchValue === undefined) {
        this.props.history.push(
          path.productCustomEditPath(this.props.params.productId, {
            ...this.props.params,
            searchValue: null,
          })
        )
      } else if (this.props.params.searchBy === undefined) {
        this.props.history.push(
          path.productCustomEditPath(this.props.params.productId, {
            ...this.props.params,
            searchBy: "sum_insured",
          })
        )
      }
    },
  }),
  withHooks((props) => {
    const occupationOptions = useMemo(() => {
      let tempOccupationOptions = []
      if (
        props.occupationCategoryOptions.length !== 0 &&
        props.occupationCategoryOptions[0].value === null
      ) {
        tempOccupationOptions = props.occupationCategoryOptions[0].occupations
        return tempOccupationOptions
      } else {
        tempOccupationOptions = props.occupationCategoryOptions.find(
          (option) => option.value === props.values.occupationCategory
        )
        return (tempOccupationOptions || {}).occupations
      }
    }, [props.occupationCategoryOptions, props.values.occupationCategory])

    useEffect(() => {
      if (![null, undefined, ""].includes(props.values.occupation)) {
        props.calculatePremium(props.values.sumInsured)
      }
    }, [props.values.occupation])

    let stickySummaryRider = 0
    let stickySummarySumInsuredRider = 0

    const [stickySummary, setStickySummary] = useState(0)

    useEffect(() => {
      props.setHaveRider(false)
      props.values.riders &&
        props.values.riders.forEach((item) => {
          if (item.selected) {
            stickySummaryRider += convertNaNtoZeroFloat(item.premium)
            stickySummarySumInsuredRider += convertNaNtoZeroFloat(item.sumInsured)
            props.setHaveRider(true)
          }
        })
      setStickySummary(parseFloat(props.values.premium) + parseFloat(stickySummaryRider))
    }, [props.values.riders, props.values.premium])

    return {
      occupationOptions,
      stickySummary: stickySummary,
      stickySummaryRider: stickySummaryRider,
      stickySummarySumInsuredRider: stickySummarySumInsuredRider,
    }
  }),
  withProps((props) => {
    const { params } = props
    let links = []
    switch (params.from) {
      case "custom_lead":
        links = [
          { label: "ลูกค้าใหม่", path: path.leadsPath("custom") },
          {
            label: "รายละเอียดลีด",
            path: path.leadOrderInfoPath(params.leadId),
          },
          {
            label: "ค้นหาแบบประกัน",
            path: path.productsSearchPath(params.leadId, {
              leadId: params.leadId,
              gender: params.gender,
              type: params.type,
              birthdate: params.birthdate,
              from: params.from,
              searchValue: params.searchValue,
              searchBy: params.searchBy,
              opportunityKey: params.opportunityKey,
            }),
          },
          {
            label: "เลือกแบบประกัน",
            path: path.productsPath(params.leadId, {
              gender: params.gender,
              type: params.type,
              leadId: params.leadId,
              birthdate: params.birthdate,
              from: params.from,
              searchValue: params.searchValue,
              searchBy: params.searchBy,
              opportunityKey: params.opportunityKey,
            }),
          },
          {
            label: "คำนวณเบี้ย",
          },
        ]
        break
      case "batch_lead":
        links = [
          { label: "ลีดทีคิวเอ็ม", path: path.leadsPath("all") },
          {
            label: "รายละเอียดลีด",
            path: path.leadOrderInfoPath(params.leadId),
          },
          {
            label: "ค้นหาแบบประกัน",
            path: path.productsSearchPath(params.leadId, {
              leadId: params.leadId,
              gender: params.gender,
              type: params.type,
              birthdate: params.birthdate,
              from: params.from,
              searchValue: params.searchValue,
              searchBy: params.searchBy,
              opportunityKey: params.opportunityKey,
            }),
          },
          {
            label: "เลือกแบบประกัน",
            path: path.productsPath(params.leadId, {
              gender: params.gender,
              type: params.type,
              leadId: params.leadId,
              birthdate: params.birthdate,
              from: params.from,
              searchValue: params.searchValue,
              searchBy: params.searchBy,
              opportunityKey: params.opportunityKey,
            }),
          },
          {
            label: "คำนวณเบี้ย",
          },
        ]
        break
      case "order":
        links = [
          {
            label: "คำสั่งซื้อ",
            path: path.ordersPath(),
          },
          {
            label: "คำนวณเบี้ย",
          },
        ]
        break
      default:
        links = [
          {
            label: "ผลิตภัณฑ์",
            path: "/",
          },
          {
            label: "ค้นหาแบบประกัน",
            path: path.productsSearchPath({
              gender: params.gender,
              type: params.type,
              birthdate: params.birthdate,
              searchValue: params.searchValue,
              searchBy: params.searchBy,
              opportunityKey: params.opportunityKey,
            }),
          },
          {
            label: "เลือกแบบประกัน",
            path: path.productsPath({
              gender: params.gender,
              type: params.type,
              birthdate: params.birthdate,
              from: params.from,
              searchValue: params.searchValue,
              searchBy: params.searchBy,
              opportunityKey: params.opportunityKey,
            }),
          },
          {
            label: "คำนวณเบี้ย",
          },
        ]
        break
    }
    return { links }
  })
)

export default enhancer(ProductEdit)
