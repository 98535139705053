import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { compose, withHandlers, defaultProps, withState, lifecycle } from "recompose"
import * as path from "@common/path"
import { withForm, withStores } from "@enhancers"
import {
  Button,
  Authorize,
  SubHeader as TextSubHeader,
  Icon,
  TabStrip,
  TabStripTab,
} from "@components"
import PlanningModal from "@pages/leads/show/PlanningModal"
import { isClient } from "@common/helper"

import dashboardImg from "@assets/images/menu_background_images/dashboard.png"
import leadManageImg from "@assets/images/menu_background_images/lead_manage.png"
import leadPoolManageImg from "@assets/images/menu_background_images/lead_pool_manage.png"
import leadTQMImg from "@assets/images/menu_background_images/lead_TQM.png"
import newClientImg from "@assets/images/menu_background_images/new_client.png"
import orderImg from "@assets/images/menu_background_images/order.png"

import planningSearchImg from "@assets/images/menu_background_images/planning_search.png"
import commissionPaySettinghImg from "@assets/images/menu_background_images/commission_pay_setting.png"
import lifeInsuranceImg from "@assets/images/menu_background_images/life_insurance.png"
import contractImg from "@assets/images/menu_background_images/contract.png"
import packageImg from "@assets/images/menu_background_images/package.png"
import paymentSettingImg from "@assets/images/menu_background_images/payment_setting.png"
import paymentPackageImg from "@assets/images/menu_background_images/payment_package.png"

import companyImg from "@assets/images/menu_background_images/company.png"

import commissionInsurerImg from "@assets/images/menu_background_images/commission_insurer.png"
import commissionPayImg from "@assets/images/menu_background_images/commission_pay.png"
import commissionInsurerSettingImg from "@assets/images/menu_background_images/commission_insurer_setting.png"
import chooseInsuranceImg from "@assets/images/menu_background_images/choose_insurance.png"
import commissionSettingImg from "@assets/images/menu_background_images/commission_setting.png"

import teamMangeImg from "@assets/images/menu_background_images/team_mange.png"

import userManageImg from "@assets/images/menu_background_images/user_manage.png"

import landingBG from "@assets/images/menu_background_images/landing_BG.png"

import { desktopSize } from "@common/styled"

const Layout = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  height: calc(100% - 100px);

  /* @media screen and (max-width: 760px) {
     min-width: 100vh;
  } */

  .k-tabstrip-items.k-reset {
    background-color: #deeaf6;
    display: flex;
    justify-content: space-evenly;
    padding: 0 22px;
    overflow: hidden;
  }

  .k-item.k-state-default {
    background-color: transparent;

    > span {
      font-family: "ThaiSans Neue";
      font-weight: bold;
      font-size: 24px;
      line-height: 31px;
    }

    :hover {
      background-color: transparent !important;
      color: #0275d8 !important;
    }
  }

  .k-item.k-state-active.k-tab-on-top {
    background-color: transparent !important;
    border-bottom: 4px solid #0275d8;

    > span {
      font-family: "ThaiSans Neue";
      font-weight: bold;
      font-size: 24px;
      line-height: 31px;
      color: #0275d8;
      padding-bottom: 4px;
    }
  }

  .k-widget.k-header.k-floatwrap.k-tabstrip.k-tabstrip-top {
    height: calc(100% - 28px);

    > div {
      background: ${(props) => `url(${props.backgroundImg})`};
      background-repeat: repeat-x;
      /* background-size: cover;
      background-position: center; */
    }
  }
`
const WelcomeText = styled.div`
  background-color: #025aa5;
  color: #ffffff;
  width: 100%;
  height: 28px;
  font-size: 16px;
  padding: 6px 24px;

  ${(props) =>
    desktopSize(`
    padding: 6px 39px;
  `)}
`
const CardLayout = styled(Button)`
  background-color: #025aa5;
  color: #ffffff;
  width: 352px;
  height: 136px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: #ffffff;
  font-family: "ThaiSans Neue";
  font-weight: bold;
  font-size: 30px;
  line-height: 39px;
  background: ${(props) => `url(${props.backgroundImg})`};

  :hover,
  :active,
  :focus {
    color: #ffffff;
    border-color: #ffffff;
  }

  ${(props) =>
    !props.disabled
      ? css`
          cursor: pointer;
        `
      : css`
          pointer-events: none;
          background-color: #ffffff;
          color: #eeeef2;
          border-color: #eeeef2;
          filter: grayscale(100%) !important;
        `}
`
const SubMenuContainer = styled.div`
  display: grid;
  justify-content: center;
  grid-gap: 16px;
  grid-template-columns: auto auto;
  padding-top: ${(props) => (props.hidden ? "41px" : "0px")};
`
const ProductText = styled.div`
  font-family: "ThaiSans Neue";
  font-weight: bold;
  font-size: 26px;
  line-height: 34px;
  color: #666666;
`

// const WrapperBody = styled.div`
//   margin-top: 49px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
// `
// const Items = styled.div``
// const Item = styled(TextSubHeader)`
//   color: #666666;
//   font-size: 16px;
// `
// const ButtonLabel = styled(Button)`
//   display: flex;
//   justify-content: space-between;
//   padding: 0px 16.5px;
//   min-width: 225px;
//   min-height: 38px;
//   font-size: 24px;
//   font-family: "ThaiSans Neue";
//   border-radius: 4px;
//   background-color: #0275d8;
//   color: #ffffff;
//   margin-bottom: 28px;
//   > div {
//     font-size: 24px;
//   }
//   :hover,
//   :active,
//   :focus {
//     background-color: #0275d8;
//     color: #ffffff;
//   }

//   ${(props) =>
//     !props.disabled
//       ? css`
//           cursor: pointer;
//         `
//       : css`
//           pointer-events: none;
//           background-color: #ffffff;
//           color: #eeeef2;
//           border-color: #eeeef2;
//         `}
// `

const MenuCard = (props) => (
  <CardLayout
    onClick={props.makeOnClickLink(props.item.path)}
    disabled={props.disabled}
    backgroundImg={props.item.image}
  >
    {props.item.label}
  </CardLayout>
)

const Landing = (props) => (
  <Layout backgroundImg={landingBG}>
    <WelcomeText>{`WELCOME, ${props.currentUser}`}</WelcomeText>
    <TabStrip selected={props.tabSelected} onSelect={props.onTabChange} keepTabsMounted={true}>
      {props.menuItems.map((menuItem, index) => (
        <TabStripTab title={menuItem.label} key={index}>
          <div>
            <SubMenuContainer hidden={menuItem.label !== "แบบประกัน"}>
              <ProductText hidden={menuItem.label !== "แบบประกัน"}>ผลิตภัณฑ์</ProductText>
              {menuItem.label === "แบบประกัน" && <div />}

              {menuItem.subMenuItems.map((subMenuItem, index) => (
                <Authorize key={index} roles={subMenuItem.roles}>
                  {({ authorized }) => (
                    <MenuCard
                      item={subMenuItem}
                      key={index}
                      makeOnClickLink={props.makeOnClickLink}
                      disabled={!authorized}
                    />
                  )}
                </Authorize>
              ))}
            </SubMenuContainer>
          </div>
        </TabStripTab>
      ))}
    </TabStrip>

    {/* <WrapperBody>
      {props.menuItems.map((item, index) => (
        <Authorize key={index} roles={item.roles}>
          {({ authorized }) => (
            <Items>
              <Item>
                {item.label === "ผลิตภัณฑ์" ? (
                  <ButtonLabel
                    disabled={!authorized}
                    onClick={() => props.setShowPlanningModal(true)}
                  >
                    <div>{item.label}</div>
                    <Icon icon="arrow-60-right" />
                  </ButtonLabel>
                ) : (
                  <ButtonLabel disabled={!authorized} onClick={props.makeOnClickLink(item.path)}>
                    <div>{item.label}</div>
                    <Icon icon="arrow-60-right" />
                  </ButtonLabel>
                )}
              </Item>
            </Items>
          )}
        </Authorize>
      ))}
    </WrapperBody> */}
    <PlanningModal
      {...props}
      open={props.showPlanningModal}
      onClose={() => props.setShowPlanningModal(false)}
    />
  </Layout>
)

const MENUITEMS = [
  {
    label: "แดชบอร์ด",
    subMenuItems: [
      {
        label: "แดชบอร์ด",
        roles: [
          "member.team_leader",
          "member.salesman",
          "member.biz_dev",
          "member.admin",
          "member.brokerage_admin",
        ],
        path: path.dashboardPath(),
        image: dashboardImg,
      },
    ],
  },
  {
    label: "ลีด",
    subMenuItems: [
      {
        label: "จัดการลีดทีคิวเอ็ม",
        roles: ["member.lead_admin", "member.biz_dev", "member.admin"],
        path: path.batchesPath(),
        image: leadManageImg,
      },
      {
        label: "จัดการลีดจากผู้แนะนำ",
        roles: ["member.lead_admin", "member.team_leader", "member.brokerage_admin"],
        path: path.leadPoolsPath(),
        image: leadPoolManageImg,
      },
      {
        label: "ลีดทีคิวเอ็ม",
        roles: ["member.team_leader", "member.salesman"],
        path: path.leadsPath("all"),
        image: leadTQMImg,
      },
      {
        label: "ลูกค้าใหม่",
        roles: [
          "member.team_leader",
          "member.salesman",
          "member.biz_dev",
          "member.admin",
          "member.affiliate",
        ],
        path: path.leadsPath("custom"),
        image: newClientImg,
      },
      {
        label: "คำสั่งซื้อ",
        roles: ["member.team_leader", "member.salesman", "member.biz_dev", "member.affiliate"],
        path: path.ordersPath(),
        image: orderImg,
      },
    ],
  },
  {
    label: "แบบประกัน",
    subMenuItems: [
      {
        label: "วางแผนค้นหาประกัน",
        roles: ["member.team_leader", "member.salesman", "member.biz_dev", "member.affiliate"],
        path: path.planningPath(),
        image: planningSearchImg,
      },
      {
        label: "เลือกแบบประกัน",
        roles: ["member.team_leader", "member.salesman", "member.biz_dev", "member.affiliate"],
        path: path.productsSearchPath(),
        image: commissionPaySettinghImg,
      },
      {
        label: "แบบประกันชีวิต",
        roles: ["member.policy_admin", "member.biz_dev"],
        path: path.lifeInsurancePoliciesPath(),
        image: lifeInsuranceImg,
      },
      {
        label: "สัญญาเพิ่มเติม",
        roles: ["member.policy_admin", "member.biz_dev"],
        path: path.healthInsurancePoliciesPath(),
        image: contractImg,
      },
      {
        label: "จัดการแพ็กเกจ",
        roles: ["member.policy_admin", "member.biz_dev"],
        path: path.packagesPath(),
        image: packageImg,
      },
      {
        label: "ตั้งค่าวิธีจ่ายเงิน",
        roles: ["member.policy_admin", "member.biz_dev"],
        path: path.paymentMappingsPath(),
        image: paymentSettingImg,
      },
      {
        label: "ตั้งค่าวิธีจ่ายเงิน (แพ็กเกจ)",
        roles: ["member.policy_admin", "member.biz_dev"],
        path: path.packagePaymentMappingsPath(),
        image: paymentPackageImg,
      },
    ],
  },
  {
    label: "บริษัทประกัน",
    subMenuItems: [
      {
        label: "บริษัทประกัน",
        roles: ["member.policy_admin", "member.biz_dev"],
        path: path.insurersPath(),
        image: companyImg,
      },
    ],
  },
  {
    label: "คอมมิชชัน",
    subMenuItems: [
      {
        label: "คอมมิชชันรับ",
        roles: ["member.accountant", "member.accountant_approver"],
        path: path.insurerCommissionsPath(),
        image: commissionInsurerImg,
      },
      {
        label: "คอมมิชชันจ่าย",
        roles: ["member.accountant", "member.accountant_approver"],
        path: path.saleCommissionsPath(),
        image: commissionPayImg,
      },
      {
        label: "ตั้งค่าคอมมิชชันรับ",
        roles: [
          "member.policy_admin",
          "member.biz_dev",
          "member.accountant",
          "member.accountant_approver",
        ],
        path: path.commissisonPath(),
        image: commissionInsurerSettingImg,
      },
      {
        label: "ตั้งค่าการแก้ไขคอมมิชชัน",
        roles: ["member.accountant_approver"],
        path: path.commissionEditSettingPath(),
        image: commissionSettingImg,
      },
      {
        label: "คำนวณโบนัส",
        roles: [
          "member.policy_admin",
          "member.biz_dev",
          "member.accountant",
          "member.accountant_approver",
        ],
        path: path.bonusCalculationPath(),
        image: commissionSettingImg,
      },
    ],
  },
  {
    label: "การบริหารจัดการทีม",
    subMenuItems: [
      {
        label: "การบริหารจัดการทีม",
        roles: ["member.admin", "member.biz_dev",],
        path: path.teamsPath(),
        image: teamMangeImg,
      },
      {
        label: "จัดการแผนการตลาด",
        roles: [
          "member.admin",
          "member.biz_dev",
          "member.accountant",
          "member.accountant_approver",
        ],
        path: path.marketPlansPath(),
        image: teamMangeImg,
      },
    ],
  },
  {
    label: "จัดการผู้ใช้",
    subMenuItems: [
      {
        label: "จัดการผู้ใช้ของทีคิวเอ็ม",
        roles: [
          "member.admin",
          "member.biz_dev",
          "member.accountant",
          "member.accountant_approver",
        ],
        path: path.tqmMembersPath(),
        image: userManageImg,
      },
    ],
  },
]

// const MENUITEMS = [
//   {
//     label: "จัดการลีดทีคิวเอ็ม",
//     roles: ["member.lead_admin"],
//     path: path.batchesPath(),
//   },
//   {
//     label: "ลีดทีคิวเอ็ม",
//     roles: ["member.team_leader", "member.salesman"],
//     path: path.leadsPath("all"),
//   },
//   {
//     label: "ลูกค้าใหม่",
//     roles: ["member.team_leader", "member.salesman", "member.biz_dev"],
//     path: path.leadsPath("custom"),
//   },
//   {
//     label: "คำสั่งซื้อ",
//     roles: ["member.team_leader", "member.salesman", "member.biz_dev"],
//     path: path.ordersPath(),
//   },
//   {
//     label: "ผลิตภัณฑ์",
//     roles: ["member.team_leader", "member.salesman", "member.biz_dev"],
//     path: path.homePath(),
//   },
//   {
//     label: "คอมมิชชั่นรับ",
//     roles: ["member.accountant", "member.accountant_approver"],
//     path: path.insurerCommissionsPath(),
//   },
//   {
//     label: "คอมมิชชั่นจ่าย",
//     roles: ["member.accountant", "member.accountant_approver"],
//     path: path.saleCommissionsPath(),
//   },
//   {
//     label: "ตั้งค่าการแก้ไข",
//     roles: ["member.accountant", "member.accountant_approver"],
//     path: path.commissionEditSettingPath(),
//   },
//   {
//     label: "บริษัทประกัน",
//     roles: ["member.policy_admin", "member.biz_dev"],
//     path: path.insurersPath(),
//   },
//   {
//     label: "แบบประกันชีวิต",
//     roles: ["member.policy_admin", "member.biz_dev"],
//     path: path.lifeInsurancePoliciesPath(),
//   },
//   {
//     label: "สัญญาเพิ่มเติม",
//     roles: ["member.policy_admin", "member.biz_dev"],
//     path: path.healthInsurancePoliciesPath(),
//   },
//   {
//     label: "จัดการแพ็กเก็จ",
//     roles: ["member.policy_admin", "member.biz_dev"],
//     path: path.packagesPath(),
//   },
//   {
//     label: "ตั้งค่าวิธีจ่ายเงิน",
//     roles: [
//       "member.policy_admin",
//       "member.biz_dev",
//       "member.accountant",
//       "member.accountant_approver",
//     ],
//     path: path.paymentMappingsPath(),
//   },
//   {
//     label: "ตั้งค่าวิธีจ่ายเงิน(แพ็กเกจ)",
//     roles: [
//       "member.policy_admin",
//       "member.biz_dev",
//       "member.accountant",
//       "member.accountant_approver",
//     ],
//     path: path.packagePaymentMappingsPath(),
//   },
//   {
//     label: "ตั้งค่าคอมมิชชั่นรับ",
//     roles: [
//       "member.policy_admin",
//       "member.biz_dev",
//       "member.accountant",
//       "member.accountant_approver",
//     ],
//     path: path.commissisonPath(),
//   },
//   {
//     label: "การบริหารจัดการทีม",
//     roles: ["member.admin"],
//     path: path.teamsPath(),
//   },
//   {
//     label: "จัดการผู้ใช้ของทีคิวเอ็ม",
//     roles: ["member.admin"],
//     path: path.tqmMembersPath(),
//   },
//   {
//     label: "จัดการแผนการตลาด",
//     roles: ["member.admin"],
//     path: path.marketPlansPath(),
//   },
//   {
//     label: "สรุปยอดขาย",
//     roles: ["member.admin", "member.team_leader", "member.salesman"],
//     path: path.dashboardPath(),
//   },
//   {
//     label: "สถิติ",
//     roles: ["member.admin", "member.team_leader", "member.salesman"],
//     path: path.statsPath(),
//   },
// ]

const enhancer = compose(
  defaultProps({ menuItems: MENUITEMS }),
  withStores((stores) => ({
    currentUser: stores.appStore.$("currentUser.nameThInfo.firstName"),
  })),
  withForm({ form: "leadInfoForm" }),
  withState("showPlanningModal", "setShowPlanningModal", false),
  withState("tabSelected", "setTabSelected", 0),
  withHandlers({
    makeOnClickLink: (props) => (path) =>
      path &&
      (() => {
        if (path.startsWith("http")) {
          window.open(path, "_blank")
        } else {
          setTimeout(() => props.history.push(path), 200)
        }
      }),
    onTabChange: (props) => (event) => {
      props.setTabSelected(event.selected)
    },
  }),
  lifecycle({
    componentDidMount() {
      if (isClient) {
        window.scrollTo(0, 0)
      }
    },
  })
)

export default enhancer(Landing)
