import React, { useCallback, useState } from "react"
import { Grid, GridColumn, Field, DatePicker } from "@components"
import styled from "@emotion/styled"
import { compose } from "recompose"
import { withHooks } from "@enhancers/index"

import {
  Layout,
  CustomInput as Input,
  CustomButton as Button,
  CustomIcon as Icon,
} from "./MonthlyBonusTable"

const Table = styled(Grid)`
  table {
    width: 100% !important;
  }

  .k-grid-header {
    height: 56px !important;
    padding: 0px !important;
  }

  .k-grid-header-wrap {
    align-self: center;
  }

  .k-header.k-link {
    padding: 17px 8px !important;
  }

  .k-master-row > td {
    padding: 8px !important;
    line-height: 21px;
    height: 56px;

    :nth-of-type(5) {
      margin: 0 !important;

      > div {
        width: 100%;
      }

      .k-widget.k-combobox.k-header.k-combobox-clearable {
        padding: unset !important;
        margin: 0 !important;
      }
    }
  }

  .k-grid-content.k-virtual-content {
    ::-webkit-scrollbar {
      width: 0px;
      background: transparent;
    }
  }

  .k-master-row.k-alt {
    > td:nth-of-type(2) {
      line-height: 21px;
    }
  }
`
const CustomDatePicker = styled(DatePicker)`
  width: 100%;
  max-width: 220px;
  height: 56px;
`
const CustomInput = styled(Input)`
  width: 100%;
  max-width: 220px;
  height: 56px;

  justify-content: unset;
`

const MonthlyCommissionTable = (props) => {
  return (
    <Layout>
      {props.readOnly ? (
        <Table data={props.data} readOnly={props.readOnly}>
          <GridColumn
            title="เปอร์เซ็นต์อัตราจ่าย (AFYP) (%)"
            cell={props.commissionPercentCell}
            width="80px"
          />
          <GridColumn title="วันที่มีผลบังคับใช้" cell={props.beginDateCell} width="100px" />

          {props.readOnly && <GridColumn title="" cell={props.addButtonCell} width="20px" />}
        </Table>
      ) : (
        <Table data={props.data}>
          <GridColumn
            title="เปอร์เซ็นต์อัตราจ่าย (AFYP) (%)"
            cell={props.commissionPercentCell}
            width="80px"
          />
          <GridColumn title="วันที่มีผลบังคับใช้" cell={props.beginDateCell} width="100px" />

          {props.readOnly && <GridColumn title="" cell={props.addButtonCell} width="20px" />}
        </Table>
      )}
    </Layout>
  )
}

const enhancer = compose(
  withHooks((props) => {
    const commissionPercentCell = useCallback(
      (cell) => {
        return (
          <td>
            <Field
              name={`companyCommissionRates[${cell.dataIndex}].commissionPercent`}
              component={CustomInput}
              onBlur={(e) => {
                e.preventDefault()
              }}
              placeholder="กรุณาระบุ"
              readOnly={!props.readOnly}
            />
          </td>
        )
      },
      [props.readOnly, props.data.length]
    )

    const beginDateCell = useCallback(
      (cell) => {
        return (
          <td>
            <Field
              name={`companyCommissionRates[${cell.dataIndex}].beginDate`}
              component={CustomDatePicker}
              placeholder="กรุณาเลือก"
              readOnly={!props.readOnly}
            />
          </td>
        )
      },
      [props.readOnly, props.data.length]
    )

    const addItem = useCallback(() => {
      props.change("companyCommissionRates", [
        ...props.data,
        {
          beginDate: null,
          commissionPercent: null,
        },
      ])
    }, [props.data])

    const removeItem = useCallback(
      (index) => () => {
        let array = props.data
        if (index > -1) {
          array.splice(index, 1)
        }

        props.change("companyCommissionRates", array)
      },
      [props.data]
    )

    const addButtonCell = useCallback(
      (cell) => {
        return (
          <td>
            {cell.dataIndex + 1 !== props.data.length ? (
              <Button onClick={removeItem(cell.dataIndex)} styletype="delete">
                <Icon icon="minus" />
              </Button>
            ) : (
              <Button onClick={() => addItem()} hidden={cell.dataIndex + 1 !== props.data.length}>
                <Icon icon="plus" />
              </Button>
            )}
          </td>
        )
      },
      [props.data.length, addItem, removeItem]
    )

    return {
      commissionPercentCell,
      beginDateCell,
      addButtonCell,
    }
  })
)

export default enhancer(MonthlyCommissionTable)
