import React, { useCallback, useEffect, useState } from "react"
import styled from "@emotion/styled"
import { compose, withHandlers, withProps } from "recompose"
import { Field, Button, DatePicker, Dropdown, TreeView, Icon } from "@components/index"
import moment from "moment"
import { withHooks, withRouter, withStores } from "@enhancers/index"
import convertUplineTree from "@common/convertUplineTree"
import { exceptNullKeys } from "@common/helper"

const Layout = styled.div`
  display: ${(props) => (props.hidden ? "none" : "flex")};
  flex-direction: column;
  border-radius: 4px;
  margin-top: 21px;
  padding: 24px;
  background-color: #fff;
`
const CustomField = styled(Field)`
  [class*="LeftPanel"] > div {
    font-weight: 400;

    > span {
      display: none;
    }
  }
`
const CustomDatePicker = styled(DatePicker)`
  [class*="RightPanel"] > div > span {
    width: 160px;
  }
`
const CustomDropdown = styled(Dropdown)`
  [class*="RightPanel"] > span {
    width: 256px;
  }
`
const TreeContainer = styled.div``
const TreeHeaderText = styled.div`
  margin-bottom: 17px;
  line-height: 21px;
`
const CustomTreeView = styled(TreeView)`
  border: 1px solid #666666;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 18px 24px;
  min-height: ${(props) => (props.withOutTree ? "128px" : "100%")};

  .k-treeview-lines {
    margin: 0px;
  }

  .k-in {
    :hover {
      background: white;
      border-color: white;
    }
  }

  .k-icon {
    font-size: 20px;
  }

  [class*="UplineTextContainer"] > div:nth-of-type(2) {
    color: #666666;
  }

  [aria-expanded] {
    margin-left: -4px;
  }
`
const TrashButton = styled(Button)`
  width: 100px;
  align-self: center;
  margin-top: 24px;
`

const UplineTreeLayout = styled.div`
  display: flex;
  flex-direction: row;
`
const UplineTextContainer = styled.div``

const UserIcon = styled(Icon)`
  align-self: center;
  font-size: 18px;
  color: ${(props) => props.color};
`
const UplineText = styled.div`
  font-size: 16px;
  line-height: 21px;
  color: ${(props) => props.color};
`

const UplineTreeComponent = (cell) => {
  return (
    <UplineTreeLayout>
      <UserIcon color={cell.item.value.colorCode} awesomeIcon={["fas", "user"]} />
      <UplineTextContainer>
        <UplineText color={cell.item.value.colorCode} style={{ marginBottom: "4px" }}>
          {cell.item.value.name}
        </UplineText>
        <UplineText>{cell.item.value.levelDisplay}</UplineText>
      </UplineTextContainer>
    </UplineTreeLayout>
  )
}

const SalesCommissionPlansCard = (props) => {
  return (
    <Layout hidden={props.readOnly && props.uplineTreePlanData.length === 0}>
      <CustomField
        component={CustomDatePicker}
        name={`${props.stucture}.beginDate`}
        caption="วันที่มีผลบังคับ"
        leftWidth="168"
        min={moment()._d}
        readOnly={props.readOnly}
        validate={props.required ? "required" : null}
        placeholder="กรุณาเลือก"
        style={{ marginBottom: "16px" }}
      />

      <CustomField
        component={CustomDropdown}
        name={`${props.stucture}.mkPlanId`}
        caption="ชื่อแผน"
        leftWidth="168"
        options={props.marketPlanOptions}
        readOnly={props.readOnly}
        validate={props.required ? "required" : null}
        placeholder="กรุณาเลือก"
        style={{ marginBottom: "16px" }}
        onChange={props.changeMarketPlan}
      />

      <CustomField
        component={CustomDropdown}
        name={`${props.stucture}.positionLevel`}
        caption="ตำแหน่ง"
        leftWidth="168"
        options={props.positionLevelOptions}
        readOnly={props.readOnly}
        validate={props.required ? "required" : null}
        placeholder="กรุณาเลือก"
        style={{ marginBottom: "16px" }}
      />

      <CustomField
        component={CustomDropdown}
        name={`${props.stucture}.uplineId`}
        caption="อัพไลน์"
        leftWidth="168"
        options={props.uplineOptions}
        readOnly={props.readOnly}
        placeholder="กรุณาเลือก"
        style={{ marginBottom: "22px" }}
        disabled={
          props.positionLevelOptions
            ? props.values.positionLevel === undefined ||
              props.values.positionLevel === props.positionLevelOptions.length
            : false
        }
      />

      <TreeContainer>
        <TreeHeaderText>ผังต้นไม้ของอัพไลน์</TreeHeaderText>
        <CustomTreeView
          data={props.uplineTreePlanData}
          expandIcons={true}
          itemRender={UplineTreeComponent}
          withOutTree={!props.uplineTreePlanData || props.uplineTreePlanData.length === 0}
        />
      </TreeContainer>

      <TrashButton
        icon="trash"
        onClick={() => props.onDeleteStucture(props.index)}
        styletype="delete"
        hidden={props.readOnly}
      >
        ลบแผน
      </TrashButton>
    </Layout>
  )
}

const enhance = compose(
  withRouter(),
  withStores((stores) => ({
    fetchUplineTreePlan: stores.tqmUserStore.fetchUplineTreePlan,
  })),
  withProps((props) => {
    const moreThanOneBeneficiary = props.fields.length > 1
    const canEdit = (props.fields.get(props.index) || {}).canEdit
    const readOnly = canEdit !== undefined ? props.readOnly || !canEdit : props.readOnly
    const values = props.fields.get(props.index) || {}

    return {
      moreThanOneBeneficiary,
      canEdit,
      readOnly,
      values,
    }
  }),
  withHandlers({
    onDeleteStucture: (props) => (index) => {
      props.onDeleteStucture(index)
    },
  }),
  withHooks((props) => {
    const [positionLevelOptions, setPositionLevelOptions] = useState([])
    const [uplineTreePlanData, setUplineTreePlanData] = useState([])

    useEffect(() => {
      if (props.values.uplineTreePlan) {
        const uplineTreePlan = convertUplineTree(props.values.uplineTreePlan)
        setUplineTreePlanData(uplineTreePlan)
      } else {
        if (
          props.values.beginDate &&
          props.values.mkPlanId &&
          props.values.positionLevel &&
          props.values.uplineId
        ) {
          const callApi = async () => {
            const exceptNullParams = exceptNullKeys(
              props.values,
              "beginDate",
              "mkPlanId",
              "positionLevel",
              "uplineId"
            )
            const fullName = exceptNullKeys(props.fullname)
            const response = await props.fetchUplineTreePlan({
              ...exceptNullParams,
              fullName,
              memberId: props.match.params.id,
            })
            const uplineTreePlan = convertUplineTree(response)
            setUplineTreePlanData(uplineTreePlan)
          }
          callApi()
        } else {
          setUplineTreePlanData([])
        }
      }
    }, [props.values.uplineTreePlan, props.fields.length])

    const changeMarketPlan = useCallback(
      (event) => {
        const positionLevelOptions = (
          (props.marketPlanOptions.find((option) => option.value === event) || {}).positionLevel ||
          {}
        ).options

        setPositionLevelOptions(positionLevelOptions)
        props.change(`${props.stucture}.positionLevel`, null)
      },
      [props.marketPlanOptions, props.values]
    )

    useEffect(() => {
      async function changeUplineTree() {
        try {
          if (!props.readOnly && props.values.positionLevel === null) {
            setUplineTreePlanData([])
            props.change(`${props.stucture}.uplineId`, null)
          } else if (
            props.values.beginDate &&
            props.values.mkPlanId &&
            props.values.positionLevel &&
            !props.readOnly
          ) {
            const exceptNullParams = exceptNullKeys(
              props.values,
              "beginDate",
              "mkPlanId",
              "positionLevel"
            )
            const fullName = exceptNullKeys(props.fullname)
            const response = await props.fetchUplineTreePlan({
              ...exceptNullParams,
              fullName,
              memberId: props.match.params.id,
              uplineId: props.values.uplineId,
            })
            const uplineTreePlan = convertUplineTree(response)
            setUplineTreePlanData(uplineTreePlan)
          }
        } catch (e) {
          if (e.message === "Submit Validation Failed") {
            alert(
              "ต้องกรอกวันที่มีผลบังคับ,ชื่อแผนและตำแหน่งให้ครบถ้วน จึงจะแสดงผังต้นไม้ของอัพไลน์ได้"
            )
          } else {
            alert("เกิดข้อผิดพลาดบางอย่างขึ้น กรุณาลองใหม่ภายหลัง")
            console.log(e)
          }
        }
      }

      changeUplineTree()
    }, [props.values.mkPlanId, props.values.positionLevel, props.values.uplineId])

    useEffect(() => {
      const positionLevelOptions = (
        (props.marketPlanOptions.find((option) => option.value === props.values.mkPlanId) || {})
          .positionLevel || {}
      ).options
      setPositionLevelOptions(positionLevelOptions)
    }, [props.marketPlanOptions, props.values])

    return {
      positionLevelOptions,
      uplineTreePlanData,
      changeMarketPlan,
    }
  })
)
export default enhance(SalesCommissionPlansCard)
