import React from "react"
import styled from "@styled"
import { Field, Dropdown, DatePicker, Input } from "@components"
import { compose } from "recompose"
import { withStores } from "@enhancers/index"

const Layout = styled.div`
  padding-left: 5px;
  padding-bottom: 5px;
`
const Row = styled.div`
  display: flex;
  margin-top: 20px;

  &:nth-of-type(1) {
    margin-top: 0;
  }

  &:last-of-type {
    > div {
      margin-left: 16px;
    }
  }

  > div {
    margin-left: 32px;

    &:nth-of-type(1) {
      margin-left: 0;
    }
  }
`
const CustomField = styled(Field)`
  > div > div {
    font-weight: unset;
  }
`

const FilterPanel = (props) => (
  <Layout>
    <Row>
      <CustomField
        component={Dropdown}
        name="affiliateTeamId"
        caption="ชื่อทีมผู้แนะนำ"
        captionStyle={{ fontWeight: "normal" }}
        options={props.affiliateTeamOptions}
        leftWidth="150"
        rightWidth="231"
        placeholder="กรุณาเลือก"
      />
    </Row>
    <Row>
      <CustomField
        component={Dropdown}
        name="areaCode"
        caption="พื้นที่"
        captionStyle={{ fontWeight: "normal" }}
        options={props.areaOptions}
        leftWidth="150"
        rightWidth="231"
        placeholder="กรุณาเลือก"
      />
    </Row>
    <Row>
      <CustomField
        component={Dropdown}
        name="branchCode"
        caption="สาขา"
        captionStyle={{ fontWeight: "normal" }}
        options={props.branchOptions}
        leftWidth="150"
        rightWidth="231"
        placeholder="กรุณาระบุ"
      />
    </Row>
    <Row>
      <CustomField
        component={Input}
        name="leaderStaffCode"
        caption="รหัสหัวหน้าผู้แนะนำ"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="150"
        rightWidth="231"
        placeholder="กรุณาระบุ"
      />
    </Row>
    <Row>
      <CustomField
        component={Input}
        name="leaderName"
        caption="ชื่อหัวหน้าผู้แนะนำ"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="150"
        rightWidth="231"
        placeholder="กรุณาระบุ"
      />
    </Row>
  </Layout>
)

const enhancer = compose(
  withStores((stores) => ({
    affiliateTeamOptions: stores.saleCommissionStore.$("configs.filter.affiliateTeam.options", []),
    areaOptions: stores.saleCommissionStore.$("configs.filter.area.options", []),
    branchOptions: stores.saleCommissionStore.$("configs.filter.branch.options", []),
  }))
)

export default enhancer(FilterPanel)
