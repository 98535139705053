/* eslint-disable no-dupe-keys */
import React, { Fragment, useState, useEffect, useCallback, useMemo } from "react"
import styled from "@emotion/styled"
import { withStores, withRouter, withHooks } from "@enhancers"
import { BreadCrumb, Authorize } from "@components"
import { compose, withProps } from "recompose"
import * as path from "@common/path"
import Table from "./Table"

import { map, get } from "lodash"
import Loading from "@pages/index/Loading"
import { exceptNullKeys, call } from "@common/helper"
import Header from "./Header"
import { desktopSize } from "@common/styled"
import { useStores } from "@hooks/index"

const Layout = styled.div`
  margin: ${(props) => (props.shouldMarginTop ? "60px auto 25px auto" : "0px auto 25px auto")};
  width: 100%;
  overflow-x: hidden;
`
export const StickyTab = styled.div`
  height: 40px;
  width: 100%;
  position: -webkit-sticky;
  position: fixed;
  top: 60px;
  left: 0px;
  z-index: 3;
  display: ${(props) => (props.shouldDisplayTab ? "flex" : "none")};
  justify-content: center;
  align-items: center;

  ${(props) =>
    desktopSize(`
      background-color: #229ec0;
  `)}
`
export const DataContainer = styled.div`
  display: flex;
  height: 40px;
  width: 82%;

  @media screen and (max-width: 768px) {
    width: 100%;
    padding-left: 64px;
  }
`
export const AllLeadContainer = styled.div`
  max-width: 188px;
  width: 100%;
  background-color: #0275d8;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  font-weight: bold;
`
export const AssignedLeadContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  background-color: #229ec0;
  justify-content: flex-end;
  margin-right: 24px;
`
const UnassignToTeamLeadContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  background-color: #229ec0;
  margin-right: 24px;
`
const UnassignLeadContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  background-color: #229ec0;
  margin-right: 24px;
`
export const RightPanel = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  background-color: #229ec0;
`

const BatchLeadsIndexPage = (props) => (
  <Fragment>
    {props.loading ? (
      <Loading />
    ) : (
      <Fragment>
        <Authorize roles={["member.team_leader"]}>
          <StickyTab shouldDisplayTab={props.batchId === "all"}>
            <DataContainer>
              <AllLeadContainer>{`ทั้งหมด ${props.leadsCount || 0} ลีด`}</AllLeadContainer>
              <RightPanel>
                <AssignedLeadContainer>{`มอบหมายแล้ว ${
                  props.assignedLeadsCount || 0
                } ลีด`}</AssignedLeadContainer>
                <UnassignToTeamLeadContainer>{`โยกลีดกลับได้ ${
                  props.unassignableLeadsCount || 0
                } ลีด`}</UnassignToTeamLeadContainer>
                <UnassignLeadContainer>{`ยังไม่มอบหมาย ${
                  props.teamAssignedLeadsCount || 0
                } ลีด`}</UnassignLeadContainer>
              </RightPanel>
            </DataContainer>
          </StickyTab>
        </Authorize>

        <Layout
          shouldMarginTop={props.currentRole === "member.team_leader" && props.batchId === "all"}
        >
          <Header
            selectedLeadIds={props.selectedLeadIds}
            disabledStateFilter={props.disabledStateFilter}
            setSelectedLeadIds={props.setSelectedLeadIds}
            teams={props.teams}
          />
          <Table
            // loading={props.loadingTable}
            loadingTable={props.loadingTable}
            data={props.leads}
            teams={props.teams}
            paging={props.paging}
            onPageChange={props.changePage}
            rowPath={(dataItem) => path.leadPath(dataItem.id)}
            showSelectColumn={props.selectingLead}
            onSelectRow={props.selectRow}
            footer={props.footer}
            onSelectAll={props.selectAll}
          />
        </Layout>
      </Fragment>
    )}
  </Fragment>
)

const enhancer = compose(
  withRouter(),
  withStores((stores, props) => ({
    fetchLeadCountInfo: stores.leadStore.fetchCountInfo,
    fetchConfigs: stores.leadStore.fetchConfigs,
    fetchTeams: stores.teamStore.fetch,
    fetchLeads: stores.leadStore.fetch,
    paging: stores.leadStore.paging,
    batchId: props.match.params.batchId,
    currentRole: stores.appStore.currentRole,
    teamId: stores.appStore.$("currentSale.team.id"),
    saleId: stores.appStore.$("currentSale.id"),
    footer: stores.leadStore.footer,
    query: props.location.query,
    mode: props.location.query.mode,
  })),
  withProps((props) => {
    const newProps = {}
    newProps.initialState = "new"

    return {
      initialState: newProps.initialState,
      ...props,
    }
  }),
  withHooks((props) => {
    const [leads, setLeads] = useState([])
    const [teams, setTeams] = useState([])
    const [paging, setPaging] = useState({})
    const [footer, setFooter] = useState({})
    const [leadCountInfo, setLeadCountInfo] = useState([])
    const [selectedLeadIds, setSelectedLeadIds] = useState([])

    const [loading, setLoading] = useState(false)
    const [initialized, setInitialized] = useState(false)
    const [loadingTable, setLoadingTable] = useState(false)
    const [toggleAssignedSuccessModalValue, setToggleAssignedSuccessModal] = useState(false)

    const { leadsCount, assignedLeadsCount, unassignableLeadsCount, teamAssignedLeadsCount } =
      leadCountInfo || {}

    let customLeads = leads

    switch (props.mode) {
      case "deleting":
        break
      case "assigning":
        customLeads = leads.filter((l) => l.state === "team_assigned")
        break
      case "unAssigning":
        customLeads = leads.filter((l) => l.state === "assigned" || l.state === "team_assigned")
        break
      case "unAssigningToTeam":
        customLeads = leads.filter((l) => l.state === "assigned")
        break
      default:
        break
    }

    customLeads.forEach((lead) => {
      lead.selected = selectedLeadIds.includes(lead.id)
      switch (props.mode) {
        case "deleting":
          lead.disabled = false
          lead.hasSelect = lead.state === "new"
          break
        case "assigning":
          lead.disabled = false
          lead.hasSelect = lead.state === "team_assigned"
          break
        case "unAssigning":
          lead.disabled = false
          lead.hasSelect = lead.state === "assigned" || lead.state === "team_assigned"
          break
        case "unAssigningToTeam":
          lead.disabled = false
          lead.hasSelect = lead.state === "assigned"
          break
        default:
          lead.disabled = false
          lead.hasSelect = false
          break
      }
    })

    const changePage = useCallback(async (page) => {
      props.history.push(
        path.leadsPath(props.batchId, {
          ...props.query,
          page: page,
        })
      )
    })

    const selectAll = useCallback((selected) => {
      if (selected) {
        setSelectedLeadIds(map(customLeads, "id"))
      } else {
        setSelectedLeadIds([])
      }
    })

    const selectRow = useCallback((value, dataItem) => {
      if (value) {
        setSelectedLeadIds([...selectedLeadIds, dataItem.id])
      } else {
        setSelectedLeadIds(selectedLeadIds.filter((id) => id !== dataItem.id))
      }
    })

    const closeAssignSuccessModal = useCallback(() => {
      setToggleAssignedSuccessModal(!props.toggleAssignedSuccessModal)
    })

    const configParams = {
      "user.admin": { leadBatchId: props.batchId },
      "member.salesman": { assigneeId: props.saleId },
      "member.affiliate": { assigneeId: props.saleId },
      "member.team_leader": { teamId: props.teamId },
      "member.biz_dev":
        props.batchId === "custom" ? { isPersonalLead: true } : { leadBatchId: props.batchId },
      "member.admin":
        props.batchId === "custom" ? { isPersonalLead: true } : { leadBatchId: props.batchId },
      "member.policy_admin": { isPersonalLead: true },
    }[props.currentRole]

    const defaultParams = useMemo(() => {
      if (props.currentRole === "member.team_leader") {
        if (props.batchId === "custom") {
          return {
            assigneeId: { eq: props.saleId },
            isPersonalLead: true,
          }
        } else {
          return {
            teamId: { eq: props.teamId },
          }
        }
      } else if (props.currentRole === "member.salesman") {
        if (props.batchId === "all") {
          return {
            assigneeId: { eq: props.saleId },
            isPersonalLead: false,
          }
        } else if (props.batchId === "custom") {
          return {
            assigneeId: { eq: props.saleId },
            isPersonalLead: true,
          }
        } else {
          return {
            leadBatchId: { eq: props.batchId },
            assigneeId: { eq: props.saleId },
          }
        }
      } else if (props.currentRole === "member.affiliate") {
        if (props.batchId === "custom") {
          return {
            assigneeId: { eq: props.saleId },
            isPersonalLead: true,
          }
        } else {
          return {
            leadBatchId: { eq: props.batchId },
            assigneeId: { eq: props.saleId },
          }
        }
      } else if (["member.biz_dev", "member.admin"].includes(props.currentRole)) {
        if (props.batchId === "custom") {
          return {
            isPersonalLead: true,
          }
        }

        return {
          leadBatchId: { eq: props.batchId },
        }
      } else {
        return {
          isPersonalLead: true,
        }
      }
    }, [props.currentRole])

    const fetchLeads = useCallback(async (params) => {
      const exceptNullParams = exceptNullKeys(
        params,
        "age.min",
        "age.max",
        "state.eq",
        "gender.eq",
        "province",
        "district",
        "subDistrict",
        "teamId.eq",
        "staffCode",
        "salesmanName"
      )

      return await props.fetchLeads({
        ...defaultParams,
        ...exceptNullParams,
      })
    })

    const fetchLeadCountInfo = useCallback(async (params) => {
      const exceptNullParams = exceptNullKeys(
        params,
        "age.min",
        "age.max",
        "state.eq",
        "gender.eq",
        "province",
        "district",
        "subDistrict",
        "teamId.eq",
        "staffCode",
        "salesmanName"
      )

      return await props.fetchLeadCountInfo({
        ...defaultParams,
        ...exceptNullParams,
      })
    })

    useEffect(() => {
      call(async () => {
        setLoadingTable(true)

        if (["assigning", "unAssigning", "unAssigningToTeam"].includes(props.mode)) {
          setSelectedLeadIds([])
        }

        const [leadCountInfo, { leads, paging, footer }, teams, _] = await Promise.all([
          fetchLeadCountInfo({ ...props.query }),
          fetchLeads({ ...props.query }),
          props.fetchConfigs(configParams),
        ])

        setLeadCountInfo(leadCountInfo)
        setLeads(leads)
        setPaging(paging)
        setFooter(footer)

        if (["assigning", "unAssigning", "unAssigningToTeam"].includes(props.mode)) {
          setSelectedLeadIds(leads.map((lead) => lead.id))
        }

        setInitialized(true)
        setLoadingTable(false)
      })
    }, [JSON.stringify(props.query)])

    return {
      loading,
      initialized,
      loadingTable,
      leads: customLeads,
      teams,
      paging,
      footer,
      toggleAssignedSuccessModalValue,
      selectedLeadIds,

      leadsCount,
      assignedLeadsCount,
      unassignableLeadsCount,
      teamAssignedLeadsCount: teamAssignedLeadsCount || leadsCount - assignedLeadsCount,

      selectAll,
      selectRow,
      changePage,
      closeAssignSuccessModal,
      setToggleAssignedSuccessModal,

      selectingLead: ["deleting", "assigning", "unAssigning", "unAssigningToTeam"].includes(
        props.mode
      ),
      disabledFilter: [].includes(props.mode) || !initialized,
      disabledStateFilter: ["assigning", "unAssigningToTeam"].includes(props.mode) || !initialized,
      batchCode: get(leads, "[0].batchCode", props.batchId),
    }
  })
)

export default enhancer(BatchLeadsIndexPage)
