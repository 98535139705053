import React from "react"
import styled from "@styled"
import { Field, Dropdown, DatePicker, Input } from "@components"
import { compose } from "recompose"
import { withStores } from "@enhancers/index"

const Layout = styled.div`
  padding-left: 5px;
  padding-bottom: 5px;
`
const Row = styled.div`
  display: flex;
  margin-top: 20px;

  &:nth-of-type(1) {
    margin-top: 0;
    > div:nth-of-type(2) {
      margin-left: 44px;
    }
  }

  &:nth-of-type(3) {
    > div:nth-of-type(2) {
      margin-left: 44px;
    }
  }

  &:nth-of-type(4) {
    > div:nth-of-type(2) {
      margin-left: 44px;
    }
  }

  &:last-of-type {
    > div {
      margin-left: 16px;
    }
  }

  > div {
    &:nth-of-type(1) {
      margin-left: 0;
    }
    &:nth-of-type(2) {
      margin-left: 16px;
    }
  }
`
const CustomField = styled(Field)`
  > div > div {
    font-weight: unset;
  }
`

const FilterPanel = (props) => (
  <Layout>
    <Row>
      <CustomField
        component={Input}
        name="tqmOrderId"
        caption="รหัสคำสั่งซื้อ"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="99"
        rightWidth="231"
        placeholder="กรุณาระบุ"
      />
      <CustomField
        component={Dropdown}
        name="state"
        caption="สถานะ"
        captionStyle={{ fontWeight: "normal" }}
        options={props.stateOptions}
        leftWidth="117"
        rightWidth="231"
        placeholder="กรุณาเลือก"
      />
    </Row>
    <Row>
      <CustomField
        component={Dropdown}
        name="insuranceCompanyId"
        caption="ชื่อบริษัท"
        captionStyle={{ fontWeight: "normal" }}
        options={props.insuranceCompanyOptions}
        leftWidth="99"
        rightWidth="231"
        placeholder="กรุณาเลือก"
      />
    </Row>
    <Row>
      <CustomField
        component={Input}
        name="memberName"
        caption="ชื่อพนักงาน"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="99"
        rightWidth="231"
        placeholder="กรุณาระบุ"
      />
      <CustomField
        component={Dropdown}
        name="memberStatus"
        caption="สถานะพนักงาน"
        captionStyle={{ fontWeight: "normal" }}
        options={props.memberStatusOptions}
        leftWidth="117"
        rightWidth="231"
        placeholder="กรุณาเลือก"
      />
    </Row>
    <Row>
      <CustomField
        component={Dropdown}
        name="salesmanPosition"
        caption="ตำแหน่ง"
        captionStyle={{ fontWeight: "normal" }}
        options={props.salesmanPositionOptions}
        leftWidth="99"
        rightWidth="231"
        placeholder="กรุณาเลือก"
      />
      <CustomField
        component={Dropdown}
        name="editingStatus"
        caption="การแก้ไข"
        captionStyle={{ fontWeight: "normal" }}
        options={props.editingStatusOptions}
        leftWidth="117"
        rightWidth="231"
        placeholder="กรุณาเลือก"
      />
    </Row>
    <Row>
      <CustomField
        component={Input}
        name="affiliateStaffCode"
        caption="รหัสผู้แนะนำ"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="99"
        rightWidth="231"
        placeholder="กรุณาระบุ"
      />
      <CustomField
        component={Input}
        name="affiliateName"
        caption="ชื่อผู้แนะนำ"
        captionStyle={{ fontWeight: "normal", marginLeft: "28px" }}
        leftWidth="117"
        rightWidth="231"
        placeholder="กรุณาระบุ"
      />
    </Row>
    <Row>
      <CustomField
        component={Dropdown}
        name="affiliateTeamId"
        caption="ทีมผู้แนะนำ"
        captionStyle={{ fontWeight: "normal" }}
        options={props.affiliateTeamOptions}
        leftWidth="99"
        rightWidth="231"
        placeholder="กรุณาเลือก"
      />
    </Row>
    <Row>
      <CustomField
        component={DatePicker}
        name="affiliateSubmittedAt.min"
        caption="วันที่แจ้งแนะนำ"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="120"
        rightWidth="155"
        placeholder="กรุณาเลือก"
        showErrorMessage={true}
      />
      <CustomField
        component={DatePicker}
        name="affiliateSubmittedAt.max"
        caption="ถึง"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="29"
        rightWidth="155"
        placeholder="กรุณาเลือก"
        showErrorMessage={true}
      />
    </Row>
    <Row>
      <CustomField
        component={DatePicker}
        name="submittedAt.min"
        caption="ช่วงวันที่แจ้งงาน"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="120"
        rightWidth="155"
        placeholder="กรุณาเลือก"
        showErrorMessage={true}
      />
      <CustomField
        component={DatePicker}
        name="submittedAt.max"
        caption="ถึง"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="29"
        rightWidth="155"
        placeholder="กรุณาเลือก"
        showErrorMessage={true}
      />
    </Row>
    <Row>
      <CustomField
        component={DatePicker}
        name="receivedAt.min"
        caption="ช่วงวันที่รับเงิน"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="120"
        rightWidth="155"
        placeholder="กรุณาเลือก"
        showErrorMessage={true}
      />
      <CustomField
        component={DatePicker}
        name="receivedAt.max"
        caption="ถึง"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="29"
        rightWidth="155"
        placeholder="กรุณาเลือก"
        showErrorMessage={true}
      />
    </Row>
    <Row>
      <CustomField
        component={DatePicker}
        name="coverageDate.min"
        caption="ช่วงวันที่คุ้มครอง"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="120"
        rightWidth="155"
        placeholder="กรุณาเลือก"
        showErrorMessage={true}
      />
      <CustomField
        component={DatePicker}
        name="coverageDate.max"
        caption="ถึง"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="29"
        rightWidth="155"
        placeholder="กรุณาเลือก"
        showErrorMessage={true}
      />
    </Row>
    <Row>
      <CustomField
        component={DatePicker}
        name="calculateCommissionExportedDate.min"
        caption="ช่วงวันที่นำออกคำนวณคอมมิชชั่น"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="267"
        rightWidth="155"
        placeholder="กรุณาเลือก"
        showErrorMessage={true}
      />
      <CustomField
        component={DatePicker}
        name="calculateCommissionExportedDate.max"
        caption="ถึง"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="29"
        rightWidth="155"
        placeholder="กรุณาเลือก"
        showErrorMessage={true}
      />
    </Row>
    <Row>
      <CustomField
        component={DatePicker}
        name="salesCommissionExportedDate.min"
        caption="ช่วงวันที่นำออกคอมมิชชั่นจ่าย"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="267"
        rightWidth="155"
        placeholder="กรุณาเลือก"
        showErrorMessage={true}
      />
      <CustomField
        component={DatePicker}
        name="salesCommissionExportedDate.max"
        caption="ถึง"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="29"
        rightWidth="155"
        placeholder="กรุณาเลือก"
        showErrorMessage={true}
      />
    </Row>
    <Row>
      <CustomField
        component={DatePicker}
        name="pendingCommissionExportedDate.min"
        caption="ช่วงวันที่นำออกคอมมิชชั่นค้างจ่าย"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="267"
        rightWidth="155"
        placeholder="กรุณาเลือก"
        showErrorMessage={true}
      />
      <CustomField
        component={DatePicker}
        name="pendingCommissionExportedDate.max"
        caption="ถึง"
        captionStyle={{ fontWeight: "normal" }}
        leftWidth="29"
        rightWidth="155"
        placeholder="กรุณาเลือก"
        showErrorMessage={true}
      />
    </Row>
  </Layout>
)

const enhancer = compose(
  withStores((stores) => ({
    salesmanPositionOptions: stores.saleCommissionStore.$(
      "configs.filters.salesmanPosition.options",
      []
    ),
    editingStatusOptions: stores.saleCommissionStore.$("configs.filters.editingStatus.options", []),
    stateOptions: stores.saleCommissionStore.$("configs.filters.state.options", []),
    memberStatusOptions: stores.saleCommissionStore.$("configs.filters.memberStatus.options", []),
    insuranceCompanyOptions: stores.saleCommissionStore.$(
      "configs.filters.insuranceCompany.options",
      []
    ),
    affiliateTeamOptions: stores.saleCommissionStore.$("configs.filters.affiliateTeam.options", []),
  }))
)

export default enhancer(FilterPanel)
